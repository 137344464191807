import React, { FC, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components/native';
import { RenderItemParams } from 'react-native-draggable-flatlist';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { CustomText } from '../../../../components/shared';
import DragIcon from '../../../../assets/icons/PlaylistCreation/icons-drag.svg';
import DragIconSecondary from '../../../../assets/icons/PlaylistCreation/icons-drag-secondary.svg';
import PlayIcon from '../../../../assets/icons/PlaylistCreation/play-icon-bite-card.svg';
import RadioButton from '../../../../assets/icons/radio-default.svg';
import RadioButtonSelected from '../../../../assets/icons/radio-selected.svg';
import { ISelectedBite } from '../../../../store/createPlaylist/createPlaylist.types';
import { toggleSelectedToRemoveBite } from '../../../../store/createPlaylist/createPlaylist.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectedBiteIdsToRemoveMapSelector } from '../../../../store/createPlaylist/createPlaylist.selectors';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import WithoutCoverOverlay from '../../../../components/feed/biteItemOverlays/WithoutCoverOverlay';
import { AWS_URL } from '../../../../utils/constants/env';
import openPreviewBite from '../../../../utils/bite/openPreviewBite';

interface IProps {
  renderItemParams: RenderItemParams<ISelectedBite>;
  isDeleteBiteMode?: boolean;
}

const OrganizeBiteItem: FC<IProps> = ({ renderItemParams, isDeleteBiteMode }) => {
  const { item: bite, drag, isActive } = renderItemParams;
  const dispatch = useDispatch();
  const selectedBiteIdsToRemoveMap = useSelector(selectedBiteIdsToRemoveMapSelector);
  const selectBiteToRemove = selectedBiteIdsToRemoveMap[bite.id];
  const animatedLeft = useSharedValue(0);

  useEffect(() => {
    animatedLeft.value = isActive ? calcWidth(20) : 0;
  }, [animatedLeft, isActive]);

  const image = useMemo(() => ({ uri: bite?.linked_cover_url || null }), [bite?.linked_cover_url]);
  const workspaceLogoUrl = bite?.branding?.logo_url ? `${AWS_URL}/${bite.branding.logo_url}` : null;
  const withWorkspaceLogo = !image.uri;

  const handleBiteToRemoveSelection = useCallback(() => {
    dispatch(toggleSelectedToRemoveBite(bite.id));
  }, [dispatch, bite]);

  const openPreview = useCallback(() => {
    openPreviewBite({
      biteId: bite?.id,
      withoutDelete: true,
      withoutEdit: true,
      withoutClone: true,
    });
  }, [bite]);

  const shadowOffset: ShadowProps['offset'] = useMemo(() => [0, 2], []);

  const animatedStyle = useAnimatedStyle(() => ({
    position: 'relative',
    left: withSpring(animatedLeft.value, {
      damping: 15,
      mass: 1,
      stiffness: 150,
      overshootClamping: true,
      restSpeedThreshold: 0.001,
      restDisplacementThreshold: 0.001,
    }),
  }));

  return (
    <Animated.View style={animatedStyle}>
      <S.Container>
        <ShadowedContainer distance={isActive ? 18 : 14} offset={shadowOffset} radius={16}>
          <S.InnerContainer>
            <S.BiteCard isDeleteBiteMode={isDeleteBiteMode} isActive={isActive}>
              <S.BiteInfo>
                <S.ThumbnailContainer onPress={openPreview}>
                  <S.PreviewImage source={image}>
                    {withWorkspaceLogo && <WithoutCoverOverlay workspaceLogoUrl={workspaceLogoUrl} small />}
                    <S.ImageWrapper>
                      <PlayIcon style={{ width: calcWidth(25), height: calcHeight(25) }} />
                    </S.ImageWrapper>
                  </S.PreviewImage>
                </S.ThumbnailContainer>
                <BiteTitleContainer>
                  <S.BiteTitle lineBreakMode='tail'>{bite.subject}</S.BiteTitle>
                </BiteTitleContainer>
              </S.BiteInfo>
              {!isDeleteBiteMode && (
                <S.DragButtonContainer onLongPress={drag} delayLongPress={10}>
                  <S.DragIconContainer isActive={isActive}>
                    {isActive ? <DragIconSecondary /> : <DragIcon />}
                  </S.DragIconContainer>
                </S.DragButtonContainer>
              )}
            </S.BiteCard>

            {isDeleteBiteMode && (
              <S.Selectable onPress={handleBiteToRemoveSelection}>
                {!selectBiteToRemove ? <RadioButton /> : <RadioButtonSelected />}
              </S.Selectable>
            )}
          </S.InnerContainer>
        </ShadowedContainer>
      </S.Container>
    </Animated.View>
  );
};

const Container = styled.View`
  padding-top: ${calcHeight(8)}px;
  padding-left: ${calcHeight(30)}px;
  padding-right: ${calcHeight(30)}px;
  padding-bottom: ${calcHeight(8)}px;
  border-radius: 15px;
  width: 100%;
`;
const InnerContainer = styled.View`
  flex-direction: row;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.lightGray1};
  height: ${calcHeight(94)}px;
  border-radius: 15px;
  justify-content: center;
  width: 100%;
`;

const BiteCard = styled.View<{ isDeleteBiteMode: boolean; isActive: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  background-color: ${({ theme, isDeleteBiteMode, isActive }) =>
    isDeleteBiteMode ? theme.colors.pink : isActive ? theme.colors.blueSticky : theme.colors.white};
  border-radius: 15px;
`;

const BiteInfo = styled.View`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
`;

const PreviewImage = styled.ImageBackground`
  height: ${calcHeight(69)}px;
  width: ${calcHeight(49)}px;
  border-radius: 10px;
  margin-left: ${calcWidth(10)}px;
  margin-top: ${calcWidth(10)}px;
  margin-bottom: ${calcWidth(10)}px;
  background-color: ${({ theme }) => theme.colors.lightGray1};
  overflow: hidden;
`;

const BiteTitleContainer = styled.View`
  justify-content: center;
  width: ${calcWidth(180)}px;
  margin-left: ${calcWidth(18)}px;
`;

const BiteTitle = styled(CustomText)`
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const DragButtonContainer = styled.TouchableOpacity`
  margin-right: 15px;
  height: 41px;
  width: 41px;
`;

const DragIconContainer = styled.View<{ isActive: boolean }>`
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryBlue : '#f2f2f2')};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%
  width: 100%
`;

const Selectable = styled.TouchableOpacity`
  margin-left: ${calcWidth(16)}px;
`;

const ThumbnailContainer = styled.TouchableOpacity``;

const ImageWrapper = styled.View`
  position: absolute;
  flex-direction: row;
  align-items: center;
  margin: ${calcWidth(40)}px 0 0 ${calcHeight(20)}px;
`;

const S = {
  Container,
  InnerContainer,
  DragIconContainer,
  ThumbnailContainer,
  PreviewImage,
  ImageWrapper,
  BiteCard,
  BiteTitleContainer,
  BiteTitle,
  BiteInfo,
  DragButtonContainer,
  Selectable,
};

export default OrganizeBiteItem;
