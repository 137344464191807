import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Modal from '../ModalController';
import styled, { css } from 'styled-components/native';
import RadioForm, { RadioButton, RadioButtonInput } from 'react-native-simple-radio-button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ColorPicker from './components/ColorPicker';

import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { createShadow } from '../../../utils';
import { CustomText } from '../../../components/shared';
import { playlistSelector } from '../../../store/playlist/playlist.selectors';
import { ControlledModal } from '../../../types/modals';
import { setQuizImageBackground, setHasQuizImageBackground } from '../../../store/playlist/playlist.actions';
import ChooseMediaModal from '../ChooseMediaModal';
import useMedia, { formatFileTypesForWeb } from '../../../hooks/useMedia';
import uploadImage from '../../../utils/uploadImage';
import { logError } from '../../../store/appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../utils/constants/toastConfig';

const modalColors = {
  backgroundColors: ['#000000', '#26547C', '#EF476F', '#FFD166', '#06D6A0', '#FFFCF9'],
  questionColors: ['#FFFFFF', '#809BCE', '#95B8D1', '#B8E0D2', '#D6EADF', '#EAC4D5'],
};
interface IProps {
  isVisible: boolean;
  closeModal: () => void;
  handleQuestionColorChange: (activeColor: string) => void;
  handleBackgroundColorChange: (activeColor: string) => void;
  backgroundColor: string;
  questionColor: string;
}

const ColorSettingsModal: FC<IProps> = ({
  closeModal,
  isVisible,
  backgroundColor,
  questionColor,
  handleBackgroundColorChange,
  handleQuestionColorChange,
}) => {
  const { quizSettings, hasImageBackground } = useSelector(playlistSelector);
  const localePrefix = 'createQuizStack.quizSettings';
  const { t } = useTranslation();
  const radioProps = [
    { label: t(`${localePrefix}.backgroundImage`), value: 0 },
    { label: t(`${localePrefix}.backgroundColor`), value: 1 },
  ];
  const mediaModalRef = useRef<ControlledModal>(null);
  const dispatch = useDispatch();

  const [isBackgroundImage, setIsBackgroundImage] = useState(hasImageBackground);

  const changeQuestionColor = (_questionColor: string) => {
    handleQuestionColorChange(_questionColor);
  };

  const changeBackgroundColor = (_backgroundColor: string) => {
    handleBackgroundColorChange(_backgroundColor);
  };

  const handleSelectBackgroundImage = () => {
    mediaControls.launchImageLibrary();
  };

  const onRadioChange = (i) => {
    if (i === 0) {
      if (!mediaControls.mediaURI) {
        handleSelectBackgroundImage();
      }
      setIsBackgroundImage(true);
    } else {
      setIsBackgroundImage(false);
    }
  };

  const mediaControls = useMedia({
    onMediaSelectionCB: () => {
      mediaModalRef?.current?.close();
    },
    initialMediaUri: quizSettings?.background_url,
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    from: 'QuizBackground',
  });

  useEffect(() => {
    const backgroundUrl = quizSettings?.background_url;
    if (backgroundUrl && backgroundUrl !== '') {
      setIsBackgroundImage(true);
    } else {
      dispatch(setHasQuizImageBackground(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizSettings?.background_url]);

  const uploadBackgroundImage = useCallback(async () => {
    if (!mediaControls.mediaURI || mediaControls.mediaURI === quizSettings?.background_url) {
      return;
    }
    const path = 'upload_quiz_background';
    try {
      const { data } = isWeb
        ? await uploadImage({
            file: mediaControls.mediaFileForWeb,
            path,
          })
        : await uploadImage({
            uri: mediaControls.mediaURI,
            path,
            type: mediaControls?.mimeType,
          });
      dispatch(setQuizImageBackground(data));
      setIsBackgroundImage(true);
      dispatch(setHasQuizImageBackground(true));
    } catch (error) {
      dispatch(
        logError({
          event: 'ColorSettingsModal.uploadBackgroundImage: error',
          error,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  }, [
    dispatch,
    mediaControls.mediaFileForWeb,
    mediaControls.mediaURI,
    mediaControls?.mimeType,
    quizSettings?.background_url,
  ]);

  useEffect(() => {
    if (isWeb) {
      uploadBackgroundImage();
    }
  }, [mediaControls.mediaFileForWeb, uploadBackgroundImage]);

  useEffect(() => {
    if (!isWeb) {
      uploadBackgroundImage();
    }
  }, [mediaControls.mediaURI, uploadBackgroundImage]);

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={closeModal}
      backdropOpacity={0.4}
      hideModalContentWhileAnimating
      style={{ justifyContent: 'flex-end', margin: 0 }}
    >
      <S.Container>
        <S.Header>
          <S.Title>{t(`${localePrefix}.colorSettings`)}</S.Title>
        </S.Header>
        <S.PreviewContainer>
          <S.BackgroundPreview customColor={backgroundColor}>
            {isBackgroundImage && mediaControls.mediaURI && mediaControls.mediaURI !== '' && (
              <S.BackgroundImagePreview source={{ uri: mediaControls.mediaURI }} />
            )}
            <S.QuestionBackgroundPreview customColor={questionColor} />
          </S.BackgroundPreview>
        </S.PreviewContainer>
        <RadioForm>
          {radioProps.map((obj, i) => (
            <React.Fragment key={i}>
              <RadioButton labelHorizontal>
                <RadioButtonInput
                  obj={obj}
                  index={i}
                  isSelected={i === 0 ? isBackgroundImage : !isBackgroundImage}
                  onPress={onRadioChange}
                  borderWidth={1}
                  buttonInnerColor={'#353535'}
                  buttonOuterColor={'#353535'}
                  buttonSize={11}
                  buttonOuterSize={24}
                  buttonWrapStyle={{ marginLeft: 10 }}
                />
                <S.RadioButtonText>{obj.label}</S.RadioButtonText>
              </RadioButton>
              {i === 0 ? (
                <S.RowContainer>
                  <S.BackgroundImageOuter onPress={handleSelectBackgroundImage}>
                    <S.BackgroundImageInner>
                      {mediaControls.mediaURI && mediaControls.mediaURI !== '' ? (
                        <S.BackgroundImage source={{ uri: mediaControls.mediaURI }} />
                      ) : (
                        <S.PlusText>+</S.PlusText>
                      )}
                    </S.BackgroundImageInner>
                  </S.BackgroundImageOuter>
                </S.RowContainer>
              ) : (
                <>
                  <S.RowContainer>
                    <ColorPicker
                      setColor={changeBackgroundColor}
                      activeColor={backgroundColor}
                      colors={modalColors.backgroundColors}
                    />
                  </S.RowContainer>
                  <S.RowContainer>
                    <S.SettingText>{t(`${localePrefix}.questionColor`)}</S.SettingText>
                    <ColorPicker
                      setColor={changeQuestionColor}
                      activeColor={questionColor}
                      colors={modalColors.questionColors}
                    />
                  </S.RowContainer>
                </>
              )}
            </React.Fragment>
          ))}
        </RadioForm>
        <ChooseMediaModal ref={mediaModalRef} mediaControls={mediaControls} photoOnly />
      </S.Container>
      {mediaControls.dropZoneUploadingForWeb}
    </Modal>
  );
};

const Container = styled.View`
  margin-right: ${calcWidth(5)}px;
  margin-left: ${calcWidth(5)}px;
  padding-bottom: ${calcHeight(25)}px;
  border-top-right-radius: ${calcWidth(20)}px;
  border-top-left-radius: ${calcWidth(20)}px;
  ${createShadow({ elevation: 8 })};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightGray3};
`;

const RowContainer = styled.View`
  margin-left: ${calcWidth(20)}px;
  margin-right: ${calcWidth(20)}px;
  margin-bottom: ${calcHeight(25)}px;
`;

const PreviewContainer = styled.View`
  margin-left: ${calcWidth(20)}px;
  margin-right: ${calcWidth(20)}px;
  margin-bottom: ${calcHeight(15)}px;
  padding-bottom: ${calcHeight(15)}px;
  align-items: center;
  border-bottom-color: ${({ theme }) => theme.colors.lightGray1};
  border-bottom-width: 1px;
`;

const PREVIEW_HEIGHT = calcHeight(122);
const PREVIEW_WIDTH = calcWidth(70);
const PREVIEW_BORDER_RADIUS = calcWidth(8);

const BackgroundPreview = styled.View<{ customColor: any }>`
  background-color: ${({ theme, customColor }) => customColor || theme.colors.lightGray1};
  height: ${PREVIEW_HEIGHT}px;
  width: ${PREVIEW_WIDTH}px;
  align-items: center;
  padding: ${calcHeight(13)}px;
  border-radius: ${PREVIEW_BORDER_RADIUS}px;
  overflow: hidden;
`;

const BackgroundImagePreview = styled.Image`
  height: ${PREVIEW_HEIGHT}px;
  width: ${PREVIEW_WIDTH}px;
  position: absolute;
  left: 0;
  top: 0;
`;

const QuestionBackgroundPreview = styled.View<{ customColor: string }>(
  ({ theme, customColor }) => css`
    background-color: ${() => customColor || theme.colors.lightGray1};
    width: 100%;
    height: 100%;
    border-radius: ${PREVIEW_BORDER_RADIUS}px;
  `,
);

const BackgroundImageOuter = styled.TouchableWithoutFeedback``;

const BackgroundImageInner = styled.View`
  ${() => (isWeb ? 'cursor: pointer;' : '')}
  margin-top: ${calcHeight(5)}px;
  border: 1px solid gray;
  height: ${calcHeight(90)}px;
  width: ${calcHeight(90)}px;
  padding: ${calcHeight(3)}px;
  justify-content: center;
  align-items: center;
`;

const BackgroundImage = styled.Image`
  height: 100%;
  width: 100%;
`;

const PlusText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${calcFontSize(60)}px;
    color: ${theme.colors.darkGray1};
  `,
);

const Header = styled.View`
  text-align: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightblue1};
  padding-top: ${calcHeight(20)}px;
  margin-bottom: ${calcHeight(20)}px;
  padding-bottom: ${calcHeight(3)}px;
  border-bottom-color: ${({ theme }) => theme.colors.lightGray1};
  border-bottom-width: 1px;
`;

const Title = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.darkGray1};
  `,
);

const SettingText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s16}px;
    color: ${theme.colors.darkGray1};
    margin-bottom: ${calcHeight(5)}px;
  `,
);

const RadioButtonText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s16}px;
    color: ${theme.colors.darkGray1};
    margin-top: 4px;
    margin-left: 7px;
    margin-right: 7px;
  `,
);

const S = {
  Container,
  RowContainer,
  Header,
  Title,
  SettingText,
  PlusText,
  RadioButtonText,
  PreviewContainer,
  BackgroundPreview,
  QuestionBackgroundPreview,
  BackgroundImagePreview,
  BackgroundImage,
  BackgroundImageInner,
  BackgroundImageOuter,
};

export default ColorSettingsModal;
