import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components/native';
import { IStackNavigation } from '../../navigation/types';
import { BackHandler, ScrollView } from 'react-native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import EnterEmail from './common/ForgotPassword/EnterEmail';
import BackButton from '../../components/shared/BackButton';
import BitesLogoIcon from '../../assets/icons/bites-logo.svg';
import ResetInfo from './common/ForgotPassword/ResetInfo';
import ResetPassword from './common/ForgotPassword/ResetPassword';
import FormContainer from './common/FormContainer';
import { logout } from '../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { accessCodeSelector, currentFlowSelector, currentFormSelector } from '../../store/authForm/authForm.selectors';
import { EAuthFlowType, EAuthFormType } from './auth.types';
import UserInfoForm from './common/UserInfoForm';
import { KeyboardAwareView } from '../../components/shared';
import { setCurrentForm } from '../../store/authForm/authForm.slice';
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context';
import { authSelector } from '../../store/auth/auth.selectors';
import SsoAuthLoader from './common/SsoAuthLoader';
import AuthForm from './common/AuthForm';
import PhoneNumberAuth from './common/PhoneNumber/PhoneNumberAuth';
import { log, setExternalAction } from '../../store/appActivity/appActivity.slice';
import EnterEmailCode from './common/EnterCodeForm/EnterEmailCode';
import EnterPhoneCode from './common/EnterCodeForm/EnterPhoneCode';
import InitialForm from './common/InitialForm';
import LogoutFrames from './LogoutFrames';
import AccessCodeForm from './common/AccessCodeForm';

interface IProps extends IStackNavigation {}

const ScrollComponent = isWeb ? ScrollView : KeyboardAwareView;
const containerStyles = { flexGrow: 1 };

const Auth: React.FC<IProps> = ({ navigation }) => {
  const insets = useSafeAreaInsets();
  const dispatch = useDispatch();

  const accessCode = useSelector(accessCodeSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = useSelector(currentFormSelector);
  const { ssoInProgress } = useSelector(authSelector);

  const navigateTo = useCallback(
    (destination: EAuthFormType) => {
      dispatch(log({ event: 'Auth.navigateTo', data: { destination, currentForm } }));
      dispatch(setCurrentForm(destination));
    },
    [dispatch, currentForm],
  );

  const handleBackToInitialScreen = useCallback(() => {
    if (isWeb) {
      navigateTo(EAuthFormType.INITIAL);
      return;
    }
    navigation.goBack();
  }, [navigateTo, navigation]);

  const handleAccessCodeBack = useCallback(() => {
    handleBackToInitialScreen();
    dispatch(setExternalAction(null));
  }, [dispatch, handleBackToInitialScreen]);

  const handleBackButtonPress = useCallback(() => {
    dispatch(
      log({
        event: 'Auth.handleBackButtonPress',
        data: { currentForm },
      }),
    );

    switch (currentForm) {
      case EAuthFormType.ENTER_CODE_EMAIL:
      case EAuthFormType.FORGOT_PASSWORD_RESET:
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
      case EAuthFormType.PHONE_NUMBER:
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.USER_INFO:
        dispatch(logout());
        navigateTo(EAuthFormType.AUTH);
        break;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
        navigateTo(EAuthFormType.FORGOT_PASSWORD_EMAIL);
        break;
      case EAuthFormType.ENTER_CODE_PHONE:
        navigateTo(EAuthFormType.PHONE_NUMBER);
        break;
      case EAuthFormType.ACCESS_CODE:
        handleAccessCodeBack();
        break;
      default:
        if (currentFlow === EAuthFlowType.SIGN_UP && accessCode) {
          navigateTo(EAuthFormType.ACCESS_CODE);
          return;
        }
        handleBackToInitialScreen();
    }
  }, [currentForm, currentFlow, accessCode, dispatch, handleBackToInitialScreen, handleAccessCodeBack, navigateTo]);

  const renderContent = () => {
    switch (currentForm) {
      case EAuthFormType.FORGOT_PASSWORD_EMAIL:
        return <EnterEmail />;
      case EAuthFormType.FORGOT_PASSWORD_INFO:
        return <ResetInfo />;
      case EAuthFormType.PHONE_NUMBER:
        return <PhoneNumberAuth />;
      case EAuthFormType.FORGOT_PASSWORD_RESET:
        return <ResetPassword />;
      case EAuthFormType.ENTER_CODE_PHONE:
        return <EnterPhoneCode />;
      case EAuthFormType.ENTER_CODE_EMAIL:
        return <EnterEmailCode />;
      case EAuthFormType.USER_INFO:
        return <UserInfoForm />;
      case EAuthFormType.INITIAL:
        return <InitialForm />;
      case EAuthFormType.ACCESS_CODE:
        return <AccessCodeForm />;
      default:
        return <AuthForm />;
    }
  };

  useEffect(() => {
    if (isWeb) {
      return;
    }
    const callback = () => {
      handleBackButtonPress();
      return true;
    };

    BackHandler.addEventListener('hardwareBackPress', callback);

    return () => BackHandler.removeEventListener('hardwareBackPress', callback);
  }, [handleBackButtonPress]);

  return ssoInProgress ? (
    <SsoAuthLoader />
  ) : (
    <>
      {/* scrollEnabled={!isWeb} is needed to not show scroll bar with KeyboardAwareView */}
      <ScrollComponent scrollEnabled={!isWeb} contentContainerStyle={containerStyles}>
        <S.Content insets={insets}>
          <FormContainer>
            <AuthBackButton onPress={handleBackButtonPress} />
            <S.Header pointerEvents='none'>
              <BitesLogoIcon width='91' height='49' />
            </S.Header>
            {renderContent()}
          </FormContainer>
        </S.Content>
      </ScrollComponent>
      <LogoutFrames />
    </>
  );
};

const AuthBackButton = ({ onPress }) => {
  const currentForm = useSelector(currentFormSelector);

  if (isWeb && currentForm === EAuthFormType.INITIAL) {
    return <S.EmptyView />;
  }

  return (
    <S.BackButtonContainer>
      <BackButton onPress={onPress} />
    </S.BackButtonContainer>
  );
};

const S = {
  Content: styled.View<{ insets: EdgeInsets }>`
    flex: 1;
    flex-basis: auto;
    flex-shrink: 0;
    justify-content: flex-start;
  `,
  BackButtonContainer: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    margin: ${calcHeight(11)}px 0 0 ${calcWidth(14)}px;
  `,
  EmptyView: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    margin: ${calcHeight(11)}px 0 0 ${calcWidth(14)}px;
  `,
  Header: styled.View`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: ${calcHeight(-20)}px;
    pointer-events: none;
  `,
};

export default memo(Auth);
