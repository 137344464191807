import store from '../../store';
import { addUserToOrganization } from '../../store/api/bites-api/calls/auth.calls';
import { logError, trackEvent } from '../../store/appActivity/appActivity.slice';
import { getInOrganization } from '../../store/org/org.slice';

const joinOrganization = async (code) => {
  try {
    const {
      data: { organization },
    } = await addUserToOrganization(code);
    store.dispatch(getInOrganization({ organizationId: organization }));
    store.dispatch(
      trackEvent({
        event: 'join_new_organization',
      }),
    );
  } catch (error) {
    store.dispatch(
      logError({
        event: 'joinOrganization: error',
        data: {
          error,
        },
      }),
    );
  }
};
export default joinOrganization;
