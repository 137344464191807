import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import ArrangeIcon from '../../assets/icons/editFlow/arrange-edit.svg';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmIcon from '../../assets/icons/confirm.svg';
import { createPlaylistSelector } from '../../store/createPlaylist/createPlaylist.selectors';
import Routes from '../../navigation/routes';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

export enum EType {
  EDIT = 'Edit',
}

interface IProps {
  type?: EType;
  disabled?: boolean;
  edited?: boolean;
  onPress?: () => void;
}

const SectionItem: React.FC<IProps> = ({ type, disabled, onPress, edited }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const theme = useTheme();
  const { editedPlaylist } = useSelector(createPlaylistSelector);

  const SectionIcon = useCallback(
    ({ style }) => {
      switch (type) {
        case EType.EDIT:
          return <ArrangeIcon style={style} />;
      }
    },
    [type],
  );

  const handlePressSection = useCallback(() => {
    if (onPress) {
      onPress();
      return;
    }
    switch (type) {
      case EType.EDIT:
        navigation.navigate(Routes.EditPlaylistStack.StackName, {
          screen: Routes.EditPlaylistStack.OrganizePlaylist,
          params: { playlistId: editedPlaylist?.id },
        });
        dispatch(
          trackEvent({
            event: 'edit_playlist_btn',
            props: { playlist_id: editedPlaylist?.id },
          }),
        );
        return;
    }
  }, [onPress, type, navigation, dispatch, editedPlaylist?.id]);

  return (
    <S.SectionItemContainer onPress={handlePressSection} disabled={disabled}>
      <SectionIcon style={S.IconStyles} />
      <S.SectionName disabled={disabled}>{t(`editPlaylist.buttons.${type}`)}</S.SectionName>

      {!disabled && edited && (
        <S.EditedTextWrapper>
          <ConfirmIcon style={S.ConfirmIconStyles} fill={theme.colors.greenChoose} />
          <S.EditedText>{t('editMain.sections.edited')}</S.EditedText>
        </S.EditedTextWrapper>
      )}
    </S.SectionItemContainer>
  );
};

const S = {
  SectionName: styled.Text<{ disabled?: boolean }>`
    margin-top: ${calcHeight(3)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray11 : theme.colors.text)};
  `,
  ProcessingText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  AddSectionButton: styled.TouchableOpacity``,
  AddSectionButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SectionItemContainer: styled.TouchableOpacity`
    margin-left: ${calcWidth(15)}px;
    margin-right: ${calcWidth(15)}px;
    align-items: center;
    justify-content: center;
  `,
  SectionsContainer: styled.View`
    flex-direction: row;
  `,
  IconStyles: { marginBottom: calcHeight(5) },
  EditedTextWrapper: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  EditedText: styled.Text`
    margin-left: 2px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  ConfirmIconStyles: {
    width: calcWidth(12),
    height: calcHeight(12),
  },
};

export default SectionItem;
