import axios from 'axios';
import { AWS_URL, IS_PROD } from '../../../../utils/constants/env';
import { IUserOrgPreferences } from '../../../appActivity/appActivity.types';
import BitesApi from '../index';

export const BITES_UI_BUCKET = `${AWS_URL}/staticfiles/bites-ui`;

export const getConfig = () => {
  return axios.get(`${BITES_UI_BUCKET}/config.json?r=${Date.now()}`);
};

export const getContentLocales = () => {
  return axios.get(`${BITES_UI_BUCKET}/configs/content-locales.json`);
};

export const getSpeedTest = () => {
  return axios.get(`${BITES_UI_BUCKET}/speed-test.jpg?r=${Date.now()}`);
};

export const getMyPreferences = (organizationId) => {
  return BitesApi.get(`/accounts/user/preferences/?organization=${organizationId}`);
};

export const updateUserOrgPreferences = (organizationId, body: Partial<IUserOrgPreferences>) => {
  return BitesApi.patch(`/accounts/user/preferences/?organization=${organizationId}`, body);
};

export const loadTranslations = () => {
  return axios.get(
    `${BITES_UI_BUCKET}/translations/${IS_PROD ? 'prod' : 'staging'}.json${!IS_PROD ? `?r=${Date.now()}` : ''}`,
    {},
  );
};

export const getBiteIntroHelpersConfig = () => {
  return axios.get(`${BITES_UI_BUCKET}/configs/bite-intro-helpers.json?r=${Date.now()}`);
};

export const getBiteCreatedHelpersConfig = () => {
  return axios.get(`${BITES_UI_BUCKET}/configs/bite-created-helpers.json?r=${Date.now()}`);
};
export const trackEvent = (data) => {
  return BitesApi.post('/events/', data);
};
