import { IModalComponentProps } from './AppModals';
import React, { useCallback, useEffect } from 'react';
import BaseModal from '../../../screens/creationBite/common/BaseModal';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import SupportIcon from '../../../assets/icons/editFlow/edit-question.svg';
import Intercom from '../../../services/intercom';
import { useDispatch } from 'react-redux';
import { log } from '../../../store/appActivity/appActivity.slice';

const UploadMediaErrorModal: React.FC<IModalComponentProps> = ({ isVisible, onClose, onModalHide }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSupportPress = useCallback(() => {
    Intercom.showNewMessage('Hi, I experienced an error when uploading my video :(');
    onClose();
  }, [onClose]);

  useEffect(() => {
    dispatch(
      log({
        event: 'upload_media_error_modal',
      }),
    );
  }, [dispatch]);

  return (
    <S.BaseModal isVisible={isVisible} onClose={onClose} onModalHide={onModalHide}>
      <S.Title>{t('support.uploadTitle')}</S.Title>
      <S.SupportContainer>
        <SupportIcon style={S.SupportIconStyles} fill={theme.colors.redChoose} />
        <S.SupportTextContainer>
          <S.SupportText>{t('support.pleaseContact')}</S.SupportText>
          <S.SupportButton onPress={handleSupportPress}>
            <S.SupportButtonText>{t('support.bitesSupport')}</S.SupportButtonText>
          </S.SupportButton>
          <S.SupportText>{t('support.forHelp')}</S.SupportText>
        </S.SupportTextContainer>
      </S.SupportContainer>
    </S.BaseModal>
  );
};

const S = {
  BaseModal: styled(BaseModal)`
    height: 600px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s20};
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  SupportText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s20};
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  SupportButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s20};
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryBlue};
    text-decoration: underline;
  `,
  SupportContainer: styled.View`
    margin: ${calcWidth(20)}px 0 ${calcWidth(70)}px;
    align-items: center;
    justify-content: center;
    max-width: ${calcWidth(300)}px;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  SupportButton: styled.TouchableOpacity``,
  SupportTextContainer: styled.View`
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: ${calcWidth(200)}px;
  `,
  SupportIconStyles: { width: calcWidth(40), height: calcHeight(40), marginRight: calcWidth(5) },
};

export default UploadMediaErrorModal;
