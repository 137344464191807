import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';

const getIsInCreationStack = () => {
  const rootState = navigationRef.current?.getRootState();

  const level1 = rootState?.routes?.[rootState.index]?.state;
  const level2 = level1?.routes[level1?.index];

  return (
    level2?.name === Routes.CreateBiteStack.StackName ||
    level2?.name === Routes.CreatePlaylistStack.StackName ||
    level2?.name === Routes.CreateQuizStack.StackName
  );
};
export default getIsInCreationStack;
