import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { calcHeight } from '../../../utils/dimensions';
import { MainContentWrapper } from '../../createBite/sharedComponents';
import { BlueHeader, BlueHeaderTitle } from '../../../components/layout';
import { TextInput, KeyboardAwareView, ContinueButton } from '../../../components/shared';
import { IStackNavigation } from '../../../navigation/types';
import { setPlaylistSubject } from '../../../store/createPlaylist/createPlaylist.actions';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import Routes from '../../../navigation/routes';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';

const lettersLimit = 40;

interface IProps extends IStackNavigation {}
const NameYourBite: FC<IProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subject, editMode, editedPlaylist } = useSelector(createPlaylistSelector);

  const handleRename = useCallback(
    (text) => {
      dispatch(
        trackEvent({
          event: 'rename_playlist',
          props: { playlist_id: editedPlaylist?.id },
        }),
      );
      dispatch(setPlaylistSubject(text));
    },
    [dispatch, editedPlaylist?.id],
  );

  return (
    <KeyboardAwareView>
      <S.Container>
        <BlueHeader>
          <BlueHeaderTitle>{t('createPlaylistStack.nameYourPlaylist.headerTitle')}</BlueHeaderTitle>
        </BlueHeader>

        <MainContentWrapper>
          <S.InputWrapper>
            <TextInput
              onChangeText={handleRename}
              value={subject}
              maxLength={lettersLimit}
              placeholder={t('createPlaylistStack.navTitles.placeholder')}
            />
            <S.LetterCounterText>
              {subject.length} / {lettersLimit}
            </S.LetterCounterText>
          </S.InputWrapper>

          <ContinueButton
            onPress={() => {
              editMode ? navigation.goBack() : navigation.navigate(Routes.CreatePlaylistStack.AddBites);
            }}
            content={t(`common.${editMode ? 'save' : 'next'}`)}
            disabled={subject.length < 4}
          />
        </MainContentWrapper>
      </S.Container>
    </KeyboardAwareView>
  );
};

const S = {
  Container: styled.View``,
  InputWrapper: styled.View`
    transform: translateY(-35px);
    margin-bottom: ${calcHeight(175 - 35)}px;
  `,
  LetterCounterText: styled.Text`
    align-self: flex-end;
    line-height: ${calcHeight(16)}px;
    padding-top: ${calcHeight(7)}px;
    ${({ theme }) => css`
      color: ${theme.colors.lightGray8};
      font-family: ${theme.fontFamilies.GilroyMedium};
      font-size: ${theme.fontSizes.s14}px;
    `}
  `,
};

export default NameYourBite;
