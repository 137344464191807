import store from '../../store';
import { setSelectedTimelineItemId } from '../../store/videoEditor/videoEditor.slice';

export const clickTargetRef = {
  current: null,
};

document.addEventListener('click', () => {
  if (clickTargetRef.current?.isTimelineItemTarget) {
    clickTargetRef.current = null;
    return;
  }

  clickTargetRef.current = null;
  store.dispatch(setSelectedTimelineItemId(null));
});
