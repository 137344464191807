import Modal from '../../modals/ModalController';
import styled, { useTheme } from 'styled-components/native';
import React, { useMemo } from 'react';
import { calcHeight, calcWidth, deviceHeight, deviceWidth } from '../../../utils/dimensions';
import CloseIcon from '../../../assets/icons/close.svg';
import { SafeAreaView } from 'react-native';
import { imageModalCloseButtonDataSet, imageModalDataSet } from './ImageModal.constants';

interface IProps {
  isVisible: boolean;
  image: string;
  onClose: () => void;
}

const ImageModal: React.FC<IProps> = ({ isVisible, image, onClose }) => {
  const theme = useTheme();

  const source = useMemo(() => ({ uri: image }), [image]);

  return (
    <Modal
      //@ts-ignore
      dataSet={imageModalDataSet}
      isVisible={isVisible}
      statusBarTranslucent
      animationOut={'fadeOut'}
      animationIn={'fadeIn'}
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
    >
      <S.SafeAreaView>
        <S.CloseButton
          //@ts-ignore
          dataSet={imageModalCloseButtonDataSet}
          onPress={onClose}
        >
          <CloseIcon fill={theme.colors.white} />
        </S.CloseButton>
        <S.Image resizeMode='contain' source={source} />
      </S.SafeAreaView>
    </Modal>
  );
};

const S = {
  CloseButton: styled.TouchableOpacity`
    position: absolute;
    width: ${calcWidth(20)}px;
    height: ${calcWidth(20)}px;
    top: ${calcHeight(32)}px;
    right: ${calcWidth(22)}px;
    z-index: 3;
  `,
  SafeAreaView: styled(SafeAreaView)`
    align-items: center;
    justify-content: center;
    flex: 1;
  `,
  Image: styled.Image`
    width: ${deviceWidth}px;
    height: ${deviceHeight - calcHeight(100)}px;
    aspect-ratio: 1;
  `,
};

export default ImageModal;
