import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import store from '../../store';
import { setCurrentForm, setRecoveryToken } from '../../store/authForm/authForm.slice';
import { EAuthFormType } from '../../screens/auth/auth.types';
import { log } from '../../store/appActivity/appActivity.slice';

const openResetPasswordForm = (recovery_token) => {
  store.dispatch(log({ event: 'openResetPasswordForm', data: { recovery_token } }));
  store.dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_RESET));
  store.dispatch(setRecoveryToken(recovery_token));

  navigationRef.current.navigate(Routes.AuthStack.StackName, {
    screen: Routes.AuthStack.Auth,
  });
};
export default openResetPasswordForm;
