import React, { memo } from 'react';
import styled from 'styled-components/native';
import { useSelector } from 'react-redux';
import { isDisplayLogoutFramesSelector } from '../../store/auth/auth.selectors';
import WebView from '../../components/WebView';
import { DASHBOARD_URL, SITE_URL } from '../../utils/constants/urls';

const LogoutFrames: React.FC = ({}) => {
  const isDisplayLogoutFrames = useSelector(isDisplayLogoutFramesSelector);

  if (!isDisplayLogoutFrames) {
    return null;
  }

  return (
    <S.Container>
      <WebView url={`${DASHBOARD_URL}/login?action=logout`} />
      <WebView url={`${SITE_URL}/auth?action=logout`} />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    position: absolute;
    top: -100px;
    left: -100px;
    width: 1px;
    height: 1px;
  `,
};

export default memo(LogoutFrames);
