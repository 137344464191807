import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { memo, useCallback } from 'react';
import Routes from '../../../navigation/routes';
import styled from 'styled-components/native';
import { calcHeight, isWeb } from '../../../utils/dimensions';

const ReturnToFeed = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any, any>>();

  const handleReturnToFeedPress = useCallback(() => {
    if (isWeb) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Routes.HomeStack.StackName,
            params: {
              screen: Routes.HomeStack.Home,
            },
          },
        ],
      });
      return;
    }

    navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [navigation]);

  return (
    <S.Container>
      <S.Text>{t('common.somethingWentWrong')}</S.Text>
      <S.ReturnToFeedButton onPress={handleReturnToFeedPress}>
        <S.ReturnToFeedButtonText>{t('common.returnToFeed')}</S.ReturnToFeedButtonText>
      </S.ReturnToFeedButton>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
  `,
  Text: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,
  ReturnToFeedButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(10)}px;
  `,
  ReturnToFeedButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,
};

export default memo(ReturnToFeed);
