import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../../../../utils/dimensions';
import Switch from '../../../../../../shared/Switch';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { introSubtitlesLinesSelector } from '../../../../../../../store/createBite/createBite.selectors';
import { saveSubtitlesApprovedState } from '../../../../../../../store/createBite/createBites.actions';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { useIsMounted } from '../../../../../../../hooks/useIsMounted';

const VerifiedToggle = ({ isDisabled }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMountedRef = useIsMounted();

  const introSubtitlesLines = useSelector(introSubtitlesLinesSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(introSubtitlesLines?.approved);

  useEffect(() => {
    setIsVerified(introSubtitlesLines?.approved);
  }, [introSubtitlesLines?.approved]);

  const thumbOnStyle = useMemo(
    () => ({
      ...S.ThumbStyles,
      borderColor: theme.colors.primaryBlue,
    }),
    [theme.colors.primaryBlue],
  );

  const thumbOffStyle = useMemo(
    () => ({
      ...S.ThumbStyles,
      borderColor: theme.colors.lightGray9,
    }),
    [theme.colors.lightGray9],
  );

  const handleSuccessStateChange = useCallback(
    (state) => {
      if (isMountedRef.current) {
        setIsVerified(state);
        setIsLoading(false);
      }
    },
    [isMountedRef],
  );

  const handleErrorStateChange = useCallback(() => {
    if (isMountedRef.current) {
      setIsVerified(introSubtitlesLines?.approved);
      setIsLoading(false);
    }
  }, [introSubtitlesLines?.approved, isMountedRef]);

  const handleToggle = useCallback(() => {
    if (isLoading || isDisabled) {
      return;
    }

    setIsLoading(true);
    setIsVerified(!isVerified);
    dispatch(
      saveSubtitlesApprovedState({
        id: introSubtitlesLines.id,
        approved: !isVerified,
        onSuccess: handleSuccessStateChange,
        onError: handleErrorStateChange,
      }),
    );
  }, [
    isLoading,
    isDisabled,
    isVerified,
    dispatch,
    introSubtitlesLines?.id,
    handleSuccessStateChange,
    handleErrorStateChange,
  ]);

  return (
    <S.Container>
      <Switch
        // @ts-ignore
        thumbOnStyle={thumbOnStyle}
        thumbOffStyle={thumbOffStyle}
        trackOnStyle={S.TrackStyles}
        trackOffStyle={S.TrackStyles}
        scale={1}
        size={'medium'}
        isOn={isVerified}
        onToggle={handleToggle}
        disabled={isLoading || isDisabled}
        icon={
          isLoading ? (
            <S.Loader
              style={S.LoaderStyles}
              color={introSubtitlesLines?.approved ? theme.colors.primaryBlue : theme.colors.text}
            />
          ) : undefined
        }
      />
      <TouchableOpacity activeOpacity={1} onPress={handleToggle}>
        <S.Text>{t(`enhancements.${isVerified ? 'verified' : 'unverified'}`)}</S.Text>
      </TouchableOpacity>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: ${calcWidth(41)}px;
    height: ${calcHeight(22)}px;
    flex-direction: column;
    align-items: center;
    overflow: visible;
  `,
  Text: styled.Text`
    width: ${calcWidth(55)}px;
    margin-top: ${calcHeight(4)}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    font-weight: 400;
  `,
  Loader: styled(ActivityIndicator)``,
  ThumbStyles: {
    left: -4,
    width: 22,
    height: 22,
    borderRadius: 11,
    borderWidth: 1,
  },
  TrackStyles: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: 40,
    height: 17,
    padding: 9,
  },
  LoaderStyles: { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] },
};

export default memo(VerifiedToggle);
