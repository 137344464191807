import React from 'react';
import LottieView from 'lottie-react';
import styled from 'styled-components';

const IntroProcessingAnimation = () => {
  return (
    <S.LottieView animationData={require('../../../../assets/lottie/intro-processing-lottie.json')} autoPlay loop />
  );
};

const S = {
  LottieView: styled(LottieView)`
    flex: 1;
  `,
};

export default IntroProcessingAnimation;
