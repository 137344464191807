import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { calcHeight, isWeb } from '../../utils/dimensions';
import { getDrafts } from '../../services/videoEditor/videoEditor';
import BaseModal from '../creationBite/common/BaseModal';
import styled from 'styled-components/native';
import GenericButton from '../../components/shared/buttons/GenericButton';

interface IProps {
  isVisible: boolean;
  onButtonPress: (hasDrafts: boolean) => void;
  onClose: () => void;
  onModalHide: () => void;
}

const DraftModal: React.FC<IProps> = ({ isVisible, onButtonPress, onClose, onModalHide }) => {
  const { t } = useTranslation();
  const [hasDrafts, setHasDrafts] = useState(null);

  const getHasDrafts = useCallback(async () => {
    if (isWeb) {
      return null;
    }
    const drafts = await getDrafts();
    setHasDrafts(!!drafts.length);
  }, []);

  useEffect(() => {
    if (isVisible) {
      getHasDrafts();
    }
  }, [getHasDrafts, isVisible]);

  const title = hasDrafts ? t('draftModal.title') : t('draftModal.titleWithoutDraft');
  const description = hasDrafts ? t('draftModal.description') : t('draftModal.descriptionWithoutDraft');
  const buttonText = hasDrafts ? t('common.Continue') : t('draftModal.createNew');

  const handleButtonPress = useCallback(() => {
    onButtonPress(hasDrafts);
  }, [hasDrafts, onButtonPress]);

  return (
    <BaseModal
      onClose={onClose}
      isVisible={isVisible}
      title={title}
      description={description}
      onModalHide={onModalHide}
    >
      <S.Button onPress={handleButtonPress} content={buttonText} />
    </BaseModal>
  );
};

const S = {
  Button: styled(GenericButton)`
    min-width: 100%;
    align-self: center;
    margin-top: ${calcHeight(20)}px;
    margin-bottom: ${calcHeight(20)}px;
  `,
  Text: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s17};
    color: ${({ theme }) => theme.colors.white};
    line-height: ${({ theme }) => theme.fontSizes.s17};
  `,
};

export default DraftModal;
