import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { videoResolutionSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import Title from './Title.web';
import defaultTheme from '../../../themes';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import { startNewVideo } from '../../../store/videoEditor/videoEditor.slice';

const GeneralSettings = () => {
  const dispatch = useDispatch();

  const resolution = useSelector(videoResolutionSeletor);

  const [newResolution, setNewResolution] = useState(null);

  const handleClickPortrait = useCallback(() => {
    setNewResolution({ width: 720, height: 1280 });
  }, []);

  const handleClickLandscape = useCallback(() => {
    setNewResolution({ width: 1280, height: 720 });
  }, []);

  // const handleClickSquare = useCallback(() => {
  //   setNewResolution({ width: 720, height: 720 });
  //   setIsConfirmModalOpen(true);
  // }, []);

  const closeConfirmModal = useCallback(() => {
    setNewResolution(null);
  }, []);

  const handleConfirm = useCallback(() => {
    dispatch(
      startNewVideo({
        resolution: newResolution,
      }),
    );
  }, [dispatch, newResolution]);

  return (
    <>
      <Title text='Video settings' withEnabledControl={false} />

      <S.ControlTitle>Video orientation</S.ControlTitle>
      <S.ResolutionControlContainer>
        <S.Button
          isSelected={resolution.width < resolution.height}
          onClick={resolution.width < resolution.height ? undefined : handleClickPortrait}
        >
          <S.Portrait isSelected={resolution.width > resolution.height} />
          9:16
        </S.Button>
        <S.Button
          isSelected={resolution.width > resolution.height}
          onClick={resolution.width > resolution.height ? undefined : handleClickLandscape}
        >
          <S.Landscape isSelected={resolution.width < resolution.height} />
          16:9
        </S.Button>
        {/* <S.Button
          isSelected={resolution.width === resolution.height}
          onClick={resolution.width === resolution.height ?undefined: handleClickSquare}
        >
          <S.Square isSelected={resolution.width === resolution.height} />
          1:1
        </S.Button> */}
      </S.ResolutionControlContainer>
      <BlueAndRedButtonsModal
        title={'Please note'}
        description={
          'Changing the video aspect ratio will require you to start the new video from the beginning. Your current progress will not be transferable to the new video. However, your current work will be saved as a draft for your.'
        }
        isVisible={newResolution}
        onClose={closeConfirmModal}
        onRightButtonClick={handleConfirm}
        onLeftButtonClick={closeConfirmModal}
        rightButtonLabel={'Start with new ratio'}
        leftButtonLabel={'Don’t chage'}
        reverseColors
      />
    </>
  );
};
export default GeneralSettings;

const S = {
  Separator: styled.div`
    height: 1px;
    background-color: ${defaultTheme.colors.lightGray45};
    margin: 16px 0;
  `,
  Spacing: styled.div<{ size?: number }>`
    height: ${({ size = 1 }) => size * 8}px;
  `,
  ControlTitle: styled.div`
    padding: 0 8px;
    font-size: 13px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlContainer: styled.div`
    position: relative;
    padding: 0 8px;
  `,
  ResolutionControlContainer: styled.div`
    margin-top: 8px;
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 8px;
    padding: 0 8px;
    height: 38px;
  `,
  Button: styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    font-size: 12px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border-radius: 8px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  Portrait: styled.div<{ isSelected: boolean }>`
    margin-right: 8px;
    width: 12px;
    height: 22px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
  Landscape: styled.div<{ isSelected: boolean }>`
    margin-right: 8px;
    width: 22px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
  Square: styled.div<{ isSelected: boolean }>`
    margin-right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
};
