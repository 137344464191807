// import {
// 	getToken,
// 	onMessage,
// 	getMessaging,
// 	isSupported,
// } from 'firebase/messaging';
// import { FIREBASE_MESSAGING_VAPID_KEY } from '../../utils/constants/config';
// import { registerNotificationToken } from '../../store/api/bites-api/calls/auth.calls';
// import { logError } from '../log';
// import gtmTrack from '../gtm/track';

export const initializeNotifications = async () => {
  //TODO: REMOVE AND UNCOMMENT WHEN WEB PUSHES ARE NEEDED
  return true;
  //DISABLED DUE TO MYBITES-3760 TASK (https://mybites.atlassian.net/browse/MYBITES-3760)
  // try {
  // 	const messagingIsSupported = await isSupported();
  //
  // 	if (!messagingIsSupported) {
  // 		return;
  // 	}
  //
  // 	const messaging = getMessaging();
  // 	const fcmToken = await getToken(messaging, {
  // 		vapidKey: FIREBASE_MESSAGING_VAPID_KEY,
  // 	});
  //
  // 	if (fcmToken) {
  // 		await registerNotificationToken(fcmToken, 'web');
  //
  // 		onMessage(messaging, message => {
  // 			gtmTrack('push_notification_received', { is_background: false });
  // 			console.log('MESSAGE HAS BEEN RECEIVED', { message });
  // 		});
  // 		return true;
  // 	}
  // 	return false;
  // } catch (error) {
  // 	logError({error});
  // 	return false;
  // }
};
