import React, { memo } from 'react';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { skeletonDataSet } from '../../../screens/bite/bite.constants';

interface IProps {
  width: number;
  height: number;
  borderRadius?: number;
}

const Skeleton: React.FC<IProps> = ({ width, height, borderRadius }) => {
  return (
    <S.Container
      // @ts-ignore
      dataSet={skeletonDataSet}
    >
      <S.Skeleton height={height} width={width} borderRadius={borderRadius} />
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    margin: 2px;
  `,
  Skeleton: styled(LoadingSkeleton)<{ height: number; width: number; borderRadius: number }>`
    border-radius: ${({ borderRadius }) => borderRadius}px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  `,
};

export default memo(Skeleton);
