import React from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import StarsLottie from './StarsLottie/StarsLottie';
import LogoLottie from './LogoLottie/LogoLottie';
import HeaderHandle from './HeaderHandle';

interface IProps {
  title: string;
  isOpen: boolean;
}

const TellYourStoryElementsPanelHeader: React.FC<IProps> = ({ title, isOpen }) => (
  <S.CustomHeaderContainer>
    <S.HeaderHandleContainer>
      <HeaderHandle isOpen={isOpen} />
    </S.HeaderHandleContainer>
    <S.CustomHeaderTitleContainer>
      <LogoLottie />
      <S.CustomHeaderTitle>{title}</S.CustomHeaderTitle>
    </S.CustomHeaderTitleContainer>
    <S.IconsContainer>
      <StarsLottie />
    </S.IconsContainer>
  </S.CustomHeaderContainer>
);

const S = {
  CustomHeaderContainer: styled.View`
    width: 100%;
    height: ${calcHeight(85)}px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  IconsContainer: styled.View`
    width: 40%;
    height: ${calcHeight(85)}px;
    margin-right: ${calcWidth(10)}px;
    align-self: flex-end;
  `,
  CustomHeaderTitleContainer: styled.View`
    margin-left: ${calcHeight(25)}px;
    width: 50%;
    flex-direction: row;
    align-items: center;
  `,
  CustomHeaderTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    margin-left: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  HeaderHandleContainer: styled.View`
    position: absolute;
    align-items: center;
    width: 100%;
    top: ${calcHeight(6)}px;
  `,
};

export default TellYourStoryElementsPanelHeader;
