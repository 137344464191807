import React, { useEffect } from 'react';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import Header from '../../../components/Header';
import { DefaultTheme, useTheme } from 'styled-components/native';
import EditMain from '../../../screens/editBite/EditMain/EditMain';
import Routes from '../../routes';
import BiteTellYourStory from '../../../screens/creationBite/BiteTellYourStory';
import CreateSummaryNotes from '../../../screens/creationBite/CreateSummaryNotes';
import BiteEditQuestion from '../../../screens/creationBite/EditQuestion/BiteEditQuestion';
import AddQuestion from '../../../screens/Shared/EditQuestion/AddQuestion';
import EditBiteIntroEnhancements from '../../../screens/editBite/EditBiteIntroEnhancements';
import { activateKeepAwake, deactivateKeepAwake } from '@sayem314/react-native-keep-awake';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFlow } from '../../../store/appActivity/appActivity.slice';
import { EAppFlow } from '../../../store/appActivity/appActivity.types';
import { useIsFocused } from '@react-navigation/native';
import { currentFlowSelector } from '../../../store/appActivity/appActivity.selectors';
import useCurrentRoute from '../../../hooks/useCurrentRoute';
import GoogleSlides from '../../../screens/googleSlides/GoogleSlides';
import { isWeb } from '../../../utils/dimensions';
import VideoEditor from '../../../screens/videoEditor/VideoEditor';
import ExistingVideos from '../../../screens/videoEditor/ExistingVideos';
// import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
// import { biteItemSelector } from '../../../store/bite/bite.selectors';
// import useIsItemOwner from '../../../hooks/useIsItemOwner';

const Stack = createStackNavigator();

const defaultScreenOptions = { headerShown: false, animationEnabled: !isWeb };
const initialParams = { isEditMode: true };
const existingVideosOptions = {
  headerTitle: 'Video drafts',
};

const EditBiteStack = ({}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { params } = useCurrentRoute();

  // const biteId = params?.params?.biteId;
  // const bite = useSelector(biteItemSelector(biteId));
  // const isBiteOwner = useIsItemOwner(bite);
  // const isContentAdmin = useSelector(isContentAdminSelector);
  // const isUserCanEdit = isBiteOwner || isContentAdmin;
  const currentFlow = useSelector(currentFlowSelector);

  useEffect(() => {
    if (!isFocused) {
      if (currentFlow?.flow === EAppFlow.BITE_EDITION) {
        dispatch(setCurrentFlow(null));
        deactivateKeepAwake();
      }
      return;
    }

    dispatch(
      setCurrentFlow({
        flow: EAppFlow.BITE_EDITION,
        hasIntroVideo: false,
        videoUploadedToS3: false,
        biteId: params?.params?.biteId,
      }),
    );
    activateKeepAwake();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused]);

  // useEffect(() => {
  //   if (!isUserCanEdit) {
  //     navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  //   }
  // }, [isUserCanEdit, navigation]);

  // if (!isUserCanEdit) {
  //   return null;
  // }

  return (
    <Stack.Navigator screenOptions={getScreenOptions(theme)}>
      <Stack.Screen
        name={Routes.EditBiteStack.EditMain}
        component={EditMain}
        options={defaultScreenOptions}
        initialParams={initialParams}
      />
      <Stack.Screen
        name={Routes.EditBiteStack.EditEnhancements}
        component={EditBiteIntroEnhancements}
        options={defaultScreenOptions}
        initialParams={initialParams}
      />
      <Stack.Screen name={Routes.EditBiteStack.EditMedia} component={BiteTellYourStory} initialParams={initialParams} />
      <Stack.Screen
        name={Routes.EditBiteStack.EditSummaryNotes}
        component={CreateSummaryNotes}
        initialParams={initialParams}
      />
      <Stack.Screen name={Routes.EditBiteStack.AddQuestion} component={AddQuestion} initialParams={initialParams} />
      <Stack.Screen
        name={Routes.EditBiteStack.BiteEditQuestion}
        component={BiteEditQuestion}
        initialParams={initialParams}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.BiteGoogleSlides}
        component={GoogleSlides}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.ExistingVideos}
        component={ExistingVideos}
        options={existingVideosOptions}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.BiteVideoEditor}
        component={VideoEditor}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },
    header: getHeader,
    headerMode: 'screen',
    animationEnabled: !isWeb,
    ...TransitionPresets.SlideFromRightIOS,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

export default EditBiteStack;
