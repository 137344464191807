import { EShareMode } from '../../utils/constants/bite';
import { EAppAuthType } from '../../utils/constants/config';

const Types = {
  INIT_AUTH_REQUEST: 'INIT_AUTH_REQUEST',
  INIT_AUTH_SUCCESS: 'INIT_AUTH_SUCCESS',
  INIT_AUTH_FAILURE: 'INIT_AUTH_FAILURE',
  REFRESH_USER: 'REFRESH_USER',
  REFRESH_USER_SUCCESS: 'REFRESH_USER_SUCCESS',
  REFRESH_USER_FAILURE: 'REFRESH_USER_FAILURE',
  SET_REFRESH_USER_ERRORS: 'SET_REFRESH_USER_ERRORS',
  CLEAR_USER_PROFILE_ERRORS_BY_TYPE: 'CLEAR_USER_PROFILE_ERRORS_BY_TYPE',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_WITH_PHONE_REQUEST: 'LOGIN_WITH_PHONE_REQUEST',
  STOP_LOGIN_LOADING: 'STOP_LOGIN_LOADING',

  SET_CODE_SENT: 'SET_CODE_SENT',
  SET_IS_PHONE_MISSING: 'SET_IS_PHONE_MISSING',
  SET_WRONG_VERIFICATION_CODE: 'SET_WRONG_VERIFICATION_CODE',
  SET_AUTH_ERROR_CODES: 'SET_AUTH_ERROR_CODES',
  SIGNUP_WITH_PHONE_REQUEST: 'SIGNUP_WITH_PHONE_REQUEST',
  SIGNUP_WITH_EMAIL_REQUEST: 'SIGNUP_WITH_EMAIL_REQUEST',

  PASSWORD_RECOVERY_REQUEST: 'PASSWORD_RECOVERY_REQUEST',
  PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
  PASSWORD_RECOVERY_ERROR: 'PASSWORD_RECOVERY_ERROR',

  RESET_AUTH_ERRORS: 'RESET_AUTH_ERRORS',

  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  LOGOUT: 'LOGOUT',
  SET_SSO_IN_PROGRESS: 'SET_SSO_IN_PROGRESS',
  LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN: 'LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN',
  LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_SUCCESS: 'LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_SUCCESS',
  LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_ERROR: 'LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_ERROR',
  SWITCH_ACTIVE_ORGANIZATION: 'SWITCH_ACTIVE_ORGANIZATION',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  FINALIZE_USER_PROFILE: 'FINALIZE_USER_PROFILE',
  SET_PROFILE_IMAGE_URL_TO_UPLOAD: 'SET_PROFILE_IMAGE_URL_TO_UPLOAD',
  SET_LEAD_NAME: 'SET_LEAD_NAME',
  DELETE_MY_USER: 'DELETE_MY_USER',
  SET_NEW_PASSWORD_REQUEST: 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_REQUEST_LOADING: 'SET_NEW_PASSWORD_REQUEST_LOADING',
  SET_NEW_PASSWORD_REQUEST_ERROR: 'SET_NEW_PASSWORD_REQUEST_ERROR',
};

export default Types;

export interface ISignInCredentials {
  username: string;
  password: string;
  otp?: string;
  marketing_params?: IMarketingParams;
  processId?: string;
  onOtp?: (processId: string) => void;
}

export interface IValidatePayload {
  email?: string;
  code?: string;
}

export interface IRegistrationCredentials {
  email?: string;
  password1?: string;
  password2?: string;
  organizations?: string[];
  creator?: boolean;
  marketing_params?: IMarketingParams;
}

export interface IPasswordRecoveryBody {
  email: string;
  callback?: () => void;
}

export type IUser = {
  id: number;
  email: string;
  phone: string;
  username: string;
  full_name?: string;
  first_name?: string;
  last_name?: string;
  organizations: IUserOrganizations[];
  organization_attached?: boolean;
  profile_image?: string;
  marketing_params?: IMarketingParams;
} | null;

export interface IUserOrganizations {
  id: number;
  user_organization_id: number;
  is_teams_related: boolean;
  name: string;
  is_profile_complete: boolean;
  show_dashboard: boolean;
  is_owner: boolean;
  is_creator: boolean;
  is_opted_out: boolean;
  brand_icon: string;
  brand_share: string;
  brand_footer: string;
  is_default: boolean;
  default_sharing_mode: EShareMode;
  lead_name: string;
  content_admin?: boolean;
}

export type SocialAuthType = 'azuread-oauth2' | 'google-oauth2' | 'apple-oauth2' | 'apple-oauth2-convert-web';

export interface IMarketingParams {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  utm_content?: string;
  hsa_grp?: string;
  hsa_ad?: string;
  hsa_src?: string;
  hsa_tgt?: string;
  hsa_kw?: string;
  hsa_url?: string;
  hsa_cam?: string;
  referrer_url?: string;
  hutk?: string;
  ga_marketing_client_id?: string;
}

export interface IGetBitesAuthTokenPayload {
  token: string;
  backend: SocialAuthType;
  creator: boolean;
  organization?: number; //organization id if the user is not registered and registering through google
  needToAddOrgByTenant?: boolean;
  tenant_id?: string;
  email?: string;
  navigation?: any;
  app: EAppAuthType;
  marketing_params?: IMarketingParams;
}

export interface IEditProfileDataDetails {
  email: string | null;
  phone: string | null;
  first_name: string;
  last_name: string;
  profile_image: string | null;
  organizationId: number;
}

export interface IEditProfileData {
  email?: string;
  phone?: string;
  firstName: string;
  lastName: string;
  profile_image?: string;
  organizationId: number;
  onSuccess?: () => void;
}

export interface IFinalizeProfileAction {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  onSuccess?: () => void;
}
export interface IFinalizeProfileDataRequest {
  email?: string | null;
  phone?: string | null;
  first_name?: string;
  last_name?: string;
}

export interface ILoginWithPhone {
  credentials: {
    username: string;
    otp?: string;
    app?: string;
    marketing_params?: IMarketingParams;
  };
  options?: { isSignup: boolean };
  onOtp?: (processId: string) => void;
  processId?: string;
}

export interface ISignupPhoneCredentials {
  first_name?: string;
  last_name?: string;
  secondary_email?: string;
  email?: string;
  phone: string;
  organizations?: any[];
  creator: boolean;
  app?: string;
  marketing_params?: IMarketingParams;
  onOtp?: (processId: string) => void;
  processId?: string;
}

export interface ISignupEmailCredentials {
  first_name?: string;
  last_name?: string;
  email: string;
  organizations: any[];
  password: string;
  creator: boolean;
  app?: string;
  marketing_params?: IMarketingParams;
  processId?: string;
  onOtp?: (processId: string) => void;
}

export interface INewPasswordCredentials {
  token: string;
  password: string;
  callback?: () => void;
}

export type TSignupErrorCodes = '1' | '2' | '3';
