import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';

interface IProps {
  name?: string;
  isBitePreview?: boolean;
  isQuiz?: boolean;
}

const CreatorsName: React.FC<IProps> = ({ name, isBitePreview, isQuiz }) => {
  const { t } = useTranslation();
  const bite = t('common.Bite');
  const quiz = t('common.quiz');
  const playlist = t('common.playlist');
  const createdBy = t('common.createdBy');
  const createdByPlaylistQuiz = t('common.createdByPlaylistQuiz');

  return (
    <S.CreatorsNameContainer>
      {isBitePreview ? (
        <S.RowText>
          {bite} {createdBy}:
        </S.RowText>
      ) : isQuiz ? (
        <S.RowText>
          {quiz} {createdByPlaylistQuiz}:
        </S.RowText>
      ) : (
        <S.RowText>
          {playlist} {createdByPlaylistQuiz}:
        </S.RowText>
      )}
      <S.CreatorsName> {name}</S.CreatorsName>
    </S.CreatorsNameContainer>
  );
};

const S = {
  CreatorsNameContainer: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  CreatorsName: styled.Text`
    font-weight: bold;
  `,
  RowText: styled.Text``,
  Date: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  DateContainer: styled.View``,
};

export default memo(CreatorsName);
