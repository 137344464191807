import React, { memo, ReactElement } from 'react';
import nativeStyled, { useTheme } from 'styled-components/native';
import styled from 'styled-components';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import Switch from '../../../shared/Switch';
import EditIcon from '../../../../assets/icons/preview/edit.svg';
import cn from 'classnames';
import { ActivityIndicator } from 'react-native';

interface IProps {
  enabled: boolean;
  disabled: boolean;
  isOpen: boolean;
  title: string;
  description?: string;
  disabledDescription: string;
  onEdit?: () => void;
  onSwitchPress: () => void;
  renderIcon: () => ReactElement;
  onPress?: () => void;
  subtitle?: string;
  isLoading?: boolean;
}

const SettingButton: React.FC<IProps> = ({
  enabled,
  title,
  isOpen = true,
  description,
  disabledDescription,
  disabled,
  onEdit,
  onSwitchPress,
  renderIcon,
  onPress,
  subtitle,
  isLoading,
}) => {
  const theme = useTheme();
  const classNames = cn({ active: isOpen });

  return (
    <S.Container className={classNames}>
      <S.MagicButton disabled={disabled} onPress={onPress}>
        <S.IconContainer>
          {enabled && <S.MagicButtonStatus>ON</S.MagicButtonStatus>}
          {renderIcon()}
        </S.IconContainer>
        <S.MagicButtonTextWrapper>
          <S.MagicButtonText numberOfLines={1}>
            {title}
            {subtitle && <S.SelectedOptionText>({subtitle})</S.SelectedOptionText>}
          </S.MagicButtonText>
          <S.MagicButtonDescription>
            {disabled && !isLoading ? disabledDescription : description}
          </S.MagicButtonDescription>
        </S.MagicButtonTextWrapper>
        {isLoading && <ActivityIndicator color='white' />}
      </S.MagicButton>
      {!isLoading && !disabled && (
        <S.InnerButtons className={classNames}>
          {typeof onEdit === 'function' && (
            <S.EditButtonWrapper>
              <S.EditButton onPress={onEdit}>
                <EditIcon width='28' height='28' style={S.Icon} color={theme.colors.white} />
              </S.EditButton>
            </S.EditButtonWrapper>
          )}
          <Switch isOn={enabled} disabled={disabled} onToggle={onSwitchPress} />
        </S.InnerButtons>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    background-color: rgba(0, 0, 0, 0.65);
    width: ${calcWidth(50)}px;
    height: ${calcWidth(50)}px;
    border-radius: ${calcWidth(50) / 2}px;
    margin: ${calcHeight(12)}px 0;
    flex-direction: row;
    justify-content: space-between;
    transition: width 300ms;

    &.active {
      width: ${calcWidth(350)}px;
    }
  `,
  InnerButtons: styled.div`
      display: flex;
      visibility: hidden;
      opacity: 0;
      height: ${calcHeight(50)}px;
      flex-direction: row;
      align-items: center;
      align-self: flex-end;
      transition: all 300ms;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }`,
  MagicButton: nativeStyled.Pressable<{ disabled: boolean }>`
    height: ${calcWidth(50)}px;
    flex-direction: row;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  `,
  MagicButtonText: nativeStyled.Text`
    margin-left: ${calcWidth(8)}px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: bold;
    min-width: ${calcWidth(140)}px;
    align-items: center;
  `,
  SelectedOptionText: nativeStyled.Text`
    margin-left: ${calcWidth(3)}px;
    color: ${({ theme }) => theme.colors.lightGray38};
    font-size: ${({ theme }) => theme.fontSizes.s11};
  `,
  MagicButtonTextWrapper: styled.div`
    flex-direction: row;
    width: ${calcWidth(140)}px;
  `,
  MagicButtonStatus: nativeStyled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-style: italic;
    font-weight: bold;
    position: absolute;
    top: 0;
  `,
  MagicButtonDescription: nativeStyled.Text`
    position: absolute;
    bottom: ${calcHeight(5)}px;
    left: ${calcWidth(58)}px;
    color: ${({ theme }) => theme.colors.lightGray38};
    font-size: ${({ theme }) => theme.fontSizes.s11};
    width: ${calcWidth(200)}px;
  `,
  IconContainer: nativeStyled.View`
    height: ${calcWidth(50)}px;
    width: ${calcWidth(50)}px;
    border-radius: ${calcWidth(50) / 2}px;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  `,
  EditButtonWrapper: nativeStyled.View`
    width: ${calcWidth(50)}px;
    height: ${calcWidth(50)}px;
    border: 1px solid ${({ theme }) => theme.colors.gray23};
    border-top-width: 0;
    border-bottom-width: 0;
    align-items: center;
    justify-content: center;
  `,
  EditButton: nativeStyled.TouchableOpacity``,
  Icon: {
    width: calcWidth(24),
    height: calcHeight(24),
  },
};
export default memo(SettingButton);
