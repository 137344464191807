const CreationFlows = {
  QuizCreation: 'quiz_creation',
  BiteCreation: 'bite_creation',
};

const Constants = {
  CreationFlows,
};

export default Constants;

export const deleteButtonDataSet = { cy: 'delete-btn' };

export const replaceIntroDataset = { cy: 'replace-intro-btn' };

export const confirmDeleteQuestionDataset = { cy: 'confirm-delete-question' };
