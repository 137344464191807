import React, { useMemo } from 'react';
import { GestureResponderEvent, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

type Size = 'small' | 'medium';
interface Props {
  onPress: (event: GestureResponderEvent) => void;
  size?: Size;
  children: any;
  style?: any;
}

function Button1(props: Props) {
  const { size = 'medium', onPress, children, style } = props;
  const sizeFactor = useMemo(() => {
    if (size === 'small') {
      return 0.8;
    }
    return 1;
  }, [size]);
  return (
    <TouchableOpacity onPress={onPress}>
      <S.Container {...{ sizeFactor }} style={style}>
        {children}
      </S.Container>
    </TouchableOpacity>
  );
}

const MEDIUM_HEIGHT = 43;
const MEDIUM_FONT_SIZE = 17;
const MEDIUM_BORDER_RADIUS = 23;

const S = {
  Container: styled.Text<{ sizeFactor: number }>`
    ${({ sizeFactor }) => css`
      height: ${MEDIUM_HEIGHT}px;
      line-height: ${MEDIUM_HEIGHT}px;
      font-size: ${MEDIUM_FONT_SIZE}px;
      padding-right: ${MEDIUM_HEIGHT}px;
      padding-left: ${MEDIUM_HEIGHT}px;
      border-radius: ${MEDIUM_BORDER_RADIUS}px;
      transform: ${`scale(${sizeFactor})`};
    `}
    text-align: center;
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.primaryBlue};
    color: #fff;
    overflow: hidden;
  `,
};

export default Button1;
