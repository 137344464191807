import React, { useCallback } from 'react';
import { Share, I18nManager, StyleProp, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native-animatable';
import styled from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { userSelector, activeOrganizationSelector } from '../../store/auth/auth.selectors';
import {
  isOrgCodeLoadingSelector,
  joinDeeplinkSelector,
  organizationCodeErrorSelector,
  organizationCodeSelector,
} from '../../store/org/org.selectors';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';

import Header from '../../components/Header';
import InviteCodeClipboard from './common/InviteCodeClipboard';
import { IPanelChildInjectedProps } from '../../store/bottomPanel/bottomPanel';
import { DRAWER_FORM_WIDTH } from '../../utils/constants/drawer';
import { fetchOrgCode } from '../../store/org/org.slice';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import SsoAuthLoader from '../auth/common/SsoAuthLoader';
import { EFeatureFlag } from '../../store/appActivity/appActivity.types';

interface IProps extends IPanelChildInjectedProps {}

const dataSet = { 'intercom-target': 'invite-btn' };

const UserInvitation: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeOrganizationSelector);
  const organizationCode = useSelector(organizationCodeSelector);
  const organizationCodeError = useSelector(organizationCodeErrorSelector);
  const joinDeeplink = useSelector(joinDeeplinkSelector);
  const code = organizationCode && organizationCode[0]?.code;
  const user = useSelector(userSelector);
  const isOrgCodeLoading = useSelector(isOrgCodeLoadingSelector);
  const dispatch = useDispatch();
  const isJoinDeeplinkEnabled = useFeatureFlag(EFeatureFlag.joinDeeplink);

  const message = t('common.accessCodeShareMessage', {
    username: `${user?.first_name} ${user?.last_name}`,
    workspace: activeOrganization.name,
    code,
  });

  const linkMessage = t('userInvitation.linkShareMessage', {
    username: `${user?.first_name} ${user?.last_name}`,
    workspace: activeOrganization.name,
    link: joinDeeplink,
  });

  const onCodeShareNative = useCallback(async () => {
    await Share.share(
      {
        message,
      },
      {
        // Android only :
        dialogTitle: 'org code',
        // iOS only:
        excludedActivityTypes: [],
      },
    );
  }, [message]);

  const onCodeShareWeb = useCallback(() => {
    const toastText = t('drawerNavigator.inviteMessageCopied');
    toast(toastText);
    copy(message);
  }, [message, t]);

  const onCodeShare = useCallback(() => {
    isWeb ? onCodeShareWeb() : onCodeShareNative();
  }, [onCodeShareNative, onCodeShareWeb]);

  const onLinkShareNative = useCallback(async () => {
    await Share.share(
      {
        message: linkMessage,
      },
      {
        // Android only :
        dialogTitle: 'org code',
        // iOS only:
        excludedActivityTypes: [],
      },
    );
  }, [linkMessage]);

  const onLinkShareWeb = useCallback(() => {
    const toastText = t('drawerNavigator.inviteMessageCopied');
    toast(toastText);
    copy(linkMessage);
  }, [linkMessage, t]);

  const onLinkShare = useCallback(() => {
    isWeb ? onLinkShareWeb() : onLinkShareNative();
  }, [onLinkShareNative, onLinkShareWeb]);

  const onErrorRefresh = useCallback(() => {
    dispatch(fetchOrgCode(activeOrganization.id));
  }, [activeOrganization.id, dispatch]);

  return (
    <S.Container>
      <S.TextContainer>
        <S.Title>{t('userInvitation.invite')}</S.Title>
        <S.Description>{t('userInvitation.description', { workspace: activeOrganization.name })}</S.Description>
      </S.TextContainer>

      {isOrgCodeLoading ? (
        <SsoAuthLoader />
      ) : organizationCodeError ? (
        <S.ErrorContainer>
          <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
          <S.RefreshButton onPress={onErrorRefresh}>
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      ) : (
        <>
          {isJoinDeeplinkEnabled && joinDeeplink && (
            <S.FormContainer>
              <S.Subtitle>{t('userInvitation.linkLabel')}</S.Subtitle>
              <S.Description>{t('userInvitation.invitationLinkDescription')}</S.Description>

              <S.CodeClipboard code={joinDeeplink} toastText={t('userInvitation.inviteLinkCopied')} />
              <S.Button
                onPress={onLinkShare}
                // @ts-ignore
                dataSet={dataSet}
              >
                <S.ButtonText>{t('userInvitation.copyLink')}</S.ButtonText>
              </S.Button>
            </S.FormContainer>
          )}
          <S.FormContainer>
            <S.Subtitle>{t('userInvitation.label')}</S.Subtitle>
            <S.Description>{t('userInvitation.invitationCodeDescription')}</S.Description>

            <S.CodeClipboard code={code} toastText={t('drawerNavigator.inviteCodeCopied')} />
            <S.Button
              onPress={onCodeShare}
              // @ts-ignore
              dataSet={dataSet}
              inverted={true}
            >
              <S.ButtonText inverted={true}>{t('userInvitation.copyCode')}</S.ButtonText>
            </S.Button>
          </S.FormContainer>
        </>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    height: 100%;
    align-items: center;
  `,
  FormContainer: styled.View`
    width: ${DRAWER_FORM_WIDTH}px;
    margin: ${calcHeight(10)}px ${calcWidth(24)}px 0 ${calcWidth(24)}px;
  `,
  CodeClipboard: styled(InviteCodeClipboard)`
    width: ${DRAWER_FORM_WIDTH}px;
    height: ${calcHeight(50)}px;
    border-radius: ${calcHeight(50) / 2}px;
    justify-content: space-between;
  `,
  TextContainer: styled.View`
    align-items: flex-start;
    width: ${DRAWER_FORM_WIDTH}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.darkGray1};
    margin-bottom: ${calcHeight(8)}px;
  `,
  Description: styled(Text)`
    color: ${({ theme }) => theme.colors.darkGray12};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    line-height: ${({ theme }) => theme.fontSizes.s15 * 1.4}px;
    text-align: left;
    margin-bottom: ${calcHeight(7)}px;
  `,
  Subtitle: styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.darkGray1};
    margin: ${calcHeight(25)}px 0 ${calcHeight(10)}px;
  `,
  Button: styled.TouchableOpacity<{ inverted?: boolean }>`
    flex-direction: row;
    width: ${DRAWER_FORM_WIDTH}px;
    height: ${calcHeight(50)}px;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: ${calcHeight(24)}px;
    border-radius: ${calcWidth(32)}px;
    background-color: ${({ theme, inverted }) => (inverted ? theme.colors.white : theme.colors.blueInputBg)};
    border: ${({ theme, inverted }) => (inverted ? theme.colors.blueInputBg : theme.colors.white)};
  `,
  ButtonText: styled.Text<{ inverted?: boolean }>`
    color: ${({ theme, inverted }) => (inverted ? theme.colors.blueInputBg : theme.colors.white)};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: 700;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  `,

  Header: styled(Header)`
    background: transparent;
  `,
  InviteHeaderStyles: {
    position: 'absolute',
    top: calcHeight(-30),
    right: calcWidth(-15),
    transform: I18nManager.isRTL
      ? [
          {
            scaleX: -1,
          },
        ]
      : undefined,
  } as StyleProp<ViewStyle>,

  ErrorText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
  `,
  ErrorContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: ${calcHeight(20)}px 0;
  `,
  RefreshButton: styled.TouchableOpacity`
    margin-top: 10px;
  `,
  RefreshText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default UserInvitation;
