import axios from 'axios';
import BitesApi from '..';
import {
  TGetUserQuestionnaires,
  TCreateUserQuestionnaire,
  TUpdateUserQuestionnaire,
  TGetQuestionnaire,
} from '../../../questionnaire/questionnaire.types';
import { BITES_UI_BUCKET } from './common.calls';

export const getOnboardingQuestionnaire: TGetQuestionnaire = () =>
  axios.get(`${BITES_UI_BUCKET}/questionnaires/onboarding.json`);

export const getUserQuestionnaires: TGetUserQuestionnaires = () => BitesApi.get('/user_questionnaire/');

export const createUserQuestionnaire: TCreateUserQuestionnaire = (payload) =>
  BitesApi.post('/user_questionnaire/', payload);

export const updateUserQuestionnaire: TUpdateUserQuestionnaire = (id, payload) =>
  BitesApi.patch('/user_questionnaire/', payload);
// BitesApi.patch(`/user_questionnaire/${id}/`, payload);
