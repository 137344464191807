import React from 'react';
import styled, { css } from 'styled-components/native';
import Modal from '../ModalController';
import { View } from 'react-native';
import GenericButton from '../../shared/buttons/GenericButton';
import { colors } from '../../../themes/defaultTheme';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';

interface IButtonProps {
  content: string;
  onPress: () => void;
  disabled?: boolean;
}

interface IProps {
  isVisible: boolean;
  header?: string;
  confirmButton: IButtonProps;
  cancelButton?: IButtonProps;
  style?: any;
  children: React.ReactNode;
}

const GenericModal: React.FC<IProps> = (props) => {
  const { isVisible, header, children, cancelButton, confirmButton, style } = props;

  return (
    <Modal isVisible={isVisible} backdropOpacity={0.4} style={style}>
      <S.Container>
        <View>
          {header && (
            <S.ModalHeader>
              <S.HeaderTitle>{header}</S.HeaderTitle>
            </S.ModalHeader>
          )}

          {children}
        </View>
        <S.ButtonsContanier hasCancelButton={Boolean(cancelButton)}>
          {cancelButton && (
            <GenericButton
              content={cancelButton.content}
              backgroundColor={'transparent'}
              borderColor={'transparent'}
              fontSize={17}
              width={140}
              color={colors.primaryBlue}
              onPress={cancelButton.onPress}
              disabled={cancelButton.disabled}
            />
          )}
          <GenericButton
            content={confirmButton.content}
            width={140}
            onPress={confirmButton.onPress}
            disabled={confirmButton.disabled}
          />
        </S.ButtonsContanier>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    ${({ theme }) => css`
      background-color: ${theme.colors.lightGray13};
    `}
    ${isWeb
      ? css`
          padding-bottom: 0;
          align-self: center;
          max-width: ${calcWidth(400)}px;
          padding-top: ${calcHeight(15)}px;
        `
      : null}
  `,
  ModalHeader: styled.View`
    width: 100%;
    height: ${calcHeight(70)}px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    justify-content: space-around;
    align-items: center;
  `,
  HeaderTitle: styled.Text(
    ({ theme }) => css`
      font-size: ${calcFontSize(21)}px;
      font-weight: bold;
      color: ${theme.colors.white};
    `,
  ),
  ButtonsContanier: styled.View<{ hasCancelButton: boolean }>`
    display: flex;
    border-top-width: 1px;
    border-color: ${({ theme }) => theme.colors.lightGray5};
    flex-direction: row;
    justify-content: ${({ hasCancelButton }) => (hasCancelButton ? 'space-between' : 'center')};
    padding: ${({ hasCancelButton }) => (hasCancelButton ? '30px 30px 30px 5px' : '30px 5px 30px 5px')};
  `,
};

export default GenericModal;
