import BitesApi from '../index';

interface ICopyBiteResponse {
  message: string;
  original_id: number;
  clone_id: number;
}

interface ICopyBiteProps {
  bite: number;
  cloned_bite_name?: string;
  organization: number;
  owner: number;
}

export const copyBite = (body: ICopyBiteProps) => BitesApi.post<ICopyBiteResponse>('/clone_bite/', body);
