import React, { FC, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components/native';

import { CustomText, KeyboardAwareView } from '../../../components/shared';
import { calcHeight, deviceWidth } from '../../../utils/dimensions';
import { IStackNavigation } from '../../../navigation/types';
import { MainContentWrapper } from '../../createBite/sharedComponents';
import { useDispatch, useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import {
  resetCreatePlaylistState,
  resetPlaylistAsQuiz,
  setPlaylistSubject,
} from '../../../store/createPlaylist/createPlaylist.actions';
import { useTranslation } from 'react-i18next';
import Routes from '../../../navigation/routes';
import TextInputBlueFrame from '../../../components/shared/inputs/TextInput/TextInputBlueFrame';
import { loadBiteHelperConfigs, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useIsFocused } from '@react-navigation/native';
import { cleanEditAndCreateBiteState } from '../../../store/bite/bite.actions';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { nameYourQuizContinuebuttonDataSet } from './NameYourQuiz.constants';

const lettersLimit = 40;
interface IProps extends IStackNavigation {}

const containerStyle = { flex: 1 };
const NameYourQuiz: FC<IProps> = ({ route, navigation }) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const editMode = route?.params?.isEditMode;
  const { subject } = useSelector(createPlaylistSelector);

  const renderHeaderTitle = useCallback(
    () => <S.HeaderTitle>{t('createQuizStack.nameYourQuiz.headerTitle')}</S.HeaderTitle>,
    [t],
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }
    navigation.setOptions({
      headerTitle: renderHeaderTitle,
    });
  }, [isFocused, navigation, renderHeaderTitle]);

  useEffect(() => {
    // when starting new quiz creation
    if (!editMode) {
      dispatch(resetCreatePlaylistState());
      dispatch(resetPlaylistAsQuiz());
    }
  }, [dispatch, editMode]);

  const handleChangeName = useCallback(
    (text) => {
      dispatch(setPlaylistSubject(text));
    },
    [dispatch],
  );

  const handleContinue = useCallback(() => {
    if (editMode) {
      navigation.replace(Routes.EditQuizStack.StackName, {
        screen: Routes.EditQuizStack.EditQuiz,
      });
      return;
    }

    dispatch(
      trackEvent({
        event: 'save_quiz_name',
      }),
    );

    dispatch(cleanEditAndCreateBiteState());
    navigation.navigate(Routes.CreateQuizStack.QuizTellYourStory);
  }, [dispatch, editMode, navigation]);

  useEffect(() => {
    dispatch(loadBiteHelperConfigs());
  }, [dispatch]);

  return (
    <KeyboardAwareView contentContainerStyle={containerStyle}>
      <S.Container>
        <S.Title>{t('createQuizStack.nameYourQuiz.titleText')}</S.Title>
        <MainContentWrapper>
          <S.InputWrapper>
            <TextInputBlueFrame
              onChangeText={handleChangeName}
              value={subject}
              maxLength={lettersLimit}
              placeholder={t('createQuizStack.navTitles.placeholder')}
            />
            <S.LetterCounterText>
              {subject.length} / {lettersLimit}
            </S.LetterCounterText>
          </S.InputWrapper>

          <S.Continue
            onPress={handleContinue}
            disabled={subject.length < 4}
            content={t(`common.${editMode ? 'done' : 'Continue'}`)}
            dataSet={nameYourQuizContinuebuttonDataSet}
          />
        </MainContentWrapper>
      </S.Container>
    </KeyboardAwareView>
  );
};

const S = {
  Container: styled.View`
    top: ${calcHeight(70)}px;
    flex: 1;
  `,
  Title: styled.Text`
    align-self: center;
    max-width: ${deviceWidth - calcHeight(40)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s26}px;
    text-align: center;
    padding-bottom: ${calcHeight(80)}px;
  `,
  InputWrapper: styled.View`
    margin-bottom: ${calcHeight(170)}px;
  `,
  Continue: styled(GenericButton)``,
  LetterCounterText: styled.Text`
    align-self: flex-end;
    line-height: ${calcHeight(16)}px;
    padding-top: ${calcHeight(7)}px;
    ${({ theme }) => css`
      color: ${theme.colors.lightGray8};
      font-family: ${theme.fontFamilies.GilroyMedium};
      font-size: ${theme.fontSizes.s14}px;
    `}
  `,
  HeaderTitle: styled(CustomText)`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    color: ${({ theme }) => theme.colors.black};
  `,
};

export default NameYourQuiz;
