import React, { FC, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { CustomText } from '../../../../components/shared';
import { IBiteShare } from '../../../../types/biteShare';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';

interface IProps {
  biteShare: IBiteShare;
  onPress?: (bite: IBiteShare) => void;
  index?: any;
}

const ExploreBiteItem: FC<IProps> = ({ biteShare, onPress, index }) => {
  const image = useMemo(() => ({ uri: biteShare.cover_url || undefined }), [biteShare.cover_url]);

  const dispatch = useDispatch();

  const handlePress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'view-inspiration-bite',
      }),
    );

    if (typeof onPress !== 'function') {
      return;
    }
    onPress(biteShare);
  }, [biteShare, dispatch, onPress]);

  const dataSet = useMemo(() => {
    return { 'intercom-target': `bite-${index}` };
  }, [index]);

  return (
    <S.Container
      onPress={handlePress}
      // @ts-ignore
      dataSet={dataSet}
    >
      <S.BiteImage source={image} />
      <S.BiteTitle>{biteShare.subject}</S.BiteTitle>
    </S.Container>
  );
};

const Container = styled.TouchableOpacity`
  width: ${isWeb ? calcWidth(170) : calcWidth(110)}px;
  margin-left: ${isWeb ? calcWidth(10) : calcWidth(7)}px;
  margin-bottom: ${calcHeight(10)}px;
`;

const BiteImage = styled.ImageBackground`
  width: ${isWeb ? calcWidth(169) : calcWidth(109)}px;
  height: ${isWeb ? calcWidth(239) : calcWidth(154)}px;
  ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  border-radius: 12.2px;
  overflow: hidden;
`;

const BiteTitle = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.ArimoBold};
    font-size: ${isWeb ? theme.fontSizes.s16 : theme.fontSizes.s11}px;
    margin-top: ${calcHeight(2)}px;
    font-weight: bold;
  `,
);

const S = {
  Container,
  BiteImage,
  BiteTitle,
};

export default React.memo(ExploreBiteItem);
