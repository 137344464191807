import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import Modal from '../../../../components/modals/ModalController';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import CircleButton, { EButtonIcon } from '../../../../components/shared/buttons/CircleButton';
import { css } from 'styled-components';
import { StyleProp, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/shared/buttons/Button/Button';
import { useTheme } from 'styled-components/native';
import { chooseCoverDataSet } from './mediaModal.constants';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onChoose: () => void;
  source: string;
}

const buttonStyle = { width: calcWidth(325), height: calcHeight(44) };

const PreviewModal: React.FC<IProps> = ({ isVisible, onClose, onChoose, source }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const imageSource = useMemo(() => {
    return { uri: source };
  }, [source]);
  return (
    <>
      <S.Modal isVisible={isVisible} statusBarTranslucent animationOut={'fadeOut'} animationIn={'fadeIn'}>
        <CircleButton style={S.CloseBtnStyle} onPress={onClose} icon={EButtonIcon.CLOSE} />
        <S.Wrapper>
          <S.Container>
            <S.Image source={imageSource} />
          </S.Container>
          <Button
            onPress={onChoose}
            text={t('common.previewCover')}
            fill={theme.colors.primaryBlue}
            style={buttonStyle}
            isBoldText={true}
            textColor={theme.colors.white}
            //@ts-ignore
            dataSet={chooseCoverDataSet}
          />
        </S.Wrapper>
      </S.Modal>
    </>
  );
};

const S = {
  Modal: styled(Modal)`
    justify-content: flex-end;
    ${() => css`
      margin-right: ${calcWidth(30)}px;
      margin-left: ${calcWidth(30)}px;
    `}
  `,
  Wrapper: styled.View`
    margin-bottom: ${calcHeight(60)}px;
  `,
  Container: styled.View`
    align-self: center;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    margin-bottom: ${isWeb ? calcHeight(50) : calcHeight(77)}px;
  `,
  CloseBtnStyle: {
    position: 'absolute',
    top: isWeb ? calcHeight(20) : calcHeight(41),
    right: isWeb ? calcWidth(300) : 0,
  } as StyleProp<ViewStyle>,
  Image: styled.Image`
    width: ${calcWidth(330)}px;
    height: ${calcHeight(482)}px;
  `,
};

export default PreviewModal;
