import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components/native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import FormTextInput from '../../../../components/shared/inputs/FormTextInput/FormTextInput';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import Header from '../Header';
import { useDispatch, useSelector } from 'react-redux';
import { passwordRecovery, resetAuthErrors } from '../../../../store/auth/auth.actions';
import { emailFormSelector } from '../../../../store/authForm/authForm.selectors';
import { setCurrentForm, setEmail } from '../../../../store/authForm/authForm.slice';
import { useTranslation } from 'react-i18next';
import { EAuthFormType } from '../../auth.types';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { getIsEmailValid } from '../../../../utils/auth';
import { isForgotEmailInProgressSelector } from '../../../../store/auth/auth.selectors';

interface IProps {}

const EnterEmail: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const email = useSelector(emailFormSelector);
  const isLoading = useSelector(isForgotEmailInProgressSelector);
  const isEmailValid = getIsEmailValid(email);

  const [emailError, setEmailError] = useState(null);

  const handleChangeEmail = useCallback(
    (text) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleSuccess = useCallback(() => {
    dispatch(
      log({
        event: 'EnterEmail.navigateTo',
        data: { destination: EAuthFormType.FORGOT_PASSWORD_INFO },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_INFO));
  }, [dispatch]);

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (!isEmailValid) {
      setEmailError(t('authForm.emailIsNotValid'));
      return;
    }

    dispatch(
      passwordRecovery({
        email,
        callback: handleSuccess,
      }),
    );
  }, [dispatch, email, handleSuccess, isEmailValid, isLoading, t]);

  return (
    <S.Content entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <Header title={t('authForm.forgotPassword')} description={t('authForm.forgotPasswordForm.typeEmail')} />
      <S.Form>
        <S.TextInput
          value={email}
          onChangeText={handleChangeEmail}
          label={t('authForm.forgotPasswordForm.emailInputLabel')}
          keyboardType={'email-address'}
          autoCompleteType={'email'}
          onEnterPress={handleContinue}
          error={emailError}
        />
        <S.ContinueButton isLoading={isLoading} onPress={handleContinue} content={t('common.Continue')} />
      </S.Form>
    </S.Content>
  );
};

const S = {
  Content: styled(Animated.View)``,
  Form: styled.View`
    margin-top: ${calcHeight(32)}px;
    align-items: center;
  `,
  TextInput: styled(FormTextInput)`
    height: ${calcHeight(50)}px;
    margin-bottom: ${calcHeight(18)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
    margin-bottom: ${calcHeight(50)}px;
  `,
};

export default memo(EnterEmail);
