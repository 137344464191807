import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import Modal from '../../../components/modals/ModalController';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import PointsIcon from '../../../assets/icons/PlaylistCreation/buttons-rounded-icon.svg';
import Switch from '../../../components/shared/Switch';
import { TouchableOpacity } from 'react-native';
import { IS_IOS } from '../../../utils/constants/env';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';

interface IProps {
  isPublicDefault: boolean;
  isVisible: boolean;
  onClose: () => void;
  onApplyToggleIsPublic: (isPublic: boolean) => void;
  renderLeftButton: () => React.ReactNode;
}

const PlaylistSettingsModal: React.FC<IProps> = ({
  isPublicDefault,
  isVisible,
  onClose,
  renderLeftButton,
  onApplyToggleIsPublic,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPublic, setIsPublic] = useState(isPublicDefault);
  const { editedPlaylist } = useSelector(createPlaylistSelector);

  const handleDonePress = useCallback(() => {
    if (isPublic === isPublicDefault) {
      onClose();
      return;
    }

    dispatch(
      trackEvent({
        event: 'playlist_change_toggle',
        props: {
          playlist_id: editedPlaylist?.id,
          toggle_type: 'is_public',
          is_on: isPublic,
        },
      }),
    );
    onApplyToggleIsPublic(isPublic);
  }, [dispatch, editedPlaylist?.id, isPublic, isPublicDefault, onApplyToggleIsPublic, onClose]);

  const handlePublicPress = useCallback(() => {
    setIsPublic((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setIsPublic(isPublicDefault);
  }, [isPublicDefault, onClose]);

  useEffect(() => {
    setIsPublic(isPublicDefault);
  }, [isPublicDefault]);

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={handleClose}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      backdropOpacity={0.4}
      hideModalContentWhileAnimating
      useNativeDriver
    >
      <S.Container pointerEvents={'box-none'}>
        <S.PointsButton onPress={handleClose}>
          <PointsIcon />
        </S.PointsButton>
        <S.Content>
          <S.Triangle />
          <S.SettingsContainer>
            <S.SwitchContainer>
              <S.SwitchLabel>{t('playlistCreated.settings.isPublic')}</S.SwitchLabel>
              <Switch isOn={isPublic} onToggle={handlePublicPress} />
            </S.SwitchContainer>
            <S.ToggleInfo>{t('playlistCreated.settings.isPublicInfo')}</S.ToggleInfo>
          </S.SettingsContainer>
          <S.ButtonContainer>
            {renderLeftButton ? renderLeftButton() : <S.EmptyView />}
            <TouchableOpacity onPress={handleDonePress}>
              <S.ButtonText>{t('playlistCreated.settings.done')}</S.ButtonText>
            </TouchableOpacity>
          </S.ButtonContainer>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

const S = {
  EmptyView: styled.View``,
  ButtonContainer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
  `,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.SFProRegular};
  `,
  SettingsContainer: styled.View``,
  SwitchLabel: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.SFProRegular};
  `,
  SwitchContainer: styled.View`
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,
  Triangle: styled.View`
    position: absolute;
    width: 0;
    height: 0;
    top: ${calcHeight(-10)}px;
    right: ${calcWidth(18)}px;
    background-color: transparent;
    border-style: solid;
    border-left-width: ${calcWidth(9)}px;
    border-right-width: ${calcWidth(9)}px;
    border-bottom-width: ${calcHeight(12)}px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  `,
  PointsButton: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(41 / 2)}px;
    height: ${calcWidth(41)}px;
    width: ${calcWidth(41)}px;
    right: ${calcWidth(16)}px;
    top: ${calcHeight(isWeb ? -15 : -20)}px;
    z-index: 2;
  `,
  Content: styled.View`
    justify-content: space-between;
    padding: ${calcHeight(24)}px 0 ${calcHeight(24)}px ${calcWidth(15)}px;
    position: relative;
    width: ${calcWidth(355)}px;
    height: ${calcHeight(215)}px;
    margin: 0 ${calcWidth(10)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  `,
  Container: styled.View`
    align-items: flex-end;
    flex: 1;
    padding-top: ${calcHeight(IS_IOS ? 68 : 30)}px;
  `,
  ToggleInfo: styled.Text`
    padding-right: ${calcWidth(15)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.gray18};
    font-family: ${({ theme }) => theme.fontFamilies.SFProRegular};
    line-height: ${calcHeight(20)}px;
  `,
};

export default PlaylistSettingsModal;
