import { ISummaryCard, FontType } from '../types/biteSummary';
import { DefaultTheme } from 'styled-components/native';
import { generateFrontendId } from './frontendId';

export interface ICardColorPalette {
  labelColor: string;
  cardColor: string;
}

export const defaultCardPalette: ICardColorPalette = {
  labelColor: '#a340f2',
  cardColor: '#e0c5f6',
};

export const summaryCardColors: ICardColorPalette[] = [
  { labelColor: '#e9de1d', cardColor: '#fbf7bf' },
  { labelColor: '#e99c1d', cardColor: '#ffe4cb' },
  { labelColor: '#f76868', cardColor: '#ffc5c5' },
  { labelColor: '#ff3ba0', cardColor: '#ffd5eb' },
  { labelColor: '#1d9ce9', cardColor: '#cceafc' },
  { labelColor: '#1de9c3', cardColor: '#c8fef4' },
  { labelColor: '#9ce91d', cardColor: '#e0f5bc' },
  { ...defaultCardPalette },
];

export const fontTypes = ['classic', 'typewriter', 'handwriting'];

export const getRndCardColor = (excludedColor?: string): string => {
  const colorPalette = excludedColor
    ? summaryCardColors.filter((palette) => palette.cardColor !== excludedColor)
    : summaryCardColors;

  const randomInt = Math.floor(Math.random() * colorPalette.length);
  return colorPalette[randomInt].cardColor;
};

export const generateSummaryCard = (
  isDefault: boolean = false,
  excludedColor?: string,
  choosenColor?: ICardColorPalette,
  text?: string,
): ISummaryCard => {
  const newColor = isDefault
    ? defaultCardPalette.cardColor
    : choosenColor
    ? choosenColor.cardColor
    : getRndCardColor(excludedColor);

  return {
    id: generateFrontendId(),
    text: text || '',
    font: 'typewriter',
    align: 'center',
    size: 'normal',
    color: newColor,
    background: null,
    order: null,
  };
};

export const filterIfCardIsEmpty = (card: ISummaryCard) => card.text?.trim();

export const isSummaryCardListIsValid = (cards: ISummaryCard[]) => {
  return cards.filter(filterIfCardIsEmpty).length > 0;
};

export const getFontFamily = (theme: DefaultTheme, font: FontType) => {
  const fontFamilyMapper = {
    classic: theme.fontFamilies.FrankRuhlLibreRegular,
    typewriter: theme.fontFamilies.FredokaRegular,
    handwriting: theme.fontFamilies.AmaticSCRegular,
  };

  return fontFamilyMapper[font as FontType];
};
