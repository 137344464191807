import React, { useCallback, useMemo, useRef } from 'react';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import AddImageIcon from '../../../../assets/icons/creationFlow/add-image-icon.svg';
import styled, { css, useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { biteQuestionSelector } from '../../../../store/biteQuestion/biteQuestion.selectors';
import { StyleProp, TextStyle, TouchableWithoutFeedback } from 'react-native';
import { useSelector } from 'react-redux';
import MediaImage from '../../../../components/shared/MediaImage/MediaImage';
import { EQuestionType } from '../../../../types/biteQuestion';
import { IS_ANDROID } from '../../../../utils/constants/env';
import i18next from 'i18next';
import { isRtlByLang } from '../../../../locale/i18n';
import { questionInputValueDataSet } from '../QuestionSticker/QuestionSticker.constants';

interface IProps {
  color?: string;
  onAddImage: () => void;
  onDeleteImage: () => void;
  isImageLoading: boolean;
  mediaURI: string;
  height?: number;
  text: string;
  onChangeText: (value: string) => void;
}

const QuestionSticker: React.FC<IProps> = ({
  color,
  onAddImage,
  onDeleteImage,
  isImageLoading,
  mediaURI,
  height = 288,
  text,
  onChangeText,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const questionInputRef = useRef(null);

  const { type } = useSelector(biteQuestionSelector);

  const placeHolderByType = useMemo(() => {
    switch (type) {
      case EQuestionType.OPEN_ENDED:
        return t('createBiteStack.typeYourQuestionScreen.openQplaceholder');
      case EQuestionType.SURVEY:
        return t('createBiteStack.typeYourQuestionScreen.surveyplaceholder');
      case EQuestionType.MULTI_CHOICE:
        return t('createBiteStack.typeYourQuestionScreen.multipleQplaceholder');
    }
  }, [t, type]);

  const questionInputStyle: StyleProp<TextStyle> = useMemo(
    () => ({
      textAlignVertical: 'top',
    }),
    [],
  );

  const stickerContainerStyle = useMemo(() => ({ minHeight: height }), [height]);

  const handleContainerPress = useCallback(() => questionInputRef.current?.focus(), []);

  const placeholderInsets = useMemo(() => {
    if (isWeb) {
      return {
        top: calcHeight(24),
        left: calcWidth(20),
      };
    }

    if (IS_ANDROID) {
      return {
        top: calcHeight(34),
        left: calcWidth(24),
      };
    }

    return {
      top: calcHeight(28),
      left: calcWidth(20),
    };
  }, []);

  return (
    <TouchableWithoutFeedback onPress={handleContainerPress}>
      <S.StickerContainer style={stickerContainerStyle} color={color || theme.colors.orangeSticky}>
        {text.length === 0 && (
          <S.Placeholder top={placeholderInsets.top} left={placeholderInsets.left}>
            {placeHolderByType}
          </S.Placeholder>
        )}
        <S.QuestionInput
          ref={questionInputRef}
          style={questionInputStyle}
          value={text}
          onChangeText={onChangeText}
          maxLength={150}
          multiline
          autoFocus
          // @ts-ignore
          dataSet={questionInputValueDataSet}
        />
        <S.MediaImage
          uri={mediaURI}
          isLoading={isImageLoading}
          openMediaModal={onAddImage}
          onDeleteImage={onDeleteImage}
          withModal
        />
        <S.LengthCounter>{`${text?.length || '0'} / 150`}</S.LengthCounter>
        <S.AddImageIconContainer onPress={onAddImage}>
          <AddImageIcon />
        </S.AddImageIconContainer>
      </S.StickerContainer>
    </TouchableWithoutFeedback>
  );
};

const S = {
  MediaImage: styled(MediaImage)`
    height: ${calcHeight(240)}px;
    border-radius: 18px;
    overflow: hidden;
  `,
  StickerContainer: styled.View<{ color: string }>`
    width: ${calcWidth(327)}px;
    border-radius: 9px;
    padding: ${calcHeight(24)}px ${calcWidth(20)}px ${calcHeight(50)}px;
    background-color: ${({ color }) => color};
  `,
  LengthCounter: styled.Text`
    position: absolute;
    bottom: ${calcHeight(12)}px;
    left: ${calcWidth(21)}px;

    font-size: ${({ theme }) => theme.fontSizes.s13};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  AddImageIconContainer: styled.TouchableOpacity`
    position: absolute;
    bottom: ${calcHeight(12)}px;
    right: ${calcWidth(21)}px;
  `,
  QuestionInput: styled.TextInput`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    color: ${({ theme }) => theme.colors.darkPrimary};
    margin-bottom: ${calcHeight(16)}px;
    min-height: ${calcHeight(120)}px;
    max-height: ${calcHeight(120)}px;
  `,
  Placeholder: styled.Text<{ top: number; left: number }>`
    position: absolute;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    color: ${({ theme }) => theme.colors.lightGray2};
    width: ${calcWidth(287)}px;
    ${({ top, left }) => css`
      top: ${top}px;
      ${isWeb && isRtlByLang[i18next.language] ? `right: ${left}px` : `left: ${left}px`};
      text-align: ${isWeb && isRtlByLang[i18next.language] ? 'right' : 'left'};
    `};
    ${isWeb
      ? `
      user-select: none;
      cursor: text;
    `
      : ''};
  `,
};

export default QuestionSticker;
