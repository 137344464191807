import React, { useCallback, useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { calcFontSize, calcHeight, deviceWidth } from '../../../../utils/dimensions';
import { ScrollView } from 'react-native-gesture-handler';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import { creatorIdsSelector } from '../../../../store/feed/feed.selectors';
import { loadNextPage, setCreatorIds, setCreatorsLabel } from '../../../../store/feed/feed.slice';
import { creatorsSelector } from '../../../../store/org/org.selectors';
import { userSelector } from '../../../../store/auth/auth.selectors';
import { ICreator } from '../../../../store/org/org.types';
import { useTranslation } from 'react-i18next';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { sortBy } from 'lodash';

const shadowOffset: ShadowProps['offset'] = [0, 0];

enum EType {
  'all' = 'all',
  'me' = 'me',
  'everyone_but_me' = 'everyone_but_me',
  'user' = 'user',
}
type TOnPress = (data: number[] | null, isActive: boolean, type: EType, label: string) => void;

interface IProps {}

const FeedCreatorsPanel: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(userSelector);
  const creators = useSelector(creatorsSelector);
  const selectedCreatorIds = useSelector(creatorIdsSelector);

  const creatorsFiltered = useMemo(() => {
    const creatorsArray = creators.filter((creator) => creator.id !== user?.id);
    const sortedCreatorsArray = sortBy(creatorsArray, 'first_name');
    return sortedCreatorsArray;
  }, [creators, user?.id]);

  const myUser = useMemo(() => {
    return [user?.id];
  }, [user]);

  const currentType: EType =
    selectedCreatorIds === null
      ? EType.all
      : selectedCreatorIds?.length === 1 && selectedCreatorIds[0] === user?.id
      ? EType.me
      : selectedCreatorIds?.length > 1
      ? EType.everyone_but_me
      : EType.user;

  const setFilter = useCallback(
    (ids, isActive, type, label) => {
      dispatch(togglePanel(null));

      if (isActive) {
        return;
      }

      dispatch(
        trackEvent({
          event: 'set_creator_filter',
          props: { from_value: currentType, to_value: type },
        }),
      );

      dispatch(setCreatorIds(ids));
      dispatch(setCreatorsLabel(label));
      dispatch(loadNextPage({ reset: true }));
    },
    [currentType, dispatch],
  );

  return (
    <ShadowedContainer offset={shadowOffset}>
      <S.Container>
        <ScrollView>
          <CreatorOption
            type={EType.all}
            isActive={currentType === EType.all}
            data={null}
            label={t('filterPannel.all')}
            onPress={setFilter}
          />

          <CreatorOption
            type={EType.me}
            isActive={currentType === EType.me}
            data={myUser}
            label={t('filterPannel.me')}
            onPress={setFilter}
          />
          {creatorsFiltered.map((creator) => (
            <Creator
              type={EType.user}
              isActive={selectedCreatorIds?.length === 1 && selectedCreatorIds[0] === creator.id}
              creator={creator}
              onPress={setFilter}
              key={creator.id}
            />
          ))}
        </ScrollView>
      </S.Container>
    </ShadowedContainer>
  );
};

interface ICreatorComponent {
  type: EType;
  creator: ICreator;
  isActive: boolean;
  onPress: TOnPress;
}
const Creator: FC<ICreatorComponent> = ({ type, creator, isActive, onPress }) => {
  const data = useMemo(() => {
    return [creator.id];
  }, [creator.id]);

  return (
    <CreatorOption
      type={type}
      isActive={isActive}
      data={data}
      label={`${creator.first_name} ${creator.last_name}`}
      onPress={onPress}
    />
  );
};

interface ICreatorOption {
  type: EType;
  label: string;
  data: number[] | null;
  isActive: boolean;
  onPress: TOnPress;
}

const CreatorOption: FC<ICreatorOption> = ({ type, label, data, isActive, onPress }) => {
  const handlePress = useCallback(() => {
    onPress(data, isActive, type, label);
  }, [data, isActive, label, onPress, type]);

  return (
    <S.ValueContainer onPress={handlePress}>
      <S.ValueText isActive={isActive}>{label}</S.ValueText>
    </S.ValueContainer>
  );
};

const S = {
  Container: styled.View`
    padding-bottom: ${calcHeight(100)}px;
    width: ${deviceWidth}px;
    height: ${calcHeight(300)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    height: ${calcHeight(40)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueText: styled.Text<{ isActive: boolean }>`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ isActive }) => calcFontSize(isActive ? 20 : 14)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
};

export default FeedCreatorsPanel;
