import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { TextInput, CustomText } from '../../../components/shared';
import { calcHeight, calcWidth, isWeb, verticalScale } from '../../../utils/dimensions';
import BottomController from '../components/BottomController';

import 'react-native-gesture-handler';
import FeedItemsList from '../../../components/feed/FeedItemsList';
import { useDispatch, useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import Intercom from '../../../services/intercom';
import { ChipButton } from '../../../components/shared/buttons';
import { useCallback } from 'react';
import { updateSelectedBites } from '../../../store/createPlaylist/createPlaylist.actions';
import Routes from '../../../navigation/routes';
import { selectBite } from '../../../store/createPlaylist/createPlaylist.actions';
import SearchPanel from '../../../components/shared/SearchPanel';
import SearchIcon from '../../../assets/icons/search-icon.svg';
import { useTheme } from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import ShadowedContainer from '../../../components/ShadowedContainer';
import {
  isLoadingSelector,
  nextPageSelector,
  resultsSelector,
  searchStrSelector,
} from '../../../store/bitesFeed/bitesFeed.selectors';
import { loadNextPage, setSearchStr } from '../../../store/bitesFeed/bitesFeed.slice';
import BiteItem from '../../../components/feed/BiteItem';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { continueFromSelectBitesDataSet } from './addBites.constants';

const AddBites = ({ navigation }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const { editedPlaylist } = useSelector(createPlaylistSelector);
  const { selectedBites, editMode } = useSelector(createPlaylistSelector);
  const isLoading = useSelector(isLoadingSelector);
  const searchStr = useSelector(searchStrSelector);
  const results = useSelector(resultsSelector);
  const nextPage = useSelector(nextPageSelector);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleChangeSearchStr = useCallback(
    (newSearchStr) => {
      dispatch(setSearchStr(newSearchStr));
      dispatch(loadNextPage({ reset: true, debounce: true }));
    },
    [dispatch],
  );

  const handleOpenSearchPanel = useCallback(() => {
    setIsSearchOpen(true);
  }, []);

  const handleCloseSearchPanel = useCallback(() => {
    dispatch(setSearchStr(''));
    dispatch(loadNextPage({ reset: true }));
    setIsSearchOpen(false);
  }, [dispatch]);

  const renderHeaderRight = useCallback(() => {
    return (
      <>
        {isSearchOpen ? (
          <SearchPanel
            isSearching={isLoading}
            value={searchStr}
            onClosePanel={handleCloseSearchPanel}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
            onChangeText={handleChangeSearchStr}
            expandedPanelWidth={isWeb ? calcWidth(263) : calcWidth(240)}
            autoFocus
          />
        ) : (
          <ShadowedContainer>
            <S.SearchIconWrapper onPress={handleOpenSearchPanel}>
              <SearchIcon color={theme.colors.primaryBlue} />
            </S.SearchIconWrapper>
          </ShadowedContainer>
        )}
      </>
    );
  }, [
    isSearchOpen,
    isLoading,
    searchStr,
    handleCloseSearchPanel,
    handleChangeSearchStr,
    handleOpenSearchPanel,
    theme.colors.primaryBlue,
  ]);

  const renderHeaderTitle = useCallback(
    () =>
      isSearchOpen ? null : <S.HeaderTitle>{t('createPlaylistStack.addBitesToPlaylist.headerTitle')}</S.HeaderTitle>,
    [isSearchOpen, t],
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    navigation.setOptions({
      headerRight: renderHeaderRight,
      headerTitle: renderHeaderTitle,
    });
  }, [isSearchOpen, navigation, renderHeaderRight, renderHeaderTitle, isFocused]);

  useEffect(() => {
    if (!isLoading && results.length === 0) {
      dispatch(loadNextPage({ reset: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Intercom.updateUser({
      customAttributes: {
        app_visited_playlist_create: true,
      },
    });
  }, []);

  const handleClearSelectedBites = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'clear_all',
      }),
    );
    dispatch(updateSelectedBites([]));
  }, [dispatch]);

  const handlePullToRefresh = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'reload',
        props: { page_title: 'AddBitesToPlaylist' },
      }),
    );
    dispatch(loadNextPage({ reset: true }));
  }, [dispatch]);

  const handlePressNext = useCallback(() => {
    if (editMode) {
      dispatch(
        trackEvent({
          event: 'select_bites_save',
          props: { page_title: 'AddBitesToPlaylist' },
        }),
      );
      navigation.goBack();
    } else {
      dispatch(
        trackEvent({
          event: 'select_bites_continue',
          props: { page_title: 'AddBitesToPlaylist' },
        }),
      );
      navigation.navigate(Routes.CreatePlaylistStack.OrganizePlaylist);
    }
  }, [dispatch, editMode, navigation]);

  const handleBitePress = useCallback(
    ({ bite }) => {
      dispatch(
        trackEvent({
          event: 'playlist_select_bite',
          props: { playlist_id: editedPlaylist?.id, bite_id: bite.id },
        }),
      );
      dispatch(
        selectBite({
          id: bite.id,
          bite_preview: bite.bite_preview,
          cover_url: bite.cover_url,
          linked_cover_url: bite.linked_cover_url,
          subject: bite.subject,
          watched: bite?.watched,
          participants: bite?.participants,
          comments: bite?.comments,
          branding: bite?.branding,
        }),
      );
    },
    [editedPlaylist?.id, dispatch],
  );

  const renderItem = useCallback(
    ({ item: biteId, index }) => {
      return (
        <BiteItem biteId={biteId} index={index} isSmall withBiteInPlaylistSelectedOverlay onPress={handleBitePress} />
      );
    },
    [handleBitePress],
  );

  const handleEndReached = useCallback(() => {
    if (nextPage === null || isLoading) {
      return;
    }
    dispatch(loadNextPage({}));
  }, [dispatch, isLoading, nextPage]);

  const keyExtractor = useCallback((id) => `${id}`, []);

  return (
    <S.Container>
      <S.ScrolledContentContainer>
        <FeedItemsList
          items={results}
          isSmall
          isLoading={isLoading}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          onEndReached={handleEndReached}
          onPullToRefresh={handlePullToRefresh}
        />
        <S.Spacing />
        <BottomController>
          <S.TextsContainer>
            <S.SelectedWrapper>
              <S.BitesLenghtWrapper>
                <S.BitesLength>{selectedBites.length}</S.BitesLength>
              </S.BitesLenghtWrapper>
              <S.SelectedText>{t('createPlaylistStack.addBitesToPlaylist.bitesSelected')} </S.SelectedText>
            </S.SelectedWrapper>
            {selectedBites.length > 0 && (
              <TouchableOpacity onPress={handleClearSelectedBites}>
                <S.ClearTextWrapper>
                  <S.ClearText>{t('createPlaylistStack.addBitesToPlaylist.clear')}</S.ClearText>
                </S.ClearTextWrapper>
              </TouchableOpacity>
            )}
          </S.TextsContainer>
          <S.ContinueWrapper>
            <ChipButton
              content={t(`common.${editMode ? 'Save' : 'Continue'}`)}
              disabled={selectedBites.length === 0}
              onPress={handlePressNext}
              dataSet={continueFromSelectBitesDataSet}
            />
          </S.ContinueWrapper>
        </BottomController>
      </S.ScrolledContentContainer>
    </S.Container>
  );
};

const SearchIconWrapper = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${calcWidth(40 / 2)}px;
  height: ${calcWidth(40)}px;
  width: ${calcWidth(40)}px;
`;
const HeaderTitle = styled(CustomText)`
  font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  font-size: ${({ theme }) => theme.fontSizes.s18}px;
  color: ${({ theme }) => theme.colors.black};
`;

const Container = styled.View`
  flex: 1;
  align-items: center;
  width: 100%;
`;

const ScrolledContentContainer = styled.View(
  ({ theme }) => css`
    z-index: 10;
    flex: 1;
    width: 100%;
    background-color: ${theme.colors.white};
    overflow: hidden;
  `,
);
const textInputHeight = 70;

const SearchBiteInput = styled(TextInput)(
  ({ theme }) => css`
    transform: translateY(-${calcHeight(textInputHeight) / 2}px);
    font-size: ${theme.fontSizes.s20}px;
    font-family: ${theme.fontFamilies.GilroyBold};
    align-self: center;
  `,
);

const SearchBiteWrapper = styled.View`
  height: ${verticalScale(50)}px;
  width: ${verticalScale(50)}px;
  border-radius: ${verticalScale(50) / 2}px;
  background-color: ${({ theme }) => theme.colors.darkerPrimaryBlue};
  justify-content: center;
  align-items: center;
`;

const TextsContainer = styled.View`
  flex-direction: row;
`;

const SelectedWrapper = styled.View`
  display: flex;
  flex-direction: column;
  padding-left: ${calcWidth(30)}px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BitesLenghtWrapper = styled.View`
  width: ${calcWidth(34)}px;
  height: ${calcHeight(26)}px;
  border-radius: 18px;
  background-color: #9ce91d;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const BitesLength = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyBold};
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.black};
  `,
);
const ClearTextWrapper = styled.View`
  justify-content: center;
  align-self: center;
  left: ${calcWidth(20)}px;
  padding-top: ${calcHeight(20)}px;
`;

const ClearText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s13}px;
    color: ${theme.colors.primaryBlue};
  `,
);

const SelectedText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.Arimo};
    font-size: ${theme.fontSizes.s11}px;
    color: ${theme.colors.gray1};
  `,
);

const ContinueWrapper = styled.View`
  padding-right: ${calcWidth(24)}px;
`;

const Spacing = styled.View`
  height: ${calcHeight(100)}px;
`;

const S = {
  Container,
  SearchIconWrapper,
  HeaderTitle,
  Spacing,
  ScrolledContentContainer,
  SearchBiteInput,
  SearchBiteWrapper,
  ContinueWrapper,
  ClearTextWrapper,
  BitesLenghtWrapper,
  SelectedWrapper,
  SelectedText,
  BitesLength,
  ClearText,
  TextsContainer,
};

export default AddBites;
