import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth, calcHeight, calcFontSize } from '../../../../../../utils/dimensions';
import ArrowCollapse from '../../../../../../assets/icons/arrow-collapse.svg';
import { PANEL_HEADER_HEIGHT } from '../Panel';
import VerifiedToggle from './common/VerifiedToggle';

interface IProps {
  onClick?: () => void;
  isExpanded?: boolean;
  title?: string;
  isSubtitlesLoading?: boolean;
}

const PanelHeaderWeb: React.FC<IProps> = ({ onClick, title, isExpanded, isSubtitlesLoading }) => {
  const theme = useTheme();

  return (
    <S.Header onPress={onClick}>
      <S.Content>
        <S.VerifiedToggleContainer>
          <VerifiedToggle isDisabled={isSubtitlesLoading} />
        </S.VerifiedToggleContainer>
        {title && (
          <S.HeaderMain>
            <S.HeaderText>{title}</S.HeaderText>
          </S.HeaderMain>
        )}
      </S.Content>
      <S.ArrowContainer isExpanded={isExpanded}>
        <ArrowCollapse color={theme.colors.primaryBlue} />
      </S.ArrowContainer>
    </S.Header>
  );
};

const S = {
  Content: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
  Header: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px ${calcWidth(16)}px;
    height: ${calcHeight(PANEL_HEADER_HEIGHT)}px;
  `,
  HeaderMain: styled.View`
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex: 1;
  `,
  HeaderText: styled.Text`
    flex-direction: row;
    font-size: ${calcFontSize(18)}px;
    color: ${({ theme }) => theme.colors.gray9};
    font-weight: 400;
    align-self: center;
  `,
  ArrowContainer: styled.View<{ isExpanded: boolean }>`
    width: ${calcWidth(12)}px;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : '')};
    position: absolute;
    align-self: center;
    margin-top: ${calcHeight(-40)}px;
  `,
  VerifiedToggleContainer: styled.View`
    position: absolute;
    left: ${calcWidth(8)}px;
    z-index: 3;
  `,
};

export default PanelHeaderWeb;
