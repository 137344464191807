import React, { FC, useMemo } from 'react';
import { JSX } from 'react-dom';
import styled, { css } from 'styled-components/native';
import { TextInputProps, I18nManager } from 'react-native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { ITheme } from '../../../../themes/defaultTheme';
import { createShadow } from '../../../../utils';

interface IProps extends TextInputProps {
  RightComponent?: JSX.Element;
  LeftComponent?: JSX.Element;
  noTopRadius?: boolean;
  noBottomRadius?: boolean;
  hasError?: boolean;
  noErrorOnBorder?: boolean;
  placeholder?: string;
  placeholderTextColor?: string;
  focused?: boolean;
}

const TextInput: FC<IProps> = (props) => {
  const { RightComponent, LeftComponent, style, ...restProps } = props;

  const inputStyle = useMemo(() => {
    return [createShadow({ elevation: 6 }), style];
  }, [style]);

  return (
    <S.InputWrapper autoCompleteType='off' autoCorrect={false} {...restProps} style={inputStyle}>
      {LeftComponent && LeftComponent}

      {/* TODO: styled-component types don't match with react types */}
      {/* @ts-ignore */}
      <S.Input {...restProps} />

      {RightComponent && RightComponent}
    </S.InputWrapper>
  );
};

const InputWrapper = styled.View(
  ({ theme, noBottomRadius, noTopRadius, hasError, noErrorOnBorder }: IProps & ITheme) => `
  flex-direction: row;
  align-items: center;
  background-color: ${theme.colors.white};
  height: ${calcHeight(70)}px;
	width: ${calcWidth(315)}px;
  
  ${!noBottomRadius && !noTopRadius ? 'border-radius: 15px;' : ''}
  ${noBottomRadius && !noTopRadius ? 'border-top-left-radius: 15px; border-top-right-radius: 15px;' : ''}
  ${!noBottomRadius && noTopRadius ? 'border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;' : ''}

	${hasError && !noErrorOnBorder ? `border-width: 2px; border-color: ${theme.colors.warning}` : ''}
  padding-right: ${calcWidth(20)}px;
  padding-left: ${calcWidth(20)}px;
`,
);

const Input = styled.TextInput(
  ({ theme, LeftComponent, RightComponent, hasError }: IProps & ITheme) => css`
    font-size: ${theme.fontSizes.s18}px;
    color: ${hasError ? theme.colors.warning : theme.colors.darkPrimary};
    flex: 1;
    ${LeftComponent ? `margin-left: ${calcWidth(10)}px;` : ''}
    ${RightComponent ? `margin-right: ${calcWidth(10)}px;` : ''}
	text-align: ${I18nManager.isRTL && !isWeb ? 'right' : 'left'};
  `,
);

const S = { Input, InputWrapper };

export default TextInput;
