import AnalyticsList from '../List';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterAttributeByIdSelector } from '../../../../store/attributes/attributes.selector';
import { getFilterList } from '../../../../store/attributes/attributes.slice';
import styled from 'styled-components/native';
import { IAttributeValue } from '../../../../store/attributes/attributes.types';
import { log } from '../../../../store/appActivity/appActivity.slice';

interface IProps {
  initialSelectedValues: IAttributeValue[];
  initialSelectedValuesMap: { [attributeId: number]: boolean };
  displaySelectedValues: IAttributeValue[];
  isShowSelectedFilters: boolean;
  selectedAttributeId: number;
  renderItem: (props: { item: IAttributeValue }) => React.ReactElement;
  maxHeight: number;
  dataSet?: { [key: string]: string };
}

const FilterList: React.FC<IProps> = ({
  initialSelectedValues,
  initialSelectedValuesMap,
  isShowSelectedFilters,
  selectedAttributeId,
  renderItem,
  maxHeight,
  displaySelectedValues,
  dataSet,
}) => {
  const dispatch = useDispatch();
  const attributeMapItem = useSelector(filterAttributeByIdSelector(selectedAttributeId));

  const data = useMemo(() => {
    if (!attributeMapItem?.next && !attributeMapItem.isLoading) {
      const selectedIdsInResultsMap = {};
      const result = [];

      attributeMapItem.data.values.forEach((value) => {
        if (initialSelectedValuesMap[value.id]) {
          selectedIdsInResultsMap[value.id] = true;
        }

        result.push(value);
      });

      initialSelectedValues.forEach((value) => {
        if (!selectedIdsInResultsMap[value.id]) {
          result.push(value);
        }
      });

      return result;
    }

    return attributeMapItem.data.values;
  }, [
    attributeMapItem.data.values,
    attributeMapItem.isLoading,
    attributeMapItem?.next,
    initialSelectedValues,
    initialSelectedValuesMap,
  ]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'FilterList.handleEndReached',
        data: { selectedAttributeId, attributeMapItem },
      }),
    );

    dispatch(getFilterList({ attributeId: selectedAttributeId }));
  }, [attributeMapItem, dispatch, selectedAttributeId]);

  const handleEndReachedProp = useMemo(() => {
    if (isShowSelectedFilters || attributeMapItem.isLoading || !attributeMapItem?.next || attributeMapItem?.error) {
      return;
    }

    return handleEndReached;
  }, [attributeMapItem, handleEndReached, isShowSelectedFilters]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'FilterList.handleErrorRefresh',
        data: { selectedAttributeId },
      }),
    );

    dispatch(getFilterList({ attributeId: selectedAttributeId }));
  }, [dispatch, selectedAttributeId]);

  const handleRefresh = useCallback(
    ({ callback }) => {
      dispatch(
        log({
          event: 'FilterList.handleRefresh',
          data: { selectedAttributeId },
        }),
      );

      dispatch(getFilterList({ attributeId: selectedAttributeId, reset: true, callback }));
    },
    [dispatch, selectedAttributeId],
  );

  return (
    <S.AnalyticsList
      dataSet={dataSet}
      maxHeight={maxHeight}
      isLoading={attributeMapItem?.isLoading}
      onEndReached={handleEndReachedProp}
      onErrorRefresh={handleErrorRefresh}
      onRefresh={handleRefresh}
      error={attributeMapItem?.error}
      data={isShowSelectedFilters ? displaySelectedValues : data}
      renderItem={renderItem}
    />
  );
};

const S = {
  AnalyticsList: styled(AnalyticsList)<{ maxHeight: number }>`
    max-height: ${({ maxHeight }) => maxHeight}px;
    height: auto;
  `,
};

export default memo(FilterList);
