import { IStateType, IActionTypes, ICheckBox } from './types';

const useComponentReducer = () => {
  const initialState: IStateType = {
    usersList: [],
    usersDisplayedList: [],
    isLoading: false,
    searchString: '',
  };

  const reducer = (state: IStateType, action: IActionTypes) => {
    switch (action.type) {
      case 'setUsersList': {
        const { usersList } = action.payload;
        return {
          ...state,
          usersList,
          usersDisplayedList: usersList,
          isLoading: false,
        };
      }
      case 'toggleCheckBox': {
        const { id, value } = action.payload;
        const { usersList } = state;
        const updateUsersList: Array<ICheckBox> = usersList.map((checkbox) => {
          if (checkbox.id === id) {
            return { ...checkbox, isSelect: value };
          }
          return checkbox;
        });
        const updateDisplayList: Array<ICheckBox> = updateUsersList.filter((checkbox) =>
          checkbox.name.includes(state.searchString),
        );
        return {
          ...state,
          usersList: updateUsersList,
          usersDisplayedList: updateDisplayList,
        };
      }
      case 'loading': {
        return { ...state, isLoading: true };
      }
      case 'search': {
        const { text } = action.payload;

        const usersDisplayedList = state.usersList.filter((user) =>
          user.name.toLowerCase().includes(text.toLowerCase()),
        );
        return {
          ...state,
          searchString: text,
          usersDisplayedList,
        };
      }
      case 'initModal': {
        const resetUsersList = state.usersList.map((user) => ({
          ...user,
          isSelect: false,
        }));

        return {
          ...state,
          searchString: '',
          usersList: resetUsersList,
          usersDisplayedList: resetUsersList,
        };
      }
      default:
        // @ts-ignore
        throw new Error(`unknown action type ${action.type}`);
    }
  };
  return { reducer, initialState };
};

export default useComponentReducer;
