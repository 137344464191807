import React, { memo, useCallback } from 'react';
import UploadIcon from '../../../assets/icons/videoEditor/leftSidebar/upload.svg';
import CloseIcon from '../../../assets/icons/videoEditor/close.svg';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};
const UploadControl = ({ isVisible, setIsVisible }: Props) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      setIsVisible(!isVisible);
    },
    [isVisible, setIsVisible],
  );

  return (
    <>
      <S.Container onClick={handleClick}>{isVisible ? <CloseIcon /> : <UploadIcon />}</S.Container>
    </>
  );
};
export default memo(UploadControl);

const S = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
};
