import React, { useCallback, useState } from 'react';
import BaseModal from '../creationBite/common/BaseModal';
import { useTranslation } from 'react-i18next';
import PowerpointIcon from '../../assets/icons/biteIntro/powerpoint.svg';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import styled from 'styled-components/native';
import Intercom from '../../services/intercom/intercom';
import VideoModal from '../../components/shared/VideoModal';

const PPTXExplanationVideoId = '774161034';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onModalHide?: () => void;
}

const PowerpointModal: React.FC<IProps> = ({ isVisible, onClose, onModalHide }) => {
  const { t } = useTranslation();

  const [isPowerpointLearnHowVideoVisible, setIsPowerpointLearnHowVideoVisible] = useState(false);

  const handleOpenPowerpointLearnHowVideo = useCallback(() => {
    setIsPowerpointLearnHowVideoVisible(true);
  }, []);

  const handleClosePowerpointLearnHowVideo = useCallback(() => {
    setIsPowerpointLearnHowVideoVisible(false);
  }, []);

  const handleContactSupportPress = useCallback(() => {
    Intercom.showNewMessage(t('powerpointPresentation.intercom'));
  }, [t]);

  return (
    <>
      <BaseModal onClose={onClose} isVisible={isVisible} onModalHide={onModalHide}>
        <S.Header>
          <S.IconContainer>
            <PowerpointIcon width={calcWidth(40)} height={calcHeight(40)} />
          </S.IconContainer>
          <S.Title>{t('powerpointPresentation.title')}</S.Title>
        </S.Header>
        <S.Content>
          <S.Description>{t('powerpointPresentation.description')}</S.Description>

          <S.PointsContainer>
            <S.DescriptionPointContainer>
              <S.Point>1.</S.Point>
              <S.DescriptionPoint>
                {t('powerpointPresentation.descriptionOptionUseGoogleSlides')}
                {'\n'}
                <S.LineButton onPress={handleOpenPowerpointLearnHowVideo}>
                  <S.BlueText>{t('powerpointPresentation.learnHow')}</S.BlueText>
                </S.LineButton>
              </S.DescriptionPoint>
            </S.DescriptionPointContainer>

            <S.DescriptionPointContainer>
              <S.Point>2.</S.Point>
              <S.DescriptionPoint>
                {t('powerpointPresentation.descriptionOptionSend')}
                {'\n'}
                <S.LineButton onPress={handleContactSupportPress}>
                  <S.BlueText>{t('powerpointPresentation.contactSupport')}</S.BlueText>
                </S.LineButton>{' '}
              </S.DescriptionPoint>
            </S.DescriptionPointContainer>
          </S.PointsContainer>
        </S.Content>
      </BaseModal>

      <VideoModal
        isVisible={isPowerpointLearnHowVideoVisible}
        onBtnRightClick={handleClosePowerpointLearnHowVideo}
        videoUrl={PPTXExplanationVideoId}
        btnRightLabel={t('common.Close')}
        btnRightStyle={'primary'}
        isAutoPlay
        isMutedInitial={false}
        videoLinkRequired
      />
    </>
  );
};

const S = {
  Header: styled.View`
    margin-top: ${calcHeight(-40)}px;
    align-items: center;
  `,
  Content: styled.View`
    margin-bottom: ${calcHeight(20)}px;
  `,
  IconContainer: styled.View`
    margin-right: ${calcWidth(10)}px;
  `,
  Title: styled.Text`
    margin-top: ${calcHeight(10)}px;
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.lightGray17};
  `,
  Description: styled.Text`
    text-align: center;
    margin-top: ${calcHeight(10)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  DescriptionPointContainer: styled.View`
    flex-direction: row;
  `,
  DescriptionPoint: styled.Text`
    margin-top: ${calcHeight(10)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  Point: styled.Text`
    margin-top: ${calcHeight(10)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray11};
    margin-right: ${calcWidth(5)}px;
  `,
  PointsContainer: styled.View``,

  LineButton: styled.TouchableOpacity`
    height: ${calcHeight(19)}px;
  `,
  BlueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.primaryBlue};
    text-decoration: underline;
  `,
};

export default PowerpointModal;
