import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { EShareMode } from '../../../utils/constants/bite';
import { calcHeight } from '../../../utils/dimensions';
import CodeClipboard from '../../shared/CodeClipboard';
import SharePanelCheckbox from './SharePanelCheckbox';

interface IProps {
  accessCode: string;
  sharingMode: EShareMode;
  onChange: (mode: EShareMode.REQUIRE_LOGIN | EShareMode.TEAM_ONLY_ALLOW_JOIN) => void;
}

const SharePanelAccessCodeForm: React.FC<IProps> = ({ accessCode, sharingMode, onChange }) => {
  const { t } = useTranslation();

  const [isChecked, setCheckedState] = useState(false);

  useEffect(() => {
    setCheckedState(sharingMode === EShareMode.TEAM_ONLY_ALLOW_JOIN);
  }, [sharingMode]);

  const handlePress = useCallback(
    (newChecked: boolean) => {
      const newMode = newChecked ? EShareMode.TEAM_ONLY_ALLOW_JOIN : EShareMode.REQUIRE_LOGIN;
      setCheckedState(newChecked);
      onChange(newMode);
    },
    [onChange],
  );

  return (
    <S.Container>
      <SharePanelCheckbox label={t('share.requireAnAccessCode')} isChecked={isChecked} onChange={handlePress} />

      <S.Divider />

      <CodeClipboard disabled={!isChecked} code={accessCode} />
    </S.Container>
  );
};

const Container = styled.View`
  width: 100%;
`;

const Divider = styled.View`
  width: 100%;
  height: ${calcHeight(10)}px;
`;

const S = {
  Container,
  Divider,
};

export default memo(SharePanelAccessCodeForm);
