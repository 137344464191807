import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calcHeight, isWeb } from '../../../../utils/dimensions';
import NewBiteBtn from '../../../../assets/icons/feed/create-new-bite-btn.svg';
import FeedItemsList from '../../../../components/feed/FeedItemsList';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';

import {
  activeOrganizationSelector,
  isContentCreatorSelector,
  userSelector,
} from '../../../../store/auth/auth.selectors';
import { trackDraftEvents } from '../../../../store/drafts/drafts.slice';
import {
  isLoadingSelector,
  nextPageSelector,
  resultsSelector,
  searchStrSelector,
} from '../../../../store/feed/feed.selectors';
import { loadNextPage } from '../../../../store/feed/feed.slice';
import FeedItem from './FeedItem';
import MediaModal from '../../../creationBite/common/MediaModal';
import useMedia, { formatFileTypesForWeb } from '../../../../hooks/useMedia';
import { IFeedItem } from '../../../../store/feed/feed.types';
import { updateBiteCover, uploadBiteCoverImage } from '../../../../store/bite/bite.actions';
import { EObjectType } from '../../../../types/common';
import { updatePlaylistCover, uploadPlaylistCoverImage } from '../../../../store/playlist/playlist.actions';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import EmptyList from '../../../../components/shared/EmptyList';
import { useTranslation } from 'react-i18next';
import startBiteCreationFlow from '../../../../utils/bite/startBiteCreationFlow';
import uploadImageByUrl from '../../../../utils/uploadImageByUrl/uploadImageByUrl';

interface IProps {}

const shadowedMainContainerViewStyle: StyleProp<ViewStyle> = {
  alignSelf: 'center',
  marginTop: calcHeight(53),
};

const newBiteBtnStyle: StyleProp<ViewStyle> = { alignSelf: 'center' };

const Feed: FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeOrganization = useSelector(activeOrganizationSelector);
  const user = useSelector(userSelector);
  const isLoading = useSelector(isLoadingSelector);
  const results = useSelector(resultsSelector);
  const nextPage = useSelector(nextPageSelector);
  const searchStr = useSelector(searchStrSelector);
  const isContentCreator = useSelector(isContentCreatorSelector);

  const [isMediaModalVisible, setMediaModalVisible] = useState<boolean>(false);
  const [selectedFeedItem, setSelectedFeedItem] = useState<IFeedItem>(null);

  const handleOpenMediaModal = useCallback(() => {
    setMediaModalVisible(true);
  }, []);

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const handleAddCover = useCallback(
    (item: IFeedItem) => {
      setSelectedFeedItem(item);
      handleOpenMediaModal();
    },
    [handleOpenMediaModal],
  );

  const onMediaSelected = useCallback(
    (uri, type, editedImageUri) => {
      handleCloseMediaModal();
      if (selectedFeedItem.content_type === EObjectType.bite) {
        dispatch(
          uploadBiteCoverImage({
            biteId: selectedFeedItem.id,
            uri: isWeb ? uri : editedImageUri || uri,
            type,
          }),
        );

        return;
      }

      dispatch(
        uploadPlaylistCoverImage({
          playlistId: selectedFeedItem.id,
          uri: isWeb ? uri : editedImageUri || uri,
          type,
        }),
      );
    },
    [selectedFeedItem, handleCloseMediaModal, dispatch],
  );

  const handleSearchOnlineSelect = useCallback(
    async (imageUrl: string) => {
      const media = await uploadImageByUrl({ url: imageUrl });

      if (selectedFeedItem.content_type === EObjectType.bite) {
        dispatch(updateBiteCover({ biteId: selectedFeedItem.id, uri: media.image_url }));
        return;
      }

      dispatch(updatePlaylistCover({ playlistId: selectedFeedItem.id, uri: media.image_url }));
    },
    [selectedFeedItem, dispatch],
  );

  const { launchImageLibrary, launchImageCamera, dropZoneUploadingForWeb } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    onMediaSelectionCB: onMediaSelected,
  });

  const handlePullToRefresh = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'reload',
        props: { page_title: 'feed' },
      }),
    );
    dispatch(loadNextPage({ reset: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    dispatch(
      trackDraftEvents({
        biteIds: results.map(({ id }) => id),
      }),
    );
  }, [activeOrganization?.id, user?.id, dispatch, results]);

  const renderItem = useCallback(
    ({ item, index }) => <FeedItem onAddCover={handleAddCover} item={item} index={index} />,
    [handleAddCover],
  );

  const keyExtractor = useCallback(({ id }) => `${id}`, []);
  const handleEndReached = useCallback(() => {
    if (nextPage === null || isLoading) {
      return;
    }

    dispatch(loadNextPage({}));
  }, [dispatch, isLoading, nextPage]);

  const handleCreateBite = useCallback(() => {
    startBiteCreationFlow();
    dispatch(togglePanel(null));
  }, [dispatch]);

  const renderEmptyList = useCallback(() => {
    if (searchStr === '') {
      if (isContentCreator) {
        return (
          <ShadowedContainer containerViewStyle={shadowedMainContainerViewStyle}>
            <TouchableOpacity onPress={handleCreateBite}>
              <NewBiteBtn style={newBiteBtnStyle} />
            </TouchableOpacity>
          </ShadowedContainer>
        );
      }

      return <EmptyList message={t('homeScreen.emptyFeed')} />;
    }

    return <EmptyList message={t('homeScreen.emptySearchList')} />;
  }, [handleCreateBite, isContentCreator, searchStr, t]);

  return (
    <>
      <FeedItemsList
        items={results}
        isLoading={isLoading}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        onEndReached={handleEndReached}
        onPullToRefresh={handlePullToRefresh}
        renderEmptyList={renderEmptyList}
      />
      <MediaModal
        isVisible={isMediaModalVisible}
        onClose={handleCloseMediaModal}
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onSelectStockImage={handleSearchOnlineSelect}
        from='cover_photo'
      />
      {dropZoneUploadingForWeb}
    </>
  );
};

export default Feed;
