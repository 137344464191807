import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logError, setUserIPInfo } from '../store/appActivity/appActivity.slice';
import { useIsMounted } from './useIsMounted';

function useUserIPInfo() {
  const dispatch = useDispatch();
  const useMountedRef = useIsMounted();

  useEffect(() => {
    const getUserIPInfo = async () => {
      try {
        const {
          data: { data },
        } = await axios('https://api.ipbase.com/v2/info?apikey=6jpbXFjnWIqxDA9QrFHqPkPpf1MkQFPs5f2w8Sz7');

        if (!useMountedRef.current) {
          return;
        }

        // const dialCode = data?.location?.country?.calling_codes?.[0];

        dispatch(setUserIPInfo(data));
      } catch (error) {
        dispatch(
          logError({
            event: 'getUserIPInfo: error',
            error,
          }),
        );
      }
    };
    getUserIPInfo();
    // we want this to run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
export default useUserIPInfo;
