import React, { ReactElement } from 'react';
import { ModalProps } from 'react-native-modal';
import styled, { useTheme } from 'styled-components/native';
import Modal from '../ModalController';
import { TouchableOpacity, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import ShadowedContainer from '../../ShadowedContainer';
import CircleButton, { EButtonIcon } from '../../shared/buttons/CircleButton';

interface IProps extends Partial<ModalProps> {
  isVisible: boolean;
  title?: string;
  titleStyles?: StyleProp<TextStyle>;
  description?: string | ReactElement;
  onClose?: () => void;
  onRightButtonClick?: () => void;
  onLeftButtonClick?: () => void;
  rightButtonLabel?: string;
  leftButtonLabel?: string;
  reverseColors?: boolean;
  topIcon?: ReactElement;
  dataSet?: { [key: string]: string };
  renderContent?: () => ReactElement;
  buttonsMarginTop?: number;
}

const BlueAndRedButtonsModal: React.FC<IProps> = ({
  isVisible,
  onClose,
  title,
  titleStyles,
  description,
  onRightButtonClick,
  onLeftButtonClick,
  rightButtonLabel,
  leftButtonLabel,
  reverseColors,
  topIcon,
  dataSet,
  renderContent,
  buttonsMarginTop,
  ...modalProps
}) => {
  const theme = useTheme();
  const leftButtonColor = reverseColors ? theme.colors.failRed : theme.colors.primaryBlue;
  const rightButtonColor = !reverseColors ? theme.colors.failRed : theme.colors.primaryBlue;
  return (
    <Modal isVisible={isVisible} backdropOpacity={0.4} {...modalProps}>
      <ShadowedContainer containerViewStyle={S.ShadowedMainContainerViewStyle}>
        <S.Container hasTopIcon={!!topIcon}>
          {topIcon && <S.TopIconContainer>{topIcon}</S.TopIconContainer>}
          {onClose && (
            <ShadowedContainer containerViewStyle={S.ShadowedContainerViewStyle}>
              <CircleButton style={S.ShadowedContainerViewStyle} onPress={onClose} icon={EButtonIcon.CLOSE} />
            </ShadowedContainer>
          )}

          {!!title && <S.Title style={titleStyles}>{title}</S.Title>}
          {!!description && <S.Description>{description}</S.Description>}
          {!!renderContent && renderContent()}
          <S.ButtonsContainer marginTop={buttonsMarginTop}>
            <S.ButtonContainer>
              {onLeftButtonClick ? (
                <TouchableOpacity onPress={onLeftButtonClick}>
                  <S.Text color={leftButtonColor}>{leftButtonLabel}</S.Text>
                </TouchableOpacity>
              ) : null}
            </S.ButtonContainer>
            <S.ButtonContainer>
              {onRightButtonClick ? (
                <TouchableOpacity
                  onPress={onRightButtonClick}
                  //@ts-ignore
                  dataSet={dataSet}
                >
                  <S.Text color={rightButtonColor}>{rightButtonLabel}</S.Text>
                </TouchableOpacity>
              ) : null}
            </S.ButtonContainer>
          </S.ButtonsContainer>
        </S.Container>
      </ShadowedContainer>
    </Modal>
  );
};

const S = {
  Container: styled.View<{ hasTopIcon: boolean }>`
    align-self: center;
    align-items: center;
    width: ${calcWidth(355)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    padding-top: ${({ hasTopIcon }) => (hasTopIcon ? calcHeight(20) : calcHeight(77))}px;
    padding-right: ${calcWidth(35)}px;
    padding-left: ${calcWidth(35)}px;
    padding-bottom: ${calcHeight(30)}px;
  `,
  Title: styled.Text`
    max-width: ${calcWidth(250)}px;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    line-height: ${({ theme }) => theme.fontSizes.s18 * 1.39};
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  Description: styled.Text`
    margin-top: ${calcHeight(12)}px;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.gray11};
    text-align: center;
  `,
  ButtonsContainer: styled.View<{ marginTop?: number }>`
    flex-direction: row;
    width: 100%;
    margin-top: ${({ marginTop }) => calcHeight(marginTop ? marginTop : 54)}px;
    justify-content: space-between;
    align-items: center;
  `,
  ButtonContainer: styled.View``,
  TopIconContainer: styled.View`
    margin-bottom: ${calcHeight(20)}px;
  `,

  Text: styled.Text<{ color: string }>`
    font-size: ${({ theme }) => theme.fontSizes.s17};
    color: ${({ color }) => color};
  `,
  ShadowedContainerViewStyle: {
    position: 'absolute',
    top: calcHeight(12),
    right: calcWidth(12),
  } as StyleProp<ViewStyle>,
  ShadowedMainContainerViewStyle: {
    alignSelf: 'center',
  } as StyleProp<ViewStyle>,
};

export default BlueAndRedButtonsModal;
