import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from '../store/auth/auth.selectors';

const useIsPlaylistOwner = (playlist) => {
  const user = useSelector(userSelector);

  const isPlaylistOwner = useMemo(() => {
    if (playlist && user?.id === playlist.creator) {
      return true;
    }
    return false;
  }, [playlist, user?.id]);

  return isPlaylistOwner;
};

export default useIsPlaylistOwner;
