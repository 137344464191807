import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBiteItem } from '../../types/bite';
import AuthTypes from '../auth/auth.types';

interface IState {
  isFetching: boolean;
  bites: IBiteItem[];
  selectedBite: IBiteItem | null;
  isCloningByBiteId: { [key in number]: boolean };
}

const initialState: IState = {
  isFetching: false,
  bites: [],
  selectedBite: null as IBiteItem | null,
  isCloningByBiteId: {},
};

const EXPLORE_TAB = 'EXPLORE_TAB';

const exploreBitesSlice = createSlice({
  name: EXPLORE_TAB,
  initialState,
  reducers: {
    setIsCloning: (state, { payload }: PayloadAction<{ biteId: number; isCloning: boolean }>) => {
      if (payload.isCloning) {
        state.isCloningByBiteId[payload.biteId] = true;
        return;
      }
      delete state.isCloningByBiteId[payload.biteId];
    },

    fetchExploreBitesRequest: (state) => {
      state.isFetching = true;
    },
    fetchExploreBitesSuccess: (state, { payload }: PayloadAction<IBiteItem[]>) => {
      state.bites = payload;
      state.isFetching = false;
    },
    fetchExploreBitesError: (state) => {
      state.isFetching = false;
    },
  },
  extraReducers: {
    [AuthTypes.LOGOUT]: () => {
      return initialState;
    },
  },
});

export const previewExploreBiteById = createAction<number>(`${EXPLORE_TAB}/previewExploreBiteById`);

export const { fetchExploreBitesRequest, fetchExploreBitesSuccess, fetchExploreBitesError, setIsCloning } =
  exploreBitesSlice.actions;

export default exploreBitesSlice.reducer;
