import Types from './icons.types';
import AuthTypes from '../auth/auth.types';
import { IIcon } from './icons.types';

const initState = {
  isFetchingIcons: false,
  fetchResults: [] as IIcon[],
  isSearchingIcons: false,
  searchResults: [] as IIcon[],
};

const iconReducer = (state = initState, action: any): typeof initState => {
  switch (action.type) {
    case Types.FETCH_ICONS_REQUEST:
      return {
        ...state,
        isFetchingIcons: true,
      };

    case Types.FETCH_ICONS_SUCCUESS:
      return {
        ...state,
        isFetchingIcons: false,
        fetchResults: action.payload,
      };

    case Types.SEARCH_ICONS_REQUEST:
      return {
        ...state,
        isSearchingIcons: true,
      };

    case Types.SEARCH_ICONS_SUCCESS:
      return {
        ...state,
        isSearchingIcons: false,
        searchResults: action.payload,
      };

    case Types.CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [],
      };

    case AuthTypes.LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default iconReducer;
