import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';

import DrawerIcon from '../../../assets/icons/feed/drawer.svg';
import ShadowedContainer from '../../ShadowedContainer';
import { useNavigation, DrawerActions } from '@react-navigation/native';
import { calcWidth, isWeb } from '../../../utils/dimensions';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {}

const DrawerButton: FC<IProps> = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const toggleDrawer = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'opening_side_menu',
      }),
    );
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, [dispatch, navigation]);

  if (isWeb) {
    return <S.EmptyLeft />;
  }
  return (
    <ShadowedContainer>
      <S.Button onPress={toggleDrawer}>
        <DrawerIcon fill={theme.colors.white} />
      </S.Button>
    </ShadowedContainer>
  );
};

const S = {
  Button: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
  EmptyLeft: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
};

export default DrawerButton;
