import { put, all, select, delay, takeLatest, spawn } from 'redux-saga/effects';
import {
  setIsLoading,
  loadNextPage,
  loadResultsSuccess,
  resetResults,
  setBaseFiltersAndSorting,
  setHasUserContent,
} from './feed.slice';
import * as calls from '../api/bites-api/calls/feed.calls';
import { authSelector } from '../auth/auth.selectors';
import {
  creatorIdsSelector,
  nextPageSelector,
  searchStrSelector,
  sortDirectionSelector,
  sortFieldSelector,
  typesSelector,
} from './feed.selectors';
import { IPlaylist } from '../playlist/playlist.types';
import { IBiteItem } from '../../types/bite';
import { EObjectType } from '../../types/common';
import { fetchFullPlaylistsSaga } from '../playlist/playlist.saga';
import { IFeedObjectType, ILoadNextPage, TGetFeedResponse } from './feed.types';
import { fetchFullBites, setBites, setHasBites } from '../bite/bite.actions';
import { setPlaylists } from '../playlist/playlist.actions';
import { IAction } from '../common/types';
import { logError, trackEvent } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';

const DEFAULT_PAGE_SIZE = 15;

export function* loadNextPageSaga({ payload }: Partial<IAction<ILoadNextPage>>) {
  try {
    if (payload.debounce) {
      yield delay(300);
    }
    if (payload.reset || payload.withBaseFiltersAndSorting) {
      yield put(resetResults());
    }
    if (payload.withBaseFiltersAndSorting) {
      yield put(setBaseFiltersAndSorting());
    }
    yield put(setIsLoading(true));

    const { activeOrganizationId } = yield select(authSelector);
    const nextPage = yield select(nextPageSelector);
    const sortField = yield select(sortFieldSelector);
    const sortDirection = yield select(sortDirectionSelector);
    const searchStr = yield select(searchStrSelector);
    const types = yield select(typesSelector);
    const creatorIds = yield select(creatorIdsSelector);

    yield put(
      trackEvent({
        event: 'search',
        props: { search_term: searchStr, search_category: 'feed' },
      }),
    );
    const {
      data: { total_pages, page, results },
    }: TGetFeedResponse = yield calls.getFeed({
      filters: {
        organization: activeOrganizationId,
        type: types,
        creatorIds: creatorIds || undefined,
        search: searchStr || undefined,
      },
      sort: {
        field: sortField,
        direction: sortDirection,
      },
      pagination: true,
      page: nextPage,
      page_size: DEFAULT_PAGE_SIZE,
    });
    const bites: IBiteItem[] = []; // light objects to set to store
    const playlists: IPlaylist[] = []; // light objects to set to store
    const biteIdsToLoadMap: { [key in number]: boolean } = {}; // bites ids to load (bites & quiz intro bites)
    const playlistIdsToLoad: number[] = []; // playlist ids to load (bites & quiz intro bites)

    results.forEach((item) => {
      if (item.content_type === EObjectType.bite) {
        bites.push(item as IBiteItem);
        biteIdsToLoadMap[item.id] = true;
        return;
      }
      if (item.content_type === EObjectType.quiz) {
        const quiz = item as IPlaylist;
        const biteId = quiz.bite_shares?.find(({ playlist_section }) => playlist_section === 'intro')?.bite;
        if (biteId) {
          biteIdsToLoadMap[biteId] = true;
        }
      }
      playlists.push(item as IPlaylist);
      playlistIdsToLoad.push(item.id);
    });
    const biteIdsToLoad = Object.keys(biteIdsToLoadMap).map((id) => parseInt(id, 10));

    yield put(setBites(bites));
    yield put(setPlaylists(playlists));
    yield put(
      loadResultsSuccess({
        results: results.map(({ content_type, id }) => ({ content_type, id })),
        nextPage: total_pages > page ? page + 1 : null,
      }),
    );
    if (bites.length > 0) {
      yield put(setHasBites(true));
    }

    if (!searchStr && page === 1 && types.length === 1 && types[0] === IFeedObjectType.all && creatorIds === null) {
      yield put(setHasUserContent({ results }));
    }
    yield put(fetchFullBites(biteIdsToLoad));
    yield spawn(fetchFullPlaylistsSaga, { payload: playlistIdsToLoad });
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsLoading(false));

    yield put(
      logError({
        event: 'loadNextPageSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

export default function* appActivitySaga() {
  yield all([takeLatest(loadNextPage, loadNextPageSaga)]);
}
