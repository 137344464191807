const Types = {
  SET_QUESTION_TYPE: 'SET_QUESTION_TYPE',
  SET_QUESTION_CONTENT: 'SET_QUESTION_CONTENT',
  SET_QUESTION_MEDIA: 'SET_QUESTION_MEDIA',
  SET_QUESTION_MEDIA_UPLOADING: 'SET_QUESTION_MEDIA_UPLOADING',
  SET_QUESTION_MEDIA_DONE_UPLOADING: 'SET_QUESTION_MEDIA_DONE_UPLOADING',
  QUESTION_MEDIA_UPLOADING_ERROR: 'QUESTION_MEDIA_UPLOADING_ERROR',
  ADD_ANSWER: 'ADD_ANSWER',
  REMOVE_ANSWER: 'REMOVE_ANSWER',
  RESET_ANSWERS: 'RESET_ANSWERS',
  SET_ANSWER: 'UPDATE_ANSWER',
  SET_CORRECT_ANSWER: 'SET_CORRECT_ANSWER',
  FILTER_EMPTY_CHOICES: 'FILTER_EMPTY_CHOICES',
  SET_CHOICES: 'SET_CHOICES',
  SET_SKIP_QUESTION: 'SET_SKIP_QUESTION',
  SET_CREATED_QUESTION: 'SET_CREATED_QUESTION',
  SET_QUIZ_FIRST_QUESTION: 'SET_QUIZ_FIRST_QUESTION',
  CLEAN_QUESTIONS: 'CLEAN_QUESTIONS',
  SET_IS_QUIZ: 'SET_IS_QUIZ',
  SET_IS_MULTI_CHOICE: 'SET_IS_MULTI_CHOICE',
};

export default Types;
