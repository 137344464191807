import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import FormTextInput from '../../../../components/shared/inputs/FormTextInput/FormTextInput';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuthErrors, setNewPassword, setNewPasswordError } from '../../../../store/auth/auth.actions';
import { setCurrentForm } from '../../../../store/authForm/authForm.slice';
import { recoveryTokenSelector } from '../../../../store/authForm/authForm.selectors';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { v4 as uuid } from 'uuid';
import { isValidPasswordMessage } from '../../../../utils/auth';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { EAuthFormType } from '../../auth.types';
import { isResetPasswordInProgressSelector, resetPasswordErrorSelector } from '../../../../store/auth/auth.selectors';

interface IProps {}

const ResetPassword: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMountedRef = useIsMounted();

  const passwordRequestErrorCode = useSelector(resetPasswordErrorSelector);
  const token = useSelector(recoveryTokenSelector);
  const isLoading = useSelector(isResetPasswordInProgressSelector);

  const [password, setPassword] = useState('');

  const invalidPasswordMessage = isValidPasswordMessage(password);

  const passwordRequestError = useMemo(() => {
    if (!passwordRequestErrorCode) {
      return null;
    }

    switch (passwordRequestErrorCode) {
      case 'auth.reset_pass_expired':
        return t('authForm.forgotPasswordForm.resetPassExpired');
      case 'auth.reset_pass_too_common':
        return t('authForm.forgotPasswordForm.resetPassTooCommon');
      default:
        return t('common.somethingWentWrong');
    }
  }, [passwordRequestErrorCode, t]);

  const handleContinue = useCallback(() => {
    dispatch(log({ event: 'ResetPassword.navigateTo', data: { destination: EAuthFormType.AUTH } }));
    dispatch(setCurrentForm(EAuthFormType.AUTH));
  }, [dispatch]);

  const handleContinueButtonPress = useCallback(() => {
    if (isLoading) {
      return;
    }

    if (invalidPasswordMessage) {
      dispatch(setNewPasswordError(invalidPasswordMessage));
      return;
    }

    const processId = uuid();

    dispatch(
      log({
        event: 'ResetPassword.handleContinue',
        processId,
        data: {
          token,
          password,
        },
      }),
    );

    const callback = () => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(
        log({
          event: 'ResetPassword.setNewPassword.callback',
          processId,
          data: {
            token,
            password,
          },
        }),
      );

      handleContinue();
    };

    dispatch(
      setNewPassword({
        token,
        password,
        callback,
      }),
    );
  }, [invalidPasswordMessage, dispatch, token, password, isMountedRef, handleContinue, isLoading]);

  const handleChangeText = useCallback(
    (text: string) => {
      setPassword(text);
      dispatch(resetAuthErrors());
      dispatch(setNewPasswordError(null));
    },
    [dispatch],
  );

  return (
    <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <Header
        title={t('authForm.forgotPasswordForm.resetPassword')}
        description={t('authForm.forgotPasswordForm.typeNewPassword')}
      />
      <S.Form>
        <S.TextInput
          value={password}
          onChangeText={handleChangeText}
          label={t('authForm.forgotPasswordForm.passwordInputLabel')}
          error={passwordRequestError}
          onEnterPress={handleContinueButtonPress}
          password
        />
        <S.ContinueButton isLoading={isLoading} onPress={handleContinueButtonPress} content={t('common.Continue')} />
      </S.Form>
    </Animated.View>
  );
};

const S = {
  Form: styled.View`
    margin-top: ${calcHeight(32)}px;
    align-items: center;
  `,
  TextInput: styled(FormTextInput)`
    margin-bottom: ${calcHeight(18)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
    margin-bottom: ${calcHeight(50)}px;
  `,
};

export default memo(ResetPassword);
