import store from '../../store';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import redirectTo from '../restoreFlows/restoreBiteFlow';
import { EDeepLinkContentType, EDeepLinkMessageType, EDeepLinkSection } from '../restoreFlows/types';

interface IProps {
  biteId: number;
  section?: EDeepLinkSection;
  isDraft?: boolean;
}

const openEditBite = ({ biteId, section = EDeepLinkSection.INTRO, isDraft = false }: IProps) => {
  store.dispatch(
    trackEvent({
      event: 'edit',
      props: { bite_id: biteId, is_draft: isDraft },
    }),
  );

  redirectTo[EDeepLinkContentType.BITE][EDeepLinkMessageType.EDIT](biteId, section);
};
export default openEditBite;
