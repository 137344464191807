import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayModeSelector,
  initialAttributesMapByIdSelector,
  organizationAttributesSelector,
} from '../../../store/attributes/attributes.selector';
import { log } from '../../../store/appActivity/appActivity.slice';
import { IAttributeMap, IGetAttributesPayload } from '../../../store/attributes/attributes.types';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { isRefreshingSelector } from '../../../store/analytics/analytics.selector';

interface IProps {
  attributes: IAttributeMap;
  onLoad: ActionCreatorWithOptionalPayload<IGetAttributesPayload, string>;
  isRefreshing?: boolean;
  manuallySelectedSectionId?: number;
}

const useAttributesData = ({ attributes, onLoad, manuallySelectedSectionId }: IProps) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const isRefreshing = useSelector(isRefreshingSelector);
  const displayMode = useSelector(displayModeSelector);
  const organizationAttributes = useSelector(organizationAttributesSelector);
  const initialAttributesMapById = useSelector(initialAttributesMapByIdSelector);

  const lastState = useRef({
    isFocused: null,
    selectedSectionId: null,
    displayMode: null,
  });

  const isLeavingFocusedMode = lastState.current.selectedSectionId && !manuallySelectedSectionId;

  const { attributesToRender, singleAttributeId } = useMemo(() => {
    const formattedAttributes = organizationAttributes
      .filter((attr) => !manuallySelectedSectionId || attr.id === manuallySelectedSectionId)
      .map((attr) => ({
        id: attr.id,
        title: attributes[attr.id].data.title,
        data: null,
        isLoading: attributes[attr.id].isLoading,
        isError: !!attributes[attr.id].error,
        next: attributes[attr.id].next,
      }));

    const filteredList = formattedAttributes.filter(
      (attr) =>
        (attributes[attr.id].isLoading && attributes[attr.id].data.values === null) ||
        attributes[attr.id].error ||
        attributes[attr.id].data.values?.length,
    );

    const singleId = filteredList.length === 1 && !isLeavingFocusedMode ? filteredList[0].id : null;

    const formattedAttributesWithData = formattedAttributes.map((attr) => {
      const isDisplayError = attributes[attr.id].error && !attributes[attr.id].data.values?.length;
      const isDisplaySkeleton =
        attributes[attr.id].isLoading && !isRefreshing && !attributes[attr.id].data.values?.length;

      return {
        ...attr,
        isDisplaySkeleton,
        data:
          isDisplaySkeleton || isDisplayError || !attributes[attr.id].data.values
            ? []
            : manuallySelectedSectionId || singleId
            ? attributes[attr.id].data.values
            : attributes[attr.id].data.values.slice(0, 6),
      };
    });

    const result = singleId
      ? [formattedAttributesWithData.find(({ id }) => id === singleId)]
      : formattedAttributesWithData;

    return {
      attributesToRender: result,
      singleAttributeId: singleId,
    };
  }, [organizationAttributes, isLeavingFocusedMode, manuallySelectedSectionId, attributes, isRefreshing]);

  const attributeIdsToLoad = useMemo(() => {
    const isNeedToLoadOnError =
      (!lastState.current.isFocused && isFocused) ||
      isLeavingFocusedMode ||
      lastState.current.displayMode !== displayMode;

    return attributesToRender
      .map(({ id, isLoading, isError }) => {
        return !isLoading &&
          ((isError && isNeedToLoadOnError) || attributes[id].data.values === null) &&
          isFocused &&
          (initialAttributesMapById[id].data.values.length === 1 || isRefreshing)
          ? id
          : null;
      })
      .filter((id) => id)
      .sort(); // sort to compare with previous state
  }, [
    attributes,
    attributesToRender,
    displayMode,
    initialAttributesMapById,
    isFocused,
    isLeavingFocusedMode,
    isRefreshing,
  ]);

  lastState.current = {
    isFocused,
    displayMode,
    selectedSectionId: manuallySelectedSectionId,
  };

  const handleLoad = useCallback(() => {
    dispatch(
      log({
        event: 'useAttributesData.handleLoad',
      }),
    );

    dispatch(onLoad({ attributeIds: attributeIdsToLoad, clearAnalyticsCache: false, reset: true }));
  }, [attributeIdsToLoad, dispatch, onLoad]);

  useEffect(() => {
    if (attributeIdsToLoad.length === 0) {
      return;
    }

    handleLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeIdsToLoad.length]);

  return { attributesToRender, singleAttributeId };
};

export default useAttributesData;
