import { RootState } from '../index';
import { IPanelData } from './bottomPanel';

export const bottomPanelVisibleSelector = (state: RootState) => {
  return state.bottomPanel.isVisible;
};

export const bottomPanelDataSelector = (state: RootState): IPanelData => {
  const { componentName, componentProps } = state.bottomPanel;
  return { componentName, componentProps };
};
