export const photoOptions = {
  mediaType: 'photo' as any,
  compressImageMaxWidth: 800,
};

export const videoOptions = {
  mediaType: 'video' as any,
};

export const galleryOptions = {
  mediaType: 'any' as any,
  compressImageMaxWidth: 800,
};

export const imageGalleryOptions = {
  mediaType: 'photo' as any,
  compressImageMaxWidth: 800,
};
