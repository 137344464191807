import React from 'react';
import styled from 'styled-components/native';
import { calcWidth, calcHeight, calcFontSize } from '../../../../../../utils/dimensions';
import { PanGestureHandler } from 'react-native-gesture-handler';
import { PANEL_HEADER_HEIGHT } from '../Panel';
import VerifiedToggle from './common/VerifiedToggle';

interface IProps {
  onSwipe: ({ nativeEvent }: any) => void;
  title?: string;
  isSubtitlesLoading?: boolean;
}

const PanelHeaderNative: React.FC<IProps> = ({ onSwipe, title, isSubtitlesLoading }) => {
  return (
    <PanGestureHandler onHandlerStateChange={onSwipe}>
      <S.Header>
        <S.Handle />
        <S.Content>
          <S.VerifiedToggleContainer>
            <VerifiedToggle isDisabled={isSubtitlesLoading} />
          </S.VerifiedToggleContainer>
          {title && (
            <S.HeaderMain>
              <S.HeaderText>{title}</S.HeaderText>
            </S.HeaderMain>
          )}
        </S.Content>
      </S.Header>
    </PanGestureHandler>
  );
};

const S = {
  Content: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
  Header: styled.View`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 ${calcWidth(16)}px;
    height: ${calcHeight(PANEL_HEADER_HEIGHT)}px;
  `,
  HeaderMain: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
  HeaderText: styled.Text`
    flex-direction: row;
    font-size: ${calcFontSize(20)}px;
    color: ${({ theme }) => theme.colors.gray9};
  `,
  Handle: styled.View`
    margin-top: ${calcHeight(8)}px;
    width: ${calcWidth(46)}px;
    height: ${calcHeight(4)}px;
    border-radius: ${calcWidth(48)}px;
    background-color: ${({ theme }) => theme.colors.gray7};
  `,
  VerifiedToggleContainer: styled.View`
    position: absolute;
    left: ${calcWidth(2)}px;
    z-index: 3;
  `,
};

export default PanelHeaderNative;
