import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nManager } from 'react-native';
import { isWeb } from '../utils/dimensions';
import RNRestart from 'react-native-restart';

export type TLocale = 'he' | 'en' | 'es' | 'fr' | 'de' | 'pt';

export const isRtlByLang: { [key in TLocale]: boolean } = {
  en: false,
  es: false,
  he: true,
  fr: false,
  de: false,
  pt: false,
};

export const switchDirection = (newLang: TLocale, needToRestart = false) => {
  const isRTL = isRtlByLang[newLang];
  if (!isWeb) {
    if (I18nManager.isRTL !== isRTL) {
      I18nManager.forceRTL(isRTL);
      I18nManager.allowRTL(isRTL);
      RNRestart.restart();
      return;
    }
    if (needToRestart) {
      RNRestart.restart();
    }
    return;
  }
  if (needToRestart) {
    window.location.reload();
  }
};

export const applyTranslations = (translations) => {
  i18n.use(initReactI18next).init({
    resources: translations,
    lng: undefined,
    fallbackLng: 'en',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  });
};

export const getIsWebRtl = () => isWeb && i18n?.dir(i18n.language) === 'rtl';
export const getIsRtl = () => (isWeb ? i18n?.dir(i18n.language) === 'rtl' : I18nManager.isRTL);

export default i18n;
