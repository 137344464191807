import Types from './biteQuestion.types';
import BiteTypes from '../bite/bite.types';
import AuthTypes from '../auth/auth.types';
import { IBiteQuestion, EQuestionType } from '../../types/biteQuestion';
import { filterEmpties } from '../../utils/general';
import { createAnswer } from '../../utils/question';
import { IMedia } from '../../types/media';
import { cloneDeep, isEmpty } from 'lodash';

interface IInitialState extends IBiteQuestion {
  mediaUploading: boolean;
  mediaUploadingError: boolean;
  skipped: boolean;
  isQuizFirstQuestion: boolean;
  isPreview: boolean;
  isCreated: boolean;
  isEdit: boolean;
  isQuiz: boolean;
  isMultiChoice: boolean;
}

export const initialState: IInitialState = {
  type: EQuestionType.OPEN_ENDED,
  text: '',
  media: null as IMedia | null,
  mediaUploading: false,
  mediaUploadingError: false,
  answers: [createAnswer(), createAnswer()],
  skipped: false,
  isQuizFirstQuestion: false,
  isPreview: false,
  isCreated: false,
  isEdit: false,
  isQuiz: false,
  isMultiChoice: false,
};

export type BiteQuestionState = typeof initialState;

const biteQuestionReducer = (state = initialState, action: any): BiteQuestionState => {
  switch (action.type) {
    case Types.SET_QUESTION_TYPE:
      const type: EQuestionType = action.payload;
      return {
        ...state,
        type,
        isEdit: true,
      };
    case Types.SET_QUESTION_CONTENT:
      const text = action.payload;
      return {
        ...state,
        text,
      };
    case Types.SET_QUESTION_MEDIA:
      const media = action.payload;
      return {
        ...state,
        media,
        mediaUploading: false,
      };

    case Types.SET_QUESTION_MEDIA_UPLOADING:
      return {
        ...state,
        mediaUploading: true,
        mediaUploadingError: false,
      };

    case Types.SET_QUESTION_MEDIA_DONE_UPLOADING:
      return {
        ...state,
        mediaUploading: false,
        mediaUploadingError: false,
      };

    case Types.QUESTION_MEDIA_UPLOADING_ERROR:
      return {
        ...state,
        mediaUploading: false,
        mediaUploadingError: true,
      };

    case Types.ADD_ANSWER:
      const answer = createAnswer();
      return {
        ...state,
        answers: [...state.answers, answer],
      };
    case Types.REMOVE_ANSWER:
      const answerId = action.payload;
      return {
        ...state,
        answers: state.answers.filter((item) => item.id !== answerId),
      };
    case Types.RESET_ANSWERS:
      return {
        ...state,
        answers: cloneDeep(initialState.answers),
      };
    case Types.SET_ANSWER:
      const stateCopy: BiteQuestionState = JSON.parse(JSON.stringify(state));
      const answerIdx = state.answers.findIndex((_answer) => _answer.id === action.payload.id);
      stateCopy.answers[answerIdx] = action.payload;
      return stateCopy;
    case Types.SET_CORRECT_ANSWER:
      return {
        ...state,
        answers: state.answers.map((_answer) => {
          _answer.is_correct = action.payload === _answer.id;
          return _answer;
        }),
      };
    case Types.FILTER_EMPTY_CHOICES: {
      return {
        ...state,
        answers: filterEmpties(state.answers, 'text'),
      };
    }
    case Types.SET_CHOICES: {
      return {
        ...state,
        answers: [...action.payload],
      };
    }
    case BiteTypes.SET_BITE_TO_EDIT: {
      const { questionState } = action.payload;
      const question = isEmpty(questionState) ? initialState : questionState;
      return cloneDeep(question);
    }

    case Types.SET_SKIP_QUESTION:
      return {
        ...state,
        skipped: action.payload,
      };

    case Types.SET_CREATED_QUESTION:
      return {
        ...state,
        isCreated: action.payload,
      };

    case Types.SET_IS_MULTI_CHOICE:
      return {
        ...state,
        isMultiChoice: action.payload,
      };

    case Types.SET_QUIZ_FIRST_QUESTION:
      return {
        ...state,
        isQuizFirstQuestion: action.payload,
      };

    case Types.SET_IS_QUIZ:
      return {
        ...state,
        isQuiz: action.payload,
      };

    case Types.CLEAN_QUESTIONS:
      return { ...cloneDeep(initialState), isQuiz: state.isQuiz };

    case BiteTypes.CLEAN_EDIT_AND_CREATE_BITE_STATE:
      return cloneDeep(initialState);

    case AuthTypes.LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
};

export default biteQuestionReducer;
