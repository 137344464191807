import { EShareMode } from '../../utils/constants/bite';
import Types, { IPlaylistSettings } from './createPlaylist.types';
import { ISelectedBite } from './createPlaylist.types';
import { IPlaylist } from '../playlist/playlist.types';

export const setPlaylistSubject = (playlistSubject: string) => ({
  type: Types.SET_PLAYLIST_NAME,
  payload: playlistSubject,
});

export const setPlaylistDescription = (playlistAbout: string) => ({
  type: Types.SET_PLAYLIST_ABOUT,
  payload: playlistAbout,
});

export const selectBite = (bite: ISelectedBite) => ({
  type: Types.SELECT_BITE,
  payload: bite,
});

export const updateSelectedBites = (bites: ISelectedBite[]) => ({
  type: Types.UPDATE_SELECTED_BITES,
  payload: bites,
});

export const removeSelectedBite = (biteId: number) => ({
  type: Types.REMOVE_SELECTED_BITE,
  payload: biteId,
});

export const cleanSelectedToRemoveBites = () => ({
  type: Types.CLEAN_SELECTED_TO_REMOVE_BITES,
});

export const toggleSelectedToRemoveBite = (biteId: number) => ({
  type: Types.TOGGLE_SELECTED_TO_REMOVE_BITE,
  payload: biteId,
});

export const removeSelectedBites = () => ({
  type: Types.REMOVE_SELECTED_BITES,
});

export const updatePlaylistSettings = (settingKey: keyof IPlaylistSettings) => ({
  type: Types.UPDATE_PLAYLIST_SETTING,
  payload: settingKey,
});

export const postPlaylist = () => ({
  type: Types.POST_PLAYLIST_REQUEST,
});

export const postAndUpdatePlaylistSuccess = () => ({
  type: Types.POST_AND_UPDATE_PLAYLIST_SUCCESS,
});

export const postAndUpdatePlaylistError = () => ({
  type: Types.POST_AND_UPDATE_PLAYLIST_ERROR,
});

export const setPlaylistToEdit = (payload: { playlist: IPlaylist; editMode?: boolean }) => ({
  type: Types.SET_PLAYLIST_TO_EDIT,
  payload,
});

export const updateEditedPlaylist = (payload: Partial<IPlaylist>) => ({
  type: Types.UPDATE_EDITED_PLAYLIST,
  payload,
});

export const updateEditedPlaylistShareMode = (shareMode: EShareMode) => ({
  type: Types.UPDATE_EDITED_PLAYLIST_SHARE_MODE,
  payload: { shareMode },
});

export const updatePlaylist = () => ({
  type: Types.UPDATE_PLAYLIST_REQUEST,
});

export const resetCreatePlaylistState = () => ({
  type: Types.RESET_CREATE_PLAYLIST_STATE,
});

export const resetPlaylistAsQuiz = () => ({
  type: Types.RESET_PLAYLIST_AS_QUIZ,
});

export const setLinkedCoverURL = (payload: string) => ({
  type: Types.SET_LINKED_COVER_URL,
  payload,
});
