import BitesApi from '../index';
import { IPlaylistPostOrUpdateBody } from '../../../createPlaylist/createPlaylist.types';
import { IPostQuizSettings } from '../../../../store/playlist/playlist.types';

export const fetchPlaylist = (id: number) => BitesApi.get(`/playlists/${id}/`);

export const fetchPlaylistsBySearchQuery = (orgId: number, query: string) =>
  BitesApi.get(`/playlists/?organization=${orgId}&search=${query}`);

export const postOrUpdatePlaylist = (playlistPostBody: IPlaylistPostOrUpdateBody) =>
  BitesApi.post('playlists/', playlistPostBody);

export const updatePlaylist = (playListId: number, playlistPostBody: Partial<IPlaylistPostOrUpdateBody>) =>
  BitesApi.patch(`playlists/${playListId}/`, playlistPostBody);

export const deletePlaylist = (playlistId: number) => BitesApi.delete(`/playlists/${playlistId}/`);

export const fetchQuizSettings = (id: number) => BitesApi.get(`/playlists/${id}/settings/`);

export const postQuizSettings = (body: IPostQuizSettings) => {
  BitesApi.post(`playlists/${body.id}/settings/`, body.quizSettings);
};

interface ICloneResponse {
  message: string;
  original_id: number;
  clone_id: number;
}

interface IClonePlaylistProps {
  playlist: number;
  organization: number;
  owner: number;
}

export const clonePlaylist = (body: IClonePlaylistProps) => BitesApi.post<ICloneResponse>('/clone_playlist/', body);

interface ICloneQuizProps {
  quiz: number;
  organization: number;
  owner: number;
}

export const cloneQuiz = (body: ICloneQuizProps) => BitesApi.post<ICloneResponse>('/clone_quiz/', body);

export const transactionalPlaylistCreated = ({ playlistId }: { playlistId: number }) =>
  BitesApi.post('/common_services/transactional/playlist/created', { playlistId });

export const transactionalQuizCreated = ({ quizId }: { quizId: number }) =>
  BitesApi.post('/common_services/transactional/quiz/created', { quizId });
