import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import Modal from '../../modals/ModalController';
import { calcHeight, calcWidth, isWeb, WEB_CONTAINER_WIDTH } from '../../../utils/dimensions';
import { TVideoModalButtonStyle } from '../../../store/appModals/appModals.types';

const ANIMATION_TIMING = 700;

type Props = {
  isInline?: boolean;
  isVisible: boolean;
  btnLeftLabel: string;
  btnRightLabel: string;
  btnLeftStyle?: TVideoModalButtonStyle;
  btnRightStyle?: TVideoModalButtonStyle;
  onBtnLeftClick?: () => void;
  onBtnRightClick?: () => void;
  onBackdropPress?: () => void;
  onModalHide?: () => void;
  title?: string;
  url: string;
};
const AudioModal: React.FC<Props> = ({
  isInline,
  isVisible,
  btnLeftLabel,
  btnRightLabel,
  btnLeftStyle = 'transparent',
  btnRightStyle = 'transparent',
  onBtnLeftClick,
  onBtnRightClick,
  onBackdropPress,
  onModalHide,
  title = '',
  url,
}) => {
  const renderContent = useCallback(() => {
    return (
      <S.Container>
        {!!title && <S.Title>{title}</S.Title>}
        <S.MediaContainer>
          <audio controls>
            <source src={url} />
          </audio>
        </S.MediaContainer>
        <S.ButtonContainer>
          {typeof onBtnLeftClick === 'function' ? (
            <S.Button type={btnLeftStyle} onPress={onBtnLeftClick}>
              <S.ButtonText type={btnLeftStyle}>{btnLeftLabel}</S.ButtonText>
            </S.Button>
          ) : (
            <S.Empty />
          )}
          {typeof onBtnRightClick === 'function' ? (
            <S.Button type={btnRightStyle} onPress={onBtnRightClick}>
              <S.ButtonText type={btnRightStyle}>{btnRightLabel}</S.ButtonText>
            </S.Button>
          ) : (
            <S.Empty />
          )}
        </S.ButtonContainer>
      </S.Container>
    );
  }, [btnLeftLabel, btnLeftStyle, btnRightLabel, btnRightStyle, onBtnLeftClick, onBtnRightClick, title, url]);

  if (isInline) {
    return renderContent();
  }

  return (
    <S.Modal
      isVisible={isVisible}
      backdropTransitionOutTiming={0}
      backdropOpacity={0.7}
      animationInTiming={ANIMATION_TIMING}
      animationOutTiming={ANIMATION_TIMING}
      onBackdropPress={onBackdropPress}
      onModalHide={onModalHide}
    >
      {renderContent()}
    </S.Modal>
  );
};

const S = {
  Modal: styled(Modal)`
    margin: 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  `,
  Container: styled.View`
    max-width: ${WEB_CONTAINER_WIDTH}px;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: ${calcHeight(16)}px;
  `,
  MediaContainer: styled.View`
    ${({ width, height }) => `
		max-width: ${width};
		min-width: ${width};
		max-height: ${height};
		min-height: ${height};
	`}
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    overflow: hidden;
  `,
  ButtonContainer: styled.View`
    width: 100%;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    bottom: ${isWeb ? calcHeight(16) : calcHeight(42)}px;
    padding: 0 ${calcHeight(16)}px;
    align-items: center;
  `,
  Button: styled.TouchableOpacity<{ type: TVideoModalButtonStyle }>`
    align-items: center;
    justify-content: center;
    padding: ${calcHeight(8)}px ${calcWidth(16)}px;
    border-radius: 32px;

    ${({ theme, type }) => {
      switch (type) {
        case 'primary':
          return `
            background-color: ${theme.colors.primaryBlue};
            border: 1px ${theme.colors.primaryBlue} solid;
          `;
        case 'secondary':
          return `
            background-color: ${theme.colors.gray16};
            border: 1px ${theme.colors.gray16} solid;
          `;
        case 'transparent':
          return `
            background-color: ${theme.colors.transparentBlack};
            border: 1px ${theme.colors.white} solid;
          `;
      }
    }}
  `,
  ButtonText: styled.Text<{ type: TVideoModalButtonStyle }>`
    color: ${({ theme, type }) => (type === 'secondary' ? theme.colors.text : theme.colors.white)};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
  `,
  Title: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s20}px;
    font-weight: 600;
    top: -${calcHeight(16)}px;
    margin-bottom: ${calcHeight(16)}px;
  `,
  Empty: styled.View``,
};

export default AudioModal;
