import { removeFrontendIds } from './frontendId';
import { IBiteQuestion, EQuestionType } from '../types/biteQuestion';
import { ISummaryCard } from '../types/biteSummary';
import { normalizeOrder } from './general';
import { ISelectedBite, IPlaylistPostOrUpdateBiteShare } from '../store/createPlaylist/createPlaylist.types';
import { IBiteShare } from '../types/biteShare';
import { IExtraSettings } from '../store/playlist/playlist.types';

export const formatQuestionToServer = (question: IBiteQuestion) => {
  const { answers, ...restQuestion } = question;
  return {
    ...restQuestion,
    choices: question.type === EQuestionType.OPEN_ENDED ? [] : normalizeOrder(removeFrontendIds(answers)),
  };
};

export const formatSummaryCardsToServer = (summaryCards: ISummaryCard[]) => removeFrontendIds(summaryCards);

export const formatUpdatedPlaylistBiteShares = (selectedBites: ISelectedBite[], prevBiteShares: IBiteShare[]) => {
  return selectedBites.map((selectedBite, index) => {
    const updatedBiteShare: IPlaylistPostOrUpdateBiteShare = {
      bite: selectedBite.id,
      order: index + 1,
      playlist_section: selectedBite.playlist_section || 'body',
    };

    const existedBiteShareInsidePlaylist = prevBiteShares.find(
      (prevBiteShare) => prevBiteShare.bite === selectedBite.id,
    );
    if (existedBiteShareInsidePlaylist) {
      updatedBiteShare.id = existedBiteShareInsidePlaylist.id;
    }
    return updatedBiteShare;
  });
};

export const formatQuizSettings = (settings: IExtraSettings, hasBackground: boolean) => {
  return {
    correct_answer_indication: settings.correct_answer_indication,
    show_report: settings.show_report,
    background_color: settings.background_color,
    background_url: settings.background_url,
    question_color: settings.question_color,
    background: (hasBackground && Number(settings.background)) || null,
  };
};
