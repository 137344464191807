import * as msal from '@azure/msal-browser';

import oAuthClientKeys from '../../../utils/constants/oauthConfig';
import { loginOrRegisterWithSocialToken, loginOrRegisterWithSocialTokenError } from '../../../store/auth/auth.actions';
import store from '../../../store';
import setSsoInProgressService from '../../../services/setSsoInProgress';
import { APP_TYPE, MS_PERSONAL_TENANT_ID } from '../../../utils/constants/config';
import { logError } from '../../../store/appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../utils/constants/toastConfig';

const msalConfig = {
  auth: {
    clientId: oAuthClientKeys.azureClientId,
    redirectUri: window.location.origin,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

// [AdGo] 20.10.20 DO NOT MOVE THIS
// register for CB after being redirected back from MS auth process
// this only works OUTSIDE the hook itself. that's why the store is
// imported directly here instead of using useDispatch
msalInstance
  .handleRedirectPromise()
  .then((response) => {
    if (!response) {
      setSsoInProgressService(false);
      return;
    }
    const token = response.tenantId === MS_PERSONAL_TENANT_ID ? response.idToken : response.accessToken;

    setSsoInProgressService(true);

    store.dispatch(
      loginOrRegisterWithSocialToken({
        app: APP_TYPE,
        token: token,
        backend: 'azuread-oauth2',
        creator: true,
        needToAddOrgByTenant: true,
        tenant_id: response.tenantId,
        email: response.account.username,
      }),
    );
  })
  .catch((error) => {
    store.dispatch(loginOrRegisterWithSocialTokenError());
    setSsoInProgressService(false);
    store.dispatch(logError({ error }));
  });

const useMicrosoftLogin = () => {
  return async function useMicrosoftLoginFunction() {
    try {
      setSsoInProgressService(true);
      // we use redirect because login popup doesn't work on mobile, submitted a bug
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3118
      msalInstance.loginRedirect({ scopes: [] });
    } catch (error) {
      store.dispatch(loginOrRegisterWithSocialTokenError());
      setSsoInProgressService(false);

      store.dispatch(
        logError({
          event: 'useMicrosoftLogin: error',
          error,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  };
};

export default useMicrosoftLogin;
