import RNFetchBlob, { FetchBlobResponse, StatefulPromise } from 'rn-fetch-blob';
import shortid from 'shortid';
import { VIDEO_EDITOR_CACHE_DIR } from './constants/env';
import store from '../store';
import { logError } from '../store/appActivity/appActivity.slice';

interface IOptions {
  localPath?: string;
  withClearCache?: boolean;
}
const getLocalUrlPath = async (
  url: string,
  options: IOptions = {},
): Promise<{ request: StatefulPromise<FetchBlobResponse>; promise: Promise<string> }> => {
  try {
    const { localPath: localPathProp, withClearCache = true } = options;

    const localPath = localPathProp || VIDEO_EDITOR_CACHE_DIR;

    if (withClearCache) {
      const cacheExists = await RNFetchBlob.fs.exists(localPath);

      if (cacheExists) {
        await RNFetchBlob.fs.unlink(localPath);
      }
    }

    const request = RNFetchBlob.fetch('GET', url);

    const promise = request.then(async (response) => {
      const contentType = response?.info()?.headers?.['content-type'] || 'video/mp4';
      const path = `${localPath}/${shortid.generate()}.${contentType.split('/')[1]}`;

      await RNFetchBlob.fs.writeFile(path, response.data, 'base64');

      return `file://${path}`;
    });

    return { request, promise };
  } catch (error) {
    store.dispatch(
      logError({
        event: 'getLocalUrlPath: error',
        error,
      }),
    );
  }
};

export default getLocalUrlPath;
