import React, { useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';

export const StockFilterControls = ({ query, orientation, setOrientation }) => {
  const handleClickPortrait = useCallback(() => {
    setOrientation('portrait');
  }, [setOrientation]);

  const handleClickLandscape = useCallback(() => {
    setOrientation('landscape');
  }, [setOrientation]);

  const handleClickSquare = useCallback(() => {
    setOrientation('square');
  }, [setOrientation]);

  if (!query) {
    return null;
  }

  return (
    <S.Container>
      <S.Button isSelected={orientation === 'portrait'} onClick={handleClickPortrait}>
        <S.Portrait isSelected={orientation === 'portrait'} />
      </S.Button>
      <S.Button isSelected={orientation === 'landscape'} onClick={handleClickLandscape}>
        <S.Landscape isSelected={orientation === 'landscape'} />
      </S.Button>
      <S.Button isSelected={orientation === 'square'} onClick={handleClickSquare}>
        <S.Square isSelected={orientation === 'square'} />
      </S.Button>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    gap: 8px;
    margin: 0px 12px 8px;
    height: 30px;
  `,
  Button: styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    border-radius: 8px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  Portrait: styled.div<{ isSelected: boolean }>`
    width: 12px;
    height: 22px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
  Landscape: styled.div<{ isSelected: boolean }>`
    width: 22px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
  Square: styled.div<{ isSelected: boolean }>`
    width: 22px;
    height: 22px;
    border-radius: 2px;
    border: 1px solid ${defaultTheme.colors.gray19};
    border-color: ${({ isSelected }) =>
      isSelected ? defaultTheme.colors.backgroundPurple : defaultTheme.colors.gray19};

    &:hover {
      border-color: ${defaultTheme.colors.backgroundPurple};
    }
  `,
};
