import React, { memo, useEffect, useState } from 'react';
import StatsOverview from './common/StatsOverview';
import { useCallback } from 'react';
import BiteOverview from './common/BiteOverview';
import { getStatsOverview } from '../../store/analyticsOverview/analyticsOverview.slice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { bitesLoadingStateMapSelector } from '../../store/bite/bite.selectors';
import { RefreshControl, ViewStyle } from 'react-native';
import { fetchFullBites } from '../../store/bite/bite.actions';
import { StyledProps } from 'styled-components';
import { resetAnalytics } from '../../store/analytics/analytics.slice';
import BiteScreenHeader from '../analytics/common/BiteScreenHeader';
import Error from './common/Error';
import { biteIdSelector } from '../../store/analytics/analytics.selector';
import { log, trackEvent } from '../../store/appActivity/appActivity.slice';
import RestrictedDataBanner from './common/RestrictedDataBanner';
import Routes from '../../navigation/routes';

const BiteScreen = () => {
  const dispatch = useDispatch();
  const biteId = useSelector(biteIdSelector);
  const biteLoadingState = useSelector(bitesLoadingStateMapSelector(biteId));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isError = !biteLoadingState.isLoading && biteLoadingState?.error;

  const handleRefresh = useCallback(() => {
    dispatch(log({ event: 'BiteScreen.handleRefresh', data: { biteId } }));
    setIsRefreshing(true);
    dispatch(resetAnalytics({ excludeFields: ['biteId', 'hasDistributions', 'isFilterTooltipShown'] }));
    dispatch(fetchFullBites([biteId]));
    dispatch(getStatsOverview());
  }, [biteId, dispatch]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(log({ event: 'BiteScreen.handleErrorRefresh', data: { biteId } }));
    dispatch(fetchFullBites([biteId]));
    dispatch(getStatsOverview());
  }, [biteId, dispatch]);

  useEffect(() => {
    if (isRefreshing && !biteLoadingState.isLoading) {
      setIsRefreshing(false);
    }
  }, [biteLoadingState?.isLoading, isRefreshing]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: Routes.AnalyticsStack.BiteOverview },
      }),
    );

    return () => {
      dispatch(log({ event: 'BiteScreen.exit', data: { biteId } }));
    };
  }, [biteId, dispatch]);

  return (
    <S.Container
      contentContainerStyle={S.ContentContainerStyles}
      refreshControl={<S.RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} />}
    >
      <BiteScreenHeader />
      {isError ? (
        <Error onRefresh={handleErrorRefresh} />
      ) : (
        <>
          <BiteOverview isRefreshing={isRefreshing} />
          <StatsOverview isRefreshing={isRefreshing} />
          <RestrictedDataBanner />
        </>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView``,
  ContentContainerStyles: { alignItems: 'center' } as StyledProps<ViewStyle>,
  RefreshControl: styled(RefreshControl)``,
};

export default memo(BiteScreen);
