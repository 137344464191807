import Types, { IExtraSettings, IInitialState } from './playlist.types';
import AuthTypes from '../auth/auth.types';

const initState: IInitialState = {
  isFetching: false,
  playlistsMap: {},
  quizSettings: null as IExtraSettings | null,
  hasImageBackground: false,
  playlistsInProgress: {},
};

const playlistReducer = (state = initState, action: any): typeof initState => {
  switch (action.type) {
    case Types.CLEAR_PLAYLISTS:
      return {
        ...state,
        playlistsMap: {},
      };

    case Types.SET_PLAYLISTS:
      return (() => {
        const newPlaylistsMap = {
          ...state.playlistsMap,
        };
        action.payload.forEach((playlist) => {
          newPlaylistsMap[playlist.id] = { ...(newPlaylistsMap[playlist.id] || {}), ...playlist };
        });
        return {
          ...state,
          playlistsMap: newPlaylistsMap,
        };
      })();

    case Types.SET_PLAYLIST_IN_PROGRESS:
      return (() => {
        const { itemId: playlistId, task, status, taskId, errorCodes } = action.payload;

        const playlistInProgress = {
          ...(state.playlistsInProgress[playlistId] || {}),
          [task]: {
            taskId,
            status,
            errorCodes,
          },
        };

        if (!status) {
          delete playlistInProgress[task];

          if (Object.keys(playlistInProgress).length === 0) {
            const playlistsInProgress = { ...state.playlistsInProgress };
            delete playlistsInProgress[playlistId];
            return {
              ...state,
              playlistsInProgress,
            };
          }
        }

        return {
          ...state,
          playlistsInProgress: {
            ...state.playlistsInProgress,
            [playlistId]: playlistInProgress,
          },
        };
      })();

    case Types.DELETE_PLAYLIST:
      return (() => {
        const newPlaylistsMap = { ...state.playlistsMap };
        delete newPlaylistsMap[action.payload];
        return {
          ...state,
          playlistsMap: newPlaylistsMap,
        };
      })();

    case Types.FETCH_QUIZ_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case Types.SET_QUIZ_SETTINGS:
      return {
        ...state,
        isFetching: false,
        quizSettings: action.payload[0] || null,
      };
    case Types.SET_QUIZ_IMAGE_BACKGROUND:
      const backgroundImage = action.payload?.id.toString();
      return {
        ...state,
        quizSettings: { ...state.quizSettings, background: backgroundImage },
      };
    case Types.SET_HAS_QUIZ_IMAGE_BACKGROUND:
      return {
        ...state,
        hasImageBackground: action.payload,
      };

    case Types.RESET_QUIZ_SETTINGS:
      return {
        ...state,
        isFetching: false,
        quizSettings: null,
      };

    case Types.UPDATE_QUIZ_BOOLEAN_SETTING:
      let updateBooleanSettings = { ...state.quizSettings };
      updateBooleanSettings[action.payload] = !updateBooleanSettings[action.payload];
      return {
        ...state,
        quizSettings: updateBooleanSettings,
      };

    case Types.UPDATE_QUIZ_COLOR_SETTING:
      let updateColorSettings = { ...state.quizSettings };
      updateColorSettings[action.payload?.settingKey] = action.payload.color;
      return {
        ...state,
        quizSettings: updateColorSettings,
      };

    case Types.POST_QUIZ_SETTINGS:
      return {
        ...state,
      };

    case Types.POST_QUIZ_IMAGE_BACKGROUND:
      return {
        ...state,
      };

    case AuthTypes.SWITCH_ACTIVE_ORGANIZATION:
      return initState;

    case AuthTypes.LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default playlistReducer;
