import { useSelector } from 'react-redux';
import { featureFlagsSelector } from '../store/appActivity/appActivity.selectors';
import { activeOrganizationSelector, userSelector } from '../store/auth/auth.selectors';
import { getIsFeatureEnabled } from '../utils/featureFlags';

const useFeatureFlag = (feature: string) => {
  const user = useSelector(userSelector);
  const org = useSelector(activeOrganizationSelector);
  const featureFlags = useSelector(featureFlagsSelector);

  const userId = user?.id;
  const email = user?.email;
  const orgId = org?.id;

  const isEnabled = getIsFeatureEnabled({ feature, featureFlags, userId, email, orgId });
  return isEnabled;
};

export default useFeatureFlag;
