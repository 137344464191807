export const getIntercomUserProps = (props) => {
  const filteredProps = Object.entries(props).reduce((map, [key, value]) => {
    if (intercomUserProps[key]) {
      map[key] = value;
    }
    return map;
  }, {});
  return filteredProps;
};
export const intercomUserProps = {
  language: true,
};
