export interface IElementsPanelData {
  videoUrl: string;
  action: EAction;
  biteId?: number;
}

export interface IElementsPanelItem {
  image: string;
  label: string;
  data: IElementsPanelData;
}

export interface IElementsPanelContent {
  id: string;
  title: string;
  items: IElementsPanelItem[];
}

export interface IElementsPanelConfig {
  title: string;
  isActive: boolean;
  sections: IElementsPanelContent[];
}

export enum EAction {
  TEMPLATE = 'template',
  VIDEO = 'video',
}
