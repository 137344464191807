import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { calcFontSize, calcHeight, deviceWidth } from '../../../../utils/dimensions';
import { ScrollView } from 'react-native-gesture-handler';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import { sortDirectionSelector, sortFieldSelector } from '../../../../store/feed/feed.selectors';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { loadNextPage, setSortDirection, setSortField } from '../../../../store/feed/feed.slice';
import { sortOptions } from '../../../../store/homeScreen/homeScreen.constants';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { feedControlsTypeOptionsDataSet } from './component.constants';

const shadowOffset: ShadowProps['offset'] = [0, 0];

interface IProps {}

const FeedSortingPanel: React.FC<IProps> = ({}) => {
  return (
    <ShadowedContainer offset={shadowOffset}>
      <S.Container>
        <ScrollView>
          {sortOptions.map((sortOption) => (
            <SortOption
              // @ts-ignore
              dataSet={feedControlsTypeOptionsDataSet}
              sortOption={sortOption}
              key={sortOption.label}
            />
          ))}
        </ScrollView>
      </S.Container>
    </ShadowedContainer>
  );
};

const SortOption = ({ sortOption }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sortField = useSelector(sortFieldSelector);
  const sortDirection = useSelector(sortDirectionSelector);

  const isActive = sortOption.field === sortField && sortOption.direction === sortDirection;

  const handleSetSorting = useCallback(() => {
    dispatch(togglePanel(null));
    if (isActive) {
      return;
    }
    dispatch(
      trackEvent({
        event: 'set_feed_sorting',
        props: { from_value: `${sortField}_${sortDirection}`, to_value: `${sortOption.field}_${sortOption.direction}` },
      }),
    );
    dispatch(setSortField(sortOption.field));
    dispatch(setSortDirection(sortOption.direction));
    dispatch(loadNextPage({ reset: true }));
  }, [dispatch, isActive, sortField, sortDirection, sortOption.field, sortOption.direction]);

  return (
    <S.ValueContainer onPress={handleSetSorting}>
      <S.ValueText isActive={isActive}>{t(`filterPannel.${sortOption.label}`)}</S.ValueText>
    </S.ValueContainer>
  );
};

const S = {
  Container: styled.View`
    padding-bottom: ${calcHeight(70)}px;
    width: ${deviceWidth}px;
    height: ${calcHeight(300)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    height: ${calcHeight(40)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueText: styled.Text<{ isActive: boolean }>`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ isActive }) => calcFontSize(isActive ? 20 : 14)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
};

export default FeedSortingPanel;
