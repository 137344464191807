import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components/native';
import Modal from '../../../../components/modals/ModalController';
import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../../../utils/dimensions';
import SearchOnline from '../../../createBite/PostYourBite/SelectCover/SearchOnline/SearchOnline';
import { useDispatch, useSelector } from 'react-redux';
import { biteSelector } from '../../../../store/bite/bite.selectors';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { css } from 'styled-components';
import CircleButton, { EButtonIcon } from '../../../../components/shared/buttons/CircleButton';
import { StyleProp, ViewStyle } from 'react-native';
import PreviewModal from './PreviewModal';
import { useIsMounted } from '../../../../hooks/useIsMounted';
import { coverFromMediaDataSet } from './mediaModal.constants';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onSelectStockImage?: (imgUrl: string) => void;
  onSelectGallery: (handlePreviewModal) => void;
  onSelectCamera: (handlePreviewModalRef) => void;
  from?: string;
  defaultSearchValue?: string;
}
const shadowedContainerStyle = { marginLeft: 22 };
const MediaModal: React.FC<IProps> = ({
  isVisible,
  onClose,
  onSelectStockImage,
  onSelectGallery,
  onSelectCamera,
  from,
  defaultSearchValue,
}) => {
  const dispatch = useDispatch();
  const { selectedBite } = useSelector(biteSelector);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewSource, setPreviewSource] = useState<string>(null);
  const uploadMediaRef = useRef(null);
  const isMountedRef = useIsMounted();
  const [pressedMediaOption, setPressedMediaOption] = useState<EButtonIcon>(EButtonIcon.SERACH_ONLINE);

  const openSearchOnlineModalVisible = useCallback(() => {
    setPressedMediaOption(EButtonIcon.SERACH_ONLINE);
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'stock_images', bite_id: selectedBite?.id, from },
      }),
    );
  }, [dispatch, selectedBite?.id, from]);

  const handleShowPreviewModal = useCallback(
    (imageUrl, callback) => {
      if (!isMountedRef.current) {
        return;
      }
      setIsPreviewOpen(true);
      setPreviewSource(imageUrl);
      uploadMediaRef.current = callback;
      setPressedMediaOption(EButtonIcon.SERACH_ONLINE);
    },
    [isMountedRef],
  );

  const handleLaunchGallery = useCallback(() => {
    onSelectGallery(handleShowPreviewModal);
  }, [handleShowPreviewModal, onSelectGallery]);

  const handleLaunchCamera = useCallback(() => {
    onSelectCamera(handleShowPreviewModal);
  }, [handleShowPreviewModal, onSelectCamera]);

  const handleMediaSelection = useCallback(() => {
    if (typeof uploadMediaRef.current === 'function') {
      uploadMediaRef.current();
    } else {
      onSelectStockImage(previewSource);
    }
    setIsPreviewOpen(false);
    onClose();
  }, [onClose, onSelectStockImage, previewSource]);

  const handleOnClose = useCallback(() => {
    setIsPreviewOpen(false);
  }, []);

  return (
    <>
      <S.Modal isVisible={isVisible} statusBarTranslucent animationOut={'fadeOut'} animationIn={'fadeIn'}>
        <S.Container>
          <S.TopBar>
            <CircleButton style={S.CloseBtnStyle} onPress={onClose} icon={EButtonIcon.CLOSE} />
            <S.OptionsButtons>
              {onSelectStockImage && (
                <CircleButton
                  style={shadowedContainerStyle}
                  onPress={openSearchOnlineModalVisible}
                  icon={EButtonIcon.SERACH_ONLINE}
                  isPressed={pressedMediaOption === EButtonIcon.SERACH_ONLINE}
                />
              )}

              <CircleButton
                style={shadowedContainerStyle}
                onPress={handleLaunchGallery}
                icon={EButtonIcon.GALLERY}
                isPressed={pressedMediaOption === EButtonIcon.GALLERY}
                dataSet={coverFromMediaDataSet}
              />
              {!isWeb && (
                <CircleButton
                  style={shadowedContainerStyle}
                  onPress={handleLaunchCamera}
                  icon={EButtonIcon.CAMERA}
                  isPressed={pressedMediaOption === EButtonIcon.CAMERA}
                />
              )}
            </S.OptionsButtons>
          </S.TopBar>
          <SearchOnline
            onSelect={handleShowPreviewModal}
            panelWidth={calcWidth(325)}
            defaultValue={defaultSearchValue}
          />
        </S.Container>
      </S.Modal>
      <PreviewModal
        isVisible={isPreviewOpen}
        onClose={handleOnClose}
        onChoose={handleMediaSelection}
        source={previewSource}
      />
    </>
  );
};

const S = {
  Modal: styled(Modal)`
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-end;
    ${() => css`
      ${isWeb ? `top: ${calcHeight(60)}px;` : ''}
      margin-right: ${calcWidth(30)}px;
      margin-left: ${calcWidth(30)}px;
    `}
  `,
  OptionsButtons: styled.View`
    flex-direction: row;
  `,
  TopBar: styled.View`
    flex-direction: row;
    top: ${calcWidth(15)}px;
    margin-bottom: ${calcWidth(28)}px;
    justify-content: space-between;
  `,
  Container: styled.View`
    align-self: center;
    width: ${deviceWidth}px;
    height: ${isWeb ? `${deviceHeight - calcHeight(120)}px;` : `${deviceHeight}px;`}
    ${!isWeb ? `padding-top: ${calcHeight(40)}px;` : ''}
    justify-content: space-between;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    padding-bottom: 10px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  CloseBtnStyle: {
    position: 'absolute',
    right: calcWidth(13),
    top: 0,
  } as StyleProp<ViewStyle>,
};

export default MediaModal;
