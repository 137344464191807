import React, { useEffect } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { StatusBar } from 'react-native';
import { useTranslation } from 'react-i18next';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';

const NetworkErrorToast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      logError({
        event: 'SomethingWentWrong: show',
      }),
    );
  }, [dispatch]);

  return (
    <S.Container>
      <S.Title>{t('networkErrorToast.title')}</S.Title>
      <S.Info>{t('networkErrorToast.description')}</S.Info>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
    height: ${calcHeight(152)}px;
    background: ${({ theme }) => theme.colors.transparentPink};
    justify-content: center;
    vertical-align: center;
    padding-top: ${StatusBar.currentHeight ? StatusBar.currentHeight + calcHeight(25) : calcHeight(25)}px;
    padding-left: ${calcWidth(25)}px;
    padding-right: ${calcWidth(25)}px;
  `,
  Title: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  `,
  Info: styled.Text`
    margin-top: ${calcHeight(20)}px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    line-height: ${({ theme }) => theme.fontSizes.s14 * 1.21}px;
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default NetworkErrorToast;
