import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../../utils/dimensions';
import { IStackNavigation } from '../../../navigation/types';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMounted } from '../../../hooks/useIsMounted';
import styled, { useTheme } from 'styled-components/native';
import {
  biteSelector,
  selectedBiteVideoSelector,
  selectedBiteVideoIsLoadingSelector,
} from '../../../store/bite/bite.selectors';
import { changedOrActiveSubtitlesSelector, createBiteSelector } from '../../../store/createBite/createBite.selectors';
import {
  biteIntroHelpersConfigSelector,
  isVideoEditorOpenSelector,
} from '../../../store/appActivity/appActivity.selectors';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { useBufferedTasks } from '../../../hooks/useBufferedTasks';
import { createThumbnail } from 'react-native-create-thumbnail';
import uploadImageUtil from '../../../utils/uploadImage';
import useMedia, { formatFileTypesForWeb } from '../../../hooks/useMedia';
import Routes from '../../../navigation/routes';
import DeleteButton from '../../../components/shared/buttons/DeleteButton';
import {
  clearBiteCover,
  clearBiteMedia,
  deleteBiteIntro,
  resetQuestionSuggestion,
  resetSummarySuggestionCards,
  setBiteIntro,
  setBiteIntroDuration,
  setIntroMediaProcessingStatus,
} from '../../../store/createBite/createBites.actions';
import convertMediaToMediaViewerProps from '../../../utils/convertMediaToMediaViewerProps';
import { stopYoutubeVideo } from '../../../utils/general';
import CreateIntroButtons from '../CreateIntroButtons/CreateIntroButtons';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import DraftModal from '../DraftModal';
import YoutubeUploadModal from '../../../components/modals/YoutubeUploadModal';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { InteractionManager, StyleProp, ViewProps } from 'react-native';
import { isEqual } from 'lodash';
import BackButton from '../../../components/shared/BackButton';
import { MAX_MEDIA_SIZE } from '../../../hooks/useMedia/constants';
import BaseModal from '../../creationBite/common/BaseModal';
import { EFileType, IMedia, IVideoDetails } from '../../../types/media';
import useVideoThumbnail from '../../../utils/introMedia/useVideoThumbnail/useVideoThumbnail';
import { loadNextPage } from '../../../store/feed/feed.slice';
import {
  log,
  logError,
  setCurrentFlow,
  setIsGoogleSlidesExplainerVisibleInitial,
  setSlowConnection,
  trackEvent,
} from '../../../store/appActivity/appActivity.slice';
import useIntroSubtitles from '../../../hooks/useIntroSubtitles';
import { useIsFocused } from '@react-navigation/native';
import { formatMediaSize } from '../../../utils/formatMediaSize';
import { showBottomPanel } from '../../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../../utils/constants/bottomPanel';
import {
  EExportType,
  EVideoEditorEntryPoint,
  getDrafts,
  IVideoEditorResult,
} from '../../../services/videoEditor/videoEditor';
import PowerpointModal from '../PowerpointModal';
import BitesApi from '../../../store/api/bites-api';
import HelpersPanel from '../HelpersPanel';
import { IEnhancement } from '../../../types/bite';
import getLocalUrlPath from '../../../utils/getLocalUrlPath';
import { FetchBlobResponse, StatefulPromise } from 'rn-fetch-blob';
import Toast from 'react-native-toast-message';
import Button from '../../../components/shared/buttons/Button/Button';
import LoadingOverlay from '../../../components/shared/LoadingOverlay';
import { IVideoMeta } from '../../../hooks/useMedia/useMedia';
import {
  confirmDeleteButtonDataSet,
  continueBtnDataSet,
  editIntroButtonDataSet,
  editIntroButtonDisabledDataSet,
  skipIntroButtonDataSet,
  tellYourStoryDropzoneDataset,
} from './tellYourStory.constants';
import { replaceIntroDataset } from '../constants';
import { EToastTypes } from '../../../utils/constants/toastConfig';
import TellYourStoryElementsPanelHeader from '../../../components/shared/ElementsPanel/common/TellYourStoryElementsPanelHeader';
import { loadVideo } from '../../../store/videoEditor/videoEditor.slice';

const MAX_MEDIA_HEIGHT = isWeb ? deviceHeight - 380 : Math.round(deviceHeight - 420);
const MAX_MEDIA_WIDTH = isWeb ? 550 : Math.round(deviceWidth - 60);
const MAX_MEDIA_FILE_SIZE_IN_MB = MAX_MEDIA_SIZE / (1024 * 1024);

interface IIntroThumbnail {
  image: string;
  id: string;
}

export interface IUploadVideoProps {
  file: File;
  videoUri: string;
  videoMeta: IVideoMeta;
  thumbnail: string | File;
  // below for old flow of quiz
  uploadVideo: () => Promise<{
    taskId: string;
    mediaUri: string;
  }>;
  setLoading: (isUpdated: boolean) => void;
  uploadThumbnail?: () => Promise<IIntroThumbnail>;
}

interface IProps extends IStackNavigation {
  from: string;
  renderMediaViewer: (mediaViewerProps) => ReactNode;
  onSkip: () => void;
  isEditMode: boolean;
  isPrefilled?: boolean;
  onMediaSelect?: (setLoading?: (isUpdated: boolean) => void, thumbnailUrl?: string, media?: string | IMedia) => void;
  onUploadVideo: (props: IUploadVideoProps) => void;
  handleRedirectToNextScreen: (setLoading: (isUpdated: boolean) => void) => void;
  withDelete?: boolean;
  isControlsDisabledOnLoading?: boolean;
  launchVideoEditorWithDrafts?: boolean;
  openImportMediaPanel?: boolean;
  isHelperTemplatesEnabled?: boolean;
  setIntroUpdated: (isUpdated: boolean) => void;
  isIntroUpdated: boolean;
  onVideoEditorResult?: (videoEditorResult: IVideoEditorResult) => void;
  videoEditorExportType?: EExportType;
  isVideoUploading?: boolean;
  onWebVideoEditorContinue?: () => void;
}

const TellYourStory: React.FC<IProps> = ({
  from,
  renderMediaViewer,
  onSkip,
  isEditMode,
  isPrefilled,
  navigation,
  onMediaSelect,
  onUploadVideo,
  handleRedirectToNextScreen,
  withDelete = true,
  isControlsDisabledOnLoading = false,
  launchVideoEditorWithDrafts = false,
  openImportMediaPanel,
  isHelperTemplatesEnabled = false,
  setIntroUpdated,
  isIntroUpdated,
  onVideoEditorResult,
  videoEditorExportType,
  isVideoUploading,
  onWebVideoEditorContinue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMountRef = useIsMounted();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const selectedBiteVideo = useSelector(selectedBiteVideoSelector);
  const selectedBiteVideoIsLoading = useSelector(selectedBiteVideoIsLoadingSelector);

  const youtubeModalRef = useRef(null);
  const mediaRef = useRef(null);
  const mediaMethods = useRef(null);
  const continueRef = useRef(false);
  const isDraftRef = useRef(false);

  const changedOrActiveSubtitles = useSelector(changedOrActiveSubtitlesSelector);

  const { selectedBite } = useSelector(biteSelector);
  const { introMedia, introEnhancements, introEnhancementsChanges, introMediaProcessingStatus } =
    useSelector(createBiteSelector);

  const elementsPanelConfigInitial = useSelector(biteIntroHelpersConfigSelector);
  const elementsPanelConfig = useMemo(() => {
    if (!elementsPanelConfigInitial || isHelperTemplatesEnabled) {
      return elementsPanelConfigInitial;
    }

    const newSections = elementsPanelConfigInitial?.sections.filter(({ id }) => id !== 'templates');

    return {
      ...elementsPanelConfigInitial,
      sections: newSections,
    };
  }, [elementsPanelConfigInitial, isHelperTemplatesEnabled]);

  const isVideoEditorOpen = useSelector(isVideoEditorOpenSelector);
  const [videoSizes, setVideoSizes] = useState<IVideoDetails>(null);
  const [formattedVideoSizes, setFormattedVideoSizes] = useState(() => ({
    width: calcWidth((9 / 16) * MAX_MEDIA_HEIGHT),
    height: calcWidth(MAX_MEDIA_HEIGHT),
  }));
  const [introThumbnail, setIntroThumbnail] = useState<string | File>(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDraft, setIsLoadingDraft] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isReplaceMyStoryModalVisible, setReplaceMyStoryModalVisible] = useState(false);
  const [isReplaceWithYoutubeModalVisible, setIsReplaceWithYoutubeModalVisible] = useState(false);
  const [isGoBackModalVisible, setIsGoBackModalVisible] = useState(false);
  const [isDraftModalVisible, setIsDraftModalVisible] = useState(false);
  const [isPowerpointModalVisible, setIsPowerpointModalVisible] = useState(false);
  const [isDveChromeOnly, setIsDveChromeOnly] = useState(false);

  const [isEditLoading, setIsEditLoading] = useState(false);
  const downloadVideoRequestRef = useRef<StatefulPromise<FetchBlobResponse>>(null);
  const cancelEditLoading = useCallback(() => {
    setIsEditLoading(false);
    downloadVideoRequestRef.current?.cancel();
    downloadVideoRequestRef.current = null;
  }, []);

  const isIntroMediaProcessing = introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING;

  const { addTask, callNextTask } = useBufferedTasks();
  const { linesData } = useIntroSubtitles(changedOrActiveSubtitles);

  const handleOpenPowerpointModal = useCallback(() => {
    setIsPowerpointModalVisible(true);
  }, []);

  const handleClosePowerpointModal = useCallback(() => {
    setIsPowerpointModalVisible(false);
  }, []);

  const handleOpenDraftModal = useCallback(() => {
    mediaMethods.current?.pause?.();
    setIsDraftModalVisible(true);
  }, []);

  const handleCloseDraftModal = useCallback(() => {
    setIsDraftModalVisible(false);
  }, []);

  const handleOpenGoBackModal = useCallback(() => {
    mediaMethods.current?.pause?.();
    setIsGoBackModalVisible(true);
  }, []);

  const handleCloseGoBackModal = useCallback(() => {
    setIsGoBackModalVisible(false);
  }, []);

  const handleOpenDeleteModal = useCallback(() => {
    mediaMethods.current?.pause?.();
    cancelEditLoading();
    setDeleteModalVisible(true);
  }, [cancelEditLoading]);

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const handleOpenReplaceModal = useCallback(() => {
    mediaMethods.current?.pause?.();
    setIsReplaceWithYoutubeModalVisible(true);
  }, []);

  const handleCloseReplaceModal = useCallback(() => {
    setIsReplaceWithYoutubeModalVisible(false);
  }, []);

  const handleCloseReplaceMyStoryModal = useCallback(() => {
    setReplaceMyStoryModalVisible(false);
  }, []);

  const handleOpenReplaceMyStoryModal = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'replace_intro',
      }),
    );
    mediaMethods.current?.pause?.();
    handleCloseDeleteModal();
    setReplaceMyStoryModalVisible(true);
  }, [handleCloseDeleteModal, dispatch]);

  const handleCloseAllModals = useCallback(() => {
    handleCloseReplaceModal();
    handleCloseDeleteModal();
    handleCloseGoBackModal();
    handleCloseReplaceMyStoryModal();
    handleCloseDraftModal();
    handleClosePowerpointModal();
  }, [
    handleCloseReplaceModal,
    handleCloseDeleteModal,
    handleCloseGoBackModal,
    handleCloseReplaceMyStoryModal,
    handleCloseDraftModal,
    handleClosePowerpointModal,
  ]);

  const intro = useMemo(() => {
    const enhancements = introEnhancements?.find(({ id, enabled }) => {
      return introEnhancementsChanges.mediaId ? introEnhancementsChanges.mediaId === id : enabled;
    });

    if (introMedia?.file_type === EFileType.YOUTUBE || introMedia?.file_type === EFileType.GOOGLE_SLIDES) {
      return introMedia;
    }

    return enhancements || introMedia;
  }, [introEnhancements, introEnhancementsChanges, introMedia]);

  const subtitles = useMemo(() => {
    return (
      changedOrActiveSubtitles && {
        uri: changedOrActiveSubtitles.s3_url,
        language: changedOrActiveSubtitles.locale,
      }
    );
  }, [changedOrActiveSubtitles]);

  const uploadThumbnail = useCallback(async (): Promise<IIntroThumbnail> => {
    if (typeof introThumbnail === 'string') {
      const { data } = await uploadImageUtil({
        uri: introThumbnail,
        type: 'image/jpeg',
        path: 'upload_cover',
      });

      return {
        id: data.id,
        image: data.image,
      };
    }

    const { data } = await uploadImageUtil({
      file: introThumbnail,
      path: 'upload_cover',
    });

    return {
      id: data.id,
      image: data.image,
    };
  }, [introThumbnail]);

  const { getThumbnail, thumbnailCanvas } = useVideoThumbnail();

  const handleMediaSelection = useCallback(
    async (media, mediaType) => {
      dispatch(setCurrentFlow({ hasIntroVideo: mediaType === EFileType.VIDEO, videoUploadedToS3: false }));

      if (!isWeb && mediaType === EFileType.VIDEO) {
        dispatch(setSlowConnection({ isSlowConnection: false, isDismissed: false }));
      }

      // async flow is handled separately
      if (mediaType === EFileType.VIDEO && typeof onVideoEditorResult === 'function') {
        return;
      }

      // local status until we proceed to the next screen
      setIntroUpdated(true);

      // only for video scenario below
      // generate the thumbnail
      if ((isWeb && !media?.type.includes('video')) || (!isWeb && mediaType !== EFileType.VIDEO)) {
        return;
      }

      setLoading(true);

      try {
        let newThumbnail = null;

        if (isWeb) {
          // File in web case
          newThumbnail = await getThumbnail(media);
        } else {
          // path string in mobile case
          const { path } = await createThumbnail({ url: `file://${media}` });
          newThumbnail = path;
        }

        if (!isMountRef.current) {
          return;
        }

        setIntroThumbnail(newThumbnail);
        setLoading(false);
      } catch (error) {
        dispatch(
          logError({
            event: 'TellYourStory.handleMediaSelection: error',
            error,
          }),
        );
      }
    },
    [dispatch, getThumbnail, isMountRef, onVideoEditorResult, setIntroUpdated],
  );

  const handleYoutubeSuccess = useCallback(() => {
    setIntroUpdated(true);
    dispatch(setCurrentFlow({ hasIntroVideo: false, videoUploadedToS3: false }));
  }, [dispatch, setIntroUpdated]);

  const mediaControls = useMedia({
    initialMedia: intro,
    initialMediaUri:
      intro?.file_type === EFileType.GOOGLE_SLIDES ||
      (intro?.file_type === EFileType.VIDEO && introMedia?.isVideoPathLocal)
        ? introMedia?.media_url
        : undefined,
    onMediaSelectionCB: handleMediaSelection,
    fileTypesForWeb: formatFileTypesForWeb({ image: true, video: true }),
    from,
    onYoutubeSuccess: handleYoutubeSuccess,
    withVideoEditorDrafts: true,
    dropzoneDataset: tellYourStoryDropzoneDataset,
  });

  const {
    media: mediaViewer,
    mediaURI,
    videoMeta,
    mediaFileForWeb,
    mediaType,
    youtubeMedia,
    launchVideoEditor,
    launchImageLibrary,
    dropZoneUploadingForWeb,
    clearState,
    uploadImage,
    uploadVideo,
    uploadYoutube,
    lastDraft,
    isMediaSizeExceeded,
    setGoogleSlidesMedia,
  } = mediaControls;

  const isMediaVisible = !!(mediaURI || youtubeMedia || intro || isIntroMediaProcessing);

  const handleDeleteComplete = useCallback(() => {
    if (!isMountRef.current) {
      return;
    }

    dispatch(loadNextPage({ withBaseFiltersAndSorting: true }));

    navigation.goBack();
  }, [dispatch, isMountRef, navigation]);

  const handleRemoveIntroMedia = useCallback(
    (withoutRedirect?: boolean) => {
      handleCloseAllModals();
      clearState();
      dispatch(clearBiteCover());
      dispatch(clearBiteMedia());
      dispatch(resetSummarySuggestionCards());
      dispatch(resetQuestionSuggestion());
      dispatch(setCurrentFlow({ hasIntroVideo: false, videoUploadedToS3: false }));

      if (selectedBite?.id) {
        dispatch(
          deleteBiteIntro({
            biteId: selectedBite?.id,
            callback: !withoutRedirect && handleDeleteComplete,
          }),
        );
      }
    },
    [clearState, handleCloseAllModals, dispatch, handleDeleteComplete, selectedBite?.id],
  );

  const navigateBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [navigation]);

  const handleGoBackModalRightButtonPress = useCallback(() => {
    handleCloseGoBackModal();

    navigateBack();
  }, [handleCloseGoBackModal, navigateBack]);

  const handleBackButtonPress = useCallback(() => {
    if (isMediaVisible && !isGoBackModalVisible && !isEditMode && !isWeb) {
      handleOpenGoBackModal();
      return;
    }
    navigateBack();
  }, [isMediaVisible, isGoBackModalVisible, isEditMode, navigateBack, handleOpenGoBackModal]);

  const renderHeaderLeft = useCallback(
    () =>
      !isLoadingDraft &&
      !isVideoUploading && (
        <BackButton onPress={handleBackButtonPress} disabled={isLoading && isControlsDisabledOnLoading} />
      ),
    [handleBackButtonPress, isLoadingDraft, isVideoUploading, isLoading, isControlsDisabledOnLoading],
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    navigation.setOptions({
      headerRight:
        withDelete && (mediaURI || youtubeMedia || intro)
          ? () => <DeleteButton width={calcWidth(41)} height={calcWidth(41)} onPress={handleOpenDeleteModal} />
          : () => null,
      headerLeft: renderHeaderLeft,
    });
  }, [isFocused, navigation, handleOpenDeleteModal, renderHeaderLeft, withDelete, mediaURI, youtubeMedia, intro]);

  useEffect(() => {
    if (!isFocused && typeof mediaMethods.current?.pause === 'function') {
      mediaMethods.current.pause();
    }
  }, [isFocused]);

  const launchVideoEditorAfterInteractions = useCallback(
    (props) => {
      dispatch(
        log({
          event: 'launchVideoEditorAfterInteractions',
        }),
      );

      const launch = async () => {
        if (!isMountRef.current || isLaunched) {
          dispatch(
            log({
              event: 'launchVideoEditorAfterInteractions: returning',
              data: {
                isMounted: isMountRef.current,
                isLaunched,
              },
            }),
          );
          return;
        }

        dispatch(
          log({
            event: 'launchVideoEditorAfterInteractions: launching',
          }),
        );

        setIsLoadingDraft(true);

        clearTimeout(timeout);
        isLaunched = true;
        const result = await launchVideoEditor({ ...props, exportType: videoEditorExportType });

        setIsLoadingDraft(false);

        dispatch(
          log({
            event: 'launchVideoEditorAfterInteractions: result',
            data: {
              result,
              onVideoEditorResult: !!onVideoEditorResult,
            },
          }),
        );

        if (result && typeof onVideoEditorResult === 'function') {
          onVideoEditorResult(result);
        }
      };

      let isLaunched = false;
      InteractionManager.runAfterInteractions(launch);

      // in some cases (not clear yet)
      // InteractionManager.runAfterInteractions
      // does not run the callback
      // timeout is a workaround
      // quite a long delay, because we actually want
      // to start banuba after interactions
      let timeout = setTimeout(() => {
        dispatch(
          log({
            event: 'launchVideoEditorAfterInteractions: from setTimeout',
          }),
        );

        launch();
      }, 1000);
    },
    [dispatch, isMountRef, launchVideoEditor, onVideoEditorResult, videoEditorExportType],
  );

  const openExistingVideos = useCallback(() => {
    navigation.navigate(Routes.CreateBiteStack.ExistingVideos, {
      onContinue: onWebVideoEditorContinue,
    });
  }, [navigation, onWebVideoEditorContinue]);

  const handleOpenDraft = useCallback(async () => {
    if (isWeb) {
      handleCloseAllModals();
      openExistingVideos();
      return;
    }
    if (isDraftRef.current) {
      return;
    }
    isDraftRef.current = true;

    try {
      setIsLoadingDraft(true);
      dispatch(
        log({
          event: 'handleOpenDraft',
        }),
      );

      const drafts = await getDrafts();

      if (!drafts.length) {
        dispatch(
          log({
            event: 'handleOpenDraft: no drafts',
            data: drafts,
          }),
        );

        handleOpenDraftModal();
        setIsLoadingDraft(false);
        return;
      }

      dispatch(
        log({
          event: 'handleOpenDraft: have drafts',
          data: drafts,
        }),
      );

      if (!isReplaceMyStoryModalVisible) {
        dispatch(
          log({
            event: 'handleOpenDraft: launching banuba',
            data: drafts,
          }),
        );

        launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.DRAFTS });
        return;
      }

      dispatch(
        log({
          event: 'handleOpenDraft: hiding modal',
          data: drafts,
        }),
      );

      addTask('SHOW_VIDEO_EDITOR_WITH_DRAFT', () => {
        setTimeout(() => {
          launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.DRAFTS });
        }, 100);
      });
      handleCloseReplaceMyStoryModal();
    } catch (error) {
      dispatch(
        logError({
          event: 'TellYourStory.handleOpenDraft: error',
          error,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });

      setIsLoadingDraft(false);
    }
  }, [
    addTask,
    dispatch,
    handleCloseAllModals,
    handleCloseReplaceMyStoryModal,
    handleOpenDraftModal,
    isReplaceMyStoryModalVisible,
    launchVideoEditorAfterInteractions,
    openExistingVideos,
  ]);

  const handleOpenYouTubeModalNative = useCallback(() => {
    mediaMethods.current?.pause?.();

    handleCloseAllModals();
    youtubeModalRef.current?.open();
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'youtube', from: Routes.CreateBiteStack.BiteTellYourStory },
      }),
    );
  }, [handleCloseAllModals, dispatch]);

  const handleOpenYouTubeModalWeb = useCallback(() => {
    if (isMediaVisible && !isReplaceWithYoutubeModalVisible) {
      handleCloseAllModals();
      handleOpenReplaceModal();
      return;
    }

    handleCloseAllModals();
    youtubeModalRef.current?.open();
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'youtube', from: Routes.CreateBiteStack.BiteTellYourStory },
      }),
    );
  }, [isMediaVisible, isReplaceWithYoutubeModalVisible, handleCloseAllModals, dispatch, handleOpenReplaceModal]);

  const handleOpenYouTubeModal = useCallback(() => {
    mediaMethods.current?.pause?.();
    setLoading(false);
    if (isWeb) {
      handleOpenYouTubeModalWeb();
      return;
    }
    handleOpenYouTubeModalNative();
  }, [handleOpenYouTubeModalNative, handleOpenYouTubeModalWeb]);

  const handleGoogleSlidesPress = useCallback(() => {
    navigation.navigate(Routes.CreateBiteStack.BiteGoogleSlides, {
      biteId: selectedBite?.id,
      initialMedia: intro?.file_type === EFileType.GOOGLE_SLIDES ? intro : undefined,
      setGoogleSlidesMedia: (media) => {
        dispatch(setIsGoogleSlidesExplainerVisibleInitial(false));
        setIntroUpdated(true);
        setGoogleSlidesMedia(media);
      },
    });
  }, [dispatch, intro, navigation, selectedBite?.id, setGoogleSlidesMedia, setIntroUpdated]);

  const handlePowerPointPress = useCallback(() => {
    handleCloseAllModals();
    handleOpenPowerpointModal();
  }, [handleCloseAllModals, handleOpenPowerpointModal]);

  const handleOpenImportMediaPanel = useCallback(() => {
    handleCloseAllModals();
    dispatch(
      showBottomPanel({
        componentName: EBottomPanelComponentName.ImportMedia,
        componentProps: {
          withoutHandle: true,
          onYoutubePress: handleOpenYouTubeModal,
          onGoogleSlidesPress: handleGoogleSlidesPress,
          onPowerpointPress: handlePowerPointPress,
        },
      }),
    );
  }, [dispatch, handleCloseAllModals, handleGoogleSlidesPress, handleOpenYouTubeModal, handlePowerPointPress]);

  const reOpenImportMediaPanel = useCallback(() => {
    if (isMediaVisible) {
      return;
    }
    handleOpenImportMediaPanel();
  }, [handleOpenImportMediaPanel, isMediaVisible]);

  const handleDraftModalButtonPress = useCallback(
    (hasDrafts: boolean) => {
      addTask('SHOW_VIDEO_EDITOR_FROM_DRAFT_MODAL', () => {
        setTimeout(
          () =>
            launchVideoEditorAfterInteractions({
              entryPoint: hasDrafts ? EVideoEditorEntryPoint.DRAFTS : EVideoEditorEntryPoint.CAMERA,
            }),
          100,
        );
      });
      handleCloseDraftModal();
    },
    [addTask, handleCloseDraftModal, launchVideoEditorAfterInteractions],
  );

  const handleUploadYoutube = useCallback(async () => {
    try {
      stopYoutubeVideo(mediaRef);

      const { media, thumbnailUrl } = await uploadYoutube();

      dispatch(setBiteIntro(media));

      if (typeof onMediaSelect === 'function') {
        onMediaSelect(setLoading, thumbnailUrl, media);
      }
    } catch (e) {
      setLoading(false);

      dispatch(
        setIntroMediaProcessingStatus({
          youtube: EIntroMediaProcessingStatus.ERROR,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  }, [dispatch, onMediaSelect, uploadYoutube]);

  const handleUploadGoogleSlides = useCallback(async () => {
    try {
      const { data } = await BitesApi.post('/media/google_slides/', mediaViewer);
      dispatch(setBiteIntro(data.media));
      if (typeof onMediaSelect === 'function') {
        onMediaSelect(setLoading, null, data.media);
      }
    } catch (e) {
      setLoading(false);
    }
  }, [dispatch, mediaViewer, onMediaSelect]);

  const handleUploadImage = useCallback(async () => {
    try {
      const { media } = await uploadImage();

      dispatch(setBiteIntro(media));
      setLoading(false);

      if (typeof onMediaSelect === 'function') {
        onMediaSelect(setLoading, media.image_url, media);
      }
    } catch (e) {
      setLoading(false);

      dispatch(
        setIntroMediaProcessingStatus({
          image: EIntroMediaProcessingStatus.ERROR,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  }, [dispatch, onMediaSelect, uploadImage]);

  const handleEditVideo = useCallback(async () => {
    mediaMethods.current?.pause?.();

    const type = mediaType || intro?.file_type;

    if (isWeb && (type !== EFileType.VIDEO || !selectedBiteVideo || !onWebVideoEditorContinue)) {
      handleOpenReplaceMyStoryModal();
      return;
    }

    if (isEditLoading) {
      return;
    }

    if (type === 'youtube') {
      handleOpenYouTubeModal();
      return;
    }

    if (type === 'google_slides') {
      handleGoogleSlidesPress();
      return;
    }

    // web video editor
    if (isWeb && selectedBiteVideo && onWebVideoEditorContinue) {
      dispatch(
        loadVideo({
          videoId: selectedBiteVideo.id,
        }),
      );
      navigation.navigate(Routes.CreateBiteStack.BiteVideoEditor, {
        onContinue: onWebVideoEditorContinue,
      });
      return;
    }

    if (isWeb) {
      handleOpenReplaceMyStoryModal();
      return;
    }

    // Banuba below
    const s3VideoUrl = (intro as IEnhancement)?.s3_video_url;
    if (isPrefilled && !lastDraft && s3VideoUrl) {
      try {
        setIsEditLoading(true);

        const { request, promise } = await getLocalUrlPath(s3VideoUrl);
        downloadVideoRequestRef.current = request;
        const localVideoUri = await promise;

        if (!isMountRef.current || !downloadVideoRequestRef.current) {
          return;
        }

        cancelEditLoading();
        launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.EDITOR, localVideoUri });
      } catch (error) {
        dispatch(
          logError({
            event: 'TellYourStory.handleEditVideo: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });

        cancelEditLoading();
      }
      return;
    }

    if (!lastDraft) {
      handleOpenDraftModal();
      return;
    }

    launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.DRAFTS, draftId: lastDraft.sequenceId });
  }, [
    onWebVideoEditorContinue,
    isEditLoading,
    mediaType,
    intro,
    selectedBiteVideo,
    isPrefilled,
    lastDraft,
    launchVideoEditorAfterInteractions,
    handleOpenReplaceMyStoryModal,
    handleOpenYouTubeModal,
    handleGoogleSlidesPress,
    dispatch,
    navigation,
    isMountRef,
    cancelEditLoading,
    handleOpenDraftModal,
  ]);

  const handleVideoEnd = useCallback(() => {
    mediaMethods.current.pause();
    setTimeout(() => {
      mediaMethods.current?.seek(0);
    }, 0);
  }, []);

  const handlePlayerMethodsAssign = useCallback((methods) => (mediaMethods.current = methods), []);

  const handleMediaDetails = useCallback(
    (details: IVideoDetails) => {
      dispatch(setBiteIntroDuration(details?.duration || null));

      if (isEqual(details, videoSizes) || !details.width || !details.height) {
        return;
      }

      const formattedSizes = formatMediaSize({
        initialWidth: details.width,
        initialHeight: details.height,
        maxWidth: MAX_MEDIA_WIDTH,
        maxHeight: MAX_MEDIA_HEIGHT,
        isGoogleSlides: mediaType === EFileType.GOOGLE_SLIDES,
      });
      setFormattedVideoSizes(formattedSizes);
      setVideoSizes(details);
    },
    [dispatch, mediaType, videoSizes],
  );

  const mediaViewerProps = useMemo(() => {
    if ((isEditMode || isPrefilled) && !(mediaURI || youtubeMedia) && intro) {
      return {
        ...convertMediaToMediaViewerProps({ ...intro, subtitles }),
        setRef: handlePlayerMethodsAssign,
        disableVolume: true,
        disableFullscreen: true,
        isLessControlMode: true,
        onMethods: handlePlayerMethodsAssign,
        onEnd: handleVideoEnd,
        isAutoPlay: true,
        onMediaDetails: handleMediaDetails,
        lines: linesData,
        initialSizes: { width: mediaViewer?.width, height: mediaViewer?.height },
        subtitlesLocale: changedOrActiveSubtitles?.locale,
      };
    }

    return {
      setRef: handlePlayerMethodsAssign,
      mediaURI,
      mediaType,
      youtubeMedia,
      disableVolume: true,
      disableFullscreen: true,
      isLessControlMode: true,
      onMethods: handlePlayerMethodsAssign,
      onEnd: handleVideoEnd,
      isAutoPlay: true,
      onMediaDetails: handleMediaDetails,
      initialSizes: { width: mediaViewer?.width, height: mediaViewer?.height },
      subtitlesLocale: changedOrActiveSubtitles?.locale,
    };
  }, [
    isEditMode,
    isPrefilled,
    mediaURI,
    youtubeMedia,
    intro,
    handlePlayerMethodsAssign,
    mediaType,
    handleVideoEnd,
    handleMediaDetails,
    mediaViewer?.width,
    mediaViewer?.height,
    changedOrActiveSubtitles?.locale,
    subtitles,
    linesData,
  ]);

  const handleCloseDveChromeOnly = useCallback(() => {
    setIsDveChromeOnly(false);
  }, []);

  const createMedia = useCallback(
    (isCreateFromModal?: boolean) => {
      if (isWeb && onWebVideoEditorContinue) {
        const isChromeDesktop = getIsChromeDesktop();
        if (!isChromeDesktop) {
          setIsDveChromeOnly(true);
          return;
        }

        navigation.navigate(Routes.CreateBiteStack.BiteVideoEditor, {
          onContinue: onWebVideoEditorContinue,
        });
        return;
      }

      if (isWeb) {
        console.log('createMedia: isWeb should not get here');
        // launchImageLibrary();
        return;
      }

      if (!isCreateFromModal) {
        launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.CAMERA });
        return;
      }

      // wait for the replace modal close
      addTask('SHOW_VIDEO_EDITOR', () => {
        setTimeout(() => launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.CAMERA }), 100);
      });
    },
    [addTask, launchVideoEditorAfterInteractions, navigation, onWebVideoEditorContinue],
  );

  const handleUploadPress = useCallback(() => {
    launchImageLibrary();
    handleCloseAllModals();
  }, [handleCloseAllModals, launchImageLibrary]);

  const handleCreateMedia = useCallback(() => {
    createMedia(isMediaVisible);
    handleCloseAllModals();
  }, [createMedia, handleCloseAllModals, isMediaVisible]);

  const handleReplaceIntroMediaWithYoutube = useCallback(() => {
    handleCloseAllModals();
    clearState();

    handleOpenYouTubeModal();
  }, [clearState, handleCloseAllModals, handleOpenYouTubeModal]);

  const handleOpenFromGalleryPress = useCallback(() => {
    if (!isMediaVisible) {
      launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.GALLERY });
      return;
    }

    // wait for the replace modal close
    addTask('SHOW_VIDEO_EDITOR', () => {
      setTimeout(() => launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.GALLERY }), 100);
    });
  }, [addTask, isMediaVisible, launchVideoEditorAfterInteractions]);

  const handleOpenNativeVideoEditor = useCallback(() => {
    callNextTask();
  }, [callNextTask]);

  const deleteConfirmTitle = useMemo(() => {
    if (isEditMode) {
      return t('confirmDeleteSection.intro.title');
    }

    return t('confirmDeleteModal.title');
  }, [isEditMode, t]);

  const deleteConfirmDescription = useMemo(() => {
    if (isEditMode) {
      return t('confirmDeleteSection.intro.description');
    }

    return t('confirmDeleteModal.description');
  }, [isEditMode, t]);

  const editButtonText = useMemo(() => {
    const type = mediaType || intro?.file_type;

    if (type === 'image' || type === 'google_slides') {
      return t('common.Edit');
    }

    return t(`tellYourStory.${isWeb && !selectedBiteVideo ? 'replaceVideo' : 'editVideo'}`);
  }, [intro?.file_type, mediaType, selectedBiteVideo, t]);

  useEffect(() => {
    // reset the ref once loading is true
    if (isLoading) {
      continueRef.current = false;
    }
  }, [isLoading]);

  useEffect(() => {
    // reset the ref once isLoadingDraft is false
    if (!isLoadingDraft) {
      isDraftRef.current = false;
    }
  }, [isLoadingDraft]);

  const handleContinue = useCallback(async () => {
    cancelEditLoading();

    if (!isIntroUpdated) {
      handleRedirectToNextScreen(setLoading);
      return;
    }

    // use ref for the cases of slow device and the screen
    // is not rerendered yet with the new loading state
    if (continueRef.current) {
      return;
    }
    continueRef.current = true;
    setLoading(true);

    dispatch(resetSummarySuggestionCards());
    dispatch(resetQuestionSuggestion());

    switch (mediaType) {
      case 'youtube':
        await handleUploadYoutube();
        break;
      case 'google_slides':
        await handleUploadGoogleSlides();
        break;
      case 'image':
        await handleUploadImage();
        break;
      default:
        onUploadVideo({
          file: mediaFileForWeb,
          videoUri: mediaURI,
          videoMeta,
          thumbnail: introThumbnail,
          setLoading,
          // below for old flow of quiz
          uploadVideo,
          uploadThumbnail,
        });
    }
  }, [
    cancelEditLoading,
    isIntroUpdated,
    dispatch,
    mediaType,
    handleRedirectToNextScreen,
    handleUploadYoutube,
    handleUploadGoogleSlides,
    handleUploadImage,
    onUploadVideo,
    mediaFileForWeb,
    mediaURI,
    videoMeta,
    introThumbnail,
    uploadVideo,
    uploadThumbnail,
  ]);

  const handleClosePowerpointModalAndOpenMediaPanel = useCallback(() => {
    setIsPowerpointModalVisible(false);
    handleOpenImportMediaPanel();
  }, [handleOpenImportMediaPanel]);

  const isContinueButtonLoading = useMemo(
    () => (isLoading || isMediaSizeExceeded === null) && isIntroUpdated,
    [isIntroUpdated, isLoading, isMediaSizeExceeded],
  );

  const handlePressSkip = useCallback(() => {
    cancelEditLoading();

    if (typeof onSkip === 'function') {
      onSkip();
    }
  }, [cancelEditLoading, onSkip]);

  useEffect(() => {
    if (launchVideoEditorWithDrafts && !isVideoEditorOpen) {
      launchVideoEditorAfterInteractions({ entryPoint: EVideoEditorEntryPoint.DRAFTS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchVideoEditorWithDrafts]);

  useEffect(() => {
    if (!isFocused || !openImportMediaPanel || isMediaVisible) {
      return;
    }

    handleOpenImportMediaPanel();
  }, [openImportMediaPanel, isFocused, handleOpenImportMediaPanel, isMediaVisible]);

  const continueButtonDataSet = useMemo(() => {
    return !isContinueButtonLoading ? continueBtnDataSet : undefined;
  }, [isContinueButtonLoading]);

  const renderCustomHeader = useCallback(
    ({ isOpen }) => <TellYourStoryElementsPanelHeader title={t('helpers.biteIntro.panelTitle')} isOpen={isOpen} />,
    [t],
  );

  const createMediaDescription = useMemo(() => {
    if (isWeb && !onWebVideoEditorContinue) {
      return t('tellYourStory.uploadDescription');
    }

    return t('tellYourStory.createDescription');
  }, [onWebVideoEditorContinue, t]);

  const createMediaTitle = useMemo(() => {
    if (isWeb && !onWebVideoEditorContinue) {
      return t('tellYourStory.upload');
    }
    return t('tellYourStory.create');
  }, [onWebVideoEditorContinue, t]);

  return (
    <S.Container>
      <LoadingOverlay isVisible={isLoadingDraft} />
      <S.Content bounces={false}>
        <S.Center>
          <S.TitleWrapper>
            <S.Title>{isMediaVisible ? t('tellYourStory.titleOnMedia') : t('tellYourStory.title')}</S.Title>
          </S.TitleWrapper>
          {isMediaVisible ? (
            <>
              <S.MediaWrapper>
                <S.MediaContainer isMaxHeight={isWeb && mediaType === 'video'} videoSizes={formattedVideoSizes}>
                  {renderMediaViewer(mediaViewerProps)}
                </S.MediaContainer>
              </S.MediaWrapper>

              {isMediaSizeExceeded && (
                <S.FileExceededTextContainer>
                  <S.FileExceededText>
                    {t('tellYourStory.mediaSizeExceededMessage', { maxFileSize: MAX_MEDIA_FILE_SIZE_IN_MB })}
                  </S.FileExceededText>
                </S.FileExceededTextContainer>
              )}
              <S.ButtonsWrapper width={isWeb ? formattedVideoSizes.width : MAX_MEDIA_WIDTH}>
                <S.EditButton
                  isLoading={isEditLoading || isVideoEditorOpen || selectedBiteVideoIsLoading}
                  onPress={handleEditVideo}
                  disabled={isControlsDisabledOnLoading && isLoading}
                  text={editButtonText}
                  border={theme.colors.primaryBlue}
                  fill={theme.colors.white}
                  textColor={theme.colors.primaryBlue}
                  dataSet={
                    isControlsDisabledOnLoading && isLoading ? editIntroButtonDisabledDataSet : editIntroButtonDataSet
                  }
                />
                <S.BottomButton
                  disabled={isMediaSizeExceeded}
                  isLoading={isContinueButtonLoading}
                  onPress={handleContinue}
                  content={t(`common.${isEditMode ? 'Save' : 'Continue'}`)}
                  dataSet={continueButtonDataSet}
                />
              </S.ButtonsWrapper>
            </>
          ) : (
            <CreateIntroButtons
              createMediaTitle={createMediaTitle}
              createMediaDescription={createMediaDescription}
              onCreateMediaPress={onWebVideoEditorContinue || !isWeb ? handleCreateMedia : undefined}
              onUploadPress={handleUploadPress}
              isCreateMediaDisabled={isVideoEditorOpen}
              onImportMediaPress={handleOpenImportMediaPanel}
              onOpenFromGalleryPress={handleOpenFromGalleryPress}
              onDraftPress={handleOpenDraft}
              isDraftDisabled={isLoadingDraft || isVideoEditorOpen}
            />
          )}

          {!isMediaVisible && !isEditMode && (
            <S.LineButton
              //@ts-ignore
              dataSet={skipIntroButtonDataSet}
              onPress={handlePressSkip}
            >
              <S.LineButtonText>{t('tellYourStory.skip')}</S.LineButtonText>
            </S.LineButton>
          )}
        </S.Center>
        {/* Youtube only */}
        <BlueAndRedButtonsModal
          title={t('confirmDeleteSection.replaceIntro.title')}
          description={t('confirmDeleteSection.replaceIntro.description')}
          isVisible={isReplaceWithYoutubeModalVisible}
          onClose={handleCloseAllModals}
          onRightButtonClick={handleReplaceIntroMediaWithYoutube}
          onLeftButtonClick={handleCloseAllModals}
          onModalHide={handleOpenNativeVideoEditor}
          rightButtonLabel={t('confirmDeleteModal.replace')}
          leftButtonLabel={t('confirmDeleteModal.cancel')}
          dataSet={replaceIntroDataset}
        />
        <BlueAndRedButtonsModal
          title={deleteConfirmTitle}
          description={deleteConfirmDescription}
          isVisible={isDeleteModalVisible}
          onClose={handleCloseAllModals}
          onRightButtonClick={handleRemoveIntroMedia}
          onLeftButtonClick={handleOpenReplaceMyStoryModal}
          rightButtonLabel={t('confirmDeleteModal.delete')}
          leftButtonLabel={t('confirmDeleteModal.replace')}
          dataSet={confirmDeleteButtonDataSet}
        />
        <BlueAndRedButtonsModal
          title={t('confirmGoBackModal.title')}
          description={t('confirmGoBackModal.description')}
          isVisible={isGoBackModalVisible}
          onClose={handleCloseAllModals}
          onRightButtonClick={handleGoBackModalRightButtonPress}
          onLeftButtonClick={handleCloseAllModals}
          rightButtonLabel={t('confirmGoBackModal.goBack')}
          leftButtonLabel={t('confirmGoBackModal.cancel')}
        />
        <BlueAndRedButtonsModal
          title={'Supported on \nGoogle Chrome only'}
          description={'Please use Google Chrome browser\n to create a video'}
          isVisible={isDveChromeOnly}
          onClose={handleCloseDveChromeOnly}
          // onRightButtonClick={handleReplaceIntroMediaWithYoutube}
          // onLeftButtonClick={handleCloseAllModals}
          // onModalHide={handleOpenNativeVideoEditor}
          rightButtonLabel={'Close'}
          // leftButtonLabel={t('confirmDeleteModal.cancel')}
          // dataSet={replaceIntroDataset}
        />
        <BaseModal
          title={t('replaceModal.intro.title')}
          description={t('replaceModal.intro.description')}
          isVisible={isReplaceMyStoryModalVisible}
          onClose={handleCloseReplaceMyStoryModal}
          onModalHide={handleOpenNativeVideoEditor}
        >
          <CreateIntroButtons
            createMediaTitle={createMediaTitle}
            createMediaDescription={createMediaDescription}
            onCreateMediaPress={onWebVideoEditorContinue || !isWeb ? handleCreateMedia : undefined}
            onUploadPress={handleUploadPress}
            isCreateMediaDisabled={isVideoEditorOpen}
            onImportMediaPress={handleOpenImportMediaPanel}
            onOpenFromGalleryPress={handleOpenFromGalleryPress}
            onDraftPress={handleOpenDraft}
            isDraftDisabled={isLoadingDraft || isVideoEditorOpen}
          />
        </BaseModal>
        <DraftModal
          isVisible={isDraftModalVisible}
          onClose={handleCloseDraftModal}
          onButtonPress={handleDraftModalButtonPress}
          onModalHide={handleOpenNativeVideoEditor}
        />
        <PowerpointModal isVisible={isPowerpointModalVisible} onClose={handleClosePowerpointModalAndOpenMediaPanel} />
        <YoutubeUploadModal
          ref={youtubeModalRef}
          onClose={reOpenImportMediaPanel}
          mediaControls={mediaControls}
          from={from}
        />
        {dropZoneUploadingForWeb}
        {thumbnailCanvas}
      </S.Content>
      {!isMediaVisible && <HelpersPanel config={elementsPanelConfig} renderCustomHeader={renderCustomHeader} />}
    </S.Container>
  );
};

const S = {
  YoutubeIcon: styled.TouchableOpacity``,
  PlayTouchable: styled.TouchableOpacity`
    position: absolute;
    align-self: center;
    z-index: 2;
  `,
  Center: styled.View`
    align-self: center;
    margin-bottom: ${calcHeight(125)}px;
  `,
  Content: styled.ScrollView`
    flex: 1;
    background-color: white;
  `,
  Container: styled.View`
    flex: 1;
  `,
  TitleWrapper: styled.View`
    max-width: ${calcWidth(330)}px;
    align-items: flex-start;
    margin-top: ${calcHeight(16)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  LineButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(22)}px;
    align-self: center;
  `,
  LineButtonText: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  ButtonsWrapper: styled.View<{ width: number }>`
    flex: 1;
    width: ${({ width }) => width}px;
    min-width: ${calcWidth(250)}px;
    margin-top: ${calcHeight(24)}px;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
  `,
  BottomButton: styled(GenericButton)`
    width: ${calcWidth(131)}px;
  `,
  EditButton: styled(Button)`
    max-width: ${calcWidth(180)}px;
    height: ${calcHeight(44)}px;
    padding: ${calcHeight(4)}px ${calcWidth(20)}px 0;
    align-items: center;
    justify-items: center;
  `,
  MediaContainer: styled.View<{ isMaxHeight?: boolean; videoSizes: { width: number; height: number } }>`
    overflow: hidden;
    justify-content: center;
    border-radius: 20px;
    width: ${({ videoSizes }) => videoSizes.width}px;
    ${({ videoSizes, isMaxHeight }) =>
      isMaxHeight ? `max-height: ${videoSizes.height}px` : `height: ${videoSizes.height}px`};
  `,
  MediaWrapper: styled.View`
    align-self: center;
    margin-top: ${calcHeight(24)}px;
  `,
  DeleteButton: {
    zIndex: 1,
    position: 'absolute',
    top: -calcHeight(30 / 2),
    right: -calcWidth(30 / 2),
  } as StyleProp<ViewProps>,
  FileExceededText: styled.Text`
    color: ${({ theme }) => theme.colors.failRed};
  `,
  FileExceededTextContainer: styled.View`
    margin-top: ${calcHeight(20)}px;
    align-items: center;
    justify-content: center;
  `,
};

export default TellYourStory;

function getIsChromeDesktop() {
  if (!isWeb || typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false;
  }

  if (!window?.navigator?.userAgent) {
    return false;
  }

  const userAgent = window.navigator.userAgent;
  const isChrome =
    userAgent.includes('Chrome') &&
    userAgent.includes('Safari') &&
    !userAgent.includes('Edg') &&
    !userAgent.includes('OPR') &&
    !userAgent.includes('Chromium') &&
    !userAgent.includes('Arc');

  const isDesktop = !/Mobi|Android/i.test(userAgent);

  return isChrome && isDesktop;
}
