import React, { useCallback } from 'react';

interface IProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type'> {}

const Checkbox: React.FC<IProps> = ({ checked, onChange, ...props }) => {
  const handleValueChange = useCallback(
    (event) => {
      onChange(event.target.checked);
    },
    [onChange],
  );

  return <input type='checkbox' checked={checked} onChange={handleValueChange} {...props} />;
};

export default Checkbox;
