import React, { FC } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Routes from '../../routes';
import PreviewBite from '../../../screens/Preview/PreviewBite';
import PreviewPlaylist from '../../../screens/Preview/PreviewPlaylist';
import Dashboard from '../../../screens/Preview/Dashboard';
import PreviewExploreBite from '../../../screens/Preview/PreviewExploreBite';
import { isWeb } from '../../../utils/dimensions';

interface IProps {}

const Stack = createStackNavigator();

const defaultScreenOptions = {
  animationEnabled: !isWeb,
  headerShown: false,
  cardStyle: {
    backgroundColor: 'white',
  },
};

const PreviewStack: FC<IProps> = ({}) => {
  return (
    <Stack.Navigator>
      <Stack.Screen name={Routes.PreviewStack.PreviewBite} component={PreviewBite} options={defaultScreenOptions} />
      <Stack.Screen
        name={Routes.PreviewStack.PreviewPlaylist}
        component={PreviewPlaylist}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name={Routes.PreviewStack.PreviewExploreBite}
        component={PreviewExploreBite}
        options={defaultScreenOptions}
      />
      <Stack.Screen name={Routes.PreviewStack.Dashboard} component={Dashboard} options={defaultScreenOptions} />
    </Stack.Navigator>
  );
};

export default PreviewStack;
