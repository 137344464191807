import styled from 'styled-components/native';
import React from 'react';

import { calcWidth } from '../../../utils/dimensions';
import ShadowedContainer from '../../ShadowedContainer';
import PointsIcon from '../../../assets/icons/creationFlow/buttons-rounded-icon.svg';

interface IProps {
  onPress: any;
  dataSet?: { [key: string]: string };
}

const EditBiteNameInput: React.FC<IProps> = ({ onPress, dataSet }) => {
  return (
    <ShadowedContainer>
      <S.HeaderButton
        onPress={onPress}
        //@ts-ignore
        dataSet={dataSet}
      >
        <PointsIcon />
      </S.HeaderButton>
    </ShadowedContainer>
  );
};

const S = {
  HeaderButton: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
};

export default EditBiteNameInput;
