import AnalyticsList from '../List';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchDataSelector,
  searchValuesErrorSelector,
  searchValuesLoadingSelector,
  searchValuesNextSelector,
} from '../../../../store/attributes/attributes.selector';
import { getSearchList } from '../../../../store/attributes/attributes.slice';
import styled from 'styled-components/native';
import { calcHeight, deviceHeight } from '../../../../utils/dimensions';
import { IAttributeValue } from '../../../../store/attributes/attributes.types';
import { log } from '../../../../store/appActivity/appActivity.slice';

interface IProps {
  selectedAttributeId: number;
  selectedValues: IAttributeValue[];
  selectedValuesMap: { [attributeId: number]: boolean };
  renderItem: (props: { item: IAttributeValue }) => React.ReactElement;
  dataSet?: { [key: string]: string };
}

const SearchList: React.FC<IProps> = ({
  selectedAttributeId,
  selectedValues,
  selectedValuesMap,
  renderItem,
  dataSet,
}) => {
  const dispatch = useDispatch();
  const isSearchDataLoading = useSelector(searchValuesLoadingSelector);
  const searchData = useSelector(searchDataSelector);
  const searchDataError = useSelector(searchValuesErrorSelector);
  const searchDataNext = useSelector(searchValuesNextSelector);

  const data = useMemo(() => {
    if (!searchDataNext && !isSearchDataLoading && selectedValues.length) {
      const selectedIdsInResultsMap = {};
      const result = [];

      searchData.forEach((value) => {
        if (selectedValuesMap[value.id]) {
          selectedIdsInResultsMap[value.id] = true;
        }

        result.push(value);
      });

      selectedValues.forEach((value) => {
        if (!selectedIdsInResultsMap[value.id]) {
          result.push(value);
        }
      });

      return result;
    }

    return searchData;
  }, [isSearchDataLoading, searchData, searchDataNext, selectedValues, selectedValuesMap]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'SearchList.handleEndReached',
        data: { selectedAttributeId, isSearchDataLoading, searchDataNext, searchDataError },
      }),
    );

    dispatch(getSearchList({ attributeId: selectedAttributeId }));
  }, [isSearchDataLoading, searchDataNext, searchDataError, dispatch, selectedAttributeId]);

  const handleEndReachedProp = useMemo(() => {
    if (isSearchDataLoading || !searchDataNext || searchDataError) {
      return;
    }

    return handleEndReached;
  }, [isSearchDataLoading, searchDataNext, searchDataError, handleEndReached]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'SearchList.handleErrorRefresh',
        data: { selectedAttributeId },
      }),
    );

    dispatch(getSearchList({ attributeId: selectedAttributeId }));
  }, [dispatch, selectedAttributeId]);

  const handleRefresh = useCallback(
    ({ callback }) => {
      dispatch(
        log({
          event: 'SearchList.handleRefresh',
          data: { selectedAttributeId },
        }),
      );

      dispatch(getSearchList({ attributeId: selectedAttributeId, reset: true, callback }));
    },
    [dispatch, selectedAttributeId],
  );

  return (
    <S.AnalyticsList
      dataSet={dataSet}
      isLoading={isSearchDataLoading}
      onEndReached={handleEndReachedProp}
      onErrorRefresh={handleErrorRefresh}
      onRefresh={handleRefresh}
      error={searchDataError}
      data={data}
      renderItem={renderItem}
    />
  );
};

const S = {
  AnalyticsList: styled(AnalyticsList)`
    width: 100%;
    max-height: ${deviceHeight - calcHeight(100)}px;
    margin-bottom: ${calcHeight(13)}px;
  `,
};

export default memo(SearchList);
