import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import BlueArrow from '../../../../../assets/icons/feed/blue-arrow.svg';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../../utils/dimensions';
import ChevronRightIcon from '../../../../../assets/icons/menu/chevron-right.svg';
import { PAGE_WIDTH } from '../../../../../components/shared/PreviewControlPanel/constants';
import { ETabType } from './useInspirationBitesTabs';
import { StyleProp, ViewStyle } from 'react-native';
import Label from './Label';
import GenericLabel from './Shared/GenericLabel';

interface IProps {
  tabs: any[];
  selectedUseCasesMap: { [key: string]: boolean };
  selectedIndustriesMap: { [key: string]: boolean };
  toggleTabIsSelected: (props: { type: ETabType; id: string }) => void;
  selectAllTabs: () => void;
  showSecondTitle: boolean;
}

const blueArrowStyle: StyleProp<ViewStyle> = {
  width: calcWidth(40),
  height: calcHeight(40),
  zIndex: 15,
  marginLeft: calcWidth(150),
  position: 'absolute',
  marginTop: calcHeight(70),
};
const scrollViewStyle = { height: 50 };

const ExploreLabels: React.FC<IProps> = ({
  tabs,
  selectedUseCasesMap,
  selectedIndustriesMap,
  toggleTabIsSelected,
  selectAllTabs,
  showSecondTitle,
}) => {
  const { t } = useTranslation();
  const scrollRef = useRef(null);
  const nextPageOffset = PAGE_WIDTH / 2;
  const [rightScrollingWidth, setRightScrollingWidth] = useState(nextPageOffset);
  const [leftScrollingWidth, setLeftScrollingWidth] = useState(nextPageOffset);

  const areMapsEmpty = useMemo(() => {
    return Object.keys(selectedUseCasesMap).length + Object.keys(selectedIndustriesMap).length === 0;
  }, [selectedIndustriesMap, selectedUseCasesMap]);

  useEffect(() => {
    if (areMapsEmpty) {
      scrollRef.current.scrollTo({ x: 0 });
    }
  }, [areMapsEmpty, selectedIndustriesMap, selectedUseCasesMap]);

  const scrollLeftPress = useCallback(() => {
    scrollRef.current.scrollTo({ x: leftScrollingWidth, y: 0, animated: true });
    setLeftScrollingWidth(leftScrollingWidth - PAGE_WIDTH / 2);
  }, [leftScrollingWidth]);

  const scrollRightPress = useCallback(() => {
    scrollRef.current.scrollTo({ x: rightScrollingWidth, y: 0, animated: true });
    setRightScrollingWidth(rightScrollingWidth + PAGE_WIDTH / 2);
  }, [rightScrollingWidth]);

  return (
    <S.Wrapper>
      <S.Scroller>
        {isWeb && (
          <S.LeftArrowContainer onPress={scrollLeftPress}>
            <ChevronRightIcon />
          </S.LeftArrowContainer>
        )}
        <ScrollView ref={scrollRef} horizontal={!isWeb} showsHorizontalScrollIndicator={false} style={scrollViewStyle}>
          <S.Container>
            <GenericLabel onPress={selectAllTabs} text={t('inpirationBitesTabs.all')} isPressed={areMapsEmpty} />
            {tabs.map((item) => (
              <Label
                item={item}
                key={item.id}
                selectedUseCasesMap={selectedUseCasesMap}
                selectedIndustriesMap={selectedIndustriesMap}
                toggleTabIsSelected={toggleTabIsSelected}
              />
            ))}
          </S.Container>
        </ScrollView>
        {isWeb && (
          <S.RightArrowContainer onPress={scrollRightPress}>
            <ChevronRightIcon />
          </S.RightArrowContainer>
        )}
      </S.Scroller>
      {showSecondTitle && <S.SecondaryTitle>{t('homeScreen.exploreTitle')}</S.SecondaryTitle>}
      {isWeb && showSecondTitle && <BlueArrow style={blueArrowStyle} />}
    </S.Wrapper>
  );
};

const S = {
  Wrapper: styled.View`
    margin-bottom: ${calcHeight(27)}px;
  `,
  Container: styled.View`
    height: ${calcHeight(30)}px;
    flex-direction: row;
    ${isWeb
      ? ''
      : `padding-right: ${calcWidth(10)}px;
    padding-left: ${calcWidth(10)}px;`}
  `,
  SecondaryTitle: styled.Text`
    font-size: ${({ theme }) => (isWeb ? theme.fontSizes.s14 : theme.fontSizes.s11)};
    color: ${({ theme }) => theme.colors.gray19};
    line-height: ${({ theme }) => theme.fontSizes.s14};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
  `,
  Scroller: styled.View`
    flex-direction: row;
    margin-top: ${calcHeight(20)}px;
  `,
  RightArrowContainer: styled.TouchableOpacity`
    margin-top: ${calcHeight(8)}px;
    margin-left: ${calcWidth(8)}px;
  `,
  LeftArrowContainer: styled.TouchableOpacity`
    transform: rotate(180deg);
    justify-content: center;
    margin-bottom: ${calcHeight(18)}px;
    margin-right: ${calcWidth(8)}px;
    margin-left: ${calcWidth(8)}px;
  `,
};

export default ExploreLabels;
