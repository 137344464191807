import React, { FC } from 'react';
import styled from 'styled-components/native';
import Switch from './Switch';
import { calcFontSize, calcHeight } from '../../utils/dimensions';

interface IProps {
  isSelected: boolean;
  label: string;
  onToggle: () => void;
  dataSet?: Record<string, string>;
}

const SwitchWithText: FC<IProps> = ({ isSelected, onToggle, label, dataSet }) => {
  return (
    <S.Container>
      <S.SwitchContainer
        //@ts-ignore
        dataSet={dataSet}
      >
        <Switch isOn={isSelected} onToggle={onToggle} />
      </S.SwitchContainer>
      <S.SettingText>{label}</S.SettingText>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex-direction: row;
    align-items: center;
    height: ${calcHeight(43)}px;
  `,
  SettingText: styled.Text`
    max-width: ${calcHeight(180)}px;
    color: ${({ theme }) => theme.colors.darkGray6};
    font-size: ${calcFontSize(17)}px;
  `,
  SwitchContainer: styled.View``,
};

export default SwitchWithText;
