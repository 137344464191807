import { DISPLAY_FRAMES_PER_SECOND } from '../Timeline.web';

export const formatTime = (dimension: 'h' | 'm' | 's' | 'f', num: number) => {
  const time = { h: 0, m: 0, s: 0, f: 0 };

  if (dimension === 'f') {
    time.f = num % DISPLAY_FRAMES_PER_SECOND;
    num = Math.floor(num / DISPLAY_FRAMES_PER_SECOND);
    dimension = 's';
  }

  if (dimension === 's') {
    time.s = num % 60;
    num = Math.floor(num / 60);
    dimension = 'm';
  }

  if (dimension === 'm') {
    time.m = num % 60;
    num = Math.floor(num / 60);
    dimension = 'h';
  }

  if (dimension === 'h') {
    time.h = num;
  }

  const result: string[] = [];
  if (time.h > 0) {
    result.push(`${time.h}h`);
  }
  if (time.m > 0) {
    result.push(`${time.m}m`);
  }
  if (time.s > 0) {
    result.push(`${time.s}s`);
  }
  if (time.f > 0) {
    result.push(`${time.f}f`);
  }

  return result.join(' ');
};
