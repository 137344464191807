import { useCallback, useEffect, useMemo, useState } from 'react';
import { EUploadStatus } from '../types/bite';
import { useDispatch, useSelector } from 'react-redux';
import { introSubtitlesLinesSelector } from '../store/createBite/createBite.selectors';
import { ILine, ISubtitles, ISubtitlesLines } from '../types/subtitles';
import formatSubtitles from '../utils/formatSubtitles';
import { fetchIntroSubtitlesLines, updateIntroSubtitlesLines } from '../store/createBite/createBites.actions';
import { useIsFocused } from '@react-navigation/native';

const secondsToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

const useIntroSubtitles = (subtitlesToDisplay?: ISubtitles) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const linesOriginalData: ISubtitlesLines = useSelector(introSubtitlesLinesSelector);

  const [linesData, setLinesData] = useState<ILine[]>(null);
  const [initialLinesData, setInitialLinesData] = useState<ILine[]>(null);

  const subtitlesAvailable = useMemo(
    () => subtitlesToDisplay?.upload_status === EUploadStatus.done,
    [subtitlesToDisplay],
  );

  const setLines = useCallback(
    (updatedLine) => {
      const lines = linesData.reduce((acc, line, index) => {
        if (line.num === updatedLine.num) {
          return [...acc, updatedLine];
        }

        if (index > 0 && acc[index - 1].endTime > line.startTime) {
          const startTime = acc[index - 1].endTime;
          const startTimeText = secondsToTime(startTime);
          const endTime = startTime + line.endTime - line.startTime;
          const endTimeText = secondsToTime(endTime);

          return [
            ...acc,
            {
              ...line,
              startTime,
              startTimeText,
              endTime,
              endTimeText,
              time_line: `00:${startTimeText}.000 --> 00:${endTimeText}.000`,
            },
          ];
        }

        return [...acc, line];
      }, []);

      setLinesData(lines);
      dispatch(updateIntroSubtitlesLines(lines));
    },
    [dispatch, linesData],
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (
      subtitlesToDisplay &&
      subtitlesAvailable &&
      (!linesOriginalData?.lines || linesOriginalData?.id !== subtitlesToDisplay.id)
    ) {
      dispatch(fetchIntroSubtitlesLines(subtitlesToDisplay.id));
    }
  }, [linesOriginalData, dispatch, subtitlesAvailable, subtitlesToDisplay, isFocused]);

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (!linesOriginalData || (subtitlesToDisplay && subtitlesToDisplay.id !== linesOriginalData.id)) {
      setLinesData(null);
      setInitialLinesData(null);
      return;
    }

    if (linesData) {
      return;
    }

    const lines = formatSubtitles(linesOriginalData.lines);

    setLinesData(lines);
    setInitialLinesData(lines);
  }, [subtitlesToDisplay, linesData, linesOriginalData, isFocused]);

  return {
    subtitlesAvailable,
    initialLinesData,
    linesData,
    linesOriginalData,
    setLines,
  };
};

export default useIntroSubtitles;
