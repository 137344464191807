import React, { memo } from 'react';
import { IPanelChildInjectedProps } from '../../../../store/bottomPanel/bottomPanel';
import useI18n from '../../../../hooks/useI18n';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../utils/dimensions';
import languages from '../../../../utils/languages';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';

const LanguageSelectMenu: React.FC<IPanelChildInjectedProps> = ({ onClosePanel }) => {
  const { i18n, changeLanguage } = useI18n();
  const dispatch = useDispatch();

  const handleChangeLang = (lang) => {
    onClosePanel(() => {
      dispatch(
        trackEvent({
          event: 'change_language',
          props: { from_language: i18n.language, to_language: lang },
        }),
      );
      changeLanguage(lang);
    });
  };

  return (
    <S.Container>
      {languages.map(({ lang, title }) => (
        <S.LanguageWrapper key={lang} onPress={() => handleChangeLang(lang)}>
          <S.Title isActive={i18n.language === lang}>{title}</S.Title>
        </S.LanguageWrapper>
      ))}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
  `,
  LanguageWrapper: styled.TouchableOpacity`
    height: ${calcHeight(75)}px;
    border-color: ${({ theme }) => theme.colors.gray17};
    border-bottom-width: 1px;

    align-items: center;
    justify-content: center;
  `,
  Title: styled.Text<{ isActive: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryBlue : theme.colors.gray18)};
  `,
};

export default memo(LanguageSelectMenu);
