import { Font, fonts, fontsLoaded, fontsLoading } from './text/fonts';
import { logError } from '../../../store/appActivity/appActivity.slice';
import store from '../../../store';
import { filterFonts } from './filterFonts';

export const loadFont = async (filter: Partial<Font>) => {
  const fontOption = filterFonts(fonts, filter)[0];

  if (!fontOption || fontsLoading[fontOption.fileName] || fontsLoaded[fontOption.fileName]) {
    return;
  }

  fontsLoading[fontOption.fileName] = true;

  const font = new FontFace(fontOption.label, `url(/assets/fonts/${fontOption.fileName})`, {
    style: fontOption.italic ? 'italic' : 'normal',
    weight: fontOption.weight ? `${fontOption.weight}` : undefined,
  });

  try {
    const loadedFont = await font.load();
    document.fonts.add(loadedFont);

    fontsLoaded[fontOption.fileName] = true;
  } catch (error) {
    store.dispatch(
      logError({
        event: 'TextSettings font load error',
        data: {
          error,
        },
      }),
    );

    delete fontsLoading[fontOption.fileName];

    throw error;
  }
};
