import React, { useEffect, useReducer, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import { View, ActivityIndicator, Alert } from 'react-native';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useComponentActions from './useComponentActions';
import useComponentReducer from './useComponentReducer';
import OptionsList from './components/OptionsList';
import { isWeb } from '../../../utils/dimensions';
import { ICheckBox } from './types';

import GenericModal from '../GenericModal/GenericModal';
import { TextInput } from '../../shared';

import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { shareWithIntegration } from '../../../store/api/bites-api/calls/biteShare.calls';

interface IProps {
  isVisible: boolean;
  closeModal: () => void;
  onShare?: () => void;
  shareId: number;
  isPlaylist?: boolean;
}

const TeamsShareModal: React.FC<IProps> = ({ isVisible, closeModal, shareId, isPlaylist, onShare }) => {
  const { reducer, initialState } = useComponentReducer();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { onToggleCheckBox, getTeamsUsersList, isLoading, setIsLoading, onSharedComplete } = useComponentActions(
    dispatch,
    closeModal,
  );

  const activeOrganization = useSelector(activeOrganizationSelector);
  const { searchString, usersList } = state;
  const { t } = useTranslation();

  const selectedUsersIds: null | number[] = useMemo(() => {
    const result = usersList.filter((user: ICheckBox) => user.isSelect).map((user) => user.id);
    return result.length === 0 ? null : result;
  }, [usersList]);

  const isDisabledShare = useMemo(() => {
    return !selectedUsersIds;
  }, [selectedUsersIds]);

  const onModalClose = () => {
    closeModal();
    dispatch({ type: 'initModal' });
  };

  useEffect(() => {
    if (isVisible) {
      getTeamsUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, activeOrganization.id]);

  const shareOptionsList = useMemo(() => {
    const listOptions = state.usersDisplayedList;
    return {
      listOptions,
    };
  }, [state.usersDisplayedList]);

  const handleShare = async () => {
    try {
      await onShareTry();
    } catch (err) {
      onShareError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onShareTry = async () => {
    setIsLoading(true);

    await shareWithIntegration({
      [isPlaylist ? 'playlist' : 'bite_share']: shareId,
      users: selectedUsersIds,
      integration: 'teams',
    });

    const sharedMsg = t('share.biteShared');
    isWeb ? toast(sharedMsg) : Alert.alert(sharedMsg);
    onSharedComplete();
    if (typeof onShare === 'function') {
      onShare();
    }
  };

  const onShareError = (err) => {
    console.error(err);
    Alert.alert(
      'Something went wrong',
      'Please try again',
      [
        {
          text: 'OK',
          onPress: onSharedComplete,
        },
      ],
      { cancelable: true, onDismiss: onSharedComplete },
    );
  };

  return (
    <GenericModal
      isVisible={isVisible}
      header={t('share.shareWithTeams')}
      confirmButton={{
        content: t('common.share'),
        onPress: handleShare,
        disabled: isLoading || isDisabledShare,
      }}
      cancelButton={{
        content: t('common.cancel'),
        onPress: onModalClose,
        disabled: isLoading,
      }}
    >
      <View>
        <S.BodyTopPart>
          <S.LeftController>Users</S.LeftController>

          <TextInput
            value={searchString}
            onChangeText={(text) => {
              //@ts-ignore
              dispatch({ type: 'search', payload: { text } });
            }}
            placeholder={t('common.search')}
          />
        </S.BodyTopPart>
        {isLoading ? (
          <View style={{ margin: 40 }}>
            <ActivityIndicator />
          </View>
        ) : (
          <OptionsList {...shareOptionsList} onToggleCheckBox={onToggleCheckBox} />
        )}
      </View>
    </GenericModal>
  );
};

const S = {
  BodyTopPart: styled.View`
    display: flex;
    align-items: center;
    ${({ theme }) => css`
      background-color: ${theme.colors.lightGray1};
      padding: 8px;
    `};
  `,
  LeftController: styled.Text`
    margin: 8px;
    ${({ theme }) => css`
      font-size: ${theme.fontSizes.s18}px;
    `};
  `,
};

export default TeamsShareModal;
