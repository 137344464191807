import { IUser, IUserOrganizations } from '../store/auth/auth.types';

export interface IProps {
  isActive?: boolean;
  isCreator?: boolean;
  includeId1?: boolean;
  contentAdmin?: boolean;
}

const getOrganizations = (
  user: IUser,
  { isActive, isCreator, includeId1, contentAdmin }: IProps = {},
): IUserOrganizations[] => {
  isActive = isActive ?? true;

  const isMyBitesEmail = /@mybites\.io$/.test(user?.email);
  includeId1 = includeId1 ?? isMyBitesEmail;

  const orgs =
    user?.organizations.filter(({ is_opted_out, is_creator, content_admin, id }) => {
      return (
        (isActive ? !is_opted_out : true) &&
        (isCreator ? is_creator : true) &&
        (!includeId1 ? id !== 1 : true) &&
        (contentAdmin ? content_admin : true)
      );
    }) || [];

  return orgs;
};

export default getOrganizations;
