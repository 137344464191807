export enum EEnvironment {
  PROD = 'prod',
  QA = 'qa',
}

export const IS_NOTIFICATION_PERMISSIONS_MODAL_SHOWN = 'is-notification-permissions-modal-shown';

// this is the minimum chuck size for multipart upload - 5mb
export const DEFAULT_MULTI_UPLOAD_PART_SIZE = 5 * 1024 * 1024; // 5MB
export const DEFAULT_PARALLEL_UPLOADS = 10;

export const MAX_RETRIES_DEFAULT = 5;
