import { RootState } from '..';
import { IUser, IUserOrganizations } from './auth.types';
import { getDefaultOrganization } from '../../utils/formatDataFromServer';

export const authSelector = (state: RootState) => state.auth;

export const authInProgressSelector = (state: RootState) => state.auth.authSuite.auth.isLoading;

export const isPhoneMissingSelector = (state: RootState) => state.auth.isPhoneMissing;

export const errorCodesSelector = (state: RootState) => state.auth.errorCodes;

export const isAuthErrorSelector = (state: RootState) => state.auth.authError;

export const isSsoErrorSelector = (state: RootState) => state.auth.ssoError;

export const userSelector = (state: RootState): IUser => authSelector(state).user;

export const leadNameSelector = (state: RootState) => authSelector(state).leadName;

// TODO: remove after stats stage 2 feature finished
export const organizationsListSelector = (state: RootState) => {
  return authSelector(state).user?.organizations?.filter((org) => org.id !== 1 && !org.is_opted_out) || [];
};

export const isContentAdminSelector = (state: RootState) => {
  const activeOrganization = activeOrganizationSelector(state);
  return activeOrganization?.content_admin;
};

export const isContentCreatorSelector = (state: RootState) => {
  const activeOrganization = activeOrganizationSelector(state);
  return activeOrganization?.is_creator;
};

export const activeOrganizationSelector = (state: RootState) => {
  const organizations = authSelector(state).user?.organizations;
  const { activeOrganizationId } = authSelector(state);

  if (organizations) {
    return organizations.find((o) => o.id === activeOrganizationId) || getDefaultOrganization(organizations);
  }
  return {} as IUserOrganizations;
};

export const isResetPasswordInProgressSelector = (state: RootState) =>
  state.auth.authSuite.newRecoverPassword.isLoading;

export const isForgotEmailInProgressSelector = (state: RootState) => state.auth.authSuite.passwordRecovery.isLoading;

export const isWrongVerificationSelector = (state: RootState) => state.auth.isWrongVerification;

export const isProfileLoadingSelector = (state: RootState) => state.auth.authSuite.userProfile.isLoading;

export const profileErrorsSelector = (state: RootState) => state.auth.authSuite.userProfile.errors;

export const resetPasswordErrorSelector = (state: RootState) => state.auth.authSuite.newRecoverPassword.error;

export const isDisplayLogoutFramesSelector = (state: RootState) => state.auth.isDisplayLogoutFrames;
