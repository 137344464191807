import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../../utils/dimensions';
import CloseIcon from '../../../assets/icons/close.svg';
import ShadowedContainer from '../../ShadowedContainer';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { closeIconPanelDataSet, closePanelDataSet } from './BottomSheet.constants';
import { log } from '../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import HeaderHandle from '../ElementsPanel/common/HeaderHandle';

interface IProps {
  title?: (isOpen: boolean) => React.ReactNode | string;
  fontSize?: number;
  height?: number;
  withCloseButton?: boolean;
  isVisibleInitial?: boolean;
  renderCustomHeader?: (isVisible: boolean) => React.ReactNode;
  children: React.ReactNode;
}

const ANIMATION_TIME = 200;

const BottomSheet: React.FC<IProps> = ({
  title,
  height = calcHeight(deviceHeight * 0.7),
  withCloseButton = false,
  children,
  isVisibleInitial = false,
  fontSize,
  renderCustomHeader,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(isVisibleInitial);

  const handleOpenBottomSheet = useCallback(() => {
    dispatch(
      log({
        event: 'BottomSheet: handleOpenBottomSheet',
      }),
    );
    setIsVisible(true);
  }, [dispatch]);

  const handleCloseBottomSheet = useCallback(() => {
    dispatch(
      log({
        event: 'BottomSheet: handleCloseBottomSheet',
      }),
    );
    setIsVisible(false);
  }, [dispatch]);

  const toggleBottomSheet = useCallback(() => {
    if (isVisible) {
      handleCloseBottomSheet();
      return;
    }
    handleOpenBottomSheet();
  }, [handleCloseBottomSheet, handleOpenBottomSheet, isVisible]);

  const animatedHeight = useSharedValue(isVisible ? height : calcHeight(85));

  useEffect(() => {
    animatedHeight.value = isVisible ? height : calcHeight(85);
  }, [height, animatedHeight, isVisible]);

  const handleSwipe = useCallback(
    ({ nativeEvent }) => {
      if (nativeEvent.oldState !== State.ACTIVE) {
        return;
      }

      if (nativeEvent.translationY < 0) {
        handleOpenBottomSheet();
        return;
      }

      handleCloseBottomSheet();
    },
    [handleCloseBottomSheet, handleOpenBottomSheet],
  );

  const animatedStyle = useAnimatedStyle(
    () => ({
      width: isWeb ? deviceWidth - calcWidth(20) : deviceWidth,
      backgroundColor: '#fff',
      alignItems: 'center',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      maxHeight: withTiming(animatedHeight.value, {
        duration: ANIMATION_TIME,
        easing: Easing.linear,
      }),
      minHeight: withTiming(animatedHeight.value, {
        duration: ANIMATION_TIME,
        easing: Easing.linear,
      }),
    }),
    [animatedHeight],
  );

  const renderHeader = useCallback(() => {
    if (title) {
      if (withCloseButton) {
        return (
          <>
            <S.EmptyView />
            <S.TitleContainer>
              <HeaderHandle isOpen={isVisible} />
              <S.TitleWrapper>
                {typeof title === 'string' ? <S.Title fontSize={fontSize}>{title}</S.Title> : title(isVisible)}
              </S.TitleWrapper>
            </S.TitleContainer>
            {isVisible ? (
              <S.CloseButton
                // @ts-ignore
                dataSet={closeIconPanelDataSet}
                onPress={handleCloseBottomSheet}
              >
                <CloseIcon fill={theme.colors.primaryBlue} />
              </S.CloseButton>
            ) : (
              <S.EmptyView />
            )}
          </>
        );
      }
      return (
        <S.TitleContainer>
          <HeaderHandle isOpen={isVisible} />
          <S.TitleWrapper>{typeof title === 'string' ? <S.Title>{title}</S.Title> : title(isVisible)}</S.TitleWrapper>
        </S.TitleContainer>
      );
    }

    return <HeaderHandle isOpen={isVisible} />;
  }, [fontSize, handleCloseBottomSheet, isVisible, theme.colors.primaryBlue, title, withCloseButton]);

  return (
    <>
      {isVisible && <S.Overlay onPress={handleCloseBottomSheet} />}
      <S.Container>
        <ShadowedContainer>
          {/*Fragment needed to avoid crash with animated component inside shadow*/}
          <>
            <Animated.View style={animatedStyle}>
              <PanGestureHandler onHandlerStateChange={handleSwipe}>
                <S.Header
                  activeOpacity={0.9}
                  onPressOut={toggleBottomSheet}
                  spaceBetween={withCloseButton}
                  // @ts-ignore
                  dataSet={closePanelDataSet}
                >
                  {typeof renderCustomHeader === 'function' ? renderCustomHeader(isVisible) : renderHeader()}
                </S.Header>
              </PanGestureHandler>
              {isVisible && <S.ContentContainer>{children}</S.ContentContainer>}
            </Animated.View>
          </>
        </ShadowedContainer>
      </S.Container>
    </>
  );
};

const S = {
  Container: styled.View`
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: ${isWeb ? calcWidth(10) : 0}px;
    width: ${isWeb ? deviceWidth - calcWidth(20) : deviceWidth}px;
  `,
  Header: styled.TouchableOpacity<{ spaceBetween?: boolean }>`
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'center')};
  `,
  TitleContainer: styled.View`
    flex: 5;
    margin: ${calcHeight(20)}px 0 0;
    max-width: 80%;
    align-items: center;
  `,
  Title: styled.Text<{ fontSize?: number }>`
    font-size: ${({ fontSize, theme }) => (fontSize ? fontSize : theme.fontSizes.s22)}px;
    text-align: center;
  `,
  ContentContainer: styled.View`
    flex: 1;
  `,
  EmptyView: styled.View`
    flex: 1;
  `,
  CloseButton: styled.TouchableOpacity`
    flex: 1;
    width: 20px;
    height: 20px;
  `,
  Overlay: styled.TouchableOpacity`
    position: absolute;
    top: 0;
    left: 0;
    width: ${deviceWidth}px;
    height: ${deviceHeight}px;
    z-index: 1;
  `,
  TitleWrapper: styled.View`
    margin-top: ${calcHeight(10)}px;
  `,
};

export default BottomSheet;
