import { useEffect, useState } from 'react';
import BitesApi from '../store/api/bites-api/index';
import { VimeoLink, VimeoLinksResponse } from '../types/media';

const useVimeoLink = (videoLinkRequired: boolean, vimeoVideoId: string = '1234566') => {
  const [isFetchingLink, setIsFetchingLink] = useState(false);
  const [isVideoStillDecoding, setVideoStillDecoding] = useState(false);
  const [fetchingLinkError, setFetchingLinkError] = useState('');
  const [vimeoVideoUri, setVimeoVideoUri] = useState<null | string>(null);

  useEffect(() => {
    if (videoLinkRequired && vimeoVideoId) {
      fetchVideoLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vimeoVideoId]);

  const fetchVideoLinks = async () => {
    try {
      setIsFetchingLink(true);
      const res = await BitesApi.get(`media/video_links/?videoId=${vimeoVideoId}`);
      const vimeoLinksResponse = res.data as VimeoLinksResponse;
      if (vimeoLinksResponse.error) {
        setFetchingLinkError(vimeoLinksResponse.error);
      } else if (!vimeoLinksResponse.length) {
        setVideoStillDecoding(true);
      } else {
        const small: VimeoLink[] = [];
        const large: VimeoLink[] = [];
        let s3: VimeoLink = null;
        vimeoLinksResponse.forEach((element) => {
          if (element.width === 0) {
            s3 = element;
            return;
          }
          if (element.quality === 'hls') {
            return;
          }
          if (parseInt(element.rendition, 10) < 720) {
            small.push(element);
            return;
          }
          large.push(element);
        });
        small.sort((a, b) => parseInt(a.rendition, 10) - parseInt(b.rendition, 10)); //from smallest to largest
        large.sort((a, b) => parseInt(b.rendition, 10) - parseInt(a.rendition, 10)); //from biggest to smallest

        const highestQualityVideo = small.length > 0 ? small.pop() : large.length > 0 ? large.pop() : s3;
        setVimeoVideoUri(highestQualityVideo.link);
      }

      setIsFetchingLink(false);
    } catch (error) {
      setFetchingLinkError('Error Loading the required video');
    }
  };

  return {
    isFetchingLink,
    isVideoStillDecoding,
    fetchingLinkError,
    vimeoVideoUri,
  };
};

export default useVimeoLink;
