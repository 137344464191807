import React, { memo, useCallback, useEffect, useState } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { StyleProp, TextInputProps, ViewStyle, I18nManager } from 'react-native';
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import PasswordHiddenIcon from '../../../../assets/icons/password-hidden.svg';
import PasswordVisibleIcon from '../../../../assets/icons/password-visible.svg';
import { IS_ANDROID } from '../../../../utils/constants/env';
import Label from './common/Label';
import i18n from '../../../../locale/i18n';

interface IProps extends Omit<TextInputProps, 'style'> {
  style?: StyleProp<ViewStyle>;
  inputStyles?: TextInputProps['style'];
  label?: string;
  error?: string;
  password?: boolean;
  onEnterPress?: () => void;
}

const expandedErrorHeight = calcHeight(40);

const FormTextInput: React.FC<IProps> = ({
  value,
  onChangeText,
  style,
  inputStyles,
  label,
  error,
  password,
  onEnterPress,
  ...props
}) => {
  const isRtl = isWeb ? i18n?.dir(i18n.language) === 'rtl' : I18nManager.isRTL;
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(!!value?.length);

  const colorsShared = useSharedValue(0); // 0 - default, 1 - focused, 2 - error

  const [isPasswordVisible, setIsPasswordVisible] = useState(!password);

  const inputContainerAnimatedStyles = useAnimatedStyle(() => {
    return {
      borderColor: interpolateColor(
        colorsShared.value,
        [0, 1, 2],
        [theme.colors.lightGray34, theme.colors.primaryBlue, theme.colors.pinkError],
      ),
    };
  }, [colorsShared]);

  useEffect(() => {
    if (isFocused) {
      colorsShared.value = withTiming(1);
      return;
    }

    if (error) {
      colorsShared.value = withTiming(2);
      return;
    }

    if (isFilled) {
      colorsShared.value = withTiming(1);
      return;
    }

    colorsShared.value = withTiming(0);
  }, [colorsShared, error, isFocused, isFilled]);

  const errorAnimatedStyles = useAnimatedStyle(
    () => ({
      height: withTiming(error ? expandedErrorHeight : 0, { duration: 300 }),
      opacity: withTiming(error ? 1 : 0, { duration: 400 }),
    }),
    [colorsShared, error],
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChangeText = useCallback(
    (text) => {
      setIsFilled(!!text.length);
      onChangeText?.(text);
    },
    [onChangeText],
  );

  const handleSecurePress = useCallback(() => {
    setIsPasswordVisible((prev) => !prev);
  }, []);

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key === 'Enter' && typeof onEnterPress === 'function') {
        onEnterPress();
      }
    },
    [onEnterPress],
  );

  return (
    <S.Container style={style}>
      {!!label && <Label isRtl={isRtl} label={label} isShifted={isFilled || isFocused} />}
      <S.InputContainer isRtl={isRtl} style={inputContainerAnimatedStyles}>
        {/* @ts-ignore */}
        <S.Input
          isRtl={isRtl}
          value={value}
          onChangeText={handleChangeText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={inputStyles}
          password={password}
          secureTextEntry={!isPasswordVisible}
          onKeyPress={handleEnterPress}
          {...props}
          placeholderTextColor={theme.colors.lightGray5}
        />
        {!!password && (
          <S.SecureButton isRtl={isRtl} onPress={handleSecurePress}>
            {isPasswordVisible ? <PasswordHiddenIcon /> : <PasswordVisibleIcon />}
          </S.SecureButton>
        )}
      </S.InputContainer>
      {!!error && <S.ErrorText style={errorAnimatedStyles}>{error}</S.ErrorText>}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: ${calcWidth(297)}px;
  `,
  InputContainer: styled(Animated.View)<{ isRtl?: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    height: ${calcHeight(IS_ANDROID ? 59 : 49)}px;
    border: 1px solid ${({ theme }) => theme.colors.lightGray34};
    border-radius: ${calcHeight(59 / 2)}px;
    overflow: hidden;
  `,
  Input: styled.TextInput<{ password?: boolean; isRtl?: boolean }>`
    flex: 1;
    height: 100%;
    width: 100%;
    text-align: ${({ isRtl }) => (isRtl ? 'right' : 'left')};
    padding: ${calcWidth(IS_ANDROID ? 0 : 16)}px ${calcHeight(22)}px;
    ${({ password, isRtl }) =>
      password
        ? isRtl && isWeb
          ? css`
              padding-left: ${calcWidth(52)}px;
            `
          : css`
              padding-right: ${calcWidth(52)}px;
            `
        : undefined}
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.white};
  `,
  ErrorText: styled(Animated.Text)`
    text-align: center;
    width: ${calcWidth(297)}px;
    font-size: ${({ theme }) => theme.fontSizes.s13};
    line-height: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.pinkError};
    align-self: center;
  `,
  SecureButton: styled.TouchableOpacity<{ isRtl?: boolean }>`
    position: absolute;
    height: 100%;
    top: ${calcHeight(12)}px;
    align-items: center;
    ${({ isRtl }) => (isRtl ? 'left' : 'right')}: ${calcWidth(20)}px;
  `,
};

export default memo(FormTextInput);
