import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MultiChoiceIcon from '../../../../../assets/icons/creationFlow/multi-choice-icon.svg';
import OpenEndedIcon from '../../../../../assets/icons/creationFlow/open-ended-icon.svg';
import SurveyIcon from '../../../../../assets/icons/creationFlow/survey-icon.svg';
import { questionSuggestionSelector } from '../../../../../store/createBite/createBite.selectors';
import { EQuestionType } from '../../../../../types/biteQuestion';
import ShadowedButton from '../../../ShadowedButton';
import {
  addMultichoiceQuestionContinueBtnDataSet,
  addOpenEndedQuestionContinueBtnDataSet,
  addSurveyQuestionContinueBtnDataSet,
} from './questionButtons.constants';

interface IProps {
  addMultiChoiceQuestion: () => void;
  addOpenEndedQuestion: () => void;
  addSurveyQuestion: () => void;
  selectedQuestionType?: EQuestionType;
}

const QuestionButtons: React.FC<IProps> = ({
  addMultiChoiceQuestion,
  addOpenEndedQuestion,
  addSurveyQuestion,
  selectedQuestionType,
}) => {
  const { t } = useTranslation();
  const questionSuggestion = useSelector(questionSuggestionSelector);

  const renderMultiChoiceIcon = useCallback(() => <MultiChoiceIcon />, []);
  const renderOpenEndedIcon = useCallback(() => <OpenEndedIcon />, []);
  const renderSurveyIcon = useCallback(() => <SurveyIcon />, []);

  return (
    <>
      <ShadowedButton
        disabled={selectedQuestionType === EQuestionType.MULTI_CHOICE}
        renderIcon={renderMultiChoiceIcon}
        title={t('addQuestion.multiChoice')}
        description={t('addQuestion.multiChoiceDescription')}
        withAISuggestions={!!questionSuggestion?.multiChoice}
        // @ts-ignore
        dataSet={addMultichoiceQuestionContinueBtnDataSet}
        onPress={addMultiChoiceQuestion}
      />

      <ShadowedButton
        disabled={selectedQuestionType === EQuestionType.OPEN_ENDED}
        renderIcon={renderOpenEndedIcon}
        title={t('addQuestion.openEnded')}
        description={t('addQuestion.openEndedDescription')}
        withAISuggestions={!!questionSuggestion?.open}
        dataSet={addOpenEndedQuestionContinueBtnDataSet}
        onPress={addOpenEndedQuestion}
      />

      <ShadowedButton
        disabled={selectedQuestionType === EQuestionType.SURVEY}
        renderIcon={renderSurveyIcon}
        title={t('addQuestion.survey')}
        description={t('addQuestion.surveyDescription')}
        withAISuggestions={!!questionSuggestion?.survey}
        // @ts-ignore
        dataSet={addSurveyQuestionContinueBtnDataSet}
        onPress={addSurveyQuestion}
      />
    </>
  );
};

export default QuestionButtons;
