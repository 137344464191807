import { useRef } from 'react';
import { useNavigationState } from '@react-navigation/native';

const useIsCurrentScreen = () => {
  const routesLength = useNavigationState((state) => {
    return state.routes.length;
  });
  const state = useRef({
    routePosition: routesLength - 1,
  });
  return state.current.routePosition === routesLength - 1;
};

export default useIsCurrentScreen;
