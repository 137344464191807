import React from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, WEB_CONTAINER_WIDTH } from '../../../utils/dimensions';
import { inputUrlDataSet } from './UrlInput.constants';

interface IProps {
  placeholder: string;
  value: string;
  onChangeText: (text: string) => void;
  selectTextOnFocus?: boolean;
  fullWidth?: boolean;
}

const UrlInput: React.FC<IProps> = ({
  placeholder,
  value,
  onChangeText,
  selectTextOnFocus = false,
  fullWidth = false,
}) => {
  return (
    <S.UrlInput
      selectTextOnFocus={selectTextOnFocus}
      placeholder={placeholder}
      placeholderTextColor='#707175'
      value={value}
      onChangeText={onChangeText}
      fullWidth={fullWidth}
      // @ts-ignore
      dataSet={inputUrlDataSet}
    />
  );
};

const S = {
  UrlInput: styled.TextInput<{ fullWidth?: boolean }>(
    ({ theme, fullWidth }) => css`
      min-height: ${calcHeight(71)}px;
      max-height: ${calcHeight(71)}px;
      width: ${fullWidth ? WEB_CONTAINER_WIDTH - calcWidth(40) : calcWidth(325)}px;
      border-color: ${theme.colors.primaryBlue};
      border-width: 1px;
      border-radius: 30px;
      margin-top: ${calcHeight(25)}px;
      padding-right: ${calcWidth(50)}px;
      padding-left: ${calcWidth(25)}px;
      font-family: ${theme.fontFamilies.GilroyMedium};
      color: ${theme.colors.text};
      font-size: ${theme.fontSizes.s18}px;
    `,
  ),
};

export default UrlInput;
