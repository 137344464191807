import React, { useCallback, useState } from 'react';
import ElementsPanel from '../../components/shared/ElementsPanel/ElementsPanel';
import {
  IElementsPanelItem,
  IElementsPanelConfig,
  IElementsPanelContent,
} from '../../components/shared/ElementsPanel/types';
import VideoModal from '../../components/shared/VideoModal';
import redirectTo from '../../utils/restoreFlows/restoreBiteFlow';
import { EDeepLinkSection, EDeepLinkContentType, EDeepLinkMessageType } from '../../utils/restoreFlows/types';
import { EAction } from '../../components/shared/ElementsPanel/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { log } from '../../store/appActivity/appActivity.slice';

interface IProps {
  config: IElementsPanelConfig;
  renderCustomHeader?: ({ isOpen }: { isOpen: boolean }) => React.ReactNode;
}

const HelpersPanel: React.FC<IProps> = ({ config, renderCustomHeader }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IElementsPanelItem>();

  const handleOpenVideoModal = useCallback(() => {
    dispatch(
      log({
        event: 'HelpersPanel: handleOpenVideoModal',
        data: {
          item: selectedItem,
        },
      }),
    );

    setIsVideoModalVisible(true);
  }, [dispatch, selectedItem]);

  const handleCloseVideoModal = useCallback(() => {
    dispatch(
      log({
        event: 'HelpersPanel: handleCloseVideoModal',
      }),
    );
    setIsVideoModalVisible(false);
  }, [dispatch]);

  const handleUseTemplatePress = useCallback(() => {
    if (selectedItem?.data.biteId) {
      dispatch(
        log({
          event: 'HelpersPanel: handleUseTemplatePress',
          data: {
            biteId: selectedItem?.data.biteId,
            section: EDeepLinkSection.INTRO,
          },
        }),
      );

      redirectTo[EDeepLinkContentType.BITE][EDeepLinkMessageType.CREATE](
        selectedItem?.data.biteId,
        EDeepLinkSection.INTRO,
      );
    }
    handleCloseVideoModal();
  }, [dispatch, handleCloseVideoModal, selectedItem?.data.biteId]);

  const handleItemPress = useCallback(
    (item: IElementsPanelItem, section: IElementsPanelContent) => {
      const sectionIndex = config.sections.findIndex((el) => el.id === section.id);
      const itemIndex = section.items.findIndex((el) => el.label === item.label);

      dispatch(
        log({
          event: 'HelpersPanel: handleItemPress',
          data: {
            sectionIndex,
            sectionTitle: section?.title,
            itemIndex,
            item,
          },
        }),
      );

      setSelectedItem(item);
      handleOpenVideoModal();
    },
    [config, dispatch, handleOpenVideoModal],
  );

  const getHandleVideoModalAction = useCallback(() => {
    switch (selectedItem?.data.action) {
      case EAction.TEMPLATE:
        return handleUseTemplatePress;
      case EAction.VIDEO:
      default:
        return null;
    }
  }, [handleUseTemplatePress, selectedItem?.data.action]);

  const handleVideoModalAction = getHandleVideoModalAction();

  if (!config || !config.isActive) {
    return null;
  }

  return (
    <>
      <ElementsPanel data={config} onItemPress={handleItemPress} renderCustomHeader={renderCustomHeader} />
      <VideoModal
        isVisible={isVideoModalVisible}
        videoUrl={selectedItem?.data?.videoUrl}
        btnRightLabel={
          handleVideoModalAction ? t(`helpers.videoModal.${selectedItem?.data?.action}`) : t('helpers.videoModal.close')
        }
        btnRightStyle={handleVideoModalAction ? 'primary' : 'secondary'}
        onBtnRightClick={handleVideoModalAction || handleCloseVideoModal}
        btnLeftLabel={t('helpers.videoModal.close')}
        btnLeftStyle={'secondary'}
        onBtnLeftClick={handleVideoModalAction ? handleCloseVideoModal : null}
      />
    </>
  );
};

export default HelpersPanel;
