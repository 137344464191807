import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

interface IProps {
  src: string;
  video?: HTMLVideoElement;
  type?: string;
  isAutoPlay: boolean;
  isFocused: boolean;
}

const Source: React.FC<IProps> = ({ src, video, type, isAutoPlay, isFocused }) => {
  const extension = src.split('?')[0].split('#')[0].split('.').pop().toLowerCase();

  const hlsRef = useRef(null);

  useEffect(() => {
    if (!Hls.isSupported() || extension !== 'm3u8') {
      return;
    }

    hlsRef.current = new Hls({
      xhrSetup: function (xhr) {
        xhr.withCredentials = true; // do send cookie
      },
    });
    hlsRef.current.loadSource(src);
    hlsRef.current.attachMedia(video);
    hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
      if (isAutoPlay && isFocused) {
        video.play();
      }
    });

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return <source src={src} type={type} />;
};

export default Source;
