import React, { memo, useCallback } from 'react';
import EnterCodeForm from './EnterCodeForm';
import { loginWithPhone } from '../../../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlowSelector, phoneFormSelector } from '../../../../store/authForm/authForm.selectors';
import { EAuthFlowType, EAuthFormType } from '../../auth.types';
import { v4 as uuid } from 'uuid';
import { log } from '../../../../store/appActivity/appActivity.slice';

const EnterPhoneCode = () => {
  const dispatch = useDispatch();

  const phone = useSelector(phoneFormSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = EAuthFormType.ENTER_CODE_PHONE;

  const handleContinue = useCallback(
    (otp: string) => {
      const processId = uuid();

      dispatch(
        log({
          event: 'EnterPhoneCode.handleContinue',
          processId,
          data: { username: phone.trim(), otp },
        }),
      );

      dispatch(
        loginWithPhone({
          credentials: {
            username: phone.trim(),
            otp,
          },
          options: {
            isSignup: currentFlow === EAuthFlowType.SIGN_UP,
          },
          processId,
        }),
      );
    },
    [currentFlow, dispatch, phone],
  );

  const handleResend = useCallback(() => {
    const processId = uuid();

    dispatch(
      log({
        event: 'EnterPhoneCode.handleResend',
        processId,
        data: {
          currentFlow,
          currentForm,
          username: phone.trim(),
        },
      }),
    );

    dispatch(
      loginWithPhone({
        credentials: {
          username: phone.trim(),
        },
      }),
    );
  }, [currentFlow, currentForm, dispatch, phone]);

  return <EnterCodeForm onContinue={handleContinue} onResend={handleResend} destination={phone} />;
};

export default memo(EnterPhoneCode);
