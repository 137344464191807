import Routes from '../../navigation/routes';
import { navigationRef } from '../../navigation/RootNavigation';
import store from '../../store';
import { getBiteDistributions, prepareAnalytics } from '../../store/analytics/analytics.slice';
import { getStatsOverview } from '../../store/analyticsOverview/analyticsOverview.slice';
import { getOrganizationAttributes } from '../../store/attributes/attributes.slice';
import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';
import { CommonActions } from '@react-navigation/native';
import { getAnalyticsStatsRoute } from '../restoreFlows/getAnalyticsRoutes';
import { log } from '../../store/appActivity/appActivity.slice';

const openBiteStats = ({ biteId, tab }: { biteId: number; tab?: EAnalyticsScreenTabs }) => {
  store.dispatch(log({ event: 'openBiteStats', data: { biteId, tab } }));

  store.dispatch(prepareAnalytics(biteId));
  store.dispatch(getStatsOverview());

  const orgId = store.getState().bite.bitesMap[biteId].organization;
  store.dispatch(getOrganizationAttributes({ organizationId: orgId }));
  store.dispatch(getBiteDistributions());

  if (tab) {
    const navigationState = getAnalyticsStatsRoute(tab);
    navigationRef.current?.dispatch(CommonActions.reset(navigationState));
    return;
  }

  navigationRef.current.navigate(Routes.AnalyticsStack.StackName, {
    screen: Routes.AnalyticsStack.BiteOverview,
  });
};

export default openBiteStats;
