import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import MapIcon from '../../../assets/icons/creationFlow/map-icon.svg';
import StoryIcon from '../../../assets/icons/biteIntro/story-icon.svg';
import QuestionIcon from '../../../assets/icons/creationFlow/question-icon.svg';
import SummaryIcon from '../../../assets/icons/creationFlow/summary-icon.svg';
import ShadowedContainer from '../../../components/ShadowedContainer';
import { Button } from '../../../components/shared';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { trackEvent, updateUserOrgPreferences } from '../../../store/appActivity/appActivity.slice';
import Routes from '../../../navigation/routes';
import { continueBtnDataSet } from './createBiteInfo.constants';
import { StackNavigationProp } from '@react-navigation/stack';

const INFO = [
  {
    title: 'createBiteInfo.story',
    description: 'createBiteInfo.storyDescription',
    Icon: StoryIcon,
  },
  {
    title: 'createBiteInfo.question',
    description: 'createBiteInfo.questionDescription',
    Icon: QuestionIcon,
  },
  {
    title: 'createBiteInfo.summary',
    description: 'createBiteInfo.summaryDescription',
    Icon: SummaryIcon,
  },
];

const CreateBiteInfoScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const onGetStartedButtonPress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'lets_get_started_bite',
      }),
    );
    navigation.navigate(Routes.CreateBiteStack.BiteTellYourStory);
  }, [dispatch, navigation]);

  const onContinuePress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'continue_dont_show_again_bite',
      }),
    );
    dispatch(
      updateUserOrgPreferences({
        skip_create_bite_flow_overview_screen: true,
      }),
    );
    // @ts-ignore
    navigation.replace(Routes.CreateBiteStack.BiteTellYourStory);
  }, [dispatch, navigation]);

  return (
    <S.Container>
      <S.Center>
        <S.TitleWrapper>
          <S.Title>{t('createBiteInfo.createABite')}</S.Title>
        </S.TitleWrapper>
        <ShadowedContainer>
          <S.Content>
            {INFO.map(({ Icon, title, description }, index) => (
              <S.ContentBlock key={title}>
                <S.ContentNumber>{index + 1}</S.ContentNumber>
                <S.IconWrapper>
                  <Icon height={calcHeight(39)} width={calcWidth(39)} />
                </S.IconWrapper>
                <S.ContentTextWrapper>
                  <S.ContentTitle>{t(title)}</S.ContentTitle>
                  <S.ContentDescription>{t(description)}</S.ContentDescription>
                </S.ContentTextWrapper>
              </S.ContentBlock>
            ))}
          </S.Content>
        </ShadowedContainer>
        <S.MapWrapper>
          <MapIcon />
        </S.MapWrapper>
        <S.InfoText>{t('createBiteInfo.shareInfo')}</S.InfoText>
        <S.Button
          onPress={onGetStartedButtonPress}
          content={t('createBiteInfo.buttonText')}
          // @ts-ignore
          dataSet={continueBtnDataSet}
        />
        <S.ContinueButton onPress={onContinuePress}>
          <S.ContinueText>{t('createBiteInfo.continue')}</S.ContinueText>
        </S.ContinueButton>
      </S.Center>
    </S.Container>
  );
};

const S = {
  Center: styled.View`
    align-self: center;
  `,
  InfoText: styled.Text`
    align-self: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray11};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    line-height: ${({ theme }) => theme.fontSizes.s13 * 1.31};
    margin-top: ${calcHeight(4)}px;
    max-width: ${calcWidth(180)}px;
  `,
  MapWrapper: styled.View`
    width: 100%;
    padding-left: ${calcWidth(30)}px;
  `,
  Container: styled.View`
    flex: 1;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  TitleWrapper: styled.View`
    width: 100%;
    align-items: flex-start;
    margin-top: ${calcHeight(16)}px;
    margin-bottom: ${calcHeight(24)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  Content: styled.View`
    padding: 0 0 ${calcHeight(16)}px 0;
    width: ${calcWidth(327)}px;
    height: auto;
    border-radius: ${calcWidth(29)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ContentBlock: styled.View`
    flex-direction: row;
    align-items: center;
    padding: ${calcHeight(16)}px 0 ${calcHeight(8)}px ${calcWidth(24)}px;
  `,
  ContentNumber: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  IconWrapper: styled.View`
    margin-left: ${calcWidth(12)}px;
    margin-right: ${calcWidth(12)}px;
  `,
  ContentTextWrapper: styled.View`
    align-items: flex-start;
  `,
  ContentTitle: styled.Text`
    width: ${isWeb ? calcWidth(200) : deviceWidth - calcWidth(180)}px;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin-bottom: ${calcHeight(2)}px;
    margin-top: ${calcHeight(7)}px;
  `,
  ContentDescription: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s13};
    color: ${({ theme }) => theme.colors.gray11};
    max-width: ${calcWidth(190)}px;
    text-align: left;
  `,
  Button: styled(Button)`
    height: ${calcHeight(50)}px;
    padding-left: ${calcWidth(155)}px;
    padding-right: ${calcWidth(155)}px;
    align-self: center;
    margin-top: ${calcHeight(24)}px;
  `,
  ContinueButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(36)}px;
    align-self: center;
  `,
  ContinueText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default CreateBiteInfoScreen;
