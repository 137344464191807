import { ISubtitlesLine } from './subtitles';
import {
  EExportType,
  EVideoEditorEntryPoint,
  IBanubaDraft,
  IVideoEditorResult,
} from '../services/videoEditor/videoEditor';
import { GestureResponderEvent } from 'react-native';
import { PickerErrorCode } from '../services/ImagePicker';
import { IVideoMeta } from '../hooks/useMedia/useMedia';

export interface IMediaSubtitles {
  uri: string;
  language: string;
}

export interface IMedia {
  id: number;
  bite_section: number | null;
  file?: string | null;
  file_type?: EFileType;
  cloud_service?: null;
  url?: string | null;
  media_url: string | null;
  image_url: string | null;
  video_start_at: null | number;
  video_ends_at?: null | number;
  video_duration: null | number; // not needed
  is_cc_enabled?: boolean;
  auto_play_muted: boolean;
  created_at: string;
  isVideoPathLocal?: boolean;
  subtitles?: IMediaSubtitles;
  embed_version?: string;
  original_embed?: string;
  width?: number;
  height?: number;
}

export enum EFileType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  YOUTUBE = 'youtube',
  GOOGLE_SLIDES = 'google_slides',
}

export type MediaType =
  | EFileType.IMAGE
  | EFileType.VIDEO
  | EFileType.AUDIO
  | EFileType.YOUTUBE
  | EFileType.GOOGLE_SLIDES;

interface IPreviewMediaLaunch {
  imageUrl: string;
  callback: () => void;
}

interface ILaunchVideoEditor {
  entryPoint?: EVideoEditorEntryPoint;
  draftId?: string;
  videoUrl?: string;
  localVideoUri?: string;
  exportType?: EExportType;
}

export interface ILaunchVideoEditorResult extends IVideoEditorResult {
  videoMeta: IVideoMeta;
}
export type TLaunchVideoEditor = (props: ILaunchVideoEditor) => Promise<ILaunchVideoEditorResult | null | void>;

export interface MediaController {
  media: IMedia;
  mediaURI: string | null | undefined;
  mediaType: MediaType;
  mimeType?: string;
  videoMeta?: IVideoMeta;
  youtubeMedia: YoutubeMedia | null;
  editorError: boolean;
  uploadYoutube: () => Promise<{ media: any; thumbnailUrl: string }>;
  uploadImage: () => Promise<{ media: any }>;
  uploadVideo: () => Promise<{ taskId: string; mediaUri: string }>;
  uploadError: boolean;
  clearState: () => void;
  launchVideoEditor: TLaunchVideoEditor;
  launchImageCamera: (handlePreviewModal?: IPreviewMediaLaunch) => void;
  launchLibrary: () => void;
  launchImageLibrary: (event?: GestureResponderEvent, handlePreviewModal?: IPreviewMediaLaunch) => void;
  setYoutubeMedia: (youtubeMedia: YoutubeMedia) => void;
  dropZoneUploadingForWeb: any;
  mediaFileForWeb: any;
  lastDraft: IBanubaDraft;
  isMediaSizeExceeded: boolean;
  setIsMediaSizeExceeded: (isMediaSizeExceeded: boolean) => void;
  handleSelectOnlineMedia: (url: string) => void;
  setGoogleSlidesMedia: (media: GoogleSlidesMedia) => void;
}

export interface ImageController {
  mediaURI: string | null | undefined;
  mediaType: 'image' | null | undefined;
  youtubeMedia: null;
  editorError: boolean;
  uploadMedia: (
    onSuccess: (media: IMedia) => void,
    onStartUploading: (taskId: string | null) => void,
    onUploadError?: () => void,
  ) => void;
  isUploading: boolean;
  uploadError: boolean;
  clearState: () => void;
  launchVideoCamera: () => void;
  launchImageCamera: () => void;
  launchLibrary: () => void;
  launchImageLibrary: () => void;
  setYoutubeMedia: () => void;
}

export interface ImageController {
  mediaURI: string | null | undefined;
  mediaType: 'image' | null | undefined;
  youtubeMedia: null;
  editorError: boolean;
  uploadMedia: (
    onSuccess: (media: IMedia) => void,
    onStartUploading: (taskId: string | null) => void,
    onUploadError?: () => void,
  ) => void;
  isUploading: boolean;
  uploadError: boolean;
  clearState: () => void;
  launchVideoCamera: () => void;
  launchImageCamera: () => void;
  launchLibrary: () => void;
  launchImageLibrary: () => void;
  setYoutubeMedia: () => void;
}

export interface YoutubeMedia {
  videoId?: string;
  url: string | null;
  video_start_at: number | null;
  video_end_at: number | null;
  is_cc_enabled?: boolean;
}

export type IMediaViewerMethods = {
  seek: (n: number) => void;
  pause: () => void;
  play: () => void;
};
export type IVideoDetails = {
  width: number;
  height: number;
  duration?: number;
};
export type IMediaProgress = {
  currentTime: number;
  duration: number;
};

export type MediaViewerProps = {
  disablePlayPause?: boolean;
  disableSeekbar?: boolean;
  disableVolume?: boolean;
  disableFullscreen?: boolean;
  disableTimer?: boolean;
  paused?: boolean;
  tapAnywhereToPause?: boolean;
  onRemoveMedia?: () => void;
  setRef?: (ref: any) => void;
  mediaURI: string | null | undefined;
  subtitles?: IMediaSubtitles;
  lines?: ISubtitlesLine[];
  mediaType?: MediaType;
  youtubeMedia?: YoutubeMedia | null;
  videoLinkRequired?: boolean;
  onEnterFullscreen?: () => void;
  onExitFullscreen?: () => void;
  onProgress?: (progress: IMediaProgress) => void;
  onEnd?: () => void;
  onMethods?: (methods: IMediaViewerMethods) => void;
  onMediaDetails?: (videoDetails: IVideoDetails) => void;
  resizeMode?: 'contain' | 'cover' | 'fill' | 'none';
  isAutoPlay?: boolean;
  isMutedInitial?: boolean;
  isLessControlMode?: boolean;
  withPlayButton?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  initialSizes?: {
    width: number;
    height: number;
  };
  subtitlesLocale?: string;
};

type VimeoLinksError = {
  error: string;
};
export type VimeoLink = {
  quality: 'sd' | 'hd' | 'hls';
  type: string;
  width: number;
  height: number;
  link: string;
  created_time: string;
  fps: number;
  size: number;
  md5: string;
  rendition: string;
};

export type PickerError = Error & {
  code: PickerErrorCode;
};

export type VimeoLinksResponse = VimeoLink[] & VimeoLinksError;

export type GoogleSlidesMedia = {
  loop: boolean;
  start: boolean;
  width: number;
  height: number;
  delayms: number;
  media_url: string;
  original_embed: string;
  embed_version: string;
  file_type: string;
};
