import React, { FC } from 'react';
import { I18nManager, TouchableWithoutFeedback } from 'react-native';
import styled, { css } from 'styled-components/native';
import BiteLogo from '../../assets/images/bites-logo-text.png';
import { EAuthFlowType } from '../../utils/constants/auth';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import CustomText from './CustomText';

interface IProps {
  type?: EAuthFlowType;
  onLogoClick?: () => void;
}

const BiteLogoAndText: FC<IProps> = ({ onLogoClick }) => {
  return (
    <S.Container>
      {!isWeb && (
        <TouchableWithoutFeedback onPress={onLogoClick}>
          <S.Logo source={BiteLogo} resizeMode='contain' onLogoClick={onLogoClick} />
        </TouchableWithoutFeedback>
      )}
      <S.HeaderTitle>Create Your First Bite Today</S.HeaderTitle>
    </S.Container>
  );
};

const Container = styled.View`
  overflow: ${() => (isWeb ? 'hidden' : 'visible')};
  text-align: ${() => (isWeb ? 'center' : 'left')};
`;

const Logo = styled.Image<{ onLogoClick: () => void }>`
  ${({ onLogoClick }) => isWeb && onLogoClick && 'cursor: pointer'};
  width: ${calcWidth(74)}px;
  height: ${calcHeight(36)}px;
  align-self: ${I18nManager.isRTL ? 'flex-end' : 'flex-start'};
`;

const HeaderTitle = styled(CustomText)<{ bold?: boolean }>(
  ({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    margin: ${calcHeight(24)}px 0 ${calcHeight(16)}px;
    text-align: center;
    font-family: ${theme.fontFamilies.UniSans};
    font-size: ${() => (isWeb ? 19 : `${theme.fontSizes.s19}px`)};
    letter-spacing: 0.8px;
  `,
);

const S = { Container, Logo, HeaderTitle };

export default BiteLogoAndText;
