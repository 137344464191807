import Types from './auth.types';
import { IUser } from './auth.types';

import { formatActiveOrgIdFromServer } from '../../utils/formatDataFromServer';
import { setCurrentFlow, setCurrentForm } from '../authForm/authForm.slice';

const initState = {
  user: null as IUser,
  firstName: '',
  lastName: '',
  activeOrganizationId: 0,
  leadName: '',
  isAuthenticated: false,
  isFinishedInitAuth: false,
  authError: false,
  ssoError: false,
  errorCodes: [],
  ssoInProgress: false,
  profileImageUrlToUpload: null,
  isPhoneMissing: false,
  isWrongVerification: false,
  authSuite: {
    auth: {
      isLoading: false,
    },
    signin: {
      errors: [],
      withPassword: false,
    },
    signup: {
      errors: [],
    },
    passwordRecovery: {
      isLoading: false,
    },
    newRecoverPassword: {
      isLoading: false,
      error: null,
    },
    userProfile: {
      isLoading: false,
      errors: [],
    },
  },
  isDisplayLogoutFrames: false,
};

type State = typeof initState;

const authReducer = (state = initState, action: any): State => {
  switch (action.type) {
    case Types.LOGIN:
    case Types.LOGIN_WITH_PHONE_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: true,
          },
          signin: {
            ...state.authSuite.signin,
            errors: [],
          },
        },
      };
    case Types.SET_CODE_SENT:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case Types.SET_IS_PHONE_MISSING:
      return {
        ...state,
        isPhoneMissing: action.payload,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case Types.SET_WRONG_VERIFICATION_CODE:
      return {
        ...state,
        authError: false,
        isWrongVerification: action.payload,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
          signin: {
            ...state.authSuite.signin,
            errors: [],
          },
        },
      };
    case Types.SET_AUTH_ERROR_CODES:
      return {
        ...state,
        authError: true,
        errorCodes: action.payload.errorCodes,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };
    case Types.SIGNUP_WITH_EMAIL_REQUEST:
    case Types.SIGNUP_WITH_PHONE_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: true,
          },
          signup: {
            errors: [],
          },
        },
      };
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        authError: false,
        ssoError: false,
        isWrongVerification: false,
        user: action.payload.user,
        activeOrganizationId: formatActiveOrgIdFromServer(action.payload.user.organizations),
      };
    }

    case Types.STOP_LOGIN_LOADING:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          auth: {
            isLoading: false,
          },
        },
      };

    case Types.LOGIN_FAILURE:
      return {
        ...state,
        authError: true,
        isWrongVerification: false,
        authSuite: {
          ...state.authSuite,
          signin: {
            ...state.authSuite.signin,
            errors: [],
          },
        },
      };

    case Types.RESET_AUTH_ERRORS:
      return {
        ...state,
        ssoError: false,
        authError: false,
        isPhoneMissing: false,
        isWrongVerification: false,
        errorCodes: [],
      };
    case Types.INIT_AUTH_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        isFinishedInitAuth: true,
        ssoInProgress: false,
        user: action.payload.user,
        activeOrganizationId: formatActiveOrgIdFromServer(action.payload.user.organizations),
      };
    }

    case Types.REFRESH_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            isLoading: false,
            errors: [],
          },
        },
      };
    }

    case Types.REFRESH_USER_FAILURE: {
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            ...state.authSuite.userProfile,
            isLoading: false,
          },
        },
      };
    }

    case Types.SET_REFRESH_USER_ERRORS: {
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            ...state.authSuite.userProfile,
            errors: action.payload || [],
          },
        },
      };
    }

    case Types.CLEAR_USER_PROFILE_ERRORS_BY_TYPE:
      return (() => {
        const newErrors = state.authSuite.userProfile.errors.filter((errorCode) => !action.payload.includes(errorCode));

        if (newErrors.length === state.authSuite.userProfile.errors.length) {
          return state;
        }

        return {
          ...state,
          authSuite: {
            ...state.authSuite,
            userProfile: {
              ...state.authSuite.userProfile,
              errors: newErrors,
            },
          },
        };
      })();

    case Types.INIT_AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isFinishedInitAuth: true,
        ssoInProgress: false,
      };
    case Types.REGISTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case Types.SET_SSO_IN_PROGRESS:
      return {
        ...state,
        ssoInProgress: action.payload,
      };
    case Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        ssoInProgress: false,
        isFinishedInitAuth: true,
      };

    case Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_ERROR:
      return {
        ...state,
        ssoInProgress: false,
        ssoError: true,
      };
    case Types.SET_PROFILE_IMAGE_URL_TO_UPLOAD:
      return {
        ...state,
        profileImageUrlToUpload: action.payload,
      };
    case Types.LOGOUT:
      return {
        ...initState,
        isDisplayLogoutFrames: true,
      };
    case Types.SWITCH_ACTIVE_ORGANIZATION:
      return { ...state, activeOrganizationId: action.payload };
    case Types.UPDATE_USER_PROFILE: {
      return {
        ...state,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            isLoading: true,
            errors: [],
          },
        },
      };
    }
    case Types.FINALIZE_USER_PROFILE:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          userProfile: {
            isLoading: true,
            errors: [],
          },
        },
      };
    case Types.PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: false,
          },
        },
      };
    case Types.PASSWORD_RECOVERY_ERROR:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: false,
          },
        },
      };
    case Types.SET_LEAD_NAME:
      return { ...state, leadName: action.payload };
    case Types.PASSWORD_RECOVERY_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          passwordRecovery: {
            isLoading: true,
          },
        },
      };
    case Types.SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          newRecoverPassword: {
            error: null,
            isLoading: true,
          },
        },
      };
    case Types.SET_NEW_PASSWORD_REQUEST_LOADING:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          newRecoverPassword: {
            ...state.authSuite.newRecoverPassword,
            isLoading: action.payload,
          },
        },
      };
    case Types.SET_NEW_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        authSuite: {
          ...state.authSuite,
          newRecoverPassword: {
            ...state.authSuite.newRecoverPassword,
            error: action.payload,
          },
        },
      };
    case setCurrentFlow.type:
    case setCurrentForm.type:
      return {
        ...state,
        errorCodes: initState.errorCodes,
        authError: initState.authError,
        ssoError: initState.ssoError,
        authSuite: initState.authSuite,
      };
    default:
      return state;
  }
};

export default authReducer;
