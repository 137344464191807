import React, { FC, memo, useCallback, useRef } from 'react';
import { IStackNavigation } from '../../../navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { introMediaProcessingStatusSelector } from '../../../store/createBite/createBite.selectors';
import AIExpectation from './AIExpectation';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import Routes from '../../../navigation/routes';
import { log } from '../../../store/appActivity/appActivity.slice';
import { EImproves } from './aiExpectation.constants';

const IMPROVES = [
  EImproves.soundQuality,
  EImproves.backgroundNoise,
  EImproves.subtitles,
  EImproves.questions,
  EImproves.summary,
  EImproves.biteName,
  EImproves.biteCover,
];

interface IProps extends IStackNavigation {}

const AIExpectationFirst: FC<IProps> = ({ navigation, route }) => {
  const dispatch = useDispatch();

  const biteQuestion = useSelector(biteQuestionSelector);
  const isBiteQuestionCreated = biteQuestion.isCreated;

  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);

  const isProcessing =
    introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.INACTIVE ||
    introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.PROCESSING ||
    introMediaProcessingStatus.summarySuggestion === EIntroMediaProcessingStatus.PROCESSING ||
    introMediaProcessingStatus.questionSuggestion === EIntroMediaProcessingStatus.PROCESSING;

  const isAINotApplicable =
    !isProcessing &&
    (introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.NOT_APPLICABLE ||
      introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.ERROR);

  const isSuccess = !isProcessing && !isAINotApplicable;

  const openTsRef = useRef(Date.now());

  const handleContinue = useCallback(() => {
    const nextRoute = isBiteQuestionCreated
      ? Routes.CreateBiteStack.BiteEditQuestion
      : Routes.CreateBiteStack.AddQuestion;

    const statusLabel = isSuccess ? 'Success' : isAINotApplicable ? 'AI Not Applicable' : 'Processing';
    dispatch(
      log({
        event: `AIExpectationFirst: continue from ${statusLabel}`,
        data: {
          introMediaProcessingStatus,
          isBiteQuestionCreated,
          nextRoute,
          screenTs: Date.now() - openTsRef.current,
          statusLabel,
        },
      }),
    );

    navigation.replace(nextRoute, { isPrefilled: route.params?.isPrefilled });
  }, [
    dispatch,
    introMediaProcessingStatus,
    isAINotApplicable,
    isBiteQuestionCreated,
    isSuccess,
    navigation,
    route.params?.isPrefilled,
  ]);

  return (
    <AIExpectation
      isSuccess={isSuccess}
      isAINotApplicable={isAINotApplicable}
      improves={IMPROVES}
      onContinue={handleContinue}
    />
  );
};

export default memo(AIExpectationFirst);
