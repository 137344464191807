import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from './analyticsOverview.types';
import { resetAnalytics } from '../analytics/analytics.slice';

export const initialState: IInitialState = {
  views: {
    isLoading: true,
    isRequestInProgress: false,
    error: null,
    data: null,
  },
  comments: {
    isLoading: true,
    isRequestInProgress: false,
    error: null,
    data: null,
  },
  answers: {
    isLoading: true,
    isRequestInProgress: false,
    error: null,
    data: null,
  },
};

const ANALYTICS_OVERVIEW = 'ANALYTICS_OVERVIEW';

export const getStatsOverview = createAction(`${ANALYTICS_OVERVIEW}/getStatsOverview`);

const analyticsOverviewSlice = createSlice({
  name: ANALYTICS_OVERVIEW,
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getViewsNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.views.isLoading = true;
      state.views.error = null;
      state.views.isRequestInProgress = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getAnswersNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.answers.isLoading = true;
      state.answers.error = null;
      state.answers.isRequestInProgress = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCommentsNum: (state, payload: PayloadAction<{ biteIds: string[] }>) => {
      state.comments.isLoading = true;
      state.comments.error = null;
      state.comments.isRequestInProgress = true;
    },
    setViewsNum: (state, { payload }: PayloadAction<number>) => {
      state.views.data = payload;
      state.views.isLoading = false;
      state.views.isRequestInProgress = false;
    },
    setAnswersNum: (state, { payload }: PayloadAction<number>) => {
      state.answers.data = payload;
      state.answers.isLoading = false;
      state.answers.isRequestInProgress = false;
    },
    setCommentsNum: (state, { payload }: PayloadAction<number>) => {
      state.comments.data = payload;
      state.comments.isLoading = false;
      state.comments.isRequestInProgress = false;
    },
    setViewsNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.views.error = payload.error;
      state.views.isLoading = false;
      state.views.isRequestInProgress = false;
    },
    setAnswersNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.answers.error = payload.error;
      state.answers.isLoading = false;
      state.answers.isRequestInProgress = false;
    },
    setCommentsNumError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.comments.error = payload.error;
      state.comments.isLoading = false;
      state.comments.isRequestInProgress = false;
    },
    resetViewsNum: (state) => {
      state.views = initialState.views;
    },
    resetAnswersNum: (state) => {
      state.answers = initialState.answers;
    },
    resetCommentsNum: (state) => {
      state.comments = initialState.comments;
    },
  },
  extraReducers: {
    [resetAnalytics.type]: () => initialState,
  },
});

export const {
  getViewsNum,
  getAnswersNum,
  getCommentsNum,
  setViewsNum,
  setAnswersNum,
  setCommentsNum,
  setViewsNumError,
  setAnswersNumError,
  setCommentsNumError,
  resetViewsNum,
  resetAnswersNum,
  resetCommentsNum,
} = analyticsOverviewSlice.actions;

export default analyticsOverviewSlice.reducer;
