import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import store from '../../store';
import { setCurrentFlow, setCurrentForm } from '../../store/authForm/authForm.slice';
import { EAuthFlowType, EAuthFormType } from '../../screens/auth/auth.types';
import { log } from '../../store/appActivity/appActivity.slice';

const openSignUpForm = () => {
  store.dispatch(log({ event: 'openSignUpForm' }));
  store.dispatch(setCurrentForm(EAuthFormType.AUTH));
  store.dispatch(setCurrentFlow(EAuthFlowType.SIGN_UP));
  navigationRef.current.navigate(Routes.AuthStack.StackName, {
    screen: Routes.AuthStack.Auth,
  });
};
export default openSignUpForm;
