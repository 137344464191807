export enum EPanel {
  ORGANIZATIONS,
  NEW_OBJECT,
  FEED_SORTING,
  FEED_OBJECT_TYPE,
  CREATORS,
}

export interface IState {
  activeTab: Tabs;
  activePanel: EPanel;
}
export enum Tabs {
  FEED,
  EXPLORE,
}
