import { EDeepLinkContentType, EDeepLinkMessageType, EDeepLinkSection } from './types';
import { navigationRef } from '../../navigation/RootNavigation';
import store from '../../store';
import { fetchBiteToEditRequest, cloneAndFetchBite } from '../../store/bite/bite.actions';
import { CommonActions } from '@react-navigation/native';
import {
  getEditEnhancementsRoute,
  getEditIntroRoute,
  getEditQuestionRoute,
  getEditSummaryRoute,
} from './getEditRoutes';
import {
  getCreateEnhancementsRoute,
  getCreateIntroRoute,
  getCreateQuestionRoute,
  getCreateSummaryRoute,
} from './getCreateRoutes';
import {
  setBiteIntroEdited,
  setBiteIntroEnhancementsEdited,
  setBiteQuestionEdited,
  setBiteSummaryEdited,
} from '../../store/createBite/createBites.actions';

const fillCreateBiteFlow = async (biteId: number, section: EDeepLinkSection) => {
  store.dispatch(cloneAndFetchBite(biteId, () => redirectToCreateBiteFlow(biteId, section)));
};

const fillEditBiteFlow = async (biteId: number, section: EDeepLinkSection) => {
  store.dispatch(fetchBiteToEditRequest(biteId, () => redirectToEditBiteFlow(biteId, section)));
};

const redirectToCreateBiteFlow = (biteId: number, section: EDeepLinkSection) => {
  let navigationState;
  store.dispatch(setBiteIntroEdited(true));
  store.dispatch(setBiteQuestionEdited(true));
  store.dispatch(setBiteSummaryEdited(true));
  store.dispatch(setBiteIntroEnhancementsEdited(true));
  switch (section) {
    case EDeepLinkSection.INTRO:
      navigationState = getCreateIntroRoute();
      break;
    case EDeepLinkSection.QUESTION:
      navigationState = getCreateQuestionRoute();
      break;
    case EDeepLinkSection.SUMMARY:
      navigationState = getCreateSummaryRoute();
      break;
    case EDeepLinkSection.ENHANCEMENTS:
      navigationState = getCreateEnhancementsRoute();
      break;
  }

  navigationRef.current?.dispatch(CommonActions.reset(navigationState));
};

const redirectToEditBiteFlow = (biteId: number, section: EDeepLinkSection) => {
  let navigationState;
  switch (section) {
    case EDeepLinkSection.INTRO:
      navigationState = getEditIntroRoute(biteId);
      break;
    case EDeepLinkSection.QUESTION:
      navigationState = getEditQuestionRoute(biteId);
      break;
    case EDeepLinkSection.SUMMARY:
      navigationState = getEditSummaryRoute(biteId);
      break;
    case EDeepLinkSection.ENHANCEMENTS:
      navigationState = getEditEnhancementsRoute(biteId);
      break;
  }

  navigationRef.current?.dispatch(CommonActions.reset(navigationState));
};

const redirectTo = {
  [EDeepLinkContentType.BITE]: {
    [EDeepLinkMessageType.CREATE]: fillCreateBiteFlow,
    [EDeepLinkMessageType.EDIT]: fillEditBiteFlow,
  },
};

export default redirectTo;
