import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../store/auth/auth.selectors';
import getOrganizations, { IProps } from '../utils/getOrganizations';

const useOrganizations = ({ isActive, isCreator, includeId1 }: IProps = {}) => {
  const user = useSelector(userSelector);

  const orgs = useMemo(() => {
    return getOrganizations(user, { isActive, isCreator, includeId1 });
  }, [includeId1, isActive, isCreator, user]);

  return orgs;
};

export default useOrganizations;
