import { CommonActions, useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Routes from '../../../../navigation/routes';
import { fetchBiteToEditRequest } from '../../../../store/bite/bite.actions';
import BlueAndRedButtonsModal from '../../BlueAndRedButtonsModal';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  biteId: number;
}

const BiteEditionRestoreModal: React.FC<IProps> = ({ biteId }) => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(true);

  const handleOnDiscard = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleBiteEditionNavigation = useCallback(() => {
    dispatch(fetchBiteToEditRequest(biteId));

    navigation.dispatch(
      CommonActions.reset({
        index: 2,
        routes: [
          {
            name: Routes.HomeDrawer.StackName,
          },
          {
            name: Routes.EditBiteStack.StackName,
            params: {
              screen: Routes.EditBiteStack.EditMain,
              params: {
                biteId: biteId,
                isEditMode: true,
              },
            },
          },
          {
            name: Routes.EditBiteStack.StackName,
            params: {
              screen: Routes.EditBiteStack.EditMedia,
              params: {
                biteId: biteId,
                isEditMode: true,
                launchVideoEditorWithDrafts: true,
              },
            },
          },
        ],
      }),
    );
  }, [biteId, dispatch, navigation]);

  return (
    <BlueAndRedButtonsModal
      reverseColors={true}
      isVisible={isVisible}
      onModalHide={handleOnDiscard}
      title={t('restoreModal.biteEdition.title')}
      onRightButtonClick={handleBiteEditionNavigation}
      onLeftButtonClick={handleOnDiscard}
      leftButtonLabel={t('common.discard')}
      rightButtonLabel={t('restoreModal.biteEdition.button')}
    />
  );
};

export default BiteEditionRestoreModal;
