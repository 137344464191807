import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/native';
import GenericModal from '../../../../../../components/modals/GenericModal/GenericModal';
import { IUserOrganizations } from '../../../../../../store/auth/auth.types';
import { leaveUserOrganization } from '../../../../../../store/org/org.slice';
import {
  organizationLeaveShowStatusSelector,
  organizationLeaveRequestStatusSelector,
} from '../../../../../../store/org/org.selectors';
import { calcHeight, calcWidth } from '../../../../../../utils/dimensions';
import { getErrorMessage } from '../../../../../../utils/general';

import RenameIcon from '../../../../../../assets/icons/rename.svg';

interface IProps {
  organization: IUserOrganizations;
  onClose: () => void;
}

const OrganizationLeave: React.FC<IProps> = (props) => {
  const { organization, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isVisible = useSelector(organizationLeaveShowStatusSelector);
  const { isLoading, error } = useSelector(organizationLeaveRequestStatusSelector);

  const confirmButton = {
    content: t('common.leave'),
    disabled: isLoading,
    onPress: () => {
      if (isLoading) {
        return;
      }
      dispatch(leaveUserOrganization({ id: organization.user_organization_id }));
    },
  };

  const cancelButton = {
    content: t('common.stay'),
    disabled: isLoading,
    onPress: () => {
      onClose();
    },
  };

  return (
    <GenericModal isVisible={isVisible} confirmButton={confirmButton} cancelButton={cancelButton}>
      <RenameIcon
        style={{
          width: calcWidth(64),
          height: calcWidth(64),
          marginTop: calcHeight(24),
          marginBottom: calcHeight(12),
          alignSelf: 'center',
        }}
      />
      <S.Title>{t('organizationMenu.leaveConfirm')}</S.Title>
      {error ? <S.Error>{getErrorMessage(error)}</S.Error> : null}
    </GenericModal>
  );
};

const Title = styled.Text(
  ({ theme }) => css`
    width: ${calcWidth(188)}px;
    align-self: center;
    color: ${theme.colors.gray9};
    font-size: ${theme.fontSizes.s17};
    text-align: center;
    margin-bottom: ${calcHeight(41)}px;
  `,
);

const Error = styled.Text(
  ({ theme }) => css`
    color: ${theme.colors.failRed};
    font-size: ${theme.fontSizes.s13};
    text-align: center;
    margin-bottom: ${calcHeight(18)}px;
  `,
);

const S = {
  Title,
  Error,
};

export default OrganizationLeave;
