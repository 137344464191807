export interface IInitialState {
  displayMode: TDisplayMode;
  overviewAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IAttributeMap;
    percentage: IAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  viewsAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IAttributeMap;
    percentage: IAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  answersAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IAttributeMap;
    percentage: IAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  commentsAttributesMapById: {
    sortBy: ESortByType;
    sortDirection: ESortingDirection;
    absolute: IAttributeMap;
    percentage: IAttributeMap;
    isGuestsAttributesLoaded: boolean;
  };
  selectedValueIds: number[];
  filterAttributesMapById: IAttributeMap;
  initialAttributesMapById: IAttributeMap;
  sharedWithFilter: ESharedWithFilter[];
  filterSearchValue: string;
  searchValues: {
    isLoading: boolean;
    error?: string;
    data: IAttributeValue[];
    next: null | string;
  };

  organizationAttributes: {
    isLoading: boolean;
    error?: string;
    data: IAttribute[];
  };

  guestsAttribute: {
    isLoading: boolean;
    error?: string;
    data: IAttributeValue;
  };
  guestsAttributeAnswersWithOption: {
    isLoading: boolean;
    error?: string;
    data: IAttributeValue;
  };
}

export interface IAttributeMapItem {
  isLoading: boolean;
  error?: string;
  data: IAttribute;
  selectedValues?: IAttributeValue[];
  next?: string;
}

export interface IAttributeMap {
  [key: number]: IAttributeMapItem;
}

export interface IAttributeValue {
  id: number;
  name: string;
  attribute_title_id: number;
  answers_absolute: number;
  views_absolute: number;
  comments_absolute: number;
  total_users_count: number;
}

export interface IGuestsAttributeValue {
  name: string;
  answers_absolute: number;
  views_absolute: number;
  comments_absolute: number;
  total_users_count: number;
}

export interface IAttribute {
  title: string;
  index: number;
  id: number;
  organization_id: number;
  values: IAttributeValue[];
}

export enum EAggregatedFields {
  VIEWS = 'views',
  ANSWERS = 'answers',
  COMMENTS = 'comments',
  TOTAL_USERS_COUNT = 'total_users_count',
}

export interface IGetAttributesAction {
  organizationId: number;
  onSuccess?: (attributes: any) => void;
  onError?: () => void;
}

export type TDisplayMode = 'absolute' | 'percentage';

export enum ESortBy {
  VIEWS_ABSOLUTE = 'views_absolute',
  VIEWS_PERCENTAGE = 'views_percentage',
  ANSWERS_ABSOLUTE = 'answers_absolute',
  ANSWERS_PERCENTAGE = 'answers_percentage',
  COMMENTS_ABSOLUTE = 'comments_absolute',
  COMMENTS_PERCENTAGE = 'comments_percentage',
}

export enum ESortByType {
  VIEWS = 'views',
  ANSWERS = 'answers',
  COMMENTS = 'comments',
}

export enum ESortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IGetAttributesPayload {
  attributeIds: number[];
  clearAnalyticsCache?: boolean;
  reset?: boolean;
  callback?: () => void;
}

export enum ESharedWithFilter {
  COMMUNICATION_HUB = 'communication_hub',
  ORGANICALLY = 'organically',
}
