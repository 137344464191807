import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from '../../../../navigation/routes';
import BlueAndRedButtonsModal from '../../BlueAndRedButtonsModal';
import { StackNavigationProp } from '@react-navigation/stack';

const BiteCreationRestoreModal: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const [isVisible, setIsVisible] = useState(true);

  const handleOnDiscard = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleBiteCreationNavigation = useCallback(() => {
    navigation.navigate(Routes.CreateBiteStack.StackName, {
      screen: Routes.CreateBiteStack.BiteTellYourStory,
      params: {
        launchVideoEditorWithDrafts: true,
      },
    });
    setIsVisible(false);
  }, [navigation]);

  return (
    <BlueAndRedButtonsModal
      reverseColors={true}
      isVisible={isVisible}
      onModalHide={handleOnDiscard}
      title={t('restoreModal.biteCreation.title')}
      onRightButtonClick={handleBiteCreationNavigation}
      onLeftButtonClick={handleOnDiscard}
      leftButtonLabel={t('common.discard')}
      rightButtonLabel={t('restoreModal.biteCreation.button')}
    />
  );
};

export default BiteCreationRestoreModal;
