import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { calcFontSize, calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import BgMagicIcon from '../../../assets/icons/bg-magic-icon.svg';
import { KeyboardAwareView } from '../../../components/shared';
import HomeButton from '../../../components/shared/HomeButton';
import Header from '../../../components/Header';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import Routes from '../../../navigation/routes';
import { resetCreatePlaylistState, setPlaylistSubject } from '../../../store/createPlaylist/createPlaylist.actions';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useDebouncedCallback } from 'use-debounce/lib';
import { patchPlaylist, triggerCreatedQuizTransactionalCommunication } from '../../../store/playlist/playlist.actions';
import ShareSection from '../../creationBite/common/ShareSection';
import { EShareItemType } from '../../../store/sharePanel/sharePanel.slice';
import { setHomeActiveTab } from '../../../store/homeScreen/homeScreen.slice';
import { Tabs } from '../../../store/homeScreen/homeScreen.types';
import { isQuizHaveQuestions } from '../../../utils/playlistHelper';
import WithoutCoverOverlay from '../../../components/feed/biteItemOverlays/WithoutCoverOverlay';
import ShadowedContainer from '../../../components/ShadowedContainer';
import openPreviewQuiz from '../../../utils/quiz/openPreviewQuiz';
import { renameQuizInputDataSet } from './QuizCreated.constants';
import { useIsFocused } from '@react-navigation/native';

const QuizCreated = ({ navigation }) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const { editedPlaylist, subject, linkedCoverUrl } = useSelector(createPlaylistSelector);
  const [localInputValue, setLocalInputValue] = useState(subject);

  const renderHeaderTitle = useCallback(() => <S.Title>{t('quizCreated.title')}</S.Title>, [t]);
  const renderHeaderLeft = useCallback(() => <HomeButton />, []);

  const resetName = useCallback(() => {
    setLocalInputValue(subject);
  }, [subject]);

  useEffect(() => {
    return () => {
      dispatch(resetCreatePlaylistState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isFocused && editedPlaylist) {
      dispatch(triggerCreatedQuizTransactionalCommunication({ quizId: editedPlaylist.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused]);

  const updatePlaylistName = useDebouncedCallback((value: string) => {
    dispatch(patchPlaylist(editedPlaylist?.id, { subject: value }));
  }, 300);

  const onChangeText = useCallback(
    (value) => {
      if (value.length <= 40 && value !== subject) {
        dispatch(
          trackEvent({
            event: 'rename_quiz',
            props: {
              playlist_id: editedPlaylist?.id,
            },
          }),
        );
        setLocalInputValue(value);
        dispatch(setPlaylistSubject(value));
        updatePlaylistName.callback(value);
      }
    },
    [subject, editedPlaylist?.id, dispatch, updatePlaylistName],
  );

  const openPreview = useCallback(() => {
    openPreviewQuiz({
      quizId: editedPlaylist?.id,
    });
  }, [editedPlaylist?.id]);

  const skipHandler = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'back_to_my_feed',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
    dispatch(setHomeActiveTab(Tabs.FEED));
    navigation.navigate(Routes.HomeStack.Home);
  }, [dispatch, editedPlaylist?.id, navigation]);

  const hasQuestions = useMemo(() => {
    return isQuizHaveQuestions(editedPlaylist);
  }, [editedPlaylist]);

  const quizImage = useMemo(() => {
    return {
      uri: linkedCoverUrl || null,
    };
  }, [linkedCoverUrl]);

  return (
    <>
      <S.Container>
        <View>
          <BgMagicIcon style={S.BgMagicIconStyles} />
          <Header headerLeft={renderHeaderLeft} title={renderHeaderTitle} />
          <ShadowedContainer containerViewStyle={S.ShadowedContainerViewStyles}>
            <S.CoverImageWrapper>
              <S.ImageWrap>
                {quizImage.uri ? (
                  <S.Image source={quizImage} />
                ) : (
                  <WithoutCoverOverlay workspaceLogoUrl={editedPlaylist?.organization_logo} />
                )}
                <S.Preview onPress={openPreview} disabled={!hasQuestions}>
                  <S.PreviewButtonText disabled={!hasQuestions}>{t('quizCreated.previewQuiz')}</S.PreviewButtonText>
                </S.Preview>
              </S.ImageWrap>
            </S.CoverImageWrapper>
          </ShadowedContainer>
          <S.NameInput
            onBlur={resetName}
            onChangeText={onChangeText}
            value={localInputValue}
            //@ts-ignore
            dataSet={renameQuizInputDataSet}
          />
          {editedPlaylist && (
            <ShareSection
              subject={subject}
              shareData={editedPlaylist}
              shareItemType={EShareItemType.PLAYLIST}
              disabled={!hasQuestions}
              from='quiz_created'
            />
          )}
          {!hasQuestions && (
            <S.NoQuestionsText>{t('share.toShareAQuizItMustContainAtLeastOneQuestion')}</S.NoQuestionsText>
          )}
          <S.SkipButton onPress={skipHandler}>
            <S.SkipText>{t('playlistCreated.backToMyFeed')}</S.SkipText>
          </S.SkipButton>
        </View>
      </S.Container>
    </>
  );
};

const S = {
  Header: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${calcHeight(isWeb ? -12 : 47)}px ${calcWidth(17)}px ${calcHeight(20)}px ${calcWidth(24)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: normal;
    max-width: ${deviceWidth - calcWidth(60) * 2}px;
  `,
  Container: styled(KeyboardAwareView)``,
  Text: styled.Text`
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${calcFontSize(20)}px;
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-align: center;
  `,
  NoQuestionsText: styled.Text`
    text-align: center;
    font-size: ${calcFontSize(16)}px;
    margin-top: ${calcHeight(20)}px;
  `,
  ImageWrap: styled.View<{ isProcessing?: boolean }>`
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    overflow: hidden;
    width: ${calcWidth(248)}px;
    height: ${calcHeight(361)}px;
    ${isWeb ? 'width: 500px;' : ''}
    border-radius: 25px;
    ${({ theme, isProcessing }) => (isProcessing ? `background-color: ${theme.colors.transparentBlack}` : '')};
  `,
  Image: styled.Image<{ isProcessing?: boolean }>`
    position: absolute;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100%;
    height: ${calcHeight(361)}px;
    opacity: ${({ isProcessing }) => (isProcessing ? 0.5 : 1)};
  `,
  BgMagicIcon: styled(BgMagicIcon)`
    position: absolute;
  `,
  PreviewButton: styled.TouchableOpacity`
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
    padding: ${calcHeight(10)}px;
    height: ${calcHeight(42)}px;
    border-radius: ${calcHeight(42 / 2)}px;
    width: ${calcWidth(185)}px;
    margin-bottom: ${calcHeight(24)}px;
  `,
  PreviewButtonText: styled.Text<{ disabled: boolean }>`
    align-self: center;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray1 : theme.colors.white)};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: bold;
  `,
  SkipButton: styled.TouchableOpacity`
    align-self: center;
    margin-top: ${calcHeight(23)}px;
  `,
  SkipText: styled.Text`
    text-align: center;
    color: ${({ theme }) => theme.colors.gray11};
  `,
  Preview: styled.TouchableOpacity<{ disabled: boolean }>`
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid ${({ theme, disabled }) => (!disabled ? theme.colors.primaryBlue : theme.colors.gray1)};
    padding: ${calcHeight(10)}px;
    height: ${calcHeight(42)}px;
    border-radius: ${calcHeight(42 / 2)}px;
    width: ${calcWidth(185)}px;
    margin-bottom: ${calcHeight(24)}px;
  `,
  PreviewTitle: styled.Text`
    font-size: ${calcFontSize(15)}px;
    ${({ theme }) => css`
      color: ${theme.colors.white};
      font-family: ${theme.fontFamilies.GilroyMedium};
    `}
    padding-top: ${calcHeight(3)}px;
    text-align: center;
  `,
  BgMagicIconStyles: {
    position: 'absolute',
    alignSelf: 'center',
    height: isWeb ? calcHeight(846) : calcHeight(445),
    width: calcWidth(deviceWidth),
    opacity: 0.4,
  } as StyleProp<ViewStyle>,
  CoverImageWrapper: styled.View`
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    overflow: hidden;
    height: ${calcHeight(361)}px;
    width: ${calcWidth(248)}px;
    border-radius: 25px;
  `,
  NameInput: styled.TextInput`
    z-index: 1;
    align-self: center;
    margin: ${calcHeight(23)}px 0 ${calcHeight(14)}px;
    padding: ${calcHeight(12)}px ${calcWidth(22)}px;
    width: ${calcWidth(325)}px;
    height: ${calcHeight(49)}px;
    border-radius: ${calcWidth(30)}px;
    font-size: ${calcFontSize(22)}px;
    color: ${({ theme }) => theme.colors.gray1};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    ${({ theme }) => css`
      border: 2px solid ${theme.colors.primaryBlue};
    `};
  `,
  ShadowedContainerViewStyles: {
    height: calcHeight(361),
    width: calcWidth(248),
    alignSelf: 'center',
  } as StyleProp<ViewStyle>,
};

export default QuizCreated;
