import TooltipContentContainer from '../TooltipContentContainer';
import React from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import { ToastConfigParams } from 'react-native-toast-message/lib/src/types';

interface IProps {
  biteName: string;
  workspaceName: string;
}

const CopySuccessToast: React.FC<ToastConfigParams<IProps>> = ({ props: { biteName, workspaceName } }) => {
  const { t } = useTranslation();

  return (
    <S.TooltipContentContainer withGradient>
      <S.TooltipText>
        {workspaceName
          ? t('copySuccessToast.titleCloneToWorkspace', { biteName, workspaceName })
          : t('copySuccessToast.title', { biteName })}
      </S.TooltipText>
    </S.TooltipContentContainer>
  );
};

const S = {
  TooltipContentContainer: styled(TooltipContentContainer)`
    width: ${deviceWidth - calcWidth(48)}px;
  `,
  TooltipText: styled.Text`
    max-width: ${isWeb ? calcWidth(320) : deviceWidth - calcWidth(60)}px;
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s16};
  `,
  CloseTooltip: styled.TouchableOpacity`
    z-index: 3;
    top: ${calcHeight(7)}px;
    right: ${calcWidth(7)}px;
    position: absolute;
    width: ${calcWidth(12)}px;
    height: ${calcHeight(12)}px;
  `,
};

export default CopySuccessToast;
