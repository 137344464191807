import React, { useCallback } from 'react';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import { IStackNavigation } from '../../../navigation/types';
import { editBiteQuestion } from '../../../store/createBite/createBites.actions';
import Routes from '../../../navigation/routes';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { isBiteSummaryCreatedSelector } from '../../../store/biteSummary/biteSummary.selectors';
import EditQuestion from '../../Shared/EditQuestion';

const BiteEditQuestion: React.FC<IStackNavigation> = ({ route, navigation }) => {
  const isEditMode = route?.params?.isEditMode;
  const isPrefilled = route?.params?.isPrefilled;

  const dispatch = useDispatch();

  const { type } = useSelector(biteQuestionSelector);
  const { selectedBite } = useSelector(biteSelector);

  const isBiteSummaryCreated = useSelector(isBiteSummaryCreatedSelector);

  const handleContinue = useCallback(
    (setLoading, isMountRef) => {
      setLoading(true);
      dispatch(
        trackEvent({
          event: 'continue_btn',
          props: {
            page_title: type,
            from: Routes.EditBiteStack.BiteEditQuestion,
          },
        }),
      );

      const editComplete = () => {
        if (!isMountRef.current) {
          return;
        }

        setLoading(false);

        if (isEditMode) {
          navigation.pop(2);
          return;
        }

        const nextRoute =
          isBiteSummaryCreated || isPrefilled
            ? Routes.CreateBiteStack.CreateSummaryNotes
            : Routes.CreateBiteStack.SummaryNotes;

        navigation.navigate(nextRoute, { isPrefilled });
      };

      dispatch(
        editBiteQuestion({
          biteId: selectedBite?.id,
          callback: editComplete,
          isPrefilled,
        }),
      );
    },
    [dispatch, isBiteSummaryCreated, isEditMode, isPrefilled, navigation, selectedBite?.id, type],
  );

  const props = {
    onContinue: handleContinue,
    from: Routes.EditBiteStack.BiteEditQuestion,
    navigation,
    route,
    withDelete: true,
  };

  return <EditQuestion {...props} />;
};

export default BiteEditQuestion;
