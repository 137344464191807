import { takeLatest, all, put } from 'redux-saga/effects';
import * as calls from '../api/bites-api/calls/icons.calls';
import Types from './icons.types';
import { fetchIconsSuccess, searchIconsSuccess } from './icons.actions';
import { IAction } from '../common/types';

function* fetchIconsSaga() {
  try {
    const iconsResult = yield calls.getIcons();
    yield put(fetchIconsSuccess(iconsResult.data));
  } catch (err) {
    console.log('Error fetchIconsSaga', err);
  }
}

function* searchIconsSaga({ payload }: IAction<string>) {
  try {
    const iconResults = yield calls.getIcons(payload);
    yield put(searchIconsSuccess(iconResults.data));
  } catch (err) {
    console.log('Error searchIconsSaga', err);
  }
}

export default function* iconsSagaWatcher() {
  yield all([
    takeLatest(Types.FETCH_ICONS_REQUEST, fetchIconsSaga),
    takeLatest(Types.SEARCH_ICONS_REQUEST, searchIconsSaga),
  ]);
}
