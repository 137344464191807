import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import CreationAnimation from '../../../components/shared/CreationAnimation';
import LoadingBar from './common/LoadingBar';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import AIReady from './common/AIReady/AIReady';
import {
  introMediaDurationSelector,
  introMediaProcessingStatusSelector,
} from '../../../store/createBite/createBite.selectors';
import AINotApplicable from './common/AINotApplicable';
import { useTranslation } from 'react-i18next';
import { log } from '../../../store/appActivity/appActivity.slice';
import { EImproves } from './aiExpectation.constants';
import WarningIcon from '../../../assets/icons/triangle-exclamation.svg';
import { useTheme } from 'styled-components/native';
interface IProps {
  isSuccess: boolean;
  isAINotApplicable: boolean;
  initialProgress?: number;
  improves: EImproves[];
  onContinue: () => void;
}

const AIExpectation: FC<IProps> = ({ isSuccess, isAINotApplicable, initialProgress = 1, improves, onContinue }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);
  const introMediaDuration = useSelector(introMediaDurationSelector) || 4 * 60;

  const progressBarTiming = introMediaDuration ? (Number(introMediaDuration) * 1000) / 100 : null;

  const [descriptionIndex, setDescriptionIndex] = useState(0);
  const [loadingCount, setLoadingCount] = useState(initialProgress);
  const theme = useTheme();

  useEffect(() => {
    const interval = setInterval(() => {
      if (descriptionIndex < improves.length - 1) {
        setDescriptionIndex(descriptionIndex + 1);
        return;
      }
      setDescriptionIndex(0);
    }, 2000);
    return () => clearInterval(interval);
  }, [descriptionIndex, improves.length]);

  useEffect(() => {
    if (!progressBarTiming) {
      return;
    }
    const interval = setInterval(() => {
      if (loadingCount < 100) {
        setLoadingCount(
          loadingCount < 80
            ? loadingCount + 1
            : loadingCount < 90
            ? loadingCount + 1 / (loadingCount / 4)
            : loadingCount + 1 / (loadingCount / 2),
        );
        return;
      }
      setLoadingCount(0);
    }, progressBarTiming);

    return () => clearInterval(interval);
  }, [progressBarTiming, loadingCount]);

  // logging
  const statusLabel = isSuccess ? 'Success' : isAINotApplicable ? 'AI Not Applicable' : 'Processing';
  const statusTsRef = useRef(Date.now());
  useEffect(() => {
    dispatch(
      log({
        event: `AIExpectation: ${statusLabel}`,
        data: {
          introMediaProcessingStatus,
          statusLabel,
          statusTs: Date.now() - statusTsRef.current,
          isSuccess,
          isAINotApplicable,
          initialProgress,
          improves,
        },
      }),
    );
    statusTsRef.current = Date.now();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusLabel]);

  if (isAINotApplicable) {
    return <AINotApplicable improves={improves} onContinuePress={onContinue} />;
  }

  if (isSuccess) {
    return <AIReady improves={improves} onContinuePress={onContinue} />;
  }

  return (
    <S.Container>
      <S.EmptyContainer />
      <S.Content>
        <S.AnimationContainer>
          <CreationAnimation />
        </S.AnimationContainer>
        <S.LoadingTitle>{t('aiExpectations.title')}</S.LoadingTitle>
        <S.LoadingDescription>{t(`aiExpectations.improves.${improves[descriptionIndex]}`)}</S.LoadingDescription>
        <S.LoadingBarContainer>
          <LoadingBar
            current={loadingCount}
            total={100}
            width={isWeb ? calcWidth(206) : calcWidth(106)}
            duration={progressBarTiming}
          />
        </S.LoadingBarContainer>
        {!isWeb && (
          <>
            <S.LoadingInfoWrapper>
              <WarningIcon style={S.WarningIconStyles} fill={theme.colors.yellowWarning} />
              <S.LoadingInfo>{t('aiExpectations.loadingInfo')}</S.LoadingInfo>
            </S.LoadingInfoWrapper>
          </>
        )}
      </S.Content>

      <S.SkipButtonContainer>
        <S.SkipButton onPress={onContinue}>
          <S.SkipButtonText>{t('aiExpectations.continue')}</S.SkipButtonText>
        </S.SkipButton>
      </S.SkipButtonContainer>
    </S.Container>
  );
};

const S = {
  EmptyContainer: styled.View`
    flex: 1;
  `,
  Container: styled.View`
    flex: 1;
    align-items: center;
    justify-content: space-between;
  `,
  Content: styled.View`
    flex: 6;
    align-items: center;
    justify-content: center;
  `,
  SkipButtonContainer: styled.View`
    flex: 1;
  `,
  SkipButton: styled.TouchableOpacity`
    align-self: center;
  `,
  SkipButtonText: styled.Text`
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;

    text-align: center;
    color: ${({ theme }) => theme.colors.gray4};
  `,
  LoadingTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    margin-top: ${calcHeight(29)}px;
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
  `,
  LoadingDescription: styled.Text`
    width: ${calcWidth(306)}px;
    color: ${({ theme }) => theme.colors.text};
    margin-top: ${calcHeight(13)}px;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
  `,
  LoadingBarContainer: styled.View`
    margin-top: ${calcHeight(21)}px;
  `,
  AnimationContainer: styled.View`
    margin-left: ${isWeb ? calcWidth(-355) : calcWidth(-135)}px;
  `,
  LoadingInfo: styled.Text`
    max-width: ${calcWidth(260)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18}px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    margin-left: ${calcWidth(12)}px;
  `,
  LoadingInfoWrapper: styled.View`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: ${calcHeight(70)}px;
    border: 1px solid ${({ theme }) => theme.colors.gray4};
    border-radius: 10px;
    padding: ${calcWidth(12)}px;
  `,
  WarningIconStyles: {
    width: calcWidth(22),
    height: calcHeight(22),
  },
};

export default memo(AIExpectation);
