import { useEffect } from 'react';

export const useOnWindowScroll = (fn: () => void) => {
  // call fn when window is scrolled
  useEffect(() => {
    window.addEventListener('scroll', fn);

    return () => {
      window.removeEventListener('scroll', fn);
    };
  }, [fn]);
};
