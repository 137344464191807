import React, { useCallback, useState } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { brandResultsSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useSelector } from 'react-redux';
import { setBrandResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { useCurrentUserOrg } from '../../../hooks/useCurrentUserOrg';
import UploadControl from './UploadControl';
import FileUpload from './FileUpload.web';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const additionalRequestFilters = {
  isBrandAsset: true,
};

const BrandSidebar = () => {
  const brandResults = useSelector(brandResultsSelector);

  const userOrg = useCurrentUserOrg();

  const [isUploadVisible, setIsUploadVisible] = useState(false);

  const renderUploadControls = useCallback(() => {
    return <UploadControl isVisible={isUploadVisible} setIsVisible={setIsUploadVisible} />;
  }, [isUploadVisible]);

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileTypes={FILE_TYPES} />;
  }, []);

  return (
    <>
      <SidebarTitle title='Libraries' renderControls={userOrg.content_admin ? renderUploadControls : undefined} />
      {!isUploadVisible ? (
        <Cloud
          cloudResults={brandResults}
          additionalRequestFilters={additionalRequestFilters}
          renderFilterControls={renderFilterControls}
          setCloudResults={setBrandResults}
        />
      ) : (
        <FileUpload isBrandAsset />
      )}
    </>
  );
};
export default BrandSidebar;
