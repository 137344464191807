import React, { FC, useEffect, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import NameYourPlaylist from '../../../screens/createPlaylist/NameYourPlaylist';
import AddBites from '../../../screens/createPlaylist/AddBites';
import OrganizePlaylist from '../../../screens/createPlaylist/OrganizePlaylist';
import PlaylistSettings from '../../../screens/createPlaylist/PlaylistSettings';
import PlaylistCreated from '../../../screens/createPlaylist/PlaylistCreated';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOrganizationSelector,
  isContentCreatorSelector,
  leadNameSelector,
} from '../../../store/auth/auth.selectors';
import CreatePlayListInfo from '../../../screens/createPlaylist/CreatePlaylistInfo/CreatePlaylistInfo';
import Header from '../../../components/Header';
import { userOrgPreferencesSelector } from '../../../store/appActivity/appActivity.selectors';
import Routes from '../../routes';
import { BackHeader } from '../../../components/shared';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import useCurrentRoute from '../../../hooks/useCurrentRoute';
import { IStackNavigation } from '../../types';
import { isWeb } from '../../../utils/dimensions';

const Stack = createStackNavigator();

interface IProps extends IStackNavigation {}

const localePrefix = 'createPlaylistStack.navTitles';

const CreatePlaylistStack: FC<IProps> = ({ navigation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isContentCreator = useSelector(isContentCreatorSelector);
  const userOrgPreferences = useSelector(userOrgPreferencesSelector);
  const org = useSelector(activeOrganizationSelector);
  const leadName = useSelector(leadNameSelector);

  const { routes, route, params } = useCurrentRoute();

  const currentScreenName = useMemo(() => {
    if (route?.state === undefined) {
      if (params?.screen) {
        return params?.screen;
      }

      if (userOrgPreferences.skip_create_playlist_flow_overview_screen) {
        return Routes.CreatePlaylistStack.AddBites;
      }

      return Routes.CreatePlaylistStack.CreatePlaylistInfo;
    }
    const screens = route?.state?.routes;
    if (screens?.[screens?.length - 1]?.name) {
      return screens[screens.length - 1]?.name;
    }
  }, [route?.state, params?.screen, userOrgPreferences.skip_create_playlist_flow_overview_screen]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: currentScreenName, from: params?.from },
      }),
    );
  }, [routes, org.id, currentScreenName, params, leadName, dispatch]);

  useEffect(() => {
    if (!isContentCreator) {
      navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
    }
  }, [isContentCreator, navigation]);

  if (!isContentCreator) {
    return null;
  }

  return (
    <Stack.Navigator screenOptions={() => getScreenOptions(theme)}>
      {!userOrgPreferences.skip_create_playlist_flow_overview_screen && (
        <Stack.Screen
          name={Routes.CreatePlaylistStack.CreatePlaylistInfo}
          component={CreatePlayListInfo}
          options={{
            header: getHeader,
          }}
        />
      )}

      <Stack.Screen
        name={Routes.CreatePlaylistStack.AddBites}
        component={AddBites}
        options={{
          headerTitle: t(`${localePrefix}.addBites`),
          header: getHeader,
        }}
      />

      <Stack.Screen
        name={Routes.CreatePlaylistStack.OrganizePlaylist}
        component={OrganizePlaylist}
        options={{
          headerTitle: t(`${localePrefix}.organize`),
          header: getHeader,
        }}
      />

      <Stack.Screen
        name={Routes.CreatePlaylistStack.PlaylistCreated}
        component={PlaylistCreated}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={Routes.EditPlaylistStack.PlaylistSettings}
        component={PlaylistSettings}
        options={() => getSecondaryScreenOptions({ theme, headerTitle: t(`${localePrefix}.create`) })}
      />
      <Stack.Screen
        name={Routes.EditPlaylistStack.NameYourPlaylist}
        component={NameYourPlaylist}
        options={() => getSecondaryScreenOptions({ theme, headerTitle: t(`${localePrefix}.addBites`) })}
      />
    </Stack.Navigator>
  );
};

const getSecondaryScreenOptions = ({
  theme,
  headerTitle,
}: {
  theme: DefaultTheme;
  headerTitle?: string;
}): StackNavigationOptions => {
  return {
    headerTitleStyle: {
      fontFamily: theme.fontFamilies.TTNormsMedium,
      fontSize: theme.fontSizes.s16,
      color: theme.colors.white,
      textTransform: 'uppercase',
    },
    headerTitleAlign: 'center' as 'center',
    headerTitle,
    cardStyle: {
      backgroundColor: theme.colors.defaultGrayBg,
      flex: 1,
    },
    headerStyle: {
      backgroundColor: theme.colors.primaryBlue,
      shadowColor: 'transparent',
      elevation: 0,
    },
    ...TransitionPresets.SlideFromRightIOS,
    headerLeft: () => <BackHeader />,
  };
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },
    animationEnabled: !isWeb,
    header: getHeader,
    headerMode: 'screen',
    ...TransitionPresets.SlideFromRightIOS,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

export default CreatePlaylistStack;
