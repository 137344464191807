import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import styled from 'styled-components/native';
import { View, FlatList } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isWeb, calcWidth, calcHeight, deviceHeight } from '../../../../utils/dimensions';
import Panel, { PANEL_HEADER_HEIGHT, ANIMATION_TIME } from './common/Panel';
import Line from './common/Line';
import { keyboardDismiss } from '../../../../utils/general';
import useKeyboardHeight from '../../../../hooks/useKeyboardHeight';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector, leadNameSelector } from '../../../../store/auth/auth.selectors';
import { State } from 'react-native-gesture-handler';
import PanelHeaderNative from './common/PanelHeader/PanelHeaderNative';
import PanelHeaderWeb from './common/PanelHeader/PanelHeaderWeb';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { ILine, ISubtitles } from '../../../../types/subtitles';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { WebView } from 'react-native-webview';
import { EUploadStatus } from '../../../../types/bite';
import { useTranslation } from 'react-i18next';
import { introSubtitlesLinesSelector } from '../../../../store/createBite/createBite.selectors';

export interface ISubtitlesMethods {
  scrollTo: null | ((number) => void);
}

interface IProps {
  isVisible: boolean;
  lines: ILine[];
  linesOriginal: ILine[];
  currentLine: ILine;
  onScroll: () => void;
  onSetCurrentLine: (ILine) => void;
  onChangeLine: (ILine) => void;
  onClose?: () => void;
  onMethods: (ISubtitlesMethods) => void;
  subtitlesToDisplay?: ISubtitles;
}

interface IState {
  flatListRef: null | FlatList;
  targetLineIndex: null | number;
  dragging: boolean;
  methods: ISubtitlesMethods;
}

const source = { uri: 'https://bites-bucket.s3-eu-west-1.amazonaws.com/feed_preloader.svg' };
const originWhitelist = ['*'];

const SubtitlesPanel: React.FC<IProps> = ({
  isVisible,
  lines,
  linesOriginal,
  currentLine,
  onScroll,
  onSetCurrentLine,
  onChangeLine,
  onClose,
  onMethods,
  subtitlesToDisplay,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keyboardHeight } = useKeyboardHeight();
  const [isExpanded, setIsExpanded] = useState(false);
  // const [subtitlesUpdatedCounter, setSubtitlesUpdatedCounter] = useState(0);
  const org = useSelector(activeOrganizationSelector);
  const leadName = useSelector(leadNameSelector);
  const linesOriginalData = useSelector(introSubtitlesLinesSelector);

  const state = useRef<IState>({
    flatListRef: null,
    targetLineIndex: null,
    dragging: false,
    methods: {
      scrollTo: null,
    },
  });

  const isLoading = !subtitlesToDisplay || !linesOriginalData;
  const isUnavailable = subtitlesToDisplay && subtitlesToDisplay.upload_status !== EUploadStatus.done;
  const isSubtitlesLoading = isLoading || linesOriginalData.id !== subtitlesToDisplay.id;

  const animatedBottomPaddingHeight = useSharedValue(0);
  const animatedHeight = useSharedValue(calcHeight(200));

  const getFlatListRef = useCallback(
    (flatListRef) => {
      state.current.flatListRef = flatListRef;
      state.current.methods.scrollTo = (num) => {
        if (state.current.dragging) {
          return;
        }
        const index = lines.findIndex((line) => line.num === num);
        if (index === state.current.targetLineIndex) {
          return;
        }
        state.current.targetLineIndex = index;
        state.current.flatListRef?.scrollToIndex({
          index,
          animated: true,
        });
      };
    },
    [lines],
  );

  const handleOnScrollBeginDrag = useCallback(() => {
    state.current.targetLineIndex = null;
    state.current.dragging = true;
    onScroll?.();
  }, [onScroll]);

  const handleOnScrollEndDrag = useCallback(() => {
    state.current.dragging = false;
  }, []);

  // this callback is needed to prevent crashes
  // error: scrollToIndex should be used in conjunction with getItemLayout or onScrollToIndexFailed, otherwise there is no way to know the location of offscreen indicies or handle failures
  // before the subtitlesUpdatedCounter key was intended to be used
  // but looks like it is working wothout full list rerendering
  const handleScrollToIndexFailed = useCallback((info) => {
    console.log('>>> handleScrollToIndexFailed', info);
  }, []);

  const handleOnClose = useCallback(() => {
    keyboardDismiss();
    setIsExpanded(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isVisible) {
      dispatch(
        trackEvent({
          event: 'enhancements_drawer',
          props: {
            type: 'subtitles',
            expanded: isExpanded,
          },
        }),
      );
    }
  }, [isExpanded, isVisible, org.id, leadName, dispatch]);

  useEffect(() => {
    animatedHeight.value = isExpanded
      ? keyboardHeight
        ? deviceHeight * 0.7 - keyboardHeight
        : deviceHeight * 0.7
      : calcHeight(200);
    if (!isWeb) {
      animatedBottomPaddingHeight.value = keyboardHeight ? keyboardHeight : 0;
    }
  }, [animatedBottomPaddingHeight, animatedHeight, isExpanded, keyboardHeight]);

  useEffect(() => {
    onMethods?.(state.current.methods);
  }, [onMethods]);

  const onSwipeUp = useCallback(() => {
    if (!isExpanded) {
      keyboardDismiss();
      setIsExpanded(true);
    }
  }, [isExpanded]);

  const onSwipeDown = useCallback(() => {
    if (isExpanded) {
      keyboardDismiss();
      setIsExpanded(false);
      return;
    }

    if (keyboardHeight > 0) {
      keyboardDismiss();
      return;
    }

    handleOnClose();
  }, [handleOnClose, isExpanded, keyboardHeight]);

  const handleWebToggleSubtitle = useCallback(() => {
    isExpanded ? onSwipeDown() : onSwipeUp();
  }, [isExpanded, onSwipeUp, onSwipeDown]);

  const handleSwipe = useCallback(
    ({ nativeEvent }) => {
      if (nativeEvent.oldState !== State.ACTIVE) {
        return;
      }

      if (nativeEvent.translationY < 0) {
        onSwipeUp();
        return;
      }

      onSwipeDown();
    },
    [onSwipeDown, onSwipeUp],
  );

  const animatedStyle = useAnimatedStyle(() => {
    return {
      maxHeight: withTiming(animatedHeight.value, {
        duration: ANIMATION_TIME,
      }),
      minHeight: withTiming(animatedHeight.value, {
        duration: ANIMATION_TIME,
      }),
      position: 'relative',
    };
  }, []);

  const animatedBottomPaddingStyle = useAnimatedStyle(() => {
    return {
      maxHeight: withTiming(animatedBottomPaddingHeight.value, {
        duration: ANIMATION_TIME,
      }),
      minHeight: withTiming(animatedBottomPaddingHeight.value, {
        duration: ANIMATION_TIME,
      }),
    };
  }, []);

  const handleListItemBlur = useCallback(() => {
    // setSubtitlesUpdatedCounter(Date.now());
  }, []);

  const initialScrollIndex = useMemo(
    () => lines?.findIndex((line) => line.num === currentLine?.num),
    [currentLine?.num, lines],
  );

  const renderItem = useCallback(
    ({ item, index }) => (
      <LineItem
        index={index}
        isCurrent={currentLine === item}
        line={item}
        lineOriginal={linesOriginal[index]}
        lines={lines}
        withBottomPadding={index === lines.length - 1}
        onChangeLine={onChangeLine}
        onBlur={handleListItemBlur}
        onSetCurrentLine={onSetCurrentLine}
      />
    ),
    [currentLine, handleListItemBlur, lines, linesOriginal, onChangeLine, onSetCurrentLine],
  );

  const keyExtractor = useCallback((item: ILine) => item.num, []);

  return (
    <>
      <Panel isVisible={isVisible}>
        <S.Container>
          {isWeb ? (
            <PanelHeaderWeb
              onClick={handleWebToggleSubtitle}
              isExpanded={isExpanded}
              title={t('enhancements.subtitles')}
              isSubtitlesLoading={isSubtitlesLoading}
            />
          ) : (
            <PanelHeaderNative
              onSwipe={handleSwipe}
              title={t('enhancements.subtitles')}
              isSubtitlesLoading={isSubtitlesLoading}
            />
          )}

          <Animated.View style={animatedStyle}>
            {isLoading && (
              <S.LoaderContainer>
                <S.PreloaderWrapper>
                  {isWeb ? (
                    <object style={S.Preloader} data={source.uri} />
                  ) : (
                    <WebView style={S.Preloader} source={source} originWhitelist={originWhitelist} />
                  )}
                </S.PreloaderWrapper>
              </S.LoaderContainer>
            )}
            {isUnavailable && (
              <S.UnavailableContainer>
                <S.UnavailableText>{t('bitesMagic.subtitlesUnavailable')}</S.UnavailableText>
              </S.UnavailableContainer>
            )}
            {!!(lines && linesOriginal && linesOriginalData) && (
              <S.SubtitlesContainer
                // key={subtitlesUpdatedCounter}
                keyExtractor={keyExtractor}
                initialScrollIndex={initialScrollIndex}
                ref={getFlatListRef}
                onScrollBeginDrag={handleOnScrollBeginDrag}
                onScrollEndDrag={handleOnScrollEndDrag}
                onScrollToIndexFailed={handleScrollToIndexFailed}
                data={lines}
                renderItem={renderItem}
              />
            )}
          </Animated.View>
          <Animated.View style={animatedBottomPaddingStyle} />
        </S.Container>
      </Panel>
    </>
  );
};

const LineItem = ({
  index,
  isCurrent,
  line,
  lineOriginal,
  withBottomPadding,
  onChangeLine,
  onSetCurrentLine,
  onBlur,
  lines,
}) => {
  const insets = useSafeAreaInsets();

  const handleSetCurrentLine = useCallback(() => {
    onSetCurrentLine(line);
  }, [line, onSetCurrentLine]);

  return (
    <>
      <Line
        index={index}
        isCurrent={isCurrent}
        line={line}
        lineOriginal={lineOriginal}
        onSelect={handleSetCurrentLine}
        onChangeLine={onChangeLine}
        onBlur={onBlur}
        lines={lines}
      />
      {withBottomPadding && <View style={{ height: insets.bottom }} />}
    </>
  );
};

const S = {
  Container: styled.View`
    align-items: stretch;
    width: 100%;
  `,
  Header: styled.View`
    flex-direction: row;
    align-items: center;
    padding: 0 ${calcWidth(16)}px;
    height: ${calcHeight(PANEL_HEADER_HEIGHT)}px;
  `,
  SubtitlesContainer: styled.FlatList`
    max-height: 100%;
  `,
  UnavailableContainer: styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${calcWidth(16)}px 0;
  `,
  UnavailableText: styled.Text``,
  LoaderContainer: styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  `,
  PreloaderWrapper: styled.View`
    position: relative;
    width: ${calcWidth(isWeb ? 90 : 250)}px;
    height: ${calcHeight(150)}px;
  `,
  Preloader: {
    backgroundColor: 'transparent',
    resizeMode: 'cover',
    flex: 0.8,
  },
};

export default SubtitlesPanel;
