import React, { useCallback, useEffect } from 'react';
import Modal from '../ModalController';
import styled from 'styled-components/native';
import { calcHeight } from '../../../utils/dimensions';
import OfflineIcon from '../../../assets/icons/offline.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlowSelector, slowConnectionSelector } from '../../../store/appActivity/appActivity.selectors';
import { EAppFlow } from '../../../store/appActivity/appActivity.types';
import { log, setSlowConnection } from '../../../store/appActivity/appActivity.slice';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const SlowConnectionModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();

  const slowConnection = useSelector(slowConnectionSelector);
  const currentFlow = useSelector(currentFlowSelector);

  const isInsideFlow =
    currentFlow?.flow === EAppFlow.BITE_CREATION && currentFlow.hasIntroVideo && !currentFlow.videoUploadedToS3;

  const isModalVisible = slowConnection.isSlowConnection && !slowConnection.isDismissed && isInsideFlow;

  const handleModalHide = useCallback(() => {
    dispatch(setSlowConnection({ isDismissed: true }));
    dispatch(
      log({
        event: 'is_slow_connection_modal_dismissed',
        is_dismissed: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (isModalVisible) {
      dispatch(
        log({
          event: 'is_slow_connection_modal',
        }),
      );
    }
  }, [dispatch, isModalVisible]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      isVisible
      statusBarTranslucent
      animationOut={'fadeOut'}
      animationIn={'fadeIn'}
      backdropOpacity={0.3}
      onBackdropPress={handleModalHide}
    >
      <S.Container insets={insets} onPress={handleModalHide}>
        <OfflineIcon />
        <S.Title>{t('slowConnectionModal.title')}</S.Title>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.TouchableOpacity<{ insets }>`
    position: absolute;
    top: 0;
    align-items: center;
    width: 100%;
    background: ${({ theme }) => theme.colors.transparentPink};
    justify-content: center;
    vertical-align: center;
    padding-top: ${({ insets }) => insets.top + calcHeight(10)}px;
    padding-bottom: ${calcHeight(20)}px;
  `,
  Title: styled.Text`
    margin-top: ${calcHeight(7)}px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s14 * 1.07};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default SlowConnectionModal;
