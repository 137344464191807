import store from '../../store';
import { logError } from '../../store/appActivity/appActivity.slice';
import './intercom-init';

import { IIntercom } from './intercom.types';

const queue = [];
const process = (fn) => {
  queue.push(fn);
  if (queue.length > 1) {
    return;
  }
  processQueue();
};
const processQueue = () => {
  if (queue.length === 0) {
    return;
  }
  const fn = queue[0];
  try {
    fn();
  } catch (error) {
    store.dispatch(logError({ error }));
  }
  setTimeout(() => {
    queue.shift();
    processQueue();
  }, 2500);
};

const methods: IIntercom = {
  boot: () => {
    process(() => {
      window.Intercom('boot');
    });
  },
  showChat: () => {
    process(() => {
      window.Intercom('update', {
        hide_default_launcher: false,
      });
    });
    process(() => {
      window.Intercom('boot');
    });
  },
  hideChat: () => {
    process(() => {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    });
    process(() => {
      window.Intercom('shutdown');
    });
  },
  registerUnidentifiedUser: () => {
    // updateUser({
    //     user_id: undefined,
    // });
  },
  registerIdentifiedUser: () => {
    // updateUser({
    //     user_id: userId,
    // });
  },
  updateUser: (props) => {
    process(() => {
      const customAttributes = props.customAttributes || {};
      delete props.customAttributes;
      window.Intercom('update', {
        ...props,
        ...customAttributes,
        // hide_default_launcher: true,
      });
    });
  },
  trackEvent: (event, props = {}) => {
    process(() => {
      window.Intercom('trackEvent', event, props);
    });
  },
  displayMessenger: () => {
    window.Intercom('showMessages');
  },
  showNewMessage: (text) => {
    window.Intercom('showNewMessage', text);
  },
  displayHelp: () => {
    window.Intercom('showSpace', 'help');
  },
  displayArticle: (articleId: string) => {
    window.Intercom('showArticle', articleId);
  },
};
export default methods;
