import React, { FC, useEffect, useRef, useCallback, memo, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import useVimeoLink from '../../../../hooks/useVimeoLink';
import { MediaViewerProps, IMediaViewerMethods } from '../../../../types/media';
import {
  Player,
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  DurationDisplay,
  VolumeMenuButton,
} from 'video-react';
import ErrorMessage from '../../ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useIsFocused } from '@react-navigation/native';
import Subtitles from './Subtitles';
import Source from './Source';

interface IProps {
  mediaViewerProps: MediaViewerProps;
}
interface IPlayerState {
  currentTime: number;
  duration: number;
  ended: boolean;
}
interface IState {
  isActive: boolean;
  player: null | Player;
  methods: IMediaViewerMethods;
  handleStateChange: (playerState: IPlayerState) => void;
}

const VimeoVideoPlayer: FC<IProps> = ({ mediaViewerProps }) => {
  const {
    videoLinkRequired = false,
    mediaURI,
    lines,
    onProgress,
    onMethods,
    onMediaDetails,
    isAutoPlay,
    isMutedInitial,
    isLessControlMode,
    subtitles,
    onPlay,
    onPause,
  } = mediaViewerProps;

  const vimeoLinkData = useVimeoLink(videoLinkRequired, mediaURI!);
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const [isPaused, setPaused] = useState(true);
  const [subtitlesKey, setSubtitlesKey] = useState(0);
  const [isMuted, setIsMuted] = useState(isMutedInitial || (isAutoPlay && isMutedInitial !== false));

  const play = useCallback(() => {
    if (typeof onPlay === 'function') {
      onPlay();
    }

    setPaused(false);
  }, [onPlay]);

  const pause = useCallback(() => {
    if (typeof onPause === 'function') {
      onPause();
    }

    setPaused(true);
  }, [onPause]);

  const handleStateChange = useCallback(
    (playerState) => {
      if (!state.current.isActive) {
        return;
      }

      if (playerState.paused && !isPaused) {
        pause();
      }

      if (!playerState.paused && isPaused) {
        play();
      }

      onProgress?.({
        currentTime: playerState.currentTime,
        duration: playerState.duration,
      });
      if (playerState.ended) {
        mediaViewerProps?.onEnd?.();
      }
    },
    [pause, play, isPaused, mediaViewerProps, onProgress],
  );

  const state = useRef<IState>({
    isActive: true,
    player: null,
    methods: {
      seek: null,
      pause: null,
      play: null,
    },
    handleStateChange,
  });

  useEffect(() => {
    state.current.handleStateChange = handleStateChange;
  }, [handleStateChange, mediaViewerProps]);

  const getRef = useCallback(
    (playerRef) => {
      state.current.player = playerRef;

      // do nothing if video player does not exist
      if (state.current.player === null || state.current.player?.video?.video?.readyState === undefined) {
        return;
      }

      if (state.current.player?.video?.video?.readyState >= 2) {
        onMediaDetails?.({
          width: state.current.player?.video?.video?.videoWidth,
          height: state.current.player?.video?.video?.videoHeight,
          duration: state.current.player?.video?.video?.duration,
        });
        if (isFocused && isAutoPlay) {
          playerRef?.play?.();
        }
      } else {
        state.current.player?.video.video.addEventListener('loadedmetadata', () => {
          const video = state.current.player?.video?.video;

          const tracks = video?.textTracks;
          if (video && tracks.length > 0 && !!subtitles?.uri) {
            tracks[0].mode = 'showing'; // turn on subtitles
          }

          onMediaDetails?.({
            width: video?.videoWidth,
            height: video?.videoHeight,
            duration: video?.duration,
          });

          if (isFocused && isAutoPlay) {
            playerRef?.play?.();
          }
        });
      }
      state.current.player?.subscribeToStateChange((playerState) => state.current.handleStateChange(playerState));
      state.current.methods.seek = (time) => state.current.player?.seek?.(time + 0.1);
      state.current.methods.pause = () => state.current.player?.pause?.();
      state.current.methods.play = () => state.current.player?.play?.();
      onMethods?.(state.current.methods);
    },
    [isAutoPlay, isFocused, onMethods, onMediaDetails, subtitles],
  );

  useEffect(() => {
    onMethods?.(state.current.methods);
  }, [onMethods]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state.current.isActive = false;
    };
  }, []);

  const uri = useMemo(() => {
    return videoLinkRequired ? vimeoLinkData.vimeoVideoUri : mediaURI;
  }, [vimeoLinkData, videoLinkRequired, mediaURI]);

  useEffect(() => {
    if (isFocused && isAutoPlay) {
      state.current.methods.play?.();
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state.current.methods.pause?.();
    };
  }, [isAutoPlay, isFocused]);

  const handlePause = useCallback(() => setIsMuted(false), []);

  useEffect(() => {
    setSubtitlesKey(Date.now());
  }, [subtitles]);

  const render = () => {
    if (vimeoLinkData.vimeoVideoUri || (!videoLinkRequired && mediaURI)) {
      const isBitesStreaming =
        uri && ['video.small-bites.com', 'video.mybiteshares.com', 'video.mybites.io'].includes(new URL(uri).hostname);

      return (
        <Player
          onPause={handlePause}
          muted={isMuted}
          crossOrigin={isBitesStreaming ? 'use-credentials' : 'anonymous'}
          ref={getRef}
          key={uri}
        >
          <Source
            //@ts-ignore
            isVideoChild // this prop is used to prevent the source from rendering outside the video element
            src={uri}
            isAutoPlay={isAutoPlay}
            isFocused={isFocused}
          />
          <BigPlayButton disabled={isAutoPlay} position='center' className='preview-play-button' />
          {isLessControlMode && (
            <ControlBar>
              <CurrentTimeDisplay disabled />
              <TimeDivider disabled />
              <DurationDisplay disabled />
              <VolumeMenuButton disabled />
            </ControlBar>
          )}
          {!!subtitles?.uri && (
            <track
              label={subtitles.language}
              src={subtitles.uri}
              srcLang={subtitles.language}
              default
              key={subtitlesKey}
            />
          )}
          {!subtitles?.uri && lines && <Subtitles lines={lines} playerRef={state.current.player} />}
        </Player>
      );
    }
    if (vimeoLinkData.isFetchingLink) {
      return <ActivityIndicator />;
    }
    if (vimeoLinkData.isVideoStillDecoding) {
      return <ErrorMessage msg={t('common.videoIsStillDecoding')} />;
    }
    if (vimeoLinkData.fetchingLinkError) {
      return <ErrorMessage msg={vimeoLinkData.fetchingLinkError} />;
    }
    return null;
  };

  return render();
};

export default memo(VimeoVideoPlayer);
