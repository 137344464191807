import { useSelector } from 'react-redux';
import { initialAttributesMapByIdSelector } from '../../../store/attributes/attributes.selector';
import { useMemo } from 'react';

const useHasOrganizationAttributes = () => {
  const initialAttributesMap = useSelector(initialAttributesMapByIdSelector);
  const hasOrganizationAttributes = useMemo(
    () => Object.values(initialAttributesMap).some((attribute) => attribute.data.values.length),
    [initialAttributesMap],
  );

  return {
    hasOrganizationAttributes,
  };
};

export default useHasOrganizationAttributes;
