import { useSelector } from 'react-redux';
import { userSelector } from '../store/auth/auth.selectors';
import { IPlaylist } from '../store/playlist/playlist.types';
import { IBiteItem } from '../types/bite';
import { IBiteShare } from '../types/biteShare';

const useIsItemOwner = (item: IBiteItem | IBiteShare | IPlaylist | null): boolean => {
  const user = useSelector(userSelector);

  const userId = user?.id;
  const ownerId: number | null = (() => {
    if (item?.hasOwnProperty('owner')) {
      return (item as IBiteItem).owner;
    }

    if (item?.hasOwnProperty('creator')) {
      return (item as IPlaylist)?.creator;
    }

    return null;
  })();

  if (!userId || !ownerId) {
    return false;
  }

  return userId === ownerId;
};

export default useIsItemOwner;
