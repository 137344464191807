import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addToTimeline,
  addUndo,
  removeEmptyTimelineLayers,
  saveVideo,
  updateCanvas,
} from '../../../store/videoEditor/videoEditor.slice';

import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import Button from '../../../components/shared/buttons/Button/Button';
import { loadFont } from '../RightSidebar/loadFont';
import { fontsByFileName } from '../RightSidebar/text/fonts';

const DEFAULT_TEXT = 'Lorem ipsum dolor sit amet';

const fontFiles = [
  'BebasNeue-Regular.ttf',
  'Comfortaa-Regular.ttf',
  'Figtree-Black.ttf',
  'ShadowsIntoLight-Regular.ttf',
  'AlfaSlabOne-Regular.ttf',
  'Courgette-Regular.ttf',
  'Arimo-Regular.ttf',
];

const samples = fontFiles.map((fontFile) => ({
  type: 'text',
  fontFamily: fontsByFileName[fontFile].label,
  fontSize: 5,
  x: 0,
  y: 40,
  width: 100,
  color: '#000000',
}));

interface IProps {}
const TextSidebar = ({}: IProps) => {
  const dispatch = useDispatch();

  const [text, setText] = useState(DEFAULT_TEXT);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = useCallback((e) => {
    setText(e.target.value);

    // Reset the height to a default (e.g., 'auto' or minimum height)
    textareaRef.current.style.height = 'auto';
    // Set the height based on the scroll height
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 8 + 'px';
  }, []);

  const handleAddText = useCallback(
    (timelineItem) => {
      dispatch(
        addToTimeline({
          timelineItem: {
            ...timelineItem,
            text,
          },
        }),
      );
      dispatch(removeEmptyTimelineLayers());
      dispatch(updateCanvas({}));
      dispatch(addUndo({}));
      dispatch(saveVideo({}));
    },
    [dispatch, text],
  );

  const handleClear = useCallback(() => {
    setText(DEFAULT_TEXT);
  }, []);

  useEffect(() => {
    fontFiles.forEach((fontFile) => {
      const font = fontsByFileName[fontFile];
      loadFont(font);
    });
  }, []);

  return (
    <>
      <S.Title>Add text</S.Title>
      <S.ControlContainer>
        <S.Textarea value={text} placeholder='Enter text here...' ref={textareaRef} onInput={handleInput} />
      </S.ControlContainer>
      <S.Controls>
        <S.ClearBtn isDisabled={text === DEFAULT_TEXT} onClick={handleClear}>
          Clear
        </S.ClearBtn>
      </S.Controls>
      <S.SamplesContainer>
        {samples.map((sample, index) => (
          <Sample text={text} sample={sample} key={index} onAddText={handleAddText} />
        ))}
      </S.SamplesContainer>
    </>
  );
};
export default TextSidebar;

const addButtonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 30,
};
const Sample = ({
  text,
  sample,
  onAddText,
}: {
  text: string;
  sample: {
    fontFamily: string;
    fontSize: number;
    color: string;
  };
  onAddText: (timelineItem: any) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = useCallback(() => {
    onAddText(sample);
  }, [onAddText, sample]);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <S.Sample onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <S.SampleText fontFamily={sample.fontFamily} fontSize={sample.fontSize} color={sample.color}>
        {text || DEFAULT_TEXT}
      </S.SampleText>
      <S.AddSampleBtnContainer isVisible={isHovered}>
        <Button
          text='Add'
          height={30}
          textColor={defaultTheme.colors.primaryBlue}
          border={defaultTheme.colors.primaryBlue}
          fill={defaultTheme.colors.white}
          style={addButtonStyle}
          onPress={handleClick}
        />
      </S.AddSampleBtnContainer>
    </S.Sample>
  );
};

const S = {
  Title: styled.div`
    padding: 16px;
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlContainer: styled.div`
    margin-top: 16px;
    padding: 0 16px;
  `,
  Textarea: styled.textarea`
    padding: 12px 16px;
    width: 100%;
    min-height: 80px;
    border: 1px solid ${defaultTheme.colors.gray17};
    border-radius: 20px;
    resize: none;
  `,
  Controls: styled.div`
    display: flex;
    align-items: center;
    padding: 0 16px;
  `,
  ClearBtn: styled.div<{
    isDisabled: boolean;
  }>`
    color: ${defaultTheme.colors.primaryBlue};
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
    text-decoration: underline;
    cursor: pointer;
  `,
  SamplesContainer: styled.div`
    flex: 1;
    padding: 0 16px;
    overflow-y: auto;
  `,
  Sample: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 8px 0;
    padding: 0 12px;
    height: 56px;
    transition: background-color 0.2s;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  SampleText: styled.div<{
    fontFamily: string;
    fontSize: number;
    color: string;
  }>`
    flex: 1;
    color: ${(props) => props.color};
    font-size: 26px;
    font-family: ${(props) => props.fontFamily};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  AddSampleBtnContainer: styled.div<{ isVisible: boolean }>`
    position: absolute;
    right: 8px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 0.2s;
  `,
};
