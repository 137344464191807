import BlueAndRedButtonsModal from '../BlueAndRedButtonsModal';
import React, { useCallback } from 'react';
import { IAcceptCoverChangeModalProps } from '../../../store/appModals/appModals.types';
import { IModalComponentProps } from './AppModals';
import { useTranslation } from 'react-i18next';
import { isRtlByLang } from '../../../locale/i18n';
import i18next from 'i18next';
import { keepCurrentCoverButtonDataSet } from './AppModal.contants';

interface IProps extends IAcceptCoverChangeModalProps, IModalComponentProps {}

const AcceptCoverChangeModal: React.FC<IProps> = ({
  isVisible,
  onClose,
  onModalHide,
  onAccept,
  onReject,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleAccept = useCallback(() => {
    onAccept();
    onClose();
  }, [onAccept, onClose]);

  const handleReject = useCallback(() => {
    if (typeof onReject === 'function') {
      onReject();
    }
    onClose();
  }, [onReject, onClose]);

  const handleOnClose = useCallback(() => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    onClose();
  }, [onCancel, onClose]);

  return (
    <BlueAndRedButtonsModal
      isVisible={isVisible}
      onClose={handleOnClose}
      onModalHide={onModalHide}
      description={t('acceptCoverChangeModal.description')}
      onLeftButtonClick={handleAccept}
      onRightButtonClick={handleReject}
      rightButtonLabel={t('acceptCoverChangeModal.keepCurrent')}
      leftButtonLabel={t('acceptCoverChangeModal.newCover')}
      reverseColors={isRtlByLang[i18next.language]}
      dataSet={keepCurrentCoverButtonDataSet}
    />
  );
};

export default AcceptCoverChangeModal;
