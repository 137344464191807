import styled from 'styled-components/native';
import React, { ReactElement, useCallback, useState } from 'react';
import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../../utils/dimensions';
import SelectQuestion from './common/SelectQuestion';
import { useTranslation } from 'react-i18next';
import {
  EQuestionAnswerType,
  EQuestionType,
  IQuestion,
  IQuestionAnswer,
  IQuestionnaire,
  IUserQuestionnaire,
} from '../../../store/questionnaire/questionnaire.types';
import BackButton from '../BackButton';
import BooleanQuestion from './common/BooleanQuestion';
import { SafeAreaView } from 'react-native';
import Preloader from '../Preloader';

export interface IQuestionnaireProps {
  questionnaire: IQuestionnaire;
  answers: IUserQuestionnaire['answers'];
  renderHeaderIcon?: () => ReactElement;
  onQuestionAnswer?: ({
    question,
    answers,
    answer,
  }: {
    answers: IUserQuestionnaire['answers'];
    question?: IQuestion;
    answer?: IQuestionAnswer;
  }) => void;
  onFinish: () => void;
}

const Questionnaire: React.FC<IQuestionnaireProps> = ({
  questionnaire,
  answers,
  renderHeaderIcon,
  onQuestionAnswer,
  onFinish,
}) => {
  const { t } = useTranslation();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const isFinalQuestion = questionnaire && currentQuestion >= questionnaire.questions.length - 1;
  const isFirstQuestion = currentQuestion === 0;

  const question = questionnaire?.questions[currentQuestion];

  const handleNextQuestion = useCallback(() => {
    if (!isFinalQuestion) {
      setCurrentQuestion(currentQuestion + 1);
      return;
    }

    onFinish();
  }, [currentQuestion, onFinish, isFinalQuestion]);

  const handlePreviousQuestion = useCallback(() => {
    if (!isFirstQuestion) {
      setCurrentQuestion(currentQuestion - 1);
      return;
    }
  }, [currentQuestion, isFirstQuestion]);

  const handleQuestionAnswer = useCallback(
    (value: IQuestionAnswer['value'], type?: EQuestionAnswerType) => {
      const answer: IQuestionAnswer = {
        type,
        value,
      };

      if (question.type !== EQuestionType.MULTI_SELECT || type === EQuestionAnswerType.array) {
        answer.hubspotSyncField = question.hubspotSyncField;
        answer.hubspotSyncObject = question.hubspotSyncObject;
      }

      const updatedAnswers = {
        ...answers,
        [question.id]: answer,
      };

      onQuestionAnswer({ answers: updatedAnswers, question, answer });

      handleNextQuestion();
    },
    [answers, handleNextQuestion, onQuestionAnswer, question],
  );

  const renderQuestion = useCallback(
    (q: IQuestion) => {
      const answer = answers?.[q.id];
      const props = { question: q, answer, isFinalQuestion, key: q.id, onQuestionAnswer: handleQuestionAnswer };
      switch (q.type) {
        case EQuestionType.SINGLE_SELECT:
          return <SelectQuestion {...props} />;
        case EQuestionType.BOOLEAN:
          return <BooleanQuestion {...props} />;
        case EQuestionType.MULTI_SELECT:
          return <SelectQuestion isMultiSelect {...props} />;
        default:
          return null;
      }
    },
    [answers, handleQuestionAnswer, isFinalQuestion],
  );

  const renderCurrentScreen = useCallback(() => {
    return (
      <>
        <S.Description>{t(question.description)}</S.Description>
        <S.Title>{t(question.title)}</S.Title>
        {renderQuestion(question)}
      </>
    );
  }, [t, question, renderQuestion]);

  return (
    <S.Wrapper>
      <S.Container>
        {!questionnaire ? (
          <S.PreloaderContainer>
            <Preloader />
          </S.PreloaderContainer>
        ) : (
          <>
            <S.Header>
              {question.canGoBack && !isFirstQuestion ? (
                <S.BackButtonContainer>
                  <BackButton onPress={handlePreviousQuestion} />
                </S.BackButtonContainer>
              ) : (
                <S.EmptyView />
              )}
              {renderHeaderIcon && renderHeaderIcon()}
              {question.isSkippable ? (
                <S.SkipButton onPress={handleNextQuestion}>
                  <S.SkipButtonText>{t('common.Skip')}</S.SkipButtonText>
                </S.SkipButton>
              ) : (
                <S.EmptyView />
              )}
            </S.Header>
            <S.RenderQuestionnaire>{renderCurrentScreen()}</S.RenderQuestionnaire>
          </>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

const S = {
  PreloaderContainer: styled.View`
    flex: 1;
    height: 100%;
    justify-content: center;
  `,
  Wrapper: styled(SafeAreaView)`
    ${!isWeb ? 'flex: 1;' : ''}
  `,
  Container: styled.View`
    align-self: center;
    max-height: ${deviceHeight - calcHeight(100)}px;
    width: ${isWeb ? calcWidth(900) : deviceWidth - calcWidth(20)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 20px;
  `,
  RenderQuestionnaire: styled.View`
    width: 100%;
  `,
  Description: styled.Text`
    white-space: break-spaces;
    align-self: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightGray40};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  Title: styled.Text`
    white-space: break-spaces;
    align-self: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.s22};
    text-align: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  Question: styled.View``,
  Header: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: ${calcWidth(25)}px;
    padding-left: ${calcWidth(25)}px;
    padding-right: ${calcWidth(25)}px;
  `,

  Button: styled.TouchableOpacity<{ disabled?: boolean }>`
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.white};
    margin: 0;
    ${({ disabled }) => disabled && 'opacity: 0.5;'}
  `,
  SkipButton: styled.TouchableOpacity`
    flex: 1;
    align-items: flex-end;
    height: 100%;
    margin-right: ${!isWeb ? calcWidth(-15) : 0}px;
    ${({ disabled }) => disabled && 'opacity: 0.5;'};
  `,
  SkipButtonText: styled.Text<{ disabled?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.borderGray : theme.colors.text)};
  `,
  ButtonText: styled.Text<{ disabled?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.borderGray : theme.colors.text)};
  `,
  BackButtonContainer: styled.View`
    flex: 1;
    align-items: flex-start;
    height: 100%;
    margin-left: ${!isWeb ? calcWidth(-15) : 0}px;
  `,
  EmptyView: styled.View`
    flex: 1;
  `,
};

export default Questionnaire;
