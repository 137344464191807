import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, StyleProp, ViewStyle } from 'react-native';
import formatSubtitles from '../../../../utils/formatSubtitles';
import { ISubtitlesLine } from '../../../../types/subtitles';

interface IProps {
  playerRef: any;
  lines: ISubtitlesLine[];
}

const Subtitles: React.FC<IProps> = ({ playerRef, lines }) => {
  const [subtitles, setSubtitles] = useState([]);
  const [currentLine, setCurrentLine] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  const updateCurrentTime = useCallback((eventProps) => {
    setCurrentTime(Math.floor(eventProps.currentTime * 10) / 10.0);
  }, []);

  useEffect(() => {
    if (playerRef) {
      playerRef.subscribeToStateChange(updateCurrentTime);
    }
  }, [playerRef, updateCurrentTime]);

  useEffect(() => {
    if (!subtitles) {
      return;
    }

    const selectedLine =
      subtitles.find((line) => currentTime < line.endTime && currentTime > line.startTime)?.line || null;

    setCurrentLine(selectedLine);
  }, [currentTime, subtitles]);

  useEffect(() => {
    if (!lines) {
      return;
    }

    setSubtitles(formatSubtitles(lines));
  }, [lines]);

  if (!currentLine) {
    return null;
  }

  return (
    <View pointerEvents={'none'} style={S.container}>
      <View pointerEvents={'none'} style={S.subtitleContainer}>
        <Text style={S.subtitle}>{currentLine}</Text>
      </View>
    </View>
  );
};

const S = {
  subtitle: {
    color: 'white',
    textAlign: 'center',
    textShadowColor: 'black',
    textShadowOffset: { width: 1, height: 1 },
  } as StyleProp<ViewStyle>,
  subtitleContainer: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: 10,
    borderRadius: 10,
  } as StyleProp<ViewStyle>,
  container: {
    position: 'absolute',
    bottom: '7%',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 100,
  } as StyleProp<ViewStyle>,
};

export default Subtitles;
