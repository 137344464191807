import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IModalStackItem {
  modalId: number;
  isVisibleProp: boolean;
  isVisibleInStack: boolean;
}
export interface IInitialState {
  modalStack: IModalStackItem[];
}

const initialState: IInitialState = {
  modalStack: [],
};

const modalController = 'modalController';

const modalControllerSlice = createSlice({
  name: modalController,
  initialState,
  reducers: {
    removeFromStack: (state, { payload }: PayloadAction<number>) => {
      state.modalStack = state.modalStack.filter((item) => item.modalId !== payload);
    },
    addToStack: (
      state,
      { payload: { modalId, isVisibleProp } }: PayloadAction<{ modalId: number; isVisibleProp: boolean }>,
    ) => {
      state.modalStack = [
        ...state.modalStack,
        {
          isVisibleProp,
          modalId,
          isVisibleInStack: false,
        },
      ];
    },
    setIsVisibleProp: (
      state,
      { payload: { modalId, isVisibleProp } }: PayloadAction<{ modalId: number; isVisibleProp: boolean }>,
    ) => {
      const stackItemIndex = state.modalStack.findIndex((item) => item.modalId === modalId);
      if (stackItemIndex === -1) {
        return;
      }
      const stackItem = state.modalStack[stackItemIndex];
      if (!stackItem.isVisibleProp && isVisibleProp) {
        state.modalStack.splice(stackItemIndex, 1);
        state.modalStack.push(stackItem);
      }
      stackItem.isVisibleProp = isVisibleProp;
    },
    setIsVisibleInStack: (
      state,
      { payload: { modalId, isVisible } }: PayloadAction<{ modalId: number; isVisible: boolean }>,
    ) => {
      state.modalStack.forEach((item) => {
        if (item.modalId === modalId) {
          item.isVisibleInStack = isVisible;
          return;
        }
        if (isVisible === true) {
          item.isVisibleInStack = false;
        }
      });
    },
  },
});

export const { removeFromStack, addToStack, setIsVisibleProp, setIsVisibleInStack } = modalControllerSlice.actions;

export default modalControllerSlice.reducer;
