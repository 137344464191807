import React, { memo, useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import ShadowedContainer from '../../../components/ShadowedContainer';
import letters from '../../../utils/letters';
import i18n, { TLocale } from '../../../locale/i18n';
import CorrectAnswerIcon from '../../../assets/icons/analytics/question_correct.svg';
import CloseIcon from '../../../assets/icons/close.svg';
import { StyleProp, ViewStyle } from 'react-native';
import Skeleton from '../../../components/shared/Skeleton';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import defaultTheme from '../../../themes';
import { IBiteAnswer } from '../../../types/biteQuestion';

interface IProps {
  answer: IBiteAnswer;
  percent: number;
  onPress: (answerId: number) => void;
  withCloseControl?: boolean;
  isCorrect?: boolean;
  isLoading?: boolean;
  isRefreshing?: boolean;
  dataSet?: { [key: string]: string | number };
}

const OFFSET: [number, number] = [0, 0];

const AnswerItem: React.FC<IProps> = ({
  answer,
  percent,
  onPress,
  isCorrect,
  withCloseControl,
  isLoading,
  isRefreshing,
  dataSet,
}) => {
  const theme = useTheme();

  const handlePress = useCallback(() => {
    onPress(answer.id);
  }, [answer, onPress]);

  return (
    <ShadowedContainer offset={OFFSET} distance={15} containerViewStyle={S.ShadowedContainerContainerViewStyle}>
      <S.AnswerButton
        // @ts-ignore
        dataSet={dataSet}
        onPress={handlePress}
      >
        <AnimatedFill percent={percent} />
        <S.LetterText>{letters[i18n.language as TLocale][answer.order - 1]}</S.LetterText>
        <S.AnswerText>{answer.text}</S.AnswerText>

        <S.PercentText>
          {isLoading && !isRefreshing ? <Skeleton width={calcWidth(35)} height={calcHeight(17)} /> : `${percent}%`}
        </S.PercentText>

        {isCorrect && !withCloseControl && (
          <S.CorrectAnswerIconContainer>
            <CorrectAnswerIcon />
          </S.CorrectAnswerIconContainer>
        )}
        {withCloseControl && (
          <ShadowedContainer containerViewStyle={S.CloseIconContainerViewStyle}>
            <S.CloseIconContainer>
              <CloseIcon width={calcWidth(13)} height={calcWidth(13)} fill={theme.colors.primaryBlue} />
            </S.CloseIconContainer>
          </ShadowedContainer>
        )}
      </S.AnswerButton>
    </ShadowedContainer>
  );
};

interface IAnsweredFillProps {
  percent: number;
}

const AnimatedFill: React.FC<IAnsweredFillProps> = ({ percent }) => {
  const shared = useSharedValue(0);

  useEffect(() => {
    shared.value = withTiming(percent || 0, {
      duration: 300,
      easing: Easing.ease,
    });
  }, [percent, shared]);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      zIndex: -1,
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      borderWidth: shared.value > 0 ? 1 : 0,
      borderColor: defaultTheme.colors.white,
      borderRadius: 10,
      backgroundColor: defaultTheme.colors.gray28,
      width: `${shared.value}%`,
    };
  }, []);

  return <Animated.View style={animatedStyles} />;
};

const S = {
  ShadowedContainerContainerViewStyle: {
    marginBottom: calcHeight(7),
    marginLeft: calcWidth(45 / 2),
    marginRight: calcWidth(45 / 2),
    borderRadius: 10,
  },
  AnswerButton: styled.TouchableOpacity`
    min-height: ${calcHeight(50)}px;
    width: ${isWeb ? '100%' : deviceWidth - calcWidth(45)}px;
    border-radius: 10px;
    flex-direction: row;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  LetterText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin: ${calcHeight(14)}px 0 ${calcHeight(14)}px ${calcWidth(12)}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: right;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: 700;
  `,
  AnswerText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    width: ${isWeb ? calcWidth(400) : deviceWidth - calcWidth(140)}px;
    margin: ${calcHeight(16)}px ${calcWidth(12)}px;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-weight: 400;
    text-align: left;
  `,
  PercentText: styled.Text`
    position: absolute;
    top: ${calcHeight(16)}px;
    right: ${calcWidth(15)}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: right;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 700;
  `,
  CorrectAnswerIconContainer: styled.View`
    position: absolute;
    top: ${calcHeight(13)}px;
    right: ${calcWidth(-12)}px;
  `,
  CloseIconContainer: styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    width: ${calcWidth(26)}px;
    height: ${calcWidth(26)}px;
    border-radius: ${calcWidth(13)}px;
    align-items: center;
    justify-content: center;
  `,
  CloseIconContainerViewStyle: {
    position: 'absolute',
    top: calcHeight(-15),
    right: calcWidth(-11),
  } as StyleProp<ViewStyle>,
};

export default memo(AnswerItem);
