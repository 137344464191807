import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { StyleSheet, Platform, View } from 'react-native';
import Modal from '../ModalController';
import { ControlledModal } from '../../../types/modals';
import { useSafeAreaInsets, EdgeInsets } from 'react-native-safe-area-context';
import { calcWidth, calcHeight } from '../../../utils/dimensions';
import { youtubeUrlParser } from '../../../utils/youtubeUrlParser';
import { useTranslation } from 'react-i18next';
import { MediaController } from '../../../types/media';
import { KeyboardAwareView } from '../../../components/shared';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import ClearUrl from '../../../assets/icons/close.svg';
import Header from '../../Header';
import YoutubeIcon from '../../../assets/icons/buttons-rounded-youtube-red.svg';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import Switch from '../../shared/Switch';
import { INITIAL_YOUTUBE_URL } from '../../../utils/constants/urls';
import YoutubePlayer from '../../shared/MediaViewer/VideoPlayers/YoutubePlayer.web';
import UrlInput from '../../shared/UrlInput/UrlInput';
import { clearYoutubeUrlDataSet, continueFromYoutubeDataSet } from './YoutubeUploadModal.constants';

interface IProps {
  mediaControls: MediaController;
  closeMediaModal?: () => void;
  from?: string;
  onClose?: () => void;
}

const YoutubeUploadModal = React.forwardRef<ControlledModal, IProps>((props, ref) => {
  const dispatch = useDispatch();
  const { mediaControls, closeMediaModal, from, onClose } = props;
  const { youtubeMedia: initialYoutubeMedia } = mediaControls;

  const [isYouTubeModalOpen, setYouTubeModalOpen] = useState(false);
  const [urlInput, setUrlInput] = useState(initialYoutubeMedia?.url || 'https://www.youtube.com/watch?v=C92gtfXd6Xc');
  const [validYoutubeURL, setValidYoutubeURL] = useState('');
  const [isSubtitlesOn, setIsSubtitlesOn] = useState(initialYoutubeMedia?.is_cc_enabled || false);

  const insets = useSafeAreaInsets();
  const { t } = useTranslation();
  const youtubeVideoRef = useRef(null);
  const isEditingRange = useRef(false);
  const theme = useTheme();

  const handleResetState = useCallback(() => {
    const initialUrl = initialYoutubeMedia?.url || INITIAL_YOUTUBE_URL;
    const validYoutubeUrl = youtubeUrlParser(initialUrl);

    setUrlInput(initialUrl);
    setIsSubtitlesOn(initialYoutubeMedia?.is_cc_enabled || false);
    setValidYoutubeURL(validYoutubeUrl);
  }, [initialYoutubeMedia?.is_cc_enabled, initialYoutubeMedia?.url]);

  const open = useCallback(() => setYouTubeModalOpen(true), []);
  const close = useCallback(() => setYouTubeModalOpen(false), []);

  const handleCloseWithReset = useCallback(() => {
    handleResetState();
    close();

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [close, handleResetState, onClose]);

  useEffect(() => {
    if (urlInput) {
      const youtubeURL = youtubeUrlParser(urlInput);
      if (youtubeURL) {
        setValidYoutubeURL(youtubeURL);
        youtubeVideoRef.current?.loadVideo(youtubeURL, 0);
        isEditingRange.current = false;
        return;
      }
    }
    setValidYoutubeURL('');
  }, [urlInput]);

  useEffect(() => {
    //@ts-ignore
    ref.current = {
      open,
      close,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanUrl = useCallback(() => {
    setUrlInput('');
  }, []);

  const saveYoutubeMedia = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'continue_btn',
        props: { page_title: 'YoutubeUploadModal', from },
      }),
    );
    if (validYoutubeURL) {
      const youtubeMedia = {
        videoId: validYoutubeURL,
        url: urlInput,
        video_start_at: 0,
        video_end_at: 0,
        is_cc_enabled: isSubtitlesOn,
      };
      mediaControls.setYoutubeMedia(youtubeMedia);
      closeMediaModal?.();
      close();
    }
  }, [dispatch, from, validYoutubeURL, urlInput, isSubtitlesOn, mediaControls, closeMediaModal, close]);

  const handleOpenYoutubeApp = useCallback(() => {
    window.open('https://www.youtube.com/', '_blank');
  }, []);

  const youtubeModalHeader = useCallback(() => {
    return (
      <S.YoutubeIconWrap onPress={handleOpenYoutubeApp}>
        <YoutubeIcon />
      </S.YoutubeIconWrap>
    );
  }, [handleOpenYoutubeApp]);

  const handleSwitchPress = useCallback(() => {
    setIsSubtitlesOn((prev) => !prev);

    dispatch(
      trackEvent({
        event: 'youtube_subtitles',
        props: { from, is_on: !isSubtitlesOn },
      }),
    );
  }, [dispatch, from, isSubtitlesOn]);

  const youtubeMedia = useMemo(
    () => ({ videoId: validYoutubeURL, is_cc_enabled: isSubtitlesOn }),
    [validYoutubeURL, isSubtitlesOn],
  );

  return (
    <Modal style={styles.modal} isVisible={isYouTubeModalOpen} onBackButtonPress={handleCloseWithReset}>
      <KeyboardAwareView>
        <S.Container {...{ insets }}>
          <Header title='Youtube' headerRight={youtubeModalHeader} onBackButtonPress={handleCloseWithReset} />
          <View>
            <UrlInput
              placeholder={t('createBiteStack.createBiteIntro.inputPlaceholder')}
              value={urlInput}
              onChangeText={setUrlInput}
              selectTextOnFocus
            />

            {validYoutubeURL ? (
              <>
                <S.ClearUrlContainer
                  onPress={cleanUrl}
                  //@ts-ignore
                  dataSet={clearYoutubeUrlDataSet}
                >
                  <ClearUrl fill={theme.colors.primaryBlue} />
                </S.ClearUrlContainer>
                <S.VideoContainer>
                  <YoutubePlayer youtubeMedia={youtubeMedia} />
                </S.VideoContainer>
              </>
            ) : (
              <S.VideoContainerEmpty onPress={() => window.open('https://www.youtube.com/', '_blank')}>
                <S.VideoContainerText>{t('createBiteStack.createBiteIntro.openYoutube')}</S.VideoContainerText>
              </S.VideoContainerEmpty>
            )}

            <S.ButtonsContainer>
              <S.SwitchContainer>
                <Switch isOn={isSubtitlesOn} onToggle={handleSwitchPress} />
                <S.SwitchText>{t('createBiteStack.createBiteIntro.enableYoutubeSubtitles')}</S.SwitchText>
              </S.SwitchContainer>
              <S.ContinueButton
                disabled={!validYoutubeURL}
                onPress={saveYoutubeMedia}
                content={t('common.Continue')}
                dataSet={continueFromYoutubeDataSet}
              />
            </S.ButtonsContainer>
          </View>
        </S.Container>
      </KeyboardAwareView>
    </Modal>
  );
});

const S = {
  Container: styled.View<{ insets: EdgeInsets }>`
    padding-top: ${({ insets }) => (Platform.OS === 'ios' ? insets.top : 0)}px;
    padding-bottom: ${({ insets }) => insets.bottom}px;
    align-items: center;
    flex: 1;
  `,
  YoutubeIconWrap: styled.TouchableOpacity``,
  VideoContainer: styled.View(
    ({ theme }) => css`
      background-color: ${theme.colors.gray16};
      height: ${calcWidth(188)}px;
      width: ${calcWidth(328)}px;
      border-radius: 1px;
      overflow: hidden;
      margin-top: ${calcHeight(41)}px;
    `,
  ),
  YoutubeLink: styled.TouchableOpacity`
    width: ${calcWidth(32)}px;
    height: ${calcWidth(32)}px;
    margin-top: ${calcWidth(30)}px;
  `,
  YoutubeImage: styled.Image`
    width: 100%;
    height: 100%;
  `,
  ClearUrlContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(15)}px;
    height: ${calcWidth(20)}px;
    width: ${calcWidth(18)}px;
    position: absolute;
    top: ${calcHeight(49)}px;
    right: ${calcWidth(34)}px;
  `,
  ContinueButton: styled(GenericButton)`
    width: ${calcWidth(111)}px;
    height: ${calcHeight(47)}px;
    align-self: flex-end;
    margin-top: 3%;
  `,
  VideoContainerEmpty: styled.TouchableOpacity(
    ({ theme }) => css`
      background-color: ${theme.colors.gray16};
      height: ${calcWidth(188)}px;
      width: ${calcWidth(328)}px;
      border-radius: 12px;
      overflow: hidden;
      margin-top: ${calcHeight(41)}px;
      display: flex;
      justify-content: center;
    `,
  ),
  VideoContainerText: styled.Text(
    ({ theme }) => css`
      color: ${theme.colors.primaryBlue};
      font-size: ${theme.fontSizes.s15}px;
      font-family: ${theme.fontFamilies.Arimo};
      text-align: center;
    `,
  ),
  ButtonsContainer: styled.View`
    width: ${calcWidth(316)}px;
    margin-bottom: ${calcHeight(200)}px;
    top: ${calcHeight(15)}px;
    height: ${calcHeight(47)}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,
  SwitchContainer: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  SwitchText: styled.Text`
    display: flex;
    justify-content: left;
    width: ${calcWidth(108)}px;
    font-size: ${({ theme }) => theme.fontSizes.s12};
    color: ${({ theme }) => theme.colors.text};
    line-height: ${({ theme }) => theme.fontSizes.s12 * 1.5}px;
  `,
};
const styles = StyleSheet.create({
  modal: {
    margin: 0,
    backgroundColor: '#fff',
  },
  actionButton: {
    padding: calcHeight(10),
  },
  youtubePlayer: {
    height: '100%',
    width: '100%',
  },
  link: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
});

export default YoutubeUploadModal;
