import React, { useMemo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import BiteLogo from '../../../assets/images/bite-logo.png';

interface IProps {
  workspaceLogoUrl?: string;
  onAddCover?: () => void;
  small?: boolean;
}

const WithoutCoverOverlay: React.FC<IProps> = ({ workspaceLogoUrl, onAddCover, small }) => {
  const { t } = useTranslation();

  const source = useMemo(() => (workspaceLogoUrl ? { uri: workspaceLogoUrl } : BiteLogo), [workspaceLogoUrl]);

  return (
    <S.Container withLogo={!!workspaceLogoUrl}>
      {typeof onAddCover === 'function' && (
        <S.AddCoverButton onPress={onAddCover}>
          <S.AddCoverButtonText>{t('homeScreen.addCover')}</S.AddCoverButtonText>
        </S.AddCoverButton>
      )}
      {workspaceLogoUrl && <S.Logo resizeMode='contain' source={source} small={small} />}
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ withLogo: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: ${({ theme, withLogo }) => (withLogo ? theme.colors.white : 'transparent')};
    z-index: 2;
  `,
  Logo: styled.Image<{ small?: boolean }>`
    width: ${({ small }) => calcWidth(small ? 30 : 100)}px;
    max-width: ${({ small }) => calcWidth(small ? 30 : 100)}px;
    height: ${({ small }) => calcHeight(small ? 15 : 50)}px;
    max-height: ${({ small }) => calcHeight(small ? 15 : 50)}px;
  `,
  AddCoverButton: styled.TouchableOpacity`
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid ${({ theme }) => theme.colors.lightGray35};
    border-top-width: 0;
    border-right-width: 0;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    align-items: center;
    justify-content: center;
    padding: ${calcHeight(6)}px ${calcWidth(13)}px;
    background: ${({ theme }) => theme.colors.white};
  `,
  AddCoverButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default WithoutCoverOverlay;
