import React, { memo, useCallback } from 'react';
import { showBottomPanel } from '../../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../../utils/constants/bottomPanel';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import ArrowDown from '../../../assets/icons/feed/arrow-down.svg';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { TFilter, TFilterValue } from '../../../store/analytics/analytics.types';
import { usersFilterDataSet } from '../analytics.constants';
import { log } from '../../../store/appActivity/appActivity.slice';

interface IProps {
  filters: TFilter[];
  currentFilter: TFilterValue;
  onChangeFilter: (filter: TFilterValue) => void;
}

const Filter: React.FC<IProps> = ({ filters, currentFilter, onChangeFilter }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const current = filters.find((filter) => filter.value === currentFilter);

  const handleFilterPress = useCallback(() => {
    dispatch(
      log({
        event: 'Filter.handleFilterPress',
        data: { currentFilter, filters },
      }),
    );

    dispatch(
      showBottomPanel({
        componentName: EBottomPanelComponentName.AnalyticsFilter,
        componentProps: {
          currentFilter,
          onChangeFilter,
          filters,
        },
      }),
    );
  }, [dispatch, currentFilter, onChangeFilter, filters]);

  return (
    <S.FilterContainer>
      <S.FilterButton
        // @ts-ignore
        dataSet={usersFilterDataSet}
        onPress={handleFilterPress}
      >
        <S.FilterText>{t(current.label)}</S.FilterText>
        <S.ArrowIconContainer>
          <ArrowDown color={theme.colors.primaryBlue} />
        </S.ArrowIconContainer>
      </S.FilterButton>
    </S.FilterContainer>
  );
};

const S = {
  FilterButton: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  FilterContainer: styled.View`
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 16px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  FilterText: styled.Text`
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  `,
  ArrowIconContainer: styled.View`
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;
  `,
};

export default memo(Filter);
