import { View } from 'react-native';
import React, { ReactElement, useCallback, useRef } from 'react';

const useVideoThumbnail = (): {
  thumbnailCanvas: ReactElement;
  getThumbnail: (file: File, thumbnailTime?: number) => Promise<File>;
} => {
  const dropzoneRef = useRef(null);
  const thumbnailCanvas = <View ref={dropzoneRef} style={S.ThumbnailCanvas} />;

  const getThumbnail: (file: File, thumbnailTime?: number) => Promise<File> = useCallback(
    (file: File, thumbnailTime: number = 0.1) =>
      new Promise((resolve, reject) => {
        try {
          window.URL = window.URL || window.webkitURL;
          const fileUrl = window.URL.createObjectURL(file);
          const name = file.name.replace(/\.[^/.]+$/, '').replace(/\s+/g, '_') + '_thumbnail.png';
          const videoPlayer = document.createElement('video');

          const convertBlobToFile = (blob: Blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob as Blob);
            reader.onloadend = () => {
              const thumbnail = new File([blob], name, { type: 'image/png' });
              resolve(thumbnail);
            };
          };

          const onSeek = () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            dropzoneRef.current.appendChild(canvas);

            const context = canvas.getContext('2d');

            context.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

            context.canvas.toBlob(convertBlobToFile, 'image/jpeg', 1);

            dropzoneRef.current.removeChild(canvas);
          };

          const onLoadData = () => {
            videoPlayer.currentTime = thumbnailTime;
          };

          const onError = (error: ErrorEvent) => {
            reject(`Error when loading video file ${JSON.stringify(error)}`);
          };

          videoPlayer.addEventListener('error', onError);
          videoPlayer.addEventListener('loadeddata', onLoadData);
          videoPlayer.addEventListener('seeked', onSeek);

          videoPlayer.setAttribute('src', fileUrl);
          videoPlayer.crossOrigin = 'anonymous';
          videoPlayer.load();
        } catch (error) {
          reject(error);
        }
      }),
    [],
  );

  return {
    getThumbnail,
    thumbnailCanvas,
  };
};

const S = {
  ThumbnailCanvas: {
    flex: 1,
  },
};

export default useVideoThumbnail;

export const getVideoThumbnail = async (file: File | Blob, seconds: number = 0): Promise<File> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const objectUrl = URL.createObjectURL(file);

    video.addEventListener(
      'error',
      () => {
        URL.revokeObjectURL(objectUrl);
        reject(new Error('Error loading video file.'));
      },
      { once: true },
    );

    video.addEventListener(
      'loadedmetadata',
      () => {
        // Set video playback to the specified time
        video.currentTime = seconds;

        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');

        video.addEventListener(
          'seeked',
          () => {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              if (blob) {
                const fileName = `thumbnail_${Date.now()}.jpeg`; // Generate a unique file name
                const thumbnailFile = new File([blob], fileName, { type: 'image/jpeg' });
                resolve(thumbnailFile);
              } else {
                reject(new Error('Failed to create blob from canvas.'));
              }
              URL.revokeObjectURL(objectUrl);
            }, 'image/jpeg');
          },
          { once: true },
        );
      },
      { once: true },
    );

    video.src = objectUrl;
    video.muted = true;
  });
};
