import { cloneDeep } from 'lodash';
import Types from './createPlaylist.types';
import { ISelectedBite } from './createPlaylist.types';
import { IPlaylist } from '../playlist/playlist.types';

const initState = {
  subject: '',
  description: '',
  selectedBites: [] as ISelectedBite[],
  selectedBiteIdsToRemoveMap: {} as { [key in number]: boolean },
  is_quiz: false,
  playlistSettings: {
    is_public: false,
    is_linear: true,
  },
  quizSettings: {
    correct_answer_indication: false,
    show_report: true,
    background_color: '',
    question_color: '',
  },
  isPosting: false,
  postOrUpdateSuccess: false,
  editMode: false,
  linkedCoverUrl: null,
  editedPlaylist: null as IPlaylist | null,
};

export type CreatePlaylistState = typeof initState;

const playlistReducer = (state = initState, action: any): CreatePlaylistState => {
  switch (action.type) {
    case Types.SET_PLAYLIST_NAME:
      return {
        ...state,
        subject: action.payload,
      };

    case Types.SELECT_BITE:
      const selectedBitesCopy = [...state.selectedBites];
      const biteIndex = selectedBitesCopy.findIndex((bite) => bite.id === action.payload.id);
      if (biteIndex === -1) {
        selectedBitesCopy.push(action.payload);
      } else {
        selectedBitesCopy.splice(biteIndex, 1);
      }
      return {
        ...state,
        selectedBites: selectedBitesCopy,
      };

    case Types.UPDATE_SELECTED_BITES:
      return {
        ...state,
        selectedBites: [...action.payload],
      };
    case Types.REMOVE_SELECTED_BITE:
      return {
        ...state,
        selectedBites: state.selectedBites.filter((selectedBite) => selectedBite.id !== action.payload),
      };

    case Types.CLEAN_SELECTED_TO_REMOVE_BITES:
      return {
        ...state,
        selectedBiteIdsToRemoveMap: {},
      };

    case Types.TOGGLE_SELECTED_TO_REMOVE_BITE:
      const selectedBiteIdsToRemoveMap = { ...state.selectedBiteIdsToRemoveMap };

      if (selectedBiteIdsToRemoveMap[action.payload]) {
        delete selectedBiteIdsToRemoveMap[action.payload];
      } else {
        selectedBiteIdsToRemoveMap[action.payload] = true;
      }
      return {
        ...state,
        selectedBiteIdsToRemoveMap,
      };

    case Types.REMOVE_SELECTED_BITES:
      return {
        ...state,
        selectedBites: state.selectedBites.filter((selectedBite) => !state.selectedBiteIdsToRemoveMap[selectedBite.id]),
        selectedBiteIdsToRemoveMap: {},
      };

    case Types.UPDATE_PLAYLIST_SETTING:
      const playlistSettings = { ...state.playlistSettings };
      playlistSettings[action.payload] = !playlistSettings[action.payload];
      return {
        ...state,
        playlistSettings,
      };

    case Types.SET_PLAYLIST_ABOUT:
      return {
        ...state,
        description: action.payload,
      };

    case Types.POST_PLAYLIST_REQUEST:
      return {
        ...state,
        isPosting: true,
      };

    case Types.POST_AND_UPDATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        isPosting: false,
        postOrUpdateSuccess: true,
      };

    case Types.POST_AND_UPDATE_PLAYLIST_ERROR:
      return {
        ...state,
        isPosting: false,
        postOrUpdateSuccess: false,
      };

    case Types.RESET_CREATE_PLAYLIST_STATE:
      return initState;

    case Types.SET_PLAYLIST_TO_EDIT:
      const { playlist, editMode = true } = action.payload;
      return {
        ...state,
        editMode,
        editedPlaylist: playlist,
        subject: playlist.subject,
        description: playlist.description || '',
        linkedCoverUrl: playlist.linked_cover_url,
        selectedBites: playlist.bite_shares.map((biteShare) => ({
          id: biteShare.bite,
          cover_url: biteShare.cover_url,
          linked_cover_url: biteShare.linked_cover_url,
          subject: biteShare.subject,
          playlist_section: biteShare.playlist_section,
        })),
        playlistSettings: {
          is_public: playlist.is_public,
          is_linear: playlist.is_linear,
        },
        postOrUpdateSuccess: false,
        is_quiz: playlist.is_quiz,
      };

    case Types.UPDATE_EDITED_PLAYLIST_SHARE_MODE: {
      const { shareMode } = action.payload;
      const newState = cloneDeep(state);
      newState.editedPlaylist.sharing_mode = shareMode;
      return newState;
    }

    case Types.UPDATE_EDITED_PLAYLIST: {
      return {
        ...state,
        editedPlaylist: {
          ...(state.editedPlaylist || {}),
          ...action.payload,
        },
      };
    }

    case Types.RESET_PLAYLIST_AS_QUIZ:
      return {
        ...state,
        is_quiz: true,
        playlistSettings: {
          is_public: false,
          is_linear: true,
        },
      };

    case Types.SET_LINKED_COVER_URL:
      return {
        ...state,
        linkedCoverUrl: action.payload,
      };

    default:
      return state;
  }
};

export default playlistReducer;
