import React from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { I18nManager, ViewStyle } from 'react-native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';

interface IProps {
  isEditMode?: boolean;
  text: string;
  placeholder?: string;
  onTextChange: (text: string) => void;
  fontSize?: number;
  textPlaceholder?: string;
  style?: ViewStyle;
}

const EditableTextInput: React.FC<IProps> = (props) => {
  const { isEditMode, text, placeholder, onTextChange, fontSize, textPlaceholder, style } = props;
  const theme = useTheme();

  return (
    <S.View style={style}>
      {isEditMode ? (
        <S.Input
          value={text}
          placeholder={placeholder}
          placeholderTextColor={theme.colors.lightGray5}
          onChangeText={onTextChange}
        />
      ) : (
        <S.ContactDetailsText numberOfLines={1} fontSize={fontSize}>
          {text || textPlaceholder}
        </S.ContactDetailsText>
      )}
    </S.View>
  );
};

const S = {
  View: styled.View`
    width: 100%;
  `,
  Text: styled.Text``,
  ContactDetailsText: styled.Text<{ fontSize?: number }>`
    text-align: center;
    ${({ theme, fontSize }) => css`
      color: ${theme.colors.text};
      font-size: ${fontSize || theme.fontSizes.s18}px;
      font-family: ${theme.fontFamilies.GilroyMedium};
      padding: 4%;
    `}
  `,
  Input: styled.TextInput(
    ({ theme }) => css`
      font-size: ${theme.fontSizes.s16};
      text-align: ${I18nManager.isRTL && !isWeb ? 'right' : 'left'};
      border: 1px solid ${theme.colors.gray20};
      padding: ${calcHeight(15)}px ${calcWidth(20)}px;
      color: ${theme.colors.text};
      height: ${calcHeight(50)}px;
      border-radius: ${calcHeight(50) / 2}px;
      background-color: #fff;
    `,
  ),
};

export default EditableTextInput;
