// @ts-nocheck
/* eslint-disable */

const searchParams = new URLSearchParams(window.location.search);
if (!searchParams.has('test')) {
  window.smartlook ||
    (function (d) {
      var o = (smartlook = function () {
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName('head')[0];
      var c = d.createElement('script');
      o.api = new Array();
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://rec.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);
  window.smartlook('init', '918937fc051f467b133fb1dc3ff1184ddf5abf91');
}

export {};
