import React, { FC } from 'react';
import styled from 'styled-components/native';
import Switch from './Switch';
import {
  POST_BITE_HORIZONTAL_PADDING,
  POST_BITE_TOGGLE_ICON_WIDTH,
  POST_BITE_TOGGLE_ICON_END_MARGIN,
} from '../../screens/createBite/PostYourBite/uiConstants';
import { calcFontSize, calcHeight, calcWidth } from '../../utils/dimensions';

interface IProps {
  isSelected: boolean;
  settingText: string;
  onTogglePress: () => void;
  isLast?: boolean;
}

const SettingItem: FC<IProps> = ({ isSelected, onTogglePress, settingText, isLast }) => {
  return (
    <S.Container isLast={isLast}>
      <S.SettingText>{settingText}</S.SettingText>
      <S.SwitchContainer>
        <Switch isOn={isSelected} onToggle={onTogglePress} />
      </S.SwitchContainer>
    </S.Container>
  );
};

const SETTING_TEXT_START_PADDING = POST_BITE_TOGGLE_ICON_WIDTH + POST_BITE_TOGGLE_ICON_END_MARGIN;

const S = {
  Container: styled.View<{ isLast: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${calcHeight(43)}px;
    border-bottom-color: #e8eef4;
    border-style: solid;
    ${({ isLast }) => !isLast && 'border-bottom-width: 3px'};
    margin-left: ${POST_BITE_HORIZONTAL_PADDING}px;
    margin-right: ${POST_BITE_HORIZONTAL_PADDING}px;
  `,
  SettingText: styled.Text`
    font-size: ${calcFontSize(17)}px;
    color: ${({ theme }) => theme.colors.darkGray6};
    margin-left: ${SETTING_TEXT_START_PADDING}px;
  `,
  SwitchContainer: styled.View`
    margin-right: ${calcWidth(30)}px;
  `,
};

export default SettingItem;
