import React, { memo, useCallback } from 'react';
import WebViewModal from '../../../components/modals/WebViewModal/WebViewModal';
import { isWeb } from '../../../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import { setTermsConditionsVisible } from '../../../store/authForm/authForm.slice';
import { isTermsConditionsVisibleSelector } from '../../../store/authForm/authForm.selectors';
import { TERMS_CONDITIONS_NATIVE } from '../../../utils/constants/auth';

interface IProps {}

const TermsConditionsModal: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isTermsConditionsVisibleSelector);

  const handleModalHide = useCallback(() => {
    dispatch(setTermsConditionsVisible(false));
  }, [dispatch]);

  if (isWeb) {
    return null;
  }

  return (
    <WebViewModal url={TERMS_CONDITIONS_NATIVE} isVisible={isVisible} onClose={handleModalHide} withCloseIcon={true} />
  );
};

export default memo(TermsConditionsModal);
