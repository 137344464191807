import { all, put, select, spawn, fork, take, takeLatest, delay } from 'redux-saga/effects';
import Types, { EIntroMediaProcessingStatus, IIntroMediaProcessingStatus } from './createBite/createBite.types';
import { introMediaDurationSelector, introMediaProcessingStatusSelector } from './createBite/createBite.selectors';
import { editBiteIntro } from './api/bites-api/calls/bite.calls';
import { activeOrganizationSelector } from './auth/auth.selectors';
import { biteSelector } from './bite/bite.selectors';
import {
  indexBites,
  setBites,
  setBiteToEdit,
  setHasBites,
  setSelectedBiteCover,
  updateBiteSection,
  updateSelectedBite,
} from './bite/bite.actions';
import {
  IEditBiteIntroVideoAction,
  setBiteCover,
  setBiteIntro,
  setBiteIntroEdited,
  setIntroMediaProcessingStatus,
  setIntroTask,
  setIntroVideoMediaUri,
  updateBiteData,
  createBiteIntroVideoBitePrepared,
  createBiteIntroVideoAudioUploaded,
  createBiteIntroVideoVideoExported,
  editBiteIntroVideo,
} from './createBite/createBites.actions';
import {
  log,
  logError,
  setBiteAvaitingEnhancements,
  setCurrentFlow,
  trackEvent,
} from './appActivity/appActivity.slice';
import { userSelector } from './auth/auth.selectors';
import { formatBiteFromServer } from '../utils/formatDataFromServer';
import { addToDrafts } from './drafts/drafts.slice';
import { loadNextPage } from './feed/feed.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../utils/constants/toastConfig';
import { createThumbnail } from 'react-native-create-thumbnail';
import uploadImageUtil from '../utils/uploadImage';
import { createBiteSaga } from './createBite/createBite.saga';
import { ITrackingMeta, ITrackingOptions } from './createBite/createBite.types';
import {
  SUBTITLES_PROCESSING_TIMEOUT,
  trackIntroMediaProcessingEnhancements,
  trackIntroMediaProcessingSubtitles,
} from './introVideoPolling.saga';
import shortid from 'shortid';
import { isWeb } from '../utils/dimensions';
import uploadToS3 from '../utils/uploadToS3';
import { displayAppModal } from './appModals/appModals.slice';
import { EAppModalStackItemType } from './appModals/appModals.types';
import { EFileType } from '../types/media';
import { exportAudio, exportVideo } from '../services/videoEditor/videoEditor';
import { IBiteItem } from '../types/bite';
import { cloneDeep } from 'lodash';
import withRetry from '../utils/withRetry';
import { PayloadAction } from '@reduxjs/toolkit';
import { IVideoMeta } from '../hooks/useMedia/useMedia';
import { configSelector } from './appActivity/appActivity.selectors';
import { FileWithPath } from 'react-dropzone';
import { getIsFeatureEnabledForCurrentState } from '../utils/featureFlags';
import { saveVideo, setUsedInBiteIds, webVideoExport } from './videoEditor/videoEditor.slice';
import prepareS3SinglePartUploadDetails from '../utils/uploadToS3/prepareS3SinglePartUploadDetails';
import { v4 as uuid } from 'uuid';
import { createExportedVideoService, getExportedVideoService } from './api/bites-api/calls/videoEditor.calls';
import { videoSelector } from './videoEditor/videoEditor.selectors';
import { IExportedVideo, ITimelineItem, IVideo } from './videoEditor/videoEditor.types';
import { getCloudAssetIdsFromTimelineLayers } from './cloudAssets/cloudAssets.saga';
import { getItemsMapFromTimelineLayers } from './videoEditor/videoEditor.saga';
import axios from 'axios';

function* webVideoExportSaga() {
  const processId = uuid();
  const org = yield select(activeOrganizationSelector);
  const user = yield select(userSelector);
  const video: IVideo = yield select(videoSelector);

  try {
    const state = yield prepareState({
      videoMeta: {
        duration: video.config.duration,
      },
    });

    yield put(setBiteIntro(null));
    yield put(setIntroMediaProcessingStatus({ ...state.trackingMeta.status }));
    yield put(setBiteIntroEdited(true));

    const { counter: initialCounter }: IIntroMediaProcessingStatus = yield select(introMediaProcessingStatusSelector);
    state.initialCounter = initialCounter;

    if (!state.biteId) {
      yield prepareBiteSaga({ state });
    }

    const cloudAssetIdsMap: Record<string, ITimelineItem[]> = yield getCloudAssetIdsFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });
    const assetIds = Object.keys(cloudAssetIdsMap);

    const itemsMap: Record<string, ITimelineItem> = yield getItemsMapFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });

    const hasUsedInValue = !!video.usedInBiteIds?.some((id) => id === state.biteId);
    let usedInBiteIds = video.usedInBiteIds || [];
    if (!hasUsedInValue) {
      usedInBiteIds = [...usedInBiteIds, state.biteId];

      // update slice
      yield put(setUsedInBiteIds(usedInBiteIds));

      // save video on the server
      yield put(
        saveVideo({
          delay: 0,
        }),
      );
    }

    const { s3UploadDetails } = yield prepareS3SinglePartUploadDetails({
      file: new File([new Blob()], 'video.mp4', { type: 'video/mp4' }),
      processId,
      orgId: org.id,
      endpoint: '/s3_upload_url/',
    });

    // update the store
    state.taskId = s3UploadDetails.task_id;
    state.trackingOptions.task_id = state.taskId;
    yield put(setIntroTask(state.taskId));

    const bucket = new URL(s3UploadDetails.url).hostname.split('.')[0];
    const url = `https://${bucket}.s3.amazonaws.com/${s3UploadDetails.fields.key}`;

    const {
      data: { exportedVideo: originalExportedVideo },
    } = yield createExportedVideoService({
      exportedVideo: {
        config: {
          ...video.config,
          itemsMap,
        },
        videoId: video.id,
        storage: {
          type: 's3',
          url,
          bucket,
          key: s3UploadDetails.fields.key,
          taskId: s3UploadDetails.task_id,
          s3UploadDetails,
        },
        assetIds,
        usedInBiteIds: [state.biteId],
        orgId: org.id,
        creatorId: user.id,
        exportStatus: 'PENDING',
      },
    });

    let exportedVideo: IExportedVideo | null = null;
    while (true) {
      yield delay(1000);
      try {
        const { data } = yield getExportedVideoService(originalExportedVideo.id);

        if (data.exportedVideo?.exportStatus === 'DONE') {
          exportedVideo = data.exportedVideo;
          break;
        }
      } catch (error: any) {
        console.log({
          event: 'webVideoExportSaga: getExportedVideoService error',
          processId,
          data: {
            errorMessage: error?.message,
            errorStack: error?.stack,
          },
        });
      }
    }

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'webVideoExportSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    if (exportedVideo.thumbnail) {
      yield fork(processExportedVideoThumbnail, { exportedVideo, state });
    }

    // TODO:
    // yield put(setIntroVideoMediaUri(state.videoUri));

    // update on server
    yield spawn(setBiteIntroSection, { state });

    yield spawn(setBiteAvaitingEnhancementsSaga, { state });

    state.trackingMeta.status.s3 = EIntroMediaProcessingStatus.SUCCESS;

    state.trackingMeta.startTs.processing_subtitles = Date.now();
    state.trackingMeta.status.subtitles = EIntroMediaProcessingStatus.PROCESSING;

    state.trackingMeta.startTs.processing_enhancements = Date.now();
    state.trackingMeta.status.original = EIntroMediaProcessingStatus.PROCESSING;
    state.trackingMeta.status.enhancements = EIntroMediaProcessingStatus.PROCESSING;

    yield put(
      log({
        event: 'webVideoExportSaga: setIntroMediaProcessingStatus',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );

    yield put(
      log({
        event: 'webVideoExportSaga: starting subtitles polling',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // spawn not to block the parent saga from finishing
    yield spawn(trackIntroMediaProcessingSubtitles, {
      initialTaskId: state.taskId,
      initialCounter: state.initialCounter,
      trackingOptions: state.trackingOptions,
      trackingMeta: state.trackingMeta,
      processId: state.processId,
      videoMeta: state.videoMeta,
    });

    state.startedSubtitlesPolling = true;

    yield put(setCurrentFlow({ videoUploadedToS3: true }));

    yield put(
      log({
        event: 'webVideoExportSaga: starting enhancements polling',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // spawn not to block the parent saga from finishing
    yield spawn(trackIntroMediaProcessingEnhancements, {
      initialTaskId: state.taskId,
      initialCounter: state.initialCounter,
      trackingOptions: state.trackingOptions,
      trackingMeta: state.trackingMeta,
      processId: state.processId,
      videoMeta: state.videoMeta,
    });
  } catch (error) {
    yield put(
      logError({
        event: 'webVideoExportSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );
  }
}

function* processExportedVideoThumbnail({ exportedVideo, state }: { exportedVideo: IExportedVideo; state: IState }) {
  try {
    yield put(
      log({
        event: 'processExportedVideoThumbnail: start',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    const { data: thumbnail } = yield axios.get(exportedVideo.thumbnail?.url, {
      responseType: 'blob',
    });

    yield put(
      log({
        event: 'processExportedVideoThumbnail: download thumbnail done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    const thumbnailFile = new File([thumbnail], 'thumbnail.jpg', { type: 'image/png' });

    const { data } = yield withRetry(
      () =>
        uploadImageUtil({
          file: thumbnailFile,
          path: 'upload_cover',
        }),
      {
        errorContext: {
          processId: state.processId,
          data: {
            ...cloneDeep(state),
            aciton: 'webVideoExportSaga: uploadImageUtil',
          },
        },
      },
    );

    yield put(
      log({
        event: 'processExportedVideoThumbnail: thumbnail uploading done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    if (!state.biteId) {
      yield put(
        log({
          event: 'processExportedVideoThumbnail: processThumbnail waiting for bite',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      yield take(Types.CREATE_BITE_INTRO_VIDEO_BITE_PREPARED);
    }

    yield put(
      log({
        event: 'processExportedVideoThumbnail: processThumbnail have bite',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // update on server
    yield put(
      updateBiteData({
        biteId: state.biteId,
        body: {
          cover: data.id,
          linked_cover_url: data.image,
        },
      }),
    );

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'processExportedVideoThumbnail: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    yield put(
      log({
        event: 'processExportedVideoThumbnail: processThumbnail update bite done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // bite.reducer - selected bite
    yield put(setSelectedBiteCover(data.image));

    const { selectedBite } = yield select(biteSelector);

    // bite.reducer - bite in all bites map
    yield put(
      setBites([
        {
          ...selectedBite,
          cover_url: data.image,
          linked_cover_url: data.image,
        },
      ]),
    );

    // createBite.reducer
    yield put(setBiteCover(null, data.image));

    yield put(
      log({
        event: 'processExportedVideoThumbnail: done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );
  } catch (error: any) {
    yield put(
      logError({
        event: 'processExportedVideoThumbnail: error',
        processId: state.processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );
  }
}

function* setBiteIntroSection({ state }: { state: IState }) {
  try {
    yield put(
      log({
        event: 'set_intro_section_start',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    if (!state.biteId) {
      yield put(
        log({
          event: 'set_intro_section_wait_for_bite',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      yield take(Types.CREATE_BITE_INTRO_VIDEO_BITE_PREPARED);
    }

    yield put(
      log({
        event: 'set_intro_section_request',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    const { data: introSection } = yield withRetry(() => editBiteIntro(state.biteId, { task_id: state.taskId }), {
      errorContext: {
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          aciton: 'editBiteIntro',
        },
      },
    });

    yield put(indexBites({ biteIds: [state.biteId] }));

    yield put(updateBiteSection({ biteId: state.biteId, section: introSection }));

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      return;
    }

    const { selectedBite } = yield select(biteSelector);
    yield put(
      updateSelectedBite({
        bite_sections: [...selectedBite.bite_sections.filter((item) => item.type !== 'intro'), introSection],
        no_sections: false,
      }),
    );

    yield put(
      trackEvent({
        event: 'save_story_section',
        props: { bite_id: state.biteId },
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'set_intro_section_failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    yield put(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));

    // stop the flow
    yield put(editBiteIntroVideo({ isCancel: true }));
  }
}

function* setBiteAvaitingEnhancementsSaga({ state }: { state: IState }) {
  yield put(
    log({
      event: 'set_bite_avaiting_enhancements',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );

  if (!state.biteId) {
    yield put(
      log({
        event: 'set_bite_avaiting_enhancements_waiting_for_bite',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    yield take(Types.CREATE_BITE_INTRO_VIDEO_BITE_PREPARED);
  }

  yield put(
    log({
      event: 'setBiteAvaitingEnhancementsSaga have the bite',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );

  yield put(
    setBiteAvaitingEnhancements({
      biteId: state.biteId,
      isAvaitingEnhancements: true,
    }),
  );

  yield put(
    log({
      event: 'set_bite_avaiting_enhancements_done',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );
}

function* getIsSameProcess({ state }: { state: IState }) {
  const { selectedBite } = yield select(biteSelector);
  const introMediaProcessingStatus: IIntroMediaProcessingStatus = yield select(introMediaProcessingStatusSelector);

  if (
    selectedBite?.id !== state.biteId || // new bite in progress
    introMediaProcessingStatus.counter !== state.initialCounter // new media in progress
  ) {
    return false;
  }

  return true;
}

function* uploadAudioSaga({ state }: { state: IState }) {
  try {
    yield put(
      log({
        event: 'uploadAudioSaga',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    state.trackingMeta.startTs.s3_audio = Date.now();

    yield uploadFileSaga({
      state,
      fileUri: state.audioUri,
      file: null,
      getS3UploadDetailsEndpoint: '/s3_upload_url/audio/',
      // getS3MultiPartUploadDetailsEndpoint: '/presign_multi_part_upload_urls',
      // postS3MultiPartUploadCompleteEndpoint: '/complete_multi_part_upload',
      mediaType: EFileType.AUDIO,
      withS3StatusTracking: false,
      withStatusUpdateOnSubtitlesFailure: false,
      withEnhancenentsPolling: false,
    });

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'uploadAudioSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    state.trackingOptions.s3_audio_ts = Date.now() - state.trackingMeta.startTs.s3_audio;

    // for other async logic within the flow
    state.uploadedAudio = true;
    yield put(createBiteIntroVideoAudioUploaded());

    yield put(
      log({
        event: 'uploadAudioSaga: done',
        processId: state.processId,
        data: cloneDeep(state),
        metrics: {
          uploadAudioMs: state.trackingOptions.s3_audio_ts,
          uploadAudioMsPerMb: state.videoMeta?.size
            ? state.trackingOptions.s3_audio_ts / state.videoMeta?.size
            : undefined,
          uploadAudioMsPerSec: state.videoMeta?.duration
            ? state.trackingOptions.s3_audio_ts / state.videoMeta?.duration
            : undefined,
        },
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'uploadAudioSaga: failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    // let the video proceed
    state.taskId = null;
    state.audioUri = null;
    state.uploadedAudio = true;
    yield put(createBiteIntroVideoAudioUploaded());
  }
}

function* uploadVideoSaga({ state, file }: { state: IState; file: FileWithPath }) {
  try {
    yield put(
      log({
        event: 'uploadVideoSaga',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          file: !!file,
        },
      }),
    );

    if (state.audioUri && !state.uploadedAudio) {
      yield put(
        log({
          event: 'uploadVideoSaga: waiting audio to upload',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      yield take(Types.CREATE_BITE_INTRO_VIDEO_AUDIO_UPLOADED);
    }

    state.trackingMeta.startTs.s3 = Date.now();

    const endpointQuery = state.taskId ? `?task_id=${state.taskId}` : '';

    yield uploadFileSaga({
      state,
      fileUri: state.videoUri,
      file,
      getS3UploadDetailsEndpoint: `/s3_upload_url/${endpointQuery}`,
      getS3MultiPartUploadDetailsEndpoint: `/presign_multi_part_upload_urls/${endpointQuery}`,
      postS3MultiPartUploadCompleteEndpoint: '/complete_multi_part_upload/',
      mediaType: EFileType.VIDEO,
      withS3StatusTracking: true,
      withStatusUpdateOnSubtitlesFailure: true,
      withEnhancenentsPolling: true,
    });

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'uploadVideoSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    state.trackingOptions.s3_ts = Date.now() - state.trackingMeta.startTs.s3;

    yield put(setCurrentFlow({ videoUploadedToS3: true }));

    yield put(
      log({
        event: 'uploadVideoSaga: done',
        processId: state.processId,
        data: cloneDeep(state),
        metrics: {
          uploadVideoMs: state.trackingOptions.s3_ts,
          uploadVideoMsPerMb: state.videoMeta?.size ? state.trackingOptions.s3_ts / state.videoMeta?.size : undefined,
          uploadVideoMsPerSec: state.videoMeta?.duration
            ? state.trackingOptions.s3_ts / state.videoMeta?.duration
            : undefined,
        },
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'uploadVideoSaga: failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'uploadVideoSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    yield put(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));

    yield put(
      setIntroMediaProcessingStatus({
        all: EIntroMediaProcessingStatus.ERROR,
        s3: EIntroMediaProcessingStatus.ERROR,
      }),
    );

    // stop the flow
    yield put(editBiteIntroVideo({ isCancel: true }));
  }
}

// do not try-catch here
// handle errors on the parent business logic sagas
function* uploadFileSaga({
  state,
  fileUri,
  file,
  getS3UploadDetailsEndpoint,
  mediaType,
  withS3StatusTracking,
  withStatusUpdateOnSubtitlesFailure,
  withEnhancenentsPolling,
  getS3MultiPartUploadDetailsEndpoint = null,
  postS3MultiPartUploadCompleteEndpoint = null,
}: {
  state: IState;
  fileUri: string;
  file: FileWithPath;
  getS3UploadDetailsEndpoint: string;
  getS3MultiPartUploadDetailsEndpoint?: string;
  postS3MultiPartUploadCompleteEndpoint?: string;
  mediaType: EFileType;
  withS3StatusTracking: boolean;
  withStatusUpdateOnSubtitlesFailure: boolean;
  withEnhancenentsPolling: boolean;
}) {
  const org = yield select(activeOrganizationSelector);

  yield put(
    log({
      event: 'uploadFileSaga: start',
      processId: state.processId,
      data: {
        ...cloneDeep(state),
        fileUri,
        file: !!file,
        getS3UploadDetailsEndpoint,
        mediaType,
        withS3StatusTracking,
        withEnhancenentsPolling,
        org,
      },
    }),
  );

  const { taskId } = yield uploadToS3({
    uri: fileUri,
    file,
    processId: state.processId,
    orgId: org.id,
    endpoint: getS3UploadDetailsEndpoint,
    multiPartEndpoints: {
      preSignEndpoint: getS3MultiPartUploadDetailsEndpoint,
      completeEndpoint: postS3MultiPartUploadCompleteEndpoint,
    },
    mediaType,
  });

  const isSameProcess = yield getIsSameProcess({ state });
  if (!isSameProcess) {
    yield put(
      log({
        event: 'uploadFileSaga: is new process',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );
    return;
  }

  if (!state.taskId) {
    yield put(
      log({
        event: 'uploadFileSaga: no task id. Setting now',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          taskId: state.taskId,
        },
      }),
    );

    // update the store
    state.taskId = taskId;
    state.trackingOptions.task_id = state.taskId;
    yield put(setIntroTask(state.taskId));

    // update on server
    yield spawn(setBiteIntroSection, { state });
  }

  yield put(
    log({
      event: 'uploadFileSaga: uploadToS3 done',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );

  yield spawn(setBiteAvaitingEnhancementsSaga, { state });

  if (withS3StatusTracking) {
    state.trackingMeta.status.s3 = EIntroMediaProcessingStatus.SUCCESS;
  }

  if (!state.startedSubtitlesPolling) {
    state.trackingMeta.startTs.processing_subtitles = Date.now();
    state.trackingMeta.status.subtitles = EIntroMediaProcessingStatus.PROCESSING;
  }

  if (
    state.trackingMeta.status.subtitles === EIntroMediaProcessingStatus.ERROR ||
    state.trackingMeta.status.subtitles === EIntroMediaProcessingStatus.NOT_APPLICABLE
  ) {
    yield put(
      log({
        event: `uploadFileSaga: subtitles started polling, but status is ${state.trackingMeta.status.subtitles}. Resetting the state...`,
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    state.startedSubtitlesPolling = false;
    state.trackingMeta.status.subtitles = EIntroMediaProcessingStatus.PROCESSING;
    state.trackingMeta.startTs.processing_subtitles = Date.now();
    state.trackingOptions.subtitles_processing_ts = null;
    state.trackingOptions.total_ts = null;
  }

  if (withEnhancenentsPolling) {
    state.trackingMeta.startTs.processing_enhancements = Date.now();
    state.trackingMeta.status.original = EIntroMediaProcessingStatus.PROCESSING;
    state.trackingMeta.status.enhancements = EIntroMediaProcessingStatus.PROCESSING;
  }

  if (withS3StatusTracking || !state.startedSubtitlesPolling) {
    yield put(
      log({
        event: 'uploadFileSaga: setIntroMediaProcessingStatus',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );
  }

  // update even if the polling is in progress
  // in audio flow it is false because we want to keep consistent state of PROCESSING
  // even in case of failure, because video flow will do the retry
  state.trackingMeta.withStatusUpdateOnSubtitlesFailure = withStatusUpdateOnSubtitlesFailure;

  if (!state.startedSubtitlesPolling) {
    yield put(
      log({
        event: 'uploadFileSaga: starting subtitles polling',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // spawn not to block the parent saga from finishing
    yield spawn(trackIntroMediaProcessingSubtitles, {
      initialTaskId: state.taskId,
      initialCounter: state.initialCounter,
      trackingOptions: state.trackingOptions,
      trackingMeta: state.trackingMeta,
      processId: state.processId,
      videoMeta: state.videoMeta,
    });

    state.startedSubtitlesPolling = true;
  } else {
    yield put(
      log({
        event: 'uploadFileSaga: extending the polling of subtitles',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // extend the polling of subtitles
    // if they started polling from the audio flow
    state.trackingMeta.subtitlesProcessingTimeout = Date.now() + SUBTITLES_PROCESSING_TIMEOUT;
  }

  if (withEnhancenentsPolling) {
    yield put(
      log({
        event: 'uploadFileSaga: starting enhancements polling',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // spawn not to block the parent saga from finishing
    yield spawn(trackIntroMediaProcessingEnhancements, {
      initialTaskId: state.taskId,
      initialCounter: state.initialCounter,
      trackingOptions: state.trackingOptions,
      trackingMeta: state.trackingMeta,
      processId: state.processId,
      videoMeta: state.videoMeta,
    });
  }

  yield put(
    log({
      event: 'uploadFileSaga: done',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );
}

function* prepareBiteSaga({ state }: { state: IState }) {
  try {
    yield put(
      log({
        event: 'prepare_bite',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    const newBite = yield createBiteSaga({}, { processId: state.processId });

    yield put(
      log({
        event: 'prepare_bite_request_done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    yield put(setCurrentFlow({ biteId: newBite.id }));
    yield put(addToDrafts(newBite.id));

    const formattedData = {
      ...formatBiteFromServer(newBite),
      selectedBite: newBite,
    };
    // keep the local video for AI Enhancements screen
    delete formattedData.createBiteState.introMedia;
    yield put(setBiteToEdit(formattedData));
    yield put(setBites([newBite]));

    yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
    yield put(setHasBites(true));

    // for other async logic within the flow
    state.biteId = newBite.id;
    state.trackingOptions.bite_id = newBite.id;
    state.trackingMeta.biteId = newBite.id;
    yield put(createBiteIntroVideoBitePrepared());

    yield put(
      setIntroMediaProcessingStatus({
        biteId: state.biteId,
      }),
    );

    yield put(
      log({
        event: 'prepare_bite_done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'prepare_bite_failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    yield put(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));

    // stop the flow
    yield put(editBiteIntroVideo({ isCancel: true }));
  }
}

function* exportAudioSaga({ state }: { state: IState }) {
  try {
    yield put(
      log({
        event: 'exportAudioSaga',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    state.trackingMeta.startTs.audio_exporting = Date.now();

    state.trackingMeta.status.audioExport = EIntroMediaProcessingStatus.PROCESSING;
    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );

    const exportAudioResult = yield exportAudio();

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'exportAudioSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    state.trackingOptions.audio_exporting_ts = Date.now() - state.trackingMeta.startTs.audio_exporting;
    state.audioUri = exportAudioResult.audioUri;

    state.trackingMeta.status.audioExport = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );

    yield put(
      log({
        event: 'exportAudioSaga: done',
        processId: state.processId,
        data: cloneDeep(state),
        metrics: {
          exportAudioMs: state.trackingOptions.audio_exporting_ts,
          // exporting only on mobile where we have only duration
          exportAudioMsPerSec: state.videoMeta?.duration
            ? state.trackingOptions.audio_exporting_ts / state.videoMeta?.duration
            : undefined,
        },
      }),
    );
  } catch (error) {
    state.trackingMeta.status.audioExport = EIntroMediaProcessingStatus.ERROR;
    const isSameProcess = yield getIsSameProcess({ state });
    if (isSameProcess) {
      yield put(
        setIntroMediaProcessingStatus({
          ...state.trackingMeta.status,
        }),
      );
    }

    // silently log error, but do not cancel the flow
    // not to break the whole process
    // we still have video to rely on
    yield put(
      logError({
        event: 'exportAudioSaga: error',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );
  }
}

function* exportVideoSaga({ state }: { state: IState }) {
  try {
    yield put(
      log({
        event: 'exportVideoSaga',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    state.trackingMeta.startTs.video_exporting = Date.now();

    state.trackingMeta.status.videoExport = EIntroMediaProcessingStatus.PROCESSING;
    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );

    const exportVideoResult = yield exportVideo();

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'exportVideoSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    state.trackingOptions.video_exporting_ts = Date.now() - state.trackingMeta.startTs.video_exporting;
    state.videoUri = exportVideoResult.videoUri;

    state.trackingMeta.status.videoExport = EIntroMediaProcessingStatus.SUCCESS;
    yield put(
      setIntroMediaProcessingStatus({
        ...state.trackingMeta.status,
      }),
    );

    yield put(
      log({
        event: 'exportVideoSaga: done',
        processId: state.processId,
        data: cloneDeep(state),
        metrics: {
          exportVideoMs: state.trackingOptions.video_exporting_ts,
          // exporting only on mobile where we have only duration
          exportVideoMsPerSec: state.videoMeta?.duration
            ? state.trackingOptions.video_exporting_ts / state.videoMeta?.duration
            : undefined,
        },
      }),
    );

    yield put(createBiteIntroVideoVideoExported());
  } catch (error) {
    state.trackingMeta.status.videoExport = EIntroMediaProcessingStatus.ERROR;
    const isSameProcess = yield getIsSameProcess({ state });
    if (isSameProcess) {
      yield put(
        setIntroMediaProcessingStatus({
          ...state.trackingMeta.status,
        }),
      );
    }

    yield put(
      logError({
        event: 'exportVideoSaga: error',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    yield put(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));

    // stop the flow
    yield put(editBiteIntroVideo({ isCancel: true }));
  }
}

function* processThumbnailSaga({ state, thumbnail }: { state: IState; thumbnail: string | File }) {
  try {
    yield put(
      log({
        event: 'process_thumbnail',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          thumbnailExists: !!thumbnail,
        },
      }),
    );

    if (!state.videoUri && !thumbnail) {
      yield put(
        log({
          event: 'process_thumbnail_waiting_for_video_export',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      yield take(Types.CREATE_BITE_INTRO_VIDEO_VIDEO_EXPORTED);
    }

    yield put(
      log({
        event: 'processThumbnailSaga: video_exported_or_thumbnail_is_provided',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // is web case thumbnail is provided in the prop
    // mobile only
    if (!thumbnail) {
      const { path } = yield withRetry(() => createThumbnail({ url: `file://${state.videoUri}` }), {
        errorContext: {
          processId: state.processId,
          data: {
            ...cloneDeep(state),
            aciton: 'processThumbnailSaga.createThumbnail',
          },
        },
      });
      thumbnail = path;
    }

    yield put(
      log({
        event: 'processThumbnailSaga: created thumbnail',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    let asset = null;
    // mobile case
    if (typeof thumbnail === 'string') {
      yield put(
        log({
          event: 'processThumbnailSaga: uploading string',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      const { data } = yield withRetry(
        () =>
          uploadImageUtil({
            uri: thumbnail as string,
            type: 'image/jpeg',
            path: 'upload_cover',
          }),
        {
          errorContext: {
            processId: state.processId,
            data: {
              ...cloneDeep(state),
              aciton: 'processThumbnailSaga.uploadImageUtil (string)',
            },
          },
        },
      );

      asset = data;
    } else {
      // web case

      yield put(
        log({
          event: 'processThumbnailSaga: uploading file',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      const { data } = yield withRetry(
        () =>
          uploadImageUtil({
            file: thumbnail,
            path: 'upload_cover',
          }),
        {
          errorContext: {
            processId: state.processId,
            data: {
              ...cloneDeep(state),
              aciton: 'processThumbnailSaga.uploadImageUtil (file)',
            },
          },
        },
      );

      asset = data;
    }

    yield put(
      log({
        event: 'processThumbnailSaga: uploading done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    if (!state.biteId) {
      yield put(
        log({
          event: 'processThumbnailSaga: waiting for bite',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );

      yield take(Types.CREATE_BITE_INTRO_VIDEO_BITE_PREPARED);
    }

    yield put(
      log({
        event: 'processThumbnailSaga: have bite',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    // update on server
    yield put(
      updateBiteData({
        biteId: state.biteId,
        body: {
          cover: asset.id,
          linked_cover_url: asset.image,
        },
      }),
    );

    yield put(
      log({
        event: 'processThumbnailSaga: update bite done',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );

    const isSameProcess = yield getIsSameProcess({ state });
    if (!isSameProcess) {
      yield put(
        log({
          event: 'processThumbnailSaga: is new process',
          processId: state.processId,
          data: cloneDeep(state),
        }),
      );
      return;
    }

    // bite.reducer - selected bite
    yield put(setSelectedBiteCover(asset.image));

    const { selectedBite } = yield select(biteSelector);

    // bite.reducer - bite in all bites map
    yield put(
      setBites([
        {
          ...selectedBite,
          cover_url: asset.image,
          linked_cover_url: asset.image,
        },
      ]),
    );

    // createBite.reducer
    yield put(setBiteCover(null, asset.image));

    yield put(
      log({
        event: 'process_thumbnail_updated_store',
        processId: state.processId,
        data: cloneDeep(state),
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'process_thumbnail_failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );

    // show error, but do not cancel the whole process
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

interface IState {
  processId: string;
  biteId: IBiteItem['id'];
  audioUri: string;
  videoUri: string;
  videoMeta: IVideoMeta;
  uploadedAudio: boolean;
  startedSubtitlesPolling: boolean;
  trackingOptions: ITrackingOptions;
  trackingMeta: ITrackingMeta;
  initialCounter: number;
  taskId: string;
}

function* prepareState({
  audioUri,
  videoUri,
  videoMeta,
}: {
  audioUri?: string;
  videoUri?: string;
  videoMeta?: IVideoMeta;
}) {
  const user = yield select(userSelector);
  const { selectedBite } = yield select(biteSelector);

  const trackingOptions: ITrackingOptions = {
    bites_user_id: user.id,
    bite_id: selectedBite?.id,
    task_id: null,
    feature: null,
    status: null,

    // export
    audio_exporting_ts: null,
    video_exporting_ts: null,

    // s3
    s3_audio_ts: null,
    s3_ts: null,

    // original
    original_processing_ts: null,
    enhancements_processing_ts: null,
    subtitles_processing_ts: null,

    // total
    total_ts: null,
  };
  const trackingMeta: ITrackingMeta = {
    biteId: selectedBite?.id,
    startTs: {
      start: Date.now(),
      audio_exporting: null,
      video_exporting: null,
      s3_audio: null,
      s3: null,
      processing_subtitles: null,
      processing_enhancements: null,
    },
    status: {
      all: EIntroMediaProcessingStatus.PROCESSING,
      audioExport: EIntroMediaProcessingStatus.INACTIVE,
      videoExport: EIntroMediaProcessingStatus.INACTIVE,
      s3: EIntroMediaProcessingStatus.PROCESSING,
      original: EIntroMediaProcessingStatus.INACTIVE,
      enhancements: EIntroMediaProcessingStatus.INACTIVE,
      subtitles: EIntroMediaProcessingStatus.INACTIVE,
      summarySuggestion: EIntroMediaProcessingStatus.INACTIVE,
      questionSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      biteNameSuggestion: EIntroMediaProcessingStatus.INACTIVE,
      coverSuggestion: EIntroMediaProcessingStatus.INACTIVE,
    },
    withStatusUpdateOnSubtitlesFailure: true,
    withMetricsTracking: true,
    withSuggestions: true,
  };

  // state is used for async checks across sagas
  const state: IState = {
    processId: shortid.generate(),
    biteId: selectedBite?.id,
    audioUri,
    videoUri,
    videoMeta: videoMeta || {},
    uploadedAudio: false,
    startedSubtitlesPolling: false,
    trackingOptions,
    trackingMeta,
    initialCounter: null,
    taskId: null,
  };

  return state;
}

function* waitAndSetVideoDuration({ state }: { state: IState }) {
  yield put(
    log({
      event: 'waitAndSetVideoDuration',
      processId: state.processId,
      data: cloneDeep(state),
    }),
  );

  const existingIntroMediaDuration = yield select(introMediaDurationSelector);
  if (existingIntroMediaDuration) {
    state.videoMeta.duration = existingIntroMediaDuration;

    yield put(
      log({
        event: 'waitAndSetVideoDuration: setting existing video duration',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          existingIntroMediaDuration,
        },
      }),
    );

    return;
  }

  const startTs = Date.now();

  yield take(Types.SET_BITE_INTRO_DURATION);
  const introMediaDuration = yield select(introMediaDurationSelector);
  state.videoMeta.duration = introMediaDuration;

  const metrics: any = {
    videoDuration: state.videoMeta.duration,
    uploadAudioMsPerSec: state.trackingOptions.s3_audio_ts
      ? state.trackingOptions.s3_audio_ts / state.videoMeta.duration
      : undefined,
    uploadVideoMsPerSec: state.trackingOptions.s3_ts
      ? state.trackingOptions.s3_ts / state.videoMeta.duration
      : undefined,
    exportAudioMsPerSec: state.trackingOptions.audio_exporting_ts
      ? state.trackingOptions.audio_exporting_ts / state.videoMeta.duration
      : undefined,
    exportVideoMsPerSec: state.trackingOptions.video_exporting_ts
      ? state.trackingOptions.video_exporting_ts / state.videoMeta.duration
      : undefined,
  };

  yield put(
    log({
      event: 'waitAndSetVideoDuration: set video duration',
      processId: state.processId,
      data: {
        ...cloneDeep(state),
        introMediaDuration,
        waitTs: Date.now() - startTs,
      },
      metrics,
    }),
  );
}

// file is used on web scenario
// videoUri is used in mobile scenario
// audioUri is used in mobile scenario when exportType === EExportType.ASYNCHRONOUS

// exportAudio, exportVideo are available in ios scenario
// when exportType === EExportType.ASYNCHRONOUS only for now

// using fork in the main saga to stop child processes if a new action is dispatched
// inside child sagas - using spawn for smaller tasks, not to block each other

function* editBiteIntroVideoSaga({
  payload: { file, audioUri, videoUri, videoMeta, thumbnail, withThumbnailUpdate, isCancel },
}: PayloadAction<IEditBiteIntroVideoAction>) {
  // cancel the flow from a child saga
  // to explore later - maybe there is a better way
  // throw does not fit, because it canceles all app sagas
  if (isCancel) {
    yield put(
      log({
        event: 'editBiteIntroVideoSaga: Cancelling...',
        data: { isCancel },
      }),
    );
    return;
  }

  const config = yield select(configSelector);
  const isAiWithAudioEnabled = yield getIsFeatureEnabledForCurrentState('aiWithAudio');
  if (!isAiWithAudioEnabled) {
    audioUri = null;
  }

  const state: IState = yield prepareState({ audioUri, videoUri, videoMeta });

  try {
    yield put(
      log({
        event: 'editBiteIntroVideoSaga',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          file: !!file,
          thumbnail: !!thumbnail,
          withThumbnailUpdate,
        },
        metrics: {
          videoDuration: state.videoMeta?.duration || undefined,
          videoFileSize: state.videoMeta?.size || undefined,
        },
      }),
    );

    yield put(setBiteIntro(null));
    yield put(setIntroMediaProcessingStatus({ ...state.trackingMeta.status }));
    yield put(setBiteIntroEdited(true));

    const { counter: initialCounter }: IIntroMediaProcessingStatus = yield select(introMediaProcessingStatusSelector);
    state.initialCounter = initialCounter;

    if (!state.videoMeta?.duration) {
      yield fork(waitAndSetVideoDuration, { state });
    }

    if (withThumbnailUpdate) {
      yield fork(processThumbnailSaga, { state, thumbnail });
    }

    if (!state.biteId) {
      yield fork(prepareBiteSaga, { state });
    }

    yield put(
      log({
        event: 'editBiteIntroVideoSaga: exportAudioSaga?',
        processId: state.processId,
        data: {
          isWeb,
          aiWithAudioFeatureFlag: config?.featureFlags?.aiWithAudio,
          isAiWithAudioEnabled,
          ...cloneDeep(state),
        },
      }),
    );
    // according to business logic
    // if there is video exported,
    // audio is either already exported - EExportType === ASYNCHRONOUS
    // or not needed at all EExportType === VIDEO_SYNCHRONOUS
    if (!isWeb && !state.videoUri && !state.audioUri && isAiWithAudioEnabled) {
      yield exportAudioSaga({ state });
    }

    yield put(
      log({
        event: 'editBiteIntroVideoSaga: uploadAudioSaga?',
        processId: state.processId,
        data: {
          isWeb,
          aiWithAudioFeatureFlag: config?.featureFlags?.aiWithAudio,
          isAiWithAudioEnabled,
          ...cloneDeep(state),
        },
      }),
    );
    if (!isWeb && state.audioUri && isAiWithAudioEnabled) {
      yield fork(uploadAudioSaga, { state });
    }

    yield put(
      log({
        event: 'editBiteIntroVideoSaga: exportVideoSaga?',
        processId: state.processId,
        data: {
          isWeb,
          ...cloneDeep(state),
        },
      }),
    );
    if (!isWeb && !state.videoUri) {
      yield exportVideoSaga({ state });
    }

    yield put(setIntroVideoMediaUri(state.videoUri));

    yield fork(uploadVideoSaga, { state, file });
  } catch (error) {
    // because of fork the main saga will be cancelled upon errors
    // though we can't catch errors from forked tasks

    yield put(
      logError({
        event: 'editBiteIntroVideoSaga: failed',
        processId: state.processId,
        data: {
          ...cloneDeep(state),
          error,
        },
      }),
    );
  }
}

export default function* introVideoSagaWatcher() {
  // takeLatest forks a saga
  // meaning it is cancels if child throws an error
  yield all([
    takeLatest(Types.EDIT_BITE_INTRO_VIDEO, editBiteIntroVideoSaga),
    takeLatest(webVideoExport, webVideoExportSaga),
  ]);
}
