import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectedTimelineItemIdSelector, timelineItemSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { updateTimelineItem } from '../../../store/videoEditor/videoEditor.slice';
import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import defaultTheme from '../../../themes/defaultTheme';
import EnabledIcon from '../../../assets/icons/videoEditor/enabled.svg';
import DisabledIcon from '../../../assets/icons/videoEditor/disabled.svg';

interface IProps {
  text: string;
  withEnabledControl?: boolean;
}
const Title = ({ text, withEnabledControl = true }: IProps) => {
  const dispatch = useDispatch();

  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem: ITimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));

  const isEnabled = withEnabledControl && (timelineItem.enabled ?? true);

  const handleToggleEnabled = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          enabled: !isEnabled,
        }),
      );
    },
    [dispatch, isEnabled, selectedTimelineItemId],
  );

  return (
    <>
      <S.Container>
        <S.Title isEnabled={withEnabledControl ? isEnabled : true}>{text}</S.Title>
        {withEnabledControl && (
          <S.EnabledControl onClick={handleToggleEnabled}>
            {isEnabled ? <EnabledIcon /> : <DisabledIcon />}
          </S.EnabledControl>
        )}
      </S.Container>
    </>
  );
};
export default Title;

const S = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 16px;
    height: 48px;
    border-bottom: 1px solid ${defaultTheme.colors.lightGray45};
  `,
  Title: styled.div<{ isEnabled: boolean }>`
    font-size: 15px;
    font-weight: bold;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
    transition: opacity 0.2s;
  `,
  EnabledControl: styled.div`
    cursor: pointer;
    transform: translateY(2px);
  `,
};
