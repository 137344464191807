import React from 'react';
import styled, { css } from 'styled-components/native';
import { I18nManager } from 'react-native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { ITheme } from '../../../../themes/defaultTheme';
import { nameYourQuizDataSet } from '../../../../screens/createQuiz/NameYourQuiz/NameYourQuiz.constants';

const TextInputBlueFrame = (props) => {
  const { ...restProps } = props;

  return (
    <S.InputWrapper>
      <S.Input {...restProps} dataSet={nameYourQuizDataSet} />
    </S.InputWrapper>
  );
};

const InputWrapper = styled.View(
  ({ theme }: ITheme) => `
  flex-direction: row;
  align-items: center;
  background-color: ${theme.colors.white};
  height: ${calcHeight(70)}px;
  width: ${calcWidth(315)}px;
  border-radius: 15px;
  border: 1px solid ${theme.colors.primaryBlue};
  padding-right: ${calcWidth(20)}px;
  padding-left: ${calcWidth(20)}px;
`,
);

const Input = styled.TextInput(
  ({ theme }: ITheme) => css`
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.darkPrimary};
    flex: 1;
    text-align: ${I18nManager.isRTL && !isWeb ? 'right' : 'left'};
    border-color: ${theme.colors.primaryBlue};
  `,
);

const S = { Input, InputWrapper };

export default TextInputBlueFrame;
