import { takeLatest, put, all, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-community/async-storage';

import { setDrafts, loadValues, setValue, addToDrafts, removeFromDrafts, trackDraftEvents } from './drafts.slice';
import { TBiteId, TDraftItem } from './drafts.types';
import { draftsSelector } from './drafts.selectors';
import { logError, trackEvent } from '../appActivity/appActivity.slice';

const DRAFT_STORAGE_KEY = 'draft-bite-ids';
let loadValuesPromise = null;

function* loadValuesSaga() {
  const { drafts } = yield select(draftsSelector);
  if (drafts) {
    return;
  }
  let value: TDraftItem[] = [];
  try {
    loadValuesPromise = loadValuesPromise || AsyncStorage.getItem(DRAFT_STORAGE_KEY);
    const raw = (yield loadValuesPromise) || '[]';
    value = JSON.parse(raw) as TDraftItem[];
  } catch (error) {
    yield put(
      logError({
        event: 'drafts.saga loadValuesSaga: error',
        error,
      }),
    );
  }
  yield put(setDrafts(value));
}

function* setValueSaga({ payload: newValue }: PayloadAction<TDraftItem[]>) {
  yield put(setDrafts(newValue));
  const newValueRaw = JSON.stringify(newValue);
  yield AsyncStorage.setItem(DRAFT_STORAGE_KEY, newValueRaw);
}

function* addToDraftsSaga({ payload: biteId }: PayloadAction<TBiteId>) {
  yield loadValuesSaga();
  const { drafts } = yield select(draftsSelector);
  const newDrafts = drafts.filter(([id]) => id !== biteId);
  newDrafts.push([biteId, 0, 0]);
  yield put(setValue(newDrafts));
}

function* removeFromDraftsSaga({ payload: biteId }: PayloadAction<TBiteId>) {
  yield loadValuesSaga();
  const { drafts } = yield select(draftsSelector);
  const newDrafts = drafts.filter(([id]) => id !== biteId);
  yield put(setValue(newDrafts));
}

function* bulkUpdateSaga(updateBitesMap: { [key: TBiteId]: TDraftItem }) {
  const { drafts } = yield select(draftsSelector);
  const newDrafts = drafts.map((draftItem) => {
    const biteId = draftItem[0];
    return updateBitesMap[biteId] || draftItem;
  });
  yield put(setValue(newDrafts));
}

function* trackDraftEventsSaga({
  payload: { biteIds },
}: PayloadAction<{ biteIds: TBiteId[]; userId: number; orgId: number }>) {
  yield loadValuesSaga();
  const { draftsMap } = yield select(draftsSelector);
  let viewEventWithBiteIds = [];
  const updateBitesMap = {};

  biteIds.forEach((biteId) => {
    const draft = draftsMap[biteId];
    if (!draft) {
      return;
    }

    const draftCopy = [...draft];
    if (Date.now() - new Date(draft[1]).getTime() >= 3 * 24 * 60 * 60 * 1000) {
      viewEventWithBiteIds.push(biteId);
      draftCopy[1] = Date.now();
      updateBitesMap[biteId] = draftCopy;
    }

    if (!draft[2]) {
      put(
        trackEvent({
          event: 'view_home_feed_with_new_draft_bite',
          props: { bite_id: biteId },
        }),
      );
      draftCopy[2] = 1;
      updateBitesMap[biteId] = draftCopy;
    }
  });

  if (viewEventWithBiteIds.length > 0) {
    yield put(
      trackEvent({
        event: 'view_home_feed_with_draft_bites',
        props: { bite_ids: `,${viewEventWithBiteIds.join(',')},` },
      }),
    );
  }

  if (Object.keys(updateBitesMap).length > 0) {
    yield bulkUpdateSaga(updateBitesMap);
  }
}

export default function* appActivitySaga() {
  yield all([takeLatest(loadValues, loadValuesSaga)]);
  yield all([takeLatest(setValue, setValueSaga)]);
  yield all([takeLatest(addToDrafts, addToDraftsSaga)]);
  yield all([takeLatest(removeFromDrafts, removeFromDraftsSaga)]);
  yield all([takeLatest(trackDraftEvents, trackDraftEventsSaga)]);
}
