import React, { memo, ReactElement } from 'react';
import styled from 'styled-components/native';

import PointsIcon from '../../../assets/icons/creationFlow/buttons-rounded-icon.svg';
import Modal from '../../modals/ModalController';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { IS_IOS } from '../../../utils/constants/env';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  renderContent: () => ReactElement;
}

const SettingsTooltip: React.FC<IProps> = ({ isVisible, onClose, renderContent }) => {
  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={onClose}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      backdropOpacity={0.4}
      hideModalContentWhileAnimating
      useNativeDriver
    >
      <S.Container pointerEvents={'box-none'}>
        <S.PointsButton onPress={onClose}>
          <PointsIcon />
        </S.PointsButton>
        <S.Content>
          <S.Triangle />
          {renderContent()}
        </S.Content>
      </S.Container>
    </Modal>
  );
};

const S = {
  Triangle: styled.View`
    position: absolute;
    width: 0;
    height: 0;
    top: ${calcHeight(-10)}px;
    right: ${calcWidth(18)}px;
    background-color: transparent;
    border-style: solid;
    border-left-width: ${calcWidth(9)}px;
    border-right-width: ${calcWidth(9)}px;
    border-bottom-width: ${calcHeight(12)}px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
  `,
  PointsButton: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(41 / 2)}px;
    height: ${calcWidth(41)}px;
    width: ${calcWidth(41)}px;
    right: ${calcWidth(16)}px;
    top: ${calcHeight(isWeb ? -15 : -20)}px;
    z-index: 2;
  `,
  Content: styled.View`
    justify-content: space-between;
    padding: ${calcHeight(24)}px 0 ${calcHeight(24)}px ${calcWidth(15)}px;
    position: relative;
    width: ${calcWidth(355)}px;
    margin: 0 ${calcWidth(10)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  `,
  Container: styled.View`
    align-items: flex-end;
    flex: 1;
    padding-top: ${calcHeight(IS_IOS ? 68 : 30)}px;
    padding-right: ${isWeb ? calcWidth(20) : 0}px;
  `,
};

export default memo(SettingsTooltip);
