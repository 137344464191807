import { IAudioFileMetadata } from '../types/typings';
import { getMimeType } from './getMimeType';

// this is a web only function
// create a native specific when required
export const getAudioMetadata = async ({
  dataUrl,
  blob,
  file,
}: {
  dataUrl?: string;
  blob?: Blob;
  file?: File;
}): Promise<IAudioFileMetadata> => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();

    const mimeType = getMimeType({ blob, file, dataUrl });

    audio.onloadedmetadata = () => {
      resolve({
        name: file?.name || '',
        duration: audio.duration,
        mimeType,
      });
      URL.revokeObjectURL(audio.src); // Clean up
    };
    audio.onerror = reject;

    audio.src = dataUrl ? dataUrl : URL.createObjectURL(blob || file);
  });
};
