import React, { memo, useCallback, useEffect, useState } from 'react';
import AttributesList from '../common/AttributesList/AttributesList';
import { useDispatch, useSelector } from 'react-redux';
import { getAnswersAttributes, resetAnswersAttributes } from '../../../store/attributes/attributes.slice';
import AnswersAggregation from '../common/AnswersAggregation';
import HeaderTabs from '../common/HeaderTabs';
import {
  answersIdsFilterSelector,
  currentListSelector,
  isNeedToScrollUpAnswersSelector,
} from '../../../store/analytics/analytics.selector';
import { setAnswersData } from '../../../store/analytics/analytics.slice';
import useAnsweredAttributesMap from '../hooks/useAnsweredAttributesMap';
import useRedirectFromAttributes from '../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../navigation/types';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import useAnimatedHeaderTabs from '../hooks/useAnimatedHeaderTabs/useAnimatedHeaderTabs';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  guestsAttributeAnswersWithOptionSelector,
  selectedFilterAttributeValueIdsSelector,
  sharedWithFilterSelector,
} from '../../../store/attributes/attributes.selector';
import useHeaderTabsDivider from '../hooks/useHeaderTabsDivider';
import { ANSWERED_ATTRIBUTES_SCREEN, ANSWERED_LIST_SCREEN } from '../constants';
import { ESharedWithFilter } from '../../../store/attributes/attributes.types';
import { attributesListDataSet } from '../analytics.constants';

const AnsweredAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();

  const isNeedToScrollUp = useSelector(isNeedToScrollUpAnswersSelector);
  const selectedAnswers = useSelector(answersIdsFilterSelector);
  const currentList = useSelector(currentListSelector);
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const guestsAttributeAnswersWithOption = useSelector(guestsAttributeAnswersWithOptionSelector);
  const sharedWithFilter = useSelector(sharedWithFilterSelector);

  const { setIsShowHeaderBottomDivider, isShowHeaderBottomDivider } = useHeaderTabsDivider();
  const { attributesMap } = useAnsweredAttributesMap();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: ANSWERED_LIST_SCREEN,
    navigation,
  });

  const handleAnswerSelect = useCallback(() => {
    dispatch(resetAnswersAttributes());
    dispatch(setAnswersData({ results: null, next: null, reset: true }));
  }, [dispatch]);

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(ANSWERED_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  const { handleHeaderLayout, scrollHandler, renderAnimatedHeaderTabs, stickyHeaderStyles } = useAnimatedHeaderTabs({
    onScroll: setIsShowHeaderBottomDivider,
    customHandleOffset: selectedAnswers.length !== 0 ? 10 : undefined,
    isFocusedMode,
  });

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      return (
        <>
          {selectedAnswers.length === 0 && (
            <>
              <AnswersAggregation onSelect={handleAnswerSelect} />
              <HeaderTabs
                onLayout={handleHeaderLayout}
                currentScreen={ANSWERED_ATTRIBUTES_SCREEN}
                attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
                listScreen={ANSWERED_LIST_SCREEN}
              />
            </>
          )}
          {!selectedAttributes.length &&
            !isHideGuestsAttribute &&
            !(sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB)) && (
              <GuestsAttribute
                customData={selectedAnswers?.length ? guestsAttributeAnswersWithOption : null}
                currentTab={EAnalyticsScreenTabs.ANSWERS}
                isRefreshing={isRefreshing}
              />
            )}
        </>
      );
    },
    [
      guestsAttributeAnswersWithOption,
      handleAnswerSelect,
      handleHeaderLayout,
      isHideGuestsAttribute,
      selectedAnswers.length,
      selectedAttributes.length,
      sharedWithFilter,
    ],
  );

  return (
    <>
      {selectedAnswers.length !== 0 && (
        <>
          <AnswersAggregation onSelect={handleAnswerSelect} />
          <HeaderTabs
            currentScreen={ANSWERED_ATTRIBUTES_SCREEN}
            attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
            listScreen={ANSWERED_LIST_SCREEN}
            isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
          />
        </>
      )}

      {selectedAnswers.length === 0 && renderAnimatedHeaderTabs()}
      <AttributesList
        dataSet={attributesListDataSet}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenTabs.ANSWERS}
        scrollEventThrottle={0.0001}
        onScroll={scrollHandler}
        renderListHeaderComponent={renderListHeaderComponent}
        attributes={attributesMap}
        onLoad={getAnswersAttributes}
        stickyHeaderStyles={selectedAnswers.length === 0 ? stickyHeaderStyles : undefined}
      />
    </>
  );
};

export default memo(AnsweredAttributes);
