import React, { useMemo } from 'react';
import RNModal, { ModalProps } from 'react-native-modal';
import { StyleSheet } from 'react-native';

export interface IModalProps extends Partial<ModalProps> {
  isFullScreen?: boolean;
  dataSet?: Record<string, string>;
}

function Modal({ dataSet: dataSetProp, ...props }: IModalProps) {
  const dataSet = useMemo(() => {
    const result = {
      ...(dataSetProp || {}),
      cssforweb: 'modal',
    };
    if (props?.isFullScreen) {
      result['is-fullscreen'] = 1;
    }
    return result;
  }, [dataSetProp, props?.isFullScreen]);

  // @ts-ignore-next-line
  return <RNModal style={S.Modal} dataSet={dataSet} {...props} />;
}

const S = StyleSheet.create({
  Modal: { margin: 0 },
});

export default Modal;
