import React, { FC, useEffect, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components/native';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { useIsFocused } from '@react-navigation/native';
import { activateKeepAwake, deactivateKeepAwake } from '@sayem314/react-native-keep-awake';

import CreateBiteInfo from '../../../screens/creationBite/CreateBiteInfo';
import BiteTellYourStory from '../../../screens/creationBite/BiteTellYourStory/BiteTellYourStory';
import Header from '../../../components/Header';
import SummaryNotes from '../../../screens/creationBite/SummaryNotes';
import CreateSummaryNotes from '../../../screens/creationBite/CreateSummaryNotes';
import BitesMagic from '../../../screens/creationBite/BitesMagic';
import { currentFlowSelector, userOrgPreferencesSelector } from '../../../store/appActivity/appActivity.selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Routes from '../../routes';
import BiteCreated from '../../../screens/creationBite/BiteCreated';
import { isBiteSummaryCreatedSelector } from '../../../store/biteSummary/biteSummary.selectors';
import { IInnerHomeStackNav } from '../HomeDrawer/HomeStack/InnerHomeStack/types';
import { useDispatch } from 'react-redux';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import { setCurrentFlow, trackEvent } from '../../../store/appActivity/appActivity.slice';
import BiteEditQuestion from '../../../screens/creationBite/EditQuestion/BiteEditQuestion';
import AddQuestion from '../../../screens/Shared/EditQuestion/AddQuestion';
import { EAppFlow } from '../../../store/appActivity/appActivity.types';
import useCurrentRoute from '../../../hooks/useCurrentRoute';
import GoogleSlides from '../../../screens/googleSlides/GoogleSlides';
import { isContentCreatorSelector } from '../../../store/auth/auth.selectors';
import AIExpectationFirst from '../../../screens/creationBite/AIExpectation/AIExpectationFirst';
import AIExpectationSecond from '../../../screens/creationBite/AIExpectation/AIExpectationSecond';
import VideoEditor from '../../../screens/videoEditor/VideoEditor';
import { isWeb } from '../../../utils/dimensions';
import ExistingVideos from '../../../screens/videoEditor/ExistingVideos';

const Stack = createStackNavigator();

const defaultScreenOptions = { headerShown: false, animationEnabled: !isWeb };
const existingVideosOptions = {
  headerTitle: 'Video drafts',
};

interface IProps extends IInnerHomeStackNav<'InnerPlaylist'> {}

const CreateBiteStack: FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const isContentCreator = useSelector(isContentCreatorSelector);
  const userOrgPreferences = useSelector(userOrgPreferencesSelector);
  const isBiteSummaryCreated = useSelector(isBiteSummaryCreatedSelector);
  const biteQuestion = useSelector(biteQuestionSelector);
  const isBiteQuestionCreated = biteQuestion.isCreated;

  const { route, params } = useCurrentRoute();
  const isFocused = useIsFocused();

  const currentFlow = useSelector(currentFlowSelector);

  const currentScreenName = useMemo(() => {
    if (route?.state === undefined) {
      if (params?.screen) {
        return params?.screen;
      }

      if (userOrgPreferences.skip_create_bite_flow_overview_screen) {
        return Routes.CreateBiteStack.BiteTellYourStory;
      }

      return Routes.CreateBiteStack.CreateBiteInfo;
    }

    const screens = route?.state?.routes;
    if (screens?.[screens?.length - 1]?.name) {
      return screens[screens.length - 1]?.name;
    }
  }, [userOrgPreferences.skip_create_bite_flow_overview_screen, params, route?.state]);

  useEffect(() => {
    if (currentScreenName === Routes.CreateBiteStack.FinalScreen) {
      return;
    }
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: currentScreenName, from: params?.from },
      }),
    );
  }, [currentScreenName, params?.from, dispatch]);

  useEffect(() => {
    if (!isFocused) {
      if (currentFlow?.flow === EAppFlow.BITE_CREATION) {
        dispatch(setCurrentFlow(null));
        deactivateKeepAwake();
      }
      return;
    }

    dispatch(
      setCurrentFlow({
        flow: EAppFlow.BITE_CREATION,
        hasIntroVideo: false,
        videoUploadedToS3: false,
        biteId: null,
      }),
    );
    activateKeepAwake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused]);

  useEffect(() => {
    if (!isContentCreator) {
      navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
    }
  }, [isContentCreator, navigation]);

  if (!isContentCreator) {
    return null;
  }

  return (
    <Stack.Navigator screenOptions={getScreenOptions(theme)}>
      {!userOrgPreferences.skip_create_bite_flow_overview_screen && (
        <Stack.Screen name={Routes.CreateBiteStack.CreateBiteInfo} component={CreateBiteInfo} />
      )}
      <Stack.Screen name={Routes.CreateBiteStack.BiteTellYourStory} component={BiteTellYourStory} />

      <Stack.Screen
        name={Routes.CreateBiteStack.AIExpectationFirst}
        component={AIExpectationFirst}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.AIExpectationSecond}
        component={AIExpectationSecond}
        options={defaultScreenOptions}
      />

      {!isBiteQuestionCreated && (
        <Stack.Screen
          name={Routes.CreateBiteStack.AddQuestion}
          component={AddQuestion}
          options={{
            animationTypeForReplace: 'pop',
          }}
        />
      )}
      <Stack.Screen name={Routes.CreateBiteStack.BiteEditQuestion} component={BiteEditQuestion} />

      {!isBiteSummaryCreated && <Stack.Screen name={Routes.CreateBiteStack.SummaryNotes} component={SummaryNotes} />}
      <Stack.Screen
        name={Routes.CreateBiteStack.CreateSummaryNotes}
        component={CreateSummaryNotes}
        options={{
          headerTitle: t('addSummaryNotes.summaryCards'),
        }}
      />

      <Stack.Screen name={Routes.CreateBiteStack.BitesMagic} component={BitesMagic} options={defaultScreenOptions} />
      <Stack.Screen name={Routes.CreateBiteStack.FinalScreen} component={BiteCreated} options={defaultScreenOptions} />
      <Stack.Screen
        name={Routes.CreateBiteStack.BiteGoogleSlides}
        component={GoogleSlides}
        options={defaultScreenOptions}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.ExistingVideos}
        component={ExistingVideos}
        options={existingVideosOptions}
      />
      <Stack.Screen
        name={Routes.CreateBiteStack.BiteVideoEditor}
        component={VideoEditor}
        options={defaultScreenOptions}
      />
    </Stack.Navigator>
  );
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },
    header: getHeader,
    headerMode: 'screen',
    animationEnabled: !isWeb,
    ...TransitionPresets.SlideFromRightIOS,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

export default CreateBiteStack;
