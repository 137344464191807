import BottomSheet from '../BottomSheet/BottomSheet';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import styled from 'styled-components/native';
import React, { memo, useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Slider from './common/Slider';
import { IElementsPanelContent, IElementsPanelItem, IElementsPanelConfig } from './types';
import { useTranslation } from 'react-i18next';
import useElementsPanelTitle from './hooks/useElementsPanelTitle';

interface IProps {
  data: IElementsPanelConfig;
  onItemPress: (item: IElementsPanelItem, section: IElementsPanelContent) => void;
  renderCustomHeader?: ({ isOpen }: { isOpen: boolean }) => React.ReactNode;
}

const ElementsPanel: React.FC<IProps> = ({ onItemPress, data, renderCustomHeader }) => {
  const { t } = useTranslation();
  const { title } = useElementsPanelTitle({ config: data });

  const renderSection = useCallback(
    ({ item, index }) => {
      const contentItem = item as IElementsPanelContent;

      return (
        <S.ElementsPanelContentContainer key={index}>
          <S.ElementsPanelContentTitle>{t(contentItem.title)}</S.ElementsPanelContentTitle>
          <Slider content={contentItem} onItemPress={onItemPress} />
        </S.ElementsPanelContentContainer>
      );
    },
    [onItemPress, t],
  );

  const renderTitle = useCallback(
    (isOpen) => {
      if (renderCustomHeader) {
        return renderCustomHeader({ isOpen });
      }

      return (
        <S.ElementsPanelTitleContainer>
          <S.ElementsPanelTitle>{t(data?.title)}</S.ElementsPanelTitle>
          <S.ElementsPanelSubtitle>{!isOpen ? title : ' '}</S.ElementsPanelSubtitle>
        </S.ElementsPanelTitleContainer>
      );
    },
    [data?.title, renderCustomHeader, t, title],
  );

  const handleRenderCustomHeader = useCallback((isOpen) => renderCustomHeader({ isOpen }), [renderCustomHeader]);

  if (!data) {
    return null;
  }

  return (
    <BottomSheet title={renderTitle} height={calcHeight(600)} renderCustomHeader={handleRenderCustomHeader}>
      <S.ScrollContainer
        data={data?.sections}
        renderItem={renderSection}
        contentContainerStyle={S.ContentContainerStyles}
        nestedScrollEnabled
      />
    </BottomSheet>
  );
};

const S = {
  ScrollContainer: styled.FlatList`
    width: ${isWeb ? deviceWidth - calcWidth(20) : deviceWidth}px;
    height: ${calcHeight(650)}px;
    margin-bottom: ${calcHeight(50)}px;
  `,
  ElementsPanelContentContainer: styled.View`
    height: ${calcHeight(230)}px;
    width: ${isWeb ? calcWidth(109) * 5 + calcWidth(70) : calcWidth(109) * 3 + calcWidth(30)}px;
    margin: ${calcHeight(5)}px ${calcWidth(5)}px;
  `,
  ElementsPanelItem: styled.TouchableOpacity`
    width: ${calcWidth(109)}px;
    height: ${calcHeight(204)}px;
    margin: ${calcHeight(5)}px ${calcWidth(5)}px;
  `,
  ElementsPanelContentTitle: styled.Text`
    font-size: ${calcWidth(16)}px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
    margin: ${calcHeight(10)}px ${calcWidth(10)}px;
    margin-bottom: ${calcHeight(4)}px;
  `,
  ElementsPanelItemImage: styled.Image`
    position: relative;
    width: ${calcWidth(109)}px;
    height: ${calcHeight(154)}px;
    border-radius: 18px;
    overflow: hidden;
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  `,
  ElementsPanelContentScrollContent: styled.ScrollView``,
  ElementsPanelItemTitle: styled.Text`
    margin-top: ${calcHeight(4)}px;
    padding: 0 ${calcWidth(5)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11}px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  ContentContainerStyles: { alignItems: 'center' } as StyleProp<ViewStyle>,
  ElementsPanelTitleContainer: styled.View``,
  ElementsPanelTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
  `,
  ElementsPanelSubtitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    color: ${({ theme }) => theme.colors.gray1};
  `,
};

export default memo(ElementsPanel);
