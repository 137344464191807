import styled from 'styled-components/native';
import React from 'react';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

const TextInput: React.FC<IProps> = ({ value, onChange }) => {
  return (
    <S.Container>
      <S.Input value={value as string} onChangeText={onChange} />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    margin: ${calcHeight(10)}px ${calcHeight(isWeb ? 0 : 15)}px;
    align-items: center;
  `,
  Label: styled.Text`
    width: ${calcWidth(270)}px;
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
  `,
  Input: styled.TextInput<{ hasErrors?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    border: 2px solid ${({ theme, hasErrors }) => (hasErrors ? theme.colors.failRed : theme.colors.primaryBlue)};
    border-radius: 30px;
    padding: ${calcHeight(5)}px ${calcWidth(20)}px;
    margin: ${calcHeight(10)}px;
    width: ${calcWidth(300)}px;
    height: 60px;
  `,
};

export default TextInput;
