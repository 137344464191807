import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../store/createPlaylist/createPlaylist.selectors';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import MediaModal from '../../screens/creationBite/common/MediaModal';
import useMedia, { formatFileTypesForWeb } from '../../hooks/useMedia';
import { updatePlaylistCover, uploadPlaylistCoverImage } from '../../store/playlist/playlist.actions';
import { playlistCoverButtonDataSet } from './playlistCover.constants';
import uploadImageByUrl from '../../utils/uploadImageByUrl';

interface IProps {}

const EditPlaylistCoverButton: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { editedPlaylist } = useSelector(createPlaylistSelector);
  const [isMediaModalVisible, setMediaModalVisible] = useState(false);

  const handleOpenMediaModal = useCallback(() => {
    setMediaModalVisible(true);
  }, []);

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const handleMediaSelection = useCallback(
    (uri, type, editedImageUri) => {
      handleCloseMediaModal();

      dispatch(
        uploadPlaylistCoverImage({
          playlistId: editedPlaylist?.id,
          uri: isWeb ? uri : editedImageUri || uri,
          type,
        }),
      );
    },
    [handleCloseMediaModal, dispatch, editedPlaylist?.id],
  );

  const { launchImageLibrary, launchImageCamera, dropZoneUploadingForWeb } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    onMediaSelectionCB: handleMediaSelection,
  });

  const handleSearchOnlineSelect = useCallback(
    async (imageUrl: string) => {
      const media = await uploadImageByUrl({ url: imageUrl });
      dispatch(
        updatePlaylistCover({
          playlistId: editedPlaylist.id,
          uri: media.image_url,
        }),
      );
    },
    [editedPlaylist?.id, dispatch],
  );

  return (
    <>
      <S.EditCoverButton
        onPress={handleOpenMediaModal}
        //@ts-ignore
        dataSet={playlistCoverButtonDataSet}
      >
        <S.EditCoverButtonText>{t('biteCreated.editCover')}</S.EditCoverButtonText>
      </S.EditCoverButton>

      <MediaModal
        isVisible={isMediaModalVisible}
        onClose={handleCloseMediaModal}
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onSelectStockImage={handleSearchOnlineSelect}
        from='cover_photo'
      />
      {dropZoneUploadingForWeb}
    </>
  );
};

const S = {
  EditCoverButton: styled.TouchableOpacity`
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid ${({ theme }) => theme.colors.lightGray6};
    padding: ${calcHeight(7)}px;
    height: ${calcHeight(30)}px;
    border-radius: ${calcHeight(30 / 2)}px;
    width: ${calcWidth(177)}px;
    margin-bottom: ${calcHeight(21)}px;
    z-index: 2;
  `,
  EditCoverButtonText: styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s11};
    font-weight: bold;
  `,
};

export default EditPlaylistCoverButton;
