import React, { FC, useCallback } from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import CloseGrayIcon from '../../../assets/icons/close_gray.svg';
import { scale1, calcWidth } from '../../../utils/dimensions';
import { EFileType, MediaViewerProps } from '../../../types/media';
import YoutubePlayer from './VideoPlayers/YoutubePlayer';
import VimeoVideoPlayer from './VideoPlayers/VimeoVideoPlayer';
import SlidesPlayer from './SlidesPlayer';

const MediaViewer: FC<MediaViewerProps> = (mediaViewerProps) => {
  const {
    mediaType,
    mediaURI,
    onRemoveMedia,
    youtubeMedia,
    setRef,
    onEnterFullscreen,
    onExitFullscreen,
    onMediaDetails,
    isAutoPlay,
    initialSizes,
  } = mediaViewerProps;

  const handleImageLoad = useCallback(() => {
    Image.getSize(
      mediaURI,
      (imageWidth, imageHeight) => {
        onMediaDetails?.({
          width: imageWidth,
          height: imageHeight,
        });
      },
      () => {},
    );
  }, [mediaURI, onMediaDetails]);

  const renderRelevantMediaViewer = () => {
    if (mediaURI || youtubeMedia) {
      switch (mediaType) {
        case EFileType.VIDEO:
          return (
            <VimeoVideoPlayer
              onEnterFullscreen={onEnterFullscreen}
              onExitFullscreen={onExitFullscreen}
              mediaViewerProps={mediaViewerProps}
            />
          );
        case EFileType.IMAGE:
          return <S.Image onLoad={handleImageLoad} source={{ uri: mediaURI! }} />;
        case EFileType.YOUTUBE:
          if (youtubeMedia) {
            const playerKey = `${youtubeMedia.url}-${youtubeMedia.video_start_at}-${youtubeMedia.video_end_at}-${youtubeMedia.is_cc_enabled}`;
            return (
              <YoutubePlayer
                key={playerKey}
                onMediaDetails={onMediaDetails}
                isAutoPlay={isAutoPlay}
                setRef={setRef}
                youtubeMedia={youtubeMedia}
              />
            );
          }
          break;
        case EFileType.GOOGLE_SLIDES:
          return <SlidesPlayer url={mediaURI} onMediaDetails={onMediaDetails} initialSizes={initialSizes} />;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <S.MediaWrapper>
      {renderRelevantMediaViewer()}
      {onRemoveMedia && (
        <S.RemoveMedia>
          <TouchableOpacity onPress={onRemoveMedia}>
            <CloseGrayIcon fill='black' width={scale1(30)} height={scale1(30)} />
          </TouchableOpacity>
        </S.RemoveMedia>
      )}
    </S.MediaWrapper>
  );
};

const MediaWrapper = styled.View`
  height: 100%;
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

const Image = styled.Image`
  height: 100%;
  width: 100%;
`;

const RemoveMedia = styled.View`
  position: absolute;
  z-index: 2;
  right: ${calcWidth(5)}px;
  top: ${calcWidth(5)}px;
`;

const S = { MediaWrapper, Image, RemoveMedia };

export default MediaViewer;
