import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EAnsweredFilterValue,
  ECommentedFilterValue,
  ECurrentList,
  EEngagedFilterValue,
  IAnalyticsAnswersListItem,
  IAnalyticsBiteQuestion,
  IAnalyticsCommentsListItem,
  IAnalyticsViewsListItem,
  IInitialState,
} from './analytics.types';
import calculatePercentages from '../../utils/calculatePercentages';
import { EQuestionType } from '../../types/biteQuestion';
import { cloneDeep } from 'lodash';
import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';

export const initialState: IInitialState = {
  isFilterTooltipShown: false,
  isRefreshing: false,
  isFilterBottomSheetOpen: false,
  currentList: ECurrentList.ATTRIBUTES,
  biteId: null,
  hasDistributions: null,
  overview: {
    isNeedToScrollUp: false,
    error: null,
    filter: EEngagedFilterValue.ALL,
    isLoading: false,
    data: null,
    next: null,
  },
  views: {
    isNeedToScrollUp: false,
    error: null,
    filter: EEngagedFilterValue.ALL,
    isLoading: false,
    data: null,
    next: null,
  },
  answers: {
    isNeedToScrollUp: false,
    error: null,
    filter: {
      answerIds: [],
      answered: EAnsweredFilterValue.ALL,
    },
    isLoading: false,
    data: null,
    next: null,
  },
  comments: {
    isNeedToScrollUp: false,
    error: null,
    filter: ECommentedFilterValue.ALL,
    isLoading: false,
    data: null,
    next: null,
  },
  aggregatedAnswers: {
    isLoading: false,
    error: null,
    data: {
      question: null,
      aggregated: {
        percentage: null,
        absolute: null,
      },
    },
  },
};

type TStateFields = keyof IInitialState;
export type TExcludeFields = (
  | TStateFields
  | 'guestsAttribute'
  | 'sharedWithFilter'
  | {
      field:
        | 'overviewAttributesMapById'
        | 'viewsAttributesMapById'
        | 'answersAttributesMapById'
        | 'commentsAttributesMapById';
      attributeIds: number[];
    }
)[];

const ANALYTICS = 'ANALYTICS';

interface IGetAnalyticsDataPayload {
  reset: boolean;
  clearAnalyticsCache?: boolean;
  callback?: () => void;
}

const analyticsSlice = createSlice({
  name: ANALYTICS,
  initialState,
  reducers: {
    prepareAnalytics: (state, { payload }: PayloadAction<number>) => {
      return {
        ...initialState,
        biteId: payload,
      };
    },
    getAnswersAggregated: (state) => {
      state.aggregatedAnswers.isLoading = true;
      state.aggregatedAnswers.error = null;
    },
    setAnswersAggregated: (
      state,
      { payload }: PayloadAction<{ question: IAnalyticsBiteQuestion; aggregated: { [key: string]: number } }>,
    ) => {
      let absolute = null;
      let rounded = null;

      if (payload.question.type !== EQuestionType.OPEN_ENDED) {
        absolute = {};

        payload.question.choices.forEach((choice) => {
          absolute[choice.id] = payload.aggregated[choice.id] || 0;
        });

        const { roundedPercentages } = calculatePercentages(absolute);
        rounded = roundedPercentages;
      }

      state.aggregatedAnswers.data.aggregated.absolute = absolute;
      state.aggregatedAnswers.data.aggregated.percentage = rounded;
      state.aggregatedAnswers.data.question = payload.question;
      state.aggregatedAnswers.isLoading = false;
    },
    setAggregatedAnswersError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.aggregatedAnswers.error = payload.error;
    },
    getOverviewData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.overview.isLoading = true;
      state.overview.error = null;
      if (payload?.reset) {
        state.overview.next = null;
      }
    },
    getViewsData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.views.isLoading = true;
      state.views.error = null;
      if (payload?.reset) {
        state.views.next = null;
      }
    },
    getAnswersData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.answers.isLoading = true;
      state.answers.error = null;
      if (payload?.reset) {
        state.answers.next = null;
      }
    },
    getCommentsData: (state, { payload }: PayloadAction<IGetAnalyticsDataPayload | undefined>) => {
      state.comments.isLoading = true;
      state.comments.error = null;
      if (payload?.reset) {
        state.comments.next = null;
      }
    },
    setOverviewData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsViewsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.overview.next && !payload.reset) {
        state.overview.data = state.overview.data.concat(payload.results);
      } else {
        state.overview.data = payload.results;
      }
      state.overview.next = payload.next;
      state.overview.isLoading = false;
    },
    setViewsData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsViewsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.views.next && !payload.reset) {
        state.views.data = state.views.data.concat(payload.results);
      } else {
        state.views.data = payload.results;
      }
      state.views.next = payload.next;
      state.views.isLoading = false;
    },
    setAnswersData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsAnswersListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.answers.next && !payload.reset) {
        state.answers.data = state.answers.data.concat(payload.results);
      } else {
        state.answers.data = payload.results;
      }
      state.answers.next = payload.next;
      state.answers.isLoading = false;
    },
    setCommentsData: (
      state,
      { payload }: PayloadAction<{ results: IAnalyticsCommentsListItem[]; next: null; reset?: boolean }>,
    ) => {
      if (state.comments.next && !payload.reset) {
        state.comments.data = state.comments.data.concat(payload.results);
      } else {
        state.comments.data = payload.results;
      }
      state.comments.next = payload.next;
      state.comments.isLoading = false;
    },
    setOverviewFilter: (state, { payload }: PayloadAction<EEngagedFilterValue>) => {
      state.overview.filter = payload;
      state.overview.next = null;
    },
    setViewsFilter: (state, { payload }: PayloadAction<EEngagedFilterValue>) => {
      state.views.filter = payload;
      state.views.next = null;
    },
    setAnswersFilter: (state, { payload }: PayloadAction<EAnsweredFilterValue>) => {
      state.answers.filter.answered = payload;
      state.answers.next = null;
    },
    setAnswersIdsFilter: (state, { payload }: PayloadAction<number[]>) => {
      state.answers.filter.answerIds = payload;
      state.answers.next = null;
    },
    setCommentsFilter: (state, { payload }: PayloadAction<ECommentedFilterValue>) => {
      state.comments.filter = payload;
      state.comments.next = null;
    },
    setOverviewDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.overview.error = payload.error;
      state.overview.isLoading = false;
    },
    setViewsDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.views.error = payload.error;
      state.views.isLoading = false;
    },
    setAnswersDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.answers.error = payload.error;
      state.answers.isLoading = false;
    },
    setCommentsDataError: (state, { payload }: PayloadAction<{ error: string }>) => {
      state.comments.error = payload.error;
      state.comments.isLoading = false;
    },
    setCurrentList: (state, { payload }: PayloadAction<ECurrentList>) => {
      state.currentList = payload;
    },
    setIsRefreshing: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefreshing = payload;
    },
    setIsNeedToScrollUp: (
      state,
      { payload: { listType, status } }: PayloadAction<{ listType: EAnalyticsScreenTabs; status: boolean }>,
    ) => {
      state[listType].isNeedToScrollUp = status;
    },
    setFilterBottomSheetOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterBottomSheetOpen = payload;
    },
    setHasDistributions: (state, { payload }: PayloadAction<boolean>) => {
      state.hasDistributions = payload;
    },
    setIsFilterTooltipShown: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterTooltipShown = payload;
    },
    resetAnalytics: (
      state,
      {
        payload,
      }: PayloadAction<{
        excludeFields: TExcludeFields;
        withFiltersReset?: boolean;
      }>,
    ) => {
      const { excludeFields, withFiltersReset } = payload;

      Object.keys(state).forEach((key) => {
        if (excludeFields.some((field) => typeof field === 'string' && field === key)) {
          return;
        }

        const newState = cloneDeep(initialState[key]);

        if (!withFiltersReset && state[key].filter) {
          newState.filter = state[key].filter;
        }

        state[key] = newState;
      });
    },
  },
});

export const {
  setAnswersAggregated,
  getOverviewData,
  getViewsData,
  getAnswersData,
  getCommentsData,
  setOverviewData,
  setViewsData,
  setAnswersData,
  setCommentsData,
  setOverviewFilter,
  setViewsFilter,
  setAnswersFilter,
  setAnswersIdsFilter,
  setCommentsFilter,
  getAnswersAggregated,
  setAggregatedAnswersError,
  setOverviewDataError,
  setViewsDataError,
  setAnswersDataError,
  setCommentsDataError,
  prepareAnalytics,
  setCurrentList,
  setIsRefreshing,
  setIsNeedToScrollUp,
  setFilterBottomSheetOpen,
  setHasDistributions,
  setIsFilterTooltipShown,
  resetAnalytics,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const getBiteDistributions = createAction(`${ANALYTICS}/getBiteDistributions`);
