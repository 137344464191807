import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { calcHeight, calcWidth, deviceWidth } from '../../../../utils/dimensions';
import { WEB_DRAWER_WIDTH } from '../../../../utils/constants/drawer';
import styled from 'styled-components';
import styledNative from 'styled-components/native';
import CircleButton, { EButtonIcon } from '../../../../components/shared/buttons/CircleButton';
import { DrawerModalProps } from './DrawerModal.types';

const ANIMATION_DURATION = 300;

const DrawerModal: React.FC<DrawerModalProps> = ({ isVisible, onClose, title, renderContent, fromModal }) => {
  const [isVisibleLocal, setVisibleLocal] = useState(false);

  const className = cn({ active: isVisibleLocal, 'secondary-modal': fromModal });

  const handleClose = useCallback(() => {
    setVisibleLocal(false);
    setTimeout(onClose, ANIMATION_DURATION);
  }, [onClose]);

  useEffect(() => {
    setVisibleLocal(isVisible);

    if (!isVisible) {
      handleClose();
    }
  }, [handleClose, isVisible]);

  return (
    <S.Container className={className} isVisible={isVisible}>
      <S.Overlay onPress={handleClose} />
      <S.Header>
        <S.HeaderTitle>{title}</S.HeaderTitle>
        <CircleButton onPress={handleClose} icon={EButtonIcon.CLOSE} />
      </S.Header>
      <S.Content>{renderContent(handleClose, isVisible)}</S.Content>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isVisible: boolean }>`
    left: ${WEB_DRAWER_WIDTH}px;
    top: 0;
    position: absolute;
    width: ${calcWidth(0)}px;
    height: 100%;
    background-color: white;
    transition: all ${ANIMATION_DURATION}ms ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: ${({ isVisible }) => (isVisible ? 1 : -100)};
    border-right: 1px solid #e5ebec;
    box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.2);

    &.active {
      visibility: visible;
      width: ${calcWidth(410)}px;
      opacity: 1;
    }

    &.secondary-modal {
      margin-top: -88px;
      left: 0;
      background: white;
      z-index: 2;

      height: calc(100% + 88px);
    }
  `,
  Content: styled.div`
    flex: 1;
    height: calc(100% - 88px);
  `,
  Header: styledNative.View`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${calcHeight(24)}px ${calcWidth(24)}px 0;
    height: 88px;
  `,
  HeaderTitle: styledNative.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  Overlay: styledNative.TouchableOpacity`
    opacity: 0;
    position: absolute;
    width: ${deviceWidth}px;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
};

export default DrawerModal;
