import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { TouchableOpacity, Animated, StyleSheet } from 'react-native';
import { CustomText } from '../../../components/shared';
import { deviceWidth } from '../../../utils/dimensions';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  progress: Animated.AnimatedInterpolation<number>;
  dragX: Animated.AnimatedInterpolation<number>;
  onDelete?: () => void;
}

const BiteOptions: FC<IProps> = ({ t, dragX, onDelete }) => {
  const opacity = dragX.interpolate({
    inputRange: [0, deviceWidth],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  return (
    <Animated.View style={{ ...styles.container, opacity }}>
      {onDelete && (
        <TouchableOpacity onPress={onDelete}>
          <S.TextBtn red>{t('common.delete')}</S.TextBtn>
        </TouchableOpacity>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
});

const LeftButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

const TextBtn = styled(CustomText)<{ red?: boolean }>(
  ({ theme, red }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    color: ${red ? theme.colors.lightRed : theme.colors.primaryBlue};
  `,
);

const S = { LeftButtonsContainer, TextBtn };

export default BiteOptions;
