import React, { FC, memo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import Routes from '../../routes';
import AnalyticsScreen from '../../../screens/analytics/AnalyticsScreen';
import BiteScreen from '../../../screens/bite/BiteScreen';
import BitePreview from '../../../screens/bite/BitePreview';
import { useSelector } from 'react-redux';
import { biteIdSelector } from '../../../store/analytics/analytics.selector';
import { IStackNavigation } from '../../types';
import ReturnToFeed from '../../../components/shared/ReturnToFeed/ReturnToFeed';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import { IAttribute } from '../../../store/attributes/attributes.types';
import { organizationAttributesSelector } from '../../../store/attributes/attributes.selector';
import { isWeb } from '../../../utils/dimensions';

interface IProps extends IStackNavigation {}

const Stack = createStackNavigator();

const defaultScreenOptions = {
  headerShown: false,
  animationEnabled: !isWeb,
  cardStyle: {
    backgroundColor: 'white',
  },
};

const AnalyticsStack: FC<IProps> = () => {
  const biteId = useSelector(biteIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const attributes: IAttribute[] = useSelector(organizationAttributesSelector);

  if (!biteId || !bite) {
    return <ReturnToFeed />;
  }

  return (
    <Stack.Navigator>
      <Stack.Screen name={Routes.AnalyticsStack.BiteOverview} component={BiteScreen} options={defaultScreenOptions} />
      <Stack.Screen name={Routes.AnalyticsStack.BitePreview} component={BitePreview} options={defaultScreenOptions} />
      {!!attributes && (
        <Stack.Screen name={Routes.AnalyticsStack.Stats} component={AnalyticsScreen} options={defaultScreenOptions} />
      )}
    </Stack.Navigator>
  );
};

export default memo(AnalyticsStack);
