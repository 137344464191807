import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useDispatch } from 'react-redux';
import { calcWidth, calcHeight, calcFontSize, deviceWidth, isWeb } from '../../../../../../utils/dimensions';
import Divider from '../../../../../shared/Divider';
import { useTranslation } from 'react-i18next';
import { IS_ANDROID, IS_IOS } from '../../../../../../utils/constants/env';
import { saveIntroSubtitlesLines } from '../../../../../../store/createBite/createBites.actions';
import { ILine } from '../../../../../../types/subtitles';
import ShadowedContainer from '../../../../../ShadowedContainer';
import EditIcon from '../../../../../../assets/icons/preview/edit.svg';
import BlueAndRedButtonsModal from '../../../../../modals/BlueAndRedButtonsModal';
import { isEqual } from 'lodash';

interface IProps {
  index: number;
  isCurrent: boolean;
  line: ILine;
  lineOriginal: ILine;
  onSelect: () => void;
  onChangeLine: (ILine) => void;
  onBlur?: () => void;
  lines: ILine[];
}

export const LINE_HEIGHT = 80;
export const LINE_MARGIN = 16;
const localePrefix = 'enhancements';

const timeToSeconds = (time) => {
  const [minutes, seconds] = time.split(':').map(Number);
  return minutes * 60 + seconds;
};

const Line: React.FC<IProps> = ({ index, isCurrent, line, lineOriginal, onChangeLine, onSelect, onBlur, lines }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const [inputHeight, setInputHeight] = useState(LINE_HEIGHT);

  const isEdited = useMemo(() => !isEqual(line, lineOriginal), [line, lineOriginal]);
  const [isEditTimeModalVisible, setIsEditTimeModalVisible] = useState(false);

  const [lineUpdatedCounter, setLineUpdatedCounter] = useState(0); // save counter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialTextValue = useMemo(() => line.line, [lineUpdatedCounter]); // update on save

  const setLine = useCallback(
    (value) => {
      onChangeLine({
        ...line,
        line: value,
      });
    },
    [onChangeLine, line],
  );

  const handleContentSizeChange = useCallback((event) => {
    setInputHeight(event.nativeEvent.contentSize.height);
  }, []);

  const handleOnBlur = useCallback(() => {
    if (line.line === initialTextValue) {
      return;
    }
    setLineUpdatedCounter(Date.now()); // trigger update of currently saved state
    dispatch(saveIntroSubtitlesLines());
    onBlur();
  }, [dispatch, initialTextValue, line.line, onBlur]);

  const handleOpenEditTimeModal = useCallback(() => {
    setIsEditTimeModalVisible(true);
  }, []);

  const handleCloseEditTimeModal = useCallback(() => {
    setIsEditTimeModalVisible(false);
  }, []);

  const handleDoneEditTime = useCallback(
    (minTime, maxTime) => {
      onChangeLine({
        ...line,
        startTimeText: minTime,
        endTimeText: maxTime,
        startTime: timeToSeconds(minTime),
        endTime: timeToSeconds(maxTime),
        time_line: `00:${minTime}.000 --> 00:${maxTime}.000`,
      });

      dispatch(saveIntroSubtitlesLines());
    },
    [dispatch, line, onChangeLine],
  );

  return (
    <S.Container>
      <ShadowedContainer viewStyle={S.ShadowedContainerStyle} containerViewStyle={S.ShadowedContainerViewStyle}>
        <S.Line height={inputHeight + calcHeight(50)} isCurrent={isCurrent} onPress={onSelect}>
          <S.TextContainer>
            <S.Text
              height={isWeb && inputHeight}
              value={line.line}
              onFocus={onSelect}
              onChangeText={setLine}
              textAlignVertical='top'
              autoCorrect={false}
              scrollEnabled={false}
              onBlur={handleOnBlur}
              onContentSizeChange={handleContentSizeChange}
              multiline
            />
          </S.TextContainer>
          <S.BottomContainer>
            <S.Details>
              <S.EditButton onPress={handleOpenEditTimeModal}>
                <S.UnderlineContainer>
                  <S.DetailsText>{line.startTimeText}</S.DetailsText>
                  <S.TextUnderline>{line.startTimeText}</S.TextUnderline>
                </S.UnderlineContainer>
                <S.DetailsText>-</S.DetailsText>
                <S.UnderlineContainer>
                  <S.DetailsText>{line.endTimeText}</S.DetailsText>
                  <S.TextUnderline>{line.endTimeText}</S.TextUnderline>
                </S.UnderlineContainer>
                <S.EditIconContainer>
                  <EditIcon width='28' height='28' color={theme.colors.text} />
                </S.EditIconContainer>
              </S.EditButton>
            </S.Details>
            {isEdited && (
              <S.Details>
                <S.DetailsText>{t(`${localePrefix}.edited`)}</S.DetailsText>
              </S.Details>
            )}
          </S.BottomContainer>
        </S.Line>
      </ShadowedContainer>
      <EditTimeModal
        index={index}
        line={line}
        lines={lines}
        onDone={handleDoneEditTime}
        isVisible={isEditTimeModalVisible}
        onClose={handleCloseEditTimeModal}
      />
    </S.Container>
  );
};

interface IEditTimeModalProps {
  isVisible: boolean;
  onClose: () => void;
  onDone: (minTime: string, maxTime: string) => void;
  line: ILine;
  lines: ILine[];
  index: number;
}

const EditTimeModal: React.FC<IEditTimeModalProps> = ({ isVisible, onClose, onDone, line, lines, index }) => {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(line.startTimeText);
  const [endTime, setEndTime] = useState(line.endTimeText);

  const startTimeErrorCode = useMemo(() => {
    const startTimeSeconds = timeToSeconds(startTime);
    const endTimeSeconds = timeToSeconds(endTime);
    if (startTimeSeconds >= endTimeSeconds) {
      return 'startTimeIsTooHigh';
    }

    const previousMaxTime = index > 0 ? timeToSeconds(lines[index - 1].endTimeText) : 0;
    if (previousMaxTime > startTimeSeconds) {
      return 'startTimeIsTooLow';
    }

    return null;
  }, [endTime, index, lines, startTime]);

  const endTimeErrorCode = useMemo(() => {
    const endTimeSeconds = timeToSeconds(endTime);
    if (lines[index + 1]?.startTime && endTimeSeconds > lines[index + 1].startTime) {
      return 'endTimeIsTooHigh';
    }

    return null;
  }, [endTime, index, lines]);

  const handleStartTimeChange = useCallback((text) => {
    setStartTime(text);
  }, []);

  const handleEndTimeChange = useCallback((text) => {
    setEndTime(text);
  }, []);

  const handleDone = useCallback(() => {
    if (startTimeErrorCode !== null) {
      return;
    }

    onDone(startTime, endTime);
    onClose();
  }, [startTimeErrorCode, onDone, startTime, endTime, onClose]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    setStartTime(line.startTimeText);
    setEndTime(line.endTimeText);
  }, [isVisible, line]);

  const renderContent = useCallback(
    () => (
      <S.ModalContent>
        <S.InputsContainer>
          <TimeTextInput isError={!!startTimeErrorCode} onChangeText={handleStartTimeChange} value={startTime} />
          <S.DetailsText> - </S.DetailsText>
          <TimeTextInput onChangeText={handleEndTimeChange} value={endTime} />
        </S.InputsContainer>
        {!!startTimeErrorCode && (
          <S.ErrorText>
            {t(`${localePrefix}.subtitlesTimeModal.${startTimeErrorCode}`, {
              previousEndTime: index ? lines[index - 1].endTimeText : '00:00',
            })}
            {!!index &&
              startTimeErrorCode === 'startTimeIsTooLow' &&
              t(`${localePrefix}.subtitlesTimeModal.${startTimeErrorCode}Description`)}
          </S.ErrorText>
        )}
        {!!endTimeErrorCode && (
          <S.ErrorText isGray>{t(`${localePrefix}.subtitlesTimeModal.${endTimeErrorCode}`)}</S.ErrorText>
        )}
      </S.ModalContent>
    ),
    [
      startTimeErrorCode,
      handleStartTimeChange,
      startTime,
      handleEndTimeChange,
      endTime,
      t,
      index,
      lines,
      endTimeErrorCode,
    ],
  );

  return (
    <BlueAndRedButtonsModal
      title={line.line}
      isVisible={isVisible}
      onClose={onClose}
      onRightButtonClick={handleDone}
      leftButtonLabel={t('common.cancel')}
      rightButtonLabel={t('common.apply')}
      onLeftButtonClick={onClose}
      renderContent={renderContent}
      buttonsMarginTop={20}
      reverseColors
    />
  );
};

interface ITextTimeProps {
  value: string;
  onChangeText: (text: string) => void;
  isError?: boolean;
}

const TimeTextInput: React.FC<ITextTimeProps> = ({ value, onChangeText, isError }) => {
  const handleTextChange = useCallback(
    (text) => {
      let newText = text.replace(/[^0-9]/g, '');

      if (newText.length < 3) {
        onChangeText(newText);
        return;
      }

      let minutes = newText.substring(0, 2);
      let seconds = newText.substring(2, 4);
      if (parseInt(minutes, 10) > 59) {
        minutes = '59';
      }
      if (parseInt(seconds, 10) > 59) {
        seconds = '59';
      }
      newText = minutes + ':' + seconds;

      onChangeText(newText);
    },
    [onChangeText],
  );

  const handleBlur = useCallback(() => {
    const hasSeconds = value.length > 3;
    const notFull = value.length < 5;
    if (notFull && hasSeconds) {
      let [minutes, seconds] = value.split(':').map((v) => v.padStart(2, '0'));

      if (minutes && parseInt(minutes, 10) > 59) {
        minutes = '59';
      }
      if (seconds && parseInt(seconds, 10) > 59) {
        seconds = '59';
      }

      const newText = (minutes.padStart(2, '0') || '00') + ':' + (seconds.padStart(2, '0') || '00');
      onChangeText(newText);
      return;
    }

    if (notFull) {
      let minutes = value;
      if (minutes && parseInt(minutes, 10) > 59) {
        minutes = '59';
      }
      const newText = (minutes.padStart(2, '0') || '00') + ':' + '00';
      onChangeText(newText);
    }
  }, [onChangeText, value]);

  return (
    <S.TimeInputContainer>
      <S.TimeInput
        isError={isError}
        onChangeText={handleTextChange}
        value={value}
        onBlur={handleBlur}
        placeholder='00:00'
        keyboardType='numeric'
        maxLength={5}
        selectTextOnFocus
      />
    </S.TimeInputContainer>
  );
};

const S = {
  Container: styled.View`
    margin-top: ${calcHeight(LINE_MARGIN)}px;
  `,
  ShadowedContainerViewStyle: {
    marginLeft: calcWidth(16),
    marginRight: calcWidth(16),
  },
  ShadowedContainerStyle: {
    width: deviceWidth - calcWidth(32),
    borderRadius: 15,
  },
  Line: styled.TouchableOpacity<{ isCurrent: boolean; height: number }>`
    height: ${({ height }) => height}px;
    width: ${deviceWidth - calcWidth(32)}px;
    border: 1px ${({ theme, isCurrent }) => (isCurrent ? theme.colors.primaryBlue : 'transparent')} solid;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  TextContainer: styled.View`
    flex-grow: 1;
    padding: ${calcHeight(IS_ANDROID ? 0 : IS_IOS ? 4 : 8)}px ${calcHeight(16)}px;
  `,
  Text: styled.TextInput<{ height?: number }>`
    font-size: ${calcFontSize(20)}px;
    color: ${({ theme }) => theme.colors.darkGray15};
    line-height: ${calcHeight(IS_ANDROID ? 28 : IS_IOS ? 30 : 34)}px;
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
  `,
  BottomContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${calcHeight(6)}px ${calcWidth(18)}px ${calcHeight(16)}px;
    width: 100%;
  `,
  Details: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  DetailsIcon: styled.View``,
  DetailsText: styled.Text`
    padding-bottom: 2px;
    font-size: ${calcFontSize(14)}px;
    color: ${({ theme }) => theme.colors.darkGray15};
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray33};
  `,
  TextUnderline: styled.Text`
    color: transparent;
    text-decoration: underline;
    text-decoration-style: dotted;
    margin-top: ${calcHeight(-14)}px;
    margin-left: ${calcWidth(2)}px;
  `,
  UnderlineContainer: styled.View`
    margin: 0 ${calcHeight(4)}px;
    align-items: center;
  `,
  EditIconContainer: styled.View`
    align-items: center;
    justify-content: center;
    max-width: ${calcWidth(20)}px;
    max-height: ${calcWidth(20)}px;
    margin-bottom: 3px;
  `,
  EditButton: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ModalContent: styled.View`
    align-items: center;
    justify-content: center;
  `,
  InputsContainer: styled.View`
    margin-top: ${calcHeight(20)}px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ErrorText: styled.Text<{ isGray?: boolean }>`
    text-align: center;
    margin-top: ${calcHeight(10)}px;
    color: ${({ theme, isGray }) => (isGray ? theme.colors.text : theme.colors.pinkError)};
    font-size: ${calcFontSize(14)}px;
  `,
  TimeInputContainer: styled.View<{ isError?: boolean }>`
    width: ${calcWidth(100)}px;
    height: ${calcHeight(50)}px;
    border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.pinkError : theme.colors.gray20)};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: ${calcHeight(50) / 2}px;
    background-color: #fff;
  `,
  TimeInput: styled.TextInput`
    width: ${calcWidth(44)}px;
    height: 100%;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
  `,
};

export default Line;
