import { createStackNavigator, TransitionPresets, StackNavigationOptions } from '@react-navigation/stack';
import Home from '../../../../screens/landing/Home';
import Routes from '../../../routes';

import React, { FC, useCallback } from 'react';

import Header from '../../../../components/Header';
import styled, { DefaultTheme, useTheme } from 'styled-components/native';

import DrawerButton from '../../../../components/shared/DrawerButton';
import { isWeb } from '../../../../utils/dimensions';
import SsoAuthLoader from '../../../../screens/auth/common/SsoAuthLoader/SsoAuthLoader';

const Stack = createStackNavigator();

interface IProps {
  isLoading: boolean;
}

const HomeStack: FC<IProps> = ({ isLoading }) => {
  const theme = useTheme();
  const getScreenOptionsCallback = useCallback(() => getScreenOptions({ theme }), [theme]);

  if (isLoading) {
    return (
      <S.LoaderOverlay>
        <SsoAuthLoader />
      </S.LoaderOverlay>
    );
  }

  return (
    <Stack.Navigator screenOptions={getScreenOptionsCallback}>
      <Stack.Screen name={Routes.HomeStack.Home} component={Home} />
    </Stack.Navigator>
  );
};

const S = {
  LoaderOverlay: styled.View`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
};

const getScreenOptions = ({ theme }: { theme: DefaultTheme }): StackNavigationOptions => {
  return {
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },
    animationEnabled: !isWeb,
    header: getHeader,
    headerMode: 'screen',
    ...TransitionPresets.SlideFromRightIOS,
  };
};
const getHeader = ({ options }) => {
  return (
    <Header
      onBackButtonPress={options?.onBackButtonPress}
      headerLeft={options?.headerLeft || (() => <DrawerButton />)}
      title={options?.headerTitle}
      headerRight={options?.headerRight}
      withShadow={options?.withShadow}
    />
  );
};

export default HomeStack;
