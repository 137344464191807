import React from 'react';
import styled, { css } from 'styled-components/native';
import useI18n from '../../hooks/useI18n';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import { showBottomPanel } from '../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';
import { useDispatch } from 'react-redux';

const LanguageBar: React.FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useI18n();

  const handleOpenModal = () => {
    dispatch(
      showBottomPanel({
        componentName: EBottomPanelComponentName.LanguageSelectMenu,
      }),
    );
  };

  return (
    <S.ActiveLanguageButton onPress={handleOpenModal}>
      <S.ActiveLanguageButtonText>{i18n.language}</S.ActiveLanguageButtonText>
      <S.ActiveLanguageButtonTriangle />
    </S.ActiveLanguageButton>
  );
};

const ActiveLanguageButton = styled.TouchableOpacity(
  ({ theme }) => css`
    flex-direction: row;
    height: ${calcHeight(28)}px;
    width: ${calcHeight(28)}px;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: ${calcWidth(21)}px;
    margin-left: ${calcWidth(21)}px;
    border: 1px solid ${theme.colors.gray11};
    border-radius: 28px;
    background-color: ${theme.colors.lightGray24};
  `,
);

const ActiveLanguageButtonText = styled.Text(
  ({ theme }) => css`
    color: ${theme.colors.darkGray11};
    font-size: ${theme.fontSizes.s10};
    text-transform: uppercase;
    margin-right: ${calcWidth(2)}px;
  `,
);

const ActiveLanguageButtonTriangle = styled.View`
  width: 0;
  height: 0;
  border-style: solid;
  border-left-width: ${calcWidth(3)}px;
  border-right-width: ${calcWidth(3)}px;
  border-top-width: ${calcHeight(4)}px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: ${({ theme }) => theme.colors.darkGray11};
  background-color: transparent;
`;

const S = {
  ActiveLanguageButton,
  ActiveLanguageButtonText,
  ActiveLanguageButtonTriangle,
};

export default LanguageBar;
