import React, { FC, useRef, useEffect } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets, EdgeInsets } from 'react-native-safe-area-context';
import { BlueHeader as BaseBlueHeader } from '../../../components/layout';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { Settings, AboutPlaylist } from './components';
import ContinueButton from '../../../components/shared/buttons/ContinueButton';
import useKeyboardHeight from '../../../hooks/useKeyboardHeight';
import useLimitInputLetters from '../../../hooks/useLimitInputLetters';
import {
  setPlaylistDescription,
  postPlaylist,
  resetCreatePlaylistState,
} from '../../../store/createPlaylist/createPlaylist.actions';
import {
  playlistDescriptionSelector,
  createPlaylistSelector,
} from '../../../store/createPlaylist/createPlaylist.selectors';
import { IStackNavigation } from '../../../navigation/types';
import Routes from '../../../navigation/routes';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';

interface IProps extends IStackNavigation {}

const textBoxDefaultHeight = calcHeight(327);
const textBoxMaxPossibleHeight = calcHeight(680 - 15);

const PlaylistSettings: FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();
  const { keyboardHeight } = useKeyboardHeight();
  const playlistDescription = useSelector(playlistDescriptionSelector);
  const limitedInputValues = useLimitInputLetters(140, playlistDescription);
  const { editedPlaylist } = useSelector(createPlaylistSelector);

  const keyboardExpanded = keyboardHeight > 0;
  const scrollViewRef = useRef<ScrollView>(null);
  const textBoxHeight = useSharedValue(textBoxDefaultHeight);

  useEffect(() => {
    if (keyboardExpanded) {
      textBoxHeight.value = textBoxMaxPossibleHeight - keyboardHeight;
      scrollViewRef?.current?.scrollTo({ x: 0, y: 0, animated: true });
      return;
    }
    textBoxHeight.value = textBoxDefaultHeight;
  }, [keyboardExpanded, keyboardHeight, textBoxHeight]);

  const { isPosting, postOrUpdateSuccess, editMode } = useSelector(createPlaylistSelector);

  useEffect(() => {
    if (postOrUpdateSuccess) {
      navigation.navigate(Routes.CreatePlaylistStack.PlaylistCreated);
      dispatch(resetCreatePlaylistState());
    }
  }, [dispatch, navigation, postOrUpdateSuccess]);

  const animatedStyle = useAnimatedStyle(() => ({
    height: withTiming(textBoxHeight.value, {
      duration: 100,
      easing: Easing.linear,
    }),
  }));

  return (
    <S.Container
      insets={insets}
      bounces={false}
      scrollEnabled={!keyboardExpanded}
      showsVerticalScrollIndicator={false}
      // TODO: mistyping in styled-components regarding react-native
      // @ts-ignore
      ref={scrollViewRef}
    >
      <S.BlueHeader keyboardExpanded={keyboardExpanded}>
        <Animated.View style={[styles.textBox, animatedStyle]}>
          <AboutPlaylist {...limitedInputValues} />
        </Animated.View>
      </S.BlueHeader>

      <S.ContentWrapper>
        <Settings />
        <ContinueButton
          content={t(`common.${editMode ? 'save' : 'done'}`)}
          isLoading={isPosting}
          disabled={isPosting}
          onPress={() => {
            if (limitedInputValues.value) {
              dispatch(
                trackEvent({
                  event: 'playlist_add_description',
                  props: {
                    playlist_id: editedPlaylist?.id,
                  },
                }),
              );
            }
            dispatch(setPlaylistDescription(limitedInputValues.value));
            editMode ? navigation.goBack() : dispatch(postPlaylist());
          }}
        />
      </S.ContentWrapper>
    </S.Container>
  );
};

const Container = styled.ScrollView<{ insets: EdgeInsets }>`
  margin-bottom: ${({ insets }) => calcHeight(-196 + insets.bottom + 5)}px;
  flex: 1;
`;

const BlueHeader = styled(BaseBlueHeader)<{ keyboardExpanded: boolean }>`
  height: ${({ keyboardExpanded }) => (keyboardExpanded ? calcHeight(716) : calcHeight(393))}px;
  padding-top: ${calcHeight(36)}px;
  padding-right: ${calcWidth(30)}px;
  padding-left: ${calcWidth(30)}px;
`;

const ContentWrapper = styled.View`
  width: 100%;
  padding-right: ${calcWidth(30)}px;
  padding-left: ${calcWidth(30)}px;
`;

const S = { Container, BlueHeader, ContentWrapper };

const styles = StyleSheet.create({
  textBox: {
    backgroundColor: '#0900FF',
    width: calcWidth(315),
    borderRadius: 25,
    overflow: 'hidden',
  },
});

export default PlaylistSettings;
