import { takeLatest, all, put, call, select, takeEvery, spawn, delay } from 'redux-saga/effects';
import Types, { IClonePlaylistAction, IPlaylist, IUploadPlaylistCover } from './playlist.types';
import * as calls from '../api/bites-api/calls/playlist.calls';
import { setQuizSetting, setPlaylists, setPlaylistInProgress } from './playlist.actions';
import { playlistInProgressSelector, playlistSelector } from './playlist.selectors';
import { createPlaylistSelector } from '../createPlaylist/createPlaylist.selectors';
import { formatQuizSettings } from '../../utils/formatDataToServer';
import { loadBiteHelperConfigs, logError, trackEvent } from '../appActivity/appActivity.slice';
import uploadCover from '../../utils/uploadImage/uploadImage';
import { isWeb } from '../../utils/dimensions';
import { v4 as getUUID } from 'uuid';
import { clonePlaylist, updatePlaylist, cloneQuiz } from '../api/bites-api/calls/playlist.calls';
import { updateEditedPlaylist } from '../createPlaylist/createPlaylist.actions';
import { EInProgressStatus, EInProgressTask, IItemInProgressSagaPayload } from '../../types/common';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import store from '..';
import { activeOrganizationSelector, userSelector } from '../auth/auth.selectors';
import { setHomeActiveTab } from '../homeScreen/homeScreen.slice';
import getOrganizations from '../../utils/getOrganizations';
import { navigate } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import { Tabs } from '../homeScreen/homeScreen.types';
import { loadNextPageSaga } from '../feed/feed.saga';
import withRetry from '../../utils/withRetry';
import { PayloadAction } from '@reduxjs/toolkit';

export function* fetchFullPlaylistsSaga({ payload: playlistsIds }: Partial<PayloadAction<number[]>>) {
  // get list with statistics
  const promises = playlistsIds.map(async (playlistsId) => {
    try {
      const result = await calls.fetchPlaylist(playlistsId);
      return result;
    } catch (error) {
      store.dispatch(
        logError({
          event: 'fetchFullPlaylistsSaga: error',
          error,
        }),
      );
    }
  });
  let results = yield Promise.all(promises);
  const playlists = results.filter((_) => _).map(({ data }) => data as IPlaylist);

  yield put(setPlaylists(playlists));
}

function* deletePlaylistSaga({ payload }: PayloadAction<number>) {
  yield put(
    trackEvent({
      event: 'delete_playlist_creation',
    }),
  );
  try {
    yield call(calls.deletePlaylist, payload);
  } catch (err) {
    console.log('ERROR deletePlaylistSaga', err);

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* fetchQuizSettings({ payload: id }: PayloadAction<number>) {
  try {
    // @ts-ignore
    const res = yield calls.fetchQuizSettings(id);
    yield put(setQuizSetting(res.data));
  } catch (error) {
    yield put(
      logError({
        event: 'fetchQuizSettings: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* postQuizSettings() {
  try {
    const { quizSettings, hasImageBackground } = yield select(playlistSelector);
    const { editedPlaylist } = yield select(createPlaylistSelector);
    const body = {
      id: editedPlaylist?.id,
      quizSettings: formatQuizSettings(quizSettings, hasImageBackground),
    };
    // @ts-ignore
    yield call(calls.postQuizSettings, body);
  } catch (err) {
    console.log('ERROR update Quiz settings', err);

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* patchPlaylist({
  payload: { playlistId, playlist },
}: PayloadAction<{ playlistId: number; playlist: IPlaylist }>) {
  try {
    const result = yield calls.updatePlaylist(playlistId, playlist);
    yield put(setPlaylists([result.data]));
  } catch (error) {
    yield put(
      logError({
        event: 'patchPlaylist: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* setPlaylistInProgressSaga(payload: IItemInProgressSagaPayload) {
  const taskId = payload.taskId;
  let { overwriteTaskId, withResetTimeout, ...taskDetails } = payload;

  if (!overwriteTaskId) {
    const { taskId: currentTaskId } = yield select(playlistInProgressSelector(payload.itemId, payload.task));
    if (currentTaskId !== taskId) {
      return false;
    }
  }

  yield put(setPlaylistInProgress(taskDetails));

  if (withResetTimeout) {
    yield spawn(resetPlaylistInProgressSaga, { withResetTimeout, ...taskDetails });
  }

  return true;
}

function* resetPlaylistInProgressSaga(payload: IItemInProgressSagaPayload) {
  const taskId = payload.taskId;
  let { withResetTimeout, ...taskDetails } = payload;

  withResetTimeout = withResetTimeout === true ? 5000 : (withResetTimeout as number);

  yield delay(withResetTimeout);

  const { taskId: currentTaskId } = yield select(playlistInProgressSelector(taskDetails.itemId, taskDetails.task));
  if (currentTaskId !== taskId) {
    return false;
  }

  yield put(
    setPlaylistInProgress({
      itemId: taskDetails.itemId,
      task: taskDetails.task,
      taskId: taskDetails.taskId,
      status: null,
    }),
  );
}

function* updatePlaylistCoverSaga({ payload: { playlistId, uri } }: Partial<PayloadAction<IUploadPlaylistCover>>) {
  const taskDetails = {
    itemId: playlistId,
    task: EInProgressTask.COVER,
    taskId: getUUID(),
  };
  try {
    yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    yield updatePlaylist(playlistId, {
      linked_cover_url: uri,
    });

    const isSameTask = yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });

    if (!isSameTask) {
      return;
    }

    yield put(
      setPlaylists([
        {
          id: playlistId,
          linked_cover_url: uri,
          cover_url: uri,
        },
      ]),
    );

    const { editedPlaylist } = yield select(createPlaylistSelector);
    if (playlistId === editedPlaylist?.id) {
      yield put(
        updateEditedPlaylist({
          linked_cover_url: uri,
          cover_url: uri,
        }),
      );
    }
  } catch (error) {
    yield put(
      logError({
        event: 'updatePlaylistCoverSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
  }
}

function* uploadPlaylistCoverImageSaga({
  payload: { playlistId, uri, type = '' },
}: PayloadAction<IUploadPlaylistCover>) {
  const taskDetails = {
    itemId: playlistId,
    task: EInProgressTask.COVER,
    taskId: getUUID(),
  };
  try {
    yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    const {
      data: { id, image },
    } = yield uploadCover(
      isWeb
        ? {
            file: uri,
            path: 'upload_cover',
          }
        : {
            uri: uri,
            type,
            path: 'upload_cover',
          },
    );

    const isBeforeUpdateSameTask = yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
    });

    if (!isBeforeUpdateSameTask) {
      return false;
    }

    yield updatePlaylist(playlistId, {
      cover: id,
      linked_cover_url: image,
    });

    const isSameTask = yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });

    if (!isSameTask) {
      return;
    }

    yield put(
      setPlaylists([
        {
          id: playlistId,
          cover_url: image,
          linked_cover_url: image,
        },
      ]),
    );
    const { editedPlaylist } = yield select(createPlaylistSelector);
    if (playlistId === editedPlaylist.id) {
      yield put(
        updateEditedPlaylist({
          linked_cover_url: image,
          cover_url: image,
        }),
      );
    }
  } catch (error) {
    yield put(
      logError({
        event: 'uploadPlaylistCoverImageSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
  }
}

function* clonePlaylistRequestSaga({ payload: { playlist, org } }: PayloadAction<IClonePlaylistAction>) {
  yield put(loadBiteHelperConfigs());
  const itemId = playlist.id;
  const taskDetails = {
    itemId,
    task: EInProgressTask.CLONE,
    taskId: getUUID(),
  };

  try {
    yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.IN_PROGRESS,
      overwriteTaskId: true,
    });

    const user = yield select(userSelector);
    const activeOrg = yield select(activeOrganizationSelector);

    const isCurrentWorkspace = org.id === activeOrg.id;
    const creatorOrganizations = getOrganizations(user, { isCreator: true });

    const commonParams = {
      organization: org.id,
      owner: user.id,
    };

    const { data } = yield playlist.is_quiz
      ? cloneQuiz({
          ...commonParams,
          quiz: itemId,
        })
      : clonePlaylist({
          ...commonParams,
          playlist: itemId,
        });

    const isSameTask = yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.SUCCESS,
      withResetTimeout: true,
    });
    if (!isSameTask) {
      return;
    }

    yield put(
      trackEvent({
        event: 'clone',
        props: { playlist_id: data.clone_id, inspiration_bite: false },
      }),
    );

    if (creatorOrganizations.length === 1) {
      navigate(Routes.HomeStack.StackName, {
        screen: Routes.HomeStack.Home,
      });
    }

    yield spawn(loadNextPageSaga, { payload: { reset: true } });
    yield put(setHomeActiveTab(Tabs.FEED));

    Toast.show({
      type: EToastTypes.copySuccess,
      props: {
        biteName: playlist?.subject,
        workspaceName: !isCurrentWorkspace && org?.name,
      },
    });
  } catch (error) {
    const isSameTask = yield setPlaylistInProgressSaga({
      ...taskDetails,
      status: EInProgressStatus.ERROR,
      withResetTimeout: true,
    });
    if (!isSameTask) {
      return;
    }

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
    yield put(
      logError({
        event: 'clonePlaylistRequestSaga: error',
        error,
      }),
    );
  }
}

function* triggerCreatedPlaylistTransactionalCommunicationSaga({
  payload: { playlistId },
}: PayloadAction<{ playlistId: number }>) {
  try {
    yield withRetry(() => calls.transactionalPlaylistCreated({ playlistId }), {
      errorContext: {
        action: 'triggerCreatedPlaylistTransactionalCommunicationSaga',
      },
    });
  } catch (error) {
    yield put(
      logError({
        event: 'triggerCreatedPlaylistTransactionalCommunicationSaga: error',
        data: error,
      }),
    );
  }
}

function* triggerCreatedQuizTransactionalCommunicationSaga({ payload: { quizId } }: PayloadAction<{ quizId: number }>) {
  try {
    yield withRetry(() => calls.transactionalQuizCreated({ quizId }), {
      errorContext: {
        action: 'triggerCreatedQuizTransactionalCommunicationSaga',
      },
    });
  } catch (error) {
    yield put(
      logError({
        event: 'triggerCreatedQuizTransactionalCommunicationSaga: error',
        data: error,
      }),
    );
  }
}

export default function* playlistSagaWatcher() {
  yield all([
    takeEvery(Types.UPDATE_PLAYLIST_COVER, updatePlaylistCoverSaga),
    takeEvery(Types.UPLOAD_PLAYLIST_COVER_IMAGE, uploadPlaylistCoverImageSaga),
    takeLatest(Types.DELETE_PLAYLIST, deletePlaylistSaga),
    takeLatest(Types.FETCH_QUIZ_SETTINGS, fetchQuizSettings),
    takeLatest(Types.POST_QUIZ_SETTINGS, postQuizSettings),
    takeLatest(Types.PATCH_PLAYLIST, patchPlaylist),
    takeLatest(Types.FETCH_FULL_PLAYLISTS, fetchFullPlaylistsSaga),
    takeLatest(Types.CLONE_PLAYLIST, clonePlaylistRequestSaga),
    takeLatest(
      Types.TRIGGER_CREATED_PLAYLIST_TRANSACTIONAL_COMMUNICATION,
      triggerCreatedPlaylistTransactionalCommunicationSaga,
    ),
    takeLatest(
      Types.TRIGGER_CREATED_QUIZ_TRANSACTIONAL_COMMUNICATION,
      triggerCreatedQuizTransactionalCommunicationSaga,
    ),
  ]);
}
