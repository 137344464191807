import { useSelector } from 'react-redux';
import {
  answersAttributesAbsoluteMapSelector,
  answersAttributesPercentageMapSelector,
  displayModeSelector,
} from '../../../store/attributes/attributes.selector';
import { useMemo } from 'react';

const useAnsweredAttributesMap = () => {
  const displayMode = useSelector(displayModeSelector);
  const answeredAttributesAbsoluteMap = useSelector(answersAttributesAbsoluteMapSelector);
  const answeredAttributesPercentageMap = useSelector(answersAttributesPercentageMapSelector);

  const attributesMap = useMemo(
    () => (displayMode === 'absolute' ? answeredAttributesAbsoluteMap : answeredAttributesPercentageMap),
    [displayMode, answeredAttributesAbsoluteMap, answeredAttributesPercentageMap],
  );

  return {
    attributesMap,
  };
};

export default useAnsweredAttributesMap;
