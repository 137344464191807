import { useDispatch, useSelector } from 'react-redux';
import {
  commentsFilterSelector,
  commentsDataSelector,
  commentsLoadingSelector,
  commentsErrorSelector,
  commentsNextSelector,
  currentListSelector,
  isNeedToScrollUpCommentsSelector,
} from '../../../store/analytics/analytics.selector';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import ListItemComponent from '../common/ListItem/ListItem';
import { getCommentsData, setCommentsData, setCommentsFilter } from '../../../store/analytics/analytics.slice';
import Filter from '../common/Filter';
import { CommentsIcons } from '../common/Icons';
import List from '../common/List';
import {
  ECommentedFilterValue,
  ECurrentList,
  IAnalyticsCommentsListItem,
} from '../../../store/analytics/analytics.types';
import { useIsFocused } from '@react-navigation/native';
import HeaderTabs from '../common/HeaderTabs';
import { log } from '../../../store/appActivity/appActivity.slice';
import useHasAttributesToRender from '../hooks/useHasAttributesToRender';
import useCommentsAttributesMap from '../hooks/useCommentsAttributesMap';
import useHasOrganizationAttributes from '../hooks/useHasOrganizationAttributes';
import { IStackNavigation } from '../../../navigation/types';
import useAttributesData from '../hooks/useAttributesData';
import { getCommentsAttributes } from '../../../store/attributes/attributes.slice';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import { COMMENTS_ATTRIBUTES_SCREEN, COMMENTS_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../hooks/useHeaderTabsDivider';
import { analyticsListDataSet } from '../analytics.constants';

const COMMENTS_FILTERS = [
  {
    label: 'analytics.filter.all',
    value: ECommentedFilterValue.ALL,
  },
  {
    label: 'analytics.filter.commented',
    value: ECommentedFilterValue.COMMENTED,
  },
  {
    label: 'analytics.filter.notCommented',
    value: ECommentedFilterValue.NOT_COMMENTED,
  },
];

const CommentsList: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();

  const isFocused = useIsFocused();
  const isLoading = useSelector(commentsLoadingSelector);
  const commentsList = useSelector(commentsDataSelector);
  const currentFilter = useSelector(commentsFilterSelector);
  const commentsError = useSelector(commentsErrorSelector);
  const commentsNext = useSelector(commentsNextSelector);
  const currentList = useSelector(currentListSelector);
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();
  const isNeedToScrollUp = useSelector(isNeedToScrollUpCommentsSelector);

  const { attributesMap } = useCommentsAttributesMap();
  const { hasAttributesToRender } = useHasAttributesToRender({ attributesMap });
  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useAttributesData({
    attributes: attributesMap,
    onLoad: getCommentsAttributes,
  });

  const renderListItem = useCallback(
    ({ item, index }) => {
      const withDivider = index > 0 && !item.activity && !!commentsList[index - 1]?.activity;
      return <ListItem item={item} withDivider={withDivider} />;
    },
    [commentsList],
  );

  const handleFilterChange = useCallback(
    (filter) => {
      dispatch(
        log({
          event: 'CommentsList.handleFilterChange',
          data: { filter },
        }),
      );

      dispatch(setCommentsData({ results: [], next: null, reset: true }));
      dispatch(setCommentsFilter(filter));
      dispatch(getCommentsData());
    },
    [dispatch],
  );
  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'CommentsList.handleEndReached',
        data: { commentsNext, isLoading, commentsError },
      }),
    );

    dispatch(getCommentsData());
  }, [commentsNext, isLoading, commentsError, dispatch]);

  const handleEndReachedProp = useMemo(() => {
    if (!commentsNext || isLoading || commentsError) {
      return;
    }

    return handleEndReached;
  }, [commentsError, commentsNext, handleEndReached, isLoading]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'CommentsList.handleErrorRefresh',
      }),
    );
    dispatch(getCommentsData());
  }, [dispatch]);

  const handleRefresh = useCallback(
    ({ isPullToRefresh, callback }) => {
      dispatch(
        log({
          event: 'CommentsList.handleRefresh',
        }),
      );

      dispatch(getCommentsData({ reset: true, clearAnalyticsCache: isPullToRefresh, callback }));
    },
    [dispatch],
  );

  const switchedToFocusRef = useRef(isFocused);

  useEffect(() => {
    const switchedToFocus = isFocused && !switchedToFocusRef.current;
    switchedToFocusRef.current = isFocused;

    if (isLoading || !isFocused || (commentsList !== null && (!commentsError || !switchedToFocus))) {
      return;
    }

    dispatch(getCommentsData({ reset: !commentsError }));
  }, [commentsError, commentsList, dispatch, isFocused, isLoading]);

  useEffect(() => {
    if (currentList === ECurrentList.ATTRIBUTES && hasOrganizationAttributes) {
      navigation.replace(COMMENTS_ATTRIBUTES_SCREEN);
    }
  }, [currentList, hasOrganizationAttributes, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={COMMENTS_LIST_SCREEN}
        attributesScreen={COMMENTS_ATTRIBUTES_SCREEN}
        listScreen={COMMENTS_LIST_SCREEN}
        rightComponent={
          <Filter currentFilter={currentFilter} filters={COMMENTS_FILTERS} onChangeFilter={handleFilterChange} />
        }
        disableAttributes={!hasAttributesToRender}
        isShowBottomDivider={isShowHeaderBottomDivider}
        isComments
      />
      <List
        dataSet={analyticsListDataSet}
        data={commentsList}
        renderItem={renderListItem}
        onEndReached={handleEndReachedProp}
        onErrorRefresh={handleErrorRefresh}
        isLoading={isLoading}
        onRefresh={handleRefresh}
        error={commentsError}
        listType={EAnalyticsScreenTabs.COMMENTS}
        isNeedToScrollUp={isNeedToScrollUp}
        scrollEventThrottle={10}
        onScroll={handleScroll}
      />
    </>
  );
};

interface IListItemProps {
  item: IAnalyticsCommentsListItem;
  withDivider?: boolean;
}

const ListItem: React.FC<IListItemProps> = ({ item, withDivider }) => {
  const { activity, user } = item;

  const renderIcons = useCallback(() => {
    return <CommentsIcons activity={activity} />;
  }, [activity]);

  return (
    <ListItemComponent
      lastActivity={activity?.comment_date || activity?.last_interaction_date}
      user={user}
      renderIcons={renderIcons}
      withDivider={withDivider}
    />
  );
};

export default memo(CommentsList);
