import { useNetInfo } from '@react-native-community/netinfo';
import React, { useEffect, useRef } from 'react';
import Modal from '../ModalController';
import styled from 'styled-components/native';
import { calcHeight } from '../../../utils/dimensions';
import OfflineIcon from '../../../assets/icons/offline.svg';
import { StatusBar } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { log } from '../../../store/appActivity/appActivity.slice';

const ConnectionLostModal: React.FC = () => {
  const { t } = useTranslation();
  const { isConnected } = useNetInfo();
  const dispatch = useDispatch();
  const isConnectedRef = useRef(null);

  useEffect(() => {
    if (isConnected) {
      // when going offline can't send the request
      // sending when back online
      if (isConnectedRef.current === false) {
        dispatch(
          log({
            event: 'internet_connection',
            state: 'not_connected',
          }),
        );
      }

      dispatch(
        log({
          event: 'internet_connection',
          state: 'is_connected',
        }),
      );
    }

    isConnectedRef.current = isConnected;
  }, [dispatch, isConnected]);

  if (isConnected) {
    return null;
  }

  return (
    <Modal isVisible statusBarTranslucent animationOut={'fadeOut'} animationIn={'fadeIn'} backdropOpacity={0.3}>
      <S.Container>
        <S.Content>
          <OfflineIcon />
          <S.Title>{t('connectionLostModal.title')}</S.Title>
          <S.Info>{t('connectionLostModal.description')}</S.Info>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    position: absolute;
    top: 0;
    width: 100%;
    height: ${calcHeight(152)}px;
    background: ${({ theme }) => theme.colors.transparentPink};
    justify-content: center;
    vertical-align: center;
    padding-top: ${StatusBar.currentHeight ? StatusBar.currentHeight : calcHeight(30)}px;
  `,
  Content: styled.View`
    align-items: center;
  `,
  Title: styled.Text`
    margin-top: ${calcHeight(7)}px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s14 * 1.07};
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  `,
  Info: styled.Text`
    margin-top: ${calcHeight(9)}px;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14 * 1.21}px;
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default ConnectionLostModal;
