import React, { FC, useEffect } from 'react';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { DefaultTheme, useTheme } from 'styled-components/native';

import NameYourQuiz from '../../../screens/createQuiz/NameYourQuiz';
import QuizTellYourStory from '../../../screens/createQuiz/QuizTellYourStory';
import QuizSettings from '../../../screens/createQuiz/QuizSettings';
import { useIsFocused } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeOrganizationSelector,
  isContentCreatorSelector,
  leadNameSelector,
} from '../../../store/auth/auth.selectors';
import Routes from '../../routes';
import { setCurrentFlow, trackEvent } from '../../../store/appActivity/appActivity.slice';
import Header from '../../../components/Header';
import { activateKeepAwake, deactivateKeepAwake } from '@sayem314/react-native-keep-awake';
import { EAppFlow } from '../../../store/appActivity/appActivity.types';
import { currentFlowSelector } from '../../../store/appActivity/appActivity.selectors';
import GoogleSlides from '../../../screens/googleSlides/GoogleSlides';
import { IStackNavigation } from '../../types';
import { isWeb } from '../../../utils/dimensions';

const Stack = createStackNavigator();
interface IProps extends IStackNavigation {}

const CreateQuizStack: FC<IProps> = ({ navigation }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { routes } = navigation.getState();
  const isContentCreator = useSelector(isContentCreatorSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);
  const leadName = useSelector(leadNameSelector);
  const isFocused = useIsFocused();

  const currentFlow = useSelector(currentFlowSelector);

  useEffect(() => {
    if (!isFocused) {
      if (currentFlow?.flow === EAppFlow.QUIZ_CREATION) {
        dispatch(setCurrentFlow(null));
        deactivateKeepAwake();
      }
      return;
    }

    dispatch(
      setCurrentFlow({
        flow: EAppFlow.QUIZ_CREATION,
      }),
    );
    activateKeepAwake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused]);

  useEffect(() => {
    try {
      if (routes[1]?.state === undefined) {
        dispatch(
          trackEvent({
            event: 'pageview',
            props: { page_title: Routes.CreateQuizStack.NameYourQuiz },
          }),
        );
      } else {
        const screens = routes[1]?.state?.routes;
        dispatch(
          trackEvent({
            event: 'pageview',
            props: { page_title: screens[screens.length - 1]?.name },
          }),
        );
      }
    } catch (err) {
      dispatch(
        trackEvent({
          event: 'pageview_error',
          props: { error: err },
        }),
      );
    }
  }, [activeOrganization.id, dispatch, leadName, routes]);

  useEffect(() => {
    if (!isContentCreator) {
      navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
    }
  }, [isContentCreator, navigation]);

  if (!isContentCreator) {
    return null;
  }

  return (
    <Stack.Navigator screenOptions={() => getScreenOptions(theme)}>
      <Stack.Screen name={Routes.CreateQuizStack.NameYourQuiz} component={NameYourQuiz} options={commonScreenOptions} />
      <Stack.Screen
        name={Routes.CreateQuizStack.QuizTellYourStory}
        component={QuizTellYourStory}
        options={commonScreenOptions}
      />
      <Stack.Screen name={Routes.CreateQuizStack.QuizSettings} component={QuizSettings} options={commonScreenOptions} />
      <Stack.Screen
        name={Routes.CreateQuizStack.BiteGoogleSlides}
        component={GoogleSlides}
        options={withoutHeaderScreenOptions}
      />
    </Stack.Navigator>
  );
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    headerTitleStyle: {
      fontFamily: theme.fontFamilies.TTNormsMedium,
      fontSize: theme.fontSizes.s16,
      color: theme.colors.white,
      textTransform: 'uppercase',
    },
    headerTitleAlign: 'center' as 'center',
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },

    headerStyle: {
      backgroundColor: theme.colors.primaryBlue,
      shadowColor: 'transparent',
      elevation: 0,
    },
    animationEnabled: !isWeb,
    headerMode: 'screen',
    ...TransitionPresets.SlideFromRightIOS,
    header: getHeader,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

const commonScreenOptions = { header: getHeader };
const withoutHeaderScreenOptions = { headerShown: false };

export default CreateQuizStack;
