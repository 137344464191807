import React, { memo, useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import CommentsAttributes from './CommentsAttributes';
import CommentsList from './CommentsList';
import { IStackNavigation } from '../../../navigation/types';
import useHasOrganizationAttributes from '../hooks/useHasOrganizationAttributes';
import { useDispatch, useSelector } from 'react-redux';
import { currentListSelector } from '../../../store/analytics/analytics.selector';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { COMMENTS_ATTRIBUTES_SCREEN, COMMENTS_LIST_SCREEN, STATS_COMMENTS_SCREEN } from '../constants';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useIsFocused } from '@react-navigation/native';

const Stack = createStackNavigator();

const defaultScreenOptions = {
  headerShown: false,
  cardStyle: {
    backgroundColor: 'white',
  },
  animationEnabled: false,
};
interface IProps extends IStackNavigation {}
const Comments: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();

  const currentList = useSelector(currentListSelector);
  const initialRouteName =
    currentList === ECurrentList.USERS || !hasOrganizationAttributes
      ? COMMENTS_LIST_SCREEN
      : COMMENTS_ATTRIBUTES_SCREEN;

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: STATS_COMMENTS_SCREEN },
      }),
    );
  }, [dispatch, isFocused]);

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {hasOrganizationAttributes && (
        <Stack.Screen name={COMMENTS_ATTRIBUTES_SCREEN} component={CommentsAttributes} options={defaultScreenOptions} />
      )}
      <Stack.Screen name={COMMENTS_LIST_SCREEN} component={CommentsList} options={defaultScreenOptions} />
    </Stack.Navigator>
  );
};

export default memo(Comments);
