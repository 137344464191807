import { useEffect } from 'react';

export const useOnWindowResize = (fn: () => void) => {
  // call fn when window is resized
  useEffect(() => {
    window.addEventListener('resize', fn);

    return () => {
      window.removeEventListener('resize', fn);
    };
  }, [fn]);
};
