import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { useSelector, useDispatch } from 'react-redux';
import useMedia, { formatFileTypesForWeb } from '../../../../hooks/useMedia';
import { KeyboardAwareView } from '../../../../components/shared';
import { setBiteCoverFromUrl } from '../../../../store/createBite/createBites.actions';
import { createBiteSelector, questionSuggestionSelector } from '../../../../store/createBite/createBite.selectors';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import MediaModal from '../../../creationBite/common/MediaModal';
import QuestionSticker from '../../../creationBite/common/QuestionSticker';
import {
  questionMediaUploadingError,
  setChoices,
  setQuestionContent,
  setQuestionMedia,
  setQuestionMediaDoneUploading,
} from '../../../../store/biteQuestion/biteQuestion.actions';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useTranslation } from 'react-i18next';
import BiteAnswerInput from '../../BiteAnswerInput/BiteAnswerInput';
import { I18nManager, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import useKeyboardHeight from '../../../../hooks/useKeyboardHeight';
import WebScrollingView from '../../../creationBite/common/WebScrollingView';
import Tooltip from 'react-native-walkthrough-tooltip';
import useQuestion from '../useQuestion';
import { keyboardDismiss } from '../../../../utils/general';
import { IS_ANDROID } from '../../../../utils/constants/env';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import Button, { EIcon } from '../../../../components/shared/buttons/Button/Button';
import SuggestionHeader from '../../SuggestionHeader';
import { addAnswerBtnDataSet, questionContinueBtnDataSet } from '../question.constants';

interface IProps {
  onContinue: (localInputValue?: string) => void;
  isEditMode?: boolean;
  isLoading?: boolean;
  from: string;
}

const AddMultiChoiceQuestion: React.FC<IProps> = ({ isLoading, onContinue, isEditMode, from }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const questionSuggestion = useSelector(questionSuggestionSelector);

  const uploadImageRef = useRef<() => Promise<any>>();

  const {
    localInputValue,
    setLocalInputValue,
    localMedia,
    setLocalMedia,
    localAnswers,
    setLocalAnswers,
    handleChangeAnswer,
    handleAddAnswer,
    handleDeleteAnswer,
    handleSelectCorrectAnswer,
  } = useQuestion({ from, questionSuggestion: questionSuggestion?.multiChoice });

  const [isMediaModalVisible, setMediaModalVisible] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const { introMedia, cover_url } = useSelector(createBiteSelector);
  const { keyboardHeight } = useKeyboardHeight();

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const handleUploadImage = async (uri: string, type?: string, editedImageUri?: string) => {
    handleCloseMediaModal();

    const uploadImage = uploadImageRef.current;

    if (!editedImageUri && typeof uploadImage !== 'function') {
      return;
    }

    setImageLoading(true);

    try {
      const { media: _media } = await uploadImage();
      setLocalMedia(_media);
    } catch (error) {
      dispatch(questionMediaUploadingError());
    }

    setImageLoading(false);
    dispatch(setQuestionMediaDoneUploading());
  };

  const {
    mediaURI,
    launchImageLibrary,
    launchImageCamera,
    uploadImage,
    clearState,
    dropZoneUploadingForWeb,
    handleSelectOnlineMedia,
  } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    from: 'MultiChoiceQuestion',
    onMediaSelectionCB: handleUploadImage,
  });

  useEffect(() => {
    uploadImageRef.current = uploadImage;
  }, [uploadImage]);

  const isCorrectAnswerSelected = useMemo(() => localAnswers.some((answer) => answer.is_correct), [localAnswers]);

  const disabledContinueButton = useMemo(() => {
    const isLengthLessThanFive = localInputValue.length < 5;
    const isAnswersEmpty = localAnswers.filter((answer) => !answer.text).length > 0;

    return isLengthLessThanFive || isAnswersEmpty || !isCorrectAnswerSelected;
  }, [localAnswers, isCorrectAnswerSelected, localInputValue.length]);

  const handleAddImage = useCallback(() => {
    if (isWeb) {
      launchImageLibrary();
      return;
    }
    setMediaModalVisible(true);
  }, [launchImageLibrary]);

  const handleDragEnd = useCallback(
    ({ data }) => {
      setLocalAnswers(data);
    },
    [setLocalAnswers],
  );

  const keyExtractor = useCallback((item) => `draggable-item-${item.id!}`, []);

  const handleContinue = useCallback(() => {
    dispatch(setChoices(localAnswers));
    dispatch(setQuestionContent(localInputValue));
    dispatch(setQuestionMedia(localMedia));

    if (!introMedia && !cover_url) {
      dispatch(setBiteCoverFromUrl(localMedia?.image_url!));
    }

    onContinue(localInputValue);
  }, [localAnswers, cover_url, dispatch, introMedia, localMedia, onContinue, localInputValue]);

  const renderAnswer = useCallback(
    ({ drag, item, getIndex }) => {
      const index = getIndex();
      const autoFocus = !!localInputValue && localAnswers.findIndex((localAnswer) => !localAnswer.text) === index;
      return (
        <BiteAnswerInput
          key={item.id}
          item={item}
          index={index}
          onSelect={handleSelectCorrectAnswer}
          onRemove={localAnswers.length > 2 && handleDeleteAnswer}
          autoFocus={autoFocus}
          onDrag={drag}
          color={theme.colors.blueSticky}
          onChangeText={handleChangeAnswer}
        />
      );
    },
    [
      handleChangeAnswer,
      handleDeleteAnswer,
      handleSelectCorrectAnswer,
      localAnswers,
      localInputValue,
      theme.colors.blueSticky,
    ],
  );

  const handleCloseTooltip = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  const handleOpenTooltip = useCallback(() => {
    if (isCorrectAnswerSelected) {
      return;
    }

    keyboardDismiss();
    setTooltipVisible(true);
  }, [isCorrectAnswerSelected]);

  const handleDeleteImage = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'delete_question_image',
        props: { question_type: 'MultiChoice', from },
      }),
    );

    setLocalMedia(null);
    clearState();
  }, [dispatch, from, setLocalMedia, clearState]);

  return (
    <S.Container>
      <S.KeyboardAvoidingView keyboardVerticalOffset={calcHeight(-22)} behavior={'padding'}>
        <S.KeyboardAwareView contentContainerStyle={S.ContentContainerStyles}>
          <WebScrollingView>
            {questionSuggestion?.multiChoice && <SuggestionHeader title={t('addQuestion.questionSuggestion.title')} />}
            <QuestionSticker
              text={localInputValue}
              onChangeText={setLocalInputValue}
              height={158}
              color={theme.colors.blueSticky}
              isImageLoading={isImageLoading}
              onAddImage={handleAddImage}
              mediaURI={mediaURI || localMedia?.image_url}
              onDeleteImage={handleDeleteImage}
            />
            <DraggableFlatList
              contentContainerStyle={S.DraggableFlatListContainer}
              keyboardShouldPersistTaps={'handled'}
              bounces={false}
              data={localAnswers}
              keyExtractor={keyExtractor}
              onDragEnd={handleDragEnd}
              autoscrollThreshold={0.8}
              autoscrollSpeed={100}
              renderItem={renderAnswer}
              scrollEnabled={false}
              activationDistance={20}
            />
          </WebScrollingView>
        </S.KeyboardAwareView>
        <S.ButtonContainer isKeyboardOpen={Boolean(keyboardHeight)}>
          <Tooltip
            disableShadow
            showChildInTooltip={false}
            backgroundColor='transparent'
            topAdjustment={IS_ANDROID ? -24 : undefined}
            contentStyle={{ backgroundColor: theme.colors.primaryBlue }}
            backgroundStyle={S.TooltipBackgroundStyles}
            arrowStyle={S.TooltipArrowStyles}
            tooltipStyle={S.TooltipStyles}
            isVisible={isTooltipVisible && keyboardHeight === 0}
            onClose={handleCloseTooltip}
            content={<S.TooltipText>{t('addQuestion.markCorrectAnswerTooltip')}</S.TooltipText>}
          >
            <TouchableOpacity onPress={handleOpenTooltip}>
              <S.ContinueButton
                onPress={handleContinue}
                width={122}
                isLoading={isLoading}
                disabled={disabledContinueButton}
                content={t(`common.${isEditMode ? 'Save' : 'Continue'}`)}
                // @ts-ignore
                dataSet={questionContinueBtnDataSet}
              />
            </TouchableOpacity>
          </Tooltip>

          {localAnswers.length < 5 && (
            <S.AddAnswerButton
              text={t('addQuestion.addAnswer')}
              onPress={handleAddAnswer}
              icon={EIcon.ADD}
              border={theme.colors.primaryBlue}
              fill={theme.colors.white}
              textColor={theme.colors.primaryBlue}
              dataSet={addAnswerBtnDataSet}
            />
          )}
        </S.ButtonContainer>
      </S.KeyboardAvoidingView>

      <MediaModal
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onSelectStockImage={handleSelectOnlineMedia}
        onClose={handleCloseMediaModal}
        isVisible={isMediaModalVisible}
        from='AddMultiChoiceQuestion'
      />
      {dropZoneUploadingForWeb}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    align-self: center;
    position: relative;
    padding-bottom: 20px;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ButtonContainer: styled.View<{ isKeyboardOpen?: boolean }>`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: ${isWeb ? 370 : deviceWidth}px;
    top: -${({ isKeyboardOpen }) => (!isWeb && isKeyboardOpen ? calcHeight(120) : 0)}px;
    background: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
  `,
  ContinueButton: styled(GenericButton)``,
  AddAnswerButton: styled(Button)`
    max-width: ${calcWidth(210)}px;
    height: ${calcHeight(42)}px;
    justify-content: center;
  `,
  KeyboardAvoidingView: styled.KeyboardAvoidingView`
    flex: 1;
    align-items: center;
  `,
  TooltipText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
  `,
  KeyboardAwareView: styled(KeyboardAwareView)``,
  DraggableFlatListContainer: {
    width: isWeb ? 370 : deviceWidth,
  } as StyleProp<ViewStyle>,
  ContentContainerStyles: {
    alignItems: 'center',
  } as StyleProp<ViewStyle>,
  TooltipStyles: {
    marginTop: 7,
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
  TooltipArrowStyles: {
    marginTop: -7,
  } as StyleProp<ViewStyle>,
  TooltipBackgroundStyles: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
};

export default AddMultiChoiceQuestion;
