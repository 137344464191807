import { IMarketingParams } from '../../../store/auth/auth.types';
import { getGaMarketingClientId } from '../../../services/gtm/track';

const getLoginMarketingParams = (): IMarketingParams => {
  return {
    ga_marketing_client_id: getGaMarketingClientId(),
  };
};

export default getLoginMarketingParams;
