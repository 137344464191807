import React, { memo, useEffect } from 'react';
import styled from 'styled-components/native';
import { IStackNavigation } from '../types';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import leftImage from '../../assets/images/login/auth-app-web-image.png';
import Routes from '../routes';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { LandingLeftContent } from '../../components/shared';
import Auth from '../../screens/auth/Auth';
import { resetAuthForm } from '../../store/authForm/authForm.slice';

interface IProps extends IStackNavigation {}

const AuthStackWeb: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: Routes.AuthStack.StackName },
      }),
    );

    return () => {
      dispatch(resetAuthForm());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <S.CenterContainer>
        <S.LeftTitle>
          <LandingLeftContent />
        </S.LeftTitle>
        <S.Left>
          <S.LeftImage source={leftImage} resizeMode='contain' />
        </S.Left>
        <S.Right>
          <S.AuthContainer>
            <Auth {...props} />
          </S.AuthContainer>
        </S.Right>
      </S.CenterContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView`
    padding: ${calcHeight(16)}px;
    background-color: white;
  `,
  Top: styled.View`
    top: ${calcHeight(50)}px;
  `,
  Logo: styled.Image`
    width: ${calcWidth(74)}px;
    height: ${calcHeight(36)}px;
    cursor: pointer;
  `,
  CenterContainer: styled.View`
    flex-direction: row;
  `,
  Left: styled.View`
    flex-grow: 1;
    flex-direction: row;
    position: absolute;
  `,
  LeftTitle: styled.View`
    margin-top: ${calcHeight(100)}px;
    z-index: 1;
  `,
  LeftImage: styled.Image`
    width: ${calcWidth(149)}px;
    height: ${calcHeight(250)}px;
    left: ${calcHeight(230)}px;
  `,
  Right: styled.View`
    flex-shrink: 0;
    padding-right: ${calcHeight(8)}px;
  `,
  AuthContainer: styled.View`
    width: ${calcHeight(400)}px;
    left: ${calcWidth(40)}px;
    border: 1px ${({ theme }) => theme.colors.lightGray1} solid;
    border-radius: 21px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 6px;
    overflow: hidden;
  `,
};

export default memo(AuthStackWeb);
