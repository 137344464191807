import { takeLatest, put, select, call, delay, takeLeading } from 'redux-saga/effects';

import {
  launchOnboardingQuestionnaire,
  loadOnboardingQuestionnaire,
  loadUserQuestionnaires,
  saveUserQuestionnaire,
  setQuestionnaire,
  setUserQuestionnaire,
  setUserQuestionnaires,
} from './questionnaire.slice';
import {
  IGetUserQuestionnairesResult,
  IQuestionnaire,
  ISaveUserQuestionnaireResult,
  IUserQuestionnaire,
} from './questionnaire.types';
import {
  createUserQuestionnaire,
  getOnboardingQuestionnaire,
  getUserQuestionnaires,
  updateUserQuestionnaire,
} from '../api/bites-api/calls/questionnaire.calls';
import { AxiosResponse } from 'axios';
import { logError } from '../appActivity/appActivity.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  questionnaireSelector,
  userQuestionnaireByQuestionnaireIdSelector,
  userQuestionnairesSelector,
} from './questionnaire.selectors';
import { displayAppModal } from '../appModals/appModals.slice';
import { EAppModalStackItemType } from '../appModals/appModals.types';
import { ONBOARDING_QUESTIONNAIRE_ID } from './questionnaire.constants';

function* loadUserQuestionnairesSaga() {
  try {
    const userQuestionnaires = yield select(userQuestionnairesSelector);
    if (userQuestionnaires) {
      return;
    }

    const { data }: AxiosResponse<IGetUserQuestionnairesResult> = yield call(getUserQuestionnaires);

    const newUserQuestionnaires = data.results.map(({ userQuestionnaire }) => userQuestionnaire);
    yield put(setUserQuestionnaires(newUserQuestionnaires));
  } catch (error) {
    yield put(
      logError({
        event: 'loadUserQuestionnairesSaga: error',
        error,
      }),
    );
  }
}

function* saveUserQuestionnaireSaga({ payload }: PayloadAction<IQuestionnaire['id']>) {
  try {
    yield delay(1000);

    const userQuestionnaire: IUserQuestionnaire = yield select(userQuestionnaireByQuestionnaireIdSelector(payload));

    const { data }: AxiosResponse<ISaveUserQuestionnaireResult> = yield !userQuestionnaire.id
      ? createUserQuestionnaire({ userQuestionnaire })
      : updateUserQuestionnaire(userQuestionnaire.id, { userQuestionnaire });

    yield put(setUserQuestionnaire(data.userQuestionnaire));
  } catch (error) {
    yield put(
      logError({
        event: 'saveUserQuestionnaireSaga: error',
        error,
      }),
    );
  }
}

function* loadOnboardingQuestionnaireSaga() {
  try {
    const existingOnboardingQuestionnaire = yield select(questionnaireSelector(ONBOARDING_QUESTIONNAIRE_ID));

    if (existingOnboardingQuestionnaire) {
      return;
    }

    const { data: onboardingQuestionnaire }: AxiosResponse<IQuestionnaire> = yield call(getOnboardingQuestionnaire);
    yield put(setQuestionnaire(onboardingQuestionnaire));
  } catch (error) {
    yield put(
      logError({
        event: 'loadOnboardingQuestionnaireSaga: error',
        error,
      }),
    );
  }
}

function* launchOnboardingQuestionnaireSaga() {
  yield put(loadOnboardingQuestionnaire());
  yield put(displayAppModal({ type: EAppModalStackItemType.ONBOARDING_QUESTIONNAIRE }));
}

export default function* appActivitySaga() {
  yield takeLeading(loadOnboardingQuestionnaire, loadOnboardingQuestionnaireSaga);
  yield takeLatest(loadUserQuestionnaires, loadUserQuestionnairesSaga);
  yield takeLatest(launchOnboardingQuestionnaire, launchOnboardingQuestionnaireSaga);
  yield takeLatest(saveUserQuestionnaire, saveUserQuestionnaireSaga);
}
