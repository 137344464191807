import Types from './biteQuestion.types';
import { IMedia } from '../../types/media';
import { EQuestionType, IBiteAnswer } from '../../types/biteQuestion';

export const setQuestionType = (questionType: EQuestionType) => ({
  type: Types.SET_QUESTION_TYPE,
  payload: questionType,
});

export const setQuestionContent = (text: string) => ({
  type: Types.SET_QUESTION_CONTENT,
  payload: text,
});

export const setQuestionMedia = (media: IMedia | null) => ({
  type: Types.SET_QUESTION_MEDIA,
  payload: media,
});

export const setQuestionMediaUploading = () => ({
  type: Types.SET_QUESTION_MEDIA_UPLOADING,
});

export const setQuestionMediaDoneUploading = () => ({
  type: Types.SET_QUESTION_MEDIA_DONE_UPLOADING,
});

export const questionMediaUploadingError = () => ({
  type: Types.QUESTION_MEDIA_UPLOADING_ERROR,
});

export const addAnswer = () => ({
  type: Types.ADD_ANSWER,
});

export const removeAnswer = (answerId: string) => ({
  type: Types.REMOVE_ANSWER,
  payload: answerId,
});

export const resetAnswers = () => ({
  type: Types.RESET_ANSWERS,
});

export const setAnswer = (answer: IBiteAnswer) => ({
  type: Types.SET_ANSWER,
  payload: answer,
});

export const setCorrectAnswer = (answerId: string) => ({
  type: Types.SET_CORRECT_ANSWER,
  payload: answerId,
});

export const filterEmptyChoices = () => ({
  type: Types.FILTER_EMPTY_CHOICES,
});

export const setChoices = (choices: IBiteAnswer[]) => ({
  type: Types.SET_CHOICES,
  payload: choices,
});

export const setIsMultiChoice = (isMultiChoice: boolean) => ({
  type: Types.SET_IS_MULTI_CHOICE,
  payload: isMultiChoice,
});

export const setSkipQuestion = (skip: boolean) => ({
  type: Types.SET_SKIP_QUESTION,
  payload: skip,
});

export const setCreatedQuestion = (state: boolean) => ({
  type: Types.SET_CREATED_QUESTION,
  payload: state,
});

export const setQuizFirstQuestion = (isFirst: boolean) => ({
  type: Types.SET_QUIZ_FIRST_QUESTION,
  payload: isFirst,
});

export const clearQuestions = () => ({
  type: Types.CLEAN_QUESTIONS,
});

export const setIsQuiz = () => ({
  type: Types.SET_IS_QUIZ,
  payload: true,
});
