import { RootState } from '../index';
import { EInProgressTask, IItemInProgressStatus } from '../../types/common';

export const playlistSelector = (state: RootState) => state.playlist;
export const playlistItemSelector = (playlistId: number) => (state: RootState) =>
  state.playlist.playlistsMap[playlistId];

export const playlistInProgressSelector =
  (playlistId: number, task: EInProgressTask) =>
  (state: RootState): IItemInProgressStatus =>
    playlistSelector(state).playlistsInProgress?.[playlistId]?.[task];
