import React, { memo, useState } from 'react';
import ProcessingStatus from '../ProcessingStatus.web';
import { TSidebarButton } from '../VideoEditor.web';
import LeftPrimarySidebar from './LeftPrimarySidebar';
import styled from 'styled-components';
import defaultTheme from '../../../themes';
import {
  IS_EXPANDED_TRANSITION_TS,
  SECONDARY_SIDEBAR_WIDTH,
  SPACING,
  TIMELINE_CONTROLS_HEIGHT,
} from '../videoEditor.constants';
import CloudSidebar from './CloudSidebar';
import StockSidebar from './StockSidebar';
// import FileSidebar from './FileSidebar.web';
import TextSidebar from './TextSidebar.web';
import RecordSidebar from './RecordSidebar.web';
import FavoritesSidebar from './FavoritesSidebar';
import BrandSidebar from './BrandSidebar';
import ScopedSidebar from './ScopedSidebar';
import { useSelector } from 'react-redux';
import { overlaySelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import AddToBrandOverlay from './AddToBrandOverlay/AddToBrandOverlay';
import RemoveFromBrandOverlay from './AddToBrandOverlay/RemoveFromBrandOverlay';

const secondarySidebarComponentByType = {
  CLOUD: CloudSidebar,
  SCOPED: ScopedSidebar,
  FAVORITES: FavoritesSidebar,
  BRAND: BrandSidebar,
  // FILE: FileSidebar,
  STOCK: StockSidebar,
  RECORD: RecordSidebar,
  TEXT: TextSidebar,
};

const overlayByType = {
  ADD_TO_BRAND: AddToBrandOverlay,
  REMOVE_FROM_BRAND: RemoveFromBrandOverlay,
};

const LeftSidebar = ({
  timelineHeight,
  isTimelineExpanded,
}: {
  timelineHeight: number;
  isTimelineExpanded: boolean;
}) => {
  const overlay = useSelector(overlaySelector);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [secondarySidebar, setSecondarySidebar] = useState<TSidebarButton>('CLOUD');

  const SecondarySidebarComponent = secondarySidebarComponentByType[secondarySidebar];
  const OverlayComponent = overlayByType[overlay?.type];

  return (
    <S.Sidebar isSidebarExpanded={isSidebarExpanded} timelineHeight={timelineHeight}>
      <LeftPrimarySidebar
        secondarySidebar={secondarySidebar}
        isTimelineExpanded={isTimelineExpanded}
        isSidebarExpanded={isSidebarExpanded}
        setSecondarySidebar={setSecondarySidebar}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      <S.SecondarySidebar>
        {SecondarySidebarComponent && <SecondarySidebarComponent />}
        <ProcessingStatus />
      </S.SecondarySidebar>
      {overlay && OverlayComponent && <OverlayComponent />}
    </S.Sidebar>
  );
};
export default memo(LeftSidebar);

export const S = {
  Sidebar: styled.div<{
    isSidebarExpanded: boolean;
    timelineHeight: number;
  }>`
    position: absolute;
    top: ${SPACING}px;
    left: ${SPACING}px;
    bottom: ${({ isSidebarExpanded, timelineHeight }) =>
      !isSidebarExpanded ? TIMELINE_CONTROLS_HEIGHT + timelineHeight + SPACING : SPACING}px;
    display: flex;
    flex-shrink: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    transition: bottom ${IS_EXPANDED_TRANSITION_TS}ms ease-in-out;
    overflow: hidden;
  `,
  SecondarySidebar: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${SECONDARY_SIDEBAR_WIDTH}px;
  `,
};
