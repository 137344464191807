import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { cloneDeep, isEmpty } from 'lodash';
import { calcHeight, calcWidth, deviceWidth, isWeb, WEB_CONTAINER_WIDTH } from '../../../utils/dimensions';
import NoteSticker from '../common/NoteSticker';
import {
  biteSummaryCardsSelector,
  isBiteSummaryCreatedSelector,
} from '../../../store/biteSummary/biteSummary.selectors';
import { useSelector } from 'react-redux';
import Carousel, { CarouselProps, getInputRangeFromIndexes, Pagination } from 'react-native-snap-carousel';
import NotePanel from '../common/NotePanel';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { clearState, setSummaryCards } from '../../../store/biteSummary/biteSummary.actions';
import { useDispatch } from 'react-redux';
import getHasEnhancementsEdit from '../../../utils/introMedia/getHasEnhancementsEdit';
import { createBiteSelector, introMediaProcessingStatusSelector } from '../../../store/createBite/createBite.selectors';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareView } from '../../../components/shared';
import Routes from '../../../navigation/routes';
import {
  deleteBiteSummary,
  editBiteSummary,
  resetSummarySuggestionCards,
} from '../../../store/createBite/createBites.actions';
import { CommonActions, useIsFocused } from '@react-navigation/native';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { I18nManager, ScrollView, InteractionManager, ViewStyle } from 'react-native';
import DeleteButton from '../../../components/shared/buttons/DeleteButton';
import { Animated, StyleProp } from 'react-native';
import { ISummaryCard } from '../../../types/biteSummary';
import { filterIfCardIsEmpty, generateSummaryCard, summaryCardColors } from '../../../utils/biteSummary';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { IS_ANDROID } from '../../../utils/constants/env';
import { isSafari } from 'react-device-detect';
import Button, { EIcon } from '../../../components/shared/buttons/Button/Button';
import SuggestionHeader from '../../Shared/SuggestionHeader';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { confirmDeleteSummaryNoteDataSet, createSummaryNotesContinueBtnDataSet } from './createSummaryNotes.constants';

function scrollInterpolator(index: number, carouselProps: CarouselProps<any>) {
  const range = [2, 1, 0, -1];
  const inputRange = getInputRangeFromIndexes(range, index, carouselProps);
  const outputRange = range;

  return { inputRange, outputRange };
}

function animatedNativeStyles(
  index: number,
  animatedValue: Animated.AnimatedValue,
  carouselProps: CarouselProps<any>,
): StyleProp<any> {
  const sizeRef = carouselProps.vertical ? carouselProps.itemHeight : carouselProps.itemWidth;
  const translateProp = carouselProps.vertical ? 'translateY' : 'translateX';

  return {
    zIndex: carouselProps.data.length - index,
    opacity: animatedValue.interpolate({
      inputRange: [-1, 0, 1, 2],
      outputRange: [0.75, 1, 0.6, 0.4],
    }),
    transform: [
      {
        rotate: animatedValue.interpolate({
          inputRange: [-1, 0, 1, 2],
          outputRange: I18nManager.isRTL ? ['0deg', '0deg', '-5deg', '-8deg'] : ['0deg', '0deg', '5deg', '8deg'],
          extrapolate: 'clamp',
        }),
      },
      {
        scale: animatedValue.interpolate({
          inputRange: [-1, 0, 1, 2],
          outputRange: [0.96, 1, 0.85, 0.7],
        }),
      },
      {
        [translateProp]: animatedValue.interpolate({
          inputRange: [-1, 0, 1, 2],
          outputRange: I18nManager.isRTL
            ? [0, 0, sizeRef - 30, sizeRef * 2 - 45]
            : [0, 0, -sizeRef + 30, -sizeRef * 2 + 45],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
}

function animatedWebStyles(index: number, animatedValue: Animated.AnimatedValue): StyleProp<any> {
  return {
    transform: [
      {
        scale: animatedValue.interpolate({
          inputRange: [-2, -1, 0, 1, 2],
          outputRange: [0.8, 0.9, 1, 0.9, 0.8],
          extrapolate: 'clamp',
        }),
      },
      {
        translateY: animatedValue.interpolate({
          inputRange: [-2, -1, 0, 1, 2],
          outputRange: [-40, -20, 1, -20, -40],
          extrapolate: 'clamp',
        }),
      },
    ],
  };
}

export enum EditableProp {
  color,
  font,
}

const carouselSizes = {
  slideStyle: {
    height: calcHeight(500),
  },
  itemWidth: calcWidth(327),
  stickerHeight: calcHeight(268),
};

const CreateSummaryNotes = ({ navigation, route }) => {
  const isPrefilled = route?.params?.isPrefilled;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isEditMode = route.params?.isEditMode;
  const carouselRef = useRef(null);
  const isFocused = useIsFocused();

  const biteSummaryCards = useSelector(biteSummaryCardsSelector);
  const isCreated = useSelector(isBiteSummaryCreatedSelector);

  const { introEnhancements, introSubtitles, summarySuggestionCards } = useSelector(createBiteSelector);
  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);
  const { selectedBite } = useSelector(biteSelector);

  const [activeNoteIndex, setActiveNoteIndex] = useState(0);
  const [editableProp, setEditableProp] = useState<EditableProp | null>(null);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const formmatedSummarySuggestion = useMemo(() => {
    if (summarySuggestionCards && !isEditMode) {
      const summaryArray = summarySuggestionCards.map((card, index) => {
        return generateSummaryCard(false, null, summaryCardColors[index], card);
      });
      return summaryArray;
    }
    return null;
  }, [isEditMode, summarySuggestionCards]);

  const [localSummaryCards, setLocalSummaryCards] = useState(
    isEditMode
      ? biteSummaryCards
      : biteSummaryCards.length === 1 && biteSummaryCards[0].text === ''
      ? formmatedSummarySuggestion || biteSummaryCards
      : biteSummaryCards,
  );
  const [isLoading, setLoading] = useState(false);

  const isMountRef = useIsMounted();

  const handleOpenDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const renderDeleteButton = useCallback(
    () => <DeleteButton width={calcWidth(41)} height={calcWidth(41)} onPress={handleOpenDeleteModal} />,
    [handleOpenDeleteModal],
  );

  useEffect(() => {
    navigation.setOptions({
      headerTitle: t('addSummaryNotes.headerTitle'),
    });

    if (!isFocused || (!isEditMode && !isCreated)) {
      return;
    }

    navigation.setOptions({
      headerRight: renderDeleteButton,
      headerTitle: t('addSummaryNotes.headerTitle'),
    });
  }, [isEditMode, isFocused, navigation, handleOpenDeleteModal, t, isCreated, renderDeleteButton]);

  const snapToItem = useCallback((index: number) => {
    if (!carouselRef.current) {
      return;
    }

    carouselRef.current.snapToItem(index);
  }, []);

  const handleAddNote = useCallback(() => {
    const currentIndex = carouselRef.current?.currentIndex;
    const currentCard: ISummaryCard = localSummaryCards[currentIndex];

    const updatedSummaryCards = cloneDeep(localSummaryCards);
    const spliceIndex =
      I18nManager.isRTL && IS_ANDROID ? localSummaryCards.length - 1 - currentIndex : currentIndex + 1;
    updatedSummaryCards.splice(spliceIndex, 0, generateSummaryCard(false, currentCard?.color));
    setLocalSummaryCards(updatedSummaryCards);
    setEditableProp(null);

    dispatch(
      trackEvent({
        event: 'add_a_card_btn',
      }),
    );
    setTimeout(() => {
      if (!carouselRef.current || isEmpty(localSummaryCards)) {
        return;
      }

      snapToItem(currentIndex + 1);
    }, 50);
  }, [dispatch, localSummaryCards, snapToItem]);

  const handleNotePress = useCallback(
    (index: number) => {
      const localIndex = I18nManager.isRTL && IS_ANDROID ? localSummaryCards.length - 1 - index : index;
      snapToItem(localIndex);
    },
    [localSummaryCards.length, snapToItem],
  );

  const handleDeleteNote = useCallback(
    (id: number) => {
      dispatch(
        trackEvent({
          event: 'delete_card',
        }),
      );

      const updatedSummaryCards = cloneDeep(localSummaryCards).filter((card) => card.id !== id);
      setLocalSummaryCards(updatedSummaryCards);
      if (activeNoteIndex === localSummaryCards.length - 1) {
        carouselRef.current.snapToPrev(true);
        setActiveNoteIndex(activeNoteIndex - 1);
      }
    },
    [dispatch, localSummaryCards, activeNoteIndex],
  );

  const disabledContinue = useMemo(() => {
    return localSummaryCards.findIndex((item) => item.text.length > 0) === -1;
  }, [localSummaryCards]);

  const handleEditComplete = useCallback(() => {
    if (!isMountRef.current) {
      return;
    }

    setLoading(false);

    if (isEditMode) {
      navigation.goBack();
      return;
    }

    const hasEnhancementsEdit = getHasEnhancementsEdit({
      introEnhancements,
      introSubtitles,
    });

    if (hasEnhancementsEdit) {
      dispatch(
        log({
          event: 'CreateSummaryNotes.onSkip: opening BitesMagic',
          data: {
            introMediaProcessingStatus,
            hasEnhancementsEdit,
          },
        }),
      );
      navigation.navigate(Routes.CreateBiteStack.BitesMagic, { isPrefilled });
      return;
    }

    const isAIProcessing =
      introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING &&
      (introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.INACTIVE ||
        introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.PROCESSING ||
        introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.INACTIVE ||
        introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.PROCESSING);

    if (isAIProcessing) {
      dispatch(
        log({
          event: 'CreateSummaryNotes.onSkip: opening AIExpectationSecond',
          data: {
            isAIProcessing,
            introMediaProcessingStatus,
            hasEnhancementsEdit,
          },
        }),
      );
      navigation.navigate(Routes.CreateBiteStack.AIExpectationSecond, { isPrefilled });
      return;
    }

    dispatch(
      log({
        event: 'CreateSummaryNotes.onSkip: opening FinalScreen',
        data: {
          isAIProcessing,
          introMediaProcessingStatus,
          hasEnhancementsEdit,
        },
      }),
    );

    InteractionManager.runAfterInteractions(() => {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: Routes.CreateBiteStack.FinalScreen,
            },
          ],
        }),
      );
    });
  }, [
    dispatch,
    introEnhancements,
    introMediaProcessingStatus,
    introSubtitles,
    isEditMode,
    isMountRef,
    isPrefilled,
    navigation,
  ]);

  const handleContinuePress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'continue_btn',
        props: { page_title: Routes.CreateBiteStack.CreateSummaryNotes },
      }),
    );
    setLoading(true);

    const updatedSummaryCards = localSummaryCards?.filter(filterIfCardIsEmpty) || [];

    updatedSummaryCards.forEach((card, index) => {
      card.order = index;
    });
    setLocalSummaryCards(updatedSummaryCards);

    dispatch(setSummaryCards(updatedSummaryCards));
    dispatch(
      editBiteSummary({
        biteId: selectedBite?.id,
        callback: handleEditComplete,
      }),
    );
  }, [dispatch, handleEditComplete, localSummaryCards, selectedBite?.id]);

  const handleDeleteComplete = useCallback(() => {
    if (!isMountRef.current) {
      return;
    }

    dispatch(clearState());

    navigation.goBack();
  }, [dispatch, isMountRef, navigation]);

  const handleRemoveSection = useCallback(() => {
    handleCloseDeleteModal();
    dispatch(resetSummarySuggestionCards());
    dispatch(
      deleteBiteSummary({
        biteId: selectedBite?.id,
        callback: handleDeleteComplete,
      }),
    );
  }, [dispatch, handleCloseDeleteModal, handleDeleteComplete, selectedBite?.id]);

  const handleChangeLocalSummaryCard = useCallback(
    (card: ISummaryCard) => {
      const updatedCards = localSummaryCards.map((localCard) => {
        if (localCard.id === card.id) {
          return card;
        }
        return localCard;
      });
      setLocalSummaryCards(updatedCards);
    },
    [localSummaryCards],
  );

  const handleSnapToItem = useCallback(
    (index) => {
      const localIndex = I18nManager.isRTL && IS_ANDROID ? localSummaryCards.length - 1 - index : index;
      setActiveNoteIndex(localIndex);
    },
    [localSummaryCards.length],
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      const isActive =
        I18nManager.isRTL && IS_ANDROID
          ? carouselRef.current?.currentIndex === localSummaryCards.length - 1 - index
          : carouselRef.current?.currentIndex === index;
      const localIndex = I18nManager.isRTL && IS_ANDROID ? localSummaryCards.length - 1 - index : index;

      return (
        <NoteSticker
          onChange={handleChangeLocalSummaryCard}
          isActive={isActive}
          index={localIndex}
          onPress={handleNotePress}
          onDelete={handleDeleteNote}
          editableProp={editableProp}
          onIconPress={setEditableProp}
          disableDelete={localSummaryCards.length === 1}
          note={item}
          height={carouselSizes.stickerHeight}
        />
      );
    },
    [editableProp, handleChangeLocalSummaryCard, handleDeleteNote, handleNotePress, localSummaryCards.length],
  );

  return (
    <S.KeyboardAvoidingView keyboardVerticalOffset={calcHeight(100)} behavior='padding'>
      <ScrollView>
        <S.Center>
          {formmatedSummarySuggestion && (
            <SuggestionHeader
              title={t('addSummaryNotes.summarySuggestion.title')}
              subtitle={t('addSummaryNotes.summarySuggestion.header')}
            />
          )}
          <S.TopContainer>
            <S.CarouselContainer>
              <Carousel
                useScrollView={!(I18nManager.isRTL && IS_ANDROID)}
                ref={carouselRef}
                enableSnap={!isWeb}
                scrollEnabled={!isWeb || isSafari}
                slideStyle={carouselSizes.slideStyle}
                onSnapToItem={handleSnapToItem}
                scrollInterpolator={scrollInterpolator}
                slideInterpolatedStyle={isWeb ? animatedWebStyles : animatedNativeStyles}
                sliderWidth={isWeb ? WEB_CONTAINER_WIDTH : deviceWidth}
                itemWidth={carouselSizes.itemWidth}
                data={localSummaryCards}
                renderItem={renderItem}
              />
              <Pagination
                containerStyle={S.DotsContainerStyles}
                carouselRef={carouselRef.current}
                dotsLength={localSummaryCards.length}
                activeDotIndex={activeNoteIndex}
                dotColor={theme.colors.gray1}
                inactiveDotColor={theme.colors.gray11}
                animatedDuration={0}
                tappableDots
              />
            </S.CarouselContainer>
            {editableProp !== null && (
              <NotePanel
                onChange={handleChangeLocalSummaryCard}
                editableProp={editableProp}
                note={localSummaryCards[activeNoteIndex]}
              />
            )}
          </S.TopContainer>
          <S.ButtonContainer>
            {localSummaryCards.length < 8 && (
              <S.AddButton
                text={t('addSummaryNotes.addCard')}
                onPress={handleAddNote}
                border={theme.colors.primaryBlue}
                icon={EIcon.ADD}
                fill={theme.colors.white}
                textColor={theme.colors.primaryBlue}
              />
            )}
            <S.ContinueButton
              isLoading={isLoading}
              onPress={handleContinuePress}
              disabled={disabledContinue}
              content={t(`common.${isEditMode ? 'Save' : 'Continue'}`)}
              fontSize={15}
              // @ts-ignore
              dataSet={createSummaryNotesContinueBtnDataSet}
            />
          </S.ButtonContainer>
          <BlueAndRedButtonsModal
            title={t('confirmDeleteSection.summary.title')}
            description={t('confirmDeleteSection.summary.description')}
            isVisible={isDeleteModalVisible}
            onClose={handleCloseDeleteModal}
            onRightButtonClick={handleRemoveSection}
            onLeftButtonClick={handleCloseDeleteModal}
            rightButtonLabel={t('confirmDeleteModal.delete')}
            leftButtonLabel={t('confirmDeleteModal.cancel')}
            dataSet={confirmDeleteSummaryNoteDataSet}
          />
        </S.Center>
      </ScrollView>
    </S.KeyboardAvoidingView>
  );
};

const S = {
  KeyboardAvoidingView: styled.KeyboardAvoidingView`
    flex: 1;
    align-items: center;
  `,
  KeyboardAwareView: styled(KeyboardAwareView)``,
  Center: styled.SafeAreaView`
    margin-top: ${calcHeight(10)}px;
    flex: 1;
    background-color: white;
    align-self: center;
    position: relative;
    align-items: center;
    justify-content: ${IS_ANDROID ? 'space-between' : 'flex-start'};
  `,
  CarouselContainer: styled.View`
    overflow: visible;
    height: ${calcHeight(272)}px;
  `,
  ButtonContainer: styled.View`
    margin-top: ${calcHeight(32)}px;
    height: ${calcHeight(50)}px;
    width: ${calcWidth(327)}px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${calcHeight(20)}px;
  `,
  ContinueButton: styled(GenericButton)`
    width: ${calcWidth(124)}px;
  `,
  AddButton: styled(Button)`
    max-width: ${calcWidth(180)}px;
    height: ${calcHeight(44)}px;
  `,
  DotsContainerStyles: { position: 'absolute', bottom: -40, alignSelf: 'center' } as StyleProp<ViewStyle>,
  TopContainer: styled.View`
    align-items: center;
  `,
};

export default CreateSummaryNotes;
