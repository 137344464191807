import store from '../../store';
import { log } from '../../store/appActivity/appActivity.slice';
import withRetry, { IWithRetryProps } from '../withRetry';
import prepareS3UploadDetails from './prepareS3UploadDetails';
import { IUploadToS3 } from './uploadToS3.types';
import uploadToS3WithDetails from './uploadToS3WithDetails';
import getPromiseSingleton from '../getPromiseSingleton';
import getBlobFromLocalFile, { IGetBlobFromLocalFileResult } from '../getBlobFromLocalFile';
import { DEFAULT_PARALLEL_UPLOADS } from '../constants/common';
import { isWeb } from '../dimensions';

const uploadToS3 = async ({
  uri,
  file,
  processId,
  endpoint,
  multiPartEndpoints,
  orgId,
  mediaType,
}: IUploadToS3): Promise<{ taskId: string }> => {
  const config = store.getState()?.appActivity?.config;
  const parallelUploadsNum = config?.s3MultiPartUpload?.parallelUploads || DEFAULT_PARALLEL_UPLOADS;

  let filePartsCached = null;

  const logContext: any = {
    processId,
    data: {
      parallelUploadsNum,
      uri,
      endpoint,
      multiPartEndpoints,
      orgId,
      mediaType,
      fileSize: isWeb ? file.size : null,
    },
  };

  const getBlobFromLocalFileLocal = getPromiseSingleton<IGetBlobFromLocalFileResult>(async () => {
    const result = await getBlobFromLocalFile(uri);
    logContext.data.fileSize = result.blob.size;
    return result;
  });

  try {
    return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
      logContext.data.attempt = attempt;
      logContext.data.isLastAttempt = isLastAttempt;

      const startTs = Date.now();

      setErrorContext({
        ...logContext,
        action: 'uploadToS3: prepareS3UploadDetails',
        processId,
      });

      store.dispatch(
        log({
          ...logContext,
          event: 'uploadToS3: start',
        }),
      );

      const { singleUploadDetails, multiUploadDetails, getMultiUploadDetails } = await prepareS3UploadDetails({
        uri,
        file,
        processId,
        endpoint,
        multiPartEndpoints,
        orgId,
        mediaType,
        logContext,
        filePartsCached,
        getBlobFromLocalFile: getBlobFromLocalFileLocal,
      });

      const multiUploadDetailsLog = multiUploadDetails ? { ...multiUploadDetails } : null;

      if (multiUploadDetails?.fileParts) {
        filePartsCached = multiUploadDetails.fileParts;
      }

      setErrorContext({
        ...logContext,
        action: 'uploadToS3: uploadToS3WithDetails',
        processId,
        data: {
          ...logContext.data,
          singleUploadDetails,
          multiUploadDetailsLog,
        },
      });

      store.dispatch(
        log({
          ...logContext,
          event: 'uploadToS3: uploadToS3WithDetails',
          data: {
            ...logContext.data,
            singleUploadDetails,
            multiUploadDetailsLog,
          },
          metrics: {
            prepareS3UploadDetailsTs: Date.now() - startTs,
          },
        }),
      );

      const { taskId, fileName } = await uploadToS3WithDetails({
        processId,
        singleUploadDetails,
        multiUploadDetails,
        getMultiUploadDetails,
        fileSize: logContext.data.fileSize,
      });

      store.dispatch(
        log({
          ...logContext,
          event: 'uploadToS3: done',
          data: {
            ...logContext.data,
            taskId,
            singleUploadDetails,
            multiUploadDetailsLog,
          },
          metrics: {
            uploadToS3Ts: Date.now() - startTs,
            uploadToS3TsPerMb: logContext.data.fileSize
              ? (Date.now() - startTs) / (logContext.data.fileSize / 1024 / 1024)
              : null,
          },
        }),
      );

      return { taskId, fileName };
    });
  } catch (error) {
    store.dispatch(
      log({
        ...logContext,
        event: 'uploadToS3: error',
        data: {
          ...logContext.data,
          error,
        },
      }),
    );

    throw error;
  }
};
export default uploadToS3;
