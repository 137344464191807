import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteBite, fetchFullBites } from '../../store/bite/bite.actions';
import useIsItemOwner from '../../hooks/useIsItemOwner';
import { getBiteShareId } from '../../utils/formatDataFromServer';
import { EShareItemType, showSharePanel } from '../../store/sharePanel/sharePanel.slice';
import Routes from '../../navigation/routes';
import useIsDraft from '../../hooks/useIsDraft';
import useBiteUploadingMediaStatus from '../../hooks/useBiteUploadingMediaStatus';
import { EIntroMediaProcessingStatus } from '../../store/createBite/createBite.types';
import { ESharePanelCallbackType } from '../../types/sharePanel';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../utils/dimensions';
import { Platform, StatusBar } from 'react-native';
import { useTranslation } from 'react-i18next';
import { DASHBOARD_URL, PREVIEW_SITE_URL } from '../../utils/constants/urls';
import useToken from '../../hooks/useToken';
import { EPreviewMode, PAGE_WIDTH } from '../../components/shared/PreviewControlPanel/constants';
import { IStackNavigation } from '../../navigation/types';
import styled, { useTheme } from 'styled-components/native';
import WebView from '../../components/WebView';
import StatisticsButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/StatisticsButton';
import EditIcon from '../../assets/icons/preview/edit.svg';
import ShareButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/ShareButton';
import DeleteButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/DeleteButton';
import PreviewControlPanel from '../../components/shared/PreviewControlPanel';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import BlueAndRedButtonsModal from '../../components/modals/BlueAndRedButtonsModal';
import CopyButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/CopyButton';
import { copyBite } from '../../store/bite/bite.actions';
import { showBottomPanel } from '../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';
import { biteItemSelector } from '../../store/bite/bite.selectors';
import { IOrganization } from '../../types/organization';
import useOrganizations from '../../hooks/useOrganizations';
import { activeOrganizationSelector, isContentAdminSelector } from '../../store/auth/auth.selectors';
import { useIsFocused } from '@react-navigation/native';
import CreatorsName from './CreatorsName';
import SsoAuthLoader from '../auth/common/SsoAuthLoader';

interface IProps extends IStackNavigation {
  trigger?: string;
}

const PreviewBite: React.FC<IProps> = ({ navigation, route, trigger = 'card' }) => {
  const {
    biteId,
    initialMode = EPreviewMode.PREVIEW,
    withoutEdit,
    withoutDelete,
    withoutClone,
    withoutShare,
    shareOnMount,
  } = route.params;

  const isShareShowedRef = useRef(false);

  const bite = useSelector(biteItemSelector(biteId));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const { token } = useToken();

  const isBiteOwner = useIsItemOwner(bite);
  const isContentAdmin = useSelector(isContentAdminSelector);
  const isUserCanEdit = isBiteOwner || isContentAdmin;
  const activeOrganization = useSelector(activeOrganizationSelector);

  const biteShareId = getBiteShareId(bite);
  const creatorOrganizationsList = useOrganizations({ isCreator: true });
  const uploadingMediaStatus = useBiteUploadingMediaStatus(bite);

  const isDraft = useIsDraft(bite?.id);

  const [currentMode, setCurrentMode] = useState(initialMode);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>(null);
  const [isDeleteBiteModalVisible, setDeleteBiteModalVisible] = useState(false);

  const isAllowShare =
    !withoutShare &&
    !(
      bite?.no_sections ||
      uploadingMediaStatus === EIntroMediaProcessingStatus.PROCESSING ||
      uploadingMediaStatus === EIntroMediaProcessingStatus.PARTIAL_SUCCESS
    );
  const isAllowDelete = !withoutDelete && isUserCanEdit;
  const isAllowEdit = !withoutEdit && isUserCanEdit;

  const isCloneAvailable = activeOrganization.is_creator && !withoutClone;
  const isCloneOnMainControlsScreen = isCloneAvailable && !isBiteOwner && !isContentAdmin;
  const isCloneOnSecondaryControlsScreen = isCloneAvailable && !isCloneOnMainControlsScreen;

  const maxPages = isAllowDelete ? 2 : 1;

  const url = useMemo(
    () => (isFocused ? `${PREVIEW_SITE_URL}/bites/${biteShareId}/?token=${token}&rnd=${Date.now()}&isPreview=1` : null), // CURRENT URL WRAPPED INTO USEMEMO BECAUSE IT USED AS KEY IN WEBVIEW
    [biteShareId, token, isFocused],
  );
  const dashboardUrl = `${DASHBOARD_URL}/bites/${bite?.id}/?token=${token}&isPreview=1`;

  const closeDeleteBiteModal = useCallback(() => {
    setDeleteBiteModalVisible(false);
  }, []);

  const openDeleteBiteModal = useCallback(() => {
    setDeleteBiteModalVisible(true);
  }, []);

  const openCopyModal = useCallback(() => {
    setIsCopyModalOpen(true);
  }, []);

  const closeCopyModal = useCallback(() => {
    setSelectedOrganization(null);
    setIsCopyModalOpen(false);
  }, []);

  const handleEdit = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'edit',
        props: { bite_id: bite?.id, is_draft: isDraft },
      }),
    );

    navigation.navigate(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditMain,
      params: { biteId: bite?.id },
    });
  }, [bite?.id, isDraft, dispatch, navigation]);

  const handleShare = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'share_btn',
        props: { bite_id: bite?.id, from: 'bite_preview' },
      }),
    );
    dispatch(
      showSharePanel({
        callbackType: ESharePanelCallbackType.BITE_IN_LIST,
        itemType: EShareItemType.BITE,
        data: bite,
        from: 'bite_preview',
      }),
    );
  }, [bite, dispatch]);

  const handleCopyConfirmation = useCallback(() => {
    const targetOrg = creatorOrganizationsList.length === 1 ? creatorOrganizationsList[0] : selectedOrganization;
    dispatch(copyBite({ bite, org: targetOrg }));
    closeCopyModal();
  }, [creatorOrganizationsList, selectedOrganization, dispatch, bite, closeCopyModal]);

  const handleCopy = useCallback(() => {
    if (creatorOrganizationsList.length > 1) {
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.OrganizationSelectMenu,
          componentProps: {
            onOrganizationSelect: (org: IOrganization) => {
              setSelectedOrganization(org);
              openCopyModal();
            },
            isCreator: true,
          },
        }),
      );
      return;
    }
    openCopyModal();
  }, [dispatch, openCopyModal, creatorOrganizationsList.length]);

  const handleDeleteBite = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'delete',
        props: { bite_id: bite?.id },
      }),
    );

    dispatch(deleteBite(bite.id!));
    closeDeleteBiteModal();

    if (isWeb) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Routes.HomeStack.StackName,
            params: {
              screen: Routes.HomeStack.Home,
            },
          },
        ],
      });
      return;
    }

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [bite?.id, closeDeleteBiteModal, dispatch, navigation]);

  const toggleDashBoard = useCallback(() => {
    setCurrentMode((prev) => (prev === EPreviewMode.PREVIEW ? EPreviewMode.DASHBOARD : EPreviewMode.PREVIEW));
  }, []);

  useEffect(() => {
    if (currentMode === EPreviewMode.DASHBOARD) {
      dispatch(
        trackEvent({
          event: 'redirect_to_dashboard',
          props: { bite_id: bite?.id, target_url: dashboardUrl },
        }),
      );
      return;
    }
    dispatch(
      trackEvent({
        event: 'view',
        props: { bite_id: bite?.id, external_target_url: url, trigger },
      }),
    );
  }, [bite?.id, currentMode, dashboardUrl, dispatch, trigger, url]);

  useEffect(() => {
    if (shareOnMount && !isShareShowedRef.current) {
      handleShare();
      isShareShowedRef.current = true;
    }
  }, [shareOnMount, handleShare]);

  const copyBiteModalDescription = useMemo(
    () =>
      selectedOrganization
        ? t('copyBiteModal.descriptionCloneToWorkspace', {
            biteName: bite?.subject,
            workspaceName: selectedOrganization.name,
          })
        : t('copyBiteModal.description', {
            biteName: bite?.subject,
          }),
    [bite, selectedOrganization, t],
  );

  useEffect(() => {
    if (!bite) {
      dispatch(fetchFullBites([biteId]));
    }
  }, [bite, biteId, dispatch]);

  if (!bite) {
    return <SsoAuthLoader />;
  }

  return (
    <S.Container>
      <S.WebViewWrap2>
        <S.WebViewWrap>
          <WebView url={currentMode === EPreviewMode.DASHBOARD ? dashboardUrl : url} />
          <PreviewControlPanel maxPages={maxPages} onClose={navigation.goBack}>
            <S.SwipePage>
              <StatisticsButton
                watched={bite?.watched}
                participants={bite?.answers}
                comments={bite?.comments}
                onPress={toggleDashBoard}
                isDashboard={currentMode === EPreviewMode.DASHBOARD}
              />

              {isAllowEdit && (
                <S.EditButton onPress={handleEdit}>
                  <EditIcon width='28' height='28' style={S.Icon} color={theme.colors.primaryBlue} />
                  <S.ButtonText>{t('common.Edit')}</S.ButtonText>
                </S.EditButton>
              )}
              {isCloneOnMainControlsScreen && <CopyButton onPress={handleCopy} />}

              {isAllowShare && (
                <ShareButton
                  disabled={!bite?.sharing_mode}
                  onPress={handleShare}
                  width={(PAGE_WIDTH - calcWidth(10)) / 4}
                />
              )}
            </S.SwipePage>

            {(isAllowDelete || isCloneOnSecondaryControlsScreen) && (
              <S.SwipePage>
                {isCloneOnSecondaryControlsScreen && <CopyButton onPress={handleCopy} />}
                {isAllowDelete && <DeleteButton onPress={openDeleteBiteModal} />}
              </S.SwipePage>
            )}
          </PreviewControlPanel>
        </S.WebViewWrap>
      </S.WebViewWrap2>
      <CreatorsName isBitePreview name={bite?.owner_name} />
      <BlueAndRedButtonsModal
        title={t('deleteBiteModal.title')}
        description={t('deleteBiteModal.description')}
        isVisible={isDeleteBiteModalVisible}
        onClose={closeDeleteBiteModal}
        onRightButtonClick={handleDeleteBite}
        onLeftButtonClick={closeDeleteBiteModal}
        rightButtonLabel={t('deleteBiteModal.delete')}
        leftButtonLabel={t('deleteBiteModal.cancel')}
      />
      <BlueAndRedButtonsModal
        title={t('copyBiteModal.title')}
        description={copyBiteModalDescription}
        isVisible={isCopyModalOpen}
        onClose={closeCopyModal}
        onRightButtonClick={handleCopyConfirmation}
        onLeftButtonClick={closeCopyModal}
        rightButtonLabel={t('copyBiteModal.clone')}
        leftButtonLabel={t('copyBiteModal.close')}
        reverseColors
      />
    </S.Container>
  );
};

const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap2: styled.View`
    flex-grow: 1;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  WebViewWrap: styled.View`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
  `,
  SwipePage: styled.View`
    flex-direction: row;
    justify-content: flex-end;
    height: ${calcHeight(55)}px;
    width: ${PAGE_WIDTH}px;
  `,
  ButtonText: styled.Text<{ color?: string }>`
    margin-top: ${calcHeight(2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.gray19};
  `,
  DeleteButton: styled.TouchableOpacity`
    height: ${calcHeight(53)}px;
    max-width: ${PAGE_WIDTH / 4}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    margin: 0 ${calcWidth(isWeb ? 8 : 2)}px;
    align-items: center;
    justify-content: center;
  `,
  EditButton: styled.TouchableOpacity`
    flex: 1;
    height: ${calcHeight(53)}px;
    min-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    max-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    margin: 0 ${calcWidth(2)}px;
    align-items: center;
    justify-content: center;
  `,
  Icon: {
    width: calcWidth(28),
    height: calcHeight(28),
  },
};

export default memo(PreviewBite);
