import React, { FC, useCallback, useState } from 'react';
import { useIsFocused, StackActions } from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';

import MediaViewer from '../../../components/shared/MediaViewer/MediaViewer';
import {
  postOrUpdateQuizBiteRequest,
  setBiteIcon,
  setBiteIntroUploading,
  setBiteName,
} from '../../../store/createBite/createBites.actions';
import {
  postPlaylist,
  setPlaylistToEdit,
  setLinkedCoverURL,
} from '../../../store/createPlaylist/createPlaylist.actions';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import { IStackNavigation } from '../../../navigation/types';
import Routes from '../../../navigation/routes';
import { setPlaylists } from '../../../store/playlist/playlist.actions';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { biteSelector } from '../../../store/bite/bite.selectors';
import TellYourStory, { IUploadVideoProps } from '../../Shared/TellYourStory/TellYourStory';
import { setSkipQuestion } from '../../../store/biteQuestion/biteQuestion.actions';
import { setSummaryCards } from '../../../store/biteSummary/biteSummary.actions';
import { displayAppModal } from '../../../store/appModals/appModals.slice';
import { EAppModalStackItemType } from '../../../store/appModals/appModals.types';
import { useIsMounted } from '../../../hooks/useIsMounted';
import VideoUploadingOverlay from '../components/VideoUploadingOverlay';

interface IProps extends IStackNavigation {}

const QuizTellYourStory: FC<IProps> = ({ navigation, route }) => {
  const isEditMode = route?.params?.isEditMode;
  const openImportMediaPanel = route.params?.openImportMediaPanel;

  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { subject, editedPlaylist } = useSelector(createPlaylistSelector);
  const { selectedBite } = useSelector(biteSelector);
  const [isIntroUpdated, setIntroUpdated] = useState(false);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const isMounted = useIsMounted();

  const handleCoverUpdate = useCallback(
    async ({
      thumbnail: thumbnailProp,
      uploadThumbnail,
    }: {
      thumbnail?: { image: string };
      uploadThumbnail?: () => Promise<{ image: string }>;
    }) => {
      try {
        const thumbnail = thumbnailProp || (await uploadThumbnail());

        dispatch(setLinkedCoverURL(thumbnail.image));
        if (!editedPlaylist?.id) {
          return;
        }

        const updatedPlaylist = {
          ...editedPlaylist,
          linked_cover_url: thumbnail.image,
        };

        dispatch(setPlaylists([updatedPlaylist]));

        dispatch(setPlaylistToEdit({ playlist: updatedPlaylist, editMode: isEditMode }));
      } catch (e) {}
    },
    [dispatch, editedPlaylist, isEditMode],
  );

  const handleRedirectToNextScreen = useCallback(
    (setLoading) => {
      setLoading(false);
      setIsVideoUploading(false);
      navigation.dispatch(
        StackActions.replace(Routes.EditQuizStack.StackName, { params: { playlistId: editedPlaylist?.id } }),
      );
    },
    [editedPlaylist?.id, navigation],
  );

  const handleMediaUpload = useCallback(
    (setLoading) => {
      dispatch(setBiteIcon({ id: 2, name: '', image: '' }));
      dispatch(setBiteName(`${subject}_intro`));
      dispatch(setSummaryCards([]));
      dispatch(setSkipQuestion(true));
      dispatch(
        postOrUpdateQuizBiteRequest({
          playlistSection: 'intro',
          callback: () => handleRedirectToNextScreen(setLoading),
          onError: () => {
            if (!isMounted.current) {
              return;
            }
            setLoading(false);
            setIsVideoUploading(false);
            dispatch(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));
          },
        }),
      );
    },
    [dispatch, handleRedirectToNextScreen, isMounted, subject],
  );

  const handleMediaSelect = useCallback(
    async (setLoading, thumbnailUrl) => {
      if (editedPlaylist?.linked_cover_url && isEditMode) {
        dispatch(
          displayAppModal({
            type: EAppModalStackItemType.ACCEPT_COVER_CHANGE,
            data: {
              onAccept: async () => {
                await handleCoverUpdate({ thumbnail: { image: thumbnailUrl } });
                handleMediaUpload(setLoading);
              },
              onReject: () => {
                handleMediaUpload(setLoading);
              },
              onCancel: () => {
                setLoading(false);
              },
            },
          }),
        );
        return;
      }

      await handleCoverUpdate({ thumbnail: { image: thumbnailUrl } });
      handleMediaUpload(setLoading);
    },
    [dispatch, handleCoverUpdate, handleMediaUpload, isEditMode, editedPlaylist?.linked_cover_url],
  );

  const handleIntroUpdate = useCallback(
    (uploadVideo, setLoading) => {
      dispatch(
        setBiteIntroUploading({
          uploadVideo,
          isEditMode,
          biteId: selectedBite?.id,
          onError: () => {
            if (!isMounted.current) {
              return;
            }
            setLoading(false);
            setIsVideoUploading(false);
            dispatch(displayAppModal({ type: EAppModalStackItemType.UPLOAD_ERROR }));
          },
        }),
      );
      handleMediaUpload(setLoading);
    },
    [dispatch, handleMediaUpload, isEditMode, isMounted, selectedBite?.id],
  );
  const handleUploadVideo = useCallback(
    async ({ uploadVideo, setLoading, uploadThumbnail }: IUploadVideoProps) => {
      if (editedPlaylist?.linked_cover_url && isEditMode) {
        dispatch(
          displayAppModal({
            type: EAppModalStackItemType.ACCEPT_COVER_CHANGE,
            data: {
              onAccept: async () => {
                setIsVideoUploading(true);
                await handleCoverUpdate({ uploadThumbnail });
                handleIntroUpdate(uploadVideo, setLoading);
              },
              onReject: () => {
                setIsVideoUploading(true);
                handleIntroUpdate(uploadVideo, setLoading);
              },
              onCancel: () => {
                setLoading(false);
              },
            },
          }),
        );
        return;
      }

      setIsVideoUploading(true);
      await handleCoverUpdate({ uploadThumbnail });
      handleIntroUpdate(uploadVideo, setLoading);
    },
    [dispatch, handleCoverUpdate, handleIntroUpdate, isEditMode, editedPlaylist?.linked_cover_url],
  );

  const handleSkip = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'skip_intro_creation',
        props: { type: 'quiz' },
      }),
    );
    dispatch(postPlaylist());
    navigation.dispatch(
      StackActions.replace(Routes.EditQuizStack.StackName, { params: { playlistId: editedPlaylist?.id } }),
    );
  }, [dispatch, editedPlaylist?.id, navigation]);

  const renderMediaViewer = useCallback(
    (mediaViewerProps) => {
      if (!isFocused) {
        return null;
      }

      return <MediaViewer {...mediaViewerProps} />;
    },
    [isFocused],
  );

  return (
    <>
      <TellYourStory
        from={Routes.CreateQuizStack.QuizTellYourStory}
        renderMediaViewer={renderMediaViewer}
        onSkip={handleSkip}
        onUploadVideo={handleUploadVideo}
        onMediaSelect={handleMediaSelect}
        isEditMode={isEditMode}
        handleRedirectToNextScreen={handleRedirectToNextScreen}
        navigation={navigation}
        route={route}
        withDelete={false}
        isControlsDisabledOnLoading={true}
        openImportMediaPanel={openImportMediaPanel}
        setIntroUpdated={setIntroUpdated}
        isIntroUpdated={isIntroUpdated}
        isVideoUploading={isVideoUploading}
      />
      <VideoUploadingOverlay isVisible={isVideoUploading} />
    </>
  );
};

export default QuizTellYourStory;
