import React from 'react';
import styled from 'styled-components/native';
import MagicStarsBlueIcon from '../../assets/icons/magic-stars-blue.svg';
import { calcHeight, calcWidth } from '../../utils/dimensions';

interface IProps {
  title: string;
  subtitle?: string;
}
const magicIconStyle = { marginRight: 5 };

const SuggestionHeader: React.FC<IProps> = ({ title, subtitle }) => {
  return (
    <S.SuggestionWrapper>
      <MagicStarsBlueIcon style={magicIconStyle} />
      <S.SuggestionTextWrapper>
        <S.SuggestionTitle>{title}</S.SuggestionTitle>
        {subtitle && <S.SuggestionSecondTitle>{subtitle}</S.SuggestionSecondTitle>}
      </S.SuggestionTextWrapper>
    </S.SuggestionWrapper>
  );
};

const S = {
  SuggestionTextWrapper: styled.View`
    margin-left: ${calcWidth(5)}px;
  `,
  SuggestionWrapper: styled.View`
    flex-direction: row;
    margin-bottom: ${calcHeight(17)}px;
  `,
  SuggestionTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    width: ${calcWidth(240)}px;
  `,
  SuggestionSecondTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray1};
  `,
};

export default SuggestionHeader;
