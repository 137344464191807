import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRegistry, LogBox } from 'react-native';
import { TransitionPresets } from '@react-navigation/stack';
import { ToastContainer, toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import 'video-react/dist/video-react.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';

import './services/setSsoInProgress';
import './index.web.css';
import App from './App';
import { name as appName } from './app.json';
import store, { persistor } from './store';
import i18n from './locale/i18n';
import defaultTheme from './themes/defaultTheme';
import SsoAuthLoader from './screens/auth/common/SsoAuthLoader';

// @ts-ignore
import './utils/smartlook';
import './services/youtube';

// for develoment purposes, uncomment to wipe local storage
// import AsyncStorage from '@react-native-community/async-storage';
// AsyncStorage.clear();

TransitionPresets.SlideFromRightIOS = {};

const WrappedApp = () => (
  <Provider store={store}>
    <PersistGate loading={<SsoAuthLoader />} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        <ThemeProvider theme={defaultTheme}>
          <SafeAreaProvider>
            <App />
          </SafeAreaProvider>
        </ThemeProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

LogBox.ignoreLogs([
  'VirtualizedLists should never be nested', // TODO: Remove when fixed
  'Expected style', // Removes styled components warning about units
]);

AppRegistry.registerComponent(appName, () => WrappedApp);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
