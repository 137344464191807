import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../utils/dimensions';
import UrlInput from '../../components/shared/UrlInput/UrlInput';
import { ActivityIndicator, Clipboard, Linking } from 'react-native';
import WebView from '../../components/WebView';
import Header from '../../components/Header';
import GoogleSlidesIcon from '../../assets/icons/biteIntro/google-slides.svg';
import GenericButton from '../../components/shared/buttons/GenericButton';
import HowToGetTheLink from './common/HowToGetTheLink';
import BitesApi from '../../store/api/bites-api';
import { useDebouncedCallback } from 'use-debounce';
import { IStackNavigation } from '../../navigation/types';
import SlidesPlayer from '../../components/shared/MediaViewer/SlidesPlayer';
import VideoModal from '../../components/shared/VideoModal';
import { EFileType, GoogleSlidesMedia } from '../../types/media';
import { useIsMounted } from '../../hooks/useIsMounted';
import { formatMediaSize } from '../../utils/formatMediaSize';
import { continueFromGoogleSlides } from './GoogleSlides.constants';

const EMBED_VERSION = '1';
const GoogleSlidesImportVideoId = '774331766';

interface IGoogleSlidesMedia {
  height: number;
  width: number;
  src: string;
  frameborder: number;
  allowfullscreen: boolean;
  mozallowfullscreen: boolean;
  webkitallowfullscreen: boolean;
  delayms: number;
  start: boolean;
  loop: boolean;
}

interface IProps extends IStackNavigation {}

const MAX_MEDIA_HEIGHT = Math.round(deviceHeight - 400);
const MAX_MEDIA_WIDTH = isWeb ? Math.round(deviceWidth - 120) : Math.round(deviceWidth - 80);

const GoogleSlides: React.FC<IProps> = ({ navigation, route }) => {
  const isMounted = useIsMounted();
  const theme = useTheme();
  const initialMedia = route?.params?.initialMedia;
  const setGoogleSlidesMedia = route?.params?.setGoogleSlidesMedia;

  const { t } = useTranslation();
  const [urlInput, setUrlInput] = useState(initialMedia?.original_embed || '');
  const [media, setMedia] = useState(initialMedia || null);
  const [errorCode, setErrorCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const loadingKeyRef = useRef(0);
  const [isExplanationVideoModalOpen, setExplanationVideoModalOpen] = useState(false);

  const handleGoBack = useCallback(
    (params?: unknown) => {
      const routes = navigation.getState()?.routes;
      const prevRoute = routes[routes.length - 2];
      navigation.navigate(prevRoute.name, params);
    },
    [navigation],
  );

  const getAttributesFromString = useCallback((text) => {
    const parts = text.slice(7, text.length - 10).split(' ');

    const mediaAttributes: IGoogleSlidesMedia = parts.reduce((map, item) => {
      const [key, ...valueParts] = item.split('=');
      if (!key) {
        return map;
      }
      if (valueParts.length === 0) {
        map[key] = true;
        return map;
      }
      const valuePart = valueParts.join('=');
      const value = valuePart.slice(1, valuePart.length - 1);
      map[key] = value;

      if (value === 'true') {
        map[key] = true;
      }

      if (value === 'false') {
        map[key] = false;
      }

      if (key === 'frameborder') {
        map[key] = Number(map[key]);
      }

      if (key === 'width' || key === 'height') {
        map[key] = Number(map[key]);
      }
      return map;
    }, {});

    mediaAttributes.delayms = Number(mediaAttributes.src?.split('delayms=')[1]?.split('&')[0]) || 3000;
    mediaAttributes.start = mediaAttributes.src?.split('start=')[1]?.split('&')[0] === 'true' || false;
    mediaAttributes.loop = mediaAttributes.src?.split('loop=')[1]?.split('&')[0] === 'true' || false;

    return mediaAttributes;
  }, []);

  const validateUrl = useDebouncedCallback(async (text: string) => {
    const mediaAttributes = getAttributesFromString(text);

    setLoading(true);
    const loadingKey = ++loadingKeyRef.current;
    setMedia(null);
    setErrorCode(null);

    try {
      await BitesApi.post('/media/google_slides/validate/', {
        media_url: mediaAttributes.src,
      });

      if (!isMounted.current || loadingKey !== loadingKeyRef.current) {
        return;
      }

      const formattedSizes = formatMediaSize({
        initialHeight: mediaAttributes.height,
        initialWidth: mediaAttributes.width,
        maxHeight: MAX_MEDIA_HEIGHT,
        maxWidth: MAX_MEDIA_WIDTH,
        isGoogleSlides: true,
      });

      setLoading(false);
      setMedia({
        ...mediaAttributes,
        file_type: EFileType.GOOGLE_SLIDES,
        media_url: mediaAttributes.src,
        original_embed: text,
        embed_version: EMBED_VERSION,
        height: formattedSizes.height,
        width: formattedSizes.width,
      });
    } catch (e) {
      setLoading(false);
      if (e?.response?.data?.code) {
        setErrorCode(e.response.data.code);
        return;
      }
      setErrorCode('common');
    }
  }, 300);

  const processInput = useCallback(
    (text) => {
      if (!text?.trim()) {
        setLoading(false);
        loadingKeyRef.current++;
        setErrorCode(null);
        setMedia(null);
        return;
      }

      if (text.slice(0, 7) !== '<iframe' || text.slice(text.length - 10, text.length) !== '></iframe>') {
        setLoading(false);
        loadingKeyRef.current++;
        setMedia(null);
        setErrorCode('bad_format');
        return;
      }

      validateUrl.callback(text);
    },
    [validateUrl],
  );

  const handleChangeText = useCallback(
    (text: string) => {
      setUrlInput(text);
      processInput(text);
    },
    [processInput],
  );

  const handleCleanUrl = useCallback(() => {
    handleChangeText('');
  }, [handleChangeText]);

  const handleCopyFromClipboard = useCallback(async () => {
    const text = await Clipboard.getString();
    handleChangeText(text);
  }, [handleChangeText]);

  const handleGoogleSlidesPress = useCallback(() => {
    if (isWeb) {
      window.open('https://docs.google.com/presentation/', '_blank');
      return;
    }

    Linking.openURL('https://docs.google.com/presentation/');
  }, []);

  const handleContinuePress = useCallback(async () => {
    if (media?.original_embed === initialMedia?.original_embed) {
      handleGoBack({ openImportMediaPanel: false });
      return;
    }

    const { src, width, height, delayms, start, loop } = getAttributesFromString(urlInput);

    const formattedMedia: GoogleSlidesMedia = {
      loop,
      start,
      width,
      height,
      delayms,
      media_url: src,
      original_embed: urlInput,
      embed_version: EMBED_VERSION,
      file_type: EFileType.GOOGLE_SLIDES,
    };

    setGoogleSlidesMedia(formattedMedia);
    handleGoBack({ openImportMediaPanel: false });
  }, [
    getAttributesFromString,
    handleGoBack,
    initialMedia?.original_embed,
    media?.original_embed,
    setGoogleSlidesMedia,
    urlInput,
  ]);

  useEffect(() => {
    if (initialMedia?.original_embed) {
      processInput(initialMedia?.original_embed);
    }
  }, [processInput, initialMedia?.original_embed]);

  const renderHeaderRight = useCallback(() => {
    return (
      <S.HeaderButton onPress={handleGoogleSlidesPress}>
        <GoogleSlidesIcon width={calcWidth(40)} height={calcWidth(40)} />
      </S.HeaderButton>
    );
  }, [handleGoogleSlidesPress]);

  const handleCloseExplanationVideoModal = useCallback(() => {
    setExplanationVideoModalOpen(false);
  }, []);

  const handleOpenExplanationVideoModal = useCallback(() => {
    setExplanationVideoModalOpen(true);
  }, []);

  const handleBackButtonPress = useCallback(() => {
    handleGoBack({ openImportMediaPanel: true });
  }, [handleGoBack]);

  return (
    <>
      <S.Container>
        <Header
          title={t('googleSlides.title')}
          headerRight={renderHeaderRight}
          onBackButtonPress={handleBackButtonPress}
        />
        <S.ScrollContainer bounces={false}>
          <UrlInput
            onCopyFromClipboard={handleCopyFromClipboard}
            onCleanUrl={handleCleanUrl}
            onChangeText={handleChangeText}
            value={urlInput}
            placeholder={t('googleSlides.inputPlaceholder')}
            selectTextOnFocus
            fullWidth
          />
          <S.WebViewContainer contentSizes={media ? { width: media.width, height: media.height } : null}>
            {isLoading ? (
              <ActivityIndicator size='large' color={theme.colors.primaryBlue} />
            ) : media?.media_url?.length ? (
              <SlidesPlayer url={media?.media_url} initialSizes={{ width: media.width, height: media.height }} />
            ) : (
              <S.Empty>
                {urlInput.length && !!errorCode ? (
                  <S.ErrorText>{t(`googleSlides.errors.${errorCode}`)}</S.ErrorText>
                ) : (
                  <S.EmptyText />
                )}
              </S.Empty>
            )}
          </S.WebViewContainer>
          {!!media?.media_url?.length && (
            <S.ContinueButton
              onPress={handleContinuePress}
              content={t('common.Continue')}
              dataSet={continueFromGoogleSlides}
            />
          )}
        </S.ScrollContainer>
      </S.Container>
      <HowToGetTheLink onReadMorePress={handleOpenExplanationVideoModal} />
      <VideoModal
        videoUrl={GoogleSlidesImportVideoId}
        isVisible={isExplanationVideoModalOpen}
        onBtnRightClick={handleCloseExplanationVideoModal}
        btnRightLabel={t('common.Close')}
        btnRightStyle={'primary'}
        isAutoPlay
        isMutedInitial={false}
        videoLinkRequired
      />
    </>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    align-items: center;
  `,
  WebView: styled(WebView)`
    width: ${deviceWidth - calcWidth(40)}px;
    height: ${(9 / 16) * (deviceWidth - calcWidth(40))}px;
  `,
  WebViewContainer: styled.View<{ contentSizes?: { width: number; height: number } }>`
    margin-top: ${calcHeight(25)}px;
    width: ${({ contentSizes }) => contentSizes?.width || deviceWidth - calcWidth(40)}px;
    height: ${({ contentSizes }) => (contentSizes ? contentSizes.height : (9 / 16) * (deviceWidth - calcWidth(40)))}px;
    border-radius: 15px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    align-self: center;
  `,
  Empty: styled.View`
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.shadowColor};
    align-items: center;
    justify-content: center;
  `,
  EmptyText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
  `,
  ErrorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    color: ${({ theme }) => theme.colors.pinkChoose};
    text-align: center;
  `,
  HeaderButton: styled.TouchableOpacity`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    justify-content: center;
    align-items: center;
  `,
  ContinueButton: styled(GenericButton)`
    margin: ${calcHeight(25)}px ${calcHeight(5)}px;
    align-self: flex-end;
  `,
  ScrollContainer: styled.ScrollView`
    margin-top: ${calcHeight(15)}px;
    padding-bottom: ${isWeb ? calcHeight(100) : 0}px;
    flex: 1;
  `,
};

export default GoogleSlides;
