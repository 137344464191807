import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../../../modals/ModalController';
import { I18nManager } from 'react-native';
import styled from 'styled-components/native';
import BackButton from '../../../shared/BackButton';
import { calcHeight, calcWidth, deviceWidth, isWeb, WEB_CONTAINER_WIDTH } from '../../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadContentLocales } from '../../../../store/appActivity/appActivity.slice';
import { contentLocalesSelector } from '../../../../store/appActivity/appActivity.selectors';
import GoogleTranslateIcon from '../../../../assets/icons/google-translate.svg';
import { IContentLocale } from '../../../../store/appActivity/appActivity.types';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onLocaleSelect: (locale: IContentLocale) => void;
}

const LanguageSelectModal: React.FC<IProps> = ({ isVisible, onClose, onLocaleSelect }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const locales = useSelector(contentLocalesSelector);

  const [searchValue, setSearchValue] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const localesWithLabels = useMemo(() => {
    return (
      locales?.map((localeData) => ({
        ...localeData,
        label: t(`locales.${localeData.locale}`),
      })) || []
    );
  }, [locales, t]);

  const displayLocales = useMemo(() => {
    if (!searchValue) {
      return localesWithLabels;
    }
    const searchValueLowerCase = searchValue.toLowerCase();
    return localesWithLabels.filter((localeData) => localeData.label.toLowerCase().includes(searchValueLowerCase));
  }, [searchValue, localesWithLabels]);

  useEffect(() => {
    if (isVisible) {
      setSearchValue('');
    }
  }, [isVisible]);

  useEffect(() => {
    dispatch(loadContentLocales());
  }, [dispatch]);

  const renderLanguage = useCallback(
    ({ item }) => {
      return <Locale localeData={item} onLocaleSelect={onLocaleSelect} />;
    },
    [onLocaleSelect],
  );

  const handleFocus = useCallback(() => {
    setIsSearchFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsSearchFocused(false);
  }, []);

  return (
    <Modal isVisible={isVisible}>
      <S.Background>
        <S.Container>
          <S.Header>
            <BackButton onPress={onClose} />
            <S.SearchInput
              isFocused={isSearchFocused}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={searchValue}
              onChangeText={setSearchValue}
              placeholder={t('languageSelectModal.searchPlaceholder')}
            />
          </S.Header>
          <S.GoogleTranslateContainer>
            <S.GoogleTranslateText>
              <S.GoogleTranslateTextBold>{'FYI '}</S.GoogleTranslateTextBold>
              {t('languageSelectModal.googleTranslate')}
            </S.GoogleTranslateText>
            <S.GoogleTranslateIconContainer>
              <S.GoogleTranslateIconText>by</S.GoogleTranslateIconText>

              <GoogleTranslateIcon width={calcWidth(24)} height={calcHeight(24)} />
            </S.GoogleTranslateIconContainer>
          </S.GoogleTranslateContainer>
          <S.FlatList data={displayLocales} renderItem={renderLanguage} />
        </S.Container>
      </S.Background>
    </Modal>
  );
};

const Locale = ({ localeData, onLocaleSelect }) => {
  const { t } = useTranslation();

  const handlePress = useCallback(() => {
    onLocaleSelect(localeData);
  }, [localeData, onLocaleSelect]);

  return (
    <S.LanguageTextContainer onPress={handlePress}>
      <S.LanguageText>{t(`locales.${localeData.locale}`)}</S.LanguageText>
    </S.LanguageTextContainer>
  );
};

const S = {
  FlatList: styled.FlatList`
    width: 100%;
    padding: 0 ${calcWidth(20)}px;
    margin-top: ${calcHeight(16)}px;
  `,
  LanguageText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    color: ${({ theme }) => theme.colors.text};
    margin: ${calcHeight(15)}px 0;
    text-align: center;
  `,
  Container: styled.SafeAreaView`
    width: ${WEB_CONTAINER_WIDTH}px;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  Header: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 50px;
    margin-top: ${calcHeight(20)}px;
    padding: 0 ${calcWidth(20)}px;
  `,
  SearchInput: styled.TextInput<{ isFocused: boolean }>`
    width: ${deviceWidth - calcWidth(120)}px;
    justify-content: center;
    padding: ${calcWidth(5)}px;
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.black};
    text-align: ${I18nManager.isRTL && !isWeb ? 'right' : 'left'};
    opacity: 0.5;
    border-width: 1px;
    border-color: ${({ isFocused, theme }) => (isFocused ? theme.colors.primaryBlue : theme.colors.borderGray)};
    height: ${isWeb ? 40 : 50}px;
    border-radius: 100px;
    padding: 0 ${calcWidth(20)}px;
    margin: 0 ${calcWidth(20)}px;
  `,
  Background: styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  GoogleTranslateContainer: styled.View`
    flex-direction: row;
    max-width: ${deviceWidth - calcWidth(60)}px;
    margin-top: ${calcHeight(27)}px;
    border: 1px solid ${({ theme }) => theme.colors.orangeChoose};
    border-radius: 10px;
    padding: ${calcHeight(8)}px ${calcWidth(13)}px;
    min-height: ${calcHeight(50)}px;
    align-items: center;
    justify-content: center;
  `,
  GoogleTranslateText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    line-height: ${({ theme }) => theme.fontSizes.s17};
    margin: 0 ${calcWidth(10)}px;
  `,
  GoogleTranslateTextBold: styled.Text`
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    line-height: ${({ theme }) => theme.fontSizes.s17};
  `,
  GoogleTranslateIconContainer: styled.View`
    flex-direction: row;
  `,
  GoogleTranslateIconText: styled.Text`
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s10};
    color: ${({ theme }) => theme.colors.gray19};
    margin-top: ${calcHeight(-4)}px;
    margin-right: ${calcWidth(2)}px;
  `,
  LanguageTextContainer: styled.TouchableOpacity``,
};

export default memo(LanguageSelectModal);
