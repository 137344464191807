import { RootState } from '../';
export const biteQuestionSelector = (state: RootState) => state.biteQuestion;
export const isBiteQuestionCreatedSelector = (state: RootState) => state.biteQuestion.isCreated;
export const questionMediaUploadingSelector = (state: RootState) => {
  const questionMediaUploading = biteQuestionSelector(state).mediaUploading;
  const questionMediaUploadingError = biteQuestionSelector(state).mediaUploadingError;
  return {
    questionMediaUploading,
    questionMediaUploadingError,
  };
};
