import { IAppConfig } from '../../types/env';
import { EEnvironment } from './common';
import { ENV } from './env';

const CONFIGS: { [key in EEnvironment]: IAppConfig } = {
  [EEnvironment.QA]: {
    googleClientId: '872512406703-7hg92vbjmh8sk7tdfusnm7psngfa08q5.apps.googleusercontent.com',
    azureClientId: '746e6bcb-cba5-46c9-9f76-c83c366537e2',
  },
  [EEnvironment.PROD]: {
    googleClientId: '885796386880-95l4tnci4dr9hnkqmpv1gvgolf7kknks.apps.googleusercontent.com',
    azureClientId: '746e6bcb-cba5-46c9-9f76-c83c366537e2',
  },
};

export default CONFIGS[ENV];
