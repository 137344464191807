import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShowPanelPayload } from './bottomPanel';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';

interface IState {
  isVisible: boolean;
  componentName: EBottomPanelComponentName | null;
  componentProps: Record<string, any>;
}

const initialState: IState = {
  isVisible: false,
  componentName: null,
  componentProps: {},
};

const BOTTOM_PANEL = 'BOTTOM_PANEL';

const bottomPanelSlice = createSlice({
  name: BOTTOM_PANEL,
  initialState,
  reducers: {
    showBottomPanel: (state, { payload }: PayloadAction<IShowPanelPayload>) => {
      const { componentName, componentProps = {} } = payload;
      state.componentName = componentName;
      state.componentProps = componentProps;
      state.isVisible = true;
    },
    hideBottomPanel: (state) => {
      state.isVisible = false;
    },
    clearBottomPanel: () => {
      return initialState;
    },
  },
});

export const { showBottomPanel, hideBottomPanel, clearBottomPanel } = bottomPanelSlice.actions;

export default bottomPanelSlice.reducer;
