import { IMedia } from './media';

export enum EQuestionType {
  OPEN_ENDED = 'open ended',
  MULTI_CHOICE = 'multiple choices',
  SURVEY = 'survey',
}

export type ScreenOfScreensOrder = 'TypeYourQuestion' | 'AddAnswers' | 'MarkCorrectAnswer';

export interface IBiteAnswer {
  id: number;
  text: string;
  order: number | null;
  is_correct?: boolean;
  question?: number;
  created_at?: Date;
}

export type QuestionMedia = null | IMedia;

export interface IBiteQuestion {
  text: string;
  type: EQuestionType;
  media: IMedia | null;
  answers: IBiteAnswer[];
}
