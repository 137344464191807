import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { biteSelector } from '../../store/bite/bite.selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearQuestions,
  resetAnswers,
  setCreatedQuestion,
  setQuestionType,
} from '../../store/biteQuestion/biteQuestion.actions';
import RightArrowIcon from '../../assets/icons/creationFlow/right-arrow-icon.svg';
import { biteQuestionSelector } from '../../store/biteQuestion/biteQuestion.selectors';
import { useIsFocused } from '@react-navigation/native';
import { IStackNavigation } from '../../navigation/types';
import { deleteBiteQuestion, resetQuestionSuggestion } from '../../store/createBite/createBites.actions';
import { useIsMounted } from '../../hooks/useIsMounted';
import { EQuestionType } from '../../types/biteQuestion';
import { isEmpty } from 'lodash';
import Routes from '../../navigation/routes';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import BlueAndRedButtonsModal from '../../components/modals/BlueAndRedButtonsModal';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import AddSurveyQuestion from './EditQuestion/AddSurveyQuestion';
import AddOpenEndedQuestion from './EditQuestion/AddOpenEndedQuestion';
import AddMultiChoiceQuestion from './EditQuestion/AddMultiChoiceQuestion';
import QuestionButtons from './EditQuestion/AddQuestion/common/QuestionButtons';
import BaseModal from '../creationBite/common/BaseModal';
import ShadowedContainer from '../../components/ShadowedContainer';
import PointsIcon from '../../assets/icons/creationFlow/buttons-rounded-icon.svg';
import { TouchableOpacity } from 'react-native';
import QuestionSettingsModal from './QuestionSettingsModal';
import DeleteButton from '../../components/shared/buttons/DeleteButton';
import { confirmDeleteQuestionDataset } from './constants';

interface IProps extends IStackNavigation {
  withDelete?: boolean;
  onContinue: (setLoading, isMountRef, inputValue?) => void;
  from: string;
}

const EditQuestion: React.FC<IProps> = ({ route, navigation, withDelete, onContinue, from }) => {
  const theme = useTheme();
  const isEditMode = route?.params?.isEditMode;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const isMountRef = useIsMounted();

  const { type, isCreated, answers } = useSelector(biteQuestionSelector);
  const { selectedBite } = useSelector(biteSelector);

  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isReplaceModalVisible, setReplaceModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  const openSettingsModal = useCallback(() => setIsSettingsModalVisible(true), []);

  const closeSettingsModal = useCallback(() => setIsSettingsModalVisible(false), []);

  const handleOpenDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
    closeSettingsModal();
  }, [closeSettingsModal]);

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const handleOpenReplaceModal = useCallback(() => {
    closeSettingsModal();
    handleCloseDeleteModal();
    setReplaceModalVisible(true);
  }, [closeSettingsModal, handleCloseDeleteModal]);

  const handleCloseReplaceModal = useCallback(() => {
    setReplaceModalVisible(false);
  }, []);

  const isTypeSurvey = type === EQuestionType.SURVEY;

  const questionTypeString = useMemo(() => {
    if (type === EQuestionType.OPEN_ENDED) {
      return t('addQuestion.openEnded');
    }

    if (type === EQuestionType.MULTI_CHOICE) {
      return t('addQuestion.multiChoice');
    }

    if (type === EQuestionType.SURVEY) {
      return t('addQuestion.survey');
    }
  }, [type, t]);

  const clearQuestionState = useCallback(() => {
    dispatch(clearQuestions());
  }, [dispatch]);

  const handleDeleteComplete = useCallback(() => {
    if (!isMountRef.current) {
      return;
    }

    clearQuestionState();
    handleCloseDeleteModal();

    if (isEditMode) {
      navigation.goBack();
      return;
    }

    navigation.replace(Routes.CreateBiteStack.AddQuestion);
  }, [clearQuestionState, handleCloseDeleteModal, isEditMode, isMountRef, navigation]);

  const handleRemoveSection = useCallback(async () => {
    dispatch(setCreatedQuestion(false));
    dispatch(resetQuestionSuggestion());
    dispatch(
      deleteBiteQuestion({
        biteId: selectedBite?.id,
        callback: handleDeleteComplete,
      }),
    );
  }, [dispatch, handleDeleteComplete, selectedBite?.id]);

  const addMultiChoiceQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.MULTI_CHOICE, from },
      }),
    );

    if (isEmpty(answers)) {
      dispatch(resetAnswers());
    }

    dispatch(setQuestionType(EQuestionType.MULTI_CHOICE));
    handleCloseReplaceModal();
  }, [answers, dispatch, from, handleCloseReplaceModal]);

  const addOpenEndedQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.OPEN_ENDED, from },
      }),
    );

    dispatch(setQuestionType(EQuestionType.OPEN_ENDED));
    handleCloseReplaceModal();
  }, [dispatch, from, handleCloseReplaceModal]);

  const addSurveyQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.SURVEY, from },
      }),
    );
    if (isEmpty(answers)) {
      dispatch(resetAnswers());
    }

    dispatch(setQuestionType(EQuestionType.SURVEY));
    handleCloseReplaceModal();
  }, [answers, dispatch, from, handleCloseReplaceModal]);

  const renderSettingsLeftButton = useCallback(() => {
    return (
      <TouchableOpacity onPress={handleOpenDeleteModal}>
        <S.ButtonText>{t('common.Delete')}</S.ButtonText>
      </TouchableOpacity>
    );
  }, [handleOpenDeleteModal, t]);

  const renderHeaderRight = useCallback(() => {
    if (isTypeSurvey) {
      return (
        <ShadowedContainer distance={25}>
          <S.PointsButton onPress={openSettingsModal}>
            <PointsIcon />
          </S.PointsButton>
        </ShadowedContainer>
      );
    }

    if (withDelete && (isCreated || isEditMode)) {
      return (
        <>
          <DeleteButton width={calcWidth(41)} height={calcWidth(41)} onPress={handleOpenDeleteModal} />
        </>
      );
    }
    return <S.EmptyView />;
  }, [handleOpenDeleteModal, isCreated, isEditMode, isTypeSurvey, openSettingsModal, withDelete]);

  const renderHeaderTitle = useCallback(
    () => (
      <S.TitleWrapper onPress={handleOpenReplaceModal}>
        <S.Title>{questionTypeString}</S.Title>
        <S.IconWrapper>
          <RightArrowIcon color={theme.colors.primaryBlue} width={8} height={15} />
        </S.IconWrapper>
      </S.TitleWrapper>
    ),
    [handleOpenReplaceModal, questionTypeString, theme.colors.primaryBlue],
  );

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    navigation.setOptions({
      headerRight: renderHeaderRight,
      headerTitle: renderHeaderTitle,
    });
  }, [isFocused, navigation, renderHeaderRight, renderHeaderTitle]);

  const handleContinue = useCallback(
    (inputValue) => {
      onContinue(setLoading, isMountRef, inputValue);
    },
    [isMountRef, onContinue],
  );

  const currentQuestion = useMemo(() => {
    if (type === EQuestionType.OPEN_ENDED) {
      return (
        <AddOpenEndedQuestion isLoading={isLoading} onContinue={handleContinue} isEditMode={isEditMode} from={from} />
      );
    }

    if (type === EQuestionType.MULTI_CHOICE) {
      return (
        <AddMultiChoiceQuestion isLoading={isLoading} onContinue={handleContinue} isEditMode={isEditMode} from={from} />
      );
    }

    if (type === EQuestionType.SURVEY) {
      return (
        <AddSurveyQuestion isLoading={isLoading} onContinue={handleContinue} isEditMode={isEditMode} from={from} />
      );
    }
  }, [type, isLoading, handleContinue, isEditMode, from]);

  return (
    <>
      <S.Wrapper>{currentQuestion}</S.Wrapper>
      <QuestionSettingsModal
        renderLeftButton={withDelete && (isCreated || isEditMode) ? renderSettingsLeftButton : null}
        onClose={closeSettingsModal}
        isVisible={isSettingsModalVisible}
      />
      <BlueAndRedButtonsModal
        title={t('confirmDeleteSection.question.title')}
        description={t('confirmDeleteSection.question.description')}
        isVisible={isDeleteModalVisible}
        onClose={handleCloseDeleteModal}
        onRightButtonClick={handleRemoveSection}
        onLeftButtonClick={handleOpenReplaceModal}
        rightButtonLabel={t('confirmDeleteModal.delete')}
        leftButtonLabel={t('confirmDeleteModal.replace')}
        dataSet={confirmDeleteQuestionDataset}
      />
      <BaseModal
        title={t('replaceModal.questions.title')}
        description={t('replaceModal.questions.description')}
        isVisible={isReplaceModalVisible}
        onClose={handleCloseReplaceModal}
      >
        <QuestionButtons
          selectedQuestionType={type}
          addSurveyQuestion={addSurveyQuestion}
          addMultiChoiceQuestion={addMultiChoiceQuestion}
          addOpenEndedQuestion={addOpenEndedQuestion}
        />
      </BaseModal>
    </>
  );
};

const S = {
  Wrapper: styled.View`
    flex: 1;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    color: ${({ theme }) => theme.colors.darkPrimary};
  `,
  TitleWrapper: styled.TouchableOpacity`
    align-items: center;
    top: ${calcHeight(10)}px;
  `,
  IconWrapper: styled.View`
    transform: rotate(90deg);
  `,
  PointsButton: styled.TouchableOpacity``,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.failRed};
  `,
  EmptyView: styled.View`
    width: ${calcWidth(40)}px;
  `,
};

export default EditQuestion;
