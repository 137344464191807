export const sortingType = { cy: 'sorting-style-button' };
export const feedControlsTypeOptionsDataSet = { cy: 'feed-controls-type-options' };
export const typeSelected = { cy: 'type-selected-button' };
export const selectBiteItemDataSet = { cy: 'select-bite-item' };
export const selectQuizItemDataSet = { cy: 'select-quiz-item' };
export const selectPlaylistItemDataSet = { cy: 'select-playlist-item' };

export const searchInputDataSet = { cy: 'search-input' };
export const searchClearInputDataSet = { cy: 'search-clear-input' };
export const searchButtonDataSet = { cy: 'search-button' };

export const searchCancelButtonDataSet = { cy: 'search-cancel-button' };
