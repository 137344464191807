import ShadowedContainer from '../../ShadowedContainer';
import CloseIcon from '../../../assets/icons/close.svg';
import StockIcon from '../../../assets/icons/creationFlow/stock-icon.svg';
import GalleryIcon from '../../../assets/icons/creationFlow/gallery-icon.svg';
import CameraIcon from '../../../assets/icons/creationFlow/camera-icon.svg';
import ChevronRightIcon from '../../../assets/icons/menu/chevron-right.svg';
import React, { memo, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth } from '../../../utils/dimensions';
import { StyleProp, ViewStyle } from 'react-native';

interface IProps {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  icon: EButtonIcon;
  isPressed?: boolean;
  dataSet?: { [key: string]: string };
}

export enum EButtonIcon {
  CLOSE,
  CAMERA,
  SERACH_ONLINE,
  GALLERY,
  ARROW,
}

const CircleButton: React.FC<IProps> = ({ onPress, style, icon, isPressed, dataSet }) => {
  const theme = useTheme();

  const offset: [x: string | number, y: string | number] = useMemo(() => {
    return isPressed ? [0, 0] : [0, 7];
  }, [isPressed]);

  const distance = isPressed ? 100 : 24;

  return (
    <ShadowedContainer containerViewStyle={style} distance={distance} offset={offset}>
      <S.Button
        onPress={onPress}
        isPressed={isPressed}
        //@ts-ignore
        dataSet={dataSet}
      >
        {icon === EButtonIcon.CLOSE ? (
          <CloseIcon fill={theme.colors.primaryBlue} />
        ) : icon === EButtonIcon.CAMERA ? (
          <CameraIcon color={theme.colors.primaryBlue} />
        ) : icon === EButtonIcon.GALLERY ? (
          <GalleryIcon color={theme.colors.primaryBlue} />
        ) : icon === EButtonIcon.SERACH_ONLINE ? (
          <StockIcon color={theme.colors.primaryBlue} />
        ) : icon === EButtonIcon.ARROW ? (
          <ChevronRightIcon color={theme.colors.primaryBlue} />
        ) : null}
      </S.Button>
    </ShadowedContainer>
  );
};

const S = {
  Button: styled.TouchableOpacity<{ isPressed: boolean }>`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    border-radius: ${calcWidth(40 / 2)}px;
    padding: ${calcWidth(10)}px;
    background-color: ${({ theme, isPressed }) => (isPressed ? theme.colors.gray26 : theme.colors.white)};
    ${({ isPressed, theme }) => (isPressed ? `border: solid 1px ${theme.colors.gray27};` : '')}
  `,
};

export default memo(CircleButton);
