import React from 'react';
import { useSelector } from 'react-redux';
import { calcHeight, calcWidth, calcFontSize } from '../../utils/dimensions';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import EmptyListIcon from '../../assets/icons/empty-list.svg';
import { Button1 } from '../../components/shared/buttons';
import { activeOrganizationSelector } from '../../store/auth/auth.selectors';

interface IProps {
  isSearch?: boolean;
  message: string;
  createMessage?: string;
  onCreate?: () => void;
}

const EmptyList: React.FC<IProps> = ({ isSearch, message, createMessage, onCreate }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const activeOrganization = useSelector(activeOrganizationSelector);

  return (
    <S.EmptyListContainer>
      <EmptyListIcon width={calcWidth(100)} height={calcWidth(100)} fill={theme.colors.lightblue4} />
      <S.Message>{isSearch ? t('homeScreen.emptySearchList') : message}</S.Message>
      {!isSearch && activeOrganization.is_creator && onCreate && (
        <S.Button onPress={onCreate}>{createMessage} </S.Button>
      )}
    </S.EmptyListContainer>
  );
};

const S = {
  EmptyListContainer: styled.View`
    height: 80%;
    justify-content: center;
    align-items: center;
  `,
  Message: styled.Text`
    margin-top: ${calcHeight(30)}px;
    font-size: ${calcFontSize(17)}px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray2};
  `,
  Button: styled(Button1)`
    margin-top: ${calcHeight(30)}px;
  `,
};
export default EmptyList;
