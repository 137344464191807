import React, { memo } from 'react';
import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import styled, { css } from 'styled-components';
import defaultTheme from '../../../../../themes';

interface IProps {
  isShifted?: boolean;
  label: string;
  isRtl?: boolean;
}

const Label: React.FC<IProps> = ({ isShifted, label, isRtl }) => {
  return (
    <S.LabelWrapper isRtl={isRtl} isShifted={isShifted}>
      <S.Label isShifted={isShifted}>{label}</S.Label>
    </S.LabelWrapper>
  );
};

const S = {
  LabelWrapper: styled.div<{ isShifted?: boolean; isRtl?: boolean }>`
    position: absolute;
    pointer-events: none;
    z-index: 2;
    transition: all 300ms;
    top: ${calcHeight(19)}px;
    ${({ isRtl, isShifted }) =>
      isRtl
        ? css`
            right: 22px;
            transform: translateX(${isShifted ? 3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `
        : css`
            left: 22px;
            transform: translateX(${isShifted ? -3 : 0}px) translateY(${isShifted ? -25 : 0}px)
              scale(${isShifted ? 0.75 : 1});
          `}

    background-color: ${({ isShifted }) => (isShifted ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)')};
  `,
  Label: styled.div<{ isShifted?: boolean }>`
    font-family: 'Roboto', sans-serif;
    line-height: 12px;
    color: ${defaultTheme.colors.gray19};
    padding: 0 ${calcWidth(5)}px;
    margin-bottom: ${calcHeight(-4)}px;
  `,
};

export default memo(Label);
