import { RootState } from '../index';
import { videoEditorData } from './videoEditor.data';
import { ITimelineItem } from './videoEditor.types';

export const getState = (state: RootState) => state.videoEditor;

// existing videos
export const existingVideosSelector = (state: RootState) => getState(state).existingVideos.data;
export const existingVideosPageSelector = (state: RootState) => getState(state).existingVideos.page;
export const existingVideosHasMoreSelector = (state: RootState) => getState(state).existingVideos.hasMore;
export const isExistingVideosLoadingSelector = (state: RootState) => getState(state).existingVideos.isLoading;
export const existingVideosLoadingErrorSelector = (state: RootState) => getState(state).existingVideos.loadingError;

// video
export const videoSelector = (state: RootState) => getState(state).video.data;
export const isVideoSavingSeletor = (state: RootState) => getState(state).video.isSaving;
export const isVideoAutoSavingSeletor = (state: RootState) => getState(state).video.isAutoSaving;
export const isVideoLoadingSeletor = (state: RootState) => getState(state).video.isLoading;
export const videoLoadingErrorSeletor = (state: RootState) => getState(state).video.loadingError;
export const videoSavingErrorSeletor = (state: RootState) => getState(state).video.savingError;

// exportedVideo
export const exportedVideoSeletor = (state: RootState) => getState(state).exportedVideo.data;
export const isExportedVideoLoadingSeletor = (state: RootState) => getState(state).exportedVideo.isLoading;
export const exportedVideoErrorSeletor = (state: RootState) => getState(state).exportedVideo.error;

// videoConfig
export const videoConfigSeletor = (state: RootState) => getState(state).video.data.config;
export const videoDurationSeletor = (state: RootState) => getState(state).video.data?.config.duration;
export const videoResolutionSeletor = (state: RootState) => getState(state).video.data?.config.resolution;
export const videoTimelineLayersSeletor = (state: RootState) => getState(state).video.data?.config.timelineLayers;
export const timelineItemSeletor = (timelineItemId: ITimelineItem['id']) => (state: RootState) =>
  timelineItemId ? getState(state).video.data?.config.itemsMap[timelineItemId] || null : null;
export const selectedTimelineLayerIdSelector = (state: RootState) => getState(state).selectedTimelineLayerId;
export const selectedTimelineItemLayerSelector = (state: RootState) => {
  const slice = getState(state);

  if (!slice.selectedTimelineItemId) {
    return null;
  }

  const layer = slice.video.data.config.timelineLayers.find(({ timeline }) =>
    timeline.includes(slice.selectedTimelineItemId),
  );

  return layer;
};
export const selectedTimelineItemIdSelector = (state: RootState) => getState(state).selectedTimelineItemId;
export const recordingTimelineItemIdSelector = (state: RootState) => getState(state).recordingTimelineItemId;

// drag and drop
export const ghostTimelineItemMetaSelector = (state: RootState) => getState(state).ghostTimelineItemMeta;
export const ghostTimelineLayerPrevStateSelector = (state: RootState) => getState(state).ghostTimelineLayerPrevState;
export const draggingCanvasItemMetaSelector = (state: RootState) => getState(state).draggingCanvasItemMeta;

// trim
export const trimTimelineItemMetaSelector = (state: RootState) => getState(state).trimTimelineItemMeta;

// UI state
export const isTimelineExpandedSeletor = (state: RootState) => getState(state).isTimelineExpanded;
export const timelineHeightSeletor = (state: RootState) => getState(state).timelineHeight;

// undo/redo
export const undoStackSelector = (state: RootState) => getState(state).undoStack;
export const redoStackSelector = (state: RootState) => getState(state).redoStack;

// timeline
export const timelineScaleSeletor = (state: RootState) => getState(state).timelineScale;
export const canvasItemsSeletor = (state: RootState) => getState(state).canvasItems;
export const isPlayingSeletor = (state: RootState) => getState(state).isPlaying;
export const currentSecondsSeletor = (state: RootState) => getState(state).currentSeconds;
export const currentSecondsWithIsPlayingSeletor = (state: RootState) => {
  const slice = getState(state);

  if (!slice.isPlaying || !videoEditorData.startPlayingTs) {
    return slice.currentSeconds;
  }

  return slice.currentSeconds + (Date.now() - videoEditorData.startPlayingTs) / 1000;
};
export const isSplitModeSeletor = (state: RootState) => getState(state).isSplitMode;
export const copiedTimelineItemSelector = (state: RootState) => getState(state).copiedTimelineItem;

export const processingTimelineItemsSelector = (state: RootState) => getState(state).processingTimelineItems;
export const processingTimelineItemSelector = (id: ITimelineItem['id']) => (state: RootState) =>
  id ? getState(state).processingTimelineItems.find(({ timelineItemId }) => timelineItemId === id) || null : null;

// window.getStore = () => store.getState();

// const allTimlineItemTypes = ['video', 'text', 'image', 'gif', 'shape', 'group', 'audio'] as ITimelineItem['type'][];
export const acceptTypesByTimelineLayerIdSelector = (state: RootState) => getState(state).acceptTypesByTimelineLayerId;
export const timelineSnapSelector = (state: RootState) => getState(state).timelineSnap;
