import React, { FC, memo, useEffect, useMemo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import StarIcon from '../../../../assets/icons/star-icon.svg';
import StarOutlinedIcon from '../../../../assets/icons/star-outlined-icon.svg';
import { introMediaProcessingStatusSelector } from '../../../../store/createBite/createBite.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { EIntroMediaProcessingStatus } from '../../../../store/createBite/createBite.types';
import { useTranslation } from 'react-i18next';

import { log } from '../../../../store/appActivity/appActivity.slice';
import { EImproves, improveToIntroMediaProcessingStatusFieldMap } from '../aiExpectation.constants';

interface IProps {
  improves: EImproves[];
}

const ImprovesList: FC<IProps> = ({ improves }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);

  const [doneList, processingList] = useMemo(() => {
    const doneListResult = [];
    const processingListResult = [];

    improves.forEach((improve) => {
      const statusField = improveToIntroMediaProcessingStatusFieldMap[improve];
      const status = introMediaProcessingStatus[statusField];

      if (status === EIntroMediaProcessingStatus.SUCCESS) {
        doneListResult.push(improve);
        return;
      }
      if (status === EIntroMediaProcessingStatus.PROCESSING) {
        processingListResult.push(improve);
        return;
      }
      if (
        (improve === EImproves.soundQuality || improve === EImproves.backgroundNoise) &&
        introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING &&
        introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.INACTIVE
      ) {
        processingListResult.push(improve);
      }
    });

    return [doneListResult, processingListResult];
  }, [improves, introMediaProcessingStatus]);

  useEffect(() => {
    dispatch(
      log({
        event: 'ImprovesList: render',
        data: {
          improves,
          doneList,
          processingList,
        },
      }),
    );
  }, [dispatch, doneList, improves, processingList]);

  return (
    <S.ImprovesList>
      {doneList.map((improve) => (
        <S.ImproveItem key={improve}>
          <S.ProcessingIconContainer>
            <StarIcon />
          </S.ProcessingIconContainer>
          <S.ImproveItemText>{t(`aiReady.improves.${improve}`)}</S.ImproveItemText>
        </S.ImproveItem>
      ))}
      {processingList.map((improve) => (
        <S.ImproveItem key={improve}>
          <S.ActivityIndicator>
            <S.DoneIconContainer>
              <StarOutlinedIcon />
            </S.DoneIconContainer>
          </S.ActivityIndicator>
          <S.ImproveItemText>{t(`aiExpectations.improves.${improve}`)}</S.ImproveItemText>
        </S.ImproveItem>
      ))}
    </S.ImprovesList>
  );
};

const S = {
  ImproveItem: styled.View`
    flex-direction: row;
    align-items: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  ActivityIndicator: styled.View`
    margin-right: ${calcHeight(8)}px;
  `,
  ImproveItemText: styled.Text`
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s20};
  `,
  ImprovesList: styled.View`
    height: ${calcHeight(320)}px;
    width: ${calcWidth(215)}px;
    margin-top: ${calcHeight(30)}px;
  `,
  ProcessingIconContainer: styled.View`
    height: ${calcHeight(15)}px;
    width: ${calcWidth(15)}px;
    margin-right: ${calcHeight(10)}px;
  `,
  DoneIconContainer: styled.View`
    height: ${calcHeight(15)}px;
    width: ${calcWidth(15)}px;
  `,
};

export default memo(ImprovesList);
