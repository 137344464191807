import React, { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import Header from '../Header';
import PhoneNumberInput from '../../../../components/shared/PhoneNumberInput/PhoneNumberInput';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isPhoneMissingSelector } from '../../../../store/auth/auth.selectors';
import { log } from '../../../../store/appActivity/appActivity.slice';
import Checkbox from '../../../../components/shared/Checkbox/Checkbox';
import { currentFlowSelector } from '../../../../store/authForm/authForm.selectors';
import { EAuthFlowType } from '../../auth.types';
import Agreement from '../Agreement';
import { getIsWebRtl, getIsRtl } from '../../../../locale/i18n';
import { IS_ANDROID } from '../../../../utils/constants/env';

interface IProps {
  onContinue: () => void;
  isLoading: boolean;
  onChange: (number: string) => void;
  phone: string;
  errorCodes: string[];
}

const PhoneNumberForm: React.FC<IProps> = ({ onContinue, isLoading, onChange, phone, errorCodes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isRtlWeb = getIsWebRtl();
  const isRtl = getIsRtl();

  const isPhoneMissing = useSelector(isPhoneMissingSelector);
  const currentFlow = useSelector(currentFlowSelector);

  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const handleAgreementChange = useCallback(() => {
    setIsAgreementChecked(!isAgreementChecked);
  }, [isAgreementChecked]);

  const requestErrors = useMemo(() => {
    if (errorCodes.includes('1')) {
      return t('authentication.landing.errorUserTaken');
    }

    if (errorCodes.includes('2')) {
      return t('authentication.landing.errorInvalidNumber');
    }

    if (isPhoneMissing) {
      return t('authentication.landing.phoneNumberNotExists');
    }

    return null;
  }, [isPhoneMissing, errorCodes, t]);

  const handleContinue = useCallback(() => {
    if (isLoading) {
      return;
    }

    dispatch(
      log({
        event: 'PhoneNumberForm.handleContinue',
        data: {
          errorCodes,
          phone,
          isValid,
        },
      }),
    );

    if (!isValid) {
      setError(t('authForm.phoneNumberForm.phoneNotValid'));
      return;
    }

    onContinue();
  }, [isLoading, dispatch, errorCodes, phone, isValid, onContinue, t]);

  const handlePhoneNumberChange = useCallback(
    (number: string) => {
      onChange(number);
      setError(null);
    },
    [onChange],
  );

  return (
    <S.Content entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <Header title={t('authForm.phoneNumberForm.title')} />
      <S.Form>
        <S.PhoneNumberInput
          onValidationChange={setIsValid}
          label={t('authForm.phoneNumberForm.phoneNumber')}
          phoneNumber={phone}
          onPhoneNumberChange={handlePhoneNumberChange}
          error={error || requestErrors}
          onEnterPress={handleContinue}
          isEditMode
        />
        {currentFlow === EAuthFlowType.SIGN_UP && (
          <S.CheckboxContainer isRtlWeb={isRtlWeb} activeOpacity={1} onPress={handleAgreementChange}>
            <Checkbox style={S.CheckboxStyles} checked={isAgreementChecked} onChange={() => {}} />
            <S.CheckboxText isRtl={isRtl} isRtlWeb={isRtlWeb}>
              {t('authForm.phoneNumberForm.agreementToReceiveSms')}
            </S.CheckboxText>
          </S.CheckboxContainer>
        )}
        <S.ContinueButton isLoading={isLoading} onPress={handleContinue} content={t('common.Continue')} />
        <Agreement />
      </S.Form>
    </S.Content>
  );
};

const S = {
  CheckboxStyles: {
    alignSelf: 'flex-start',
    width: calcWidth(14),
    height: calcHeight(14),
  } as any,
  CheckboxContainer: styled.TouchableOpacity<{ isRtlWeb: boolean }>`
    align-self: center;
    justify-content: center;
    width: ${calcWidth(260)}px;
    margin-bottom: ${calcHeight(18)}px;
    flex-direction: ${({ isRtlWeb }) => (isRtlWeb ? 'row-reverse' : 'row')};
  `,
  CheckboxText: styled.Text<{ isRtl: boolean; isRtlWeb: boolean }>`
    padding-top: ${calcHeight(isWeb ? 3 : 0)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    color: ${({ theme }) => theme.colors.text};
    margin-left: ${({ isRtl }) => calcWidth(IS_ANDROID ? (isRtl ? 0 : 15) : 5)}px;
    margin-right: ${calcWidth(5)}px;
    text-align: ${({ isRtlWeb }) => (isRtlWeb ? 'right' : 'left')};
  `,
  Content: styled(Animated.View)``,
  Form: styled.View`
    margin-top: ${calcHeight(32)}px;
    align-items: center;
  `,
  PhoneNumberInput: styled(PhoneNumberInput)`
    margin-bottom: ${calcHeight(18)}px;
    height: ${calcHeight(50)}px;
    width: ${calcWidth(298)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
    margin-bottom: ${calcHeight(18)}px;
  `,
};

export default memo(PhoneNumberForm);
