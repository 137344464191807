import React from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, scale1, isWeb } from '../../../../../utils/dimensions';

interface IProps {
  setColor: (color: any) => void;
  activeColor: string;
  colors: string[];
}

const ColorPicker: React.FC<IProps> = ({ setColor, activeColor, colors }) => {
  return (
    <S.ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
      {colors.map((color, index) => (
        <S.ColorController
          key={color + index}
          color={color}
          isWeb={isWeb}
          onPress={() => setColor(color)}
          isActive={activeColor === color}
        />
      ))}
    </S.ScrollView>
  );
};

interface IColorControllerProps {
  color: string;
  isActive: boolean;
  isWeb: boolean;
}
const S = {
  ScrollView: styled.ScrollView``,
  ColorController: styled.TouchableOpacity<IColorControllerProps>`
    height: ${calcHeight(45)}px;
    width: ${calcHeight(45)}px;
    margin-right: ${calcWidth(5)}px;
    ${({ theme, color, isActive }) => css`
      background-color: ${color};
      border: ${scale1(2)}px solid ${theme.colors.lightGray1};
      ${isActive &&
      css`
        border: ${scale1(3)}px solid ${theme.colors.darkGray1};
      `}
    `}
  `,
};

export default ColorPicker;
