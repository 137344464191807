import React, { FC, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { IBiteItem, IntroSection, QuestionSection, SummarySection } from '../../types/bite';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import { CustomText } from '../shared';
import IntroIcon from '../../assets/icons/feed/intro-section-icon.svg';
import QuestionIcon from '../../assets/icons/feed/question-section-icon.svg';
import SummaryIcon from '../../assets/icons/feed/summary-section-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { biteInProgressSelector, biteItemSelector } from '../../store/bite/bite.selectors';
import ViewsIcon from '../../assets/icons/feed/views.svg';
import AnswersIcon from '../../assets/icons/feed/answers.svg';
import DiscussionIcon from '../../assets/icons/feed/discussion.svg';
import ShadowedContainer from '../ShadowedContainer';
import useBiteUploadingMediaStatus from '../../hooks/useBiteUploadingMediaStatus';
import { EIntroMediaProcessingStatus } from '../../store/createBite/createBite.types';
import BiteInPlaylistSelectedOverlay from './biteItemOverlays/BiteInPlaylistSelectedOverlay';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../navigation/routes';
import { fetchBiteToEditRequest } from '../../store/bite/bite.actions';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import WithoutCoverOverlay from './biteItemOverlays/WithoutCoverOverlay';
import { AWS_URL } from '../../utils/constants/env';
import UploadingOverlay from '../shared/UploadingOverlay';
import { EInProgressStatus, EInProgressTask } from '../../types/common';
import useIsDraft from '../../hooks/useIsDraft';
import useIsItemOwner from '../../hooks/useIsItemOwner';
import MediaStatusOverlays from './biteItemOverlays/MediaStatusOverlays';
import { isContentAdminSelector, userSelector } from '../../store/auth/auth.selectors';
import OwnerInitials from './biteItemOverlays/OwnerInitials';
import { selectBiteItemDataSet } from '../../screens/landing/Home/components/component.constants';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  biteId: number;
  isSmall?: boolean;
  index?: number;
  withStatistics?: boolean;
  withStatusOverlays?: boolean;
  withBiteInPlaylistSelectedOverlay?: boolean;
  onPress?: (options: {
    bite: IBiteItem;
    uploadingMediaStatus: EIntroMediaProcessingStatus;
    isDraft?: boolean;
  }) => void;
  onAddCover?: () => void;
}

const BiteItem: FC<IProps> = ({
  biteId,
  isSmall,
  withStatistics,
  withStatusOverlays,
  withBiteInPlaylistSelectedOverlay,
  onPress,
  onAddCover,
  index,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const theme = useTheme();

  const user = useSelector(userSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const isDraft = useIsDraft(bite?.id);
  const uploadingMediaStatus = useBiteUploadingMediaStatus(bite);
  const isBiteOwner = useIsItemOwner(bite);
  const isContentAdmin = useSelector(isContentAdminSelector);

  const questionSection = useMemo(() => {
    return bite?.bite_sections?.find((section) => section.type === 'question');
  }, [bite?.bite_sections]) as QuestionSection | undefined;

  const biteCoverUploadingTask = useSelector(biteInProgressSelector(biteId, EInProgressTask.COVER));
  const isLoading = biteCoverUploadingTask?.status === EInProgressStatus.IN_PROGRESS;

  const introSection = useMemo(() => {
    return bite?.bite_sections?.find((section) => section.type === 'intro');
  }, [bite?.bite_sections]) as IntroSection | undefined;

  const summarySection = useMemo(() => {
    return bite?.bite_sections?.find((section) => section.type === 'summary');
  }, [bite?.bite_sections]) as SummarySection;

  const iconsStyle = useMemo(
    () => ({
      marginRight: calcWidth(8),
      width: calcWidth(24),
      height: calcHeight(24),
    }),
    [],
  );

  const questionIconStyle = useMemo(
    () => ({
      marginRight: calcWidth(8),
      width: calcWidth(26),
      height: calcHeight(26),
    }),
    [],
  );

  const dataSet = useMemo(() => {
    return {
      'intercom-target': `feed-item-${index}`,
      ...selectBiteItemDataSet,
    };
  }, [index]);

  const image = useMemo(() => ({ uri: bite?.linked_cover_url || null }), [bite?.linked_cover_url]);
  const workspaceLogoUrl = bite?.branding?.logo_url ? `${AWS_URL}/${bite.branding.logo_url}` : null;

  const withWorkspaceLogo = !image.uri;
  const iconColor = withWorkspaceLogo ? theme.colors.black : theme.colors.white;

  const onOpenEdit = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'edit',
        props: { bite_id: bite?.id, is_draft: isDraft },
      }),
    );
  }, [bite?.id, dispatch, isDraft]);

  const handlePress = useCallback(() => {
    if (typeof onPress !== 'function') {
      return;
    }
    onPress({ bite, uploadingMediaStatus, isDraft });
  }, [bite, isDraft, onPress, uploadingMediaStatus]);

  const handleOpenIntro = useCallback(() => {
    onOpenEdit();
    dispatch(fetchBiteToEditRequest(biteId));
    navigation.navigate(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditMedia,
      params: { biteId },
    });
  }, [biteId, dispatch, navigation, onOpenEdit]);

  const handleOpenEnhancements = useCallback(() => {
    onOpenEdit();
    dispatch(fetchBiteToEditRequest(biteId));
    navigation.navigate(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditEnhancements,
      params: { biteId },
    });
  }, [biteId, dispatch, navigation, onOpenEdit]);

  if (!bite) {
    return null;
  }

  return (
    <S.Container
      isSmall={isSmall}
      onPress={handlePress}
      //@ts-ignore
      dataSet={dataSet}
    >
      <ShadowedContainer>
        <S.BiteImage isSmall={isSmall} source={image}>
          {withWorkspaceLogo && (
            <WithoutCoverOverlay
              onAddCover={isContentAdmin || isBiteOwner ? onAddCover : null}
              workspaceLogoUrl={workspaceLogoUrl}
            />
          )}
          <S.SectionsCounter>
            {introSection && <IntroIcon color={iconColor} style={iconsStyle} />}
            {questionSection && <QuestionIcon color={iconColor} style={questionIconStyle} />}
            {summarySection && <SummaryIcon color={iconColor} style={iconsStyle} />}
          </S.SectionsCounter>
          {withBiteInPlaylistSelectedOverlay && <BiteInPlaylistSelectedOverlay bite={bite} />}
          {withStatusOverlays && (
            <MediaStatusOverlays
              bite={bite}
              onOpenIntro={handleOpenIntro}
              onOpenEnhancements={handleOpenEnhancements}
            />
          )}
          {bite.owner !== user?.id && <S.OwnerInitials name={bite.owner_name} />}
          <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
        </S.BiteImage>
      </ShadowedContainer>
      <S.BiteTitle>{bite.subject}</S.BiteTitle>
      {withStatistics && (
        <S.StatsContainer>
          {introSection ? (
            <S.StatsItem>
              <S.StatsCounter>{bite.watched || 0}</S.StatsCounter>
              <ViewsIcon />
            </S.StatsItem>
          ) : (
            <S.EmptyView />
          )}
          {questionSection ? (
            <S.StatsItem>
              <S.StatsCounter>{bite.answers}</S.StatsCounter>
              <AnswersIcon />
            </S.StatsItem>
          ) : (
            <S.EmptyView />
          )}
          {bite.discussion_enabled ? (
            <S.StatsItem>
              <S.StatsCounter>{bite.comments || 0}</S.StatsCounter>
              <DiscussionIcon />
            </S.StatsItem>
          ) : (
            <S.EmptyView />
          )}
        </S.StatsContainer>
      )}
    </S.Container>
  );
};

const S = {
  EmptyView: styled.View``,
  Container: styled.TouchableOpacity<{ isSmall: boolean }>`
    width: ${({ isSmall }) => calcWidth(isSmall ? (isWeb ? 170 : 110) : isWeb ? 220 : 165)}px;
    margin-left: ${isWeb ? calcWidth(10) : calcWidth(8)}px;
    margin-bottom: ${calcHeight(22)}px;
  `,
  BiteImage: styled.ImageBackground<{ isSmall: boolean }>`
    position: relative;
    width: ${({ isSmall }) => calcWidth(isSmall ? (isWeb ? 169 : 109) : isWeb ? 220 : 165)}px;
    height: ${({ isSmall }) => calcHeight(isSmall ? (isWeb ? 239 : 154) : isWeb ? 276 : 250)}px;
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
    border-radius: 18px;
    overflow: hidden;
  `,
  BiteTitle: styled(CustomText)(
    ({ theme }) => css`
      margin-top: ${calcHeight(4)}px;
      padding: 0 ${calcWidth(12)}px;
      font-size: ${theme.fontSizes.s12}px;
      font-weight: bold;
      font-family: ${theme.fontFamilies.Arimo};
    `,
  ),
  ChosenImageBg: styled.View`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
  `,
  SectionsCounter: styled.View`
    position: absolute;
    flex-direction: row;
    align-items: center;
    bottom: ${calcHeight(8)}px;
    left: ${calcWidth(12)}px;
  `,
  StatsContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${calcWidth(12)}px;
  `,
  StatsItem: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  StatsCounter: styled.Text`
    margin-right: ${calcWidth(4)}px;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  OwnerInitials: styled(OwnerInitials)`
    position: absolute;
    bottom: ${calcHeight(10)}px;
    right: ${calcWidth(10)}px;
  `,
};

export default React.memo(BiteItem);
