import { IAttributeValue } from '../../../store/attributes/attributes.types';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useSelectedValues = (
  initialState: IAttributeValue[],
): [IAttributeValue[], Dispatch<SetStateAction<IAttributeValue[]>>, { [key: string]: boolean }] => {
  const [selectedValues, setSelectedValues] = useState(initialState);
  const [selectedValuesMap, setSelectedValuesMap] = useState(() =>
    initialState.reduce((map, { id }) => {
      map[id] = true;
      return map;
    }, {}),
  );

  const handleUpdateState = useCallback((values) => {
    setSelectedValues(values);
    setSelectedValuesMap(() =>
      values.reduce((map, { id }) => {
        map[id] = true;
        return map;
      }, {}),
    );
  }, []);

  return [selectedValues, handleUpdateState, selectedValuesMap];
};

export default useSelectedValues;
