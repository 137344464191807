import Cookies from 'js-cookie';

import { IMarketingParams } from '../../../store/auth/auth.types';
import { getGaMarketingClientId } from '../../../services/gtm/track';

const getSignupMarketingParams = (): IMarketingParams => {
  return {
    utm_campaign: Cookies.get('utm_campaign'),
    utm_medium: Cookies.get('utm_medium'),
    utm_source: Cookies.get('utm_source'),
    utm_term: Cookies.get('utm_term'),
    utm_content: Cookies.get('utm_content'),
    hsa_grp: Cookies.get('hsa_grp'),
    hsa_ad: Cookies.get('hsa_ad'),
    hsa_src: Cookies.get('hsa_src'),
    hsa_tgt: Cookies.get('hsa_tgt'),
    hsa_kw: Cookies.get('hsa_kw'),
    hsa_url: Cookies.get('hsa_url'),
    hsa_cam: Cookies.get('hsa_cam'),
    hutk: Cookies.get('hubspotutk'),
    // referrer_url: Cookies.get('referrer_url'),
    ga_marketing_client_id: getGaMarketingClientId(),
  };
};

export default getSignupMarketingParams;
