import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import * as actions from '../../../../store/biteQuestion/biteQuestion.actions';
import { useSelector, useDispatch } from 'react-redux';
import useMedia, { formatFileTypesForWeb } from '../../../../hooks/useMedia';
import { KeyboardAwareView } from '../../../../components/shared';
import { setBiteCoverFromUrl } from '../../../../store/createBite/createBites.actions';
import { createBiteSelector, questionSuggestionSelector } from '../../../../store/createBite/createBite.selectors';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useTranslation } from 'react-i18next';
import { StyleProp, ViewStyle } from 'react-native';
import useKeyboardHeight from '../../../../hooks/useKeyboardHeight';
import { EQuestionType } from '../../../../types/biteQuestion';
import { setChoices, setQuestionContent, setQuestionMedia } from '../../../../store/biteQuestion/biteQuestion.actions';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import useQuestion from '../useQuestion';
import BiteAnswerInput from '../../BiteAnswerInput';
import WebScrollingView from '../../../creationBite/common/WebScrollingView';
import QuestionSticker from '../../../creationBite/common/QuestionSticker';
import MediaModal from '../../../creationBite/common/MediaModal';
import Button, { EIcon } from '../../../../components/shared/buttons/Button/Button';
import SuggestionHeader from '../../SuggestionHeader';
import { addAnswerBtnDataSet, questionContinueBtnDataSet } from '../question.constants';

const MAX_LENGTH = 8;

interface IProps {
  onContinue: (localInputValue?: string) => void;
  isEditMode?: boolean;
  isLoading?: boolean;
  from: string;
}

const AddSurveyQuestion: React.FC<IProps> = ({ isLoading, onContinue, isEditMode, from }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const questionSuggestion = useSelector(questionSuggestionSelector);

  const {
    localInputValue,
    setLocalInputValue,
    localMedia,
    setLocalMedia,
    localAnswers,
    setLocalAnswers,
    handleChangeAnswer,
    handleAddAnswer,
    handleDeleteAnswer,
  } = useQuestion({ from, questionSuggestion: questionSuggestion?.survey });

  const uploadImageRef = useRef<() => Promise<any>>();

  const [isMediaModalVisible, setMediaModalVisible] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const { keyboardHeight } = useKeyboardHeight();

  const { introMedia, cover_url } = useSelector(createBiteSelector);

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const handleUploadImage = async (uri: string, type?: string, editedImageUri?: string) => {
    handleCloseMediaModal();

    const uploadImage = uploadImageRef.current;

    if (!editedImageUri && typeof uploadImage !== 'function') {
      return;
    }

    setImageLoading(true);

    try {
      const { media: _media } = await uploadImage();
      dispatch(actions.setQuestionMedia(_media));
      if (!introMedia && !cover_url) {
        dispatch(setBiteCoverFromUrl(_media.image_url!));
      }
    } catch (error) {
      dispatch(actions.questionMediaUploadingError());
    }

    setImageLoading(false);
    dispatch(actions.setQuestionMediaDoneUploading());
  };

  const {
    mediaURI,
    launchImageLibrary,
    launchImageCamera,
    uploadImage,
    clearState,
    dropZoneUploadingForWeb,
    handleSelectOnlineMedia,
  } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    from: EQuestionType.SURVEY,
    onMediaSelectionCB: handleUploadImage,
  });

  useEffect(() => {
    uploadImageRef.current = uploadImage;
  }, [uploadImage]);

  const disabledContinueButton = useMemo(() => {
    const isBelowMaximum = localInputValue.length < MAX_LENGTH;
    const isAnswersEmpty = localAnswers.filter((answer) => !answer.text).length > 0;

    return isBelowMaximum || isAnswersEmpty;
  }, [localAnswers, localInputValue]);

  const handleAddImage = useCallback(() => {
    if (isWeb) {
      launchImageLibrary();
      return;
    }
    setMediaModalVisible(true);
  }, [launchImageLibrary]);

  const handleDragEnd = useCallback(
    ({ data }) => {
      setLocalAnswers(data);
    },
    [setLocalAnswers],
  );

  const keyExtractor = useCallback((item) => `draggable-item-${item.id!}`, []);

  const handleDeleteImage = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'delete_question_image',
        props: { question_type: 'Survey', from },
      }),
    );
    setLocalMedia(null);
    clearState();
  }, [dispatch, from, setLocalMedia, clearState]);

  const handleContinue = useCallback(() => {
    dispatch(setChoices(localAnswers));
    dispatch(setQuestionContent(localInputValue));
    dispatch(setQuestionMedia(localMedia));

    if (!introMedia && !cover_url) {
      dispatch(setBiteCoverFromUrl(localMedia?.image_url!));
    }

    onContinue(localInputValue);
  }, [localAnswers, cover_url, dispatch, introMedia, localMedia, onContinue, localInputValue]);

  const renderAnswer = useCallback(
    ({ drag, item, getIndex }) => {
      const index = getIndex();
      const autoFocus = !!localInputValue && localAnswers.findIndex((localAnswer) => !localAnswer.text) === index;
      return (
        <BiteAnswerInput
          item={item}
          color={theme.colors.turqSticky}
          onChangeText={handleChangeAnswer}
          index={index}
          autoFocus={autoFocus}
          onDrag={drag}
          onRemove={localAnswers.length > 2 && handleDeleteAnswer}
        />
      );
    },
    [localInputValue, localAnswers, theme.colors.turqSticky, handleChangeAnswer, handleDeleteAnswer],
  );

  return (
    <S.Container>
      <S.KeyboardAvoidingView keyboardVerticalOffset={calcHeight(-22)} behavior={'padding'}>
        <S.KeyboardAwareView contentContainerStyle={S.ContentContainerStyles}>
          <WebScrollingView>
            {questionSuggestion?.survey && <SuggestionHeader title={t('addQuestion.questionSuggestion.title')} />}
            <QuestionSticker
              text={localInputValue}
              onChangeText={setLocalInputValue}
              height={160}
              color={theme.colors.turqSticky}
              isImageLoading={isImageLoading}
              onAddImage={handleAddImage}
              mediaURI={mediaURI || localMedia?.image_url}
              onDeleteImage={handleDeleteImage}
            />
            <DraggableFlatList
              keyboardShouldPersistTaps={'handled'}
              contentContainerStyle={S.DraggableFlatListContainer}
              bounces={false}
              data={localAnswers}
              keyExtractor={keyExtractor}
              onDragEnd={handleDragEnd}
              renderItem={renderAnswer}
              scrollEnabled={false}
              activationDistance={20}
            />
          </WebScrollingView>
        </S.KeyboardAwareView>
        <S.ButtonContainer isKeyboardOpen={Boolean(keyboardHeight)}>
          <S.ContinueButton
            onPress={handleContinue}
            isLoading={isLoading}
            disabled={disabledContinueButton}
            content={t(`common.${isEditMode ? 'Save' : 'Continue'}`)}
            // @ts-ignore
            dataSet={questionContinueBtnDataSet}
          />
          {localAnswers.length < MAX_LENGTH && (
            <S.AddAnswerButton
              text={t('addQuestion.addAnswer')}
              onPress={handleAddAnswer}
              border={theme.colors.primaryBlue}
              icon={EIcon.ADD}
              fill={theme.colors.white}
              textColor={theme.colors.primaryBlue}
              dataSet={addAnswerBtnDataSet}
            />
          )}
        </S.ButtonContainer>
      </S.KeyboardAvoidingView>
      <MediaModal
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onSelectStockImage={handleSelectOnlineMedia}
        onClose={handleCloseMediaModal}
        isVisible={isMediaModalVisible}
        from='AddSurveyQuestion'
      />
      {dropZoneUploadingForWeb}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    align-self: center;
    position: relative;
    padding-bottom: 20px;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ButtonContainer: styled.View<{ isKeyboardOpen?: boolean }>`
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: ${isWeb ? 370 : deviceWidth}px;
    top: -${({ isKeyboardOpen }) => (!isWeb && isKeyboardOpen ? calcHeight(120) : 0)}px;
    padding: 10px 20px;
    background: ${({ theme }) => theme.colors.white};
  `,
  ContinueButton: styled(GenericButton)`
    width: ${calcWidth(123)}px;
    height: ${calcHeight(40)}px;
  `,
  AddAnswerButton: styled(Button)`
    max-width: ${calcWidth(210)}px;
    height: ${calcHeight(42)}px;
  `,
  KeyboardAvoidingView: styled.KeyboardAvoidingView`
    flex: 1;
    align-items: center;
  `,
  KeyboardAwareView: styled(KeyboardAwareView)``,
  DraggableFlatListContainer: {
    width: isWeb ? 370 : deviceWidth,
  } as StyleProp<ViewStyle>,
  ContentContainerStyles: {
    alignItems: 'center',
  } as StyleProp<ViewStyle>,
};

export default AddSurveyQuestion;
