import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/native';
import { StyleSheet } from 'react-native';
import Modal from '../ModalController';

import { calcHeight, calcWidth } from '../../../utils/dimensions';

import { useTranslation } from 'react-i18next';
import YoutubeUploadModal from '../YoutubeUploadModal';
import AddMediaIcon from '../../../assets/icons/add_media_icon.svg';
import { ControlledModal } from '../../../types/modals';
import { MediaController } from '../../../types/media';
import Content from './components/Content';

interface IProps {
  mediaControls: MediaController;
  photoOnly: boolean;
  from?: string;
}

const translatePrefix = 'createBiteStack.createBiteIntro';

const ChooseMediaModal = React.forwardRef<ControlledModal, IProps>((props, ref) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const youtubeModalRef = useRef<ControlledModal>(null);
  const { mediaControls, photoOnly, from } = props;
  const { t } = useTranslation();

  const open = () => setModalOpen(true);
  const close = () => setModalOpen(false);
  useEffect(() => {
    //@ts-ignore
    ref.current = {
      open,
      close,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal isVisible={isModalOpen} onBackdropPress={() => setModalOpen(false)} style={styles.modal}>
        <S.Container>
          <S.ModalHeader>
            <AddMediaIcon height='82px' width='82px' />
            <S.HeaderTitle>{t(`${translatePrefix}.headerTitle`)}</S.HeaderTitle>
          </S.ModalHeader>
          <S.Divider />
          <S.ModalContent>
            <Content
              mediaControls={mediaControls}
              photoOnly={photoOnly}
              youtubeModalRef={youtubeModalRef}
              from={from}
            />
          </S.ModalContent>
        </S.Container>
      </Modal>
      <YoutubeUploadModal ref={youtubeModalRef} mediaControls={mediaControls} closeMediaModal={close} />
    </>
  );
});

const Container = styled.View`
  width: ${calcWidth(300)}px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
`;
const ModalHeader = styled.View`
  width: 100%;
  height: ${calcHeight(150)}px;
  background-color: ${({ theme }) => theme.colors.lightGray3};
  justify-content: space-around;
  align-items: center;
  padding-top: ${calcHeight(12)}px;
  padding-bottom: ${calcHeight(10)}px;
`;

const HeaderTitle = styled.Text(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s24}px;
    color: ${theme.colors.lightGray17};
    padding-top: ${calcHeight(12)}px;
  `,
);

const ModalContent = styled.View`
  background-color: ${({ theme }) => theme.colors.lightGray3};
  flex-grow: 1;
  align-items: center;
  padding: 10px 20px 10px 20px;
`;

const Divider = styled.View`
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.lightGray1};
`;
const S = {
  Container,
  ModalHeader,
  HeaderTitle,
  ModalContent,
  Divider,
};

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
  },
});

export default ChooseMediaModal;
