import React, { FC, useMemo, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components/native';
import { IStackNavigation } from '../../navigation/types';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import TextIcon from '../../assets/icons/text-style.svg';
import SettingsIcon from '../../assets/icons/settings.svg';
import { EditScreenButtons } from '../../components/editScreensContent';
import { CustomText } from '../../components/shared';
import PlusIcon from '../../assets/icons/plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateSelectedBites,
  updatePlaylist,
  resetCreatePlaylistState,
} from '../../store/createPlaylist/createPlaylist.actions';
import { createPlaylistSelector } from '../../store/createPlaylist/createPlaylist.selectors';
import DraggableFlatList from '../../components/DraggableFlatList';
import { QuizIntroItem, OrganizeQuizItem } from '../createQuiz/components/';
import { activeOrganizationSelector, userSelector } from '../../store/auth/auth.selectors';
import { containsQuestions, formatQuizIntro } from '../../utils/quizHelper';
import { fetchQuizSettings, resetQuizSettings } from '../../store/playlist/playlist.actions';
import Routes from '../../navigation/routes';
import { loadBiteHelperConfigs, trackEvent } from '../../store/appActivity/appActivity.slice';
import GenericButton from '../../components/shared/buttons/GenericButton';
import { useIsFocused } from '@react-navigation/native';
import { cleanEditAndCreateBiteState } from '../../store/bite/bite.actions';
import { setSkipQuestion, setIsQuiz, setQuizFirstQuestion } from '../../store/biteQuestion/biteQuestion.actions';
import { setSummaryCards } from '../../store/biteSummary/biteSummary.actions';
import { clearCreateBiteState, setBiteName, setBiteIcon } from '../../store/createBite/createBites.actions';
import { createQuestionButtonDataSet, finishQuizButtonDataSet } from './EditQuiz.constants';

const localePrefix = 'editQuiz.buttons';

interface IProps extends IStackNavigation {}

const EditQuiz: FC<IProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const user = useSelector(userSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);

  const { selectedBites, subject, editedPlaylist, editMode, postOrUpdateSuccess } = useSelector(createPlaylistSelector);

  const questions = useMemo(() => {
    return selectedBites.filter((bite) => bite.playlist_section !== 'intro');
  }, [selectedBites]);

  const quizIntroBite = useMemo(() => formatQuizIntro(selectedBites), [selectedBites]);

  const isQuizOwner = useMemo(() => {
    if (!user || !editedPlaylist || !activeOrganization) {
      return false;
    }

    return user.id === editedPlaylist.creator || activeOrganization.content_admin;
  }, [user, editedPlaylist, activeOrganization]);

  const setStateForNewQuestion = useCallback(() => {
    dispatch(clearCreateBiteState());
    dispatch(cleanEditAndCreateBiteState());
    dispatch(setBiteName(`${editedPlaylist?.subject}_bite${questions?.length}`));
    dispatch(setBiteIcon({ id: 2, name: '', image: '' }));
    dispatch(setSummaryCards([]));
    dispatch(setSkipQuestion(false));
    dispatch(setIsQuiz());
    dispatch(setQuizFirstQuestion(!containsQuestions(questions || [])));
  }, [dispatch, editedPlaylist?.subject, questions]);

  const handleCreateQuestion = useCallback(() => {
    setStateForNewQuestion();
    dispatch(
      trackEvent({
        event: 'add_question_btn',
        props: { quiz_id: editedPlaylist?.id },
      }),
    );
    navigation.navigate(Routes.EditQuizStack.StackName, { screen: Routes.EditQuizStack.AddQuestion });
  }, [dispatch, editedPlaylist?.id, navigation, setStateForNewQuestion]);

  const editButtons = useMemo(
    () => [
      {
        icon: TextIcon,
        title: t(`${localePrefix}.rename`),
        onPress: () =>
          navigation.navigate(Routes.CreateQuizStack.StackName, {
            screen: Routes.CreateQuizStack.NameYourQuiz,
            params: { isEditMode: true },
          }),
      },
      {
        icon: SettingsIcon,
        title: t(`${localePrefix}.settings`),
        onPress: () => {
          dispatch(
            trackEvent({
              event: 'pageview',
              props: { quiz_id: editedPlaylist?.id, page_title: Routes.CreateQuizStack.QuizSettings },
            }),
          );
          dispatch(resetQuizSettings());
          if (editedPlaylist?.id) {
            dispatch(fetchQuizSettings(editedPlaylist.id));
          }
          navigation.navigate(Routes.CreateQuizStack.StackName, {
            screen: Routes.CreateQuizStack.QuizSettings,
          });
        },
      },
    ],
    [t, navigation, editedPlaylist?.id, dispatch],
  );

  const handleFinishPress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'save_quiz',
        props: { quiz_id: editedPlaylist?.id },
      }),
    );
    dispatch(updatePlaylist());
    if (editMode) {
      navigation.popToTop();
    } else {
      navigation.replace(Routes.EditQuizStack.QuizCreated);
    }
  }, [dispatch, editMode, editedPlaylist?.id, navigation]);

  const image = editedPlaylist?.linked_cover_url || quizIntroBite?.cover_url || null;

  const keyExtractor = useCallback((item) => item.id!.toString(), []);

  const handleDragEnd = useCallback(
    ({ data }) => {
      if (isQuizOwner) {
        const updatedBites = quizIntroBite ? [quizIntroBite, ...data] : [...data];
        dispatch(updateSelectedBites(updatedBites));
      }
    },
    [dispatch, isQuizOwner, quizIntroBite],
  );

  const renderItem = useCallback(
    (renderItemParams) => (
      <OrganizeQuizItem
        renderItemParams={renderItemParams}
        isOwner={isQuizOwner}
        isFirstElement={renderItemParams.index === 0}
        isLastElement={renderItemParams.index === questions.length - 1}
      />
    ),
    [isQuizOwner, questions.length],
  );

  useEffect(() => {
    if (postOrUpdateSuccess) {
      dispatch(resetCreatePlaylistState());
    }
  }, [dispatch, postOrUpdateSuccess]);

  useEffect(() => {
    if (isFocused && editedPlaylist?.bite_shares && !containsQuestions(questions) && editedPlaylist?.id) {
      handleCreateQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedPlaylist?.id, questions]);

  useEffect(() => {
    dispatch(loadBiteHelperConfigs());
  }, [dispatch]);

  return (
    <S.Container>
      <S.Header>
        <S.Title>{subject}</S.Title>
      </S.Header>
      <S.Content>
        <QuizIntroItem bite={quizIntroBite} image={image} isOwner={isQuizOwner} />
        <S.QuizContainer>
          <S.QuestionsList>
            <DraggableFlatList
              data={questions}
              keyExtractor={keyExtractor}
              onDragEnd={handleDragEnd}
              renderItem={renderItem}
              bottomSpacing={56}
            />
          </S.QuestionsList>
          <S.CreateButtonContainer>
            <S.CreateButton
              onPress={handleCreateQuestion}
              disabled={
                !activeOrganization.content_admin &&
                (!isQuizOwner || !(editedPlaylist?.is_public || activeOrganization.is_creator))
              }
              //@ts-ignore
              dataSet={createQuestionButtonDataSet}
            >
              <PlusIcon {...iconProps} />
            </S.CreateButton>
          </S.CreateButtonContainer>
        </S.QuizContainer>
        <S.Finish
          onPress={handleFinishPress}
          content={t(`${localePrefix}.finish`)}
          disabled={subject.length < 4 || !isQuizOwner}
          dataSet={finishQuizButtonDataSet}
        />
        {isQuizOwner && <EditScreenButtons buttons={editButtons} />}
      </S.Content>
    </S.Container>
  );
};

const iconProps = {
  height: calcWidth(20),
  width: calcWidth(20),
  fill: 'white',
};

const Container = styled.View`
  flex: 1;
`;

const Header = styled.View`
  height: ${calcHeight(100)}px;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilies.Arimo};
`;
const Title = styled.Text`
  font-size: ${({ theme }) => theme.fontSizes.s28};
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-align: center;
  font-weight: 600;
  margin-top: ${calcHeight(-20)};
`;

const Finish = styled(GenericButton)`
  margin-top: ${calcHeight(15)}px;
  margin-bottom: ${calcHeight(15)}px;
`;

const Content = styled.View`
  align-items: center;
  padding-top: ${calcWidth(15)}px;
  flex: 1;
`;

const QuestionsList = styled.View`
  height: 100%;
`;

const QuizContainer = styled.View`
  width: ${calcWidth(315)}px;
  border-radius: 25px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.lightGray5};
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  overflow: hidden;
`;

const DateAndOptionsContainer = styled.View`
  flex-direction: row;
  margin-top: ${calcHeight(13)}px;
  justify-content: space-between;
  align-items: center;
`;

const CreationTime = styled(CustomText)(
  ({ theme }) => css`
    font-size: ${theme.fontSizes.s14}px;
    opacity: 0.4;
  `,
);

const PlaylistTitle = styled(CustomText)(
  ({ theme }) => css`
    margin-top: ${calcHeight(16)}px;
    font-size: ${theme.fontSizes.s20}px;
    color: ${theme.colors.lightGray6};
    font-family: ${theme.fontFamilies.GilroyMedium};
    line-height: ${calcHeight(24)}px;
    text-align: left;
  `,
);

const CreateButtonContainer = styled(TouchableOpacity)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${calcWidth(4)}px;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.lightGray1};
  background-color: ${({ theme }) => theme.colors.lightBackground2};
`;

const CreateButton = styled(TouchableOpacity)(
  ({ disabled }) => css<{ disabled: boolean }>`
    z-index: 1000;
    width: ${calcWidth(48)}px;
    height: ${calcWidth(48)}px;
    border-radius: ${calcWidth(48) / 2}px;
    background-color: ${({ theme }) => (disabled ? theme.colors.darkGray3 : theme.colors.primaryBlue)};
    justify-content: center;
    align-items: center;
    align-self: center;
  `,
);

const S = {
  Container,
  Content,
  Header,
  Title,
  QuizContainer,
  QuestionsList,
  DateAndOptionsContainer,
  CreationTime,
  PlaylistTitle,
  CreateButtonContainer,
  CreateButton,
  Finish,
};

export default EditQuiz;
