import React, { useCallback, useEffect } from 'react';
import { CommonActions, useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import ShadowedButton from '../../Shared/ShadowedButton';
import SummaryNotesIcon from '../../../assets/icons/creationFlow/summary-notes-icon.svg';
import { calcHeight, isWeb } from '../../../utils/dimensions';
import {
  createBiteSelector,
  introMediaProcessingStatusSelector,
  selectBiteIntroMedia,
  taskIdSelector,
} from '../../../store/createBite/createBite.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import getHasEnhancementsEdit from '../../../utils/introMedia/getHasEnhancementsEdit';
import { clearState, filterEmptyCards } from '../../../store/biteSummary/biteSummary.actions';
import { useTranslation } from 'react-i18next';
import Routes from '../../../navigation/routes';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { summaryNotesContinueBtnDataSet, skipSummaryNotes } from './summaryNotes.constants';
// import MediaProcessingBanner from '../../../components/shared/MediaProcessingBanner/MediaProcessingBanner';

const SummaryNotes = ({ route, navigation }) => {
  const isPrefilled = route?.params?.isPrefilled;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const biteIntroMedia = useSelector(selectBiteIntroMedia);
  const taskId = useSelector(taskIdSelector);
  const { skipped: questionSectionSkipped } = useSelector(biteQuestionSelector);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      dispatch(clearState());
    }
  }, [dispatch, isFocused]);

  const { introEnhancements, introSubtitles, summarySuggestionCards } = useSelector(createBiteSelector);
  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);

  const isSummarySkipable =
    !!biteIntroMedia ||
    !!taskId ||
    introMediaProcessingStatus.all !== EIntroMediaProcessingStatus.INACTIVE ||
    !questionSectionSkipped;

  const addSummary = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_summary',
      }),
    );
    navigation.navigate(Routes.CreateBiteStack.CreateSummaryNotes);
  }, [dispatch, navigation]);

  const onSkip = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'skip_summary_creation',
      }),
    );

    dispatch(filterEmptyCards());

    const hasEnhancementsEdit = getHasEnhancementsEdit({
      introEnhancements,
      introSubtitles,
    });

    if (hasEnhancementsEdit) {
      dispatch(
        log({
          event: 'SummaryNotes.onSkip: opening BitesMagic',
          data: {
            introMediaProcessingStatus,
            hasEnhancementsEdit,
          },
        }),
      );
      navigation.navigate(Routes.CreateBiteStack.BitesMagic, { isPrefilled });
      return;
    }

    const isAIProcessing =
      introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING &&
      (introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.INACTIVE ||
        introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.PROCESSING ||
        introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.INACTIVE ||
        introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.PROCESSING);

    if (isAIProcessing) {
      dispatch(
        log({
          event: 'SummaryNotes.onSkip: opening AIExpectationSecond',
          data: {
            isAIProcessing,
            introMediaProcessingStatus,
            hasEnhancementsEdit,
          },
        }),
      );
      navigation.navigate(Routes.CreateBiteStack.AIExpectationSecond, { isPrefilled });
      return;
    }

    dispatch(
      log({
        event: 'SummaryNotes.onSkip: opening FinalScreen',
        data: {
          isAIProcessing,
          introMediaProcessingStatus,
          hasEnhancementsEdit,
        },
      }),
    );

    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {
            name: Routes.CreateBiteStack.FinalScreen,
          },
        ],
      }),
    );
  }, [dispatch, introEnhancements, introMediaProcessingStatus, introSubtitles, isPrefilled, navigation]);

  const renderSummaryIcon = useCallback(() => <SummaryNotesIcon width={calcHeight(49)} height={calcHeight(48)} />, []);

  return (
    <>
      <S.Container>
        <S.Center>
          <S.TitleWrapper>
            <S.Title>{t('addSummaryNotes.title')}</S.Title>
          </S.TitleWrapper>
          <ShadowedButton
            renderIcon={renderSummaryIcon}
            title={t('addSummaryNotes.summaryCards')}
            description={t('addSummaryNotes.description')}
            withAISuggestions={!!summarySuggestionCards}
            // @ts-ignore
            dataSet={summaryNotesContinueBtnDataSet}
            onPress={addSummary}
          />
          {isSummarySkipable && (
            <S.ContinueButton
              onPress={onSkip}
              // @ts-ignore
              dataSet={skipSummaryNotes}
            >
              <S.ContinueText>{t('addSummaryNotes.skip')}</S.ContinueText>
            </S.ContinueButton>
          )}
        </S.Center>
      </S.Container>
      {/*<MediaProcessingBanner containerStyle={S.MediaProcessingBannerContainerStyle} />*/}
    </>
  );
};

const S = {
  Center: styled.View`
    align-self: center;
    position: relative;
  `,
  Container: styled.View`
    flex: 1;
    background-color: white;
  `,
  TitleWrapper: styled.View`
    width: 100%;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 24px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  ContinueButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(44)}px;
    align-self: center;
  `,
  ContinueText: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  MediaProcessingBannerContainerStyle: {
    bottom: !isWeb ? calcHeight(90) : 'auto',
  },
};

export default SummaryNotes;
