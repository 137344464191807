import React, { memo, ReactNode } from 'react';
import styled, { css } from 'styled-components/native';
import ShadowedContainer from '../../../components/ShadowedContainer';
import { I18nManager, ViewStyle } from 'react-native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import i18n from '../../../locale/i18n';

interface IProps {
  title: string;
  onPress: () => void;
  icon: ReactNode;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
}

const SocialButton: React.FC<IProps> = ({ title, onPress, icon, style, containerStyle }) => {
  const isRtl = isWeb ? i18n.dir(i18n?.language) === 'rtl' : I18nManager.isRTL;

  return (
    <ShadowedContainer containerViewStyle={containerStyle}>
      <S.Button onPress={onPress} style={style}>
        {icon && <S.Icon isRtl={isRtl}>{icon}</S.Icon>}
        <S.Title>{title}</S.Title>
      </S.Button>
    </ShadowedContainer>
  );
};

const S = {
  Button: styled.TouchableOpacity`
    width: 100%;
    height: ${calcHeight(56)}px;
    border-radius: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.white};
  `,
  Icon: styled.View<{ isRtl?: boolean }>`
    position: absolute;
    ${({ isRtl }) =>
      isRtl && isWeb
        ? css`
            right: ${calcWidth(16)}px;
          `
        : css`
            left: ${calcWidth(16)}px;
          `}
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
  `,
};

export default memo(SocialButton);
