import React, { useCallback, useEffect } from 'react';
import { IStackNavigation } from '../../../navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import IntroEnhancements from '../../../components/introEnhancements';
import { saveIntroEnhancementsChanges } from '../../../store/createBite/createBites.actions';
import Routes from '../../../navigation/routes';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { setBiteAvaitingEnhancements } from '../../../store/appActivity/appActivity.slice';
import { CommonActions } from '@react-navigation/native';

interface IProps extends IStackNavigation {}

const BitesMagic: React.FC<IProps> = ({ route, navigation }) => {
  const isPrefilled = route.params?.isPrefilled;

  const dispatch = useDispatch();
  const { selectedBite } = useSelector(biteSelector);

  useEffect(() => {
    if (selectedBite?.id) {
      return;
    }
    dispatch(
      setBiteAvaitingEnhancements({
        biteId: selectedBite?.id,
        isAvaitingEnhancements: false,
      }),
    );
  }, [dispatch, selectedBite?.id]);

  const handleChanges = useCallback(() => {
    dispatch(saveIntroEnhancementsChanges());

    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {
            name: Routes.CreateBiteStack.FinalScreen,
          },
        ],
      }),
    );
  }, [dispatch, navigation]);

  return <IntroEnhancements useVimeo={isPrefilled} onNext={handleChanges} />;
};

export default BitesMagic;
