import styled, { css, useTheme } from 'styled-components/native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConfirmIcon from '../assets/icons/confirm.svg';
import { calcHeight, calcWidth } from '../utils/dimensions';
import { useIsMounted } from '../hooks/useIsMounted';
import { nameInputAcceptDataSet, nameInputDataSet } from '../screens/creationBite/BiteCreated/biteCreated.constants';

interface IProps {
  onChangeText: (value: string) => void;
  value: string;
  onAccept: (value: string) => void;
}

const EditNameInput: React.FC<IProps> = ({ onChangeText, value, onAccept }) => {
  const theme = useTheme();
  const inputRef = useRef(null);
  const isMountRef = useIsMounted();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocusVisible, setIsFocusVisible] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const isAcceptButtonActive = localValue.length > 2;

  useEffect(() => {
    if (!isFocused) {
      setLocalValue(value);
    }
  }, [isFocused, value]);

  const handleFocus = useCallback(() => {
    setIsFocusVisible(true);
    setIsFocused(true);
  }, []);

  const hideAcceptButton = useCallback(() => {
    const isInputFocused = inputRef.current?.isFocused();

    if (!isMountRef.current || isInputFocused) {
      return;
    }

    setIsFocused(false);
  }, [isMountRef]);

  const handleBlur = useCallback(() => {
    setIsFocusVisible(false);

    setTimeout(hideAcceptButton, 500);
  }, [hideAcceptButton]);

  const handleChangeText = useCallback((text: string) => {
    setLocalValue(text);
  }, []);

  const handleAccept = useCallback(() => {
    onChangeText(localValue);
    onAccept(localValue);
    setIsFocused(false);
    inputRef.current?.blur();
  }, [localValue, onAccept, onChangeText]);

  return (
    <S.NameInputContainer>
      <S.NameInput
        ref={inputRef}
        onBlur={handleBlur}
        onFocus={handleFocus}
        isFocusVisible={isFocusVisible}
        onSubmitEditing={handleAccept}
        onChangeText={handleChangeText}
        value={localValue}
        maxLength={40}
        // @ts-ignore
        dataSet={nameInputDataSet}
      />
      {isFocused && (
        <S.NameInputAcceptButton
          disabled={!isAcceptButtonActive}
          isFocusVisible={isFocusVisible}
          onPress={handleAccept}
          // @ts-ignore
          dataSet={nameInputAcceptDataSet}
        >
          <ConfirmIcon style={S.ConfirmIconStyles} fill={theme.colors.white} />
        </S.NameInputAcceptButton>
      )}
    </S.NameInputContainer>
  );
};

const S = {
  ConfirmIconStyles: {
    width: calcWidth(12),
    height: calcHeight(12),
  },
  NameInputContainer: styled.View`
    position: relative;
  `,
  NameInput: styled.TextInput<{ isFocusVisible: boolean }>`
    align-self: center;
    text-align: center;
    margin: ${calcHeight(23)}px 0 ${calcHeight(14)}px;
    padding: 0;
    width: ${calcWidth(339)}px;
    height: ${calcHeight(38)}px;
    border-radius: ${calcWidth(38 / 2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    background-color: ${({ theme }) => theme.colors.defaultGrayBg};
    border: 1px solid ${({ theme }) => theme.colors.defaultGrayBg};

    ${({ isFocusVisible, theme }) =>
      isFocusVisible &&
      css`
        border-color: ${theme.colors.primaryBlue};
        background-color: ${theme.colors.white};
      `}
  `,
  NameInputAcceptButton: styled.TouchableOpacity<{ disabled: boolean; isFocusVisible: boolean }>`
    opacity: ${({ isFocusVisible }) => (isFocusVisible ? 1 : 0)};
    z-index: 1;
    position: absolute;
    background-color: ${({ disabled, theme }) => (disabled ? theme.colors.gray1 : theme.colors.primaryBlue)};
    height: ${calcHeight(32)}px;
    width: ${calcHeight(32)}px;
    border-radius: ${calcHeight(32) / 2}px;
    right: ${calcWidth(3)}px;
    top: ${calcHeight(26)}px;
    align-items: center;
    justify-content: center;
  `,
};

export default EditNameInput;
