import React, { FC } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';

interface IProps {
  children: React.ReactNode;
}

const EditScreenContainer: FC<IProps> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

const Container = styled.View`
  flex: 1;
  padding-right: ${calcWidth(30)}px;
  padding-left: ${calcWidth(30)}px;
  align-items: center;
  padding-top: ${() => (isWeb ? 28 : `${calcHeight(36)}`)}px;
`;

const S = { Container };

export default EditScreenContainer;
