import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { IInitialState, ILoadNextPage, ILoadNextPageResults } from './bitesFeed.types';
import BiteTypes from '../bite/bite.types';
import { IAction } from '../common/types';

export const initialState: IInitialState = {
  results: [],
  nextPage: 1,
  isLoading: false,
  isWithoutEmptyBites: true,
  searchStr: '',
};

const BITES_FEED = 'BITES_FEED';

const bitesFeedSlice = createSlice({
  name: BITES_FEED,
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    resetResults: (state) => {
      state.results = [];
      state.nextPage = 1;
    },
    loadResultsSuccess: (state, { payload }: PayloadAction<ILoadNextPageResults>) => {
      state.results = [...state.results, ...payload.results];
      state.nextPage = payload.nextPage;
    },
    setIsWithoutEmptyBites: (state, { payload }: PayloadAction<boolean>) => {
      state.isWithoutEmptyBites = payload;
    },
    setSearchStr: (state, { payload }: PayloadAction<string>) => {
      state.searchStr = payload;
    },
    setBaseFiltersAndSorting: (state) => {
      state.searchStr = '';
    },
  },
  extraReducers: {
    [BiteTypes.DELETE_BITE]: (state, { payload }: IAction<number>) => {
      state.results = state.results.filter((id) => {
        return id !== payload;
      });
    },
  },
});

export const {
  setIsLoading,
  resetResults,
  loadResultsSuccess,
  setIsWithoutEmptyBites,
  setSearchStr,
  setBaseFiltersAndSorting,
} = bitesFeedSlice.actions;
export const loadNextPage = createAction<ILoadNextPage>(`${bitesFeedSlice}/loadNextPage`);

export default bitesFeedSlice.reducer;
