import React, { FC, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { IBiteItem } from '../../../types/bite';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { CustomText } from '../../shared';
import useBiteUploadingMediaStatus from '../../../hooks/useBiteUploadingMediaStatus';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { useTranslation } from 'react-i18next';
import useIsItemOwner from '../../../hooks/useIsItemOwner';
import useIsDraft from '../../../hooks/useIsDraft';
import MagicWand from '../../../assets/icons/magic-wand-white.svg';
import IntroIcon from '../../../assets/icons/feed/intro-section-icon.svg';
import EditIcon from '../../../assets/icons/feed/edit.svg';
import ProcessingAnimation from '../../../screens/Shared/ProcessingAnimation';
import { StyleProp, ViewStyle } from 'react-native';

type TCallback = (options: {
  bite: IBiteItem;
  isBiteOwner: boolean;
  uploadingMediaStatus: EIntroMediaProcessingStatus;
}) => void;

enum EDisplayStatuses {
  DRAFT,
}

interface IProps {
  bite: IBiteItem;
  displayStatuses?: (EIntroMediaProcessingStatus | EDisplayStatuses)[];
  onOpenIntro?: TCallback;
  onOpenEnhancements?: TCallback;
}

const MediaStatusOverlays: FC<IProps> = ({ bite, displayStatuses, onOpenIntro, onOpenEnhancements }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const uploadingMediaStatus = useBiteUploadingMediaStatus(bite);

  const isBiteOwner = useIsItemOwner(bite);

  const isDraft = useIsDraft(bite.id);

  const handleOpenIntro = useCallback(() => {
    if (typeof onOpenIntro === 'function') {
      onOpenIntro({ bite, isBiteOwner, uploadingMediaStatus });
    }
  }, [bite, isBiteOwner, onOpenIntro, uploadingMediaStatus]);

  const handleOpenEnhancements = useCallback(() => {
    if (typeof onOpenEnhancements === 'function') {
      onOpenEnhancements({ bite, isBiteOwner, uploadingMediaStatus });
    }
  }, [bite, isBiteOwner, onOpenEnhancements, uploadingMediaStatus]);

  const magicWandIconStyle = useMemo(() => {
    return (
      isWeb ? { width: '50px', height: '50px' } : { width: '100%', height: '100%', marginBottom: calcWidth(8) }
    ) as StyleProp<ViewStyle>;
  }, []);

  const introIconStyle = useMemo(() => {
    return (
      isWeb
        ? { width: '40px', height: '40px', marginBottom: '12px' }
        : { width: calcWidth(30), height: calcWidth(30), marginBottom: calcWidth(8) }
    ) as StyleProp<ViewStyle>;
  }, []);

  const editIconStyle = useMemo(() => {
    return (isWeb ? { width: '30px', height: '40px' } : { width: '100%', height: '100%' }) as StyleProp<ViewStyle>;
  }, []);

  if (
    uploadingMediaStatus === EIntroMediaProcessingStatus.PROCESSING &&
    (!displayStatuses || displayStatuses.includes(EIntroMediaProcessingStatus.PROCESSING))
  ) {
    return (
      <S.BottomOverlay bgColor={theme.colors.transparentBlack2}>
        <S.ProcessingAnimation>
          <ProcessingAnimation width={calcWidth(204)} height={calcHeight(120)} bgOpacity={0} />
        </S.ProcessingAnimation>
      </S.BottomOverlay>
    );
  }

  if (
    isBiteOwner &&
    uploadingMediaStatus === EIntroMediaProcessingStatus.PARTIAL_SUCCESS &&
    (!displayStatuses || displayStatuses.includes(EIntroMediaProcessingStatus.PARTIAL_SUCCESS))
  ) {
    return (
      <>
        <S.BottomOverlay bgColor={theme.colors.transparentBlack2}>
          <MagicWand style={magicWandIconStyle} />
          <S.BottomOverlayLabel>{t('enhancements.loadingEnhancements')}</S.BottomOverlayLabel>
        </S.BottomOverlay>
      </>
    );
  }

  if (
    isBiteOwner &&
    uploadingMediaStatus === EIntroMediaProcessingStatus.SUCCESS &&
    (!displayStatuses || displayStatuses.includes(EIntroMediaProcessingStatus.SUCCESS))
  ) {
    return (
      <>
        <S.TopOverlay bgColor={theme.colors.transparentBlue}>
          <S.TopOverlayLabel>{t('bitesMagic.title')}</S.TopOverlayLabel>
        </S.TopOverlay>
        <S.BottomOverlay bgColor={theme.colors.transparentBlue}>
          <MagicWand style={magicWandIconStyle} />
          <S.BottomOverlayLabel>{t('enhancements.existEnhancementsMsg')}</S.BottomOverlayLabel>
          <S.BottomOverlayButton onPress={handleOpenEnhancements}>
            <S.BottomOverlayButtonText>{t('bitesMagic.buttons.clickToReview')}</S.BottomOverlayButtonText>
          </S.BottomOverlayButton>
        </S.BottomOverlay>
      </>
    );
  }

  if (
    uploadingMediaStatus === EIntroMediaProcessingStatus.ERROR &&
    (!displayStatuses || displayStatuses.includes(EIntroMediaProcessingStatus.ERROR))
  ) {
    return (
      <S.BottomOverlay bgColor={theme.colors.transparentPink}>
        <IntroIcon style={introIconStyle} />
        <S.BottomOverlayLabel>{t('mediaStatusOverlays.somethingWentWrong')}</S.BottomOverlayLabel>
        <S.BottomOverlayButton onPress={handleOpenIntro}>
          <S.BottomOverlayButtonText>{t('mediaStatusOverlays.uploadMedia')}</S.BottomOverlayButtonText>
        </S.BottomOverlayButton>
      </S.BottomOverlay>
    );
  }

  if ((bite.no_sections || isDraft) && (!displayStatuses || displayStatuses.includes(EDisplayStatuses.DRAFT))) {
    return (
      <S.BottomOverlay bgColor={theme.colors.transparentGray}>
        <EditIcon style={editIconStyle} />
        <S.BottomOverlayLabel>{t('mediaStatusOverlays.finishYourDraft')}</S.BottomOverlayLabel>
        <S.BottomOverlayWithoutButton>
          <S.BottomOverlayButtonText>{t('mediaStatusOverlays.finishDraft')}</S.BottomOverlayButtonText>
        </S.BottomOverlayWithoutButton>
      </S.BottomOverlay>
    );
  }

  return null;
};

const S = {
  BottomOverlay: styled.View<{ bgColor: string }>`
    position: absolute;
    left: ${calcWidth(8)}px;
    right: ${calcWidth(8)}px;
    bottom: ${calcWidth(8)}px;
    align-items: center;
    padding: ${calcWidth(12)}px;
    border-radius: 18px;
    background-color: ${({ bgColor }) => bgColor};
    overflow: hidden;
  `,
  BottomOverlayLabel: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    text-align: center;
  `,
  BottomOverlayButton: styled.TouchableOpacity`
    align-self: center;
    margin-top: ${calcWidth(12)}px;
    padding: ${calcWidth(8)}px ${calcWidth(16)}px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.white};
    border-radius: 18px;
  `,
  BottomOverlayWithoutButton: styled.View`
    align-self: center;
    margin-top: ${calcWidth(12)}px;
    padding: ${calcWidth(8)}px ${calcWidth(16)}px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.white};
    border-radius: 18px;
  `,
  BottomOverlayButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
  `,
  TopOverlay: styled.View<{ bgColor: string }>`
    position: absolute;
    left: ${calcWidth(8)}px;
    right: ${calcWidth(8)}px;
    top: ${calcWidth(8)}px;
    align-items: center;
    padding: ${calcWidth(8)}px ${calcWidth(16)}px;
    border-radius: 18px;
    background-color: ${({ bgColor }) => bgColor};
  `,
  TopOverlayLabel: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
  `,
  BiteTitle: styled(CustomText)(
    ({ theme }) => css`
      font-family: ${theme.fontFamilies.Arimo};
      font-size: ${theme.fontSizes.s11}px;
      margin-top: ${calcHeight(2)}px;
      font-weight: bold;
    `,
  ),
  ProcessingAnimation: styled.View`
    height: ${calcHeight(120)}px;
  `,
};

export default React.memo(MediaStatusOverlays);
