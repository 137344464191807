export const getItemCanvasSize = ({
  width,
  height,
  resolution,
  scaleUp = false,
}: {
  width: number;
  height: number;
  resolution: { width: number; height: number };
  scaleUp?: boolean;
}) => {
  if (!scaleUp && (width < resolution.width || height < resolution.height)) {
    return {
      width: (width / resolution.width) * 100,
      height: (height / resolution.height) * 100,
    };
  }

  if (width / height > resolution.width / resolution.height) {
    const diffPc = width / resolution.width;
    const newHeight = height / diffPc;

    return {
      width: 100,
      height: (newHeight / resolution.height) * 100,
    };
  }

  const diffPc = height / resolution.height;
  const newWidth = width / diffPc;

  return {
    width: (newWidth / resolution.width) * 100,
    height: 100,
  };
};
