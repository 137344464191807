import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import CustomText from './CustomText';
import { calcHeight } from '../../utils/dimensions';

interface IProps {}

const NoResultsFound: FC<IProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.NotFoundText>{t('common.noResultsFound')}</S.NotFoundText>
    </S.Container>
  );
};

const Container = styled.View`
  width: 100%;
  align-items: center;
`;

const NotFoundText = styled(CustomText)(
  ({ theme }) => css`
    color: ${theme.colors.darkPrimary};
    height: ${calcHeight(14)}px;
    font-size: ${theme.fontSizes.s14}px;
    font-family: ${theme.fontFamilies.GilroyMedium};
    text-align: left;
  `,
);

const S = { Container, NotFoundText };

export default NoResultsFound;
