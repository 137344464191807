import { ISelectedBite } from '../store/createPlaylist/createPlaylist.types';

export const formatQuizItems = (bites: ISelectedBite[]) =>
  bites.map((bite, idx) => {
    if (bite.playlist_section !== 'intro') {
      return {
        ...bite,
        order: idx,
      };
    }
  });

export const formatQuizIntro = (bites: ISelectedBite[]) => {
  return bites.find((bite) => bite.playlist_section === 'intro');
};

export const containsQuestions = (bites: ISelectedBite[]) => {
  return bites?.some((bite) => bite.playlist_section !== 'intro');
};
