import { useSelector } from 'react-redux';
import {
  displayModeSelector,
  viewsAttributesAbsoluteMapSelector,
  viewsAttributesPercentageMapSelector,
} from '../../../store/attributes/attributes.selector';
import { useMemo } from 'react';

const useViewsAttributesMap = () => {
  const displayMode = useSelector(displayModeSelector);
  const viewsAttributesAbsoluteMap = useSelector(viewsAttributesAbsoluteMapSelector);
  const viewsAttributesPercentageMap = useSelector(viewsAttributesPercentageMapSelector);

  const attributesMap = useMemo(
    () => (displayMode === 'absolute' ? viewsAttributesAbsoluteMap : viewsAttributesPercentageMap),
    [displayMode, viewsAttributesAbsoluteMap, viewsAttributesPercentageMap],
  );

  return {
    attributesMap,
  };
};

export default useViewsAttributesMap;
