import React, { memo, useCallback } from 'react';
import Header from '../../../components/Header';
import HomeButton from '../../../components/shared/HomeButton';
import Routes from '../../../navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { biteIdSelector } from '../../../store/analytics/analytics.selector';
import BiteSettings from '../../bite/common/BiteSettings';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import dayjs from 'dayjs';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { log } from '../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

const BiteScreenHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const biteId = useSelector(biteIdSelector);
  const bite = useSelector(biteItemSelector(biteId));

  const date = bite?.created_at ? dayjs(bite.created_at).format('DD MMMM, YYYY') : null;

  const goBackHome = useCallback(() => {
    dispatch(log({ event: 'BiteScreen.goBackHome', data: { biteId } }));
    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [biteId, dispatch, navigation]);

  const renderHeaderLeft = useCallback(() => {
    return <HomeButton onPress={goBackHome} />;
  }, [goBackHome]);
  const renderHeaderRight = useCallback(() => <BiteSettings biteId={biteId} />, [biteId]);
  const renderHeaderTitle = useCallback(
    () => (
      <S.Container>
        <S.CreatorsNameContainer>
          <S.CreatorsName>{`${t('biteScreen.createdBy')} ${bite?.owner_name},`}</S.CreatorsName>
        </S.CreatorsNameContainer>
        {!!date && (
          <S.DateContainer>
            <S.Date>{date}</S.Date>
          </S.DateContainer>
        )}
      </S.Container>
    ),
    [bite?.owner_name, date, t],
  );

  return <Header headerRight={renderHeaderRight} headerLeft={renderHeaderLeft} title={renderHeaderTitle} />;
};
const S = {
  Container: styled.View`
    align-items: center;
    justify-content: space-between;
  `,
  CreatorsNameContainer: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  CreatorsName: styled.Text``,
  Date: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  DateContainer: styled.View``,
};
export default memo(BiteScreenHeader);
