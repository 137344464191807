import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFlow, setCurrentForm } from '../../../store/authForm/authForm.slice';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import { log } from '../../../store/appActivity/appActivity.slice';
import { BOOK_A_DEMO_URL, signInDataSet } from './InitialForm.constants';
import { getIsWebRtl } from '../../../locale/i18n';
import { externalActionSelector } from '../../../store/appActivity/appActivity.selectors';

const InitialForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const externalAction = useSelector(externalActionSelector);

  const handleSignInButtonPress = useCallback(() => {
    dispatch(
      log({
        event: 'InitialForm.handleSignInButtonPress',
        data: { destination: EAuthFormType.AUTH, currentForm: EAuthFormType.INITIAL },
      }),
    );

    dispatch(setCurrentFlow(EAuthFlowType.SIGN_IN));
    dispatch(setCurrentForm(EAuthFormType.AUTH));
  }, [dispatch]);

  const handleSignUpButtonPress = useCallback(() => {
    const destination =
      externalAction && externalAction.action === 'joinOrganization' ? EAuthFormType.AUTH : EAuthFormType.ACCESS_CODE;

    dispatch(
      log({
        event: 'InitialForm.handleSignUpButtonPress',
        data: { destination, currentForm: EAuthFormType.INITIAL },
      }),
    );

    dispatch(setCurrentFlow(EAuthFlowType.SIGN_UP));
    dispatch(setCurrentForm(destination));
  }, [dispatch, externalAction]);

  const handleBookADemoPress = useCallback(() => {
    window.open(BOOK_A_DEMO_URL, '_blank');
    return;
  }, []);

  return (
    <S.Container>
      <S.Text>{t('initialScreen.title')}</S.Text>
      <S.SignInButton
        onPress={handleSignInButtonPress}
        content={t('initialScreen.signIn')}
        width={calcWidth(220)}
        dataSet={signInDataSet}
      />
      <S.SignUpButton
        onPress={handleSignUpButtonPress}
        content={t('initialScreen.signUp')}
        width={calcWidth(220)}
        backgroundColor={theme.colors.white}
        color={theme.colors.primaryBlue}
      />
      {isWeb && (
        <S.BookADemoContainer rtl={getIsWebRtl()}>
          <S.BookADemoLink onPress={handleBookADemoPress}>
            <S.BookADemoLinkText>{t('initialScreen.bookADemoLink')}</S.BookADemoLinkText>
          </S.BookADemoLink>
          <S.BookADemoText>{t('initialScreen.bookADemoText')}</S.BookADemoText>
        </S.BookADemoContainer>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    align-items: center;
  `,
  Text: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s22};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Ubuntu};
    margin: ${calcHeight(20)}px ${calcWidth(10)}px;
    font-weight: 400;
    width: ${calcWidth(295)}px;
  `,
  BookADemoContainer: styled.View<{ rtl?: boolean }>`
    flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
    margin-bottom: ${calcHeight(20)}px;
    align-items: baseline;
  `,

  BookADemoText: styled.Text`
    flex-direction: row;
    align-items: center;
  `,

  BookADemoLink: styled.TouchableOpacity`
    height: ${calcHeight(16)}px;
    padding: ${calcHeight(2)}px;
    flex-direction: row;
    align-items: center;
  `,

  BookADemoLinkText: styled.Text`
    height: ${calcHeight(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.blue3};
    color: ${({ theme }) => theme.colors.blue3};
  `,

  SignInButton: styled(GenericButton)`
    height: ${calcHeight(49)}px;
    width: ${calcHeight(320)}px;
    margin-bottom: ${calcHeight(20)}px;
  `,
  SignUpButton: styled(GenericButton)`
    height: ${calcHeight(49)}px;
    width: ${calcHeight(320)}px;
    margin-bottom: ${calcHeight(20)}px;
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default InitialForm;
