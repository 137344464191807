import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import BottomSheet from '../../../components/shared/BottomSheet/BottomSheet';
import React, { useCallback } from 'react';
import { Linking, Share } from 'react-native';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import Button from '../../../components/shared/buttons';
import SupportBlock from './SupportBlock';
import { useSelector } from 'react-redux';
import { isGoogleSlidesExplainerVisibleInitialSelector } from '../../../store/appActivity/appActivity.selectors';
import { useTheme } from 'styled-components/native';

interface IProps {
  onReadMorePress: () => void;
}

const HowToGetTheLink: React.FC<IProps> = ({ onReadMorePress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isGoogleSlidesExplainerVisibleInitial = useSelector(isGoogleSlidesExplainerVisibleInitialSelector);

  const handleGetAppLinkForComputerPress = useCallback(() => {
    Share.share({ message: 'https://app.mybites.io/' });
  }, []);

  const handleOpenPresentationUrl = useCallback(() => {
    if (isWeb) {
      window.open(
        'https://docs.google.com/presentation/d/1YDMF086YWHrG1lJygJ6ld2CobdSj6EKL55WhrSmrHK0/edit#slide=id.gb66257aef9_0_0',
        '_blank',
      );
      return;
    }
    Linking.openURL(
      'https://docs.google.com/presentation/d/1YDMF086YWHrG1lJygJ6ld2CobdSj6EKL55WhrSmrHK0/edit#slide=id.gb66257aef9_0_0',
    );
  }, []);

  return (
    <BottomSheet
      title={isWeb ? t('googleSlides.howToGetTheLink') : t('googleSlides.howToGetTheLinkMobile')}
      fontSize={theme.fontSizes.s20}
      height={isWeb ? calcHeight(350) : calcHeight(430)}
      withCloseButton
      isVisibleInitial={isGoogleSlidesExplainerVisibleInitial}
    >
      {isWeb ? (
        <>
          <S.BottomSheetText>{t('googleSlides.bottomSheetText')}</S.BottomSheetText>
          <S.LearnHowWrapper onPress={handleOpenPresentationUrl}>
            <S.LearnHowText>{t('googleSlides.learnHow')}</S.LearnHowText>
            <S.YourBest>{t('googleSlides.yourBest')}</S.YourBest>
            <S.LearnHowText>{t('googleSlides.bite')}</S.LearnHowText>
          </S.LearnHowWrapper>
        </>
      ) : (
        <S.BottomSheetTextContainer>
          <S.BottomSheetTextMobile>{t('googleSlides.bottomSheetTextMobile.title')}</S.BottomSheetTextMobile>
          <S.BottomSheetTextMobile leftOffset={calcWidth(10)}>
            1. {t('googleSlides.bottomSheetTextMobile.usingComputer')}
          </S.BottomSheetTextMobile>
          <S.BottomSheetTextMobile leftOffset={calcWidth(20)}>
            • {t('googleSlides.bottomSheetTextMobile.usingComputerOpen')}
          </S.BottomSheetTextMobile>
          <S.BottomSheetTextMobile leftOffset={calcWidth(20)}>
            • {t('googleSlides.bottomSheetTextMobile.usingComputerCopy')}
          </S.BottomSheetTextMobile>
          <S.BottomSheetTextMobile leftOffset={calcWidth(10)}>
            2. <S.RecommendedText>{t('googleSlides.bottomSheetTextMobile.recommended')}</S.RecommendedText>.{' '}
            {t('googleSlides.bottomSheetTextMobile.recomendedOpen')}
          </S.BottomSheetTextMobile>

          <S.BottomSheetButton
            content={<S.BlueText>{t('googleSlides.bottomSheetTextMobile.getAppLinkForComputer')}</S.BlueText>}
            onPress={handleGetAppLinkForComputerPress}
          />
        </S.BottomSheetTextContainer>
      )}
      <S.Support onReadMorePress={onReadMorePress} />
    </BottomSheet>
  );
};

const S = {
  Support: styled(SupportBlock)`
    margin-top: ${calcHeight(20)}px;
    align-self: center;
  `,
  BottomSheetTextMobile: styled.Text<{ color?: string; leftOffset?: number }>`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme, color }) => color || theme.colors.gray1};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    margin-left: ${({ leftOffset }) => leftOffset || 0}px;
    margin-top: ${calcHeight(10)}px;
    white-space: break-spaces;
  `,
  BottomSheetButton: styled(Button)`
    margin-top: ${calcHeight(20)}px;
    align-self: center;
    height: ${calcHeight(50)}px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  `,
  BlueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
    line-height: ${calcHeight(25)}px;
  `,
  RecommendedText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyBold};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
    line-height: ${calcHeight(25)}px;
  `,
  LearnHowText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    align-items: flex-start;
    align-self: center;
    margin-top: ${calcHeight(10)}px;
  `,
  YourBest: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyBold};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    align-self: end;
  `,
  LearnHowWrapper: styled.TouchableOpacity`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
  `,
  BottomSheetText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.gray1};
    line-height: ${calcHeight(25)}px;
    text-align: center;
    margin-top: ${calcHeight(10)}px;
  `,
  BottomSheetTextContainer: styled.View`
    width: ${deviceWidth - calcWidth(30)}px;
    padding: 0 ${calcWidth(20)}px;
    align-items: flex-start;
    align-self: center;
  `,
};

export default HowToGetTheLink;
