import React from 'react';
import { SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';
import { calcWidth, calcHeight } from '../../../../utils/dimensions';

export interface INavItem {
  text: string;
  color?: string;
  Icon?: React.FC<SvgProps>;
  isHidden?: boolean;
  action: () => void;
  isActive?: boolean;
  dataSet: any;
}

const style = {};
interface IProps extends INavItem {}

const DrawerNavItem: React.FC<IProps> = (props) => {
  const { text, Icon, isHidden, action, isActive, dataSet } = props;

  if (isHidden) {
    return null;
  }

  return (
    <S.LinkContainer
      isActive={isActive}
      onPress={action}
      // @ts-ignore
      dataSet={dataSet}
    >
      <S.IconContainer>
        {Icon && <Icon fill={undefined} height={calcHeight(26)} width={calcWidth(26)} style={style} />}
      </S.IconContainer>
      <S.LinkText>{text}</S.LinkText>
    </S.LinkContainer>
  );
};

const S = {
  LinkContainer: styled.TouchableOpacity<{ isActive?: boolean }>`
    flex-direction: row;
    align-items: center;
    padding: ${calcWidth(12)}px ${calcWidth(22)}px;
    margin: ${calcWidth(5)}px ${calcWidth(10)}px;
    border-radius: 12px;
    ${({ isActive, theme }) => (isActive ? `background-color: ${theme.colors.blueSticky}99;` : '')}
  `,
  IconContainer: styled.TouchableOpacity`
    width: ${calcWidth(28)}px;
    align-items: center;
  `,
  LinkText: styled.Text`
    margin-left: ${calcWidth(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
  `,
};

export default DrawerNavItem;
