import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../../../utils/dimensions';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '../../../../../../hooks/useMedia/constants';
import { selectImageAsCoverDataSet } from '../searchOnline.constants';

const Photo = ({ photo, onSelect }) => {
  const source = useMemo(
    () => ({
      uri: `${photo.src.original}?auto=compress&cs=tinysrgb&fit=crop&h=${photo.sizes.height}&w=${photo.sizes.width}`,
    }),
    [photo.sizes.height, photo.sizes.width, photo.src.original],
  );
  const handleSelect = useCallback(
    () => onSelect(`${photo.src.original}?cs=tinysrgb&fit=crop&h=${DEFAULT_IMAGE_HEIGHT}&w=${DEFAULT_IMAGE_WIDTH}`),
    [onSelect, photo.src.original],
  );

  return (
    <S.PhotoWrapper
      onPress={handleSelect}
      //@ts-ignore
      dataSet={selectImageAsCoverDataSet}
    >
      <S.Photo height={photo.sizes.height} width={photo.sizes.width} source={source} />
    </S.PhotoWrapper>
  );
};

const S = {
  PhotoWrapper: styled.TouchableOpacity`
    margin-bottom: ${calcHeight(10)}px;
  `,
  Photo: styled.Image<{ height: number; width: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 10px;
  `,
};

export default Photo;
