import React, { memo, useCallback, useEffect, useState } from 'react';
import Header from '../Header';
import styled, { useTheme } from 'styled-components/native';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { ActivityIndicator, I18nManager, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import Animated, { FadeIn, FadeOut, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { useTranslation } from 'react-i18next';
import { setWrongVerificationCode } from '../../../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { isWrongVerificationSelector, authInProgressSelector } from '../../../../store/auth/auth.selectors';

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

interface IProps {
  onContinue: (code: string) => void;
  onResend: () => void;
  destination: string;
}

const RESENT_TIME_IN_SEC = 60;

const EnterCodeForm: React.FC<IProps> = ({ onContinue, onResend, destination }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const authInProgress = useSelector(authInProgressSelector);
  const isWrongVerificationCode = useSelector(isWrongVerificationSelector);

  const [isResendTimer, setIsResendTimer] = useState(true);
  const [timer, setTimer] = useState(RESENT_TIME_IN_SEC);

  const [code, setCode] = useState('');
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: code,
    setValue: setCode,
  });
  const otpInputRef = useBlurOnFulfill({ value: code, cellCount: 6 });

  const errorAnimatedStyle = useAnimatedStyle(
    () => ({
      height: withTiming(isWrongVerificationCode ? 16 : 0),
    }),
    [isWrongVerificationCode],
  );

  const handleResendCode = useCallback(() => {
    setCode(null);
    setIsResendTimer(true);
    onResend();
  }, [onResend]);

  const handleCodeChange = useCallback(
    (text: string) => {
      dispatch(setWrongVerificationCode(false));

      if (!text.match(/^[0-9]*$/)) {
        return;
      }

      setCode(text);

      if (text.length === 6) {
        onContinue(text);
      }
    },
    [dispatch, onContinue],
  );

  const renderCell = useCallback(
    ({ index, symbol, isFocused }) => (
      <S.Text key={index} isFocused={isFocused} onLayout={getCellOnLayoutHandler(index)}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </S.Text>
    ),
    [getCellOnLayoutHandler],
  );

  useEffect(() => {
    let interval = null;

    if (isResendTimer && timer > 0) {
      interval = setInterval(() => {
        setTimer((val) => val - 1);
      }, 1000);
    } else if (timer < 1) {
      clearInterval(interval);
      setTimer(RESENT_TIME_IN_SEC);
      setIsResendTimer(false);
    }

    return () => clearInterval(interval);
  }, [isResendTimer, timer]);

  useEffect(() => {
    if (isWrongVerificationCode) {
      setCode(null);
    }
  }, [isWrongVerificationCode]);

  useEffect(() => {
    setTimeout(() => otpInputRef.current?.focus(), 150);
  }, [otpInputRef]);

  return (
    <S.Container>
      <Header
        title={t('authForm.enterCodeForm.title')}
        description={t('authForm.enterCodeForm.description')}
        destination={destination}
      />
      <CodeField
        ref={otpInputRef}
        {...props}
        value={code}
        onChangeText={handleCodeChange}
        cellCount={6}
        rootStyle={S.OTPInputContainerStyles}
        keyboardType='number-pad'
        textContentType='oneTimeCode'
        InputComponent={S.CellInput}
        renderCell={renderCell}
        caretHidden={false}
      />
      {isWrongVerificationCode && (
        <S.ErrorText style={errorAnimatedStyle}>{t('authForm.enterCodeForm.pleaseTryAgain')}</S.ErrorText>
      )}
      {authInProgress ? (
        <S.LoaderContainer entering={FadeIn} exiting={FadeOut}>
          <S.Loader size='large' color={theme.colors.primaryBlue} />
          <S.LoaderText>{t('authForm.enterCodeForm.checking')}</S.LoaderText>
        </S.LoaderContainer>
      ) : isResendTimer ? (
        <S.ResendTimerWrapper entering={FadeIn} exiting={FadeOut}>
          <S.ResendTimer>
            {t('authentication.verification.resendIn', {
              seconds: timer,
            })}
          </S.ResendTimer>
        </S.ResendTimerWrapper>
      ) : (
        <S.GetANewCodeButton entering={FadeIn} exiting={FadeOut} onPress={handleResendCode}>
          <S.GetANewCodeButtonText>{t('authForm.enterCodeForm.getNewCode')}</S.GetANewCodeButtonText>
        </S.GetANewCodeButton>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View``,
  OTPInputContainerStyles: {
    height: calcHeight(49),
    marginTop: calcHeight(45),
    marginHorizontal: calcWidth(16),
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
  CellInput: styled.TextInput`
    height: ${calcHeight(45)};
  `,
  LoaderContainer: styled(Animated.View)`
    justify-content: center;
    align-items: center;
    margin-bottom: ${calcHeight(24)}px;
    margin-top: ${calcHeight(24)}px;
  `,
  Loader: styled(ActivityIndicator)``,
  LoaderText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s12};
    color: ${({ theme }) => theme.colors.gray19};
    margin-top: ${calcHeight(7)}px;
  `,
  GetANewCodeButton: styled(AnimatedTouchable)`
    align-self: center;
    margin-bottom: ${calcHeight(27)}px;
    margin-top: ${calcHeight(44)}px;
  `,
  GetANewCodeButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  ResendTimer: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.gray19};
  `,
  ErrorText: styled(Animated.Text)`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.pinkError};
    margin-top: ${calcHeight(7)}px;
    align-self: center;
  `,
  ResendTimerWrapper: styled(Animated.View)`
    align-self: center;
    margin-bottom: ${calcHeight(27)}px;
    margin-top: ${calcHeight(44)}px;
  `,
  Text: styled.Text<{ isFocused: boolean }>`
    width: ${calcHeight(45)}px;
    height: ${calcHeight(45)}px;
    border-radius: ${calcHeight(45 / 2)}px;
    border-width: 1px;
    border-color: ${({ theme, isFocused }) => (isFocused ? theme.colors.primaryBlue : theme.colors.lightGray34)};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    margin: 0 ${calcWidth(2)}px;
    padding: ${calcHeight(12)}px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `,
};

export default memo(EnterCodeForm);
