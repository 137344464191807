import { AxiosPromise } from 'axios';

export enum EQuestionType {
  'TEXT_INPUT' = 'TEXT_INPUT',
  'BOOLEAN' = 'BOOLEAN',
  'SINGLE_SELECT' = 'SINGLE_SELECT',
  'MULTI_SELECT' = 'MULTI_SELECT',
}

export interface IQuestionOption {
  id: string;
  label: string;
  icon?: string;
  icon_selected?: string;
}

export interface IQuestion {
  id: string;
  type: EQuestionType;
  description?: string;
  title: string;
  hubspotSyncField: string;
  hubspotSyncObject: EHubspotSyncObject;
  canGoBack: boolean;
  isSkippable: boolean;
  options: IQuestionOption[];
  allowCustomValue?: boolean;
}

export interface IQuestionnaire {
  id: string;
  questions: IQuestion[];
}

export enum EQuestionAnswerType {
  'string' = 'string',
  'number' = 'number',
  'array' = 'array',
  'boolean' = 'boolean',
}

export enum EHubspotSyncObject {
  'user' = 'user',
  'company' = 'company',
}

export interface IQuestionAnswer {
  type?: EQuestionAnswerType;
  hubspotSyncField?: string;
  hubspotSyncObject?: EHubspotSyncObject;
  value: string | boolean | string[];
}

export interface IUserQuestionnaire {
  id?: number;
  questionnaireId: IQuestionnaire['id'];
  answers: {
    [questionId: string]: IQuestionAnswer;
  };
}

export interface IGetUserQuestionnairesResult {
  results: { userQuestionnaire: IUserQuestionnaire }[];
}

export interface ISaveUserQuestionnaireResult {
  userQuestionnaire: IUserQuestionnaire;
}

export type TGetUserQuestionnaires = () => AxiosPromise<IGetUserQuestionnairesResult>;

export type TCreateUserQuestionnaire = (payload: {
  userQuestionnaire: IUserQuestionnaire;
}) => AxiosPromise<ISaveUserQuestionnaireResult>;

export type TUpdateUserQuestionnaire = (
  id: IUserQuestionnaire['id'],
  payload: {
    userQuestionnaire: IUserQuestionnaire;
  },
) => AxiosPromise<ISaveUserQuestionnaireResult>;

export type TGetQuestionnaire = () => AxiosPromise<IQuestionnaire>;

export interface IInitialState {
  questionnairesMap: {
    [id: string]: IQuestionnaire;
  };
  userQuestionnaires: IUserQuestionnaire[];
  userQuestionnairesMapByQuestionnaireId: {
    [questionnaireId: string]: IUserQuestionnaire;
  };
}
