import React, { JSX } from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';

type Props = {
  title: string;
  renderControls?: () => JSX.Element;
};
const SidebarTitle = ({ title, renderControls }: Props) => {
  return (
    <S.Title>
      <S.TitleText>{title}</S.TitleText>
      {renderControls?.() || null}
    </S.Title>
  );
};
export default memo(SidebarTitle);

export const S = {
  Title: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    margin: 4px 4px 0px 12px;
    height: 44px;
  `,
  TitleText: styled.div`
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
};
