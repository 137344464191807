import React from 'react';
import { Linking, Alert, TouchableOpacity } from 'react-native';

import { isWeb } from '../../utils/dimensions';

const errorMsg = 'There was a problem opening opening the link, please try again';

interface Props {
  url: string;
  children: React.ReactNode;
}

function ExternalLink({ url, children, ...restOfProps }: Props) {
  const openUrlForNative = async () => {
    try {
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      } else {
        isWeb ? alert(errorMsg) : Alert.alert(errorMsg);
      }
    } catch {
      isWeb ? alert(errorMsg) : Alert.alert(errorMsg);
    }
  };

  const NativeLink = (
    <TouchableOpacity onPress={() => openUrlForNative()} {...restOfProps}>
      {children}
    </TouchableOpacity>
  );

  const WebLink = (
    <a target='_blank' rel='noreferrer noopener' href={url} style={{ textDecoration: 'none' }} {...restOfProps}>
      {children}
    </a>
  );

  return isWeb ? WebLink : NativeLink;
}

export default ExternalLink;
