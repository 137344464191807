import { Share } from 'react-native';
import store from '../../../store';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { IS_ANDROID } from '../../../utils/constants/env';

const openShareLinkForNative = async ({ copyTitle, copyText, afterShareAction }) => {
  try {
    const result = await Share.share(
      {
        title: copyTitle,
        message: copyText,
      },
      {
        // Android only:
        dialogTitle: 'My bite shares',
        // iOS only:
        excludedActivityTypes: [],
      },
    );

    const isSharedAction = result.action === Share.sharedAction;
    const isSharedActivityHandled = isSharedAction && (!!result.activityType || IS_ANDROID);

    if (isSharedActivityHandled) {
      afterShareAction?.();
    }
  } catch (error) {
    store.dispatch(
      logError({
        event: 'openShareLinkForNative: error',
        error,
      }),
    );
  }
};

export default openShareLinkForNative;
