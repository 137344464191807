import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAnalyticsTabs from '../hooks/useAnalyticsTabs';
import {
  displayModeSelector,
  guestsAttributeSelector,
  isGuestsAttributeLoadedSelector,
  sharedWithFilterSelector,
} from '../../../store/attributes/attributes.selector';
import React, { useCallback, useEffect, useMemo } from 'react';
import { log } from '../../../store/appActivity/appActivity.slice';
import { getGuestsAttribute } from '../../../store/attributes/attributes.slice';
import { ISection } from './AttributesList/AttributesList';
import { ESharedWithFilter, IAttributeValue, IGuestsAttributeValue } from '../../../store/attributes/attributes.types';
import SectionHeader from './AttributesList/SectionHeader';
import AttributeValueItem from './AttributesList/AttributeValueItem';
import styled from 'styled-components/native';
import { useIsFocused } from '@react-navigation/native';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import { calcHeight } from '../../../utils/dimensions';

interface IProps {
  isRefreshing?: boolean;
  currentTab: EAnalyticsScreenTabs;
  customData?: { data: IAttributeValue; isLoading: boolean; error?: string };
}

const GuestsAttribute: React.FC<IProps> = ({ isRefreshing, currentTab, customData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFocused = useIsFocused();

  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const showViews =
    isViewsTabEnabled && (currentTab === EAnalyticsScreenTabs.OVERVIEW || currentTab === EAnalyticsScreenTabs.VIEWS);
  const showAnswers =
    isAnsweredTabEnabled &&
    (currentTab === EAnalyticsScreenTabs.OVERVIEW || currentTab === EAnalyticsScreenTabs.ANSWERS);
  const showComments =
    isCommentsTabEnabled &&
    (currentTab === EAnalyticsScreenTabs.OVERVIEW || currentTab === EAnalyticsScreenTabs.COMMENTS);

  const sharedWithFilter = useSelector(sharedWithFilterSelector);
  const displayMode = useSelector(displayModeSelector);
  const isGuestsAttributeLoaded = useSelector(isGuestsAttributeLoadedSelector(currentTab));
  const guestsAttribute = useSelector(guestsAttributeSelector);
  const { data, isLoading, error } = customData || guestsAttribute;

  const handleLoad = useCallback(() => {
    dispatch(
      log({
        event: 'GuestsAttribute.handleLoad',
      }),
    );

    dispatch(getGuestsAttribute({ currentTab }));
  }, [dispatch, currentTab]);

  const formattedHeaderValue: ISection = useMemo(
    () => ({
      title: error ? t('analytics.overview.guests') : '',
      data: data ? [data] : [],
      isLoading,
      isError: !!error,
      isDisplaySkeleton: isLoading && !isRefreshing,
    }),
    [data, error, isLoading, isRefreshing, t],
  );

  const formattedValue: IGuestsAttributeValue = useMemo(
    () => ({
      name: t('analytics.overview.guests'),
      views_absolute: data?.views_absolute,
      answers_absolute: data?.answers_absolute,
      comments_absolute: data?.comments_absolute,
      total_users_count: data?.total_users_count,
    }),
    [data?.answers_absolute, data?.comments_absolute, data?.total_users_count, data?.views_absolute, t],
  );

  useEffect(() => {
    if (!isFocused || isLoading || isGuestsAttributeLoaded) {
      return;
    }
    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, isGuestsAttributeLoaded]);

  if (
    formattedValue.total_users_count === 0 ||
    (sharedWithFilter[ESharedWithFilter.COMMUNICATION_HUB] && !sharedWithFilter[ESharedWithFilter.ORGANICALLY])
  ) {
    return null;
  }

  return (
    <S.Attribute>
      <SectionHeader section={formattedHeaderValue} onRefresh={handleLoad} skeletonRowsNum={1} />
      {(!isLoading || isRefreshing) && !error && (
        <AttributeValueItem
          isViewsTabEnabled={showViews}
          isAnsweredTabEnabled={showAnswers}
          isCommentsTabEnabled={showComments}
          displayMode={displayMode}
          item={formattedValue}
          isBoldTitle
          hasMultipleValues={currentTab === EAnalyticsScreenTabs.OVERVIEW}
          withDivider
        />
      )}
    </S.Attribute>
  );
};

const S = {
  Attribute: styled.View`
    margin-bottom: ${calcHeight(24)}px;
  `,
  AttributeName: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.black};
  `,
  AttributeValue: styled.Text`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
};

export default GuestsAttribute;
