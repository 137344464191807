import store from '../store';
import { logError } from '../store/appActivity/appActivity.slice';
import { MAX_RETRIES_DEFAULT } from './constants/common';

interface IOptions {
  delayTs?: number;
  maxAttempts?: number;
  errorContext?: { [key: string]: any };
  onError?: (error) => void;
}
export interface IWithRetryProps {
  attempt?: number;
  isLastAttempt?: boolean;
  setErrorContext?: (context: any) => void;
}
const withRetry = async <T>(fn: (fnProps?: IWithRetryProps) => Promise<T>, options: IOptions = {}): Promise<T> => {
  const { maxAttempts = MAX_RETRIES_DEFAULT, errorContext = {}, onError, delayTs = 1000 } = options;

  let attempt = 1;
  let context = errorContext;

  while (attempt <= maxAttempts) {
    try {
      const result = await fn({
        attempt,
        isLastAttempt: attempt === maxAttempts,
        setErrorContext: (newContext) => {
          context = newContext;
        },
      });

      return result;
    } catch (error) {
      console.error(error);

      store.dispatch(
        logError({
          ...context,
          event: 'withRetry: error',
          data: {
            ...(context?.data || {}),
            attempt,
            isLastAttempt: attempt === maxAttempts,
            error,
            // axios case
            errorResponse: error?.response,
          },
        }),
      );

      if (typeof onError === 'function') {
        try {
          onError(error);
        } catch (_error) {}
      }

      if (attempt === maxAttempts) {
        throw error;
      }

      await new Promise((resolve) => setTimeout(resolve, attempt * delayTs));
      attempt++;
    }
  }
};
export default withRetry;
