import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  answersAggregatedErrorSelector,
  answersAggregatedLoadingSelector,
  answersAggregatedSelector,
  answersIdsFilterSelector,
  answersQuestionSelector,
  biteIdSelector,
} from '../../../store/analytics/analytics.selector';
import AnswerItem from './AnswerItem';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { getAnswersAggregated, setAnswersIdsFilter } from '../../../store/analytics/analytics.slice';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import { QuestionSection } from '../../../types/bite';
import { EQuestionType, IBiteAnswer } from '../../../types/biteQuestion';
import Error from '../../bite/common/Error';
import { useIsFocused } from '@react-navigation/native';
import { log } from '../../../store/appActivity/appActivity.slice';
import { aggregationAnswerItemDataSet } from '../analytics.constants';

interface IProps {
  onSelect?: () => void;
  isRefreshing?: boolean;
  onLayout?: (event: any) => void;
}

const AnswersAggregation: React.FC<IProps> = ({ onSelect, isRefreshing, onLayout }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const biteId = useSelector(biteIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const biteQuestion = bite?.bite_sections?.find((section) => section.type === 'question') as QuestionSection;
  const answersQuestion = useSelector(answersQuestionSelector);
  const question = answersQuestion || biteQuestion?.questions[0];
  const answersAggregated = useSelector(answersAggregatedSelector);
  const answersAggregatedError = useSelector(answersAggregatedErrorSelector);
  const answersAggregatedLoading = useSelector(answersAggregatedLoadingSelector);
  const selectedAnswers = useSelector(answersIdsFilterSelector);

  useEffect(() => {
    if (
      question.choices.length === 0 ||
      answersAggregatedLoading ||
      !isFocused ||
      (answersAggregated.absolute !== null && !answersAggregatedError)
    ) {
      return;
    }

    dispatch(getAnswersAggregated());
  }, [
    answersAggregated,
    answersAggregatedError,
    answersAggregatedLoading,
    dispatch,
    isFocused,
    question.choices.length,
  ]);

  const filteredAnswers = useMemo(() => {
    if (question?.type === EQuestionType.OPEN_ENDED) {
      return null;
    }

    if (!selectedAnswers.length) {
      return question?.choices;
    }

    return question.choices.filter((answer: IBiteAnswer) => selectedAnswers.includes(answer.id));
  }, [question?.choices, question?.type, selectedAnswers]);

  const handleAnswerPress = useCallback(
    (answerId: number) => {
      dispatch(
        log({
          event: 'AnswersAggregation.handleAnswerPress',
          data: { answerId, selectedAnswers },
        }),
      );

      if (selectedAnswers.includes(answerId)) {
        dispatch(setAnswersIdsFilter([]));

        if (typeof onSelect === 'function') {
          onSelect();
        }

        return;
      }

      dispatch(setAnswersIdsFilter([answerId]));
      if (typeof onSelect === 'function') {
        onSelect();
      }
    },
    [dispatch, onSelect, selectedAnswers],
  );

  const renderAnswer = useCallback(
    (answer) => {
      const percent = answersAggregated?.percentage?.[answer.id] || 0;
      const withCloseControl = selectedAnswers.length > 0;
      const isCorrect = question?.correct_choice_id === answer.id;

      return (
        <AnswerItem
          // @ts-ignore
          dataSet={aggregationAnswerItemDataSet}
          key={answer.id}
          isRefreshing={isRefreshing}
          answer={answer}
          onPress={handleAnswerPress}
          percent={percent}
          withCloseControl={withCloseControl}
          isCorrect={isCorrect}
          isLoading={answersAggregatedLoading}
        />
      );
    },
    [
      answersAggregated?.percentage,
      answersAggregatedLoading,
      handleAnswerPress,
      isRefreshing,
      question?.correct_choice_id,
      selectedAnswers.length,
    ],
  );

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AnswersAggregation.handleRefresh',
      }),
    );
    dispatch(getAnswersAggregated());
  }, [dispatch]);

  if (answersAggregatedError) {
    return <Error onRefresh={handleRefresh} />;
  }

  return (
    <S.Container onLayout={onLayout}>
      <S.QuestionText>{question.text}</S.QuestionText>
      {filteredAnswers && <S.AnswersContainer>{filteredAnswers.map(renderAnswer)}</S.AnswersContainer>}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    padding: ${calcHeight(28)}px 0 ${calcHeight(14)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  QuestionText: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-weight: 400;
    margin: 0 ${calcWidth(22)}px ${calcHeight(24)}px;
  `,
  AnswersContainer: styled.View<{ isMinimized?: boolean }>`
    width: 100%;
  `,
};

export default memo(AnswersAggregation);
