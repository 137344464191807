import { RootState } from '../index';

export const resultsSelector = (state: RootState) => state.feed.results;
export const nextPageSelector = (state: RootState) => state.feed.nextPage;
export const isLoadingSelector = (state: RootState) => state.feed.isLoading;
export const isHasUserContentSelector = (state: RootState) => state.feed.hasUserContent;
export const sortFieldSelector = (state: RootState) => state.feed.sortField;
export const sortDirectionSelector = (state: RootState) => state.feed.sortDirection;
export const searchStrSelector = (state: RootState) => state.feed.searchStr;
export const typesSelector = (state: RootState) => state.feed.types;
export const creatorIdsSelector = (state: RootState) => state.feed.creatorIds;
export const creatorsLabelSelector = (state: RootState) => state.feed.creatorsLabel;
