import { useSelector } from 'react-redux';
import { biteIdSelector } from '../../../store/analytics/analytics.selector';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import { useMemo } from 'react';
import { EditBiteIconType } from '../../editBite/EditMain/common/SectionItem';
import { IntroSection, QuestionSection } from '../../../types/bite';
import { isEmpty } from 'lodash';

const useAnalyticsTabs = () => {
  const biteId = useSelector(biteIdSelector);
  const bite = useSelector(biteItemSelector(biteId));

  const isViewsTabEnabled = useMemo(() => {
    const currentSection = bite?.bite_sections?.find((item) => item.type === EditBiteIconType.INTRO) as IntroSection;

    if (!currentSection) {
      return false;
    }

    return !(currentSection.task_id === null && currentSection.media === null);
  }, [bite?.bite_sections]);

  const isAnsweredTabEnabled = useMemo(() => {
    const currentSection = bite?.bite_sections?.find(
      (item) => item.type === EditBiteIconType.QUESTION,
    ) as QuestionSection;

    if (!currentSection) {
      return false;
    }

    return !isEmpty(currentSection.questions);
  }, [bite?.bite_sections]);

  return {
    isViewsTabEnabled,
    isAnsweredTabEnabled,
    isCommentsTabEnabled: bite.discussion_enabled,
  };
};

export default useAnalyticsTabs;
