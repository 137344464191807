export enum EAuthFormType {
  AUTH = 'AUTH',
  FORGOT_PASSWORD_EMAIL = 'FORGOT_PASSWORD_EMAIL',
  FORGOT_PASSWORD_INFO = 'FORGOT_PASSWORD_INFO',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ENTER_CODE_EMAIL = 'ENTER_CODE_EMAIL',
  ENTER_CODE_PHONE = 'ENTER_CODE_PHONE',
  USER_INFO = 'USER_INFO',
  ACCESS_CODE = 'ACCESS_CODE',
  INITIAL = 'INITIAL',
}

export enum EAuthFlowType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
}
