import { all, put, takeLatest, select } from 'redux-saga/effects';
import { IBiteItem } from '../../types/bite';
import { IAction } from '../common/types';
import { fetchBite } from '../api/bites-api/calls/bite.calls';
import { configSelector } from '../appActivity/appActivity.selectors';
import { fetchPlaylist } from '../api/bites-api/calls/playlist.calls';
import {
  fetchExploreBitesError,
  fetchExploreBitesRequest,
  fetchExploreBitesSuccess,
  previewExploreBiteById,
} from './explore.slice';
import { navigationRef } from '../../navigation/RootNavigation';

import Routes from '../../navigation/routes';

import { IConfig } from '../appActivity/appActivity.types';
import { logError } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';

function* fetchExploreBitesSaga() {
  try {
    const config: IConfig = yield select(configSelector);
    const { data } = yield fetchPlaylist(config.exploreBites.playlistId);
    const bites: IBiteItem[] = data.bite_shares;
    yield put(fetchExploreBitesSuccess(bites));
  } catch (err) {
    yield put(fetchExploreBitesError());
  }
}

function* previewExploreBiteByIdSaga({ payload }: IAction<number>) {
  try {
    const { data: bite } = yield fetchBite(payload);

    navigationRef.current.navigate(Routes.PreviewStack.StackName, {
      screen: Routes.PreviewStack.PreviewExploreBite,
      params: {
        bite,
      },
    });
  } catch (error) {
    yield put(
      logError({
        event: 'previewExploreBiteByIdSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

export default function* exploreSagaWatcher() {
  yield all([
    takeLatest(fetchExploreBitesRequest, fetchExploreBitesSaga),
    takeLatest(previewExploreBiteById, previewExploreBiteByIdSaga),
  ]);
}
