import React from 'react';
import Back from './Back';
import { calcWidth } from '../../utils/dimensions';
import { IHeaderBackButton } from '../../types/header';

const BackHeader: React.FC<IHeaderBackButton> = (props) => {
  return <Back {...props} style={{ paddingHorizontal: calcWidth(11) }} />;
};

export default BackHeader;
