import { useTranslation } from 'react-i18next';
import 'i18next';

const useCustomTranslation = (customPrefix: string) => {
  const { t } = useTranslation();

  const prefixedT = (path: string) => {
    return t(`${customPrefix}.${path}`);
  };

  return { t, prefixedT };
};

export default useCustomTranslation;
