import React, { FC, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components/native';
import { IStackNavigation } from '../../navigation/types';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import { KeyboardAwareView } from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCreatePlaylistState,
  setPlaylistSubject,
  updatePlaylistSettings,
} from '../../store/createPlaylist/createPlaylist.actions';
import { createPlaylistSelector } from '../../store/createPlaylist/createPlaylist.selectors';
import Header from '../../components/Header';
import PlaylistSettingsModal from '../createPlaylist/PlaylistSettingsModal/PlaylistSettingsModal';
import { updatePlaylist } from '../../store/api/bites-api/calls/playlist.calls';
import { EShareItemType } from '../../store/sharePanel/sharePanel.slice';
import { useDebouncedCallback } from 'use-debounce/lib';
import SectionItem, { EType } from './SectionItem';
import PointsButton from '../../components/shared/buttons/PointsButton';
import HomeButton from '../../components/shared/HomeButton';
import EditNameInput from '../../components/EditNameInput';
import { activeOrganizationSelector } from '../../store/auth/auth.selectors';
import PlaylistBitesCard from '../../components/playlist/PlaylistBitesCard/PlaylistBitesCard';
import {
  deletePlaylist,
  fetchPlaylistsRequest,
  patchPlaylist,
  setPlaylists,
} from '../../store/playlist/playlist.actions';
import ShareSection from '../creationBite/common/ShareSection';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { TouchableOpacity } from 'react-native';
import BlueAndRedButtonsModal from '../../components/modals/BlueAndRedButtonsModal';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../navigation/routes';
import Button from '../../components/shared/buttons/Button/Button';
import { useTheme } from 'styled-components/native';
import { playlistSettingsDataSet } from '../createPlaylist/PlaylistSettingsModal/PlaylistSettings.constants';
import { StackNavigationProp } from '@react-navigation/stack';

const buttonStyle = {
  height: calcHeight(50),
  width: calcWidth(148),
};

interface IProps extends IStackNavigation {}
const EditPlaylist: FC<IProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const org = useSelector(activeOrganizationSelector);
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { subject, editedPlaylist, playlistSettings } = useSelector(createPlaylistSelector);

  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [localNameValue, setLocalNameValue] = useState(subject);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const closeSettingsModal = useCallback(() => setSettingsModalVisible(false), []);
  const handleOpenSettingsModal = useCallback(() => setSettingsModalVisible(true), []);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const openDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCreatePlaylistState());
      dispatch(fetchPlaylistsRequest(org.id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePlaylistName = useDebouncedCallback((value: string) => {
    dispatch(patchPlaylist(editedPlaylist?.id, { subject: value }));
  }, 300);

  const handleOnChangeNameText = useCallback(
    (value) => {
      if (value.length <= 40 && value !== subject) {
        setLocalNameValue(value);
      }
    },
    [setLocalNameValue, subject],
  );

  const handleSavePlaylistName = useCallback(
    (value: string) => {
      dispatch(
        trackEvent({
          event: 'rename_playlist',
          props: { from: 'edit_playlist', playlist_id: editedPlaylist?.id },
        }),
      );
      dispatch(setPlaylistSubject(value));
      updatePlaylistName.callback(value);
    },
    [dispatch, editedPlaylist?.id, updatePlaylistName],
  );

  const handleApplyToggleIsPublic = useCallback(
    async (isPublic) => {
      dispatch(updatePlaylistSettings('is_public'));
      closeSettingsModal();
      const { data: newPlaylist } = await updatePlaylist(editedPlaylist?.id, {
        is_public: isPublic,
      });
      dispatch(setPlaylists([newPlaylist]));
    },
    [dispatch, editedPlaylist?.id, closeSettingsModal],
  );

  const handleDeletePress = useCallback(() => {
    closeSettingsModal();
    openDeleteModal();
  }, [closeSettingsModal, openDeleteModal]);

  const renderSettingsLeftButton = useCallback(() => {
    return (
      <TouchableOpacity onPress={handleDeletePress}>
        <S.ButtonText>{t('playlistCreated.settings.delete')}</S.ButtonText>
      </TouchableOpacity>
    );
  }, [handleDeletePress, t]);

  const handleDeletePlaylist = useCallback(() => {
    if (!editedPlaylist.is_quiz) {
      dispatch(
        trackEvent({
          event: 'delete',
          props: { playlist_id: editedPlaylist.id },
        }),
      );
    } else {
      dispatch(
        trackEvent({
          event: 'delete',
          props: { quiz_id: editedPlaylist.id },
        }),
      );
    }

    dispatch(deletePlaylist(editedPlaylist.id));
    closeDeleteModal();

    if (isWeb) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Routes.HomeStack.StackName,
            params: {
              screen: Routes.HomeStack.Home,
            },
          },
        ],
      });
      return;
    }

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [closeDeleteModal, dispatch, editedPlaylist?.id, editedPlaylist?.is_quiz, navigation]);

  const handleOpenPreview = useCallback(() => {
    navigation.navigate(Routes.PreviewStack.StackName, {
      screen: Routes.PreviewStack.PreviewPlaylist,
      params: {
        playlistId: editedPlaylist?.id,
        isEdit: true,
      },
    });
  }, [editedPlaylist, navigation]);

  const renderLeftComponent = useCallback(() => {
    return (
      <Button
        isShadowed
        onPress={handleOpenPreview}
        disabled={!editedPlaylist?.id}
        text={t('common.Preview')}
        style={buttonStyle}
        fill={theme.colors.white}
        border={theme.colors.primaryBlue}
        textColor={theme.colors.primaryBlue}
      />
    );
  }, [editedPlaylist?.id, handleOpenPreview, t, theme.colors.primaryBlue, theme.colors.white]);

  const renderHeaderRight = useCallback(
    () => (
      <PointsButton
        onPress={handleOpenSettingsModal}
        //@ts-ignore
        dataSet={playlistSettingsDataSet}
      />
    ),
    [handleOpenSettingsModal],
  );

  const renderHeaderLeft = useCallback(() => {
    return <HomeButton />;
  }, []);

  return (
    <>
      <S.Container>
        <>
          <Header headerRight={renderHeaderRight} headerLeft={renderHeaderLeft} title={t('editPlaylist.title')} />
          <PlaylistBitesCard />
          <S.EditNameContainer>
            <EditNameInput
              onAccept={handleSavePlaylistName}
              onChangeText={handleOnChangeNameText}
              value={localNameValue}
            />
          </S.EditNameContainer>
          <S.SectionsContainer>
            <SectionItem type={EType.EDIT} />
          </S.SectionsContainer>
          {!!editedPlaylist?.id && (
            <ShareSection
              subject={subject}
              shareData={editedPlaylist}
              shareItemType={EShareItemType.PLAYLIST}
              from='edit_playlist'
              leftButtonComponent={renderLeftComponent}
            />
          )}
        </>
      </S.Container>
      <PlaylistSettingsModal
        renderLeftButton={renderSettingsLeftButton}
        isVisible={isSettingsModalVisible}
        onClose={closeSettingsModal}
        isPublicDefault={playlistSettings.is_public}
        onApplyToggleIsPublic={handleApplyToggleIsPublic}
      />

      <BlueAndRedButtonsModal
        title={t('deletePlaylistModal.title')}
        description={t('deletePlaylistModal.description')}
        isVisible={isDeleteModalVisible}
        onClose={closeDeleteModal}
        onRightButtonClick={handleDeletePlaylist}
        onLeftButtonClick={closeDeleteModal}
        rightButtonLabel={t('deletePlaylistModal.delete')}
        leftButtonLabel={t('deletePlaylistModal.cancel')}
      />
    </>
  );
};

const S = {
  Container: styled(KeyboardAwareView)``,
  PreviewButton: styled.TouchableOpacity`
    background: ${({ theme }) => theme.colors.transparentBlack2};
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
    padding: ${calcHeight(10)}px;
    height: ${calcHeight(42)}px;
    border-radius: ${calcHeight(42 / 2)}px;
    width: ${calcWidth(185)}px;
    margin-bottom: ${isWeb ? calcHeight(198) : calcHeight(158)}px;
  `,
  PreviewButtonText: styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: bold;
  `,
  SectionsContainer: styled.View`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    margin-top: ${calcHeight(0)}px;
    margin-bottom: ${isWeb ? calcHeight(5) : calcHeight(35)}px;
  `,
  EditNameContainer: styled.View`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
  `,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.SFProRegular};
  `,
};

export default EditPlaylist;
