import { useDispatch, useSelector } from 'react-redux';
import {
  overviewDataSelector,
  overviewLoadingSelector,
  overviewFilterSelector,
  overviewErrorSelector,
  overviewNextSelector,
  currentListSelector,
  isNeedToScrollUpOverviewSelector,
} from '../../../store/analytics/analytics.selector';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import ListItemComponent from '../common/ListItem/ListItem';
import Filter from '../common/Filter';
import { getOverviewData, setOverviewFilter, setOverviewData } from '../../../store/analytics/analytics.slice';
import { OverviewIcons } from '../common/Icons';
import { ECurrentList, EEngagedFilterValue, IAnalyticsViewsListItem } from '../../../store/analytics/analytics.types';
import List from '../common/List';
import { useIsFocused } from '@react-navigation/native';
import HeaderTabs from '../common/HeaderTabs';
import { log } from '../../../store/appActivity/appActivity.slice';
import useOverviewAttributesMap from '../hooks/useOverviewAttributesMap';
import useHasAttributesToRender from '../hooks/useHasAttributesToRender';
import useHasOrganizationAttributes from '../hooks/useHasOrganizationAttributes';
import { IStackNavigation } from '../../../navigation/types';
import useAttributesData from '../hooks/useAttributesData';
import { getOverviewAttributes } from '../../../store/attributes/attributes.slice';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import { OVERVIEW_ATTRIBUTES_SCREEN, OVERVIEW_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../hooks/useHeaderTabsDivider';
import { analyticsListDataSet } from '../analytics.constants';

const OVERVIEW_FILTERS = [
  {
    label: 'analytics.filter.all',
    value: EEngagedFilterValue.ALL,
  },
  {
    label: 'analytics.filter.engaged',
    value: EEngagedFilterValue.ENGAGED,
  },
  {
    label: 'analytics.filter.notEngaged',
    value: EEngagedFilterValue.NOT_ENGAGED,
  },
];

const OverviewList: React.FC<IStackNavigation> = ({ navigation }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const isLoading = useSelector(overviewLoadingSelector);
  const overviewList = useSelector(overviewDataSelector);
  const overviewError = useSelector(overviewErrorSelector);
  const overviewNext = useSelector(overviewNextSelector);
  const currentFilter = useSelector(overviewFilterSelector);
  const isNeedToScrollUp = useSelector(isNeedToScrollUpOverviewSelector);

  const { attributesMap } = useOverviewAttributesMap();
  const { hasAttributesToRender } = useHasAttributesToRender({ attributesMap });
  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useAttributesData({
    attributes: attributesMap,
    onLoad: getOverviewAttributes,
  });

  const currentList = useSelector(currentListSelector);
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();

  const renderListItem = useCallback(
    ({ index, item }) => {
      const withDivider = index > 0 && !item.activity && !!overviewList[index - 1]?.activity;
      return <ListItem item={item} withDivider={withDivider} />;
    },
    [overviewList],
  );

  const handleFilterChange = useCallback(
    (filter: EEngagedFilterValue) => {
      dispatch(
        log({
          event: 'OverviewList.handleFilterChange',
          data: { filter },
        }),
      );

      dispatch(setOverviewData({ results: [], next: null, reset: true }));
      dispatch(setOverviewFilter(filter));
      dispatch(getOverviewData());
    },
    [dispatch],
  );

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'OverviewList.handleEndReached',
        data: { overviewNext, isLoading, overviewError },
      }),
    );
    dispatch(getOverviewData());
  }, [dispatch, isLoading, overviewError, overviewNext]);

  const handleEndReachedProp = useMemo(() => {
    if (!overviewNext || isLoading || overviewError) {
      return;
    }

    return handleEndReached;
  }, [handleEndReached, isLoading, overviewError, overviewNext]);

  const handleRefresh = useCallback(
    ({ isPullToRefresh, callback }) => {
      dispatch(
        log({
          event: 'OverviewList.handleRefresh',
        }),
      );
      dispatch(getOverviewData({ reset: true, clearAnalyticsCache: isPullToRefresh, callback }));
    },
    [dispatch],
  );

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'OverviewList.handleErrorRefresh',
      }),
    );
    dispatch(getOverviewData());
  }, [dispatch]);

  const switchedToFocusRef = useRef(isFocused);

  useEffect(() => {
    const switchedToFocus = isFocused && !switchedToFocusRef.current;
    switchedToFocusRef.current = isFocused;

    if (isLoading || !isFocused || (overviewList !== null && (!overviewError || !switchedToFocus))) {
      return;
    }

    dispatch(getOverviewData({ reset: !overviewError }));
  }, [dispatch, isFocused, isLoading, overviewError, overviewList]);

  useEffect(() => {
    if (currentList === ECurrentList.ATTRIBUTES && hasOrganizationAttributes) {
      navigation.replace(OVERVIEW_ATTRIBUTES_SCREEN);
    }
  }, [currentList, hasOrganizationAttributes, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={OVERVIEW_LIST_SCREEN}
        attributesScreen={OVERVIEW_ATTRIBUTES_SCREEN}
        listScreen={OVERVIEW_LIST_SCREEN}
        rightComponent={
          <Filter filters={OVERVIEW_FILTERS} currentFilter={currentFilter} onChangeFilter={handleFilterChange} />
        }
        disableAttributes={!hasAttributesToRender}
        isShowBottomDivider={isShowHeaderBottomDivider}
      />
      <List
        dataSet={analyticsListDataSet}
        data={overviewList}
        renderItem={renderListItem}
        onEndReached={handleEndReachedProp}
        onErrorRefresh={handleErrorRefresh}
        isLoading={isLoading}
        onRefresh={handleRefresh}
        error={overviewError}
        listType={EAnalyticsScreenTabs.OVERVIEW}
        isNeedToScrollUp={isNeedToScrollUp}
        onScroll={handleScroll}
        scrollEventThrottle={10}
      />
    </>
  );
};

interface IListItemProps {
  item: IAnalyticsViewsListItem;
  withDivider?: boolean;
}

const ListItem: React.FC<IListItemProps> = ({ item, withDivider }) => {
  const { activity, user } = item;

  const renderIcons = useCallback(() => {
    return <OverviewIcons activity={activity} />;
  }, [activity]);

  return (
    <ListItemComponent
      lastActivity={activity?.last_interaction_date}
      user={user}
      renderIcons={renderIcons}
      withDivider={withDivider}
    />
  );
};

export default memo(OverviewList);
