import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { draftsSelector } from '../store/drafts/drafts.selectors';
import { loadValues } from '../store/drafts/drafts.slice';

const useIsDraft = (biteId: number) => {
  const dispatch = useDispatch();
  const { draftsMap } = useSelector(draftsSelector);
  const [isDraft, setIsDraft] = useState(false);

  useEffect(() => {
    dispatch(loadValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDraft(!!draftsMap?.[biteId]);
  }, [draftsMap, biteId]);

  return isDraft;
};

export default useIsDraft;
