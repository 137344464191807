import React, { FC, useCallback, useEffect } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { APP_DRAWER_WIDTH } from '../../../utils/constants/drawer';
import { isWeb } from '../../../utils/dimensions';
import DrawerContent from './DrawerContent';
import HomeStack from './HomeStack';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector, leadNameSelector } from '../../../store/auth/auth.selectors';
import Routes from '../../routes';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';

const Drawer = createDrawerNavigator();

const defaultScreenOptions = {
  animationEnabled: !isWeb,
  headerShown: false,
  drawerStyle: isWeb ? { margin: '0 auto', width: '50%' } : { width: APP_DRAWER_WIDTH },
};

const HomeDrawer: FC = () => {
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeOrganizationSelector);
  const leadName = useSelector(leadNameSelector);
  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: 'HomeStack' },
      }),
    );
  }, [activeOrganization.id, dispatch, leadName]);

  const renderDrawerContent = useCallback((props) => <DrawerContent {...props} />, []);

  return (
    <Drawer.Navigator
      drawerContent={renderDrawerContent}
      // @ts-ignore
      screenOptions={defaultScreenOptions}
    >
      <Drawer.Screen name={Routes.HomeStack.StackName} component={HomeStack} />
    </Drawer.Navigator>
  );
};

export default HomeDrawer;
