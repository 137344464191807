import { Platform, Keyboard } from 'react-native';
import { isString, isArray } from 'lodash';
import { isWeb } from './dimensions';

export const filterEmpties = (items: any[], path: string): any[] => items.filter((item) => item[path] !== '');
//TODO: generics

export const normalizeOrder = <T>(items: T[]): T[] =>
  items.map((item, idx) => {
    return { ...item, order: idx + 1 };
  });

export const stopYoutubeVideo = (ref) =>
  Platform.OS !== 'web'
    ? ref?.current?.pause()
    : ref?.current?.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');

export const keyboardDismiss = () => {
  if (isWeb) {
    return;
  }

  Keyboard.dismiss();
};

export const getErrorMessage = (error: any, defaultMessage = 'Unknown error'): string => {
  if (!error) {
    return defaultMessage;
  }

  if (isString(error)) {
    return error;
  }

  if (error.message) {
    return error.message;
  }

  if (error.code) {
    return error.code;
  }

  if (isArray(error)) {
    return error.join(', ');
  }

  return JSON.stringify(error);
};
