import IntroIcon from '../../../assets/icons/analytics/intro_empty.svg';
import QuestionIcon from '../../../assets/icons/analytics/question_empty.svg';
import CommentIcon from '../../../assets/icons/analytics/comment_empty.svg';
import React, { useCallback } from 'react';
import useAnalyticsTabs from '../hooks/useAnalyticsTabs';
import { getIsWebRtl } from '../../../locale/i18n';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import { useDispatch, useSelector } from 'react-redux';
import { sortBySelector, sortDirectionSelector } from '../../../store/attributes/attributes.selector';
import { setSortBy } from '../../../store/attributes/attributes.slice';
import { ESortByType, ESortingDirection } from '../../../store/attributes/attributes.types';
import SortIcon from '../../../assets/icons/analytics/sort.svg';
import {
  ANSWERED_ATTRIBUTES_SCREEN,
  ANSWERED_LIST_SCREEN,
  COMMENTS_ATTRIBUTES_SCREEN,
  COMMENTS_LIST_SCREEN,
  OVERVIEW_ATTRIBUTES_SCREEN,
  OVERVIEW_LIST_SCREEN,
  VIEWS_ATTRIBUTES_SCREEN,
  VIEWS_LIST_SCREEN,
} from '../constants';

const TAB_BY_SCREEN = {
  [OVERVIEW_ATTRIBUTES_SCREEN]: EAnalyticsScreenTabs.OVERVIEW,
  [OVERVIEW_LIST_SCREEN]: EAnalyticsScreenTabs.OVERVIEW,
  [VIEWS_ATTRIBUTES_SCREEN]: EAnalyticsScreenTabs.VIEWS,
  [VIEWS_LIST_SCREEN]: EAnalyticsScreenTabs.VIEWS,
  [ANSWERED_ATTRIBUTES_SCREEN]: EAnalyticsScreenTabs.ANSWERS,
  [ANSWERED_LIST_SCREEN]: EAnalyticsScreenTabs.ANSWERS,
  [COMMENTS_ATTRIBUTES_SCREEN]: EAnalyticsScreenTabs.COMMENTS,
  [COMMENTS_LIST_SCREEN]: EAnalyticsScreenTabs.COMMENTS,
};

const AttributesSorting = ({ currentScreen }) => {
  const dispatch = useDispatch();
  const isWebRtl = getIsWebRtl();
  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const currentTab = TAB_BY_SCREEN[currentScreen];

  const sortBy = useSelector(sortBySelector(currentTab));
  const sortDirection = useSelector(sortDirectionSelector(currentTab));

  const isDisplayViewsIcon =
    (currentScreen === OVERVIEW_ATTRIBUTES_SCREEN || currentScreen === VIEWS_ATTRIBUTES_SCREEN) && isViewsTabEnabled;
  const isDisplayAnsweredIcon =
    (currentScreen === OVERVIEW_ATTRIBUTES_SCREEN || currentScreen === ANSWERED_ATTRIBUTES_SCREEN) &&
    isAnsweredTabEnabled;
  const isDisplayCommentsIcon =
    (currentScreen === OVERVIEW_ATTRIBUTES_SCREEN || currentScreen === COMMENTS_ATTRIBUTES_SCREEN) &&
    isCommentsTabEnabled;

  const sortByViews = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.VIEWS
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ currentTab, sortBy: ESortByType.VIEWS, sortDirection: _sortDirection }));
  }, [currentTab, dispatch, sortBy, sortDirection]);

  const sortByAnswered = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.ANSWERS
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ currentTab, sortBy: ESortByType.ANSWERS, sortDirection: _sortDirection }));
  }, [currentTab, dispatch, sortBy, sortDirection]);

  const sortByComments = useCallback(() => {
    const _sortDirection =
      sortBy === ESortByType.COMMENTS
        ? sortDirection === ESortingDirection.ASC
          ? ESortingDirection.DESC
          : ESortingDirection.ASC
        : ESortingDirection.DESC;
    dispatch(setSortBy({ currentTab, sortBy: ESortByType.COMMENTS, sortDirection: _sortDirection }));
  }, [currentTab, dispatch, sortBy, sortDirection]);

  return (
    <S.IconsContainer isRtl={isWebRtl}>
      {isDisplayViewsIcon && (
        <S.IconSortingButton onPress={sortByViews}>
          <S.IconContainer isRtl={isWebRtl}>
            <IntroIcon />
          </S.IconContainer>
          {sortBy === ESortByType.VIEWS && (
            <S.SortIconContainer sortDirection={sortDirection}>
              <SortIcon />
            </S.SortIconContainer>
          )}
        </S.IconSortingButton>
      )}
      {isDisplayAnsweredIcon && (
        <S.IconSortingButton onPress={sortByAnswered}>
          <S.IconContainer isRtl={isWebRtl}>
            <QuestionIcon />
          </S.IconContainer>
          {sortBy === ESortByType.ANSWERS && (
            <S.SortIconContainer sortDirection={sortDirection}>
              <SortIcon />
            </S.SortIconContainer>
          )}
        </S.IconSortingButton>
      )}
      {isDisplayCommentsIcon && (
        <S.IconSortingButton onPress={sortByComments}>
          <S.IconContainer isRtl={isWebRtl}>
            <CommentIcon />
          </S.IconContainer>
          {sortBy === ESortByType.COMMENTS && (
            <S.SortIconContainer sortDirection={sortDirection}>
              <SortIcon />
            </S.SortIconContainer>
          )}
        </S.IconSortingButton>
      )}
    </S.IconsContainer>
  );
};

const S = {
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 ${calcWidth(10)}px;
  `,
  IconContainer: styled.View<{ isRtl: boolean }>`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(50)}px;
    justify-content: center;
  `,
  IconSortingButton: styled.TouchableOpacity`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  SortIconContainer: styled.View<{ sortDirection: ESortingDirection }>`
    position: absolute;
    bottom: ${calcHeight(-6)}px;
    transform: ${({ sortDirection }) => (sortDirection === ESortingDirection.ASC ? 'rotate(180deg)' : 'rotate(0deg)')};
  `,
};

export default AttributesSorting;
