import React, { CSSProperties } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import webStyled from 'styled-components';
import styled from 'styled-components/native';

interface IProps {
  viewStyle?: StyleProp<ViewStyle>;
  containerViewStyle?: CSSProperties;
  distance?: number;
  shadowColor?: string;
  children: React.ReactNode;
}

const ShadowedContainer: React.FC<IProps> = ({
  viewStyle,
  containerViewStyle,
  children,
  distance = 24,
  shadowColor,
}) => {
  return (
    <S.Shadow shadowColor={shadowColor} distance={distance} style={containerViewStyle}>
      <S.Container style={viewStyle}>{children}</S.Container>
    </S.Shadow>
  );
};

const S = {
  Shadow: webStyled.div<{ distance: number; shadowColor?: string }>`
		border-radius: 29px;
		box-shadow: 0 7px ${({ distance }) => distance}px 0 ${({ shadowColor }) =>
    shadowColor ? shadowColor : 'rgba(106, 105, 125, 0.3)'};
	`,
  Container: styled.View`
    flex: 1;
  `,
};

export default ShadowedContainer;
