import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components/native';
import { searchValueSelector } from '../../../../store/attributes/attributes.selector';
import React, { memo, useCallback } from 'react';
import { getSearchList, setFilterSearchValue } from '../../../../store/attributes/attributes.slice';
import CrossIcon from '../../../../assets/icons/close.svg';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import SearchIcon from '../../../../assets/icons/search-icon.svg';
import FilterIcon from '../../../../assets/icons/filtered.svg';
import { Keyboard } from 'react-native';
import { Divider } from '../../../../components/shared';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { getIsRtl, getIsWebRtl } from '../../../../locale/i18n';
import {
  filterListHeaderClearButtonDataSet,
  filterListHeaderSearchButtonDataSet,
  filterListHeaderSearchClearInputDataSet,
  filterListHeaderSearchDoneButtonDataSet,
  filterListHeaderSearchInputDataSet,
  filterListHeaderSelectButtonDataSet,
} from '../../analytics.constants';

interface IProps {
  isSelected: boolean;
  isSearchMode: boolean;
  selectedAttributeId: number;
  onClear: () => void;
  onDone: () => void;
  onSearch: () => void;
  selectedCount: number;
  onSelectedCountPress: () => void;
}

const HIT_SLOP = {
  top: 10,
  left: 10,
  bottom: 10,
  right: 10,
};

const FilterPanelHeader: React.FC<IProps> = ({
  isSelected,
  isSearchMode,
  selectedAttributeId,
  onClear,
  onDone,
  onSearch,
  selectedCount,
  onSelectedCountPress,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const isWebRtl = getIsWebRtl();
  const isRtl = getIsRtl();

  const searchValue = useSelector(searchValueSelector);

  const handleClearInput = useCallback(() => {
    dispatch(
      log({
        event: 'FilterPanelHeader.handleClearInput',
        data: { selectedAttributeId },
      }),
    );

    dispatch(setFilterSearchValue(''));
    dispatch(getSearchList({ attributeId: selectedAttributeId, reset: true }));
  }, [dispatch, selectedAttributeId]);

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      dispatch(
        log({
          event: 'FilterPanelHeader.handleChangeSearchValue',
          data: { selectedAttributeId, value },
        }),
      );

      dispatch(setFilterSearchValue(value));
      dispatch(getSearchList({ attributeId: selectedAttributeId, reset: true, withDebounce: true }));
    },
    [dispatch, selectedAttributeId],
  );

  const handleDone = useCallback(() => {
    Keyboard.dismiss();
    onDone();
  }, [onDone]);

  if (isSearchMode) {
    return (
      <>
        <S.PanelHeaderContainer isRtl={isWebRtl}>
          <S.PanelHeaderInputContainer>
            <S.PanelHeaderInput
              // @ts-ignore
              dataSet={filterListHeaderSearchInputDataSet}
              isRtl={isRtl}
              isWebRtl={isWebRtl}
              autoFocus
              value={searchValue}
              onChangeText={handleChangeSearchValue}
            />
            {searchValue.length > 0 && (
              <S.CrossIconContainer
                // @ts-ignore
                dataSet={filterListHeaderSearchClearInputDataSet}
                isRtl={isWebRtl}
                hitSlop={HIT_SLOP}
                onPress={handleClearInput}
              >
                <CrossIcon width={calcWidth(12)} height={calcWidth(12)} fill={theme.colors.primaryBlue} />
              </S.CrossIconContainer>
            )}
          </S.PanelHeaderInputContainer>
          <S.DonePanelHeaderTextButton
            // @ts-ignore
            dataSet={filterListHeaderSearchDoneButtonDataSet}
            onPress={handleDone}
          >
            <S.PanelHeaderTextButtonText>{t('analytics.attributes.filterPanel.done')}</S.PanelHeaderTextButtonText>
          </S.DonePanelHeaderTextButton>
        </S.PanelHeaderContainer>
      </>
    );
  }

  return (
    <>
      <S.PanelHeaderContainer isRtl={isWebRtl}>
        <S.SearchIconContainer
          // @ts-ignore
          dataSet={filterListHeaderSearchButtonDataSet}
          isRtl={isWebRtl}
          isSelectedCount={!!selectedCount}
          onPress={onSearch}
        >
          <SearchIcon color={theme.colors.black} />
        </S.SearchIconContainer>
        {!!selectedCount && (
          <>
            <S.FilteredContainer
              // @ts-ignore
              dataSet={filterListHeaderSelectButtonDataSet}
              isRtl={isWebRtl}
              onPress={onSelectedCountPress}
            >
              <S.FilterIconContainer>
                <FilterIcon color={isSelected ? theme.colors.primaryBlue : theme.colors.black} />
              </S.FilterIconContainer>
              <S.FilteredText>
                {t('analytics.attributes.filterPanel.selected', { selectedNumber: selectedCount })}
              </S.FilteredText>
            </S.FilteredContainer>
            <S.ClearPanelHeaderTextButton
              // @ts-ignore
              dataSet={filterListHeaderClearButtonDataSet}
              isRtl={isWebRtl}
              onPress={onClear}
            >
              <S.PanelHeaderTextButtonText>{t('analytics.attributes.filterPanel.clear')}</S.PanelHeaderTextButtonText>
            </S.ClearPanelHeaderTextButton>
          </>
        )}
      </S.PanelHeaderContainer>
      <S.Divider />
    </>
  );
};

const S = {
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  PanelHeaderContainer: styled.View<{ isRtl: boolean }>`
    width: 100%;
    height: ${calcHeight(47)}px;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-between;
    padding: ${calcHeight(15)}px ${calcWidth(20)}px;
  `,
  PanelHeaderInputContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border-radius: ${calcWidth(8)}px;
    height: ${calcHeight(40)}px;
  `,
  PanelHeaderInput: styled.TextInput<{ isWebRtl: boolean; isRtl: boolean }>`
    align-self: center;
    margin: ${calcHeight(23)}px ${({ isWebRtl }) => calcWidth(isWebRtl ? 0 : 16)}px ${calcHeight(14)}px
      ${({ isWebRtl }) => calcWidth(isWebRtl ? 16 : 0)}px;
    height: ${calcHeight(38)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid ${({ theme }) => theme.colors.lightGray34};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(38 / 2)}px;

    flex: 1;
    padding: 0 ${calcWidth(16)}px;
    justify-content: center;
    text-align: ${({ isRtl }) => (isRtl ? 'right' : 'left')};
  `,
  ClearPanelHeaderTextButton: styled.TouchableOpacity<{ isRtl: boolean }>`
    height: ${calcHeight(40)}px;
    align-items: ${({ isRtl }) => (isRtl ? 'flex-start' : 'flex-end')};
    justify-content: center;
    margin-left: ${calcWidth(4)}px;
    flex: 1;
  `,
  DonePanelHeaderTextButton: styled.TouchableOpacity`
    height: ${calcHeight(40)}px;
    align-items: flex-end;
    justify-content: center;
    margin-top: ${calcHeight(8)}px;
  `,
  PanelHeaderTextButtonText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SearchIconContainer: styled.TouchableOpacity<{ isSelectedCount?: boolean; isRtl: boolean }>`
    flex: ${({ isSelectedCount }) => (isSelectedCount ? 1 : 5)};
    height: ${calcHeight(40)}px;
    align-items: ${({ isRtl }) => (isRtl ? 'flex-end' : 'flex-start')};
    justify-content: center;
    margin-left: ${calcWidth(-10)}px;
  `,
  FilteredContainer: styled.TouchableOpacity<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    flex: 1;
    height: ${calcHeight(40)}px;
    align-items: center;
    justify-content: center;
  `,
  FilteredText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  FilterIconContainer: styled.View`
    align-items: center;
    justify-content: center;
    width: ${calcWidth(20)}px;
    height: ${calcHeight(20)}px;
    margin-right: ${calcWidth(5)}px;
  `,
  CrossIconContainer: styled.TouchableOpacity<{ isRtl: boolean }>`
    position: absolute;
    align-items: center;
    justify-content: center;
    width: ${calcWidth(12)}px;
    height: ${calcHeight(12)}px;
    top: 18px;
    ${({ isRtl }) =>
      isRtl
        ? css`
            left: 32px;
          `
        : css`
            right: 32px;
          `};
  `,
};

export default memo(FilterPanelHeader);
