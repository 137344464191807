import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InteractionManager } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components/native';

import SearchPhotoIcon from '../../../../../assets/icons/search-photo.svg';
import { calcHeight, calcWidth, deviceWidth, deviceHeight, isWeb } from '../../../../../utils/dimensions';
import Photo from './common/Photo';
import SearchPanel from '../../../../../components/shared/SearchPanel';
import { logError } from '../../../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH } from '../../../../../hooks/useMedia/constants';
import searchPexels, { searchPexelsWithUrl } from '../../../../../services/pexels';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../../../utils/constants/toastConfig';

const DEFAULT_SEARCH_VALUE = 'cover';
const PAGE_SIZE = 30;
const NUMS_OF_COLUMNS = isWeb ? 3 : 2;

interface Props {
  onSelect: (imageUrl: string, callback) => void;
  panelWidth?: number;
  defaultValue?: string;
}

function SearchOnline(props: Props) {
  const { onSelect, panelWidth, defaultValue } = props;
  const inputEl = useRef(null);

  const [photos, setPhotos] = useState([]);
  const [nextPage, setNextPage] = useState('');
  const [canShowMore, setCanShowMore] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(defaultValue || DEFAULT_SEARCH_VALUE);
  const dispatch = useDispatch();

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      inputEl.current?.focus();
    });
  }, [inputEl]);

  const debouncedSearch = useDebouncedCallback(
    // function
    async (query: string) => {
      if (!query?.trim()) {
        setPhotos([]);
        setCanShowMore(false);
        return;
      }
      try {
        setLoading(true);
        const json = await searchPexels(query);

        setCanShowMore(photos.length + PAGE_SIZE < json.total_results);
        setPhotos(json.photos);
        setNextPage(json.next_page);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setCanShowMore(false);

        dispatch(
          logError({
            event: 'SearchOnline.debouncedSearch: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    250,
  );

  const handleShowMorePress = useCallback(async () => {
    if (!nextPage?.length) {
      setCanShowMore(false);
      return;
    }

    try {
      setLoading(true);

      const json = await searchPexelsWithUrl(nextPage);

      setCanShowMore(photos.length + PAGE_SIZE < json.total_results);
      setPhotos((prev) => [...prev, ...json.photos]);
      setNextPage(json.next_page);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCanShowMore(false);

      dispatch(
        logError({
          event: 'SearchOnline.handleShowMorePress: error',
          error,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  }, [dispatch, nextPage, photos.length]);

  useEffect(() => {
    debouncedSearch.callback(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatPhotoSizes = useCallback((height, width) => {
    const MAX_WIDTH = (deviceWidth - calcWidth(50)) / NUMS_OF_COLUMNS;

    return {
      width: MAX_WIDTH,
      height: (height / width) * MAX_WIDTH,
    };
  }, []);

  const photoSections = useMemo(() => {
    const response = Array.from(Array(NUMS_OF_COLUMNS), () => []);

    if (!photos) {
      return response;
    }

    response.forEach((item, index) => {
      for (let i = index; i <= photos.length; i += NUMS_OF_COLUMNS) {
        if (photos[i]) {
          let selectedPhoto = photos[i];
          selectedPhoto.sizes = formatPhotoSizes(DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH);
          response[index].push(selectedPhoto);
        }
      }
    });

    return response;
  }, [formatPhotoSizes, photos]);

  const handleChangeText = useCallback(
    (value) => {
      setSearchText(value);
      debouncedSearch.callback(value);
    },
    [debouncedSearch],
  );

  return (
    <>
      <S.SearchPanelContainer>
        <SearchPanel
          isSearching={isLoading}
          value={searchText}
          isSearchOpen={true}
          onChangeText={handleChangeText}
          expandedPanelWidth={panelWidth}
          hasAISuggestion={!!defaultValue}
        />
      </S.SearchPanelContainer>
      {photos.length === 0 && (
        <S.SearchPhotoIconWrapper>
          <SearchPhotoIcon fill='#e6e6e6' width={105} height={97} />
        </S.SearchPhotoIconWrapper>
      )}
      <S.Photos nestedScrollEnabled={true}>
        <Grid>
          {photoSections.map((col, index) => (
            <S.Col key={index}>
              {col.map((photo) => (
                <Photo key={photo.id} photo={photo} onSelect={onSelect} />
              ))}
            </S.Col>
          ))}
        </Grid>

        {canShowMore &&
          (isLoading ? (
            <S.ShowMoreLoader />
          ) : (
            <S.ShowMoreButton onPress={handleShowMorePress}>
              <S.ShowMoreButtonText>Show More</S.ShowMoreButtonText>
            </S.ShowMoreButton>
          ))}
      </S.Photos>
      <S.CreditsText>Photos provided by Pexels</S.CreditsText>
    </>
  );
}

const S = {
  SearchPanelContainer: styled.View`
    align-self: center;
    margin-bottom: ${calcWidth(24)}px;
  `,
  CreditsText: styled.Text`
    font-size: 10px;
    color: ${({ theme }) => theme.colors.gray1};
    position: absolute;
    bottom: ${calcHeight(120)}px;
    right: ${isWeb ? calcWidth(-55) : calcWidth(-45)}px;
    transform: rotate(-90deg);
  `,
  SearchPhotoIconWrapper: styled.View`
    position: absolute;
    top: 50%;
    left: 0px;
    align-self: center;
    align-items: center;
    width: 100%;
    height: 97px;
  `,
  Photos: styled.ScrollView`
    padding: 0 10px;
    width: ${deviceWidth}px;
    height: ${isWeb ? deviceHeight - calcHeight(293) : deviceHeight}px;
  `,
  Col: styled(Col)`
    align-items: center;
    padding: 0 ${calcWidth(5)}px;
  `,
  Cancel: styled.Text`
    border-top-width: 1px;
    border-top-color: rgba(77, 77, 77, 0.3);
    border-style: solid;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 18px;
    height: 56px;
    line-height: 56px;
  `,
  ShowMoreButton: styled.TouchableOpacity`
    align-self: center;
    margin: 20px;
  `,
  ShowMoreButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.gray11};
  `,
  ShowMoreLoader: styled.ActivityIndicator`
    align-self: center;
    margin: 20px;
  `,
};

export default SearchOnline;
