import React, { useMemo } from 'react';
import { AWS_URL } from '../../utils/constants/env';
import BiteLogo from '../../assets/images/bite-logo.png';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import { IBiteShare } from '../../types/biteShare';

interface IProps {
  biteShare: IBiteShare;
  isSmall?: boolean;
}

const PlaylistBite: React.FC<IProps> = ({ biteShare, isSmall = false }) => {
  const biteCoverSource = useMemo(
    () => ({ uri: biteShare?.linked_cover_url || undefined }),
    [biteShare?.linked_cover_url],
  );

  const workspaceLogoSource = useMemo(
    () =>
      biteShare?.branding?.logo_url
        ? {
            uri: `${AWS_URL}/${biteShare?.branding?.logo_url}`,
          }
        : BiteLogo,
    [biteShare?.branding?.logo_url],
  );

  const withWorkspaceLogo = !biteCoverSource.uri;

  return (
    <S.BiteContainer isSmall={isSmall}>
      <S.BiteImageContainer>
        {withWorkspaceLogo ? (
          <S.WorkspaceLogo source={workspaceLogoSource} />
        ) : (
          <S.BiteImage source={biteCoverSource} />
        )}
      </S.BiteImageContainer>
    </S.BiteContainer>
  );
};

const S = {
  BiteContainer: styled.View<{ isSmall: boolean }>`
    margin-top: ${({ isSmall }) => calcHeight(isSmall ? 2 : 5)}px;
    padding-left: ${({ isSmall }) => calcWidth(isSmall ? 0 : 4)}px;
    width: ${({ isSmall }) => calcWidth(isSmall ? (isWeb ? 104 : 76) : 122)}px;
    height: ${({ isSmall }) => calcHeight(isSmall ? (isWeb ? 132 : 119) : 172)}px;
  `,
  BiteImage: styled.Image`
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    height: 50%;
  `,
  BiteImageContainer: styled.View`
    flex-grow: 1;
    flex-shrink: 1;
    align-items: stretch;
    justify-content: center;
    border: 2px solid ${({ theme }) => theme.colors.black};
    border-radius: 20px;
    background-color: #efefef;
    overflow: hidden;
  `,
  WorkspaceLogo: styled.Image`
    align-self: center;
    resize-mode: contain;
    width: ${calcWidth(80)}px;
    height: ${calcHeight(40)}px;
  `,
};

export default PlaylistBite;
