import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../../themes/defaultTheme';
import CaretDownIcon from '../../../../assets/icons/videoEditor/leftSidebar/caret-down.svg';

type Props = {
  label: string;
  isSelected: boolean;
  renderContent: ({ onClose }: { onClose: () => void }) => React.ReactNode;
};
const Select = ({ label, isSelected, renderContent }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    setIsOpened((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  useEffect(() => {
    const handleDocumentClick = () => {
      setIsOpened(false);
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (!isOpened) {
      return;
    }

    const controlRect = ref.current.getBoundingClientRect();

    const contentNode = contentRef.current;
    contentNode.style.top = `${controlRect.bottom}px`;
  }, [isOpened]);

  return (
    <>
      <S.ControlContainer isSelected={isOpened || isSelected} ref={ref} onClick={handleClick}>
        {label}
        <S.CaretDownContainer>
          <CaretDownIcon />
        </S.CaretDownContainer>
      </S.ControlContainer>
      {isOpened && (
        <S.ContentContainer ref={contentRef}>
          {renderContent({
            onClose: handleClose,
          })}
        </S.ContentContainer>
      )}
    </>
  );
};
export default Select;

const S = {
  ControlContainer: styled.div<{
    isSelected: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 30px;
    color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.primaryBlue : defaultTheme.colors.gray)};
    font-size: 13px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    line-height: 16px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    border-radius: ${8}px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  CaretDownContainer: styled.div``,
  ContentContainer: styled.div`
    z-index: 100;
    position: absolute;
    left: 12px;
    right: 5px;
    bottom: 5px;
    border-radius: 10px;
    background: ${defaultTheme.colors.white};
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  `,
};
