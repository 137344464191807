import React, { FC, ReactChild } from 'react';
import styled from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { calcHeight, isWeb } from '../../../utils/dimensions';

interface IProps {
  children: ReactChild | ReactChild[];
}

const BottomController: FC<IProps> = ({ children }) => {
  const insets = useSafeAreaInsets();
  return <S.Content bottom={insets.bottom}>{children}</S.Content>;
};

const Content = styled.View<{ bottom: number }>`
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 ${({ bottom = 0 }) => (isWeb ? 0 : bottom)}px;
  width: 100%;
  height: ${calcHeight(99)}px;
  background-color: rgba(255, 255, 255, 0.9);
`;

const S = {
  Content,
};

export default BottomController;
