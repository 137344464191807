import { IS_ANDROID } from './env';

export enum EAuthFlowType {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
  RESET_PASSWORD = 'resetPassword',
}

export enum EAuthFormType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export const TERMS_CONDITIONS_PDF =
  'https://6230538.fs1.hubspotusercontent-na1.net/hubfs/6230538/Terms/LTD/EULA%20130723.pdf';
export const TERMS_CONDITIONS_NATIVE = IS_ANDROID
  ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${TERMS_CONDITIONS_PDF}`
  : TERMS_CONDITIONS_PDF;

const PRIVACY_PDF_LINK =
  'https://6230538.fs1.hubspotusercontent-na1.net/hubfs/6230538/Terms/LTD/Privacy%20Policy%20130723.pdf';
export const URL_PRIVACY = IS_ANDROID
  ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${PRIVACY_PDF_LINK}`
  : PRIVACY_PDF_LINK;
