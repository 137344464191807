import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { isWeb } from '../../utils/dimensions';
import {
  initAuthSuccess,
  initAuthFailure,
  registerSuccess,
  loginOrRegisterWithSocialTokenError,
  refreshUserSuccess,
  setSsoInProgress,
  initAuthRequest,
  setCodeSent,
  setWrongVerificationCode,
  setIsPhoneMissing,
  passwordRecoverySuccess,
  passwordRecoveryError,
  setSignupErrorCodes,
  refreshUserFailure,
  setRefreshUserErrors,
  setNewPasswordError,
  setNewPasswordLoading,
  stopLoginLoading,
} from './auth.actions';
import * as calls from '../api/bites-api/calls/auth.calls';
import { activeOrganizationSelector, userSelector } from './auth.selectors';
import AsyncStorage from '@react-native-community/async-storage';
//types
import Types, {
  IFinalizeProfileAction,
  IFinalizeProfileDataRequest,
  ILoginWithPhone,
  IPasswordRecoveryBody,
  ISignInCredentials,
  ISignupEmailCredentials,
  ISignupPhoneCredentials,
} from './auth.types';
import { IAction } from '../common/types';
import {
  IGetBitesAuthTokenPayload,
  IUser,
  IEditProfileData,
  IEditProfileDataDetails,
  INewPasswordCredentials,
} from './auth.types';
import { setBitesApiAuthorization } from '../api/bites-api';
import { loginSuccess, loginFailure, loginOrRegisterWithSocialTokenSuccess } from './auth.actions';
import { fetchOrg } from '../org/org.slice';
import { formatActiveOrgIdFromServer } from '../../utils/formatDataFromServer';
import { gtmSetUserId, setGaMarketingClientId, gtmSetUserProps } from '../../services/gtm/track';
import { initializeNotifications } from '../../services/notifications';
import getLoginMarketingParams from '../../utils/marketingParams/loginMarketingParams';
import getSignupMarketingParams from '../../utils/marketingParams/signupMarketingParams';
import Intercom from '../../services/intercom';
import { log, trackEvent } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import {
  launchOnboardingQuestionnaire,
  loadUserQuestionnaires,
  setUserQuestionnaires,
} from '../questionnaire/questionnaire.slice';
import handleHttpErrors from '../../utils/errors/handleHttpErrors';
import { setMissedAuthData } from '../authForm/authForm.slice';
import { APP_TYPE } from '../../utils/constants/config';
import { v4 as uuid } from 'uuid';
import { datadogSetUser } from '../../services/datadog/datadog';
import { FINALIZE_USER_INFO_ERRORS } from '../../screens/auth/common/UserInfoForm';

function* initAuthSaga({ payload: initialLocationSearch }: IAction<string>) {
  try {
    let token;

    if (isWeb && initialLocationSearch) {
      const query = new URLSearchParams(initialLocationSearch);
      token = query.get('token');
    }
    if (!token) {
      token = yield AsyncStorage.getItem('token');
    }

    if (token || isWeb) {
      yield registerTokens(token);
      const { data: user } = yield call(calls.getUser);

      // @ts-ignore
      yield put(fetchOrg(formatActiveOrgIdFromServer(user.organizations)));

      if (!user?.email || (!user?.first_name && !user?.last_name)) {
        yield put(
          log({
            event: 'initAuthSaga: missingAuthData',
            data: {
              user,
            },
          }),
        );

        yield put(
          setMissedAuthData({
            isAuthDataMissing: true,
            firstName: user?.first_name || '',
            lastName: user?.last_name || '',
            email: user?.email || '',
            phone: user?.phone || '',
          }),
        );
      }

      yield put(initAuthSuccess({ user, isSignup: false }));
    } else {
      yield put(initAuthFailure());
    }
    yield initializeNotifications();
  } catch (error) {
    yield put(initAuthFailure());
  }
}

function* loginSaga({ payload }: IAction<ISignInCredentials>) {
  try {
    const loginPayload = {
      ...payload,
      app: APP_TYPE,
      marketing_params: getLoginMarketingParams(),
    };

    yield put(
      log({
        event: 'loginSaga: start',
        processId: payload.processId,
        data: { loginPayload },
      }),
    );
    const {
      data: { token, user, side_effects },
    } = yield call(calls.login, loginPayload);

    yield put(
      log({
        event: 'loginSaga: login success',
        processId: payload.processId,
        data: { token, user, side_effects },
      }),
    );

    yield registerTokens(token);
    yield initializeNotifications();
    // @ts-ignore
    yield put(fetchOrg(formatActiveOrgIdFromServer(user.organizations)));
    gtmSetUserId(user.id, { isSignup: !!payload.otp, user });
    yield put(
      trackEvent({
        event: 'login',
        props: { login_type: 'email' },
      }),
    );
    Intercom.trackEvent('login', {
      first_login: side_effects?.env_first_login ? 'YES' : 'NO',
    });

    if (!user.email || (!user?.first_name && !user?.last_name)) {
      yield put(
        log({
          event: 'loginSaga: missingAuthData',
          processId: payload.processId,
          data: {
            user,
          },
        }),
      );

      yield put(
        setMissedAuthData({
          isAuthDataMissing: true,
          firstName: user.first_name || '',
          lastName: user.last_name || '',
          email: user.email || '',
          phone: user.phone || '',
        }),
      );
    }

    yield put(loginSuccess({ user, isSignup: !!payload.otp }));
  } catch (error) {
    yield put(
      log({
        event: 'loginSaga: error',
        processId: payload.processId,
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );

    yield put(stopLoginLoading());

    const errorData = error.response?.data;
    if (errorData?.verified === false) {
      if (payload.otp) {
        yield put(setWrongVerificationCode(true));
      }

      if (typeof payload.onOtp === 'function') {
        payload.onOtp(payload.processId);
      }
    } else {
      yield put(loginFailure());
    }

    yield put(
      trackEvent({
        event: 'login_error',
        props: { login_type: 'email' },
      }),
    );
    yield call(handleHttpErrors, error);
  }
}

function* loginWithPhoneSaga(action: IAction<ILoginWithPhone>) {
  const { credentials, options, processId, onOtp } = action.payload;

  const body = {
    ...credentials,
    app: APP_TYPE,
    marketing_params: getLoginMarketingParams(),
  };

  yield put(
    log({
      event: 'loginWithPhoneSaga: start',
      processId,
      data: body,
    }),
  );

  try {
    const {
      data: { token, user, verified, side_effects },
    } = yield calls.loginWithPhone(body);

    if (!user?.email || (!user?.first_name && !user?.last_name)) {
      yield put(
        log({
          event: 'loginWithPhoneSaga: missingAuthData',
          processId,
          data: {
            user,
          },
        }),
      );

      yield put(
        setMissedAuthData({
          isAuthDataMissing: true,
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          email: user?.email || '',
          phone: user?.phone || '',
        }),
      );
    }

    yield put(
      log({
        event: 'loginWithPhoneSaga: login success',
        processId,
        data: { token, user, verified, side_effects },
      }),
    );

    if (verified) {
      yield registerTokens(token);
      yield initializeNotifications();
      yield put(fetchOrg(formatActiveOrgIdFromServer(user.organizations)));
      yield put(loginSuccess({ user, isSignup: options.isSignup }));
      gtmSetUserId(user.id, {
        isSignup: options.isSignup,
        user,
      });
      yield put(
        trackEvent({
          event: 'login',
          props: { login_type: 'phone' },
        }),
      );
      Intercom.trackEvent('login', {
        first_login: side_effects?.env_first_login ? 'YES' : 'NO',
      });

      yield put(setCodeSent());
    }
  } catch (err) {
    yield put(
      log({
        event: 'loginWithPhoneSaga: error',
        processId,
        data: {
          error: err,
          // axios case
          errorResponse: err?.response,
        },
      }),
    );

    if (!body.otp) {
      if (err.response.data?.verified === false && typeof onOtp === 'function') {
        onOtp(processId);
        yield put(setCodeSent());
      } else {
        yield put(
          trackEvent({
            event: 'login_error',
            props: { login_type: 'phone', error_message: 'no user associated with this number' },
          }),
        );
        yield put(setIsPhoneMissing(true));
      }
    } else {
      yield put(
        trackEvent({
          event: 'login_error',
          props: { login_type: 'phone', error_message: 'wrong verification code' },
        }),
      );
      yield put(setWrongVerificationCode(true));
    }
    yield call(handleHttpErrors, err);
  }
}

function* signupWithPhone(action: IAction<ISignupPhoneCredentials>) {
  const { phone, creator, organizations, onOtp, processId } = action.payload;

  const body = {
    phone,
    creator,
    organizations,
    app: APP_TYPE,
    marketing_params: getSignupMarketingParams(),
  };

  yield put(
    log({
      event: 'signupWithPhoneSaga: start',
      processId,
      data: body,
    }),
  );

  // code below in the "try" part is only for
  // a possible internal backdoor in future
  try {
    const { data } = yield calls.signupWithPhone(body);
    const { token, user: auth } = data;

    yield put(
      log({
        event: 'signupWithPhoneSaga: signup success',
        processId,
        data,
      }),
    );

    yield registerTokens(token);
    yield initializeNotifications();

    if (!auth?.email || (!auth?.first_name && !auth?.last_name)) {
      yield put(
        log({
          event: 'signupWithPhoneSaga: signup success, but auth data is missing',
          processId,
          data,
        }),
      );

      yield put(
        setMissedAuthData({
          isAuthDataMissing: true,
          firstName: auth.first_name || '',
          lastName: auth.last_name || '',
          email: auth.email || '',
          phone: auth.phone || '',
        }),
      );
    }

    yield put(registerSuccess({ user: auth, isSignup: true }));

    yield put(setCodeSent());
  } catch (err) {
    yield put(
      log({
        event: 'signupWithPhoneSaga: error',
        processId,
        data: {
          error: err,
          // axios case
          errorResponse: err?.response,
        },
      }),
    );

    if (err.response.status === 401) {
      yield put(setCodeSent());
      yield put(
        trackEvent({
          event: 'signup',
          props: { signup_type: 'phone' },
        }),
      );

      if (typeof onOtp === 'function') {
        onOtp(processId);
      }
    } else if (err.response.status === 400) {
      try {
        yield put(
          trackEvent({
            event: 'signup_error',
            props: { signup_type: 'phone' },
          }),
        );
        if (err.response.data?.error_codes?.length > 0) {
          yield put(
            setSignupErrorCodes({
              errorCodes: err.response.data.error_codes,
              errorMessage: err.response.data.phone[0],
            }),
          );
        }
      } catch (error) {
        yield put(
          trackEvent({
            event: 'signup_error',
            props: { signup_type: 'phone' },
          }),
        );
      }
    }
    yield call(handleHttpErrors, err);
  }
}

function* signupWithEmail({ payload }: IAction<ISignupEmailCredentials>) {
  try {
    const body = {
      email: payload.email,
      password1: payload.password,
      password2: payload.password,
      creator: payload.creator,
      organizations: payload.organizations,
      first_name: payload.first_name,
      last_name: payload.last_name,
      app: APP_TYPE,
      marketing_params: getSignupMarketingParams(),
    };

    yield put(
      log({
        event: 'signupWithEmail: start',
        processId: payload.processId,
        data: body,
      }),
    );
    const res = yield calls.signupWithEmail(body);
    const { token, user: auth } = res.data;

    yield put(
      log({
        event: 'signupWithEmail: success',
        processId: payload.processId,
        data: res,
      }),
    );

    yield registerTokens(token);
    yield initializeNotifications();

    if (!auth?.email || (!auth?.first_name && !auth?.last_name)) {
      yield put(
        log({
          event: 'signupWithEmail: missingAuthData',
          processId: payload.processId,
          data: {
            auth,
          },
        }),
      );

      yield put(
        setMissedAuthData({
          isAuthDataMissing: true,
          firstName: auth?.first_name || '',
          lastName: auth?.last_name || '',
          email: auth?.email || '',
          phone: auth?.phone || '',
        }),
      );
    }

    yield put(registerSuccess({ user: auth, isSignup: true }));

    gtmSetUserId(auth.id, { isSignup: true, user: auth });
    yield put(
      trackEvent({
        event: 'signup',
        props: { signup_type: 'email' },
      }),
    );

    // keep login event
    Intercom.trackEvent('login', {
      first_login: 'YES',
    });
  } catch (err) {
    yield put(
      log({
        event: 'signupWithEmail: error',
        processId: payload.processId,
        data: {
          error: err,
          // axios case
          errorResponse: err?.response,
        },
      }),
    );

    const errData = err.response?.data;

    if (err.response?.status === 401) {
      yield put(setWrongVerificationCode(false));
      if (typeof payload.onOtp === 'function') {
        payload.onOtp(payload.processId);
      }
    }

    yield put(setSignupErrorCodes({ errorCodes: errData?.error_codes || [], errorMessage: errData?.email?.[0] }));

    yield put(
      trackEvent({
        event: 'signup_error',
        props: { signup_type: 'email' },
      }),
    );
    // Intercom.trackEvent('signup');

    yield call(handleHttpErrors, err);
  }
}

function* logoutSaga() {
  try {
    // Don't wait for response
    calls.logout().catch((err) => {
      console.error('Logout call failed', err);
    });

    yield AsyncStorage.removeItem('token');
    setBitesApiAuthorization(null);

    yield put(initAuthFailure());
    yield initializeNotifications();
  } catch (err) {
    console.error('error clearing the token from AsyncStorage', err);
  }
}

function* deleteMyUserSaga() {
  try {
    yield calls.deleteMyUser();
    yield AsyncStorage.removeItem('token');
    setBitesApiAuthorization(null);
    yield put(initAuthRequest());
  } catch (err) {
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
    console.error(err);
  }
}

function* loginOrRegisterWithSocialTokenSaga({ payload }: IAction<IGetBitesAuthTokenPayload>) {
  try {
    const { needToAddOrgByTenant, tenant_id, email, ...restPayload } = payload;
    const loginOrRegisterPayload = {
      ...restPayload,
      marketing_params: getSignupMarketingParams(),
    };
    const response =
      restPayload.backend === 'apple-oauth2-convert-web'
        ? yield calls.getAppleWebAccessToken(loginOrRegisterPayload)
        : yield calls.getBitesAccessTokenWithSocialToken(loginOrRegisterPayload);
    yield registerTokens(response.data.access_token);
    yield initializeNotifications();
    let user = null;
    if (needToAddOrgByTenant && tenant_id) {
      const tenantResponse = yield calls.addTenantToMicrosoftUser({
        tenant_id,
        email,
      });
      user = tenantResponse.data;
    }
    if (!user) {
      const { data } = yield call(calls.getUser);
      user = data;
    }

    if ((!user?.email || (!user?.first_name && !user?.last_name)) && !restPayload.backend.includes('apple-oauth2')) {
      yield put(
        log({
          event: 'loginOrRegisterWithSocialTokenSaga: missingAuthData',
          data: {
            user,
          },
        }),
      );

      yield put(
        setMissedAuthData({
          isAuthDataMissing: true,
          firstName: user?.first_name || '',
          lastName: user?.last_name || '',
          email: user?.email || '',
          phone: user?.phone || '',
        }),
      );
    }

    yield put(setSsoInProgress(false));

    // TODO: fix types
    // @ts-ignore
    yield put(fetchOrg(formatActiveOrgIdFromServer(user.organizations)));

    yield put(loginOrRegisterWithSocialTokenSuccess(user));
    yield put(loginSuccess({ user, isSignup: response.data.side_effects?.registration }));
    yield gtmEventSocialLogin({
      backend: restPayload.backend,
      user,
      isSignup: response.data.side_effects?.registration,
      isFirstLogin: response.data.side_effects?.env_first_login,
    });
  } catch (error) {
    yield put(loginOrRegisterWithSocialTokenError());
    gtmEventSocialLoginError(payload?.backend, error);
    yield call(handleHttpErrors, error);
  }
}

function* registerTokens(token: string) {
  setBitesApiAuthorization(token);

  try {
    if (token) {
      yield AsyncStorage.setItem('token', token);
      return;
    }
    yield AsyncStorage.removeItem('token');
  } catch (err) {
    console.log('error updating token in AsyncStorage');
  }
}

function* updateUserProfileSaga({ payload }: IAction<IEditProfileData>) {
  const processId = uuid();
  try {
    const userDetailsPayload: IEditProfileDataDetails = {
      email: payload.email || undefined,
      phone: payload.phone?.length > 5 ? payload.phone : undefined,
      first_name: payload.firstName || undefined,
      last_name: payload.lastName || undefined,
      profile_image: payload.profile_image || undefined,
      organizationId: payload.organizationId,
    };

    yield put(
      log({
        event: 'updateUserProfileSaga: start',
        processId,
        data: { userDetailsPayload },
      }),
    );

    yield calls.updateProfile(userDetailsPayload);

    yield put(
      log({
        event: 'updateUserProfileSaga: updateProfile success',
        processId,
      }),
    );

    const { data } = yield calls.getUser();

    yield put(
      log({
        event: 'updateUserProfileSaga: getUser success',
        processId,
        data,
      }),
    );
    yield put(refreshUserSuccess({ user: data, isSignup: false }));
    if (typeof payload.onSuccess === 'function') {
      payload.onSuccess();
    }
  } catch (err) {
    yield put(
      log({
        event: 'updateUserProfileSaga: error',
        processId,
        data: {
          error: err,
          // axios case
          errorResponse: err?.response,
        },
      }),
    );

    yield put(refreshUserFailure());

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

// updates user profile after registration
// Backend checks if the user has only one workspace
// whene he is the creator
// if so backend also updates the workspace name
// and performs necessary Hubspot syncs
function* finalizeUserProfileSaga({ payload }: IAction<IFinalizeProfileAction>) {
  const processId = uuid();
  const user = yield select(userSelector);

  const requestPayload: IFinalizeProfileDataRequest = {
    email: payload.email || undefined,
    phone: payload.phone?.length > 5 ? payload.phone : undefined,
    first_name: payload.firstName || undefined,
    last_name: payload.lastName || undefined,
  };

  try {
    yield put(
      log({
        event: 'finalizeUserProfileSaga: start',
        processId,
        data: {
          userId: user.id,
          requestPayload,
        },
      }),
    );

    yield calls.finalizeUserProfile(user.id, requestPayload);

    yield put(
      log({
        event: 'finalizeUserProfileSaga: updateProfile success',
        processId,
        data: {
          userId: user.id,
        },
      }),
    );

    const { data: updatedUser } = yield calls.getUser();

    yield put(
      log({
        event: 'finalizeUserProfileSaga: getUser success',
        processId,
        data: {
          updatedUser,
          userId: user.id,
        },
      }),
    );

    yield put(refreshUserSuccess({ user: updatedUser, isSignup: false }));

    if (typeof payload.onSuccess === 'function') {
      payload.onSuccess();
    }
  } catch (error) {
    yield put(
      log({
        event: 'finalizeUserProfileSaga: error',
        processId,
        data: {
          error,
          // axios case
          errorResponse: error?.response,
          userId: user.id,
          requestPayload,
        },
      }),
    );

    yield put(refreshUserFailure());

    if (error.response?.data?.error_codes?.some((errorCode) => FINALIZE_USER_INFO_ERRORS[errorCode])) {
      yield put(setRefreshUserErrors((error.response?.data?.error_codes as string[]) || null));
      return;
    }

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* refreshUserSaga() {
  try {
    const { data } = yield calls.getUser();
    yield put(refreshUserSuccess({ user: data, isSignup: false }));
  } catch (err) {
    console.error(err);
  }
}

function* initAuthFailureSaga() {
  yield AsyncStorage.removeItem('token');
}

function* passwordRecoverySaga({ payload }: IAction<IPasswordRecoveryBody>) {
  const { email, callback } = payload;
  try {
    yield calls.passwordRecovery({ email });
    yield put(passwordRecoverySuccess());
    yield put(
      trackEvent({
        event: 'forgot_password',
        props: { status: 'Succeeded' },
      }),
    );
  } catch (err) {
    yield put(
      trackEvent({
        event: 'forgot_password',
        props: { status: 'Failed' },
      }),
    );
    yield put(passwordRecoveryError());
    yield call(handleHttpErrors, err);
  } finally {
    if (typeof callback === 'function') {
      callback();
    }
  }
}

function* onUserSaga({ payload: { user, isSignup } }: IAction<{ user: IUser; isSignup: boolean }>) {
  const org = yield select(activeOrganizationSelector);
  const language = yield AsyncStorage.getItem('language');
  setGaMarketingClientId(user.id, user.marketing_params?.ga_marketing_client_id);
  gtmSetUserProps(user.id, {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    last_org_id: `${org.id}`,
    last_org_name: org.name,
    user_orgs: ',' + user.organizations.map(({ id }) => id).join(',') + ',',
    language,
  });
  yield call(datadogSetUser, user);

  yield put(setUserQuestionnaires(null));
  yield put(loadUserQuestionnaires());

  if (user) {
    Intercom.updateUser({
      email: user.email,
      userId: `${user.id}`,
      name: user.first_name + ' ' + user.last_name,
      companies: [
        {
          id: `${formatActiveOrgIdFromServer(user.organizations)}`,
        },
      ],
      customAttributes: {
        app_visited_homepage: true,
      },
    });
  }

  if (isSignup) {
    yield put(launchOnboardingQuestionnaire());
  }
}

function* gtmEventSocialLogin({ backend, user, isSignup, isFirstLogin }) {
  if (backend === 'apple-oauth2' || backend === 'apple-oauth2-convert-web') {
    gtmSetUserId(user.id, { isSignup, user });
    yield put(
      trackEvent({
        event: isSignup ? 'signup' : 'login',
        props: { login_type: 'apple' },
      }),
    );
  } else if (backend === 'google-oauth2') {
    gtmSetUserId(user.id, { isSignup, user });
    yield put(
      trackEvent({
        event: isSignup ? 'signup' : 'login',
        props: { login_type: 'google' },
      }),
    );
  } else if (backend === 'azuread-oauth2') {
    gtmSetUserId(user.id, { isSignup, user });
    yield put(
      trackEvent({
        event: isSignup ? 'signup' : 'login',
        props: { login_type: 'microsoft' },
      }),
    );
  }
  if (!isSignup) {
    Intercom.trackEvent('login', {
      first_login: isFirstLogin ? 'YES' : 'NO',
    });
  }
}

function gtmEventSocialLoginError(backend: string, error: string) {
  if (backend === 'apple-oauth2' || backend === 'apple-oauth2-convert-web') {
    put(
      trackEvent({
        event: 'login_error',
        props: { login_type: 'apple', error_message: error },
      }),
    );
  } else if (backend === 'google-oauth2') {
    put(
      trackEvent({
        event: 'login_error',
        props: { login_type: 'google', error_message: error },
      }),
    );
  } else if (backend === 'azuread-oauth2') {
    put(
      trackEvent({
        event: 'login_error',
        props: { login_type: 'microsoft', error_message: error },
      }),
    );
  }
}

function* setNewPassword({ payload: { token, password, callback } }: IAction<INewPasswordCredentials>) {
  const processId = uuid();
  try {
    yield put(
      log({
        event: 'setNewPassword: start',
        processId,
        data: {
          token,
          password,
        },
      }),
    );
    yield calls.setNewRecoverPassword({ token, password });

    yield put(
      log({
        event: 'setNewPassword: setNewRecoverPassword success',
        processId,
        data: {
          token,
          password,
        },
      }),
    );

    yield put(setNewPasswordLoading(false));
    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    yield put(setNewPasswordLoading(false));
    yield put(
      log({
        event: 'setNewPassword: error',
        processId,
        data: {
          error: err,
          // axios case
          errorResponse: err?.response,
        },
      }),
    );
    const error = err.response?.data;
    if (error) {
      yield put(setNewPasswordError(error.errors?.[0].code));
    } else {
      yield call(handleHttpErrors, err);
    }
  }
}

export default function* authSagaWatcher() {
  yield all([
    takeLatest(Types.LOGIN, loginSaga),
    takeLatest(Types.LOGIN_WITH_PHONE_REQUEST, loginWithPhoneSaga),
    takeLatest(Types.SIGNUP_WITH_PHONE_REQUEST, signupWithPhone),
    takeLatest(Types.SIGNUP_WITH_EMAIL_REQUEST, signupWithEmail),
    takeLatest(Types.REFRESH_USER, refreshUserSaga),
    takeLatest(Types.INIT_AUTH_REQUEST, initAuthSaga),
    takeLatest(Types.LOGOUT, logoutSaga),
    takeLatest(Types.PASSWORD_RECOVERY_REQUEST, passwordRecoverySaga),
    takeLatest(Types.UPDATE_USER_PROFILE, updateUserProfileSaga),
    takeLatest(Types.FINALIZE_USER_PROFILE, finalizeUserProfileSaga),
    takeLatest(Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN, loginOrRegisterWithSocialTokenSaga),
    takeLatest(Types.INIT_AUTH_FAILURE, initAuthFailureSaga),
    takeLatest(Types.REFRESH_USER_SUCCESS, onUserSaga),
    takeLatest(Types.LOGIN_SUCCESS, onUserSaga),
    takeLatest(Types.REGISTER_SUCCESS, onUserSaga),
    takeLatest(Types.INIT_AUTH_SUCCESS, onUserSaga),
    takeLatest(Types.DELETE_MY_USER, deleteMyUserSaga),
    takeLatest(Types.SET_NEW_PASSWORD_REQUEST, setNewPassword),
  ]);
}
