import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { configSelector } from '../../../store/appActivity/appActivity.selectors';
import { isWeb } from '../../../utils/dimensions';
import { IMediaProgress, IMediaViewerMethods } from '../../../types/media';
import { IModalComponentProps } from './AppModals';
import VideoModal from '../../shared/VideoModal';
import { useTranslation } from 'react-i18next';

interface IProps extends IModalComponentProps {}

interface IVideoState {
  videoProgress: IMediaProgress;
  videoPlayerMethods: IMediaViewerMethods;
}

const IntroVideo: React.FC<IProps> = ({ isVisible, onClose, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const config = useSelector(configSelector);

  const [isFinished, setIsFinished] = useState(false);

  const state = useRef<IVideoState>({
    videoProgress: null,
    videoPlayerMethods: null,
  });

  const handleOnMethods = useCallback(
    (methods) => {
      state.current.videoPlayerMethods = methods;
      methods.play();
    },
    [state],
  );

  const onNext = useCallback(
    async (isSkipped?: boolean) => {
      const currentTime = state.current.videoProgress?.currentTime || 0;
      const duration = state.current.videoProgress?.duration || 0;

      const trackData = {
        is_skipped: isSkipped,
        progress_seconds: Math.round(currentTime),
        progress_percentage: duration && Math.round((currentTime * 100) / duration),
      };

      dispatch(
        trackEvent({
          event: 'app_intro_video_exit',
          props: trackData,
        }),
      );

      state.current.videoPlayerMethods?.pause();
      setIsFinished(true);
    },
    [dispatch],
  );

  const onProgress = useCallback((e) => {
    state.current.videoProgress = e;
  }, []);

  useEffect(() => {
    if (isFinished && typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, isFinished, onClose]);

  const introVideoConfig = useMemo(() => {
    if (isWeb) {
      return config.introVideoDesktop || config.introVideo;
    }
    return config.introVideo;
  }, [config]);

  const handleSkip = useCallback(() => onNext(true), [onNext]);

  return (
    <VideoModal
      isVisible={isVisible}
      onBtnRightClick={handleSkip}
      btnRightLabel={t('common.Skip')}
      btnRightStyle='transparent'
      videoUrl={introVideoConfig.mediaURI}
      isAutoPlay={!isWeb}
      isMutedInitial={false}
      onProgress={onProgress}
      onEnd={onNext}
      onMethods={handleOnMethods}
      {...props}
    />
  );
};

export default IntroVideo;
