import React, { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { ImageSourcePropType } from 'react-native';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { useTranslation } from 'react-i18next';
import { IPanelChildInjectedProps } from '../../../store/bottomPanel/bottomPanel';

interface IProps extends IPanelChildInjectedProps {
  imageSource: ImageSourcePropType;
  onClose: () => void;
  onLogout: () => void;
}

const LogoutMenu: React.FC<IProps> = ({ imageSource, onClosePanel, onClose, onLogout }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const buttonsWidth = isWeb ? calcWidth(316) : calcWidth(216);

  const handleClose = useCallback(async () => {
    onClosePanel(onClose);
  }, [onClose, onClosePanel]);

  const handleLogout = useCallback(() => {
    onClosePanel(onLogout);
  }, [onLogout, onClosePanel]);

  return (
    <S.Container>
      <S.Title>{t('drawerNavigator.logoutModal.message')}</S.Title>
      <S.Avatar source={imageSource} />
      <S.Button
        onPress={handleLogout}
        content={t('drawerNavigator.logout')}
        color={theme.colors.white}
        backgroundColor={theme.colors.buttonPink}
        width={buttonsWidth}
      />
      <S.Button onPress={handleClose} content={t('common.cancel')} color={theme.colors.white} width={buttonsWidth} />
    </S.Container>
  );
};

const S = {
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    line-height: ${({ theme }) => theme.fontSizes.s18 * 1.39};
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  Overlay: styled.TouchableOpacity`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  `,
  Avatar: styled.Image`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    border-radius: ${calcWidth(40) / 2}px;
    margin-top: ${calcHeight(20)}px;
    margin-bottom: ${calcHeight(8)}px;
  `,
  Button: styled(GenericButton)`
    margin-top: ${calcHeight(14)}px;
    height: ${calcHeight(50)}px;
  `,
  Container: styled.View`
    height: ${calcHeight(299)}px;
    width: 100%;
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: ${calcHeight(31)}px;
    align-items: center;
  `,
};

export default memo(LogoutMenu);
