import React, { useEffect } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import CreationAnimation from '../CreationAnimation';
import styled from 'styled-components/native';
import { calcHeight } from '../../../utils/dimensions';

interface IProps {
  loadingText?: string;
}

const CreationAnimationScreen: React.FC<IProps> = ({ loadingText }) => {
  const fadeAnim = useSharedValue(0);

  useEffect(() => {
    fadeAnim.value = 1;
  }, [fadeAnim]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(fadeAnim.value, {
        duration: 1000,
      }),
    }),
    [fadeAnim],
  );

  return (
    <S.Container>
      <CreationAnimation />
      {!!loadingText && (
        <Animated.View style={animatedStyle}>
          <S.Text>{loadingText}</S.Text>
        </Animated.View>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    justify-content: center;
  `,
  Text: styled.Text`
    text-align: center;
    align-self: center;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.text};
    line-height: ${({ theme }) => theme.fontSizes.s15 + 10};
    margin-top: ${calcHeight(40)}px;
  `,
};

export default CreationAnimationScreen;
