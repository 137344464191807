import React, { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import ShadowedButton from '../../../../screens/Shared/ShadowedButton';
import YoutubeMediaIcon from '../../../../assets/icons/biteIntro/youtube-media-icon.svg';
import GoogleSlidesIcon from '../../../../assets/icons/biteIntro/google-slides.svg';
import PowerpointIcon from '../../../../assets/icons/biteIntro/powerpoint.svg';
import InstagramIcon from '../../../../assets/icons/biteIntro/instagram.svg';
import FacebookIcon from '../../../../assets/icons/biteIntro/facebook.svg';
import TiktokIcon from '../../../../assets/icons/biteIntro/tiktok.svg';
import { IPanelChildInjectedProps } from '../../../../store/bottomPanel/bottomPanel';
import { importFromGoogleSlidesDataSet, importFromYoutubeDataSet } from './ImportMediaMenu.constants';

interface IProps extends IPanelChildInjectedProps {
  onYoutubePress?: () => void;
  onGoogleSlidesPress?: () => void;
  onPowerpointPress?: () => void;
  onInstagramPress?: () => void;
  onFacebookPress?: () => void;
  onTiktokPress?: () => void;
}

const ImportMediaMenu: React.FC<IProps> = ({
  onYoutubePress,
  onGoogleSlidesPress,
  onPowerpointPress,
  onInstagramPress,
  onFacebookPress,
  onTiktokPress,
  onClosePanel,
}) => {
  const theme = useTheme();
  const renderPowerpointIcon = useCallback(() => <PowerpointIcon width={39} height={39} />, []);
  const renderGoogleSlidesIcon = useCallback(() => <GoogleSlidesIcon width={39} height={39} />, []);
  const renderYoutubeMediaIcon = useCallback(() => <YoutubeMediaIcon width={39} height={39} />, []);
  const renderInstagramIcon = useCallback(
    () => <InstagramIcon width={39} height={39} color={theme.colors.black} />,
    [theme.colors.black],
  );
  const renderFacebookIcon = useCallback(() => <FacebookIcon width={39} height={39} />, []);
  const renderTiktokIcon = useCallback(() => <TiktokIcon width={39} height={39} />, []);

  const handleAction = useCallback(
    (action: () => void) => {
      if (typeof action !== 'function') {
        return;
      }
      return () => onClosePanel(action);
    },
    [onClosePanel],
  );
  return (
    <S.Container>
      <S.Title>Import</S.Title>
      <S.ButtonsContainer>
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderPowerpointIcon}
          onPress={handleAction(onPowerpointPress)}
          title={'Powerpoint'}
          small
        />
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderGoogleSlidesIcon}
          onPress={handleAction(onGoogleSlidesPress)}
          title={'Google Slides'}
          small
          dataSet={importFromGoogleSlidesDataSet}
        />
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderYoutubeMediaIcon}
          onPress={handleAction(onYoutubePress)}
          title={'Youtube'}
          small
          dataSet={importFromYoutubeDataSet}
        />
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderInstagramIcon}
          onPress={handleAction(onInstagramPress)}
          title={'Instagram'}
          comingOn={'soon'}
          small
        />
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderFacebookIcon}
          onPress={handleAction(onFacebookPress)}
          title={'Facebook'}
          comingOn={'soon'}
          small
        />
        <ShadowedButton
          style={S.ShadowedButton}
          renderIcon={renderTiktokIcon}
          onPress={handleAction(onTiktokPress)}
          title={'Tiktok'}
          comingOn={'soon'}
          small
        />
      </S.ButtonsContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    align-items: center;
    height: ${calcHeight(320)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s17};
    color: ${({ theme }) => theme.colors.lightGray17};
    font-weight: bold;
    margin-bottom: ${calcHeight(22)}px;
    margin-top: ${calcHeight(5)}px;
  `,
  ButtonsContainer: styled.View`
    flex-direction: row;
    flex-wrap: wrap;
    width: ${calcWidth(390)}px;
    padding: 0 ${calcWidth(20)}px;
  `,
  Button: styled.TouchableOpacity`
    width: ${calcWidth(100)}px;
    height: ${calcWidth(100)}px;
    margin: 0 10px 5px 0;
    align-items: center;
    justify-content: center;
  `,
  ButtonIcon: styled.View``,
  ButtonText: styled.Text``,
  ShadowedButton: {
    width: calcWidth(96),
    height: calcWidth(96),
    margin: calcWidth(8),
  },
};

export default memo(ImportMediaMenu);
