import React, { memo, useCallback, useEffect, useState } from 'react';
import AttributesList from '../common/AttributesList/AttributesList';
import { useSelector } from 'react-redux';
import { getOverviewAttributes } from '../../../store/attributes/attributes.slice';
import HeaderTabs from '../common/HeaderTabs';
import useOverviewAttributesMap from '../hooks/useOverviewAttributesMap';
import useRedirectFromAttributes from '../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../navigation/types';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { currentListSelector, isNeedToScrollUpOverviewSelector } from '../../../store/analytics/analytics.selector';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  selectedFilterAttributeValueIdsSelector,
  sharedWithFilterSelector,
} from '../../../store/attributes/attributes.selector';
import { OVERVIEW_ATTRIBUTES_SCREEN, OVERVIEW_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../hooks/useHeaderTabsDivider';
import { ESharedWithFilter } from '../../../store/attributes/attributes.types';
import { attributesListDataSet } from '../analytics.constants';

const OverviewAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const { attributesMap } = useOverviewAttributesMap();
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: OVERVIEW_LIST_SCREEN,
    navigation,
  });

  const isNeedToScrollUp = useSelector(isNeedToScrollUpOverviewSelector);
  const currentList = useSelector(currentListSelector);
  const sharedWithFilter = useSelector(sharedWithFilterSelector);

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return <GuestsAttribute currentTab={EAnalyticsScreenTabs.OVERVIEW} isRefreshing={isRefreshing} />;
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(OVERVIEW_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  return (
    <>
      <HeaderTabs
        currentScreen={OVERVIEW_ATTRIBUTES_SCREEN}
        attributesScreen={OVERVIEW_ATTRIBUTES_SCREEN}
        listScreen={OVERVIEW_LIST_SCREEN}
        isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
      />
      <AttributesList
        dataSet={attributesListDataSet}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenTabs.OVERVIEW}
        attributes={attributesMap}
        onLoad={getOverviewAttributes}
        renderListHeaderComponent={renderListHeaderComponent}
        hasMultipleValues
      />
    </>
  );
};

export default memo(OverviewAttributes);
