export const attributesFilterDataSet = { cy: 'attributes-filter' };
export const attributesFilterItemDataSet = { cy: 'attributes-filter-item' };
export const filterDrawerListDataSet = { cy: 'filter-drawer-list' };
export const filterDrawerSearchListDataSet = { cy: 'filter-drawer-search-ist' };

export const filterListItemDataSet = { cy: 'filter-list-item' };

export const filterListHeaderSearchButtonDataSet = { cy: 'filter-list-header-search-button' };
export const filterListHeaderSelectButtonDataSet = { cy: 'filter-list-header-select-button' };

export const filterListHeaderClearButtonDataSet = { cy: 'filter-list-header-clear-button' };

export const filterListHeaderSearchDoneButtonDataSet = { cy: 'filter-list-header-search-done-button' };
export const filterListHeaderSearchInputDataSet = { cy: 'filter-list-header-search-input' };

export const filterListHeaderSearchClearInputDataSet = { cy: 'filter-list-header-search-clear-input' };

export const filterListItemCheckedMarkDataSet = { cy: 'filter-list-item-checked-mark' };

export const analyticsListNoDataDataSet = { cy: 'analytics-list-no-data' };

export const analyticsListLoaderDataSet = { cy: 'analytics-list-loader' };

export const analyticsListErrorDataSet = { cy: 'analytics-list-error' };

export const filterDoneButtonDataSet = { cy: 'filter-done-button' };

export const attributesListDataSet = { cy: 'attributes-list' };

export const analyticsListDataSet = { cy: 'analytics-list' };

export const headerAttributesButtonDataSet = { cy: 'header-attributes-button' };

export const disabledHeaderAttributesButtonDataSet = { cy: 'disabled-header-attributes-button' };

export const headerUsersButtonDataSet = { cy: 'header-users-button' };

export const clearFiltersButtonDataSet = { cy: 'clear-filters-button' };

export const webRefreshButtonDataSet = { cy: 'web-refresh-button' };

export const sectionHeaderDataSet = { cy: 'section-header' };
export const disabledSectionHeaderDataSet = { cy: 'disabled-section-header' };

export const viewAllButtonDataSet = { cy: 'view-all-button' };

export const attributeValueItemDataSet = { cy: 'attribute-value-item' };

export const usersValueItemDataSet = { cy: 'users-value-item' };

export const displayModeButtonDataSet = { cy: 'display-mode-button' };

export const filterMenuButtonDataSet = { cy: 'filter-menu-button' };

export const filterMenuContainerDataSet = { cy: 'filter-menu-container' };

export const percentsOptionDataSet = { cy: 'percents-option' };

export const absoluteOptionDataSet = { cy: 'absolute-option' };

export const filterMenuApplyButtonDataSet = { cy: 'filter-menu-apply-button' };

export const sharedWithOptionDataSet = { cy: 'shared-with-option' };

export const lockedSharedWithOptionDataSet = { cy: 'locked-shared-with-option' };

export const percentageValueTextDataSet = { cy: 'percentage-value-text' };

export const absoluteValueTextDataSet = { cy: 'absolute-value-text' };

export const aggregationAnswerItemDataSet = { cy: 'aggregation-answer-item' };

export const overviewTabBarIconDataSet = { cy: 'overview-tab-bar-icon' };

export const viewsTabBarIconDataSet = { cy: 'views-tab-bar-icon' };

export const answeredTabBarIconDataSet = { cy: 'answered-tab-bar-icon' };

export const commentsTabBarIconDataSet = { cy: 'comments-tab-bar-icon' };

export const analyticsListItemDataSet = { cy: 'analytics-list-item' };

export const usersFilterDataSet = { cy: 'users-filter' };

export const usersFilterButtonDataSet = { cy: 'users-filter-button' };

export const sectionHeaderErrorDataSet = { cy: 'section-header-error' };

export const sectionHeaderNoDataDataSet = { cy: 'section-header-no-data' };

export const refreshButtonDataSet = { cy: 'refresh-button' };

export const attributesListErrorFooterDataSet = { cy: 'attributes-list-error-footer' };

export const attributesListLoadingFooterDataSet = { cy: 'attributes-list-loading-footer' };

export const introIconDataSet = { cy: 'intro-icon' };

export const commentsIconDataSet = { cy: 'comments-icon' };

export const questionIconDataSet = { cy: 'question-icon' };

export const questionRightIconDataSet = { cy: 'question-right-icon' };

export const questionWrongIconDataSet = { cy: 'question-wrong-icon' };

export const headerCommentsButtonDataSet = { cy: 'header-comments-button' };
