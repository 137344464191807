import { useCallback, useRef } from 'react';

interface IBufferedTasksResult {
  addTask: (taskId: string, handler: () => void) => void;
  removeTask: (taskId: string) => void;
  clearTasks: () => void;
  callNextTask: () => void;
}

interface ITaskBuffer {
  taskId: string;
  handler: () => void;
}

/*
 * Buffered tasks are used to execute a set of tasks in a certain order.
 * Tasks are added to the queue and executed one at a time in the order they were added.
 */

export const useBufferedTasks = (): IBufferedTasksResult => {
  const tasksRef = useRef<ITaskBuffer[]>([]);

  const addTask = useCallback((taskId: string, handler: () => void) => {
    tasksRef.current.push({ taskId, handler });
  }, []);

  const removeTask = useCallback((taskId: string) => {
    tasksRef.current = tasksRef.current.filter((task) => taskId !== task.taskId);
  }, []);

  const clearTasks = useCallback(() => {
    tasksRef.current = [];
  }, []);

  const callNextTask = useCallback(() => {
    const task = tasksRef.current.shift();

    if (!task || typeof task.handler !== 'function') {
      return;
    }

    task.handler();
    removeTask(task.taskId);
  }, [removeTask]);

  return {
    addTask,
    removeTask,
    clearTasks,
    callNextTask,
  };
};
