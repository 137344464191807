import React, { useCallback, useState, useMemo, memo, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeFromStack } from '../../../store/appModals/appModals.slice';
import { modalStackSelector } from '../../../store/appModals/appModals.selectors';
import { EAppModalStackItemType, IModalStackItem } from '../../../store/appModals/appModals.types';
import UploadMediaErrorModal from './UploadMediaErrorModal';
import OnboardingQuestionnaire from './OnboardingQuestionnaire';
import IntroVideo from './IntroVideo';
import AcceptCoverChangeModal from './AcceptCoverChangeModal';
import UploadBanubaFilesModal from './UploadBanubaFilesModal/UploadBanubaFilesModal';

interface IProps {}

const AppModals: React.FC<IProps> = ({}) => {
  const modalStack = useSelector(modalStackSelector);

  return (
    <>
      {modalStack.map((modal) => (
        <ModalItem modal={modal} key={modal.modalId} />
      ))}
    </>
  );
};

type IComponents = {
  [key in EAppModalStackItemType]: FunctionComponent<any>;
};

const Components: IComponents = {
  [EAppModalStackItemType.INTRO_VIDEO]: IntroVideo,
  [EAppModalStackItemType.UPLOAD_ERROR]: UploadMediaErrorModal,
  [EAppModalStackItemType.ONBOARDING_QUESTIONNAIRE]: OnboardingQuestionnaire,
  [EAppModalStackItemType.ACCEPT_COVER_CHANGE]: AcceptCoverChangeModal,
  [EAppModalStackItemType.UPLOAD_BANUBA_FILES]: UploadBanubaFilesModal,
};

interface IModalItemProps {
  modal: IModalStackItem;
}

export interface IModalComponentProps {
  isVisible: boolean;
  onClose: () => void;
  onModalHide: () => void;
}

const ModalItem: React.FC<IModalItemProps> = ({ modal }) => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(true);

  const onClose = useCallback(() => {
    setIsVisible(false);
    if (typeof modal?.data?.onClose === 'function') {
      modal?.data?.onClose();
    }
  }, [modal?.data]);

  const onModalHide = useCallback(() => {
    dispatch(removeFromStack(modal.modalId));
    if (typeof modal?.data?.onModalHide === 'function') {
      modal?.data?.onModalHide();
    }
  }, [dispatch, modal?.data, modal.modalId]);

  const props: IModalComponentProps = useMemo(
    () => ({
      ...modal.data,
      isVisible,
      onClose,
      onModalHide,
    }),
    [isVisible, onClose, onModalHide, modal.data],
  );

  const Component = Components[modal.type];

  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

export default memo(AppModals);
