import React, { useEffect } from 'react';
import styled from 'styled-components/native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { calcHeight } from '../../../utils/dimensions';

interface IProps {
  isVisible: boolean;
  children: string;
}

const AuthError: React.FC<IProps> = ({ isVisible, children }) => {
  const isVisibleShared = useSharedValue(false);

  const animatedStyles = useAnimatedStyle(
    () => ({
      opacity: isVisibleShared.value ? withTiming(1, { duration: 400 }) : withTiming(0, { duration: 200 }),
      height: withTiming(isVisibleShared.value ? 40 : 0, { duration: 300 }),
    }),
    [isVisibleShared],
  );

  useEffect(() => {
    isVisibleShared.value = isVisible;
  }, [isVisibleShared, isVisible]);

  return (
    <S.Container style={animatedStyles}>
      <S.ErrorText>{children}</S.ErrorText>
    </S.Container>
  );
};

const S = {
  Container: styled(Animated.View)`
    align-items: center;
  `,
  ErrorText: styled.Text`
    height: ${calcHeight(16)}px;
    margin-top: ${calcHeight(24)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.pinkError};
  `,
};

export default AuthError;
