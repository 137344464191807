import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import OrganizationForm from './common/OrganizationForm';
import OrganizationLeave from './common/OrganizationLeave';
import OrganizationListItem from './common/OrganizationListItem';
import { navigate } from '../../../../RootNavigation';
import { activeOrganizationSelector } from '../../../../../store/auth/auth.selectors';
import { IUserOrganizations } from '../../../../../store/auth/auth.types';
import {
  switchOrganization,
  hideUpdateUserOrganizationNameForm,
  hideLeaveUserOrganization,
} from '../../../../../store/org/org.slice';
import { calcWidth, calcHeight, deviceHeight } from '../../../../../utils/dimensions';
import Routes from '../../../../routes';
import { IPanelChildInjectedProps } from '../../../../../store/bottomPanel/bottomPanel';
import DrawerModal from '../../DrawerModal';
import JoinOrganization from '../../../../../screens/JoinOrganization';
import { trackEvent } from '../../../../../store/appActivity/appActivity.slice';
import Button, { EIcon } from '../../../../../components/shared/buttons/Button/Button';
import themes from '../../../../../themes';
import useOrganizations from '../../../../../hooks/useOrganizations';

interface IProps extends IPanelChildInjectedProps {}

const dataSet = { 'intercom-target': 'join-new-organization' };

const OrganizationList: React.FC<IProps> = (props) => {
  const { onClosePanel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationsList = useOrganizations();
  const activeOrganization = useSelector(activeOrganizationSelector);

  const [selectedOrganization, setSelectedOrganization] = useState<IUserOrganizations | null>(null);
  const [isOrganizationInviteVisible, setOrganizationInviteVisible] = useState(false);

  const handleCloseOrganizationInvite = useCallback(() => {
    setOrganizationInviteVisible(false);
  }, []);

  const handleOpenOrganizationInvite = useCallback(() => {
    setOrganizationInviteVisible(true);
  }, []);

  const handleChangeOrganization = useCallback(
    (orgId: number) => {
      if (orgId === activeOrganization?.id) {
        onClosePanel();
        return;
      }

      navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });

      dispatch(
        trackEvent({
          event: 'change_organization',
          props: { from_organization_id: activeOrganization?.id, to_organization_id: orgId },
        }),
      );
      const newActiveOrganization = organizationsList?.find((org) => org.id === orgId);

      if (newActiveOrganization) {
        dispatch(switchOrganization({ organization: newActiveOrganization }));
      }
      onClosePanel();
    },
    [activeOrganization?.id, organizationsList, onClosePanel, dispatch],
  );

  const handleCloseOrganizationForm = useCallback(() => {
    dispatch(hideUpdateUserOrganizationNameForm());
  }, [dispatch]);

  const handleCloseLeave = useCallback(() => {
    dispatch(hideLeaveUserOrganization());
  }, [dispatch]);

  const handleOpenOrganizationMenu = useCallback((org: IUserOrganizations) => {
    setSelectedOrganization(org);
  }, []);

  const renderOrganizationInvite = useCallback(() => <JoinOrganization onClose={onClosePanel} />, [onClosePanel]);

  return (
    <S.Container>
      <S.Content>
        {organizationsList.map((o) => (
          <OrganizationListItem
            key={o.id}
            isActive={o.id === activeOrganization.id}
            organization={o}
            onPress={handleChangeOrganization}
            onPressSettings={handleOpenOrganizationMenu}
          />
        ))}
      </S.Content>
      <S.JoinButtonContainer>
        <S.JoinButton
          onPress={handleOpenOrganizationInvite}
          text={t('drawerNavigator.join')}
          border={themes.colors.primaryBlue}
          icon={EIcon.ADD}
          fill={themes.colors.white}
          textColor={themes.colors.primaryBlue}
          // @ts-ignore
          dataSet={dataSet}
        />
      </S.JoinButtonContainer>
      {selectedOrganization && (
        <>
          <OrganizationForm organization={selectedOrganization} onClose={handleCloseOrganizationForm} />
          <OrganizationLeave organization={selectedOrganization} onClose={handleCloseLeave} />
        </>
      )}

      {isOrganizationInviteVisible && (
        <DrawerModal
          title={t('appSettingsStack.workspaceModal.title')}
          isVisible={isOrganizationInviteVisible}
          onClose={handleCloseOrganizationInvite}
          renderContent={renderOrganizationInvite}
          fromModal
        />
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    height: 100%;
  `,
  Content: styled.ScrollView`
    flex: 1;
    max-height: ${deviceHeight - calcHeight(180)}px;
  `,
  JoinButton: styled(Button)`
    width: ${calcWidth(205)}px;
  `,
  JoinButtonContainer: styled.View`
    align-items: center;
    justify-content: center;
    margin-top: ${calcHeight(25)}px;
    margin-bottom: ${calcHeight(25)}px;
    margin-left: ${calcWidth(32)}px;
  `,
  Button: styled.TouchableOpacity(
    ({ theme }) => css`
      align-self: center;
      min-width: ${calcWidth(62)}px;
      border: 1px solid ${theme.colors.lightGray27};
      border-radius: 12px;
      margin-vertical: ${calcHeight(48)}px;
      padding-top: ${calcHeight(2)}px;
      padding-bottom: ${calcHeight(2)}px;
      padding-left: ${calcWidth(10)}px;
      padding-right: ${calcWidth(10)}px;
    `,
  ),
  ButtonText: styled.Text(
    ({ theme }) => css`
      color: ${theme.colors.lightGray26};
      font-size: ${theme.fontSizes.s11}px;
      line-height: ${theme.fontSizes.s11}px;
      text-align: center;
    `,
  ),
};

export default OrganizationList;
