import { getMimeTypeFromURL } from './getMimeTypeFromURL';

export function getMimeType({
  url,
  blob,
  file,
  dataUrl,
}: {
  url?: string;
  blob?: Blob;
  file?: File;
  dataUrl?: string;
}) {
  let mimeType = blob ? blob.type : file ? file.type : null;

  if (!mimeType && dataUrl) {
    const match = dataUrl.match(/^data:(.*?);base64,/);

    if (match) {
      mimeType = match[1];
    }
  }

  if (!mimeType && url) {
    mimeType = getMimeTypeFromURL(url);
  }

  return mimeType;
}
