import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFullBites } from '../../store/bite/bite.actions';
import { getBiteShareId } from '../../utils/formatDataFromServer';
import Routes from '../../navigation/routes';
import useIsDraft from '../../hooks/useIsDraft';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../utils/dimensions';
import { Platform, StatusBar } from 'react-native';
import { useTranslation } from 'react-i18next';
import { PREVIEW_SITE_URL } from '../../utils/constants/urls';
import useToken from '../../hooks/useToken';
import { IStackNavigation } from '../../navigation/types';
import styled, { useTheme } from 'styled-components/native';
import WebView from '../../components/WebView';
import { log } from '../../store/appActivity/appActivity.slice';
import { biteItemSelector } from '../../store/bite/bite.selectors';
import { useIsFocused } from '@react-navigation/native';
import SsoAuthLoader from '../auth/common/SsoAuthLoader';
import ShadowedContainer from '../../components/ShadowedContainer';
import { IS_IOS } from '../../utils/constants/env';
import Button from '../../components/shared/buttons/Button/Button';
import useUserCanEdit from '../../components/shared/useUserCanEdit';
import { bitePreviewCloseButtonDataSet, bitePreviewContainerDataSet } from './bite.constants';

interface IProps extends IStackNavigation {}

const BitePreview: React.FC<IProps> = ({ navigation, route }) => {
  const { biteId } = route.params;

  const bite = useSelector(biteItemSelector(biteId));

  const userCanEdit = useUserCanEdit(bite);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const { token } = useToken();

  const biteShareId = getBiteShareId(bite);

  const isDraft = useIsDraft(bite?.id);

  const url = useMemo(
    () => (isFocused ? `${PREVIEW_SITE_URL}/bites/${biteShareId}/?token=${token}&rnd=${Date.now()}&isPreview=1` : null), // CURRENT URL WRAPPED INTO USEMEMO BECAUSE IT USED AS KEY IN WEBVIEW
    [biteShareId, token, isFocused],
  );

  const handleEdit = useCallback(() => {
    dispatch(
      log({
        event: 'BitePreview.handleEdit',
        data: { biteId: bite?.id, isDraft, isGoBack: true },
      }),
    );

    navigation.navigate(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditMain,
      params: { biteId: bite?.id, isGoBack: true },
    });
  }, [bite?.id, isDraft, dispatch, navigation]);

  const handleBack = useCallback(() => {
    dispatch(
      log({
        event: 'BitePreview.handleBack',
      }),
    );

    navigation.goBack();
  }, [dispatch, navigation]);

  useEffect(() => {
    dispatch(
      log({
        event: 'BitePreview.view',
        data: { bite_id: bite?.id, external_target_url: url },
      }),
    );
  }, [bite?.id, dispatch, url]);

  useEffect(() => {
    if (!bite) {
      dispatch(fetchFullBites([biteId]));
    }
  }, [bite, biteId, dispatch]);

  if (!bite) {
    return <SsoAuthLoader />;
  }

  return (
    <S.Container
      //@ts-ignore
      dataSet={bitePreviewContainerDataSet}
    >
      <S.WebViewWrap>
        <WebView url={url} />
        <ShadowedContainer>
          <S.BottomPanel>
            {userCanEdit ? (
              <Button
                isShadowed
                onPress={handleEdit}
                text={t('analytics.overview.editBite')}
                style={buttonStyle}
                fill={theme.colors.white}
                border={theme.colors.primaryBlue}
                textColor={theme.colors.primaryBlue}
              />
            ) : (
              <S.EmptyView />
            )}

            <Button
              isShadowed
              onPress={handleBack}
              text={t('common.Close')}
              style={buttonStyle}
              fill={theme.colors.white}
              border={theme.colors.primaryBlue}
              textColor={theme.colors.primaryBlue}
              dataSet={bitePreviewCloseButtonDataSet}
            />
          </S.BottomPanel>
        </ShadowedContainer>
      </S.WebViewWrap>
    </S.Container>
  );
};

const buttonStyle = {
  height: calcHeight(45),
  width: calcWidth(150),
};

const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap: styled.View`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  BottomPanel: styled.View`
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    height: ${calcHeight(IS_IOS ? 94 : 69)}px;
    width: 100%;
    border-radius: 11px;
    flex-direction: row;
    justify-content: space-between;
    padding: ${calcHeight(12)}px ${calcWidth(27)}px;
  `,
  EmptyView: styled.View``,
};

export default memo(BitePreview);
