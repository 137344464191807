import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../../themes';
import CloseIcon from '../../../../assets/icons/videoEditor/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { overlaySelector } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import CloudSearchItem from '../CloudSearchItem';
import { setOverlay } from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import Button from '../../../../components/shared/buttons/Button/Button';
import { toggleIsBrandCloudAsset } from '../../../../store/cloudAssets/cloudAssets.slice';

const buttonStyle = {
  height: 47,
  width: 213,
};

const RemoveFromBrandOverlay = () => {
  const dispatch = useDispatch();

  const overlay = useSelector(overlaySelector);

  const handleClose = useCallback(() => {
    dispatch(setOverlay(null));
  }, [dispatch]);

  const handleContinue = useCallback(() => {
    dispatch(
      toggleIsBrandCloudAsset({
        cloudAssetId: overlay.cloudAssetId,
      }),
    );
    handleClose();
  }, [dispatch, handleClose, overlay.cloudAssetId]);

  return (
    <S.Container>
      <S.Content>
        <S.Title>Remove from Brand Library</S.Title>
        <S.Description>
          Removing this element from the Brand Library will make it no longer public and accessible to all video editors
          within the company
        </S.Description>
        <S.PreviewContainer>
          <CloudSearchItem withFeaturedIcons={false} cloudAssetId={overlay.cloudAssetId} />
        </S.PreviewContainer>
        <S.ButtonContainer>
          <Button
            isShadowed
            text={'Continue'}
            style={buttonStyle}
            fill={defaultTheme.colors.primaryBlue}
            border={defaultTheme.colors.primaryBlue}
            textColor={defaultTheme.colors.white}
            onPress={handleContinue}
          />
        </S.ButtonContainer>
      </S.Content>
      <S.CloseContainer onClick={handleClose}>
        <CloseIcon />
      </S.CloseContainer>
    </S.Container>
  );
};
export default memo(RemoveFromBrandOverlay);

export const S = {
  Container: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${defaultTheme.colors.transparentGray1};
  `,
  Content: styled.div`
    display: flex;
    width: 294px;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.div`
    color: ${defaultTheme.colors.black};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
  `,
  Description: styled.div`
    margin-top: 24px;
    color: ${defaultTheme.colors.black};
    text-align: center;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
  `,
  PreviewContainer: styled.div`
    margin-top: 30px;
    width: 294px;
    border: 8px solid #fff;
    border-radius: 10px;
    background: ${defaultTheme.colors.white};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  `,
  ButtonContainer: styled.div`
    margin-top: 39px;
  `,
  CloseContainer: styled.div`
    position: absolute;
    top: 17px;
    right: 17px;
    cursor: pointer;
  `,
};
