import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlignRightIcon from '../../../../assets/icons/creationFlow/align-right-icon.svg';
import AlignLeftIcon from '../../../../assets/icons/creationFlow/align-left-icon.svg';
import AlignCenterIcon from '../../../../assets/icons/creationFlow/align-center-icon.svg';
import FontIcon from '../../../../assets/icons/creationFlow/font-icon.svg';
import SelectedFontIcon from '../../../../assets/icons/creationFlow/font-selected-icon.svg';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { ISummaryCard } from '../../../../types/biteSummary';
import { lightOrDark } from '../../../../themes/lightOrDarkColor';
import { summaryCardColors, getFontFamily } from '../../../../utils/biteSummary';
import { EditableProp } from '../../CreateSummaryNotes/CreateSummaryNotes';
import { StyleProp, TextInput, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import { biteSelector } from '../../../../store/bite/bite.selectors';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { summaryNotesInputValueDataSet } from '../../CreateSummaryNotes/createSummaryNotes.constants';

interface IProps {
  isActive: boolean;
  index: number;
  note: ISummaryCard;
  height?: number;
  disableDelete?: boolean;
  onIconPress: (value: EditableProp | null) => void;
  onDelete: (id: number) => void;
  onPress: (index: number) => void;
  onChange: (note: ISummaryCard) => void;
  editableProp: EditableProp | null;
}

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

const NoteSticker: React.FC<IProps> = ({
  isActive,
  index,
  editableProp,
  disableDelete = false,
  onDelete,
  onPress,
  onChange,
  note,
  height = 288,
  onIconPress,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const questionInputRef = useRef<TextInput>(null);
  const textColor = lightOrDark(note.color);
  const { selectedBite } = useSelector(biteSelector);
  const dispatch = useDispatch();

  const onChangeText = useCallback(
    (value) => {
      if (isActive) {
        const updatedCard = { ...note };
        updatedCard.text = value;
        onIconPress(null);
        onChange(updatedCard);
      }
    },
    [isActive, note, onChange, onIconPress],
  );

  const handleDeleteIconPress = useCallback(() => {
    onDelete(note.id);
  }, [note.id, onDelete]);

  const renderAlignItem = useCallback(() => {
    switch (note.align) {
      case 'center':
        return <AlignCenterIcon />;
      case 'left':
        return <AlignLeftIcon />;
      case 'right':
        return <AlignRightIcon />;
      default:
        return null;
    }
  }, [note.align]);

  const handleAlignIconPress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'change_alignment',
      }),
    );
    const updatedCard = { ...note };

    switch (note.align) {
      case 'center':
        updatedCard.align = 'right';
        break;
      case 'right':
        updatedCard.align = 'left';
        break;
      default:
        updatedCard.align = 'center';
    }

    onChange(updatedCard);
  }, [dispatch, note, onChange]);

  const handleFontIconPress = useCallback(() => {
    if (editableProp === EditableProp.font) {
      onIconPress(null);
      return;
    }

    dispatch(
      trackEvent({
        event: 'change_font',
        props: { bite_id: selectedBite?.id },
      }),
    );
    onIconPress(EditableProp.font);
  }, [dispatch, editableProp, onIconPress, selectedBite?.id]);

  const handleColorIconPress = useCallback(() => {
    if (editableProp === EditableProp.color) {
      onIconPress(null);
      return;
    }

    dispatch(
      trackEvent({
        event: 'change_background_color',
        props: { bite_id: selectedBite?.id },
      }),
    );
    onIconPress(EditableProp.color);
  }, [dispatch, editableProp, onIconPress, selectedBite?.id]);

  const labelColor: string | null = useMemo(() => {
    const colorPalette = summaryCardColors.find((palette) => {
      return palette.cardColor === note.color;
    });

    return colorPalette ? colorPalette.labelColor : null;
  }, [note.color]);

  const handlePress = useCallback(() => {
    questionInputRef.current?.focus();
    onPress(index);
  }, [index, onPress, questionInputRef]);

  const stickerContainerStyle = useMemo(() => ({ minHeight: height }), [height]);

  const animatedInputStyle: StyleProp<ViewStyle> = useMemo(
    () => ({
      fontSize: calcFontSize(30) - 4 * Math.floor(note.text.length / 30),
      lineHeight: 34 - Math.max(note.text.split(/\r\n|\r|\n/).length, note.text.length / 35),
      color: textColor,
      fontFamily: getFontFamily(theme, note.font),
      textAlign: note.align,
      marginBottom: calcHeight(16),
      minHeight: calcHeight(180),
      maxHeight: calcHeight(180),
      textAlignVertical: 'top',
    }),
    [note.text, note.font, note.align, textColor, theme],
  );

  useEffect(() => {
    if (isActive) {
      const animationFrameId = requestAnimationFrame(() => {
        questionInputRef.current?.focus();
      });

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [isActive]);

  return (
    <S.Container onPress={handlePress}>
      <S.StickerContainer style={stickerContainerStyle} color={note.color}>
        <AnimatedTextInput
          ref={questionInputRef}
          editable={isActive}
          style={animatedInputStyle}
          placeholder={t('addQuestion.notePlaceholder')}
          placeholderTextColor={theme.colors.gray11}
          value={note.text}
          onChangeText={onChangeText}
          maxLength={150}
          multiline
          autoFocus
          // @ts-ignore
          dataSet={summaryNotesInputValueDataSet}
        />
        <S.EditPanel>
          <S.IconButton onPress={handleAlignIconPress}>{renderAlignItem()}</S.IconButton>
          <S.IconButton onPress={handleFontIconPress}>
            {editableProp === EditableProp.font ? <SelectedFontIcon /> : <FontIcon />}
          </S.IconButton>
          <S.IconButton disabled={disableDelete} onPress={handleDeleteIconPress}>
            <DeleteIcon style={S.DeleteIcon} fill={disableDelete ? 'transparent' : theme.colors.text} />
          </S.IconButton>
          <S.IconButton onPress={handleColorIconPress}>
            <S.ColorPicker isSelected={editableProp === EditableProp.color} color={labelColor} />
          </S.IconButton>
        </S.EditPanel>
      </S.StickerContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.TouchableWithoutFeedback``,
  StickerContainer: styled.View<{ color: string }>`
    width: ${calcWidth(327)}px;
    border-radius: 9px;
    padding: ${calcHeight(24)}px ${calcWidth(20)}px ${calcHeight(50)}px;
    background-color: ${({ color }) => color};
    ${() => isWeb && 'cursor: pointer;'}
  `,
  ContinueButton: styled(GenericButton)`
    width: ${calcWidth(111)}px;
    align-self: flex-end;
    margin-top: ${calcHeight(24)}px;
  `,
  EditPanel: styled.View`
    bottom: ${calcHeight(13)}px;
    left: ${calcWidth(18)}px;
    right: ${calcWidth(18)}px;
    position: absolute;
    flex-direction: row;
    height: ${calcHeight(40)}px;
    align-items: center;
    justify-content: space-between;
  `,
  ColorPicker: styled.View<{ isSelected: boolean; color: string }>`
    width: ${calcWidth(20)}px;
    height: ${calcWidth(20)}px;
    border-radius: ${calcWidth(20 / 2)}px;
    background-color: ${({ color }) => color || 'transparent'};
    border: 1px solid ${({ isSelected, color, theme }) => (isSelected ? theme.colors.text : color)};
  `,
  IconButton: styled.TouchableOpacity``,
  ColorButton: styled.TouchableOpacity`
    margin-right: ${calcWidth(22)}px;
  `,
  ColorPanelList: styled.FlatList`
    width: ${calcWidth(327)}px;
    margin-top: ${calcHeight(24)}px;
    height: ${calcHeight(24)}px;
  `,
  FontPanelList: styled.FlatList`
    width: ${calcWidth(327)}px;
    margin-top: ${calcHeight(24)}px;
    height: 24px;
    border: 1px solid;
  `,
  FontIconButton: styled.TouchableOpacity<{ isSelected: boolean }>`
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.6)};
    margin-right: ${calcWidth(45)}px;
  `,
  DeleteIcon: { width: 22, height: 22 } as StyleProp<ViewStyle>,
};

export default NoteSticker;
