import React, { useCallback, useState } from 'react';
import { accessCodeDataSet, continueButtonDataSet } from './email.constants';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import FormTextInput from '../../../components/shared/inputs/FormTextInput/FormTextInput';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { useDispatch, useSelector } from 'react-redux';
import { applyExternalAction, setExternalAction } from '../../../store/appActivity/appActivity.slice';
import { setAccessCode, setCurrentForm } from '../../../store/authForm/authForm.slice';
import { EAuthFormType } from '../auth.types';
import { accessCodeSelector } from '../../../store/authForm/authForm.selectors';
import { validateAccessCode } from '../../../store/org/org.slice';
import { useIsMounted } from '../../../hooks/useIsMounted';

const AccessCodeForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const code = useSelector(accessCodeSelector);
  const [codeError, setCodeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCodeValidationSuccess = useCallback(() => {
    if (!isMounted.current) {
      return;
    }

    setIsLoading(false);
    dispatch(applyExternalAction({ action: 'joinOrganization', code }));
    dispatch(setCurrentForm(EAuthFormType.AUTH));
  }, [code, dispatch, isMounted]);

  const onCodeValidationError = useCallback(() => {
    if (!isMounted.current) {
      return;
    }

    setIsLoading(false);
    setCodeError(t('authForm.accessCodeForm.invalidCode'));
  }, [isMounted, t]);

  const handleContinuePress = useCallback(() => {
    if (!code.length) {
      dispatch(setExternalAction(null));
      setCodeError(t('authForm.accessCodeForm.emptyCode'));
      return;
    }
    setCodeError(null);

    setIsLoading(true);
    dispatch(
      validateAccessCode({
        onSuccess: onCodeValidationSuccess,
        onError: onCodeValidationError,
      }),
    );
  }, [code.length, dispatch, onCodeValidationError, onCodeValidationSuccess, t]);

  const handleChangeCode = useCallback(
    (text) => {
      dispatch(setAccessCode(text));
      setCodeError(null);
    },
    [dispatch],
  );

  return (
    <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <S.HeaderContainer>
        <S.HeaderTitle>{t('authForm.accessCodeForm.title')}</S.HeaderTitle>
      </S.HeaderContainer>
      <S.Container>
        <S.TextInput
          value={code}
          onChangeText={handleChangeCode}
          label={t('authForm.accessCodeForm.accessCode')}
          onEnterPress={handleContinuePress}
          error={codeError}
          //@ts-ignore
          dataSet={accessCodeDataSet}
        />
        <S.ContinueButton
          isLoading={isLoading}
          onPress={handleContinuePress}
          content={t('common.Continue')}
          //@ts-ignore
          dataSet={continueButtonDataSet}
        />
      </S.Container>
    </Animated.View>
  );
};

const S = {
  HeaderContainer: styled.View`
    align-items: center;
    max-width: ${calcWidth(297)}px;
    align-self: center;
  `,
  Container: styled.View`
    margin: ${calcHeight(12)}px 0 ${calcHeight(32)}px;
    align-items: center;
  `,
  TextInput: styled(FormTextInput)`
    margin-bottom: ${calcHeight(18)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
  `,
  HeaderTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s20};
    line-height: ${({ theme }) => theme.fontSizes.s28};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: ${calcHeight(10)}px;
    text-align: center;
  `,
};

export default AccessCodeForm;
