import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { IPlaylist } from '../../../store/playlist/playlist.types';
import { IBiteItem } from '../../../types/bite';
import { IBiteShare } from '../../../types/biteShare';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { IS_IOS, IS_ANDROID } from '../../../utils/constants/env';
import { log } from '../../../store/appActivity/appActivity.slice';
import Intercom from '../../../services/intercom/intercom';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector } from '../../../store/appActivity/appActivity.selectors';

interface IProps {
  data: IBiteItem | IBiteShare | IPlaylist;
  shareModeTitle: string;
  shareModeDescription: string;
  hasChangeBtn: boolean;
  isSettingsVisible: boolean;
  onChangeViewSettings: () => void;
}

const coverSize = 60;
const dataSet = { 'intercom-target': 'share-settings' };

const SharePanelHeader: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const { data, shareModeTitle, shareModeDescription, hasChangeBtn, isSettingsVisible, onChangeViewSettings } = props;

  const { t } = useTranslation();

  const handleHowToButtonPress = useCallback(() => {
    dispatch(
      log({
        event: 'SharePanelHeader.handleHowToButtonPress',
      }),
    );

    Intercom.displayArticle(config.intercom.howToShareContent);
  }, [dispatch, config]);

  if (!data) {
    return null;
  }

  const cover = data.cover_url || (data as IPlaylist).linked_cover_url;

  return (
    <S.Container>
      {cover ? (
        <S.CoverContainer>
          <CoverImage source={{ uri: cover }} />
        </S.CoverContainer>
      ) : null}

      <S.TextContainer>
        <S.Title ellipsizeMode='tail' numberOfLines={1}>
          {data.subject}
        </S.Title>

        <S.SettingsContainerWithChangeBtn
          // @ts-ignore
          dataSet={dataSet}
        >
          <S.ViewSettingsText>{t('share.viewSettings')}</S.ViewSettingsText>
          <S.ViewSettingsTextSeparator>-</S.ViewSettingsTextSeparator>
          <S.ViewSettingsOptionText>{shareModeTitle}</S.ViewSettingsOptionText>
          {hasChangeBtn && !isSettingsVisible ? (
            <S.ChangeBtnContainer>
              <S.ChangeBtn onPress={onChangeViewSettings}>
                <S.ChangeBtnText>{t('share.change')}</S.ChangeBtnText>
              </S.ChangeBtn>
            </S.ChangeBtnContainer>
          ) : null}
        </S.SettingsContainerWithChangeBtn>
        <S.IntercomButton onPress={handleHowToButtonPress}>
          <S.IntercomButtonText>{t('share.howToShareContent')}</S.IntercomButtonText>
        </S.IntercomButton>
        <S.InfoDescription>
          <S.ViewSettingsOptionDescriptionText>{shareModeDescription}</S.ViewSettingsOptionDescriptionText>
        </S.InfoDescription>
      </S.TextContainer>
    </S.Container>
  );
};

const Container = styled.View`
  flex-direction: row;
  width: 100%;
  min-height: ${calcWidth(coverSize)}px;
  padding-left: ${calcWidth(16)}px;
  padding-right: ${calcWidth(16)}px;
  padding-bottom: ${calcWidth(9)}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray17};
`;

const CoverImage = styled.Image`
  width: ${calcWidth(coverSize)}px;
  height: ${calcWidth(coverSize)}px;
  border-radius: ${calcWidth(coverSize / 2)}px;
`;

const CoverContainer = styled.View`
  ${CoverImage};
  margin-right: ${calcWidth(13)}px;
  overflow: hidden;
`;

const TextContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

const Title = styled.Text`
  text-align: left;
  margin-bottom: ${calcWidth(4)}px;
  font-size: ${calcFontSize(19)}
  color: ${({ theme }) => theme.colors.gray1};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const SettingsContainerWithChangeBtn = styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const ViewSettingsText = styled.Text`
  font-size: ${calcFontSize(15)}px;
  color: ${({ theme }) => theme.colors.gray4};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const ViewSettingsTextSeparator = styled.Text`
  font-size: ${calcFontSize(15)}px;
  color: ${({ theme }) => theme.colors.gray4};
  margin-left: ${calcWidth(4)}px;
  margin-right: ${calcWidth(4)}px;
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const ViewSettingsOptionText = styled.Text`
  margin-right: ${calcWidth(8)}px;
  font-size: ${calcFontSize(15)}px;
  color: ${({ theme }) => theme.colors.gray3};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const ChangeBtnContainer = styled.View`
  padding-top: ${calcWidth(isWeb ? 1 : IS_ANDROID ? 4 : 0)}px;
  padding-bottom: ${calcWidth(IS_IOS ? 1 : 0)}px;
`;
const ChangeBtn = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  padding-top: ${calcWidth(IS_ANDROID ? 0 : 1)}px;
  padding-right: ${calcWidth(4)}px;
  padding-left: ${calcWidth(4)}px;
  padding-bottom: ${calcWidth(IS_ANDROID ? 2 : 0)}px;
  height: ${calcHeight(isWeb ? 16 : IS_ANDROID ? 18 : 16)}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.lightGray15};
  border-radius: ${calcWidth(16)}px;
`;
const ViewSettingsOptionDescriptionText = styled.Text`
  font-size: ${calcFontSize(12)}px;
  line-height: ${calcHeight(16)}px;
  color: ${({ theme }) => theme.colors.gray11};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  text-align: left;
`;

const ChangeBtnText = styled.Text`
  text-align: center;
  font-size: ${calcFontSize(12)}px;
  color: ${({ theme }) => theme.colors.gray5};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const InfoDescription = styled.View`
  margin-top: ${calcHeight(4)}px;
`;

const IntercomButton = styled.TouchableOpacity`
  position: absolute;
  right: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.lightPrimaryBlue};
  border-radius: 32px;
  padding: ${calcHeight(6)}px ${calcWidth(12)}px;
`;

const IntercomButtonText = styled.Text`
  text-align: center;
  color: ${({ theme }) => theme.colors.lightPrimaryBlue};
  font-size: ${({ theme }) => theme.fontSizes.s14}px;
  font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  font-weight: 400;
`;

const S = {
  Container,
  CoverImage,
  CoverContainer,
  TextContainer,
  Title,
  SettingsContainerWithChangeBtn,
  ViewSettingsText,
  ViewSettingsTextSeparator,
  ViewSettingsOptionText,
  ChangeBtnContainer,
  ChangeBtn,
  ChangeBtnText,
  ViewSettingsOptionDescriptionText,
  InfoDescription,
  IntercomButton,
  IntercomButtonText,
};

export default memo(SharePanelHeader);
