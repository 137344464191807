import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { calcWidth } from '../../../utils/dimensions';
import MSTeamsIcon from '../../../assets/icons/ms-teams-logo.svg';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import { copyLinkDataSet } from './AddToAny.constants';

interface IProps {
  title: string;
  link: string;
  onCopy: () => void;
  onTeamShare?: () => void;
}

const AddToAny: React.FC<IProps> = ({ title, link, onCopy, onTeamShare }) => {
  const hasTeamsShare = typeof onTeamShare === 'function';
  const dispatch = useDispatch();

  useEffect(() => {
    //@ts-ignore
    window.a2a?.init_all();
  }, []);

  const handleMouseDown = useCallback((e) => e.target.click(), []);

  const handleTeamShare = useCallback(() => {
    dispatch(trackEvent({ event: 'share_ms_teams', props: { ms_teams_org: hasTeamsShare } }));
    if (hasTeamsShare) {
      onTeamShare();
    }
  }, [dispatch, hasTeamsShare, onTeamShare]);

  return (
    <S.Container className='a2a_kit a2a_kit_size_40 a2a_default_style' data-a2a-url={link} data-a2a-title={title}>
      <S.TeamsButton
        data-intercom-target={!hasTeamsShare ? 'ms_teams_no_integration' : null}
        onMouseDown={handleTeamShare}
      >
        <MSTeamsIcon width={calcWidth(40)} height={calcWidth(40)} />
      </S.TeamsButton>
      <a onMouseDown={onCopy} className='a2a_button_copy_link' data-cy={copyLinkDataSet.cy} />
      <a onMouseDown={handleMouseDown} className='a2a_button_sms' />
      <a onMouseDown={handleMouseDown} className='a2a_button_email' />
      <a onMouseDown={handleMouseDown} className='a2a_button_whatsapp' />
      <a onMouseDown={handleMouseDown} className='a2a_dd' href='https://www.addtoany.com/share' />
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    gap: 15px;
  `,
  TeamsButton: styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

export default memo(AddToAny);
