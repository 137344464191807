import React from 'react';
import styled from 'styled-components/native';
import Preloader from './Preloader';

interface IProps {
  isVisible: boolean;
}
const LoadingOverlay: React.FC<IProps> = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <S.Container>
      <Preloader />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.white};
    z-index: 10;
  `,
};

export default LoadingOverlay;
