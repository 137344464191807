import { Font } from './text/fonts';

export const filterFonts = (allFonts: Font[], filters: Partial<Font>) => {
  const filtersArr = Object.entries(filters);

  return allFonts.filter((font) => {
    return filtersArr.every((key) => {
      return font[key[0]] === key[1];
    });
  });
};
