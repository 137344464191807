// import React, { useCallback, useMemo, useState } from 'react';
import React, { useCallback, useMemo } from 'react';
import ShadowedContainer from '../../../ShadowedContainer';
import styled from 'styled-components/native';
// import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
// import { PanGestureHandler, State } from 'react-native-gesture-handler';
// import ArrowIcon from '../../../../assets/icons/creationFlow/right-arrow-icon.svg';
import { useTranslation } from 'react-i18next';
import { IElementsPanelContent, IElementsPanelItem } from '../types';

const PAGE_WIDTH = isWeb ? calcWidth(600) : deviceWidth - calcWidth(40);
const PAGE_SIZE = isWeb ? 5 : 3;
const PLACEHOLDER_COUNT = 7;

interface IProps {
  onItemPress: (item: IElementsPanelItem, section: IElementsPanelContent) => void;
  content: IElementsPanelContent;
}

const Slider: React.FC<IProps> = ({ onItemPress, content }) => {
  // const theme = useTheme();
  // const scrollRef = React.useRef(null);

  const maxPages = useMemo(() => Math.ceil(content.items.length / PAGE_SIZE), [content]);

  // const [currentPage, setCurrentPage] = useState(0);

  // const goToNextPage = useCallback(() => {
  //   const nextPage = currentPage < maxPages - 1 ? currentPage + 1 : currentPage;
  //   const nextPageOffset = PAGE_WIDTH * nextPage;

  //   scrollRef.current.scrollTo({ x: nextPageOffset, y: 0, animated: true });
  //   setCurrentPage(nextPage);
  // }, [currentPage, maxPages]);

  // const goToPrevPage = useCallback(() => {
  //   const prevPage = currentPage > 0 ? currentPage - 1 : currentPage;
  //   const prevPageOffset = PAGE_WIDTH * prevPage;

  //   scrollRef.current.scrollTo({ x: prevPageOffset, y: 0, animated: true });
  //   setCurrentPage(prevPage);
  // }, [currentPage]);

  // const handleSwipe = useCallback(
  //   ({ nativeEvent }) => {
  //     if (nativeEvent.oldState !== State.ACTIVE) {
  //       return;
  //     }

  //     if (nativeEvent.translationX < 0) {
  //       goToNextPage();
  //       return;
  //     }

  //     goToPrevPage();
  //   },
  //   [goToNextPage, goToPrevPage],
  // );

  const placeholderContent = useMemo(() => {
    const placeholders = [];
    for (let i = 0; i < PAGE_SIZE - content.items.length; i++) {
      const randomIndex = Math.ceil(Math.random() * PLACEHOLDER_COUNT);
      const placeholderImageUrl = `https://static.mybites.io/staticfiles/bites-ui/bite-cover-images/helpers-placeholder-${randomIndex}.png`;
      placeholders.push(
        <ElementsPanelItem
          panelItem={{ image: placeholderImageUrl, label: '', data: null }}
          section={content}
          key={i}
          onItemPress={null}
        />,
      );
    }

    return placeholders;
  }, [content]);

  return (
    <S.SectionContainer>
      {/* {isWeb && (
        <S.ControlButton onPress={goToPrevPage} disabled={currentPage === 0}>
          <ArrowIcon width={20} height={40} color={theme.colors.borderGray} />
        </S.ControlButton>
      )} */}
      {/* <S.SwipeContainer ref={scrollRef} scrollEnabled={false} showsHorizontalScrollIndicator={false} horizontal> */}
      <S.SwipeContainer>
        {/* <PanGestureHandler onHandlerStateChange={handleSwipe}> */}
        <S.ButtonsSwipeContent maxPages={maxPages}>
          {content.items.map((panelItem, index) => (
            <ElementsPanelItem
              panelItem={panelItem}
              section={content}
              key={`${panelItem.label}${index}`}
              onItemPress={onItemPress}
            />
          ))}
          {placeholderContent}
        </S.ButtonsSwipeContent>
        {/* </PanGestureHandler> */}
      </S.SwipeContainer>
      {/* {isWeb && (
        <S.ControlButton onPress={goToNextPage} disabled={currentPage === maxPages - 1} isNext>
          <ArrowIcon width={20} height={40} color={theme.colors.borderGray} />
        </S.ControlButton>
      )} */}
    </S.SectionContainer>
  );
};

const ElementsPanelItem = ({ panelItem, onItemPress, section }) => {
  const { t } = useTranslation();

  const source = useMemo(() => ({ uri: panelItem.image }), [panelItem]);
  const handleItemPress = useCallback(
    () => onItemPress && onItemPress(panelItem, section),
    [onItemPress, panelItem, section],
  );

  return (
    <S.ElementsPanelItem onPress={handleItemPress}>
      <ShadowedContainer>
        <S.ElementsPanelItemImage source={source} />
      </ShadowedContainer>
      <S.ElementsPanelItemTitle>{t(panelItem.label)}</S.ElementsPanelItemTitle>
    </S.ElementsPanelItem>
  );
};

const S = {
  // SwipeContainer: styled.ScrollView`
  SwipeContainer: styled.View`
    flex-direction: row;
    margin: 10px;
    width: ${PAGE_WIDTH}px;
    height: ${calcHeight(224)}px;
    overflow: hidden;
  `,
  ElementsPanelItem: styled.TouchableOpacity`
    width: ${isWeb ? calcWidth(109) : calcWidth(102)}px;
    height: ${isWeb ? calcHeight(204) : calcHeight(187)}px;
    margin: ${calcHeight(5)}px ${calcWidth(5)}px;
  `,
  ElementsPanelItemImage: styled.Image`
    position: relative;
    width: ${isWeb ? calcWidth(109) : calcWidth(100)}px;
    height: ${isWeb ? calcHeight(154) : calcHeight(141)}px;
    border-radius: 18px;
    overflow: hidden;
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  `,
  ElementsPanelItemTitle: styled.Text`
    margin-top: ${calcHeight(4)}px;
    padding: 0 ${calcWidth(12)}px;
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  ButtonsSwipeContent: styled.View<{ maxPages: number }>`
    min-width: ${({ maxPages }) => (maxPages > 1 ? PAGE_WIDTH * maxPages : PAGE_WIDTH + calcWidth(30))}px;
    flex-direction: row;
  `,
  ControlButton: styled.TouchableOpacity<{ disabled: boolean; isNext?: boolean }>`
    position: absolute;
    top: ${calcHeight(72)}px;
    justify-content: center;
    align-items: center;
    opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    ${({ isNext }) =>
      isNext
        ? `right: -${calcWidth(30)}px;`
        : `left: -${calcWidth(30)}px;
           transform: rotate(180deg);`};
  `,
  SectionContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
};

export default Slider;
