import { takeLatest, put, select, call, take } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { AxiosResponse } from 'axios';

import * as calls from '../api/bites-api/calls/auth.calls';
import * as orgCalls from '../api/bites-api/calls/org.calls';
import {
  fetchOrganization,
  fetchOrganizationCode,
  updateUserDefaultOrganization,
  updateOrganizationNameRequest,
  leaveOrganizationRequest,
  fetchCreatorsRequest,
  fetchJoinOrganizationDeeplink,
} from '../api/bites-api/calls/org.calls';
import {
  fetchOrg,
  fetchOrgCode,
  getCreators,
  setCreators,
  switchOrganization,
  fetchOrgCodeSuccess,
  fetchOrgFailure,
  fetchOrgSuccess,
  hideUpdateUserOrganizationNameForm,
  updateUserOrganizationName,
  updateUserOrganizationNameStart,
  updateUserOrganizationNameSuccess,
  updateUserOrganizationNameFailure,
  hideLeaveUserOrganization,
  leaveUserOrganization,
  leaveUserOrganizationStart,
  leaveUserOrganizationSuccess,
  leaveUserOrganizationFailure,
  getInOrganization,
  setJoinOrganizationDeeplink,
  setIsOrgCodeLoading,
  setOrgCodeError,
  validateAccessCode,
} from './org.slice';
import {
  ISwitchOrganizationAction,
  IUserOrganizationRenameAction,
  IUserOrganizationLeaveAction,
  IGetInOrganizationAction,
  IFetchCreatorsResult,
} from './org.types';
import { refreshUser, refreshUserSuccess, setLeadName, switchActiveOrganization } from '../auth/auth.actions';
import { fetchPlaylistsRequest } from '../playlist/playlist.actions';
import { userSelector, activeOrganizationSelector } from '../auth/auth.selectors';
import Types, { IUser, IUserOrganizations } from '../auth/auth.types';
import { getDefaultOrganization } from '../../utils/formatDataFromServer';
import { closeDrawerModal } from '../menuState/menuState.slice';
import { loadNextPage } from '../feed/feed.slice';
import { fetchHasBites } from '../bite/bite.actions';
import { gtmSetUserProps } from '../../services/gtm/track';
import { logError } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import getOrganizations from '../../utils/getOrganizations';
import { accessCodeSelector } from '../authForm/authForm.selectors';

function* fetchOrgRequest(action: PayloadAction<number>) {
  const orgId = action.payload;

  if (orgId === 1) {
    // endpoint is blocked for bites' org, so we mock an empty
    // payload to set datas to empty array
    yield put(fetchOrgSuccess({}));
    return;
  }
  try {
    const { data } = yield fetchOrganization(orgId);
    yield put(fetchOrgSuccess(data));
    yield put(setLeadName(data.lead_name));

    const user = yield select(userSelector);
    const organizations = yield call(getOrganizations, user, {
      isActive: false, // we want to get all organizations
      includeId1: true,
    });
    const organization = organizations.find((org) => org.id === data.id);

    if (organization?.is_creator) {
      yield put(fetchOrgCode(data.id));
    }
  } catch (err) {
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });

    yield put(fetchOrgFailure());
  }
}

function* getCeatorsSaga() {
  try {
    yield put(setCreators([]));
    const org = yield select(activeOrganizationSelector);
    const { data }: AxiosResponse<IFetchCreatorsResult> = yield call(fetchCreatorsRequest, org.id);
    const creators = data.results.map(({ user }) => user);
    yield put(setCreators(creators));
  } catch (error) {
    yield put(logError({ error }));
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* fetchOrgCodeRequest(action: any) {
  const orgId = action.payload;
  yield put(setJoinOrganizationDeeplink(null));
  if (orgId === 1) {
    // endpoint is blocked for bites' org, so we mock an empty
    // payload to set datas to empty array
    yield put(fetchOrgCodeSuccess(null));
    return;
  }
  try {
    const params = {
      organization: orgId,
    };
    const queryParams = queryString.stringify(params);
    const { data } = yield fetchOrganizationCode(queryParams);
    yield put(fetchOrgCodeSuccess(data));

    const code = data?.length > 0 && data[0].code;
    if (code) {
      const {
        data: { deeplink },
      } = yield fetchJoinOrganizationDeeplink(code);
      yield put(setJoinOrganizationDeeplink(deeplink));
    }

    yield put(setIsOrgCodeLoading(false));
  } catch (error) {
    yield put(setOrgCodeError(error));
  }
}

function* switchOrganizationSaga(action: PayloadAction<ISwitchOrganizationAction>) {
  const { organization, isSetDefault = true } = action.payload;
  const user = yield select(userSelector);
  const body = { is_default: true };
  gtmSetUserProps(user.id, {
    last_org_id: `${organization.id}`,
    last_org_name: organization.name,
  });
  try {
    yield put(fetchOrg(organization.id));
    yield put(switchActiveOrganization(organization.id));
    yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
    yield put(fetchPlaylistsRequest(organization.id));
    yield put(fetchHasBites());

    if (isSetDefault) {
      yield updateUserDefaultOrganization(organization.user_organization_id, body);
    }
  } catch (error) {
    yield put(
      logError({
        event: 'switchOrganizationSaga: error',
        error,
      }),
    );
  }
}

function* updateOrganizationName({ payload }: PayloadAction<IUserOrganizationRenameAction>) {
  const { id, name } = payload;
  const currentUser: IUser = yield select(userSelector);

  if (!currentUser) {
    return;
  }

  try {
    yield put(updateUserOrganizationNameStart());

    const organizations = cloneDeep(currentUser.organizations);
    const organization = organizations.find((o) => o.id === id);
    if (!organization) {
      return yield put(updateUserOrganizationNameFailure('Organization not found'));
    }

    yield updateOrganizationNameRequest(id, name);
    organization.name = name;
    yield put(
      refreshUserSuccess({
        user: {
          ...currentUser,
          organizations,
        },
        isSignup: false,
      }),
    );

    yield put(updateUserOrganizationNameSuccess());
    yield put(closeDrawerModal());
    yield put(hideUpdateUserOrganizationNameForm());
  } catch (err) {
    yield put(updateUserOrganizationNameFailure(err));
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* leaveOrganization({ payload }: PayloadAction<IUserOrganizationLeaveAction>) {
  const { id } = payload;
  const currentUser: IUser = yield select(userSelector);

  if (!currentUser) {
    return;
  }

  try {
    const activeOrganization: IUserOrganizations = yield select(activeOrganizationSelector);
    yield put(leaveUserOrganizationStart());

    yield leaveOrganizationRequest(id);

    const { data } = yield calls.getUser();
    yield put(refreshUserSuccess({ user: data, isSignup: false }));

    if (activeOrganization.user_organization_id === id) {
      const organization = getDefaultOrganization(data.organizations);
      yield put(switchOrganization({ organization }));
    }

    yield put(leaveUserOrganizationSuccess());
    yield put(hideLeaveUserOrganization());
  } catch (err) {
    yield put(leaveUserOrganizationFailure(err));
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* getInOrganizationSaga({ payload: { organizationId } }: PayloadAction<IGetInOrganizationAction>) {
  yield put(fetchOrg(organizationId));
  yield put(switchActiveOrganization(organizationId));
  yield put(refreshUser());
  yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
  yield put(fetchPlaylistsRequest(organizationId));

  yield take(Types.REFRESH_USER_SUCCESS);
  const activeOrganization = yield select(activeOrganizationSelector);
  yield updateUserDefaultOrganization(activeOrganization.user_organization_id, { is_default: true });
}

function* validateAccessCodeSaga({
  payload: { onSuccess, onError },
}: PayloadAction<{ onSuccess?: () => void; onError?: () => void }>) {
  try {
    const accessCode = yield select(accessCodeSelector);
    yield orgCalls.validateAccessCode(accessCode);

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    yield put(logError({ error }));

    if (typeof onError === 'function') {
      onError();
    }
  }
}

export default function* orgSaga() {
  // fetchOrg must be takeLatest because of the case
  // when we are switching org within external action to avoid race conditions
  yield takeLatest(fetchOrg, fetchOrgRequest);
  yield takeLatest(getCreators, getCeatorsSaga);
  yield takeLatest(fetchOrgCode, fetchOrgCodeRequest);
  yield takeLatest(switchOrganization, switchOrganizationSaga);
  yield takeLatest(updateUserOrganizationName, updateOrganizationName);
  yield takeLatest(leaveUserOrganization, leaveOrganization);
  yield takeLatest(getInOrganization, getInOrganizationSaga);
  yield takeLatest(validateAccessCode, validateAccessCodeSaga);
}
