import React from 'react';
import { IAcceptCoverChangeModalProps } from '../../../../store/appModals/appModals.types';
import { IModalComponentProps } from '../AppModals';

interface IProps extends IAcceptCoverChangeModalProps, IModalComponentProps {}

const UploadBanubaFilesModal: React.FC<IProps> = () => {
  return null;
};

export default UploadBanubaFilesModal;
