import React, { memo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';

interface IProps {
  onRefresh: () => void;
}

const Error: React.FC<IProps> = ({ onRefresh }) => {
  const { t } = useTranslation();
  return (
    <S.Error>
      <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
      <S.RefreshButton onPress={onRefresh}>
        <S.RefreshButtonText>{t('common.refresh')}</S.RefreshButtonText>
      </S.RefreshButton>
    </S.Error>
  );
};

const S = {
  Error: styled.View`
    width: ${calcWidth(351)}px;
    background: ${({ theme }) => theme.colors.overviewBackground};
    border-radius: 20px;
    padding: ${calcHeight(25)}px ${calcWidth(15)}px;
    height: ${calcHeight(195)}px;
    align-items: center;
    justify-content: center;
    align-self: center;
  `,
  ErrorText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,

  RefreshButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(10)}px;
  `,
  RefreshButtonText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,
};

export default memo(Error);
