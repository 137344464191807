import { useSelector } from 'react-redux';
import { activeOrganizationSelector, userSelector } from '../store/auth/auth.selectors';

export const useCurrentUserOrg = () => {
  const user = useSelector(userSelector);
  const org = useSelector(activeOrganizationSelector);

  const userOrg = user.organizations.find((o) => o.id === org.id) || null;

  return userOrg;
};
