import React, { FC, useRef, useEffect, useCallback, useMemo } from 'react';
import { v4 as getUUID } from 'uuid';

import { IVideoDetails } from '../../../../types/media';
import { youtubeUrlParser } from '../../../../utils/youtubeUrlParser';
import styled from 'styled-components';

interface WebYoutubeMedia {
  videoId?: string;
  is_cc_enabled?: boolean;
  url?: string;
}

interface IProps {
  youtubeMedia: WebYoutubeMedia;
  setRef?: (ref: any) => void;
  onMediaDetails?: (videoDetails: IVideoDetails) => void;
  isAutoPlay?: boolean;
}

const YoutubePlayer: FC<IProps> = ({ onMediaDetails, setRef, youtubeMedia, isAutoPlay }) => {
  const youtubeVideoRef = useRef(null);
  const playerIdRef = useRef(getUUID());

  const onApiChange = useCallback(() => {
    if (typeof youtubeVideoRef.current?.setOption === 'function') {
      youtubeVideoRef.current.setOption('captions', 'track', { languageCode: 'en' });
    }
  }, []);

  const onReady = useCallback(() => {
    if (!isAutoPlay) {
      return;
    }
    youtubeVideoRef.current?.playVideo?.();
  }, [isAutoPlay]);

  const videoId = useMemo(
    () => (youtubeMedia?.videoId ? youtubeMedia?.videoId : youtubeUrlParser(youtubeMedia?.url)),
    [youtubeMedia],
  );

  useEffect(() => {
    onMediaDetails?.({ width: 1920, height: 1080 });
    setRef?.(youtubeVideoRef.current);

    return () => {
      youtubeVideoRef.current?.destroy();
      youtubeVideoRef.current = null;
      setRef?.(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    youtubeVideoRef.current = new YT.Player(playerIdRef.current, {
      height: '100%',
      width: '100%',
      videoId,
      playerVars: {
        cc_load_policy: youtubeMedia?.is_cc_enabled ? 1 : 3,
      },
      events: {
        onReady,
        onApiChange,
      },
    });
  }, [onApiChange, onReady, videoId, youtubeMedia?.is_cc_enabled, youtubeMedia.videoId]);

  return (
    <S.Container>
      <div id={playerIdRef.current} />
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    width: 100%;
    height: 100%;
  `,
};

export default YoutubePlayer;
