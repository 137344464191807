import Views from '../../../../../assets/icons/preview/views.svg';
import ViewsColorless from '../../../../../assets/icons/preview/views-disabled.svg';
import Question from '../../../../../assets/icons/preview/question.svg';
import QuestionColorless from '../../../../../assets/icons/preview/question-disabled.svg';
import Discussion from '../../../../../assets/icons/preview/duscussion.svg';
import DiscussionColorless from '../../../../../assets/icons/preview/duscussion-disabled.svg';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import { PAGE_WIDTH } from '../../constants';

interface IProps {
  onPress: () => void;
  isDashboard?: boolean;
  watched?: number;
  participants?: number;
  participantsIsPercentage?: boolean;
  comments?: number;
}
const dataSet = { 'intercom-target': 'in-app-stats' };

const StatisticsButton: React.FC<IProps> = ({
  onPress,
  isDashboard,
  watched,
  participants,
  participantsIsPercentage,
  comments,
}) => {
  const theme = useTheme();

  return (
    <S.StatisticsButton
      isActive={isDashboard}
      onPress={onPress}
      // @ts-ignore
      dataSet={dataSet}
    >
      {watched !== undefined && (
        <S.StatisticsItem>
          {watched > 0 ? (
            <Views style={S.StatisticsIcon} />
          ) : (
            <ViewsColorless style={S.StatisticsIcon} color={theme.colors.gray19} />
          )}
          <S.StatisticsText bold={watched > 0}>{watched}</S.StatisticsText>
        </S.StatisticsItem>
      )}
      {participants !== undefined && (
        <S.StatisticsItem>
          {participants > 0 ? (
            <Question style={S.StatisticsIcon} />
          ) : (
            <QuestionColorless style={S.StatisticsIcon} color={theme.colors.gray19} />
          )}
          <S.StatisticsText bold={participants > 0}>
            {participants || 0}
            {participantsIsPercentage && participants !== undefined && participants !== null ? '%' : null}
          </S.StatisticsText>
        </S.StatisticsItem>
      )}
      {comments !== undefined && (
        <S.StatisticsItem>
          {comments > 0 ? (
            <Discussion style={S.StatisticsIcon} />
          ) : (
            <DiscussionColorless style={S.StatisticsIcon} color={theme.colors.gray19} />
          )}
          <S.StatisticsText bold={comments > 0}>{comments}</S.StatisticsText>
        </S.StatisticsItem>
      )}
    </S.StatisticsButton>
  );
};

const S = {
  StatisticsItem: styled.View``,
  StatisticsText: styled.Text<{ bold?: boolean }>`
    margin-top: ${calcHeight(2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  StatisticsButton: styled.TouchableOpacity<{ isActive?: boolean }>`
    flex: 2;
    height: ${calcHeight(53)}px;
    min-width: ${(PAGE_WIDTH - calcWidth(10)) / 2}px;
    max-width: ${(PAGE_WIDTH - calcWidth(10)) / 2}px;
    border-radius: 10px;
    border: solid 1px ${({ theme, isActive }) => (isActive ? theme.colors.gray22 : theme.colors.gray21)};
    background: ${({ theme, isActive }) => (isActive ? theme.colors.lightGray37 : theme.colors.white)};
    margin: 0 ${calcWidth(2)}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: ${calcHeight(11)}px ${calcWidth(8)}px;
  `,
  StatisticsIcon: {
    width: calcWidth(24),
    height: calcHeight(24),
  },
};

export default StatisticsButton;
