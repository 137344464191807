import { MutableRefObject, useEffect } from 'react';

export const useOnResize = (nodeRef: MutableRefObject<HTMLElement>, fn: () => void) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(fn);

    const node = nodeRef.current;

    // Start observing the target node
    resizeObserver.observe(node);

    return () => {
      resizeObserver.unobserve(node);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeRef]);
};
