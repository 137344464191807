import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAnswersNum,
  getCommentsNum,
  getStatsOverview,
  getViewsNum,
  resetAnswersNum,
  resetCommentsNum,
  resetViewsNum,
  setAnswersNum,
  setAnswersNumError,
  setCommentsNum,
  setCommentsNumError,
  setViewsNum,
  setViewsNumError,
} from './analyticsOverview.slice';
import * as calls from '../api/bites-api/calls/analytics.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { biteIdSelector } from '../analytics/analytics.selector';
import withRetry from '../../utils/withRetry';
import { biteItemSelector } from '../bite/bite.selectors';
import { EditBiteIconType } from '../../screens/editBite/EditMain/common/SectionItem';
import { IntroSection, QuestionSection } from '../../types/bite';
import { isEmpty } from 'lodash';
import { updateFeedBite } from '../bite/bite.actions';
import { setSortBy } from '../attributes/attributes.slice';
import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';
import { ESortByType, ESortingDirection } from '../attributes/attributes.types';
import { organizationFeaturesSelector } from '../org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../utils/constants/org';

function* getViewsNumberSaga() {
  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { views_num },
    } = yield withRetry(() => calls.getViewsNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'analyticsOverview.saga getViewsNumberSaga',
      },
    });
    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          watched: views_num || 0,
        },
      }),
    );
    yield put(setViewsNum(views_num));
  } catch (error) {
    yield put(setViewsNumError({ error }));
  }
}

function* getAnswersNumberSaga() {
  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { answers_num },
    } = yield withRetry(() => calls.getAnswersNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'analyticsOverview.saga getAnswersNumberSaga',
      },
    });
    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          answers: answers_num || 0,
        },
      }),
    );
    yield put(setAnswersNum(answers_num));
  } catch (error) {
    yield put(setAnswersNumError({ error }));
  }
}

function* getCommentsNumberSaga() {
  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);
    const {
      data: { comments_num },
    } = yield withRetry(() => calls.getCommentsNumber({ orgId, biteIds: [biteId], allowedDataOnly }), {
      errorContext: {
        action: 'analyticsOverview.saga getCommentsNumberSaga',
      },
    });

    yield put(
      updateFeedBite({
        id: biteId,
        bite: {
          comments: comments_num || 0,
        },
      }),
    );
    yield put(setCommentsNum(comments_num));
  } catch (error) {
    yield put(setCommentsNumError({ error }));
  }
}

function* getStatsOverviewSaga() {
  const biteId = yield select(biteIdSelector);
  const bite = yield select(biteItemSelector(biteId));

  const isViewsTabEnabled = (() => {
    const currentSection = bite?.bite_sections?.find((item) => item.type === EditBiteIconType.INTRO) as IntroSection;

    if (!currentSection) {
      return false;
    }

    return !(currentSection.task_id === null && currentSection.media === null);
  })();

  const isAnsweredTabEnabled = (() => {
    const currentSection = bite?.bite_sections?.find(
      (item) => item.type === EditBiteIconType.QUESTION,
    ) as QuestionSection;

    if (!currentSection) {
      return false;
    }

    return !isEmpty(currentSection.questions);
  })();

  // if the full bite is not loaded yet
  // we still do not know which sections are available
  // so not to wait, just request all the data
  if (isViewsTabEnabled || !bite.bite_sections) {
    yield put(getViewsNum());
  } else {
    yield put(resetViewsNum());
  }

  if (isAnsweredTabEnabled || !bite.bite_sections) {
    yield put(getAnswersNum());
  } else {
    yield put(resetAnswersNum());
  }

  if (bite.discussion_enabled) {
    yield put(getCommentsNum());
  } else {
    yield put(resetCommentsNum());
  }

  if (!isViewsTabEnabled) {
    yield put(
      setSortBy({
        currentTab: EAnalyticsScreenTabs.OVERVIEW,
        sortBy: isAnsweredTabEnabled ? ESortByType.ANSWERS : ESortByType.COMMENTS,
        sortDirection: ESortingDirection.DESC,
        withoutReset: true,
      }),
    );
  }
}

export default function* analyticsOverviewSaga() {
  yield all([
    takeLatest(getStatsOverview, getStatsOverviewSaga),
    takeLatest(getViewsNum, getViewsNumberSaga),
    takeLatest(getAnswersNum, getAnswersNumberSaga),
    takeLatest(getCommentsNum, getCommentsNumberSaga),
  ]);
}
