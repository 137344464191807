import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, I18nManager } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme, css } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import { Tabs as TabsEnum } from '../../store/homeScreen/homeScreen.types';
import MagicStarsBlueIcon from '../../assets/icons/magic-stars-blue.svg';
import themes from '../../themes';
import CloseIcon from '../../assets/icons/close.svg';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { homeTabsActiveTabSelector } from '../../store/homeScreen/homeScreen.selectors';
import {
  searchCancelButtonDataSet,
  searchClearInputDataSet,
  searchInputDataSet,
} from '../../screens/landing/Home/components/component.constants';

interface IProps {
  value: string;
  onChangeText: (value: string) => void;
  isSearching: boolean;
  isSearchOpen: boolean;
  setIsSearchOpen?: (state: boolean) => void;
  onClosePanel?: () => void;
  expandedPanelWidth?: number;
  hasAISuggestion?: boolean;
  autoFocus?: boolean;
}

const SearchPanel: React.FC<IProps> = ({
  isSearchOpen,
  value,
  onChangeText,
  isSearching,
  setIsSearchOpen,
  onClosePanel,
  expandedPanelWidth = isWeb ? calcWidth(263) : calcWidth(240),
  hasAISuggestion,
  autoFocus = false,
}) => {
  const collapsedPanelWidth = isWeb ? calcWidth(263) : calcWidth(44);
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const searchAnimatedWidth = useSharedValue(isSearchOpen ? expandedPanelWidth : collapsedPanelWidth);
  const searchAnimatedMarginLeft = useSharedValue(isSearchOpen && !isWeb ? calcWidth(10) : 0);
  const tab = useSelector(homeTabsActiveTabSelector);
  const [isTextChanged, setIsTextChanged] = useState(false);

  const handleClearPress = useCallback(() => {
    setIsTextChanged(true);
    dispatch(
      trackEvent({
        event: 'search_clear',
        props: { search_category: tab === TabsEnum.FEED ? 'feed' : 'explore' },
      }),
    );
    onChangeText('');
  }, [dispatch, onChangeText, tab]);

  const handleOpenSearch = useCallback(() => {
    if (typeof setIsSearchOpen === 'function') {
      setIsSearchOpen(true);
    }
  }, [setIsSearchOpen]);

  useEffect(() => {
    if (isSearchOpen && autoFocus) {
      searchRef.current?.focus();
    }

    searchAnimatedWidth.value = isSearchOpen ? expandedPanelWidth : collapsedPanelWidth;
    searchAnimatedMarginLeft.value = isSearchOpen && !isWeb ? calcWidth(10) : 0;
  }, [
    autoFocus,
    collapsedPanelWidth,
    expandedPanelWidth,
    isSearchOpen,
    searchAnimatedMarginLeft,
    searchAnimatedWidth,
    theme,
  ]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      width: withSpring(searchAnimatedWidth.value, {
        damping: 15,
        mass: 1,
        stiffness: 150,
        overshootClamping: true,
        restSpeedThreshold: 0.001,
        restDisplacementThreshold: 0.001,
      }),
      marginLeft: withSpring(searchAnimatedMarginLeft.value, {
        damping: 15,
        mass: 1,
        stiffness: 150,
        overshootClamping: true,
        restSpeedThreshold: 0.001,
        restDisplacementThreshold: 0.001,
      }),
      borderWidth: 1,
      borderColor: theme.colors.primaryBlue,
      height: isWeb ? 40 : 50,
    }),
    [isSearchOpen],
  );

  const handleChangeText = useCallback(
    (newText) => {
      setIsTextChanged(true);
      onChangeText(newText);
    },
    [onChangeText],
  );

  return (
    <>
      <Animated.View style={[styles.container, animatedStyle]}>
        {isSearchOpen ? (
          <S.SearchWrapper>
            {hasAISuggestion && !isTextChanged && <MagicStarsBlueIcon />}
            <S.SearchInput
              // @ts-ignore
              dataSet={searchInputDataSet}
              ref={searchRef}
              placeholder={t('common.Search')}
              placeholderTextColor={theme.colors.lightGray6}
              value={value}
              onChangeText={handleChangeText}
            />
            {value ? (
              <S.CloseSearchIconWrapper
                // @ts-ignore
                dataSet={searchClearInputDataSet}
                disabled={isSearching}
                onPress={handleClearPress}
              >
                {isSearching ? (
                  <ActivityIndicator />
                ) : (
                  <CloseIcon fill={theme.colors.primaryBlue} width={calcWidth(10)} height={calcHeight(10)} />
                )}
              </S.CloseSearchIconWrapper>
            ) : null}
          </S.SearchWrapper>
        ) : (
          <S.SearchButtonContainer onPress={handleOpenSearch} />
        )}
      </Animated.View>
      {typeof onClosePanel === 'function' && (
        <S.CancleButton
          // @ts-ignore
          dataSet={searchCancelButtonDataSet}
          onPress={onClosePanel}
        >
          <S.CancleText>{t('common.cancel')}</S.CancleText>
        </S.CancleButton>
      )}
    </>
  );
};

const S = {
  Wrapper: styled.View`
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  ClearIconContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(10 / 2)}px;
    height: ${calcWidth(10)}px;
    width: ${calcWidth(10)}px;
    transform: rotate(${I18nManager.isRTL ? '180deg' : '0deg'});
  `,
  SearchButtonContainer: styled.TouchableOpacity`
    height: 100%;
    width: 100%;
    align-items: ${() => (isWeb ? 'flex-end' : 'center')};
    justify-content: center;
    padding-right: ${() => (isWeb ? `${calcWidth(10)}px` : 0)};
    background-color: ${({ theme }) => theme.colors.white};
  `,
  SearchWrapper: styled.View`
    flex-direction: row;
    align-items: center;
    padding: ${calcWidth(10)}px;
  `,
  CloseSearchIconWrapper: styled.TouchableOpacity`
    justify-content: center;
    height: 100%;
    width: ${calcWidth(35)}px;
    align-items: center;
  `,
  SearchInput: styled.TextInput(
    ({ theme }) => css`
      flex: 1;
      justify-content: center;
      padding: 0 ${calcWidth(5)}px;
      font-size: ${theme.fontSizes.s18}px;
      font-family: ${theme.fontFamilies.Arimo};
      color: ${theme.colors.black};
      text-align: ${I18nManager.isRTL && !isWeb ? 'right' : 'left'};
      opacity: 0.5;
    `,
  ),
  CancleButton: styled.TouchableOpacity`
    color: ${themes.colors.primaryBlue};
    font-family: ${themes.fontFamilies.Arimo};
    margin-left: ${calcWidth(15)}px;
  `,
  CancleText: styled.Text`
    color: ${themes.colors.primaryBlue};
  `,
};

const styles = StyleSheet.create({
  container: {
    marginLeft: calcWidth(30),
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: '100%',
  },
});

export default SearchPanel;
