import store from '../../store';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import redirectTo from '../restoreFlows/restoreBiteFlow';
import { EDeepLinkContentType, EDeepLinkMessageType, EDeepLinkSection } from '../restoreFlows/types';

interface IProps {
  biteId: number;
  section?: EDeepLinkSection;
}

const startBiteCreationFlowFromInspirationBite = ({ biteId, section = EDeepLinkSection.INTRO }: IProps) => {
  store.dispatch(
    trackEvent({
      event: 'clone_inspiration_bite',
    }),
  );

  store.dispatch(
    trackEvent({
      event: 'clone',
      props: { bite_id: biteId, inspiration_bite: true },
    }),
  );

  redirectTo[EDeepLinkContentType.BITE][EDeepLinkMessageType.CREATE](biteId, section);
};
export default startBiteCreationFlowFromInspirationBite;
