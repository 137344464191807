import React, { FC } from 'react';
import { JSX } from 'react-dom';
import styled, { css } from 'styled-components/native';
import { TouchableOpacityProps } from 'react-native';

import { calcWidth } from '../../../utils/dimensions';
import CustomText from '../CustomText';

interface IProps extends TouchableOpacityProps {
  content: string | JSX.Element;
  disabled?: boolean;
  color?: string;
  dataSet?: { [key: string]: string };
}

const ChipButton: FC<IProps> = ({ content, disabled = false, color, onPress, dataSet }) => {
  return (
    <S.Container
      disabled={disabled}
      color={color}
      onPress={onPress}
      //@ts-ignore
      dataSet={dataSet}
    >
      <S.BtnText>{content}</S.BtnText>
    </S.Container>
  );
};

const Container = styled.TouchableOpacity<{ disabled?: boolean; color?: string }>`
  padding: ${calcWidth(16)}px ${calcWidth(24)}px;
  border-radius: 29px;
  background-color: ${({ theme, color }) => color || theme.colors.primaryBlue};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const BtnText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s16}px;
    color: ${theme.colors.white};
  `,
);

const S = { Container, BtnText };

export default ChipButton;
