import { ECAptchaAction } from '../../store/api/bites-api/captcha';
import { RECAPTCHA_SITE_KEY_WEB } from './recaptcha.constants';

const tag = document.createElement('script');
tag.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY_WEB}`;
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

export const getCaptchaToken = async (action: ECAptchaAction) => {
  return new Promise((resolve) => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY_WEB, { action }).then(resolve);
    });
  });
};

export const initCaptcha = async () => {};
