import { datadogRum } from '@datadog/browser-rum';
import { ENV } from '../../utils/constants/env';
import { datadogLogs } from '@datadog/browser-logs';
import { dDApplicationId, dDClientToken, dDService, dDSite } from './datadog.constants';
import { IUser } from '../../store/auth/auth.types';

datadogLogs.init({
  clientToken: dDClientToken,
  site: dDSite,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  version: `${process.env.BITES_VERSION_NAME} ${process.env.BITES_BUILD_NUMBER}`,
});

let isInitialized = false;

let queue = [];

const logger = (action) => async (event, data) => {
  if (!isInitialized) {
    queue.push({ action, event, data });
    return;
  }
  try {
    await datadogLogs.logger[action](event, data);
  } catch (error) {}
};

export const logInfo = logger('info');
export const logError = logger('error');

export const initDataDogRUM = () => {
  datadogRum.init({
    clientToken: dDClientToken,
    applicationId: dDApplicationId,
    site: dDSite,
    service: dDService,
    env: ENV,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();

  isInitialized = true;
  queue.forEach(({ action, event, data }) => {
    logger(action)(event, data);
  });
  queue = [];
};

export const datadogSetUser = (user: IUser) => {
  try {
    if (!user) {
      datadogRum.clearUser();
      return;
    }

    datadogRum.setUser({
      ...user,
      id: `${user.id}`,
      name: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.full_name,
    });
  } catch (error) {
    console.log('datadogSetUser error', error);
  }
};
