import { calcHeight } from '../../../../../utils/dimensions';
import React from 'react';
import styled from 'styled-components';
import LottieViewWeb from 'lottie-react';

const StarsLottie = () => (
  <S.LottieViewWeb
    animationData={require('../../../../../assets/lottie/elements-panel-stars-lottie.json')}
    autoPlay
    loop
  />
);

const S = {
  LottieViewWeb: styled(LottieViewWeb)`
    width: 40%;
    height: ${calcHeight(70)}px;
    align-self: flex-end;
  `,
};

export default StarsLottie;
