import { Platform } from 'react-native';
import { EEnvironment } from './common';

export const IS_PROD = !__DEV__ && process.env.REACT_APP_ENV !== 'staging';

export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';

export const ENV: EEnvironment = (() => {
  if (IS_PROD) {
    return EEnvironment.PROD;
  }

  return EEnvironment.QA;
})();

export const AWS_URL = IS_PROD
  ? 'https://static.mybites.io'
  : 'https://herolo-bites2-dev.s3.eu-central-1.amazonaws.com';

export const IMAGE_CACHE_DIR = ''; // for web we don't need to cache
export const VIDEO_EDITOR_CACHE_DIR = '';

export const DEV_SERVER_URL = () => process.env.REACT_APP_BACKEND_URL;
