import React from 'react';
import styled from 'styled-components/native';
import { calcFontSize, calcHeight, calcWidth } from '../../../utils/dimensions';

interface IProps {
  value: string;
  placeholder: string;
  isLoading?: boolean;
  inputHeight?: number;
  onInput: (query: string) => void;
}
const SearchInput = ({ value, placeholder, isLoading, inputHeight = 48, onInput }: IProps) => {
  return (
    <S.Container>
      <S.SearchInput value={value || ''} placeholder={placeholder} height={inputHeight} onChangeText={onInput} />
      {isLoading && <S.LoadingIndicator />}
    </S.Container>
  );
};
export default SearchInput;

const S = {
  Container: styled.View`
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: ${calcWidth(8)}px ${calcWidth(12)}px ${calcWidth(8)}px;
  `,
  SearchInput: styled.TextInput<{ height: number }>`
    padding: 0 ${calcHeight(24)}px;
    width: 100%;
    height: ${({ height }) => calcHeight(height)}px;
    color: ${({ theme }) => theme.colors.darkGray15};
    line-height: ${({ height }) => calcHeight(height)}px;
    font-size: ${calcFontSize(15)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.lightGray34};
    border-radius: ${calcHeight(24)}px;
  `,
  LoadingIndicator: styled.ActivityIndicator`
    position: absolute;
    right: ${calcWidth(32)}px;
  `,
};
