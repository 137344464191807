import React, { Dispatch, SetStateAction, memo, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsTimelineExpanded } from '../../../store/videoEditor/videoEditor.slice';
import ScopedIcon from '../../../assets/icons/videoEditor/leftSidebar/scoped.svg';
import ScopedSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/scoped-selected.svg';
// import DesktopIcon from '../../../assets/icons/videoEditor/leftSidebar/desktop.svg';
// import DesktopSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/desktop-selected.svg';
import StockIcon from '../../../assets/icons/videoEditor/leftSidebar/stock.svg';
import StockSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/stock-selected.svg';
import TextIcon from '../../../assets/icons/videoEditor/leftSidebar/text.svg';
import TextSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/text-selected.svg';
import RecordIcon from '../../../assets/icons/videoEditor/leftSidebar/record.svg';
import RecordSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/record-selected.svg';
import FavoritesIcon from '../../../assets/icons/videoEditor/leftSidebar/favorites.svg';
import FavoritesSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/favorites-selected.svg';
import SearchIcon from '../../../assets/icons/videoEditor/leftSidebar/search.svg';
import SearchSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/search-selected.svg';
import BrandIcon from '../../../assets/icons/videoEditor/leftSidebar/is-brand.svg';
import BrandSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/is-brand-selected-outlined.svg';
import CaretIcon from '../../../assets/icons/videoEditor/caret.svg';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { IS_EXPANDED_TRANSITION_TS, PRIMARY_SIDEBAR_WIDTH } from '../videoEditor.constants';
import { TSidebarButton } from '../VideoEditor.web';
import styled from 'styled-components';
import defaultTheme from '../../../themes';

const LeftPrimarySidebar = ({
  secondarySidebar,
  isTimelineExpanded,
  isSidebarExpanded,
  setSecondarySidebar,
  setIsSidebarExpanded,
}: {
  secondarySidebar: TSidebarButton;
  isTimelineExpanded: boolean;
  isSidebarExpanded: boolean;
  setSecondarySidebar: Dispatch<SetStateAction<TSidebarButton>>;
  setIsSidebarExpanded: Dispatch<SetStateAction<boolean>>;
}) => {
  const isMountedRef = useIsMounted();
  const dispatch = useDispatch();

  const [hoverIcon, setHoverIcon] = useState<TSidebarButton>(null);

  const handleBitesCloudPress = useCallback(() => {
    if (secondarySidebar === 'CLOUD') {
      return;
    }
    setSecondarySidebar('CLOUD');
  }, [secondarySidebar, setSecondarySidebar]);

  const handleBitesScopedPress = useCallback(() => {
    if (secondarySidebar === 'SCOPED') {
      return;
    }
    setSecondarySidebar('SCOPED');
  }, [secondarySidebar, setSecondarySidebar]);

  const handleBrandPress = useCallback(() => {
    if (secondarySidebar === 'BRAND') {
      return;
    }
    setSecondarySidebar('BRAND');
  }, [secondarySidebar, setSecondarySidebar]);

  const handleFavoritesPress = useCallback(() => {
    if (secondarySidebar === 'FAVORITES') {
      return;
    }
    setSecondarySidebar('FAVORITES');
  }, [secondarySidebar, setSecondarySidebar]);

  const handleStockPress = useCallback(() => {
    if (secondarySidebar === 'STOCK') {
      return;
    }
    setSecondarySidebar('STOCK');
  }, [secondarySidebar, setSecondarySidebar]);
  const handleRecordPress = useCallback(() => {
    setSecondarySidebar('RECORD');
  }, [setSecondarySidebar]);
  const handleTextPress = useCallback(() => {
    setSecondarySidebar('TEXT');
  }, [setSecondarySidebar]);

  const handleCloudMouseEnter = useCallback(() => {
    setHoverIcon('CLOUD');
  }, []);
  const handleScopedMouseEnter = useCallback(() => {
    setHoverIcon('SCOPED');
  }, []);
  const handleBrandMouseEnter = useCallback(() => {
    setHoverIcon('BRAND');
  }, []);
  const handleFavoritesMouseEnter = useCallback(() => {
    setHoverIcon('FAVORITES');
  }, []);
  const handleStockMouseEnter = useCallback(() => {
    setHoverIcon('STOCK');
  }, []);
  const handleRecordMouseEnter = useCallback(() => {
    setHoverIcon('RECORD');
  }, []);
  const handleTextMouseEnter = useCallback(() => {
    setHoverIcon('TEXT');
  }, []);
  const handleIconMouseLeave = useCallback(() => {
    setHoverIcon(null);
  }, []);

  const isExpandedTimeoutRef = useRef<number>(null);
  const handleToggleIsExpanded = useCallback(() => {
    clearTimeout(isExpandedTimeoutRef.current);

    if (isTimelineExpanded) {
      dispatch(setIsTimelineExpanded(!isTimelineExpanded));

      isExpandedTimeoutRef.current = setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }

        setIsSidebarExpanded(true);
      }, IS_EXPANDED_TRANSITION_TS) as any;

      return;
    }

    setIsSidebarExpanded(false);

    isExpandedTimeoutRef.current = setTimeout(() => {
      if (!isMountedRef.current) {
        return;
      }

      dispatch(setIsTimelineExpanded(!isTimelineExpanded));
    }, IS_EXPANDED_TRANSITION_TS) as any;
  }, [dispatch, isMountedRef, isTimelineExpanded, setIsSidebarExpanded]);

  return (
    <S.PrimarySidebar>
      <S.ControlsContainer>
        <S.SidebarIcon
          isSelected={secondarySidebar === 'CLOUD'}
          onClick={handleBitesCloudPress}
          onMouseEnter={handleCloudMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'CLOUD' || hoverIcon === 'CLOUD' ? <SearchSelectedIcon /> : <SearchIcon />}
        </S.SidebarIcon>
        <S.SidebarIcon
          isSelected={secondarySidebar === 'SCOPED'}
          onClick={handleBitesScopedPress}
          onMouseEnter={handleScopedMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'SCOPED' || hoverIcon === 'SCOPED' ? <ScopedSelectedIcon /> : <ScopedIcon />}
        </S.SidebarIcon>
        {/* <S.SidebarIcon
          onClick={handleFilePress}
          onMouseEnter={handleFileMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'FILE' || hoverIcon === 'FILE' ? <DesktopSelectedIcon /> : <DesktopIcon />}
        </S.SidebarIcon> */}
        <S.SidebarIcon
          isSelected={secondarySidebar === 'BRAND'}
          onClick={handleBrandPress}
          onMouseEnter={handleBrandMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'BRAND' || hoverIcon === 'BRAND' ? (
            <BrandSelectedIcon />
          ) : (
            <BrandIcon width={22} height={22} />
          )}
        </S.SidebarIcon>
        <S.SidebarIcon
          isSelected={secondarySidebar === 'FAVORITES'}
          onClick={handleFavoritesPress}
          onMouseEnter={handleFavoritesMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'FAVORITES' || hoverIcon === 'FAVORITES' ? (
            <FavoritesSelectedIcon />
          ) : (
            <FavoritesIcon />
          )}
        </S.SidebarIcon>

        <S.Separator />

        <S.SidebarIcon
          isSelected={secondarySidebar === 'STOCK'}
          onClick={handleStockPress}
          onMouseEnter={handleStockMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'STOCK' || hoverIcon === 'STOCK' ? <StockSelectedIcon /> : <StockIcon />}
        </S.SidebarIcon>

        <S.Separator />

        <S.SidebarIcon
          isSelected={secondarySidebar === 'RECORD'}
          onClick={handleRecordPress}
          onMouseEnter={handleRecordMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {secondarySidebar === 'RECORD' || hoverIcon === 'RECORD' ? <RecordSelectedIcon /> : <RecordIcon />}
        </S.SidebarIcon>
        <S.SidebarIcon
          isSelected={secondarySidebar === 'TEXT'}
          onClick={handleTextPress}
          onMouseEnter={handleTextMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        >
          {hoverIcon === 'TEXT' ? <TextSelectedIcon /> : <TextIcon />}
        </S.SidebarIcon>
        {/* <S.SidebarIcon
              onClick={handleAddOverlay}
              onMouseEnter={handleShapeMouseEnter}
              onMouseLeave={handleIconMouseLeave}
            >
              {hoverIcon === 'SHAPE' ? <ShapeSelectedIcon /> : <ShapeIcon />}
            </S.SidebarIcon> */}
      </S.ControlsContainer>
      <S.Grow />
      <S.Expand onClick={handleToggleIsExpanded}>
        <S.CaretIconContainer isSidebarExpanded={isSidebarExpanded}>
          <CaretIcon />
        </S.CaretIconContainer>
      </S.Expand>
    </S.PrimarySidebar>
  );
};
export default memo(LeftPrimarySidebar);

export const S = {
  PrimarySidebar: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${PRIMARY_SIDEBAR_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  `,
  ControlsContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  SidebarIcon: styled.div<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 4px;
    width: 54px;
    height: 44px;
    border-radius: 8px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  BrandAssetsImg: styled.img`
    max-width: 46px;
    max-height: 36px;
  `,
  Separator: styled.div`
    width: 45px;
    height: 1px;
    background-color: ${defaultTheme.colors.lightGray45};
  `,
  Grow: styled.div`
    flex-grow: 1;
  `,
  Expand: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    height: 48px;
    border-top: 1px solid ${defaultTheme.colors.lightGray45};
    cursor: pointer;
  `,
  CaretIconContainer: styled.div<{
    isSidebarExpanded: boolean;
  }>`
    transform: ${({ isSidebarExpanded }) => (!isSidebarExpanded ? 'rotate(180deg)' : 'none')};
    transition: transform ${IS_EXPANDED_TRANSITION_TS}ms ease-in-out;
  `,
};
