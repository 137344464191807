import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/native';

import Control from './Control';

import ImageIcon from '../../../../assets/icons/picture-landscape.svg';
import YoutubeIcon from '../../../../assets/icons/youtube.svg';
// import VideoIcon from '../../../../assets/icons/video.svg';
import PhotoIcon from '../../../../assets/icons/photo.svg';
import PlusWithBorderIcon from '../../../../assets/icons/plus-with-border.svg';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { MediaController } from '../../../../types/media';
import { ControlledModal } from '../../../../types/modals';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { EVideoEditorEntryPoint } from '../../../../services/videoEditor/videoEditor';

interface IProps {
  mediaControls: MediaController;
  youtubeModalRef: { current: ControlledModal };
  photoOnly?: boolean;
  from?: string;
}

function Content({ mediaControls, photoOnly, youtubeModalRef, from }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderPhoto = () => (
    <S.ControlsWrapperSingle>
      <Control title={t('common.uploadMedia')} onPress={mediaControls.launchImageLibrary} icon={ImageIcon} />
    </S.ControlsWrapperSingle>
  );

  const controlHandler = useCallback(() => {
    youtubeModalRef.current?.open();
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'youtube', from },
      }),
    );
  }, [youtubeModalRef, dispatch, from]);

  const renderCameraGallery = () => (
    <S.ControlsWrapper>
      <Control title={t('common.photo')} onPress={mediaControls.launchImageCamera} icon={PhotoIcon} />
      <Control title={t('common.gallery')} onPress={mediaControls.launchImageLibrary} icon={ImageIcon} />
    </S.ControlsWrapper>
  );

  const renderYoutubeLibrary = () => (
    <S.ControlsWrapper>
      <Control title={t('common.uploadMedia')} onPress={mediaControls.launchLibrary} icon={PlusWithBorderIcon} />
      <Control title={t('common.youtube')} onPress={controlHandler} icon={YoutubeIcon} />
    </S.ControlsWrapper>
  );

  // const renderWithVideoNativeForWeb = () => (
  // 	<>
  // 		{/* <S.ContentTitle>{t('common.camera')}</S.ContentTitle> */}
  // 		<S.ControlsWrapper>
  // 			<Control
  // 				title={t('common.photo')}
  // 				onPress={mediaControls.launchImageCamera}
  // 				icon={PhotoIcon}
  // 			/>
  // 			<Control
  // 				title={t('common.video')}
  // 				onPress={mediaControls.launchVideoCamera}
  // 				icon={VideoIcon}
  // 			/>
  // 		</S.ControlsWrapper>
  // 		{/* <S.ContentTitle>{t('common.upload')}</S.ContentTitle> */}
  // 		<S.ControlsWrapper>
  // 			<Control
  // 				title={t('common.youtube')}
  // 				onPress={() => {
  // 					youtubeModalRef.current?.open();
  // 					gtmTrack('add_media', {
  // 						bites_user_id: user.id,
  // 						media_type: 'youtube',
  // 						origin: 'native',
  // 					});
  // 				}}
  // 				icon={YoutubeIcon}
  // 			/>
  // 			<Control
  // 				title={t('common.gallery')}
  // 				onPress={mediaControls.launchLibrary}
  // 				icon={ImageIcon}
  // 			/>
  // 		</S.ControlsWrapper>
  // 	</>
  // );

  // const renderYoutube = () => (
  // 	<S.ControlsWrapperSingle>
  // 		<Control
  // 			title={t('common.youtube')}
  // 			onPress={() => youtubeModalRef.current?.open()}
  // 			icon={YoutubeIcon}
  // 		/>
  // 	</S.ControlsWrapperSingle>
  // );

  const handleCreatePress = useCallback(() => {
    mediaControls.launchVideoEditor({ entryPoint: EVideoEditorEntryPoint.CAMERA });
  }, [mediaControls]);

  const renderYoutubeVideo = () => (
    <>
      <S.ControlsWrapper>
        <Control title={t('common.create')} onPress={handleCreatePress} icon={PlusWithBorderIcon} />
        <Control
          title={t('common.youtube')}
          onPress={() => {
            youtubeModalRef.current?.open();
            dispatch(
              trackEvent({
                event: 'add_media',
                props: { media_type: 'youtube', from },
              }),
            );
          }}
          icon={YoutubeIcon}
        />
      </S.ControlsWrapper>
    </>
  );
  // const renderVideo = () => (
  // 	<>
  // 		<S.ControlsWrapperSingle>
  // 			<Control
  // 				title={t('common.video')}
  // 				onPress={mediaControls.launchVideoEditor}
  // 				icon={VideoIcon}
  // 			/>
  // 		</S.ControlsWrapperSingle>
  // 	</>
  // );

  // if (isWeb && !photoOnly) return renderWithVideoContentForWeb();
  // if (!isWeb && !photoOnly) return renderWithVideoNativeForWeb();

  if (isWeb && photoOnly) {
    return renderPhoto();
  }
  if (isWeb && !photoOnly) {
    return renderYoutubeLibrary();
  }
  // if (!isWeb && photoOnly) return renderVideo();
  if (!isWeb && photoOnly) {
    return renderCameraGallery();
  }
  if (!isWeb && !photoOnly) {
    return renderYoutubeVideo();
  }
  return null;
}

const S = {
  ControlsWrapperSingle: styled.View`
    flex-direction: row;
    width: ${calcWidth(isWeb ? 218 : 250)}px;
    margin-top: ${calcHeight(8)}px;
    margin-bottom: ${calcHeight(5)}px;
    justify-content: center;
  `,
  ControlsWrapper: styled.View`
    flex-direction: row;
    width: ${calcWidth(isWeb ? 240 : 250)}px;
    margin-top: ${calcHeight(8)}px;
    margin-bottom: ${calcHeight(5)}px;
    justify-content: space-between;
  `,
  ContentTitle: styled.Text(
    ({ theme }) => css`
      margin-top: ${calcHeight(30)}px;
      margin-bottom: ${calcHeight(23)}px;
      font-family: ${theme.fontFamilies.GilroyMedium};
      font-size: ${theme.fontSizes.s16}px;
      color: ${theme.colors.lightGray6};
      opacity: 0.8;
      max-width: ${calcWidth(isWeb ? 218 : 250)}px;
    `,
  ),
};

export default Content;
