import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthDataMissingPayload, IInitialState } from './authForm.types';
import { EAuthFlowType, EAuthFormType } from '../../screens/auth/auth.types';
import { isWeb } from '../../utils/dimensions';

export const initialState: IInitialState = {
  currentFlow: EAuthFlowType.SIGN_IN,
  currentForm: isWeb ? EAuthFormType.INITIAL : EAuthFormType.AUTH,
  accessCode: '',
  email: '',
  password: '',
  phone: '',
  firstName: '',
  lastName: '',
  recoveryToken: null,
  isAuthDataMissing: false,
  isTermsConditionsVisible: false,
  userSelectedCountryCode: null,
  errors: {
    email: null,
    password: null,
    phone: null,
  },
};

const AUTH_FORM = 'AUTH_FORM';

const authFormSlice = createSlice({
  name: AUTH_FORM,
  initialState,
  reducers: {
    setCurrentFlow: (state, { payload }: PayloadAction<EAuthFlowType>) => {
      state.currentFlow = payload;
      state.errors = initialState.errors;
    },
    setCurrentForm: (state, { payload }: PayloadAction<EAuthFormType>) => {
      state.currentForm = payload;
      state.errors = initialState.errors;
    },
    setAccessCode: (state, { payload }: PayloadAction<string>) => {
      state.accessCode = payload;
    },
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setPassword: (state, { payload }: PayloadAction<string>) => {
      state.password = payload;
    },
    setPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    setFirstName: (state, { payload }: PayloadAction<string>) => {
      state.firstName = payload;
    },
    setLastName: (state, { payload }: PayloadAction<string>) => {
      state.lastName = payload;
    },
    setIsAuthDataMissing: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthDataMissing = payload;
    },
    setMissedAuthData: (state, { payload }: PayloadAction<IAuthDataMissingPayload>) => {
      state.currentForm = EAuthFormType.USER_INFO;
      state.isAuthDataMissing = payload.isAuthDataMissing;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phone = payload.phone;
    },
    setTermsConditionsVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isTermsConditionsVisible = payload;
    },
    setRecoveryToken: (state, { payload }: PayloadAction<string>) => {
      state.recoveryToken = payload;
    },
    setUserSelectedCountryCode: (state, { payload }: PayloadAction<string>) => {
      state.userSelectedCountryCode = payload;
    },
    resetAuthForm: () => initialState,
  },
});

export const {
  setCurrentFlow,
  setCurrentForm,
  setAccessCode,
  setEmail,
  setPassword,
  setPhone,
  setFirstName,
  setLastName,
  setIsAuthDataMissing,
  setMissedAuthData,
  setTermsConditionsVisible,
  setRecoveryToken,
  resetAuthForm,
  setUserSelectedCountryCode,
} = authFormSlice.actions;

export default authFormSlice.reducer;
