import axios from 'axios';
import { PEXELS_API_KEY } from './pexels';

// docs:
// https://www.pexels.com/api/documentation/#videos-search__parameters__orientation

interface IOptions {
  query: string;
  orientation?: 'landscape' | 'portrait' | 'square';
  size?: 'large' | 'medium' | 'small'; // large(4K), medium(Full HD) or small(HD)
  locale?:
    | 'en-US'
    | 'pt-BR'
    | 'es-ES'
    | 'ca-ES'
    | 'de-DE'
    | 'it-IT'
    | 'fr-FR'
    | 'sv-SE'
    | 'id-ID'
    | 'pl-PL'
    | 'ja-JP'
    | 'zh-TW'
    | 'zh-CN'
    | 'ko-KR'
    | 'th-TH'
    | 'nl-NL'
    | 'hu-HU'
    | 'vi-VN'
    | 'cs-CZ'
    | 'da-DK'
    | 'fi-FI'
    | 'uk-UA'
    | 'el-GR'
    | 'ro-RO'
    | 'nb-NO'
    | 'sk-SK'
    | 'tr-TR'
    | 'ru-RU';
  page?: number; // Default: 1
  resultsPerPage?: number; // Default: 15 Max: 80
}

const searchPexelsVideos = async ({ query, orientation, size, locale, page = 1, resultsPerPage = 30 }: IOptions) => {
  const { data } = await axios.get('https://api.pexels.com/videos/search', {
    params: {
      query,
      orientation,
      size,
      locale,
      page,
      per_page: resultsPerPage,
    },
    headers: {
      Authorization: PEXELS_API_KEY,
    },
  });

  return data;
};
export default searchPexelsVideos;

interface ISearchPexelsPopularVideos {
  page?: number; // Default: 1
  resultsPerPage?: number; // Default: 15 Max: 80
}
export const searchPexelsPopularVideos = async ({ page = 1, resultsPerPage = 30 }: ISearchPexelsPopularVideos = {}) => {
  const { data } = await axios.get('https://api.pexels.com/videos/popular', {
    params: {
      page,
      per_page: resultsPerPage,
    },
    headers: {
      Authorization: PEXELS_API_KEY,
    },
  });

  return data;
};
