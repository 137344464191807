import previewPlaylist, { IProps as IPlaylistProps } from '../playlist/openPreviewPlaylist';

interface IProps extends Omit<IPlaylistProps, 'playlistId'> {
  quizId: number;
}

const openPreviewQuiz = (props: IProps) => {
  const playlistProps = {
    playlistId: props.quizId,
    ...props,
  };
  previewPlaylist(playlistProps);
};
export default openPreviewQuiz;
