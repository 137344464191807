import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TBiteId, TDraftItem } from './drafts.types';

interface IInitialState {
  drafts: TDraftItem[] | null;
  draftsMap: { [key: TBiteId]: TDraftItem } | null;
}

const initialState: IInitialState = {
  drafts: null,
  draftsMap: null,
};

const drafts = 'drafts';

export const loadValues = createAction(`${drafts}/loadValues`);
export const setValue = createAction<TDraftItem[]>(`${drafts}/setValue`);
export const addToDrafts = createAction<TBiteId>(`${drafts}/addToDrafts`);
export const removeFromDrafts = createAction<TBiteId>(`${drafts}/removeFromDrafts`);
export const trackDraftEvents = createAction<{ biteIds: TBiteId[] }>(`${drafts}/trackDraftEvents`);

const draftsSlice = createSlice({
  name: drafts,
  initialState,
  reducers: {
    setDrafts: (state, { payload }: PayloadAction<TDraftItem[]>) => {
      state.drafts = payload;
      state.draftsMap = payload.reduce((memo, draftItem) => {
        memo[draftItem[0]] = draftItem;
        return memo;
      }, {});
    },
  },
});

export const { setDrafts } = draftsSlice.actions;

export default draftsSlice.reducer;
