import Toast from 'react-native-toast-message';
import store from '../../store';
import { logError } from '../../store/appActivity/appActivity.slice';
import { EToastTypes } from '../constants/toastConfig';

const handleHttpErrors = (error) => {
  store.dispatch(logError({ error }));

  if (
    (error?.response?.status === 400 && error.response.headers['x-captcha-validation'] === 'bad_token') ||
    error?.response?.status >= 500
  ) {
    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
};
export default handleHttpErrors;
