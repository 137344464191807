import React from 'react';
import { calcHeight, isWeb } from '../../utils/dimensions';
import styled from 'styled-components/native';
import { WebView } from 'react-native-webview';

interface IProps {
  disableNativeLoader?: boolean;
}

const Preloader: React.FC<IProps> = ({ disableNativeLoader = false }) => {
  if (isWeb) {
    return (
      <S.LoaderContainer>
        <object data='https://bites-bucket.s3-eu-west-1.amazonaws.com/feed_preloader.svg' />
      </S.LoaderContainer>
    );
  }

  if (!disableNativeLoader) {
    return (
      <S.WebView
        source={{ uri: 'https://bites-bucket.s3-eu-west-1.amazonaws.com/feed_preloader.svg' }}
        originWhitelist={['*']}
      />
    );
  }

  return null;
};

const S = {
  LoaderContainer: styled.View`
    justify-content: center;
    align-self: center;
    width: ${calcHeight(70)}px;
    height: ${calcHeight(70)}px;
  `,
  WebView: styled(WebView)`
    background-color: transparent;
    resize-mode: cover;
    flex: 0.25;
    margin-top: 40%;
  `,
};

export default Preloader;
