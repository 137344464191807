import React, { memo, useCallback, JSX, useState, useMemo } from 'react';
import styled from 'styled-components';
import { ActivityIndicator } from 'react-native';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import AudioIcon from '../../../assets/icons/videoEditor/audio.svg';
import PlayMiniIcon from '../../../assets/icons/videoEditor/leftSidebar/play-mini.svg';
import FavoriteIcon from '../../../assets/icons/videoEditor/leftSidebar/is-favorite.svg';
import FavoriteSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/is-favorite-selected.svg';
import BrandIcon from '../../../assets/icons/videoEditor/leftSidebar/is-brand.svg';
import BrandSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/is-brand-selected.svg';
import defaultTheme from '../../../themes';
import { getDisplayTimeFromDuration } from '../utils/getDisplayTimeFromDuration';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../store/auth/auth.selectors';
import { useCurrentUserOrg } from '../../../hooks/useCurrentUserOrg';
import { toggleIsBrandCloudAsset, toggleIsFavoriteCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import {
  cloudAssetSelector,
  toggleIsBrandByIdSelector,
  toggleIsFavoriteByIdSelector,
} from '../../../store/cloudAssets/cloudAssets.selector';
import { setOverlay } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { DO_NOT_SHOW_AGAIN_KEY } from './AddToBrandOverlay/AddToBrandOverlay.constants';

type Props = {
  cloudAssetId: ICloudAsset['id'];
  withFeaturedIcons?: boolean;
  onSelect?: (item: any) => void;
};
const CloudSearchItem = ({ cloudAssetId, withFeaturedIcons, onSelect }: Props) => {
  const cloudAsset = useSelector(cloudAssetSelector(cloudAssetId));

  if (cloudAsset.fileType === 'video') {
    return <CloudSearchVideoItem withFeaturedIcons={withFeaturedIcons} cloudAsset={cloudAsset} onSelect={onSelect} />;
  }

  if (cloudAsset.fileType === 'audio') {
    return <CloudSearchAudioItem withFeaturedIcons={withFeaturedIcons} cloudAsset={cloudAsset} onSelect={onSelect} />;
  }

  if (cloudAsset.fileType === 'image') {
    return <CloudSearchImageItem withFeaturedIcons={withFeaturedIcons} cloudAsset={cloudAsset} onSelect={onSelect} />;
  }

  if (cloudAsset.fileType === 'gif') {
    return <CloudSearchGifItem withFeaturedIcons={withFeaturedIcons} cloudAsset={cloudAsset} onSelect={onSelect} />;
  }

  return null;
};
export default memo(CloudSearchItem);

type TypeProps = {
  cloudAsset: ICloudAsset;
  withFeaturedIcons?: boolean;
  onSelect?: (item: any) => void;
};
const CloudSearchVideoItem = memo(({ cloudAsset, withFeaturedIcons, onSelect }: TypeProps) => {
  return (
    <Template
      image={
        <>
          <S.ImageContainer>
            <S.Image
              src={
                cloudAsset.previewImage?.url ||
                // old field
                // @ts-ignore
                cloudAsset.previewImageUrl
              }
            />
            <S.PlayMiniIconContainer>
              <PlayMiniIcon />
            </S.PlayMiniIconContainer>
          </S.ImageContainer>
        </>
      }
      content={
        <>
          <S.Title>{cloudAsset.fileMeta?.generatedName || cloudAsset.fileMeta?.name}</S.Title>
          <S.Description>
            {cloudAsset?.fileMeta?.generatedDescription || (getIsProcessing(cloudAsset) ? 'Processing...' : '')}
          </S.Description>
          <S.SecondaryDescription>
            {cloudAsset.fileMeta?.duration ? getDisplayTimeFromDuration(cloudAsset.fileMeta?.duration) : ''}
            {cloudAsset.fileMeta?.duration && !cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name && ' | '}
            {(!cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name) || ''}
          </S.SecondaryDescription>
        </>
      }
      cloudAsset={cloudAsset}
      withFeaturedIcons={withFeaturedIcons}
      onSelect={onSelect}
    />
  );
});

const CloudSearchAudioItem = memo(({ cloudAsset, withFeaturedIcons, onSelect }: TypeProps) => {
  return (
    <Template
      image={<AudioIcon />}
      content={
        <>
          <S.Title>{cloudAsset.fileMeta?.generatedName || cloudAsset.fileMeta?.name}</S.Title>
          <S.Description>
            {getTranscriptionCombinedText(cloudAsset)?.slice(0, 100) ||
              (getIsProcessing(cloudAsset) ? 'Processing...' : '')}
          </S.Description>
          <S.SecondaryDescription>
            {cloudAsset.fileMeta?.duration ? getDisplayTimeFromDuration(cloudAsset.fileMeta?.duration) : ''}
            {cloudAsset.fileMeta?.duration && !cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name && ' | '}
            {(!cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name) || ''}
          </S.SecondaryDescription>
        </>
      }
      cloudAsset={cloudAsset}
      withFeaturedIcons={withFeaturedIcons}
      onSelect={onSelect}
    />
  );
});

const CloudSearchImageItem = memo(({ cloudAsset, withFeaturedIcons, onSelect }: TypeProps) => {
  return (
    <Template
      image={<S.Image src={cloudAsset.storage.url} />}
      content={
        <>
          <S.Title>{cloudAsset.fileMeta?.generatedName || cloudAsset.fileMeta?.name}</S.Title>
          <S.Description>
            {(cloudAsset.fileType === 'image' && cloudAsset.fileMeta?.generatedDescription) ||
              (getIsProcessing(cloudAsset) ? 'Processing...' : '')}
          </S.Description>
          <S.SecondaryDescription>
            {(!cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name) || ''}
          </S.SecondaryDescription>
        </>
      }
      cloudAsset={cloudAsset}
      withFeaturedIcons={withFeaturedIcons}
      onSelect={onSelect}
    />
  );
});

const CloudSearchGifItem = memo(({ cloudAsset, withFeaturedIcons, onSelect }: TypeProps) => {
  return (
    <Template
      image={<S.Image src={cloudAsset.storage.url} />}
      content={
        <>
          <S.Title>{cloudAsset.fileMeta?.generatedName || cloudAsset.fileMeta?.name}</S.Title>
          <S.Description>
            {cloudAsset.fileMeta?.generatedDescription || (getIsProcessing(cloudAsset) ? 'Processing...' : '')}
          </S.Description>
          <S.SecondaryDescription>
            {(!cloudAsset.fileMeta?.generatedName && cloudAsset.fileMeta?.name) || ''}
          </S.SecondaryDescription>
        </>
      }
      cloudAsset={cloudAsset}
      withFeaturedIcons={withFeaturedIcons}
      onSelect={onSelect}
    />
  );
});

type TemplateProps = {
  image: JSX.Element;
  content: JSX.Element;
  cloudAsset: ICloudAsset;
  withFeaturedIcons: boolean;
  onSelect: (item: any) => void;
};
const Template = memo(({ image, content, cloudAsset, withFeaturedIcons = true, onSelect }: TemplateProps) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const toggleIsFavoriteState = useSelector(toggleIsFavoriteByIdSelector(cloudAsset.id));
  const toggleIsBrandState = useSelector(toggleIsBrandByIdSelector(cloudAsset.id));

  const userOrg = useCurrentUserOrg();

  const [isHover, setIsHover] = useState(false);

  const isFavorite = useMemo(
    () => !!cloudAsset.favoriteByUserIds?.includes(user.id),
    [cloudAsset.favoriteByUserIds, user.id],
  );

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handlePress = useCallback(() => {
    onSelect(cloudAsset);
  }, [cloudAsset, onSelect]);

  const toggleFavorites = useCallback(
    async (e) => {
      e.stopPropagation();

      dispatch(
        toggleIsFavoriteCloudAsset({
          cloudAssetId: cloudAsset.id,
        }),
      );
    },
    [cloudAsset.id, dispatch],
  );

  const toggleBrand = useCallback(
    async (e) => {
      e.stopPropagation();

      if (!userOrg.content_admin) {
        return;
      }

      if (!cloudAsset.isBrandAsset) {
        if (localStorage.getItem(DO_NOT_SHOW_AGAIN_KEY)) {
          dispatch(
            toggleIsBrandCloudAsset({
              cloudAssetId: cloudAsset.id,
            }),
          );
          return;
        }

        dispatch(
          setOverlay({
            type: 'ADD_TO_BRAND',
            cloudAssetId: cloudAsset.id,
          }),
        );
        return;
      }

      dispatch(
        setOverlay({
          type: 'REMOVE_FROM_BRAND',
          cloudAssetId: cloudAsset.id,
        }),
      );
    },
    [cloudAsset.id, cloudAsset.isBrandAsset, dispatch, userOrg.content_admin],
  );

  return (
    <>
      <S.Container
        isSelectable={!!onSelect}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onSelect ? handlePress : undefined}
      >
        <S.ImageContainer>{image}</S.ImageContainer>

        <S.Content>
          {content}

          {withFeaturedIcons && (
            <>
              <S.FavoriteContainer
                isVisible={isFavorite || isHover || toggleIsFavoriteState?.isLoading}
                isShifted={
                  !userOrg.content_admin ||
                  ((isFavorite || toggleIsFavoriteState?.isLoading) &&
                    !isHover &&
                    !cloudAsset.isBrandAsset &&
                    !toggleIsBrandState?.isLoading)
                }
                onClick={toggleFavorites}
              >
                {toggleIsFavoriteState?.isLoading ? (
                  <ActivityIndicator size={16} color={defaultTheme.colors.text} />
                ) : isFavorite ? (
                  <FavoriteSelectedIcon />
                ) : (
                  <FavoriteIcon />
                )}
              </S.FavoriteContainer>
              {userOrg.content_admin && (
                <S.BrandContainer
                  isVisible={
                    cloudAsset.isBrandAsset || (userOrg.content_admin && (isHover || toggleIsBrandState?.isLoading))
                  }
                  onClick={toggleBrand}
                >
                  {toggleIsBrandState?.isLoading ? (
                    <ActivityIndicator size={16} color={defaultTheme.colors.text} />
                  ) : cloudAsset.isBrandAsset ? (
                    <BrandSelectedIcon />
                  ) : (
                    <BrandIcon width={16} height={16} />
                  )}
                </S.BrandContainer>
              )}
            </>
          )}
        </S.Content>
      </S.Container>
    </>
  );
});

export const S = {
  Container: styled.div<{ isSelectable: boolean }>`
    position: relative;
    display: flex;
    gap: 12px;
    flex: 1;
    justify-content: center;
    margin: 0 8px;
    padding: 10px 4px;
    cursor: ${(props) => (props.isSelectable ? 'pointer' : 'default')};
    overflow: hidden;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
  `,
  Image: styled.img`
    width: 48px;
    height: 48px;
    border-radius: 11px;
    object-fit: cover;
  `,
  PlayMiniIconContainer: styled.div`
    position: absolute;
    bottom: 0px;
    right: 4px;
  `,
  Content: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    white-space: nowrap;
    overflow: hidden;
  `,
  Title: styled.div`
    font-size: 14px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    color: ${defaultTheme.colors.text};
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Description: styled.div`
    font-size: 14px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    color: ${defaultTheme.fontFamilies.gray19};
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  SecondaryDescription: styled.div`
    font-size: 13px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    color: ${defaultTheme.fontFamilies.gray19};
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  FavoriteContainer: styled.div<{ isVisible: boolean; isShifted: boolean }>`
    position: absolute;
    top: 4px;
    right: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transform: ${(props) => (props.isShifted ? 'translateX(33px)' : 'translateX(0)')};
    transition: opacity 0.2s, transform 0.2s;
  `,
  BrandContainer: styled.div<{ isVisible: boolean }>`
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 0.2s;
  `,
};

const getTranscriptionCombinedText = (cloudAsset: ICloudAsset) => {
  const transcription = cloudAsset?.fileMeta?.transcription;

  if (!transcription) {
    return '';
  }

  let text = '';

  for (let i = 0; i < transcription.texts.length; i++) {
    const nextText = transcription.texts[i];

    const t = transcription.texts[i];
    text += t.text;

    if (nextText?.type === 'pronunciation') {
      text += ' ';
    }
  }

  return text;
};

const getIsProcessing = (cloudAsset: ICloudAsset) => {
  return Date.now() - new Date(cloudAsset.createDate).getTime() < 1000 * 60 * 30;
};
