import React, { useCallback, useMemo } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/auth/auth.selectors';
import { favoriteResultsSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setFavoriteResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const FavoritesSidebar = () => {
  const { user } = useSelector(authSelector);
  const favoriteResults = useSelector(favoriteResultsSelector);

  const additionalRequestFilters = useMemo(
    () => ({
      favoriteByUserId: user.id,
    }),
    [user],
  );

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileTypes={FILE_TYPES} />;
  }, []);

  return (
    <>
      <SidebarTitle title='Libraries' />
      <Cloud
        cloudResults={favoriteResults}
        additionalRequestFilters={additionalRequestFilters}
        renderFilterControls={renderFilterControls}
        setCloudResults={setFavoriteResults}
      />
    </>
  );
};
export default FavoritesSidebar;
