import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import { IUserOrganizations } from '../../../../store/auth/auth.types';
import { showUpdateUserOrganizationNameForm, showLeaveUserOrganization } from '../../../../store/org/org.slice';
import { IPanelChildInjectedProps } from '../../../../store/bottomPanel/bottomPanel';
import { calcHeight } from '../../../../utils/dimensions';

interface IProps extends IPanelChildInjectedProps {
  organization: IUserOrganizations;
}

const OrganizationMenu: React.FC<IProps> = (props) => {
  const { organization, onClosePanel } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showRename = () => {
    onClosePanel(() => {
      dispatch(showUpdateUserOrganizationNameForm());
    });
  };

  const showLeave = () => {
    onClosePanel(() => {
      dispatch(showLeaveUserOrganization());
    });
  };

  return (
    <>
      {organization.is_owner ? (
        <S.Button onPress={showRename}>
          <S.ButtonText>{t('organizationMenu.rename')}</S.ButtonText>
        </S.Button>
      ) : (
        <S.Button onPress={showLeave}>
          <S.ButtonText isDanger>{t('organizationMenu.leave')}</S.ButtonText>
        </S.Button>
      )}
    </>
  );
};

const Button = styled.TouchableOpacity`
  width: 100%;
  paddingvertical: ${calcHeight(28)}px;
`;

const ButtonText = styled.Text<{ isDanger?: boolean }>`
  color: ${({ theme, isDanger }) => (isDanger ? theme.colors.red1 : theme.colors.gray18)};
  font-size: ${({ theme }) => theme.fontSizes.s16}px;
  font-weight: 500;
  text-align: center;
`;

const S = {
  Button,
  ButtonText,
};

export default memo(OrganizationMenu);
