import { useSelector } from 'react-redux';
import { AWS_URL } from '../utils/constants/env';
import { biteItemSelector } from '../store/bite/bite.selectors';

export const useBiteCoverUrl = (biteId: number) => {
  const bite = useSelector(biteItemSelector(biteId));

  if (!biteId || !bite) {
    return null;
  }

  const url = bite?.linked_cover_url || (bite?.branding?.logo_url ? `${AWS_URL}/${bite.branding.logo_url}` : null);

  return url;
};
