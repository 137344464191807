import React, { memo, useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OverviewAttributes from './OverviewAttributes';
import OverviewList from './OverviewList';
import { IStackNavigation } from '../../../navigation/types';
import useHasOrganizationAttributes from '../hooks/useHasOrganizationAttributes';
import { useDispatch, useSelector } from 'react-redux';
import { currentListSelector } from '../../../store/analytics/analytics.selector';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { OVERVIEW_ATTRIBUTES_SCREEN, OVERVIEW_LIST_SCREEN, STATS_OVERVIEW_SCREEN } from '../constants';
import { useIsFocused } from '@react-navigation/native';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';

const Stack = createStackNavigator();

const defaultScreenOptions = {
  headerShown: false,
  cardStyle: {
    backgroundColor: 'white',
  },
  animationEnabled: false,
};
interface IProps extends IStackNavigation {}

const Overview: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { hasOrganizationAttributes } = useHasOrganizationAttributes();

  const currentList = useSelector(currentListSelector);
  const initialRouteName =
    currentList === ECurrentList.USERS || !hasOrganizationAttributes
      ? OVERVIEW_LIST_SCREEN
      : OVERVIEW_ATTRIBUTES_SCREEN;

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: STATS_OVERVIEW_SCREEN },
      }),
    );
  }, [dispatch, isFocused]);

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {hasOrganizationAttributes && (
        <Stack.Screen name={OVERVIEW_ATTRIBUTES_SCREEN} component={OverviewAttributes} options={defaultScreenOptions} />
      )}
      <Stack.Screen name={OVERVIEW_LIST_SCREEN} component={OverviewList} options={defaultScreenOptions} />
    </Stack.Navigator>
  );
};

export default memo(Overview);
