const calculatePercentages = (data: Record<string, number>) => {
  // Step 1: Calculate total number of actions
  let total = 0;
  for (let id in data) {
    total += data[id];
  }

  // Step 2: Calculate raw percentages
  const rawPercentages: Record<string, number> = {};
  for (let id in data) {
    rawPercentages[id] = (data[id] / total) * 100;
  }

  // Step 3: Calculate rounded percentages and store remainders
  const roundedPercentages: Record<string, number> = {};
  const remainders: Record<string, number> = {};
  let roundedSum = 0;
  for (let id in rawPercentages) {
    roundedPercentages[id] = Math.floor(rawPercentages[id]);
    remainders[id] = rawPercentages[id] - roundedPercentages[id];
    roundedSum += roundedPercentages[id];
  }

  // Step 4: Distribute error among the highest remainders
  const error = 100 - roundedSum;
  const sortedRemainders = Object.entries(remainders).sort((a, b) => b[1] - a[1]);
  for (let i = 0; i < error; i++) {
    let id = sortedRemainders[i][0];
    roundedPercentages[id]++;
  }

  return { roundedPercentages, total };
};

export default calculatePercentages;
