import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '../../components/shared/Switch';
import { calcHeight } from '../../utils/dimensions';
import SettingsTooltip from '../../components/shared/SettingsTooltip/SettingsTooltip';
import { setIsMultiChoice } from '../../store/biteQuestion/biteQuestion.actions';
import { biteQuestionSelector } from '../../store/biteQuestion/biteQuestion.selectors';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  renderLeftButton: () => React.ReactNode;
}

const QuestionSettingsModal: React.FC<IProps> = ({ isVisible, onClose, renderLeftButton }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isMultiChoice } = useSelector(biteQuestionSelector);

  const [isMultiChoiceSelected, setIsMultiChoiceSelected] = useState(isMultiChoice);

  const handleAllowMultipleAnswers = useCallback(() => {
    setIsMultiChoiceSelected((prev) => !prev);
  }, []);

  const handleOnCloseSaving = useCallback(() => {
    dispatch(setIsMultiChoice(isMultiChoiceSelected));
    onClose();
  }, [dispatch, isMultiChoiceSelected, onClose]);

  const handleOnCloseWithoutSaving = useCallback(() => {
    setIsMultiChoiceSelected(isMultiChoice);
    onClose();
  }, [isMultiChoice, onClose]);

  const renderModalContent = useCallback(() => {
    return (
      <>
        <S.SettingsContainer>
          <S.SwitchContainer>
            <S.SwitchLabel>{t('biteCreated.settings.multipleAllowed')}</S.SwitchLabel>
            <Switch isOn={isMultiChoiceSelected} onToggle={handleAllowMultipleAnswers} />
          </S.SwitchContainer>
        </S.SettingsContainer>
        <S.ButtonContainer>
          {renderLeftButton ? renderLeftButton() : <S.EmptyView />}
          <TouchableOpacity onPress={handleOnCloseSaving}>
            <S.ButtonText>{t('biteCreated.settings.done')}</S.ButtonText>
          </TouchableOpacity>
        </S.ButtonContainer>
      </>
    );
  }, [handleAllowMultipleAnswers, handleOnCloseSaving, isMultiChoiceSelected, renderLeftButton, t]);

  return (
    <SettingsTooltip onClose={handleOnCloseWithoutSaving} renderContent={renderModalContent} isVisible={isVisible} />
  );
};

const S = {
  EmptyView: styled.View``,
  ButtonContainer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
  `,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SettingsContainer: styled.View``,
  SwitchLabel: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SwitchLabelReplace: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.primaryBlue};
    margin-top: ${calcHeight(12)}px;
    margin-bottom: ${calcHeight(12)}px;
  `,
  SwitchContainer: styled.View`
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,
};

export default memo(QuestionSettingsModal);
