import React, { FC } from 'react';
import styled from 'styled-components/native';
import { calcHeight } from '../../utils/dimensions';
import { StyleProp, ViewStyle } from 'react-native';

interface IProps {
  style?: StyleProp<ViewStyle>;
}
const Divider: FC<IProps> = ({ style }) => {
  return <S.Line style={style} />;
};

const Line = styled.View`
  height: ${calcHeight(1)}px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const S = { Line };

export default Divider;
