import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import LinearGradient from './LinearGradient';
import { ViewStyle } from 'react-native';

interface IProps {
  withGradient: boolean;
  style?: ViewStyle;
  children: React.ReactNode;
}

const TooltipContentContainer: React.FC<IProps> = ({ withGradient, style, children }) => {
  const theme = useTheme();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colors = useMemo(() => [theme.colors.primaryBlue, theme.colors.backgroundPurple], []);
  if (withGradient) {
    return (
      <S.LinearGradient style={style} colors={colors}>
        {children}
      </S.LinearGradient>
    );
  }
  return <S.Content>{children}</S.Content>;
};

const S = {
  Content: styled.View`
    border-radius: 4px;
    align-items: center;
    background: ${({ theme }) => theme.colors.primaryBlue};
    padding: ${calcHeight(10)}px ${calcWidth(20)}px;
  `,
  LinearGradient: styled(LinearGradient)`
    padding: ${calcHeight(10)}px ${calcWidth(20)}px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
  `,
};

export default TooltipContentContainer;
