import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { SvgProps } from 'react-native-svg';
import { calcHeight, verticalScale, isWeb } from '../../utils/dimensions';
import { CustomText } from '../shared';
import { editScreenButtonDataSet } from './EditScreen.constants';

export type EditScreenButton = {
  icon: FC<SvgProps>;
  title: string;
  onPress: () => void;
};
interface IProps {
  button: EditScreenButton;
}

const EditScreenButton: FC<IProps> = ({ button }) => {
  const { icon: BtnIcon, title, onPress } = button;
  const theme = useTheme();
  return (
    <S.Container>
      <S.IconContainer
        onPress={onPress}
        //@ts-ignore
        dataSet={editScreenButtonDataSet}
      >
        <BtnIcon
          height={isWeb ? 24 : verticalScale(24)}
          width={isWeb ? 24 : verticalScale(24)}
          fill={theme.colors.primaryBlue}
        />
      </S.IconContainer>
      <S.BtnTitle>{title}</S.BtnTitle>
    </S.Container>
  );
};

const Container = styled.View`
  align-items: center;
`;

const IconContainer = styled.TouchableOpacity`
  height: ${() => (isWeb ? '60px' : `${calcHeight(60)}px`)};
  width: ${() => (isWeb ? '60px' : 'auto')};
  border-radius: ${() => (isWeb ? '30px' : `${calcHeight(60) / 2}px`)};
  aspect-ratio: 1;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.colors.lightGray1};
  border-width: 1px;
`;

const BtnTitle = styled(CustomText)(
  ({ theme }) => css`
    margin-top: ${() => (isWeb ? '10px' : `${calcHeight(10)}px`)};
    font-size: ${theme.fontSizes.s14}px;
    font-family: ${theme.fontFamilies.GilroyMedium};
    color: ${theme.colors.primaryBlue};
    text-align: center;
  `,
);

const S = {
  Container,
  IconContainer,
  BtnTitle,
};

export default EditScreenButton;
