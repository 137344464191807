import { useCallback, useState } from 'react';
import VersionCheck from 'react-native-version-check';
import semver from 'semver';
import { EEnvironment } from '../utils/constants/common';
import { ENV } from '../utils/constants/env';
import { isWeb } from '../utils/dimensions';

interface IResult {
  handleCheckVersion: (withLoading?: boolean) => Promise<void>;
  isAppOutdated: boolean;
  isInProgress: boolean;
  appStoreUrl: string;
}

const useVersionCheck = (): IResult => {
  const [isAppOutdated, setIsAppOutdated] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [appStoreUrl, setAppStoreUrl] = useState<string>('');

  const handleCheckVersion = useCallback(async (isWithLoading: boolean = false) => {
    if (isWeb || ENV === EEnvironment.QA) {
      return;
    }

    try {
      if (isWithLoading) {
        setIsInProgress(true);
      }

      const { storeUrl, latestVersion, currentVersion } = await VersionCheck.needUpdate({
        appID: '1523685054', // IOS APP ID
      });

      if (
        semver.valid(latestVersion) !== null &&
        Number(semver.major(latestVersion)) > Number(semver.major(currentVersion))
      ) {
        setAppStoreUrl(storeUrl);
        setIsAppOutdated(true);
      }

      setIsInProgress(false);
    } catch (err) {
      setIsInProgress(false);
    }
  }, []);

  return {
    handleCheckVersion,
    isAppOutdated,
    isInProgress,
    appStoreUrl,
  };
};

export default useVersionCheck;
