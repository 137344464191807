import React, { useCallback } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../themes';
import { startNewVideo } from '../../store/videoEditor/videoEditor.slice';
import { useDispatch } from 'react-redux';
import BestLabelIcon from '../../assets/icons/videoEditor/best-label.svg';

export const VideoResolutionSelector = () => {
  const dispatch = useDispatch();

  const handleClickPortrait = useCallback(() => {
    dispatch(
      startNewVideo({
        resolution: { width: 720, height: 1280 },
      }),
    );
  }, [dispatch]);

  const handleClickLandscape = useCallback(() => {
    dispatch(
      startNewVideo({
        resolution: { width: 1280, height: 720 },
      }),
    );
  }, [dispatch]);

  return (
    <S.Container>
      <S.BannerContainer>
        <S.Title>Select Video orientation</S.Title>
        <S.ButtonsContainer>
          <S.Side>
            <S.ButtonContainer>
              <S.PortraitButton onClick={handleClickPortrait}>9:16</S.PortraitButton>
              <S.BestLabel>
                <BestLabelIcon />
              </S.BestLabel>
            </S.ButtonContainer>
            <S.ButtonTitle>Portrait Mode</S.ButtonTitle>
            <S.ButtonDescription>Recommended for story-like and newly created media</S.ButtonDescription>
          </S.Side>
          <S.Side>
            <S.ButtonContainer>
              <S.LandscapeButton onClick={handleClickLandscape}>16:9</S.LandscapeButton>
            </S.ButtonContainer>
            <S.ButtonTitle>Landscape Mode</S.ButtonTitle>
            <S.ButtonDescription>Best for presentation and existing landscape media</S.ButtonDescription>
          </S.Side>
        </S.ButtonsContainer>
      </S.BannerContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  BannerContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    width: 740px;
    border-radius: 16px;
    box-shadow: 0px 9.75px 34.68px 0px #6a697d26;
  `,
  Title: styled.div`
    margin-bottom: 74px;
    font-size: 28px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ButtonsContainer: styled.div`
    display: flex;
    gap: 72px;
  `,
  Side: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    width: 222px;
  `,
  ButtonContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 222px;
    cursor: pointer;
  `,
  BestLabel: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(12px, -50%);
  `,
  PortraitButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 134px;
    height: 222px;
    color: ${defaultTheme.colors.primaryBlue};
    font-size: 24px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border: 1px solid ${defaultTheme.colors.primaryBlue};
    border-radius: 16px;
    background-color: ${defaultTheme.colors.lightGray44};
    box-shadow: 0px 9px 32px 0px #6a697d26;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  `,
  LandscapeButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 222px;
    height: 134px;
    color: ${defaultTheme.colors.primaryBlue};
    font-size: 24px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border: 1px solid ${defaultTheme.colors.primaryBlue};
    border-radius: 16px;
    background-color: ${defaultTheme.colors.lightGray44};
    box-shadow: 0px 9px 32px 0px #6a697d26;
    transition: box-shadow 0.2s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  `,
  ButtonTitle: styled.div`
    margin-top: 36px;
    color: ${defaultTheme.colors.text};
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: center;
  `,
  ButtonDescription: styled.div`
    color: ${defaultTheme.colors.lightGray40};
    font-size: 15px;
    font-weight: 400;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-align: center;
  `,
};
