import React from 'react';
import { FlatList, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import CheckBoxOption from './CheckBoxOption';
import { calcHeight } from '../../../../utils/dimensions';

export interface IOption {
  name: string;
  id: number;
  isSelect: boolean;
  is_already_shared?: boolean;
}
interface IProps {
  listOptions: Array<IOption>;
  onToggleCheckBox: (id: number, value: boolean) => void;
}

const OptionsList: React.FC<IProps> = (props) => {
  const { listOptions, onToggleCheckBox } = props;

  return (
    <S.Container>
      <ScrollView>
        <FlatList
          bounces={false}
          data={listOptions}
          renderItem={({ item }) => (
            <CheckBoxOption option={item} onValueChange={(id: number, value: boolean) => onToggleCheckBox(id, value)} />
          )}
          keyExtractor={(item) => item.id.toString()}
        />
      </ScrollView>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    padding: 5px 0;
    height: ${calcHeight(260)}px;
  `,
};

export default OptionsList;
