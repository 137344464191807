import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import React from 'react';
import styled from 'styled-components';
import LottieViewWeb from 'lottie-react';

const LogoLottie = () => (
  <S.LottieViewWeb
    animationData={require('../../../../../assets/lottie/elements-panel-logo-lottie.json')}
    autoPlay
    loop
  />
);

const S = {
  LottieViewWeb: styled(LottieViewWeb)`
    width: ${calcWidth(27)}px;
    height: ${calcHeight(35)}px;
  `,
};

export default LogoLottie;
