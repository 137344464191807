import AsyncStorage from '@react-native-community/async-storage';

export const getAvaitingEnhancements = async () => {
  let avaitingEnhancements = {};
  try {
    const avaitingEnhancementsStr = await AsyncStorage.getItem('avaiting-intro-enhancements');
    if (avaitingEnhancementsStr) {
      avaitingEnhancements = JSON.parse(avaitingEnhancementsStr);
    }
  } catch (e) {}
  return avaitingEnhancements;
};

export const setAvaitingEnhancements = async (newAvaitingEnhancements) => {
  const avaitingEnhancementsStr = JSON.stringify(newAvaitingEnhancements);
  await AsyncStorage.setItem('avaiting-intro-enhancements', avaitingEnhancementsStr);
};
