import shortid from 'shortid';
import store from '../../store';
import BitesApi from '../../store/api/bites-api';
import { log } from '../../store/appActivity/appActivity.slice';
import withRetry, { IWithRetryProps } from '../withRetry';
import { IPrepareS3SinglePartUploadDetails, IPrepareS3UploadDetailsResult } from './uploadToS3.types';
import { isWeb } from '../dimensions';
import last from 'lodash/last';

const prepareS3SinglePartUploadDetails = ({
  file,
  uri,
  processId,
  orgId,
  endpoint,
}: IPrepareS3SinglePartUploadDetails): Promise<IPrepareS3UploadDetailsResult> => {
  return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
    const logContext = {
      processId,
      data: {
        attempt,
        isLastAttempt,
        endpoint,
      },
    };

    const extension = last(((isWeb ? file.name : uri) || '').split('.'));
    const fileName = `${shortid.generate()}.${extension}`;
    const name = `${shortid.generate()}.${extension}`;

    const getS3UploadUrlPayload = {
      filename: fileName,
      name,
      description: shortid.generate(),
      organization: orgId,
    };

    setErrorContext({
      ...logContext,
      action: 'prepareS3SinglePartUploadDetails: request',
      data: {
        ...logContext.data,
        getS3UploadUrlPayload,
      },
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3SinglePartUploadDetails: request',
        data: {
          ...logContext.data,
          getS3UploadUrlPayload,
        },
      }),
    );

    const { data: s3UploadDetails } = await BitesApi.post(endpoint, getS3UploadUrlPayload);

    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3SinglePartUploadDetails: result',
        data: {
          ...logContext.data,
          s3UploadDetails,
        },
      }),
    );

    return { fileName, extension, s3UploadDetails };
  });
};
export default prepareS3SinglePartUploadDetails;
