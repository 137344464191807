import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';

const openPreviewQuizDashboard = (quizId: number, section = 'overview') => {
  navigationRef.current.navigate(Routes.PreviewStack.StackName, {
    screen: Routes.PreviewStack.Dashboard,
    params: {
      path: `/quizzes/${quizId}/${section}`,
    },
  });
};
export default openPreviewQuizDashboard;
