import Types, { IInitialState } from './bite.types';
import AuthTypes from '../auth/auth.types';
import CreateBiteTypes from '../createBite/createBite.types';
import { IBiteItem } from '../../types/bite';

const initialState: IInitialState = {
  hasBites: false,
  bitesMap: {},
  bitesLoadingStateMap: {},
  selectedBite: null as IBiteItem | null,
  selectedBiteIntroVideo: {
    video: null,
    isLoading: false,
    error: null,
  },
  isLoading: false,
  bitesInProgress: {},
};

const biteReducer = (state = initialState, action: any): typeof initialState => {
  switch (action.type) {
    case Types.FETCH_FULL_BITES:
      return (() => {
        const newBitesLoadingStateMap = {
          ...state.bitesLoadingStateMap,
        };

        action.payload.forEach((biteId) => {
          newBitesLoadingStateMap[biteId] = { isLoading: true, error: null };
        });

        return {
          ...state,
          bitesLoadingStateMap: newBitesLoadingStateMap,
        };
      })();

    case Types.SET_BITES:
      return (() => {
        const newBitesMap = {
          ...state.bitesMap,
        };

        action.payload.forEach((bite) => {
          newBitesMap[bite.id] = { ...(newBitesMap[bite.id] || {}), ...bite };
        });
        return {
          ...state,
          bitesMap: newBitesMap,
        };
      })();

    case Types.UPDATE_FEED_BITE:
      return (() => {
        const { biteId, bite } = action.payload;

        if (!state.bitesMap[biteId]) {
          return state;
        }

        const newBitesMap = {
          ...state.bitesMap,
        };

        newBitesMap[biteId] = {
          ...newBitesMap[biteId],
          ...bite,
        };

        return {
          ...state,
          bitesMap: newBitesMap,
        };
      })();

    case Types.UPDATE_BITE_SECTION:
      return (() => {
        const { biteId, section } = action.payload;

        if (!state.bitesMap[biteId]) {
          return state;
        }

        const newBitesMap = {
          ...state.bitesMap,
        };

        newBitesMap[biteId] = {
          ...newBitesMap[biteId],
          bite_sections: [...newBitesMap[biteId].bite_sections.filter((item) => item.type !== section.type), section],
        };

        return {
          ...state,
          bitesMap: newBitesMap,
        };
      })();

    case Types.SET_BITES_LOADING_STATE:
      return (() => {
        const newBitesLoadingStateMap = {
          ...state.bitesLoadingStateMap,
        };

        action.payload.forEach(({ biteId, isLoading, error }) => {
          if (error || isLoading) {
            newBitesLoadingStateMap[biteId] = {
              isLoading,
              error,
            };
          } else {
            delete newBitesLoadingStateMap[biteId];
          }
        });

        return {
          ...state,
          bitesLoadingStateMap: newBitesLoadingStateMap,
        };
      })();

    case Types.SET_BITE_IN_PROGRESS:
      return (() => {
        const { itemId: biteId, task, status, taskId, errorCodes } = action.payload;

        const biteInProgress = {
          ...(state.bitesInProgress[biteId] || {}),
          [task]: {
            taskId,
            status,
            errorCodes,
          },
        };

        if (!status) {
          delete biteInProgress[task];

          if (Object.keys(biteInProgress).length === 0) {
            const bitesInProgress = { ...state.bitesInProgress };
            delete bitesInProgress[biteId];
            return {
              ...state,
              bitesInProgress,
            };
          }
        }

        return {
          ...state,
          bitesInProgress: {
            ...state.bitesInProgress,
            [biteId]: biteInProgress,
          },
        };
      })();

    case Types.SET_HAS_BITES:
      return {
        ...state,
        hasBites: action.payload,
      };

    case Types.SET_BITE_TO_EDIT:
      const { selectedBite } = action.payload;
      return {
        ...state,
        selectedBite,
      };

    case Types.SET_SELECTED_BITE_INTRO_VIDEO:
      return {
        ...state,
        selectedBiteIntroVideo: action.payload,
      };

    case Types.SET_CREATED_BITE_INTRO_SECTION:
      const updatedBiteSections: IBiteItem['bite_sections'] = [
        ...(state.selectedBite?.bite_sections
          ? state.selectedBite.bite_sections.filter((section) => section.type !== 'intro')
          : []),
        action.payload,
      ];

      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          bite_sections: updatedBiteSections,
        },
      };

    case Types.SET_BITE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case Types.SET_SELECTED_BITE_COVER:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          cover: null,
          cover_url: null,
          linked_cover_url: action.payload,
        },
      };

    case Types.CLEAN_EDIT_AND_CREATE_BITE_STATE:
      return {
        ...state,
        selectedBite: null,
      };

    case Types.SET_SELECTED_BITE_NAME:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          subject: action.payload,
        },
      };

    case Types.DELETE_BITE:
      return (() => {
        const newBitesMap = { ...state.bitesMap };
        delete newBitesMap[action.payload];
        return {
          ...state,
          bitesMap: newBitesMap,
        };
      })();

    case Types.UPDATE_SELECTED_BITE:
      return {
        ...state,
        selectedBite: {
          ...state.selectedBite,
          ...action.payload,
        },
      };

    case CreateBiteTypes.SET_INTRO_ENHANCEMENTS:
      return (() => {
        const biteId = action.payload.biteId;
        const enhancements = action.payload.enhancements;
        const subtitles = action.payload.subtitles;

        const bite = state.bitesMap[biteId];

        if (!biteId || !bite) {
          return state;
        }

        const extend = bite.extend || {
          enhancements: [],
          subtitles: [],
        };

        // merge enhancements
        // prefer media from the action payload
        const introEnhancements = [];
        const updateEnhancementsMap = {};

        enhancements?.forEach((media) => {
          updateEnhancementsMap[media.enhance_type] = media;
          introEnhancements.push(media);
        });

        extend.enhancements?.map((media) => {
          if (!updateEnhancementsMap[media.enhance_type]) {
            introEnhancements.push(media);
          }
        });

        return {
          ...state,
          bitesMap: {
            ...state.bitesMap,
            [biteId]: {
              ...bite,
              extend: {
                ...extend,
                enhancements: introEnhancements,
                subtitles: subtitles || extend.subtitles,
              },
            },
          },
        };
      })();

    case AuthTypes.SWITCH_ACTIVE_ORGANIZATION:
      return initialState;

    case AuthTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default biteReducer;
