import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ONBOARDING_QUESTIONNAIRE_ID } from '../../../store/questionnaire/questionnaire.constants';
import {
  questionnaireSelector,
  userQuestionnaireByQuestionnaireIdSelector,
} from '../../../store/questionnaire/questionnaire.selectors';
import { saveUserQuestionnaire, setUserQuestionnaire } from '../../../store/questionnaire/questionnaire.slice';
import { IUserQuestionnaire } from '../../../store/questionnaire/questionnaire.types';
import Questionnaire from '../../shared/Questionnaire/Questionnaire';
import Modal from '../ModalController';
import { IModalComponentProps } from './AppModals';
import LinearGradient from '../../shared/LinearGradient';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth, isWeb } from '../../../utils/dimensions';
import BitesIcon from '../../../assets/icons/bites-icon-with-stars.svg';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { StyleProp, ViewStyle } from 'react-native';

const linearGradientStyle = { flex: 1 };

interface IProps extends IModalComponentProps {}

const OnboardingQuestionnaire: React.FC<IProps> = ({ isVisible, onClose, ...modalProps }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const questionnaire = useSelector(questionnaireSelector(ONBOARDING_QUESTIONNAIRE_ID));
  const userQuestionnaireStore = useSelector(userQuestionnaireByQuestionnaireIdSelector(ONBOARDING_QUESTIONNAIRE_ID));

  const userQuestionnaire = useMemo(() => {
    return (
      userQuestionnaireStore || {
        questionnaireId: ONBOARDING_QUESTIONNAIRE_ID,
        answers: {},
      }
    );
  }, [userQuestionnaireStore]);

  const handleQuestionAnswer = useCallback(
    ({ answers }) => {
      const newUserQuestionnaire: IUserQuestionnaire = {
        ...userQuestionnaire,
        answers,
      };
      dispatch(setUserQuestionnaire(newUserQuestionnaire));
      dispatch(saveUserQuestionnaire(ONBOARDING_QUESTIONNAIRE_ID));
      dispatch(
        trackEvent({
          event: 'questionnaire_anwser_question',
          props: {
            object_id: ONBOARDING_QUESTIONNAIRE_ID,
          },
        }),
      );
    },
    [dispatch, userQuestionnaire],
  );

  const handleFinish = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'questionnaire_finish',
        props: {
          object_id: ONBOARDING_QUESTIONNAIRE_ID,
        },
      }),
    );

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, onClose]);

  const renderHeaderIcon = useCallback(() => {
    const width = isWeb ? calcWidth(300) : calcWidth(100);
    const height = isWeb ? calcWidth(180) : calcWidth(50);
    const style: StyleProp<ViewStyle> = isWeb ? { alignSelf: 'center' } : { flex: 1 };

    return <BitesIcon width={width} height={height} style={style} />;
  }, []);

  const customBackdrop = useMemo(() => {
    if (isWeb) {
      return <LinearGradient colors={theme.colors.questionnaireGradient} style={linearGradientStyle} />;
    }

    return <S.WhiteBackdrop />;
  }, [theme.colors.questionnaireGradient]);

  return (
    <Modal
      isVisible={isVisible}
      backdropOpacity={1}
      customBackdrop={customBackdrop}
      animationInTiming={1}
      {...modalProps}
    >
      {isWeb && renderHeaderIcon()}
      <Questionnaire
        questionnaire={questionnaire}
        answers={userQuestionnaire.answers}
        renderHeaderIcon={!isWeb && renderHeaderIcon}
        onQuestionAnswer={handleQuestionAnswer}
        onFinish={handleFinish}
      />
    </Modal>
  );
};

const S = {
  WhiteBackdrop: styled.View`
    background-color: ${({ theme }) => theme.colors.white};
    flex: 1;
  `,
};

export default OnboardingQuestionnaire;
