import { RootState } from '../index';
import { TDisplayMode } from './attributes.types';
import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';

export const organizationAttributesLoadingSelector = (state: RootState) =>
  state.attributes.organizationAttributes.isLoading;
export const overviewAttributesAbsoluteMapSelector = (state: RootState) =>
  state.attributes.overviewAttributesMapById.absolute;
export const overviewAttributesPercentageMapSelector = (state: RootState) =>
  state.attributes.overviewAttributesMapById.percentage;
export const overviewAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.attributes.overviewAttributesMapById[displayMode][attributeId]?.next;

export const viewsAttributesAbsoluteMapSelector = (state: RootState) =>
  state.attributes.viewsAttributesMapById.absolute;
export const viewsAttributesPercentageMapSelector = (state: RootState) =>
  state.attributes.viewsAttributesMapById.percentage;

export const viewsAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.attributes.viewsAttributesMapById[displayMode][attributeId]?.next;

export const answersAttributesAbsoluteMapSelector = (state: RootState) =>
  state.attributes.answersAttributesMapById.absolute;
export const answersAttributesPercentageMapSelector = (state: RootState) =>
  state.attributes.answersAttributesMapById.percentage;

export const answersAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.attributes.answersAttributesMapById[displayMode][attributeId]?.next;

export const commentsAttributesAbsoluteMapSelector = (state: RootState) =>
  state.attributes.commentsAttributesMapById.absolute;
export const commentsAttributesPercentageMapSelector = (state: RootState) =>
  state.attributes.commentsAttributesMapById.percentage;

export const commentsAttributeNextSelector = (attributeId: number, displayMode: TDisplayMode) => (state: RootState) =>
  state.attributes.commentsAttributesMapById[displayMode][attributeId]?.next;

export const organizationAttributesSelector = (state: RootState) => state.attributes.organizationAttributes.data;

export const organizationAttributesErrorSelector = (state: RootState) => state.attributes.organizationAttributes.error;

export const filterAttributesMapByIdSelector = (state: RootState) => state.attributes.filterAttributesMapById;

export const initialAttributesMapByIdSelector = (state: RootState) => state.attributes.initialAttributesMapById;

export const selectedValuesByAttributeIdSelector = (attributeId: number) => (state: RootState) =>
  state.attributes.filterAttributesMapById[attributeId]?.selectedValues;

export const selectedFilterAttributeValueIdsSelector = (state: RootState) => state.attributes.selectedValueIds;

export const filterAttributeByIdLoadingSelector = (attributeId: number) => (state: RootState) =>
  state.attributes.filterAttributesMapById[attributeId]?.isLoading;

export const searchValueSelector = (state: RootState) => state.attributes.filterSearchValue;

export const displayModeSelector = (state: RootState) => state.attributes.displayMode;

export const filterAttributeByIdSelector = (attributeId: number) => (state: RootState) =>
  state.attributes.filterAttributesMapById[attributeId];

export const searchDataSelector = (state: RootState) => state.attributes.searchValues.data || [];

export const searchValuesLoadingSelector = (state: RootState) => state.attributes.searchValues.isLoading;

export const searchValuesErrorSelector = (state: RootState) => state.attributes.searchValues.error;

export const searchValuesNextSelector = (state: RootState) => state.attributes.searchValues.next;

export const hasOverviewAttributesSelector = (state: RootState) => {
  const displayMode = state.attributes.displayMode;
  const map = state.attributes.overviewAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};
export const hasViewsAttributesSelector = (state: RootState) => {
  const displayMode = state.attributes.displayMode;
  const map = state.attributes.viewsAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const hasAnsweredAttributesSelector = (state: RootState) => {
  const displayMode = state.attributes.displayMode;
  const map = state.attributes.answersAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const hasCommentsAttributesSelector = (state: RootState) => {
  const displayMode = state.attributes.displayMode;
  const map = state.attributes.commentsAttributesMapById[displayMode];

  return Object.values(map).some((value) => value.data.values.length > 0);
};

export const isGuestsAttributeLoadedSelector = (currentTab: EAnalyticsScreenTabs) => (state: RootState) =>
  state.attributes[`${currentTab}AttributesMapById`].isGuestsAttributesLoaded;

export const guestsAttributeSelector = (state: RootState) => state.attributes.guestsAttribute;

export const guestsAttributeAnswersWithOptionSelector = (state: RootState) =>
  state.attributes.guestsAttributeAnswersWithOption;

export const sortDirectionSelector = (currentTab: EAnalyticsScreenTabs) => (state: RootState) =>
  state.attributes[`${currentTab}AttributesMapById`].sortDirection;

export const sortBySelector = (currentTab: EAnalyticsScreenTabs) => (state: RootState) =>
  state.attributes[`${currentTab}AttributesMapById`].sortBy;

export const sharedWithFilterSelector = (state: RootState) => state.attributes.sharedWithFilter;
