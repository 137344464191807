import { Platform } from 'react-native';

interface ICreateShadowArgs {
  shadowColor?: string;
  shadowOpacity?: number;
  shadowRadius?: number;
  offsetWidth?: number;
  offsetHeight?: number;
  elevation?: number;
}

const createShadow = ({
  shadowColor = '#000',
  shadowOpacity = 0.25,
  shadowRadius = 4.3,
  offsetWidth = 0,
  offsetHeight = 4,
  elevation = 10,
}: ICreateShadowArgs = {}) => {
  return Platform.select({
    ios: {
      shadowColor,
      shadowOffset: {
        width: offsetWidth,
        height: offsetHeight,
      },
      shadowOpacity,
      shadowRadius,
    },
    android: {
      elevation,
    },
  });
};

export default createShadow;
