function hasNumber(myString) {
  return /\d/.test(myString);
}

function hasUppercase(myString) {
  return /\.*[A-Z]/.test(myString);
}

export const isValidPasswordMessage = (pass: string) => {
  if (!pass) {
    return "Password shouldn't be empty";
  } else if (pass.length < 8) {
    return 'Password must contain at least 8 characters';
  } else if (!hasNumber(pass)) {
    return 'Password must contain number';
  } else if (!hasUppercase(pass)) {
    return 'Password must contain uppercase letter';
  } else {
    return '';
  }
};

export const getIsEmailValid = (email: string = '') => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !!re.test(email.trim());
};
