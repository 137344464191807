import React, { memo, useCallback } from 'react';
import CheckMarkIcon from '../../../../assets/icons/checkmark.svg';
import { IAttributeValue } from '../../../../store/attributes/attributes.types';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../utils/dimensions';
import { filterListItemCheckedMarkDataSet } from '../../analytics.constants';

interface IProps {
  isSelected: boolean;
  onPress: (value: IAttributeValue) => void;
  value: IAttributeValue;
  dataSet?: { [key: string]: string };
}

const FilterButton: React.FC<IProps> = ({ isSelected, value, onPress, dataSet }) => {
  const handlePress = useCallback(() => {
    onPress(value);
  }, [onPress, value]);

  return (
    <S.FilterWrapper
      // @ts-ignore
      dataSet={dataSet}
      key={value.id}
      onPress={handlePress}
    >
      <S.Title>{value.name}</S.Title>
      {isSelected && (
        <S.CheckmarkIconContainer
          // @ts-ignore
          dataSet={filterListItemCheckedMarkDataSet}
        >
          <CheckMarkIcon />
        </S.CheckmarkIconContainer>
      )}
    </S.FilterWrapper>
  );
};

const S = {
  FilterWrapper: styled.TouchableOpacity`
    flex-direction: row;
    height: ${calcHeight(60)}px;
    align-items: center;
    justify-content: space-between;
  `,
  Title: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s18};
  `,
  CheckmarkIconContainer: styled.View``,
};

export default memo(FilterButton);
