import React, { FC, useRef } from 'react';
import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { calcWidth, calcHeight } from '../../../../utils/dimensions';
import CustomText from '../../../../components/shared/CustomText';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: string;
  safeSetValue: (value: string) => void;
  lettersLimit: number;
}

const AboutPlaylist: FC<IProps> = ({ value, safeSetValue, lettersLimit }) => {
  const { t } = useTranslation();
  const textInput = useRef(null);

  return (
    <S.Container onPress={() => textInput.current?.focus()}>
      <S.TextInputContainer>
        <S.TextInput
          value={value}
          onChangeText={safeSetValue}
          multiline
          autoCorrect={false}
          ref={textInput}
          placeholder={t('createPlaylistStack.playlistSettings.settings.placeholder')}
          placeholderTextColor='#CDCDCD'
          autoCompleteType={Platform.OS === 'ios' ? undefined : 'off'}
        />
      </S.TextInputContainer>
      <S.BottomMenu>
        <S.MenuText>{`${value?.length || 0} / ${lettersLimit} `}</S.MenuText>
      </S.BottomMenu>
    </S.Container>
  );
};

const Container = styled.TouchableOpacity`
  height: 100%;
  justify-content: space-between;
  padding-right: ${calcWidth(18)}px;
  padding-left: ${calcWidth(18)}px;
  padding-top: ${calcHeight(14)}px;
  padding-bottom: ${calcHeight(14)}px;
`;

const TextInputContainer = styled.View`
  flex: 1;
`;

const BottomMenu = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${calcHeight(10)}px;
`;

const TextInput = styled.TextInput(
  ({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s18}px;
  `,
);

const MenuText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.s16}px;
    opacity: 0.52;
  `,
);

const S = { Container, TextInputContainer, BottomMenu, TextInput, MenuText };

export default AboutPlaylist;
