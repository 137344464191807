import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { calcHeight, isWeb } from '../../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { clearQuestions, setQuestionType, setSkipQuestion } from '../../../../store/biteQuestion/biteQuestion.actions';
import Routes from '../../../../navigation/routes';
import QuestionButtons from './common/QuestionButtons';
import { EQuestionType } from '../../../../types/biteQuestion';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { isBiteSummaryCreatedSelector } from '../../../../store/biteSummary/biteSummary.selectors';
// import MediaProcessingBanner from '../../../../components/shared/MediaProcessingBanner/MediaProcessingBanner';

const AddQuestion = ({ route, navigation }) => {
  const isEditMode = route?.params?.isEditMode;
  const isQuiz = route?.params?.isQuiz;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isBiteSummaryCreated = useSelector(isBiteSummaryCreatedSelector);

  const onSkip = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'skip_question_creation',
      }),
    );
    dispatch(setSkipQuestion(true));

    const nextRoute = isBiteSummaryCreated
      ? Routes.CreateBiteStack.CreateSummaryNotes
      : Routes.CreateBiteStack.SummaryNotes;

    navigation.navigate(nextRoute);
  }, [dispatch, isBiteSummaryCreated, navigation]);

  const handleNavigation = useCallback(() => {
    if (route?.params?.isQuiz) {
      navigation.navigate(Routes.EditQuizStack.QuizEditQuestion);
      return;
    }
    navigation.navigate(Routes.CreateBiteStack.BiteEditQuestion);
  }, [navigation, route?.params?.isQuiz]);

  const addMultiChoiceQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.MULTI_CHOICE },
      }),
    );
    dispatch(clearQuestions());
    dispatch(setQuestionType(EQuestionType.MULTI_CHOICE));
    handleNavigation();
  }, [dispatch, handleNavigation]);

  const addOpenEndedQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.OPEN_ENDED },
      }),
    );
    dispatch(clearQuestions());
    dispatch(setQuestionType(EQuestionType.OPEN_ENDED));
    handleNavigation();
  }, [dispatch, handleNavigation]);

  const addSurveyQuestion = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_question',
        props: { question_type: EQuestionType.SURVEY },
      }),
    );
    dispatch(clearQuestions());
    dispatch(setQuestionType(EQuestionType.SURVEY));
    handleNavigation();
  }, [dispatch, handleNavigation]);

  return (
    <>
      <S.Container>
        <S.Center>
          <S.TitleWrapper>
            <S.Title>{t('addQuestion.title')}</S.Title>
          </S.TitleWrapper>
          <QuestionButtons
            addSurveyQuestion={addSurveyQuestion}
            addMultiChoiceQuestion={addMultiChoiceQuestion}
            addOpenEndedQuestion={addOpenEndedQuestion}
          />
          {!isEditMode && !isQuiz && (
            <S.ContinueButton onPress={onSkip}>
              <S.ContinueText>{t('addQuestion.skip')}</S.ContinueText>
            </S.ContinueButton>
          )}
        </S.Center>
      </S.Container>
      {/*<MediaProcessingBanner containerStyle={S.MediaProcessingBannerContainerStyle} />*/}
    </>
  );
};

const S = {
  Center: styled.View`
    align-self: center;
    position: relative;
  `,
  Container: styled.View`
    flex: 1;
    align-items: center;
    background-color: white;
  `,
  TitleWrapper: styled.View`
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 24px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s26};
    color: ${({ theme }) => theme.colors.text};
  `,
  ContinueButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(44)}px;
    align-self: center;
    text-align: center;
  `,
  ContinueText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  MediaProcessingBannerContainerStyle: {
    bottom: !isWeb ? calcHeight(90) : 'auto',
  },
};

export default AddQuestion;
