import store from '../../store';
import { log, logError } from '../../store/appActivity/appActivity.slice';
import { IUploadMultiUploadToS3WithDetails, IUploadSingleUploadToS3WithDetails } from './uploadToS3.types';
import uploadSinglePartToS3WithDetails from './uploadSinglePartToS3WithDetails';
import uploadMultiPartToS3WithDetails from './uploadMultiPartToS3WithDetails';
import { DEFAULT_PARALLEL_UPLOADS } from '../constants/common';

const uploadToS3WithDetails = async ({
  processId,
  singleUploadDetails,
  multiUploadDetails,
  getMultiUploadDetails,
  fileSize,
}: {
  processId: string;
  singleUploadDetails: IUploadSingleUploadToS3WithDetails;
  multiUploadDetails?: IUploadMultiUploadToS3WithDetails;
  getMultiUploadDetails?: () => Promise<
    Omit<IUploadMultiUploadToS3WithDetails, 'parallelUploadsNum' | 'fileParts' | 'fileSize'>
  >;
  fileSize: number;
}): Promise<{ taskId: string; fileName: string }> => {
  const config = store.getState()?.appActivity?.config;
  const parallelUploadsNum = config?.s3MultiPartUpload?.parallelUploads || DEFAULT_PARALLEL_UPLOADS;

  const logContext = {
    processId,
    data: {
      fileSize,
      parallelUploadsNum,
    },
  };

  store.dispatch(
    log({
      ...logContext,
      event: 'uploadToS3WithDetails',
      data: {
        ...logContext.data,
        singleUploadDetails,
        multiUploadDetails,
      },
    }),
  );

  if (multiUploadDetails) {
    const withSequentialFallback = multiUploadDetails.fileParts.length > 1 && parallelUploadsNum > 1;

    try {
      store.dispatch(
        log({
          ...logContext,
          event: 'uploadToS3WithDetails: multi with parallel start',
          data: {
            ...logContext.data,
            withSequentialFallback,
          },
        }),
      );

      await uploadMultiPartToS3WithDetails({
        ...multiUploadDetails,
        parallelUploadsNum,
        fileSize,
      });

      store.dispatch(
        log({
          ...logContext,
          event: 'uploadToS3WithDetails: multi with parallel done',
          data: {
            ...logContext.data,
            withSequentialFallback,
          },
        }),
      );

      return {
        taskId: multiUploadDetails.s3UploadDetails.task_id,
        fileName: multiUploadDetails.fileName,
      };
    } catch (multiError) {
      store.dispatch(
        logError({
          ...logContext,
          event: 'uploadToS3WithDetails: multi with parallel error',
          data: {
            ...logContext.data,
            error: multiError,
            withSequentialFallback,
          },
        }),
      );
    }

    if (withSequentialFallback) {
      try {
        store.dispatch(
          log({
            ...logContext,
            event: 'uploadToS3WithDetails: multi sequential fallback get upload details',
          }),
        );

        const multiUploadDetailsFallback = await getMultiUploadDetails();

        store.dispatch(
          log({
            ...logContext,
            event: 'uploadToS3WithDetails: multi sequential fallback start',
            data: {
              ...logContext.data,
              multiUploadDetailsFallback,
            },
          }),
        );

        await uploadMultiPartToS3WithDetails({
          ...multiUploadDetailsFallback,
          fileParts: multiUploadDetails.fileParts,
          parallelUploadsNum: 1,
          fileSize,
        });

        store.dispatch(
          log({
            ...logContext,
            event: 'uploadToS3WithDetails: multi sequential fallback done',
          }),
        );

        return {
          taskId: multiUploadDetailsFallback.s3UploadDetails.task_id,
          fileName: multiUploadDetailsFallback.fileName,
        };
      } catch (multiFallbackError) {
        store.dispatch(
          logError({
            ...logContext,
            event: 'uploadToS3WithDetails: multi sequential failed',
            data: {
              ...logContext.data,
              multiFallbackError,
              singleUploadDetails,
            },
          }),
        );
      }
    }
  }

  if (!singleUploadDetails) {
    const error = 'uploadToS3WithDetails: single is not applicable, singleUploadDetails are not provided';
    store.dispatch(
      logError({
        ...logContext,
        event: { error },
      }),
    );
    throw new Error(error);
  }

  try {
    store.dispatch(
      log({
        ...logContext,
        event: 'uploadToS3WithDetails: single start',
      }),
    );

    await uploadSinglePartToS3WithDetails({
      ...singleUploadDetails,
      fileSize,
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadToS3WithDetails: single done',
      }),
    );

    return {
      taskId: singleUploadDetails.s3UploadDetails.task_id,
      fileName: singleUploadDetails.fileName,
    };
  } catch (singleError) {
    store.dispatch(
      logError({
        ...logContext,
        event: 'uploadToS3WithDetails: single error',
        data: {
          ...logContext.data,
          singleError,
        },
      }),
    );
    throw singleError;
  }
};
export default uploadToS3WithDetails;
