import { IS_PROD } from './env';

export const PREVIEW_SITE_URL = IS_PROD ? 'https://preview.mybites.io' : 'https://small-bites.com';
export const SITE_URL = IS_PROD ? 'https://mybiteshares.com' : 'https://small-bites.com';

export const DASHBOARD_URL = IS_PROD ? 'https://dashboard.mybites.io' : 'https://dashboard.small-bites.com';

export const MARKETING_SITE_URL = 'https://mybites.io';
export const APP_STORE_URL = 'https://apps.apple.com/il/app/my-bites-app/id1523685054';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.bites.learning';

export const INITIAL_YOUTUBE_URL = 'https://www.youtube.com/watch?v=C92gtfXd6Xc';
