import shortid from 'shortid';
import store from '../../store';
import { log } from '../../store/appActivity/appActivity.slice';
import withRetry, { IWithRetryProps } from '../withRetry';
import { IPrepareS3MultiUploadDetails, IPrepareS3MultiUploadDetailsResult } from './uploadToS3.types';
import BitesApi from '../../store/api/bites-api';
import { isWeb } from '../dimensions';
import last from 'lodash/last';
import { buildPartsNumArray, calculatePartsNum } from './uploadToS3.utils';

const prepareS3MultiUploadDetails = ({
  processId,
  uri,
  file,
  endpoint,
  getBlobFromLocalFile,
}: IPrepareS3MultiUploadDetails): Promise<IPrepareS3MultiUploadDetailsResult> => {
  return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
    const logContext = {
      processId,
      data: {
        attempt,
        isLastAttempt,
        endpoint,
      },
    };

    setErrorContext({
      ...logContext,
      action: 'prepareS3MultiUploadDetails: getFileSize',
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3MultiUploadDetails: getFileSize',
        data: {
          ...logContext.data,
          uri,
        },
      }),
    );

    const config = store.getState()?.appActivity?.config;
    const fileSize = isWeb ? file.size : await getBlobFromLocalFile().then(({ blob }) => blob.size);
    const partsNum = calculatePartsNum(fileSize, config?.s3MultiPartUpload?.partSize);
    const partsNumArray = buildPartsNumArray(partsNum);

    const extension = last(((isWeb ? file.name : uri) || '').split('.'));
    const fileName = `${shortid.generate()}.${extension}`;

    setErrorContext({
      ...logContext,
      action: 'prepareS3MultiUploadDetails: request',
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3MultiUploadDetails: request',
        data: {
          ...logContext.data,
          fileSize,
          partsNum,
          partsNumArray,
          extension,
          fileName,
        },
        metrics: {
          multipartUploadPartsNum: partsNum,
        },
      }),
    );

    const { data: s3UploadDetails } = await BitesApi.get(endpoint, {
      params: {
        file_name: fileName,
        part_numbers: JSON.stringify(partsNumArray),
      },
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3MultiUploadDetails: result',
        data: {
          ...logContext.data,
          s3UploadDetails,
        },
      }),
    );

    return { fileName, extension, s3UploadDetails, partsNumArray };
  });
};
export default prepareS3MultiUploadDetails;
