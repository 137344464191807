import React, { FC, useCallback, useRef } from 'react';
import { View } from 'react-native';
import Modal from '../ModalController';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import ModalButton from './components/ModalButton';
import { createShadow } from '../../../utils';
import { IModalProps } from '../Modal';

interface IButton {
  text: string;
  action: () => void;
}

export interface IButtonsConfig {
  openLink?: () => void;
  topButtons: IButton[];
  bottomButton?: IButton;
}

interface IProps {
  isVisible: boolean;
  closeModal: () => void;
  buttonsConfig: IButtonsConfig;
  openDirectShare?: boolean;
  modalProps?: IModalProps;
}

const BottomButtonsModal: FC<IProps> = ({ closeModal, isVisible, buttonsConfig, modalProps }) => {
  const { topButtons, bottomButton } = buttonsConfig;

  const actionAfterHideRef = useRef<() => void | null>(null);

  const handleModalHide = useCallback(() => {
    if (typeof actionAfterHideRef.current === 'function') {
      actionAfterHideRef.current();
    }

    actionAfterHideRef.current = null;
  }, []);

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={closeModal}
      backdropOpacity={0.4}
      hideModalContentWhileAnimating
      onModalHide={handleModalHide}
      style={{ justifyContent: 'flex-end', margin: 0 }}
      {...modalProps}
    >
      <S.Container>
        <S.TopBottunsContainer>
          <S.RadiusContainer>
            {topButtons.map((btnProps, idx) => {
              const handleBtnPress = () => {
                actionAfterHideRef.current = btnProps.action;
                closeModal();
              };

              return (
                <View key={btnProps.text + idx}>
                  <ModalButton {...btnProps} action={handleBtnPress} />
                  {topButtons.length - 1 !== idx && <S.Divider />}
                </View>
              );
            })}
          </S.RadiusContainer>
        </S.TopBottunsContainer>
        {bottomButton && (
          <RadiusContainer>
            <ModalButton bottomButton {...bottomButton} />
          </RadiusContainer>
        )}
      </S.Container>
    </Modal>
  );
};

const Container = styled.View`
  ${() =>
    isWeb
      ? `
		margin-bottom: ${calcHeight(28)}px;
		margin-right: ${calcWidth(30)}px;
		margin-left: ${calcWidth(30)}px;
	`
      : `
		width: ${deviceWidth};
		margin: 0 auto;
	`}
`;

const TopBottunsContainer = styled.TouchableOpacity`
  margin-bottom: ${calcHeight(10)}px;
`;

const RadiusContainer = styled.View`
  border-radius: 9px;
  overflow: hidden;
  ${createShadow({ elevation: 8 })};
  background-color: ${({ theme }) => theme.colors.lightGray3};
`;

const Divider = styled.View(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGray5};
    height: ${calcHeight(1)}px;
    width: 100%;
  `,
);

const S = { Container, TopBottunsContainer, RadiusContainer, Divider };

export default BottomButtonsModal;
