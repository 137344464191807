import { Features } from '../../utils/featureFlag/featureFlag.types';
import { RootState } from '../index';

export const organizationCodeSelector = (state: RootState) => state.org.code;

export const organizationCodeErrorSelector = (state: RootState) => state.org.codeError;
export const isOrgCodeLoadingSelector = (state: RootState) => state.org.isOrgCodeLoading;

export const joinDeeplinkSelector = (state: RootState) => state.org.joinDeeplink;

export const organizationFormStatusSelector = (state: RootState) => {
  const { isShowOrganizationForm } = state.org;
  return isShowOrganizationForm;
};

export const organizationRenameStatusSelector = (state: RootState) => {
  const { rename } = state.org;
  return rename;
};

export const organizationLeaveShowStatusSelector = (state: RootState) => {
  const { isShowOrganizationLeave } = state.org;
  return isShowOrganizationLeave;
};

export const organizationLeaveRequestStatusSelector = (state: RootState) => {
  const { leave } = state.org;
  return leave;
};

export const organizationCreatedAtSelector = (state: RootState) => {
  const { code } = state.org;
  return code?.[0]?.created_at;
};

export const creatorsSelector = (state: RootState) => state.org.creators;

export const organizationAttributesSelector = (state: RootState) => state.org.attributes.data;
export const organizationAttributesIsLoadingSelector = (state: RootState) => state.org.attributes.isLoading;

export const organizationAttributesErrorSelector = (state: RootState) => state.org.attributes.error;

export const organizationFeaturesSelector = (state: RootState) => state.org.features;

export const organizationHasFeatureFlagSelector = (feature: Features) => (state: RootState) => {
  return state.org.features.includes(feature);
};
