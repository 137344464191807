import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import NotFoundBiteIcon from '../../../../assets/icons/not-found-bite-logo.svg';
import { useTranslation } from 'react-i18next';
import ImprovesList from './ImprovesList';
import { EImproves } from '../aiExpectation.constants';

interface IProps {
  improves: EImproves[];
  onContinuePress: () => void;
}

const AINotApplicable: FC<IProps> = ({ improves, onContinuePress }) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Content>
        <NotFoundBiteIcon />
        <S.Description>
          {t('aiNotApplicable.description')}
          <S.BoldText>{t('aiNotApplicable.boldDescription')}</S.BoldText>
        </S.Description>

        <ImprovesList improves={improves} />
      </S.Content>

      <S.ContinueButtonContainer>
        <S.ContinueButton onPress={onContinuePress} width={calcWidth(226)} content={t('common.continue')} />
      </S.ContinueButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.SafeAreaView`
    flex: 1;
    align-items: center;
    justify-content: space-between;
  `,
  Content: styled.View`
    max-width: ${calcWidth(320)}px;
    flex: 6;
    align-items: center;
    justify-content: center;
  `,
  ContinueButtonContainer: styled.View`
    flex: 1;
  `,
  ContinueButton: styled(GenericButton)``,
  Description: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    margin-top: ${calcHeight(30)}px;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s20};
    text-align: center;
  `,
  BoldText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s20};
    text-align: center;
  `,
};

export default memo(AINotApplicable);
