import { EEnhanceType, EUploadStatus, IBiteItem, IntroSection } from '../../types/bite';
import { EIntroMediaProcessingStatus } from '../../store/createBite/createBite.types';
import getIntroProcessingStatusByUploadStatus from './getIntroProcessingStatusByUploadStatus';

const getBiteUploadingMediaStatus = (
  bite: IBiteItem,
  isAwaitingIntroEnhancements = false,
): EIntroMediaProcessingStatus => {
  if (!bite) {
    return EIntroMediaProcessingStatus.INACTIVE;
  }

  const introSection = bite?.bite_sections?.find(({ type }) => type === 'intro') as IntroSection | undefined;
  const taskId = introSection?.task_id;
  const original = bite?.extend?.enhancements?.find(({ enhance_type }) => enhance_type === EEnhanceType.original);
  const enhancements = bite?.extend?.enhancements?.find(
    ({ enhance_type }) => enhance_type === EEnhanceType.speech_enhancement,
  );
  const introSubtitles = bite?.extend?.subtitles;
  const subtitles =
    introSubtitles && introSubtitles.length > 0
      ? introSubtitles.find((sub) => sub.enabled) ||
        introSubtitles.find(({ upload_status }) => upload_status === EUploadStatus.done) ||
        introSubtitles[0]
      : null;

  if (!taskId || !original || !enhancements) {
    return EIntroMediaProcessingStatus.INACTIVE;
  }

  const originalUploadStatus = getIntroProcessingStatusByUploadStatus(original?.upload_status);
  const enhancementsUploadStatus = getIntroProcessingStatusByUploadStatus(enhancements?.upload_status);
  const subtitlesUploadStatus = getIntroProcessingStatusByUploadStatus(
    subtitles?.upload_status || EUploadStatus.in_progress,
  );

  return getUploadingMediaStatus({
    originalUploadStatus,
    enhancementsUploadStatus,
    subtitlesUploadStatus,
    isAwaitingIntroEnhancements,
  });
};

interface IProps {
  originalUploadStatus: EIntroMediaProcessingStatus;
  enhancementsUploadStatus: EIntroMediaProcessingStatus;
  subtitlesUploadStatus: EIntroMediaProcessingStatus;

  summarySuggestionStatus?: EIntroMediaProcessingStatus;
  questionSuggestionStatus?: EIntroMediaProcessingStatus;
  biteNameSuggestionStatus?: EIntroMediaProcessingStatus;
  coverSuggestionStatus?: EIntroMediaProcessingStatus;
  isAwaitingIntroEnhancements?: boolean;
}
export const getUploadingMediaStatus = ({
  originalUploadStatus,
  enhancementsUploadStatus,
  subtitlesUploadStatus,
  summarySuggestionStatus,
  questionSuggestionStatus,
  biteNameSuggestionStatus,
  coverSuggestionStatus,
  isAwaitingIntroEnhancements = false,
}: IProps) => {
  if (originalUploadStatus === EIntroMediaProcessingStatus.ERROR) {
    return EIntroMediaProcessingStatus.ERROR;
  }

  if (
    (originalUploadStatus === EIntroMediaProcessingStatus.SUCCESS ||
      enhancementsUploadStatus === EIntroMediaProcessingStatus.SUCCESS ||
      subtitlesUploadStatus === EIntroMediaProcessingStatus.SUCCESS) &&
    (originalUploadStatus === EIntroMediaProcessingStatus.PROCESSING ||
      enhancementsUploadStatus === EIntroMediaProcessingStatus.PROCESSING ||
      subtitlesUploadStatus === EIntroMediaProcessingStatus.PROCESSING ||
      summarySuggestionStatus === EIntroMediaProcessingStatus.PROCESSING ||
      questionSuggestionStatus === EIntroMediaProcessingStatus.PROCESSING ||
      biteNameSuggestionStatus === EIntroMediaProcessingStatus.PROCESSING ||
      coverSuggestionStatus === EIntroMediaProcessingStatus.PROCESSING)
  ) {
    return EIntroMediaProcessingStatus.PARTIAL_SUCCESS;
  }

  if (
    originalUploadStatus === EIntroMediaProcessingStatus.PROCESSING ||
    enhancementsUploadStatus === EIntroMediaProcessingStatus.PROCESSING ||
    subtitlesUploadStatus === EIntroMediaProcessingStatus.PROCESSING
  ) {
    return EIntroMediaProcessingStatus.PROCESSING;
  }

  if (
    originalUploadStatus === EIntroMediaProcessingStatus.SUCCESS &&
    (enhancementsUploadStatus === EIntroMediaProcessingStatus.SUCCESS ||
      subtitlesUploadStatus === EIntroMediaProcessingStatus.SUCCESS) &&
    isAwaitingIntroEnhancements
  ) {
    return EIntroMediaProcessingStatus.SUCCESS;
  }
  if (
    originalUploadStatus === EIntroMediaProcessingStatus.SUCCESS &&
    (enhancementsUploadStatus === EIntroMediaProcessingStatus.NOT_APPLICABLE ||
      enhancementsUploadStatus === EIntroMediaProcessingStatus.ERROR) &&
    (subtitlesUploadStatus === EIntroMediaProcessingStatus.NOT_APPLICABLE ||
      subtitlesUploadStatus === EIntroMediaProcessingStatus.ERROR)
  ) {
    return EIntroMediaProcessingStatus.NOT_APPLICABLE;
  }

  return EIntroMediaProcessingStatus.INACTIVE;
};

export default getBiteUploadingMediaStatus;
