export enum EDeepLinkMessageType {
  EDIT = 'edit',
  CREATE = 'create',
}

export enum EDeepLinkContentType {
  BITE = 'bite',
}

export enum EDeepLinkSection {
  INTRO = 'intro',
  QUESTION = 'question',
  SUMMARY = 'summary',
  ENHANCEMENTS = 'enhancements',
}

export interface IDeepLinkingMessageProps {
  msg_type: EDeepLinkMessageType;
  bites_content_type: EDeepLinkContentType;
  section?: EDeepLinkSection;
  bites_content_id?: number;
}
