export const PEXELS_API_KEY = '563492ad6f91700001000001a982d9ca8f2a42c3a66ac10eb0f570ad';

interface IOptions {
  resultsPerPage?: number;
  orientation?: 'portrait' | 'landscape' | 'square';
}

const searchPexels = async (query: string, { resultsPerPage, orientation }: IOptions = { resultsPerPage: 30 }) => {
  const response = await fetch(
    `https://api.pexels.com/v1/search?query=${query}&per_page=${resultsPerPage}${
      orientation ? `&orientation=${orientation}` : ''
    }`,
    {
      headers: {
        Authorization: PEXELS_API_KEY,
      },
    },
  );

  const json = await response.json();

  return json;
};

export default searchPexels;

export const searchPexelsWithUrl = async (url: string) => {
  const response = await fetch(url, {
    headers: {
      Authorization: PEXELS_API_KEY,
    },
  });

  const json = await response.json();

  return json;
};

// https://www.pexels.com/api/documentation/#photos-curated
export const searchPexelsCuratedImages = async ({ resultsPerPage }: IOptions = { resultsPerPage: 30 }) => {
  const response = await fetch(`https://api.pexels.com/v1/curated?&per_page=${resultsPerPage}`, {
    headers: {
      Authorization: PEXELS_API_KEY,
    },
  });

  const json = await response.json();

  return json;
};
