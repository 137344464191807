import axios from 'axios';

import { BASE_BACKEND_URL } from '../../store/api/bites-api';

const bitesCrmTrack = async (data) => {
  try {
    await axios.post(`${BASE_BACKEND_URL}/api/common_services/crm/track`, data);
  } catch (error) {}
};
export default bitesCrmTrack;
