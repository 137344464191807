import * as React from 'react';
import { CommonActions } from '@react-navigation/native';

export const navigationRef = React.createRef<any>();

export function navigate(name: string, params = {}) {
  navigationRef.current?.navigate(name, params);
}

export function replace(name: string, params = {}) {
  navigationRef.current?.dispatch(
    CommonActions.reset({
      index: 1,
      routes: [
        {
          name,
          params,
        },
      ],
    }),
  );
}
