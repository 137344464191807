const runInParallel = async <T, R>(parts: T[], parallelNum: number = 1, fn: (part: T, index: number) => Promise<R>) => {
  const _parts = [...parts];
  let index = -1;
  const results = [];

  const processRunner = async () => {
    while (_parts.length > 0) {
      const part = _parts.shift();
      index++;
      const localIndex = index;

      const result = await fn(part, localIndex);
      results.push({ index: localIndex, data: result });
    }
  };

  await Promise.all([...Array(parallelNum)].map(processRunner));

  results.sort((a, b) => a.index - b.index);
  return results.map(({ data }) => data);
};
export default runInParallel;
