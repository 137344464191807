import { IUploadSingleUploadToS3WithDetails } from './uploadToS3.types';
import store from '../../store';
import { log, logError } from '../../store/appActivity/appActivity.slice';
import axios from 'axios';
import { getS3ErrorCode } from './uploadToS3.utils';

const uploadSinglePartToS3WithDetails = async ({
  file,
  processId,
  s3UploadDetails,
  fileSize,
}: IUploadSingleUploadToS3WithDetails) => {
  const logContext = {
    processId,
    data: {
      s3UploadDetails,
      fileSize,
    },
  };

  const startTs = Date.now();

  const uploadRequestLogData = {
    ...logContext,
    event: 'uploadSinglePartToS3WithDetails: start',
    data: {
      ...logContext.data,
      request_url: s3UploadDetails.url,
      request_file_name: file?.name,
      request_file_path: file?.path,
      request_fields: JSON.stringify(s3UploadDetails.fields),
    },
  };

  const formData = new FormData();
  for (const fieldName in s3UploadDetails.fields) {
    formData.append(fieldName, s3UploadDetails.fields[fieldName]);
    uploadRequestLogData.data[`request_field_${fieldName}`] = s3UploadDetails.fields[fieldName];
  }
  formData.append('file', file);

  store.dispatch(log(uploadRequestLogData));

  try {
    const result = await axios({
      method: 'POST',
      url: s3UploadDetails.url,
      data: formData,
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadSinglePartToS3WithDetails: done',
        data: {
          ...logContext.data,
          result,
        },
        metrics: {
          uploadToS3Status: result.status,
          uploadSinglePartToS3Ts: Date.now() - startTs,
          uploadSinglePartToS3TsPerMb: fileSize ? (Date.now() - startTs) / (fileSize / 1024 / 1024) : null,
        },
      }),
    );
  } catch (error) {
    store.dispatch(
      logError({
        ...logContext,
        event: 'uploadSinglePartToS3WithDetails: error',
        data: {
          ...logContext.data,
          error,
          // axios case
          errorResponse: error?.response,
        },
        metrics: {
          uploadToS3Status: error?.response?.status,
          uploadToS3StatusErrorCode: getS3ErrorCode(error?.response?.data),
        },
      }),
    );

    throw error;
  }
};

export default uploadSinglePartToS3WithDetails;
