import styled from 'styled-components/native';
import React, { memo, useCallback, useRef, useState } from 'react';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import Header from './Header';
import FormTextInput from '../../../components/shared/inputs/FormTextInput/FormTextInput';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { useTranslation } from 'react-i18next';
import { clearUserProfileErrorsByType, finalizeUserProfile } from '../../../store/auth/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  emailFormSelector,
  firstNameSelector,
  lastNameSelector,
  phoneFormSelector,
} from '../../../store/authForm/authForm.selectors';
import { isProfileLoadingSelector, profileErrorsSelector } from '../../../store/auth/auth.selectors';
import {
  setEmail,
  setFirstName,
  setIsAuthDataMissing,
  setLastName,
  setPhone,
} from '../../../store/authForm/authForm.slice';
import { log } from '../../../store/appActivity/appActivity.slice';
import { getIsEmailValid } from '../../../utils/auth';
import PhoneNumberInput from '../../../components/shared/PhoneNumberInput/PhoneNumberInput';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { IFinalizeProfileAction } from '../../../store/auth/auth.types';

interface IProps {}

enum EResponseErrors {
  phone_conflict = 'phone_conflict',
  bad_phone = 'bad_phone',
  email_conflict = 'email_conflict',
  bad_email = 'bad_email',
}

export const FINALIZE_USER_INFO_ERRORS = {
  [EResponseErrors.phone_conflict]: true,
  [EResponseErrors.bad_phone]: true,
  [EResponseErrors.email_conflict]: true,
  [EResponseErrors.bad_email]: true,
};

const PHONE_ERRORS = [EResponseErrors.phone_conflict, EResponseErrors.bad_phone];
const EMAIL_ERRORS = [EResponseErrors.email_conflict, EResponseErrors.bad_email];

const UserInfoForm: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const email = useSelector(emailFormSelector);
  const phone = useSelector(phoneFormSelector);
  const firstName = useSelector(firstNameSelector);
  const lastName = useSelector(lastNameSelector);
  const isLoading = useSelector(isProfileLoadingSelector);
  const responseErrors = useSelector(profileErrorsSelector);

  const hasEmailRef = useRef(!!email);
  const hasPhoneRef = useRef(!!phone);
  const hasNameRef = useRef(firstName && lastName);

  const isEmailValid = getIsEmailValid(email);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const phoneDisplayError = responseErrors.includes(EResponseErrors.phone_conflict)
    ? t('authForm.phoneTaken')
    : responseErrors.includes(EResponseErrors.bad_phone)
    ? t('authForm.phoneNumberForm.phoneNotValid')
    : phoneError;

  const emailDisplayError = responseErrors.includes(EResponseErrors.email_conflict)
    ? t('authForm.emailTaken')
    : responseErrors.includes(EResponseErrors.bad_email)
    ? t('authForm.emailIsNotValid')
    : emailError;

  const isContinueDisabled = !firstName || !lastName || (!hasEmailRef.current && !email);

  const handleFirstNameChange = useCallback(
    (text) => {
      dispatch(setFirstName(text));
    },
    [dispatch],
  );

  const handleLastNameChange = useCallback(
    (text) => {
      dispatch(setLastName(text));
    },
    [dispatch],
  );

  const handleEmailChange = useCallback(
    (text) => {
      setEmailError(null);
      dispatch(clearUserProfileErrorsByType(EMAIL_ERRORS));
      dispatch(setEmail(text));
    },
    [dispatch],
  );

  const handlePhoneChange = useCallback(
    (text) => {
      setPhoneError(null);
      dispatch(clearUserProfileErrorsByType(PHONE_ERRORS));
      dispatch(setPhone(text));
    },
    [dispatch],
  );

  const handleContinue = useCallback(() => {
    if (isLoading || isContinueDisabled) {
      return;
    }

    const isFirstNameError = !hasNameRef.current && !firstName;
    if (isFirstNameError) {
      setFirstNameError(t('authForm.required'));
    }

    const isLastNameError = !hasNameRef.current && !lastName;
    if (isLastNameError) {
      setLastNameError(t('authForm.required'));
    }

    const isPhoneError = !hasPhoneRef.current && !isPhoneValid && phone.length > 5;
    if (isPhoneError) {
      setPhoneError(t('authForm.phoneNumberForm.phoneNotValid'));
    }

    const isEmailError = !hasEmailRef.current && !isEmailValid;
    if (isEmailError) {
      setEmailError(t('authForm.emailIsNotValid'));
    }

    if (isPhoneError || isEmailError || isLastNameError || isFirstNameError) {
      return;
    }

    dispatch(
      log({
        event: 'UserInfoForm.handleContinue',
        data: {
          email,
          firstName,
          lastName,
          phone,
        },
      }),
    );
    const onSuccess = () => dispatch(setIsAuthDataMissing(false));

    let payload: IFinalizeProfileAction = { onSuccess };

    if (!hasNameRef.current) {
      payload.firstName = firstName;
      payload.lastName = lastName;
    }

    if (!hasEmailRef.current) {
      payload.email = email;
    }

    if (!hasPhoneRef.current) {
      payload.phone = phone;
    }

    dispatch(finalizeUserProfile(payload));
  }, [isLoading, isContinueDisabled, isPhoneValid, phone, isEmailValid, dispatch, email, firstName, lastName, t]);

  return (
    <S.Content entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <Header title={t('authForm.userInfoForm.title')} />
      <S.Form>
        {!hasNameRef.current && (
          <>
            <S.TextInput
              label={t('authForm.userInfoForm.firstName')}
              value={firstName}
              onChangeText={handleFirstNameChange}
              onEnterPress={handleContinue}
              error={firstNameError}
            />
            <S.TextInput
              label={t('authForm.userInfoForm.lastName')}
              value={lastName}
              onChangeText={handleLastNameChange}
              onEnterPress={handleContinue}
              error={lastNameError}
            />
          </>
        )}

        {!hasEmailRef.current && (
          <S.TextInput
            label={t('authForm.userInfoForm.email')}
            value={email}
            onChangeText={handleEmailChange}
            error={emailDisplayError}
            onEnterPress={handleContinue}
          />
        )}

        {!hasPhoneRef.current && (
          <S.PhoneNumberInput
            onValidationChange={setIsPhoneValid}
            isEditMode
            label={t('authForm.userInfoForm.phoneNumber')}
            phoneNumber={phone}
            error={phoneDisplayError}
            onPhoneNumberChange={handlePhoneChange}
            onEnterPress={handleContinue}
          />
        )}

        <S.ContinueButton
          isLoading={isLoading}
          disabled={isContinueDisabled}
          onPress={handleContinue}
          content={t('common.Continue')}
        />
      </S.Form>
    </S.Content>
  );
};

const S = {
  Content: styled(Animated.View)`
    align-items: center;
  `,
  Form: styled.View`
    margin-top: ${calcHeight(36)}px;
  `,
  BackButtonContainer: styled.View`
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    margin: ${calcHeight(11)}px 0 0 ${calcWidth(14)}px;
  `,
  Header: styled.View`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: ${calcHeight(-10)}px;
  `,
  TextInput: styled(FormTextInput)`
    margin-bottom: ${calcHeight(22)}px;
  `,
  PhoneNumberInput: styled(PhoneNumberInput)`
    min-width: ${calcWidth(298)}px;
    margin-bottom: ${calcHeight(22)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
    margin-top: ${calcHeight(2)}px;
    margin-bottom: ${calcHeight(36)}px;
  `,
};

export default memo(UserInfoForm);
