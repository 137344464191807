import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import { IOption } from './OptionsList';
import TestIcon from '../../../../assets/icons/testIcon.svg';
import { calcWidth, calcHeight } from '../../../../utils/dimensions';
interface IProps {
  option: IOption;
  onValueChange: (id: number, value: boolean) => void;
}

const CheckBoxOption: React.FC<IProps> = (props) => {
  const { option, onValueChange } = props;

  return (
    <S.Container>
      <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <input
          type='checkbox'
          checked={option.isSelect}
          onChange={(evt: any) => onValueChange(option.id, evt.target.checked)}
          style={{
            width: 25,
            height: 25,
            margin: 8,
          }}
        />
        <Text>{option.name}</Text>
      </label>
      {option.is_already_shared ? <TestIcon width={calcWidth(25)} height={calcHeight(20)} /> : null}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 0 5%;
  `,
};

export default CheckBoxOption;
