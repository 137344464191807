import React, { useEffect } from 'react';
import styled from 'styled-components/native';
import WebView from '../../WebView/WebView';
import { IVideoDetails } from '../../../types/media';

interface IProps {
  url: string;
  onMediaDetails?: (videoDetails: IVideoDetails) => void;
  initialSizes?: { width: number; height: number };
}

const SlidesPlayer: React.FC<IProps> = ({ url, onMediaDetails, initialSizes = { width: 1920, height: 1240 } }) => {
  useEffect(() => {
    onMediaDetails?.(initialSizes);
  }, [initialSizes, onMediaDetails]);

  return (
    <S.SlidesPlayerContainer>
      <WebView url={url} />
    </S.SlidesPlayerContainer>
  );
};

const S = {
  SlidesPlayerContainer: styled.View`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  `,
};

export default SlidesPlayer;
