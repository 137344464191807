export function lightOrDark(color) {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substr(0, 2), 16);
  const cG = parseInt(hex.substr(2, 2), 16);
  const cB = parseInt(hex.substr(4, 2), 16);
  const brightness = (cR * 299 + cG * 587 + cB * 114) / 1000;

  if (brightness > 155) {
    return 'black';
  }
  return 'white';
}
