import { RootState } from '../index';

// currently visible in stack
// after transition between modals should be the same as currentModal
export const visibleModalSelector = (state: RootState) =>
  state.modalController.modalStack.find((item) => item.isVisibleInStack) || null;

// last in stack with isVisibleProp=true
// after transition between modals should be the same as visibleModal
export const currentModalSelector = (state: RootState) => {
  for (let i = state.modalController.modalStack.length - 1; i >= 0; i--) {
    const item = state.modalController.modalStack[i];
    if (item.isVisibleProp) {
      return item;
    }
  }
  return null;
};
