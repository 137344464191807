import React from 'react';
import styled from 'styled-components/native';
import { useSelector } from 'react-redux';
import { calcFontSize, calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import MagicStarsIcon from '../../../assets/icons/magic-stars-white.svg';
import { ImageURISource, StyleProp, ViewStyle } from 'react-native';
import { css } from 'styled-components';
import UploadingOverlay from '../UploadingOverlay';
import WithoutCoverOverlay from '../../feed/biteItemOverlays/WithoutCoverOverlay';
import { IBiteItem } from '../../../types/bite';
import { AWS_URL } from '../../../utils/constants/env';
import ShadowedContainer from '../../ShadowedContainer';
import { coverKeywordSuggestionSelector } from '../../../store/createBite/createBite.selectors';

interface IProps {
  source: ImageURISource;
  originalVideoIsProcessing?: boolean;
  isLoading?: boolean;
  onPreviewPress?: () => void;
  onEditPress: () => void;
  bite: IBiteItem;
}

const BiteCoverImage: React.FC<IProps> = ({
  source,
  originalVideoIsProcessing,
  onPreviewPress,
  onEditPress,
  bite,
  isLoading,
}) => {
  const { t } = useTranslation();
  const coverKeywordSuggestion = useSelector(coverKeywordSuggestionSelector);

  const workspaceLogoUrl = bite?.branding?.logo_url ? `${AWS_URL}/${bite?.branding?.logo_url}` : null;
  const withWorkspaceLogo = !source?.uri;

  return (
    <ShadowedContainer containerViewStyle={S.ShadowedContainerViewStyles}>
      <S.ImageWrap isProcessing={originalVideoIsProcessing}>
        <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
        <S.Image isProcessing={originalVideoIsProcessing} source={source} />
        {withWorkspaceLogo && <WithoutCoverOverlay workspaceLogoUrl={workspaceLogoUrl} />}
        {originalVideoIsProcessing && (
          <S.PreviewOverlay>
            <S.PreviewOverlayInProgressText>{t('enhancements.processingBiteCreation')}</S.PreviewOverlayInProgressText>
          </S.PreviewOverlay>
        )}
        {!isLoading && (
          <>
            {typeof onPreviewPress === 'function' && (
              <S.PreviewButton onPress={onPreviewPress} disabled={bite?.no_sections}>
                <S.PreviewButtonText>{t('biteCreated.previewBite')}</S.PreviewButtonText>
              </S.PreviewButton>
            )}
            <S.EditCoverButton
              onPress={onEditPress}
              isCoverKeywordSuggestion={!!coverKeywordSuggestion}
              hasPreviewButton={!!onPreviewPress}
            >
              {coverKeywordSuggestion && <MagicStarsIcon />}
              <S.EditCoverButtonText isCoverKeywordSuggestion={!!coverKeywordSuggestion}>
                {coverKeywordSuggestion ? t('biteCreated.coverSuggestion') : t('biteCreated.editCover')}
              </S.EditCoverButtonText>
            </S.EditCoverButton>
          </>
        )}
      </S.ImageWrap>
    </ShadowedContainer>
  );
};

const S = {
  ImageWrap: styled.View<{ isProcessing?: boolean }>`
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    overflow: hidden;
    height: ${calcHeight(361)}px;
    width: ${calcWidth(248)}px;
    border-radius: 25px;
    ${({ theme, isProcessing }) => (isProcessing ? `background-color: ${theme.colors.transparentBlack}` : '')};
  `,
  PreviewOverlay: styled.View<{ dark?: boolean }>`
    z-index: 2;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    justify-content: center;
    background-color: ${({ theme, dark }) => (dark ? theme.colors.transparentBlack : theme.colors.lightBackground2)};
  `,
  PreviewOverlayInProgressText: styled.Text`
    color: white;
    font-size: ${calcFontSize(20)}px;
    ${({ theme }) => css`
      color: ${theme.colors.white};
      font-family: ${theme.fontFamilies.GilroyMedium};
    `}
    text-align: center;
  `,
  Image: styled.Image<{ isProcessing?: boolean }>`
    position: absolute;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100%;
    height: ${calcHeight(361)}px;
    opacity: ${({ isProcessing }) => (isProcessing ? 0.5 : 1)};
  `,
  PreviewButton: styled.TouchableOpacity`
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue};
    padding: ${calcHeight(10)}px;
    height: ${calcHeight(42)}px;
    border-radius: ${calcHeight(42 / 2)}px;
    width: ${calcWidth(185)}px;
    margin-bottom: ${calcHeight(24)}px;
  `,
  PreviewButtonText: styled.Text`
    align-self: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: bold;
  `,
  EditCoverButton: styled.TouchableOpacity<{ isCoverKeywordSuggestion: boolean; hasPreviewButton: boolean }>`
    background: rgba(0, 0, 0, 0.5);

    border: 1px solid ${({ theme }) => theme.colors.lightGray6};
    ${({ isCoverKeywordSuggestion }) => (isCoverKeywordSuggestion ? 'flex-direction: row;' : '')}
    align-items: center;
    padding: ${calcHeight(7)}px;
    height: ${calcHeight(30)}px;
    border-radius: ${calcHeight(30 / 2)}px;
    min-width: ${calcWidth(177)}px;
    max-width: ${calcWidth(230)}px;
    margin-bottom: ${({ hasPreviewButton }) => (hasPreviewButton ? calcHeight(21) : calcHeight(316))}px;
    z-index: 2;
  `,
  EditCoverButtonText: styled.Text<{ isCoverKeywordSuggestion: boolean }>`
    text-align: center;
    align-self: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.s11};
    line-height: ${({ theme }) => theme.fontSizes.s11};
    font-weight: bold;
    ${({ isCoverKeywordSuggestion }) => (isCoverKeywordSuggestion ? `margin-left: ${calcWidth(8)}px;` : '')}
  `,
  ShadowedContainerViewStyles: {
    height: calcHeight(361),
    width: calcWidth(248),
    alignSelf: 'center',
  } as StyleProp<ViewStyle>,
};

export default BiteCoverImage;
