import { IImageFileMetadata } from '../types/typings';
import { getMimeType } from './getMimeType';

// this is a web only function
// create a native specific when required
export const getImageMetadata = async ({
  url,
  blob,
  file,
}: {
  url?: string;
  blob?: Blob;
  file?: File;
}): Promise<IImageFileMetadata> => {
  return new Promise((resolve) => {
    const img = new Image();

    // Set the source
    img.src = url ? url : URL.createObjectURL(blob || file);

    const mimeType = getMimeType({ url, blob, file });

    // Load the video and get duration, width, and height
    img.onload = function () {
      resolve({
        name: file?.name || null,
        width: img.naturalWidth,
        height: img.naturalHeight,
        mimeType,
      });
      URL.revokeObjectURL(img.src); // Clean up
    };
  });
};
