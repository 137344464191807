import React, { memo, useCallback, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SvgProps } from 'react-native-svg';
import styled from 'styled-components/native';
import { EShareMode } from '../../../utils/constants/bite';
import { calcFontSize, calcHeight, calcWidth } from '../../../utils/dimensions';
import { changeSharingModeDataSet } from './SharePanel.constants';

export interface IShareViewOption {
  type: EShareMode;
  secondType?: EShareMode;
  title: string;
  description: string;
  Icon: React.FC<SvgProps>;
  children?: React.ReactChild;
}

interface IProps extends IShareViewOption {
  isSelected: boolean;
  sharingMode: EShareMode;
  isExpanded?: boolean;
  onPress: (type: EShareMode) => void;
}

const SharePanelFullScreenViewOption: React.FC<IProps> = ({
  isSelected,
  title,
  description,
  onPress,
  children,
  type,
  sharingMode,
  isExpanded = false,
}) => {
  const { t } = useTranslation();

  const [isCollapsed, setCollapseState] = useState(!isExpanded);

  const handleCollapse = useCallback(() => {
    setCollapseState(true);
  }, []);

  const handleExpand = useCallback(() => {
    setCollapseState(false);
  }, []);

  const handlePress = useCallback(() => {
    onPress(type);
  }, [onPress, type]);

  return (
    <S.Container isSelected={isSelected}>
      <S.OptionBtn
        onPress={handlePress}
        //@ts-ignore
        dataSet={changeSharingModeDataSet}
      >
        <S.OptionMain>
          <S.TextContainerText>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TextContainerText>
        </S.OptionMain>
        <S.RadioButtonContainer>{isSelected && <S.RadioButton />}</S.RadioButtonContainer>

        {children && isCollapsed && (
          <S.AdvancedContainer>
            {sharingMode === EShareMode.TEAM_ONLY_ALLOW_JOIN ? (
              <S.RequireAnAccessCodeText>{`${t('share.requireAnAccessCode')}`}</S.RequireAnAccessCodeText>
            ) : (
              <View />
            )}

            <S.CollapseBtn onPress={handleExpand}>
              <S.CollapseBtnText>{t('share.advanced')}</S.CollapseBtnText>
            </S.CollapseBtn>
          </S.AdvancedContainer>
        )}
      </S.OptionBtn>

      {children && !isCollapsed && (
        <S.ChildWrapper>
          {children}

          <S.CollapseBtn onPress={handleCollapse}>
            <S.CollapseBtnText>{t('share.close')}</S.CollapseBtnText>
          </S.CollapseBtn>
        </S.ChildWrapper>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ isSelected: boolean }>`
    width: ${calcWidth(341)}px;
    min-height: ${calcHeight(85)}px;
    padding-left: ${calcWidth(11)}px;
    padding-right: ${calcWidth(11)}px;
    padding-bottom: ${calcHeight(10)}px;
    border-radius: ${calcWidth(5)}px;
    margin-left: ${calcWidth(16)}px;
    margin-right: ${calcWidth(16)}px;
    background-color: ${({ theme, isSelected }) => {
      return isSelected ? theme.colors.gray16 : theme.colors.white;
    }};
  `,
  RadioButtonContainer: styled.View`
    position: absolute;
    top: ${calcHeight(12)}px;
    right: ${calcWidth(12)}px;
    width: ${calcWidth(24)}px;
    height: ${calcWidth(24)}px;
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
    border-radius: ${calcWidth(24 / 2)}px;
    align-items: center;
    justify-content: center;
  `,
  RadioButton: styled.View`
    width: ${calcWidth(18)}px;
    height: ${calcWidth(18)}px;
    border-radius: ${calcWidth(18 / 2)}px;
    background: ${({ theme }) => theme.colors.primaryBlue};
  `,
  OptionBtn: styled.TouchableOpacity`
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: ${calcHeight(21)}px;
    width: 100%;
  `,
  OptionMain: styled.View`
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  `,
  ChildWrapper: styled.View`
    padding-top: ${calcHeight(15)}px;
  `,
  TextContainerText: styled.View`
    align-items: flex-start;
    padding-right: ${calcWidth(35)}px;
    max-width: 100%;
  `,
  Title: styled.Text`
    margin-bottom: ${calcHeight(8)}px;
    font-size: ${calcFontSize(18)}px;
    color: ${({ theme }) => theme.colors.gray12};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-align: left;
  `,
  Description: styled.Text`
    font-size: ${calcFontSize(12)}px;
    line-height: ${calcHeight(16)}px;
    color: ${({ theme }) => theme.colors.gray11};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-align: left;
  `,
  CollapseBtn: styled.TouchableOpacity`
    height: ${calcHeight(17)}px;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    padding-left: ${calcWidth(6)}px;
    padding-right: ${calcWidth(6)}px;
    border-radius: ${calcWidth(9)}px;
  `,
  CollapseBtnText: styled.Text`
    font-size: ${calcFontSize(12)}px;
    color: ${({ theme }) => theme.colors.gray5};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  `,
  AdvancedContainer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: ${calcHeight(5)}px ${calcHeight(4)}px 0 0;
  `,
  RequireAnAccessCodeText: styled.Text`
    font-size: ${calcFontSize(13)}px;
    color: ${({ theme }) => theme.colors.purpleChoose};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  `,
};

export default memo(SharePanelFullScreenViewOption);
