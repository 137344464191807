import React, { memo } from 'react';
import styled from 'styled-components/native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { calcHeight } from '../../../../utils/dimensions';
import Header from '../Header';
import { useTranslation } from 'react-i18next';

interface IProps {}

const ResetInfo: React.FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <S.Content entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
      <Header title={t('authForm.forgotPasswordForm.resetPassword')} />
      <S.Info>{t('authForm.forgotPasswordForm.resetPasswordInfo')}</S.Info>
    </S.Content>
  );
};

const S = {
  Content: styled(Animated.View)``,
  Info: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin-top: ${calcHeight(8)}px;
    text-align: center;
    margin-bottom: ${calcHeight(31)}px;
  `,
};

export default memo(ResetInfo);
