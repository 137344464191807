import { HapticOptions, HapticFeedbackTypes } from 'react-native-haptic-feedback';

export const hapticOptions: HapticOptions = {
  enableVibrateFallback: true,
  ignoreAndroidSystemSettings: false,
};

type THapticTypesMap = { [key in HapticFeedbackTypes]: HapticFeedbackTypes };
export const hapticTypes: THapticTypesMap = [
  'selection',
  'impactLight',
  'impactMedium',
  'impactHeavy',
  'rigid',
  'soft',
  'notificationSuccess',
  'notificationWarning',
  'notificationError',
  'clockTick',
  'contextClick',
  'keyboardPress',
  'keyboardRelease',
  'keyboardTap',
  'longPress',
  'textHandleMove',
  'virtualKey',
  'virtualKeyRelease',
  'effectClick',
  'effectDoubleClick',
  'effectHeavyClick',
  'effectTick',
].reduce((map, type) => {
  map[type] = type;
  return map;
}, {}) as THapticTypesMap;
