import { useEffect } from 'react';
import queryString, { ParsedQuery } from 'query-string';
import store from '../../store';
import { applyExternalAction } from '../../store/appActivity/appActivity.slice';

const useDeepLinking = () => {
  const handleDynamicLink = (params: ParsedQuery<string>) => {
    console.log('LINK RECEIVED', params);
    store.dispatch(applyExternalAction(params));
  };

  useEffect(() => {
    if (!location.search) {
      return;
    }

    const query = queryString.parse(location.search);
    handleDynamicLink(query);
  }, []);
};

export default useDeepLinking;
