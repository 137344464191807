import React, { memo, ReactNode, useCallback } from 'react';
import { APP_TYPE } from '../../../utils/constants/config';
import { isWeb } from '../../../utils/dimensions';
import AppleLogin from 'react-apple-login';
import { BASE_BACKEND_URL } from '../../../store/api/bites-api';
import { ViewStyle } from 'react-native';
import SocialButton from './SocialButton';
import { appleAuth, appleAuthAndroid } from '@invertase/react-native-apple-authentication';
import useAppleAuth from '../../../hooks/sso/useAppleAuth';

interface IProps {
  title: string;
  icon: ReactNode;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
}

const isAppleAuthSupportedOnNative = !isWeb && (appleAuth.isSupported || appleAuthAndroid.isSupported);

const AppleAuthButton: React.FC<IProps> = ({ title, icon, style, containerStyle }) => {
  const handleAppleAuth = useAppleAuth();

  const state =
    isWeb &&
    encodeURIComponent(
      JSON.stringify({
        app: APP_TYPE,
        redirect: `${window.location.protocol}//${window.location.hostname}${
          window.location.hostname === 'localhost' ? ':' + window.location.port : ''
        }${window.location.pathname}?token=ACCESS_TOKEN`,
      }),
    );

  const render = useCallback(
    ({ onClick }) => (
      <SocialButton style={style} onPress={onClick} icon={icon} title={title} containerStyle={containerStyle} />
    ),
    [style, icon, title, containerStyle],
  );

  if (isWeb) {
    return (
      <AppleLogin
        clientId='app.bites.creatorapp'
        redirectURI={`${BASE_BACKEND_URL}/teams/get_auth_token/`}
        scope='name email'
        responseType='code id_token'
        responseMode='form_post'
        state={state}
        render={render}
      />
    );
  }

  if (isAppleAuthSupportedOnNative) {
    return (
      <SocialButton style={style} onPress={handleAppleAuth} icon={icon} title={title} containerStyle={containerStyle} />
    );
  }

  return null;
};

export default memo(AppleAuthButton);
