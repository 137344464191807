import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import EditStoryDisabledIcon from '../../../../assets/icons/editFlow/edit-story-disabled.svg';
import EditStoryIcon from '../../../../assets/icons/editFlow/edit-story.svg';
import EditQuestionDisabledIcon from '../../../../assets/icons/editFlow/edit-question-disabled.svg';
import EditQuestionIcon from '../../../../assets/icons/editFlow/edit-question.svg';
import EditSummaryDisabledIcon from '../../../../assets/icons/editFlow/edit-summary-disabled.svg';
import EditSummaryIcon from '../../../../assets/icons/editFlow/edit-summary.svg';
import EditEnhancementIcon from './EditEnhancementIcon/EditEnhancementIcon';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../../../navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import { View } from 'react-native';
import { biteQuestionSelector } from '../../../../store/biteQuestion/biteQuestion.selectors';
import ConfirmIcon from '../../../../assets/icons/confirm.svg';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { editIntroDataSet } from '../EditMain.contants';
import { StackNavigationProp } from '@react-navigation/stack';

export enum EditBiteIconType {
  INTRO = 'intro',
  QUESTION = 'question',
  SUMMARY = 'summary',
  INTRO_ENHANCEMENTS = 'intro_enhancements',
}

interface IProps {
  type: EditBiteIconType;
  disabled?: boolean;
  edited?: boolean;
  onPress?: () => void;
}

const SectionItem: React.FC<IProps> = ({ type, disabled, edited, onPress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const bite = useSelector(selectedBiteSelector);
  const biteQuestion = useSelector(biteQuestionSelector);
  const theme = useTheme();

  const handlePress = useCallback(() => {
    if (onPress) {
      onPress();
      return;
    }
    switch (type) {
      case EditBiteIconType.INTRO:
        navigation.navigate(Routes.EditBiteStack.StackName, {
          screen: Routes.EditBiteStack.EditMedia,
        });
        return;
      case EditBiteIconType.QUESTION:
        if (biteQuestion.isCreated) {
          navigation.navigate(Routes.EditBiteStack.StackName, {
            screen: Routes.EditBiteStack.BiteEditQuestion,
          });
          return;
        }
        navigation.navigate(Routes.EditBiteStack.StackName, {
          screen: Routes.EditBiteStack.AddQuestion,
        });
        return;
      case EditBiteIconType.SUMMARY:
        navigation.navigate(Routes.EditBiteStack.StackName, {
          screen: Routes.EditBiteStack.EditSummaryNotes,
        });
        return;
      case EditBiteIconType.INTRO_ENHANCEMENTS:
        navigation.navigate(Routes.EditBiteStack.StackName, {
          screen: Routes.EditBiteStack.EditEnhancements,
        });
        return;
      default:
        return null;
    }
  }, [biteQuestion.isCreated, navigation, type, onPress]);

  const SectionIcon = useCallback(
    ({ style }) => {
      switch (type) {
        case EditBiteIconType.INTRO:
          if (disabled) {
            return <EditStoryDisabledIcon style={style} />;
          }
          return <EditStoryIcon style={style} />;
        case EditBiteIconType.QUESTION:
          if (disabled) {
            return <EditQuestionDisabledIcon style={style} />;
          }
          return <EditQuestionIcon style={style} />;
        case EditBiteIconType.SUMMARY:
          if (disabled) {
            return <EditSummaryDisabledIcon style={style} />;
          }
          return <EditSummaryIcon style={style} />;
        case EditBiteIconType.INTRO_ENHANCEMENTS:
          return (
            <View style={style}>
              <EditEnhancementIcon disabled={disabled} />
            </View>
          );
      }
    },
    [disabled, type],
  );

  const handlePressSection = useCallback(() => {
    switch (type) {
      case EditBiteIconType.INTRO_ENHANCEMENTS:
        dispatch(
          trackEvent({
            event: 'bitesai_section_btn',
            props: { bite_id: bite.id },
          }),
        );
        break;
      default:
        dispatch(
          trackEvent({
            event: `edit_${type}_section`,
            props: { bite_id: bite?.id },
          }),
        );
    }
    handlePress();
  }, [type, handlePress, dispatch, bite?.id]);

  const handleAddSection = useCallback(() => {
    dispatch(
      trackEvent({
        event: `edit_${type}_section`,
        props: { bite_id: bite?.id },
      }),
    );
    handlePress();
  }, [dispatch, type, bite?.id, handlePress]);

  return (
    <S.SectionItemContainer
      onPress={handlePressSection}
      disabled={disabled}
      // @ts-ignore
      dataSet={editIntroDataSet}
    >
      <SectionIcon style={S.IconStyles} />
      <S.SectionName disabled={disabled}>{t(`editMain.sections.${type}`)}</S.SectionName>
      {disabled && type !== EditBiteIconType.INTRO_ENHANCEMENTS && (
        <S.AddSectionButton onPress={handleAddSection}>
          <S.AddSectionButtonText>{t('editMain.sections.add')}</S.AddSectionButtonText>
        </S.AddSectionButton>
      )}
      {disabled && type === EditBiteIconType.INTRO_ENHANCEMENTS && (
        <S.ProcessingText>{t('editMain.sections.processing')}</S.ProcessingText>
      )}
      {!disabled && edited && (
        <S.EditedTextWrapper>
          <ConfirmIcon style={S.ConfirmIconStyles} fill={theme.colors.greenChoose} />
          <S.EditedText>{t('editMain.sections.edited')}</S.EditedText>
        </S.EditedTextWrapper>
      )}
    </S.SectionItemContainer>
  );
};

const S = {
  SectionName: styled.Text<{ disabled?: boolean }>`
    margin-top: ${calcHeight(3)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray11 : theme.colors.text)};
  `,
  ProcessingText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  AddSectionButton: styled.TouchableOpacity``,
  AddSectionButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SectionItemContainer: styled.TouchableOpacity`
    margin-left: ${calcWidth(15)}px;
    margin-right: ${calcWidth(15)}px;
    align-items: center;
    justify-content: center;
  `,
  SectionsContainer: styled.View`
    flex-direction: row;
  `,
  IconStyles: { marginBottom: calcHeight(5) },
  EditedTextWrapper: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  EditedText: styled.Text`
    margin-left: 2px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  ConfirmIconStyles: {
    width: calcWidth(12),
    height: calcHeight(12),
  },
};

export default SectionItem;
