import React from 'react';
import styled from 'styled-components/native';
import { IS_IOS } from '../../../../../../utils/constants/env';
import { calcHeight, calcWidth, isWeb } from '../../../../../../utils/dimensions';

interface IProps {
  isPressed: boolean;
  onPress: () => void;
  text: string;
}

const GenericLabel: React.FC<IProps> = ({ isPressed, onPress, text }) => {
  return (
    <S.LabelContainer>
      <S.Label onPress={onPress} isPressed={isPressed}>
        <S.LabelText isPressed={isPressed}>{text}</S.LabelText>
      </S.Label>
    </S.LabelContainer>
  );
};

const S = {
  Label: styled.TouchableOpacity<{ isPressed: boolean }>`
    height: ${calcHeight(30)}px;
    justify-content: center;
    text-align: center;
    background-color: ${({ theme, isPressed }) => (isPressed ? theme.colors.lightPrimaryBlue : theme.colors.white)};
    border-radius: 15px;
    border: solid;
    border-color: ${({ theme, isPressed }) => (isPressed ? theme.colors.white : theme.colors.lightPrimaryBlue)};
    align-self: center;
    margin-right: ${calcWidth(10)}px;
  `,
  LabelContainer: styled.View``,
  LabelText: styled.Text<{ isPressed: boolean }>`
    height: ${calcHeight(30)}px;
    color: ${({ theme, isPressed }) => (isPressed ? theme.colors.white : theme.colors.lightPrimaryBlue)};
    padding-left: ${calcWidth(10)}px;
    padding-right: ${calcWidth(10)}px;
    padding-top: ${calcWidth(10)}px;
    margin-bottom: ${isWeb ? calcHeight(7) : 0}px;
    ${isWeb || IS_IOS ? `padding-bottom: ${calcWidth(10)}px;` : ''}
    ${!isWeb ? `bottom: ${IS_IOS ? calcHeight(4) : calcHeight(8)}px;` : ''}
  `,
};

export default GenericLabel;
