import React from 'react';
import styled from 'styled-components/native';
import { calcWidth } from '../utils/dimensions';
import { useTranslation } from 'react-i18next';
import MagicStarsBlueIcon from '../assets/icons/magic-stars-blue.svg';

interface IProps {}

const WithAISuggestionsSign: React.FC<IProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Text>{t('aiExpectations.withAiSuggestions')}</S.Text>
      <MagicStarsBlueIcon />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  Text: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    margin-right: ${calcWidth(4)}px;
  `,
};

export default WithAISuggestionsSign;
