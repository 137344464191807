import { calcHeight, calcWidth, isWeb } from '../../../../../../../utils/dimensions';
import ShadowedContainer from '../../../../../../../components/ShadowedContainer';
import ThreeDotsSvg from '../../../../../../../assets/icons/three-dots.svg';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import Tooltip from 'react-native-walkthrough-tooltip';
import { I18nManager, StyleProp, ViewStyle } from 'react-native';
import { IUserOrganizations } from '../../../../../../../store/auth/auth.types';
import {
  showLeaveUserOrganization,
  showUpdateUserOrganizationNameForm,
} from '../../../../../../../store/org/org.slice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IS_ANDROID } from '../../../../../../../utils/constants/env';

interface IProps {
  organization: IUserOrganizations;
  isFocused: boolean;
  onPress: (org: IUserOrganizations) => void;
}

const TOOLTIP_INSETS = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 120,
};

const SettingsButton: React.FC<IProps> = ({ organization, isFocused, onPress }) => {
  const theme = useTheme();
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const topAdjustment = useMemo(() => {
    if (isWeb) {
      return 100;
    }

    if (IS_ANDROID) {
      return -40;
    }

    return -15;
  }, []);

  const handleOpenTooltip = useCallback(() => {
    onPress(organization);
    setTooltipVisible(true);
  }, [onPress, organization]);

  const handleCloseTooltip = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  const renderButton = () => {
    if ((!isWeb || isFocused) && !isTooltipVisible) {
      return (
        <ShadowedContainer distance={IS_ANDROID ? 15 : 32} viewStyle={S.ShadowedContainerViewStyles}>
          <S.Button onPress={handleOpenTooltip}>
            <ThreeDotsSvg fill={theme.colors.primaryBlue} width={16} height={16} />
          </S.Button>
        </ShadowedContainer>
      );
    }
    return (
      <S.Button isPressed={isTooltipVisible} onPress={handleOpenTooltip}>
        <ThreeDotsSvg fill={theme.colors.primaryBlue} width={16} height={16} />
      </S.Button>
    );
  };

  return (
    <Tooltip
      showChildInTooltip={false}
      backgroundColor='transparent'
      placement={!isWeb ? 'bottom' : undefined}
      topAdjustment={topAdjustment}
      displayInsets={TOOLTIP_INSETS}
      backgroundStyle={S.TooltipBackgroundStyles}
      arrowStyle={S.TooltipArrowStyles}
      tooltipStyle={S.TooltipStyles}
      contentStyle={S.TooltipContentStyles}
      isVisible={isTooltipVisible}
      onClose={handleCloseTooltip}
      content={<TooltipContent organization={organization} onClose={handleCloseTooltip} />}
    >
      {renderButton()}
    </Tooltip>
  );
};

const TooltipContent = ({ organization, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const showRename = () => {
    dispatch(showUpdateUserOrganizationNameForm());
    onClose();
  };

  const showLeave = () => {
    dispatch(showLeaveUserOrganization());
    onClose();
  };
  return organization.is_owner ? (
    <S.TooltipButton onPress={showRename}>
      <S.TooltipButtonText>{t('organizationMenu.rename')}</S.TooltipButtonText>
    </S.TooltipButton>
  ) : (
    <S.TooltipButton onPress={showLeave}>
      <S.TooltipButtonText isDanger>{t('organizationMenu.leave')}</S.TooltipButtonText>
    </S.TooltipButton>
  );
};

const S = {
  ShadowedContainerViewStyles: { borderRadius: calcWidth(35) / 2 } as StyleProp<ViewStyle>,
  Container: styled.View``,
  Button: styled.TouchableOpacity<{ isPressed?: boolean }>`
    background: ${({ theme, isPressed }) => (isPressed ? theme.colors.gray17 : theme.colors.white)};
    width: ${calcWidth(35)}px;
    height: ${calcWidth(35)}px;
    border-radius: ${calcWidth(35) / 2}px;
    align-items: center;
    justify-content: center;
    margin-right: ${calcWidth(5)}px;
  `,
  TooltipStyles: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    padding: 0,
  } as StyleProp<ViewStyle>,
  TooltipArrowStyles: {
    display: 'none',
    opacity: 0,
  } as StyleProp<ViewStyle>,
  TooltipBackgroundStyles: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
  TooltipContentStyles: {
    elevation: 5,
  } as StyleProp<ViewStyle>,

  TooltipContent: styled.View`
    color: ${({ theme }) => theme.colors.white};
  `,
  TooltipButton: styled.TouchableOpacity`
    justify-content: center;
    padding: ${calcHeight(isWeb ? 16 : 10)}px ${calcWidth(16)}px;
    flex: 1;
  `,
  TooltipButtonText: styled.Text<{ isDanger?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme, isDanger }) => (isDanger ? theme.colors.red1 : theme.colors.primaryBlue)};
    white-space: nowrap;
  `,
};

export default SettingsButton;
