export const COMMENTS_LIST_SCREEN = 'CommentsList';
export const COMMENTS_ATTRIBUTES_SCREEN = 'CommentsAttributes';
export const ANSWERED_LIST_SCREEN = 'AnsweredList';
export const ANSWERED_ATTRIBUTES_SCREEN = 'AnsweredAttributes';
export const OVERVIEW_LIST_SCREEN = 'OverviewList';
export const OVERVIEW_ATTRIBUTES_SCREEN = 'OverviewAttributes';
export const VIEWS_LIST_SCREEN = 'ViewsList';
export const VIEWS_ATTRIBUTES_SCREEN = 'ViewsAttributes';

// Screen names for pageview events
export const STATS_OVERVIEW_SCREEN = 'StatsOverview';
export const STATS_VIEWS_SCREEN = 'StatsViews';
export const STATS_ANSWERS_SCREEN = 'StatsAnswers';
export const STATS_COMMENTS_SCREEN = 'StatsComments';
