import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { EObjectType, ESortDirection } from '../../types/common';

import {
  IInitialState,
  ILoadNextPageResults,
  EFeedSortField,
  IFeedObjectType,
  ILoadNextPage,
  ISetUserContent,
} from './feed.types';
import BiteTypes from '../bite/bite.types';
import PlaylistTypes from '../playlist/playlist.types';
import AuthTypes from '../auth/auth.types';
import { IAction } from '../common/types';

export const initialState: IInitialState = {
  results: [],
  nextPage: 1,
  isLoading: false,
  sortField: EFeedSortField.created_at,
  sortDirection: ESortDirection.desc,
  searchStr: '',
  types: [IFeedObjectType.all],
  creatorIds: null,
  creatorsLabel: null,
  hasUserContent: true,
};

const FEED = 'FEED';

const feedSlice = createSlice({
  name: FEED,
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    resetResults: (state) => {
      state.results = [];
      state.nextPage = 1;
    },
    loadResultsSuccess: (state, { payload }: PayloadAction<ILoadNextPageResults>) => {
      state.results = [...state.results, ...payload.results];
      state.nextPage = payload.nextPage;
    },
    setHasUserContent: (state, {}: PayloadAction<ISetUserContent>) => {
      state.hasUserContent =
        state.results.filter((result) => result.content_type === EObjectType.playlist).length > 1 ||
        state.results.some(
          (result) => result.content_type === EObjectType.quiz || result.content_type === EObjectType.bite,
        );
    },
    setSortField: (state, { payload }: PayloadAction<EFeedSortField>) => {
      state.sortField = payload;
    },
    setSortDirection: (state, { payload }: PayloadAction<ESortDirection>) => {
      state.sortDirection = payload;
    },
    setSearchStr: (state, { payload }: PayloadAction<string>) => {
      state.searchStr = payload;
    },
    setTypes: (state, { payload }: PayloadAction<IFeedObjectType[]>) => {
      state.types = payload;
    },
    setCreatorIds: (state, { payload }: PayloadAction<number[]>) => {
      state.creatorIds = payload;
    },
    setCreatorsLabel: (state, { payload }: PayloadAction<string>) => {
      state.creatorsLabel = payload;
    },
    setBaseFiltersAndSorting: (state) => {
      state.types = [IFeedObjectType.all];
      state.searchStr = '';
      state.sortField = EFeedSortField.created_at;
      state.sortDirection = ESortDirection.desc;
      state.creatorIds = null;
      state.creatorsLabel = null;
    },
  },
  extraReducers: {
    [BiteTypes.DELETE_BITE]: (state, { payload }: IAction<number>) => {
      state.results = state.results.filter(({ id, content_type }) => {
        return content_type !== EObjectType.bite || id !== payload;
      });
    },
    [PlaylistTypes.DELETE_PLAYLIST]: (state, { payload }: IAction<number>) => {
      state.results = state.results.filter(({ id, content_type }) => {
        return (content_type !== EObjectType.playlist && content_type !== EObjectType.quiz) || id !== payload;
      });
    },
    [AuthTypes.SWITCH_ACTIVE_ORGANIZATION]: () => {
      return initialState;
    },
    [AuthTypes.LOGOUT]: () => {
      return initialState;
    },
  },
});

export const {
  setSearchStr,
  setTypes,
  setCreatorIds,
  setCreatorsLabel,
  setSortField,
  setSortDirection,
  setIsLoading,
  resetResults,
  loadResultsSuccess,
  setHasUserContent,
  setBaseFiltersAndSorting,
} = feedSlice.actions;

export const loadNextPage = createAction<ILoadNextPage>(`${FEED}/loadNextPage`);

export default feedSlice.reducer;
