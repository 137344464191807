import { FileWithPath } from 'react-dropzone';
import RNFetchBlob from 'rn-fetch-blob';
import { isWeb } from './dimensions';

export const getFileSize = async (file: string | FileWithPath): Promise<number | null> => {
  if (isWeb) {
    return (file as File).size;
  }

  const uri = file as string;
  const stats = await RNFetchBlob.fs.stat(uri);
  return stats.size;
};
