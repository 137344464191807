import { takeLatest, put, all, select, delay } from 'redux-saga/effects';

import {
  loadNextPage,
  loadResultsSuccess,
  resetResults,
  setBaseFiltersAndSorting,
  setIsLoading,
} from './bitesFeed.slice';
import * as calls from '../api/bites-api/calls/bite.calls';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { isWithoutEmptyBitesSelector, nextPageSelector, searchStrSelector } from './bitesFeed.selectors';
import { ILoadNextPage, TGetBitesFeedResponse } from './bitesFeed.types';
import { fetchFullBites, setBites } from '../bite/bite.actions';
import { IAction } from '../common/types';
import { logError } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';

const DEFAULT_PAGE_SIZE = 15;

function* loadNextPageSaga({ payload }: IAction<ILoadNextPage>) {
  try {
    if (payload.debounce) {
      yield delay(300);
    }
    if (payload.reset || payload.withBaseFiltersAndSorting) {
      yield put(resetResults());
    }
    if (payload.withBaseFiltersAndSorting) {
      yield put(setBaseFiltersAndSorting());
    }

    yield put(setIsLoading(true));

    const org = yield select(activeOrganizationSelector);
    const searchStr = yield select(searchStrSelector);
    const isWithoutEmptyBites = yield select(isWithoutEmptyBitesSelector);
    const nextPage = yield select(nextPageSelector);

    const { data }: TGetBitesFeedResponse = yield calls.fetchBites(org.id, {
      search: searchStr,
      isWithoutEmptyBites,
      pageSize: DEFAULT_PAGE_SIZE,
      page: nextPage,
    });

    yield put(setBites(data.results));

    const biteIds = data.results.map(({ id }) => id);

    yield put(
      loadResultsSuccess({
        results: biteIds,
        nextPage: data.next ? nextPage + 1 : null,
      }),
    );

    yield put(fetchFullBites(biteIds));

    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsLoading(false));

    yield put(
      logError({
        event: 'loadNextPageSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

export default function* appActivitySaga() {
  yield all([takeLatest(loadNextPage, loadNextPageSaga)]);
}
