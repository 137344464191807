import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import {
  existingVideosHasMoreSelector,
  existingVideosLoadingErrorSelector,
  existingVideosSelector,
  isExistingVideosLoadingSelector,
} from '../../store/videoEditor/videoEditor.selectors';
import { loadExistingVideos, loadExistingVideosNextPage, loadVideo } from '../../store/videoEditor/videoEditor.slice';
import Preloader from '../../components/shared/Preloader';
import Routes from '../../navigation/routes';
import FeedItemsList from '../../components/feed/FeedItemsList';
import { log } from '../../store/appActivity/appActivity.slice';
import { getDisplayTimeFromDuration } from './utils/getDisplayTimeFromDuration';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import BiteLogo from '../../assets/images/bite-logo.png';
import { activeOrganizationSelector } from '../../store/auth/auth.selectors';
import ShadowedContainer from '../../components/ShadowedContainer';
import EmptyList from '../../components/shared/EmptyList';
import { IVideo } from '../../store/videoEditor/videoEditor.types';
import { useIsFocused } from '@react-navigation/native';

const ExistingVideos = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const existingVideos = useSelector(existingVideosSelector);
  const existingVideosHasMore = useSelector(existingVideosHasMoreSelector);
  const isLoading = useSelector(isExistingVideosLoadingSelector);
  const isError = useSelector(existingVideosLoadingErrorSelector);

  const reloadExistingVideos = useCallback(() => {
    dispatch(
      log({
        event: 'ExistingVideos.reloadExistingVideos',
      }),
    );
    dispatch(loadExistingVideos());
  }, [dispatch]);

  const handlePullToRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'ExistingVideos.handlePullToRefresh',
      }),
    );
    dispatch(loadExistingVideos());
  }, [dispatch]);

  const loadMore = useCallback(() => {
    dispatch(
      log({
        event: 'ExistingVideos.loadMore',
      }),
    );

    dispatch(loadExistingVideosNextPage());
  }, [dispatch]);

  const renderItem = useCallback(
    ({ item: existingVdeo }) => {
      return <ExistingVideo navigation={navigation} existingVdeo={existingVdeo} onContinue={route.params.onContinue} />;
    },
    [navigation, route.params.onContinue],
  );

  const createNewVideo = useCallback(() => {
    navigation.navigate(Routes.CreateBiteStack.BiteVideoEditor, {
      onContinue: route.params.onContinue,
    });
  }, [navigation, route.params.onContinue]);

  const renderEmptyList = useCallback(() => {
    return (
      <>
        <EmptyList message={'No video drafts'} createMessage='Create a new video' onCreate={createNewVideo} />
      </>
    );
  }, [createNewVideo]);

  useEffect(() => {
    if (isFocused) {
      dispatch(loadExistingVideos());
    }
  }, [dispatch, isFocused]);

  if (!existingVideos && !isError) {
    return (
      <S.PreloaderContainer>
        <Preloader />
      </S.PreloaderContainer>
    );
  }

  return (
    <>
      {existingVideos && (
        <FeedItemsList
          items={existingVideos}
          isLoading={isLoading}
          isSmall
          renderEmptyList={renderEmptyList}
          renderItem={renderItem}
          onPullToRefresh={handlePullToRefresh}
          onEndReached={existingVideosHasMore && !isError ? loadMore : undefined}
        />
      )}
      {isError && (
        <S.IntoContainer>
          <S.ErrorText>Something went wrong</S.ErrorText>
          <S.RetryButton onPress={existingVideos ? loadMore : reloadExistingVideos}>
            <S.RetryText>Retry</S.RetryText>
          </S.RetryButton>
        </S.IntoContainer>
      )}
    </>
  );
};
export default ExistingVideos;

const ExistingVideo = ({
  navigation,
  existingVdeo,
  onContinue,
}: {
  navigation: any;
  existingVdeo: IVideo;
  onContinue: any;
}) => {
  const dispatch = useDispatch();

  const activeOrganization = useSelector(activeOrganizationSelector);

  const source = useMemo(
    () =>
      activeOrganization.brand_icon ? { uri: existingVdeo.thumbnail?.url || activeOrganization.brand_icon } : BiteLogo,
    [activeOrganization.brand_icon, existingVdeo.thumbnail?.url],
  );
  const duration = getDisplayTimeFromDuration(existingVdeo.config.duration);

  const handlePress = useCallback(() => {
    dispatch(
      loadVideo({
        videoId: existingVdeo.id,
      }),
    );
    // dispatch(
    //   cloneExistingVideo({
    //     videoId: existingVdeo.id,
    //   }),
    // );
    navigation.navigate(Routes.CreateBiteStack.BiteVideoEditor, {
      onContinue,
    });
  }, [dispatch, existingVdeo.id, navigation, onContinue]);

  return (
    <S.Video onPress={handlePress} key={existingVdeo.id}>
      <ShadowedContainer>
        <S.ImageContainer>
          {existingVdeo.thumbnail ? (
            <S.Image source={source} resizeMode='cover' />
          ) : (
            <S.LogoImage source={source} resizeMode='contain' />
          )}
        </S.ImageContainer>
      </ShadowedContainer>
      <S.DurationContainer>
        <S.DurationText>{duration}</S.DurationText>
      </S.DurationContainer>
      <S.DateContainer>
        <S.DateText>{new Date(existingVdeo.updateDate).toLocaleDateString()}</S.DateText>
      </S.DateContainer>
    </S.Video>
  );
};

const S = {
  PreloaderContainer: styled.View`
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  `,
  Video: styled.TouchableOpacity`
    margin: ${calcHeight(12)}px;
  `,
  ImageContainer: styled.View`
    align-items: center;
    justify-content: center;
    width: ${calcWidth(169)}px;
    height: ${calcHeight(239)}px;
    border-radius: 8px;
    overflow: hidden;
  `,
  LogoImage: styled.Image`
    width: ${calcWidth(100)}px;
    height: ${calcHeight(100)}px;
  `,
  Image: styled.Image`
    width: ${calcWidth(169)}px;
    height: ${calcHeight(239)}px;
  `,
  DurationContainer: styled.View`
    margin-top: ${calcHeight(8)}px;
  `,
  DurationText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
  `,
  DateContainer: styled.View`
    margin-top: ${calcHeight(4)}px;
  `,
  DateText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
  `,
  IntoContainer: styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
  `,
  ErrorText: styled.Text`
    color: ${({ theme }) => theme.colors.failRed};
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  InfoText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  RetryButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(16)}px;
  `,
  RetryText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-decoration: underline;
  `,
};
