import React, { useCallback, useState } from 'react';
import { ViewStyle } from 'react-native';
import UploadingOverlay from '../UploadingOverlay';
import ReplaceIcon from '../../../assets/icons/creationFlow/replace-icon.svg';
import DeleteIcon from '../../../assets/icons/delete-icon.svg';
import StockIcon from '../../../assets/icons/creationFlow/stock-icon.svg';
import GalleryIcon from '../../../assets/icons/creationFlow/gallery-icon.svg';
import CameraIcon from '../../../assets/icons/creationFlow/camera-icon.svg';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import styled, { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import MediaModal from '../../../screens/creationBite/common/MediaModal';

interface IProps {
  style?: ViewStyle;
  uri: string;
  isLoading?: boolean;
  from?: string;
  onDeleteImage?: () => void;
  openMediaModal?: () => void;
  withModal?: boolean;
  onSelectStockImage?: (url: string) => void;
  onSelectGallery?: () => void;
  onSelectCamera?: () => void;
}

const MediaImage: React.FC<IProps> = ({
  style,
  uri,
  isLoading,
  from,
  onDeleteImage,
  withModal,
  openMediaModal,
  onSelectStockImage,
  onSelectGallery,
  onSelectCamera,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isImageOverlayVisible, setImageOverlayVisible] = useState(false);
  const [isMediaModalVisible, setMediaModalVisible] = useState(false);
  const { selectedBite } = useSelector(biteSelector);

  const handleToggleOverlay = useCallback(() => {
    setImageOverlayVisible((prev) => !prev);
  }, []);

  const handleAddImage = useCallback(() => {
    if (openMediaModal) {
      openMediaModal();
    }
    setImageOverlayVisible(false);
  }, [openMediaModal]);

  const handleDeleteImage = useCallback(() => {
    onDeleteImage();
    setImageOverlayVisible(false);
  }, [onDeleteImage]);

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const openSearchOnlineModal = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'stock_images', from, bite_id: selectedBite?.id },
      }),
    );
    setMediaModalVisible(true);
  }, [dispatch, from, selectedBite?.id]);

  const onSelectStock = useCallback(
    (url: string) => {
      onSelectStockImage(url);
      handleCloseMediaModal();
    },
    [handleCloseMediaModal, onSelectStockImage],
  );

  if (!uri) {
    return null;
  }

  return (
    <S.Container style={style}>
      <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
      {!isLoading && (
        <S.Backdrop onPress={handleToggleOverlay}>
          {isImageOverlayVisible && (
            <S.ImageOverlay>
              <S.Content>
                {withModal ? (
                  <S.ImageAction onPress={handleAddImage}>
                    <ReplaceIcon />
                    <S.ImageActionName>{t('addQuestion.replaceImage')}</S.ImageActionName>
                  </S.ImageAction>
                ) : (
                  <>
                    <S.ImageAction onPress={openSearchOnlineModal}>
                      <StockIcon width={calcHeight(23)} height={calcHeight(23)} color={theme.colors.white} />
                      <S.ImageActionName>{t('mediaModal.stock')}</S.ImageActionName>
                    </S.ImageAction>
                    <S.ImageAction onPress={onSelectGallery}>
                      <GalleryIcon width={calcWidth(23)} height={calcHeight(23)} color={theme.colors.white} />
                      <S.ImageActionName>{t('mediaModal.gallery')}</S.ImageActionName>
                    </S.ImageAction>
                    {!isWeb && (
                      <S.ImageAction onPress={onSelectCamera}>
                        <CameraIcon width={calcWidth(23)} height={calcHeight(23)} color={theme.colors.white} />
                        <S.ImageActionName>{t('mediaModal.photo')}</S.ImageActionName>
                      </S.ImageAction>
                    )}
                  </>
                )}

                {typeof onDeleteImage === 'function' && (
                  <>
                    <S.Line />
                    <S.ImageAction onPress={handleDeleteImage}>
                      <DeleteIcon width={calcWidth(23)} height={calcHeight(23)} fill={theme.colors.white} />
                      <S.ImageActionName>{t('addQuestion.deleteImage')}</S.ImageActionName>
                    </S.ImageAction>
                  </>
                )}
              </S.Content>
            </S.ImageOverlay>
          )}
        </S.Backdrop>
      )}
      <S.Image resizeMode='cover' source={{ uri }} />

      <MediaModal
        onSelectStockImage={onSelectStock}
        onSelectGallery={onSelectGallery}
        onSelectCamera={onSelectCamera}
        onClose={handleCloseMediaModal}
        isVisible={isMediaModalVisible}
      />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    height: ${calcHeight(240)}px;
    border-radius: 18px;
    overflow: hidden;
  `,
  Image: styled.Image`
    flex: 1;
  `,
  ImageAction: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 50px;
    margin-left: ${calcWidth(9)}px;
  `,
  ImageActionName: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.white};
    margin-left: 7px;
  `,
  Content: styled.View`
    align-items: flex-start;
  `,
  Line: styled.View`
    border-color: ${({ theme }) => theme.colors.white};
    border-bottom-width: 1px;
    width: ${calcWidth(140)}px;
  `,
  ImageOverlay: styled.View`
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    justify-content: center;
    align-items: center;
  `,
  Backdrop: styled.TouchableOpacity`
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    flex: 1;
  `,
};

export default MediaImage;
