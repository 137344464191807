import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';

import { useSelector } from 'react-redux';

import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { IS_ANDROID } from '../../../utils/constants/env';
import { createShadow } from '../../../utils';
import EditPlaylistCoverButton from '../EditPlaylistCoverButton';
import { playlistInProgressSelector } from '../../../store/playlist/playlist.selectors';
import UploadingOverlay from '../../shared/UploadingOverlay';
import { EInProgressStatus, EInProgressTask } from '../../../types/common';
import PlaylistBite from '../PlaylistBite';

const BitesContainerWrapper: FC = ({}) => {
  const { editedPlaylist } = useSelector(createPlaylistSelector);
  const displayBitesLength = 4;
  const source = useMemo(() => ({ uri: editedPlaylist?.linked_cover_url }), [editedPlaylist?.linked_cover_url]);

  const playlistCoverUploadingTask = useSelector(playlistInProgressSelector(editedPlaylist?.id, EInProgressTask.COVER));

  const isLoading = playlistCoverUploadingTask?.status === EInProgressStatus.IN_PROGRESS;

  return (
    <S.Content>
      <S.BitesContainerWrap>
        <S.EditPlaylistContainer>
          <EditPlaylistCoverButton />
        </S.EditPlaylistContainer>

        {!source.uri ? (
          <S.BitesContainer>
            {(editedPlaylist?.bite_shares || []).slice(0, displayBitesLength).map((biteShare) => (
              <PlaylistBite biteShare={biteShare} />
            ))}
          </S.BitesContainer>
        ) : (
          <S.PlaylistImage source={source} />
        )}
      </S.BitesContainerWrap>
      <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
    </S.Content>
  );
};

const S = {
  EditPlaylistContainer: styled.View`
    display: flex;
    align-items: center;
    top: ${calcHeight(10)}px;
    left: ${calcHeight(40)}px;
    z-index: 1;
    position: absolute;
  `,
  BitesContainerWrap: styled.View`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100%;
  `,
  BitesContainer: styled.View`
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
  `,
  Content: styled.View`
    z-index: 1;
    position: relative;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.gray25};
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    align-self: center;
    padding-bottom: 0;
    height: ${calcHeight(361)}px;
    width: ${calcWidth(248)}px;
    ${IS_ANDROID
      ? createShadow()
      : `
      box-shadow: rgba(0, 0, 0, 0.2) -2px 2px 4px;
  `};
  `,
  PlaylistImage: styled.Image`
    position: absolute;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100%;
    height: ${calcHeight(361)}px;
  `,
};

export default BitesContainerWrapper;
