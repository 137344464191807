import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import ArrowTop from '../../../../assets/icons/arrow-collapse.svg';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';

const HeaderHandle = ({ isOpen }) => {
  const theme = useTheme();

  if (isWeb) {
    return (
      <S.ArrowTopContainer isOpened={isOpen}>
        <ArrowTop color={theme.colors.primaryBlue} />
      </S.ArrowTopContainer>
    );
  }

  return <S.Handle />;
};

const S = {
  Handle: styled.View`
    width: ${calcWidth(46)}px;
    height: ${calcHeight(4)}px;
    border-radius: ${calcWidth(48)}px;
    background-color: ${({ theme }) => theme.colors.gray7};
  `,
  ArrowTopContainer: styled.View<{ isOpened: boolean }>`
    width: ${calcWidth(30)}px;
    height: ${calcHeight(20)}px;
    ${({ isOpened }) => (isOpened ? 'transform: rotate(180deg);' : '')}
  `,
};

export default HeaderHandle;
