import BitesApi, { BASE_BACKEND_URL } from '..';

import {
  ICreateVideoServicePayload,
  IExportedVideo,
  IUpdateVideoRequest,
  IVideo,
  TSearchVideo,
} from '../../../videoEditor/videoEditor.types';
import { IDataTableRequest, IDataTableResult } from '../../../../types/common';

const VIDEO_BASE_URL = `${BASE_BACKEND_URL}/api/common_services/content/video`;
// const VIDEO_BASE_URL = `http://localhost:3000/api/common_services/content/video`;

const EXPORTED_VIDEO_BASE_URL = `${BASE_BACKEND_URL}/api/common_services/content/exported_video`;
// const EXPORTED_VIDEO_BASE_URL = `http://localhost:3000/api/common_services/content/exported_video`;

export const searchVideosService = async (
  payload: IDataTableRequest<{ orgId: number; isDraft: boolean }, undefined, { updateDate: -1 }>,
) => BitesApi.post<IDataTableResult<{ video: TSearchVideo }, undefined>>(`${VIDEO_BASE_URL}/search`, payload);

export const getVideoService = async ({ id, usedInBiteId }: { id?: IVideo['id']; usedInBiteId?: number }) =>
  BitesApi.get<{ video: IVideo }>(`${VIDEO_BASE_URL}`, {
    params: {
      id,
      usedInBiteId,
    },
  });

export const createVideoService = async (payload: ICreateVideoServicePayload) =>
  BitesApi.post<{ video: IVideo }>(`${VIDEO_BASE_URL}`, payload);

export const updateVideoService = async (payload: IUpdateVideoRequest) => BitesApi.patch(`${VIDEO_BASE_URL}`, payload);

export const createExportedVideoService = async (payload: { exportedVideo: IExportedVideo }) =>
  BitesApi.post<{ video: IExportedVideo }>(`${EXPORTED_VIDEO_BASE_URL}`, payload);

export const getExportedVideoService = async (id: IExportedVideo['id']) =>
  BitesApi.get<{ video: IExportedVideo }>(`${EXPORTED_VIDEO_BASE_URL}/${id}`);

export const searchVideosUsedInBites = async (
  payload: IDataTableRequest<
    {
      orgId: IVideo['orgId'];
      search: string;
    },
    undefined,
    {}
  >,
) =>
  BitesApi.post<IDataTableResult<{ video: TSearchVideo; biteId: number }, undefined>>(
    `${VIDEO_BASE_URL}/bite/search`,
    payload,
  );
