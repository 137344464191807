import VideoModal from '../../../components/shared/VideoModal';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  configSelector,
  isMobileStatsExplanationVideoWatchedSelector,
} from '../../../store/appActivity/appActivity.selectors';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-community/async-storage';
import { setIsMobileStatsExplanationVideoWatched } from '../../../store/appActivity/appActivity.slice';

const ExplanationVideoModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVideoWatched = useSelector(isMobileStatsExplanationVideoWatchedSelector);
  const [isVisible, setIsVisible] = useState(!isVideoWatched);
  const { mobileStatsIntroVideo } = useSelector(configSelector);
  const videoPlayerMethodsRef = useRef(null);

  const handleSkip = useCallback(async () => {
    videoPlayerMethodsRef.current?.pause();
    setIsVisible(false);

    dispatch(setIsMobileStatsExplanationVideoWatched(true));
    await AsyncStorage.setItem('mobileStatsIntroVideoWatched', 'true');
  }, [dispatch]);

  const handleOnMethods = useCallback((methods) => {
    videoPlayerMethodsRef.current = methods;
  }, []);

  return (
    <VideoModal
      isVisible={isVisible}
      videoUrl={mobileStatsIntroVideo}
      onBtnRightClick={handleSkip}
      btnRightLabel={t('common.Skip')}
      btnRightStyle='transparent'
      isMutedInitial={false}
      onEnd={handleSkip}
      onMethods={handleOnMethods}
      isAutoPlay
    />
  );
};

export default ExplanationVideoModal;
