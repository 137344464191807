import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/native';
import withRetry from '../../../utils/withRetry';
import Search from './Search.web';
import { searchPexelsWithUrl } from '../../../services/pexels';
import VideoModal from '../../../components/shared/VideoModal';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import searchPexelsVideos, { searchPexelsPopularVideos } from '../../../services/pexelsVideos';
import VideoSearchItem from './VideoSearchItem.web';
import { getVideoMetadata } from '../../../utils/getVideoMetadata.web';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import { videoResolutionSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import {
  ICloudAssetVideoCache,
  cloneCloudAssetCacheForVideoTimelineItem,
} from '../../../store/cloudAssets/cloudAssets.cache';
import { STOCK_FILE_TYPES, videoEditorPortal } from '../videoEditor.constants';
import { getVideoThumbnail } from '../../../utils/introMedia/useVideoThumbnail/useVideoThumbnail.web';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import { StockFilterControls } from './StockFilterControls.web';
import { IVideoConfig } from '../../../store/videoEditor/videoEditor.types';
import { getResizeTarget } from '../utils/getResizeTarget';
import { stockVideoResultsSelector } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useOrientation } from './utils/setOrientation';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { setStockVideoResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { FileTypeSelector } from './FileTypeSelector';

const StockVideos = () => {
  const dispatch = useDispatch();

  const resolution = useSelector(videoResolutionSeletor);
  const stockVideoResults = useSelector(stockVideoResultsSelector);

  const { orientation, setOrientation } = useOrientation();

  const requestIdRef = useRef(0);

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset?: boolean;
      withDelayedReset?: boolean;
    }) => {
      const requestId = ++requestIdRef.current;
      try {
        dispatch(
          setStockVideoResults({
            data: withReset ? null : stockVideoResults.data,
            next: withReset ? null : stockVideoResults.next,
            isLoading: true,
            error: null,
          }),
        );

        const service =
          stockVideoResults.next && !(withReset || withDelayedReset)
            ? () => searchPexelsWithUrl(stockVideoResults.next)
            : query
            ? () => searchPexelsVideos({ query, orientation })
            : () => searchPexelsPopularVideos();

        const result = await withRetry(service, {
          errorContext: {
            data: {
              action: 'StockVideos.handleSearch',
            },
          },
        });

        if (requestId !== requestIdRef.current) {
          return;
        }

        dispatch(
          setStockVideoResults({
            data: withReset || withDelayedReset ? result.videos : [...stockVideoResults.data, ...result.videos],
            next: result.next_page,
            isLoading: false,
          }),
        );
      } catch (error) {
        if (requestId !== requestIdRef.current) {
          return;
        }

        dispatch(
          logError({
            event: 'StockVideos.handleSearch: error',
            data: {
              error,
            },
          }),
        );

        dispatch(
          setStockVideoResults({
            data: withDelayedReset ? [] : stockVideoResults.data,
            next: withDelayedReset ? null : stockVideoResults.next,
            isLoading: false,
            error,
          }),
        );
      }
    },
    [dispatch, orientation, stockVideoResults.data, stockVideoResults.next],
  );

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const getItemKey = useCallback((item) => `${item.id}`, []);

  const handleVideoSelect = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  const handleVideoUnselect = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <VideoSearchItem
          url={item.image || null}
          item={item}
          // isLoading={isUploadingMap[item.id]}
          onSelect={handleVideoSelect}
        />
      );
    },
    [handleVideoSelect],
  );

  const pexelsVideo = useMemo(() => {
    if (selectedItem) {
      const resizeTarget = getResizeTarget(resolution);

      const _pexelsVideo = getPexelsVideo({
        videoFiles: selectedItem.video_files,
        resolution: resizeTarget,
      });

      return _pexelsVideo;
    }

    return null;
  }, [resolution, selectedItem]);

  const handleUseVideo = useCallback(async () => {
    if (!pexelsVideo) {
      return;
    }

    const processId = uuid();

    const timelineItemId = uuid();
    dispatch(
      addToTimeline({
        timelineItem: {
          id: timelineItemId,
          type: 'video',
          x: 0,
          y: 0,
        },
        duration: selectedItem.duration,
      }),
    );
    dispatch(
      updateTimelineItemLayer({
        timelineItemId,
      }),
    );
    dispatch(removeEmptyTimelineLayers());

    const undoId = uuid();
    dispatch(
      addUndo({
        id: undoId,
      }),
    );

    const processingLabel = 'Stock video';

    const handleFail = (error: any) => {
      dispatch(
        setProcessingTimelineItemState({
          timelineItemId,
          label: processingLabel,
          isProcessing: false,
          isDone: false,
          retryFunctionId,
          error: {
            details: error,
          },
        }),
      );
    };

    const retryFunctionId = uuid();
    const applyItemFunction = async () => {
      try {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            isProcessing: true,
            isDone: false,
            retryFunctionId,
          }),
        );

        // native
        // const { promise } = getLocalUrlPath(selectedItem.video_files[0].link, {
        //   withClearCache: false,
        //   localPath: 'cloudAssets', // to think about the path for each file
        // });
        // const localPath = await promise;

        setSelectedItem(null);

        const response = await fetch(pexelsVideo.link);
        const blob = await response.blob();

        const previewImageFile = await getVideoThumbnail(blob);

        const fileMeta = await getVideoMetadata({ blob });
        const fileName = pexelsVideo.link.split('/').pop().split('?')[0].split('#')[0];
        fileMeta.name = fileName;

        const file = new File([blob], fileName, {
          type: blob.type, // Preserves the MIME type
          lastModified: new Date().getTime(), // Converts the date to a timestamp
        });

        dispatch(
          createCloudAsset({
            processId,
            fileType: 'video',
            fileMeta,
            file,
            originalSrc: 'pexels',
            originalData: selectedItem,
            previewImageFile,
            onCacheReady: async ({ cloudAsset, cloudAssetCache }) => {
              try {
                dispatch(
                  removeUndoRedoById({
                    id: undoId,
                  }),
                );

                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    isProcessing: false,
                    isDone: true,
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    deleteProcessingTimelineItemState({
                      timelineItemId,
                    }),
                  );
                }, 5000);

                const isOnTimeline = getIsItemOnTimeline(timelineItemId);

                if (!isOnTimeline) {
                  return;
                }

                const canvasSize = getItemCanvasSize({
                  width: fileMeta.width,
                  height: fileMeta.height,
                  resolution,
                  scaleUp: true,
                });

                await cloneCloudAssetCacheForVideoTimelineItem(
                  cloudAssetCache as ICloudAssetVideoCache,
                  timelineItemId,
                );

                dispatch(
                  updateTimelineItem({
                    id: timelineItemId,
                    cloudAssetId: cloudAsset.id,
                    width: canvasSize.width,
                    height: canvasSize.height,
                  }),
                );

                dispatch(
                  updateTimelineItemWithCloudAssetDuration({
                    timelineItemId,
                  }),
                );

                dispatch(updateCanvas({}));
                dispatch(
                  addUndo({
                    id: undoId,
                  }),
                );
                dispatch(saveVideo({}));
              } catch (error) {
                handleFail(error);
              }
            },
            onFail: handleFail,
          }),
        );
      } catch (error) {
        handleFail(error);
      }
    };
    retryFunctions[retryFunctionId] = applyItemFunction;
    applyItemFunction();
  }, [dispatch, pexelsVideo, resolution, selectedItem]);

  const renderFilterControls = useCallback(
    ({ query }: { query: string }) => {
      return (
        <>
          <FileTypeSelector fileTypes={STOCK_FILE_TYPES} />
          <StockFilterControls query={query} orientation={orientation} setOrientation={setOrientation} />
        </>
      );
    },
    [orientation, setOrientation],
  );

  return (
    <>
      <Search
        results={stockVideoResults.data}
        isLoading={stockVideoResults.isLoading}
        isError={!!stockVideoResults.error}
        hasMore={!!stockVideoResults.next}
        withSearchInput
        layout='masonry'
        renderFilterControls={renderFilterControls}
        onSearch={handleSearch}
        renderItem={renderItem}
        getItemKey={getItemKey}
      />

      {selectedItem &&
        createPortal(
          <S.PreviewContainer>
            <VideoModal
              isInline
              videoUrl={pexelsVideo.link}
              btnRightLabel={'Use video'}
              btnRightStyle={'primary'}
              onBtnRightClick={handleUseVideo}
              btnLeftLabel={'Close'}
              btnLeftStyle={'secondary'}
              onBtnLeftClick={handleVideoUnselect}
            />
          </S.PreviewContainer>,
          videoEditorPortal.current,
        )}
    </>
  );
};
export default StockVideos;

const getPexelsVideo = ({
  videoFiles,
  resolution,
}: {
  videoFiles: any[];
  resolution: Partial<IVideoConfig['resolution']>;
}) => {
  const videoFilesSorted = [...videoFiles].sort((a, b) => a.height - b.height);

  const videoFile = videoFilesSorted.find((file) => {
    // we can take a bigger video, since afterwards it will be resized to our target
    if (
      (resolution.height && file.height >= resolution.height) ||
      (resolution.width && file.width >= resolution.width)
    ) {
      return file;
    }

    return false;
  });

  if (!videoFile) {
    return videoFilesSorted.slice(-1)[0];
  }

  return videoFile;
};

export const S = {
  PreviewContainer: styled.View`
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.transparentBlack2};
  `,
};
