const getPromiseSingleton = <T>(fn: () => Promise<T>): (() => Promise<T>) => {
  let promise = null;

  return () => {
    if (!promise) {
      promise = fn();
    }

    return promise;
  };
};

export default getPromiseSingleton;
