import { useSelector } from 'react-redux';
import useIsItemOwner from '../../hooks/useIsItemOwner';
import { isContentAdminSelector } from '../../store/auth/auth.selectors';
import { IBiteItem } from '../../types/bite';
import { IPlaylist } from '../../store/playlist/playlist.types';
import { IBiteShare } from '../../types/biteShare';

const useUserCanEdit = (data: IBiteItem | IBiteShare | IPlaylist) => {
  const isBiteOwner = useIsItemOwner(data);
  const isContentAdmin = useSelector(isContentAdminSelector);
  return isBiteOwner || isContentAdmin;
};

export default useUserCanEdit;
