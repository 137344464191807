import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components/native';
import { calcWidth } from '../../../utils/dimensions';
import { StyleProp, ViewStyle } from 'react-native';
import { last } from 'lodash';

interface IProps {
  style?: StyleProp<ViewStyle>;
  name?: string;
  photo?: string;
  size?: number;
  isGrayed?: boolean;
}

const OwnerInitials: React.FC<IProps> = ({ style, name, photo, size, isGrayed }) => {
  const getInitialsFromFullName = useCallback((fullName: string) => {
    const fullNameArr = fullName?.split(' ') || [''];

    if (fullNameArr.length <= 1) {
      return last(fullNameArr).charAt(0).toUpperCase();
    }

    const initials = `${fullNameArr[0].charAt(0)}${last(fullNameArr).charAt(0)}`;
    return initials.toUpperCase();
  }, []);

  const initials = useMemo(() => getInitialsFromFullName(name), [getInitialsFromFullName, name]);
  const photoSource = useMemo(() => ({ uri: photo }), [photo]);

  return (
    <S.OwnerContainer style={style} size={size} withBorder={!photo} isGrayed={isGrayed}>
      {photo ? <S.Photo size={size} source={photoSource} /> : <S.OwnerName isGrayed={isGrayed}>{initials}</S.OwnerName>}
    </S.OwnerContainer>
  );
};

const S = {
  OwnerContainer: styled.View<{ size: number; withBorder: boolean; isGrayed?: boolean }>`
    width: ${({ size }) => size || calcWidth(27)}px;
    height: ${({ size }) => size || calcWidth(27)}px;
    border-radius: ${calcWidth(27) / 2}px;
    border: ${({ withBorder }) => (withBorder ? 2 : 0)}px;
    border-color: ${({ theme, isGrayed }) => (isGrayed ? theme.colors.gray19 : theme.colors.black)}
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
  `,
  OwnerName: styled.Text<{ isGrayed?: boolean }>`
    color: ${({ theme, isGrayed }) => (isGrayed ? theme.colors.text : theme.colors.black)};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: bold;
    letter-spacing: -0.37px;
  `,
  Photo: styled.Image<{ size?: number }>`
    width: ${({ size }) => size || calcWidth(27)}px;
    height: ${({ size }) => size || calcWidth(27)}px;
  `,
};

export default OwnerInitials;
