import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';

import ShadowedContainer from '../../ShadowedContainer';
import { useNavigation } from '@react-navigation/native';
import { calcWidth } from '../../../utils/dimensions';
import BackIcon from '../../../assets/icons/creationFlow/back.svg';
import { I18nManager } from 'react-native';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import Routes from '../../../navigation/routes';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  onPress?: () => void;
  disabled?: boolean;
  dataSet?: { [key: string]: string };
}

const BackButton: FC<IProps> = ({ onPress, disabled, dataSet }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const { index, routes } = navigation.getState();
  const screenName = routes[index].name;
  const goBack = useCallback(() => {
    if (disabled) {
      return;
    }
    if (typeof onPress === 'function') {
      onPress();
      return;
    }
    dispatch(
      trackEvent({
        event: 'navigate_back',
        props: { page_title: screenName },
      }),
    );

    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [disabled, dispatch, navigation, onPress, screenName]);

  return (
    <ShadowedContainer>
      <S.BackButton
        onPress={goBack}
        disabled={disabled}
        //@ts-ignore
        dataSet={dataSet}
      >
        <BackIcon fill={theme.colors.primaryBlue} />
      </S.BackButton>
    </ShadowedContainer>
  );
};

const S = {
  BackButton: styled.TouchableOpacity<{
    disabled?: boolean;
  }>`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.darkGray3 : theme.colors.white)};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    transform: rotate(${I18nManager.isRTL ? '180deg' : '0deg'});
  `,
};

export default BackButton;
