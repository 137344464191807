import React from 'react';
import Modal from '../ModalController';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import InfoIcon from '../../../assets/icons/info-icon.svg';
import CloseIcon from '../../../assets/icons/close-modal.svg';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const SharePanelInfoModal: React.FC<IProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal onModalHide={onClose} onBackdropPress={onClose} isVisible={isVisible} backdropOpacity={0.4}>
      <S.Container>
        <S.CloseIconWrapper onPress={onClose}>
          <CloseIcon />
        </S.CloseIconWrapper>
        <S.InfoIconWrapper>
          <InfoIcon />
        </S.InfoIconWrapper>
        <S.TitleWrapper>
          <S.Title>{t('share.requireAnAccessCode')}</S.Title>
        </S.TitleWrapper>

        <S.Description>{t('share.requireAnAccessCodeInfo')}</S.Description>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    align-items: center;
    align-self: center;
    border-radius: 20px;

    width: ${calcWidth(isWeb ? 400 : deviceWidth - 66)}px;
    background: ${({ theme }) => theme.colors.lightGray13};
    padding: ${calcWidth(16)}px ${calcWidth(23)}px ${calcWidth(48)}px;
  `,
  Title: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s22};
    color: ${({ theme }) => theme.colors.darkGray9};
  `,
  TitleWrapper: styled.View`
    width: 100%;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.lightGray5};
    padding-bottom: ${calcHeight(16)}px;
  `,
  Description: styled.Text`
    text-align: center;
    margin-top: ${calcHeight(26)}px;
    color: ${({ theme }) => theme.colors.gray12};
    font-size: ${({ theme }) => theme.fontSizes.s15};
  `,
  InfoIconWrapper: styled.View`
    width: ${calcWidth(71)}px;
    height: ${calcWidth(71)}px;
    margin-bottom: ${calcHeight(26)}px;
  `,
  CloseIconWrapper: styled.TouchableOpacity`
    position: absolute;
    top: ${calcHeight(8)}px;
    right: ${calcWidth(8)}px;
    width: ${calcWidth(43)}px;
    height: ${calcWidth(43)}px;
  `,
};

export default SharePanelInfoModal;
