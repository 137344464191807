export const EditButtonDataSet = {
  'intercom-target': 'edit-btn',
  cy: 'bite-edition-btn',
};

export const playlistQuizEditButtonDataSet = {
  'intercom-target': 'edit-btn',
  cy: 'playlist-quiz-edition-btn',
};

export const deletePlaylistQuizButtonDataSet = { cy: 'delete-playlist-quiz-btn' };

export const confirmDeletePlaylistQuizButtonDataSet = { cy: 'confirm-delete-playlist-quiz-btn' };
