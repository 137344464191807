import React, { FC, useEffect } from 'react';
import CustomText from './CustomText';
import styled from 'styled-components/native';
import { calcHeight } from '../../utils/dimensions';

interface IProps {
  msg: string;
  cleanMsg?: () => void;
}

const ErrorMessage: FC<IProps> = ({ msg, cleanMsg }) => {
  useEffect(() => {
    const timeoutID = setTimeout(() => cleanMsg?.(), 3500);
    return () => clearTimeout(timeoutID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg]);

  return (
    <S.Container>
      <S.Message>{msg || ' '}</S.Message>
    </S.Container>
  );
};

const Container = styled.View`
	align-items: center;
	padding-top: ${calcHeight(15)}px;
	padding- bottom: ${calcHeight(15)}px;
`;

const Message = styled(CustomText)(
  ({ theme }) => `
color: ${theme.colors.failRed};
text-align: center;

`,
);

const S = { Message, Container };

export default ErrorMessage;
