import axios from 'axios';

// docs:
// https://developers.giphy.com/docs/api/endpoint#search
// https://developers.giphy.com/docs/api/schema#gif-object

export const GIPHY_API_KEY = 'fqBy42x7NzoRbrep7CDNUP2ou02dkAzQ';

interface IOptions {
  query: string;
  limit?: number;
  offset?: number;
  rating?: 'g' | 'pg' | 'pg-13' | 'r'; // https://developers.giphy.com/docs/optional-settings/#language-support
  lang?: string; // https://developers.giphy.com/docs/optional-settings/#language-support
  random_id?: string;
  bundle?: string;
}

export const searchGiphyGifs = async ({
  query,
  limit = 30,
  offset = 0,
  rating = 'g',
  lang,
  random_id,
  bundle,
}: IOptions) => {
  const { data } = await axios.get('https://api.giphy.com/v1/gifs/search', {
    params: {
      q: query,
      limit,
      offset,
      rating,
      lang,
      random_id,
      bundle,
      api_key: GIPHY_API_KEY,
    },
  });

  return data;
};

export const searchGiphyStickers = async ({ query, limit, offset, rating, lang, random_id, bundle }: IOptions) => {
  const { data } = await axios.get('https://api.giphy.com/v1/stickers/search', {
    params: {
      q: query,
      limit,
      offset,
      rating,
      lang,
      random_id,
      bundle,
      api_key: GIPHY_API_KEY,
    },
  });

  return data;
};

interface ITrendingOptions {
  limit?: number;
  offset?: number;
  rating?: 'g' | 'pg' | 'pg-13' | 'r'; // https://developers.giphy.com/docs/optional-settings/#language-support
  random_id?: string;
  bundle?: string;
}
export const searchGiphyTrendingGifs = async ({
  limit = 30,
  offset = 0,
  rating = 'g',
  random_id,
  bundle,
}: ITrendingOptions = {}) => {
  const { data } = await axios.get('https://api.giphy.com/v1/gifs/trending', {
    params: {
      limit,
      offset,
      rating,
      random_id,
      bundle,
      api_key: GIPHY_API_KEY,
    },
  });

  return data;
};

export const searchGiphyTrendingStickers = async ({
  limit,
  offset,
  rating,
  random_id,
  bundle,
}: ITrendingOptions = {}) => {
  const { data } = await axios.get('https://api.giphy.com/v1/stickers/trending', {
    params: {
      limit,
      offset,
      rating,
      random_id,
      bundle,
      api_key: GIPHY_API_KEY,
    },
  });

  return data;
};
