import { RootState } from '../index';

export const createPlaylistSelector = (state: RootState) => state.createPlaylist;

export const playlistIntroSelector = (state: RootState) =>
  state.createPlaylist.selectedBites.find((selectedBite) => selectedBite.playlist_section === 'intro');

export const selectedBitesSelector = (state: RootState) => createPlaylistSelector(state).selectedBites;

export const playlistSubjectSelector = (state: RootState) => createPlaylistSelector(state).subject;

export const selectedBiteIdsToRemoveMapSelector = (state: RootState) =>
  createPlaylistSelector(state).selectedBiteIdsToRemoveMap;

export const playlistSettingsSelector = (state: RootState) => createPlaylistSelector(state).playlistSettings;

export const playlistSettingsSelectorLinear = (state: RootState) =>
  createPlaylistSelector(state).playlistSettings.is_linear;

export const playlistDescriptionSelector = (state: RootState) => createPlaylistSelector(state).description;
