import React, { useMemo } from 'react';
import { useTheme, DefaultTheme } from 'styled-components/native';
import { View, StyleSheet, ViewStyle, StyleProp } from 'react-native';
//library
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';
//components
import CustomText from '../shared/CustomText';
//utils
import { calcFontSize, scale1 } from '../../utils/dimensions';
import createShadow from '../../utils/createShadow';

interface IProps {
  cellCount: number;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  style?: StyleProp<ViewStyle>;
}

const InputBoxes: React.FC<IProps> = ({ cellCount, value, onChange, style }) => {
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const ref = useBlurOnFulfill({ value, cellCount: cellCount });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: onChange,
  });

  return (
    <CodeField
      ref={ref}
      rootStyle={style}
      {...props}
      value={value}
      onChangeText={onChange}
      cellCount={cellCount}
      keyboardType='number-pad'
      renderCell={({ index, symbol, isFocused }) => {
        const additionalStyle = symbol || isFocused ? styles.FulfillOrFocusedCell : styles.emptyCell;
        return (
          <View style={isFocused && createShadow({ elevation: 6 })} key={index}>
            <CustomText
              key={index}
              style={[styles.cell, additionalStyle, isFocused && styles.focusedCell]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused && <Cursor />)}
            </CustomText>
          </View>
        );
      }}
    />
  );
};

const getStyles = (theme: DefaultTheme) => {
  const CELL_LENGTH = 65;
  return StyleSheet.create({
    cell: {
      height: scale1(CELL_LENGTH),
      width: scale1(CELL_LENGTH),
      lineHeight: scale1(CELL_LENGTH),
      fontSize: calcFontSize(24),
      borderWidth: 1,
      borderColor: theme.colors.darkPrimary,
      textAlign: 'center',
      borderRadius: 15,
      overflow: 'hidden',
      fontFamily: theme.fontFamilies.GilroyBold,
    },
    emptyCell: {
      opacity: 0.2,
    },
    focusedCell: {
      color: theme.colors.primaryBlue,
      fontFamily: theme.fontFamilies.LucidaGrande,
      fontSize: calcFontSize(28),
    },
    focusedCellWrapper: {
      ...createShadow({ elevation: 6 }),
    },
    FulfillOrFocusedCell: {
      backgroundColor: theme.colors.white,
      borderWidth: 0,
    },
  });
};

export default InputBoxes;
