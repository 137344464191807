import { useEffect, useRef, useState } from 'react';
import { fetchExploreBitesRequest } from '../store/explore/explore.slice';
import { loadNextPage } from '../store/feed/feed.slice';
import { fetchHasBites } from '../store/bite/bite.actions';
import { setHomeActiveTab } from '../store/homeScreen/homeScreen.slice';
import { Tabs } from '../store/homeScreen/homeScreen.types';
import { useDispatch, useSelector } from 'react-redux';
import { configSelector, externalActionSelector } from '../store/appActivity/appActivity.selectors';
import { isHasUserContentSelector, isLoadingSelector } from '../store/feed/feed.selectors';
import Intercom from '../services/intercom/intercom';
import { runInit } from '../store/appActivity/appActivity.slice';
import { userSelector } from '../store/auth/auth.selectors';
import { exploreBitesSelector } from '../store/explore/explore.selectors';

const useInitialData = (): { isLoading: boolean } => {
  const dispatch = useDispatch();

  const loadingRef = useRef(false);

  const config = useSelector(configSelector);
  const hasUserContent = useSelector(isHasUserContentSelector);
  const user = useSelector(userSelector);
  const externalAction = useSelector(externalActionSelector);

  const { isFetching: isExploreBitesFetching } = useSelector(exploreBitesSelector);
  const isFeedLoading = useSelector(isLoadingSelector);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (externalAction) {
      loadingRef.current = true;
      setIsLoading(false);
      return;
    }
    if (loadingRef.current && isLoading && !isFeedLoading && !isExploreBitesFetching) {
      setIsLoading(false);
      return;
    }
    loadingRef.current = isFeedLoading || isExploreBitesFetching;
  }, [isFeedLoading, isExploreBitesFetching, isLoading, externalAction]);

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    Intercom.registerIdentifiedUser({ userId: `${user?.id}` });
    dispatch(runInit());
  }, [dispatch, user?.id]);

  useEffect(() => {
    dispatch(loadNextPage({}));
    dispatch(fetchHasBites());
  }, [dispatch]);

  useEffect(() => {
    if (config.exploreBites.playlistId) {
      dispatch(fetchExploreBitesRequest());
    }
  }, [config.exploreBites.playlistId, dispatch]);

  useEffect(() => {
    if (!hasUserContent) {
      dispatch(setHomeActiveTab(Tabs.EXPLORE));
    }
  }, [dispatch, hasUserContent]);

  return {
    isLoading,
  };
};

export default useInitialData;
