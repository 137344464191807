import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';

const getIsInEditionStack = () => {
  const rootState = navigationRef.current?.getRootState();

  const level1 = rootState?.routes?.[rootState.index]?.state;
  const level2 = level1?.routes[level1?.index];

  return (
    level2?.name === Routes.EditBiteStack.StackName ||
    level2?.name === Routes.EditPlaylistStack.NameYourPlaylist ||
    level2?.name === Routes.EditQuizStack.StackName
  );
};
export default getIsInEditionStack;
