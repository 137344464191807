import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';
import store from '../../store';
import { trackEvent } from '../../store/appActivity/appActivity.slice';

const startQuizCreationFlow = () => {
  store.dispatch(
    trackEvent({
      event: 'new_quiz',
    }),
  );
  navigationRef.current.navigate(Routes.CreateQuizStack.StackName);
};
export default startQuizCreationFlow;
