import { ESortDirection } from '../../types/common';
import { EFeedSortField, IFeedObjectType } from '../feed/feed.types';

export const getFeedObjectTypeKey = (types) => types.join(',');
export const feedObjectTypeOptions = [
  {
    label: 'allTypes',
    types: [IFeedObjectType.all],
    key: '',
  },
  {
    label: 'bites',
    types: [IFeedObjectType.bite],
    key: '',
  },
  {
    label: 'playlists',
    types: [IFeedObjectType.playlist],
    key: '',
  },
  {
    label: 'quizzes',
    types: [IFeedObjectType.quiz],
    key: '',
  },
].map((option) => {
  return {
    ...option,
    key: getFeedObjectTypeKey(option.types),
  };
});

export const sortOptions = [
  {
    label: 'newestFirst',
    field: EFeedSortField.created_at,
    direction: ESortDirection.desc,
  },
  {
    label: 'name',
    field: EFeedSortField.subject,
    direction: ESortDirection.asc,
  },
];
