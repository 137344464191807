import AsyncStorage from '@react-native-community/async-storage';

import { SSO_IN_PROGRESS_LS_KEY } from './constants';

import store from '../../store';
import { setSsoInProgress } from '../../store/auth/auth.actions';

const setSsoInProgressService = async (isInProgress: boolean): Promise<void> => {
  store.dispatch(setSsoInProgress(isInProgress));
  await AsyncStorage.setItem(SSO_IN_PROGRESS_LS_KEY, `${isInProgress}`);
};

export default setSsoInProgressService;

init();

async function init() {
  const isSsoInProgress = JSON.parse(await AsyncStorage.getItem(SSO_IN_PROGRESS_LS_KEY));
  if (isSsoInProgress) {
    store.dispatch(setSsoInProgress(isSsoInProgress));
  }
}
