import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBiteItem } from '../../types/bite';
import { IBiteShare } from '../../types/biteShare';
import { IPlaylist } from '../playlist/playlist.types';
import { IChangeItemShareMode, ESharePanelCallbackType } from '../../types/sharePanel';

export enum EShareItemType {
  BITE = 'BITE',
  PLAYLIST = 'PLAYLIST',
}

export enum ESharePanelViewMode {
  FULL = 'FULL',
  VIEW_OPTIONS = 'VIEW_OPTIONS',
  FULLSCREEN = 'FULLSCREEN',
}

interface IState {
  isVisible: boolean;
  isHiding: boolean;
  callbackType: ESharePanelCallbackType | null;
  itemType: EShareItemType | null;
  data: IBiteItem | IBiteShare | IPlaylist | null;
  viewMode: ESharePanelViewMode;
  isInspirationBite: boolean | undefined;
  from?: string;
}

interface ISharePanelPayload {
  itemType: EShareItemType;
  data: IBiteItem | IBiteShare | IPlaylist;
  callbackType?: ESharePanelCallbackType;
  viewMode?: ESharePanelViewMode;
  isInspirationBite?: boolean;
  from?: string;
}

const initialState: IState = {
  isVisible: false,
  isHiding: false,
  data: null,
  itemType: null,
  callbackType: null,
  viewMode: ESharePanelViewMode.FULL,
  isInspirationBite: undefined,
};

const SHARE_PANEL = 'SHARE_PANEL';

export const changeBiteShareMode = createAction<IChangeItemShareMode>(`${SHARE_PANEL}/changeBiteShareMode`);
export const changePlaylistShareMode = createAction<IChangeItemShareMode>(`${SHARE_PANEL}/changePlaylistShareMode`);

const sharePanelSlice = createSlice({
  name: SHARE_PANEL,
  initialState,
  reducers: {
    showSharePanel: (state, { payload }: PayloadAction<ISharePanelPayload>) => {
      return {
        ...state,
        ...payload,
        isVisible: true,
      };
    },

    hideSharePanel: (state) => {
      if (state.isVisible) {
        state.isHiding = true;
      }
      state.isVisible = false;
    },

    sharePanelSetIsHiding: (state, { payload }: { payload: boolean }) => {
      state.isHiding = payload;
    },

    clearSharePanelState: () => {
      return initialState;
    },

    shareModeChangeSuccess: (state, { payload }: PayloadAction<IBiteItem | IPlaylist>) => {
      if (state.data === null) {
        return initialState;
      }

      state.data = payload;
    },

    shareModeChangeFail: (state) => {
      return state;
    },
  },
});

export const {
  hideSharePanel,
  showSharePanel,
  sharePanelSetIsHiding,
  clearSharePanelState,
  shareModeChangeSuccess,
  shareModeChangeFail,
} = sharePanelSlice.actions;

export default sharePanelSlice.reducer;
