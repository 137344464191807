import Routes from '../../navigation/routes';
import { getHomeScreen } from './common';

export const getCreateIntroRoute = () => ({
  index: 1,
  routes: [
    getHomeScreen(),
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteTellYourStory,
        params: {
          isPrefilled: true,
        },
      },
    },
  ],
});

export const getCreateSummaryRoute = () => ({
  index: 2,
  routes: [
    getHomeScreen(),
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteTellYourStory,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteEditQuestion,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.CreateSummaryNotes,
        params: {
          isPrefilled: true,
        },
      },
    },
  ],
});

export const getCreateEnhancementsRoute = () => ({
  index: 4,
  routes: [
    getHomeScreen(),
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteTellYourStory,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteEditQuestion,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.CreateSummaryNotes,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BitesMagic,
        params: {
          isPrefilled: true,
        },
      },
    },
  ],
});

export const getCreateQuestionRoute = () => ({
  index: 2,
  routes: [
    getHomeScreen(),
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteTellYourStory,
        params: {
          isPrefilled: true,
        },
      },
    },
    {
      name: Routes.CreateBiteStack.StackName,
      params: {
        screen: Routes.CreateBiteStack.BiteEditQuestion,
        params: {
          isPrefilled: true,
        },
      },
    },
  ],
});
