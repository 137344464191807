import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useOnWindowResize } from './useOnWindowResize.web';
import { useOnWindowScroll } from './useOnWindowScroll.web';
import { useOnResize } from './useOnResize.web';

export const useGetNodeRect = (nodeRef: MutableRefObject<HTMLElement>) => {
  const [counter, setCounter] = useState(0);

  const rectRef = useRef<DOMRect | null>(null);

  const resetRect = useCallback(() => {
    rectRef.current = null;
    setCounter((prev) => prev + 1);
  }, []);

  useOnWindowResize(resetRect);
  useOnWindowScroll(resetRect);
  useOnResize(nodeRef, resetRect);

  const getNodeRect = useCallback(() => {
    if (!nodeRef?.current) {
      rectRef.current = null;
      return null;
    }

    if (rectRef.current) {
      return rectRef.current;
    }

    rectRef.current = nodeRef.current.getBoundingClientRect();
    return rectRef.current;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeRef, counter]);

  return getNodeRect;
};
