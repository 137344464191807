import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'react-native-animatable';
import styled from 'styled-components/native';
import { ErrorMessage } from '../components/shared';
import { calcHeight, calcWidth } from '../utils/dimensions';

import { navigationRef } from '../navigation/RootNavigation';
import { DRAWER_FORM_WIDTH } from '../utils/constants/drawer';
import GenericButton from '../components/shared/buttons/GenericButton';
import { colors } from '../themes/defaultTheme';
import joinOrganization from '../utils/organization/joinOrganization';
import FormTextInput from '../components/shared/inputs/FormTextInput/FormTextInput';

interface IProps {
  onClose?: () => void;
}

const JoinOrganization: React.FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      await joinOrganization(code);

      if (typeof onClose === 'function') {
        onClose();
      }

      if (navigationRef.current.canGoBack()) {
        navigationRef.current.goBack();
      }
    } catch (err) {
      setError(t('joinOrganizationScreen.error'));
      setIsProcessing(false);
    }
  };

  const handleChangeCode = (_code: string) => {
    setCode(_code);
    setError('');
  };

  return (
    <S.Container>
      <S.FormContainer>
        <S.Subtitle>{t('joinOrganizationScreen.subtitle')}</S.Subtitle>
        <S.TextInput
          secureTextEntry
          value={code}
          onChangeText={handleChangeCode}
          placeholder={t('joinOrganizationScreen.placeholder')}
        />
        <S.SubmitButton
          disabled={!code || isProcessing}
          onPress={handleSubmit}
          content={t('joinOrganizationScreen.submit')}
          color={colors.white}
          fontSize={13}
          isbold
        />
      </S.FormContainer>
      <ErrorMessage msg={error} />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    height: 100%;
    align-items: center;
  `,
  FormContainer: styled.View`
    width: ${DRAWER_FORM_WIDTH}px;
    margin: ${calcHeight(24)}px ${calcWidth(24)}px 0 ${calcWidth(24)}px;
  `,
  TextInput: styled(FormTextInput)`
    width: ${DRAWER_FORM_WIDTH}px;
    height: ${calcHeight(50)}px;
    border-radius: ${calcHeight(50) / 2}px;
    justify-content: space-between;
    margin-bottom: ${calcHeight(24)}px;
  `,
  Subtitle: styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.darkGray1};
    margin: 0 0 ${calcHeight(17)}px;
  `,
  SubmitButton: styled(GenericButton)`
    min-width: ${DRAWER_FORM_WIDTH}px;
    align-self: center;
  `,
};

export default JoinOrganization;
