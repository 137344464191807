import React from 'react';

interface IProps {
  url: string;
}

const WebViewWeb: React.FC<IProps> = ({ url }) => {
  return <iframe frameBorder='0' width='100%' height='100%' src={url} allowFullScreen key={url} />;
};

export default WebViewWeb;
