import { IExternalAction } from '../../store/appActivity/appActivity.types';
import startBiteCreationFlow from '../bite/startBiteCreationFlow';
import startBiteCreationFlowFromInspirationBite from '../bite/startBiteCreationFlowFromInspirationBite';
import openPreviewPlaylistDashboard from '../playlist/openPreviewPlaylistDashboard';
import openPreviewQuizDashboard from '../quiz/openPreviewQuizDashboard';
import openPreviewPlaylist from '../playlist/openPreviewPlaylist';
import openPreviewQuiz from '../quiz/openPreviewQuiz';
import getIsInCreationStack from '../flow/getIsInCreationStack';
import getIsInEditionStack from '../flow/getIsInEditionStack';
import openEditBite from '../bite/openEditBite';
import openPreviewInspirationBite from '../bite/openPreviewInspirationBite';
import startPlaylistCreationFlow from '../playlist/startPlaylistCreationFlow';
import startQuizCreationFlow from '../quiz/startQuizCreationFlow';
import openShareBite from '../bite/openShareBite';
import openSharePlaylist from '../playlist/openSharePlaylist';
import openShareQuiz from '../quiz/openShareQuiz';
import joinOrganization from '../organization/joinOrganization';
import openResetPasswordForm from '../auth/openResetPasswordForm';
import openSignIn from '../auth/openSignIn';
import openSignUp from '../auth/openSignUp';
import store from '../../store';
import { log } from '../../store/appActivity/appActivity.slice';
import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';
import openFeedBite from '../bite/openFeedBite';

const runExternalAction = (data: IExternalAction) => {
  const isInCreationStack = getIsInCreationStack();
  const isInEditionStack = getIsInEditionStack();

  store.dispatch(
    log({
      event: 'runExternalAction',
      data: {
        data,
        isInCreationStack,
        isInEditionStack,
      },
    }),
  );

  if (!data || isInCreationStack || isInEditionStack) {
    return;
  }

  const { msg_type, bites_content_type, action } = data;

  // legacy
  let fn = actions[msg_type as string]?.[bites_content_type];
  if (typeof fn === 'function') {
    fn(data);
    return;
  }

  fn = actions[action];
  if (typeof fn === 'function') {
    fn(data);
  }
};
export default runExternalAction;

const actions = {
  joinOrganization: (data) => joinOrganization(data.code),

  createBite: startBiteCreationFlow,
  createPlaylist: startPlaylistCreationFlow,
  createQuiz: startQuizCreationFlow,

  previewBite: (data) => openFeedBite({ biteId: data.bite_id }),
  previewPlaylist: (data) => openPreviewPlaylist({ playlistId: data.playlist_id }),
  previewQuiz: (data) => openPreviewQuiz({ quizId: data.quiz_id }),

  editBite: (data) => openEditBite({ biteId: data.bite_id, section: data.section }),

  shareBite: (data) => openShareBite(data.bite_id),
  sharePlaylist: (data) => openSharePlaylist(data.playlist_id),
  shareQuiz: (data) => openShareQuiz(data.quiz_id),

  biteDashboard: (data) => openFeedBite({ biteId: data.bite_id, tab: data.tab || EAnalyticsScreenTabs.OVERVIEW }),
  playlistDashboard: (data) => openPreviewPlaylistDashboard(data.playlist_id, data.section),
  quizDashboard: (data) => openPreviewQuizDashboard(data.quiz_id, data.section),

  previewInspirationBite: (data) => openPreviewInspirationBite(data.bite_id),
  cloneInspirationBite: (data) =>
    startBiteCreationFlowFromInspirationBite({
      biteId: data.bite_id,
      section: data.section,
    }),
  createNewPassword: (data) => {
    openResetPasswordForm(data.recovery_token);
  },
  signin: () => {
    openSignIn();
  },
  signup: () => {
    openSignUp();
  },

  // legacy interface
  view: {
    bite: (data) => openFeedBite({ biteId: data.bites_content_id, tab: EAnalyticsScreenTabs.VIEWS }),
  },
  answer: {
    bite: (data) => openFeedBite({ biteId: data.bites_content_id, tab: EAnalyticsScreenTabs.ANSWERS }),
  },
  comment: {
    bite: (data) => openFeedBite({ biteId: data.bites_content_id, tab: EAnalyticsScreenTabs.COMMENTS }),
  },
  started: {
    playlist: (data) => openPreviewPlaylistDashboard(data.bites_content_id),
    quiz: (data) => openPreviewQuizDashboard(data.bites_content_id),
  },
  completed: {
    playlist: (data) => openPreviewPlaylistDashboard(data.bites_content_id),
    quiz: (data) => openPreviewQuizDashboard(data.bites_content_id),
  },
  inspiration: {
    bite: (data) => openPreviewInspirationBite(data.bites_content_id),
  },
};

export const unauthorizedExternalActionTypes = ['createNewPassword', 'signin', 'signup'];
