import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import { CustomText } from '../../components/shared';

interface IProps {
  headerTitle?: string;
}

export const ShortBlueHeader: FC<IProps> = ({ headerTitle }) => {
  return <ShortHeader>{headerTitle && <HeaderTitle>{headerTitle}</HeaderTitle>}</ShortHeader>;
};

export const ShortHeader = styled.View(
  // todo: rename to BlueHeader
  ({ theme }) => css`
    height: ${calcHeight(174)}px;
    width: 100%;
    padding-top: ${calcHeight(28)}px;
    align-items: center;
    background-color: ${theme.colors.primaryBlue};
  `,
);
export const HeaderTitle = styled(CustomText)(
  // todo: rename to BlueHeaderTitle
  ({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.s32}px;
    font-family: ${theme.fontFamilies.TTNormsMedium};
    text-align: center;
  `,
);

export const MainContentWrapper = styled.View`
  padding: 0 ${calcWidth(30)}px;
  align-items: center;
`;
