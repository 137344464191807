import { RootState } from '../index';

export const biteIdSelector = (state: RootState) => state.analytics.biteId;

export const answersQuestionSelector = (state: RootState) => state.analytics.aggregatedAnswers.data.question;

export const answersAggregatedSelector = (state: RootState) => state.analytics.aggregatedAnswers.data.aggregated;

export const overviewDataSelector = (state: RootState) => state.analytics.overview.data;

export const viewsDataSelector = (state: RootState) => state.analytics.views.data;

export const answersDataSelector = (state: RootState) => state.analytics.answers.data;

export const commentsDataSelector = (state: RootState) => state.analytics.comments.data;

export const overviewLoadingSelector = (state: RootState) => state.analytics.overview.isLoading;

export const viewsLoadingSelector = (state: RootState) => state.analytics.views.isLoading;

export const answersLoadingSelector = (state: RootState) => state.analytics.answers.isLoading;

export const commentsLoadingSelector = (state: RootState) => state.analytics.comments.isLoading;

export const overviewNextSelector = (state: RootState) => state.analytics.overview.next;

export const viewsNextSelector = (state: RootState) => state.analytics.views.next;

export const answersNextSelector = (state: RootState) => state.analytics.answers.next;

export const commentsNextSelector = (state: RootState) => state.analytics.comments.next;

export const overviewFilterSelector = (state: RootState) => state.analytics.overview.filter;

export const viewsFilterSelector = (state: RootState) => state.analytics.views.filter;

export const answersFilterSelector = (state: RootState) => state.analytics.answers.filter.answered;

export const answersIdsFilterSelector = (state: RootState) => state.analytics.answers.filter.answerIds;

export const commentsFilterSelector = (state: RootState) => state.analytics.comments.filter;

export const overviewErrorSelector = (state: RootState) => state.analytics.overview.error;

export const viewsErrorSelector = (state: RootState) => state.analytics.views.error;

export const answersErrorSelector = (state: RootState) => state.analytics.answers.error;

export const commentsErrorSelector = (state: RootState) => state.analytics.comments.error;

export const answersAggregatedLoadingSelector = (state: RootState) => state.analytics.aggregatedAnswers.isLoading;

export const answersAggregatedErrorSelector = (state: RootState) => state.analytics.aggregatedAnswers.error;

export const currentListSelector = (state: RootState) => state.analytics.currentList;

export const isRefreshingSelector = (state: RootState) => state.analytics.isRefreshing;

export const isNeedToScrollUpOverviewSelector = (state: RootState) => state.analytics.overview.isNeedToScrollUp;

export const isNeedToScrollUpViewsSelector = (state: RootState) => state.analytics.views.isNeedToScrollUp;

export const isNeedToScrollUpAnswersSelector = (state: RootState) => state.analytics.answers.isNeedToScrollUp;

export const isNeedToScrollUpCommentsSelector = (state: RootState) => state.analytics.comments.isNeedToScrollUp;

export const isFilterBottomSheetOpenSelector = (state: RootState) => state.analytics.isFilterBottomSheetOpen;

export const hasDistributionsSelector = (state: RootState) => state.analytics.hasDistributions;

export const isFilterTooltipShownSelector = (state: RootState) => state.analytics.isFilterTooltipShown;
