import Types from './icons.types';
import { IIcon } from './icons.types';

export const fetchIconsRequest = () => ({
  type: Types.FETCH_ICONS_REQUEST,
});

export const fetchIconsSuccess = (icons: IIcon[]) => ({
  type: Types.FETCH_ICONS_SUCCUESS,
  payload: icons,
});

export const searchIconsRequest = (query: string) => ({
  type: Types.SEARCH_ICONS_REQUEST,
  payload: query,
});

export const searchIconsSuccess = (icons: IIcon[]) => ({
  type: Types.SEARCH_ICONS_SUCCESS,
  payload: icons,
});

export const clearSearchResults = () => ({
  type: Types.CLEAR_SEARCH_RESULTS,
});
