// import { useCallback, useEffect } from 'react';
// import gtmTrack from '../services/gtm/track';
// import { isWeb } from '../utils/dimensions';

// const webBroadcastChannel = (
// 	isWeb &&
// 	typeof BroadcastChannel !== 'undefined' &&
// 	new BroadcastChannel('sw-messages')
// );

const useSWMessages = () => {
  // const callback = useCallback(({ data }) => {
  // 	if (data === 'background-message-received') {
  // 		gtmTrack('push_notification_received', { is_background: true });
  // 	}
  // }, []);
  // useEffect(() => {
  // 	if (webBroadcastChannel) {
  // 		webBroadcastChannel?.addEventListener('message', callback);
  // 		return webBroadcastChannel?.removeEventListener('message', callback);
  // 	}
  // }, [callback]);
};

export default useSWMessages;
