const Types = {
  ADD_SUMMARY_CARD: 'ADD_SUMMARY_CARD',
  DELETE_SUMMARY_CARD: 'DELETE_SUMMARY_CARD',
  REMOVE_SUMMARY_CARD: 'REMOVE_SUMMARY_CARD',
  SET_SUMMARY_CARD: 'SET_SUMMARY_CARD',
  FILTER_EMPTY_SUMMARY_CARDS: 'FILTER_EMPTY_SUMMARY_CARDS',
  SET_SUMMARY_CARDS: 'SET_SUMMARY_CARDS',
  SET_CREATED_SUMMARY: 'SET_CREATED_SUMMARY',
  CLEAR_STATE: 'CLEAR_STATE',
};

export default Types;
