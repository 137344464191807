import { AppState, AppStateStatus } from 'react-native';
import { useCallback, useEffect, useRef } from 'react';

export enum EAppState {
  active = 'active',
  background = 'background',
  inactive = 'inactive',
  launch = 'launch',
}

const useOnAppStateChange = (callback: (nextAppState: EAppState) => void) => {
  const previousAppState = useRef(AppState.currentState);

  const handleAppStateChange = useCallback(
    (nextAppState: AppStateStatus) => {
      if (nextAppState !== previousAppState.current) {
        callback(EAppState[nextAppState]);
      }

      previousAppState.current = nextAppState;
    },
    [callback],
  );

  useEffect(() => {
    callback(EAppState.launch);
    const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, [callback, handleAppStateChange]);
};

export default useOnAppStateChange;
