import React from 'react';
import Button, { IButtonProps } from './Button';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth, isWeb } from '../../../utils/dimensions';
// import NextArrow from '../../../assets/icons/next.svg';
import ShareIC from '../../../assets/icons/share-ic.svg';
import { useTranslation } from 'react-i18next';
import { I18nManager } from 'react-native';

const ShareButton: React.FC<IButtonProps> = ({ content, longSize, inverted, ...rest }) => {
  const { t } = useTranslation();
  return (
    <S.Button
      content={content || t('common.shareBite')}
      renderedIcon={<ShareIcon />}
      longSize={longSize}
      inverted={inverted}
      iconPosition='right'
      {...rest}
    />
  );
};

export default ShareButton;

const ShareIcon: React.FC<{ inverted?: boolean }> = ({ inverted }) => {
  const theme = useTheme();

  return (
    <S.IconWrapper
      inverted={inverted}
      style={{
        transform: [{ rotateY: I18nManager.isRTL ? '180deg' : '0deg' }],
      }}
    >
      <ShareIC width={calcWidth(10)} height={calcWidth(10)} stroke={theme.colors.primaryBlue} />
    </S.IconWrapper>
  );
};

const S = {
  IconWrapper: styled.View<{ inverted?: boolean }>`
    border-radius: 100px;
    background: #fff;
    align-items: center;
    justify-content: center;
    height: ${calcWidth(18)}px;
    width: ${calcWidth(18)}px;
    margin: 5px;
  `,
  Button: styled(Button)<{ longSize?: boolean }>`
    max-width: ${({ longSize }) => (longSize ? '100%' : '200px')};
    ${() => isWeb && 'min-width: 200px'};
    align-self: center;
  `,
};
