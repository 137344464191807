import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components/native';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { calcWidth, calcHeight, deviceWidth } from '../../../utils/dimensions';
import { isWeb } from '../../../utils/dimensions';
import { IS_ANDROID } from '../../../utils/constants/env';
import { createShadow } from '../../../utils';
import Modal from '../../modals/ModalController';
import WebView from '../../WebView';
import CircleButton, { EButtonIcon } from '../../shared/buttons/CircleButton';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { webViewModalCloseButtonDataSet, webViewModalDataSet } from './WebViewModal.constants';

interface IProps {
  url: string;
  isVisible?: boolean;
  onModalHide?: () => void;
  onClose?: () => void;
  controlsPanel?: ReactNode;
  topPanel?: ReactNode;
  withCloseIcon?: boolean;
  topInsetColor?: string;
  bottomInsetColor?: string;
}

const WebViewModal: React.FC<IProps> = ({
  url,
  onModalHide,
  isVisible = true,
  controlsPanel,
  topPanel,
  withCloseIcon,
  onClose,
  topInsetColor = 'white',
  bottomInsetColor = 'white',
}) => {
  const safeAreaInsets = useSafeAreaInsets();

  const customBackdrop = useMemo(() => {
    return (
      !isWeb && (
        <S.Backdrop activeOpacity={1}>
          <View style={StyleSheet.absoluteFill} />
        </S.Backdrop>
      )
    );
  }, []);

  return (
    <>
      <S.Modal
        isVisible={isVisible}
        backdropTransitionOutTiming={0}
        onModalHide={onModalHide}
        customBackdrop={customBackdrop}
      >
        <S.Container
          //@ts-ignore
          dataSet={webViewModalDataSet}
        >
          <S.WebViewWrap2>
            {!!topInsetColor && <S.TopView height={safeAreaInsets.top} backgroundColor={topInsetColor} />}
            {topPanel}
            <S.WebViewWrap>
              {withCloseIcon && (
                <CircleButton
                  dataSet={webViewModalCloseButtonDataSet}
                  style={S.CloseButtonStyles}
                  onPress={onClose}
                  icon={EButtonIcon.CLOSE}
                />
              )}
              <WebView url={url} />
              {controlsPanel}
              {!!bottomInsetColor && <S.BottomView height={safeAreaInsets.bottom} backgroundColor={bottomInsetColor} />}
            </S.WebViewWrap>
          </S.WebViewWrap2>
        </S.Container>
      </S.Modal>
    </>
  );
};

const S = {
  Modal: styled(Modal)`
    margin: 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
  `,
  Backdrop: styled(TouchableOpacity)`
    flex: 1;
  `,
  Container: styled.View`
    flex-grow: 1;
    align-items: center;
  `,
  WebViewWrap2: styled(View)`
    flex-grow: 1;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(500)}px` : `${deviceWidth}px`};
  `,
  WebViewWrap: styled(View)`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
  `,
  ControlsPanelWrap: styled.View`
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: ${calcHeight(40)}px;
    flex-direction: row;
    align-items: center;
    width: 0px;
    overflow: visible;
  `,
  ButtonView: styled.View`
    align-items: flex-end;
    top: 20;
    right: 20;
  `,
  ControlsPanel: styled.View`
    position: absolute;
    right: 0px;
    align-items: center;
    padding: ${calcHeight(16)}px;
    padding-left: ${calcHeight(18)}px;
    padding-right: ${calcHeight(16)}px;
    width: ${calcWidth(70)};
    border-top-left-radius: 36px;
    border-bottom-left-radius: 36px;
    background-color: ${({ theme }) => theme.colors.lightBlue};
    ${IS_ANDROID
      ? createShadow()
      : `
			box-shadow: rgba(0, 0, 0, 0.4) -2px 2px 4px;
		`}
  `,
  CloseButtonStyles: {
    position: 'absolute',
    top: calcHeight(8),
    right: calcWidth(30),
    width: calcWidth(30),
    height: calcWidth(30),
    zIndex: 1,
  } as StyleProp<ViewStyle>,
  TopView: styled.View<{ height?: number; backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    height: ${({ height }) => height || 0}px;
  `,
  BottomView: styled.View<{ height?: number; backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    height: ${({ height }) => height || 0}px;
  `,
};

export default WebViewModal;
