import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalRN, { IModalProps } from './Modal';
import getModalCounter from '../../store/modalController/modalController.counter';
import {
  removeFromStack,
  addToStack,
  setIsVisibleProp,
  setIsVisibleInStack,
} from '../../store/modalController/modalController.slice';
import { visibleModalSelector, currentModalSelector } from '../../store/modalController/modalController.selectors';
import { modalDataSet } from './modalController.constants';

const Modal: React.FC<IModalProps> = ({ children, isVisible, onModalHide, ...props }) => {
  const dispatch = useDispatch();
  const visibleModal = useSelector(visibleModalSelector);
  const currentModal = useSelector(currentModalSelector);

  const modalId = useMemo(() => getModalCounter(), []);

  // modal component states
  const isWaitingOtherToHide =
    currentModal?.modalId === modalId && visibleModal?.modalId !== modalId && visibleModal !== null;

  const isStartingToShow = currentModal?.modalId === modalId && visibleModal === null;

  const isCurrentShowing = currentModal?.modalId === modalId && visibleModal?.modalId === modalId;

  // not used currently, just describing the relevant conditions
  // const isHiding = (
  //     currentModal?.modalId !== modalId &&
  //     visibleModal?.modalId === modalId
  // );

  const isHidden = currentModal?.modalId !== modalId && visibleModal?.modalId !== modalId;
  const isRenderModal = !(isWaitingOtherToHide || isHidden);

  const isRenderModalRef = useRef(isRenderModal);
  isRenderModalRef.current = isRenderModal;

  const handleOnModalShow = useCallback(() => {
    if (!isRenderModalRef.current) {
      return;
    }

    dispatch(
      setIsVisibleInStack({
        modalId,
        isVisible: true,
      }),
    );
  }, [modalId, dispatch]);

  const handleOnModalHide = useCallback(() => {
    dispatch(
      setIsVisibleInStack({
        modalId,
        isVisible: false,
      }),
    );
    onModalHide?.();
  }, [modalId, onModalHide, dispatch]);

  useEffect(() => {
    dispatch(
      addToStack({
        modalId,
        isVisibleProp: isVisible,
      }),
    );
    return () => {
      dispatch(removeFromStack(modalId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setIsVisibleProp({
        modalId,
        isVisibleProp: isVisible,
      }),
    );
  }, [dispatch, isVisible, modalId]);

  if (!isRenderModal) {
    return null;
  }
  return (
    <ModalRN
      dataSet={isCurrentShowing ? modalDataSet : undefined}
      isVisible={isStartingToShow || isCurrentShowing}
      onModalHide={handleOnModalHide}
      onModalShow={handleOnModalShow}
      {...props}
    >
      {children}
    </ModalRN>
  );
};

export default Modal;
