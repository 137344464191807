import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useOpenShareBiteLink, { getOpenShareBiteLinkParams } from '../../../hooks/openShareLinks/useOpenShareBiteLink';
import useOpenSharePlaylistLink, {
  getOpenSharePlaylistLinkParams,
} from '../../../hooks/openShareLinks/useOpenSharePlaylistLink';
import useIsItemOwner from '../../../hooks/useIsItemOwner';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { IPlaylist } from '../../../store/playlist/playlist.types';
import { clearSharePanelState, EShareItemType, hideSharePanel } from '../../../store/sharePanel/sharePanel.slice';
import { IBiteItem } from '../../../types/bite';
import { IBiteShare } from '../../../types/biteShare';
import { EShareMode } from '../../../utils/constants/bite';
import { sharePanelIsHidingSelector, sharePanelVisibleSelector } from '../../../store/sharePanel/sharePanel.selectors';
import { getBiteId, getBiteShareId } from '../../../utils/formatDataFromServer';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { LinkParams } from '../../../hooks/openShareLinks/types';
import { isWeb } from '../../../utils/dimensions';
import { checkNotificationsStatus } from '../../../services/notifications';
import { isRequestNotificationPermissionsModalShownSelector } from '../../../store/appActivity/appActivity.selectors';
import { useIsMounted } from '../../../hooks/useIsMounted';

interface IProps {
  shareData: IBiteItem | IBiteShare | IPlaylist | null;
  shareItemType: EShareItemType;
  shareMode?: EShareMode;
  isInspirationBite?: boolean;
  from?: string;
}

interface IResult {
  isOwner: boolean;
  shareModeTitle: string;
  shareModeDescription: string;
  hasTeamsShare: boolean;
  handleShareItem: () => Promise<void>;
  isAwaitingOpenTeams: boolean;
  isShareWithTeamsModalOpen: boolean;
  isRequestNotificationPermissionsModalOpen: boolean;
  handleOpenNotificationPermissionModal: () => void;
  handleCloseNotificationPermissionModal: () => void;
  handleOpenTeamsModal: () => void;
  handleCloseTeamsModal: () => void;
  shareLinkParams: LinkParams;
}

export const useShare = ({ shareData, shareMode, shareItemType, isInspirationBite, from }: IProps): IResult => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();
  const { t } = useTranslation();

  const openShareBiteLink = useOpenShareBiteLink();
  const openSharePlaylistLink = useOpenSharePlaylistLink();

  const biteLinkParams = useMemo(
    () =>
      shareData
        ? getOpenShareBiteLinkParams({
            biteShareId: getBiteShareId(shareData as IBiteItem),
            biteSubject: shareData.subject,
            t,
          })
        : null,
    [shareData, t],
  );

  const playlistLinkParams = useMemo(
    () =>
      shareData
        ? getOpenSharePlaylistLinkParams({
            playlist: shareData as IPlaylist,
            subject: shareData.subject,
            t,
          })
        : null,
    [shareData, t],
  );

  const organization = useSelector(activeOrganizationSelector);
  const isVisibleSharePanel = useSelector(sharePanelVisibleSelector);
  const sharePanelIsHiding = useSelector(sharePanelIsHidingSelector);
  const isRequestNotificationPermissionsModalShown = useSelector(isRequestNotificationPermissionsModalShownSelector);

  const isOwner = useIsItemOwner(shareData);
  const hasTeamsShare =
    organization.is_teams_related &&
    (shareItemType === EShareItemType.BITE || shareItemType === EShareItemType.PLAYLIST);

  const [isShareWithTeamsModalOpen, setShareWithTeamsModalState] = useState(false);
  const [isRequestNotificationPermissionsModalOpen, setIsRequestNotificationPermissionsModalOpen] = useState(false);
  const [isAwaitingOpenTeams, setIsAwaitingOpenTeams] = useState(false);

  const handleOpenNotificationPermissionModal = useCallback(async () => {
    if (isWeb || isRequestNotificationPermissionsModalShown || !isMountedRef.current) {
      return;
    }

    const { isDetermined: isNotificationsDetermined } = await checkNotificationsStatus();

    setIsRequestNotificationPermissionsModalOpen(!isNotificationsDetermined);
  }, [isMountedRef, isRequestNotificationPermissionsModalShown]);

  const handleCloseNotificationPermissionModal = useCallback(() => {
    setIsRequestNotificationPermissionsModalOpen(false);
  }, []);

  const handleOpenTeamsModal = useCallback(() => {
    setIsAwaitingOpenTeams(true);
    dispatch(hideSharePanel());
  }, [dispatch]);

  const handleCloseTeamsModal = useCallback(() => {
    setShareWithTeamsModalState(false);
    dispatch(clearSharePanelState());
  }, [dispatch]);

  const shareModeTitle = useMemo(() => {
    const mode = shareMode || shareData?.sharing_mode;

    switch (mode) {
      case EShareMode.ANYONE:
        return t('share.shareTypeAnyoneTitle');
      case EShareMode.REQUIRE_LOGIN:
      case EShareMode.TEAM_ONLY_ALLOW_JOIN:
        return t('share.shareTypeRequireLoginTitle');
      case EShareMode.TEAM_ONLY:
        return t('share.shareTypeTeamOnlyTitle');
      case EShareMode.NO_ONE:
        return t('share.shareTypeNoOneTitle');
      default:
        return 'None';
    }
  }, [shareData, shareMode, t]);

  const shareModeDescription = useMemo(() => {
    const mode = shareMode || shareData?.sharing_mode;

    switch (mode) {
      case EShareMode.ANYONE:
        return t('share.shareTypeAnyoneDesc');
      case EShareMode.REQUIRE_LOGIN:
      case EShareMode.TEAM_ONLY_ALLOW_JOIN:
        return t('share.shareTypeRequireLoginDesc');
      case EShareMode.TEAM_ONLY:
        return t('share.shareTypeTeamOnlyDesc');
      case EShareMode.NO_ONE:
        return t('share.shareTypeNoOneDesc');
      default:
        return 'None';
    }
  }, [shareData, shareMode, t]);

  const handleShareBite = useCallback(async () => {
    const data = shareData as IBiteItem | IBiteShare;

    dispatch(
      trackEvent({
        event: 'share',
        props: { bite_id: getBiteId(data), inspiration_bite: isInspirationBite, from },
      }),
    );

    await openShareBiteLink({
      biteShareId: getBiteShareId(data),
      biteSubject: data.subject,
    });
  }, [shareData, dispatch, isInspirationBite, from, openShareBiteLink]);

  const handleSharePlaylist = useCallback(async () => {
    const data = shareData as IPlaylist;

    const trackObject = {
      ...(data.is_quiz ? { quiz_id: data.id } : { playlist_id: data.id }),
      from,
    };

    dispatch(
      trackEvent({
        event: 'share',
        props: trackObject,
      }),
    );

    await openSharePlaylistLink({ playlist: shareData, subject: data.subject });
  }, [shareData, from, dispatch, openSharePlaylistLink]);

  const handleShareItem = useCallback(async () => {
    await (shareItemType === EShareItemType.BITE ? handleShareBite() : handleSharePlaylist());
    handleOpenNotificationPermissionModal();

    dispatch(hideSharePanel());
  }, [dispatch, handleOpenNotificationPermissionModal, handleShareBite, handleSharePlaylist, shareItemType]);

  useEffect(() => {
    if (!isVisibleSharePanel && !sharePanelIsHiding && isAwaitingOpenTeams) {
      setIsAwaitingOpenTeams(false);
      setShareWithTeamsModalState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleSharePanel, sharePanelIsHiding]);

  return {
    isOwner,
    hasTeamsShare,
    shareModeTitle,
    shareModeDescription,
    isAwaitingOpenTeams,
    isShareWithTeamsModalOpen,
    isRequestNotificationPermissionsModalOpen,
    handleCloseNotificationPermissionModal,
    handleOpenNotificationPermissionModal,
    handleCloseTeamsModal,
    handleOpenTeamsModal,
    handleShareItem,
    shareLinkParams: shareItemType === EShareItemType.BITE ? biteLinkParams : playlistLinkParams,
  };
};
