import { getSingleOrMultiple } from './getSingleOrMultiple';

export const getCreatedDateString = (creationDate: Date) => {
  const currentDate = new Date();

  const diffInMs = currentDate.getTime() - creationDate.getTime();

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  let result = '';

  if (years > 0) {
    result = `Created ${years} ${getSingleOrMultiple(years, 'year', 'years')} ago`;
  } else if (months > 0) {
    result = `Created ${months} ${getSingleOrMultiple(months, 'month', 'months')} ago`;
  } else if (days > 0) {
    result = `Created ${days} ${getSingleOrMultiple(days, 'day', 'days')} ago`;
  } else if (hours > 0) {
    result = `Created ${hours} ${getSingleOrMultiple(hours, 'hour', 'hours')} ago`;
  } else if (minutes > 0) {
    result = `Created ${minutes} ${getSingleOrMultiple(minutes, 'minute', 'minutes')} ago`;
  } else {
    result = `Created ${seconds} ${getSingleOrMultiple(seconds, 'second', 'seconds')} ago`;
  }

  return result;
};
