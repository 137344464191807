import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { calcHeight } from '../../../../utils/dimensions';
import { SettingItem } from '../../../../components/shared';
import { TFunction } from 'i18next';
import { playlistSettingsSelector } from '../../../../store/createPlaylist/createPlaylist.selectors';
import { IPlaylistSettings } from '../../../../store/createPlaylist/createPlaylist.types';
import { updatePlaylistSettings } from '../../../../store/createPlaylist/createPlaylist.actions';

interface IProps {}

const getSettingText = (t: TFunction, key: keyof IPlaylistSettings) => {
  const localePrefix = 'createPlaylistStack.playlistSettings.settings';
  const biteSettingTextMapper = {
    is_public: t(`${localePrefix}.public`),
    is_linear: t(`${localePrefix}.linearOrder`),
  };
  return biteSettingTextMapper[key];
};

const BiteSettings: FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const playlistSettings = useSelector(playlistSettingsSelector);
  const { t } = useTranslation();

  const booleanSettings = useMemo(() => {
    return Object.entries(playlistSettings).map(([key, value]) => ({
      isSelected: value,
      settingText: getSettingText(t, key as keyof IPlaylistSettings),
      onTogglePress: () => {
        dispatch(updatePlaylistSettings(key as keyof IPlaylistSettings));
      },
    }));
  }, [dispatch, playlistSettings, t]);
  return (
    <S.Container>
      {booleanSettings.map((setting) => (
        <SettingItem key={setting.settingText} {...setting} />
      ))}
    </S.Container>
  );
};

const Container = styled.View`
  width: 100%;
  margin-top: ${calcHeight(50)}px;
  margin-bottom: ${calcHeight(32)}px;
`;

const S = { Container };

export default BiteSettings;
