import React, { FC } from 'react';
import styled from 'styled-components/native';
import { calcHeight } from '../../utils/dimensions';
import EditScreenButtonComponent, { EditScreenButton } from './EditScreenButton';

interface IProps {
  buttons: EditScreenButton[];
}

const EditScreenButtons: FC<IProps> = ({ buttons }) => {
  return (
    <S.Container>
      {buttons.map((btn) => (
        <EditScreenButtonComponent button={btn} key={btn.title} />
      ))}
    </S.Container>
  );
};

const Container = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  max-width: 385px;
  margin-bottom: ${calcHeight(22)}px;
`;

const S = { Container };

export default EditScreenButtons;
