import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { createBiteSelector } from '../../../store/createBite/createBite.selectors';
import { useTranslation } from 'react-i18next';
import BiteSettings from '../../creationBite/common/BiteSettingsModal';
import {
  setBiteCover,
  setBiteCoverFromUrl,
  setBiteName,
  updateBiteSettings,
} from '../../../store/createBite/createBites.actions';
import { updateBiteData } from '../../../store/api/bites-api/calls/bite.calls';
import useMedia, { formatFileTypesForWeb } from '../../../hooks/useMedia';
import uploadCover from '../../../utils/uploadImage/uploadImage';
import { biteSelector, selectedBiteSelector } from '../../../store/bite/bite.selectors';
import { KeyboardAwareView } from '../../../components/shared';
import { TouchableOpacity, View } from 'react-native';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import SectionItem, { EditBiteIconType } from './common/SectionItem';
import { isEmpty } from 'lodash';
import {
  deleteBite,
  fetchBiteToEditRequest,
  indexBites,
  prepareBiteEditScreen,
  setBites,
} from '../../../store/bite/bite.actions';
import { TBiteSection } from '../../../types/bite';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import Header from '../../../components/Header';
import getHasEnhancementsEdit from '../../../utils/introMedia/getHasEnhancementsEdit';
import CreationAnimationScreen from '../../../components/shared/CreationAnimationScreen';
import { IStackNavigation } from '../../../navigation/types';
import MediaModal from '../../creationBite/common/MediaModal';
import BiteCoverImage from '../../../components/shared/BiteCoverImage';
import ShareSection from '../../creationBite/common/ShareSection';
import PointsButton from '../../../components/shared/buttons/PointsButton';
import Routes from '../../../navigation/routes';
import HomeButton from '../../../components/shared/HomeButton';
import EditNameInput from '../../../components/EditNameInput';
import { loadNextPage } from '../../../store/feed/feed.slice';
import { loadBiteHelperConfigs, logError, trackEvent } from '../../../store/appActivity/appActivity.slice';
import Tooltip from '../../../components/Tooltip/Tooltip';
import Button from '../../../components/shared/buttons/Button/Button';
import openPreviewBite from '../../../utils/bite/openPreviewBite';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../utils/constants/toastConfig';
import BackButton from '../../../components/shared/BackButton';
import uploadImageByUrl from '../../../utils/uploadImageByUrl';
import { useIsMounted } from '../../../hooks/useIsMounted';

interface IProps extends IStackNavigation {}

const buttonStyle = {
  height: calcHeight(50),
  width: calcWidth(148),
};

const EditMain: React.FC<IProps> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { biteId: currentBiteId, isCloned, isGoBack } = route?.params;
  const { index, routes } = navigation.getState();
  const screenName = routes[index].name;

  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [isDeleteEmptyBiteModalVisible, setDeleteEmptyBiteModalVisible] = useState(false);
  const [isDeleteBiteModalVisible, setDeleteBiteModalVisible] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isMediaModalVisible, setMediaModalVisible] = useState(false);

  const { isLoading } = useSelector(biteSelector);
  const selectedBite = useSelector(selectedBiteSelector);
  const createBite = useSelector(createBiteSelector);
  const {
    biteName,
    linked_cover_url,
    biteSettings,
    introMediaProcessingStatus,
    introEnhancements,
    introSubtitles,
    editedSections,
  } = createBite;

  const originalVideoIsProcessing = introMediaProcessingStatus.original === EIntroMediaProcessingStatus.PROCESSING;

  const isIntroMediaProcessing = introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING;
  const isUploadingToS3 = introMediaProcessingStatus.s3 === EIntroMediaProcessingStatus.PROCESSING;

  const isInitialMount = useRef(true);
  const isMountedRef = useIsMounted();

  useEffect(() => {
    if (isInitialMount.current) {
      dispatch(prepareBiteEditScreen(currentBiteId));
      isInitialMount.current = false;
      return;
    }

    if (!isUploadingToS3) {
      dispatch(fetchBiteToEditRequest(currentBiteId));
    }
  }, [currentBiteId, dispatch, isUploadingToS3]);

  const openMediaModal = useCallback(() => {
    setMediaModalVisible(true);
  }, []);

  const closeMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  useEffect(() => {
    dispatch(loadBiteHelperConfigs());
    return () => {
      dispatch(loadNextPage({ withBaseFiltersAndSorting: true }));
    };
  }, [dispatch]);

  const isEnhancementsExists: boolean = useMemo(() => {
    return getHasEnhancementsEdit({
      introEnhancements,
      introSubtitles,
    });
  }, [introEnhancements, introSubtitles]);

  const onMediaSelected = useCallback(
    async (uri, type, editedImageUri) => {
      try {
        setImageLoading(true);
        closeMediaModal();
        const {
          data: { id, image },
        } = await uploadCover(
          isWeb
            ? {
                file: uri,
                path: 'upload_cover',
              }
            : {
                uri: editedImageUri || uri,
                type,
                path: 'upload_cover',
              },
        );

        await updateBiteData(selectedBite?.id, {
          cover: id,
          linked_cover_url: image,
        });
        dispatch(setBiteCover(id, image));
        dispatch(
          setBites([
            {
              ...selectedBite,
              cover_url: image,
              linked_cover_url: image,
            },
          ]),
        );

        setImageLoading(false);
      } catch (error) {
        setImageLoading(false);

        dispatch(
          logError({
            event: 'EditMain.onMediaSelected: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    [closeMediaModal, dispatch, selectedBite],
  );

  const { launchImageLibrary, launchImageCamera, dropZoneUploadingForWeb } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    onMediaSelectionCB: onMediaSelected,
    from: 'edit_cover_photo',
  });

  const handleOpenSettingsModal = useCallback(() => setSettingsModalVisible(true), []);
  const handleCloseSettingsModal = useCallback(() => setSettingsModalVisible(false), []);

  const handleApplySettings = useCallback(
    (settings) => {
      dispatch(updateBiteSettings(settings));
      updateBiteData(selectedBite?.id, settings);
      handleCloseSettingsModal();
    },
    [dispatch, handleCloseSettingsModal, selectedBite?.id],
  );

  const goBackHome = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'navigate_home',
        props: { page_title: screenName },
      }),
    );

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [dispatch, navigation, screenName]);

  const handleBackButtonPress = useCallback(() => {
    if (selectedBite?.no_sections) {
      setDeleteEmptyBiteModalVisible(true);
      return;
    }

    goBackHome();
  }, [goBackHome, selectedBite?.no_sections]);

  const handleSearchOnlineSelect = useCallback(
    async (url: string) => {
      try {
        setImageLoading(true);
        const media = await uploadImageByUrl({ url });
        await updateBiteData(selectedBite?.id, {
          cover: null,
          linked_cover_url: media.image_url,
        });
        dispatch(setBiteCoverFromUrl(media.image_url));
        dispatch(
          setBites([
            {
              ...selectedBite,
              cover_url: url,
              linked_cover_url: media.image_url,
            },
          ]),
        );
        if (isMountedRef.current) {
          setImageLoading(false);
        }
      } catch (error) {
        setImageLoading(false);

        dispatch(
          logError({
            event: 'EditMain.handleSearchOnlineSelect: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    [dispatch, isMountedRef, selectedBite],
  );

  const onChangeText = useCallback(
    (value) => {
      if (value.length <= 40 && value !== biteName) {
        dispatch(setBiteName(value));
      }
    },
    [biteName, dispatch],
  );

  const handleSaveBiteName = useCallback(
    async (value: string) => {
      dispatch(
        trackEvent({
          event: 'rename_bite',
          props: { bite_id: selectedBite.id, from: 'edit_main' },
        }),
      );
      dispatch(setBites([{ ...selectedBite, subject: value }]));
      await updateBiteData(selectedBite?.id, {
        subject: value,
      });
      dispatch(indexBites({ biteIds: [selectedBite.id] }));
    },
    [dispatch, selectedBite],
  );

  const handleDeleteBite = useCallback(() => {
    dispatch(deleteBite(selectedBite?.id!));
    navigation.navigate(Routes.HomeStack.Home);
  }, [dispatch, navigation, selectedBite?.id]);

  const closeDeleteEmptyBiteModal = useCallback(() => {
    setDeleteEmptyBiteModalVisible(false);
  }, []);

  const closeDeleteBiteModal = useCallback(() => {
    setDeleteBiteModalVisible(false);
  }, []);

  const openDeleteBiteModal = useCallback(() => {
    setDeleteBiteModalVisible(true);
  }, []);

  const isSectionEdited = useCallback(
    (type: EditBiteIconType): boolean => {
      switch (type) {
        case EditBiteIconType.INTRO:
          return editedSections.intro;

        case EditBiteIconType.QUESTION:
          return editedSections.question;

        case EditBiteIconType.SUMMARY:
          return editedSections.summary;

        case EditBiteIconType.INTRO_ENHANCEMENTS:
          return editedSections.introEnhancements;

        default:
          return false;
      }
    },
    [editedSections],
  );

  const isSectionDisabled = useCallback(
    (type): boolean => {
      const currentSection: TBiteSection = selectedBite?.bite_sections?.find((item) => item.type === type);

      if (!currentSection) {
        return true;
      }

      switch (currentSection.type) {
        case EditBiteIconType.INTRO:
          return currentSection?.task_id === null && currentSection?.media === null;

        case EditBiteIconType.QUESTION:
          return isEmpty(currentSection?.questions);

        case EditBiteIconType.SUMMARY:
          return isEmpty(currentSection?.summary_cards);

        default:
          return true;
      }
    },
    [selectedBite?.bite_sections],
  );

  const isSharingDisabled = useMemo(() => {
    if (isEmpty(selectedBite?.bite_sections) || originalVideoIsProcessing) {
      return true;
    }

    return selectedBite?.bite_sections.every((item) => isSectionDisabled(item.type));
  }, [selectedBite?.bite_sections, originalVideoIsProcessing, isSectionDisabled]);

  const renderHeaderRight = useCallback(
    () => <PointsButton onPress={handleOpenSettingsModal} />,
    [handleOpenSettingsModal],
  );

  const renderHeaderLeft = useCallback(() => {
    if (isGoBack) {
      return <BackButton />;
    }

    return <HomeButton onPress={handleBackButtonPress} />;
  }, [handleBackButtonPress, isGoBack]);

  const renderHeaderTitle = useCallback(() => <S.Title>{t('editMain.title')}</S.Title>, [t]);

  const openPreview = useCallback(() => {
    if (originalVideoIsProcessing) {
      return;
    }

    openPreviewBite({
      biteId: selectedBite?.id,
    });
  }, [selectedBite?.id, originalVideoIsProcessing]);

  const handleDeletePress = useCallback(() => {
    handleCloseSettingsModal();
    openDeleteBiteModal();
  }, [handleCloseSettingsModal, openDeleteBiteModal]);

  const renderSettingsLeftButton = useCallback(() => {
    return (
      <TouchableOpacity onPress={handleDeletePress}>
        <S.ButtonTextRed>{t('biteCreated.settings.delete')}</S.ButtonTextRed>
      </TouchableOpacity>
    );
  }, [handleDeletePress, t]);

  const renderLeftComponent = useCallback(() => {
    return (
      <Button
        isShadowed
        onPress={openPreview}
        disabled={selectedBite?.no_sections || originalVideoIsProcessing}
        text={t('common.Preview')}
        style={buttonStyle}
        fill={theme.colors.white}
        border={theme.colors.primaryBlue}
        textColor={theme.colors.primaryBlue}
      />
    );
  }, [
    openPreview,
    selectedBite?.no_sections,
    t,
    theme.colors.primaryBlue,
    theme.colors.white,
    originalVideoIsProcessing,
  ]);

  if (isUploadingToS3 || isLoading) {
    return <CreationAnimationScreen loadingText={isUploadingToS3 ? t('biteCreated.animationScreenText') : undefined} />;
  }

  return (
    <S.Container>
      <View>
        <Header headerRight={renderHeaderRight} headerLeft={renderHeaderLeft} title={renderHeaderTitle} />
        <S.Center>
          <S.ClonedTooltip
            initialVisible={isCloned}
            text={t('editBite.clonedTooltip')}
            fontSize={theme.fontSizes.s14}
            withCloseIcon
            withGradient
            disableBackgroundClose
          >
            <BiteCoverImage
              originalVideoIsProcessing={originalVideoIsProcessing}
              source={{ uri: linked_cover_url || null }}
              onEditPress={openMediaModal}
              bite={selectedBite}
              isLoading={isImageLoading}
            />
          </S.ClonedTooltip>
          <EditNameInput onAccept={handleSaveBiteName} onChangeText={onChangeText} value={biteName} />
          <S.SectionsContainer>
            <SectionItem
              edited={isSectionEdited(EditBiteIconType.INTRO)}
              disabled={isSectionDisabled(EditBiteIconType.INTRO)}
              type={EditBiteIconType.INTRO}
              // pass the prop
            />
            <SectionItem
              edited={isSectionEdited(EditBiteIconType.QUESTION)}
              disabled={isSectionDisabled(EditBiteIconType.QUESTION)}
              type={EditBiteIconType.QUESTION}
            />
            <SectionItem
              edited={isSectionEdited(EditBiteIconType.SUMMARY)}
              disabled={isSectionDisabled(EditBiteIconType.SUMMARY)}
              type={EditBiteIconType.SUMMARY}
            />
            {(isEnhancementsExists || isIntroMediaProcessing) && (
              <SectionItem
                edited={isSectionEdited(EditBiteIconType.INTRO_ENHANCEMENTS)}
                disabled={isIntroMediaProcessing}
                type={EditBiteIconType.INTRO_ENHANCEMENTS}
              />
            )}
          </S.SectionsContainer>
          {selectedBite && (
            <ShareSection
              disabled={isSharingDisabled}
              subject={biteName}
              shareData={selectedBite}
              from='edit_bite'
              leftButtonComponent={renderLeftComponent}
            />
          )}
        </S.Center>

        <MediaModal
          isVisible={isMediaModalVisible}
          onClose={closeMediaModal}
          onSelectGallery={launchImageLibrary}
          onSelectCamera={launchImageCamera}
          onSelectStockImage={handleSearchOnlineSelect}
          from='cover_photo'
        />
        <BiteSettings
          isVisible={isSettingsModalVisible}
          onClose={handleCloseSettingsModal}
          isPrivateDefault={biteSettings.is_private}
          isAllowedDiscussionDefault={biteSettings.discussion_enabled}
          isSkipEnabledDefault={biteSettings.skip_able}
          onDone={handleApplySettings}
          renderLeftButton={renderSettingsLeftButton}
          withSkip
        />
        {dropZoneUploadingForWeb}
        <BlueAndRedButtonsModal
          title={t('deleteEmptyBiteModal.title')}
          description={t('deleteEmptyBiteModal.description')}
          isVisible={isDeleteEmptyBiteModalVisible}
          onClose={closeDeleteEmptyBiteModal}
          onRightButtonClick={handleDeleteBite}
          onLeftButtonClick={goBackHome}
          rightButtonLabel={t('deleteEmptyBiteModal.delete')}
          leftButtonLabel={t('deleteEmptyBiteModal.addLater')}
        />
        <BlueAndRedButtonsModal
          title={t('deleteBiteModal.title')}
          description={t('deleteBiteModal.description')}
          isVisible={isDeleteBiteModalVisible}
          onClose={closeDeleteBiteModal}
          onRightButtonClick={handleDeleteBite}
          onLeftButtonClick={closeDeleteBiteModal}
          rightButtonLabel={t('deleteBiteModal.delete')}
          leftButtonLabel={t('deleteBiteModal.cancel')}
        />
      </View>
    </S.Container>
  );
};

const S = {
  SectionsContainer: styled.View`
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    margin-top: ${calcHeight(15)}px;
    margin-bottom: ${calcHeight(35)}px;
  `,
  Container: styled(KeyboardAwareView)``,
  Center: styled.View`
    align-items: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  Header: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${isWeb ? calcHeight(28) : calcHeight(53)}px ${calcWidth(24)}px ${calcHeight(5)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-weight: normal;
  `,
  ShareModeText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
  `,
  ShareModeButtonText: styled.Text`
    margin: 0 ${calcWidth(16)}px;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  ClonedTooltip: styled(Tooltip)``,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  ButtonTextRed: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.failRed};
  `,
};

export default EditMain;
