import React from 'react';
import LottieView from 'lottie-react';
import { deviceWidth } from '../../../../utils/dimensions';
import styled from 'styled-components';

const IntroEnhancementAnimation = () => {
  return <S.LottieView animationData={require('../../../../assets/lottie/enhancement-lottie.json')} autoPlay loop />;
};

const S = {
  LottieView: styled(LottieView)`
    width: ${deviceWidth / 1.2}px;
  `,
};

export default IntroEnhancementAnimation;
