import React, { useCallback, useMemo } from 'react';
import SelectQuestion from './SelectQuestion';
import {
  EQuestionAnswerType,
  EQuestionType,
  IQuestion,
  IQuestionAnswer,
} from '../../../../store/questionnaire/questionnaire.types';
import { useTranslation } from 'react-i18next';

interface IProps {
  question: IQuestion;
  answer: IQuestionAnswer;
  isFinalQuestion: boolean;
  onQuestionAnswer: (value: IQuestionAnswer['value'], type: EQuestionAnswerType) => void;
}

const BooleanQuestion: React.FC<IProps> = ({ question, answer, isFinalQuestion, onQuestionAnswer }) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        id: '0',
        label: t('common.no'),
      },
      {
        id: '1',
        label: t('common.yes'),
      },
    ],
    [t],
  );

  const localValue = useMemo(
    () => ({
      value: typeof answer?.value === 'boolean' ? (answer?.value ? options[1].id : options[0].id) : null,
    }),
    [answer?.value, options],
  );

  const selectQuestion = useMemo(() => {
    return {
      ...question,
      type: EQuestionType.SINGLE_SELECT,
      options,
    };
  }, [options, question]);

  const handleQuestionAnswer = useCallback(
    (value) => {
      onQuestionAnswer(value?.length > 0 && value[0] === '1', EQuestionAnswerType.boolean);
    },
    [onQuestionAnswer],
  );

  return (
    <SelectQuestion
      question={selectQuestion}
      answer={localValue}
      isFinalQuestion={isFinalQuestion}
      onQuestionAnswer={handleQuestionAnswer}
    />
  );
};

export default BooleanQuestion;
