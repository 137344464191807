import React from 'react';
import { deviceWidth, isWeb } from '../../../utils/dimensions';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

interface IProps {
  children: React.ReactNode;
}

const WebScrollingView: React.FC<IProps> = ({ children }) => {
  if (isWeb) {
    return <S.ScrollView contentContainerStyle={S.ContentContainerStyles}>{children}</S.ScrollView>;
  }

  return <>{children}</>;
};

const S = {
  ContentContainerStyles: {
    alignItems: 'center',
  } as StyleProp<ViewStyle>,
  ScrollView: styled.ScrollView`
    width: ${deviceWidth};
    flex: 1;
  `,
};

export default WebScrollingView;
