import React, { ReactElement, useCallback } from 'react';
import { ActivityIndicator, TouchableOpacityProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import { calcFontSize, calcWidth, isWeb } from '../../../utils/dimensions';

import { IS_IOS, IS_ANDROID } from '../../../utils/constants/env';

export interface IButtonProps extends TouchableOpacityProps {
  content?: string | ReactElement;
  inverted?: boolean;
  longSize?: boolean;
  height?: number;
  renderedIcon?: ReactElement;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  activityIndicatorColor?: string;
  fontSize?: number;
  fontFamily?: string;
}
const Button: React.FC<IButtonProps> = ({
  content,
  inverted = false,
  renderedIcon: icon,
  iconPosition = 'left',
  isLoading,
  disabled,
  activityIndicatorColor = 'black',
  fontSize,
  fontFamily,
  onPress,
  ...touchableOpacityProps
}) => {
  const handlePress = useCallback(
    (e) => {
      if (disabled || isLoading || typeof onPress !== 'function') {
        return;
      }

      onPress(e);
    },
    [onPress, disabled, isLoading],
  );

  return (
    <S.Button
      inverted={inverted}
      disabled={disabled}
      iconPosition={iconPosition}
      onPress={handlePress}
      {...touchableOpacityProps}
    >
      {isLoading ? (
        <>
          <S.Text inverted={inverted} fontSize={fontSize} fontFamily={fontFamily}>
            {content}
          </S.Text>
          <S.View style={{ alignItems: 'center' }}>
            <ActivityIndicator color={activityIndicatorColor} />
          </S.View>
        </>
      ) : (
        <>
          <S.Text inverted={inverted} fontSize={fontSize} fontFamily={fontFamily}>
            {content}
          </S.Text>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </>
      )}
    </S.Button>
  );
};

//varients
const primaryStyle = {
  Touchable: css(
    ({ theme }) => `
			background-color: ${theme.colors.primaryBlue};
		`,
  ),
  Text: css(
    ({ theme }) => `
			color: ${theme.colors.white};
		`,
  ),
};

const invertedStyle = {
  Touchable: css(
    ({ theme }) => `
			background-color: ${theme.colors.white};
		`,
  ),
  Text: css(
    ({ theme }) => `
			color: ${theme.colors.primaryBlue};
		`,
  ),
};

//types
type Inverted = boolean | undefined;
type Disabled = boolean | undefined;
type IconPosition = 'left' | 'right' | undefined;

//style
const S = {
  Button: styled.TouchableOpacity<{
    inverted: Inverted;
    disabled: Disabled;
    iconPosition: IconPosition;
  }>`
    ${() => isWeb && 'min-height: 50px'};
    border-radius: 33px;
    padding: ${calcWidth(5)}px;
    aspect-ratio: ${200 / 35};
    justify-content: flex-end;
    align-items: center;
    ${({ theme, inverted, disabled, iconPosition }) => css`
      ${inverted ? invertedStyle.Touchable : primaryStyle.Touchable};
      flex-direction: ${iconPosition === 'left' ? 'row-reverse' : 'row'};

      ${disabled &&
      css`
        background-color: ${theme.colors.darkGray3};
      `}
    `}
  `,
  Text: styled.Text<{
    inverted?: Inverted;
    fontSize?: number;
    fontFamily?: string;
  }>`
    font-size: ${({ fontSize }) => calcFontSize(fontSize || 16)}px;
    text-align: center;
    position: absolute;
    padding-right: ${IS_ANDROID ? 0 : calcWidth(7)}px;
    letter-spacing: ${calcWidth(1.2)}px;
    font-family: ${({ theme, fontFamily }) => fontFamily || theme.fontFamilies.SFProRegular};

    left: 0;
    right: 0;

    ${({ theme, inverted }) => css`
      ${inverted ? invertedStyle.Text : primaryStyle.Text};
      /* font-family: ${theme.fontFamilies.GilroyMedium}; */
    `}
    line-height: ${({ fontSize }) => calcFontSize(fontSize || (IS_IOS ? 20 : IS_ANDROID ? 17 : 16))}px;
  `,
  IconWrapper: styled.View`
    margin: 0;
  `,

  View: styled.View`
    padding: ${calcWidth(7)}px;
  `,
};

export default Button;
