import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';

const openPreviewPlaylistDashboard = (playlistId: number, section = 'overview') => {
  navigationRef.current.navigate(Routes.PreviewStack.StackName, {
    screen: Routes.PreviewStack.Dashboard,
    params: {
      path: `/playlists/${playlistId}/${section}`,
    },
  });
};
export default openPreviewPlaylistDashboard;
