import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectedTimelineItemIdSelector, timelineItemSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { updateTimelineItem } from '../../../store/videoEditor/videoEditor.slice';
import { fonts } from './text/fonts';
import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import { loadFont } from './loadFont';
import { filterFonts } from './filterFonts';
import Title from './Title.web';
import defaultTheme from '../../../themes/defaultTheme';
import AlignLeftIcon from '../../../assets/icons/videoEditor/align-left.svg';
import AlignCenterIcon from '../../../assets/icons/videoEditor/align-center.svg';
import AlignRightIcon from '../../../assets/icons/videoEditor/align-right.svg';
import MinusIcon from '../../../assets/icons/videoEditor/minus.svg';
import PlusIcon from '../../../assets/icons/videoEditor/plus.svg';

const TextSettings = () => {
  const dispatch = useDispatch();

  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem: ITimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const fontOptionLabels = useMemo(() => {
    return [...new Set(fonts.map(({ label }) => label))];
  }, []);

  const currentFontOption = useMemo(() => {
    return fonts.find((font) => {
      return (
        font.label === timelineItem.fontFamily &&
        font.weight === (timelineItem.fontWeight || 400) &&
        font.italic === (timelineItem.textItalic || false)
      );
    });
  }, [timelineItem]);

  const currentFontOptions = useMemo(() => {
    if (!currentFontOption) {
      return null;
    }

    return fonts.filter((font) => font.label === currentFontOption.label);
  }, [currentFontOption]);

  const fontOptionWeightLabels = useMemo(() => {
    if (!currentFontOptions) {
      return null;
    }

    return [
      ...new Set([...currentFontOptions].sort((a, b) => a.weight - b.weight).map(({ weightLabel }) => weightLabel)),
    ];
  }, [currentFontOptions]);

  const isItalicAvailable = useMemo(() => {
    if (!currentFontOption) {
      return false;
    }

    const italicFonts = filterFonts(currentFontOptions, {
      weight: currentFontOption.weight,
      italic: true,
    });

    return italicFonts.length > 0;
  }, [currentFontOption, currentFontOptions]);

  const handleInput = useCallback(
    (e) => {
      e.stopPropagation();

      // Reset the height to a default (e.g., 'auto' or minimum height)
      textareaRef.current.style.height = 'auto';
      // Set the height based on the scroll height
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 8 + 'px';

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          text: e.target.value,
        }),
      );
    },
    [dispatch, selectedTimelineItemId],
  );

  const handleColorInput = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          color: e.target.value,
        }),
      );
    },
    [dispatch, selectedTimelineItemId],
  );

  const handleFontSizeInput = useCallback(
    (e) => {
      e.stopPropagation();

      const fontSize = parseInt(e.target.value, 10);

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontSize,
        }),
      );
    },
    [dispatch, selectedTimelineItemId],
  );

  const FONT_STEP = 1;
  const handleDecreaseFontSize = useCallback(
    (e) => {
      e.stopPropagation();

      const fontSize = Math.ceil(timelineItem.fontSize / FONT_STEP) * FONT_STEP;
      const newFontSize = Math.max(fontSize - FONT_STEP, 0);

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontSize: newFontSize,
        }),
      );
    },
    [dispatch, selectedTimelineItemId, timelineItem.fontSize],
  );
  const handleIncreaseFontSize = useCallback(
    (e) => {
      e.stopPropagation();

      const fontSize = Math.ceil(timelineItem.fontSize / FONT_STEP) * FONT_STEP;
      const newFontSize = Math.min(fontSize + FONT_STEP, 100);

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontSize: newFontSize,
        }),
      );
    },
    [dispatch, selectedTimelineItemId, timelineItem.fontSize],
  );

  const handleSetFont = useCallback(
    async (e) => {
      const label = e.target.value;

      const weight = currentFontOption?.weight || 400;
      const italic = currentFontOption?.italic || false;

      const fontsForLabel = filterFonts(fonts, {
        label,
      });

      const fontsForWeight = filterFonts(fontsForLabel, {
        weight,
      });

      let newFontOption = filterFonts(fontsForWeight, {
        italic,
      })[0];

      // pick not italic if it exists
      if (!newFontOption && italic && fontsForWeight.length > 0) {
        newFontOption = fontsForWeight[0];
      }

      // pick regular weight not italic
      if (!newFontOption) {
        newFontOption = filterFonts(fontsForLabel, {
          weight: 400,
          italic: false,
        })[0];
      }

      // pick any weight not italic
      if (!newFontOption) {
        newFontOption = filterFonts(fontsForLabel, {
          italic: false,
        })[0];
      }

      // pick any weight
      if (!newFontOption) {
        newFontOption = fontsForLabel[0];
      }

      await loadFont({
        label: newFontOption.label,
        weight: newFontOption.weight,
        italic: newFontOption.italic,
      });

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontFamily: newFontOption.label,
          fontWeight: newFontOption.weight,
          textItalic: newFontOption.italic,
        }),
      );
    },
    [currentFontOption, dispatch, selectedTimelineItemId],
  );

  const handleSetWeight = useCallback(
    async (e) => {
      if (!currentFontOption) {
        return;
      }

      const weightLabel = e.target.value;

      const fontOptionsForWeight = filterFonts(fonts, {
        label: currentFontOption.label,
        weightLabel,
      });

      let newFontOption = filterFonts(fontOptionsForWeight, {
        italic: currentFontOption.italic,
      })[0];

      if (!newFontOption && currentFontOption.italic && fontOptionsForWeight.length > 0) {
        newFontOption = fontOptionsForWeight[0];
      }
      await loadFont({
        label: newFontOption.label,
        weight: newFontOption.weight,
        italic: newFontOption.italic,
      });

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontFamily: newFontOption.label,
          fontWeight: newFontOption.weight,
          textItalic: newFontOption.italic,
        }),
      );
    },
    [currentFontOption, dispatch, selectedTimelineItemId],
  );

  const handleSetItalic = useCallback(
    async (e) => {
      const italic: boolean = e.target.checked;

      const newFontOption = filterFonts(fonts, {
        label: currentFontOption.label,
        weight: currentFontOption.weight,
        italic,
      })[0];

      await loadFont({
        label: newFontOption.label,
        weight: newFontOption.weight,
        italic: newFontOption.italic,
      });

      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          fontFamily: newFontOption.label,
          textItalic: newFontOption.italic,
        }),
      );
    },
    [currentFontOption, dispatch, selectedTimelineItemId],
  );

  const handleOpacityInput = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          opacity: parseInt(e.target.value, 10),
        }),
      );
    },
    [dispatch, selectedTimelineItemId],
  );

  const handleSelectTextAlignLeft = useCallback(() => {
    dispatch(
      updateTimelineItem({
        id: selectedTimelineItemId,
        textAlign: 'left',
      }),
    );
  }, [dispatch, selectedTimelineItemId]);

  const handleSelectTextAlignCenter = useCallback(() => {
    dispatch(
      updateTimelineItem({
        id: selectedTimelineItemId,
        textAlign: 'center',
      }),
    );
  }, [dispatch, selectedTimelineItemId]);

  const handleSelectTextAlignRight = useCallback(() => {
    dispatch(
      updateTimelineItem({
        id: selectedTimelineItemId,
        textAlign: 'right',
      }),
    );
  }, [dispatch, selectedTimelineItemId]);

  const colorInputRef = React.createRef<HTMLInputElement>();

  return (
    <>
      <Title text='Text settings' />
      <S.ControlContainer>
        <S.Textarea
          value={timelineItem.text}
          placeholder='Enter text here...'
          ref={textareaRef}
          onInput={handleInput}
        />
      </S.ControlContainer>
      <S.Spacing />

      <S.ControlTitle>Color</S.ControlTitle>
      <S.Spacing />
      <S.ColorControlContainer>
        <S.ColorInput
          color={timelineItem.color}
          onClick={() => {
            colorInputRef.current?.click();
          }}
        />
        <S.ColorInputText value={timelineItem.color} onInput={handleColorInput} />
        <S.HiddenColorInput type='color' value={timelineItem.color} ref={colorInputRef} onChange={handleColorInput} />
      </S.ColorControlContainer>

      <S.Separator />

      <S.ControlTitle>Font</S.ControlTitle>
      <S.Spacing />
      <S.ControlContainer>
        <S.Select value={currentFontOption?.label || ''} onInput={handleSetFont}>
          {!currentFontOption && (
            <option value='' disabled>
              Select a font
            </option>
          )}
          {fontOptionLabels.map((label) => (
            <option key={label} value={label}>
              {label}
            </option>
          ))}
        </S.Select>
      </S.ControlContainer>

      {currentFontOption && (
        <>
          <S.Spacing />
          <S.ControlContainer>
            <S.Select value={currentFontOption.weightLabel} onInput={handleSetWeight}>
              {fontOptionWeightLabels.map((label) => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
            </S.Select>
          </S.ControlContainer>
        </>
      )}

      {isItalicAvailable && (
        <>
          <S.Spacing />
          <S.ControlContainer>
            <S.CheckboxContainer>
              <input
                type='checkbox'
                checked={currentFontOption.italic}
                style={{
                  width: 14,
                  height: 14,
                  marginRight: 4,
                }}
                onChange={handleSetItalic}
              />
              Italic
            </S.CheckboxContainer>
          </S.ControlContainer>
        </>
      )}

      <S.Spacing size={2} />
      <S.ControlTitle>Font size</S.ControlTitle>
      <S.ControlContainer>
        <S.RangeInput
          type='range'
          min='3'
          max='40'
          step='1'
          // value={timelineItem.scaleX !== undefined ? timelineItem.scaleX : 1}
          value={timelineItem.fontSize}
          onInput={handleFontSizeInput}
        />
        <S.Btn onClick={handleDecreaseFontSize}>
          <S.MinusIcon />
        </S.Btn>
        <S.Btn onClick={handleIncreaseFontSize}>
          <PlusIcon />
        </S.Btn>
      </S.ControlContainer>

      <S.Spacing />
      <S.BtnGroup>
        <S.BtnGroupLeft
          isSelected={!timelineItem.textAlign || timelineItem.textAlign === 'left'}
          onClick={handleSelectTextAlignLeft}
        >
          <AlignLeftIcon />
        </S.BtnGroupLeft>
        <S.BtnGroupCenter isSelected={timelineItem.textAlign === 'center'} onClick={handleSelectTextAlignCenter}>
          <AlignCenterIcon />
        </S.BtnGroupCenter>
        <S.BtnGroupRight isSelected={timelineItem.textAlign === 'right'} onClick={handleSelectTextAlignRight}>
          <AlignRightIcon />
        </S.BtnGroupRight>
      </S.BtnGroup>

      <S.Separator />

      <S.ControlTitle>Opacity</S.ControlTitle>
      <S.ControlContainer>
        <S.RangeInput
          type='range'
          min='0'
          max='100'
          step='1'
          value={timelineItem.opacity !== undefined ? timelineItem.opacity : 100}
          onInput={handleOpacityInput}
        />
      </S.ControlContainer>

      <S.Spacing size={2} />
    </>
  );
};
export default TextSettings;

const S = {
  Separator: styled.div`
    height: 1px;
    background-color: ${defaultTheme.colors.lightGray45};
    margin: 16px 0;
  `,
  Spacing: styled.div<{ size?: number }>`
    height: ${({ size = 1 }) => size * 8}px;
  `,
  ControlTitle: styled.div`
    padding: 0 8px;
    font-size: 13px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 8px;
  `,
  Btn: styled.div`
    cursor: pointer;
  `,
  MinusIcon: styled(MinusIcon)`
    transform: translateY(-6px);
  `,
  Textarea: styled.textarea`
    padding: 4px 8px;
    width: 100%;
    min-height: 80px;
    border: 1px solid ${defaultTheme.colors.gray17};
    border-radius: 8px;
    resize: none;
  `,
  ColorControlContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 8px;
    padding: 2px;
    border: 1px solid ${defaultTheme.colors.gray17};
  `,
  ColorInput: styled.div<{ color: string }>`
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    background-color: ${({ color }) => color || '#000000'};
  `,
  ColorInputText: styled.input<{ value: string }>`
    flex: 1;
    width: 100%;
    line-height: 100%;
    font-size: 14px;
    color: ${defaultTheme.colors.text};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border: 0;
  `,
  HiddenColorInput: styled.input`
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  `,
  Select: styled.select`
    padding: 4px 8px;
    width: 100%;
    height: 32px;
    border: 1px solid ${defaultTheme.colors.gray17};
    border-radius: 8px;
    cursor: pointer;
  `,
  RangeInput: styled.input`
    width: 100%;
  `,
  RangeInputWithTextControl: styled.input`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  BtnGroup: styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    padding: 0 8px;
  `,
  BtnGroupLeft: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  BtnGroupCenter: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  BtnGroupRight: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  CheckboxContainer: styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
};
