// [AdGo] - 20.11.20
// ATM this slice is only used for deriving if the current active organization
// has datas, in order to know whether to show the everyone/custom screen
// after clicking "share" when creating a new bite share
// in the future we should put new org related logic here,
// instead of under auth reducer -> user -> organizations
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import {
  ISwitchOrganizationAction,
  IUserOrganizationRenameAction,
  IUserOrganizationLeaveAction,
  IGetInOrganizationAction,
  ICreator,
  IGetAttributesAction,
} from './org.types';

interface IOrganizationCode {
  code: string;
  organization: number;
  used: boolean;
  created_at: string;
}

interface IStatusFlags {
  isLoading: boolean;
  isSuccess: boolean;
  error: null | string;
}

interface IInitialState {
  isLoading: boolean;
  isOrgCodeLoading: boolean;
  datas: string[];
  attributes: {
    data: boolean;
    isLoading: boolean;
    error: null | string;
  };
  code: IOrganizationCode | null;
  codeError: boolean;
  joinDeeplink: string;
  isShowOrganizationForm: boolean;
  isShowOrganizationLeave: boolean;
  rename: IStatusFlags;
  leave: IStatusFlags;
  creators: ICreator[];
  features: number[];
}

const initOrganizationRename: IStatusFlags = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const initOrganizationLeave: IStatusFlags = {
  isLoading: false,
  isSuccess: false,
  error: null,
};

const initialState: IInitialState = {
  isLoading: false,
  attributes: {
    data: null,
    isLoading: false,
    error: null,
  },
  isOrgCodeLoading: false,
  datas: [],
  code: null,
  joinDeeplink: null,
  isShowOrganizationForm: false,
  isShowOrganizationLeave: false,
  rename: initOrganizationRename,
  leave: initOrganizationLeave,
  creators: [],
  codeError: null,
  features: [],
};

const ORGANIZATION = 'ORGANIZATION';

export const switchOrganization = createAction<ISwitchOrganizationAction>(`${ORGANIZATION}/updateDefaultOrganization`);
export const updateUserOrganizationName = createAction<IUserOrganizationRenameAction>(
  `${ORGANIZATION}/updateUserOrganizationName`,
);
export const leaveUserOrganization = createAction<IUserOrganizationLeaveAction>(
  `${ORGANIZATION}/leaveUserOrganization`,
);
export const getInOrganization = createAction<IGetInOrganizationAction>(`${ORGANIZATION}/getInOrganization`);

export const getCreators = createAction(`${ORGANIZATION}/getCreators`);

export const waitForAttributes = createAction<{ onError: () => void; onSuccess: () => void }>(
  `${ORGANIZATION}/waitForAttributes`,
);
export const validateAccessCode = createAction<{ onSuccess?: () => void; onError?: () => void }>(
  `${ORGANIZATION}/validateAccessCode`,
);

const orgSlice = createSlice({
  name: ORGANIZATION,
  initialState,
  reducers: {
    // Payload is not explicitly used in the saga
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOrg: (state, action: PayloadAction<number>) => {
      state.isLoading = true;
    },
    fetchOrgSuccess: (state, action) => {
      state.datas = formatDatas(action.payload);
      state.features = action.payload.features || [];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchOrgCode: (state, action: PayloadAction<number>) => {
      state.isOrgCodeLoading = true;
      state.codeError = null;
    },
    setOrgCodeError: (state, action: PayloadAction<any>) => {
      state.isOrgCodeLoading = false;
      state.codeError = action.payload;
    },
    fetchOrgCodeSuccess: (state, action: PayloadAction<IOrganizationCode>) => {
      state.code = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrgAttributes: (state, action: PayloadAction<IGetAttributesAction>) => {
      state.attributes.isLoading = true;
    },
    setAttributes: (state, action: PayloadAction<boolean>) => {
      state.attributes.data = action.payload;
      state.attributes.isLoading = false;
    },
    setAttributesError: (state, action: PayloadAction<string>) => {
      state.attributes.error = action.payload;
      state.attributes.isLoading = false;
    },
    setJoinOrganizationDeeplink: (state, action: PayloadAction<string>) => {
      state.joinDeeplink = action.payload;
    },
    setIsOrgCodeLoading: (state, action: PayloadAction<boolean>) => {
      state.isOrgCodeLoading = action.payload;
    },
    setCreators: (state, action: PayloadAction<ICreator[]>) => {
      state.creators = action.payload;
    },
    fetchOrgFailure: (state) => {
      state.isLoading = false;
    },
    showUpdateUserOrganizationNameForm: (state) => {
      state.isShowOrganizationForm = true;
      state.rename = initOrganizationRename;
    },
    hideUpdateUserOrganizationNameForm: (state) => {
      state.isShowOrganizationForm = false;
      state.rename = initOrganizationRename;
    },
    updateUserOrganizationNameStart: (state) => {
      state.rename = {
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    },
    updateUserOrganizationNameSuccess: (state) => {
      state.rename = {
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    },
    updateUserOrganizationNameFailure: (state, action) => {
      state.rename = {
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    },
    showLeaveUserOrganization: (state) => {
      state.isShowOrganizationLeave = true;
      state.leave = initOrganizationLeave;
    },
    hideLeaveUserOrganization: (state) => {
      state.isShowOrganizationLeave = false;
      state.leave = initOrganizationLeave;
    },
    leaveUserOrganizationStart: (state) => {
      state.leave = {
        isLoading: true,
        isSuccess: false,
        error: null,
      };
    },
    leaveUserOrganizationSuccess: (state) => {
      state.leave = {
        isLoading: false,
        isSuccess: true,
        error: null,
      };
    },
    leaveUserOrganizationFailure: (state, action) => {
      state.leave = {
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    },
  },
});

export const {
  fetchOrg,
  fetchOrgSuccess,
  fetchOrgFailure,
  fetchOrgCode,
  fetchOrgCodeSuccess,
  setOrgCodeError,
  setIsOrgCodeLoading,
  setJoinOrganizationDeeplink,
  showUpdateUserOrganizationNameForm,
  hideUpdateUserOrganizationNameForm,
  updateUserOrganizationNameStart,
  updateUserOrganizationNameSuccess,
  updateUserOrganizationNameFailure,
  showLeaveUserOrganization,
  hideLeaveUserOrganization,
  leaveUserOrganizationStart,
  leaveUserOrganizationSuccess,
  leaveUserOrganizationFailure,
  setAttributes,
  setAttributesError,
  getOrgAttributes,
  setCreators,
} = orgSlice.actions;

export default orgSlice.reducer;

function formatDatas(payload: any) {
  const datas: [] = [];
  for (let i = 1; i <= 8; i++) {
    const title = payload[`data${i}_title`];
    if (!title) {
      continue;
    }
    const options = payload[`data${i}`];
    // @ts-ignore
    datas.push({ title, options });
  }
  return datas;
}
