import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModalStackItem } from './appModals.types';
import getModalId from './appModals.counter';
export interface IInitialState {
  modalStack: IModalStackItem[];
}

const initialState: IInitialState = {
  modalStack: [],
};

const appModals = 'appModals';

const appModalsSlice = createSlice({
  name: appModals,
  initialState,
  reducers: {
    displayAppModal: (state, { payload }: PayloadAction<IModalStackItem>) => {
      state.modalStack.push({
        modalId: getModalId(),
        ...payload,
      });
    },
    removeFromStack: (state, { payload }: PayloadAction<number>) => {
      state.modalStack = state.modalStack.filter((item) => item.modalId !== payload);
    },
  },
});

export const { removeFromStack, displayAppModal } = appModalsSlice.actions;

export default appModalsSlice.reducer;
