import styled, { useTheme } from 'styled-components/native';
import React, { useCallback, useMemo, useState } from 'react';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { Image as ImageWeb, ScrollView } from 'react-native';
import { SvgUri } from 'react-native-svg';
import ShadowedContainer from '../../../ShadowedContainer';
import GenericButton from '../../buttons/GenericButton';
import TextInput from './TextInput';
import {
  EQuestionAnswerType,
  IQuestion,
  IQuestionAnswer,
  IQuestionOption,
} from '../../../../store/questionnaire/questionnaire.types';
import { IS_IOS } from '../../../../utils/constants/env';

interface IProps {
  question: IQuestion;
  answer?: IQuestionAnswer;
  isMultiSelect?: boolean;
  isFinalQuestion: boolean;
  onQuestionAnswer: (value: IQuestionAnswer['value'], type?: EQuestionAnswerType) => void;
}

const SelectQuestion: React.FC<IProps> = ({ question, answer, isMultiSelect, isFinalQuestion, onQuestionAnswer }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isCustomValue, setIsCustomValue] = useState(
    () => typeof answer?.value === 'string' && !question.options.some(({ id }) => id === answer?.value),
  );
  const [value, setValue] = useState(() =>
    !isCustomValue && answer?.value ? (isMultiSelect ? (answer?.value as string[]) : [answer?.value as string]) : [],
  );
  const [customValue, setCustomValue] = useState(() =>
    isCustomValue && answer?.value ? (answer?.value as string) : '',
  );

  const valuesMap = useMemo(() => {
    return (value as string[]).reduce((map, v) => {
      map[v] = true;
      return map;
    }, {});
  }, [value]);

  const handleOptionPress = useCallback(
    (option: IQuestionOption) => () => {
      if (isMultiSelect) {
        const isOptionSelected = valuesMap[option.id];
        const newValue = isOptionSelected
          ? (value as string[]).filter((id) => id !== option.id)
          : [...value, option.id];
        setValue(newValue);
        return;
      }

      onQuestionAnswer(option.id, EQuestionAnswerType.string);
    },
    [valuesMap, value, isMultiSelect, onQuestionAnswer],
  );

  const handleSetOtherValue = useCallback((newValue) => {
    setCustomValue(newValue);
  }, []);

  const handleOtherPress = useCallback(() => {
    setIsCustomValue((prev) => !prev);
  }, []);

  const handleContinuePress = useCallback(() => {
    const resultValue = isCustomValue ? customValue : value;
    const type = isCustomValue ? EQuestionAnswerType.string : EQuestionAnswerType.array;
    onQuestionAnswer(resultValue, type);
  }, [customValue, isCustomValue, onQuestionAnswer, value]);

  const shadowContainerStyle = useMemo(
    () => ({ borderRadius: calcHeight(10), margin: calcHeight(isWeb ? 5 : 10) }),
    [],
  );

  const renderOption = useCallback(
    (option: IQuestionOption) => {
      const isOptionSelected = valuesMap[option.id];
      return (
        <ShadowedContainer containerViewStyle={shadowContainerStyle}>
          <S.Option isSelected={isOptionSelected} onPress={handleOptionPress(option)}>
            <Icon uri={isOptionSelected ? option?.icon_selected || option?.icon : option?.icon} />
            <S.OptionLabel isSelected={isOptionSelected}>{t(option.label)}</S.OptionLabel>
          </S.Option>
        </ShadowedContainer>
      );
    },
    [handleOptionPress, shadowContainerStyle, t, valuesMap],
  );

  const renderContent = useCallback(() => {
    return (
      <>
        {isCustomValue ? (
          <TextInput value={customValue} onChange={handleSetOtherValue} />
        ) : (
          <S.Wrapper>
            {isMultiSelect && <S.Subtitle>{t('questionnaires.multiSelectDescription')}</S.Subtitle>}
            <S.Container>
              <S.Options>{question.options.map(renderOption)}</S.Options>
            </S.Container>
          </S.Wrapper>
        )}

        {(isMultiSelect || question.allowCustomValue) && (
          <S.Footer>
            {question.allowCustomValue ? (
              <GenericButton
                backgroundColor={theme.colors.white}
                fontSize={calcWidth(16)}
                borderColor={theme.colors.white}
                color={theme.colors.primaryBlue}
                disabled={false}
                onPress={handleOtherPress}
                content={t(isCustomValue ? 'common.back' : 'common.other')}
              />
            ) : (
              <S.Empty />
            )}
            {(isMultiSelect || isCustomValue) && (
              <GenericButton
                disabled={false}
                onPress={handleContinuePress}
                content={isFinalQuestion ? t('common.done') : t('common.Continue')}
              />
            )}
          </S.Footer>
        )}
      </>
    );
  }, [
    customValue,
    handleContinuePress,
    handleOtherPress,
    handleSetOtherValue,
    isCustomValue,
    isFinalQuestion,
    isMultiSelect,
    question.allowCustomValue,
    question.options,
    renderOption,
    t,
    theme.colors.primaryBlue,
    theme.colors.white,
  ]);

  if (isWeb) {
    return renderContent();
  }
  return <ScrollView>{renderContent()}</ScrollView>;
};

const Icon = ({ uri }) => {
  const source = useMemo(() => ({ uri }), [uri]);

  const imageStyles: unknown = useMemo(
    () => ({ width: calcWidth(36), height: calcHeight(36), marginBottom: calcHeight(4), resizeMode: 'contain' }),
    [],
  );

  if (!uri) {
    return null;
  }
  if (isWeb) {
    return <ImageWeb source={source} style={imageStyles} />;
  }
  return <SvgUri uri={uri} width={calcWidth(36)} height={calcHeight(36)} style={imageStyles} />;
};

const S = {
  Wrapper: styled.View``,
  Container: styled.View`
    width: 100%;
    align-items: center;
  `,
  LabelContainer: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  Label: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text};
    text-align: center;
    align-items: center;
  `,
  Options: styled.View`
    ${isWeb ? `max-width: ${calcWidth(760)}px;` : `width: ${deviceWidth - calcWidth(40)}px;`}
    margin: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    overflow: visible;
  `,
  Option: styled.TouchableOpacity<{ isSelected?: boolean }>`
    border-radius: ${calcHeight(10)}px;
    padding: ${calcHeight(10)}px ${calcWidth(20)}px;
    height: ${calcHeight(90)}px;
    width: ${isWeb ? calcWidth(170) : calcWidth(143)}px;
    align-items: center;
    justify-content: center;
    background: ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryBlue : theme.colors.white)};
  `,
  OptionLabel: styled.Text<{ isSelected?: boolean }>`
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.text)};
    text-align: center;
    align-items: center;
  `,
  CheckboxStyles: {
    width: calcWidth(20),
    height: calcHeight(20),
    marginRight: calcWidth(10),
  },
  Subtitle: styled.Text`
    align-self: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    font-weight: lighter;
    margin-bottom: ${calcHeight(20)}px;
    color: ${({ theme }) => theme.colors.gray19};
  `,
  Footer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: ${calcHeight(IS_IOS ? 5 : 15)}px ${calcWidth(25)}px;
  `,
  Empty: styled.View``,
};

export default SelectQuestion;
