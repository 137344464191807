import React, { memo, useCallback } from 'react';
import styled from 'styled-components/native';
import { calcHeight } from '../../../../utils/dimensions';
import { IOrganization } from '../../../../types/organization';

interface IProps {
  org: IOrganization;
  onPress: (org: IOrganization) => void;
}

const Organization: React.FC<IProps> = ({ org, onPress }) => {
  const handlePress = useCallback(() => onPress(org), [onPress, org]);

  return (
    <S.OrgWrapper onPress={handlePress}>
      <S.Title>{org.name}</S.Title>
    </S.OrgWrapper>
  );
};

const S = {
  OrgWrapper: styled.TouchableOpacity`
    height: ${calcHeight(75)}px;
    border-color: ${({ theme }) => theme.colors.gray17};
    border-bottom-width: 1px;

    align-items: center;
    justify-content: center;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray18};
  `,
};

export default memo(Organization);
