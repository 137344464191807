import React, { ReactElement } from 'react';
import { ActivityIndicator, View, TouchableOpacityProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';

export interface IGenericButtonProps extends TouchableOpacityProps {
  content?: string | ReactElement;
  renderedIcon?: ReactElement;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  isSmall?: boolean;
  backgroundColor?: string;
  fontSize?: number;
  color?: string;
  width?: number;
  disabled?: boolean;
  borderColor?: string;
  isbold?: boolean;
  dataSet?: { [key: string]: string };
}
const GenericButton: React.FC<IGenericButtonProps> = ({
  content,
  renderedIcon: icon,
  iconPosition = 'left',
  isLoading,
  disabled,
  isSmall,
  backgroundColor,
  fontSize,
  borderColor,
  color,
  isbold,
  width = 120,
  ...touchableOpacityProps
}) => {
  return (
    <S.Button
      disabled={disabled || isLoading}
      isSmall={isSmall}
      iconPosition={iconPosition}
      backgroundColor={backgroundColor}
      color={color}
      width={width}
      borderColor={borderColor}
      {...touchableOpacityProps}
    >
      {isLoading ? (
        <View style={{ width: '100%', alignItems: 'center' }}>
          <ActivityIndicator color='black' />
        </View>
      ) : (
        <>
          <S.Text color={color} isbold={isbold} fontSize={fontSize}>
            {content}
          </S.Text>
          {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
        </>
      )}
    </S.Button>
  );
};

//style
const S = {
  Button: styled.TouchableOpacity<{
    disabled?: boolean;
    iconPosition?: 'left' | 'right';
    backgroundColor?: string;
    color?: string;
    width: number;
    isSmall?: boolean;
    borderColor?: string;
  }>`
    border-radius: 33px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    ${({ theme, disabled, iconPosition, backgroundColor, width, borderColor, isSmall }) => css`
		padding-right: ${isWeb ? (isSmall ? 0 : 0) : isSmall ? 6 : 30}px;
		padding-left: ${isWeb ? (isSmall ? 20 : 0) : isSmall ? 18 : 30}px;
        background-color: ${backgroundColor ? backgroundColor : theme.colors.primaryBlue}
		border: ${borderColor ? '1px solid' : 'none'};
		border-color: ${borderColor ? borderColor : theme.colors.primaryBlue};
        width: ${calcWidth(width)}px;

		height: ${isSmall ? 28 : 45}px;
		${
      !isSmall &&
      css`
        aspect-ratio: ${width / 39};
      `
    }
		flex-direction: ${iconPosition === 'left' ? 'row-reverse' : 'row'};
			${
        disabled &&
        css`
          background-color: ${theme.colors.darkGray3};
        `
      }
		`}
  `,
  Text: styled.Text<{ color?: string; isbold?: boolean; fontSize?: number }>`
    text-align: center;
    width: 100%;
    line-height: ${calcHeight(40)}px;
    height: ${calcHeight(40)}px;
    ${({ theme, color, isbold, fontSize }) => css`
      color: ${color ? color : theme.colors.white};
      font-weight: ${isbold ? 'bold' : 'normal'};
      font-size: ${fontSize ? calcFontSize(fontSize) : calcFontSize(16)}px;
    `}
  `,
  IconWrapper: styled.View`
    margin: 0 auto;
  `,
};

export default GenericButton;
