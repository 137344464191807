import { IBiteShare } from '../../types/biteShare';
import { EObjectType, IItemInProgress } from '../../types/common';
import { EShareMode } from '../../utils/constants/bite';
import { IOrganization } from '../../types/organization';

const Types = {
  FETCH_PLAYLISTS_REQUEST: 'FETCH_PLAYLISTS_REQUEST',
  PUSH_NEW_PLAYLIST: 'PUSH_NEW_PLAYLIST',
  UPDATE_PLAYLIST: 'UPDATE_PLAYLIST',
  DELETE_PLAYLIST: 'DELETE_PLAYLIST',
  FETCH_QUIZ_SETTINGS: 'FETCH_QUIZ_SETTINGS',
  SET_QUIZ_SETTINGS: 'SET_QUIZ_SETTINGS',
  RESET_QUIZ_SETTINGS: 'RESET_QUIZ_SETTINGS',
  UPDATE_QUIZ_BOOLEAN_SETTING: 'UPDATE_QUIZ_BOOLEAN_SETTING',
  POST_QUIZ_SETTINGS: 'POST_QUIZ_SETTINGS',
  UPDATE_QUIZ_COLOR_SETTING: 'UPDATE_QUIZ_COLOR_SETTING',
  POST_QUIZ_IMAGE_BACKGROUND: 'POST_QUIZ_IMAGE_BACKGROUND',
  SET_QUIZ_IMAGE_BACKGROUND: 'SET_QUIZ_IMAGE_BACKGROUND',
  SET_HAS_QUIZ_IMAGE_BACKGROUND: 'SET_HAS_QUIZ_IMAGE_BACKGROUND',
  FETCH_MORE_PLAYLISTS_REQUEST: 'FETCH_MORE_PLAYLISTS_REQUEST',
  FETCH_PLAYLIST_BITES_REQUEST: 'FETCH_PLAYLIST_BITES_REQUEST',
  CLEAR_PLAYLISTS: 'CLEAR_PLAYLISTS',
  SET_PLAYLISTS: 'SET_PLAYLISTS',
  PATCH_PLAYLIST: 'PATCH_PLAYLIST',
  UPDATE_PLAYLIST_COVER: 'UPDATE_PLAYLIST_COVER',
  UPLOAD_PLAYLIST_COVER_IMAGE: 'UPLOAD_PLAYLIST_COVER_IMAGE',
  SET_PLAYLIST_IN_PROGRESS: 'SET_PLAYLIST_IN_PROGRESS',
  FETCH_FULL_PLAYLISTS: 'FETCH_FULL_PLAYLISTS',
  CLONE_PLAYLIST: 'CLONE_PLAYLIST',
  TRIGGER_CREATED_PLAYLIST_TRANSACTIONAL_COMMUNICATION: 'TRIGGER_CREATED_PLAYLIST_TRANSACTIONAL_COMMUNICATION',
  TRIGGER_CREATED_QUIZ_TRANSACTIONAL_COMMUNICATION: 'TRIGGER_CREATED_QUIZ_TRANSACTIONAL_COMMUNICATION',
};

export default Types;

export interface IInitialState {
  isFetching: boolean;
  playlistsMap: { [key in string | number]: IPlaylist };
  quizSettings: IExtraSettings | null;
  hasImageBackground: boolean;
  playlistsInProgress?: IItemInProgress;
}

export interface IPlaylist {
  content_type: EObjectType;
  id: number;
  subject: string;
  description: string;
  creator: number;
  creator_name: string;
  is_public: boolean;
  is_linear: boolean;
  created_at: string;
  watched: number;
  participants: number;
  success_rate: null | number;
  comments: number;
  bite_shares: IBiteShare[];
  is_quiz: boolean;
  cover: null | number;
  cover_url: string | null;
  linked_cover_url: string;
  sharing_mode: EShareMode;
  organization_logo?: string;
}

export interface IExtraSettings {
  correct_answer_indication: boolean;
  show_report: boolean;
  background_color: string | '';
  background_url?: string | '';
  question_color: string | '';
  background: string | '';
}

export interface IPostQuizSettings {
  id: number;
  quizSettings: IExtraSettings;
}

export interface IUploadPlaylistCover {
  playlistId: number;
  uri: string;
  type?: string;
}

export interface IClonePlaylistAction {
  playlist: IPlaylist;
  org: IOrganization;
}
