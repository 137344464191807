import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import styled, { useTheme } from 'styled-components/native';
import AnyoneIcon from '../../../assets/icons/anyone.svg';
import RequireLoginIcon from '../../../assets/icons/require_login.svg';
import SettingsIcon from '../../../assets/icons/settings.svg';
import TeamOnlyIcon from '../../../assets/icons/team_only.svg';
import TeamOnlyAllowIcon from '../../../assets/icons/team_only_allow_join.svg';
import { EShareMode } from '../../../utils/constants/bite';
import { calcFontSize, calcHeight, calcWidth } from '../../../utils/dimensions';
import SharePanelAccessCodeForm from './SharePanelAccessCodeForm';
import SharePanelViewOption, { IShareViewOption } from './SharePanelViewOption';

interface IProps {
  sharingMode: EShareMode;
  accessCode: string;
  isVisible: boolean;
  onChangeOption: (type: EShareMode) => void;
}

const SharePanelViewSettings: React.FC<IProps> = (props) => {
  const { sharingMode, accessCode, onChangeOption, isVisible } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const animatedHeight = useSharedValue(0);

  useEffect(() => {
    animatedHeight.value = isVisible ? 2000 : 0;
  }, [animatedHeight, isVisible]);

  const viewOptions: IShareViewOption[] = [
    {
      type: EShareMode.ANYONE,
      title: t('share.shareTypeAnyoneTitle'),
      description: t('share.shareTypeAnyoneDesc'),
      Icon: AnyoneIcon,
    },
    {
      type: EShareMode.REQUIRE_LOGIN,
      secondType: EShareMode.TEAM_ONLY_ALLOW_JOIN,
      title: t('share.shareTypeRequireLoginTitle'),
      description: t('share.shareTypeRequireLoginDesc'),
      Icon: RequireLoginIcon,
    },
    {
      type: EShareMode.TEAM_ONLY,
      title: t('share.shareTypeTeamOnlyTitle'),
      description: t('share.shareTypeTeamOnlyDesc'),
      Icon: TeamOnlyAllowIcon,
    },
    {
      type: EShareMode.NO_ONE,
      title: t('share.shareTypeNoOneTitle'),
      description: t('share.shareTypeNoOneDesc'),
      Icon: TeamOnlyIcon,
    },
  ];

  const animatedStyle = useAnimatedStyle(() => ({
    maxHeight: withTiming(animatedHeight.value, {
      duration: isVisible ? 500 : 200,
      easing: Easing.linear,
    }),
  }));

  return (
    <S.Container>
      <Animated.View style={animatedStyle}>
        <S.TitleContainer>
          <S.IconWrapper>
            <SettingsIcon width={calcWidth(17)} height={calcWidth(17)} fill={theme.colors.gray10} />
          </S.IconWrapper>

          <S.Title>{t('share.viewSettings')}</S.Title>
        </S.TitleContainer>

        <S.Container>
          {viewOptions.map((option) => {
            return (
              <SharePanelViewOption
                key={option.type}
                onPress={onChangeOption}
                sharingMode={sharingMode}
                isSelected={[option.type, option.secondType].includes(sharingMode)}
                isExpanded={option.secondType === sharingMode}
                {...option}
              >
                {option.secondType === EShareMode.TEAM_ONLY_ALLOW_JOIN ? (
                  <SharePanelAccessCodeForm
                    sharingMode={sharingMode}
                    accessCode={accessCode}
                    onChange={onChangeOption}
                  />
                ) : null}
              </SharePanelViewOption>
            );
          })}
        </S.Container>
      </Animated.View>
    </S.Container>
  );
};

const Container = styled.ScrollView`
  width: 100%;
`;

const TitleContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: ${calcHeight(11)};
  padding-top: ${calcHeight(11)};
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray8};
`;

const IconWrapper = styled.View`
  margin-right: ${calcWidth(9)}px;
`;

const Title = styled.Text`
  font-size: ${calcFontSize(18)}px;
  color: ${({ theme }) => theme.colors.gray9};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const S = {
  Container,
  TitleContainer,
  IconWrapper,
  Title,
};

export default memo(SharePanelViewSettings);
