interface Props {
  children: any;
}

function KeyboardAwareView(props: Props) {
  const { children } = props;

  return children;
}

export default KeyboardAwareView;
