import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import { CustomText } from '../shared';

export const BlueHeader = styled.View(
  ({ theme }) => css`
    height: ${calcHeight(174)}px;
    width: 100%;
    padding-top: ${calcHeight(28)}px;
    align-items: center;
    background-color: ${theme.colors.primaryBlue};
  `,
);

export const BlueHeaderTitle = styled.Text`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.s32}px;
    font-family: ${theme.fontFamilies.TTNormsMedium};
    text-align: center;
  `}
`;

export const BlueHeaderSmallText = styled(CustomText)(
  ({ theme }) => css`
    margin-top: ${calcHeight(24)}px;
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s16}px;
    color: ${theme.colors.white};
  `,
);
export const MainContentWrapper = styled.View`
  width: ${calcWidth(315)}px;
  flex: 1;
`;

//TODO: change this folder name
