import { IQuestionnaire, IUserQuestionnaire } from './questionnaire.types';

const getSlice = (state) => state.questionnaire;

export const userQuestionnairesSelector = (state): IUserQuestionnaire[] => getSlice(state).userQuestionnaires;

export const questionnaireSelector =
  (questionnaireId: string) =>
  (state): IQuestionnaire =>
    getSlice(state).questionnairesMap?.[questionnaireId];

export const userQuestionnaireByQuestionnaireIdSelector =
  (questionnaireId) =>
  (state): IUserQuestionnaire =>
    getSlice(state).userQuestionnairesMapByQuestionnaireId?.[questionnaireId];
