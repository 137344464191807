import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { calcHeight } from '../../../utils/dimensions';
import { ActivityIndicator } from 'react-native';

type Props = {
  url: string;
  item: any;
  isLoading?: boolean;
  onSelect: (item: any) => void;
};
const VideoSearchItem = ({ url, item, isLoading, onSelect }: Props) => {
  // url = item.fileType ? getCloudAssetImage(item) : url;
  // const videoCoverUrl = useVideoThumbnail(!url ? item.storage.url : null);
  // const displayUrl = url || videoCoverUrl;

  // console.log('displayUrl', displayUrl);

  const handlePress = useCallback(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <>
      <S.Container onClick={!isLoading ? handlePress : undefined}>
        <S.Image src={url} />

        {isLoading && (
          <S.LoadingOverlay>
            <ActivityIndicator size='large' color='white' />
          </S.LoadingOverlay>
        )}
      </S.Container>
    </>
  );
};
export default memo(VideoSearchItem);

// function useVideoThumbnail(videoUrl, captureTime = 1) {
//   const [thumbnailUrl, setThumbnailUrl] = useState('');

//   useEffect(() => {
//     if (!videoUrl) {
//       return;
//     }

//     // Create video and canvas elements
//     const video = document.createElement('video');
//     video.preload = 'metadata';
//     video.src = videoUrl;
//     video.style.display = 'none'; // Hide the video element

//     const canvas = document.createElement('canvas');
//     canvas.style.display = 'none'; // Hide the canvas element

//     // Function to capture the thumbnail
//     const captureThumbnail = () => {
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       const context = canvas.getContext('2d');
//       context.drawImage(video, 0, 0, canvas.width, canvas.height);
//       const imageDataUrl = canvas.toDataURL();
//       console.log('imageDataUrl', imageDataUrl);

//       setThumbnailUrl(imageDataUrl);
//     };

//     // Event listeners for video metadata loading and seeking
//     const onLoadedMetadata = () => {
//       video.currentTime = captureTime;
//     };
//     const onSeeked = () => {
//       captureThumbnail();
//       document.body.removeChild(video); // Clean up video element
//       document.body.removeChild(canvas); // Clean up canvas element
//     };

//     video.addEventListener('loadedmetadata', onLoadedMetadata);
//     video.addEventListener('seeked', onSeeked);

//     // Add video and canvas to the document body to ensure they can load and render
//     document.body.appendChild(video);
//     document.body.appendChild(canvas);

//     // Clean up function
//     return () => {
//       video.removeEventListener('loadedmetadata', onLoadedMetadata);
//       video.removeEventListener('seeked', onSeeked);
//       if (video.parentNode) {
//         video.parentNode.removeChild(video);
//       }
//       if (canvas.parentNode) {
//         canvas.parentNode.removeChild(canvas);
//       }
//     };
//   }, [videoUrl, captureTime]);

//   return thumbnailUrl;
// }

export const S = {
  Container: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 0px ${calcHeight(8)}px ${calcHeight(8)}px;
    cursor: pointer;
    overflow: hidden;
  `,
  Image: styled.img`
    width: 100%;
  `,
  LoadingOverlay: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  `,
};
