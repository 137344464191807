import React from 'react';
import styled from 'styled-components/native';
import ElementPanelPhoto from '../../../../assets/images/element-panel-photo.png';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import HeaderHandle from './HeaderHandle';

interface IProps {
  title: string;
  subtitle: string;
  isOpen: boolean;
}

const BiteCreatedElementsPanelHeader: React.FC<IProps> = ({ title, subtitle, isOpen }) => {
  return (
    <S.CustomHeaderContainer>
      <S.HeaderHandleContainer>
        <HeaderHandle isOpen={isOpen} />
      </S.HeaderHandleContainer>
      <S.CustomHeaderTitleContainer>
        <S.CustomHeaderTitleBold>{title}</S.CustomHeaderTitleBold>
        <S.CustomHeaderTitle>{!isOpen ? subtitle : ''}</S.CustomHeaderTitle>
      </S.CustomHeaderTitleContainer>
      <S.Image source={ElementPanelPhoto} resizeMode={'contain'} />
    </S.CustomHeaderContainer>
  );
};

const S = {
  Image: styled.Image`
    margin-top: ${calcHeight(-26)}px;
    width: ${calcWidth(154)}px;
    height: ${calcHeight(76)}px;
  `,
  CustomHeaderContainer: styled.View`
    width: 100%;
    height: ${calcHeight(85)}px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  CustomHeaderTitleContainer: styled.View`
    margin-left: ${calcWidth(17)}px;
    text-align: left;
    justify-content: center;
  `,
  CustomHeaderTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
  `,
  CustomHeaderTitleBold: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
  `,
  HeaderHandleContainer: styled.View`
    position: absolute;
    align-items: center;
    width: 100%;
    top: ${calcHeight(6)}px;
  `,
};

export default BiteCreatedElementsPanelHeader;
