import shortid from 'shortid';
import { IUploadImageArgs } from './uploadImageByUrl.types';
import withRetry from '../withRetry';
import store from '../../store';
import { logError } from '../../store/appActivity/appActivity.slice';
import last from 'lodash/last';
import BitesApi from '../../store/api/bites-api';
import { EFileType } from '../../types/media';

const uploadImageByUrl = ({ url, processId }: IUploadImageArgs) => {
  try {
    return withRetry(
      async () => {
        const response = await fetch(url);
        const blob = await response.blob();

        const extension = last(url.split('?')[0].split('.'));
        const contentType = blob?.type || `image/${extension}`;
        const fileName = `${shortid.generate()}.${extension}`;
        const file = new File([blob], fileName, { type: contentType });

        const formdata = new FormData();
        formdata.append('file', file, fileName);
        formdata.append('file_type', EFileType.IMAGE);

        const { data } = await BitesApi.post('/media/', formdata, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        return data;
      },
      {
        errorContext: {
          processId,
          data: {
            action: 'uploadImageByUrl',
          },
        },
      },
    );
  } catch (error) {
    store.dispatch(
      logError({
        event: 'uploadImageByUrl: error',
        data: error,
      }),
    );
  }
};

export default uploadImageByUrl;
