import React, { memo } from 'react';
import OwnerInitials from '../../../../components/feed/biteItemOverlays/OwnerInitials';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import dayjs from 'dayjs';
import Divider from '../../../../components/shared/Divider';
import { IAnalyticsUser } from '../../../../store/analytics/analytics.types';
import Answer from './common/Answer';
import { getIsWebRtl } from '../../../../locale/i18n';
import { analyticsListItemDataSet } from '../../analytics.constants';

interface IProps {
  renderIcons?: () => React.ReactNode;
  lastActivity?: string;
  user: IAnalyticsUser;
  answer?: string;
  withDivider?: boolean;
  mediaUrl?: string;
}

const OWNER_INITIALS_SIZE = calcWidth(44);

const ListItem: React.FC<IProps> = ({ renderIcons, user, lastActivity, answer, mediaUrl, withDivider }) => {
  const isWebRtl = getIsWebRtl();
  const isGuest = !!user.guest_id;
  const userName = isGuest
    ? user.guest_alias
    : `${user.first_name ? user.first_name + ' ' : ''}${user.last_name || ''}`;
  const profileImage = user.profile_image;

  const lastActivityDate = dayjs(lastActivity);
  const formattedLastActivity = lastActivityDate.isToday()
    ? lastActivityDate.format('HH:mm')
    : lastActivityDate.fromNow();

  return (
    <>
      {withDivider && <S.Divider />}
      <S.Container
        // @ts-ignore
        dataSet={analyticsListItemDataSet}
        isRtl={isWebRtl}
      >
        <S.LeftBlock isRtl={isWebRtl}>
          <S.OwnerInitials size={OWNER_INITIALS_SIZE} name={userName} photo={profileImage} isGrayed={!lastActivity} />
          <S.TextContainer>
            <S.NameContainer isRtl={isWebRtl}>
              {lastActivity ? (
                <>
                  <S.LastActivityText>{formattedLastActivity}</S.LastActivityText>
                  <S.NameText>{userName}</S.NameText>
                </>
              ) : (
                <>
                  <S.LastActivityTextGrayed>---</S.LastActivityTextGrayed>
                  <S.NameTextGrayed>{userName}</S.NameTextGrayed>
                </>
              )}
            </S.NameContainer>
            <Answer answer={answer} url={mediaUrl} />
          </S.TextContainer>
        </S.LeftBlock>
        <S.IconsContainer>{renderIcons()}</S.IconsContainer>
      </S.Container>
    </>
  );
};

const S = {
  Container: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    margin: ${calcHeight(8)}px ${calcWidth(20)}px;
    justify-content: space-between;
  `,
  LeftBlock: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  TextContainer: styled.View`
    justify-content: center;
    flex-direction: column;
  `,
  OwnerInitials: styled(OwnerInitials)`
    border-radius: 100px;
  `,
  NameContainer: styled.View<{ isRtl: boolean }>`
    align-items: ${({ isRtl }) => (isRtl ? 'flex-end' : 'flex-start')};
    margin: 0 ${calcWidth(8)}px;
  `,
  LastActivityText: styled.Text`
    height: ${calcHeight(15)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
    text-align: left;
  `,
  LastActivityTextGrayed: styled.Text`
    height: ${calcHeight(15)}px;
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 400;
    text-align: left;
  `,

  NameText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 700;
  `,
  NameTextGrayed: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s13};
    font-weight: 700;
  `,
  IconsContainer: styled.View`
    margin-top: ${calcHeight(8)}px;
  `,
  Divider: styled(Divider)`
    margin: ${calcHeight(8)}px 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
};

export default memo(ListItem);
