import { cloneDeep } from 'lodash';

type ObjectWithId = {
  id: string | number;
};

const removeFrontendId = <T extends ObjectWithId>(item: T) => {
  const itemCopy = cloneDeep(item);
  if (item.id < 0) {
    delete itemCopy.id;
  }
  return itemCopy;
};

let counter = 0;
export const generateFrontendId = () => --counter;

export const removeFrontendIds = <T extends ObjectWithId>(items: T[]) => items.map((item) => removeFrontendId<T>(item));
