import { takeLatest, put, all, select, delay, takeEvery, takeLeading, race, call, take } from 'redux-saga/effects';
import AsyncStorage from '@react-native-community/async-storage';
import { tokenRef } from '../api/bites-api';

import {
  runInit,
  setAvaitingEnhancements,
  setBiteAvaitingEnhancements,
  setConfig,
  updateUserOrgPreferences,
  userOrgPreferencesSuccess,
  startBiteCreation,
  startPlaylistCreation,
  trackEvent,
  loadConfig,
  setTranslationsAreInitialized,
  setIsRequestNotificationPermissionsModalShown,
  setRequestNotificationPermissionsModalShown,
  log,
  logError,
  setIsConnected,
  setAppState,
  setSlowConnection,
  setBiteIntroHelpersConfig,
  setBiteCreatedHelpersConfig,
  setExternalAction,
  applyExternalAction,
  runExternalAction,
  loadBiteHelperConfigs,
  setContentLocales,
  loadContentLocales,
  initCaptcha as initCaptchaAction,
  setIsMobileStatsExplanationVideoWatched,
} from './appActivity.slice';
import gtmTrack, { getEnvironmentGenericProps } from '../../services/gtm/track';
import {
  avaitingEnhancementsSelector,
  configSelector,
  acrossSessionsIdSelector,
  userOrgPreferencesSelector,
  isConnectedSelector,
  appStateSelector,
  slowConnectionSelector,
  currentFlowSelector,
  biteIntroHelpersConfigSelector,
  contentLocalesSelector,
  externalActionSelector,
  isNavigationStackDisplayedSelector,
} from './appActivity.selectors';
import { activeOrganizationSelector, leadNameSelector, userSelector } from '../auth/auth.selectors';
import * as calls from '../api/bites-api/calls/common.calls';

import * as avaitingEnhancementsUtils from '../../utils/introMedia/avaitingEnhancements';
import { clearCreateBiteState } from '../createBite/createBites.actions';
import { cleanEditAndCreateBiteState } from '../bite/bite.actions';
import Routes from '../../navigation/routes';
import { navigate, replace } from '../../navigation/RootNavigation';
import { resetCreatePlaylistState } from '../createPlaylist/createPlaylist.actions';
import {
  EAppFlow,
  IAvaitingEnhancements,
  IGenericTrackingProps,
  INativeGenericTrackingProps,
  ITrackInfoArgs,
  IUserOrgPreferences,
  IWebGenericTrackingProps,
  IExternalAction,
} from './appActivity.types';
import { organizationCreatedAtSelector } from '../org/org.selectors';
import { applyTranslations, TLocale } from '../../locale/i18n';
import { IS_NOTIFICATION_PERMISSIONS_MODAL_SHOWN } from '../../utils/constants/common';
import { isWeb } from '../../utils/dimensions';
import sendLog, { logError as sendErrorLog } from '../../services/log';
import { sessionId } from './appActivity.constants';
import { EAppState } from '../../hooks/useOnAppStateChange';
import { IS_ANDROID, IS_IOS, IS_PROD } from '../../utils/constants/env';
import { getBuildNumber, getVersion } from 'react-native-device-info';
import { PayloadAction } from '@reduxjs/toolkit';
import runAction from '../../utils/runExternalAction';
import { initCaptcha } from '../../services/recaptcha';
import { unauthorizedExternalActionTypes } from '../../utils/runExternalAction/runExternalAction';

function* loadConfigSaga() {
  const [{ data: config }, { data: translations }] = yield all([calls.getConfig(), calls.loadTranslations()]);

  yield put(setConfig(config));
  yield applyTranslations(translations as { [key in TLocale]: any });
  yield put(setTranslationsAreInitialized());
}

function* initCaptchaSaga() {
  let config = yield select(configSelector);

  if (!config) {
    yield take(setConfig);
  }

  config = yield select(configSelector);

  yield initCaptcha(config);
}

function* loadContentLocalesSaga() {
  const locales = yield select(contentLocalesSelector);

  if (locales) {
    return;
  }

  const { data } = yield calls.getContentLocales();
  yield put(setContentLocales(data.locales));
}

function* initSaga() {
  const config = yield select(configSelector);

  if (!config) {
    yield take(setConfig);
  }
  yield all([
    loadUserOrgPreferencesSaga(),
    loadAvaitingEnhancementsSaga(),
    initNotificationsPermissionSaga(),
    isMobileStatsExplanationVideoWatchedSaga(),
  ]);
}

function* loadUserOrgPreferencesSaga() {
  const organization = yield select(activeOrganizationSelector);

  const { data } = (yield calls.getMyPreferences(organization.id)) as { data: IUserOrgPreferences };
  yield put(userOrgPreferencesSuccess(data));
}

function* updateUserOrgPreferencesSaga({ payload }: { payload: Partial<IUserOrgPreferences> }) {
  const organization = yield select(activeOrganizationSelector);
  const { data } = yield calls.updateUserOrgPreferences(organization.id, payload);
  yield put(userOrgPreferencesSuccess(data));
}

function* initNotificationsPermissionSaga() {
  const didShare = yield AsyncStorage.getItem(IS_NOTIFICATION_PERMISSIONS_MODAL_SHOWN);
  yield put(setIsRequestNotificationPermissionsModalShown(didShare === '1'));
}

function* setRequestNotificationPermissionsModalShownSaga({ payload }: { payload: boolean }) {
  yield AsyncStorage.setItem(IS_NOTIFICATION_PERMISSIONS_MODAL_SHOWN, payload ? '1' : '0');
  yield put(setIsRequestNotificationPermissionsModalShown(payload));
}

function* isMobileStatsExplanationVideoWatchedSaga() {
  const isVideoWatched = yield AsyncStorage.getItem('mobileStatsIntroVideoWatched');
  yield put(setIsMobileStatsExplanationVideoWatched(isVideoWatched === 'true'));
}

function* loadAvaitingEnhancementsSaga() {
  const avaitingEnhancements: IAvaitingEnhancements = yield avaitingEnhancementsUtils.getAvaitingEnhancements();
  const cleanedAvaitingEnhancements = Object.entries(avaitingEnhancements).reduce((cleaned, [biteId, dateStr]) => {
    if (Date.now() < new Date(dateStr).getTime() + 30 * 24 * 60 * 60 * 1000) {
      cleaned[biteId] = dateStr;
    }
    return cleaned;
  }, {} as IAvaitingEnhancements);
  yield put(setAvaitingEnhancements(cleanedAvaitingEnhancements));
}

function* setBiteAvaitingEnhancementsSaga() {
  const avaitingEnhancements: IAvaitingEnhancements = yield select(avaitingEnhancementsSelector);
  yield setAvaitingEnhancementsSaga({ payload: avaitingEnhancements });
}

function* setAvaitingEnhancementsSaga({ payload: avaitingEnhancements }: { payload: IAvaitingEnhancements }) {
  yield avaitingEnhancementsUtils.setAvaitingEnhancements(avaitingEnhancements);
}

function* loadBiteHelperConfigsSaga() {
  const biteIntroHelpersConfig = yield select(biteIntroHelpersConfigSelector);
  if (biteIntroHelpersConfig) {
    return;
  }

  // TODO: add config loading for the other helper sections
  const [{ data: intro }, { data: created }] = yield all([
    calls.getBiteIntroHelpersConfig(),
    calls.getBiteCreatedHelpersConfig(),
  ]);

  yield put(setBiteIntroHelpersConfig(intro));
  yield put(setBiteCreatedHelpersConfig(created));
}

function* startBiteCreationSaga() {
  yield put(loadBiteHelperConfigs());
  const userOrgPreferences = yield select(userOrgPreferencesSelector);
  const screen = userOrgPreferences.skip_create_bite_flow_overview_screen
    ? Routes.CreateBiteStack.BiteTellYourStory
    : Routes.CreateBiteStack.CreateBiteInfo;

  yield put(clearCreateBiteState());
  yield put(cleanEditAndCreateBiteState());

  if (isWeb) {
    replace(Routes.CreateBiteStack.StackName, { screen });
    return;
  }
  navigate(Routes.CreateBiteStack.StackName, { screen });
}

function* startPlaylistCreationSaga() {
  const userOrgPreferences = yield select(userOrgPreferencesSelector);
  const screen = userOrgPreferences.skip_create_playlist_flow_overview_screen
    ? Routes.CreatePlaylistStack.AddBites
    : Routes.CreatePlaylistStack.CreatePlaylistInfo;

  yield put(resetCreatePlaylistState());

  if (isWeb) {
    replace(Routes.CreatePlaylistStack.StackName, { screen });
    return;
  }
  navigate(Routes.CreatePlaylistStack.StackName, { screen });
}

const logsQueue = [];

function* processLogsQueueSaga() {
  const isConnected = yield select(isConnectedSelector);
  const appState = yield select(appStateSelector);

  if (!isConnected || (!isWeb && appState !== EAppState.active)) {
    return;
  }

  while (logsQueue.length > 0) {
    const { logger, payload } = logsQueue.shift();
    try {
      yield logger(payload);
    } catch (error) {}
  }
}

function* trackEventSaga({ payload }: { payload: ITrackInfoArgs }) {
  const config = yield select(configSelector);
  if (!config) {
    yield take(setConfig);
  }
  yield sendLogControllerSaga({
    logger: async (enrichedPayload) => gtmTrack(payload.event, enrichedPayload, { config }),
    payload: payload.props || {},
  });
}

function* logSaga({ payload }) {
  // console.log('logSaga', payload);

  yield sendLogControllerSaga({
    logger: sendLog,
    payload,
  });
}

function* logErrorSaga({ payload }) {
  yield sendLogControllerSaga({
    logger: sendErrorLog,
    payload,
  });
}

function* sendLogControllerSaga({ payload, logger }) {
  const genericProps = yield getGenericTrackingPropsSaga();

  const enrichedPayload = {
    ...genericProps,
    ...payload,
  };

  const isConnected = yield select(isConnectedSelector);
  const appState = yield select(appStateSelector);

  if (!isConnected || (!isWeb && appState !== EAppState.active && appState !== EAppState.launch)) {
    logsQueue.push({ logger, payload: enrichedPayload });
    return;
  }

  logger(enrichedPayload).catch((error) => {
    console.error('Failed to log', error);
  });
}

let eventCounter = 0;
export function* getGenericTrackingPropsSaga() {
  const user = yield select(userSelector);
  const org = yield select(activeOrganizationSelector);
  const leadName = yield select(leadNameSelector);
  const createdAt = yield select(organizationCreatedAtSelector);
  const acrossSessionsId = yield select(acrossSessionsIdSelector);
  const isConnected = yield select(isConnectedSelector);
  const appState = yield select(appStateSelector);
  const slowConnection = yield select(slowConnectionSelector);

  const environmentGenericProps: INativeGenericTrackingProps | IWebGenericTrackingProps =
    yield getEnvironmentGenericProps();

  const version = isWeb
    ? `${process.env.BITES_VERSION_NAME} ${process.env.BITES_BUILD_NUMBER}`
    : `${getVersion()} ${getBuildNumber()}`;

  const genericTrackingProps: IGenericTrackingProps = {
    ...environmentGenericProps,
    token: tokenRef.current,
    version,
    app_version: version,
    app_env: IS_PROD ? 'prod' : 'staging',
    across_sessions_id: acrossSessionsId,
    bites_session_id: sessionId,
    bites_user_id: user?.id,
    org_id: org?.id,
    lead_name: leadName,
    username: `${user?.first_name} ${user?.last_name}`,
    user_email: user?.email,
    org_name: org?.name,
    org_create_date: createdAt,
    is_connected: isConnected,
    app_state: appState,
    date_utc: new Date().toISOString(),
    timestamp: Date.now(),
    event_counter: ++eventCounter,
    is_slow_connection: slowConnection.isSlowConnection,
    platform: isWeb ? 'web' : IS_ANDROID ? 'android' : IS_IOS ? 'ios' : null,
    origin: 'native',
  };
  return genericTrackingProps;
}

function* testSlowConnection() {
  while (true) {
    try {
      const slowConnection = yield select(slowConnectionSelector);
      const currentFlow = yield select(currentFlowSelector);
      const config = yield select(configSelector);
      if (!config) {
        yield take(setConfig);
      }

      const isInsideFlow =
        currentFlow?.flow === EAppFlow.BITE_CREATION && currentFlow.hasIntroVideo && !currentFlow.videoUploadedToS3;

      if (!isInsideFlow || !config.slowConnectionThreshold) {
        yield delay(5000);
        continue;
      }

      const { timeout } = yield race({
        request: calls.getSpeedTest(),
        timeout: delay(config.slowConnectionThreshold),
      });
      const isSlowConnection = !!timeout;

      if (slowConnection.isSlowConnection !== isSlowConnection) {
        yield put(setSlowConnection({ isSlowConnection }));
        yield put(
          log({
            event: 'is_slow_connection',
          }),
        );
      }

      yield delay(isSlowConnection ? 10000 : 5000);
    } catch (e) {
      yield delay(5000);
    }
  }
}

function* applyExternalActionSaga({ payload }: PayloadAction<IExternalAction>) {
  const user = yield select(userSelector);
  const isNavigationStackDisplayed = yield select(isNavigationStackDisplayedSelector);

  yield put(setExternalAction(payload));

  if (
    ((payload?.action && unauthorizedExternalActionTypes.includes(payload.action)) || user) &&
    isNavigationStackDisplayed
  ) {
    yield runExternalActionSaga();
  }
}

function* runExternalActionSaga() {
  const externalAction = yield select(externalActionSelector);

  if (!externalAction) {
    return;
  }

  yield put(setExternalAction(null));
  yield call(runAction, externalAction);
}

export default function* appActivitySaga() {
  yield takeLatest(applyExternalAction, applyExternalActionSaga);
  yield takeLatest(runExternalAction, runExternalActionSaga);
  yield takeLatest(loadConfig, loadConfigSaga);
  yield takeLatest(initCaptchaAction, initCaptchaSaga);
  yield takeLatest(runInit, initSaga);
  yield takeLatest(setRequestNotificationPermissionsModalShown, setRequestNotificationPermissionsModalShownSaga);
  yield takeLatest(updateUserOrgPreferences, updateUserOrgPreferencesSaga);
  yield takeLatest(setAvaitingEnhancements, setAvaitingEnhancementsSaga);
  yield takeLatest(setBiteAvaitingEnhancements, setBiteAvaitingEnhancementsSaga);
  yield takeLatest(startPlaylistCreation, startPlaylistCreationSaga);
  yield takeLatest(startBiteCreation, startBiteCreationSaga);
  yield takeEvery(trackEvent, trackEventSaga);
  yield takeEvery(log, logSaga);
  yield takeEvery(logError, logErrorSaga);
  yield takeLeading(setIsConnected, processLogsQueueSaga);
  yield takeLeading(setAppState, processLogsQueueSaga);
  yield takeLeading(loadBiteHelperConfigs, loadBiteHelperConfigsSaga);
  yield takeLeading(loadContentLocales, loadContentLocalesSaga);

  if (!isWeb) {
    yield testSlowConnection();
  }
}
