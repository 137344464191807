import CopySuccessToast from '../../components/shared/Toasts/CopySuccessToast';
import NetworkErrorToast from '../../components/shared/Toasts/NetworkErrorToast';

export enum EToastTypes {
  networkError = 'networkError',
  copySuccess = 'copySuccess',
}

const TOAST_CONFIG = {
  [EToastTypes.networkError]: NetworkErrorToast,
  [EToastTypes.copySuccess]: CopySuccessToast,
};

export default TOAST_CONFIG;
