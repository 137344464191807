import shortid from 'shortid';

import BitesApi from '../../store/api/bites-api';
import { IUploadImageArgs } from './uploadImageTypes';
import { logError } from '../../services/log';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../constants/toastConfig';

const uploadImage = async ({ file, path }: IUploadImageArgs) => {
  try {
    const formData = new FormData();
    const fileName = shortid.generate() + '.' + file.type.split('/')[1];
    formData.append('image', file, fileName);
    const { data } = await BitesApi.post(`${path}/`, formData);
    return { data };
  } catch (error) {
    logError(error);

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
};

export default uploadImage;
