import React from 'react';
import Modal from '../ModalController';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../../shared';
import { useTranslation } from 'react-i18next';
import GenericButton from '../../shared/buttons/GenericButton';
import GearIcon from '../../../assets/icons/gear.svg';
import { calcHeight, calcWidth } from '../../../utils/dimensions';

interface IUpgradeToContinueModal {
  storeUrl: string;
}

const UpgradeToContinueModal: React.FC<IUpgradeToContinueModal> = ({ storeUrl }) => {
  const { t } = useTranslation();

  return (
    <Modal backdropOpacity={0.4} hideModalContentWhileAnimating isVisible>
      <S.Container>
        <S.Content>
          <S.TopContent>
            <S.Icon />
            <S.Title>{t('upgradeToContinueModal.title')}</S.Title>
          </S.TopContent>
          <S.BottomContent>
            <S.Description>{t('upgradeToContinueModal.description')}</S.Description>
            <GenericButton
              content={t('upgradeToContinueModal.update')}
              width={140}
              onPress={() => Linking.openURL(storeUrl)}
            />
          </S.BottomContent>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    margin: 0 auto;
  `,
  Content: styled.View`
    border-radius: 16px;
    background: ${({ theme }) => theme.colors.white};
  `,
  Icon: styled(GearIcon)`
    width: ${calcWidth(70)}px;
    height: ${calcWidth(70)}px;
    margin-bottom: ${calcHeight(16)}px;
    align-items: center;
    justify-content: center;
  `,
  TopContent: styled.View`
    align-items: center;
    border: solid 0.5px ${({ theme }) => theme.colors.gray5};
    padding: ${calcHeight(33)}px ${calcWidth(14)}px ${calcHeight(18)}px;
  `,
  BottomContent: styled.View`
    align-items: center;
    padding: ${calcHeight(18)}px ${calcWidth(14)}px ${calcHeight(33)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s22}px;
    color: ${({ theme }) => theme.colors.borderGray};
    font-weight: 500;
  `,
  Description: styled.Text`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.s18}px;
    margin-bottom: ${calcHeight(30)}px;
    line-height: ${({ theme }) => theme.fontSizes.s28}px;
    color: ${({ theme }) => theme.colors.borderGray};
    font-weight: 500;
  `,
  Button: styled(Button)`
    height: ${calcHeight(40)}px;
  `,
};

export default UpgradeToContinueModal;
