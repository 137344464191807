import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import setToClipboard from '../../utils/clipboard/clipboard';
import { calcFontSize, calcHeight, calcWidth } from '../../utils/dimensions';
import CopyIcon from '../../assets/icons/copy.svg';
import CopyFillIcon from '../../assets/icons/copy_fill.svg';
import { ViewStyle } from 'react-native';

interface IProps {
  style?: ViewStyle;
  disabled?: boolean;
  code: string;
}

const CodeClipboard: React.FC<IProps> = ({ disabled, code, style }) => {
  const handleCopy = useCallback(() => {
    setToClipboard(code);
  }, [code]);

  const Icon = disabled ? CopyIcon : CopyFillIcon;

  return (
    <S.Container style={style} disabled={disabled} onPress={handleCopy}>
      <S.CodeWrapper>
        <S.Code disabled={disabled}>{code}</S.Code>
      </S.CodeWrapper>

      <S.DashedLineWrapper>
        <S.DashedLine />
      </S.DashedLineWrapper>

      <S.IconBlock>
        <Icon width={21} height={22} />
      </S.IconBlock>
    </S.Container>
  );
};

const S = {
  Container: styled.TouchableOpacity<{ disabled: boolean }>`
    flex-direction: row;
    align-self: flex-start;
    height: ${calcHeight(33)}px;
    border-radius: ${calcWidth(10)}px;
    border-width: 1px;
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray15 : theme.colors.gray14)};
    ${({ disabled, theme }) => (!disabled ? `background-color: ${theme.colors.white}` : '')}
  `,
  IconBlock: styled.View`
    height: 100%;
    width: ${calcWidth(35)}px;
    text-align: center;
    justify-content: center;
    align-items: center;
  `,
  CodeWrapper: styled.View`
    padding-left: ${calcWidth(25)}px;
    padding-right: ${calcWidth(25)}px;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  `,
  Code: styled.Text<{ disabled: boolean }>`
    text-align: center;
    font-size: ${calcFontSize(19)}px;
    color: ${({ theme, disabled }) => {
      return disabled ? theme.colors.lightGray15 : theme.colors.gray13;
    }};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  `,
  DashedLineWrapper: styled.View`
    width: 1px;
    overflow: hidden;
  `,
  DashedLine: styled.View`
    border: 1px dashed ${({ theme }) => theme.colors.lightGray15};
    border-radius: 1px;
    flex: 1;
  `,
};

export default CodeClipboard;
