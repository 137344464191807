import { useNavigationState } from '@react-navigation/native';
import { IAnyObject } from '../types/generics';

const useCurrentRoute = () => {
  const routes = useNavigationState((state) => state.routes);
  const currentRoute = routes[routes.length - 1];
  const routeParams: IAnyObject = currentRoute?.params;

  return {
    routes,
    route: currentRoute,
    params: routeParams,
  };
};

export default useCurrentRoute;
