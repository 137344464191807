import React, { useCallback, useRef } from 'react';
import styled from 'styled-components/native';
import setToClipboard from '../../../utils/clipboard/clipboard';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import ShareCopyIcon from '../../../assets/icons/share-copy.svg';
import { ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { toast } from 'react-toastify';
import Tooltip from '../../../components/Tooltip';

interface IProps {
  style?: ViewStyle;
  disabled?: boolean;
  code: string;
  toastText: string;
}

const TOOLTIP_SHOWING_TIME = 3000;

const InviteCodeClipboard: React.FC<IProps> = ({ disabled, code, style, toastText }) => {
  const theme = useTheme();
  const tooltipRef = useRef(null);

  const showTooltip = useCallback(() => {
    tooltipRef.current?.show?.();

    setTimeout(tooltipRef.current?.hide, TOOLTIP_SHOWING_TIME);
  }, []);

  const handleCopy = useCallback(() => {
    if (isWeb) {
      toast(toastText);
      setToClipboard(code);
      return;
    }
    showTooltip();
    setToClipboard(code);
  }, [code, showTooltip, toastText]);

  return (
    <S.Container style={style} disabled={disabled} onPress={handleCopy}>
      <S.CodeWrapper>
        <S.Code disabled={disabled} numberOfLines={1}>
          {code}
        </S.Code>
      </S.CodeWrapper>

      <S.IconContainer>
        <Tooltip methodsRef={tooltipRef} text={toastText} fontSize={theme.fontSizes.s11} withGradient>
          <S.IconBlock>
            <ShareCopyIcon width={24} height={24} color={disabled ? theme.colors.text : theme.colors.primaryBlue} />
          </S.IconBlock>
        </Tooltip>
      </S.IconContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.TouchableOpacity<{ disabled: boolean }>`
    flex-direction: row;
    align-self: flex-start;
    height: ${calcHeight(33)}px;
    border-radius: ${calcWidth(10)}px;
    border-width: 1px;
    border-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGray15 : theme.colors.lightGray36)};
    ${({ disabled, theme }) => (!disabled ? `background-color: ${theme.colors.white};` : '')}
    overflow: hidden;
  `,
  IconContainer: styled.View`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  IconBlock: styled.View`
    height: 100%;
    width: ${calcWidth(35)}px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  `,
  CodeWrapper: styled.View`
    padding-left: ${calcWidth(25)}px;
    padding-right: ${calcWidth(25)}px;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  `,
  Code: styled.Text<{ disabled: boolean }>`
    text-align: center;
    font-size: ${calcFontSize(19)}px;
    color: ${({ theme, disabled }) => {
      return disabled ? theme.colors.lightGray15 : theme.colors.gray13;
    }};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  `,
};

export default InviteCodeClipboard;
