import { ITimelineItem } from '../videoEditor/videoEditor.types';

interface ICloudAssetCacheStore {
  [key: string]: TCloudAssetCache;
}

export interface ICloudAssetImageCache {
  blobUrl: string;
  image: HTMLImageElement;
}
export interface ICloudAssetGifCache {
  gifFrames: {
    delay: number;
    dims: {
      width: number;
      height: number;
    };
    patch: Uint8ClampedArray;
  }[];
}
export interface ICloudAssetVideoCache {
  blobUrl: string;
  video: HTMLVideoElement;
  getVideo: () => Promise<HTMLVideoElement>;
}
export interface ICloudAssetAudioCache {
  blobUrl: string;
  audio: HTMLAudioElement;
  getAudio: () => Promise<HTMLAudioElement>;
}

export type TCloudAssetCache =
  | ICloudAssetImageCache
  | ICloudAssetGifCache
  | ICloudAssetVideoCache
  | ICloudAssetAudioCache;

export const cloudAssetCache: ICloudAssetCacheStore = {};
export const cloudAssetCacheByTimelineItemId: ICloudAssetCacheStore = {};

// @ts-ignore
window.cloudAssetCache = cloudAssetCache;
// @ts-ignore
window.cloudAssetCacheByTimelineItemId = cloudAssetCacheByTimelineItemId;

export const cloneCloudAssetCacheForAudioTimelineItem = async (
  audioCloudAssetCache: ICloudAssetAudioCache,
  timelineItemId: ITimelineItem['id'],
): Promise<TCloudAssetCache> => {
  const audio = await audioCloudAssetCache.getAudio();

  cloudAssetCacheByTimelineItemId[timelineItemId] = {
    ...audioCloudAssetCache,
    audio,
  };

  return cloudAssetCacheByTimelineItemId[timelineItemId];
};

export const cloneCloudAssetCacheForVideoTimelineItem = async (
  videoCloudAssetCache: ICloudAssetVideoCache,
  timelineItemId: ITimelineItem['id'],
): Promise<TCloudAssetCache> => {
  const video = await videoCloudAssetCache.getVideo();

  cloudAssetCacheByTimelineItemId[timelineItemId] = {
    ...videoCloudAssetCache,
    video,
  };

  return cloudAssetCacheByTimelineItemId[timelineItemId];
};

export const videoFramesBytimelineItemId: Record<string, HTMLCanvasElement> = {};
