const languages = [
  {
    lang: 'he',
    title: 'עברית',
  },
  {
    lang: 'en',
    title: 'English',
  },
  {
    lang: 'es',
    title: 'Español',
  },
  // {
  //   lang: 'fr',
  //   title: 'Français',
  // },
  {
    lang: 'de',
    title: 'Deutsche',
  },
  {
    lang: 'pt',
    title: 'Português',
  },
];

export default languages;
