import { isWeb } from '../dimensions';
import Routes from '../../navigation/routes';

export const getHomeScreen = () => {
  if (isWeb) {
    return {
      name: Routes.HomeStack.StackName,
      params: {
        screen: Routes.HomeStack.Home,
      },
    };
  }

  return {
    name: Routes.HomeDrawer.StackName,
  };
};
