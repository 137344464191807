import { AxiosPromise } from 'axios';
import store from '../..';
import { getCaptchaToken } from '../../../services/recaptcha';
import { log, logError } from '../../appActivity/appActivity.slice';

export enum ECAptchaAction {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  GENERATE_OTP = 'GENERATE_OTP',
  PASSWORD_RESET = 'PASSWORD_RESET',
}

let withCaptcha = false;

export const wrapWithCaptcha = (action: ECAptchaAction, fn: (...args: any[]) => AxiosPromise) => {
  const _fn = async (...props) => {
    store.dispatch(
      log({
        event: 'wrapWithCaptcha',
        data: {
          action,
          withCaptcha,
        },
      }),
    );

    const captchaToken = withCaptcha ? await getCaptchaToken(action) : undefined;

    const headers = captchaToken
      ? {
          'x-recaptcha-token': captchaToken,
        }
      : {};

    store.dispatch(
      log({
        event: 'wrapWithCaptcha: request',
        data: {
          action,
          withCaptcha,
          captchaToken,
        },
      }),
    );

    try {
      return await fn(...props, { headers });
    } catch (error) {
      if (
        error.response?.status === 405 &&
        (error.response?.headers?.['x-amzn-waf-action'] === 'captcha' ||
          error.response?.headers?.['X-Amzn-Waf-Action'] === 'captcha') &&
        !captchaToken
      ) {
        withCaptcha = true;

        store.dispatch(
          log({
            event: 'wrapWithCaptcha: error, retry with captcha',
            data: {
              error,
              action,
              withCaptcha,
              captchaToken,
            },
          }),
        );

        return _fn(...props);
      }

      store.dispatch(
        logError({
          event: 'wrapWithCaptcha: error, throw',
          data: {
            error,
            action,
            withCaptcha,
            captchaToken,
          },
        }),
      );

      throw error;
    }
  };

  return _fn;
};
