import * as firebase from '@firebase/app';
import { EEnvironment } from '../../utils/constants/common';
import { ENV } from '../../utils/constants/env';

const FIREBASE_CONFIGS: { [key in EEnvironment]: firebase.FirebaseOptions } = {
  [EEnvironment.QA]: {
    apiKey: 'AIzaSyDREO_n3OSrtOjDdEf6LUDUDv52qZacJxE',
    authDomain: 'bites-staging-a0dbb.firebaseapp.com',
    projectId: 'bites-staging-a0dbb',
    storageBucket: 'bites-staging-a0dbb.appspot.com',
    messagingSenderId: '872512406703',
    appId: '1:872512406703:web:998241b88b1daa4eb8405a',
    measurementId: 'G-P7N94SM1KS',
  },
  [EEnvironment.PROD]: {
    apiKey: 'AIzaSyAHyvpj4VVoOGRA7BG2yWuqxtM3uAuKh3k',
    authDomain: 'bites-f8ec3.firebaseapp.com',
    databaseURL: 'https://bites-f8ec3.firebaseio.com',
    projectId: 'bites-f8ec3',
    storageBucket: 'bites-f8ec3.appspot.com',
    messagingSenderId: '885796386880',
    appId: '1:885796386880:web:1d30785c5a284e377707a5',
    measurementId: 'G-9YKSHB8G0L',
  },
};

const app = firebase.getApps().length ? firebase.getApp() : firebase.initializeApp(FIREBASE_CONFIGS[ENV]);

export default app;
