import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import withRetry from '../../utils/withRetry';
import {
  addToTimeline,
  createExportedVideo,
  playVideo,
  setCanvasItems,
  setCurrentSeconds,
  setExportedVideo,
  setExportedVideoError,
  setIsPlaying,
  setVideo,
  updateCanvas,
  updateVideoConfig,
  setGhostTimelineItemMeta,
  timelineGhostItemHoverTimelineLayer,
  setGhostTimelineLayerPrevState,
  removeTimelineItem,
  setIsSplitMode,
  splitTimelineItem,
  resetGhostTimelineLayerPrevState,
  pasteTimelineItem,
  addUndo,
  undo,
  redo,
  trimTimelineItemStart,
  trimTimelineItemEnd,
  updateTimelineItem,
  updateTimelineItemLayer,
  refreshCanvas,
  updateTimelineMeta,
  setGeneratedSubtitlesEnabledState,
  updateVideoDuration,
  loadVideo,
  saveVideo,
  setIsVideoSaving,
  setVideoSavingError,
  startNewVideo,
  setVideoLoadingError,
  updateTimelineItemWithCloudAssetDuration,
  clearStateForTimelineItem,
  updateTimelineItemInItemsMap,
  removeEmptyTimelineLayers,
  updateAcceptTypesForTimelineLayersInConfig,
  setAcceptTypesByTimelineLayerId,
  loadExistingVideos,
  applyExistingVideosDtResult,
  loadExistingVideosNextPage,
  setExistingVideosError,
  setVideoLoaded,
  cloneExistingVideo,
} from './videoEditor.slice';
import { log, logError } from '../appActivity/appActivity.slice';
import { activeOrganizationSelector, authSelector } from '../auth/auth.selectors';
import {
  createExportedVideoService,
  createVideoService,
  getVideoService,
  searchVideosService,
  updateVideoService,
} from '../api/bites-api/calls/videoEditor.calls';
import { AxiosResponse } from 'axios';
import {
  IAddToTimeline,
  ICreateExportedVideoPayload,
  IExportedVideo,
  ITimelineItemsPositionsMap,
  ITimelineItem,
  IVideo,
  IVideoConfig,
  IUpdateCanvas,
  ITimelineLayer,
  TTimelineItemsMap,
  ITimelineItemMeta,
  INextItems,
  IRemoveTimelineItem,
  IGhostTimelineItemMeta,
  IGetTimelineItemsEndingBetween,
  ITimelineGhostItemHoverTimelineLayer,
  IGetTimelineItemsBetweenSaga,
  IGetTimelineItemsAtSecondsSaga,
  ISetGhostTimelineLayerPrevState,
  ISplitTimelineItem,
  IRefreshCanvas,
  ITrimTimelineItemStart,
  ITrimTimelineItemEnd,
  ILayersDisplayDataLayer,
  IUpdateTimelineItemLayer,
  IGetIsOverlapping,
  ILoadVideoPayload,
  ISaveVideo,
  TSearchVideo,
  ICloneExistingVideoPayload,
} from './videoEditor.types';
import { PayloadAction } from '@reduxjs/toolkit';
import { IOrganization } from '../../types/organization';
import { IUser } from '../auth/auth.types';
import {
  acceptTypesByTimelineLayerIdSelector,
  copiedTimelineItemSelector,
  currentSecondsSeletor,
  existingVideosPageSelector,
  ghostTimelineItemMetaSelector,
  ghostTimelineLayerPrevStateSelector,
  isPlayingSeletor,
  processingTimelineItemSelector,
  recordingTimelineItemIdSelector,
  timelineItemSeletor,
  trimTimelineItemMetaSelector,
  videoConfigSeletor,
  videoSelector,
} from './videoEditor.selectors';
import { v4 as uuid } from 'uuid';
import {
  cloneCloudAssetCacheForTimelineItemSaga,
  getCloudAssetIdsFromTimelineLayers,
  loadVideoAssetsSaga,
} from '../cloudAssets/cloudAssets.saga';
import { POSITIONS_MAP_STEP, retryFunctions, timelineLayerAcceptByType, videoEditorData } from './videoEditor.data';
import { cloudAssetSelector } from '../cloudAssets/cloudAssets.selector';
import { ICloudAsset } from '../cloudAssets/cloudAssets.types';
import { cloneDeep } from 'lodash';
import { loadFont } from '../../screens/videoEditor/RightSidebar/loadFont';
import store from '..';
import { getTimelineLayerVisualType } from '../../screens/videoEditor/utils/getTimelineLayerVisualType';
import { IDataTableResult } from '../../types/common';

const DEFAULT_TIMELINE_ITEM_DURATION = 8;

function* loadExistingVideosSaga() {
  const processId = uuid();

  try {
    const org: IOrganization = yield select(activeOrganizationSelector);
    const page = yield select(existingVideosPageSelector);

    yield put(
      log({
        event: 'loadExistingVideosSaga: start',
        processId,
      }),
    );

    const { data: existingVideosDtResult }: AxiosResponse<IDataTableResult<{ video: TSearchVideo }, undefined>> =
      yield withRetry(
        () =>
          searchVideosService({
            filters: {
              orgId: org.id,
              isDraft: true,
            },
            page,
            sort: {
              updateDate: -1,
            },
          }),
        {
          errorContext: {
            processId,
            data: {
              action: 'createVideoSaga',
            },
          },
        },
      );

    yield put(applyExistingVideosDtResult(existingVideosDtResult));
  } catch (error: any) {
    yield put(
      logError({
        event: 'loadExistingVideosSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
          errorResponse: error?.response,
        },
      }),
    );

    yield put(setExistingVideosError(error));
  }
}

export function* createVideoSaga() {
  const processId = uuid();

  try {
    const org: IOrganization = yield select(activeOrganizationSelector);
    const user: IUser = yield select(authSelector);

    yield put(
      log({
        event: 'createVideoSaga: start',
        processId,
      }),
    );

    const video = yield select(videoSelector);

    const cloudAssetIdsMap: Record<string, ITimelineItem[]> = yield getCloudAssetIdsFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });
    const assetIds = Object.keys(cloudAssetIdsMap);

    const itemsMap: Record<string, ITimelineItem> = yield getItemsMapFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });

    const {
      data: { video: newVideo },
    }: AxiosResponse<{ video: IVideo }> = yield withRetry(
      () =>
        createVideoService({
          video: {
            orgId: org.id,
            creatorId: user!.id,
            config: {
              ...video.config,
              itemsMap,
            },
            assetIds,
            usedInBiteIds: [],
            isDraft: true,
          },
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'createVideoSaga',
          },
        },
      },
    );

    yield put(
      updateAcceptTypesForTimelineLayersInConfig({
        config: newVideo.config,
      }),
    );
    yield put(setVideo(newVideo));
    yield put(setVideoLoaded());
    yield put(addUndo({}));

    yield put(
      log({
        event: 'createVideoSaga: done',
        processId,
      }),
    );
  } catch (error: any) {
    yield put(
      logError({
        event: 'createVideoSaga: error',
        processId,
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    yield put(setVideoLoadingError(error));
  }
}

export function* saveVideoSaga(props?: Pick<PayloadAction<ISaveVideo>, 'payload'>) {
  const processId = props?.payload?.processId || uuid();
  const delayTs = props?.payload?.delay ?? 3000;

  if (delayTs) {
    yield delay(delayTs);
  }

  yield put(
    setIsVideoSaving({
      isSaving: props?.payload?.isManual,
      isAutoSaving: !props?.payload?.isManual,
    }),
  );

  try {
    yield put(
      log({
        event: 'saveVideoSaga: start',
        processId,
      }),
    );

    const video: IVideo = yield select(videoSelector);

    const cloudAssetIdsMap: Record<string, ITimelineItem[]> = yield getCloudAssetIdsFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });
    const assetIds = Object.keys(cloudAssetIdsMap);

    const itemsMap: Record<string, ITimelineItem> = yield getItemsMapFromTimelineLayers({
      timelineLayers: video.config.timelineLayers,
    });

    yield withRetry(
      () =>
        updateVideoService({
          filters: {
            id: video.id,
          },
          update: {
            config: {
              ...video.config,
              itemsMap,
            },
            assetIds,
            usedInBiteIds: video.usedInBiteIds,
            isDraft: !!video.isDraft,
          },
        }),
      {
        errorContext: {
          data: {
            action: 'saveVideoSaga',
          },
        },
      },
    );

    yield put(
      setIsVideoSaving({
        isSaving: false,
        isAutoSaving: false,
      }),
    );

    yield put(
      log({
        event: 'saveVideoSaga: done',
        processId,
      }),
    );

    if (props?.payload?.onDone) {
      props.payload.onDone();
    }
  } catch (error) {
    yield put(
      logError({
        event: 'saveVideoSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );

    yield put(setVideoSavingError(error));

    if (props?.payload?.onError) {
      props.payload.onError();
    }
  }
}

function* createExportedVideoSaga({ payload }: PayloadAction<ICreateExportedVideoPayload>) {
  try {
    // const org = yield select(activeOrganizationSelector);

    const { exportedVideo } = payload;

    yield put(
      log({
        event: 'createExportedVideoSaga: start',
      }),
    );

    const {
      data: { exportedVideo: newExportedVideo },
    }: AxiosResponse<{ exportedVideo: IExportedVideo }> = yield withRetry(
      () =>
        createExportedVideoService({
          // orgId: org.id,
          exportedVideo,
        }),
      {
        errorContext: {
          data: {
            action: 'createExportedVideoSaga',
          },
        },
      },
    );

    yield put(setExportedVideo(newExportedVideo));

    yield put(
      log({
        event: 'createExportedVideoSaga: done',
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'createExportedVideoSaga: error',
        data: {
          error,
        },
      }),
    );

    yield put(setExportedVideoError(error));
  }
}

function* removeEmptyTimelineLayersSaga() {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const newTimelineLayers = videoConfig.timelineLayers.filter((_layer) => _layer.timeline.length);

  if (newTimelineLayers.length === 0) {
    return;
  }

  if (newTimelineLayers.length === 0) {
    const newLayerId = uuid();
    newTimelineLayers.push({
      id: newLayerId,
      timeline: [],
    });
  }

  const updateConfigData: IVideoConfig = {
    ...videoConfig,
    timelineLayers: newTimelineLayers,
  };

  yield put(updateVideoConfig(updateConfigData));
}

// addToTimelineSaga places the elements at the start position if
// it is passed without checking the intersection with other elements
// at this specific position.

// I means that there should be no other elements at the start position -
// it should be the logic of the caller to take care of that.

// addToTimelineSaga does check if the added element overlaps with other elements
// starting after the start position and shifts them accordingly.

function* addToTimelineSaga({ payload }: Pick<PayloadAction<IAddToTimeline>, 'payload'>) {
  const {
    timelineItem: timelineItemProp,
    start,
    duration,
    layerId,
    newTimelineId,
    beforeLayerId,
    afterLayerId,
    removeExistingItem,
    onAddToTimeline,
  } = payload;
  const processId = uuid();

  let video = yield select(videoSelector);

  const videoId = video.id;

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const { timelineLayers, itemsMap, duration: videoDuration } = videoConfig;

  let layer: ITimelineLayer | null = null;

  const acceptTypesByTimelineLayerId = yield select(acceptTypesByTimelineLayerIdSelector);

  if (layerId) {
    layer = timelineLayers.find(({ id }) => id === layerId) || timelineLayers[0] || null;

    const layerAcceptTypes = acceptTypesByTimelineLayerId[layerId];
    if (!layerAcceptTypes.includes(timelineItemProp.type)) {
      layer = null;
    }
  }

  // const lastTimelineItemId = layer ? layer.timeline[layer.timeline.length - 1] : null;
  // const timelineDuration = lastTimelineItemId ? itemsMap[lastTimelineItemId]?.end : 0;

  const currentSeconds = yield select(currentSecondsSeletor);

  const itemStart = start !== undefined ? start : currentSeconds;
  let itemDuration = DEFAULT_TIMELINE_ITEM_DURATION;

  if (duration) {
    itemDuration = duration;
  } else if (timelineItemProp.end !== undefined) {
    itemDuration = timelineItemProp.end - timelineItemProp.start;
  } else {
    const cloudAsset = timelineItemProp?.cloudAssetId
      ? yield select(cloudAssetSelector(timelineItemProp?.cloudAssetId))
      : null;

    if (cloudAsset?.fileType === 'video' || cloudAsset?.fileType === 'audio') {
      itemDuration = cloudAsset.fileMeta.duration;
    }
  }

  const itemEnd = itemStart + itemDuration;

  if (!layer && !beforeLayerId && !afterLayerId) {
    for (let i = timelineLayers.length - 1; i >= 0; i--) {
      const _layer = timelineLayers[i];

      const layerAcceptTypes = acceptTypesByTimelineLayerId[_layer.id];
      const isTypeMatch = layerAcceptTypes.includes(timelineItemProp.type);

      if (!isTypeMatch) {
        continue;
      }

      const isOverlapping = yield getIsOverlappingSaga({
        timelineLayerId: _layer.id,
        start: itemStart,
        end: itemEnd,
      });

      if (!isOverlapping) {
        layer = _layer;
      }

      // try to add only to the most top layer of the matching type
      break;
    }
  }

  const isNewLayer = !layer;

  if (isNewLayer) {
    layer = {
      id: newTimelineId || uuid(),
      timeline: [],
      // type: timelineItemProp.type === 'audio' ? 'audio' : 'visual',
    };
  }

  const { timeline } = layer;

  const timelineItem: ITimelineItem = {
    ...timelineItemProp,
    id: timelineItemProp.id || uuid(),
    start: itemStart,
    end: itemEnd,
  };

  const updatedItemsMap: TTimelineItemsMap = {
    ...itemsMap,
    [timelineItem.id]: timelineItem,
  };

  let beforeItemIndex = -1;
  let existingItemIndex = -1;

  timeline.some((itemId, index) => {
    const compareItem = itemsMap[itemId];
    if (compareItem.id !== timelineItem.id && compareItem.start >= itemStart && beforeItemIndex === -1) {
      beforeItemIndex = index;
    }
    if (removeExistingItem && itemId === timelineItem.id) {
      existingItemIndex = index;
    }
    return beforeItemIndex > -1 && (!removeExistingItem || existingItemIndex > -1);
  });

  let itemIdsAfter = [];

  let updatedTimeline = null;

  if (existingItemIndex > -1) {
    if (existingItemIndex < beforeItemIndex) {
      itemIdsAfter = timeline.slice(beforeItemIndex);

      updatedTimeline = [
        ...timeline.slice(0, existingItemIndex),
        ...timeline.slice(existingItemIndex + 1, beforeItemIndex),
        timelineItem.id,
        ...itemIdsAfter,
      ];
    }

    if (existingItemIndex === beforeItemIndex - 1) {
      // same position
      updatedTimeline = timeline;
    }

    if (existingItemIndex > beforeItemIndex && beforeItemIndex === -1) {
      updatedTimeline = [
        ...timeline.slice(0, existingItemIndex),
        ...timeline.slice(existingItemIndex + 1),
        timelineItem.id,
      ];
    }

    if (existingItemIndex > beforeItemIndex && beforeItemIndex > -1) {
      itemIdsAfter = [...timeline.slice(beforeItemIndex, existingItemIndex), ...timeline.slice(existingItemIndex + 1)];
      updatedTimeline = [...timeline.slice(0, beforeItemIndex), timelineItem.id, ...itemIdsAfter];
    }
  } else {
    if (beforeItemIndex === -1) {
      updatedTimeline = [...timeline, timelineItem.id];
    }

    if (beforeItemIndex > -1) {
      itemIdsAfter = timeline.slice(beforeItemIndex);
      updatedTimeline = [...timeline.slice(0, beforeItemIndex), timelineItem.id, ...itemIdsAfter];
    }
  }

  // console.log('itemIdsAfter', itemIdsAfter);

  let withTimelineItemsShift = false;
  let prevItemEnd = timelineItem.end;
  for (let itemId of itemIdsAfter) {
    // console.log('\n\nprevItemEnd', prevItemEnd);

    const item = itemsMap[itemId];

    if (item.start < itemStart) {
      // this should not happen
      yield put(
        logError({
          event: 'addToTimelineSaga: items are not sorted',
          processId,
          data: {
            videoId,
            timeline,
            timelineLayers,
          },
        }),
      );
    }

    if (prevItemEnd <= item.start) {
      // no overlap, no need to shift next elements
      // console.log('no overlap, no need to shift next elements', item.id, item.start, prevItemEnd);
      break;
    }

    const shift = prevItemEnd - item.start;
    const newStart = item.start + shift;
    const newEnd = item.end + shift;

    // console.log('item.start', item.start);
    // console.log('shift', shift);
    // console.log('newStart', newStart);
    // console.log('newEnd', newEnd);

    prevItemEnd = newEnd;

    updatedItemsMap[item.id] = {
      ...item,
      start: newStart,
      end: newEnd,
    };

    withTimelineItemsShift = true;
  }

  const newVideoDuration = Math.max(videoDuration, timelineItem.end);

  const updatedLayer: ITimelineLayer = {
    ...layer,
    timeline: updatedTimeline,
  };
  yield put(
    setAcceptTypesByTimelineLayerId({
      timelineLayerId: updatedLayer.id,
      acceptTypes: timelineLayerAcceptByType[timelineItem.type],
    }),
  );

  const beforeLayerIndex = beforeLayerId ? timelineLayers.findIndex(({ id }) => id === beforeLayerId) : -1;
  const afterLayerIndex = afterLayerId ? timelineLayers.findIndex(({ id }) => id === afterLayerId) : -1;

  if ((beforeLayerId && beforeLayerIndex === -1) || (afterLayerId && afterLayerIndex === -1)) {
    // this should not happen
    yield put(
      logError({
        event: 'addToTimelineSaga: before-after layer not found',
        processId,
        data: {
          videoId,
          beforeLayerId,
          afterLayerId,
          beforeLayerIndex,
          afterLayerIndex,
          timelineLayers,
        },
      }),
    );
  }

  let updatedTimelineLayers: ITimelineLayer[] | null = null;

  if (!updatedTimelineLayers && isNewLayer && beforeLayerIndex > -1) {
    updatedTimelineLayers = [
      ...timelineLayers.slice(0, beforeLayerIndex),
      updatedLayer,
      ...timelineLayers.slice(beforeLayerIndex),
    ];
  }

  if (!updatedTimelineLayers && isNewLayer && afterLayerIndex > -1) {
    updatedTimelineLayers = [
      ...timelineLayers.slice(0, afterLayerIndex + 1),
      updatedLayer,
      ...timelineLayers.slice(afterLayerIndex + 1),
    ];
  }

  if (!updatedTimelineLayers && isNewLayer) {
    const visualType = getTimelineLayerVisualType([timelineItem.type]);

    // find first visual type timeline layer index
    let visualLayerIndex = [...timelineLayers].reverse().findIndex(({ id }) => {
      const _layerAcceptTypes = acceptTypesByTimelineLayerId[id];
      const _visualType = getTimelineLayerVisualType(_layerAcceptTypes);
      return _visualType === visualType;
    });

    if (visualLayerIndex > -1) {
      visualLayerIndex = timelineLayers.length - visualLayerIndex - 1;
      updatedTimelineLayers = [
        ...timelineLayers.slice(0, visualLayerIndex + 1),
        updatedLayer,
        ...timelineLayers.slice(visualLayerIndex + 1),
      ];
    } else {
      if (visualType === 'audio') {
        updatedTimelineLayers = [updatedLayer, ...timelineLayers];
      } else {
        updatedTimelineLayers = [...timelineLayers, updatedLayer];
      }
    }
  }

  if (!updatedTimelineLayers) {
    updatedTimelineLayers = timelineLayers.map((_layer) => (_layer.id === updatedLayer.id ? updatedLayer : _layer));
  }

  // TODO: keep at least one that we move?
  // const visualTimelineLayers = updatedTimelineLayers.filter(({ type }) => type === 'visual');
  // if (timelineItemProp.type === 'audio' || visualTimelineLayers.length > 1) {
  // updatedTimelineLayers = updatedTimelineLayers.filter((_layer) => _layer.timeline.length);
  // }

  const updateConfigData: Partial<IVideoConfig> = {
    timelineLayers: updatedTimelineLayers,
    itemsMap: updatedItemsMap,
    duration: newVideoDuration,
  };

  yield put(updateVideoConfig(updateConfigData));
  yield updateTimelineMetaSaga();

  if (withTimelineItemsShift) {
    yield put(updateVideoDuration());
  }

  // const updateTimelineItemLayerResult = yield updateTimelineItemLayerSaga({
  //   payload: {
  //     timelineItemId: timelineItem.id,
  //     timelineLayerId: updatedLayer.id,
  //   },
  // });

  // timelineItem = updateTimelineItemLayerResult?.timelineItem || timelineItem;
  // const timelineLayer = updateTimelineItemLayerResult?.timelineLayer || updatedLayer;

  if (typeof onAddToTimeline === 'function') {
    onAddToTimeline({
      timelineItem,
      timelineLayer: updatedLayer,
      withTimelineItemsShift,
    });
  }

  return {
    timelineItem,
    withTimelineItemsShift,
    timelineLayer: updatedLayer,
  };
}

function* cloneGroupTimelineItemSaga({
  payload,
}: Pick<PayloadAction<{ timelineItemId: string; withUpdateVideoConfig?: boolean }>, 'payload'>) {
  const { timelineItemId, withUpdateVideoConfig = true } = payload;
  const timelineItem = yield select(timelineItemSeletor(timelineItemId));

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  const { itemsMap } = videoConfig;

  const newItemsMap: TTimelineItemsMap = { ...itemsMap };

  const copiedTimelineItem: ITimelineItem = {
    ...cloneDeep(timelineItem),
    id: uuid(),
  };

  // clone internal items
  for (let internalLayer of copiedTimelineItem.timelineLayers) {
    internalLayer.id = uuid();
    const newTimeline = [];

    for (let internalItemId of internalLayer.timeline) {
      const internalItem = itemsMap[internalItemId];

      if (internalItem.type === 'group') {
        const { timelineItem: copiedInternalItem }: { timelineItem: ITimelineItem } = yield cloneGroupTimelineItemSaga({
          payload: {
            timelineItemId: internalItemId,
            withUpdateVideoConfig: false,
          },
        });

        newTimeline.push(copiedInternalItem.id);
        continue;
      }

      const copiedInternalItem: ITimelineItem = {
        ...cloneDeep(internalItem),
        id: uuid(),
      };

      newItemsMap[copiedInternalItem.id] = copiedInternalItem;
      newTimeline.push(copiedInternalItem.id);
    }

    internalLayer.timeline = newTimeline;
  }

  newItemsMap[copiedTimelineItem.id] = copiedTimelineItem;

  if (withUpdateVideoConfig) {
    yield put(
      updateVideoConfig({
        itemsMap: newItemsMap,
      }),
    );
  }

  return {
    timelineItem: copiedTimelineItem,
    itemsMap: newItemsMap,
  };
}

function* getPositionsMapForTimelineLayers(
  action: Pick<
    PayloadAction<{
      rootPositionsMap?: ITimelineItemsPositionsMap;
      positionsMap: ITimelineItemsPositionsMap;
      rootStartPositionsMap?: ITimelineItemsPositionsMap;
      rootEndPositionsMap?: ITimelineItemsPositionsMap;
      timelineLayers: ITimelineLayer[];
      videoConfig: IVideoConfig;
      startSecondsOffset?: number;
      parentGroupsPath: ITimelineItemMeta[];
      minStart?: number; // relative to root timeline start
      maxEnd?: number; // relative to root timeline start
    }>,
    'payload'
  >,
) {
  const {
    rootPositionsMap,
    positionsMap,
    rootStartPositionsMap,
    rootEndPositionsMap,
    timelineLayers,
    videoConfig,
    startSecondsOffset = 0,
    parentGroupsPath,
    minStart,
    maxEnd,
  } = action.payload;

  for (let timelineLayerIndex = 0; timelineLayerIndex < timelineLayers.length; timelineLayerIndex++) {
    const layer = timelineLayers[timelineLayerIndex];
    const { timeline } = layer;

    for (let timelineItemIndex = 0; timelineItemIndex < timeline.length; timelineItemIndex++) {
      const timelineItemId = timeline[timelineItemIndex];
      const timelineItem = videoConfig.itemsMap[timelineItemId];

      const timelineItemMeta: ITimelineItemMeta = {
        timelineLayerId: layer.id,
        timelineLayerIndex,
        timelineItemId: timelineItem.id,
        timelineItemIndex,
        startSecondsOffset,
        parentGroupsPath,
        minStart,
        maxEnd,
      };

      // root map
      if (rootPositionsMap) {
        updatePositionsMapForTimelineItem({
          startSecondsOffset,
          timelineItem,
          minStart,
          maxEnd,
          videoConfig,
          positionsMap: rootPositionsMap,
          startPositionsMap: rootStartPositionsMap,
          endPositionsMap: rootEndPositionsMap,
          timelineItemMeta,
        });
      }

      if (timelineItem.type === 'group') {
        yield getPositionsMapForTimelineLayers({
          payload: {
            positionsMap,
            timelineLayers: timelineItem.timelineLayers,
            videoConfig,
            startSecondsOffset: startSecondsOffset + timelineItem.start - (timelineItem.trimStart || 0),
            parentGroupsPath: [...parentGroupsPath, timelineItemMeta],
            minStart: getMathMax(minStart, startSecondsOffset + timelineItem.start),
            maxEnd: getMathMin(maxEnd, startSecondsOffset + timelineItem.end),
          },
        });

        continue;
      }

      // deep map
      updatePositionsMapForTimelineItem({
        startSecondsOffset,
        timelineItem,
        minStart,
        maxEnd,
        videoConfig,
        positionsMap,
        timelineItemMeta,
      });
    }
  }
}

function updatePositionsMapForTimelineItem({
  startSecondsOffset,
  timelineItem,
  minStart,
  maxEnd,
  videoConfig,
  positionsMap,
  startPositionsMap,
  endPositionsMap,
  timelineItemMeta,
}: {
  startSecondsOffset: number;
  timelineItem: ITimelineItem;
  minStart: number;
  maxEnd: number;
  videoConfig: IVideoConfig;
  positionsMap: ITimelineItemsPositionsMap;
  startPositionsMap?: ITimelineItemsPositionsMap;
  endPositionsMap?: ITimelineItemsPositionsMap;
  timelineItemMeta: ITimelineItemMeta;
}) {
  const start = Math.floor(getMathMax(startSecondsOffset + timelineItem.start, minStart, 0));
  const end = Math.ceil(getMathMin(startSecondsOffset + timelineItem.end, maxEnd, videoConfig.duration));

  for (let startSecond = start; startSecond < end; startSecond++) {
    const endSecond = startSecond + POSITIONS_MAP_STEP;
    const key = `${startSecond}-${endSecond}`;

    positionsMap[key] = positionsMap[key] || [];
    positionsMap[key].push(timelineItemMeta);
  }

  if (startPositionsMap) {
    const startToSecond = start + POSITIONS_MAP_STEP;
    const startKey = `${start}-${startToSecond}`;

    startPositionsMap[startKey] = startPositionsMap[startKey] || [];
    startPositionsMap[startKey].push(timelineItemMeta);
  }

  if (endPositionsMap) {
    const endToSecond = end - POSITIONS_MAP_STEP;
    const endKey = `${endToSecond}-${end}`;

    endPositionsMap[endKey] = endPositionsMap[endKey] || [];
    endPositionsMap[endKey].push(timelineItemMeta);
  }
}

function* updateTimelineMetaSaga(action?: Pick<PayloadAction<{ videoConfig: IVideoConfig }>, 'payload'>) {
  const videoConfigStore: IVideoConfig = yield select(videoConfigSeletor);
  const videoConfig = action?.payload?.videoConfig || videoConfigStore;
  const { timelineLayers } = videoConfig;

  const rootPositionsMap: ITimelineItemsPositionsMap = {};
  const positionsMap: ITimelineItemsPositionsMap = {};
  const rootStartPositionsMap: ITimelineItemsPositionsMap = {};
  const rootEndPositionsMap: ITimelineItemsPositionsMap = {};

  yield getPositionsMapForTimelineLayers({
    payload: {
      rootPositionsMap,
      positionsMap,
      rootStartPositionsMap,
      rootEndPositionsMap,
      timelineLayers,
      videoConfig,
      startSecondsOffset: 0,
      parentGroupsPath: [],
    },
  });

  videoEditorData.rootTimelineItemsPositionsMap = rootPositionsMap;
  videoEditorData.timelineItemsPositionsMap = positionsMap;
  videoEditorData.rootStartTimelineItemsPositionsMap = rootStartPositionsMap;
  videoEditorData.rootEndTimelineItemsPositionsMap = rootEndPositionsMap;
}

function* updateCanvasSaga(props?: Pick<PayloadAction<IUpdateCanvas>, 'payload'>) {
  const { payload = {} } = props || {};
  const { seconds } = payload;

  const currentSeconds = yield select(currentSecondsSeletor);

  // deep
  const items = yield getCanvasItemsMetaSaga({
    seconds: seconds ?? currentSeconds,
    precise: true,
  });

  // console.log('updateCanvasSaga', seconds);
  // console.log('updateCanvasSaga items', items);

  yield put(setCanvasItems(items));
}
interface IGetNextBySecondsForTimelineLayersResult {
  seconds: number;
  items: ITimelineItemMeta[];
  item: ITimelineItemMeta | null;
}
function* getNextBySecondsForTimelineLayers({
  payload,
}: Pick<
  PayloadAction<{
    timelineLayers: ITimelineLayer[];
    nextTimelineItemMeta: ITimelineItemMeta;
    startSecondsOffset: number; // relative to root timeline start
    parentGroupsPath: ITimelineItemMeta[];
    seconds: number; // relative to root timeline start
    targetSeconds: number; // relative to root timeline start
    minStart?: number; // relative to root timeline start
    maxEnd?: number; // relative to root timeline start
  }>,
  'payload'
>) {
  const { timelineLayers, startSecondsOffset, parentGroupsPath, targetSeconds, minStart, maxEnd } = payload;

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  let nextTimelineItemMeta: ITimelineItemMeta | null = payload.nextTimelineItemMeta;
  let seconds: number = payload.seconds;

  const nextItems = [];
  for (let timelineLayerIndex = 0; timelineLayerIndex < timelineLayers.length; timelineLayerIndex++) {
    const { timeline, ...layer } = timelineLayers[timelineLayerIndex];

    let nextTimelineItemMetaForLayer: ITimelineItemMeta | null = null;

    for (let timelineItemIndex = 0; timelineItemIndex < timeline.length; timelineItemIndex++) {
      const timelineItemId = timeline[timelineItemIndex];

      const timelineItem = videoConfig.itemsMap[timelineItemId];

      let start = timelineItem.start + startSecondsOffset;
      let end = timelineItem.end + startSecondsOffset;

      let isPlaying = start <= targetSeconds && end > targetSeconds;
      const isNext = start > targetSeconds;

      if (!isPlaying && !isNext) {
        continue;
      }

      nextTimelineItemMetaForLayer = {
        timelineLayerId: layer.id,
        timelineLayerIndex,
        timelineItemId,
        timelineItemIndex,
        startSecondsOffset,
        parentGroupsPath,
        minStart,
        maxEnd,
      };

      if (timelineItem.type === 'group') {
        const groupStartSecondsOffset = startSecondsOffset + timelineItem.start;

        const groupNext: IGetNextBySecondsForTimelineLayersResult = yield getNextBySecondsForTimelineLayers({
          payload: {
            timelineLayers: timelineItem.timelineLayers,
            nextTimelineItemMeta,
            seconds,
            targetSeconds,
            startSecondsOffset: groupStartSecondsOffset,
            parentGroupsPath: [...parentGroupsPath, nextTimelineItemMetaForLayer],
            minStart: getMathMax(minStart, groupStartSecondsOffset + timelineItem.start),
            maxEnd: getMathMin(maxEnd, groupStartSecondsOffset + timelineItem.end),
          },
        });

        nextTimelineItemMetaForLayer = groupNext.item;

        const groupNextItem = videoConfig.itemsMap[groupNext.item.timelineItemId];
        start = groupNextItem.start + groupStartSecondsOffset;
        end = groupNextItem.end + groupStartSecondsOffset;

        isPlaying = start <= targetSeconds && end > targetSeconds;
      }

      const nextSeconds = isPlaying ? end : start;
      if (seconds > nextSeconds) {
        seconds = nextSeconds;
        nextTimelineItemMeta = nextTimelineItemMetaForLayer;
      }

      break;
    }

    nextItems.push(nextTimelineItemMetaForLayer);
  }

  return { seconds, items: nextItems, item: nextTimelineItemMeta };
}

function* getNextKeyframeBySecondsSaga(targetSeconds: number) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const { items, seconds }: IGetNextBySecondsForTimelineLayersResult = yield getNextBySecondsForTimelineLayers({
    payload: {
      timelineLayers: videoConfig.timelineLayers,
      nextTimelineItemMeta: null,
      startSecondsOffset: 0,
      parentGroupsPath: [],
      seconds: videoConfig.duration,
      targetSeconds,
    },
  });

  const result: INextItems = {
    seconds,
    items,
  };

  return result;
}

function* getNextKeyframeByNextItemsSaga(next: INextItems) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  let seconds: number = videoConfig.duration;

  let items = [];
  for (let i = 0; i < next.items.length; i++) {
    let timelineItemMeta = next.items[i];

    if (!timelineItemMeta) {
      items.push(null);
      continue;
    }

    let timelineItem = videoConfig.itemsMap[timelineItemMeta.timelineItemId];

    const start = timelineItem.start + timelineItemMeta.startSecondsOffset;
    const end = timelineItem.end + timelineItemMeta.startSecondsOffset;

    const isPlaying = start <= next.seconds && end > next.seconds;
    const isNext = start > next.seconds;

    if (isPlaying || isNext) {
      const nextSeconds = isPlaying
        ? timelineItem.end + timelineItemMeta.startSecondsOffset
        : timelineItem.start + timelineItemMeta.startSecondsOffset;
      seconds = Math.min(seconds, nextSeconds);

      items.push(timelineItemMeta);
      continue;
    }

    let nextTimelineItemIndex = null;
    let nextTimelineItemId = null;

    const path = [...timelineItemMeta.parentGroupsPath, timelineItemMeta];

    while (!nextTimelineItemId) {
      timelineItemMeta = path.pop();
      timelineItem = videoConfig.itemsMap[timelineItemMeta.timelineItemId];

      const parentTimelineItemMeta = path[path.length - 1];
      const parentTimelineItem = parentTimelineItemMeta
        ? videoConfig.itemsMap[parentTimelineItemMeta.timelineItemId]
        : null;

      const layerContaier = parentTimelineItem ? parentTimelineItem : videoConfig;
      const timelineLayer = layerContaier.timelineLayers[timelineItemMeta.timelineLayerIndex];
      const timeline = timelineLayer.timeline;

      nextTimelineItemIndex = timelineItemMeta.timelineItemIndex + 1;
      nextTimelineItemId = timeline[nextTimelineItemIndex];

      if (nextTimelineItemId || path.length === 0) {
        break;
      }
    }

    if (!nextTimelineItemId) {
      items.push(null);
      continue;
    }

    const nextTimelineItem = videoConfig.itemsMap[nextTimelineItemId];

    let nextItem: ITimelineItemMeta = {
      timelineLayerId: timelineItemMeta.timelineLayerId,
      timelineLayerIndex: timelineItemMeta.timelineLayerIndex,
      timelineItemId: nextTimelineItemId,
      timelineItemIndex: nextTimelineItemIndex,
      startSecondsOffset: timelineItemMeta.startSecondsOffset,
      parentGroupsPath: path,
      minStart: timelineItemMeta.minStart,
      maxEnd: timelineItemMeta.maxEnd,
    };

    if (nextTimelineItem.type === 'group') {
      const currentSeconds = yield select(currentSecondsSeletor);
      const groupNext: IGetNextBySecondsForTimelineLayersResult = yield getNextBySecondsForTimelineLayers({
        payload: {
          timelineLayers: nextTimelineItem.timelineLayers,
          nextTimelineItemMeta: null,
          startSecondsOffset: timelineItemMeta.startSecondsOffset,
          parentGroupsPath: path,
          seconds: videoConfig.duration,
          targetSeconds: currentSeconds,
        },
      });

      nextItem = groupNext.item;
    }

    seconds = Math.min(seconds, nextTimelineItem.start + timelineItemMeta.startSecondsOffset);

    items.push(nextItem);
  }

  const result: INextItems = {
    seconds,
    items,
  };

  return result;
}

function* playVideoSaga() {
  yield put(setIsPlaying(true));
  const recordingTimelineItemId = yield select(recordingTimelineItemIdSelector);

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  let currentSeconds = yield select(currentSecondsSeletor);
  videoEditorData.startPlayingTs = Date.now();

  if (!recordingTimelineItemId && currentSeconds >= videoConfig.duration) {
    currentSeconds = 0;
    yield put(setCurrentSeconds(currentSeconds));
    yield updateCanvasSaga();
  }

  // yield fork(updateCurrentSecondsWhilePlayingSaga);

  let next: INextItems = yield getNextKeyframeBySecondsSaga(currentSeconds);
  // console.log('next 1', next);

  let isPlaying = true;

  while (true) {
    yield delay((next.seconds - currentSeconds) * 1000);

    isPlaying = yield select(isPlayingSeletor);
    if (!isPlaying) {
      return;
    }

    if (!next.items.some((item) => item)) {
      if (!recordingTimelineItemId) {
        yield put(setIsPlaying(false));
      }
      return;
    }

    currentSeconds = next.seconds;
    // console.log('playVideo -> updateCanvasSaga');

    yield updateCanvasSaga({
      payload: { seconds: currentSeconds },
    });

    next = yield getNextKeyframeByNextItemsSaga(next);
    // console.log('next 2', next);
  }
}

// function* updateCurrentSecondsWhilePlayingSaga() {
//   const startTs = Date.now();
//   const initialCurrentSeconds = yield select(currentSecondsSeletor);

//   while (true) {
//     // yield delay(1000 / 30);
//     yield delay(10);

//     const ts = Date.now();
//     const newCurrentSeconds = initialCurrentSeconds + (ts - startTs) / 1000;

//     yield put(setCurrentSeconds(newCurrentSeconds));

//     const isPlaying = yield select(isPlayingSeletor);
//     if (!isPlaying) {
//       break;
//     }
//   }
// }

function* loadFontsSaga({ payload }: Pick<PayloadAction<{ processId: string }>, 'payload'>) {
  const config: IVideoConfig = yield select(videoConfigSeletor);

  yield all(
    Object.values(config.itemsMap)
      .filter((item) => {
        return item.type === 'text' && item.fontFamily;
      })
      .map(async (item) => {
        try {
          await loadFont({
            label: item.fontFamily,
            weight: item.fontWeight ?? 400,
            italic: item.textItalic ?? false,
          });
        } catch (error) {
          store.dispatch(
            logError({
              event: 'loadFontsSaga: error',
              processId: payload.processId,
              data: {
                error,
                fontFamily: item.fontFamily,
              },
            }),
          );
        }
      }),
  );
}

function* loadVideoSaga({ payload }: Pick<PayloadAction<ILoadVideoPayload>, 'payload'>) {
  const processId = uuid();
  const { videoId } = payload;

  yield put(
    log({
      event: 'loadVideoSaga: start',
      processId,
    }),
  );

  try {
    const {
      data: { video },
    } = yield getVideoService({
      id: videoId,
    });

    yield put(
      log({
        event: 'loadVideoSaga: video loaded',
        processId,
      }),
    );

    yield put(
      updateAcceptTypesForTimelineLayersInConfig({
        config: video.config,
      }),
    );
    yield put(setVideo(video));

    yield all([
      loadVideoAssetsSaga({
        payload: {
          processId,
        },
      }),
      loadFontsSaga({
        payload: {
          processId,
        },
      }),
    ]);

    yield put(setVideoLoaded());

    yield refreshCanvasSaga({
      payload: { seconds: 0 },
    });

    yield put(addUndo({}));
    yield put(saveVideo({}));

    yield put(
      log({
        event: 'loadVideoSaga: done',
        processId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'loadVideoSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );
  }
}

function* cloneExistingVideoSaga({ payload }: Pick<PayloadAction<ICloneExistingVideoPayload>, 'payload'>) {
  const processId = uuid();
  const { videoId } = payload;

  yield put(
    log({
      event: 'cloneExistingVideoSaga: start',
      processId,
    }),
  );

  try {
    const {
      data: { video },
    } = yield getVideoService({
      id: videoId,
    });

    video.clonedFromVideoId = videoId;
    delete video.id;

    yield put(
      log({
        event: 'cloneExistingVideoSaga: video loaded',
        processId,
      }),
    );

    const {
      data: { video: newVideo },
    }: AxiosResponse<{ video: IVideo }> = yield withRetry(
      () =>
        createVideoService({
          video,
        }),
      {
        errorContext: {
          processId,
          data: {
            action: 'cloneExistingVideoSaga: clone',
          },
        },
      },
    );

    yield put(
      log({
        event: 'cloneExistingVideoSaga: video cloned',
        processId,
      }),
    );

    yield put(
      updateAcceptTypesForTimelineLayersInConfig({
        config: newVideo.config,
      }),
    );
    yield put(setVideo(newVideo));

    yield all([
      loadVideoAssetsSaga({
        payload: {
          processId,
        },
      }),
      loadFontsSaga({
        payload: {
          processId,
        },
      }),
    ]);

    yield put(setVideoLoaded());

    yield refreshCanvasSaga({
      payload: { seconds: 0 },
    });

    yield put(addUndo({}));
    yield put(saveVideo({}));

    yield put(
      log({
        event: 'cloneExistingVideoSaga: done',
        processId,
      }),
    );
  } catch (error) {
    yield put(
      logError({
        event: 'loadVideoSaga: error',
        processId,
        data: {
          error,
        },
      }),
    );
  }
}

function* removeTimelineItemSaga({ payload }: Pick<PayloadAction<IRemoveTimelineItem>, 'payload'>) {
  const { timelineItemId, fromTimeline } = payload;

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  const { timelineLayers } = videoConfig;

  const updateConfigData: Partial<IVideoConfig> = {};

  if (fromTimeline) {
    const layer = yield getTimelineLayerForTimelineItemSaga({ timelineItemId });

    if (!layer) {
      return;
    }

    const timelineItemIndex = layer.timeline.findIndex((itemId) => itemId === timelineItemId);

    if (timelineItemIndex === -1) {
      return;
    }

    const updatedTimeline = [
      ...layer.timeline.slice(0, timelineItemIndex),
      ...layer.timeline.slice(timelineItemIndex + 1),
    ];

    const updatedTimelineLayers = timelineLayers.map((_layer) => {
      if (_layer.id !== layer.id) {
        return _layer;
      }

      return {
        ..._layer,
        timeline: updatedTimeline,
      };
    });

    updateConfigData.timelineLayers = updatedTimelineLayers;

    // TODO: keep at least one that we move?
    // const visualTimelineLayers = updatedTimelineLayers.filter(({ type }) => type === 'visual');
    // if (layer.type === 'audio' || visualTimelineLayers.length > 1) {
    // updateConfigData.timelineLayers = updatedTimelineLayers.filter(({ timeline }) => timeline.length);
    // }
  }

  // if (fromAssetsMap) {
  // const updatedItemsMap = { ...itemsMap };
  // delete updatedItemsMap[timelineItemId];
  // updateConfigData.itemsMap = updatedItemsMap;

  // yield updateTimelineMetaSaga({
  //   payload: {
  //     videoConfig: {
  //       ...videoConfig,
  //       ...updateConfigData,
  //     },
  //   },
  // });

  //   const currentSeconds = yield select(currentSecondsSeletor);
  //   yield updateCanvasSaga({
  //     payload: { seconds: currentSeconds },
  //   });
  // }

  yield put(updateVideoConfig(updateConfigData));
}

function* getMinNoOverlapPosition({
  min,
  from,
  timelineLayerId,
  exceptIds,
}: {
  min: number;
  from: number;
  timelineLayerId: string;
  exceptIds: string[];
}) {
  let minStart = Math.max(0, min);
  let position = from;

  const exceptIdsMap = (exceptIds || []).reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {} as Record<string, true>);

  while (true) {
    // root
    const itemsMeta: ITimelineItemMeta[] = yield getCanvasItemsMetaSaga({
      seconds: position,
      root: true,
    });

    for (let i = itemsMeta.length - 1; i >= 0; i--) {
      const itemMeta = itemsMeta[i];

      if (itemMeta && itemMeta.timelineLayerId === timelineLayerId && !exceptIdsMap[itemMeta.timelineItemId]) {
        const item = yield select(timelineItemSeletor(itemMeta.timelineItemId));

        if (item.end + itemMeta.startSecondsOffset >= position && item.start + itemMeta.startSecondsOffset <= from) {
          return {
            item,
            position: item.end,
          };
        }
      }
    }

    if (position === minStart) {
      return { position };
    }

    position = Math.max(position - POSITIONS_MAP_STEP, minStart);
  }
}
function* getMaxNoOverlapPosition({
  max,
  from,
  timelineLayerId,
  exceptIds,
}: {
  max: number;
  from: number;
  timelineLayerId: string;
  exceptIds: string[];
}) {
  let maxEnd = max;
  let position = from;

  const exceptIdsMap = (exceptIds || []).reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {} as Record<string, true>);

  while (true) {
    // root
    const itemsMeta: ITimelineItemMeta[] = yield getCanvasItemsMetaSaga({
      seconds: position,
      root: true,
    });

    for (let i = 0; i < itemsMeta.length; i++) {
      const itemMeta = itemsMeta[i];

      if (itemMeta && itemMeta.timelineLayerId === timelineLayerId && !exceptIdsMap[itemMeta.timelineItemId]) {
        const item = yield select(timelineItemSeletor(itemMeta.timelineItemId));

        if (item.start + itemMeta.startSecondsOffset <= position && item.end + itemMeta.startSecondsOffset >= from) {
          return {
            item,
            position: item.start,
          };
        }
      }
    }

    if (position === maxEnd) {
      return { position };
    }

    position = Math.min(position + POSITIONS_MAP_STEP, maxEnd);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getCanvasItemMetaOnLayerSaga({
  seconds,
  timelineLayerId,
  exceptIds,
}: {
  seconds: number;
  timelineLayerId: string;
  exceptIds?: string[];
}) {
  if (!timelineLayerId) {
    return null;
  }

  // root
  const keyItems: ITimelineItemMeta[] = yield getCanvasItemsMetaSaga({
    seconds,
    precise: true,
    root: true,
  });

  const exceptIdsMap = (exceptIds || []).reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {} as Record<string, true>);

  const result = keyItems.find(
    (item) => item.timelineLayerId === timelineLayerId && !exceptIdsMap?.[item.timelineItemId],
  );

  return result || null;
}

export const getMathMin = (...props: (number | null)[]) => {
  props = props.filter((prop) => prop !== null && prop !== undefined) as number[];

  return Math.min(...props);
};
export const getMathMax = (...props: (number | null)[]) => {
  props = props.filter((prop) => prop !== null && prop !== undefined) as number[];

  return Math.max(...props);
};

function* getCanvasItemsMetaSaga({ seconds, precise, root }: { seconds: number; precise?: boolean; root?: boolean }) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const startPositionKeyStart = Math.floor(seconds);
  const startPositionKeyEnd = startPositionKeyStart + POSITIONS_MAP_STEP;
  const key = `${startPositionKeyStart}-${startPositionKeyEnd}`;

  const map = root ? videoEditorData.rootTimelineItemsPositionsMap : videoEditorData.timelineItemsPositionsMap;
  const keyItems = map[key] || [];

  if (!precise) {
    return keyItems;
  }

  const secondItems = keyItems.filter(({ timelineItemId, startSecondsOffset, minStart, maxEnd }) => {
    const item = videoConfig.itemsMap[timelineItemId];
    return (
      getMathMax(minStart, item.start + startSecondsOffset) <= seconds &&
      seconds < getMathMin(maxEnd, item.end + startSecondsOffset)
    );
  });

  return secondItems;
}

function* resetGhostTimelineLayerPrevStateSaga({
  payload,
}: Pick<PayloadAction<ITimelineGhostItemHoverTimelineLayer>, 'payload'>) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  const ghostTimelineLayerPrevState: ISetGhostTimelineLayerPrevState = yield select(
    ghostTimelineLayerPrevStateSelector,
  );

  if (ghostTimelineLayerPrevState) {
    // reset target timeline
    const updatedLayers = videoConfig.timelineLayers.map((_layer) => {
      if (_layer.id !== ghostTimelineLayerPrevState.timelineLayerId) {
        return _layer;
      }

      return {
        ..._layer,
        timeline: ghostTimelineLayerPrevState.timeline,
      };
    });

    yield put(
      updateVideoConfig({
        itemsMap: ghostTimelineLayerPrevState.itemsMap,
        timelineLayers: updatedLayers,
      }),
    );
    yield updateTimelineMetaSaga();
    yield put(setGhostTimelineLayerPrevState(null));
  }

  if (payload) {
    yield timelineGhostItemHoverTimelineLayerSaga({ payload });
  }
}

function* pasteTimelineItemSaga() {
  let copiedTimelineItem = yield select(copiedTimelineItemSelector);

  let newId = null;
  if (copiedTimelineItem.type === 'group') {
    const cloneGroupTimelineItemResult: { timelineItem: ITimelineItem } = yield cloneGroupTimelineItemSaga({
      payload: {
        timelineItemId: copiedTimelineItem.id,
      },
    });
    copiedTimelineItem = cloneGroupTimelineItemResult.timelineItem;
    newId = copiedTimelineItem.id;
  }

  if (!newId) {
    newId = uuid();
  }

  const timelineItem = {
    ...copiedTimelineItem,
    id: newId,
  };

  yield cloneCloudAssetCacheForTimelineItemSaga({
    payload: {
      timelineItem,
    },
  });

  yield addToTimelineSaga({
    payload: {
      timelineItem,
    },
  });

  yield put(addUndo({}));
  yield put(saveVideo({}));
}

function* timelineGhostItemHoverTimelineLayerSaga({
  payload,
}: Pick<PayloadAction<ITimelineGhostItemHoverTimelineLayer>, 'payload'>) {
  // console.log('\n\ntimelineGhostItemHoverTimelineLayerSaga', payload);

  const {
    // hoverLayerId, start,
    newGhostTimelineItemMeta,
    mouseSeconds,
    hoverLayerDisplayItem,
  } = payload;

  let prevGhostTimelineItemMeta: IGhostTimelineItemMeta = yield select(ghostTimelineItemMetaSelector);

  prevGhostTimelineItemMeta = prevGhostTimelineItemMeta || newGhostTimelineItemMeta;
  const ghostTimelineItem: ITimelineItem = yield select(timelineItemSeletor(prevGhostTimelineItemMeta.timelineItemId));
  const visualType = ghostTimelineItem.type === 'audio' ? 'audio' : 'visual';

  const acceptTypesByTimelineLayerId = yield select(acceptTypesByTimelineLayerIdSelector);
  const acceptTypes =
    hoverLayerDisplayItem?.type === 'layer' && acceptTypesByTimelineLayerId[hoverLayerDisplayItem.layer.id];

  if (
    !hoverLayerDisplayItem ||
    (hoverLayerDisplayItem.type === 'placeholder' && !hoverLayerDisplayItem.accept.includes(visualType)) ||
    (hoverLayerDisplayItem.type === 'layer' && !acceptTypes.includes(ghostTimelineItem.type))
  ) {
    // console.log('>>> return', hoverLayerDisplayItem, type);
    return;
  }

  // console.log('>>> not return', hoverLayerDisplayItem);

  const hoverLayerId = (hoverLayerDisplayItem as ILayersDisplayDataLayer).layer?.id || null;
  const isSameLayer = hoverLayerId === prevGhostTimelineItemMeta.timelineLayerId;

  // if we moved from the previous layer
  if (prevGhostTimelineItemMeta.timelineLayerId && !isSameLayer) {
    // reset state on previous layer if hover layer changed
    yield put(resetGhostTimelineLayerPrevState());

    // remove timeline item from previous layer
    yield put(
      removeTimelineItem({
        timelineItemId: prevGhostTimelineItemMeta.timelineItemId,
        fromTimeline: true,
      }),
    );
  }

  yield put(setGhostTimelineItemMeta(newGhostTimelineItemMeta));

  // no need for recalculations if we hover placeholder
  if (hoverLayerDisplayItem.type === 'placeholder') {
    return;
  }

  const startPosition = yield dragGetStartPosition({
    hoverLayerId,
    timelineItem: ghostTimelineItem,
    start: newGhostTimelineItemMeta.seconds,
    mouseSeconds,
  });

  // already target placement
  if (hoverLayerId === prevGhostTimelineItemMeta.timelineLayerId && startPosition === ghostTimelineItem.start) {
    // console.log('already target placement: hoverLayerId ', hoverLayerId);
    // console.log('already target placement: startPosition ', startPosition);
    return;
  }

  // if there are changes to apply and we have a previous state
  // reset previous state first
  // then recalculate possible changes again
  const ghostTimelineLayerPrevState: ISetGhostTimelineLayerPrevState = yield select(
    ghostTimelineLayerPrevStateSelector,
  );
  if (ghostTimelineLayerPrevState) {
    yield resetGhostTimelineLayerPrevStateSaga({ payload });
    return;
  }

  const addToTimelinePayload: IAddToTimeline = {
    timelineItem: ghostTimelineItem,
    layerId: hoverLayerId,
    start: startPosition,
    removeExistingItem: isSameLayer,
  };

  // if (!isSameLayer) {
  //   // yield removeTimelineItemSaga({
  //   //   payload: {
  //   //     timelineItemId: ghostTimelineItem.id,
  //   //     layerId: ghostTimelineItemMeta.timelineLayerId,
  //   //     fromTimeline: true,
  //   //   },
  //   // });

  //   yield put(
  //     setGhostTimelineItemMeta({
  //       ...prevGhostTimelineItemMeta,
  //       timelineLayerId: hoverLayerId,
  //     }),
  //   );
  // }

  // const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  // const timeline = videoConfig.timelineLayers.find(({ id }) => id === hoverLayerId)?.timeline || [];

  // get items map before making the shift
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const { withTimelineItemsShift } = yield addToTimelineSaga({
    payload: addToTimelinePayload,
  });

  if (withTimelineItemsShift) {
    const timeline = hoverLayerDisplayItem.layer.timeline;

    yield put(
      setGhostTimelineLayerPrevState({
        timelineLayerId: hoverLayerId,
        timeline,
        itemsMap: videoConfig.itemsMap,
      }),
    );
  }
}

function* dragGetStartPosition({
  hoverLayerId,
  timelineItem,
  start,
  mouseSeconds,
}: {
  hoverLayerId: string;
  timelineItem: ITimelineItem;
  start: number;
  mouseSeconds: number;
}) {
  const ghostItemDuration = timelineItem.end - timelineItem.start;

  let startPosition: number = null;

  let exceptIds = [timelineItem.id];

  const minPosition = yield getMinNoOverlapPosition({
    from: mouseSeconds,
    min: start,
    timelineLayerId: hoverLayerId,
    exceptIds,
  });

  startPosition = minPosition.position;

  if (minPosition.item) {
    exceptIds.push(minPosition.item.id);
  }
  let minPosition2 = null;

  if (minPosition.item && (minPosition.item.end - minPosition.item.start) / 2 > mouseSeconds - minPosition.item.start) {
    minPosition2 = yield getMinNoOverlapPosition({
      from: minPosition.item.start,
      min: minPosition.item.start - ghostItemDuration,
      timelineLayerId: hoverLayerId,
      exceptIds: [timelineItem.id, minPosition.item.id],
    });

    startPosition = minPosition2.position;

    exceptIds = [timelineItem.id, minPosition2.item?.id].filter(Boolean);
  }

  const maxPosition = yield getMaxNoOverlapPosition({
    from: startPosition,
    max: startPosition + ghostItemDuration,
    timelineLayerId: hoverLayerId,
    exceptIds,
  });

  exceptIds = [timelineItem.id, maxPosition.item?.id].filter(Boolean);

  if (startPosition + ghostItemDuration > maxPosition.position) {
    const minPosition3 = yield getMinNoOverlapPosition({
      from: maxPosition.position,
      min: maxPosition.position - ghostItemDuration,
      timelineLayerId: hoverLayerId,
      exceptIds,
    });

    startPosition = minPosition3.position;

    if (startPosition + ghostItemDuration > maxPosition.position) {
      // const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
      // const timeline = videoConfig.timelineLayers.find(({ id }) => id === hoverLayerId)?.timeline || [];
      // console.log('offset 1');
      // yield put(
      //   setGhostTimelineLayerPrevState({
      //     timelineLayerId: hoverLayerId,
      //     timeline,
      //     itemsMap: videoConfig.itemsMap,
      //   }),
      // );
    }
  }
  return startPosition;
}

// function* updateTimelineItemInItemsMapSaga({ payload: timelineItem }: Pick<PayloadAction<ITimelineItem>, 'payload'>) {
//   const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

//   const updatedItemsMap: TTimelineItemsMap = {
//     ...videoConfig.itemsMap,
//     [timelineItem.id]: timelineItem,
//   };

//   yield put(
//     updateVideoConfig({
//       itemsMap: updatedItemsMap,
//       duration: Math.max(timelineItem.end, videoConfig.duration),
//     }),
//   );
// }

const MIN_TRIM_DURATION = 0.5;
function* trimTimelineItemStartSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemStart>, 'payload'>) {
  const { timelineItemId } = yield select(trimTimelineItemMetaSelector);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));
  // const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));

  if (timelineItem.type === 'video' || timelineItem.type === 'audio' || timelineItem.type === 'group') {
    yield trimTimelineItemStartWithDurationSaga({ payload });
    return;
  }

  yield trimTimelineItemStartNoDurationSaga({ payload });
}

function* trimTimelineItemStartNoDurationSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemStart>, 'payload'>) {
  let { start } = payload;
  const { timelineLayerId, timelineItemId } = yield select(trimTimelineItemMetaSelector);

  // limit from start of the timeline
  start = Math.max(start, 0);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));

  const maxDurationEnd = timelineItem.end - MIN_TRIM_DURATION;

  // limit from end based on duration
  start = Math.min(start, maxDurationEnd);

  if (start < timelineItem.start) {
    const { position } = yield getMinNoOverlapPosition({
      from: timelineItem.start,
      min: start,
      timelineLayerId,
      exceptIds: [timelineItemId],
    });

    start = Math.max(start, position);
  }

  if (payload.onTrim) {
    payload.onTrim({
      start,
    });
  }

  if (start === timelineItem.start) {
    return;
  }

  yield put(
    updateTimelineItemInItemsMap({
      ...timelineItem,
      start,
    }),
  );

  yield refreshCanvasSaga({
    payload: {},
  });
}

function* trimTimelineItemStartWithDurationSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemStart>, 'payload'>) {
  let { start } = payload;
  const { timelineLayerId, timelineItemId } = yield select(trimTimelineItemMetaSelector);

  // limit from start of the timeline
  start = Math.max(start, 0);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));

  let assetDuration = null;

  if (timelineItem.cloudAssetId) {
    const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));
    assetDuration = cloudAsset.fileMeta.duration;
  }

  const minDuration = getMathMin(MIN_TRIM_DURATION, assetDuration);
  const maxDurationEnd = timelineItem.end - minDuration;

  // limit from end based on duration
  start = Math.min(start, maxDurationEnd);

  const noTrimStart = timelineItem.start - (timelineItem.trimStart || 0);

  // limit from start based on duration
  start = Math.max(noTrimStart, start);

  if (start < timelineItem.start) {
    const { position } = yield getMinNoOverlapPosition({
      from: timelineItem.start,
      min: start,
      timelineLayerId,
      exceptIds: [timelineItemId],
    });

    start = Math.max(start, position);
  }

  let newTrim = start - noTrimStart;
  newTrim = Math.max(0, newTrim);

  if (payload.onTrim) {
    payload.onTrim({
      start,
    });
  }

  if (start === timelineItem.start && newTrim === timelineItem.trimStart) {
    return;
  }

  yield put(
    updateTimelineItemInItemsMap({
      ...timelineItem,
      start,
      trimStart: newTrim,
    }),
  );
  yield refreshCanvasSaga({
    payload: {},
  });
}

function* trimTimelineItemEndSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemEnd>, 'payload'>) {
  const { timelineItemId } = yield select(trimTimelineItemMetaSelector);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));
  // const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));

  // TO CHECK: group?
  if (timelineItem.type === 'video' || timelineItem.type === 'audio') {
    yield trimTimelineItemEndWithDurationSaga({ payload });
    return;
  }

  yield trimTimelineItemEndNoDurationSaga({ payload });
}

function* trimTimelineItemEndNoDurationSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemEnd>, 'payload'>) {
  let { end } = payload;
  const { timelineLayerId, timelineItemId } = yield select(trimTimelineItemMetaSelector);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));

  const minDurationStart = timelineItem.start + MIN_TRIM_DURATION;

  // limit from start based on duration
  end = Math.max(end, minDurationStart);

  if (end > timelineItem.end) {
    const { position } = yield getMaxNoOverlapPosition({
      from: timelineItem.end,
      max: end,
      timelineLayerId,
      exceptIds: [timelineItemId],
    });

    end = Math.min(end, position);
  }

  if (payload.onTrim) {
    payload.onTrim({
      end,
    });
  }

  if (end === timelineItem.end) {
    return;
  }

  yield put(
    updateTimelineItemInItemsMap({
      ...timelineItem,
      end,
    }),
  );
  yield refreshCanvasSaga({
    payload: {},
  });
}

function* trimTimelineItemEndWithDurationSaga({ payload }: Pick<PayloadAction<ITrimTimelineItemEnd>, 'payload'>) {
  let { end } = payload;
  const { timelineLayerId, timelineItemId } = yield select(trimTimelineItemMetaSelector);

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));
  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));

  const assetDuration = cloudAsset.fileMeta.duration;

  const minDuration = Math.min(MIN_TRIM_DURATION, assetDuration);
  const minDurationStart = timelineItem.start + minDuration;

  // limit from start based on duration
  end = Math.max(end, minDurationStart);

  const noTrimStart = timelineItem.start - (timelineItem.trimStart || 0);
  const maxDurationEnd = noTrimStart + assetDuration;

  // limit from end based on duration
  end = Math.min(end, maxDurationEnd);

  if (end > timelineItem.end) {
    const { position } = yield getMaxNoOverlapPosition({
      from: timelineItem.end,
      max: end,
      timelineLayerId,
      exceptIds: [timelineItemId],
    });

    end = Math.min(end, position);
  }

  if (payload.onTrim) {
    payload.onTrim({
      end,
    });
  }

  if (end === timelineItem.end) {
    return;
  }

  yield put(
    updateTimelineItemInItemsMap({
      ...timelineItem,
      end,
    }),
  );
  yield refreshCanvasSaga({
    payload: {},
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getTimelineItemsAtSecondsSaga({ payload }: Pick<PayloadAction<IGetTimelineItemsAtSecondsSaga>, 'payload'>) {
  const { timelineLayerId, seconds } = payload;

  const positionsMap = videoEditorData.timelineItemsPositionsMap;

  const startSecond = Math.floor(seconds);
  const endSecond = startSecond + POSITIONS_MAP_STEP;
  const key = `${startSecond}-${endSecond}`;

  console.log('key', key);

  const hoverTimelineItem = positionsMap[key]?.find((item) => item.timelineLayerId === timelineLayerId);

  return hoverTimelineItem;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getTimelineItemsBetweenSecondsSaga({
  payload,
}: Pick<PayloadAction<IGetTimelineItemsBetweenSaga>, 'payload'>) {
  const { timelineLayerId, start, end, exceptIds = [] } = payload;

  const positionsMap = videoEditorData.timelineItemsPositionsMap;

  const exceptIdsMap = exceptIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {} as any);

  const items = [];
  const itemIdsMap = {};
  for (let startSecond = Math.floor(start); startSecond < Math.ceil(end); startSecond++) {
    const endSecond = startSecond + POSITIONS_MAP_STEP;
    const key = `${startSecond}-${endSecond}`;

    (positionsMap[key] || []).forEach((item) => {
      if (
        exceptIdsMap[item.timelineItemId] ||
        itemIdsMap[item.timelineItemId] ||
        item.timelineLayerId !== timelineLayerId
      ) {
        return;
      }

      items.push(item);
      itemIdsMap[item.timelineItemId] = true;
    });
  }

  return items;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getTimelineItemsEndingBetweenSecondsSaga({
  payload,
}: Pick<PayloadAction<IGetTimelineItemsEndingBetween>, 'payload'>) {
  const { timelineLayerId, start, end, exceptIds } = payload;
  const exceptIdsMap = exceptIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {} as any);

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const layer = videoConfig.timelineLayers.find(({ id }) => id === timelineLayerId);

  if (!layer) {
    yield put(
      logError({
        event: 'getTimelineItemsEndingBetweenSaga: layer not found',
        data: {
          payload,
          videoConfig,
        },
      }),
    );
    return [];
  }

  const { timeline } = layer;

  const items = [];

  for (let i = 0; i < timeline.length; i++) {
    const timelineItemId = timeline[i];
    const timelineItem = videoConfig.itemsMap[timelineItemId];

    if (exceptIdsMap[timelineItemId]) {
      continue;
    }

    if (timelineItem.end > start && timelineItem.end <= end) {
      items.push(timelineItem);
    }
  }

  return items;
}

function* splitTimelineItemSaga({ payload }: Pick<PayloadAction<ISplitTimelineItem>, 'payload'>) {
  const { timelineLayerId, timelineItemId, seconds } = payload;

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);
  let timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));

  let updatedItemsMap: TTimelineItemsMap = {
    ...videoConfig.itemsMap,
  };

  let newId = uuid();
  if (timelineItem.type === 'group') {
    const cloneGroupTimelineItemResult: { timelineItem: ITimelineItem; itemsMap: TTimelineItemsMap } =
      yield cloneGroupTimelineItemSaga({
        payload: {
          timelineItemId: timelineItem.id,
          withUpdateVideoConfig: false,
        },
      });
    timelineItem = cloneGroupTimelineItemResult.timelineItem;
    newId = timelineItem.id;

    updatedItemsMap = {
      ...updatedItemsMap,
      ...cloneGroupTimelineItemResult.itemsMap,
    };
  }

  updatedItemsMap[timelineItemId] = {
    ...timelineItem,
    end: seconds,
  };

  yield put(updateVideoConfig({ itemsMap: updatedItemsMap }));

  const newTimelineItem: ITimelineItem = {
    ...timelineItem,
    id: newId,
    start: seconds,
    end: timelineItem.end,
    trimStart: (timelineItem.trimStart || 0) + seconds - timelineItem.start,
  };

  yield cloneCloudAssetCacheForTimelineItemSaga({
    payload: {
      timelineItem: newTimelineItem,
    },
  });

  yield addToTimelineSaga({
    payload: {
      timelineItem: newTimelineItem,
      layerId: timelineLayerId,
      start: seconds,
    },
  });

  yield put(setIsSplitMode(false));
}

function* refreshCanvasSaga({ payload = {} }: Pick<PayloadAction<IRefreshCanvas>, 'payload'>) {
  const { seconds } = payload;

  yield updateTimelineMetaSaga();

  yield updateCanvasSaga({
    payload: { seconds },
  });
}

// function* updateTimelineItemSaga({ payload }: Pick<PayloadAction<Partial<ITimelineItem>>, 'payload'>) {
//   const { id } = payload;

//   const timelineItem: ITimelineItem = yield select(timelineItemSeletor(id));

//   if (!timelineItem) {
//     return;
//   }

//   const updatedTimelineItem = {
//     ...timelineItem,
//     ...payload,
//   };

//   Object.entries(payload).forEach(([key, value]) => {
//     if (value === undefined) {
//       delete updatedTimelineItem[key];
//     }
//   });

//   yield updateTimelineItemInItemsMapSaga({
//     payload: updatedTimelineItem,
//   });
// }

function* getIsOverlappingSaga(payload: IGetIsOverlapping) {
  const { timelineLayerId, start, end, exceptIds } = payload;

  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const layer = videoConfig.timelineLayers.find(({ id }) => id === timelineLayerId);

  for (let timelineItemId of layer.timeline) {
    const timelineItem = videoConfig.itemsMap[timelineItemId];

    if (exceptIds?.includes(timelineItemId)) {
      continue;
    }

    if (start <= timelineItem.start && timelineItem.start < end) {
      return true;
    }
    if (start < timelineItem.end && timelineItem.end < end) {
      return true;
    }
    if (timelineItem.start <= start && start < timelineItem.end) {
      return true;
    }
    if (timelineItem.start < end && end < timelineItem.end) {
      return true;
    }
    if (timelineItem.start > end) {
      return false;
    }
  }

  return false;
}

function* getTimelineLayerForTimelineItemSaga({ timelineItemId }: { timelineItemId: string }) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const layer = videoConfig.timelineLayers.find(({ timeline }) => timeline.includes(timelineItemId));

  return layer;
}

function* updateTimelineItemLayerSaga({ payload }: Pick<PayloadAction<IUpdateTimelineItemLayer>, 'payload'>) {
  let { timelineItemId, timelineLayerId, onChangeLayer } = payload;

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));

  const start = timelineItem.start;
  const end = timelineItem.end;

  if (!timelineLayerId) {
    const timelineLayer = yield getTimelineLayerForTimelineItemSaga({ timelineItemId });
    timelineLayerId = timelineLayer.id;
  }

  const isOverlapping = yield getIsOverlappingSaga({
    timelineLayerId,
    start,
    end,
    exceptIds: [timelineItemId],
  });

  if (!isOverlapping) {
    return;
  }

  yield removeTimelineItemSaga({
    payload: {
      timelineItemId: timelineItem.id,
      fromTimeline: true,
    },
  });

  const addToTimelineResult = yield addToTimelineSaga({
    payload: {
      timelineItem,
      afterLayerId: timelineLayerId,
      start,
      onAddToTimeline: onChangeLayer,
    },
  });

  return addToTimelineResult;
}

function* setGeneratedSubtitlesEnabledStateSaga({
  payload: { enabled },
}: Pick<PayloadAction<{ enabled: boolean }>, 'payload'>) {
  const config: IVideoConfig = yield select(videoConfigSeletor);

  const newItemsMap = {
    ...config.itemsMap,
  };

  for (const id in config.itemsMap) {
    const item = config.itemsMap[id];

    if (item.type === 'text' && item.generatedMeta?.isSubtitlesOfTimelineItemId) {
      if (enabled && item.enabled === false) {
        newItemsMap[item.id] = {
          ...item,
        };
        delete newItemsMap[item.id].enabled;
      }

      if (!enabled && item.enabled === undefined) {
        newItemsMap[item.id] = {
          ...item,
          enabled: false,
        };
      }
    }
  }

  yield put(
    updateVideoConfig({
      itemsMap: newItemsMap,
    }),
  );
}

export function* updateVideoDurationSaga() {
  const { timelineLayers, itemsMap }: IVideoConfig = yield select(videoConfigSeletor);

  let duration = 0;
  for (let i = 0; i < timelineLayers.length; i++) {
    const { timeline } = timelineLayers[i];

    if (timeline.length === 0) {
      continue;
    }

    const timelineItemId = timeline[timeline.length - 1];
    const timelineItem = itemsMap[timelineItemId];

    duration = Math.max(duration, timelineItem.end);
  }

  yield put(
    updateVideoConfig({
      duration,
    }),
  );
}
export function* getItemsMapFromTimelineLayers(payload: {
  itemsMap?: Record<string, ITimelineItem>;
  timelineLayers: ITimelineLayer[];
}) {
  const { itemsMap = {}, timelineLayers } = payload;

  for (let layer of timelineLayers) {
    const { timeline } = layer;

    for (let timelineItemId of timeline) {
      const timelineItem = yield select(timelineItemSeletor(timelineItemId));

      if (timelineItem.type === 'group') {
        yield getItemsMapFromTimelineLayers({
          itemsMap,
          timelineLayers: timelineItem.timelineLayers,
        });

        continue;
      }

      if (['video', 'image', 'audio', 'gif'].includes(timelineItem.type) && !timelineItem.cloudAssetId) {
        continue;
      }

      itemsMap[timelineItem.id] = timelineItem;
    }
  }

  return itemsMap;
}

function* updateTimelineItemWithCloudAssetDurationSaga({
  payload,
}: Pick<PayloadAction<{ timelineItemId: ITimelineItem['id'] }>, 'payload'>) {
  const { timelineItemId } = payload;

  const timelineItem: ITimelineItem = yield select(timelineItemSeletor(timelineItemId));
  if (!timelineItem.cloudAssetId) {
    return;
  }

  const cloudAsset: ICloudAsset = yield select(cloudAssetSelector(timelineItem.cloudAssetId));
  const duration = cloudAsset.fileMeta.duration;

  yield put(
    updateTimelineItem({
      ...timelineItem,
      end: timelineItem.start + duration,
    }),
  );

  yield updateTimelineMetaSaga();

  yield updateTimelineItemLayerSaga({
    payload: {
      timelineItemId: timelineItem.id,
    },
  });
}

function* clearStateForTimelineItemSaga({
  payload: { timelineItemId },
}: Pick<PayloadAction<{ timelineItemId: string }>, 'payload'>) {
  const itemProcessingStatus: any = select(processingTimelineItemSelector(timelineItemId));

  if (itemProcessingStatus?.retryFunctionId) {
    delete retryFunctions[itemProcessingStatus.retryFunctionId];
  }
}

export function* getTimelineItemsByCloudAssetIdSaga({ cloudAssetId }: { cloudAssetId: string }) {
  const videoConfig: IVideoConfig = yield select(videoConfigSeletor);

  const items = Object.values(videoConfig.itemsMap).filter((item) => item.cloudAssetId === cloudAssetId);

  return items;
}

export default function* videoEditorSaga() {
  yield takeLatest(loadExistingVideos, loadExistingVideosSaga);
  yield takeLatest(loadExistingVideosNextPage, loadExistingVideosSaga);
  yield takeLatest(startNewVideo, createVideoSaga);
  yield takeLatest(loadVideo, loadVideoSaga);
  yield takeLatest(cloneExistingVideo, cloneExistingVideoSaga);
  yield takeLatest(saveVideo, saveVideoSaga);
  yield takeLatest(createExportedVideo, createExportedVideoSaga);
  yield takeLatest(addToTimeline, addToTimelineSaga);
  yield takeLatest(removeEmptyTimelineLayers, removeEmptyTimelineLayersSaga);
  yield takeLatest(updateCanvas, updateCanvasSaga);
  yield takeLatest(playVideo, playVideoSaga);
  yield takeLatest(timelineGhostItemHoverTimelineLayer, timelineGhostItemHoverTimelineLayerSaga);
  yield takeLatest(trimTimelineItemStart, trimTimelineItemStartSaga);
  yield takeLatest(trimTimelineItemEnd, trimTimelineItemEndSaga);
  yield takeLatest(removeTimelineItem, removeTimelineItemSaga);
  yield takeLatest(splitTimelineItem, splitTimelineItemSaga);
  yield takeLatest(resetGhostTimelineLayerPrevState, resetGhostTimelineLayerPrevStateSaga);
  yield takeLatest(pasteTimelineItem, pasteTimelineItemSaga);
  yield takeLatest(undo, refreshCanvasSaga);
  yield takeLatest(redo, refreshCanvasSaga);
  yield takeLatest(updateTimelineItemLayer, updateTimelineItemLayerSaga);
  yield takeLatest(refreshCanvas, refreshCanvasSaga);
  yield takeLatest(updateTimelineMeta, updateTimelineMetaSaga);
  yield takeLatest(setGeneratedSubtitlesEnabledState, setGeneratedSubtitlesEnabledStateSaga);
  yield takeLatest(updateVideoDuration, updateVideoDurationSaga);
  yield takeLatest(updateTimelineItemWithCloudAssetDuration, updateTimelineItemWithCloudAssetDurationSaga);
  yield takeLatest(clearStateForTimelineItem, clearStateForTimelineItemSaga);
}
