import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { I18nManager, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { calcWidth, scale1 } from '../../utils/dimensions';
import { EHeaderBackButtonType } from '../../utils/constants/header';
import { IHeaderBackButton } from '../../types/header';

import BackIcon from '../../assets/icons/back.svg';
import BackInnerIcon from '../../assets/icons/back-inner.svg';
import { useDispatch } from 'react-redux';
import CloseIcon from './../../assets/icons/close-modal.svg';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps extends IHeaderBackButton {
  style?: StyleProp<ViewStyle>;
}

const Back: React.FC<IProps> = (props) => {
  const { mode = EHeaderBackButtonType.ARROW, color, style } = props;
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { getState } = useNavigation<StackNavigationProp<any>>();
  const { index, routes } = getState();
  const screenName = routes[index].name;

  const size = mode === EHeaderBackButtonType.CLOSE ? calcWidth(32) : scale1(25);
  const isRotate = mode !== EHeaderBackButtonType.CLOSE && I18nManager.isRTL;

  const Icon = useMemo(() => {
    switch (mode) {
      case EHeaderBackButtonType.ARROW:
        return BackIcon;
      case EHeaderBackButtonType.INNER_ARROW:
        return BackInnerIcon;
      case EHeaderBackButtonType.CLOSE:
        return CloseIcon;
      default:
        return BackIcon;
    }
  }, [mode]);

  const handlePress = () => {
    navigation.goBack();
    dispatch(
      trackEvent({
        event: 'navigate_back',
        props: { page_title: screenName },
      }),
    );
  };

  return (
    <TouchableOpacity style={style} onPress={handlePress}>
      <View
        style={{
          transform: [{ rotateY: isRotate ? '180deg' : '0deg' }],
        }}
      >
        <Icon height={size} width={size} fill={color} />
      </View>
    </TouchableOpacity>
  );
};

export default Back;
