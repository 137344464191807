import React, { useCallback } from 'react';
import { ListRenderItem } from 'react-native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { ISummaryCard } from '../../../../types/biteSummary';
import { getFontFamily, ICardColorPalette, summaryCardColors } from '../../../../utils/biteSummary';
import { EditableProp } from '../../CreateSummaryNotes/CreateSummaryNotes';

interface IProps {
  note: ISummaryCard;
  editableProp: EditableProp;
  onChange: (note: ISummaryCard) => void;
}

const FontIcons = {
  classic: (text) => <S.FontIconClassic>{text}</S.FontIconClassic>,
  typewriter: (text) => <S.FontIconTypewriter>{text}</S.FontIconTypewriter>,
  handwriting: (text) => <S.FontIconHandwriting>{text}</S.FontIconHandwriting>,
};

const NotePanel: React.FC<IProps> = ({ editableProp, note, onChange }) => {
  const onFontSelect = useCallback(
    (fontKey) => {
      const updatedCard = { ...note };
      updatedCard.font = fontKey;
      onChange(updatedCard);
    },
    [note, onChange],
  );

  const handleColorPick = useCallback(
    (colorPalette: ICardColorPalette) => () => {
      const updatedCard = { ...note };
      updatedCard.color = colorPalette.cardColor;
      onChange(updatedCard);
    },
    [note, onChange],
  );

  const renderItem: ListRenderItem<ICardColorPalette> = useCallback(
    ({ item }) => {
      return (
        <S.ColorButton onPress={handleColorPick(item)}>
          <S.ColorPicker isSelected={note.color === item.cardColor} color={item.labelColor} />
        </S.ColorButton>
      );
    },
    [handleColorPick, note],
  );

  if (!note) {
    return <S.Container />;
  }

  if (editableProp === EditableProp.color) {
    return (
      <S.Container>
        <S.ColorPanelList
          showsHorizontalScrollIndicator={false}
          data={summaryCardColors}
          renderItem={renderItem}
          horizontal
        />
      </S.Container>
    );
  }

  if (editableProp === EditableProp.font) {
    return (
      <S.Container>
        <S.FontPanelList>
          {Object.keys(FontIcons).map((item) => (
            <FontButton key={item} item={item} onPress={onFontSelect} isSelected={item === note.font} />
          ))}
        </S.FontPanelList>
      </S.Container>
    );
  }

  return <S.Container />;
};

const FontButton = ({ item, onPress, isSelected }) => {
  const { t } = useTranslation();

  const pressHandler = useCallback(() => {
    onPress(item);
  }, [item, onPress]);

  return (
    <S.FontIconButton onPress={pressHandler}>
      <S.FontIconWrapper isSelected={isSelected}>{FontIcons[item](t('addSummaryNotes.fontButton'))}</S.FontIconWrapper>
    </S.FontIconButton>
  );
};

const S = {
  Container: styled.View`
    height: ${calcHeight(55)}px;
  `,
  ColorPicker: styled.View<{ isSelected: boolean; color: string }>`
    width: ${calcWidth(20)}px;
    height: ${calcWidth(20)}px;
    border-radius: ${calcWidth(20 / 2)}px;
    background: ${({ color }) => color};
    border: 1px solid ${({ isSelected, color, theme }) => (isSelected ? theme.colors.text : color)};
  `,
  ColorButton: styled.TouchableOpacity`
    margin-right: ${calcWidth(22)}px;
  `,
  ColorPanelList: styled.FlatList`
    flex: 1;
    width: ${calcWidth(327)}px;
    margin-top: ${calcHeight(32)}px;
    height: ${calcHeight(24)}px;
  `,
  FontPanelList: styled.View`
    flex: 1;
    flex-direction: row;
    width: ${calcWidth(327)}px;
    margin-top: ${calcHeight(32)}px;
    height: 24px;
  `,
  FontIconButton: styled.TouchableOpacity`
    margin-right: ${calcWidth(45)}px;
  `,
  FontIconWrapper: styled.View<{ isSelected: boolean }>`
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.2)};
  `,
  FontIconClassic: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => getFontFamily(theme, 'classic')};
  `,
  FontIconTypewriter: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => getFontFamily(theme, 'typewriter')};
  `,
  FontIconHandwriting: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => getFontFamily(theme, 'handwriting')};
  `,
};

export default NotePanel;
