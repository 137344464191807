export enum EImproves {
  soundQuality = 'soundQuality',
  backgroundNoise = 'backgroundNoise',
  subtitles = 'subtitles',
  smartTags = 'smartTags',
  questions = 'questions',
  summary = 'summary',
  keyTakeaways = 'keyTakeaways',
  biteName = 'biteName',
  biteCover = 'biteCover',
}

export const improveToIntroMediaProcessingStatusFieldMap = {
  [EImproves.soundQuality]: 'enhancements',
  [EImproves.backgroundNoise]: 'enhancements',
  [EImproves.subtitles]: 'subtitles',
  [EImproves.questions]: 'questionSuggestion',
  [EImproves.summary]: 'summarySuggestion',
  [EImproves.biteName]: 'biteNameSuggestion',
  [EImproves.biteCover]: 'coverSuggestion',
};
