import { IFeatureFlags } from '../store/appActivity/appActivity.types';
import store from '../store';

export interface IIsFeatureEnabledParams {
  feature: string;
  featureFlags: IFeatureFlags;
  orgId?: number;
  email: string;
  userId: number;
}

export const getIsFeatureEnabled = (params: IIsFeatureEnabledParams): boolean => {
  const { feature, featureFlags, email, userId, orgId } = params;

  if (!featureFlags) {
    return false;
  }

  const featureConfig = featureFlags[feature];

  if (typeof featureConfig === 'boolean') {
    return <boolean>featureConfig;
  }

  if (featureConfig?.orgIds && featureConfig.orgIds.includes?.(orgId)) {
    return true;
  }

  if (featureConfig?.userIds && featureConfig.userIds.includes?.(userId)) {
    return true;
  }

  return featureConfig?.emailPatterns && featureConfig.emailPatterns.some((pattern) => email.indexOf(pattern) >= 0);
};

const getFeatureFlagsProps = (): Omit<IIsFeatureEnabledParams, 'feature'> => {
  const featureFlags = store.getState()?.appActivity?.config?.featureFlags;
  const userId = store.getState().auth?.user?.id;
  const email = store.getState().auth?.user?.email;
  const orgId = store.getState().auth?.activeOrganizationId;
  return { featureFlags, orgId, email, userId };
};

export const getIsFeatureEnabledForCurrentState = (feature: string) => {
  const props = getFeatureFlagsProps();
  const isEnabled = getIsFeatureEnabled({ ...props, feature });
  return isEnabled;
};
