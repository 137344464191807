import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import styled, { css, useTheme } from 'styled-components/native';
import { ViewStyle, I18nManager } from 'react-native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import cn from 'classnames';
import countries from '../../../utils/countries';
import defaultTheme from '../../../themes';
import { useDispatch, useSelector } from 'react-redux';
import { countryCodeSelector } from '../../../store/appActivity/appActivity.selectors';
import { userSelectedCountryCodeSelector } from '../../../store/authForm/authForm.selectors';
import { setUserSelectedCountryCode } from '../../../store/authForm/authForm.slice';

interface IProps {
  isEditMode: boolean;
  onPhoneNumberChange: (number: string) => void;
  phoneNumber: string;
  textPlaceholder?: string;
  onValidationChange: (isValid: boolean) => void;
  style?: ViewStyle;
  label?: string;
  error?: string;
  onEnterPress?: () => void;
}

const expandedErrorHeight = calcHeight(40);
const collapsedInputHeight = calcHeight(50);
const expandedInputHeight = calcHeight(70);

const phoneInputStyles = {
  fontSize: defaultTheme.fontSizes.s16,
  padding: `${calcHeight(12)}px ${calcWidth(20)}px`,
  color: defaultTheme.colors.text,
  borderRadius: calcHeight(50) / 2,
  border: 'none',
};

const COUNTRY_ISO_CODES: string[] = countries.map((country) => country.isoCode);

const PhoneNumberInput: React.FC<IProps> = ({
  isEditMode,
  onPhoneNumberChange,
  phoneNumber,
  textPlaceholder,
  style,
  label,
  error,
  onValidationChange,
  onEnterPress,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const countryCode = useSelector(countryCodeSelector);
  const userSelectedCountryCode = useSelector(userSelectedCountryCodeSelector);

  const [isFocused, setIsFocused] = useState(false);

  const colorsShared = useSharedValue(0); // 0 - default, 1 - focused, 2 - error

  const selectedCountry = userSelectedCountryCode || countryCode || 'US';

  useEffect(() => {
    if (isFocused) {
      colorsShared.value = withTiming(1);
      return;
    }
    if (error) {
      colorsShared.value = withTiming(2);
      return;
    }
    colorsShared.value = withTiming(0);
  }, [isFocused, error, colorsShared]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const className = useMemo(
    () =>
      cn({
        'PhoneInput--rtl': I18nManager.isRTL,
        'PhoneInput--force-ltr': I18nManager.isRTL && !!phoneNumber,
      }),
    [phoneNumber],
  );

  const handleChange = useCallback(
    (value: string) => {
      onPhoneNumberChange(value || '');

      if (typeof onValidationChange === 'function') {
        onValidationChange(isValidPhoneNumber(value || ''));
      }
    },
    [onPhoneNumberChange, onValidationChange],
  );

  const handleCountryChange = useCallback(
    (iso: string) => {
      dispatch(setUserSelectedCountryCode(iso));
    },
    [dispatch],
  );

  const phoneInputContainerAnimatedStyles = useAnimatedStyle(
    () => ({
      border: `1px solid ${theme.colors.gray5}`,
      borderRadius: calcHeight(50) / 2,
      borderColor: interpolateColor(
        colorsShared.value,
        [0, 1, 2],
        [theme.colors.lightGray34, theme.colors.primaryBlue, theme.colors.pinkError],
      ),
    }),
    [colorsShared],
  );

  const containerAnimatedStyles = useAnimatedStyle(
    () => ({
      height: withTiming(error ? expandedInputHeight : collapsedInputHeight),
    }),
    [error],
  );

  const errorAnimatedStyles = useAnimatedStyle(
    () => ({
      height: withTiming(error ? expandedErrorHeight : 0, { duration: 300 }),
      opacity: withTiming(error ? 1 : 0, { duration: 400 }),
    }),
    [error],
  );

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onEnterPress();
      }
    },
    [onEnterPress],
  );

  return (
    <S.View style={[containerAnimatedStyles, style]}>
      {!!label && <S.Label>{label}</S.Label>}
      {isEditMode ? (
        <S.PhoneInputContainer style={phoneInputContainerAnimatedStyles}>
          <ReactPhoneInput
            defaultCountry={selectedCountry}
            className={className}
            style={phoneInputStyles}
            value={phoneNumber}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyPress={handleEnterPress}
            countries={COUNTRY_ISO_CODES}
            country={selectedCountry}
            onCountryChange={handleCountryChange}
            onChange={handleChange}
            limitMaxLength
            international
          />
        </S.PhoneInputContainer>
      ) : (
        <S.ContactDetailsText>{phoneNumber || textPlaceholder}</S.ContactDetailsText>
      )}
      {!!error && <S.ErrorText style={errorAnimatedStyles}>{error}</S.ErrorText>}
    </S.View>
  );
};

const S = {
  View: styled(Animated.View)`
    width: 100%;
  `,
  ContactDetailsText: styled.Text<{ fontSize?: number }>`
    text-align: center;
    ${({ theme, fontSize }) => css`
      color: ${theme.colors.text};
      font-size: ${fontSize ? fontSize : theme.fontSizes.s20}px;
      font-family: ${theme.fontFamilies.GilroyMedium};
    `}
  `,
  Label: styled.Text`
    position: absolute;
    color: ${({ theme }) => theme.colors.gray19};
    background: ${({ theme }) => theme.colors.white};
    padding: 0 ${calcWidth(5)}px;
    top: -10px;
    left: 25px;
    font-size: ${({ theme }) => theme.fontSizes.s12};
    z-index: 2;
  `,
  ErrorText: styled(Animated.Text)`
    font-size: ${({ theme }) => theme.fontSizes.s13};
    line-height: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.pinkError};
    align-self: center;
    text-align: center;
  `,
  PhoneInputContainer: styled(Animated.View)``,
};

export default PhoneNumberInput;
