import React, { useCallback, memo, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPlaylistToEdit } from '../../store/createPlaylist/createPlaylist.actions';
import useIsPlaylistOwner from '../../hooks/useIsPlaylistOwner';
import { EShareItemType, showSharePanel } from '../../store/sharePanel/sharePanel.slice';
import Routes from '../../navigation/routes';
import { ESharePanelCallbackType } from '../../types/sharePanel';
import useToken from '../../hooks/useToken';
import { DASHBOARD_URL, PREVIEW_SITE_URL } from '../../utils/constants/urls';
import { EPreviewMode, PAGE_WIDTH } from '../../components/shared/PreviewControlPanel/constants';
import { IStackNavigation } from '../../navigation/types';
import WebView from '../../components/WebView/WebView';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../utils/dimensions';
import StatisticsButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/StatisticsButton';
import EditIcon from '../../assets/icons/preview/edit.svg';
import ShareButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/ShareButton';
import DeleteButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/DeleteButton';
import CopyButton from '../../components/shared/PreviewControlPanel/common/ControlPanelButtons/CopyButton';
import PreviewControlPanel from '../../components/shared/PreviewControlPanel';
import { Platform, StatusBar } from 'react-native';
import { deletePlaylist, fetchFullPlaylists } from '../../store/playlist/playlist.actions';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { playlistItemSelector } from '../../store/playlist/playlist.selectors';
import BlueAndRedButtonsModal from '../../components/modals/BlueAndRedButtonsModal';
import CreatorsName from './CreatorsName';
import SsoAuthLoader from '../auth/common/SsoAuthLoader';
import { activeOrganizationSelector, isContentAdminSelector } from '../../store/auth/auth.selectors';
import { confirmDeletePlaylistQuizButtonDataSet, playlistQuizEditButtonDataSet } from './Preview.contants';
import useOrganizations from '../../hooks/useOrganizations';
import { showBottomPanel } from '../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';
import { IOrganization } from '../../types/organization';
import { clonePlaylist } from '../../store/playlist/playlist.actions';
import { useShare } from '../../components/SharePanel/hooks/useShare';

interface IProps extends IStackNavigation {}

const PreviewPlaylist: React.FC<IProps> = ({ navigation, route }) => {
  const { playlistId, initialMode = EPreviewMode.PREVIEW, isEdit, shareOnMount } = route.params;

  const playlist = useSelector(playlistItemSelector(playlistId));

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const isShareShowedRef = useRef(false);

  const { token } = useToken();

  const [currentMode, setCurrentMode] = useState(initialMode);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganization>(null);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

  const closeDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
  }, []);

  const openDeleteModal = useCallback(() => {
    setDeleteModalVisible(true);
  }, []);

  const url = `${PREVIEW_SITE_URL}/${playlist?.is_quiz ? 'quiz' : 'playlists'}/${
    playlist?.id
  }/?token=${token}&rnd=${Date.now()}&isPreview=1`;

  const dashboardUrl = `${DASHBOARD_URL}/${playlist?.is_quiz ? 'quizzes' : 'playlists'}/${
    playlist?.id
  }/?token=${token}&rnd=${Date.now()}&isPreview=1`;

  const isContentAdmin = useSelector(isContentAdminSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);
  const isPlaylistOwner = useIsPlaylistOwner(playlist);
  const isAllowEdit = isPlaylistOwner || isContentAdmin;
  const maxPages = isAllowEdit ? 2 : 1;

  const isAllowSharing = playlist?.bite_shares.some((bite) => bite.no_sections === false);
  const isCloneAvailable = activeOrganization.is_creator;
  const creatorOrganizationsList = useOrganizations({ isCreator: true });
  const { handleShareItem } = useShare({
    shareData: playlist,
    shareItemType: EShareItemType.PLAYLIST,
    shareMode: playlist?.sharing_mode,
    from: playlist?.is_quiz ? 'quiz_preview' : 'playlist_preview',
  });

  useEffect(() => {
    if (!playlist) {
      dispatch(fetchFullPlaylists([playlistId]));
    }
  }, [dispatch, playlist, playlistId]);

  useEffect(() => {
    if (!playlist) {
      return;
    }

    const from = playlist?.is_quiz
      ? isEdit
        ? 'edit_quiz'
        : 'quiz_created'
      : isEdit
      ? 'edit_playlist'
      : 'playlist_created';

    if (currentMode === EPreviewMode.DASHBOARD) {
      dispatch(
        trackEvent({
          event: 'redirect_to_dashboard',
          props: { playlist_id: playlist?.id, target_url: dashboardUrl },
        }),
      );
      return;
    }

    dispatch(
      trackEvent({
        event: playlist?.is_quiz ? 'preview_quiz' : 'preview_playlist',
        props: { playlist_id: playlist?.id, from },
      }),
    );
  }, [currentMode, dashboardUrl, dispatch, isEdit, playlist, playlist?.id, playlist?.is_quiz]);

  const handleEdit = useCallback(() => {
    if (playlist?.is_quiz) {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { quiz_id: playlist?.id },
        }),
      );
    } else {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { playlist_id: playlist?.id },
        }),
      );
    }
    dispatch(setPlaylistToEdit({ playlist }));
    if (playlist?.is_quiz) {
      navigation.navigate(Routes.EditQuizStack.StackName, { params: { playlistId: playlist.id } });
      return;
    }
    navigation.navigate(Routes.EditPlaylistStack.StackName, { params: { playlistId: playlist.id } });
  }, [playlist, dispatch, navigation]);

  const handleShare = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'share_btn',
        props: { playlist_id: playlist?.id, from: playlist?.is_quiz ? 'quiz_preview' : 'playlist_preview' },
      }),
    );

    if (isWeb) {
      dispatch(
        showSharePanel({
          callbackType: ESharePanelCallbackType.PLAYLIST_IN_LIST,
          itemType: EShareItemType.PLAYLIST,
          data: playlist,
          from: playlist?.is_quiz ? 'quiz_preview' : 'playlist_preview',
        }),
      );
      return;
    }

    handleShareItem();
  }, [dispatch, handleShareItem, playlist]);

  const handleDeletePlaylist = useCallback(() => {
    if (!playlist?.is_quiz) {
      dispatch(
        trackEvent({
          event: 'delete',
          props: { playlist_id: playlist?.id },
        }),
      );
    } else {
      dispatch(
        trackEvent({
          event: 'delete',
          props: { quiz_id: playlist?.id },
        }),
      );
    }

    dispatch(deletePlaylist(playlist?.id));
    closeDeleteModal();

    if (isWeb) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Routes.HomeStack.StackName,
            params: {
              screen: Routes.HomeStack.Home,
            },
          },
        ],
      });
      return;
    }

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [closeDeleteModal, dispatch, playlist?.id, playlist?.is_quiz, navigation]);

  const toggleDashBoard = useCallback(() => {
    setCurrentMode((prev) => (prev === EPreviewMode.PREVIEW ? EPreviewMode.DASHBOARD : EPreviewMode.PREVIEW));
  }, []);

  const openCloneModal = useCallback(() => {
    setIsCloneModalOpen(true);
  }, []);

  const closeCloneModal = useCallback(() => {
    setSelectedOrganization(null);
    setIsCloneModalOpen(false);
  }, []);

  const handleCloneConfirmation = useCallback(() => {
    const targetOrg = creatorOrganizationsList.length === 1 ? creatorOrganizationsList[0] : selectedOrganization;
    dispatch(clonePlaylist({ playlist, org: targetOrg }));
    closeCloneModal();
  }, [creatorOrganizationsList, selectedOrganization, dispatch, playlist, closeCloneModal]);

  const handleClone = useCallback(() => {
    if (creatorOrganizationsList.length > 1) {
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.OrganizationSelectMenu,
          componentProps: {
            onOrganizationSelect: (org: IOrganization) => {
              setSelectedOrganization(org);
              openCloneModal();
            },
            isCreator: true,
          },
        }),
      );
      return;
    }
    openCloneModal();
  }, [dispatch, openCloneModal, creatorOrganizationsList.length]);

  useEffect(() => {
    if (playlist && shareOnMount && !isShareShowedRef.current) {
      handleShare();
      isShareShowedRef.current = true;
    }
  }, [shareOnMount, handleShare, playlist]);

  const cloneModalDescription = useMemo(
    () =>
      selectedOrganization
        ? t('copyBiteModal.descriptionCloneToWorkspace', {
            biteName: playlist?.subject,
            workspaceName: selectedOrganization.name,
          })
        : t('copyBiteModal.description', {
            biteName: playlist?.subject,
          }),
    [playlist, selectedOrganization, t],
  );

  if (!playlist) {
    return <SsoAuthLoader />;
  }

  return (
    <S.Container>
      <S.WebViewWrap2>
        <S.WebViewWrap>
          <WebView url={currentMode === EPreviewMode.DASHBOARD ? dashboardUrl : url} />
          <PreviewControlPanel maxPages={maxPages} onClose={navigation.goBack}>
            <S.SwipePage>
              <StatisticsButton
                watched={playlist?.watched}
                participants={playlist?.participants}
                comments={playlist?.comments}
                onPress={toggleDashBoard}
                isDashboard={currentMode === EPreviewMode.DASHBOARD}
              />

              {isAllowEdit && (
                <S.EditButton
                  onPress={handleEdit}
                  //@ts-ignore
                  dataSet={playlistQuizEditButtonDataSet}
                >
                  <EditIcon width='28' height='28' style={S.Icon} color={theme.colors.primaryBlue} />
                  <S.ButtonText>{t('common.Edit')}</S.ButtonText>
                </S.EditButton>
              )}

              {isAllowSharing && (
                <ShareButton
                  disabled={!playlist?.sharing_mode}
                  onPress={handleShare}
                  width={(PAGE_WIDTH - calcWidth(10)) / 4}
                />
              )}
            </S.SwipePage>

            <S.SwipePage>
              {isCloneAvailable && <CopyButton onPress={handleClone} />}
              {isAllowEdit && <DeleteButton onPress={openDeleteModal} />}
            </S.SwipePage>
          </PreviewControlPanel>
        </S.WebViewWrap>
      </S.WebViewWrap2>
      <CreatorsName name={playlist?.creator_name} isQuiz={playlist?.is_quiz} />
      <BlueAndRedButtonsModal
        title={playlist?.is_quiz ? t('deleteQuizModal.title') : t('deletePlaylistModal.title')}
        description={playlist?.is_quiz ? t('deleteQuizModal.description') : t('deletePlaylistModal.description')}
        isVisible={isDeleteModalVisible}
        onClose={closeDeleteModal}
        onRightButtonClick={handleDeletePlaylist}
        onLeftButtonClick={closeDeleteModal}
        rightButtonLabel={t('deletePlaylistModal.delete')}
        leftButtonLabel={t('deletePlaylistModal.cancel')}
        dataSet={confirmDeletePlaylistQuizButtonDataSet}
      />
      <BlueAndRedButtonsModal
        title={playlist?.is_quiz ? t('cloneQuizModal.title') : t('clonePlaylistModal.title')}
        description={cloneModalDescription}
        isVisible={isCloneModalOpen}
        onClose={closeCloneModal}
        onRightButtonClick={handleCloneConfirmation}
        onLeftButtonClick={closeCloneModal}
        rightButtonLabel={t('copyBiteModal.clone')}
        leftButtonLabel={t('copyBiteModal.close')}
        reverseColors
      />
    </S.Container>
  );
};

const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap2: styled.View`
    flex-grow: 1;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  WebViewWrap: styled.View`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
  `,
  SwipePage: styled.View`
    flex-direction: row;
    justify-content: flex-end;
    height: ${calcHeight(55)}px;
    width: ${PAGE_WIDTH}px;
  `,
  ButtonText: styled.Text<{ color?: string }>`
    margin-top: ${calcHeight(2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11}
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.gray19}
  `,
  DeleteButton: styled.TouchableOpacity`
    height: ${calcHeight(53)}px;
    max-width: ${PAGE_WIDTH / 4}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    margin: 0 ${calcWidth(isWeb ? 8 : 2)}px;
    align-items: center;
    justify-content: center;
  `,
  EditButton: styled.TouchableOpacity`
    flex: 1;
    height: ${calcHeight(53)}px;
    min-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    max-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    margin: 0 ${calcWidth(2)}px;
    align-items: center;
    justify-content: center;
  `,
  Icon: {
    width: calcWidth(24),
    height: calcHeight(24),
  },
};

export default memo(PreviewPlaylist);
