import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EPanel, IState, Tabs } from './homeScreen.types';
import AuthTypes from '../auth/auth.types';

const initialState: IState = {
  activeTab: Tabs.FEED,
  activePanel: null,
};

const HOME_SCREEN = 'HOME_SCREEN';

const homeScreenSlice = createSlice({
  name: HOME_SCREEN,
  initialState,
  reducers: {
    setHomeActiveTab: (state, { payload }: PayloadAction<Tabs>) => {
      state.activeTab = payload;
    },
    setActivePanel: (state, { payload }: PayloadAction<EPanel | null>) => {
      state.activePanel = payload;
    },
    togglePanel: (state, { payload }: PayloadAction<EPanel | null>) => {
      const activePanel = state.activePanel;
      if (payload === activePanel) {
        state.activePanel = null;
        return;
      }
      state.activePanel = payload;
    },
  },
  extraReducers: {
    [AuthTypes.LOGOUT]: () => {
      return initialState;
    },
  },
});

export const { setHomeActiveTab, setActivePanel, togglePanel } = homeScreenSlice.actions;

export default homeScreenSlice.reducer;
