import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import BlueAndRedButtonsModal from '../BlueAndRedButtonsModal';
import { initializeNotificationsWithPermissionRequest } from '../../../services/notifications';
import { setRequestNotificationPermissionsModalShown } from '../../../store/appActivity/appActivity.slice';
import IconNotifications from '../../../assets/icons/icons-popup-notifications.svg';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const RequestNotificationPermissionsModal: React.FC<IProps> = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleRightButtonClick = useCallback(async () => {
    onClose();
    await initializeNotificationsWithPermissionRequest();
  }, [onClose]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    dispatch(setRequestNotificationPermissionsModalShown(true));
  }, [dispatch, isVisible]);

  const topIcon = useMemo(() => {
    return <IconNotifications fill='black' />;
  }, []);

  return (
    <BlueAndRedButtonsModal
      isVisible={isVisible}
      title={t('notificationPermissionModal.title')}
      description={t('notificationPermissionModal.description')}
      onRightButtonClick={handleRightButtonClick}
      rightButtonLabel={t('notificationPermissionModal.rightButton')}
      leftButtonLabel={t('notificationPermissionModal.leftButton')}
      onLeftButtonClick={onClose}
      reverseColors
      topIcon={topIcon}
    />
  );
};

export default RequestNotificationPermissionsModal;
