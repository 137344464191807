import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exploreBitesSelector } from '../../../../../store/explore/explore.selectors';

import { ONBOARDING_QUESTIONNAIRE_ID } from '../../../../../store/questionnaire/questionnaire.constants';
import {
  questionnaireSelector,
  userQuestionnaireByQuestionnaireIdSelector,
} from '../../../../../store/questionnaire/questionnaire.selectors';
import { loadOnboardingQuestionnaire } from '../../../../../store/questionnaire/questionnaire.slice';

const MIN_RESULTS = 9;

export enum ETabType {
  'USE_CASE' = 'USE_CASE',
  'INDUSTRY' = 'INDUSTRY',
}

const FEATURED_USE_CASE = 'featured';

const getQuestionOptionsMap = (questionnaire, questionId) => {
  return (
    questionnaire?.questions
      ?.find(({ id }) => id === questionId)
      ?.options?.reduce((map, { id }) => {
        map[id] = true;
        return map;
      }, {}) || {}
  );
};

const useInspirationBitesTabs = ({ searchStr = '' }) => {
  const dispatch = useDispatch();

  const { bites } = useSelector(exploreBitesSelector);

  const userQuestionnaire = useSelector(userQuestionnaireByQuestionnaireIdSelector(ONBOARDING_QUESTIONNAIRE_ID));
  const onboardingQuestionnaire = useSelector(questionnaireSelector(ONBOARDING_QUESTIONNAIRE_ID));

  const questionUseCaseOptionsMap = useMemo(
    () => getQuestionOptionsMap(onboardingQuestionnaire, 'use_cases'),
    [onboardingQuestionnaire],
  );
  const questionIndustryOptionsMap = useMemo(
    () => getQuestionOptionsMap(onboardingQuestionnaire, 'industry'),
    [onboardingQuestionnaire],
  );

  const userUseCases = useMemo(() => {
    let result = (userQuestionnaire?.answers.use_cases?.value as string[]) || [];
    result = Array.isArray(result) ? result : [result];
    result = result.filter((id) => questionUseCaseOptionsMap[id]);
    return result;
  }, [questionUseCaseOptionsMap, userQuestionnaire?.answers.use_cases?.value]);

  const userIndustries = useMemo(() => {
    let result = (userQuestionnaire?.answers.industry?.value as string[]) || [];
    result = Array.isArray(result) ? result : [result];
    result = result.filter((id) => questionIndustryOptionsMap[id]);
    return result;
  }, [questionIndustryOptionsMap, userQuestionnaire?.answers.industry?.value]);

  const {
    tabs,
    initialSelectedTabs,
    initialSelectedUseCasesMap,
    initialSelectedIndustriesMap,
    useCaseBitesMap,
    industryBitesMap,
  } = useMemo(() => {
    const preselectedTabs = [];
    const useCasesTabs = [];
    const industryTabs = [];

    const result = {
      tabs: [], // all the tabs

      // array to display the selected tab results
      initialSelectedTabs: [],

      // object to highlight the tabs
      initialSelectedUseCasesMap: {},
      initialSelectedIndustriesMap: {},

      // map of bites by tabs
      useCaseBitesMap: {},
      industryBitesMap: {},
    };

    const addUseCaseTab = (id, targetTabs) => {
      targetTabs.push({
        type: ETabType.USE_CASE,
        id,
      });
      useCaseTabsMap[id] = true;
    };
    const addIndustryTab = (id, targetTabs) => {
      targetTabs.push({
        type: ETabType.INDUSTRY,
        id,
      });
      industryTabsMap[id] = true;
    };
    const setUseCaseSelected = (id) => {
      result.initialSelectedTabs.push({
        type: ETabType.USE_CASE,
        id,
      });

      result.initialSelectedUseCasesMap[id] = true;
    };

    const setIndustrySelected = (id) => {
      result.initialSelectedTabs.push({
        type: ETabType.INDUSTRY,
        id,
      });

      result.initialSelectedIndustriesMap[id] = true;
    };

    const useCaseTabsMap = {};
    const industryTabsMap = {};

    bites.forEach((biteShare) => {
      (biteShare.bite_use_case_tags || []).forEach((useCase) => {
        result.useCaseBitesMap[useCase] = result.useCaseBitesMap[useCase] || [];
        result.useCaseBitesMap[useCase].push(biteShare);
      });

      (biteShare.bite_industry_tags || []).forEach((industry) => {
        result.industryBitesMap[industry] = result.industryBitesMap[industry] || [];
        result.industryBitesMap[industry].push(biteShare);
      });
    });

    [FEATURED_USE_CASE, ...userUseCases].forEach((id) => {
      if (!result.useCaseBitesMap[id]) {
        return;
      }
      addUseCaseTab(id, preselectedTabs);
      setUseCaseSelected(id);
    });

    userIndustries.forEach((id) => {
      if (!result.industryBitesMap[id]) {
        return;
      }
      addIndustryTab(id, preselectedTabs);
      setIndustrySelected(id);
    });

    const resultsMap = {};
    const results = [];

    bites.forEach((biteShare) => {
      (biteShare.bite_use_case_tags || []).forEach((useCase) => {
        if (!useCaseTabsMap[useCase]) {
          addUseCaseTab(useCase, useCasesTabs);
        }

        if (result.initialSelectedUseCasesMap[useCase] && !resultsMap[biteShare.id]) {
          results.push(biteShare);
          resultsMap[biteShare.id] = true;
        }
      });

      (biteShare.bite_industry_tags || []).forEach((industry) => {
        if (!industryTabsMap[industry]) {
          addIndustryTab(industry, industryTabs);
        }

        if (result.initialSelectedIndustriesMap[industry] && !resultsMap[biteShare.id]) {
          results.push(biteShare);
          resultsMap[biteShare.id] = true;
        }
      });
    });

    result.tabs = [...preselectedTabs, ...useCasesTabs, ...industryTabs];

    const addToResults = (biteShare) => {
      if (resultsMap[biteShare.id]) {
        return;
      }
      results.push(biteShare);
      resultsMap[biteShare.id] = true;
    };

    if (results.length < MIN_RESULTS) {
      result.tabs.some(({ id, type }) => {
        if (type === ETabType.USE_CASE && !result.initialSelectedUseCasesMap[id]) {
          setUseCaseSelected(id);
          result.useCaseBitesMap[id].forEach(addToResults);
        }

        if (type === ETabType.INDUSTRY && !result.initialSelectedIndustriesMap[id]) {
          setIndustrySelected(id);
          result.industryBitesMap[id].forEach(addToResults);
        }

        return results.length >= MIN_RESULTS;
      });
    }

    return result;
  }, [bites, userIndustries, userUseCases]);

  const [selectedTabs, setSelectedTabs] = useState(initialSelectedTabs); // array to display the selected tab results
  const [selectedUseCasesMap, setSelectedUseCasesMap] = useState(initialSelectedUseCasesMap); // object to highlight the tabs
  const [selectedIndustriesMap, setSelectedIndustriesMap] = useState(initialSelectedIndustriesMap); // map of bites by tabs

  useEffect(() => {
    setSelectedTabs(initialSelectedTabs);
  }, [initialSelectedTabs]);

  useEffect(() => {
    setSelectedUseCasesMap(initialSelectedUseCasesMap);
  }, [initialSelectedUseCasesMap]);

  useEffect(() => {
    setSelectedIndustriesMap(initialSelectedIndustriesMap);
  }, [initialSelectedIndustriesMap]);

  const selectAllTabs = useCallback(() => {
    setSelectedTabs([]);
    setSelectedUseCasesMap({});
    setSelectedIndustriesMap({});
  }, []);

  const addTabToSelected = useCallback(
    ({ type, id }) => {
      const newSelectedTabs = selectedUseCasesMap[FEATURED_USE_CASE]
        ? [
            {
              type: ETabType.USE_CASE,
              id: FEATURED_USE_CASE,
            },
            { type, id },
            ...selectedTabs.filter((tab) => !(tab.type === ETabType.USE_CASE && tab.id === FEATURED_USE_CASE)),
          ]
        : [{ type, id }, ...selectedTabs];
      setSelectedTabs(newSelectedTabs);
    },
    [selectedTabs, selectedUseCasesMap],
  );

  const toggleTabIsSelected = useCallback(
    ({ type, id }) => {
      if (type === ETabType.USE_CASE) {
        if (selectedUseCasesMap[id]) {
          const newSelectedTabs = selectedTabs.filter((tab) => tab.id !== id);
          setSelectedTabs(newSelectedTabs);

          const newSelectedUseCasesMap = { ...selectedUseCasesMap };
          delete newSelectedUseCasesMap[id];
          setSelectedUseCasesMap(newSelectedUseCasesMap);
          return;
        }
        if (tabs.length === selectedTabs.length + 1) {
          selectAllTabs();
          return;
        }

        addTabToSelected({ type, id });

        const newSelectedUseCasesMap = { ...selectedUseCasesMap, [id]: true };
        setSelectedUseCasesMap(newSelectedUseCasesMap);

        return;
      }

      if (selectedIndustriesMap[id]) {
        const newSelectedTabs = selectedTabs.filter((tab) => tab.id !== id);
        setSelectedTabs(newSelectedTabs);

        const newSelectedIndustriesMap = { ...selectedIndustriesMap };
        delete newSelectedIndustriesMap[id];
        setSelectedIndustriesMap(newSelectedIndustriesMap);
        return;
      }
      if (tabs.length === selectedTabs.length + 1) {
        selectAllTabs();
        return;
      }

      addTabToSelected({ type, id });

      const newSelectedIndustriesMap = { ...selectedIndustriesMap, [id]: true };
      setSelectedIndustriesMap(newSelectedIndustriesMap);
    },
    [addTabToSelected, selectAllTabs, selectedIndustriesMap, selectedTabs, selectedUseCasesMap, tabs.length],
  );

  const results = useMemo(() => {
    if (searchStr) {
      const filteredResults = bites.filter((biteShare) =>
        biteShare.subject.toLowerCase().includes(searchStr.toLowerCase()),
      );
      return filteredResults;
    }

    if (selectedTabs.length === 0) {
      return [
        ...(useCaseBitesMap[FEATURED_USE_CASE] || []),
        ...bites.filter((biteShare) => !biteShare.bite_use_case_tags.includes(FEATURED_USE_CASE)),
      ];
    }

    const resultsMap = {};
    const tabResults = [];

    selectedTabs.forEach(({ type, id }) => {
      if (type === ETabType.USE_CASE) {
        useCaseBitesMap[id]?.forEach((biteShare) => {
          if (resultsMap[biteShare.id]) {
            return;
          }
          tabResults.push(biteShare);
          resultsMap[biteShare.id] = true;
        });

        return;
      }

      industryBitesMap[id]?.forEach((biteShare) => {
        if (resultsMap[biteShare.id]) {
          return;
        }
        tabResults.push(biteShare);
        resultsMap[biteShare.id] = true;
      });
    });

    return tabResults;
  }, [bites, industryBitesMap, searchStr, selectedTabs, useCaseBitesMap]);

  useEffect(() => {
    dispatch(loadOnboardingQuestionnaire());
  }, [dispatch]);

  return {
    tabs,
    selectedUseCasesMap,
    selectedIndustriesMap,
    results,
    selectAllTabs,
    toggleTabIsSelected,
  };
};

export default useInspirationBitesTabs;
