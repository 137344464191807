import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { IStackNavigation } from '../../../navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import { resetCreatePlaylistState } from '../../../store/createPlaylist/createPlaylist.actions';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';
import { playlistSelector } from '../../../store/playlist/playlist.selectors';
import {
  postQuizSettings,
  resetQuizSettings,
  updateQuizBooleanSettings,
  updateQuizColorSettings,
} from '../../../store/playlist/playlist.actions';
import { CustomText, Switch } from '../../../components/shared';
import ColorSettingsModal from '../../../components/modals/ColorSettingsModal';
import { useCallback } from 'react';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import GenericButton from '../../../components/shared/buttons/GenericButton';

interface IProps extends IStackNavigation {}

const QuizSettings: FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const localePrefix = 'createQuizStack.quizSettings';
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [questionColor, setQuestionColor] = useState('');
  const { quizSettings } = useSelector(playlistSelector);
  const { isPosting, postOrUpdateSuccess, editMode, editedPlaylist } = useSelector(createPlaylistSelector);

  const toggleCorrectAnswer = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'quiz_change_toggle',
        props: {
          quiz_id: editedPlaylist?.id,
          toggle_type: 'correct_answer_indication',
          is_on: !quizSettings?.correct_answer_indication,
        },
      }),
    );
    dispatch(updateQuizBooleanSettings('correct_answer_indication'));
  }, [dispatch, editedPlaylist?.id, quizSettings?.correct_answer_indication]);

  const toggleShowReport = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'quiz_change_toggle',
        props: {
          quiz_id: editedPlaylist?.id,
          toggle_type: 'show_report',
          is_on: !quizSettings?.show_report,
        },
      }),
    );
    dispatch(updateQuizBooleanSettings('show_report'));
  }, [dispatch, editedPlaylist?.id, quizSettings?.show_report]);

  const handleBackgroundColorChange = (activeColor: string) => {
    setBackgroundColor(activeColor);
    dispatch(updateQuizColorSettings('background_color', activeColor));
  };

  const handleQuestionColorChange = (activeColor: string) => {
    setQuestionColor(activeColor);
    dispatch(updateQuizColorSettings('question_color', activeColor));
  };

  const handleColorSettingsPress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'change_quiz_background',
        props: {
          quiz_id: editedPlaylist?.id,
        },
      }),
    );
    setIsModalOpen(true);
  }, [dispatch, editedPlaylist?.id]);

  const handleContinue = useCallback(() => {
    dispatch(postQuizSettings());
    navigation.goBack();
  }, [dispatch, navigation]);

  useEffect(() => {
    if (postOrUpdateSuccess) {
      dispatch(resetQuizSettings());
      navigation.popToTop();

      if (navigation.canGoBack()) {
        navigation.goBack();
      }

      dispatch(resetCreatePlaylistState());
    }
  }, [dispatch, navigation, postOrUpdateSuccess]);

  useEffect(() => {
    if (quizSettings?.background_color) {
      setBackgroundColor(quizSettings?.background_color);
    }
    if (quizSettings?.question_color) {
      setQuestionColor(quizSettings?.question_color);
    }
  }, [quizSettings]);

  return (
    <>
      <S.Container>
        <S.TitleText>{t('createQuizStack.navTitles.settings')}</S.TitleText>
        <S.ContentWrapper>
          <S.ItemsContainer>
            <S.ColorSettingsBtn onPress={handleColorSettingsPress}>
              <S.SettingText>{t(`${localePrefix}.colorSettings`)}</S.SettingText>
            </S.ColorSettingsBtn>
            <S.SettingsItem>
              <S.SettingText>{t(`${localePrefix}.correctAnswer`)}</S.SettingText>
              <Switch isOn={quizSettings?.correct_answer_indication} onToggle={toggleCorrectAnswer} />
            </S.SettingsItem>
            <S.SettingsItem>
              <S.SettingText>{t(`${localePrefix}.showReport`)}</S.SettingText>
              <Switch isOn={quizSettings?.show_report} onToggle={toggleShowReport} />
            </S.SettingsItem>
          </S.ItemsContainer>
          <S.Continue
            content={t(`common.${editMode ? 'save' : 'done'}`)}
            isLoading={isPosting}
            disabled={isPosting || !quizSettings}
            onPress={handleContinue}
          />
        </S.ContentWrapper>
      </S.Container>
      <ColorSettingsModal
        isVisible={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        backgroundColor={backgroundColor}
        questionColor={questionColor}
        handleBackgroundColorChange={handleBackgroundColorChange}
        handleQuestionColorChange={handleQuestionColorChange}
      />
    </>
  );
};

const Container = styled.View`
  flex: 1;
`;
const TitleText = styled.Text`
  font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.s26};
`;
const ContentWrapper = styled.View`
  width: 100%;
  flex: 1;
  padding-right: ${calcWidth(30)}px;
  padding-left: ${calcWidth(30)}px;
  align-items: center;
  padding-top: ${calcHeight(100)};
`;

const Continue = styled(GenericButton)`
  margin-bottom: ${calcHeight(45)};
  width: ${() => (isWeb ? '200px' : 'auto')};
`;
const ItemsContainer = styled.View`
  width: 100%;
  flex: 1;
  margin-top: ${calcHeight(32)}px;
  margin-bottom: ${calcHeight(32)}px;
`;

const SettingsItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${calcHeight(8)}px;
  padding-bottom: ${calcHeight(8)}px;
  border-color: ${({ theme }) => theme.colors.lightGray1};
  border-bottom-width: 1px;
`;

const SettingText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.darkGray1};
  `,
);

const SwitchContainer = styled.View`
  transform: scale(0.75);
`;

const ColorSettingsBtn = styled.TouchableOpacity`
  width: 100%;
  height: ${calcHeight(48)}px;
  margin-bottom: ${calcHeight(8)}px;
  border-radius: ${calcWidth(48)}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.primaryBlue};
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  align-items: center;

  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      background-color: ${theme.colors.darkGray3};
      opacity: 0.58;
    `}
  `}
`;

const S = {
  Container,
  ColorSettingsBtn,
  ContentWrapper,
  Continue,
  ItemsContainer,
  SettingsItem,
  SwitchContainer,
  SettingText,
  TitleText,
};

export default QuizSettings;
