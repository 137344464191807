import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import ShadowedContainer from './ShadowedContainer';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../utils/dimensions';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import BackButton from './shared/BackButton';
import { backButtonDataSet } from './shared/BackButton/BackButton.constants';

interface IProps {
  onBackButtonPress?: () => void;
  style?: StyleProp<ViewStyle>;
  headerRight?: (props?: { tintColor?: string }) => React.ReactNode;
  title?: string | ((props?: any) => React.ReactNode);
  withShadow?: boolean;
  headerLeft?: (props?: any) => React.ReactNode;
}

const Header: React.FC<IProps> = ({ title, headerLeft, headerRight, onBackButtonPress, style, withShadow }) => {
  const insets = useSafeAreaInsets();

  const render = useCallback(
    () => (
      <S.Header style={style} insets={insets}>
        {headerLeft ? headerLeft() : <BackButton onPress={onBackButtonPress} dataSet={backButtonDataSet} />}
        {title && (typeof title === 'function' ? title() : <S.Title>{title}</S.Title>)}
        {headerRight ? headerRight() : <S.EmptyView />}
      </S.Header>
    ),
    [onBackButtonPress, headerLeft, headerRight, insets, style, title],
  );

  if (withShadow) {
    return <ShadowedContainer>{render()}</ShadowedContainer>;
  }
  return render();
};

const S = {
  Header: styled.View<{ insets: { top: number } }>`
    z-index: 4;
    position: relative;
    min-height: ${calcHeight(isWeb ? 80 : 100)}px;
    max-height: ${calcHeight(isWeb ? 80 : 100)}px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${({ insets }) => calcHeight(isWeb ? 0 : insets.top)}px ${calcWidth(16)}px ${calcHeight(9)}px
      ${calcWidth(16)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.text};
    flex-direction: column;
  `,
  TitleSecondary: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    color: ${({ theme }) => theme.colors.gray19};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    justify-content: center;
    text-align: center;
  `,
  EmptyView: styled.View`
    height: ${calcHeight(40)}px;
    width: ${calcWidth(40)}px;
  `,
};

export default Header;
