import React, { memo } from 'react';
import styled, { useTheme } from 'styled-components/native';
import Animated, { useAnimatedStyle, withTiming, interpolate, Easing } from 'react-native-reanimated';
import { calcHeight } from '../../../../utils/dimensions';

interface IProps {
  total: number;
  current: number;
  width?: number;
  duration?: number;
}
const LoadingBar: React.FC<IProps> = ({ total, current, width = 106, duration = 500 }) => {
  const theme = useTheme();

  const animatedStyle = useAnimatedStyle(() => {
    return {
      zIndex: 2,
      height: '100%',
      backgroundColor: theme.colors.primaryBlue,
      width: withTiming(interpolate(current, [0, total], [0, width]), { duration, easing: Easing.ease }),
      borderRadius: 2,
    };
  });

  return (
    <S.Container width={width}>
      <Animated.View style={animatedStyle} />
    </S.Container>
  );
};

const S = {
  Text: styled.Text``,
  Container: styled.View<{ width: number }>`
    width: ${({ width }) => width}px;
    height: ${calcHeight(4)}px;
    background-color: ${({ theme }) => theme.colors.lightGray34};
    border-radius: 2px;
    overflow: hidden;
  `,
};

export default memo(LoadingBar);
