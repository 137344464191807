import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  copiedTimelineItemSelector,
  currentSecondsSeletor,
  isPlayingSeletor,
  isSplitModeSeletor,
  processingTimelineItemSelector,
  recordingTimelineItemIdSelector,
  redoStackSelector,
  selectedTimelineItemIdSelector,
  selectedTimelineItemLayerSelector,
  timelineItemSeletor,
  timelineScaleSeletor,
  undoStackSelector,
  videoDurationSeletor,
} from '../../store/videoEditor/videoEditor.selectors';
import {
  addToTimeline,
  addUndo,
  clearStateForTimelineItem,
  pasteTimelineItem,
  playVideo,
  redo,
  refreshCanvas,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  saveVideo,
  setCopiedTimelineItem,
  setIsPlaying,
  setIsSplitMode,
  setSelectedTimelineItemId,
  setTimelineScale,
  undo,
  updateVideoDuration,
} from '../../store/videoEditor/videoEditor.slice';
import { v4 as uuid } from 'uuid';
import { cloneCloudAssetCacheForTimelineItem } from '../../store/cloudAssets/cloudAssets.slice';
import { TIMELINE_CONTROLS_HEIGHT } from './videoEditor.constants';
import defaultTheme from '../../themes/defaultTheme';
import UndoIcon from '../../assets/icons/videoEditor/undo.svg';
import RedoIcon from '../../assets/icons/videoEditor/redo.svg';
import DeleteIcon from '../../assets/icons/videoEditor/delete.svg';
import DuplicateIcon from '../../assets/icons/videoEditor/duplicate.svg';
import SplitIcon from '../../assets/icons/videoEditor/split.svg';
import PlayIcon from '../../assets/icons/videoEditor/play.svg';
import PauseIcon from '../../assets/icons/videoEditor/pause.svg';
import RangeIcon from '../../assets/icons/videoEditor/range.svg';
import { getDisplayTimeFromDuration } from './utils/getDisplayTimeFromDuration';

const Controls = () => {
  const dispatch = useDispatch();

  const isPlaying = useSelector(isPlayingSeletor);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const selectedTimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const selectedTimelineItemProcessingStatus = useSelector(processingTimelineItemSelector(selectedTimelineItemId));
  const isSplitMode = useSelector(isSplitModeSeletor);
  const timelineScale = useSelector(timelineScaleSeletor);
  const copiedTimelineItem = useSelector(copiedTimelineItemSelector);
  const currentSeconds = useSelector(currentSecondsSeletor);
  const undoStack = useSelector(undoStackSelector);
  const redoStack = useSelector(redoStackSelector);
  const recordingTimelineItemId = useSelector(recordingTimelineItemIdSelector);
  const duration = useSelector(videoDurationSeletor);
  const selectedTimelineItemLayer = useSelector(selectedTimelineItemLayerSelector);

  const handleTogglePlay = useCallback(() => {
    if (isPlaying) {
      dispatch(setIsPlaying(false));
      return;
    }

    dispatch(playVideo());
  }, [dispatch, isPlaying]);

  const handleDelete = useCallback(() => {
    dispatch(setSelectedTimelineItemId(null));
    dispatch(
      clearStateForTimelineItem({
        timelineItemId: selectedTimelineItemId,
      }),
    );
    dispatch(
      removeTimelineItem({
        timelineItemId: selectedTimelineItemId,
        fromTimeline: true,
      }),
    );
    dispatch(removeEmptyTimelineLayers());
    dispatch(updateVideoDuration());
    dispatch(refreshCanvas());
    dispatch(addUndo({}));
    dispatch(saveVideo({}));
  }, [dispatch, selectedTimelineItemId]);

  const handleDuplicate = useCallback(() => {
    const timelineItem = {
      ...selectedTimelineItem,
      id: uuid(),
    };

    dispatch(
      cloneCloudAssetCacheForTimelineItem({
        timelineItem,
      }),
    );

    dispatch(
      addToTimeline({
        timelineItem,
        layerId: selectedTimelineItemLayer?.id,
        start: selectedTimelineItem.end,
      }),
    );

    dispatch(addUndo({}));
    dispatch(saveVideo({}));
  }, [dispatch, selectedTimelineItem, selectedTimelineItemLayer?.id]);

  const handleSplit = useCallback(() => {
    // reset
    dispatch(setIsPlaying(false));
    dispatch(setSelectedTimelineItemId(null));

    dispatch(setIsSplitMode(!isSplitMode));

    dispatch(addUndo({}));
    dispatch(saveVideo({}));
  }, [dispatch, isSplitMode]);

  const handleChangeTimelineScale = useCallback(
    (event) => {
      dispatch(setIsPlaying(false));
      dispatch(setTimelineScale(Number(event.target.value)));
    },
    [dispatch],
  );

  const handleUndo = useCallback(() => {
    dispatch(undo());
  }, [dispatch]);

  const handleRedo = useCallback(() => {
    dispatch(redo());
  }, [dispatch]);

  const keydownRef = useRef(false);
  useEffect(() => {
    const keyupHandler = function (event) {
      // original target is input or textarea
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return;
      }

      keydownRef.current = false;

      if (event.key === ' ') {
        handleTogglePlay();
      }
      // delete on click delete or backspace
      if (event.key === 'Delete' || event.key === 'Backspace') {
        handleDelete();
        dispatch(addUndo({}));
        dispatch(saveVideo({}));
      }
      // escape
      if (event.key === 'Escape') {
        dispatch(setSelectedTimelineItemId(null));
        dispatch(setIsSplitMode(false));
      }
    };

    const keydownHandler = function (event) {
      // original target is input or textarea
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        return;
      }

      // ctrl/cmd + d
      if ((event.ctrlKey || event.metaKey) && event.key === 'd') {
        if (!selectedTimelineItem) {
          return;
        }

        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
        dispatch(removeEmptyTimelineLayers());
        dispatch(updateVideoDuration());
        dispatch(refreshCanvas());
        dispatch(setSelectedTimelineItemId(null));
        dispatch(addUndo({}));
        dispatch(saveVideo({}));
      }

      // ctrl/cmd + x
      if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
        if (
          !selectedTimelineItem ||
          selectedTimelineItemProcessingStatus?.error ||
          selectedTimelineItemProcessingStatus?.isProcessing
        ) {
          return;
        }

        dispatch(setCopiedTimelineItem(selectedTimelineItem));
        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
        dispatch(removeEmptyTimelineLayers());
        dispatch(updateVideoDuration());
        dispatch(addUndo({}));
        dispatch(saveVideo({}));
      }

      // ctrl/cmd + c
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        if (
          !selectedTimelineItem ||
          selectedTimelineItemProcessingStatus?.error ||
          selectedTimelineItemProcessingStatus?.isProcessing
        ) {
          return;
        }

        dispatch(setCopiedTimelineItem(selectedTimelineItem));
      }

      // ctrl/cmd + v
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        if (!copiedTimelineItem || keydownRef.current) {
          return;
        }

        dispatch(pasteTimelineItem());

        keydownRef.current = true;
      }

      // ctrl/cmd + z
      if ((event.ctrlKey || event.metaKey) && !event.shiftKey && event.key === 'z') {
        dispatch(undo());
      }

      // ctrl + y
      if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
        dispatch(redo());
      }

      // shift + ctrl/cmd + z
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
        dispatch(redo());
      }
    };

    document.addEventListener('keyup', keyupHandler);
    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keyup', keyupHandler);
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [
    copiedTimelineItem,
    currentSeconds,
    dispatch,
    handleDelete,
    handleTogglePlay,
    selectedTimelineItem,
    selectedTimelineItemId,
    selectedTimelineItemProcessingStatus?.error,
    selectedTimelineItemProcessingStatus?.isProcessing,
  ]);

  const [time, setTime] = useState(currentSeconds);

  const timeIntervalRef = useRef<any>(null);
  useEffect(() => {
    if (!isPlaying) {
      setTime(currentSeconds);
      return;
    }

    timeIntervalRef.current = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(timeIntervalRef.current);
    };
  }, [currentSeconds, isPlaying]);

  return (
    <S.Container>
      <S.Left>
        <S.Control
          isEnabled={!recordingTimelineItemId && undoStack.length > 0}
          onClick={!recordingTimelineItemId && undoStack.length > 0 ? handleUndo : undefined}
        >
          <UndoIcon />
          <S.ControlText>Undo</S.ControlText>
        </S.Control>

        <S.Separator />

        <S.Control
          isEnabled={!recordingTimelineItemId && redoStack.length > 0}
          onClick={!recordingTimelineItemId && redoStack.length > 0 ? handleRedo : undefined}
        >
          <RedoIcon />
          <S.ControlText>Redo</S.ControlText>
        </S.Control>

        {/* {!isPlaying && <S.Control onClick={handleRecordAudio}>Rec</S.Control>} */}

        <S.Control
          isEnabled={!recordingTimelineItemId && !!selectedTimelineItemId}
          onClick={!recordingTimelineItemId && selectedTimelineItemId ? handleDelete : undefined}
        >
          <DeleteIcon stroke='#313235' width='20' height='22' />
          <S.ControlText>Delete</S.ControlText>
        </S.Control>
        <S.Separator />
        <S.Control
          isEnabled={!recordingTimelineItemId && !!selectedTimelineItemId}
          onClick={selectedTimelineItemId ? handleDuplicate : undefined}
        >
          <DuplicateIcon />
          <S.ControlText>Duplicate</S.ControlText>
        </S.Control>
        <S.Separator />

        <S.Control
          isEnabled={!recordingTimelineItemId && duration > 0}
          isActive={isSplitMode}
          onClick={!recordingTimelineItemId && duration > 0 ? handleSplit : undefined}
        >
          <SplitIcon stroke={isSplitMode ? defaultTheme.colors.primaryBlue : defaultTheme.colors.black} />
          <S.ControlText>{isSplitMode ? 'Splitting' : 'Split'}</S.ControlText>
        </S.Control>
      </S.Left>
      <S.Center isEnabled={duration > 0}>
        <S.PlayControl isEnabled={duration > 0} onClick={duration > 0 ? handleTogglePlay : undefined}>
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
          <S.PlayControlText>{isPlaying ? 'Pause' : 'Play'}</S.PlayControlText>
        </S.PlayControl>
        <S.PlayTime>
          <S.TimeText>{getDisplayTimeFromDuration(time)}</S.TimeText>
          <S.Separator />
          <S.TimeText>{getDisplayTimeFromDuration(duration)}</S.TimeText>
        </S.PlayTime>
      </S.Center>
      <S.Right>
        <S.RangeControl>
          <RangeIcon />
          <S.RangeControlInput>
            <input type='range' min={0} max={100} step={1} value={timelineScale} onInput={handleChangeTimelineScale} />
          </S.RangeControlInput>
        </S.RangeControl>
      </S.Right>
    </S.Container>
  );
};
export default Controls;

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: 4px 8px;
    width: 100%;
    height: ${TIMELINE_CONTROLS_HEIGHT}px;
    border-bottom: 1px solid ${defaultTheme.colors.lightGray34};
    background-color: ${defaultTheme.colors.white};
  `,
  Left: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    width: 30%;
  `,
  Center: styled.div<{ isEnabled: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};

    ${({ isEnabled }) =>
      isEnabled
        ? css`
            &:hover {
              background-color: ${defaultTheme.colors.lightGray44};
            }
          `
        : ''}
  `,
  Right: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin-right: 32px;
    width: 30%;
  `,
  Separator: styled.div`
    width: 1px;
    height: 24px;
    background-color: ${defaultTheme.colors.lightGray45};
  `,
  Control: styled.div<{ isEnabled: boolean; isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 12px;
    color: ${({ isActive }) => (isActive ? defaultTheme.colors.primaryBlue : defaultTheme.colors.lightGray46)};
    border-radius: 8px;
    cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'default')};
    opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};

    ${({ isEnabled, isActive }) =>
      isEnabled
        ? css`
            &:hover {
              color: ${isActive ? defaultTheme.colors.primaryBlue : defaultTheme.colors.black};
              background-color: ${defaultTheme.colors.lightGray44};
            }
          `
        : ''}
  `,
  RangeControl: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin: 4px 8px;
    padding: 0 12px;
  `,
  ControlText: styled.div`
    margin-left: 6px;
    font-size: 12px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    white-space: nowrap;
  `,
  TimeText: styled.div`
    color: ${defaultTheme.colors.lightGray46};
    font-size: 12px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    white-space: nowrap;
  `,
  RangeControlInput: styled.div`
    margin-left: 12px;
  `,
  PlayControl: styled.div<{ isEnabled: boolean }>`
    position: absolute;
    right: -100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    margin-right: 3px;
    padding: 0 12px;
    height: 100%;
    color: ${defaultTheme.colors.lightGray46};
    border-radius: 8px;
    cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'default')};

    ${({ isEnabled }) =>
      isEnabled
        ? css`
            &:hover {
              color: ${defaultTheme.colors.black};
              background-color: ${defaultTheme.colors.lightGray44};
            }
          `
        : ''}
  `,
  PlayControlText: styled.div`
    font-size: 12px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    white-space: nowrap;
  `,
  PlayTime: styled.div`
    position: absolute;
    left: -100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    margin-left: 3px;
  `,
};
