import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  processingTimelineItemsSelector,
  videoTimelineLayersSeletor,
} from '../../store/videoEditor/videoEditor.selectors';
import defaultTheme from '../../themes/defaultTheme';
import { ActivityIndicator } from 'react-native';
import { retryFunctions } from '../../store/videoEditor/videoEditor.data';
import DeleteIcon from '../../assets/icons/videoEditor/delete.svg';
import CheckmarkIcon from '../../assets/icons/checkmark.svg';
import {
  addUndo,
  clearStateForTimelineItem,
  refreshCanvas,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  saveVideo,
  setSelectedTimelineItemId,
  updateVideoDuration,
} from '../../store/videoEditor/videoEditor.slice';
import { MAX_ASSET_FILE_SIZE_MB } from './videoEditor.constants';

const ProcessingStatus = ({}) => {
  const processingTimelineItems = useSelector(processingTimelineItemsSelector);

  if (processingTimelineItems.length === 0) {
    return null;
  }

  return (
    <>
      <S.Container>
        {processingTimelineItems.map((processing) => (
          <ProcessingItem processing={processing} key={processing.timelineItemId} />
        ))}
      </S.Container>
    </>
  );
};
export default ProcessingStatus;

const ProcessingItem = ({ processing }) => {
  const dispatch = useDispatch();

  const timelineLayers = useSelector(videoTimelineLayersSeletor);

  const isOnTimeline = useMemo(() => {
    return timelineLayers.some((layer) => layer.timeline.includes(processing.timelineItemId));
  }, [timelineLayers, processing.timelineItemId]);

  const handleDelete = useCallback(() => {
    dispatch(setSelectedTimelineItemId(null));
    dispatch(
      clearStateForTimelineItem({
        timelineItemId: processing.timelineItemId,
      }),
    );
    dispatch(
      removeTimelineItem({
        timelineItemId: processing.timelineItemId,
        fromTimeline: true,
      }),
    );
    dispatch(removeEmptyTimelineLayers());
    dispatch(updateVideoDuration());
    dispatch(refreshCanvas());
    dispatch(addUndo({}));
    dispatch(saveVideo({}));
  }, [dispatch, processing.timelineItemId]);

  if (!isOnTimeline) {
    return null;
  }

  return (
    <S.ProcessingItem key={processing.timelineItemId}>
      <S.Label>{processing.label}</S.Label>
      {processing.isProcessing && <ActivityIndicator />}
      {processing.error &&
        (processing.error.code === 'FILE_SIZE_LIMIT_EXCEEDED' ? (
          <S.Error>
            File too large ({processing.error.details.sizeMb}MB, max {MAX_ASSET_FILE_SIZE_MB}MB)
          </S.Error>
        ) : (
          <S.Error
            onClick={() => {
              if (processing.retryFunctionId) {
                retryFunctions[processing.retryFunctionId]?.();
              }
            }}
          >
            Error. Click to retry
          </S.Error>
        ))}
      {processing.isDone ? (
        <S.CheckContainer>
          <CheckmarkIcon />
        </S.CheckContainer>
      ) : (
        <S.DeleteContainer onClick={handleDelete}>
          <DeleteIcon stroke='black' width={20 * 0.8} height={22 * 0.8} />
        </S.DeleteContainer>
      )}
    </S.ProcessingItem>
  );
};

const S = {
  Container: styled.div`
    position: relative;
    background-color: ${defaultTheme.colors.lightGray43};
  `,
  ProcessingItem: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 8px;
    height: 36px;
  `,
  Label: styled.div`
    flex: 1;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
  Error: styled.div<{
    onClick?: () => void;
  }>`
    color: ${defaultTheme.colors.failRed};
    font-size: 12px;
    font-weight: 500;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    text-decoration: ${({ onClick }) => (onClick ? 'underline' : 'none')};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  `,
  DeleteContainer: styled.div`
    padding: 6px 8px 4px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray45};
    }
  `,
  CheckContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
  `,
};
