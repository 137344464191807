import BitesApi from '../index';
import { ESharedWithFilter } from '../../../attributes/attributes.types';

interface IAnalyticsRequestData {
  orgId: number;
  biteIds: number[];
  userIds?: number[];
  answerIds?: number[];
  next?: string;
  filter?: string;
  attributeValueIds?: number[];
  pageSize?: number;
  sharedWith?: ESharedWithFilter[];
  allowedDataOnly: boolean;
}

export const getBiteDistributions = async ({ orgId, biteId }) =>
  BitesApi.get(`distribution/${orgId}/bite/${biteId}/distributions`);

export const getViewsNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/views/`, {
    params: { bite_ids: JSON.stringify(biteIds), allowed_data_only: allowedDataOnly || undefined },
  });

export const getCommentsNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/comments/`, {
    params: { bite_ids: JSON.stringify(biteIds), allowed_data_only: allowedDataOnly || undefined },
  });

export const getAnswersNumber = async ({
  orgId,
  biteIds,
  allowedDataOnly,
}: {
  orgId: number;
  biteIds: string[];
  allowedDataOnly: boolean;
}) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/answers/`, {
    params: { bite_ids: JSON.stringify(biteIds), allowed_data_only: allowedDataOnly || undefined },
  });

export const getOverviewList = async ({
  orgId,
  biteIds,
  filter,
  attributeValueIds,
  next,
  sharedWith,
  allowedDataOnly,
}: IAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/overview/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      engaged: filter,
    },
  });

export const getViewsList = async ({
  orgId,
  biteIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/views/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      viewed: filter,
      page_size: pageSize,
    },
  });

export const getAnswersList = async ({
  orgId,
  biteIds,
  userIds,
  answerIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/answers/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      user_ids: userIds?.length ? JSON.stringify(userIds) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      answered: filter,
      page_size: pageSize,
    },
  });

export const getAnswersAggregated = async ({ orgId, biteId, attributeValueIds, sharedWith, allowedDataOnly }) =>
  BitesApi.get(`/analytics/${orgId}/aggregated/answers/${biteId}/`, {
    params: {
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
    },
  });

export const getCommentsList = async ({
  orgId,
  biteIds,
  userIds,
  answerIds,
  filter,
  next,
  attributeValueIds,
  sharedWith,
  pageSize,
  allowedDataOnly,
}: IAnalyticsRequestData) =>
  BitesApi.get(`/analytics/${orgId}/comments/bite/`, {
    params: {
      bite_ids: JSON.stringify(biteIds),
      user_ids: userIds?.length ? JSON.stringify(userIds) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      commented: filter,
      page_size: pageSize,
    },
  });
