export enum ESortDirection {
  desc = 'desc',
  asc = 'asc',
}

export interface IDataTable<T> {
  count: number;
  total_pages: number;
  page: number;
  results: T[];
}
export interface IDataTableRequest<TFilters, TAdditionalFields, TSort extends Record<string, 1 | -1>> {
  filters?: TFilters;
  additionalFilters?: TAdditionalFields;
  sort?: TSort; // if not provided, default is by _id desc
  pageSize?: number; // if not provided, all results are returned
  page?: number; // starting from 0
}
export interface IDataTableResult<TResult, TAdditionalFields extends Record<string, any>> {
  results: TResult[];
  additionalFilters?: TAdditionalFields;
  page: number; // starting from 0
  totalPages: number; // total number of pages
  count: number; // total number of results
}

// object type
export enum EObjectType {
  bite = 'bite',
  playlist = 'playlist',
  quiz = 'quiz',
}

export interface IUploadBiteCover {
  biteId: number;
  uri: string;
  type?: string;
}

export interface IOnAttributesPayload {
  biteId: number;
  callback?: ({ attributes }: { attributes?: boolean } | undefined) => void;
}

export interface IItemInProgressStatus {
  status: EInProgressStatus | null;
  errorCodes?: string[];
  taskId: string;
}

export interface IItemInProgress {
  [itemId: string]: {
    [inProgressSection: string]: IItemInProgressStatus;
  };
}

export enum EInProgressTask {
  COVER = 'COVER',
  CLONE = 'CLONE',
}

export enum EInProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export interface IItemInProgressActionPayload extends IItemInProgressStatus {
  itemId: number;
  task: EInProgressTask;
}

export interface IItemInProgressSagaPayload extends IItemInProgressActionPayload {
  overwriteTaskId?: boolean;
  withResetTimeout?: number | boolean;
}

export enum EButtonSize {
  SMALL = 'SMALL',
  BASE = 'BASE',
}
