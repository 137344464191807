import Types, { IPlaylist, IExtraSettings, IUploadPlaylistCover, IClonePlaylistAction } from './playlist.types';
import { IItemInProgressActionPayload } from '../../types/common';

export const fetchPlaylistsRequest = (orgId: number) => ({
  type: Types.FETCH_PLAYLISTS_REQUEST,
  payload: orgId,
});

export const fetchMorePlaylistsRequest = (orgId: number) => ({
  type: Types.FETCH_MORE_PLAYLISTS_REQUEST,
  payload: orgId,
});

export const fetchPlaylistBitesRequest = (playlists: IPlaylist[]) => ({
  type: Types.FETCH_PLAYLIST_BITES_REQUEST,
  payload: playlists,
});

export const deletePlaylist = (playlistId: number) => ({
  type: Types.DELETE_PLAYLIST,
  payload: playlistId,
});

export const fetchQuizSettings = (id: number) => ({
  type: Types.FETCH_QUIZ_SETTINGS,
  payload: id,
});

export const setQuizSetting = (quizSettings: IExtraSettings) => ({
  type: Types.SET_QUIZ_SETTINGS,
  payload: quizSettings,
});

export const setQuizImageBackground = (imageUrl: string) => ({
  type: Types.SET_QUIZ_IMAGE_BACKGROUND,
  payload: imageUrl,
});

export const setHasQuizImageBackground = (hasBackground: boolean) => ({
  type: Types.SET_HAS_QUIZ_IMAGE_BACKGROUND,
  payload: hasBackground,
});

export const resetQuizSettings = () => ({
  type: Types.RESET_QUIZ_SETTINGS,
});

export const postQuizSettings = () => ({
  type: Types.POST_QUIZ_SETTINGS,
});

export const patchPlaylist = (playlistId: number, playlist: Partial<IPlaylist>) => ({
  type: Types.PATCH_PLAYLIST,
  payload: { playlistId, playlist },
});

export const updateQuizBooleanSettings = (settingKey: keyof IExtraSettings) => ({
  type: Types.UPDATE_QUIZ_BOOLEAN_SETTING,
  payload: settingKey,
});

export const updateQuizColorSettings = (settingKey: keyof IExtraSettings, color: string) => ({
  type: Types.UPDATE_QUIZ_COLOR_SETTING,
  payload: { settingKey, color },
});

export const clearPlaylists = () => ({
  type: Types.CLEAR_PLAYLISTS,
});

export const setPlaylists = (payload: Partial<IPlaylist>[]) => ({
  type: Types.SET_PLAYLISTS,
  payload,
});

export const setPlaylistInProgress = (payload: IItemInProgressActionPayload) => ({
  type: Types.SET_PLAYLIST_IN_PROGRESS,
  payload,
});

export const updatePlaylistCover = (payload: IUploadPlaylistCover) => ({
  type: Types.UPDATE_PLAYLIST_COVER,
  payload,
});

export const uploadPlaylistCoverImage = (payload: IUploadPlaylistCover) => ({
  type: Types.UPLOAD_PLAYLIST_COVER_IMAGE,
  payload,
});

export const fetchFullPlaylists = (payload: number[]) => ({
  type: Types.FETCH_FULL_PLAYLISTS,
  payload,
});

export const clonePlaylist = (payload: IClonePlaylistAction) => ({
  type: Types.CLONE_PLAYLIST,
  payload,
});

export const triggerCreatedPlaylistTransactionalCommunication = (payload: { playlistId: number }) => ({
  type: Types.TRIGGER_CREATED_PLAYLIST_TRANSACTIONAL_COMMUNICATION,
  payload,
});

export const triggerCreatedQuizTransactionalCommunication = (payload: { quizId: number }) => ({
  type: Types.TRIGGER_CREATED_QUIZ_TRANSACTIONAL_COMMUNICATION,
  payload,
});
