import { useCallback, useState } from 'react';

const useHeaderTabsDivider = (handleOffset = 10) => {
  const [isShowHeaderBottomDivider, setIsShowHeaderBottomDivider] = useState(false);

  const handleScroll = useCallback(
    (event) => {
      if (event.nativeEvent.contentOffset.y > handleOffset) {
        setIsShowHeaderBottomDivider(true);
        return;
      }

      setIsShowHeaderBottomDivider(false);
    },
    [handleOffset],
  );

  return {
    isShowHeaderBottomDivider,
    setIsShowHeaderBottomDivider,
    handleScroll,
  };
};

export default useHeaderTabsDivider;
