import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';

import { EPanel } from '../../../../store/homeScreen/homeScreen.types';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { setActivePanel } from '../../../../store/homeScreen/homeScreen.slice';
import {
  creatorsLabelSelector,
  sortDirectionSelector,
  sortFieldSelector,
  typesSelector,
} from '../../../../store/feed/feed.selectors';
import {
  getFeedObjectTypeKey,
  feedObjectTypeOptions,
  sortOptions,
} from '../../../../store/homeScreen/homeScreen.constants';
import ArrowDownIcon from '../../../../assets/icons/feed/arrow-down.svg';
import { activePanelSelector } from '../../../../store/homeScreen/homeScreen.selectors';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { sortingType } from './component.constants';

interface IProps {}

const FeedControls: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const sortField = useSelector(sortFieldSelector);
  const sortDirection = useSelector(sortDirectionSelector);
  const creatorsLabel = useSelector(creatorsLabelSelector);

  const types = useSelector(typesSelector);

  const activePanel = useSelector(activePanelSelector);

  const sortingLabel = useMemo(() => {
    return sortOptions.find((sortOption) => {
      return sortOption.field === sortField && sortOption.direction === sortDirection;
    }).label;
  }, [sortDirection, sortField]);

  const typesLabel = useMemo(() => {
    return feedObjectTypeOptions.find((typeOption) => {
      return typeOption.key === getFeedObjectTypeKey(types);
    }).label;
  }, [types]);

  const handleOpenSortingPanel = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'open_feed_sorting_panel',
      }),
    );
    dispatch(setActivePanel(EPanel.FEED_SORTING));
  }, [dispatch]);

  const handleOpenObjectTypesPanel = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'open_feed_object_types_panel',
      }),
    );
    dispatch(setActivePanel(EPanel.FEED_OBJECT_TYPE));
  }, [dispatch]);

  const handleOpenCreatorsPanel = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'open_creators_panel',
      }),
    );
    dispatch(setActivePanel(EPanel.CREATORS));
  }, [dispatch]);

  return (
    <S.Container>
      <S.ControlContainer onPress={handleOpenSortingPanel}>
        <S.ControlLabel isActive={activePanel === EPanel.FEED_SORTING}>
          {t(`filterPannel.${sortingLabel}`)}
        </S.ControlLabel>
        <ArrowDownIcon color={theme.colors.text} width={calcWidth(25)} height={calcHeight(24)} />
      </S.ControlContainer>

      <S.ControlContainer
        onPress={handleOpenObjectTypesPanel}
        // @ts-ignore
        dataSet={sortingType}
      >
        <S.ControlLabel isActive={activePanel === EPanel.FEED_OBJECT_TYPE}>
          {t(`filterPannel.${typesLabel}`)}
        </S.ControlLabel>
        <ArrowDownIcon color={theme.colors.text} width={calcWidth(25)} height={calcHeight(24)} />
      </S.ControlContainer>

      <S.ControlContainer onPress={handleOpenCreatorsPanel}>
        <S.ControlLabel isActive={activePanel === EPanel.CREATORS}>
          {creatorsLabel || t('filterPannel.all')}
        </S.ControlLabel>
        <ArrowDownIcon color={theme.colors.text} width={calcWidth(25)} height={calcHeight(24)} />
      </S.ControlContainer>
    </S.Container>
  );
};
const S = {
  Container: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: ${({}) => (isWeb ? 'center' : 'space-around')};
    padding: 0 ${calcHeight(16)}px;
  `,
  ControlContainer: styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    margin: 0 ${calcWidth(24)}px;
  `,
  ControlLabel: styled.Text<{ isActive: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: ${calcHeight(4)}px;
    ${({ isActive }) => (isActive ? 'font-weight: bold;' : '')}
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
};

export default FeedControls;
