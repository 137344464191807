import {
  ISignInCredentials,
  IRegistrationCredentials,
  IEditProfileDataDetails,
  ISignupPhoneCredentials,
  ISignupEmailCredentials,
  IPasswordRecoveryBody,
  INewPasswordCredentials,
  ILoginWithPhone,
  IFinalizeProfileDataRequest,
  IUser,
} from '../../../auth/auth.types';
import axios from 'axios';
import BitesApi, { BitesApiNoAuth, BASE_BACKEND_URL, defaultHeaders } from '../index';
import { IGetBitesAuthTokenPayload } from '../../../auth/auth.types';
import { ECAptchaAction, wrapWithCaptcha } from '../captcha';

export const login = wrapWithCaptcha(ECAptchaAction.LOGIN, async (credentials: ISignInCredentials, { headers } = {}) =>
  BitesApiNoAuth.post('/accounts/login_v2/?mobile=true', credentials, {
    headers,
  }),
);

export const logout = () => BitesApiNoAuth.post('/accounts/logout/');

export const loginWithPhone = wrapWithCaptcha(
  ECAptchaAction.LOGIN,
  (credentials: ILoginWithPhone['credentials'], { headers } = {}) =>
    BitesApiNoAuth.post('/accounts/login_v2/', credentials, { headers }),
);

export const signupWithPhone = wrapWithCaptcha(
  ECAptchaAction.SIGNUP,
  (payload: ISignupPhoneCredentials, { headers } = {}) =>
    BitesApiNoAuth.post('/accounts/registration_v2/', payload, { headers }),
);

export const signupWithEmail = wrapWithCaptcha(
  ECAptchaAction.SIGNUP,
  (payload: ISignupEmailCredentials, { headers } = {}) =>
    BitesApiNoAuth.post('/accounts/registration_v2/', payload, {
      headers,
    }),
);

export const register = wrapWithCaptcha(
  ECAptchaAction.SIGNUP,
  (registrationCredentials: IRegistrationCredentials, { headers } = {}) =>
    BitesApiNoAuth.post('/accounts/registration_v2/', registrationCredentials, { headers }),
);

export const passwordRecovery = wrapWithCaptcha(
  ECAptchaAction.PASSWORD_RESET,
  (body: IPasswordRecoveryBody, { headers } = {}) =>
    BitesApiNoAuth.post('/accounts/password_reset/', body, { headers }),
);

export const addUserToOrganization = (code: number) =>
  BitesApi.post('/accounts/attach_organization_v2/', {
    code: code,
    creator: true,
  });

export const getUser = () => BitesApi.get('/accounts/user/?mobile=true');

export const updateProfile = (payload: IEditProfileDataDetails) =>
  BitesApi.patch(`/user_organization/${payload.organizationId}/`, {
    profile_image: payload.profile_image,
    email: payload.email,
    phone: payload.phone,
    first_name: payload.first_name,
    last_name: payload.last_name,
  });

export const finalizeUserProfile = (userId: IUser['id'], payload: IFinalizeProfileDataRequest) =>
  BitesApi.post(`/accounts/finalize_registration/${userId}/`, payload);

export const getBitesAccessTokenWithSocialToken = wrapWithCaptcha(
  ECAptchaAction.LOGIN,
  (body: IGetBitesAuthTokenPayload, { headers } = {}) =>
    axios({
      url: `${BASE_BACKEND_URL}/teams/get_auth_token/`,
      method: 'POST',
      data: body,
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
      withCredentials: true,
    }),
);

export const getAppleWebAccessToken = wrapWithCaptcha(
  ECAptchaAction.LOGIN,
  (body: IGetBitesAuthTokenPayload, { headers } = {}) =>
    axios({
      url: `${BASE_BACKEND_URL}/teams/convert_token_apple_web/`,
      method: 'POST',
      data: body,
      headers: {
        ...defaultHeaders,
        ...(headers || {}),
      },
      withCredentials: true,
    }),
);

export const addTenantToMicrosoftUser = (body: { tenant_id: string; email?: string }) =>
  BitesApi({
    url: `${BASE_BACKEND_URL}/teams/tenant/`,
    method: 'POST',
    data: body,
  });

export const registerNotificationToken = (token: string, platform: 'ios' | 'android' | 'web') => {
  return BitesApi.post(`/push-notifications/register/${platform}/`, {
    registration_id: token,
  });
};

export const setNewRecoverPassword = (body: INewPasswordCredentials) =>
  BitesApi.post('/accounts/password_reset/confirm/', body);

export const deleteMyUser = () => {
  return BitesApi.post('/accounts/user/delete_me/');
};
