import React from 'react';
import Button, { IButtonProps } from '../../../components/shared/buttons/Button';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import ShareIC from '../../../assets/icons/share-ic.svg';
import { useTranslation } from 'react-i18next';
import { I18nManager } from 'react-native';

interface IProps extends IButtonProps {
  width: number;
  height: number;
  fontSize: number;
}

const SharePanelShareButton: React.FC<IProps> = (props) => {
  const { content, width, height, fontSize, ...rest } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <S.ShareButton
      content={isWeb ? t('common.copyLink') : content}
      renderedIcon={<ShareIcon disabled={rest.disabled} />}
      iconPosition='right'
      width={width}
      height={height}
      fontSize={fontSize}
      fontFamily={theme.fontFamilies.GilroyMedium}
      {...rest}
    />
  );
};

export default SharePanelShareButton;

interface IShareIconProps {
  disabled?: boolean;
}

const ShareIcon: React.FC<IShareIconProps> = ({ disabled }) => {
  const theme = useTheme();

  return (
    <S.IconWrapper style={{ transform: [{ rotateY: I18nManager.isRTL ? '180deg' : '0deg' }] }}>
      <ShareIC
        width={calcWidth(12)}
        height={calcWidth(12)}
        stroke={disabled ? theme.colors.darkGray3 : theme.colors.primaryBlue}
      />
    </S.IconWrapper>
  );
};

const IconWrapper = styled.View`
  border-radius: ${calcWidth(100)}px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  height: ${calcWidth(18)}px;
  width: ${calcWidth(18)}px;
  margin: ${calcWidth(5)}px;
`;

const ShareButton = styled(Button)<{ width: number; height: number }>`
  width: ${({ width }) => calcWidth(width)}px;
  height: ${({ height }) => calcHeight(height)}px;
  min-width: ${({ width }) => calcWidth(width)}px;
  max-width: ${({ width }) => calcWidth(width)}px;
  min-height: ${({ height }) => calcHeight(height)}px;
`;

const S = {
  IconWrapper,
  ShareButton,
};
