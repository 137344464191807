import React, { memo, useCallback } from 'react';
import { IPanelChildInjectedProps } from '../../../../store/bottomPanel/bottomPanel';
import styled from 'styled-components/native';
import { IOrganization } from '../../../../types/organization';
import Organization from './Organization';
import useOrganizations from '../../../../hooks/useOrganizations';
import { calcHeight } from '../../../../utils/dimensions';

interface IProps extends IPanelChildInjectedProps {
  isCreator: boolean;
  onOrganizationSelect: (org: IOrganization) => void;
}

const OrganizationSelectMenu: React.FC<IProps> = ({ isCreator, onClosePanel, onOrganizationSelect }) => {
  const organizationList = useOrganizations({ isCreator });

  const handleChangeOrg = useCallback(
    (org) => {
      onClosePanel(() => {
        onOrganizationSelect(org);
      });
    },
    [onClosePanel, onOrganizationSelect],
  );

  return (
    <S.Container>
      {organizationList.map((org) => (
        <Organization org={org} onPress={handleChangeOrg} key={org.id} />
      ))}
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView`
    width: 100%;
    max-height: ${calcHeight(299)}px;
  `,
};

export default memo(OrganizationSelectMenu);
