import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  biteSelector,
  generationSelector,
} from '../../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import Select from '../components/Select';
import { SearchVideosUsedInBites } from './SearchVideosUsedInBites';
import styled from 'styled-components';
import defaultTheme from '../../../../themes';

export const Sources = () => {
  const bite = useSelector(biteSelector);
  const generation = useSelector(generationSelector);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const renderBiteSearchContent = useCallback(({ onClose }: { onClose: () => void }) => {
    return <SearchVideosUsedInBites onClose={onClose} />;
  }, []);

  // const renderGenerationSearchContent = useCallback(({ onClose }: { onClose: () => void }) => {
  //   return null;
  //   // return <SearchVideosUsedInBites onClose={onClose} />;
  // }, []);

  return (
    <S.Sources onClick={handleClick}>
      <S.Source isSelected={!bite && !generation}>This bite</S.Source>
      <Select label={bite?.name || 'Another bite'} isSelected={!!bite} renderContent={renderBiteSearchContent} />
      {/* <Select
        label={generation?.name || 'Project'}
        isSelected={!!generation}
        renderContent={renderGenerationSearchContent}
      /> */}
    </S.Sources>
  );
};

const S = {
  Sources: styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin: 0 12px;
  `,
  Source: styled.div<{
    isSelected: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 30px;
    color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.primaryBlue : defaultTheme.colors.gray)};
    font-size: 13px;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    line-height: 16px;
    background-color: ${({ isSelected }) => (isSelected ? defaultTheme.colors.lightGray44 : 'transparent')};
    border-radius: ${8}px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
};
