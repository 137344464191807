import { useCallback, useState } from 'react';
import { Alert } from 'react-native';
import { useSelector } from 'react-redux';
import { ICheckBox } from './types';
import { fetchOrganiztionTeamUsers } from '../../../store/api/bites-api/calls/teams.calls';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';

const useComponentActions = (dispatch: any, closeModal: () => void) => {
  const activeOrganization = useSelector(activeOrganizationSelector);
  const [isLoading, setIsLoading] = useState(false);

  const onSharedComplete = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onToggleCheckBox = (id: number, value: boolean) => {
    //@ts-ignore
    dispatch({ type: 'toggleCheckBox', payload: { id, value } });
  };

  const getTeamsUsersList = useCallback(async () => {
    try {
      //@ts-ignore
      dispatch({ type: 'loading' });
      setIsLoading(true);
      const usersList = await fetchOrganiztionTeamUsers(activeOrganization.id);

      setIsLoading(false);
      //@ts-ignore
      dispatch({
        type: 'setUsersList',
        payload: {
          usersList: usersList.data.map((user: ICheckBox) => ({
            ...user,
            isSelect: false,
          })),
        },
      });
    } catch (e) {
      onSharedComplete();
      Alert.alert('Something went wrong', 'Please try again', [{ text: 'OK' }], { cancelable: true });
    }
  }, [activeOrganization.id, dispatch, onSharedComplete]);

  return {
    onToggleCheckBox,
    getTeamsUsersList,
    isLoading,
    setIsLoading,
    onSharedComplete,
  };
};

export default useComponentActions;
