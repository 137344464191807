import React, { useCallback, useMemo, useState } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { useSelector } from 'react-redux';
import {
  biteSelector,
  generationSelector,
  scopedResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setScopedResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { Sources } from './Sources/Sources';
import { videoSelector } from '../../../store/videoEditor/videoEditor.selectors';
import UploadControl from './UploadControl';
import FileUpload from './FileUpload.web';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const ScopedSidebar = () => {
  const scopedResults = useSelector(scopedResultsSelector);
  const bite = useSelector(biteSelector);
  const generation = useSelector(generationSelector);
  const video = useSelector(videoSelector);

  const [isUploadVisible, setIsUploadVisible] = useState(false);

  const additionalRequestFilters = useMemo(
    () =>
      bite
        ? {
            usedInVideoId: bite?.videoId,
          }
        : generation
        ? {
            generationId: generation.id,
          }
        : {
            usedInVideoId: video.id,
          },
    [bite, generation, video.id],
  );

  const renderUploadControls = useCallback(() => {
    return <UploadControl isVisible={isUploadVisible} setIsVisible={setIsUploadVisible} />;
  }, [isUploadVisible]);

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileTypes={FILE_TYPES} />;
  }, []);

  return (
    <>
      <SidebarTitle title='Libraries' renderControls={renderUploadControls} />

      {!isUploadVisible ? (
        <>
          <Sources />
          <Cloud
            cloudResults={scopedResults}
            additionalRequestFilters={additionalRequestFilters}
            renderFilterControls={renderFilterControls}
            setCloudResults={setScopedResults}
          />
        </>
      ) : (
        <FileUpload />
      )}
    </>
  );
};
export default ScopedSidebar;
