import React, { FC, useEffect, memo } from 'react';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import styled from 'styled-components/native';
import { calcWidth, deviceHeight } from '../../../../../../utils/dimensions';
import ShadowedContainer from '../../../../../ShadowedContainer';
import { ViewStyle } from 'react-native';

interface IProps {
  isVisible: boolean;
  children: React.ReactChild;
}

const MAX_CONTENT_HEIGHT = deviceHeight - 90;
export const PANEL_HEADER_HEIGHT = 60;
export const ANIMATION_TIME = 200;

const Panel: FC<IProps> = ({ children, isVisible }) => {
  const animatedMaxHeight = useSharedValue(isVisible ? MAX_CONTENT_HEIGHT : 0);

  useEffect(() => {
    animatedMaxHeight.value = isVisible ? MAX_CONTENT_HEIGHT : 0;
  }, [animatedMaxHeight, isVisible]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      maxHeight: withTiming(animatedMaxHeight.value, {
        duration: ANIMATION_TIME,
        easing: Easing.linear,
      }),
    }),
    [animatedMaxHeight],
  );

  return (
    <ShadowedContainer containerViewStyle={S.ShadowedContainerStyles}>
      {/*Fragment needed to avoid crash with animated component inside shadow*/}
      <>
        <Animated.View style={animatedStyle}>
          <S.RoundedWrapper>{children}</S.RoundedWrapper>
        </Animated.View>
      </>
    </ShadowedContainer>
  );
};

const S = {
  ShadowedContainerStyles: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    justifyContent: 'flex-end',
    zIndex: 2,
  } as ViewStyle,
  RoundedWrapper: styled.View`
    width: 100%;
    max-height: ${MAX_CONTENT_HEIGHT}px;
    border-top-left-radius: ${calcWidth(32)}px;
    border-top-right-radius: ${calcWidth(32)}px;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: flex-start;
  `,
};

export default memo(Panel);
