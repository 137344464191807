import { RootState } from '..';
import { IBiteItem } from '../../types/bite';
import { EInProgressTask, IItemInProgressStatus } from '../../types/common';

export const biteSelector = (state: RootState) => state.bite;

export const isBiteLoadingSelector = (state: RootState): boolean => state.bite.isLoading;

export const bitesMapSelector = (state: RootState): { [key in number]: IBiteItem } => state.bite.bitesMap;

const bitesLoadingStateMapItem = {
  isLoading: false,
  error: null,
};

export const bitesLoadingStateMapSelector = (biteId: number) => (state: RootState) => {
  if (!state.bite.bitesLoadingStateMap[biteId]) {
    return bitesLoadingStateMapItem;
  }

  return state.bite.bitesLoadingStateMap[biteId];
};

export const biteItemSelector =
  (biteId: number) =>
  (state: RootState): IBiteItem =>
    biteId ? state.bite.bitesMap[biteId] : null;

export const hasBitesSelector = (state: RootState): boolean => state.bite.hasBites;

export const selectedBiteSelector = (state: RootState) => biteSelector(state).selectedBite;
export const selectedBiteVideoSelector = (state: RootState) => biteSelector(state).selectedBiteIntroVideo.video;
export const selectedBiteVideoIsLoadingSelector = (state: RootState) =>
  biteSelector(state).selectedBiteIntroVideo.isLoading;
export const selectedBiteVideoErrorSelector = (state: RootState) => biteSelector(state).selectedBiteIntroVideo.error;

export const biteInProgressSelector =
  (biteId: number, task: EInProgressTask) =>
  (state: RootState): IItemInProgressStatus =>
    biteSelector(state).bitesInProgress?.[biteId]?.[task];
