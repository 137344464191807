import React, { FC, useMemo } from 'react';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';

import { EditPlaylist } from '../../../screens/editPlaylist';
import { DefaultTheme } from 'styled-components/native';
import { useTheme } from 'styled-components/native';
import OrganizePlaylist from '../../../screens/createPlaylist/OrganizePlaylist';
import Routes from '../../routes';
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import AddBites from '../../../screens/createPlaylist/AddBites';
// import { useSelector } from 'react-redux';
// import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
import { IStackNavigation } from '../../types';
import { isWeb } from '../../../utils/dimensions';
// import useIsPlaylistOwner from '../../../hooks/useIsPlaylistOwner';
// import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
// import useCurrentRoute from '../../../hooks/useCurrentRoute';

interface IProps extends IStackNavigation {}

const Stack = createStackNavigator();
const localePrefix = 'createPlaylistStack.navTitles';
const initialParams = { isEditMode: true };

const EditPlaylistStack: FC<IProps> = ({}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const { params } = useCurrentRoute();

  // const playlistId = params?.params?.playlistId;
  // const playlist = useSelector(playlistItemSelector(playlistId));
  // const isPlaylistOwner = useIsPlaylistOwner(playlist);
  // const isContentAdmin = useSelector(isContentAdminSelector);
  // const isUserCanEdit = isPlaylistOwner || isContentAdmin;

  const organizePlaylistOptions = useMemo(() => {
    return {
      headerTitle: t(`${localePrefix}.organize`),
      header: getHeader,
    };
  }, [t]);

  const addBitesOptions = useMemo(() => {
    return {
      headerTitle: t(`${localePrefix}.addBites`),
      header: getHeader,
    };
  }, [t]);

  // useEffect(() => {
  //   if (!isUserCanEdit) {
  //     navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  //   }
  // }, [isUserCanEdit, navigation]);

  // if (!isUserCanEdit) {
  //   return null;
  // }

  return (
    <Stack.Navigator screenOptions={getScreenOptions(theme)}>
      <Stack.Screen name='EditPlaylist' component={EditPlaylist} initialParams={initialParams} />
      <Stack.Screen
        name={Routes.EditPlaylistStack.OrganizePlaylist}
        component={OrganizePlaylist}
        options={organizePlaylistOptions}
      />
      <Stack.Screen name={Routes.EditPlaylistStack.AddBites} component={AddBites} options={addBitesOptions} />
    </Stack.Navigator>
  );
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },
    header: ({}) => {
      return null;
    },
    headerMode: 'screen',
    animationEnabled: !isWeb,
    ...TransitionPresets.SlideFromRightIOS,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

export default EditPlaylistStack;
