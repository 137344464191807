import { IS3Part, IUploadMultiUploadToS3WithDetails } from './uploadToS3.types';
import store from '../../store';
import { log, logError } from '../../store/appActivity/appActivity.slice';
import axios from 'axios';
import withRetry, { IWithRetryProps } from '../withRetry';
import BitesApi from '../../store/api/bites-api';
import runInParallel from '../runInParallel';

const uploadPart = async ({
  processId,
  index,
  url,
  data,
  partsNumArray,
}: {
  processId: string;
  index: number;
  url: string;
  data: Blob;
  fileName: string;
  partsNumArray: number[];
}): Promise<IS3Part | null> => {
  return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
    const logContext = {
      processId,
      data: {
        attempt,
        isLastAttempt,
        index,
        url,
        partsNumArray,
      },
    };

    const startTs = Date.now();

    setErrorContext({
      ...logContext,
      action: 'uploadPart',
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadPart: start',
      }),
    );

    const result = await axios.put(url, data, {
      headers: {
        'Content-Type': data.type,
        'Content-Length': data.size,
      },
    });

    let { etag } = result.headers;

    if (!etag) {
      store.dispatch(
        logError({
          ...logContext,
          event: 'uploadPartToS3: error',
          data: {
            ...logContext.data,
            resultData: result.data,
          },
        }),
      );

      throw result;
    }

    const PartNumber = partsNumArray[index];

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadPart: result',
        data: {
          ...logContext.data,
          result,
          etag,
          PartNumber,
        },
        metrics: {
          uploadPartTs: Date.now() - startTs,
          uploadToS3Status: result.status,
        },
      }),
    );

    return {
      PartNumber,
      ETag: etag,
    };
  });
};

const uploadMultiPartToS3WithDetails = async ({
  processId,
  fileParts,
  s3UploadDetails,
  completeEndpoint,
  parallelUploadsNum,
  fileName,
  partsNumArray,
  fileSize,
}: IUploadMultiUploadToS3WithDetails) => {
  const { upload_id: uploadId, urls, task_id: taskId } = s3UploadDetails;

  const logContext: any = {
    processId,
    data: {
      filePartsNum: fileParts.length,
      s3UploadDetails,
      completeEndpoint,
      parallelUploadsNum,
      fileSize,
      fileName,
      partsNumArray,
    },
  };

  const startTs = Date.now();

  try {
    store.dispatch(
      log({
        ...logContext,
        event: 'uploadMultiPartToS3WithDetails: start',
      }),
    );

    const parts = await runInParallel(fileParts, parallelUploadsNum, async (data, index) => {
      return uploadPart({
        processId,
        index,
        url: urls[index],
        data: <Blob>data,
        fileName,
        partsNumArray,
      });
    });

    logContext.data.partsNum = parts.length;

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadMultiPartToS3WithDetails: uploadMultiPartsToS3 done',
        metrics: {
          uploadMultiPartsToS3Ts: Date.now() - startTs,
          uploadMultiPartsToS3TsPerMb: fileSize ? (Date.now() - startTs) / (fileSize / 1024 / 1024) : null,
        },
      }),
    );

    const completeStartTs = Date.now();

    // complete upload
    const completeUploadResult = withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
      const _logContext = {
        ...logContext,
        data: {
          ...logContext.data,
          attempt,
          isLastAttempt,
        },
      };

      setErrorContext({
        ..._logContext,
        action: 'uploadMultiPartToS3WithDetails: complete upload',
      });

      store.dispatch(
        log({
          ..._logContext,
          event: 'uploadMultiPartToS3WithDetails: complete upload: start',
        }),
      );

      const { data } = await BitesApi.post(completeEndpoint, {
        parts,
        upload_id: uploadId,
        file_name: fileName,
        task_id: taskId,
      });

      return data;
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'uploadMultiPartToS3WithDetails: done',
        data: {
          completeUploadResult,
        },
        metrics: {
          completeMultiPartToS3Ts: Date.now() - completeStartTs,
          uploadMultiPartToS3WithDetailsTs: Date.now() - startTs,
        },
      }),
    );

    return completeUploadResult;
  } catch (error) {
    store.dispatch(
      logError({
        ...logContext,
        event: 'uploadMultiPartToS3WithDetails: error',
        data: {
          ...logContext.data,
          error,
        },
      }),
    );

    throw error;
  }
};
export default uploadMultiPartToS3WithDetails;
