import VideoModal from '../shared/VideoModal';
import { useSelector } from 'react-redux';
import { configSelector } from '../../store/appActivity/appActivity.selectors';
import React, { useCallback, useRef } from 'react';
import { IMediaProgress, IMediaViewerMethods } from '../../types/media';
import { useTranslation } from 'react-i18next';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

interface IVideoState {
  videoProgress: IMediaProgress;
  videoPlayerMethods: IMediaViewerMethods;
}

const ExploreBiteTutorialVideo: React.FC<IProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const config = useSelector(configSelector);

  const state = useRef<IVideoState>({
    videoProgress: null,
    videoPlayerMethods: null,
  });

  const handleOnMethods = useCallback(
    (methods) => {
      state.current.videoPlayerMethods = methods;
    },
    [state],
  );

  const onNext = useCallback(() => {
    state.current.videoPlayerMethods?.pause();
    onClose();
  }, [onClose]);

  const onProgress = useCallback((e) => {
    state.current.videoProgress = e;
  }, []);

  return (
    <VideoModal
      isVisible={isVisible}
      onBtnRightClick={onNext}
      btnRightLabel={t('common.Skip')}
      btnRightStyle='transparent'
      videoUrl={config?.video?.exploreBites}
      isAutoPlay
      isMutedInitial={false}
      onProgress={onProgress}
      onEnd={onNext}
      onMethods={handleOnMethods}
    />
  );
};

export default ExploreBiteTutorialVideo;
