import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, deviceHeight, isWeb, WEB_CONTAINER_WIDTH } from '../../../utils/dimensions';
import BaseModal from '../../modals/ModalController';
import { Direction, ModalProps } from 'react-native-modal';
import { WEB_DRAWER_WIDTH } from '../../../utils/constants/drawer';

interface IProps extends Pick<ModalProps, 'onSwipeComplete' | 'useNativeDriver' | 'propagateSwipe'> {
  isVisible: boolean;
  onClose: () => void;
  onModalHide: () => void;
  children: React.ReactChild;
}

const MAX_CONTENT_HEIGHT = calcHeight(deviceHeight - 90);

const SharePanelModal: FC<IProps> = ({ children, isVisible, onClose, onModalHide, ...props }) => {
  const swipeDirection: Direction[] = ['down', 'up'];
  return (
    <S.Modal
      isVisible={isVisible}
      onBackdropPress={onClose}
      backdropOpacity={0.4}
      onModalHide={onModalHide}
      hideModalContentWhileAnimating
      swipeDirection={swipeDirection}
      {...props}
    >
      <S.Container>
        <S.RoundedWrapper>
          <S.Handle />

          {children}
        </S.RoundedWrapper>
      </S.Container>
    </S.Modal>
  );
};

const Modal = styled(BaseModal)`
  justify-content: flex-end;
  margin: 0;
`;

const Container = styled.View`
  left: ${isWeb ? `${WEB_DRAWER_WIDTH + 20}px` : '0px'};
  width: ${isWeb ? `${WEB_CONTAINER_WIDTH}px` : '100%'};
  justify-content: flex-end;
`;

const RoundedWrapper = styled.View`
  width: 100%;
  max-height: ${MAX_CONTENT_HEIGHT}px;
  border-top-left-radius: ${calcWidth(32)}px;
  border-top-right-radius: ${calcWidth(32)}px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${calcHeight(12)}px;
  align-items: center;
  justify-content: flex-start;
`;

const Handle = styled.View`
  width: ${calcWidth(46)}px;
  height: ${calcHeight(4)};
  border-radius: ${calcWidth(48)}px;
  background-color: ${({ theme }) => theme.colors.gray7};
`;

const S = {
  Modal,
  Container,
  RoundedWrapper,
  Handle,
};

export default memo(SharePanelModal);
