import { IMedia, EFileType } from './media';
import { EShareMode } from '../utils/constants/bite';
import { EQuestionType, IBiteAnswer } from './biteQuestion';
import { ISummaryCard } from './biteSummary';
import { EObjectType } from './common';
import { ISubtitles } from './subtitles';
import { TLocale } from '../locale/i18n';
import { IOrganization } from './organization';

export interface IStatistics {
  participants: {
    watchedCount: number | null;
    totalCount: number | null;
  };
  successRatePercentage: number | null;
  commentsCount: number | null;
}

export type IntroSection = {
  id?: number;
  type: 'intro';
  media: Partial<IMedia> | null;
  task_id?: string;
};

export type QuestionSection = {
  id?: number;
  task_id?: string;
  type: 'question';
  media: Partial<IMedia> | null;
  questions: {
    id?: number;
    type: EQuestionType;
    text: string;
    choices: IBiteAnswer[];
    is_multi_choice?: boolean;
    correct_choice_id?: number;
  }[];
};

export type SummarySection = {
  id?: number;
  type: 'summary';
  summary_cards: ISummaryCard[];
  media: Partial<IMedia> | null;
};

type ContentSection = {
  id?: number;
  type: 'content'; // NOT NEEDED AT THIS POINT
  content: {
    content: string;
  };
};
export type TBiteSection = IntroSection | QuestionSection | SummarySection | ContentSection;

export enum EEnhanceType {
  'original' = 'original',
  'speech_enhancement' = 'speech_enhancement',
}

export enum EUploadStatus {
  'not_applicable' = 'not_applicable',
  'in_progress' = 'in_progress',
  'failed' = 'failed',
  'done' = 'done',
}

export interface IEnhancement {
  id: number;
  bite_section: number;
  file_type?: EFileType;
  image_url: any;
  media_url: string;
  video_start_at: any;
  video_ends_at: any;
  video_duration: any;
  is_cc_enabled?: boolean;
  auto_play_muted: boolean;
  created_at: string;
  enhance_type: EEnhanceType;
  task_id: string;
  s3_video_url: string;
  upload_status: EUploadStatus;
  approved: boolean;
  enabled: boolean;
  sync_failed: boolean;
  width?: number;
  height?: number;
}

export type IIntroEnhancementsChanges = {
  subtitlesEnabledState?: {
    locale: string;
    enabled: boolean;
  };
  mediaId?: number;
};

export interface IBiteExtend {
  enhancements: IEnhancement[];
  subtitles: ISubtitles[];
}

export interface IBiteItem {
  content_type: EObjectType;
  bite: number | null;
  id: number | null;
  bite_preview: number | null;
  cover: null | number;
  cover_url: string | null;
  icon: number;
  icon_url: null | string;
  created_at: string;
  subject: string;
  organization?: number;
  description?: string;
  background: number;
  language: number;
  watched: null | number;
  participants: null | number;
  linked_cover_url: string;
  comments: null | number;
  answers: number | null;
  is_private: boolean;
  discussion_enabled: boolean;
  skip_able: boolean;
  bite_sections: TBiteSection[];
  bite_shares_info: IBiteShareInfo[];
  owner: number;
  owner_name: string;
  extend?: IBiteExtend;
  sharing_mode: EShareMode;
  no_sections: boolean;
  suggestions: IBiteSuggestions;
  branding?: {
    brand_share_url: string;
    locale: TLocale;
    logo_url: string;
    name: string;
  };
  bite_use_case_tags: string[];
  bite_industry_tags: string[];
}

export interface ICopyBiteAction {
  bite: IBiteItem;
  org: IOrganization;
}

interface IBiteSuggestions {
  name: string;
  cover_keyword: string;
}

export interface IBiteShareInfo {
  id: number;
  bite: number;
  subject: string;
  group: number;
  group_name: string;
  is_shared_with_teams: boolean;
}

export interface IBiteViewProps extends IBiteItem {}
