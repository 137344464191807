import React, { FC, useCallback } from 'react';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../../../store/createPlaylist/createPlaylist.selectors';
import { useTranslation } from 'react-i18next';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import PlusIcon from '../../../../assets/icons/PlaylistCreation/plus.svg';
import { ShadowProps } from 'react-native-shadow-2';
import Routes from '../../../../navigation/routes';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  isEditMode?: boolean;
}

const shadowedContainerOffset: ShadowProps['offset'] = [0, 0];

const HeaderTitle: FC<IProps> = ({ isEditMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { selectedBites } = useSelector(createPlaylistSelector);
  const { editedPlaylist } = useSelector(createPlaylistSelector);

  const handleAdd = useCallback(() => {
    if (isEditMode) {
      return navigation.navigate(Routes.EditPlaylistStack.AddBites);
    }
    dispatch(
      trackEvent({
        event: 'add_bite_to_playlist_btn',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
    navigation.goBack();
  }, [dispatch, editedPlaylist?.id, isEditMode, navigation]);

  return (
    <>
      <S.Container>
        <S.TitleConainer>
          <S.Title>{t('createPlaylistStack.navTitles.organize')}</S.Title>
        </S.TitleConainer>
        <S.SecondaryTitleConainer>
          <S.SecondaryTitle>
            {selectedBites.length} {t('createPlaylistStack.organizePlaylist.bites')}
          </S.SecondaryTitle>
        </S.SecondaryTitleConainer>
        <S.PlusBgOuterContainer>
          <ShadowedContainer distance={10} radius={70} offset={shadowedContainerOffset}>
            <S.PlusBgInnerContainer />
          </ShadowedContainer>
        </S.PlusBgOuterContainer>
        <S.PlusOuterContainer>
          <ShadowedContainer distance={6} radius={70} offset={shadowedContainerOffset}>
            <S.PlusContainer onPress={handleAdd}>
              <PlusIcon width={calcWidth(15)} height={calcHeight(15)} />
            </S.PlusContainer>
          </ShadowedContainer>
        </S.PlusOuterContainer>
      </S.Container>
    </>
  );
};

const Container = styled.View`
  position: relative;
  align-items: center;
`;

const TitleConainer = styled.View``;
const Title = styled.Text`
  font-size: ${({ theme }) => theme.fontSizes.s18};
  color: ${({ theme }) => theme.colors.text};
`;

const SecondaryTitleConainer = styled.View`
  position: absolute;
  top: ${calcWidth(21)}px;
  width: 100%;
  align-items: center;
`;
const SecondaryTitle = styled.Text`
  font-size: ${({ theme }) => theme.fontSizes.s12};
  color: ${({ theme }) => theme.colors.gray19};
`;

const PlusBgOuterContainer = styled.View`
  position: absolute;
  top: ${calcWidth(41)}px;
  width: ${calcWidth(100)}px;
  height: ${calcHeight(60)}px;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  border-bottom-left-radius: ${isWeb ? '100%' : '50px'};
  border-bottom-right-radius: ${isWeb ? '100%' : '50px'};
`;

const PlusBgInnerContainer = styled.View`
  width: ${calcWidth(isWeb ? 55 : 50)}px;
  height: ${calcHeight(40)}px;
  border-bottom-left-radius: ${isWeb ? '100%' : '50px'};
  border-bottom-right-radius: ${isWeb ? '100%' : '50px'};
  background-color: ${({ theme }) => theme.colors.white};
`;

const PlusOuterContainer = styled.View`
  position: absolute;
  top: ${calcWidth(isWeb ? 31 : 31)}px;
  padding: ${calcWidth(8)}px;
  align-items: center;
  justify-content: center;
  border-radius: ${isWeb ? '100%' : '70px'};
`;
const PlusContainer = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  width: ${calcWidth(34)}px;
  height: ${calcHeight(34)}px;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const S = {
  Container,
  TitleConainer,
  Title,
  SecondaryTitleConainer,
  SecondaryTitle,
  PlusBgOuterContainer,
  PlusBgInnerContainer,
  PlusOuterContainer,
  PlusContainer,
};

export default HeaderTitle;
