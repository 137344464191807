import axios from 'axios';
import AsyncStorage from '@react-native-community/async-storage';
import store from '../..';
import { IS_PROD } from '../../../utils/constants/env';
import { logError } from '../../appActivity/appActivity.slice';
import { Platform } from 'react-native';
import { sessionId } from '../../appActivity/appActivity.constants';
import { DEV_SERVER_URL } from '../../../utils/constants/env.web';

const DEVELOPMENT_SERVER_URL = (DEV_SERVER_URL && DEV_SERVER_URL()) ?? 'https://api.small-bites.com';

export const BASE_BACKEND_URL = IS_PROD ? 'https://api.mybites.io' : DEVELOPMENT_SERVER_URL;
export const API_URL = `${BASE_BACKEND_URL}/api`;
const API_KEY = '02dff1d6617b476dbe55970fdd5f5219';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  'x-platform': Platform.OS,
  'x-origin': 'native',
  'X-Bites-Session-Id': sessionId,
};

const BitesApi = axios.create({
  baseURL: API_URL,
  headers: defaultHeaders,
  withCredentials: true,
});

export const BitesApiNoAuth = axios.create({
  baseURL: API_URL,
  headers: defaultHeaders,
  withCredentials: true,
});

export const BitesApiWithKey = axios.create({
  baseURL: API_URL,
  headers: {
    ...defaultHeaders,
    'X-API-Key': API_KEY,
  },
  withCredentials: true,
});

export default BitesApi;

export const tokenRef = { current: null };
export const setBitesApiAuthorization = (newToken: string | null) => {
  tokenRef.current = newToken;
  if (tokenRef.current) {
    BitesApi.defaults.headers.common.Authorization = `Bearer ${tokenRef.current}`;
    return;
  }
  delete BitesApi.defaults.headers.common.Authorization;
};

let tokenTimeout = null;
BitesApi.interceptors.response.use(function (response) {
  const accessToken = response?.headers?.['X-Access-Token'] || response?.headers?.['x-access-token'];
  // do not block the response
  clearTimeout(tokenTimeout);
  tokenTimeout = setTimeout(() => {
    if (accessToken) {
      setBitesApiAuthorization(accessToken);
      AsyncStorage.setItem('token', accessToken).catch((error) => {
        store.dispatch(logError({ error }));
      });
    }
  }, 0);
  return response;
});
