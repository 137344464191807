import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import Types from './createPlaylist.types';
import * as calls from '../api/bites-api/calls/playlist.calls';
import {
  postAndUpdatePlaylistSuccess,
  postAndUpdatePlaylistError,
  setPlaylistToEdit,
  updateSelectedBites,
} from './createPlaylist.actions';
import { createPlaylistSelector } from './createPlaylist.selectors';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import { CreatePlaylistState } from './createPlaylist.reducer';
import { IPlaylistPostOrUpdateBody } from './createPlaylist.types';
import { formatUpdatedPlaylistBiteShares } from '../../utils/formatDataToServer';
import { setPlaylists } from '../playlist/playlist.actions';
import { loadNextPage } from '../feed/feed.slice';
import { logError, trackEvent } from '../appActivity/appActivity.slice';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';

function* gtmHandler(response, is_quiz) {
  if (!is_quiz) {
    const bites_id = response.data.bite_shares.map((bite) => bite.id);
    yield put(
      trackEvent({
        event: 'save_playlist',
        props: { playlist_id: response.id, bites_id: bites_id },
      }),
    );
  } else {
    yield put(
      trackEvent({
        event: 'save_quiz',
        props: { quiz_id: response.id },
      }),
    );
  }
}

function* postPlaylistSaga() {
  try {
    const { subject, description, is_quiz, selectedBites, playlistSettings, linkedCoverUrl }: CreatePlaylistState =
      yield select(createPlaylistSelector);
    const activeOrganization = yield select(activeOrganizationSelector);
    const body: IPlaylistPostOrUpdateBody = {
      organization: activeOrganization.id,
      subject,
      description,
      is_quiz,
      linked_cover_url: linkedCoverUrl,
      ...playlistSettings,
      bite_shares: selectedBites.map((bite, index) => ({
        bite: bite.id!,
        order: index + 1,
        playlist_section: bite.playlist_section,
      })),
    };

    if (activeOrganization.default_sharing_mode) {
      body.sharing_mode = activeOrganization.default_sharing_mode;
    }

    const response = yield call(calls.postOrUpdatePlaylist, body);
    gtmHandler(response, is_quiz);
    yield put(postAndUpdatePlaylistSuccess());
    yield put(setPlaylists([response.data]));
    yield put(setPlaylistToEdit({ playlist: response.data, editMode: false }));
    yield put(loadNextPage({ withBaseFiltersAndSorting: true }));
  } catch (error) {
    yield put(
      logError({
        event: 'postPlaylistSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

function* updatePlaylistSaga() {
  try {
    const {
      subject,
      description,
      selectedBites,
      playlistSettings,
      editedPlaylist,
      is_quiz,
      editMode,
      linkedCoverUrl,
    }: CreatePlaylistState = yield select(createPlaylistSelector);

    const body: IPlaylistPostOrUpdateBody = {
      subject,
      description,
      is_quiz,
      ...playlistSettings,
      linked_cover_url: linkedCoverUrl,
      bite_shares: formatUpdatedPlaylistBiteShares(selectedBites, editedPlaylist?.bite_shares!),
    };

    const res = yield call(calls.updatePlaylist, editedPlaylist?.id, body);
    yield put(postAndUpdatePlaylistSuccess());
    yield put(setPlaylists([res.data]));
    yield put(updateSelectedBites(res.data?.bite_shares));
    yield put(setPlaylistToEdit({ playlist: res.data, editMode }));
  } catch (error) {
    yield put(postAndUpdatePlaylistError());

    yield put(
      logError({
        event: 'updatePlaylistSaga: error',
        error,
      }),
    );

    Toast.show({
      type: EToastTypes.networkError,
      topOffset: 0,
    });
  }
}

export default function* createPlaylistSagaWatcher() {
  yield all([
    takeLatest(Types.POST_PLAYLIST_REQUEST, postPlaylistSaga),
    takeLatest(Types.UPDATE_PLAYLIST_REQUEST, updatePlaylistSaga),
  ]);
}
