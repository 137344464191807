import Routes from '../../navigation/routes';

export const getEditIntroRoute = (biteId: number) => ({
  index: 1,
  routes: [
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditMain,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditMedia,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
  ],
});

export const getEditQuestionRoute = (biteId: number) => ({
  index: 1,
  routes: [
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditMain,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.BiteEditQuestion,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
  ],
});

export const getEditSummaryRoute = (biteId: number) => ({
  index: 1,
  routes: [
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditMain,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditSummaryNotes,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
  ],
});

export const getEditEnhancementsRoute = (biteId: number) => ({
  type: 'stack',
  index: 1,
  routes: [
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditMain,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
    {
      name: Routes.EditBiteStack.StackName,
      params: {
        screen: Routes.EditBiteStack.EditEnhancements,
        params: {
          isEditMode: true,
          biteId,
        },
      },
    },
  ],
});
