import React, { memo } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import MSTeamsPurpleIcon from '../../../assets/icons/ms-teams-purple.svg';
import SharePanelShareButton from './SharePanelShareButton';
import { IS_ANDROID } from '../../../utils/constants/env';
import AddToAny from '../../shared/AddToAny/AddToAny';
import { LinkParams } from '../../../hooks/openShareLinks/types';
import { sharePanelContainerDataSet } from './SharePanel.constants';

interface IProps {
  hasTeamsShare: boolean;
  disabled?: boolean;
  onTeamShare: () => void;
  onShare: () => void;
  content?: string;
  shareLinkParams: LinkParams;
}

const SharePanelBaseShare: React.FC<IProps> = (props) => {
  const { hasTeamsShare, disabled, onTeamShare, onShare, content, shareLinkParams } = props;

  return (
    // @ts-ignore
    <S.Container dataSet={sharePanelContainerDataSet} hasTeamsShare={hasTeamsShare}>
      {isWeb ? (
        <AddToAny
          title={shareLinkParams.copyTitle}
          link={shareLinkParams.link}
          onCopy={onShare}
          onTeamShare={hasTeamsShare ? onTeamShare : undefined}
        />
      ) : (
        <>
          {hasTeamsShare && (
            <S.TeamsShareBtn onPress={!disabled && onTeamShare}>
              <MSTeamsPurpleIcon width={calcWidth(42)} height={calcWidth(42)} fill='white' />
            </S.TeamsShareBtn>
          )}
          <SharePanelShareButton
            content={content}
            width={170}
            height={IS_ANDROID ? 45 : 40}
            fontSize={18}
            disabled={disabled}
            onPress={onShare}
          />
        </>
      )}
    </S.Container>
  );
};

const Container = styled.View<{ hasTeamsShare: boolean }>`
  width: 100%;
  flex-direction: row;
  justify-content: ${({ hasTeamsShare }) => (hasTeamsShare && !isWeb ? 'space-between' : 'center')};
  align-items: center;
  padding-left: ${calcWidth(13)}px;
  padding-right: ${calcWidth(13)}px;
  padding-bottom: ${calcHeight(13)}px;
  padding-top: ${calcHeight(13)}px;
`;

const TeamsShareBtn = styled.TouchableOpacity`
  padding-left: ${calcWidth(3)}px;
  padding-right: ${calcWidth(3)}px;
`;

const S = {
  Container,
  TeamsShareBtn,
};

export default memo(SharePanelBaseShare);
