import DeleteIcon from '../../../../../assets/icons/delete-icon.svg';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import { PAGE_WIDTH } from '../../constants';
import { useTranslation } from 'react-i18next';
import { deletePlaylistQuizButtonDataSet } from '../../../../../screens/Preview/Preview.contants';

interface IProps {
  onPress: () => void;
}

const DeleteButton: React.FC<IProps> = ({ onPress }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <S.DeleteButton
      onPress={onPress}
      //@ts-ignore
      dataSet={deletePlaylistQuizButtonDataSet}
    >
      <DeleteIcon style={S.Icon} fill={theme.colors.red1} />
      <S.ButtonText>{t('controlPanel.delete')}</S.ButtonText>
    </S.DeleteButton>
  );
};

const S = {
  DeleteButton: styled.TouchableOpacity`
    height: ${calcHeight(53)}px;
    min-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    max-width: ${(PAGE_WIDTH - calcWidth(10)) / 4}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    margin: 0 ${calcWidth(4)}px;
    align-items: center;
    justify-content: center;
  `,
  ButtonText: styled.Text<{ color?: string }>`
    margin-top: ${calcHeight(2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11};
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.gray19};
  `,
  Icon: {
    width: calcWidth(24),
    height: calcHeight(24),
  },
};

export default DeleteButton;
