import store from '../../store';
import { startBiteCreation, trackEvent } from '../../store/appActivity/appActivity.slice';

const startBiteCreationFlow = () => {
  store.dispatch(
    trackEvent({
      event: 'new_bite',
    }),
  );
  store.dispatch(startBiteCreation());
};
export default startBiteCreationFlow;
