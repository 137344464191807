import { useCallback } from 'react';
import { Alert, Linking } from 'react-native';
import { useTranslation } from 'react-i18next';

const useErrorAlert = () => {
  const { t } = useTranslation();

  const onError = useCallback(
    (message = '') => {
      Alert.alert(message.length ? t('common.error') : t('common.somethingWentWrong'), message, [
        {
          text: t('common.cancel'),
          onPress: () => {},
        },
        {
          text: t('common.goToSettings'),
          onPress: () => Linking.openSettings(),
        },
      ]);
    },
    [t],
  );

  return {
    onError,
  };
};

export default useErrorAlert;
