import { EUploadStatus } from '../../types/bite';
import { EIntroMediaProcessingStatus } from '../../store/createBite/createBite.types';

const getIntroProcessingStatusByUploadStatus = (uploadStatus: EUploadStatus) => {
  return uploadStatus === EUploadStatus.in_progress
    ? EIntroMediaProcessingStatus.PROCESSING
    : uploadStatus === EUploadStatus.done
    ? EIntroMediaProcessingStatus.SUCCESS
    : uploadStatus === EUploadStatus.failed
    ? EIntroMediaProcessingStatus.ERROR
    : uploadStatus === EUploadStatus.not_applicable
    ? EIntroMediaProcessingStatus.NOT_APPLICABLE
    : null;
};
export default getIntroProcessingStatusByUploadStatus;
