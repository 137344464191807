import React, { memo, useCallback, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SvgProps } from 'react-native-svg';
import styled, { useTheme } from 'styled-components/native';
import { EShareMode } from '../../../utils/constants/bite';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';

export interface IShareViewOption {
  type: EShareMode;
  secondType?: EShareMode;
  title: string;
  description: string;
  Icon: React.FC<SvgProps>;
  children?: React.ReactChild;
}

interface IProps extends IShareViewOption {
  isSelected: boolean;
  sharingMode: EShareMode;
  isExpanded?: boolean;
  onPress: (type: EShareMode) => void;
}

const SharePanelViewOption: React.FC<IProps> = (props) => {
  const { Icon, isSelected, title, description, onPress, children, type, sharingMode, isExpanded = false } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const [isCollapsed, setCollapseState] = useState(!isExpanded);

  const handleCollapse = useCallback(() => {
    setCollapseState(true);
  }, []);

  const handleExpand = useCallback(() => {
    setCollapseState(false);
  }, []);

  const handlePress = useCallback(() => {
    onPress(type);
  }, [onPress, type]);

  return (
    <S.Container isSelected={isSelected}>
      <S.OptionBtn onPress={handlePress}>
        <S.OptionMain>
          <S.IconWrapper
            style={{
              direction: isWeb ? 'ltr' : undefined,
            }}
          >
            <Icon
              width={calcWidth(28)}
              height={calcWidth(28)}
              fill={isSelected ? theme.colors.primaryBlue : theme.colors.gray10}
            />
          </S.IconWrapper>

          <S.TextContainerText>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TextContainerText>
        </S.OptionMain>

        {children && isCollapsed ? (
          <S.AdvancedContainer>
            {sharingMode === EShareMode.TEAM_ONLY_ALLOW_JOIN ? (
              <RequireAnAccessCodeText>{`*${t('share.requireAnAccessCode')}`}</RequireAnAccessCodeText>
            ) : (
              <View />
            )}

            <S.CollapseBtn onPress={handleExpand}>
              <S.CollapseBtnText>{t('share.advanced')}</S.CollapseBtnText>
            </S.CollapseBtn>
          </S.AdvancedContainer>
        ) : null}
      </S.OptionBtn>

      {children && !isCollapsed ? (
        <S.ChildWrapper>
          {children}

          <S.CollapseBtn onPress={handleCollapse}>
            <S.CollapseBtnText>{t('share.close')}</S.CollapseBtnText>
          </S.CollapseBtn>
        </S.ChildWrapper>
      ) : null}
    </S.Container>
  );
};

const Container = styled.View<{ isSelected: boolean }>`
  width: 100%;
  min-height: ${calcHeight(85)}px;
  padding-left: ${calcWidth(11)}px;
  padding-right: ${calcWidth(11)}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray8};
  background-color: ${({ theme, isSelected }) => {
    return isSelected ? theme.colors.gray16 : theme.colors.white;
  }};
`;

const OptionBtn = styled.TouchableOpacity`
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: ${calcHeight(15)}px;
  padding-bottom: ${calcHeight(12)}px;
  width: 100%;
`;

const OptionMain = styled.View`
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;

const ChildWrapper = styled.View`
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.lightBlue};
  border-style: solid;
  padding-left: ${calcHeight(41)}px;
  padding-top: ${calcHeight(10)}px;
  padding-bottom: ${calcHeight(12)}px;
`;

const IconWrapper = styled.View`
  justify-content: center;
  width: ${calcWidth(25)}px;
  margin-left: ${calcWidth(4)}px;
  margin-right: ${calcWidth(12)}px;
`;

const TextContainerText = styled.View`
  align-items: flex-start;
  padding-right: ${calcWidth(35)}px;
  max-width: 100%;
`;

const Title = styled.Text`
  margin-bottom: ${calcHeight(8)}px;
  font-size: ${calcFontSize(18)}px;
  color: ${({ theme }) => theme.colors.gray12};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  text-align: left;
`;

const Description = styled.Text`
  font-size: ${calcFontSize(12)}px;
  line-height: ${calcHeight(16)}px;
  color: ${({ theme }) => theme.colors.gray11};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  max-width: ${calcWidth(233)}px;
  text-align: left;
`;

const AdvancedContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${calcHeight(6)}px;
  padding-left: ${calcHeight(41)}px;
  flex-grow: 1;
`;

const RequireAnAccessCodeText = styled.Text`
  font-size: ${calcFontSize(12)}px;
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const CollapseBtn = styled.TouchableOpacity`
  height: ${calcHeight(17)}px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  padding-left: ${calcWidth(6)}px;
  padding-right: ${calcWidth(6)}px;
  border-radius: ${calcWidth(9)}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGray15};
`;

const CollapseBtnText = styled.Text`
  font-size: ${calcFontSize(12)}px;
  color: ${({ theme }) => theme.colors.gray5};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const S = {
  Container,
  OptionBtn,
  ChildWrapper,
  OptionMain,
  IconWrapper,
  TextContainerText,
  Title,
  Description,
  AdvancedContainer,
  CollapseBtn,
  CollapseBtnText,
};

export default memo(SharePanelViewOption);
