import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, isWeb } from '../../../../utils/dimensions';
import { ActivityIndicator, RefreshControl, SectionList, SectionListProps, ViewStyle } from 'react-native';
import { IAttributeMap, IAttributeValue, IGetAttributesPayload } from '../../../../store/attributes/attributes.types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayModeSelector,
  selectedFilterAttributeValueIdsSelector,
} from '../../../../store/attributes/attributes.selector';
import SectionHeader from './SectionHeader';
import AttributeValueItem from './AttributeValueItem';
import { log } from '../../../../store/appActivity/appActivity.slice';
import useAttributesData from '../../hooks/useAttributesData';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import {
  setIsNeedToScrollUp,
  setIsRefreshing as setIsRefreshingGlobal,
} from '../../../../store/analytics/analytics.slice';
import i18n, { getIsWebRtl, isRtlByLang } from '../../../../locale/i18n';
import { useIsFocused } from '@react-navigation/native';
import useAnalyticsTabs from '../../hooks/useAnalyticsTabs';
import { EAnalyticsScreenTabs } from '../../Analytics.types';
import Animated, { AnimatedStyleProp } from 'react-native-reanimated';
import {
  attributesListErrorFooterDataSet,
  attributesListLoadingFooterDataSet,
  attributeValueItemDataSet,
  refreshButtonDataSet,
  viewAllButtonDataSet,
} from '../../analytics.constants';

interface IProps extends Partial<SectionListProps<SectionList>> {
  attributes: IAttributeMap;
  onLoad: ActionCreatorWithOptionalPayload<IGetAttributesPayload, string>;
  renderListHeaderComponent?: ({ isRefreshing }: { isRefreshing: boolean }) => React.ReactElement;
  hasMultipleValues?: boolean;
  listType?: EAnalyticsScreenTabs;
  isNeedToScrollUp?: boolean;
  onSelectAttribute?: (state: boolean) => void;
  onSingleAttributeChanged?: (state: boolean) => void;
  stickyHeaderStyles?: AnimatedStyleProp<ViewStyle>;
  dataSet?: Record<string, string>;
}

export interface ISection {
  id?: number;
  title: string;
  data: IAttributeValue[];
  isLoading: boolean;
  isError: boolean;
  next?: string;
  isDisplaySkeleton: boolean;
}

const AttributesList: React.FC<IProps> = ({
  attributes,
  onLoad,
  renderListHeaderComponent,
  hasMultipleValues,
  listType,
  isNeedToScrollUp,
  onSelectAttribute,
  stickyHeaderStyles,
  onSingleAttributeChanged,
  dataSet,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isFocused = useIsFocused();
  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const sectionListRef = useRef(null);

  const displayMode = useSelector(displayModeSelector);
  const selectedValueIds = useSelector(selectedFilterAttributeValueIdsSelector);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [manuallySelectedSectionId, setManuallySelectedSectionId] = useState<number>(null);

  const { attributesToRender, singleAttributeId } = useAttributesData({
    attributes,
    onLoad,
    manuallySelectedSectionId,
  });

  const keyExtractor = useCallback((item, index) => `${item.id}-${index}`, []);

  const wrappedLoad = useCallback(
    ({
      attributeIds,
      isPullToRefresh,
      reset,
      callback,
    }: {
      attributeIds: number[];
      isPullToRefresh?: boolean;
      reset?: boolean;
      callback?: () => void;
    }) => {
      dispatch(onLoad({ attributeIds, clearAnalyticsCache: isPullToRefresh, callback, reset }));
    },
    [dispatch, onLoad],
  );

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleRefresh',
      }),
    );

    setIsRefreshing(true);
    dispatch(setIsRefreshingGlobal(true));

    wrappedLoad({
      attributeIds: attributesToRender.map((attribute) => attribute.id),
      isPullToRefresh: true,
      callback: () => setIsRefreshing(false),
      reset: true,
    });
  }, [attributesToRender, dispatch, wrappedLoad]);

  const handleErrorRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleErrorRefresh',
        data: { singleAttributeId },
      }),
    );

    wrappedLoad({ attributeIds: [singleAttributeId] });
  }, [dispatch, singleAttributeId, wrappedLoad]);

  const handleEndReached = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesList.handleErrorRefresh',
        data: { singleAttributeId },
      }),
    );

    wrappedLoad({ attributeIds: [singleAttributeId] });
  }, [dispatch, singleAttributeId, wrappedLoad]);

  const handleEndReachedProp = useMemo(() => {
    if (
      !singleAttributeId ||
      !attributes[singleAttributeId].next ||
      attributes[singleAttributeId].isLoading ||
      attributes[singleAttributeId].error
    ) {
      return;
    }

    return handleEndReached;
  }, [attributes, handleEndReached, singleAttributeId]);

  const scrollToTop = useCallback(({ animated }) => {
    if (sectionListRef.current) {
      const scrollResponder = sectionListRef.current.getScrollResponder();
      scrollResponder?.scrollTo({ x: 0, y: 0, animated });
    }
  }, []);

  const handleSelectSection = useCallback(
    (section: ISection) => {
      dispatch(
        log({
          event: 'AttributesList.handleSelectSection',
          data: { section, manuallySelectedSectionId },
        }),
      );

      if (section.id === manuallySelectedSectionId) {
        onSelectAttribute?.(false);
        setManuallySelectedSectionId(null);
        return;
      }

      onSelectAttribute?.(true);
      setManuallySelectedSectionId(section.id);

      if (!section.next || section.isError) {
        return;
      }

      wrappedLoad({ attributeIds: [section.id] });
    },
    [dispatch, manuallySelectedSectionId, onSelectAttribute, wrappedLoad],
  );

  const handleContentSizeChange = useCallback(
    (contentWidth, contentHeight) => {
      if (
        !singleAttributeId ||
        !attributes[singleAttributeId].next ||
        attributes[singleAttributeId].error ||
        attributes[singleAttributeId].isLoading ||
        contentHeight > window.innerHeight
      ) {
        return;
      }

      wrappedLoad({ attributeIds: [singleAttributeId] });
    },
    [attributes, singleAttributeId, wrappedLoad],
  );

  const renderItem = useCallback(
    ({ section, item, index }) => {
      if (index === 5 && !singleAttributeId) {
        return (
          <ViewAllButton
            // @ts-ignore
            dataSet={viewAllButtonDataSet}
            section={section}
            onPress={handleSelectSection}
          />
        );
      }

      return (
        <AttributeValueItem
          // @ts-ignore
          dataSet={attributeValueItemDataSet}
          isViewsTabEnabled={isViewsTabEnabled}
          isAnsweredTabEnabled={isAnsweredTabEnabled}
          isCommentsTabEnabled={isCommentsTabEnabled}
          displayMode={displayMode}
          item={item}
          withDivider={index < section.data.length - 1}
          hasMultipleValues={hasMultipleValues}
        />
      );
    },
    [
      displayMode,
      handleSelectSection,
      hasMultipleValues,
      isAnsweredTabEnabled,
      isCommentsTabEnabled,
      isViewsTabEnabled,
      singleAttributeId,
    ],
  );

  const renderSectionHeader = useCallback(
    ({ section }) => (
      <>
        <SectionHeader
          isSelected={singleAttributeId === section.id}
          section={section}
          onSelectSection={handleSelectSection}
          onRefresh={wrappedLoad}
          hasOnlyOneAttribute={singleAttributeId && !manuallySelectedSectionId}
        />
      </>
    ),
    [singleAttributeId, handleSelectSection, wrappedLoad, manuallySelectedSectionId],
  );

  const renderFooterContainer = useCallback(() => {
    if (!singleAttributeId) {
      return null;
    }

    if (attributes[singleAttributeId].isLoading && !isRefreshing && attributes[singleAttributeId].data.values?.length) {
      return (
        <S.FooterContainer
          // @ts-ignore
          dataSet={attributesListLoadingFooterDataSet}
        >
          <ActivityIndicator size='large' />
        </S.FooterContainer>
      );
    }

    if (attributes[singleAttributeId].error) {
      return (
        <S.ErrorContainer
          // @ts-ignore
          dataSet={attributesListErrorFooterDataSet}
        >
          <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
          <S.RefreshButton
            // @ts-ignore
            dataSet={refreshButtonDataSet}
            onPress={handleErrorRefresh}
          >
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      );
    }

    return null;
  }, [attributes, handleErrorRefresh, isRefreshing, singleAttributeId, t]);

  useEffect(() => {
    scrollToTop({ animated: isFocused });
  }, [isFocused, scrollToTop, selectedValueIds]);

  useEffect(() => {
    if (isFocused && isNeedToScrollUp) {
      scrollToTop({ animated: true });
      dispatch(setIsNeedToScrollUp({ listType, status: false }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToScrollUp]);

  useEffect(() => {
    onSingleAttributeChanged?.(!!singleAttributeId);
  }, [onSingleAttributeChanged, singleAttributeId]);

  const ListHeaderComponent = useMemo(() => {
    if (renderListHeaderComponent) {
      return renderListHeaderComponent({ isRefreshing });
    }

    return undefined;
  }, [isRefreshing, renderListHeaderComponent]);

  if (attributesToRender.length === 0) {
    return null;
  }

  return (
    <>
      {stickyHeaderStyles && !!singleAttributeId && (
        <Animated.View style={stickyHeaderStyles}>
          <SectionHeader
            isSelected
            section={attributesToRender[0]}
            onSelectSection={handleSelectSection}
            hasOnlyOneAttribute={singleAttributeId && !manuallySelectedSectionId}
            isSticky
          />
        </Animated.View>
      )}
      <S.SectionList
        // @ts-ignore
        dataSet={dataSet}
        ref={sectionListRef}
        keyExtractor={keyExtractor}
        sections={attributesToRender}
        stickySectionHeadersEnabled={!!singleAttributeId && !stickyHeaderStyles}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl refreshing={isRefreshing} onRefresh={handleRefresh} tintColor={theme.colors.gray19} />
        }
        renderSectionHeader={renderSectionHeader}
        onEndReachedThreshold={isWeb ? 0.001 : 0.1}
        onEndReached={handleEndReachedProp}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={renderFooterContainer}
        onContentSizeChange={isWeb && handleContentSizeChange}
        {...props}
      />
    </>
  );
};

interface IViewAllButtonProps {
  onPress: (section: ISection) => void;
  section: ISection;
  dataSet?: Record<string, string>;
}

const ViewAllButton: React.FC<IViewAllButtonProps> = ({ onPress, section, dataSet }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isWebRtl = getIsWebRtl();

  const handleViewAll = useCallback(() => {
    dispatch(
      log({
        event: 'ViewAllButton.handleViewAll',
        data: { section },
      }),
    );

    onPress(section);
  }, [dispatch, onPress, section]);

  return (
    <S.ViewAllContainer isRtl={isWebRtl}>
      <S.ViewAllButton
        // @ts-ignore
        dataSet={dataSet}
        onPress={handleViewAll}
      >
        <S.ViewAllText>{t('analytics.attributes.viewAll')}</S.ViewAllText>
      </S.ViewAllButton>
    </S.ViewAllContainer>
  );
};

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);

const S = {
  SectionList: styled(AnimatedSectionList)`
    height: 100%;
    width: 100%;
  `,
  ViewAllContainer: styled.View<{ isRtl: boolean }>`
    margin: ${calcHeight(16)}px ${calcHeight(19)}px;
    align-items: ${isRtlByLang[i18n.language] ? 'flex-start' : 'flex-end'};
  `,
  ViewAllButton: styled.TouchableOpacity``,
  ViewAllText: styled.Text`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,

  FooterContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: ${calcHeight(20)}px;
  `,
  ErrorContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: ${calcHeight(20)}px 0;
  `,
  ErrorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
  `,

  RefreshButton: styled.TouchableOpacity`
    margin-top: 10px;
  `,
  RefreshText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default memo(AttributesList);
