import React from 'react';
import Button, { IButtonProps } from './Button';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import NextArrow from '../../../assets/icons/arrow-right-icon.svg';
import { useTranslation } from 'react-i18next';
import { I18nManager } from 'react-native';

const ContinueButton: React.FC<IButtonProps> = ({ content, longSize, inverted, disabled, ...rest }) => {
  const { t } = useTranslation();

  return (
    <S.Button
      content={content || t('common.continue')}
      renderedIcon={<NextIcon disabled={disabled} inverted={inverted} />}
      longSize={longSize}
      inverted={inverted}
      disabled={disabled}
      iconPosition='right'
      {...rest}
    />
  );
};

export default ContinueButton;

const NextIcon: React.FC<{ disabled?: boolean; inverted?: boolean }> = ({ disabled, inverted }) => (
  <S.IconWrapper
    disabled={disabled}
    inverted={inverted}
    style={{
      transform: [{ rotateY: I18nManager.isRTL ? '180deg' : '0deg' }],
    }}
  >
    <NextArrow width={calcWidth(10)} height={calcWidth(10)} fill={disabled ? '#AEB4BC' : '#1253fa'} />
  </S.IconWrapper>
);

const S = {
  IconWrapper: styled.View<{ disabled?: boolean; inverted?: boolean }>`
    margin-right: ${calcWidth(10)}px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    width: ${calcWidth(22)}px;
    height: ${calcWidth(22)}px;
    ${({ theme, disabled, inverted }) =>
      disabled
        ? `background-color: ${theme.colors.white}`
        : inverted
        ? `background-color: ${theme.colors.white}`
        : `background-color: ${theme.colors.white}`}
  `,

  Button: styled(Button)<{ height?: number; longSize?: boolean }>`
    height: ${({ height }) => calcHeight(height || 47)}px;
    max-width: ${({ longSize }) => (longSize ? '100%' : '200px')};
    ${() => isWeb && 'min-width: 200px'};
    align-self: center;
  `,
};
