import React, { memo, useCallback, useMemo } from 'react';
import AuthError from './AuthError';
import PhoneIcon from '../../../assets/icons/phone.svg';
import MicrosoftIcon from '../../../assets/icons/microsoft.svg';
import GoogleIcon from '../../../assets/icons/google-colorfull.svg';
import AppleIcon from '../../../assets/icons/apple.svg';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import SocialButton from './SocialButton';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import Header from './Header';
import useMsAuth from '../../../hooks/sso/useMsAuth';
import useGoogleAuth from '../../../hooks/sso/useGoogleAuth';
import { useDispatch, useSelector } from 'react-redux';
import { currentFlowSelector } from '../../../store/authForm/authForm.selectors';
import { setCurrentForm } from '../../../store/authForm/authForm.slice';
import { isAuthErrorSelector, errorCodesSelector, isSsoErrorSelector } from '../../../store/auth/auth.selectors';
import { useTranslation } from 'react-i18next';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import { log } from '../../../store/appActivity/appActivity.slice';
import EmailForm from './EmailForm';
import AppleSocialButton from './AppleSocialButton';
import Agreement from './Agreement';

interface IProps {}

const AuthForm: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const msAuth = useMsAuth();
  const googleAuth = useGoogleAuth();

  const isSsoError = useSelector(isSsoErrorSelector);
  const isAuthError = useSelector(isAuthErrorSelector);
  const errorCodes = useSelector(errorCodesSelector);
  const currentFlow = useSelector(currentFlowSelector);

  const currentForm = currentFlow === EAuthFlowType.SIGN_IN ? 'signInForm' : 'signUpForm';

  const formError = useMemo(() => {
    if (!isAuthError && !isSsoError) {
      return null;
    }

    if (isSsoError) {
      return t('common.somethingWentWrong');
    }

    if (errorCodes) {
      if (errorCodes.includes('3')) {
        return t('authentication.landing.emailAlreadyTaken');
      }

      if (errorCodes.includes('4')) {
        return t('authForm.forgotPasswordForm.resetPassTooCommon');
      }

      return t('authentication.landing.wrongEmailOrPassword');
    }

    return t('common.somethingWentWrong');
  }, [errorCodes, isAuthError, isSsoError, t]);

  const handleSingInWithPhoneNumber = useCallback(() => {
    dispatch(
      log({
        event: 'AuthForm.navigateTo',
        data: { destination: EAuthFormType.PHONE_NUMBER, currentFlow, currentForm },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.PHONE_NUMBER));
  }, [currentFlow, currentForm, dispatch]);

  return (
    <>
      <Animated.View entering={FadeIn.duration(400)} exiting={FadeOut.duration(200)}>
        <Header title={t(`authForm.${currentForm}.title`)} description={t(`authForm.${currentForm}.subtitle`)} />
        <AuthError isVisible={!!formError}>{formError}</AuthError>
        <S.Form>
          <EmailForm />
          <S.Separator>
            <S.SeparatorLine />
            <S.SeparatorText>{t('authForm.or')}</S.SeparatorText>
            <S.SeparatorLine />
          </S.Separator>
          <S.SocialButtons>
            <S.SocialButton
              onPress={handleSingInWithPhoneNumber}
              icon={<PhoneIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withPhone`)}
              containerStyle={S.SocialButtonContainerStyles}
            />
            <S.SocialButton
              onPress={msAuth}
              icon={<MicrosoftIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withMicrosoft`)}
              containerStyle={S.SocialButtonContainerStyles}
            />
            <S.SocialButton
              onPress={googleAuth}
              icon={<GoogleIcon width={24} height={24} />}
              title={t(`authForm.${currentForm}.withGoogle`)}
              containerStyle={S.SocialButtonContainerStyles}
            />
            <S.AppleSocialButton
              icon={<AppleIcon width={24} height={24} fill={'black'} />}
              title={t(`authForm.${currentForm}.withApple`)}
              containerStyle={S.SocialButtonContainerStyles}
            />
          </S.SocialButtons>
          <Agreement />
        </S.Form>
      </Animated.View>
    </>
  );
};

const S = {
  Form: styled.View`
    margin-top: ${calcHeight(32)}px;
    align-items: center;
  `,
  Header: styled.View`
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s20};
    line-height: ${({ theme }) => theme.fontSizes.s28};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: ${calcHeight(10)}px;
  `,
  HeaderDescription: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    margin-top: ${calcHeight(4)}px;
  `,
  Separator: styled.View`
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: ${calcHeight(24)}px;
    padding: 0 ${calcWidth(20)}px;
  `,
  SeparatorLine: styled.View`
    flex: 1;
    height: 1px;
    background: ${({ theme }) => theme.colors.lightGray18};
  `,
  SeparatorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    margin: 0 ${calcWidth(10)}px;
  `,
  SocialButtons: styled.View`
    margin-top: ${calcHeight(24)}px;
  `,
  SocialButton: styled(SocialButton)`
    min-width: ${calcWidth(295)}px;
  `,
  AppleSocialButton: styled(AppleSocialButton)`
    min-width: ${calcWidth(295)}px;
  `,
  SocialButtonContainerStyles: { marginBottom: calcHeight(12) },
};

export default memo(AuthForm);
