import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { EAuthFlowType } from '../../utils/constants/auth';
import { calcFontSize, calcHeight, calcWidth } from '../../utils/dimensions';
import CustomText from './CustomText';
import PlanetSmoothie from '../../assets/icons/creationFlow/planet-smoothie.svg';
import Decathlon from '../../assets/images/brands/decathlon.svg';
import HMs from '../../assets/icons/creationFlow/hnm.jpg';
import Unilever from '../../assets/icons/creationFlow/Unilever.png';
import HPLogo from '../../assets/icons/creationFlow/hp.png';
import Sabon from '../../assets/icons/creationFlow/Sabon.png';
interface IProps {
  type?: EAuthFlowType;
}

const LandingLeftContent: FC<IProps> = () => {
  return (
    <S.Container>
      <S.HeaderTitle>Create your</S.HeaderTitle>
      <S.HeaderTitle>first Bite today</S.HeaderTitle>
      {/* 1 */}
      <S.TextItemsContainer>
        <S.StoresTextContainer>
          <S.StoresText>• Average</S.StoresText>
          <S.StoresTextHighlight> engagement</S.StoresTextHighlight>
          <S.StoresText>~90%</S.StoresText>
        </S.StoresTextContainer>
        {/* 2 */}
        <S.StoresTextContainer>
          <S.StoresText>• Fast and </S.StoresText>
          <S.StoresTextHighlight>easy</S.StoresTextHighlight>
          <S.StoresText>tiktok-style content creation</S.StoresText>
        </S.StoresTextContainer>
        {/* 3 */}
        <S.StoresTextContainer>
          <S.StoresText>• </S.StoresText>
          <S.StoresTextHighlight>Zero</S.StoresTextHighlight>
          <S.StoresText>onboarding time</S.StoresText>
        </S.StoresTextContainer>

        <S.StoresTextContainer>
          <S.StoresText>• Share </S.StoresText>
          <S.StoresTextHighlight>anywhere</S.StoresTextHighlight>
        </S.StoresTextContainer>

        <S.StoresTextContainer>
          <S.StoresText>• Advanced </S.StoresText>
          <S.StoresTextHighlight>analytics</S.StoresTextHighlight>
        </S.StoresTextContainer>

        <S.ImageContainer>
          {/* @ts-ignore */}
          <S.ImageBanner source={HMs} resizeMode='contain' />
          <Decathlon height={70} width={70} />
          <PlanetSmoothie height={calcHeight(30)} width={calcWidth(60)} />
          <S.ImageBannerHp source={HPLogo} resizeMode='contain' />
          <S.ImageBannerUnilever source={Unilever} resizeMode='contain' />
          <S.ImageBannerSabon source={Sabon} resizeMode='contain' />
        </S.ImageContainer>

        <S.StoresTextContainerBottom>
          <S.StoresTextHighlightBottom>400+ </S.StoresTextHighlightBottom>
          <S.StoresTextBottom>happy customers</S.StoresTextBottom>
        </S.StoresTextContainerBottom>
      </S.TextItemsContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    z-index: 2;
  `,
  HeaderTitle: styled(CustomText)<{ bold?: boolean }>(
    ({ theme }) => css`
      color: ${theme.colors.primaryBlue};
      text-align: center;
      font-family: ${theme.fontFamilies.UniSans};
      font-size: ${theme.fontSizes.s32}px;
      letter-spacing: 0.8px;
      width: ${calcWidth(240)}px;
      display: flex;
      align-items: start;
    `,
  ),
  StoresTextContainer: styled.View`
    flex-direction: row;
    justify-content: center;
    margin-top: ${calcHeight(12)}px;
  `,
  StoresText: styled.Text`
    color: ${({ theme }) => theme.colors.darkGray6};
    font-size: ${calcFontSize(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Ubuntu};
    line-height: 19px;
  `,
  StoresTextHighlight: styled.Text`
    margin-right: ${calcHeight(4)}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${calcFontSize(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.UbuntuBold};
    line-height: 19px;
  `,
  TextItemsContainer: styled.View`
    display: flex;
    align-items: start;
    margin-top: ${calcHeight(30)}px;
    width: 340px;
  `,
  StoresTextHighlightBottom: styled.Text`
    margin-right: ${calcHeight(4)}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${calcFontSize(20)}px;
    font-family: ${({ theme }) => theme.fontFamilies.UbuntuBold};
    line-height: 19px;
  `,
  StoresTextBottom: styled.Text`
    color: ${({ theme }) => theme.colors.darkGray6};
    font-size: ${calcFontSize(20)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Ubuntu};
    line-height: 19px;
  `,
  StoresTextContainerBottom: styled.Text`
    margin-top: ${calcHeight(20)}px;
  `,
  ImageContainer: styled.View`
    margin-top: ${calcHeight(40)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${calcWidth(15)}px;
  `,
  ImageBanner: styled.Image`
    width: ${calcWidth(40)}px;
    height: ${calcHeight(50)}px;
  `,
  ImageBannerHp: styled.Image`
    width: ${calcWidth(40)}px;
    height: ${calcHeight(30)}px;
  `,
  ImageBannerUnilever: styled.Image`
    width: ${calcWidth(40)}px;
    height: ${calcHeight(30)}px;
  `,
  ImageBannerSabon: styled.Image`
    width: ${calcWidth(45)}px;
    height: ${calcHeight(30)}px;
  `,
};

export default LandingLeftContent;
