import { IUpdateSharingModeBody } from '../store/createBite/createBite.types';

export enum ESharePanelCallbackType {
  NEW_BITE = 'NEW_BITE',
  NEW_PLAYLIST = 'NEW_PLAYLIST',
  BITE_IN_LIST = 'BITE_IN_LIST',
  PLAYLIST_IN_LIST = 'PLAYLIST_IN_LIST',
  EDIT_BITE = 'EDIT_BITE',
}

export interface IChangeItemShareMode {
  itemId: number;
  from?: string;
  body: IUpdateSharingModeBody;
  callbackType?: ESharePanelCallbackType;
}
