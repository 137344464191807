import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BackHandler, StyleProp, ViewStyle } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { calcWidth, calcHeight, deviceHeight, isWeb } from '../../../utils/dimensions';
import { ControlledModal } from '../../../types/modals';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, authSelector, activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import EditableTextInput from './EditableTextInput';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import ChooseMediaModal from '../../../components/modals/ChooseMediaModal/ChooseMediaModal';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { colors, fontSizes } from '../../../themes/defaultTheme';
import {
  deleteMyUser,
  logout,
  setProfileImageUrlToUpload,
  updateUserProfileAction,
} from '../../../store/auth/auth.actions';
import PencilIcon from '../../../assets/icons/pencilIcon.svg';
import UserAvatar from '../../../assets/images/user-avatar.png';
import PhoneNumberInput from '../../../components/shared/PhoneNumberInput/PhoneNumberInput';
import { KeyboardAwareView } from '../../../components/shared';
import { getFileSize } from '../../../utils/files';
import GenericModal from '../../../components/modals/GenericModal/GenericModal';
import useMedia, { formatFileTypesForWeb } from '../../../hooks/useMedia';
import uploadImage from '../../../utils/uploadImage';
import ShadowedContainer from '../../../components/ShadowedContainer';
import useKeyboardHeight from '../../../hooks/useKeyboardHeight';
import { showBottomPanel } from '../../../store/bottomPanel/bottomPanel.slice';
import { EBottomPanelComponentName } from '../../../utils/constants/bottomPanel';
import ArrowCollapse from '../../../assets/icons/arrow-collapse.svg';
import i18n from 'i18next';
import languages from '../../../utils/languages';
import { IS_ANDROID } from '../../../utils/constants/env';
import { DRAWER_FORM_WIDTH } from '../../../utils/constants/drawer';
import BlueAndRedButtonsModal from '../../../components/modals/BlueAndRedButtonsModal';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../utils/constants/toastConfig';
import { logError } from '../../../store/appActivity/appActivity.slice';

interface IProps {
  isVisible: boolean;
}

const MIN_PHONE_LENGTH = 4;

const UPLOAD_PROFILE_IMAGE_PATH = 'upload_user_profile_image';
const FILE_SIZE_LIMIT = 10000000;
const localePrefix = 'appSettingsStack.profileScreen';

const Profile: React.FC<IProps> = ({ isVisible }) => {
  const theme = useTheme();
  const { prefixedT, t } = useCustomTranslation('appSettingsStack');
  const dispatch = useDispatch();
  const { keyboardHeight } = useKeyboardHeight();

  const activeOrganizationId = useSelector(activeOrganizationSelector).user_organization_id;
  const { profileImageUrlToUpload } = useSelector(authSelector);
  const user = useSelector(userSelector);

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isShowSizeLimitModal, setIsShowSizeLimitModal] = useState(false);
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(false);

  const [emailAddress, setEmailAddress] = useState(user?.email || '');
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [phone, setPhone] = useState('');

  const mediaModalRef = useRef<ControlledModal>(null);

  const hasFullName = !!user?.first_name && !!user?.last_name;
  const currentLanguage = languages.find((lang) => lang.lang === i18n.language)?.title;

  const handleDeleteUser = useCallback(() => {
    dispatch(deleteMyUser());
  }, [dispatch]);

  const openDeleteUserModal = useCallback(() => {
    setIsDeleteUserModalVisible(true);
  }, []);

  const closeDeleteUserModal = useCallback(() => {
    setIsDeleteUserModalVisible(false);
  }, []);

  const handleEditButtonPress = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const mediaControls = useMedia({
    onMediaSelectionCB: () => {
      mediaModalRef?.current?.close();
    },
    initialMediaUri: user?.profile_image,
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    from: 'profile',
  });

  const uploadProfileImageForNative = useCallback(async () => {
    const uri = mediaControls.mediaURI;
    if (!user || !uri || uri === user?.profile_image) {
      return;
    }
    try {
      const fileSize = await getFileSize(uri);
      if (fileSize > FILE_SIZE_LIMIT) {
        setIsShowSizeLimitModal(true);
        return;
      }
      setIsImageUploading(true);
      const { data } = await uploadImage({
        uri,
        type: 'image/jpeg',
        path: UPLOAD_PROFILE_IMAGE_PATH,
      });
      dispatch(setProfileImageUrlToUpload(data.image));
    } catch (error) {
      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
      dispatch(logError({ error }));
    }
    setIsImageUploading(false);
  }, [dispatch, mediaControls.mediaURI, user]);

  const uploadProfileImageForWeb = useCallback(async () => {
    const file = mediaControls.mediaFileForWeb;
    if (!file) {
      return;
    }
    if (file.size > FILE_SIZE_LIMIT) {
      setIsShowSizeLimitModal(true);
      return;
    }
    try {
      setIsImageUploading(true);
      const { data } = await uploadImage({
        file,
        type: 'image/jpeg',
        path: UPLOAD_PROFILE_IMAGE_PATH,
      });
      dispatch(setProfileImageUrlToUpload(data.image));
    } catch (error) {
      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
      dispatch(logError({ error }));
    }
    setIsImageUploading(false);
  }, [dispatch, mediaControls.mediaFileForWeb]);

  const updateUserProfile = useCallback(() => {
    dispatch(
      updateUserProfileAction({
        email: emailAddress || undefined,
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        phone: phone?.length > MIN_PHONE_LENGTH ? phone : undefined,
        profile_image: profileImageUrlToUpload || undefined,
        organizationId: activeOrganizationId,
      }),
    );
    setIsEditMode(false);
  }, [activeOrganizationId, dispatch, emailAddress, firstName, lastName, phone, profileImageUrlToUpload]);

  useEffect(() => {
    if (isWeb) {
      uploadProfileImageForWeb();
      return;
    }

    uploadProfileImageForNative();
  }, [uploadProfileImageForNative, uploadProfileImageForWeb]);

  useEffect(() => {
    if (hasFullName) {
      setPhone(user?.phone && user?.phone.length > MIN_PHONE_LENGTH ? user?.phone : '+1');
    } else {
      setPhone(null);
    }

    if (!hasFullName) {
      setIsEditMode(true);

      if (isWeb) {
        return;
      }
      const preventBackHandler = BackHandler.addEventListener('hardwareBackPress', () => true);
      return () => preventBackHandler?.remove();
    }
  }, [hasFullName, user?.phone]);

  useEffect(() => {
    if (hasFullName) {
      if (firstName?.length === 0 || lastName?.length === 0 || (phone && phone.length < 6 && phone !== '+1')) {
        setIsValid(false);
        return;
      }
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isEmailValid = re.test(String(emailAddress).toLowerCase());
      if (!isEmailValid) {
        setIsValid(false);
        return;
      }
    } else {
      if (firstName?.length === 0 || lastName?.length === 0) {
        setIsValid(false);
        return;
      }
    }
    setIsValid(true);
  }, [emailAddress, firstName?.length, hasFullName, lastName?.length, phone]);

  const handleLogout = useCallback(
    () =>
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.LogoutMenu,
          componentProps: {
            onLogout: () => dispatch(logout()),
            imageSource: mediaControls.mediaURI ? { uri: mediaControls.mediaURI } : UserAvatar,
          },
        }),
      ),
    [dispatch, mediaControls.mediaURI],
  );

  const handleLanguageButtonPress = useCallback(
    () =>
      dispatch(
        showBottomPanel({
          componentName: EBottomPanelComponentName.LanguageSelectMenu,
        }),
      ),
    [dispatch],
  );

  const handleCancelPress = useCallback(() => {
    setEmailAddress(user?.email || '');
    setFirstName(user?.first_name || '');
    setLastName(user?.last_name || '');

    if (hasFullName) {
      setPhone(user?.phone && user?.phone.length > MIN_PHONE_LENGTH ? user?.phone : '+1');
    } else {
      setPhone(null);
    }

    setIsEditMode(false);
  }, [hasFullName, user?.email, user?.first_name, user?.last_name, user?.phone]);

  const confirmButton = useMemo(
    () => ({
      content: t('common.ok'),
      onPress: () => setIsShowSizeLimitModal(false),
    }),
    [t],
  );

  useEffect(() => {
    if (!isVisible) {
      handleCancelPress();
    }
  }, [handleCancelPress, isVisible]);

  const renderProfile = () => (
    <S.ProfileContainer>
      <S.Container>
        <S.ProfileImageContainer>
          <S.ProfileImage
            source={mediaControls.mediaURI ? { uri: mediaControls.mediaURI } : UserAvatar}
            isSelectedImageUrl={!!mediaControls.mediaURI}
          />
          <ShadowedContainer containerViewStyle={S.PencilButtonShadowStyles}>
            <S.PencilButton onPress={mediaControls.launchImageLibrary}>
              <PencilIcon width={12} height={12} color={theme.colors.primaryBlue} />
            </S.PencilButton>
          </ShadowedContainer>
        </S.ProfileImageContainer>
        <S.Main
          contentContainerStyle={{
            alignItems: 'center',
          }}
        >
          <S.NameContainer>
            {isEditMode ? (
              <>
                <S.EditableTextInput
                  isEditMode
                  text={firstName}
                  placeholder={prefixedT('profileScreen.firstName')}
                  onTextChange={setFirstName}
                  fontSize={fontSizes.s28}
                />

                <S.EditableTextInput
                  isEditMode
                  text={lastName}
                  placeholder={prefixedT('profileScreen.lastName')}
                  onTextChange={setLastName}
                  fontSize={fontSizes.s28}
                />
              </>
            ) : (
              <S.EditableTextInput
                text={firstName + ' ' + lastName}
                placeholder={prefixedT('profileScreen.firstName')}
                onTextChange={setFirstName}
                fontSize={fontSizes.s28}
              />
            )}
          </S.NameContainer>
          {hasFullName && (
            <S.ContactDetailsContainer>
              <S.EditableTextInput
                isEditMode={isEditMode}
                text={emailAddress}
                placeholder={prefixedT('contactScreen.emailPlaceholder')}
                onTextChange={setEmailAddress}
                textPlaceholder={prefixedT('profileScreen.emptyEmail')}
              />
              <S.PhoneNumberInput
                onPhoneNumberChange={setPhone}
                phoneNumber={phone}
                isEditMode={isEditMode}
                textPlaceholder={t(`${localePrefix}.emptyPhone`)}
                onValidationChange={setIsValid}
              />
            </S.ContactDetailsContainer>
          )}
          {isEditMode ? (
            <S.ButtonsContainer>
              <GenericButton
                content={t('common.save')}
                color={colors.white}
                onPress={updateUserProfile}
                isLoading={isImageUploading}
                disabled={!isValid || isImageUploading}
                fontSize={13}
                isbold
              />
              {hasFullName && (
                <GenericButton
                  content={t('common.cancel').toUpperCase()}
                  borderColor={colors.white}
                  backgroundColor={colors.white}
                  color={colors.primaryBlue}
                  onPress={handleCancelPress}
                  fontSize={13}
                />
              )}
            </S.ButtonsContainer>
          ) : (
            <S.EditButtonContainer>
              <S.EditButton
                content={t('appSettingsStack.profileScreen.editDetails')}
                borderColor={colors.white}
                backgroundColor={colors.white}
                color={colors.primaryBlue}
                onPress={handleEditButtonPress}
                fontSize={fontSizes.s15}
              />
              <S.EditButton
                content={t('drawerNavigator.logout')}
                borderColor={colors.white}
                backgroundColor={colors.white}
                color={colors.primaryBlue}
                onPress={handleLogout}
                fontSize={fontSizes.s15}
              />
            </S.EditButtonContainer>
          )}
        </S.Main>
        <S.BottomButtons>
          <S.LineButton onPress={handleLanguageButtonPress}>
            <S.LineButtonText>{currentLanguage}</S.LineButtonText>
            <ArrowCollapse style={S.ArrowCollapseStyles} color={theme.colors.primaryBlue} />
          </S.LineButton>
          <S.LineButton onPress={openDeleteUserModal}>
            <S.LineButtonText>{t('drawerNavigator.deleteMyUser')}</S.LineButtonText>
          </S.LineButton>
        </S.BottomButtons>
      </S.Container>
      <BlueAndRedButtonsModal
        isVisible={isDeleteUserModalVisible}
        title={t('drawerNavigator.deleteUserModal.title')}
        titleStyles={{ color: theme.colors.failRed }}
        description={t('drawerNavigator.deleteUserModal.description')}
        onClose={closeDeleteUserModal}
        onRightButtonClick={handleDeleteUser}
        onLeftButtonClick={closeDeleteUserModal}
        rightButtonLabel={t('common.Delete')}
        leftButtonLabel={t('common.cancel')}
      />
      <ChooseMediaModal ref={mediaModalRef} mediaControls={mediaControls} photoOnly />
      {isShowSizeLimitModal && (
        <GenericModal isVisible confirmButton={confirmButton}>
          <S.UploadProfileImageFileSizeLimitModalMessage>
            {t('common.imageExceedSize')}
          </S.UploadProfileImageFileSizeLimitModalMessage>
        </GenericModal>
      )}
    </S.ProfileContainer>
  );

  return isWeb ? (
    <>
      {renderProfile()}
      {mediaControls.dropZoneUploadingForWeb}
    </>
  ) : (
    <KeyboardAwareView scrollEnabled={!!keyboardHeight}>{renderProfile()}</KeyboardAwareView>
  );
};

const PROFILE_IMAGE_CONTAINER_DIMENSIONS = calcHeight(100);
const PROFILE_IMAGE_CONTAINER_PADDING = calcHeight(9);

const S = {
  LineButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  LineButton: styled.TouchableOpacity`
    height: ${calcHeight(20)}px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  BottomButtons: styled.View`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: ${calcHeight(24)}px;
    padding-bottom: ${IS_ANDROID ? 0 : calcHeight(24)}px;
  `,
  ProfileContainer: styled.View`
    flex: 1;
    justify-content: center;
    height: ${isWeb ? '100%' : deviceHeight - 100};
  `,
  Container: styled.View`
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  `,
  Logo: styled.Image`
    height: ${calcHeight(52)}px;
    width: ${calcHeight(108)}px;
    margin-bottom: ${calcHeight(24)}px;
  `,
  ProfileImageContainer: styled.View`
    flex-direction: row;
    margin-top: ${calcHeight(45)}px;
    height: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS}px;
    width: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS}px;
    justify-content: center;
  `,
  ProfileImageInnerContainer: styled.View`
    top: -${calcHeight(16)};
    position: absolute;
    left: 50%;
    height: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS}px;
    width: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS}px;
    margin-left: ${-PROFILE_IMAGE_CONTAINER_DIMENSIONS / 2}px;
    padding: ${PROFILE_IMAGE_CONTAINER_PADDING}px;
    border-radius: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS / 2}px;
    z-index: 1;
  `,
  ProfileImage: styled.Image<{ isSelectedImageUrl?: boolean }>`
    border: solid 1px ${({ theme }) => theme.colors.darkGray4};
    border-radius: ${PROFILE_IMAGE_CONTAINER_DIMENSIONS / 2}px;
    width: 100%;
    height: 100%;
    z-index: 3;
    overflow: hidden;
    ${({ theme, isSelectedImageUrl }) => {
      return css`
        resize-mode: ${isSelectedImageUrl ? 'cover' : 'center'};
        border-color: ${theme.colors.gray20};
        border-width: ${isSelectedImageUrl ? calcHeight(1) : 0}px;
        background-color: ${isSelectedImageUrl ? theme.colors.defaultGrayBg : 'transparent'};
      `;
    }}
  `,
  PencilButton: styled.TouchableOpacity`
    width: ${calcHeight(24)}px;
    height: ${calcHeight(24)}px;
    border-radius: ${calcHeight(24) / 2}px;
    background-color: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
  `,
  Main: styled.ScrollView`
    flex: 1;
    width: 100%;
    position: relative;
  `,
  EditButtonContainer: styled.View`
    margin-top: ${calcHeight(33)}px;
  `,
  EditButton: styled(GenericButton)`
    height: ${calcHeight(52)}px;
  `,
  NameContainer: styled.View`
    width: ${DRAWER_FORM_WIDTH}px;
    margin-top: ${calcHeight(30)}px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  `,
  ContactDetailsContainer: styled.View`
    width: ${DRAWER_FORM_WIDTH}px;
    align-items: center;
  `,
  ContactDetailsDivider: styled.View`
    width: 100%;
    height: 1px;
    margin: ${calcHeight(12)}px 0;
    background-color: ${({ theme }) => theme.colors.lightGray7};
  `,
  ButtonsContainer: styled.View`
    height: ${calcHeight(120)}px;
    align-items: center;
    justify-content: space-between;
    margin-top: ${calcHeight(40)}px;
  `,
  UploadProfileImageFileSizeLimitModalMessage: styled.Text`
    margin: 40px auto 40px;
    text-align: center;
    font-size: 19px;
    color: ${({ theme }) => theme.colors.darkGray4};
  `,
  PhoneNumberInput: styled(PhoneNumberInput)`
    width: ${DRAWER_FORM_WIDTH}px;
  `,
  EditableTextInput: styled(EditableTextInput)`
    margin-bottom: ${({ isEditMode }) => (isEditMode ? calcHeight(24) : calcHeight(4))}px;
  `,
  PencilButtonShadowStyles: {
    bottom: calcHeight(-4),
    right: calcWidth(4),
    zIndex: 4,
    position: 'absolute',
  } as StyleProp<ViewStyle>,
  ArrowCollapseStyles: {
    width: calcWidth(12),
    height: calcWidth(12),
    marginLeft: calcWidth(3),
  } as StyleProp<ViewStyle>,
};

export default Profile;
