import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';
import AnyoneIcon from '../../../assets/icons/anyone.svg';
import RequireLoginIcon from '../../../assets/icons/require_login.svg';
import TeamOnlyIcon from '../../../assets/icons/team_only.svg';
import TeamOnlyAllowIcon from '../../../assets/icons/team_only_allow_join.svg';
import { EShareMode } from '../../../utils/constants/bite';
import { IShareViewOption } from './SharePanelViewOption';
import SharePanelFullScreenViewOption from './SharePanelFullScreenViewOption';
import SharePanelFullScreenAccessCodeForm from './SharePanelFullScreenAccessCodeForm';

interface IProps {
  sharingMode: EShareMode;
  accessCode: string;
  onChangeOption: (type: EShareMode) => void;
}

const SharePanelFullScreenViewSettings: React.FC<IProps> = (props) => {
  const { sharingMode, accessCode, onChangeOption } = props;

  const { t } = useTranslation();

  const viewOptions: IShareViewOption[] = [
    {
      type: EShareMode.ANYONE,
      title: t('share.shareTypeAnyoneTitle'),
      description: t('share.shareTypeAnyoneDesc'),
      Icon: AnyoneIcon,
    },
    {
      type: EShareMode.REQUIRE_LOGIN,
      secondType: EShareMode.TEAM_ONLY_ALLOW_JOIN,
      title: t('share.shareTypeRequireLoginTitle'),
      description: t('share.shareTypeRequireLoginDesc'),
      Icon: RequireLoginIcon,
    },
    {
      type: EShareMode.TEAM_ONLY,
      title: t('share.shareTypeTeamOnlyTitle'),
      description: t('share.shareTypeTeamOnlyDesc'),
      Icon: TeamOnlyAllowIcon,
    },
    {
      type: EShareMode.NO_ONE,
      title: t('share.shareTypeNoOneTitle'),
      description: t('share.shareTypeNoOneDesc'),
      Icon: TeamOnlyIcon,
    },
  ];

  return (
    <S.Container contentContainerStyle={NativeStyles.Container}>
      {viewOptions.map((option) => {
        return (
          <SharePanelFullScreenViewOption
            key={option.type}
            onPress={onChangeOption}
            sharingMode={sharingMode}
            isSelected={[option.type, option.secondType].includes(sharingMode)}
            isExpanded={option.secondType === sharingMode}
            {...option}
          >
            {option.secondType === EShareMode.TEAM_ONLY_ALLOW_JOIN && (
              <SharePanelFullScreenAccessCodeForm
                sharingMode={sharingMode}
                accessCode={accessCode}
                onChange={onChangeOption}
              />
            )}
          </SharePanelFullScreenViewOption>
        );
      })}
    </S.Container>
  );
};

const NativeStyles = StyleSheet.create({
  Container: {
    alignItems: 'center',
  },
});

const S = {
  Container: styled.ScrollView`
    width: 100%;
  `,
};

export default memo(SharePanelFullScreenViewSettings);
