import { RootState } from '../index';

export const shareDataSelector = (state: RootState) => state.sharePanel.data;
export const shareItemTypeSelector = (state: RootState) => state.sharePanel.itemType;
export const shareItemIsInspirationBiteSelector = (state: RootState) => state.sharePanel.isInspirationBite;
export const shareViewModeSelector = (state: RootState) => state.sharePanel.viewMode;
export const sharePanelVisibleSelector = (state: RootState) => state.sharePanel.isVisible;
export const sharePanelIsHidingSelector = (state: RootState) => state.sharePanel.isHiding;
export const sharePanelCallbackTypeSelector = (state: RootState) => state.sharePanel.callbackType;
export const sharePanelFromSelector = (state: RootState) => state.sharePanel.from;
