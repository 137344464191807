import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-community/async-storage';
import { isRtlByLang, switchDirection, TLocale } from '../locale/i18n';
import { isWeb } from '../utils/dimensions';
import { I18nManager } from 'react-native';
import { useSelector } from 'react-redux';
import { gtmSetUserProps } from '../services/gtm/track';
import { userSelector } from '../store/auth/auth.selectors';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/es';

type TPreferedLanguage = TLocale | null;

function getLng() {
  if (!isWeb) {
    return I18nManager.isRTL ? 'he' : 'en';
  }
  return localStorage.getItem('language') || 'en';
}

const useI18n = () => {
  const { i18n, t } = useTranslation();
  const user = useSelector(userSelector);

  dayjs.locale(i18n.language);

  const [initPreferedLangFinished, setInitPreferedLangFinished] = useState(false);

  const changeLanguage = async (lang: TLocale) => {
    if (lang === i18n.language) {
      return;
    }

    await i18n.changeLanguage(lang);
    await AsyncStorage.setItem('language', lang);
    switchDirection(lang, true);
    gtmSetUserProps(user.id, {
      language: lang,
    });
  };

  const initPreferedLanguage = async () => {
    let lang = (await AsyncStorage.getItem('language')) as TPreferedLanguage;
    lang = lang && isRtlByLang.hasOwnProperty(lang) ? lang : (getLng() as TPreferedLanguage);
    await i18n.changeLanguage(lang);
    switchDirection(lang);
    await AsyncStorage.setItem('language', lang);
    setInitPreferedLangFinished(true);
  };

  return {
    changeLanguage,
    i18n,
    t,
    initPreferedLanguage,
    initPreferedLangFinished,
  };
};

export default useI18n;
