import React, { useCallback, useEffect, useRef, useState } from 'react';
import last from 'lodash/last';
import { activateKeepAwake } from '@sayem314/react-native-keep-awake';
import {
  EExportType,
  getDrafts,
  IBanubaDraft,
  openVideoEditor,
  openVideoEditorWithDraft,
} from '../../services/videoEditor/videoEditor';
import { openPhotoEditor } from '../../services/photoEditor/photoEditor';
import { getErrorMessage } from '../../utils/general';
import { galleryOptions, imageGalleryOptions, photoOptions } from './imagePickerOptions';
import { Alert, Platform } from 'react-native';
import RNFetchBlob from 'rn-fetch-blob';
import shortid from 'shortid';
import {
  EFileType,
  IMedia,
  MediaController,
  MediaType,
  PickerError,
  TLaunchVideoEditor,
  YoutubeMedia,
} from '../../types/media';
import BitesApi, { API_URL } from '../../store/api/bites-api/index';
import Dropzone from 'react-dropzone';
import { isWeb } from '../../utils/dimensions';
import useToken from '../useToken';
import { useTranslation } from 'react-i18next';
import useErrorAlert from '../useErrorAlert';
import { IMAGE_CACHE_DIR, IS_IOS } from '../../utils/constants/env';
import { useDispatch, useSelector } from 'react-redux';
import { activeOrganizationSelector } from '../../store/auth/auth.selectors';
import { youtubeUrlParser } from '../../utils/youtubeUrlParser';
import { logError, trackEvent } from '../../store/appActivity/appActivity.slice';
import { IEnhancement } from '../../types/bite';
import { useIsMounted } from '../useIsMounted';
import { decode } from 'base-64';
import { MAX_MEDIA_SIZE } from './constants';
import getLocalUrlPath from '../../utils/getLocalUrlPath';
import ImagePicker from '../../services/ImagePicker';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../utils/constants/toastConfig';
import uploadToS3 from '../../utils/uploadToS3';
import { setBiteIntroDuration } from '../../store/createBite/createBites.actions';
import getPlatformUri from '../../utils/getPlatformUri';

interface MediaOptions {
  onMediaSelectionCB?: (uri: string, type?: EFileType, editedImageUri?: string) => void;
  onYoutubeSuccess?: (media: YoutubeMedia) => void;
  initialMediaUri?: string;
  initialMedia?: IMedia | IEnhancement;
  fileTypesForWeb: Record<string, string[]>;
  maxFileSize?: number; // max file size in bytes
  from?: string;
  withVideoEditorDrafts?: boolean;
  dropzoneDataset?: { [key: string]: string };
}

export interface IVideoMeta {
  duration?: number;
  size?: number;
}

const useMedia = ({
  onMediaSelectionCB,
  onYoutubeSuccess,
  initialMediaUri = null,
  initialMedia = null,
  fileTypesForWeb,
  maxFileSize = MAX_MEDIA_SIZE,
  from,
  withVideoEditorDrafts,
  dropzoneDataset,
}: MediaOptions): MediaController => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useToken();
  const { onError } = useErrorAlert();
  const [mediaFileForWeb, setMediaFileForWeb] = useState(null);
  const webUpload = useRef(null);
  const [media, setMedia] = useState<IMedia>(initialMedia);
  const [mediaURI, setMediaURI] = useState<string | null>(initialMediaUri);
  const [videoMeta, setVideoMeta] = useState<IVideoMeta>(null);
  const [mediaType, setMediaType] = useState<MediaType>(initialMedia?.file_type || null);
  const [mimeType, setMimeType] = useState('');
  const [editorError, setEditorError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [lastDraft, setLastDraft] = useState<IBanubaDraft>(null);
  const [isMediaSizeExceeded, setIsMediaSizeExceeded] = useState(null);
  const org = useSelector(activeOrganizationSelector);
  const isMountedRef = useIsMounted();
  const launchRef = useRef(null);
  const isLaunchingVideoEditorRef = useRef(false);
  const initialDraftsRef = useRef<IBanubaDraft[]>([]);

  const [youtubeMediaState, setYoutubeMediaState] = useState<null | YoutubeMedia>(
    initialMedia?.file_type === EFileType.YOUTUBE
      ? {
          url: initialMedia?.media_url,
          video_start_at: initialMedia?.video_start_at,
          video_end_at: initialMedia?.video_ends_at,
          is_cc_enabled: initialMedia?.is_cc_enabled,
        }
      : null,
  );

  const updateInitialDrafts = useCallback(async () => {
    if (isWeb) {
      return;
    }
    const drafts = await getDrafts();
    initialDraftsRef.current = drafts;
    setLastDraft(null);
  }, []);

  useEffect(() => {
    if (withVideoEditorDrafts) {
      updateInitialDrafts();
    }
  }, [updateInitialDrafts, withVideoEditorDrafts]);

  useEffect(() => {
    setMediaURI(initialMediaUri);
  }, [initialMediaUri]);

  useEffect(() => {
    setMedia(initialMedia);

    if (initialMedia?.file_type === EFileType.YOUTUBE) {
      setYoutubeMediaState({
        url: initialMedia?.media_url,
        video_start_at: initialMedia?.video_start_at,
        video_end_at: initialMedia?.video_ends_at,
        is_cc_enabled: initialMedia?.is_cc_enabled,
      });
    }
  }, [initialMedia]);

  useEffect(() => {
    setMediaType(initialMedia?.file_type);
  }, [initialMedia?.file_type]);

  const onMediaSelectionCancel = useCallback(
    (error: PickerError) => {
      if (error.code === 'E_PICKER_CANCELLED') {
        return;
      }

      setEditorError(true);
      onError(getErrorMessage(error, ''));
    },
    [setEditorError, onError],
  );

  const handleMediaSelectionCB = (editedImageUri, responsePath, responseMime) => {
    if (typeof launchRef.current === 'function') {
      launchRef.current(editedImageUri, () => {
        if (!isMountedRef.current) {
          return;
        }
        onMediaSelectionCB?.(responsePath, responseMime, editedImageUri);
      });
      return;
    }
    onMediaSelectionCB?.(responsePath, responseMime, editedImageUri);
  };

  const onMediaSelection = (response) => {
    if (response.mime === 'image/gif') {
      isWeb ? alert(t('common.uploadedGifMessage')) : Alert.alert(t('common.uploadedGifMessage'));
      return;
    }
    setEditorError(false);
    setYoutubeMediaState(null);
    setIsMediaSizeExceeded(null);

    if (response.mime?.includes('image')) {
      openPhotoEditor({
        uri: response?.path,
        onSuccess: (editedImageUri) => {
          setMediaType(EFileType.IMAGE);
          setMimeType(response.mime);
          setMediaURI(editedImageUri);
          setIsMediaSizeExceeded(response.size > maxFileSize);

          handleMediaSelectionCB(editedImageUri, response?.path, response?.mime);
        },
        onError: () => {
          setEditorError(true);
        },
      });

      return;
    }
  };

  const onWebFileUpload = ([file]) => {
    const _mediaType = file.type.split('/')[0];
    const editedImageUri = URL.createObjectURL(file);
    setYoutubeMediaState(null);
    setMediaType(_mediaType);
    setMediaURI(editedImageUri);
    setMediaFileForWeb(file);
    setIsMediaSizeExceeded(file.size > maxFileSize);
    if (_mediaType === 'video') {
      setVideoMeta({
        size: file.size / 1000000, // b -> mb
      });
    }
    handleMediaSelectionCB(editedImageUri, file, undefined);
  };

  const uploadYoutube = async () => {
    if (!(mediaURI || youtubeMediaState)) {
      return;
    }
    setUploadError(false);
    const formdata = new FormData();
    formdata.append('file', youtubeMediaState?.url);
    formdata.append('file_type', mediaType);
    // @ts-ignore
    formdata.append('video_start_at', youtubeMediaState?.video_start_at);
    // @ts-ignore
    formdata.append('video_ends_at', youtubeMediaState?.video_end_at);
    // @ts-ignore
    formdata.append('is_cc_enabled', youtubeMediaState?.is_cc_enabled);
    const { data } = await BitesApi.post('/media/', formdata, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const videoId = youtubeUrlParser(data.media_url);
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    return { media: data as IMedia, thumbnailUrl };
  };

  const uploadImageForWeb = useCallback(() => {
    const formdata = new FormData();
    const fileName = shortid.generate() + '.' + last(mediaFileForWeb.name.split('/'));
    formdata.append('file', mediaFileForWeb, fileName);
    formdata.append('file_type', mediaType);

    return BitesApi.post('/media/', formdata, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }, [mediaFileForWeb, mediaType]);

  const uploadImageForNative = useCallback(async () => {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const { data } = await RNFetchBlob.fetch('POST', `${API_URL}/media/`, headers, [
      {
        name: 'file',
        filename: generateFileName(mediaURI, mimeType),
        data: RNFetchBlob.wrap(getPlatformUri(mediaURI)),
      },
      { name: 'file_type', data: mediaType },
      { name: 'media_url', data: '' }, // TODO remove after fixed with be
    ]);

    return {
      data: JSON.parse(data),
    };
  }, [token, mediaURI, mimeType, mediaType]);

  const uploadImage = useCallback(async () => {
    try {
      if (!(mediaURI || youtubeMediaState)) {
        return;
      }

      setUploadError(false);

      const { data } = isWeb ? await uploadImageForWeb() : await uploadImageForNative();

      return { media: data };
    } catch (error) {
      dispatch(
        logError({
          event: 'uploadImageForWeb: errpr',
          error,
        }),
      );

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }
  }, [mediaURI, youtubeMediaState, uploadImageForWeb, uploadImageForNative, dispatch]);

  const uploadVideo = async () => {
    if (!(mediaURI || youtubeMediaState)) {
      return;
    }
    setUploadError(false);

    const { taskId } = await uploadToS3({
      uri: mediaURI,
      file: mediaFileForWeb,
      orgId: org.id,
      endpoint: '/s3_upload_url/',
      multiPartEndpoints: {
        preSignEndpoint: '/presign_multi_part_upload_urls/',
        completeEndpoint: '/complete_multi_part_upload/',
      },
      mediaType: EFileType.VIDEO,
    });

    return { taskId, mediaUri: mediaURI };
  };

  const handleSelectOnlineMediaWeb = useCallback(
    async (url: string) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const contentType = blob?.type || 'image/png';
        const file = new File([blob], `${shortid.generate()}.${contentType.split('/')[1]}`, { type: contentType });

        setMediaFileForWeb(file);

        return {
          type: contentType,
          uri: URL.createObjectURL(file),
        };
      } catch (error) {
        dispatch(logError({ error }));
      }
    },
    [dispatch],
  );

  const handleSelectOnlineMediaNative = useCallback(
    async (url: string) => {
      try {
        const cacheExists = await RNFetchBlob.fs.exists(IMAGE_CACHE_DIR);

        if (cacheExists) {
          await RNFetchBlob.fs.unlink(IMAGE_CACHE_DIR);
        }

        const response = await RNFetchBlob.fetch('GET', url);
        const contentType = response?.info()?.headers?.['content-type'] || 'image/png';
        const path = `${IMAGE_CACHE_DIR}/${shortid.generate()}.${contentType.split('/')[1]}`;

        await RNFetchBlob.fs.writeFile(path, response.data, 'base64');

        return {
          type: contentType,
          uri: `file://${path}`,
        };
      } catch (error) {
        dispatch(
          logError({
            event: 'handleSelectOnlineMediaNative: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    [dispatch],
  );

  const handleSelectOnlineMedia = useCallback(
    async (url: string) => {
      const image = isWeb ? await handleSelectOnlineMediaWeb(url) : await handleSelectOnlineMediaNative(url);

      setMimeType(image.type);
      setMediaType(EFileType.IMAGE);
      setMediaURI(image.uri);
      onMediaSelectionCB(url, EFileType.IMAGE, image.uri);
    },
    [onMediaSelectionCB, handleSelectOnlineMediaWeb, handleSelectOnlineMediaNative],
  );

  const setYoutubeMedia = (youtubeMediaPayload: YoutubeMedia) => {
    setMimeType(null);
    setMediaURI(null);
    setMediaType(EFileType.YOUTUBE);
    setYoutubeMediaState(youtubeMediaPayload);
    setIsMediaSizeExceeded(false);

    if (typeof onYoutubeSuccess === 'function') {
      onYoutubeSuccess(youtubeMediaPayload);
    }
  };

  const setGoogleSlidesMedia = (newMedia) => {
    setMimeType(null);
    setMediaURI(newMedia.media_url);
    setMedia(newMedia);
    setMediaType(EFileType.GOOGLE_SLIDES);
    setYoutubeMediaState(null);
    setIsMediaSizeExceeded(false);
  };

  const clearState = () => {
    setMediaURI(null);
    setMediaType(null);
    setMimeType(null);
    setVideoMeta(null);
    setYoutubeMediaState(null);
    setIsMediaSizeExceeded(null);

    dispatch(setBiteIntroDuration(null));

    if (withVideoEditorDrafts) {
      updateInitialDrafts();
    }
  };

  const updateLastDraft = useCallback(async () => {
    const drafts = await getDrafts();
    const initialDraftsMap = initialDraftsRef.current.reduce((acc, curr) => ({ ...acc, [curr.sequenceId]: curr }), {});
    const newDraft = drafts.filter((draft) => !initialDraftsMap[draft.sequenceId]);

    if (newDraft.length === 1) {
      setLastDraft(newDraft[0]);
      return;
    }

    setLastDraft(null);
  }, []);

  const launchVideoEditor: TLaunchVideoEditor = async ({
    entryPoint,
    draftId,
    videoUrl,
    localVideoUri,
    exportType = EExportType.VIDEO_SYNCHRONOUS,
  } = {}) => {
    try {
      if (isLaunchingVideoEditorRef.current) {
        return;
      }
      isLaunchingVideoEditorRef.current = true;

      setIsMediaSizeExceeded(null);
      dispatch(
        trackEvent({
          event: 'add_media',
          props: { media_type: 'create_media', from },
        }),
      );

      dispatch(
        trackEvent({
          event: 'video_editor_open',
          props: {
            from,
            entry_point: entryPoint,
            with_draft: !!draftId,
          },
        }),
      );

      let videoEditorResult = null;
      if (draftId) {
        videoEditorResult = await openVideoEditorWithDraft({ sequenceId: draftId, exportType });
      } else {
        const videoURI = localVideoUri
          ? localVideoUri
          : videoUrl
          ? await (
              await getLocalUrlPath(videoUrl)
            ).promise
          : undefined;

        videoEditorResult = await openVideoEditor({
          entryPoint,
          videoURIs: videoURI ? [videoURI] : undefined,
          exportType,
        });
      }
      isLaunchingVideoEditorRef.current = false;
      activateKeepAwake();

      if (!videoEditorResult) {
        setIsMediaSizeExceeded(false);
        return;
      }

      updateLastDraft();
      setYoutubeMediaState(null);
      setMediaType(EFileType.VIDEO);

      dispatch(setBiteIntroDuration(null));

      if (videoEditorResult.videoUri) {
        applyVideo(videoEditorResult.videoUri);
        testVideoUriSize(videoEditorResult.videoUri);

        // currently we both return result
        // (which is needed for audio async flow with full banuba response)
        // and have onMediaSelectionCB
        // probably we should remove return and rely on onMediaSelectionCB
        // but this will require refactoring across the app:
        // different data is expected in different places
        onMediaSelectionCB?.(videoEditorResult.videoUri, EFileType.VIDEO);
      }

      let newVideoMeta = null;
      if (videoEditorResult.analytics) {
        newVideoMeta = {
          duration: videoEditorResult.analytics.video_duration,
        };
        setVideoMeta(newVideoMeta);
      }

      return {
        ...videoEditorResult,
        videoMeta: newVideoMeta,
      };
    } catch (error) {
      isLaunchingVideoEditorRef.current = false;
      setEditorError(true);
      onMediaSelectionCancel(error);
    }
  };
  const applyVideo = (videoUri) => {
    if (videoUri === mediaURI) {
      setMediaURI(null);
      setTimeout(() => {
        if (!isMountedRef.current) {
          return;
        }
        setMediaURI(videoUri);
      }, 0);
    } else {
      setMediaURI(videoUri);
    }
  };
  const testVideoUriSize = async (videoUri) => {
    if (isWeb) {
      const file = await RNFetchBlob.config({ fileCache: false }).fetch('GET', videoUri, {});
      const base64 = await file.base64();
      const size = decode(base64.substring(base64.indexOf(',') + 1)).length;
      setIsMediaSizeExceeded(size > maxFileSize);
      return;
    }

    setIsMediaSizeExceeded(false);
  };

  const launchImageCamera = (handlePreviewModal?) => {
    launchRef.current = handlePreviewModal;
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'camera' },
      }),
    );
    ImagePicker.openCamera(photoOptions).then(onMediaSelection).catch(onMediaSelectionCancel);
  };

  const launchLibrary = () => {
    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'upload_media', from },
      }),
    );
    if (isWeb) {
      webUpload.current.open();
      return;
    }
    ImagePicker.openPicker(galleryOptions).then(onMediaSelection).catch(onMediaSelectionCancel);
  };

  const launchImageLibrary = async (handlePreviewModal?) => {
    launchRef.current = handlePreviewModal;

    dispatch(
      trackEvent({
        event: 'add_media',
        props: { media_type: 'upload_media', from },
      }),
    );
    if (isWeb) {
      webUpload.current.open();
      return;
    }
    return ImagePicker.openPicker(imageGalleryOptions).then(onMediaSelection).catch(onMediaSelectionCancel);
  };

  const dropZoneUploadingForWeb =
    Platform.OS === 'web' ? (
      <Dropzone ref={webUpload} onDrop={onWebFileUpload} accept={fileTypesForWeb}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ display: 'none' }}>
            <input {...getInputProps()} data-cy={dropzoneDataset?.cy} />
          </div>
        )}
      </Dropzone>
    ) : null;

  return {
    media,
    mediaURI,
    mediaType,
    mimeType,
    videoMeta,
    youtubeMedia: youtubeMediaState,
    editorError,
    uploadYoutube,
    uploadVideo,
    uploadImage,
    uploadError,
    clearState,
    launchVideoEditor,
    launchImageCamera,
    launchLibrary,
    launchImageLibrary,
    setYoutubeMedia,
    setGoogleSlidesMedia,
    dropZoneUploadingForWeb,
    mediaFileForWeb,
    lastDraft,
    isMediaSizeExceeded,
    setIsMediaSizeExceeded,
    handleSelectOnlineMedia,
  };
};

export default useMedia;

function generateFileName(mediaURI: string, mimeType: string) {
  const fileSplitName = mediaURI!.split('.');
  const fileExtension = IS_IOS ? mimeType.split('/')[1] : fileSplitName[fileSplitName.length - 1];
  return `${shortid.generate()}.${fileExtension}`;
}

interface IFormatFileTypesArgs {
  video?: boolean | string[];
  image?: boolean | string[];
  audio?: boolean | string[];
  pdf?: boolean | string[];
  ppt?: boolean | string[];
  doc?: boolean | string[];
}

// https://react-dropzone.js.org/#section-accepting-specific-file-types
export const formatFileTypesForWeb = ({
  video,
  image,
  audio,
  pdf,
  ppt,
  doc,
}: IFormatFileTypesArgs): Record<string, string[]> => {
  if (!video && !image) {
    throw new Error('must supply video or image');
  }

  const result: any = {};
  if (video) {
    if (video === true) {
      result['video/*'] = [];
    } else {
      result['video/*'] = video;
    }
    // result.video = video === true ? ['.avi', '.mp4', '.mpeg', '.ogv', '.ts', '.webm', '.3gp', '.3g2'] : video;
  }
  if (image) {
    if (image === true) {
      result['image/*'] = [];
    } else {
      result['image/*'] = image;
    }
    // result.image =
    //   image === true ? ['.png', '.jpg', '.jpeg', '.webp', '.avif', '.bmp', '.ico', '.svg', '.tif', 'tiff'] : image;
  }
  if (audio) {
    if (audio === true) {
      result['audio/*'] = [];
    } else {
      result['audio/*'] = audio;
    }
    // result.audio =
    //   audio === true
    //     ? ['.aac', '.mid', '.midi', '.mp3', '.mp3', '.opus', '.weba', '.wav', '.wav', '.3g2', '.3gp']
    //     : audio;
  }

  if (pdf) {
    if (pdf === true) {
      result['application/pdf'] = [];
    } else {
      result['application/pdf'] = pdf;
    }
  }

  // ppt or pptx
  if (ppt) {
    if (ppt === true) {
      result['application/vnd.ms-powerpoint'] = []; // ppt
      result['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = []; // pptx
    } else {
      result['application/vnd.ms-powerpoint'] = ppt;
      result['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = ppt;
    }
  }

  // doc or docx
  if (doc) {
    if (doc === true) {
      result['application/msword'] = []; // doc
      result['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = []; // docx
    } else {
      result['application/msword'] = doc;
      result['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = doc;
    }
  }

  return result;
};
