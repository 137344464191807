import React from 'react';
import LottieView from 'lottie-react';
import { calcWidth } from '../../../utils/dimensions';
import styled from 'styled-components';
import { I18nManager } from 'react-native';

const CreationAnimation = () => {
  return <S.LottieView animationData={require('../../../assets/lottie/creation-bite-lottie.json')} autoPlay loop />;
};

const S = {
  LottieView: styled(LottieView)`
    width: ${calcWidth(450)}px;
    margin-right: ${I18nManager.isRTL ? 360 : 0}px;
  `,
};

export default CreationAnimation;
