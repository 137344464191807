import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import { calcHeight } from '../../../utils/dimensions';
import { ActivityIndicator } from 'react-native';

type Props = {
  url: string;
  item: any;
  isLoading?: boolean;
  onSelect: (item: any) => void;
};
const ImageSearchItem = ({ url, item, isLoading, onSelect }: Props) => {
  // url =
  //   item.originalSrc === 'pexels'
  //     ? item.originalData.src.original
  //     : item.originalSrc === 'giphy'
  //     ? item.originalData.images.original.url
  //     : url;

  const handlePress = useCallback(() => {
    onSelect(item);
  }, [item, onSelect]);

  return (
    <>
      <S.Container isLoading={isLoading} onClick={!isLoading ? handlePress : undefined}>
        <S.Image src={url} />

        {isLoading && (
          <S.LoadingOverlay>
            <ActivityIndicator size='large' color='white' />
          </S.LoadingOverlay>
        )}
      </S.Container>
    </>
  );
};
export default memo(ImageSearchItem);

export const S = {
  Container: styled.div<{ isLoading: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    padding: 0px ${calcHeight(8)}px ${calcHeight(8)}px;
    overflow: hidden;
    ${({ isLoading }) => (!isLoading ? 'cursor: pointer;' : '')}
  `,
  Image: styled.img`
    width: 100%;
  `,
  LoadingOverlay: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  `,
};
