import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import BaseModal from '../modals/ModalController';
import OrganizationMenu from './components/OrganizationMenu/OrganizationMenu';
import { clearBottomPanel, hideBottomPanel } from '../../store/bottomPanel/bottomPanel.slice';
import { bottomPanelDataSelector, bottomPanelVisibleSelector } from '../../store/bottomPanel/bottomPanel.selectors';
import { IPanelChildInjectedProps } from '../../store/bottomPanel/bottomPanel';
import { calcHeight, calcWidth, deviceHeight, isWeb, WEB_CONTAINER_WIDTH } from '../../utils/dimensions';
import { EBottomPanelComponentName } from '../../utils/constants/bottomPanel';
import LanguageSelectMenu from './components/LanguageSelectMenu/LanguageSelectMenu';
import { Direction } from 'react-native-modal';
import LogoutMenu from '../../screens/appSettings/Profile/LogoutMenu';
import OrganizationSelectMenu from './components/OrganizationSelectMenu';
import { WEB_DRAWER_WIDTH } from '../../utils/constants/drawer';
import ImportMediaMenu from './components/ImportMediaMenu/ImportMediaMenu';
import FilterMenu from './components/FilterMenu/FilterMenu';

const MAX_CONTENT_HEIGHT = calcHeight(deviceHeight - 90);

const BottomPanel: FC = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(bottomPanelVisibleSelector);
  const { componentName, componentProps } = useSelector(bottomPanelDataSelector);
  const panelActionRef = useRef<() => (Promise<any> | void) | null>(null);
  const { onSwipeUp, onSwipeDown, withoutHandle, onModalHide } = componentProps;

  const handlePanelHide = useCallback(async () => {
    if (typeof panelActionRef.current === 'function') {
      await panelActionRef.current();
    }
    panelActionRef.current = null;
    dispatch(clearBottomPanel());

    if (typeof onModalHide === 'function') {
      onModalHide();
    }
  }, [dispatch, onModalHide]);

  const handlePanelClose = useCallback(() => {
    dispatch(hideBottomPanel());
  }, [dispatch]);

  const handlePanelAction = useCallback(
    (action: () => Promise<any> | void) => {
      panelActionRef.current = action;
      handlePanelClose();
    },
    [handlePanelClose],
  );

  const Component = useMemo<FC<IPanelChildInjectedProps> | null>(() => {
    switch (componentName) {
      case EBottomPanelComponentName.OrganizationMenu:
        return OrganizationMenu;
      case EBottomPanelComponentName.LanguageSelectMenu:
        return LanguageSelectMenu;
      case EBottomPanelComponentName.LogoutMenu:
        return LogoutMenu;
      case EBottomPanelComponentName.OrganizationSelectMenu:
        return OrganizationSelectMenu;
      case EBottomPanelComponentName.ImportMedia:
        return ImportMediaMenu;
      case EBottomPanelComponentName.AnalyticsFilter:
        return FilterMenu;
      default:
        return null;
    }
  }, [componentName]);

  const containerPosition: { width?: number; left?: number } = useMemo(() => {
    if (!isWeb) {
      return {};
    }

    switch (componentName) {
      case EBottomPanelComponentName.LanguageSelectMenu:
      case EBottomPanelComponentName.LogoutMenu:
        return {
          width: calcWidth(WEB_DRAWER_WIDTH + 120),
          left: calcWidth(WEB_DRAWER_WIDTH + 20),
        };
      case EBottomPanelComponentName.AnalyticsFilter:
        return {
          width: calcWidth(WEB_CONTAINER_WIDTH),
          left: calcWidth(WEB_DRAWER_WIDTH + 20),
        };
      default:
        return {};
    }
  }, [componentName]);

  const handleSwipeDown = useCallback(() => {
    if (typeof onSwipeDown === 'function') {
      onSwipeDown();
      return;
    }
    handlePanelClose();
  }, [onSwipeDown, handlePanelClose]);

  const handleSwipeUp = useCallback(() => {
    if (typeof onSwipeUp === 'function') {
      onSwipeUp();
    }
  }, [onSwipeUp]);

  const onSwipeComplete = useCallback(
    ({ swipingDirection }) => {
      if (swipingDirection === 'down') {
        handleSwipeDown();
      }

      if (swipingDirection === 'up') {
        handleSwipeUp();
      }
    },
    [handleSwipeDown, handleSwipeUp],
  );

  const swipeDirection = useMemo(() => {
    let directions: Direction[] = ['down'];

    if (typeof onSwipeUp === 'function') {
      directions.push('up');
    }

    return directions;
  }, [onSwipeUp]);

  return (
    <S.Modal
      isVisible={isVisible}
      backdropOpacity={0.4}
      onModalHide={handlePanelHide}
      onBackdropPress={handlePanelClose}
      onSwipeComplete={onSwipeComplete}
      swipeDirection={swipeDirection}
      hideModalContentWhileAnimating
      useNativeDriver
      propagateSwipe
    >
      <S.Container width={containerPosition.width} left={containerPosition.left}>
        <S.RoundedWrapper>
          {!withoutHandle && <S.Handle />}

          {Component && <Component {...componentProps} onClosePanel={handlePanelAction} />}
        </S.RoundedWrapper>
      </S.Container>
    </S.Modal>
  );
};

const Modal = styled(BaseModal)`
  justify-content: flex-end;
  margin: 0;
`;

const Container = styled.View<{ width?: number; left?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  left: ${({ left }) => (left ? `${left}px` : '0px')};
  justify-content: flex-end;
`;

const RoundedWrapper = styled.View`
  width: 100%;
  max-height: ${MAX_CONTENT_HEIGHT}px;
  border-top-left-radius: ${calcWidth(32)}px;
  border-top-right-radius: ${calcWidth(32)}px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${calcHeight(12)}px;
  align-items: center;
  justify-content: flex-start;
`;

const Handle = styled.View`
  width: ${calcWidth(46)}px;
  height: ${calcHeight(4)};
  border-radius: ${calcWidth(48)}px;
  background-color: ${({ theme }) => theme.colors.gray7};
`;

const S = {
  Modal,
  Container,
  RoundedWrapper,
  Handle,
};

export default memo(BottomPanel);
