import React, { FC, useState, useMemo, useCallback } from 'react';
import styled, { css, useTheme, DefaultTheme } from 'styled-components/native';
import { RenderItemParams } from 'react-native-draggable-flatlist';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { Alert, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { CustomText } from '../../../components/shared';
import QuestionOptions from './QuestionOptions';
import ThreeDotsSvg from '../../../assets/icons/three-dots.svg';
import { ISelectedBite } from '../../../store/createPlaylist/createPlaylist.types';
import BottomButtonsModal, { IButtonsConfig } from '../../../components/modals/BottomButtonsModal/BottomButtonsModal';
import { fetchBiteToEditRequest, deleteBite } from '../../../store/bite/bite.actions';
import { useDispatch } from 'react-redux';
import { removeSelectedBite, updatePlaylist } from '../../../store/createPlaylist/createPlaylist.actions';
import Routes from '../../../navigation/routes';
import QuestionIcon from '../../../assets/icons/creationFlow/question-icon.svg';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  renderItemParams: RenderItemParams<ISelectedBite>;
  isOwner: boolean;
  isFirstElement: boolean;
  isLastElement: boolean;
}

const OrganizeQuizItem: FC<IProps> = ({ renderItemParams, isOwner, isFirstElement, isLastElement }) => {
  const { item: bite, drag, isActive, getIndex } = renderItemParams;
  const index = getIndex();
  const theme = useTheme();
  const styles = getStyles(theme, isActive);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteBite = useCallback(() => {
    // delete bite on server
    dispatch(deleteBite(bite.id!));

    // update states
    dispatch(removeSelectedBite(bite.id!));
    setIsModalOpen(false);

    // update playlist on server based on store state
    dispatch(updatePlaylist());
  }, [bite.id, dispatch]);

  const showDesktopConfirmMessage = useCallback(() => {
    const isConfirmed = confirm(t('common.areYouSure'));
    if (isConfirmed) {
      handleDeleteBite();
    }
  }, [handleDeleteBite, t]);

  const onDelete = useCallback(() => {
    isWeb
      ? showDesktopConfirmMessage()
      : Alert.alert(t('common.areYouSure'), '', [
          {
            text: t('common.cancel'),
          },
          {
            text: t('common.yes'),
            onPress: () => {
              handleDeleteBite();
            },
          },
        ]);
  }, [handleDeleteBite, showDesktopConfirmMessage, t]);

  const onEdit = useCallback(() => {
    setIsModalOpen(false);
    dispatch(fetchBiteToEditRequest(bite.id!));
    navigation.navigate(Routes.EditQuizStack.StackName, {
      screen: Routes.EditQuizStack.QuizEditQuestion,
      params: { isEditMode: true },
    });
  }, [bite.id, dispatch, navigation]);

  const bottomButtonsModalConfig: IButtonsConfig = useMemo(() => {
    const topButtons = [];
    let bottomButton;
    if (isOwner) {
      topButtons.push({
        text: t('common.edit'),
        action: onEdit,
      });

      bottomButton = {
        text: t('common.delete'),
        action: onDelete,
      };
    }
    return {
      topButtons,
      bottomButton,
      openLink: () => null,
    };
  }, [isOwner, onDelete, onEdit, t]);

  return (
    <S.Container onLongPress={drag} delayLongPress={200} isFirstElement={isFirstElement} isLastElement={isLastElement}>
      <S.DragIconContainer>
        <S.Text>{index + 1}</S.Text>
      </S.DragIconContainer>
      <Swipeable
        containerStyle={styles.swipeableContainer}
        renderLeftActions={(progress, dragX) => (
          <QuestionOptions t={t} progress={progress} dragX={dragX} onDelete={onDelete} />
        )}
      >
        <S.BiteCard>
          <S.PreviewImage>
            <QuestionIcon width={calcWidth(27)} height={calcHeight(27)} />
          </S.PreviewImage>

          <BiteTitleContainer>
            <S.BiteTitle numberOfLines={2} ellipsizeMode='tail' lineBreakMode='tail'>
              {bite.text || bite.subject}
            </S.BiteTitle>
          </BiteTitleContainer>
          {bottomButtonsModalConfig.topButtons.length > 0 && (
            <S.TouchableOpacity onPress={() => setIsModalOpen(true)}>
              <ThreeDotsSvg fill={theme.colors.darkGray1} width={28} height={28} />
            </S.TouchableOpacity>
          )}
          <BottomButtonsModal
            isVisible={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            buttonsConfig={bottomButtonsModalConfig}
          />
        </S.BiteCard>
      </Swipeable>
    </S.Container>
  );
};

const cardHeight = 55;

const getStyles = (theme: DefaultTheme, isActive: boolean) =>
  StyleSheet.create({
    swipeableContainer: {
      flex: 1,
      height: calcHeight(cardHeight),
      width: '100%',
      backgroundColor: theme.colors.white,
      borderRadius: 10,
      justifyContent: 'center',
      borderColor: isActive ? theme.colors.primaryBlue : theme.colors.lightGray1,
      borderWidth: 1,
    },
  });

const Container = styled.TouchableOpacity<{ isFirstElement: boolean; isLastElement: boolean }>`
  flex-direction: row;
  align-items: center;
  margin-top: ${({ isFirstElement }) => calcHeight(isFirstElement ? 8 : 0)}px;
  margin-left: ${calcHeight(12)}px;
  margin-right: ${calcHeight(12)}px;
  margin-bottom: ${({ isLastElement }) => calcHeight(isLastElement ? 0 : 8)}px;
  padding-bottom: ${calcHeight(8)}px;
  border-bottom-width: ${({ isLastElement }) => (!isLastElement ? 1 : 0)}px;
  border-bottom-color: ${({ theme }) => theme.colors.lightGray1};
`;
const Text = styled.Text`
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
  font-size: ${({ theme }) => calcFontSize(theme.fontSizes.s16)}px;

  text-align: center;
`;

const DragIconContainer = styled.TouchableOpacity`
  padding-right: ${calcHeight(12)}px;
`;

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  right: ${calcWidth(8)}px;
`;

const BiteCard = styled.View`
  flex-direction: row;
  height: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const PreviewImage = styled.View`
  display: flex;
  align-items: center;
  margin-left: ${calcWidth(13)}px;
`;

const BiteTitleContainer = styled.View`
  flex: 1;
  margin: 0 ${calcWidth(18)}px;
`;

const BiteTitle = styled(CustomText)(
  () => css`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    margin-right: ${calcWidth(30)}px;
  `,
);

const S = {
  Container,
  Text,
  DragIconContainer,
  PreviewImage,
  BiteCard,
  BiteTitleContainer,
  BiteTitle,
  TouchableOpacity,
};

export default OrganizeQuizItem;
