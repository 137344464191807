import { useDispatch } from 'react-redux';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { IGetBitesAuthTokenPayload } from '../../../store/auth/auth.types';
import { loginOrRegisterWithSocialToken, loginOrRegisterWithSocialTokenError } from '../../../store/auth/auth.actions';
import setSsoInProgressService from '../../../services/setSsoInProgress';
import { APP_TYPE } from '../../../utils/constants/config';
import { log, logError } from '../../../store/appActivity/appActivity.slice';

const useGoogleAuth = () => {
  const dispatch = useDispatch();

  const onSuccess = async (loginResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    const payload: IGetBitesAuthTokenPayload = {
      app: APP_TYPE,
      backend: 'google-oauth2',
      token: loginResponse.access_token,
      creator: true,
    };

    dispatch(
      log({
        event: 'useGoogleAuth: loginOrRegisterWithSocialToken',
        data: { payload },
      }),
    );

    dispatch(loginOrRegisterWithSocialToken(payload));
  };

  const onError = (error) => {
    dispatch(
      logError({
        event: 'useGoogleAuth: error',
        data: { error },
      }),
    );
    setSsoInProgressService(false);
    dispatch(loginOrRegisterWithSocialTokenError());
  };

  const signIn = useGoogleLogin({
    onSuccess,
    onError,
    onNonOAuthError: onError,
  });

  const googleAuth = () => {
    dispatch(
      log({
        event: 'useGoogleAuth',
      }),
    );
    setSsoInProgressService(true);
    signIn();
  };
  return googleAuth;
};

export default useGoogleAuth;
