import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { Text } from 'react-native';

import AuthStack from './AuthStack';
import MainStack from './MainStack';
import DrawerContent from './MainStack/HomeDrawer/DrawerContent/DrawerContent';
import { navigationRef } from './RootNavigation';
import { deviceWidth, isWeb } from '../utils/dimensions';
import Routes from './routes';
import { runExternalAction, setIsNavigationStackDisplayed } from '../store/appActivity/appActivity.slice';
import { useDispatch, useSelector } from 'react-redux';
import { externalActionSelector, isFullWidthContentSelector } from '../store/appActivity/appActivity.selectors';
import { unauthorizedExternalActionTypes } from '../utils/runExternalAction/runExternalAction';
import { NavigationContainer } from '@react-navigation/native';
// import VideoEditor from '../screens/videoEditor/VideoEditor.web';
import { startTrackingViews } from './hooks/startTrackingViews/startTrackingViews';

const MAIN_STACK_OPTIONS = { animationEnabled: false };

interface IProps {
  isSignedIn: boolean;
}

const defaultScreenOptions: StackNavigationOptions = {
  headerShown: false,
  headerMode: 'screen',
  animationEnabled: !isWeb,
};

const Navigation: React.FC<IProps> = ({ isSignedIn }) => {
  const dispatch = useDispatch();
  const RootStack = createStackNavigator();
  const externalAction = useSelector(externalActionSelector);
  const isFullWidthContent = useSelector(isFullWidthContentSelector);

  const handleReady = useCallback(() => {
    dispatch(setIsNavigationStackDisplayed(true));
    if (externalAction?.action && unauthorizedExternalActionTypes.includes(externalAction.action)) {
      dispatch(runExternalAction());
    }

    if (!isWeb) {
      startTrackingViews(navigationRef);
    }
  }, [dispatch, externalAction?.action]);

  // return (
  //   <NavigationContainer
  //     ref={navigationRef}
  //     linking={{ enabled: true, prefixes: ['http://localhost:3000'] }}
  //     fallback={<Text>Loading...</Text>}
  //     onReady={handleReady}
  //   >
  //     <RootStack.Navigator screenOptions={defaultScreenOptions}>
  //       <Stack.Screen name={Routes.CreateBiteStack.BiteVideoEditor} component={VideoEditor} />
  //     </RootStack.Navigator>
  //   </NavigationContainer>
  // );

  return (
    <S.Container>
      <S.Content isFullWidthContent={isFullWidthContent}>
        <NavigationContainer
          ref={navigationRef}
          linking={{ enabled: true, prefixes: ['http://localhost:3000'] }}
          fallback={<Text>Loading...</Text>}
          onReady={handleReady}
        >
          <RootStack.Navigator screenOptions={defaultScreenOptions}>
            {isSignedIn ? (
              <RootStack.Screen name={Routes.MainStack.StackName} component={MainStack} options={MAIN_STACK_OPTIONS} />
            ) : (
              <RootStack.Screen name={Routes.AuthStack.StackName} component={AuthStack} />
            )}
          </RootStack.Navigator>
        </NavigationContainer>
      </S.Content>

      {/* TODO: theoretically, this does not work correctly,
          since inside the component there is a login tied to props,
          which in this case are not transmitted
          */}
      {/* @ts-ignore */}
      {isWeb && isSignedIn && !isFullWidthContent && <DrawerContent />}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    flex-direction: row-reverse;
    justify-content: center;
  `,
  Content: styled.View<{ isFullWidthContent: boolean }>`
    height: 100%;
    width: ${({ isFullWidthContent }) => (isFullWidthContent ? '100%' : deviceWidth)};
  `,
};

export default Navigation;
