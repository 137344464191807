import React, { memo, useCallback, useState } from 'react';
import WebViewModal from '../../../components/modals/WebViewModal/WebViewModal';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import { useDispatch } from 'react-redux';
import { setTermsConditionsVisible } from '../../../store/authForm/authForm.slice';
import { TERMS_CONDITIONS_PDF, URL_PRIVACY } from '../../../utils/constants/auth';
import TermsConditionsModal from './TermsAndConditions';
import { log } from '../../../store/appActivity/appActivity.slice';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

interface IProps {}

const Agreement: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDisplayPrivacyPolicy, setIsDisplayPrivacyPolicy] = useState(false);

  const handleTermsConditionsPress = useCallback(() => {
    dispatch(
      log({
        event: 'AuthForm.handleTermsConditionsPress',
      }),
    );

    if (isWeb) {
      window.open(TERMS_CONDITIONS_PDF, '_blank');
      return;
    }
    dispatch(setTermsConditionsVisible(true));
  }, [dispatch]);

  const handleOpenPrivacyPolicy = useCallback(() => {
    if (isWeb) {
      window.open(URL_PRIVACY, '_blank');
      return;
    }

    setIsDisplayPrivacyPolicy(true);
  }, []);

  const handleClosePrivacyPolicy = useCallback(() => {
    setIsDisplayPrivacyPolicy(false);
  }, []);

  return (
    <>
      <S.TermsAndConditions>
        <S.TermsAndConditionsText>
          {t('authForm.agreeToOur')}{' '}
          <S.TermsAndConditionsLink onPress={handleTermsConditionsPress}>
            <S.TermsAndConditionsLinkText>{t('authForm.terms')}</S.TermsAndConditionsLinkText>
          </S.TermsAndConditionsLink>{' '}
          <S.AndTextContainer>
            <S.AndText>{t('authForm.and')}</S.AndText>
          </S.AndTextContainer>{' '}
          <S.TermsAndConditionsLink onPress={handleOpenPrivacyPolicy}>
            <S.TermsAndConditionsLinkText>{t('authForm.privacyPolicy')}</S.TermsAndConditionsLinkText>
          </S.TermsAndConditionsLink>
        </S.TermsAndConditionsText>
      </S.TermsAndConditions>

      <TermsConditionsModal />
      <WebViewModal
        url={URL_PRIVACY}
        isVisible={isDisplayPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
        withCloseIcon
      />
    </>
  );
};

export default memo(Agreement);

const S = {
  TermsAndConditions: styled.View`
    max-width: ${calcWidth(isWeb ? 295 : 240)}px;
    margin-bottom: ${calcHeight(30)}px;
    margin-top: ${calcHeight(4)}px;
    align-items: center;
  `,
  TermsAndConditionsText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    text-align: center;
  `,
  TermsAndConditionsLink: styled.TouchableOpacity`
    height: ${calcHeight(16)}px;
  `,
  TermsAndConditionsLinkText: styled.Text`
    height: ${calcHeight(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
  AndTextContainer: styled.View`
    height: ${calcHeight(16)}px;
  `,
  AndText: styled.Text`
    height: ${calcHeight(16)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s18};
  `,
};
