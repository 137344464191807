import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exploreBitesSelector } from '../../../../../store/explore/explore.selectors';
import FeedItemsList from '../../../../../components/feed/FeedItemsList';
import { fetchExploreBitesRequest } from '../../../../../store/explore/explore.slice';

import { useNavigation } from '@react-navigation/native';
import Routes from '../../../../../navigation/routes';
import ExploreBiteItem from '../ExploreBiteItem';
import { trackEvent } from '../../../../../store/appActivity/appActivity.slice';
import useInspirationBitesTabs from './useInspirationBitesTabs';

import ExploreLabels from './ExploreLabels';
import styled from 'styled-components/native';
import { calcHeight, isWeb } from '../../../../../utils/dimensions';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  searchStr?: string;
}

const ExploreBitesFeed: FC<IProps> = ({ searchStr = '' }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const { isFetching } = useSelector(exploreBitesSelector);
  const { tabs, selectedUseCasesMap, selectedIndustriesMap, results, toggleTabIsSelected, selectAllTabs } =
    useInspirationBitesTabs({
      searchStr,
    });

  const handleExploreBitePress = useCallback(
    (bite) => {
      navigation.navigate(Routes.PreviewStack.StackName, {
        screen: Routes.PreviewStack.PreviewExploreBite,
        params: {
          bite,
        },
      });
    },
    [navigation],
  );

  const handlePullToRefresh = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'reload',
        props: { page_title: 'bites_list' },
      }),
    );
    dispatch(fetchExploreBitesRequest());
  }, [dispatch]);

  const renderItem = useCallback(
    ({ item: biteShare, index }) => {
      return <ExploreBiteItem biteShare={biteShare} index={index} onPress={handleExploreBitePress} />;
    },
    [handleExploreBitePress],
  );

  const keyExtractor = useCallback((item) => `${item.id}`, []);

  return (
    <>
      <ExploreLabels
        tabs={tabs}
        selectedUseCasesMap={selectedUseCasesMap}
        selectedIndustriesMap={selectedIndustriesMap}
        toggleTabIsSelected={toggleTabIsSelected}
        selectAllTabs={selectAllTabs}
        showSecondTitle={results.length > 0}
      />

      <S.Container>
        <FeedItemsList
          items={results}
          isLoading={isFetching}
          isSmall
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          onPullToRefresh={handlePullToRefresh}
        />
      </S.Container>
    </>
  );
};
const S = {
  Container: styled.View<{}>`
    flex: 1;
    margin-bottom: ${() => (isWeb ? 0 : calcHeight(12))}px;
  `,
};

export default ExploreBitesFeed;
