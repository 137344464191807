import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { calcHeight, verticalScale, isWeb, calcWidth } from '../../../../utils/dimensions';
import { SvgProps } from 'react-native-svg';

interface IProps {
  onPress: () => void;
  title: string;
  icon: FC<SvgProps>;
}

const Control: FC<IProps> = ({ onPress, title, icon: Icon }) => {
  return (
    <S.Container>
      <S.WhiteCircle onPress={onPress}>
        <Icon height={verticalScale(64)} width={verticalScale(64)} />
      </S.WhiteCircle>
      <S.Title>{title}</S.Title>
    </S.Container>
  );
};

const Container = styled.View`
  align-items: center;
`;

const WhiteCircle = styled.TouchableOpacity`
  height: ${calcWidth(isWeb ? 107 : 115)}px;
  width: ${calcWidth(isWeb ? 107 : 115)}px;
  border-radius: ${calcHeight(42) / 2}px;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  border-color: ${({ theme }) => theme.colors.lightGray22};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px;
  border-width: 1px;
`;

const Title = styled.Text(
  ({ theme }) => css`
    margin-top: ${calcHeight(10)}px;
    font-family: ${theme.fontFamilies.GilroyMedium};
    font-size: ${theme.fontSizes.s15}px;
    color: ${theme.colors.primaryBlue};
  `,
);

const S = { Container, WhiteCircle, Title };

export default Control;
