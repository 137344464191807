import store from '../../store';
import { startPlaylistCreation, trackEvent } from '../../store/appActivity/appActivity.slice';

const startPlaylistCreationFlow = () => {
  store.dispatch(
    trackEvent({
      event: 'new_playlist',
    }),
  );
  store.dispatch(startPlaylistCreation());
};
export default startPlaylistCreationFlow;
