import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { useIsMounted } from '../../../hooks/useIsMounted';
import letters from '../../../utils/letters';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { StyleProp, ViewProps } from 'react-native';
import { IBiteAnswer } from '../../../types/biteQuestion';
import { ScaleDecorator } from 'react-native-draggable-flatlist';
import { IS_ANDROID } from '../../../utils/constants/env';
import { TLocale } from '../../../locale/i18n';
import DeleteButton from '../../../components/shared/buttons/DeleteButton';
import { answerCheckbox, answerInput } from './BiteAnswerInput.constants';

interface IProps {
  item: IBiteAnswer;
  isSelected?: boolean;
  index: number;
  onSelect?: (answerId: number) => void;
  onRemove?: (answerId: number) => void;
  onDrag?: () => void;
  autoFocus: boolean;
  onChangeText: (item: IBiteAnswer) => void;
  disableDrag?: boolean;
  color?: string;
}

const BiteAnswerInput: React.FC<IProps> = ({
  item,
  disableDrag,
  onSelect,
  index,
  onDrag,
  autoFocus,
  onChangeText,
  color,
  onRemove,
}) => {
  const { i18n } = useTranslation();
  const isRtl = isWeb && i18n.dir(i18n.language) === 'rtl';
  const [scrollHeight, setScrollHeight] = useState<number>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isRemoveVisible, setIsRemoveVisible] = useState(false);
  const blurTimeoutRef = useRef(null);
  const isMountRef = useIsMounted();
  const letter = useMemo(() => letters[i18n.language as TLocale][index], [i18n.language, index]);

  useEffect(() => {
    return () => clearTimeout(blurTimeoutRef.current);
  }, []);

  const onLayout = useCallback(
    (event) => {
      if (isWeb) {
        setIsFocused(event.nativeEvent.target.isFocused());
        setIsRemoveVisible(event.nativeEvent.target.isFocused());

        if (!scrollHeight) {
          setScrollHeight(event.nativeEvent.target.scrollHeight);
        }

        return;
      }
      setIsFocused(event.target.isFocused());
      setIsRemoveVisible(event.target.isFocused());
    },
    [scrollHeight],
  );

  const onFocus = useCallback(() => {
    setIsFocused(true);
    setIsRemoveVisible(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
    clearTimeout(blurTimeoutRef.current);
    blurTimeoutRef.current = setTimeout(() => {
      if (isMountRef.current) {
        setIsRemoveVisible(false);
      }
    }, 300);
  }, [isMountRef]);

  const selectCorrectAnswer = useCallback(() => {
    onSelect(item.id);
  }, [item.id, onSelect]);

  const onRemoveAnswer = useCallback(() => {
    onRemove(item.id);
  }, [item.id, onRemove]);

  const changeTextHandler = useCallback(
    (text) => {
      onChangeText({ ...item, text });
    },
    [item, onChangeText],
  );

  const answerInputStyle = useMemo(
    () => ({
      height: scrollHeight,
    }),
    [scrollHeight],
  );
  const handleOnContentSizeChange = useCallback((event) => {
    if (!event.nativeEvent.contentSize.height) {
      return;
    }
    const newHeight = isWeb ? event.nativeEvent.contentSize.height : event.nativeEvent.contentSize.height + 5;
    setScrollHeight(newHeight);
  }, []);

  return (
    <S.DraggableItemContainer>
      {Boolean(onRemove) && isRemoveVisible && (
        <DeleteButton
          containerViewStyle={isFocused ? S.DeleteButton : S.DeleteButtonOpacity}
          onPress={onRemoveAnswer}
          width={calcWidth(35)}
          height={calcWidth(35)}
        />
      )}
      <ScaleDecorator>
        <S.AnswerContainer isRtl={isRtl} isFocused={isFocused} color={color}>
          <TouchableWithoutFeedback disabled={disableDrag} onLongPress={onDrag}>
            <S.AnswerLetter>{letter}</S.AnswerLetter>
          </TouchableWithoutFeedback>
          <S.AnswerInput
            textAlignVertical='top'
            scrollEnabled={false}
            onLayout={onLayout}
            onFocus={onFocus}
            onBlur={onBlur}
            style={answerInputStyle}
            autoFocus={autoFocus}
            value={item.text}
            onChangeText={changeTextHandler}
            onContentSizeChange={handleOnContentSizeChange}
            multiline
            //@ts-ignore
            dataSet={answerInput}
          />
          {Boolean(onSelect) && (
            <S.CheckboxContainer>
              <S.Checkbox
                onPress={selectCorrectAnswer}
                //@ts-ignore
                dataSet={answerCheckbox}
              >
                {item.is_correct && <S.CheckboxPoint />}
              </S.Checkbox>
              {item.is_correct && <S.CheckboxText>Correct</S.CheckboxText>}
            </S.CheckboxContainer>
          )}
        </S.AnswerContainer>
      </ScaleDecorator>
    </S.DraggableItemContainer>
  );
};

const S = {
  CheckboxContainer: styled.View`
    width: ${calcWidth(40)}px;
    height: ${calcHeight(20)}px;
    align-items: center;
    margin-top: ${calcHeight(4)}px;
  `,
  Checkbox: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: ${18 / 2}px;
    border: 1px solid ${({ theme }) => theme.colors.gray11};
    background: ${({ theme }) => theme.colors.white};
  `,
  CheckboxPoint: styled.View`
    width: 12px;
    height: 12px;
    border-radius: ${12 / 2}px;
    background: ${({ theme }) => theme.colors.primaryBlue};
  `,
  CheckboxText: styled.Text`
    margin-top: 1px;
    font-size: ${({ theme }) => theme.fontSizes.s10};
    color: ${({ theme }) => theme.colors.gray11};
  `,
  AnswerContainer: styled.View<{ isRtl: boolean; isFocused: boolean; color: string }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${calcWidth(325)}px;
    padding: ${calcWidth(13)}px ${calcWidth(2)}px ${IS_ANDROID ? calcWidth(10) : calcWidth(13)}px ${calcWidth(2)}px;
    background: ${({ color }) => color};
    border-radius: 9px;
    border: solid 1px ${({ theme, isFocused, color }) => (isFocused ? theme.colors.primaryBlue : color)};
  `,
  AnswerLetter: styled.Text`
    line-height: ${({ theme }) => theme.fontSizes.s16};
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.text};
    font-weight: bold;
    margin: ${calcHeight(5)}px ${calcWidth(11)}px;
    ${() => isWeb && 'cursor: grab;'}
  `,
  AnswerInput: styled.TextInput`
    padding-bottom: 0;
    padding-top: 4px;
    align-self: center;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    line-height: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    color: ${({ theme }) => theme.colors.darkPrimary};
    width: ${calcWidth(245)}px;
  `,
  DraggableItemContainer: styled.View`
    padding-top: ${calcHeight(12)}px;
    align-items: center;
  `,
  DeleteButtonOpacity: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0,
    top: 0,
    left: calcWidth(10),
  } as StyleProp<ViewProps>,
  DeleteButton: {
    position: 'absolute',
    zIndex: 1,
    opacity: 1,
    top: 0,
    left: calcWidth(10),
  } as StyleProp<ViewProps>,
};

export default memo(BiteAnswerInput);
