import { calcHeight, calcWidth, isWeb } from './dimensions';

interface IProps {
  initialHeight: number;
  initialWidth: number;
  maxWidth: number;
  maxHeight: number;
  isGoogleSlides?: boolean;
}

type TSizes = {
  width: number;
  height: number;
};

const SLIDES_MIN_WIDTH = calcWidth(290);

export const formatMediaSize = ({
  initialHeight,
  initialWidth,
  maxWidth,
  maxHeight,
  isGoogleSlides,
}: IProps): TSizes => {
  const calcByWidth = {
    width: calcWidth(maxWidth),
    height: (initialHeight / initialWidth) * calcHeight(maxWidth),
  };

  const calcByHeight = {
    width:
      (initialWidth / initialHeight) * calcWidth(maxHeight) > maxWidth
        ? maxWidth
        : isGoogleSlides && (initialWidth / initialHeight) * calcWidth(maxHeight) < SLIDES_MIN_WIDTH
        ? SLIDES_MIN_WIDTH
        : (initialWidth / initialHeight) * calcWidth(maxHeight),
    height: calcHeight(maxHeight),
  };

  if (initialHeight === initialWidth) {
    if (isWeb) {
      return calcByHeight;
    }

    return calcByWidth;
  }

  if (initialHeight > initialWidth) {
    return calcByHeight;
  }

  return calcByWidth;
};
