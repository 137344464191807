import { useState, useEffect } from 'react';
import { EIntroMediaProcessingStatus } from '../store/createBite/createBite.types';
import { IBiteItem } from '../types/bite';
import useIsAvaitingIntroEnhancements from './useIsAvaitingIntroEnhancements';
import getBiteUploadingMediaStatus from '../utils/introMedia/getUploadingIntroMediaStatus';

const useBiteUploadingMediaStatus = (bite: IBiteItem): EIntroMediaProcessingStatus => {
  const [state, setState] = useState(EIntroMediaProcessingStatus.INACTIVE);
  const isAwaitingIntroEnhancements = useIsAvaitingIntroEnhancements(bite?.id);

  useEffect(() => {
    setState(getBiteUploadingMediaStatus(bite, isAwaitingIntroEnhancements));
  }, [bite, isAwaitingIntroEnhancements]);

  return state;
};

export default useBiteUploadingMediaStatus;
