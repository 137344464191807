import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  getAnswersAggregated,
  getAnswersData,
  getBiteDistributions,
  getCommentsData,
  getOverviewData,
  getViewsData,
  resetAnalytics,
  setAggregatedAnswersError,
  setAnswersAggregated,
  setAnswersData,
  setAnswersDataError,
  setCommentsData,
  setCommentsDataError,
  setHasDistributions,
  setIsRefreshing,
  setOverviewData,
  setOverviewDataError,
  setViewsData,
  setViewsDataError,
} from './analytics.slice';
import * as calls from '../api/bites-api/calls/analytics.calls';
import { getBiteDistributions as getBiteDistributionsService } from '../api/bites-api/calls/analytics.calls';
import {
  answersFilterSelector,
  answersIdsFilterSelector,
  answersNextSelector,
  biteIdSelector,
  commentsFilterSelector,
  commentsNextSelector,
  overviewFilterSelector,
  overviewNextSelector,
  viewsFilterSelector,
  viewsNextSelector,
} from './analytics.selector';
import { activeOrganizationSelector } from '../auth/auth.selectors';
import withRetry from '../../utils/withRetry';
import { selectedFilterAttributeValueIdsSelector, sharedWithFilterSelector } from '../attributes/attributes.selector';
import { log } from '../appActivity/appActivity.slice';
import { setSharedWithFilter } from '../attributes/attributes.slice';
import { ESharedWithFilter } from '../attributes/attributes.types';
import { organizationFeaturesSelector } from '../org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../utils/constants/org';

function* getOverviewDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;
  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(overviewNextSelector);
    const filter = yield select(overviewFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(sharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: [
            'biteId',
            'overview',
            'currentList',
            'isRefreshing',
            'hasDistributions',
            'isFilterTooltipShown',
          ],
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getOverviewList({
          orgId,
          biteIds: [biteId],
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          filter,
          next,
        }),
      {
        errorContext: {
          action: 'analytics.saga getOverviewDataSaga',
        },
      },
    );

    yield put(setOverviewData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({ excludeFields: ['biteId', 'currentList', 'hasDistributions', 'isFilterTooltipShown'] }),
      );
    }

    if (reset) {
      yield put(setOverviewData({ results: [], next: null, reset: true }));
    }
    yield put(setOverviewDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getViewsDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(viewsNextSelector);
    const filter = yield select(viewsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(sharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: ['biteId', 'views', 'currentList', 'isRefreshing', 'hasDistributions', 'isFilterTooltipShown'],
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getViewsList({
          orgId,
          biteIds: [biteId],
          filter,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          next,
        }),
      {
        errorContext: {
          action: 'analytics.saga getViewsDataSaga',
        },
      },
    );

    yield put(setViewsData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({ excludeFields: ['biteId', 'currentList', 'hasDistributions', 'isFilterTooltipShown'] }),
      );
    }

    if (reset) {
      yield put(setViewsData({ results: [], next: null, reset: true }));
    }
    yield put(setViewsDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getAnswersDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(answersNextSelector);
    const filter = yield select(answersFilterSelector);
    const answerIds = yield select(answersIdsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(sharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: [
            'biteId',
            'aggregatedAnswers',
            'answers',
            'currentList',
            'isRefreshing',
            'hasDistributions',
            'isFilterTooltipShown',
          ],
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getAnswersList({
          orgId,
          biteIds: [biteId],
          filter: !answerIds.length ? filter : undefined,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          answerIds,
          next,
        }),
      {
        errorContext: {
          action: 'analytics.saga getAnswersDataSaga',
        },
      },
    );

    yield put(setAnswersData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: ['biteId', 'aggregatedAnswers', 'currentList', 'hasDistributions', 'isFilterTooltipShown'],
        }),
      );
    }

    if (reset) {
      yield put(setAnswersData({ results: [], next: null, reset: true }));
    }
    yield put(setAnswersDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getCommentsDataSaga({ payload }) {
  const reset = payload?.reset;
  const clearAnalyticsCache = payload?.clearAnalyticsCache;

  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const next = yield select(commentsNextSelector);
    const filter = yield select(commentsFilterSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(sharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({
          excludeFields: [
            'biteId',
            'comments',
            'currentList',
            'isRefreshing',
            'hasDistributions',
            'isFilterTooltipShown',
          ],
        }),
      );
    }

    const { data } = yield withRetry(
      () =>
        calls.getCommentsList({
          orgId,
          biteIds: [biteId],
          filter,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
          next,
        }),
      {
        errorContext: {
          action: 'analytics.saga getCommentsDataSaga',
        },
      },
    );

    yield put(setCommentsData({ ...data, reset }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    if (clearAnalyticsCache) {
      yield put(
        resetAnalytics({ excludeFields: ['biteId', 'currentList', 'hasDistributions', 'isFilterTooltipShown'] }),
      );
    }

    if (reset) {
      yield put(setCommentsData({ results: [], next: null, reset: true }));
    }
    yield put(setCommentsDataError({ error }));
    yield put(setIsRefreshing(false));

    if (typeof payload?.callback === 'function') {
      payload.callback();
    }
  }
}

function* getAnswersAggregatedSaga() {
  try {
    const biteId = yield select(biteIdSelector);
    const { id: orgId } = yield select(activeOrganizationSelector);
    const selectedFilterAttributeValueIds = yield select(selectedFilterAttributeValueIdsSelector);
    const sharedWith = yield select(sharedWithFilterSelector);
    const organizationFeatures = yield select(organizationFeaturesSelector);
    const allowedDataOnly = organizationFeatures.includes(FEATURE_FLAG_IDS.OrgChart);

    const { data } = yield withRetry(
      () =>
        calls.getAnswersAggregated({
          orgId,
          biteId,
          attributeValueIds: selectedFilterAttributeValueIds,
          sharedWith,
          allowedDataOnly,
        }),
      {
        errorContext: {
          action: 'analytics.saga getAnswersAggregatedSaga',
        },
      },
    );
    yield put(setAnswersAggregated(data));
  } catch (error) {
    yield put(setAggregatedAnswersError({ error }));
  }
}

function* getBiteDistributionsSaga() {
  yield put(
    log({
      event: 'getBiteDistributionsSaga: start',
    }),
  );
  try {
    const { id: orgId } = yield select(activeOrganizationSelector);
    const biteId = yield select(biteIdSelector);

    const {
      data: { distributions },
    } = yield withRetry(() => getBiteDistributionsService({ orgId, biteId }), {
      errorContext: {
        action: 'attributes.saga getBiteDistributionsSaga',
      },
    });

    yield put(
      log({
        event: 'getBiteDistributionsSaga: success',
      }),
    );

    yield put(setHasDistributions(!!distributions.length));

    if (distributions.length) {
      yield put(setSharedWithFilter([ESharedWithFilter.COMMUNICATION_HUB, ESharedWithFilter.ORGANICALLY]));
    }
  } catch (error) {
    yield put(
      log({
        event: 'getBiteDistributionsSaga: error',
        data: {
          error,
          // axios case
          errorResponse: error?.response,
        },
      }),
    );

    yield put(setHasDistributions(true));
  }
}

export default function* analyticsSaga() {
  yield all([
    takeLatest(getOverviewData, getOverviewDataSaga),
    takeLatest(getViewsData, getViewsDataSaga),
    takeLatest(getAnswersData, getAnswersDataSaga),
    takeLatest(getCommentsData, getCommentsDataSaga),
    takeLatest(getAnswersAggregated, getAnswersAggregatedSaga),
    takeLatest(getBiteDistributions, getBiteDistributionsSaga),
  ]);
}
