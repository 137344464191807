import store from '../store';
import { log } from '../store/appActivity/appActivity.slice';
import getPlatformUri from './getPlatformUri';
import withRetry, { IWithRetryProps } from './withRetry';

export interface IGetBlobFromLocalFileResult {
  file: Response;
  blob: Blob;
}
interface IOptions {
  processId?: string;
}

// this function is for native only
const getBlobFromLocalFile = async (
  uri: string,
  { processId }: IOptions = {},
): Promise<IGetBlobFromLocalFileResult> => {
  return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
    const logContext = {
      processId,
      data: {
        attempt,
        isLastAttempt,
        uri,
      },
    };

    setErrorContext({
      action: 'getBlobFromLocalFile',
      ...logContext,
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'getBlobFromLocalFile: start',
      }),
    );

    const file = await fetch(getPlatformUri(uri));

    setErrorContext({
      action: 'getBlobFromLocalFile: get blob',
      ...logContext,
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'getBlobFromLocalFile: get blob',
      }),
    );

    const blob = await file.blob();

    store.dispatch(
      log({
        ...logContext,
        event: 'getBlobFromLocalFile: done',
      }),
    );

    return { file, blob };
  });
};
export default getBlobFromLocalFile;
