import React from 'react';
import styled from 'styled-components/native';

interface IProps {
  children: React.ReactChild;
}

const IconWrapper: React.FC<IProps> = ({ children }) => {
  return <S.View>{children}</S.View>;
};

const S = {
  View: styled.View`
    background-color: #001cf5;
    padding: 7px;
    border-radius: 100px;
  `,
};

export default IconWrapper;
