import React, { useCallback, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { StyleProp, ViewStyle, I18nManager } from 'react-native';

import NativeTooltip, { TooltipDisplayInsets } from 'react-native-walkthrough-tooltip';
import { IS_ANDROID, IS_IOS } from '../../utils/constants/env';
import CloseIcon from '../../assets/icons/close.svg';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import TooltipContentContainer from '../shared/TooltipContentContainer';

export interface ITooltipMethods {
  show?: () => void;
  hide?: () => void;
}
interface IMethodsRef {
  current: ITooltipMethods;
}
interface IProps {
  initialVisible?: boolean;
  methodsRef?: IMethodsRef;
  text: string;
  fontSize?: number;
  withCloseIcon?: boolean;
  withGradient?: boolean;
  disableBackgroundClose?: boolean;
  children: React.ReactNode;
  showChildInTooltip?: boolean;
  backgroundColor?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'center';
  displayInsets?: TooltipDisplayInsets;
  topAdjustment?: number;
}

const defaultInsets = { top: 0, right: 0, bottom: 0, left: 0 };

const Tooltip: React.FC<IProps> = ({
  initialVisible = false,
  methodsRef,
  text,
  fontSize,
  withCloseIcon,
  withGradient,
  disableBackgroundClose,
  placement = 'top',
  showChildInTooltip = false,
  backgroundColor = 'transparent',
  displayInsets = defaultInsets,
  children,
  topAdjustment,
}) => {
  const theme = useTheme();

  const [isTooltipVisible, setTooltipVisible] = useState(initialVisible);

  const handleCloseTooltip = useCallback(() => {
    setTooltipVisible(false);
  }, []);

  if (methodsRef) {
    methodsRef.current = {
      show: () => setTooltipVisible(true),
      hide: () => setTooltipVisible(false),
    };
  }

  const handleClose = useCallback(() => {
    if (disableBackgroundClose) {
      return;
    }

    handleCloseTooltip();
  }, [disableBackgroundClose, handleCloseTooltip]);

  const arrowStyle = useMemo(
    () =>
      placement === 'bottom'
        ? [
            S.TopTooltipArrowStyles,
            {
              borderTopColor: theme.colors.primaryBlue,
            },
          ]
        : [
            S.TooltipArrowStyles,
            {
              borderTopColor: withGradient ? theme.colors.backgroundPurple : theme.colors.primaryBlue,
            },
          ],
    [placement, withGradient, theme],
  );

  const tooltipStyle = useMemo(
    () =>
      ({
        flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
        marginTop: placement === 'bottom' ? -10 : 7,
      } as StyleProp<ViewStyle>),
    [placement],
  );

  const initialAdjustment = useMemo(() => {
    if (placement === 'bottom') {
      return;
    }

    if (IS_ANDROID) {
      return 0;
    }

    if (IS_IOS) {
      return 5;
    }

    return 10;
  }, [placement]);

  return (
    <NativeTooltip
      disableShadow
      showChildInTooltip={showChildInTooltip}
      backgroundColor={backgroundColor}
      arrowStyle={arrowStyle}
      tooltipStyle={tooltipStyle}
      backgroundStyle={S.TooltipBackgroundStyles}
      contentStyle={S.ContentStyles}
      isVisible={isTooltipVisible} //  && keyboardHeight === 0
      onClose={handleClose}
      placement={placement}
      displayInsets={displayInsets}
      topAdjustment={topAdjustment || initialAdjustment}
      content={
        <TooltipContentContainer withGradient={withGradient}>
          {withCloseIcon && (
            <S.CloseTooltip onPress={handleCloseTooltip}>
              <CloseIcon fill={theme.colors.white} />
            </S.CloseTooltip>
          )}
          <S.TooltipText fontSize={fontSize}>{text}</S.TooltipText>
        </TooltipContentContainer>
      }
    >
      {children}
    </NativeTooltip>
  );
};

const S = {
  CloseTooltip: styled.TouchableOpacity`
    z-index: 3;
    top: ${calcHeight(7)}px;
    right: ${calcWidth(7)}px;
    position: absolute;
    width: ${calcWidth(12)}px;
    height: ${calcHeight(12)}px;
  `,
  TooltipText: styled.Text<{ fontSize: number }>`
    text-align: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px;` : '')}
  `,
  TooltipArrowStyles: {
    top: 'auto',
    bottom: 0,
  } as StyleProp<ViewStyle>,
  TopTooltipArrowStyles: {
    bottom: 'auto',
    top: 0,
  } as StyleProp<ViewStyle>,
  TooltipBackgroundStyles: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
  ContentStyles: {
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: 'transparent',
  } as StyleProp<ViewStyle>,
};

export default Tooltip;
