import ShadowedContainer from '../../ShadowedContainer';
import React, { useCallback, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import BackButton from '../BackButton';
import { IS_IOS } from '../../../utils/constants/env';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import { PAGE_WIDTH } from './constants';
import ThreeDotsIcon from '../../../assets/icons/three-dots.svg';
import { StyleProp, ViewStyle } from 'react-native';
import { dotsButtonDataSet } from './PreviewControlPanel.constants';

interface IProps {
  maxPages?: number;
  onClose: () => void;
  children: React.ReactNode;
}

const PreviewControlPanel: React.FC<IProps> = ({ maxPages = 1, onClose, children }) => {
  const theme = useTheme();

  const [currentPage, setCurrentPage] = useState(0);
  const scrollRef = useRef(null);

  const goToNextPage = useCallback(() => {
    const nextPage = currentPage < maxPages - 1 ? currentPage + 1 : currentPage;
    const nextPageOffset = PAGE_WIDTH * nextPage;

    scrollRef.current.scrollTo({ x: nextPageOffset, y: 0, animated: true });
    setCurrentPage(nextPage);
  }, [currentPage, maxPages]);

  const goToPrevPage = useCallback(() => {
    const prevPage = currentPage > 0 ? currentPage - 1 : currentPage;
    const prevPageOffset = PAGE_WIDTH * prevPage;

    scrollRef.current.scrollTo({ x: prevPageOffset, y: 0, animated: true });
    setCurrentPage(prevPage);
  }, [currentPage]);

  const handleSwipe = useCallback(
    ({ nativeEvent }) => {
      if (nativeEvent.oldState !== State.ACTIVE) {
        return;
      }

      if (nativeEvent.translationX < 0) {
        goToNextPage();
        return;
      }

      goToPrevPage();
    },
    [goToNextPage, goToPrevPage],
  );

  const handleDotsButtonPress = useCallback(() => {
    if (currentPage < maxPages - 1) {
      goToNextPage();
      return;
    }
    goToPrevPage();
  }, [currentPage, goToNextPage, goToPrevPage, maxPages]);

  return (
    <ShadowedContainer>
      <S.BottomPanel>
        <S.BackButtonContainer>
          <S.BackButton onPress={onClose} />
        </S.BackButtonContainer>
        <S.ButtonsSwipe
          ref={scrollRef}
          hasMultiplePages={maxPages > 1}
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
          horizontal
        >
          <PanGestureHandler onHandlerStateChange={handleSwipe}>
            <S.ButtonsSwipeContent maxPages={maxPages}>{children}</S.ButtonsSwipeContent>
          </PanGestureHandler>
        </S.ButtonsSwipe>
        {maxPages > 1 && (
          <S.DotsButton
            onPress={handleDotsButtonPress}
            //@ts-ignore
            dataSet={dotsButtonDataSet}
          >
            <ThreeDotsIcon
              fill={currentPage > 0 ? theme.colors.primaryBlue : theme.colors.gray19}
              style={S.DotsIconStyles}
            />
          </S.DotsButton>
        )}
      </S.BottomPanel>
    </ShadowedContainer>
  );
};

const S = {
  DotsButton: styled.TouchableOpacity`
    flex: 1;
  `,
  ButtonsSwipeContent: styled.View<{ maxPages: number }>`
    min-width: ${({ maxPages }) => (maxPages > 1 ? PAGE_WIDTH * maxPages : PAGE_WIDTH + calcWidth(30))}px;
    flex-direction: row;
  `,
  ButtonsSwipe: styled.ScrollView<{ hasMultiplePages: boolean }>`
    flex-direction: row;
    margin: 10px;
    height: ${calcHeight(55)}px;
    max-width: ${({ hasMultiplePages }) => (hasMultiplePages ? PAGE_WIDTH : PAGE_WIDTH + calcWidth(30))}px;
    overflow: hidden;
  `,
  BackButtonContainer: styled.View`
    padding: ${calcHeight(15)}px ${calcWidth(15)}px;
  `,
  BackButton: styled(BackButton)``,
  BottomPanel: styled.View`
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: ${calcHeight(IS_IOS ? 94 : 69)}px;
    width: ${calcWidth(deviceWidth)}px;
    border-radius: 11px;
    flex-direction: row;
  `,
  DotsIconStyles: {
    transform: isWeb ? 'rotate(90deg)' : [{ rotate: '90deg' }],
    marginTop: calcHeight(22),
    width: calcWidth(20),
    height: calcHeight(20),
  } as StyleProp<ViewStyle>,
};

export default PreviewControlPanel;
