import { IBiteItem } from '../../types/bite';
import { IPlaylist } from '../../store/playlist/playlist.types';
import { EPreviewMode } from '../../components/shared/PreviewControlPanel/constants';
import { IQuestionnaireProps } from '../../components/shared/Questionnaire/Questionnaire';
import { MediaViewerProps } from '../../types/media';

export enum EBiteSection {
  discussion = 'discussion',
}
export interface IPreviewBite {
  bite: IBiteItem;
  withShare?: boolean;
  section?: EBiteSection;
  initialMode?: EPreviewMode;
}

export interface IPreviewExploreBite {
  bite: IBiteItem;
}

export interface IConfirmCloneBite {
  bite: IBiteItem;
}

export interface IPreviewPlaylist {
  playlist: IPlaylist;
  withShare?: boolean;
  isEdit?: boolean;
  initialMode?: EPreviewMode;
}

export interface IPreviewQuiz {
  quiz: IPlaylist;
  withShare?: boolean;
}

export interface IAcceptCoverChangeModalProps {
  onAccept: () => void;
  onReject?: () => void;
  onCancel?: () => void;
}

export interface IDashboard {
  path: string;
}

export type TVideoModalButtonStyle = 'primary' | 'secondary' | 'transparent';
export interface IVideoModal extends Partial<Omit<MediaViewerProps, 'onMediaDetails' | 'mediaURI'>> {
  isInline?: boolean;
  isVisible?: boolean;
  videoUrl: string;
  btnLeftLabel?: string;
  btnRightLabel: string;
  btnLeftStyle?: TVideoModalButtonStyle;
  btnRightStyle: TVideoModalButtonStyle;
  onBtnLeftClick?: () => void;
  onBtnRightClick: () => void;
  onBackdropPress?: () => void;
  onModalHide?: () => void;
  title?: string;
}

export enum EAppModalStackItemType {
  INTRO_VIDEO = 'INTRO_VIDEO',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  ONBOARDING_QUESTIONNAIRE = 'ONBOARDING_QUESTIONNAIRE',
  ACCEPT_COVER_CHANGE = 'ACCEPT_COVER_CHANGE',
  UPLOAD_BANUBA_FILES = 'UPLOAD_BANUBA_FILES',
}

export interface IModalStackItem {
  modalId?: number;
  type: EAppModalStackItemType;
  data?: (
    | IPreviewBite
    | IPreviewExploreBite
    | IConfirmCloneBite
    | IPreviewPlaylist
    | IPreviewQuiz
    | IDashboard
    | IAcceptCoverChangeModalProps
    | IQuestionnaireProps
    | IVideoModal
  ) & {
    onClose?: () => void;
    onModalHide?: () => void;
  };
}
