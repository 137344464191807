import { IPlaylist } from '../store/playlist/playlist.types';

export const doesPlaylistHaveSection = (playlist: IPlaylist, section: string): boolean => {
  return !!playlist.bite_shares?.find((biteShare) => biteShare.playlist_section === section);
};

export const isQuizHaveQuestions = (playlist: IPlaylist): boolean => {
  if (!playlist) {
    return false;
  }

  return playlist.is_quiz && doesPlaylistHaveSection(playlist, 'body');
};
