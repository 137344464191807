import React, { FC } from 'react';
import styled from 'styled-components/native';
import { View, StyleSheet } from 'react-native';
import { calcWidth, calcHeight, isWeb } from '../../utils/dimensions';

interface IProps {
  touchableContent?: boolean;
  children: React.ReactNode;
}

const EditScreenContent: FC<IProps> = ({ children, touchableContent }) => {
  return (
    <S.Container>
      <S.Overlay pointerEvents={touchableContent ? 'none' : 'auto'} />
      <View style={StyleSheet.absoluteFill}>{children}</View>
    </S.Container>
  );
};

const Container = styled.View`
  width: ${() => (isWeb ? 385 : `${calcWidth(315)}`)}px;
  flex: 1;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: ${() => (isWeb ? 32 : `${calcHeight(42)}`)}px;
`;
const Overlay = styled.View`
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: ${({ theme }) => theme.colors.black};
`;

const S = { Container, Overlay };

export default EditScreenContent;
