import React, { FC } from 'react';
import { TextProps } from 'react-native';
import styled from 'styled-components/native';
import { ITheme } from '../../themes/defaultTheme';

interface IProps extends TextProps {
  upperCase?: boolean;
}

const CustomText: FC<IProps> = (props) => {
  const { children } = props;
  return <S.Text {...props}>{children}</S.Text>;
};

const Text = styled.Text(
  ({ theme, upperCase }: IProps & ITheme) => `
  font-family: ${theme.fontFamilies.GilroyMedium};
  color: ${theme.colors.darkPrimary};
  font-size: ${theme.fontSizes.s16}px;
  ${upperCase && 'text-transform: uppercase;'}
`,
);

const S = { Text };

export default CustomText;
