import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/native';
import { calcWidth, calcHeight, isWeb } from '../../../../../../utils/dimensions';
import { IUserOrganizations } from '../../../../../../store/auth/auth.types';

import WorkspaceIcon from '../../../../../../assets/icons/workspace.svg';
import { StyleProp, ViewStyle } from 'react-native';
import SettingsButton from './SettingsButton';
import { APP_DRAWER_WIDTH } from '../../../../../../utils/constants/drawer';
import { workspaceItemDataSet } from '../../drawerContent.constants';

interface IProps {
  isActive: boolean;
  organization: IUserOrganizations;
  onPress: (id: number) => void;
  onPressSettings: (org: IUserOrganizations) => void;
}

const OrganizationListItem: React.FC<IProps> = (props) => {
  const { isActive, organization, onPress, onPressSettings } = props;
  const { id, name } = organization;
  const [isFocused, setFocused] = useState(false);

  const handleChangeOrganization = useCallback(() => {
    onPress(id);
  }, [id, onPress]);

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const dataSet = useMemo(() => workspaceItemDataSet(organization.id), [organization.id]);

  const renderOrganizationListItem = () => (
    <S.Container
      // @ts-ignore
      dataSet={dataSet}
      isFocused={isFocused}
      onPress={handleChangeOrganization}
    >
      <S.TitleContainer>
        {organization?.brand_share ? (
          <S.WorkspaceLogo
            source={{
              uri: organization?.brand_share,
            }}
          />
        ) : (
          <WorkspaceIcon style={S.WorkspaceIconStyles} />
        )}
        <S.Text numberOfLines={1} isActive={isActive}>
          {name}
        </S.Text>
      </S.TitleContainer>
      <SettingsButton onPress={onPressSettings} organization={organization} isFocused={isFocused} />
    </S.Container>
  );

  return isWeb ? (
    <div onMouseOver={handleFocus} onMouseLeave={handleBlur}>
      {renderOrganizationListItem()}
    </div>
  ) : (
    renderOrganizationListItem()
  );
};

const S = {
  Container: styled.TouchableOpacity<{ isFocused: boolean }>(
    ({ theme, isFocused }) => css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-top: ${calcHeight(20)};
      padding-bottom: ${calcHeight(20)};
      padding-left: ${calcHeight(6)};
      border-bottom-width: 1px;
      border-bottom-color: ${theme.colors.lightGray36};
      max-width: ${calcWidth(352)}px;
      margin: 0 ${calcWidth(24)}px;
      background-color: ${isFocused ? theme.colors.lightGray25 : 'transparent'};
    `,
  ),
  TitleContainer: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  WorkspaceLogo: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(32)}px;
    height: ${calcHeight(32)}px;
    margin-right: ${calcWidth(6)}px;
  `,
  WorkspaceIconStyles: {
    resizeMode: 'contain',
    width: calcWidth(32),
    height: calcWidth(32),
    marginRight: calcWidth(6),
  } as StyleProp<ViewStyle>,
  Text: styled.Text<{ isActive?: boolean }>(
    ({ theme, isActive }) => css`
      width: ${APP_DRAWER_WIDTH - calcWidth(150)}px;
      color: ${!isWeb && isActive ? theme.colors.primaryBlue : theme.colors.lightGray22};
      font-size: ${theme.fontSizes.s13}px;
      font-weight: 500;
      line-height: ${theme.fontSizes.s13}px;
    `,
  ),
};

export default OrganizationListItem;
