import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { biteQuestionSelector } from '../../store/biteQuestion/biteQuestion.selectors';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { IStackNavigation } from '../../navigation/types';
import { postOrUpdateQuizBiteRequest, setBiteName } from '../../store/createBite/createBites.actions';
import Routes from '../../navigation/routes';
import EditQuestion from '../Shared/EditQuestion';
import { setIsQuiz } from '../../store/biteQuestion/biteQuestion.actions';
import { updatePlaylist } from '../../store/createPlaylist/createPlaylist.actions';

const QuizEditQuestion: React.FC<IStackNavigation> = ({ route, navigation }) => {
  const dispatch = useDispatch();

  const { type } = useSelector(biteQuestionSelector);

  useEffect(() => {
    dispatch(setIsQuiz());
  }, [dispatch]);

  const handleContinue = useCallback(
    (setLoading, isMountRef, inputValue) => {
      setLoading(true);
      dispatch(setBiteName(inputValue.substring(0, 40)));
      dispatch(
        trackEvent({
          event: 'continue_btn',
          props: {
            page_title: type,
            from: Routes.EditQuizStack.QuizEditQuestion,
          },
        }),
      );
      const editComplete = () => {
        if (!isMountRef.current) {
          return;
        }

        setLoading(false);

        navigation.replace(Routes.EditQuizStack.StackName, {
          screen: Routes.EditQuizStack.EditQuiz,
        });
      };

      dispatch(
        postOrUpdateQuizBiteRequest({
          playlistSection: 'body',
          callback: editComplete,
        }),
      );

      dispatch(updatePlaylist());
    },
    [dispatch, navigation, type],
  );

  const props = {
    withDelete: false,
    from: Routes.EditQuizStack.QuizEditQuestion,
    onContinue: handleContinue,
    navigation,
    route,
  };

  return <EditQuestion {...props} />;
};

export default QuizEditQuestion;
