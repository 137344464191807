import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, ScrollView, Alert } from 'react-native';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useSelector, useDispatch } from 'react-redux';
import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';
import {
  createPlaylistSelector,
  playlistSettingsSelector,
  selectedBiteIdsToRemoveMapSelector,
} from '../../../store/createPlaylist/createPlaylist.selectors';
import {
  cleanSelectedToRemoveBites,
  postPlaylist,
  removeSelectedBites,
  updatePlaylist,
  updatePlaylistSettings,
  updateSelectedBites,
} from '../../../store/createPlaylist/createPlaylist.actions';
import { OrganizeBiteItem } from './components';
import { ChipButton } from '../../../components/shared/buttons';
import Chip from '../../../components/shared/Chip';
import useIsCurrentScreen from '../../../hooks/useIsCurrentScreen';
import Routes from '../../../navigation/routes';
import SwitchWithText from '../../../components/shared/SwitchWithText';
import BottomController from '../components/BottomController';
import { CustomText } from '../../../components/shared';
import { css } from 'styled-components';
import { useTheme } from 'styled-components/native';
import DeleteButton from '../../../components/shared/buttons/DeleteButton';
import HeaderTitle from './components/HeaderTitle';
import { useIsFocused } from '@react-navigation/native';
import { deletePlaylist } from '../../../store/playlist/playlist.actions';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import {
  organizePlaylistContinueButtonDataSet,
  watchInOrderToggleDataSet,
} from './components/organizePlaylist.constants';

const localePrefix = 'createPlaylistStack';

const OrganizePlaylist = (props) => {
  const { navigation } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { selectedBites } = useSelector(createPlaylistSelector);
  const isCurrentScreen = useIsCurrentScreen();
  const playlistSettings = useSelector(playlistSettingsSelector);
  const { editedPlaylist, editMode } = useSelector(createPlaylistSelector);
  const [isDeleteBiteMode, setIsDeleteBiteMode] = useState(false);
  const selectedBiteIdsToRemoveMap = useSelector(selectedBiteIdsToRemoveMapSelector);

  const selectedBitesToRemoveNum = useMemo(() => {
    return Object.keys(selectedBiteIdsToRemoveMap).length;
  }, [selectedBiteIdsToRemoveMap]);

  const handleToggleIsDeleteBiteMode = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'remove_bites_trash_btn',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
    setIsDeleteBiteMode(!isDeleteBiteMode);
    dispatch(cleanSelectedToRemoveBites());
  }, [dispatch, editedPlaylist?.id, isDeleteBiteMode]);

  const navigateHome = useCallback(() => {
    if (isWeb) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Routes.HomeStack.StackName,
            params: {
              screen: Routes.HomeStack.Home,
            },
          },
        ],
      });
      return;
    }
    navigation.popToTop();
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  }, [navigation]);

  const handleDeleteBites = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'remove_bites_from_playlist',
        props: {
          playlist_id: editedPlaylist?.id,
          number_of_bites: selectedBitesToRemoveNum,
        },
      }),
    );
    setIsDeleteBiteMode(false);
    dispatch(removeSelectedBites());
    if (selectedBitesToRemoveNum === selectedBites.length) {
      navigateHome();
    }
  }, [editedPlaylist?.id, selectedBitesToRemoveNum, dispatch, selectedBites.length, navigateHome]);

  useEffect(() => {
    if (isCurrentScreen && !selectedBites.length) {
      navigation.goBack();
    }
  }, [isCurrentScreen, navigation, selectedBites]);

  const renderDeleteButton = useCallback(
    () => (
      <DeleteButton
        isActive={isDeleteBiteMode}
        onPress={handleToggleIsDeleteBiteMode}
        width={calcWidth(41)}
        height={calcWidth(41)}
      />
    ),
    [isDeleteBiteMode, handleToggleIsDeleteBiteMode],
  );

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) {
      return;
    }
    navigation.setOptions({
      headerTitle: isDeleteBiteMode
        ? t(`${localePrefix}.organizePlaylist.removeBites`)
        : () => <HeaderTitle isEditMode={editMode} />,
      headerRight: renderDeleteButton,
      withShadow: true,
    });
  }, [
    selectedBites,
    isDeleteBiteMode,
    handleToggleIsDeleteBiteMode,
    navigation,
    t,
    isFocused,
    editMode,
    renderDeleteButton,
  ]);

  const handleToggleIsLinear = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'playlist_change_toggle',
        props: {
          playlist_id: editedPlaylist?.id,
          toggle_type: 'is_linear',
          is_on: !playlistSettings.is_linear,
        },
      }),
    );
    dispatch(updatePlaylistSettings('is_linear'));
  }, [editedPlaylist?.id, playlistSettings.is_linear, dispatch]);

  const handleDragEnd = useCallback(
    ({ data }) => {
      dispatch(
        trackEvent({
          event: 'playlist_change_order',
          props: {
            playlist_id: editedPlaylist?.id,
          },
        }),
      );
      dispatch(updateSelectedBites(data));
    },
    [dispatch, editedPlaylist?.id],
  );

  const handleCreatePlaylist = useCallback(() => {
    if (editMode) {
      dispatch(updatePlaylist());
      navigation.goBack();
      dispatch(
        trackEvent({
          event: 'save_playlist_order_btn',
          props: {
            playlist_id: editedPlaylist?.id,
          },
        }),
      );
      return;
    }
    navigation.popToTop();
    navigation.replace(Routes.CreatePlaylistStack.PlaylistCreated);
    dispatch(postPlaylist());

    dispatch(
      trackEvent({
        event: 'continue_playlist_order_btn',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
  }, [editMode, navigation, dispatch, editedPlaylist?.id]);

  const renderItem = useCallback(
    (item) => <OrganizeBiteItem renderItemParams={item} isDeleteBiteMode={isDeleteBiteMode} />,
    [isDeleteBiteMode],
  );

  const renderPlaceholder = useCallback(
    () => (
      <DropPlaceholderContainer>
        <DropPlaceholder />
      </DropPlaceholderContainer>
    ),
    [],
  );

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const deletePlaylistAndNavigateHome = useCallback(() => {
    dispatch(deletePlaylist(editedPlaylist?.id));
    navigateHome();
  }, [dispatch, editedPlaylist?.id, navigateHome]);

  const handleTrackDeletePlaylist = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'delete',
        props: {
          playlist_id: editedPlaylist?.id,
        },
      }),
    );
  }, [dispatch, editedPlaylist?.id]);

  const handleDeletePlaylistWeb = useCallback(() => {
    const isConfirmed = confirm(t('common.areYouSure'));
    if (isConfirmed) {
      deletePlaylistAndNavigateHome();
      handleTrackDeletePlaylist();
    }
  }, [t, deletePlaylistAndNavigateHome, handleTrackDeletePlaylist]);

  const handleDeletePlaylistMobile = useCallback(() => {
    Alert.alert(t('common.areYouSure'), '', [
      {
        text: t('common.cancel'),
      },
      {
        text: t('common.yes'),
        onPress: () => {
          deletePlaylistAndNavigateHome();
          handleTrackDeletePlaylist();
        },
      },
    ]);
  }, [deletePlaylistAndNavigateHome, handleTrackDeletePlaylist, t]);

  const handleDeletePlaylist = useCallback(() => {
    if (isWeb) {
      handleDeletePlaylistWeb();
      return;
    }
    handleDeletePlaylistMobile();
  }, [handleDeletePlaylistMobile, handleDeletePlaylistWeb]);

  return (
    <S.Container>
      <ScrollView bounces={false} showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollView}>
        <DraggableFlatList
          containerStyle={styles.flatList}
          scrollEnabled={true}
          dragItemOverflow={true}
          bounces={false}
          data={selectedBites}
          keyExtractor={keyExtractor}
          onDragEnd={handleDragEnd}
          autoscrollThreshold={0.8}
          autoscrollSpeed={100}
          renderItem={renderItem}
          renderPlaceholder={renderPlaceholder}
        />
      </ScrollView>

      <BottomController>
        {!isDeleteBiteMode ? (
          <S.ButtonsContainer>
            <SwitchWithText
              isSelected={playlistSettings.is_linear}
              label={t(`${localePrefix}.playlistSettings.settings.linearOrder`)}
              onToggle={handleToggleIsLinear}
              //@ts-ignore
              dataSet={watchInOrderToggleDataSet}
            />
            <ChipButton
              content={t(`common.${editMode ? 'Save' : 'Continue'}`)}
              disabled={selectedBites.length === 0}
              onPress={handleCreatePlaylist}
              dataSet={organizePlaylistContinueButtonDataSet}
            />
          </S.ButtonsContainer>
        ) : (
          <S.ButtonsContainer>
            <S.LeftComponent>
              {selectedBitesToRemoveNum === selectedBites.length ? (
                <S.DeletePlaylistText>{t(`${localePrefix}.organizePlaylist.deletePlaylistNote`)}</S.DeletePlaylistText>
              ) : null}
              <S.BitesLengthWrapper>
                <Chip bgColor={theme.colors.pink}>{selectedBitesToRemoveNum}</Chip>
                <S.SelectedText>{t(`${localePrefix}.addBitesToPlaylist.bitesSelected`)}</S.SelectedText>
              </S.BitesLengthWrapper>
            </S.LeftComponent>
            <ChipButton
              content={
                selectedBitesToRemoveNum === selectedBites.length
                  ? t(`${localePrefix}.organizePlaylist.deletePlaylist`)
                  : t(`common.${'remove'}`)
              }
              color={theme.colors.pinkDark}
              disabled={selectedBitesToRemoveNum === 0}
              onPress={
                selectedBitesToRemoveNum === selectedBites.length && editMode ? handleDeletePlaylist : handleDeleteBites
              }
            />
          </S.ButtonsContainer>
        )}
      </BottomController>
    </S.Container>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  flatList: {
    paddingBottom: calcHeight(100),
    minHeight: '100%',
  },
});

const BitesLength = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.Arimo};
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.gray19};
    display: flex;
    justify-content: center;
    margin-top: ${calcWidth(-30)}px;
    margin-left: ${() => (isWeb ? '' : `${calcWidth(170)}px`)};
  `,
);
const Container = styled.View`
  flex: 1;
`;
const ContinueButtonContainer = styled.View`
  padding-right: ${calcWidth(15)}px;
`;

const SelectedText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.Arimo};
    font-size: ${theme.fontSizes.s11}px;
    color: ${theme.colors.gray1};
  `,
);

const BitesLengthWrapper = styled.View`
  width: ${calcWidth(80)}px;
  height: ${calcHeight(26)}px;
  border-radius: 18px;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const DeletePlaylistText = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.Arimo};
    font-size: ${theme.fontSizes.s11}
    color: #ce0a70;
    position: absolute;
    left:${calcWidth(5)}px; 
    bottom: ${() => (isWeb ? `${calcHeight(40)}px` : `${calcHeight(36)}px`)};
    width:${calcWidth(250)}px; 
  `,
);

const LeftComponent = styled.View`
  flex-direction: column;
`;

const DropPlaceholderContainer = styled.View`
  padding: 0 ${calcWidth(30)}px;
  width: 100%;
`;
const DropPlaceholder = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
`;

const ButtonsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0 ${calcWidth(24)}px 0 ${calcWidth(12)}px;
`;

const S = {
  Container,
  ContinueButtonContainer,
  BitesLength,
  SelectedText,
  BitesLengthWrapper,
  DeletePlaylistText,
  LeftComponent,
  DropPlaceholderContainer,
  DropPlaceholder,
  ButtonsContainer,
};

export default OrganizePlaylist;
