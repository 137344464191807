import {
  IUser,
  IGetBitesAuthTokenPayload,
  IEditProfileData,
  ISignupPhoneCredentials,
  ISignupEmailCredentials,
  TSignupErrorCodes,
  INewPasswordCredentials,
  ILoginWithPhone,
  IPasswordRecoveryBody,
  IFinalizeProfileAction,
} from './auth.types';
import Types, { ISignInCredentials } from './auth.types';

export const refreshUser = () => ({
  type: Types.REFRESH_USER,
});

export const refreshUserSuccess = (payload: { user: IUser; isSignup: boolean }) => ({
  type: Types.REFRESH_USER_SUCCESS,
  payload,
});

export const refreshUserFailure = () => ({
  type: Types.REFRESH_USER_FAILURE,
});

export const setRefreshUserErrors = (payload: string[]) => ({
  type: Types.SET_REFRESH_USER_ERRORS,
  payload,
});

export const clearUserProfileErrorsByType = (payload: string[]) => ({
  type: Types.CLEAR_USER_PROFILE_ERRORS_BY_TYPE,
  payload,
});

export const initAuthRequest = (initialLocationSearch?: string) => ({
  type: Types.INIT_AUTH_REQUEST,
  payload: initialLocationSearch,
});

export const initAuthSuccess = (payload: { user: IUser; isSignup: boolean }) => ({
  type: Types.INIT_AUTH_SUCCESS,
  payload,
});

export const initAuthFailure = () => ({
  type: Types.INIT_AUTH_FAILURE,
});

export const setWrongVerificationCode = (state: boolean) => ({
  type: Types.SET_WRONG_VERIFICATION_CODE,
  payload: state,
});

export const setCodeSent = () => ({
  type: Types.SET_CODE_SENT,
});

export const setIsPhoneMissing = (isPhoneMissing: boolean) => ({
  type: Types.SET_IS_PHONE_MISSING,
  payload: isPhoneMissing,
});

export const login = (payload: ISignInCredentials) => ({
  type: Types.LOGIN,
  payload,
});

export const setSignupErrorCodes = (payload: { errorCodes: TSignupErrorCodes[]; errorMessage: string }) => ({
  type: Types.SET_AUTH_ERROR_CODES,
  payload,
});

export const loginWithPhone = ({ credentials, options = { isSignup: false }, processId, onOtp }: ILoginWithPhone) => ({
  type: Types.LOGIN_WITH_PHONE_REQUEST,
  payload: { credentials, options, processId, onOtp },
});

export const signupWithPhone = (credentials: ISignupPhoneCredentials) => ({
  type: Types.SIGNUP_WITH_PHONE_REQUEST,
  payload: credentials,
});

export const signupWithEmail = (credentials: ISignupEmailCredentials) => ({
  type: Types.SIGNUP_WITH_EMAIL_REQUEST,
  payload: credentials,
});

export const passwordRecovery = ({ email, callback }: IPasswordRecoveryBody) => ({
  type: Types.PASSWORD_RECOVERY_REQUEST,
  payload: { email, callback },
});

export const loginSuccess = (payload: { user: IUser; isSignup: boolean }) => ({
  type: Types.LOGIN_SUCCESS,
  payload,
});

export const loginFailure = () => ({
  type: Types.LOGIN_FAILURE,
});

export const stopLoginLoading = () => ({
  type: Types.STOP_LOGIN_LOADING,
});

export const resetAuthErrors = () => ({
  type: Types.RESET_AUTH_ERRORS,
});

export const passwordRecoverySuccess = () => ({
  type: Types.PASSWORD_RECOVERY_SUCCESS,
});

export const passwordRecoveryError = () => ({
  type: Types.PASSWORD_RECOVERY_ERROR,
  payload: true,
});

export const registerSuccess = (payload: { user: IUser; isSignup: boolean }) => ({
  type: Types.REGISTER_SUCCESS,
  payload,
});

export const logout = () => ({
  type: Types.LOGOUT,
});

export const setSsoInProgress = (isInProgress: boolean) => ({
  type: Types.SET_SSO_IN_PROGRESS,
  payload: isInProgress,
});

export const loginOrRegisterWithSocialToken = (payload: IGetBitesAuthTokenPayload) => ({
  type: Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN,
  payload,
});

export const loginOrRegisterWithSocialTokenSuccess = (user: IUser) => ({
  type: Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_SUCCESS,
  payload: user,
});

export const loginOrRegisterWithSocialTokenError = () => ({
  type: Types.LOGIN_OR_REGISTER_WITH_SOCIAL_TOKEN_ERROR,
});

export const switchActiveOrganization = (organizationId: number) => ({
  type: Types.SWITCH_ACTIVE_ORGANIZATION,
  payload: organizationId,
});

export const updateUserProfileAction = (userDetails: IEditProfileData) => ({
  type: Types.UPDATE_USER_PROFILE,
  payload: userDetails,
});

export const finalizeUserProfile = (payload: IFinalizeProfileAction) => ({
  type: Types.FINALIZE_USER_PROFILE,
  payload,
});

export const setProfileImageUrlToUpload = (imageUrl: string) => ({
  type: Types.SET_PROFILE_IMAGE_URL_TO_UPLOAD,
  payload: imageUrl,
});

export const setLeadName = (leadName: string) => ({
  type: Types.SET_LEAD_NAME,
  payload: leadName,
});

export const deleteMyUser = () => ({
  type: Types.DELETE_MY_USER,
});

export const setNewPassword = (payload: INewPasswordCredentials) => ({
  type: Types.SET_NEW_PASSWORD_REQUEST,
  payload,
});

export const setNewPasswordLoading = (payload: boolean) => ({
  type: Types.SET_NEW_PASSWORD_REQUEST_LOADING,
  payload,
});

export const setNewPasswordError = (payload?: string) => ({
  type: Types.SET_NEW_PASSWORD_REQUEST_ERROR,
  payload,
});
