import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { createStackNavigator, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { EditQuiz } from '../../../screens/editQuiz';
import QuizCreated from '../../../screens/createQuiz/QuizCreated';
import { DefaultTheme, useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes';
import Header from '../../../components/Header';
import AddQuestion from '../../../screens/Shared/EditQuestion/AddQuestion';
import QuizEditQuestion from '../../../screens/createQuiz/QuizEditQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFlow, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { useIsFocused } from '@react-navigation/native';
import { activateKeepAwake, deactivateKeepAwake } from '@sayem314/react-native-keep-awake';
import { currentFlowSelector } from '../../../store/appActivity/appActivity.selectors';
import { EAppFlow } from '../../../store/appActivity/appActivity.types';
// import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
import { IStackNavigation } from '../../types';
import { isWeb } from '../../../utils/dimensions';
// import useIsPlaylistOwner from '../../../hooks/useIsPlaylistOwner';
// import useCurrentRoute from '../../../hooks/useCurrentRoute';
// import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';

const defaultScreenOptions = { headerShown: false, animationEnabled: !isWeb };
const isQuizParams = { isQuiz: true };

interface IProps extends IStackNavigation {}

const Stack = createStackNavigator();

const EditQuizStack: FC<IProps> = ({ navigation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { index, routes } = navigation.getState();
  const isFocused = useIsFocused();

  // const { params } = useCurrentRoute();
  // const playlistId = params?.params?.playlistId;
  // const playlist = useSelector(playlistItemSelector(playlistId));
  // const isPlaylistOwner = useIsPlaylistOwner(playlist);
  // const isContentAdmin = useSelector(isContentAdminSelector);
  // const isUserCanEdit = isPlaylistOwner || isContentAdmin;
  const currentFlow = useSelector(currentFlowSelector);

  useEffect(() => {
    if (!isFocused) {
      if (currentFlow?.flow === EAppFlow.QUIZ_EDITION) {
        dispatch(setCurrentFlow(null));
        deactivateKeepAwake();
      }
      return;
    }

    dispatch(
      setCurrentFlow({
        flow: EAppFlow.QUIZ_EDITION,
      }),
    );
    activateKeepAwake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused]);

  useEffect(() => {
    try {
      if (routes[index]?.state === undefined) {
        dispatch(
          trackEvent({
            event: 'pageview',
            props: { page_title: 'EditQuiz' },
          }),
        );
      } else {
        const screens = routes[index]?.state?.routes;
        dispatch(
          trackEvent({
            event: 'pageview',
            props: { page_title: screens[screens.length - 1]?.name },
          }),
        );
      }
    } catch (err) {
      dispatch(
        trackEvent({
          event: 'pageview_error',
          props: { error: err },
        }),
      );
    }
  }, [routes, index, dispatch]);

  const screenOptions = useCallback(() => getScreenOptions(theme), [theme]);

  const optionsWithHeader = useMemo(() => {
    return {
      headerTitle: t('editQuiz.title'),
      header: getHeader,
    };
  }, [t]);

  // useEffect(() => {
  //   if (!isUserCanEdit) {
  //     navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  //   }
  // }, [isUserCanEdit, navigation]);

  // if (!isUserCanEdit) {
  //   return null;
  // }

  return (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen name={Routes.EditQuizStack.EditQuiz} component={EditQuiz} options={optionsWithHeader} />
      <Stack.Screen
        name={Routes.CreateBiteStack.AddQuestion}
        component={AddQuestion}
        options={optionsWithHeader}
        initialParams={isQuizParams}
      />
      <Stack.Screen
        name={Routes.EditQuizStack.QuizEditQuestion}
        component={QuizEditQuestion}
        options={optionsWithHeader}
      />
      <Stack.Screen name={Routes.EditQuizStack.QuizCreated} component={QuizCreated} options={defaultScreenOptions} />
    </Stack.Navigator>
  );
};

const getScreenOptions = (theme: DefaultTheme): StackNavigationOptions => {
  return {
    headerTitleStyle: {
      fontFamily: theme.fontFamilies.TTNormsMedium,
      fontSize: theme.fontSizes.s16,
      color: theme.colors.white,
      textTransform: 'uppercase',
    },
    headerTitleAlign: 'center' as 'center',
    cardStyle: {
      backgroundColor: theme.colors.white,
      flex: 1,
    },

    headerStyle: {
      backgroundColor: theme.colors.primaryBlue,
      shadowColor: 'transparent',
      elevation: 0,
    },
    ...TransitionPresets.SlideFromRightIOS,
    header: getHeader,
    animationEnabled: !isWeb,
  };
};

const getHeader = ({ options }) => (
  <Header
    onBackButtonPress={options?.onBackButtonPress}
    title={options?.headerTitle}
    headerRight={options?.headerRight}
    headerLeft={options?.headerLeft}
    withShadow={options?.withShadow}
  />
);

export default EditQuizStack;
