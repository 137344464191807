import React, { FC, useCallback } from 'react';
import { TouchableOpacity, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { calcHeight } from '../../../../../utils/dimensions';
import { CustomText } from '../../../../shared';
import { modalButtonDataSet } from './ModalButton.constants';

interface IProps {
  bottomButton?: boolean;
  text: string;
  action: () => void;
}

const ModalButton: FC<IProps> = ({ bottomButton, text, action }) => {
  const handleAction = useCallback(() => {
    if (typeof action === 'function') {
      action();
    }
  }, [action]);

  return (
    <TouchableOpacity
      onPress={handleAction}
      //@ts-ignore
      dataSet={modalButtonDataSet}
    >
      <S.Button>
        <S.ButtonText bottomButton={bottomButton}>{text}</S.ButtonText>
      </S.Button>
    </TouchableOpacity>
  );
};

const Button = styled.View(
  ({ theme }) => css`
    background-color: ${theme.colors.lightGray3};
    width: 100%;
    height: ${calcHeight(50)}px;
    justify-content: center;
    align-items: center;
  `,
);

const ButtonText = styled(CustomText)<{ bottomButton?: boolean }>(
  ({ theme, bottomButton }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    color: ${bottomButton ? theme.colors.lightRed : theme.colors.lightGray4};
    ${Platform.OS === 'ios' && `height:${calcHeight(16)}px`};
  `,
);

const S = { Button, ButtonText };

export default ModalButton;
