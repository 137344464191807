import React from 'react';
import { useNavigation } from '@react-navigation/native';
import ContinueButton from './ContinueButton';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { useSelector } from 'react-redux';
import { IButtonProps } from './Button';
import { useTranslation } from 'react-i18next';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps extends IButtonProps {
  onDynamicPress: (isEditMode: boolean) => void;
  handleGoBack?: boolean;
  navigateToOnEditSave?: string;
}
const DynamicContinueButton: React.FC<IProps> = ({
  handleGoBack = true,
  onDynamicPress,
  navigateToOnEditSave,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { selectedBite } = useSelector(biteSelector);

  return selectedBite ? (
    // when editing bite
    <ContinueButton
      {...rest}
      content={t('common.save')}
      onPress={() => {
        onDynamicPress?.(true);
        if (navigateToOnEditSave) {
          if (navigateToOnEditSave === 'fromMarkCorrectAnswer') {
            // hack to navigate to parent screen
            navigation.goBack();
            navigation.goBack();
          } else {
            navigation.navigate(navigateToOnEditSave);
          }
        } else if (handleGoBack) {
          navigation.goBack();
        }
      }}
      disabled={false}
    />
  ) : (
    // when creating bite
    <ContinueButton {...rest} onPress={() => onDynamicPress?.(false)} />
  );
};

export default DynamicContinueButton;
