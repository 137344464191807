import React, { FC, useCallback, useMemo, useState } from 'react';
import { calcWidth, deviceHeight, isWeb } from '../../../utils/dimensions';
import {
  editBiteIntroVideo,
  editBiteIntroMediaData,
  setBiteCover,
} from '../../../store/createBite/createBites.actions';
import { useDispatch, useSelector } from 'react-redux';
import MediaViewer from '../../../components/shared/MediaViewer';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import Routes from '../../../navigation/routes';
import { createBiteSelector } from '../../../store/createBite/createBite.selectors';
import { setBites } from '../../../store/bite/bite.actions';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import { log, setCurrentFlow, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { IStackNavigation } from '../../../navigation/types';
import TellYourStory, { IUploadVideoProps } from '../../Shared/TellYourStory/TellYourStory';
import ProcessingAnimation from '../../Shared/ProcessingAnimation';
import { displayAppModal } from '../../../store/appModals/appModals.slice';
import { EAppModalStackItemType } from '../../../store/appModals/appModals.types';
import { EExportType } from '../../../services/videoEditor/videoEditor';
import { ILaunchVideoEditorResult } from '../../../types/media';
import { webVideoExport } from '../../../store/videoEditor/videoEditor.slice';
import { IS_PROD } from '../../../utils/constants/env';
import { Features } from '../../../utils/featureFlag/featureFlag.types';
import { organizationHasFeatureFlagSelector } from '../../../store/org/org.selectors';

const MAX_MEDIA_HEIGHT = isWeb ? deviceHeight - 235 : deviceHeight - 420;

interface IProps extends IStackNavigation {}

const BiteTellYourStory: FC<IProps> = ({ navigation, route }) => {
  const isEditMode = route.params?.isEditMode;
  const isPrefilled = route.params?.isPrefilled;
  const openImportMediaPanel = route.params?.openImportMediaPanel;
  const launchVideoEditorWithDrafts = route.params?.launchVideoEditorWithDrafts;

  const dispatch = useDispatch();

  const [isIntroUpdated, setIntroUpdated] = useState(false);
  const { selectedBite } = useSelector(biteSelector);
  const { introMediaProcessingStatus } = useSelector(createBiteSelector);
  const biteQuestion = useSelector(biteQuestionSelector);
  const isBiteQuestionCreated = biteQuestion.isCreated;

  const withDesktopVideoEditor = useSelector(organizationHasFeatureFlagSelector(Features.DESKTOP_VIDEO_EDITOR));

  const formattedVideoSizes = useMemo(() => {
    return {
      width: calcWidth((9 / 16) * MAX_MEDIA_HEIGHT),
      height: calcWidth(MAX_MEDIA_HEIGHT),
    };
  }, []);

  const handleRedirectToNextScreen = useCallback(
    (setLoading, isVideo = false) => {
      setLoading(false);
      setIntroUpdated(false);

      if (isEditMode) {
        navigation.goBack();
        return;
      }

      if (isVideo && isIntroUpdated) {
        navigation.navigate(Routes.CreateBiteStack.AIExpectationFirst, { isPrefilled });
        return;
      }

      if (isBiteQuestionCreated) {
        navigation.navigate(Routes.CreateBiteStack.BiteEditQuestion, { isPrefilled });
        return;
      }

      navigation.navigate(Routes.CreateBiteStack.AddQuestion);
    },
    [isBiteQuestionCreated, isEditMode, isIntroUpdated, isPrefilled, navigation],
  );

  const isIntroMediaProcessing = introMediaProcessingStatus.all === EIntroMediaProcessingStatus.PROCESSING;

  const handleSkip = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'skip_intro_creation',
        props: { type: 'bite' },
      }),
    );
    if (isBiteQuestionCreated || isPrefilled) {
      navigation.navigate(Routes.CreateBiteStack.BiteEditQuestion, { isPrefilled });
      return;
    }
    navigation.navigate(Routes.CreateBiteStack.AddQuestion);
  }, [dispatch, isBiteQuestionCreated, isPrefilled, navigation]);

  const handleIntroUpdate = useCallback(
    ({
      file,
      videoUri,
      videoMeta,
      thumbnail,
      withThumbnailUpdate,
      setLoading,
    }: {
      file: IUploadVideoProps['file'];
      videoUri: IUploadVideoProps['videoUri'];
      videoMeta: IUploadVideoProps['videoMeta'];
      thumbnail?: IUploadVideoProps['thumbnail'];
      withThumbnailUpdate?: boolean;
      setLoading: (loading: boolean) => void;
    }) => {
      dispatch(
        editBiteIntroVideo({
          file,
          videoUri,
          videoMeta,
          thumbnail,
          withThumbnailUpdate,
        }),
      );

      handleRedirectToNextScreen(setLoading, true);
    },
    [dispatch, handleRedirectToNextScreen],
  );

  const handleUploadVideo = useCallback(
    async ({ file, videoUri, videoMeta, thumbnail, setLoading }: IUploadVideoProps) => {
      if (isEditMode && selectedBite?.linked_cover_url) {
        dispatch(
          displayAppModal({
            type: EAppModalStackItemType.ACCEPT_COVER_CHANGE,
            data: {
              onAccept: async () => {
                handleIntroUpdate({ file, videoUri, videoMeta, thumbnail, withThumbnailUpdate: true, setLoading });
              },
              onReject: () => {
                handleIntroUpdate({ file, videoUri, videoMeta, setLoading });
              },
              onCancel: () => {
                setLoading(false);
              },
            },
          }),
        );
        return;
      }

      handleIntroUpdate({ file, videoUri, videoMeta, thumbnail, withThumbnailUpdate: true, setLoading });
    },
    [dispatch, handleIntroUpdate, isEditMode, selectedBite?.linked_cover_url],
  );

  const renderMediaViewer = useCallback(
    (mediaViewerProps) => {
      if (isIntroMediaProcessing && (isEditMode || !mediaViewerProps.mediaURI)) {
        return (
          <ProcessingAnimation
            width={formattedVideoSizes.width}
            height={formattedVideoSizes.height}
            backgroundImageUri={selectedBite?.linked_cover_url}
          />
        );
      }

      return <MediaViewer {...mediaViewerProps} />;
    },
    [formattedVideoSizes, isEditMode, isIntroMediaProcessing, selectedBite?.linked_cover_url],
  );

  const handleUpdateIntroMediaData = useCallback(
    (setLoading, thumbnailUrl, media) => {
      if (thumbnailUrl) {
        dispatch(setBiteCover(null, thumbnailUrl));
      }
      if (selectedBite) {
        const updatedBite = thumbnailUrl
          ? {
              ...selectedBite,
              cover_url: thumbnailUrl,
              linked_cover_url: thumbnailUrl,
            }
          : selectedBite;
        dispatch(setBites([updatedBite]));
      }

      const updatedBite = thumbnailUrl
        ? {
            linked_cover_url: thumbnailUrl,
          }
        : null;

      dispatch(
        editBiteIntroMediaData({
          biteId: selectedBite?.id,
          bite: updatedBite,
          media,
          callback: () => handleRedirectToNextScreen(setLoading),
        }),
      );
    },
    [dispatch, handleRedirectToNextScreen, selectedBite],
  );

  const onMediaSelect = useCallback(
    (setLoading, thumbnailUrl, media) => {
      if (selectedBite?.linked_cover_url && isEditMode) {
        dispatch(
          displayAppModal({
            type: EAppModalStackItemType.ACCEPT_COVER_CHANGE,
            data: {
              onAccept: () => handleUpdateIntroMediaData(setLoading, thumbnailUrl, media),
              onReject: () => handleUpdateIntroMediaData(setLoading, undefined, media),
              onCancel: () => {
                setLoading(false);
              },
            },
          }),
        );
        return;
      }

      handleUpdateIntroMediaData(setLoading, thumbnailUrl, media);
    },
    [dispatch, handleUpdateIntroMediaData, isEditMode, selectedBite?.linked_cover_url],
  );

  const handleVideoEditorResult = useCallback(
    async (videoEditorResult: ILaunchVideoEditorResult) => {
      const { audioUri, videoUri, videoMeta } = videoEditorResult;

      dispatch(setCurrentFlow({ hasIntroVideo: true, videoUploadedToS3: false }));

      dispatch(
        log({
          event: 'handleVideoEditorResult: Navigating to AIExpectationFirst screen',
          data: {
            isPrefilled,
            audioUri,
            videoUri,
          },
        }),
      );

      navigation.navigate(Routes.CreateBiteStack.AIExpectationFirst, { isPrefilled });

      dispatch(
        editBiteIntroVideo({
          audioUri,
          videoUri,
          videoMeta,
          withThumbnailUpdate: true,
        }),
      );
    },
    [dispatch, isPrefilled, navigation],
  );

  const handleWebVideoEditorContinue = useCallback(() => {
    dispatch(webVideoExport());
    if (isEditMode) {
      navigation.goBack();
      navigation.goBack();

      return;
    }

    navigation.navigate(
      Routes.CreateBiteStack.AIExpectationFirst,
      // , { isPrefilled: false }
    );
  }, [dispatch, isEditMode, navigation]);

  const withWebVideoEditor = isWeb && (!IS_PROD || withDesktopVideoEditor);

  return (
    <TellYourStory
      from={Routes.CreateBiteStack.BiteTellYourStory}
      renderMediaViewer={renderMediaViewer}
      onSkip={handleSkip}
      navigation={navigation}
      route={route}
      isEditMode={isEditMode}
      isPrefilled={isPrefilled}
      launchVideoEditorWithDrafts={launchVideoEditorWithDrafts}
      onMediaSelect={onMediaSelect}
      handleRedirectToNextScreen={handleRedirectToNextScreen}
      openImportMediaPanel={openImportMediaPanel}
      isHelperTemplatesEnabled={!isEditMode}
      setIntroUpdated={setIntroUpdated}
      isIntroUpdated={isIntroUpdated}
      onUploadVideo={handleUploadVideo}
      onVideoEditorResult={!isWeb && !isEditMode ? handleVideoEditorResult : undefined}
      videoEditorExportType={isWeb ? undefined : isEditMode ? EExportType.VIDEO_SYNCHRONOUS : EExportType.ASYNCHRONOUS}
      onWebVideoEditorContinue={withWebVideoEditor ? handleWebVideoEditorContinue : undefined}
    />
  );
};

export default BiteTellYourStory;
