import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components/native';
import FormTextInput from '../../../components/shared/inputs/FormTextInput/FormTextInput';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentFlowSelector,
  emailFormSelector,
  passwordFormSelector,
} from '../../../store/authForm/authForm.selectors';
import { setCurrentForm, setEmail, setPassword } from '../../../store/authForm/authForm.slice';
import { login, resetAuthErrors, signupWithEmail } from '../../../store/auth/auth.actions';
import { log } from '../../../store/appActivity/appActivity.slice';
import { v4 as uuid } from 'uuid';
import { getIsEmailValid, isValidPasswordMessage } from '../../../utils/auth';
import { EAuthFlowType, EAuthFormType } from '../auth.types';
import { authInProgressSelector } from '../../../store/auth/auth.selectors';
import GenericButton from '../../../components/shared/buttons/GenericButton';
import { continueButtonDataSet, emailButtonDataSet, passwordButtonDataset } from './email.constants';

const EmailForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const email = useSelector(emailFormSelector);
  const password = useSelector(passwordFormSelector);
  const authInProgress = useSelector(authInProgressSelector);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const invalidPasswordMessage = isValidPasswordMessage(password);
  const isEmailValid = getIsEmailValid(email);

  const currentFlow = useSelector(currentFlowSelector);
  const currentForm = EAuthFormType.AUTH;

  const handleChangeEmail = useCallback(
    (text) => {
      dispatch(setEmail(text));
      setEmailError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleChangePassword = useCallback(
    (text) => {
      dispatch(setPassword(text));
      setPasswordError(null);
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.navigateTo',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_EMAIL, currentFlow, currentForm },
        }),
      );
      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_EMAIL));
    },
    [currentFlow, currentForm, dispatch],
  );

  const handleEmailSignUpSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleEmailSignUpSubmit',
          processId,
          data: {
            currentFlow,
            currentForm,
            email: email.trim(),
            password,
            creator: false,
          },
        }),
      );

      dispatch(
        signupWithEmail({
          email: email.trim(),
          organizations: [],
          password,
          creator: false,
          processId,
          onOtp: handleOtp,
        }),
      );
    },
    [currentFlow, currentForm, dispatch, email, handleOtp, password],
  );

  const handleEmailSignInSubmit = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'EmailForm.handleEmailSignInSubmit',
          processId,
          data: {
            currentFlow,
            currentForm,
            username: email.trim(),
            password,
          },
        }),
      );

      dispatch(
        login({
          username: email.trim(),
          password,
          processId,
          onOtp: handleOtp,
        }),
      );
    },
    [dispatch, currentFlow, currentForm, email, password, handleOtp],
  );

  const handleEmailSubmit = useCallback(
    (processId) => {
      if (currentFlow === EAuthFlowType.SIGN_IN) {
        handleEmailSignInSubmit(processId);
        return;
      }
      handleEmailSignUpSubmit(processId);
    },
    [currentFlow, handleEmailSignInSubmit, handleEmailSignUpSubmit],
  );

  const handleContinuePress = useCallback(() => {
    if (authInProgress) {
      return;
    }

    const processId = uuid();
    dispatch(
      log({
        event: 'AuthForm.handleContinuePress',
        processId,
        data: {
          currentFlow,
          currentForm,
          password,
          invalidPasswordMessage,
          isEmailValid,
        },
      }),
    );

    if (invalidPasswordMessage && currentFlow === EAuthFlowType.SIGN_UP) {
      setPasswordError(invalidPasswordMessage);
    }

    if (!isEmailValid) {
      setEmailError(t('authForm.emailIsNotValid'));
    }

    if (!(invalidPasswordMessage && currentFlow === EAuthFlowType.SIGN_UP) && isEmailValid) {
      handleEmailSubmit(processId);
    }
  }, [
    authInProgress,
    dispatch,
    currentFlow,
    currentForm,
    password,
    invalidPasswordMessage,
    isEmailValid,
    t,
    handleEmailSubmit,
  ]);

  const handleForgotPasswordPress = useCallback(() => {
    dispatch(
      log({
        event: 'EmailForm.navigateTo',
        data: { destination: EAuthFormType.FORGOT_PASSWORD_EMAIL, currentFlow, currentForm },
      }),
    );
    dispatch(setCurrentForm(EAuthFormType.FORGOT_PASSWORD_EMAIL));
  }, [currentFlow, currentForm, dispatch]);

  return (
    <>
      <S.TextInput
        value={email}
        onChangeText={handleChangeEmail}
        label={t('authForm.email')}
        keyboardType={'email-address'}
        autoCompleteType={'email'}
        onEnterPress={handleContinuePress}
        error={emailError}
        //@ts-ignore
        dataSet={emailButtonDataSet}
      />
      <S.TextInput
        value={password}
        onChangeText={handleChangePassword}
        label={t('authForm.password')}
        autoCompleteType={'password'}
        error={passwordError}
        onEnterPress={handleContinuePress}
        password
        //@ts-ignore
        dataSet={passwordButtonDataset}
      />
      <S.ContinueButton
        isLoading={authInProgress}
        onPress={handleContinuePress}
        content={t('common.Continue')}
        //@ts-ignore
        dataSet={continueButtonDataSet}
      />
      {currentFlow === EAuthFlowType.SIGN_IN && (
        <S.ForgotPassword onPress={handleForgotPasswordPress}>
          <S.ForgotPasswordText>{t('authForm.forgotPassword')}</S.ForgotPasswordText>
        </S.ForgotPassword>
      )}
    </>
  );
};

const S = {
  TextInput: styled(FormTextInput)`
    margin-bottom: ${calcHeight(18)}px;
  `,
  ContinueButton: styled(GenericButton)`
    min-height: ${calcHeight(50)}px;
    min-width: ${calcWidth(298)}px;
  `,
  ForgotPassword: styled.TouchableOpacity`
    margin-top: ${calcHeight(24)}px;
  `,
  ForgotPasswordText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.gray19};
  `,
};

export default memo(EmailForm);
