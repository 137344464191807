import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import GenericButton from '../../../../../components/shared/buttons/GenericButton';
import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import BitesLogoIcon from '../../../../../assets/icons/bites-logo.svg';
import { useTranslation } from 'react-i18next';
import { Platform, StatusBar } from 'react-native';
import ImprovesList from '../ImprovesList';
import { EImproves } from '../../aiExpectation.constants';
import { continueBtnDataSet } from './aiReady.constants';

interface IProps {
  improves: EImproves[];
  onContinuePress: () => void;
}

const AIReady: FC<IProps> = ({ improves, onContinuePress }) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <BitesLogoIcon width='91' height='49' />
          <S.HeaderTitle>{t('aiReady.title')}</S.HeaderTitle>
        </S.Header>
        <S.HeaderDescription>{t('aiReady.description')}</S.HeaderDescription>

        <ImprovesList improves={improves} />
      </S.Content>

      <S.ContinueButtonContainer>
        <S.ContinueButton
          onPress={onContinuePress}
          width={calcWidth(226)}
          content={t('common.continue')}
          // @ts-ignore
          dataSet={continueBtnDataSet}
        />
      </S.ContinueButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.SafeAreaView`
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  `,
  Content: styled.View`
    max-width: ${calcWidth(280)}px;
    flex: 6;
    align-items: center;
    justify-content: center;
  `,
  ContinueButtonContainer: styled.View`
    flex: 1;
  `,
  ContinueButton: styled(GenericButton)``,
  Header: styled.View`
    align-items: center;
  `,
  HeaderTitle: styled.Text`
    margin-top: ${calcHeight(24)}px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s20};
    line-height: ${({ theme }) => theme.fontSizes.s28};
  `,
  HeaderDescription: styled.Text`
    margin-top: ${calcHeight(30)}px;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s20};
    text-align: center;
  `,
};

export default memo(AIReady);
