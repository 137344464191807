import { getAnalytics, logEvent, setUserId, setUserProperties } from '@firebase/analytics';
import { browserName } from 'react-device-detect';
import Cookies from 'js-cookie';

import { IGtmSetUserId, IGtmTrack, ISetGaMarketingClientId, ISetUserProps } from './track.types';
import app from '../firebase/app';
import { IUser } from '../../store/auth/auth.types';
import log from '../log';
import Intercom from '../intercom';
import store from '../../store';
import { logError } from '../../store/appActivity/appActivity.slice';
import toStringValues from './utils/toStringValues';
import { IWebGenericTrackingProps } from '../../store/appActivity/appActivity.types';
import { getIntercomUserProps } from './intercom-filter';
import { trackEvent } from '../../store/api/bites-api/calls/common.calls';

interface IIPData {
  ip: string;
  latitude: number;
  longitude: number;
}

interface IIPInfo {
  ip?: IIPData;
}

const analytics = getAnalytics(app);

const URL = 'https://ip.nf/me.json';
let info: IIPInfo = {};

fetch(URL, { method: 'get' })
  .then((response) => response.json())
  .then((data) => {
    info = data;
  })
  .catch((e) => console.error(e));

export const getEnvironmentGenericProps = (): IWebGenericTrackingProps => {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const timezoneOffset = new Date().getTimezoneOffset();
  const orientation = isLandscape() ? 'landscape' : 'portrait';

  const props = {
    browser_name: browserName,
    device_orientation: orientation,
    page_url: window.location.href,
    ip: info.ip?.ip,
    ip_latitude: info.ip?.latitude,
    ip_longitude: info.ip?.longitude,
    referring_url: document.referrer,
    timezone_offset: timezoneOffset / 60,
  };

  return props;
};

const gtmTrack: IGtmTrack = (e, props, { config }) => {
  try {
    logEvent(analytics, e, JSON.parse(JSON.stringify(props)));
  } catch (error) {
    store.dispatch(logError({ error }));
  }

  trackEvent({
    event: e,
    ...props,
  }).catch(() => {
    // server is returning 500 if org_id is not provided
    // no need to log
    // store.dispatch(logError({ error }));
  });

  try {
    if (config.intercom.eventsWhitelist.includes(e)) {
      // @ts-ignore
      Intercom.trackEvent(e, JSON.parse(JSON.stringify(props)));
      setTimeout(() => {
        Intercom.updateUser({});
      }, 1000);
    }
  } catch (error) {
    store.dispatch(logError({ error }));
  }

  try {
    window.smartlook?.(
      'track',
      'event',
      toStringValues({
        event: e,
        ...props,
      }),
    );
  } catch (error) {
    logError({ error });
  }

  log({
    event: e,
    ...props,
  });
};
export default gtmTrack;

export const gtmSetUserId: IGtmSetUserId = (userId, { isSignup, user } = { isSignup: false }) => {
  try {
    setUserId(analytics, `${userId}`);
  } catch (error) {
    store.dispatch(logError({ error }));
  }

  gtmSetUserProps(userId, { bites_user_id: `${userId}` });

  try {
    if (isSignup) {
      // marketing site UA account, for google optimize
      window.gtag('event', 'auth', {
        send_to: 'UA-86180202-1',
      });
      // google ads conversion
      const userPhoneField: keyof IUser = 'phone';
      window.enhanced_conversion_data = user
        ? {
            email: user.email,
            phone_number: Object.prototype.hasOwnProperty.call(user, userPhoneField)
              ? (user as IUser).phone
              : undefined,
            first_name: user.first_name,
            last_name: user.last_name,
          }
        : {};
      window.gtag('event', 'conversion', {
        send_to: 'AW-455693332/7RalCOSLlvUBEJSopdkB',
      });
    }
  } catch (error) {
    store.dispatch(logError({ error }));
  }
};

let clientId = null;
let gaMarketingClientId = Cookies.get('ga_marketing_client_id');
const updateDimensionGaMarketingClientId = (userId: string | number) => {
  const props = {
    ga_marketing_client_id: getGaMarketingClientId(),
  };
  gtmSetUserProps(userId, props);
};
export const getGaMarketingClientId = () => gaMarketingClientId || clientId;
export const setGaMarketingClientId: ISetGaMarketingClientId = (userId, newGaMarketingClientId) => {
  gaMarketingClientId = newGaMarketingClientId;
  updateDimensionGaMarketingClientId(userId);
};

const waitForGaGlobal = (fn, counter = 0) => {
  if (!window.gaGlobal) {
    setTimeout(() => waitForGaGlobal(fn, counter + 1), 1000);
    return;
  }
  fn();
};
waitForGaGlobal(() => {
  clientId = window.gaGlobal?.vid;
  updateDimensionGaMarketingClientId(null);
});

// marketing site UA account, for google optimize
window.gtag('config', 'UA-86180202-1', { send_page_view: false });
// google ads
window.gtag('config', 'AW-455693332', { allow_enhanced_conversions: true });

export const gtmSetUserProps: ISetUserProps = async (userId, props) => {
  try {
    setUserProperties(analytics, props);
  } catch (error) {
    store.dispatch(logError({ error }));
  }

  try {
    if (userId) {
      window.smartlook?.('identify', `${userId}`, props);
    }
  } catch (error) {
    store.dispatch(logError({ error }));
  }

  const intercomProps = getIntercomUserProps(props);
  if (Object.keys(intercomProps).length > 0) {
    Intercom.updateUser({
      customAttributes: intercomProps,
    });
  }
};
