import React, { memo, useCallback } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { ViewStyle } from 'react-native';
import LinearGradient from '../../../components/shared/LinearGradient';
import { calcHeight, calcWidth, deviceHeight, deviceWidth, isWeb } from '../../../utils/dimensions';
import ShadowedContainer from '../../../components/ShadowedContainer';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface IProps {
  children: React.ReactNode;
}

const FormContainer: React.FC<IProps> = ({ children }) => {
  const theme = useTheme();

  const formHeightShared = useSharedValue(740);

  const animatedStyles = useAnimatedStyle(
    () => ({
      height: withTiming(formHeightShared.value, { duration: 200 }),
    }),
    [formHeightShared],
  );

  const handleLayoutChange = useCallback(
    ({ nativeEvent }) => {
      formHeightShared.value = nativeEvent.layout.height;
    },
    [formHeightShared],
  );

  if (isWeb) {
    return (
      <S.Wrapper style={animatedStyles}>
        <S.Content onLayout={handleLayoutChange}>{children}</S.Content>
      </S.Wrapper>
    );
  }

  return (
    <S.ScrollView bounces={false}>
      <S.Container>
        <LinearGradient colors={theme.colors.questionnaireGradient} style={S.LinearGradientStyles} />
        <ShadowedContainer containerViewStyle={{ paddingTop: calcHeight(50), paddingBottom: calcHeight(20) }}>
          {/*Workaround to avoid crash with shadow animation*/}
          <>
            <S.Wrapper style={animatedStyles}>
              <S.Content onLayout={handleLayoutChange}>{children}</S.Content>
            </S.Wrapper>
          </>
        </ShadowedContainer>
      </S.Container>
    </S.ScrollView>
  );
};

const S = {
  Container: styled.View`
    flex: 1;
    width: 100%;
    min-height: ${deviceHeight}px;
    align-items: center;
    padding-top: ${calcHeight(14)}px;
  `,
  ScrollView: styled.ScrollView`
    flex: 1;
    width: 100%;
  `,
  LinearGradientStyles: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  } as ViewStyle,

  Wrapper: styled(Animated.View)`
    width: ${isWeb ? calcWidth(398) : deviceWidth - calcWidth(50)}px;
    border-radius: 10px;
  `,

  Content: styled.View`
    height: auto;
    align-self: center;
    min-height: 1px;
    width: ${isWeb ? calcWidth(398) : deviceWidth - calcWidth(50)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    z-index: 1;
  `,
};

export default memo(FormContainer);
