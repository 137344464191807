import React, { memo, useCallback } from 'react';
import LoadingState from './LoadingState';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import { ISection } from './AttributesList';
import { useTranslation } from 'react-i18next';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import { getIsWebRtl } from '../../../../locale/i18n';
import {
  disabledSectionHeaderDataSet,
  refreshButtonDataSet,
  sectionHeaderDataSet,
  sectionHeaderErrorDataSet,
} from '../../analytics.constants';

interface IProps {
  section: ISection;
  isSelected?: boolean;
  onSelectSection?: (section: ISection) => void;
  onRefresh?: ({ attributeIds }: { attributeIds: number[] }) => void;
  hasOnlyOneAttribute?: boolean;
  hasIcons?: boolean;
  skeletonRowsNum?: number;
  isSticky?: boolean;
}

const SectionHeader: React.FC<IProps> = ({
  section,
  isSelected,
  onSelectSection,
  onRefresh,
  hasOnlyOneAttribute,
  skeletonRowsNum,
  isSticky,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isWebRtl = getIsWebRtl();
  const isDisplaySkeleton = section.isDisplaySkeleton;
  const isDisplayError = section.isError && !isSelected;
  const isSectionEmpty = section.data.length === 0;

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'SectionHeader.handleRefresh',
        data: { attributeIds: [section.id] },
      }),
    );

    onRefresh({ attributeIds: [section.id] });
  }, [dispatch, onRefresh, section]);

  const handleSelectSection = useCallback(() => {
    if (typeof onSelectSection !== 'function') {
      return;
    }

    dispatch(
      log({
        event: 'SectionHeader.handleSelectSection',
        data: { section },
      }),
    );

    onSelectSection(section);
  }, [dispatch, onSelectSection, section]);

  const renderFooterContainer = useCallback(() => {
    if (isSticky) {
      return null;
    }

    if (isDisplaySkeleton) {
      return <LoadingState rowsNum={skeletonRowsNum} />;
    }

    if (isDisplayError) {
      return (
        <S.ErrorContainer
          //@ts-ignore
          dataSet={sectionHeaderErrorDataSet}
        >
          <S.ErrorText>{t('common.somethingWentWrong')}</S.ErrorText>
          <S.RefreshButton
            // @ts-ignore
            dataSet={refreshButtonDataSet}
            onPress={handleRefresh}
          >
            <S.RefreshText>{t('analytics.error.refresh')}</S.RefreshText>
          </S.RefreshButton>
        </S.ErrorContainer>
      );
    }
  }, [handleRefresh, isDisplayError, isDisplaySkeleton, isSticky, skeletonRowsNum, t]);

  if (isSectionEmpty && !isDisplaySkeleton && !isDisplayError) {
    return null;
  }

  return (
    <>
      <S.SectionTitleContainer
        isRtl={isWebRtl}
        isShowBottomBorder={!isDisplayError && !isDisplaySkeleton && !!section.title}
        withoutTitle={!section.title}
        isSticky={isSticky}
      >
        {!!section.title && (
          <S.SectionTitleButton
            // @ts-ignore
            dataSet={hasOnlyOneAttribute ? disabledSectionHeaderDataSet : sectionHeaderDataSet}
            disabled={hasOnlyOneAttribute}
            onPress={handleSelectSection}
          >
            {isSelected && !hasOnlyOneAttribute ? (
              <S.SectionTitle>
                {'x '}
                {section.title}
              </S.SectionTitle>
            ) : (
              <S.SectionTitle>
                {section.title}
                {!hasOnlyOneAttribute && section.title ? ' >' : ''}
              </S.SectionTitle>
            )}
          </S.SectionTitleButton>
        )}
      </S.SectionTitleContainer>

      {renderFooterContainer()}
    </>
  );
};

const S = {
  SectionTitle: styled.Text`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15}px;
    font-weight: 700;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    margin-bottom: ${calcHeight(16)}px;
  `,
  SectionTitleContainer: styled.View<{
    isRtl?: boolean;
    isShowBottomBorder: boolean;
    withoutTitle: boolean;
    isSticky?: boolean;
  }>`
    background-color: ${({ theme }) => theme.colors.white};
    padding-top: ${({ withoutTitle }) => (withoutTitle ? 0 : calcHeight(16))}px;
    margin: 0 ${calcWidth(19)}px;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    border: 0 solid ${({ theme }) => theme.colors.overviewDivider};
    border-bottom-width: ${({ isShowBottomBorder }) => (isShowBottomBorder ? 1 : 0)}px;

    ${({ isSticky }) =>
      isSticky &&
      css`
        position: absolute;
        top: ${calcHeight(41)}px;
        left: 0;
        right: 0;
        z-index: 2;
      `}
  `,
  SectionTitleButton: styled.TouchableOpacity`
    flex-direction: row;
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex: 1;
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: ${({ isRtl }) => (isRtl ? 'flex-start' : 'flex-end')};
    flex-direction: row;
    align-items: center;
  `,
  IconContainer: styled.View<{ isRtl: boolean }>`
    align-items: center;
    height: ${calcHeight(24)}px;
    width: ${calcWidth(50)}px;
    justify-content: center;
  `,
  ErrorText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.text};
  `,
  ErrorContainer: styled.View`
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: ${calcHeight(20)}px 0;
  `,
  RefreshButton: styled.TouchableOpacity`
    margin-top: 10px;
  `,
  RefreshText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default memo(SectionHeader);
