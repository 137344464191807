import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { EPanel, Tabs as TabsEnum } from '../../../../store/homeScreen/homeScreen.types';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { setActivePanel, setHomeActiveTab, togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { activePanelSelector, homeTabsActiveTabSelector } from '../../../../store/homeScreen/homeScreen.selectors';
import { activeOrganizationSelector } from '../../../../store/auth/auth.selectors';
import ExploreIcon from '../../../../assets/icons/feed/explore.svg';
import ExploreSelectedIcon from '../../../../assets/icons/feed/explore-selected.svg';
import PlusIcon from '../../../../assets/icons/plus-button.svg';
import FeedIcon from '../../../../assets/icons/feed/feed.svg';
import FeedSelectedIcon from '../../../../assets/icons/feed/feed-selected.svg';
import CurveTopIcon from '../../../../assets/icons/feed/curve-top.svg';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import { IS_IOS } from '../../../../utils/constants/env';
import Tooltip, { ITooltipMethods } from '../../../../components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { hapticOptions, hapticTypes } from '../../../../utils/haptic';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';

interface IProps {}

const BottomPanel: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();

  const organization = useSelector(activeOrganizationSelector);
  const homeActiveTab = useSelector(homeTabsActiveTabSelector);
  const activePanel = useSelector(activePanelSelector);

  const plusTooltipRef = useRef<ITooltipMethods>();

  const animatedPlusButtonRotate = useSharedValue(0);

  const setActiveTab = useCallback(
    (tab: TabsEnum) => {
      dispatch(setActivePanel(null));
      dispatch(setHomeActiveTab(tab));
      dispatch(
        trackEvent({
          event: 'home_screen_tab',
          props: { tab: homeActiveTab === TabsEnum.FEED ? 'EXPLORE' : 'FEED' },
        }),
      );
    },
    [dispatch, homeActiveTab],
  );

  const handleOpenExplore = useCallback(() => {
    setActiveTab(TabsEnum.EXPLORE);
  }, [setActiveTab]);

  const handleOpenFeed = useCallback(() => {
    setActiveTab(TabsEnum.FEED);
  }, [setActiveTab]);

  const handleToggleAddObjectPanel = useCallback(() => {
    if (!organization?.is_creator) {
      plusTooltipRef.current?.show();
      return;
    }
    dispatch(
      trackEvent({
        event: 'create_click',
      }),
    );
    dispatch(togglePanel(EPanel.NEW_OBJECT));
  }, [dispatch, organization?.is_creator]);

  const handleOpenOrganizationPanel = useCallback(() => {
    ReactNativeHapticFeedback.trigger(hapticTypes.impactMedium, hapticOptions);
    dispatch(togglePanel(EPanel.ORGANIZATIONS));
  }, [dispatch]);

  const bottomPanelShadowOffset: ShadowProps['offset'] = useMemo(() => [0, 0], []);

  useEffect(() => {
    animatedPlusButtonRotate.value = activePanel === EPanel.NEW_OBJECT ? 45 : 0;
  }, [activePanel, animatedPlusButtonRotate]);

  const animatedPlusButtonStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate: withTiming(`${animatedPlusButtonRotate.value}deg`, {
            duration: 100,
            easing: Easing.linear,
          }),
        },
      ],
    }),
    [],
  );

  if (isWeb) {
    return null;
  }
  return (
    <>
      <ShadowedContainer offset={bottomPanelShadowOffset} distance={8}>
        <S.Container>
          <S.CurveTopIcon>
            <CurveTopIcon />
          </S.CurveTopIcon>
          <S.ControlsContainer insets={insets}>
            <S.LeftContainer>
              <S.ExploreButton onPress={handleOpenExplore}>
                {homeActiveTab === TabsEnum.EXPLORE ? (
                  <ExploreSelectedIcon height={calcWidth(26)} width={calcWidth(21)} />
                ) : (
                  <ExploreIcon height={calcWidth(26)} width={calcWidth(21)} />
                )}
                <S.LabelContainer>
                  <S.Label>{t('homeScreen.explore')}</S.Label>
                </S.LabelContainer>
              </S.ExploreButton>
            </S.LeftContainer>
            <S.CenterContainer />
            <S.RightContainer>
              <S.OrganizationButton onPress={handleOpenFeed} onLongPress={handleOpenOrganizationPanel}>
                {organization?.brand_share ? (
                  <S.CompanyLogoContainer isSelected={homeActiveTab === TabsEnum.FEED}>
                    <S.CompanyLogo
                      source={{
                        uri: organization.brand_share,
                      }}
                    />
                  </S.CompanyLogoContainer>
                ) : homeActiveTab === TabsEnum.FEED ? (
                  <FeedSelectedIcon height={calcWidth(27)} width={calcWidth(21)} />
                ) : (
                  <FeedIcon height={calcWidth(27)} width={calcWidth(21)} />
                )}

                <S.LabelContainer>
                  <S.Label>{t('homeScreen.myFeed')}</S.Label>
                </S.LabelContainer>
              </S.OrganizationButton>
            </S.RightContainer>
          </S.ControlsContainer>

          <S.CenterAbsContainer>
            <Tooltip methodsRef={plusTooltipRef} text={t('homeScreen.notCreator')} withGradient>
              <Animated.View style={animatedPlusButtonStyle}>
                <S.PlusButton isDisabled={!organization.is_creator} onPress={handleToggleAddObjectPanel}>
                  <PlusIcon height={calcWidth(28)} width={calcWidth(28)} fill='white' />
                </S.PlusButton>
              </Animated.View>
            </Tooltip>
          </S.CenterAbsContainer>
        </S.Container>
      </ShadowedContainer>
    </>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
  `,
  CurveTopIcon: styled.View`
    position: absolute;
    top: ${calcWidth(-18)}px;
    align-items: center;
    width: 100%;
  `,
  ControlsContainer: styled.View<{ insets: { bottom: number } }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${calcHeight(4)}px ${calcWidth(16)}px ${({ insets }) => insets.bottom + calcHeight(4)}px;
    width: 100%;
    height: ${calcHeight(IS_IOS ? 90 : 65)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  LeftContainer: styled.View``,
  ExploreButton: styled.TouchableOpacity`
    align-items: center;
  `,
  CenterContainer: styled.View`
    top: ${calcWidth(-8)}px;
  `,
  CenterAbsContainer: styled.View`
    position: absolute;
    top: ${calcWidth(-8)}px;
    left: 50%;
    margin-left: -${calcWidth(30)}px;
  `,
  PlusButton: styled.TouchableOpacity<{ isDisabled: boolean }>`
    justify-content: center;
    align-items: center;
    width: ${calcWidth(60)}px;
    height: ${calcWidth(60)}px;
    border-radius: ${calcWidth(60) / 2}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  `,
  RightContainer: styled.View``,
  OrganizationButton: styled.TouchableOpacity`
    align-items: center;
  `,
  LabelContainer: styled.View`
    margin-top: ${calcWidth(4)}px;
  `,
  Label: styled.Text`
    font-size: ${calcFontSize(10)}px;
    font-family: ${({ theme }) => theme.fontFamilies.OpenSansHebrew};
    text-transform: capitalize;
  `,
  CompanyLogoContainer: styled.View<{ isSelected: boolean }>`
    width: ${calcWidth(30)}px;
    height: ${calcHeight(30)}px;
    border: 1px ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryBlue : 'transparent')} solid;
    border-radius: ${calcHeight(15)}px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  `,
  CompanyLogo: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(32)}px;
    height: ${calcHeight(32)}px;
  `,
};

export default BottomPanel;
