import React, { FC, useCallback } from 'react';
import { EObjectType } from '../../../../types/common';
import { IFeedItem } from '../../../../store/feed/feed.types';
import BiteItem from '../../../../components/feed/BiteItem';
import PlaylistItem from './PlaylistItem';
import QuizItem from './QuizItem';
import Routes from '../../../../navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { EIntroMediaProcessingStatus } from '../../../../store/createBite/createBite.types';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { useDispatch } from 'react-redux';
import openBiteStats from '../../../../utils/bite/openBiteStats';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  item: IFeedItem;
  index?: number;
  onAddCover: (item: IFeedItem) => void;
}

const FeedItem: FC<IProps> = ({ item, onAddCover, index }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const dispatch = useDispatch();

  const handleAddCover = useCallback(() => {
    onAddCover(item);
  }, [item, onAddCover]);

  const handleBitePress = useCallback(
    ({ bite, uploadingMediaStatus, isDraft }) => {
      if (
        bite.no_sections ||
        isDraft ||
        uploadingMediaStatus === EIntroMediaProcessingStatus.PROCESSING ||
        uploadingMediaStatus === EIntroMediaProcessingStatus.PARTIAL_SUCCESS
      ) {
        dispatch(
          trackEvent({
            event: 'edit',
            props: {
              bite_id: bite?.id,
              is_draft: isDraft,
            },
          }),
        );
        navigation.navigate(Routes.EditBiteStack.StackName, {
          screen: Routes.EditBiteStack.EditMain,
          params: { biteId: bite?.id },
        });
        return;
      }

      openBiteStats({ biteId: bite?.id });
    },
    [dispatch, navigation],
  );

  if (item.content_type === EObjectType.playlist) {
    return <PlaylistItem onAddCover={handleAddCover} playlistId={item.id} index={index} />;
  }

  if (item.content_type === EObjectType.quiz) {
    return <QuizItem onAddCover={handleAddCover} quizId={item.id} index={index} />;
  }

  return (
    <BiteItem
      onAddCover={handleAddCover}
      biteId={item.id}
      withStatistics
      withStatusOverlays
      onPress={handleBitePress}
      index={index}
    />
  );
};

export default React.memo(FeedItem);
