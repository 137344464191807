import React, { memo, useCallback, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { IAttribute } from '../../../store/attributes/attributes.types';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import { useDispatch, useSelector } from 'react-redux';
import {
  initialAttributesMapByIdSelector,
  organizationAttributesSelector,
  selectedFilterAttributeValueIdsSelector,
  selectedValuesByAttributeIdSelector,
} from '../../../store/attributes/attributes.selector';
import ArrowDownIcon from '../../../assets/icons/feed/arrow-down.svg';
import { getFilterList, resetSearchValues } from '../../../store/attributes/attributes.slice';
import FilterBottomSheet from './FilterPanel/FilterBottomSheet';
import { log } from '../../../store/appActivity/appActivity.slice';
import Skeleton from '../../../components/shared/Skeleton';
import { getIsRtl, getIsWebRtl } from '../../../locale/i18n';
import EraserIcon from '../../../assets/icons/analytics/eraser.svg';
import { resetAnalytics } from '../../../store/analytics/analytics.slice';
import { IS_IOS } from '../../../utils/constants/env';
import {
  attributesFilterDataSet,
  attributesFilterItemDataSet,
  clearFiltersButtonDataSet,
} from '../analytics.constants';

const HIT_SLOP = {
  top: 10,
  left: 10,
  bottom: 10,
  right: 10,
};

interface IProps {}

const AttributesFilter: React.FC<IProps> = () => {
  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();
  const dispatch = useDispatch();
  const attributes: IAttribute[] = useSelector(organizationAttributesSelector);
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);

  const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>(null);

  const keyExtractor = useCallback((item) => item.id.toString(), []);

  const handleFilterPress = useCallback(
    (item) => {
      dispatch(
        log({
          event: 'AttributesFilter.handleFilterPress',
          data: { item },
        }),
      );

      dispatch(getFilterList({ attributeId: item.id }));
      setIsFilterPanelVisible(true);
      setSelectedAttributeId(item.id);
    },
    [dispatch],
  );

  const handleFilterPanelClose = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesFilter.handleFilterPanelClose',
      }),
    );
    setIsFilterPanelVisible(false);
    dispatch(resetSearchValues());
  }, [dispatch]);

  const renderItem = useCallback(
    ({ item }) => {
      return <FilterListItem isRtl={isRtl} isWebRtl={isWebRtl} item={item} onPress={handleFilterPress} />;
    },
    [handleFilterPress, isRtl, isWebRtl],
  );

  const handleFilterClear = useCallback(() => {
    dispatch(
      log({
        event: 'AttributesFilter.handlePressClearFilters',
      }),
    );

    dispatch(
      resetAnalytics({
        excludeFields: ['biteId', 'currentList', 'hasDistributions', 'sharedWithFilter', 'isFilterTooltipShown'],
        withFiltersReset: true,
      }),
    );
  }, [dispatch]);

  if (attributes.length === 0) {
    return null;
  }

  return (
    <>
      <S.FilterListContainer>
        <S.FilterList
          keyExtractor={keyExtractor}
          isRtl={isWebRtl}
          showsHorizontalScrollIndicator={false}
          data={attributes}
          renderItem={renderItem}
          horizontal
        />
        {!!selectedAttributes?.length && !!attributes?.length && (
          <S.EraserContainer>
            <S.EraserButtonBorder />
            <S.EraserButtonContainer>
              <S.EraserButton dataSet={clearFiltersButtonDataSet} hitSlop={HIT_SLOP} onPress={handleFilterClear}>
                <EraserIcon />
              </S.EraserButton>
            </S.EraserButtonContainer>
          </S.EraserContainer>
        )}
      </S.FilterListContainer>
      <FilterBottomSheet
        isVisible={isFilterPanelVisible}
        onClose={handleFilterPanelClose}
        selectedAttributeId={selectedAttributeId}
      />
    </>
  );
};

interface IFilterListItemProps {
  item: IAttribute;
  onPress: (item: IAttribute) => void;
  isRtl: boolean;
  isWebRtl: boolean;
}

const FilterListItem: React.FC<IFilterListItemProps> = ({ item, onPress, isRtl, isWebRtl }) => {
  const theme = useTheme();
  const initialAttributesMap = useSelector(initialAttributesMapByIdSelector);
  const isLoading = useMemo(
    () => item.values === null && initialAttributesMap[item.id].data.values.length,
    [initialAttributesMap, item.id, item.values],
  );

  const selectedValues = useSelector(selectedValuesByAttributeIdSelector(item.id));
  const isSelected = !!selectedValues.length;
  const isEmpty = !item.values?.length && !isSelected;

  const handlePress = useCallback(() => {
    if (isEmpty) {
      return;
    }

    onPress(item);
  }, [isEmpty, item, onPress]);

  return (
    <S.ListItemContainer
      // @ts-ignore
      dataSet={attributesFilterDataSet}
      isWebRtl={isWebRtl}
      isRtl={isRtl}
      activeOpacity={isEmpty ? 1 : undefined}
      isEmpty={isEmpty}
      onPress={handlePress}
      isSelected={isSelected}
    >
      {isLoading ? (
        <Skeleton height={calcHeight(20)} width={calcWidth(70)} />
      ) : (
        <>
          <S.ListItemText
            // @ts-ignore
            dataSet={attributesFilterItemDataSet}
            isSelected={isSelected}
            isEmpty={isEmpty}
          >
            {selectedValues.length === 1 ? selectedValues[0].name : item.title}
          </S.ListItemText>
          {selectedValues.length > 1 && (
            <S.SelectedNumberContainer>
              <S.SelectedNumberText>{selectedValues.length}</S.SelectedNumberText>
            </S.SelectedNumberContainer>
          )}
          {!isEmpty && (
            <S.ArrowDownIconContainer>
              <ArrowDownIcon color={theme.colors.primaryBlue} />
            </S.ArrowDownIconContainer>
          )}
        </>
      )}
    </S.ListItemContainer>
  );
};

const S = {
  FilterListContainer: styled.View`
    width: ${isWeb ? calcWidth(550) : deviceWidth}px;
    align-self: center;
    display: flex;
    flex-direction: row;

    border: 0px solid ${({ theme }) => theme.colors.overviewDivider};
    border-bottom-width: 1px;
  `,
  FilterList: styled.FlatList<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    max-height: ${calcHeight(24)}px;

    padding: 0 ${calcWidth(19)}px;
    margin-bottom: ${calcHeight(10)}px;
  `,
  ListItemContainer: styled.TouchableOpacity<{
    isWebRtl: boolean;
    isRtl: boolean;
    isEmpty?: boolean;
    isSelected: boolean;
  }>`
    align-items: center;
    border-radius: 4px;
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.lightBlue : 'transparent')};
    padding: ${calcHeight(6)}px ${calcWidth(3)}px ${calcHeight(isWeb ? 6 : 3)}px ${calcWidth(6)}px;
    flex-direction: ${({ isWebRtl }) => (isWebRtl ? 'row-reverse' : 'row')};
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-left: ${calcWidth(12)}px;
          `
        : css`
            margin-right: ${calcWidth(12)}px;
          `}
    ${({ isEmpty }) =>
      isWeb
        ? css`
            cursor: ${isEmpty ? 'default' : 'pointer'};
          `
        : ''};
  `,

  ArrowDownIconContainer: styled.View`
    width: ${calcWidth(16)}px;
    height: ${calcHeight(16)}px;
    align-items: center;
    justify-content: center;
    margin-bottom: ${isWeb ? 0 : calcHeight(4)}px;
  `,
  ListItemText: styled.Text<{ isSelected?: boolean; isEmpty?: boolean }>`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    color: ${({ theme, isSelected, isEmpty }) =>
      isSelected ? theme.colors.text : isEmpty ? theme.colors.gray17 : theme.colors.gray19};
    font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
    margin-bottom: ${calcHeight(IS_IOS ? 2 : 0)}px;
  `,
  SelectedNumberContainer: styled.View`
    width: ${calcWidth(17)}px;
    height: ${calcHeight(17)}px;
    border-radius: ${calcWidth(9)}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    align-items: center;
    justify-content: center;
    margin-left: ${calcWidth(4)}px;
    margin-bottom: ${calcHeight(isWeb ? 0 : 4)}px;
  `,
  SelectedNumberText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s12}px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    text-align: center;
  `,
  EraserButtonContainer: styled.View`
    background: ${({ theme }) => theme.colors.white};
    align-items: center;
    justify-content: center;
  `,
  EraserButtonBorder: styled.View`
    width: 3px;
    background: ${({ theme }) => theme.colors.overviewDividerTransparent};
    border: 0 solid ${({ theme }) => theme.colors.overviewDivider};
    border-right-width: 1px;
  `,
  EraserContainer: styled.View`
    display: flex;
    flex-direction: row;
    right: 0;
    height: 28px;
  `,
  EraserButton: styled.TouchableOpacity`
    margin-left: ${calcWidth(10)}px;
    width: ${calcWidth(30)}px;
  `,
};

export default memo(AttributesFilter);
