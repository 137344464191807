import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import EditIcon from '../../../assets/icons/creationFlow/icons-edit.svg';
import MagicStarsBlueIcon from '../../../assets/icons/magic-stars-blue.svg';
import { createBiteSelector } from '../../../store/createBite/createBite.selectors';
import { setBiteName } from '../../../store/createBite/createBites.actions';
import { updateBiteData } from '../../../store/api/bites-api/calls/bite.calls';
import { biteSelector } from '../../../store/bite/bite.selectors';
import { indexBites, setBites } from '../../../store/bite/bite.actions';
import { calcFontSize, calcHeight, calcWidth } from '../../../utils/dimensions';
import EditNameInput from '../../../components/EditNameInput';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import Constants from '../../Shared/constants';
import { nameLabel } from './biteCreated.constants';

const NameSuggestionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { biteName, isNameSuggestion } = useSelector(createBiteSelector);
  const { selectedBite } = useSelector(biteSelector);
  const [isNamePanelOpen, setIsNamePanelOpen] = useState(false);

  const onChangeText = useCallback(
    (value) => {
      if (value.length <= 40 && value !== biteName) {
        dispatch(setBiteName(value));
      }
    },
    [biteName, dispatch],
  );
  const handleSaveBiteName = useCallback(
    (value: string) => {
      dispatch(
        trackEvent({
          event: 'rename_bite',
          props: { bite_id: selectedBite.id, from: Constants.CreationFlows.BiteCreation },
        }),
      );
      dispatch(setBites([{ ...selectedBite, subject: value }]));
      updateBiteData(selectedBite.id, {
        subject: value,
      });

      dispatch(
        indexBites({
          biteIds: [selectedBite.id],
        }),
      );
      setIsNamePanelOpen(false);
    },
    [dispatch, selectedBite],
  );

  const handleEditPress = useCallback(() => {
    setIsNamePanelOpen(true);
  }, []);

  return (
    <S.Container>
      {!isNamePanelOpen ? (
        <>
          {isNameSuggestion && (
            <S.WeHaveSuggestionContainer>
              <MagicStarsBlueIcon />
              <S.BiteAIText>{t('biteCreated.nameSuggestion')}</S.BiteAIText>
            </S.WeHaveSuggestionContainer>
          )}
          <S.NameWrapper
            extraHeight={!isNameSuggestion}
            onPress={handleEditPress}
            // @ts-ignore
            dataSet={nameLabel}
          >
            <S.NameText>{biteName}</S.NameText>
            <S.EditIconButton>
              <EditIcon />
            </S.EditIconButton>
          </S.NameWrapper>
        </>
      ) : (
        <EditNameInput onAccept={handleSaveBiteName} onChangeText={onChangeText} value={biteName} />
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
  `,
  WeHaveSuggestionContainer: styled.View`
    flex-direction: row;
  `,
  BiteAIText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 13px;
    margin-top: ${calcHeight(6)}px;
    margin-left: ${calcHeight(6)}px;
  `,
  NameText: styled.Text`
    font-size: 18px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: bold;
    align-self: center;
    text-align: center
    margin-right: ${calcWidth(10)}px;
    max-width: ${calcWidth(248)}px;
  `,
  EditIconButton: styled.View``,
  NameWrapper: styled.TouchableOpacity<{ extraHeight: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${calcHeight(21)}px;
    margin-top: ${({ extraHeight }) => (extraHeight ? calcHeight(33) : calcHeight(10))}px;
  `,
  NameInput: styled.TextInput`
    align-self: center;
    margin: ${calcHeight(23)}px 0 ${calcHeight(14)}px;
    padding: ${calcHeight(12)}px ${calcWidth(22)}px;
    width: ${calcWidth(325)}px;
    height: ${calcHeight(49)}px;
    border-radius: ${calcWidth(30)}px;
    font-size: ${calcFontSize(22)}px;
    color: ${({ theme }) => theme.colors.lightGray6};
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    border: 2px solid #1253fa;
  `,
};

export default NameSuggestionComponent;
