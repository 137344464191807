import React, { memo } from 'react';
import styled from 'styled-components/native';
import { calcHeight } from '../../../utils/dimensions';

interface IProps {
  title: string;
  description?: string;
  destination?: string;
}

const Header: React.FC<IProps> = ({ title, description, destination }) => {
  return (
    <S.Header>
      <S.HeaderTitle>{title}</S.HeaderTitle>
      {description && <S.HeaderDescription>{description}</S.HeaderDescription>}
      {destination && <S.HeaderDestination>{destination}</S.HeaderDestination>}
    </S.Header>
  );
};

const S = {
  Header: styled.View`
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
  HeaderTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s20};
    line-height: ${({ theme }) => theme.fontSizes.s28};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    margin-top: ${calcHeight(10)}px;
  `,
  HeaderDescription: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.gray19};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    margin-top: ${calcHeight(4)}px;
    text-align: center;
  `,
  HeaderDestination: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.ArimoBold};
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    line-height: ${({ theme }) => theme.fontSizes.s16};
    margin-top: ${calcHeight(4)}px;
    text-align: center;
  `,
};
export default memo(Header);
