import React, { memo } from 'react';
import styled from 'styled-components/native';
import { Platform, StatusBar, View } from 'react-native';

import { calcHeight, calcWidth, deviceWidth } from '../../utils/dimensions';
import useToken from '../../hooks/useToken';
import { DASHBOARD_URL } from '../../utils/constants/urls';
import { isWeb } from '../../utils/dimensions';

import WebView from '../../components/WebView';
import PreviewControlPanel from '../../components/shared/PreviewControlPanel/PreviewControlPanel';
import { IStackNavigation } from '../../navigation/types';
import { useSelector } from 'react-redux';
import Button from '../../components/shared/buttons/Button/Button';
import themes from '../../themes';
import { useTranslation } from 'react-i18next';
import { isHasUserContentSelector } from '../../store/feed/feed.selectors';
import startBiteCreationFlow from '../../utils/bite/startBiteCreationFlow';

interface IProps extends IStackNavigation {}

const Dashboard: React.FC<IProps> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const hasUserContent = useSelector(isHasUserContentSelector);
  const { path } = route.params;
  const { token } = useToken();

  const url = `${DASHBOARD_URL}${path}?token=${token}&rnd=${Date.now()}&isPreview=1`;

  return (
    <S.Container>
      <S.WebViewWrap2>
        <S.WebViewWrap>
          <WebView url={url} />
          <PreviewControlPanel onClose={navigation.goBack}>
            {!hasUserContent && (
              <S.BiteCreationButtonWrapper>
                <S.NewBiteCreation
                  onPress={startBiteCreationFlow}
                  text={t('createBiteInfo.createFirstBite')}
                  textColor={themes.colors.white}
                  fill={themes.colors.primaryBlue}
                />
              </S.BiteCreationButtonWrapper>
            )}
          </PreviewControlPanel>
        </S.WebViewWrap>
      </S.WebViewWrap2>
    </S.Container>
  );
};

const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap2: styled(View)`
    flex-grow: 1;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  WebViewWrap: styled(View)`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
  `,
  NewBiteCreation: styled(Button)`
    height: ${calcHeight(50)}px;
    border-radius: ${calcHeight(50 / 2)}px;
    background: ${({ theme }) => theme.colors.primaryBlue};
    flex-direction: row;
    padding: ${calcHeight(16)}px ${calcWidth(20)}px;
  `,
  BiteCreationButtonWrapper: styled.View`
    margin-left: ${calcWidth(40)}px;
  `,
};

export default memo(Dashboard);
