import HomeIcon from '../../../assets/icons/creationFlow/home-icon.svg';
import ShadowedContainer from '../../ShadowedContainer';
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import styled, { useTheme } from 'styled-components/native';
import { calcWidth } from '../../../utils/dimensions';
import Routes from '../../../navigation/routes';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';
import { homeButtonDataSet } from './HomeButton.constants';

interface IProps {
  onPress?: () => void;
}

const HomeButton: React.FC<IProps> = ({ onPress }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { index, routes } = navigation.getState();
  const screenName = routes[index].name;

  const handlePress = () => {
    if (typeof onPress === 'function') {
      onPress();
      return;
    }

    dispatch(
      trackEvent({
        event: 'navigate_home',
        props: { page_title: screenName },
      }),
    );

    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  };

  return (
    <ShadowedContainer>
      <S.HomeButton
        //@ts-ignore
        dataSet={homeButtonDataSet}
        onPress={handlePress}
      >
        <HomeIcon fill={theme.colors.primaryBlue} />
      </S.HomeButton>
    </ShadowedContainer>
  );
};

const S = {
  HomeButton: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
  `,
};

export default HomeButton;
