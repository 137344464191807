import React, { FC, useCallback, useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { CustomText } from '../../../../components/shared';
import IntroIcon from '../../../../assets/icons/feed/intro-section-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import ViewsIcon from '../../../../assets/icons/feed/views.svg';
import AnswersIcon from '../../../../assets/icons/feed/answers.svg';
import DiscussionIcon from '../../../../assets/icons/feed/discussion.svg';
import { playlistInProgressSelector, playlistItemSelector } from '../../../../store/playlist/playlist.selectors';
import { IBiteShare } from '../../../../types/biteShare';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../../../navigation/routes';
import { useTranslation } from 'react-i18next';
import WithoutCoverOverlay from '../../../../components/feed/biteItemOverlays/WithoutCoverOverlay';
import UploadingOverlay from '../../../../components/shared/UploadingOverlay';
import { EInProgressStatus, EInProgressTask } from '../../../../types/common';
import { setPlaylistToEdit } from '../../../../store/createPlaylist/createPlaylist.actions';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import OwnerInitials from '../../../../components/feed/biteItemOverlays/OwnerInitials';
import { isContentAdminSelector, userSelector } from '../../../../store/auth/auth.selectors';
import openPreviewQuiz from '../../../../utils/quiz/openPreviewQuiz';
import useIsPlaylistOwner from '../../../../hooks/useIsPlaylistOwner';
import { selectQuizItemDataSet } from './component.constants';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {
  quizId: number;
  onAddCover: () => void;
  index?: number;
}

const QuizItem: FC<IProps> = ({ quizId, onAddCover, index }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isContentAdmin = useSelector(isContentAdminSelector);
  const user = useSelector(userSelector);
  const quiz = useSelector(playlistItemSelector(quizId));
  const isPlaylistOwner = useIsPlaylistOwner(quiz);
  const isUserCanEdit = isContentAdmin || isPlaylistOwner;

  const playlistCoverUploadingTask = useSelector(playlistInProgressSelector(quizId, EInProgressTask.COVER));
  const isLoading = playlistCoverUploadingTask?.status === EInProgressStatus.IN_PROGRESS;

  const workspaceLogoUrl = quiz?.organization_logo;

  const introSection: IBiteShare = useMemo(() => {
    return quiz?.bite_shares?.find(({ playlist_section }) => playlist_section === 'intro');
  }, [quiz?.bite_shares]);

  const hasQuestions = useMemo(() => {
    return quiz?.bite_shares?.some(({ playlist_section }) => playlist_section === 'body');
  }, [quiz?.bite_shares]);

  const iconsStyle = useMemo(
    () => ({
      marginRight: calcWidth(8),
      width: calcWidth(24),
      height: calcHeight(24),
    }),
    [],
  );
  const image = useMemo(
    () => ({ uri: quiz?.linked_cover_url || introSection?.cover_url || null }),
    [introSection?.cover_url, quiz?.linked_cover_url],
  );

  const dataSet = useMemo(() => {
    return {
      'intercom-target': `feed-item-${index}`,
      ...selectQuizItemDataSet,
    };
  }, [index]);

  const withWorkspaceLogo = typeof onAddCover === 'function' && !image.uri;
  const iconColor = withWorkspaceLogo ? theme.colors.black : theme.colors.white;

  const handlePress = useCallback(() => {
    if (!hasQuestions && isUserCanEdit) {
      dispatch(setPlaylistToEdit({ playlist: quiz }));
      dispatch(
        trackEvent({
          event: 'edit',
          props: { quiz_id: quizId },
        }),
      );
      navigation.navigate(Routes.EditQuizStack.StackName, {
        screen: Routes.EditQuizStack.EditQuiz,
        params: { playlistId: quizId },
      });
      return;
    }

    openPreviewQuiz({ quizId });
  }, [dispatch, hasQuestions, isUserCanEdit, navigation, quiz, quizId]);

  if (!quiz) {
    return null;
  }

  return (
    <S.Container
      onPress={handlePress}
      //@ts-ignore
      dataSet={dataSet}
    >
      <ShadowedContainer>
        <S.BiteImage source={image}>
          {withWorkspaceLogo && (
            <WithoutCoverOverlay
              //onAddCover={(isPlaylistOwner || isUserCanEdit) ? onAddCover : null} // to put back when we add support for quiz cover edition
              workspaceLogoUrl={workspaceLogoUrl}
            />
          )}
          <S.SectionsCounter>{introSection && <IntroIcon color={iconColor} style={iconsStyle} />}</S.SectionsCounter>
          <S.TypeLabelContainer>
            <S.TypeLabel>{t('common.quiz')}</S.TypeLabel>
          </S.TypeLabelContainer>
          <UploadingOverlay isVisible={isLoading} height={calcHeight(200)} width={calcWidth(200)} />
        </S.BiteImage>

        {quiz.creator !== user?.id && <S.OwnerInitials name={quiz.creator_name} />}
      </ShadowedContainer>
      <S.BiteTitle>{quiz.subject}</S.BiteTitle>
      <S.StatsContainer>
        <S.StatsItem>
          <S.StatsCounter>{quiz.watched || 0}</S.StatsCounter>
          <ViewsIcon />
        </S.StatsItem>
        <S.StatsItem>
          <S.StatsCounter>{quiz.success_rate?.toFixed(0) || 0}%</S.StatsCounter>
          <AnswersIcon />
        </S.StatsItem>
        <S.StatsItem>
          <S.StatsCounter>{quiz.comments || 0}</S.StatsCounter>
          <DiscussionIcon />
        </S.StatsItem>
      </S.StatsContainer>
    </S.Container>
  );
};

const S = {
  EmptyView: styled.View``,
  Container: styled.TouchableOpacity`
    position: relative;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    margin-left: ${isWeb ? calcWidth(15) : calcWidth(9)}px;
    margin-bottom: ${calcHeight(10)}px;
  `,
  BiteImage: styled.ImageBackground`
    position: relative;
    width: ${calcWidth(isWeb ? 220 : 165)}px;
    height: ${calcHeight(isWeb ? 276 : 250)}px;
    border-radius: 18px;
    overflow: hidden;
    ${isWeb ? 'box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;' : ''}
  `,
  TypeLabelContainer: styled.View`
    position: absolute;
    padding: ${calcHeight(4)}px ${calcWidth(8)}px;
    top: ${calcHeight(8)}px;
    left: ${calcWidth(8)}px;
    border-radius: 15px;
    background-color: ${({ theme }) => theme.colors.purpleChoose};
  `,
  TypeLabel: styled.Text`
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.OpenSansHebrew};
  `,
  BiteTitle: styled(CustomText)(
    ({ theme }) => css`
      margin-top: ${calcHeight(4)}px;
      padding: 0 ${calcWidth(12)}px;
      font-family: ${theme.fontFamilies.Arimo};
      font-size: ${theme.fontSizes.s12}px;
      font-weight: bold;
    `,
  ),
  SectionsCounter: styled.View`
    position: absolute;
    flex-direction: row;
    align-items: center;
    bottom: ${calcHeight(8)}px;
    left: ${calcWidth(12)}px;
  `,
  StatsContainer: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${calcWidth(12)}px;
  `,
  StatsItem: styled.View`
    flex-direction: row;
    align-items: center;
  `,
  StatsCounter: styled.Text`
    margin-right: ${calcWidth(4)}px;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s12};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
  OwnerInitials: styled(OwnerInitials)`
    position: absolute;
    bottom: ${calcHeight(10)}px;
    right: ${calcWidth(10)}px;
  `,
};

export default React.memo(QuizItem);
