import React from 'react';
import LottieView from 'lottie-react';
import { calcWidth } from '../../../utils/dimensions';
import nativeStyled, { css } from 'styled-components/native';
import styled from 'styled-components';
import { StyleProp, ViewStyle } from 'react-native';

interface IProps {
  isVisible: boolean;
  style?: StyleProp<ViewStyle>;
  height?: number;
  width?: number;
}

const UploadingOverlay: React.FC<IProps> = ({ isVisible, style, height, width }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <S.Overlay style={style}>
      <S.View height={height} width={width}>
        <S.LottieView animationData={require('../../../assets/lottie/uploading-animation-lottie.json')} autoPlay loop />
      </S.View>
    </S.Overlay>
  );
};

const S = {
  LottieView: styled(LottieView)`
    width: 100%;
  `,
  Overlay: nativeStyled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    justify-content: center;
    align-items: center;
    flex: 1;
  `,
  View: nativeStyled.View<{ width: number; height: number }>(
    ({ height, width }) => css`
      height: ${height || calcWidth(270)}px;
      width: ${width || calcWidth(270)}px;
      align-self: center;
      text-align: center;
    `,
  ),
};

export default UploadingOverlay;
