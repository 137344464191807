import { Platform } from 'react-native';

export enum EAppAuthType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const APP_TYPE: EAppAuthType = (() => {
  switch (Platform.OS) {
    case 'ios':
    case 'android':
      return EAppAuthType.MOBILE;
    case 'web':
      return EAppAuthType.DESKTOP;
  }

  return EAppAuthType.MOBILE;
})();

export const MS_PERSONAL_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';
export const FIREBASE_MESSAGING_VAPID_KEY =
  'BDeSO4zMMCo_zcn8xhFs6LByq_Wj-4guqTZm5J_95njWImHmq-wzS12V7GavLmtPRE_QLgI23cZZjfmOZpOujQU';
