import { useEffect } from 'react';
import { NavigationProp, useIsFocused } from '@react-navigation/native';
import { ParamListBase } from '@react-navigation/routers';
import { IAttributeMap } from '../../../store/attributes/attributes.types';
import useHasAttributesToRender from './useHasAttributesToRender';
import { setCurrentList } from '../../../store/analytics/analytics.slice';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { useDispatch } from 'react-redux';

export type TListScreen = 'AnsweredList' | 'CommentsList' | 'OverviewList' | 'ViewsList';

interface IProps {
  listScreen: TListScreen;
  navigation: NavigationProp<ParamListBase>;
  attributesMap: IAttributeMap;
}

const useRedirectFromAttributes = ({ attributesMap, listScreen, navigation }: IProps) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const { hasAttributesToRender } = useHasAttributesToRender({
    attributesMap,
  });

  useEffect(() => {
    if (hasAttributesToRender || !isFocused) {
      return;
    }

    //@ts-ignore
    navigation.replace(listScreen);
    dispatch(setCurrentList(ECurrentList.USERS));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAttributesToRender]);
};

export default useRedirectFromAttributes;
