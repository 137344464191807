import React, { FC, ReactElement, useCallback } from 'react';
import { ActivityIndicator, FlatList, RefreshControl, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../utils/dimensions';
import EmptyList from '../shared/EmptyList';
import Preloader from '../shared/Preloader';
import { useTranslation } from 'react-i18next';

interface IProps {
  items: any[];
  isLoading?: boolean;
  isSmall?: boolean;
  numColumns?: number;
  emptyListText?: string;
  keyExtractor?: (item) => string;
  renderItem: (item) => ReactElement;
  onPullToRefresh?: () => void;
  onEndReached?: () => void;
  renderEmptyList?: () => React.ReactElement;
}
const dataSet = { 'intercom-target': 'feed-list-container' };
const listContainerStyle = { flex: 1 };

const FeedItemsList: FC<IProps> = ({
  items,
  renderItem,
  keyExtractor,
  isLoading,
  isSmall,
  numColumns,
  emptyListText,
  onPullToRefresh,
  onEndReached,
  renderEmptyList,
}) => {
  const { t } = useTranslation();

  const keyExtractorDefault = useCallback((item) => `${item.id}`, []);

  if (items.length === 0 && isLoading) {
    return (
      <S.PreloaderContainer>
        <Preloader />
      </S.PreloaderContainer>
    );
  }

  if (items.length === 0) {
    if (typeof renderEmptyList === 'function') {
      return renderEmptyList();
    }
    return <EmptyList message={emptyListText || t('homeScreen.emptySearchList')} />;
  }

  return (
    <S.ListContainer
      style={listContainerStyle}
      //@ts-ignore
      dataSet={dataSet}
    >
      <FlatList
        data={items}
        numColumns={numColumns || (isSmall ? (isWeb ? 4 : 3) : isWeb ? 3 : 2)}
        contentContainerStyle={styles.flatListContainer}
        renderItem={renderItem}
        keyExtractor={keyExtractor || keyExtractorDefault}
        refreshControl={onPullToRefresh ? <RefreshControl refreshing={false} onRefresh={onPullToRefresh} /> : undefined}
        ListFooterComponent={items.length > 0 && isLoading && <S.FetchMoreIndicator color='#000' />}
        onEndReached={onEndReached}
        onEndReachedThreshold={isWeb ? 0.001 : 1}
      />
    </S.ListContainer>
  );
};

const styles = StyleSheet.create({
  flatListContainer: {
    paddingHorizontal: isWeb ? calcWidth(45) : calcWidth(10),
    marginBottom: calcHeight(30),
    marginRight: calcWidth(10),
  },
});

const S = {
  ListContainer: styled.View``,
  PreloaderContainer: styled.View`
    flex: 1;
    height: 100%;
    justify-content: center;
  `,
  FetchMoreIndicator: styled(ActivityIndicator)`
    margin-bottom: ${calcHeight(16)}px;
  `,
};

export default FeedItemsList;
