import { RootState } from '../index';
import { EPanel, Tabs } from './homeScreen.types';

export const homeTabsActiveTabSelector = (state: RootState): Tabs => {
  return state.homeScreen.activeTab;
};

export const activePanelSelector = (state: RootState): EPanel => {
  return state.homeScreen.activePanel;
};
