import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { calcFontSize, calcHeight, deviceWidth } from '../../../../utils/dimensions';
import { activeOrganizationSelector } from '../../../../store/auth/auth.selectors';
import { IUserOrganizations } from '../../../../store/auth/auth.types';
import { switchOrganization } from '../../../../store/org/org.slice';
import { ScrollView } from 'react-native-gesture-handler';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import useOrganizations from '../../../../hooks/useOrganizations';

const shadowOffset: ShadowProps['offset'] = [0, 0];

interface IProps {}

const OrganizationsPanel: React.FC<IProps> = ({}) => {
  const organizationList = useOrganizations();

  return (
    <ShadowedContainer offset={shadowOffset}>
      <S.Container>
        <ScrollView>
          {organizationList.map((organization) => (
            <Organization organization={organization} key={organization.id} />
          ))}
        </ScrollView>
      </S.Container>
    </ShadowedContainer>
  );
};

interface IOrganizationProps {
  organization: IUserOrganizations;
}
const Organization: FC<IOrganizationProps> = ({ organization }) => {
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeOrganizationSelector);

  const isActive = organization.id === activeOrganization.id;

  const handleChangeOrganization = useCallback(() => {
    dispatch(togglePanel(null));

    if (isActive) {
      return;
    }

    dispatch(
      trackEvent({
        event: 'change_organization',
        props: { from_organization_id: activeOrganization?.id, to_organization_id: organization.id },
      }),
    );

    if (organization) {
      dispatch(switchOrganization({ organization }));
    }
  }, [dispatch, isActive, activeOrganization?.id, organization]);

  return (
    <S.OrganizationContainer onPress={handleChangeOrganization}>
      <S.OrganizationText isActive={isActive}>{organization.name}</S.OrganizationText>
    </S.OrganizationContainer>
  );
};

const S = {
  Container: styled.View`
    padding-bottom: ${calcHeight(70)}px;
    width: ${deviceWidth}px;
    height: ${calcHeight(300)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  OrganizationContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    height: ${calcHeight(40)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  OrganizationText: styled.Text<{ isActive: boolean }>`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ isActive }) => calcFontSize(isActive ? 20 : 14)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
};

export default OrganizationsPanel;
