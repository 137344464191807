import React, { FC, useCallback } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import DraggableFlatListNative from 'react-native-draggable-flatlist';
import { RenderItem, DragEndParams } from 'react-native-draggable-flatlist';

interface IProps {
  bottomSpacing?: number;
  data: any[];
  keyExtractor: (item: any, index: number) => string;
  renderItem: RenderItem<any>;
  onDragEnd?: (params: DragEndParams<any>) => void;
}

const DraggableFlatList: FC<IProps> = ({ bottomSpacing = 0, data, keyExtractor, renderItem, onDragEnd }) => {
  const renderPlaceholder = useCallback(
    () => (
      <S.DropPlaceholderContainer>
        <S.DropPlaceholder />
      </S.DropPlaceholderContainer>
    ),
    [],
  );
  return (
    <S.Container>
      <DraggableFlatListNative
        dragItemOverflow={true}
        bounces={false}
        data={data}
        keyExtractor={keyExtractor}
        onDragEnd={onDragEnd}
        autoscrollThreshold={0.8}
        autoscrollSpeed={100}
        renderItem={renderItem}
        renderPlaceholder={renderPlaceholder}
      />
      <S.Spacing bottomSpacing={bottomSpacing} />
    </S.Container>
  );
};

const S = {
  Container: styled.ScrollView`
    height: 100%;
  `,
  Spacing: styled.View<{ bottomSpacing: number }>`
    height: ${({ bottomSpacing }) => calcHeight(bottomSpacing)}px;
  `,
  DropPlaceholderContainer: styled.View`
    padding: 0 ${calcWidth(30)}px;
    width: 100%;
  `,
  DropPlaceholder: styled.View`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default DraggableFlatList;
