import { ISummaryCard } from '../../types/biteSummary';
import { cloneDeep, last } from 'lodash';
import Types from './biteSummary.types';
import BiteTypes from '../bite/bite.types';
import AuthTypes from '../auth/auth.types';
import { IAction } from '../common/types';
import { filterIfCardIsEmpty, generateSummaryCard } from '../../utils/biteSummary';
import { isEmpty } from 'lodash';

type TInitState = {
  cards: ISummaryCard[];
  isCreated: boolean;
};

export const summaryInitState: TInitState = {
  cards: [generateSummaryCard(true)],
  isCreated: false,
};

const reducer = (state = summaryInitState, action: IAction<any>): TInitState => {
  switch (action.type) {
    case Types.ADD_SUMMARY_CARD:
      const lastCard = last(state.cards);
      return {
        ...state,
        cards: [...state.cards, generateSummaryCard(false, lastCard?.color)],
      };
    case Types.DELETE_SUMMARY_CARD:
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== action.payload),
      };
    case Types.SET_SUMMARY_CARD:
      const updatedCards = state.cards.map((card) => {
        if (card.id === action.payload.id) {
          return action.payload;
        }
        return card;
      });

      return { ...state, cards: updatedCards };
    case Types.FILTER_EMPTY_SUMMARY_CARDS:
      return { ...state, cards: state.cards?.filter(filterIfCardIsEmpty) || [] };
    case BiteTypes.SET_BITE_TO_EDIT: {
      const { summaryCardsState } = action.payload;
      const cards = isEmpty(summaryCardsState) ? summaryInitState.cards : summaryCardsState;

      return { ...state, cards: cloneDeep(cards) };
    }
    case Types.SET_SUMMARY_CARDS:
      return { ...state, cards: action.payload };

    case Types.SET_CREATED_SUMMARY:
      return {
        ...state,
        isCreated: action.payload,
      };

    case Types.CLEAR_STATE:
      return cloneDeep(summaryInitState);

    case BiteTypes.CLEAN_EDIT_AND_CREATE_BITE_STATE:
      return cloneDeep(summaryInitState);

    case AuthTypes.LOGOUT:
      return cloneDeep(summaryInitState);

    default:
      return state;
  }
};

export default reducer;
