import React, { useCallback, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getBiteShareId, getBiteId } from '../../utils/formatDataFromServer';

import { PREVIEW_SITE_URL } from '../../utils/constants/urls';
import useToken from '../../hooks/useToken';
import WebView from '../../components/WebView/WebView';
import PreviewControlPanel from '../../components/shared/PreviewControlPanel';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import styled, { useTheme } from 'styled-components/native';
import { Platform, StatusBar, View } from 'react-native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../utils/dimensions';
import CloneIcon from '../../assets/icons/clone.svg';
import { PAGE_WIDTH } from '../../components/shared/PreviewControlPanel/constants';
import { useTranslation } from 'react-i18next';
import { IStackNavigation } from '../../navigation/types';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { EDeepLinkSection } from '../../utils/restoreFlows/types';
import GenericButton from '../../components/shared/buttons/GenericButton';
import startBiteCreationFlowFromInspirationBite from '../../utils/bite/startBiteCreationFlowFromInspirationBite';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps extends IStackNavigation {}

const dataSet = { 'intercom-target': 'clone-inspiration-bite' };

const PreviewExploreBite: React.FC<IProps> = ({ route }) => {
  const { bite } = route.params;
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { t } = useTranslation();
  const theme = useTheme();
  const isFocused = useIsFocused();

  const { token } = useToken();

  const biteId = getBiteId(bite);
  const biteShareId = getBiteShareId(bite);

  const [isCloning, setIsCloning] = useState(false);

  const url = useMemo(
    () => `${PREVIEW_SITE_URL}/bites/${biteShareId}/?token=${token}&rnd=${Date.now()}&isPreview=1`,
    [biteShareId, token],
  );

  const handleClone = useCallback(() => {
    if (!isFocused || isCloning) {
      return;
    }

    setIsCloning(true);

    startBiteCreationFlowFromInspirationBite({
      biteId,
      section: EDeepLinkSection.INTRO,
    });
  }, [isFocused, isCloning, biteId]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'view',
        props: { external_target_url: url, bite_id: biteId, trigger: 'card', inspiration_bite: true },
      }),
    );
  }, [biteId, dispatch, url]);

  useEffect(() => {
    if (!isWeb) {
      return;
    }
    const subscription = (event) => {
      if (event?.data?.action === 'event' && event.data.data) {
        dispatch(
          trackEvent({
            event: 'frontliner_event',
            props: { ...event.data.data, from: 'inspiration_bite_preview' },
          }),
        );
      }
    };
    window.addEventListener('message', subscription, false);
    return () => {
      removeEventListener('message', subscription);
    };
  }, [dispatch]);

  return (
    <S.Container>
      <S.WebViewWrap2>
        <S.WebViewWrap>
          <WebView url={url} />
          <PreviewControlPanel onClose={navigation.goBack}>
            <S.SwipePage>
              <GenericButton
                isLoading={isCloning}
                onPress={handleClone}
                width={isWeb ? calcWidth(190) : PAGE_WIDTH - PAGE_WIDTH / 4 - calcWidth(9)}
                renderedIcon={<CloneIcon style={S.Icon} color={theme.colors.white} />}
                content={<S.ButtonText>{t('controlPanel.makeItOwn')}</S.ButtonText>}
                // @ts-ignore
                dataSet={dataSet}
              />
            </S.SwipePage>
          </PreviewControlPanel>
        </S.WebViewWrap>
      </S.WebViewWrap2>
    </S.Container>
  );
};
const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap2: styled(View)`
    flex-grow: 1;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  WebViewWrap: styled(View)`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
  `,
  SwipePage: styled.View`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: ${calcHeight(55)}px;
    width: ${PAGE_WIDTH + calcWidth(30)}px;
  `,
  ButtonText: styled.Text<{ color?: string }>`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.white};
    line-height: ${calcHeight(40)}px;
    height: ${calcHeight(40)}px;
    margin-right: ${isWeb ? `${calcWidth(10)}px` : '0px'};
  `,
  Icon: {
    width: calcWidth(28),
    height: calcHeight(28),
    marginRight: isWeb ? 0 : calcWidth(17),
    marginLeft: isWeb ? calcWidth(17) : 0,
  },
};

export default memo(PreviewExploreBite);
