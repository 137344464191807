import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components/native';

import ShadowedContainer from '../../ShadowedContainer';
import { calcWidth } from '../../../utils/dimensions';
import RefreshIcon from '../../../assets/icons/refresh.svg';
import { I18nManager, StyleProp, ViewStyle } from 'react-native';

interface IProps {
  onPress?: () => void;
  disabled?: boolean;
  dataSet?: { [key: string]: string };
}

const RefreshButton: FC<IProps> = ({ onPress, disabled, dataSet }) => {
  const theme = useTheme();

  return (
    <ShadowedContainer containerViewStyle={S.ShadowedContainerStyles}>
      <S.BackButton
        // @ts-ignore
        dataSet={dataSet}
        onPress={onPress}
        disabled={disabled}
      >
        <RefreshIcon color={theme.colors.primaryBlue} />
      </S.BackButton>
    </ShadowedContainer>
  );
};

const S = {
  ShadowedContainerStyles: {
    marginLeft: calcWidth(10),
  } as StyleProp<ViewStyle>,
  BackButton: styled.TouchableOpacity<{
    disabled?: boolean;
  }>`
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.darkGray3 : theme.colors.white)};
    border-radius: ${calcWidth(40 / 2)}px;
    height: ${calcWidth(40)}px;
    width: ${calcWidth(40)}px;
    transform: rotate(${I18nManager.isRTL ? '180deg' : '0deg'});
  `,
};

export default RefreshButton;
