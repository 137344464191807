import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialState, IQuestionnaire, IUserQuestionnaire } from './questionnaire.types';

const initialState: IInitialState = {
  questionnairesMap: {},
  userQuestionnaires: null,
  userQuestionnairesMapByQuestionnaireId: null,
};

const questionnaire = 'questionnaire';

export const loadUserQuestionnaires = createAction(`${questionnaire}/loadUserQuestionnaires`);
export const loadOnboardingQuestionnaire = createAction(`${questionnaire}/loadOnboardingQuestionnaire`);
export const launchOnboardingQuestionnaire = createAction(`${questionnaire}/launchOnboardingQuestionnaire`);
export const saveUserQuestionnaire = createAction<IQuestionnaire['id']>(`${questionnaire}/saveUserQuestionnaire`);

const questionnaireSlice = createSlice({
  name: questionnaire,
  initialState,
  reducers: {
    setQuestionnaire: (state, { payload }: PayloadAction<IQuestionnaire>) => {
      state.questionnairesMap[payload.id] = payload;
    },
    setUserQuestionnaires: (state, { payload }: PayloadAction<IUserQuestionnaire[]>) => {
      state.userQuestionnaires = payload;
      state.userQuestionnairesMapByQuestionnaireId = (payload || []).reduce((map, userQuestionnaire) => {
        map[userQuestionnaire.questionnaireId] = userQuestionnaire;
        return map;
      }, {});
    },
    setUserQuestionnaire: (state, { payload }: PayloadAction<IUserQuestionnaire>) => {
      state.userQuestionnaires = state.userQuestionnaires || [];
      state.userQuestionnairesMapByQuestionnaireId = state.userQuestionnairesMapByQuestionnaireId || {};

      const index = state.userQuestionnaires?.findIndex(({ id }) => id === payload.id);

      state.userQuestionnairesMapByQuestionnaireId[payload.questionnaireId] = payload;
      if (index > -1) {
        state.userQuestionnaires.splice(index, 1, payload);
        return;
      }
      state.userQuestionnaires.push(payload);
    },
  },
});

export const { setQuestionnaire, setUserQuestionnaires, setUserQuestionnaire } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
