import { EFileType, IMedia, MediaViewerProps } from '../types/media';

const convertMediaToMediaViewerProps = (media: IMedia): MediaViewerProps => {
  return {
    mediaType: media.file_type,
    mediaURI: media.file_type === EFileType.IMAGE ? media.image_url : media.media_url,
    youtubeMedia: {
      url: media.media_url,
      video_start_at: media.video_start_at,
      video_end_at: media.video_ends_at,
      is_cc_enabled: media.is_cc_enabled,
    },
    videoLinkRequired: !media.isVideoPathLocal,
    subtitles: media.subtitles,
  };
};

export default convertMediaToMediaViewerProps;
