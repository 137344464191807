import { EEnhanceType, EUploadStatus } from '../../types/bite';

const getHasEnhancementsEdit = ({ introEnhancements, introSubtitles }) => {
  const hasEnhancements = introEnhancements?.some(({ enhance_type, upload_status }) => {
    return enhance_type === EEnhanceType.speech_enhancement && upload_status === EUploadStatus.done;
  });

  const hasSubtitles = introSubtitles?.some(({ upload_status }) => upload_status === EUploadStatus.done);

  return hasEnhancements || hasSubtitles;
};
export default getHasEnhancementsEdit;
