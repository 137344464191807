import ShareIcon from '../../../../../assets/icons/preview/share.svg';
import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../../utils/dimensions';
import { useTranslation } from 'react-i18next';

interface IProps {
  disabled?: boolean;
  onPress: () => void;
  width: number;
}

const dataSet = { 'intercom-target': 'share-button' };

const ShareButton: React.FC<IProps> = ({ disabled, onPress, width }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const iconColor = disabled ? theme.colors.gray19 : theme.colors.primaryBlue;

  return (
    <S.ShareButton
      disabled={disabled}
      width={width}
      onPress={onPress}
      // @ts-ignore
      dataSet={dataSet}
    >
      <ShareIcon style={S.Icon} color={iconColor} />
      <S.ButtonText color={theme.colors.gray19}>{t('common.Share')}</S.ButtonText>
    </S.ShareButton>
  );
};

const S = {
  ShareButton: styled.TouchableOpacity<{ width: number }>`
    flex: 1;
    height: ${calcHeight(53)}px;
    min-width: ${({ width }) => width}px;
    max-width: ${({ width }) => width}px;
    border-radius: 10px;
    border: solid 1px ${({ theme }) => theme.colors.gray21};
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0 ${calcWidth(2)}px;
    align-items: center;
    justify-content: center;
  `,
  Icon: {
    width: calcWidth(28),
    height: calcHeight(28),
  },

  ButtonText: styled.Text<{ color?: string }>`
    margin-top: ${calcHeight(2)}px;
    font-size: ${({ theme }) => theme.fontSizes.s11}
    text-align: center;
    color: ${({ theme, color }) => color || theme.colors.gray19}`,
};

export default ShareButton;
