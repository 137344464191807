import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { calcFontSize, calcHeight, deviceWidth } from '../../../../utils/dimensions';
import { ScrollView } from 'react-native-gesture-handler';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { ShadowProps } from 'react-native-shadow-2';
import { typesSelector } from '../../../../store/feed/feed.selectors';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { loadNextPage, setTypes } from '../../../../store/feed/feed.slice';
import { getFeedObjectTypeKey, feedObjectTypeOptions } from '../../../../store/homeScreen/homeScreen.constants';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import { typeSelected } from './component.constants';

const shadowOffset: ShadowProps['offset'] = [0, 0];

interface IProps {}

const FeedObjectTypePanel: React.FC<IProps> = ({}) => {
  const types = useSelector(typesSelector);

  const activeOption = useMemo(
    () =>
      feedObjectTypeOptions.find((typeOption) => {
        return typeOption.key === getFeedObjectTypeKey(types);
      }),
    [types],
  );

  return (
    <ShadowedContainer offset={shadowOffset}>
      <S.Container>
        <ScrollView>
          {feedObjectTypeOptions.map((typeOption) => (
            <TypeOption activeOption={activeOption} typeOption={typeOption} key={typeOption.label} />
          ))}
        </ScrollView>
      </S.Container>
    </ShadowedContainer>
  );
};

const TypeOption = ({ activeOption, typeOption }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isActive = getFeedObjectTypeKey(typeOption.types) === activeOption.key;

  const handleSetTypes = useCallback(() => {
    dispatch(togglePanel(null));
    if (isActive) {
      return;
    }
    dispatch(
      trackEvent({
        event: 'set_feed_filter_type',
        props: { from_value: `${activeOption.key}`, to_value: `${typeOption.key}` },
      }),
    );
    dispatch(setTypes(typeOption.types));
    dispatch(loadNextPage({ reset: true }));
  }, [dispatch, isActive, activeOption.key, typeOption.key, typeOption.types]);

  return (
    <S.ValueContainer
      onPress={handleSetTypes}
      // @ts-ignore
      dataSet={typeSelected}
    >
      <S.ValueText isActive={isActive}>{t(`filterPannel.${typeOption.label}`)}</S.ValueText>
    </S.ValueContainer>
  );
};

const S = {
  Container: styled.View`
    padding-bottom: ${calcHeight(70)}px;
    width: ${deviceWidth}px;
    height: ${calcHeight(300)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueContainer: styled.TouchableOpacity`
    align-items: center;
    justify-content: center;
    height: ${calcHeight(40)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ValueText: styled.Text<{ isActive: boolean }>`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ isActive }) => calcFontSize(isActive ? 20 : 14)}px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
  `,
};

export default FeedObjectTypePanel;
