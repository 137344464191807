import { AxiosResponse } from 'axios';
import { IBiteItem } from '../../types/bite';
import { IDataTable, ESortDirection, EObjectType } from '../../types/common';
import { IPlaylist } from '../playlist/playlist.types';

export interface IFeedItem {
  content_type: EObjectType;
  id: number;
}
export interface IInitialState {
  results: IFeedItem[];
  nextPage: number | null; // null if loaded everything
  isLoading: boolean;
  sortField: EFeedSortField;
  sortDirection: ESortDirection;
  searchStr: string;
  types: IFeedObjectType[];
  creatorIds: number[];
  creatorsLabel: string;
  hasUserContent: boolean;
}
export enum IFeedObjectType {
  bite = 'bite',
  playlist = 'playlist',
  quiz = 'quiz',
  all = 'all',
}
export enum EFeedSortField {
  created_at = 'created_at',
  subject = 'subject',
}
interface IGetFeedRequest {
  filters: {
    organization: number;
    type: IFeedObjectType[];
    creatorIds: number[];
    search?: string;
  };
  sort: {
    field: EFeedSortField;
    direction: ESortDirection;
  };
  pagination: boolean;
  page: number;
  page_size: number;
}
export type TGetFeedResponse = AxiosResponse<IDataTable<IBiteItem | IPlaylist>>;
export type TGetFeed = (payload: IGetFeedRequest) => Promise<TGetFeedResponse>;

export interface ISetUserContent {
  results: IFeedItem[];
}
export interface ILoadNextPageResults {
  results: IFeedItem[];
  nextPage: number | null;
}

export interface ILoadNextPage {
  withBaseFiltersAndSorting?: boolean;
  reset?: boolean;
  debounce?: boolean;
}
