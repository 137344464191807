import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { biteQuestionSelector } from '../../../store/biteQuestion/biteQuestion.selectors';
import { IBiteAnswer } from '../../../types/biteQuestion';
import { cloneDeep } from 'lodash';
import { createAnswer } from '../../../utils/question';
import { trackEvent } from '../../../store/appActivity/appActivity.slice';
import {
  IMultichoiceSuggestion,
  IOpenSuggestion,
  ISurveySuggestion,
} from '../../../store/createBite/createBites.actions';

interface IProps {
  from: string;
  questionSuggestion: IMultichoiceSuggestion | IOpenSuggestion | ISurveySuggestion;
}

export default ({ from, questionSuggestion }: IProps) => {
  const { type, text, answers, media } = useSelector(biteQuestionSelector);
  const dispatch = useDispatch();

  const [localInputValue, setLocalInputValue] = useState(text);
  const [localMedia, setLocalMedia] = useState(media);
  const [localAnswers, setLocalAnswers] = useState([]);

  const handleChangeAnswer = useCallback((answer: IBiteAnswer) => {
    setLocalAnswers((previousLocalAnswers) =>
      previousLocalAnswers.map((localAnswer) => (localAnswer.id === answer.id ? answer : localAnswer)),
    );
  }, []);

  const handleAddAnswer = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'add_answer',
        props: {
          question_type: type,
          from,
        },
      }),
    );

    const updatedAnswers = cloneDeep(localAnswers);
    updatedAnswers.push(createAnswer());
    setLocalAnswers(updatedAnswers);
  }, [dispatch, from, localAnswers, type]);

  const handleDeleteAnswer = useCallback(
    (id: IBiteAnswer['id']) => {
      dispatch(
        trackEvent({
          event: 'delete_answer_btn',
          props: {
            question_type: type,
            from,
          },
        }),
      );

      const clonedUpdatedAnswers = cloneDeep(localAnswers);
      const updatedAnswers = clonedUpdatedAnswers.filter((item) => item.id !== id);
      setLocalAnswers(updatedAnswers);
    },
    [dispatch, from, localAnswers, type],
  );

  const handleSelectCorrectAnswer = useCallback((id: IBiteAnswer['id']) => {
    setLocalAnswers((previousLocalAnswers) =>
      previousLocalAnswers.map((localAnswer) => {
        const updatedAnswer = cloneDeep(localAnswer);
        updatedAnswer.is_correct = id === updatedAnswer.id;
        return updatedAnswer;
      }),
    );
  }, []);

  useEffect(() => {
    setLocalInputValue(text);
  }, [text]);

  useEffect(() => {
    setLocalMedia(media);
  }, [media]);

  useEffect(() => {
    setLocalAnswers(answers);
  }, [answers]);

  const setSuggestedAnswers = useCallback(() => {
    const newAnswers =
      'answers' in questionSuggestion
        ? questionSuggestion.answers.map((answer) => {
            return {
              ...createAnswer(),
              text: answer.text,
              is_correct: answer.is_correct,
            };
          })
        : [];

    setLocalAnswers(newAnswers);
  }, [questionSuggestion]);

  useEffect(() => {
    if (questionSuggestion) {
      setLocalInputValue(questionSuggestion.question);
      if (
        'answers' in questionSuggestion &&
        questionSuggestion.answers.length > 1 &&
        questionSuggestion.answers.length < 5
      ) {
        setSuggestedAnswers();
      }
    }
  }, [questionSuggestion, setSuggestedAnswers]);

  return {
    localInputValue,
    setLocalInputValue,
    localMedia,
    setLocalMedia,
    localAnswers,
    setLocalAnswers,
    handleChangeAnswer,
    handleAddAnswer,
    handleDeleteAnswer,
    handleSelectCorrectAnswer,
  };
};
