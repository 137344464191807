import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import shortid from 'shortid';
import { EAppState } from '../../hooks/useOnAppStateChange';
import { isWeb } from '../../utils/dimensions';
import { IElementsPanelConfig } from '../../components/shared/ElementsPanel/types';

import {
  IAvaitingEnhancements,
  IConfig,
  ICurrentFlowBiteCreation,
  ICurrentFlowBiteEdition,
  IInitialState,
  ITrackInfoArgs,
  IUserOrgPreferences,
  TCurrentFlowAction,
  IContentLocale,
  IExternalAction,
  IUserIPInfo,
} from './appActivity.types';

const initialState: IInitialState = {
  acrossSessionsId: shortid.generate(),
  currentFlow: null,
  config: null,
  isCaptchaInitialized: isWeb,
  isRequestNotificationPermissionsModalShown: null,
  userOrgPreferences: {
    skip_create_bite_flow_overview_screen: null,
    skip_create_playlist_flow_overview_screen: null,
    skip_edit_media_overview_modal: null,
  },
  userIPInfo: null,
  avaitingEnhancements: {},
  translationsAreInitialized: false,
  banubaDraftIds: [],
  isConnected: true,
  appState: isWeb ? EAppState.active : EAppState.launch,
  slowConnection: {
    isSlowConnection: false,
    isDismissed: false,
  },
  biteIntroHelpersConfig: null,
  biteCreatedHelpersConfig: null,
  isGoogleSlidesExplainerVisibleInitial: true,
  contentLocales: null,
  externalAction: null,
  isNavigationStackDisplayed: false,
  isVideoEditorOpen: false,
  isFullWidthContent: false,
  isMobileStatsExplanationVideoWatched: false,
};

const appActivity = 'appActivity';

export const loadConfig = createAction(`${appActivity}/loadConfig`);
export const initCaptcha = createAction(`${appActivity}/initCaptcha`);

export const updateUserOrgPreferences = createAction<Partial<IUserOrgPreferences>>(
  `${appActivity}/updateUserOrgPreferences`,
);

export const initSharePermission = createAction(`${appActivity}/initSharePermission`);
export const setRequestNotificationPermissionsModalShown = createAction<boolean>(
  `${appActivity}/setNotificationPermission`,
);
export const startBiteCreation = createAction(`${appActivity}/startBiteCreation`);
export const startPlaylistCreation = createAction(`${appActivity}/startPlaylistCreation`);
export const trackEvent = createAction<ITrackInfoArgs>(`${appActivity}/trackEvent`);
export const loadContentLocales = createAction(`${appActivity}/loadContentLocales`);
export const log = createAction<object>(`${appActivity}/log`);
export const logError = createAction<object>(`${appActivity}/logError`);
export const applyExternalAction = createAction<object>(`${appActivity}/applyExternalAction`);
export const runExternalAction = createAction<object>(`${appActivity}/runExternalAction`);
export const loadBiteHelperConfigs = createAction<object>(`${appActivity}/loadBiteHelperConfigs`);

const appActivitySlice = createSlice({
  name: appActivity,
  initialState,
  reducers: {
    runInit: () => {
      // nothing to do
    },
    setConfig: (state, { payload }: PayloadAction<IConfig>) => {
      state.config = { ...state.config, ...payload };
    },
    setIsCaptchaInitialized: (state, { payload }: PayloadAction<boolean>) => {
      state.isCaptchaInitialized = payload;
    },
    setIsRequestNotificationPermissionsModalShown: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestNotificationPermissionsModalShown = payload;
    },
    userOrgPreferencesSuccess: (state, { payload }: { payload: IUserOrgPreferences }) => {
      state.userOrgPreferences = payload;
    },
    setAvaitingEnhancements: (state, { payload }: { payload: IAvaitingEnhancements }) => {
      state.avaitingEnhancements = payload;
    },
    setBiteAvaitingEnhancements: (
      state,
      { payload: { biteId, isAvaitingEnhancements } }: { payload: { biteId: number; isAvaitingEnhancements: boolean } },
    ) => {
      if (isAvaitingEnhancements) {
        state.avaitingEnhancements[biteId] = new Date().toISOString();
      } else {
        delete state.avaitingEnhancements[biteId];
      }
    },
    setTranslationsAreInitialized: (state) => {
      state.translationsAreInitialized = true;
    },
    setCurrentFlow: (state, { payload }: PayloadAction<null | TCurrentFlowAction>) => {
      if (payload === null) {
        state.currentFlow = null;
        return;
      }

      state.currentFlow = {
        ...(state.currentFlow || {}),
        ...payload,
      } as ICurrentFlowBiteCreation | ICurrentFlowBiteEdition;
    },
    setBanubaDraftIds: (state, { payload }: PayloadAction<string[]>) => {
      state.banubaDraftIds = payload;
    },
    setIsConnected: (state, { payload }: PayloadAction<boolean>) => {
      state.isConnected = payload;
    },
    setAppState: (state, { payload }: PayloadAction<EAppState>) => {
      state.appState = payload;
    },
    setSlowConnection: (state, { payload }: PayloadAction<Partial<IInitialState['slowConnection']>>) => {
      state.slowConnection = {
        ...state.slowConnection,
        ...payload,
      };
    },
    setBiteIntroHelpersConfig: (state, { payload }: PayloadAction<IElementsPanelConfig>) => {
      state.biteIntroHelpersConfig = payload;
    },
    setBiteCreatedHelpersConfig: (state, { payload }: PayloadAction<IElementsPanelConfig>) => {
      state.biteCreatedHelpersConfig = payload;
    },
    setIsGoogleSlidesExplainerVisibleInitial: (state, { payload }: PayloadAction<boolean>) => {
      state.isGoogleSlidesExplainerVisibleInitial = payload;
    },
    setContentLocales: (state, { payload }: PayloadAction<IContentLocale[]>) => {
      state.contentLocales = payload;
    },
    setExternalAction: (state, { payload }: PayloadAction<IExternalAction>) => {
      state.externalAction = payload;
    },
    setIsNavigationStackDisplayed: (state, { payload }: PayloadAction<boolean>) => {
      state.isNavigationStackDisplayed = payload;
    },
    setUserIPInfo: (state, { payload }: PayloadAction<IUserIPInfo>) => {
      state.userIPInfo = payload;
    },
    setIsVideoEditorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isVideoEditorOpen = payload;
    },
    setIsFullWidthContent: (state, { payload }: PayloadAction<boolean>) => {
      state.isFullWidthContent = payload;
    },
    setIsMobileStatsExplanationVideoWatched: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileStatsExplanationVideoWatched = payload;
    },
  },
});

export const {
  runInit,
  setConfig,
  setIsCaptchaInitialized,
  setExternalAction,
  userOrgPreferencesSuccess,
  setBiteAvaitingEnhancements,
  setAvaitingEnhancements,
  setTranslationsAreInitialized,
  setIsRequestNotificationPermissionsModalShown,
  setCurrentFlow,
  setBanubaDraftIds,
  setIsConnected,
  setAppState,
  setSlowConnection,
  setBiteIntroHelpersConfig,
  setBiteCreatedHelpersConfig,
  setIsGoogleSlidesExplainerVisibleInitial,
  setContentLocales,
  setIsNavigationStackDisplayed,
  setUserIPInfo,
  setIsVideoEditorOpen,
  setIsFullWidthContent,
  setIsMobileStatsExplanationVideoWatched,
} = appActivitySlice.actions;

export default appActivitySlice.reducer;
