import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authInProgressSelector, errorCodesSelector } from '../../../../store/auth/auth.selectors';
import { loginWithPhone, resetAuthErrors, signupWithPhone } from '../../../../store/auth/auth.actions';
import { currentFlowSelector, phoneFormSelector } from '../../../../store/authForm/authForm.selectors';
import { setCurrentForm, setPhone } from '../../../../store/authForm/authForm.slice';
import PhoneNumberForm from './PhoneNumberForm';
import { EAuthFlowType, EAuthFormType } from '../../auth.types';
import { log } from '../../../../store/appActivity/appActivity.slice';
import { v4 as uuid } from 'uuid';

interface IProps {}

const PhoneNumberAuth: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const errorCodes = useSelector(errorCodesSelector);
  const authInProgress = useSelector(authInProgressSelector);
  const currentFlow = useSelector(currentFlowSelector);
  const phone = useSelector(phoneFormSelector);

  const handleOtp = useCallback(
    (processId) => {
      dispatch(
        log({
          event: 'PhoneNumberAuth.navigateTo',
          processId,
          data: { destination: EAuthFormType.ENTER_CODE_PHONE, currentFlow },
        }),
      );

      dispatch(setCurrentForm(EAuthFormType.ENTER_CODE_PHONE));
      dispatch(resetAuthErrors());
    },
    [currentFlow, dispatch],
  );

  const handleContinueButtonPress = useCallback(() => {
    const processId = uuid();
    dispatch(
      log({
        event: 'PhoneNumberAuth: handleContinueButtonPress',
        processId,
        data: {
          currentFlow,
          username: phone,
        },
      }),
    );

    if (currentFlow === EAuthFlowType.SIGN_IN) {
      dispatch(
        loginWithPhone({
          credentials: {
            username: phone,
          },
          processId,
          onOtp: handleOtp,
        }),
      );
      return;
    }

    dispatch(
      signupWithPhone({
        phone,
        creator: false,
        organizations: [],
        processId,
        onOtp: handleOtp,
      }),
    );
  }, [currentFlow, dispatch, handleOtp, phone]);

  const handlePhoneNumberChange = useCallback(
    (number: string) => {
      dispatch(setPhone(number));
      dispatch(resetAuthErrors());
    },
    [dispatch],
  );

  return (
    <PhoneNumberForm
      errorCodes={errorCodes}
      phone={phone}
      isLoading={authInProgress}
      onChange={handlePhoneNumberChange}
      onContinue={handleContinueButtonPress}
    />
  );
};

export default memo(PhoneNumberAuth);
