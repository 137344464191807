import React from 'react';
import IntroProcessingAnimation from '../creationBite/common/IntroProcessingAnimation';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

interface IProps {
  width?: number;
  height?: number;
  backgroundImageUri?: string;
  bgOpacity?: number;
}

const ProcessingAnimation: React.FC<IProps> = ({ backgroundImageUri, width, height, bgOpacity = 0.8 }) => {
  const { t } = useTranslation();

  return (
    <S.Container width={width} height={height}>
      <S.AnimationContainer width={width - 10} height={height - 10} bgOpacity={bgOpacity}>
        <S.IntroProcessingAnimationWrapper>
          <IntroProcessingAnimation />
        </S.IntroProcessingAnimationWrapper>
        <S.ProcessingText>{t('editMain.sections.processing')}</S.ProcessingText>
      </S.AnimationContainer>
      {backgroundImageUri && (
        <S.Image
          resizeMode='cover'
          source={{
            uri: backgroundImageUri,
          }}
        />
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View<{ width?: number; height?: number }>`
    width: ${({ width }) => width || calcWidth(169)}px;
    height: ${({ height }) => height || calcHeight(300)}px;
  `,
  Image: styled.Image`
    flex: 1;
  `,
  IntroProcessingAnimationWrapper: styled.View`
    flex: 6;
  `,
  AnimationContainer: styled.View<{ width?: number; height?: number; bgOpacity: number }>`
    flex: 1;
    z-index: 2;
    position: absolute;
    align-self: center;
    justify-content: center;
    width: ${({ width }) => width || calcWidth(159)}px;
    height: ${({ height }) => height || calcHeight(290)}px;
    ${({ bgOpacity }) => `background-color: rgba(0, 0, 0, ${bgOpacity})`};
    margin: ${calcWidth(5)}px;
    border-radius: 12px;
  `,
  ProcessingText: styled.Text`
    flex: 1;
    align-self: center;
    font-size: ${({ theme }) => theme.fontSizes.s14};
    color: ${({ theme }) => theme.colors.white};
  `,
};

export default ProcessingAnimation;
