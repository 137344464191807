import { navigationRef } from '../../navigation/RootNavigation';
import Routes from '../../navigation/routes';

export interface IProps {
  playlistId: number;
  isEdit?: boolean;
  shareOnMount?: boolean;
}

const openPreviewPlaylist = ({ playlistId, isEdit, shareOnMount }: IProps) => {
  navigationRef.current.navigate(Routes.PreviewStack.StackName, {
    screen: Routes.PreviewStack.PreviewPlaylist,
    params: {
      playlistId,
      isEdit,
      shareOnMount,
    },
  });
};
export default openPreviewPlaylist;
