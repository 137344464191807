import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericLabel from './Shared/GenericLabel';
import { ETabType } from './useInspirationBitesTabs';

interface IProps {
  selectedUseCasesMap: { [key: string]: boolean };
  selectedIndustriesMap: { [key: string]: boolean };
  toggleTabIsSelected: (props: { type: ETabType; id: string }) => void;
  item: any;
}

const Label: React.FC<IProps> = ({ selectedUseCasesMap, selectedIndustriesMap, toggleTabIsSelected, item }) => {
  const { t } = useTranslation();
  const isPressed = item.type === ETabType.USE_CASE ? selectedUseCasesMap[item.id] : selectedIndustriesMap[item.id];

  const onLabelPress = useCallback(() => {
    toggleTabIsSelected(item);
  }, [item, toggleTabIsSelected]);

  return (
    <GenericLabel
      onPress={onLabelPress}
      text={t(`inpirationBitesTabs.${item.type}.${item.id}`)}
      isPressed={isPressed}
    />
  );
};

export default Label;
