import React, { FC } from 'react';
import styled, { css } from 'styled-components/native';
import { TouchableOpacityProps } from 'react-native';

import { calcWidth, calcHeight } from '../../utils/dimensions';
import CustomText from './CustomText';

interface IProps extends TouchableOpacityProps {
  bgColor?: string;
}

const Chip: FC<IProps> = ({ children, bgColor }) => {
  return (
    <S.Container bgColor={bgColor}>
      <S.Text>{children}</S.Text>
    </S.Container>
  );
};

const Container = styled.View<{ bgColor: string }>`
  width: ${calcWidth(34)}px;
  height: ${calcHeight(26)}px;
  border-radius: 18px;
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.greenChoose};
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Text = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyBold};
    font-size: ${theme.fontSizes.s18}px;
    color: ${theme.colors.black};
  `,
);

const S = { Container, Text };

export default Chip;
