import { useSelector } from 'react-redux';

import { avaitingEnhancementsSelector } from '../store/appActivity/appActivity.selectors';

const useIsAvaitingIntroEnhancements = (biteId: number): boolean => {
  const avaitingEnhancements = useSelector(avaitingEnhancementsSelector);
  return !!avaitingEnhancements[biteId];
};

export default useIsAvaitingIntroEnhancements;
