import { IInitialState } from './appActivity.types';

export const externalActionSelector = (state) => state.appActivity.externalAction;
export const acrossSessionsIdSelector = (state) => state.appActivity.acrossSessionsId;
export const configSelector = (state) => state.appActivity.config;
export const featureFlagsSelector = (state) => configSelector(state)?.featureFlags;
export const userOrgPreferencesSelector = (state) => state.appActivity.userOrgPreferences;
export const isRequestNotificationPermissionsModalShownSelector = (state) =>
  state.appActivity.isRequestNotificationPermissionsModalShown;
export const avaitingEnhancementsSelector = (state) => state.appActivity.avaitingEnhancements;
export const translationsAreInitializedSelector = (state) => state.appActivity.translationsAreInitialized;
export const currentFlowSelector = (state) => state.appActivity.currentFlow;
export const banubaDraftIdsSelector = (state) => state.appActivity.banubaDraftIds;
export const isConnectedSelector = (state) => state.appActivity.isConnected;
export const appStateSelector = (state) => state.appActivity.appState;
export const slowConnectionSelector = (state): IInitialState['slowConnection'] => state.appActivity.slowConnection;
export const biteIntroHelpersConfigSelector = (state) => state.appActivity.biteIntroHelpersConfig;
export const biteCreatedHelpersConfigSelector = (state) => state.appActivity.biteCreatedHelpersConfig;
export const isGoogleSlidesExplainerVisibleInitialSelector = (state) =>
  state.appActivity.isGoogleSlidesExplainerVisibleInitial;
export const isNavigationStackDisplayedSelector = (state) => state.appActivity.isNavigationStackDisplayed;

export const contentLocalesSelector = (state) => state.appActivity.contentLocales;

export const userIPInfoSelector = (state) => state.appActivity.userIPInfo;
export const dialCodeSelector = (state) => userIPInfoSelector(state)?.location?.country?.calling_codes?.[0];
export const countryCodeSelector = (state) => userIPInfoSelector(state)?.location?.country?.alpha2;
export const isVideoEditorOpenSelector = (state) => state.appActivity.isVideoEditorOpen;

export const isFullWidthContentSelector = (state) => state.appActivity.isFullWidthContent;
export const isMobileStatsExplanationVideoWatchedSelector = (state) =>
  state.appActivity.isMobileStatsExplanationVideoWatched;
