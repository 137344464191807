import ShadowedContainer from '../../ShadowedContainer';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/native';
import { deviceHeight, deviceWidth, isWeb } from '../../../utils/dimensions';

const ANIMATION_TIME = 200;

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  isFullscreen?: boolean;
  minHeight?: number;
  maxHeight?: number;
  withMinFixedHeight?: boolean;
  withScroll?: boolean;
  renderFooter?: () => React.ReactElement;
  backdropOpacity?: number;
  children: React.ReactNode;
}
const AnimatedBottomSheet: React.FC<IProps> = ({
  isVisible,
  onClose,
  isFullscreen,
  minHeight,
  maxHeight,
  withMinFixedHeight,
  withScroll = false,
  backdropOpacity = 0.1,
  renderFooter,
  children,
}) => {
  const initialMinHeightRef = useRef(0);
  const contentHeight = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(
    () => ({
      zIndex: 5,
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: deviceWidth,
      height: deviceHeight,
      transform: [
        {
          translateY: withTiming(deviceHeight - contentHeight.value, {
            duration: ANIMATION_TIME,
            easing: Easing.linear,
          }),
        },
      ],
    }),
    [contentHeight],
  );

  const footerAnimatedStyles = useAnimatedStyle(() => {
    if (isWeb) {
      return {
        bottom: withTiming(deviceHeight - contentHeight.value, {
          duration: ANIMATION_TIME,
          easing: Easing.linear,
        }),
      };
    }

    return {
      transform: [
        {
          translateY: withTiming(contentHeight.value - deviceHeight, {
            duration: ANIMATION_TIME,
            easing: Easing.linear,
          }),
        },
      ],
    };
  }, [contentHeight]);

  const handleLayout = useCallback(
    ({ nativeEvent }) => {
      const height = nativeEvent.layout.height;

      if (isFullscreen || (withMinFixedHeight && initialMinHeightRef.current > height)) {
        return;
      }

      if (maxHeight && height > maxHeight) {
        contentHeight.value = maxHeight;
        initialMinHeightRef.current = maxHeight;
        return;
      }

      if (minHeight && height < minHeight) {
        contentHeight.value = minHeight;
        initialMinHeightRef.current = minHeight;
        return;
      }

      contentHeight.value = height;
      initialMinHeightRef.current = height;
    },
    [contentHeight, isFullscreen, maxHeight, minHeight, withMinFixedHeight],
  );

  useEffect(() => {
    if (isFullscreen) {
      contentHeight.value = deviceHeight;
      return;
    }

    if (withMinFixedHeight && initialMinHeightRef.current) {
      contentHeight.value = initialMinHeightRef.current;
      return;
    }
    // we do not want to depend on contentHeight
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen, withMinFixedHeight]);

  useEffect(() => {
    if (isVisible) {
      return;
    }

    contentHeight.value = 0;
    initialMinHeightRef.current = 0;
  }, [contentHeight, isVisible]);

  return (
    <>
      {isVisible && <S.Overlay activeOpacity={backdropOpacity} opacity={backdropOpacity} onPress={onClose} />}
      <Animated.View style={animatedStyle}>
        <ShadowedContainer>
          <S.Container isFullscreen={isFullscreen}>
            {isVisible && (
              <>
                <S.Wrapper scrollEnabled={false}>
                  <S.ContentContainer
                    bounces={false}
                    scrollEnabled={withScroll}
                    maxHeight={maxHeight}
                    onLayout={handleLayout}
                  >
                    {children}
                  </S.ContentContainer>
                </S.Wrapper>
                {renderFooter && <Animated.View style={footerAnimatedStyles}>{renderFooter()}</Animated.View>}
              </>
            )}
          </S.Container>
        </ShadowedContainer>
      </Animated.View>
    </>
  );
};

const S = {
  Wrapper: styled.ScrollView`
    overflow: hidden;
  `,
  Container: styled.View<{ isFullscreen: boolean }>`
    position: relative;
    width: ${deviceWidth}px;
    height: ${deviceHeight}px;
    background-color: #fff;
    align-items: center;
    border-top-left-radius: ${({ isFullscreen }) => (isFullscreen ? 0 : '20px')};
    border-top-right-radius: ${({ isFullscreen }) => (isFullscreen ? 0 : '20px')};
    flex-direction: column;
    justify-content: flex-start;
  `,
  ContentContainer: styled.ScrollView<{ maxHeight?: number }>`
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '100%')};
    height: auto;
    min-height: 1px;
    width: ${deviceWidth}px;
  `,
  Overlay: styled.TouchableOpacity<{ opacity: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: ${deviceWidth}px;
    height: ${deviceHeight}px;
    z-index: 4;
    background-color: #000;
    opacity: ${({ opacity }) => opacity};
  `,
};

export default AnimatedBottomSheet;
