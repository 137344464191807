import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/native';
import FilterMenuIcon from '../../../../assets/icons/analytics/menu.svg';
import { setFilterBottomSheetOpen, setIsFilterTooltipShown } from '../../../../store/analytics/analytics.slice';
import { useDispatch, useSelector } from 'react-redux';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { sharedWithFilterSelector } from '../../../../store/attributes/attributes.selector';
import Tooltip from '../../../../components/Tooltip';
import { hasDistributionsSelector, isFilterTooltipShownSelector } from '../../../../store/analytics/analytics.selector';
import { useTranslation } from 'react-i18next';
import { isMobileStatsExplanationVideoWatchedSelector } from '../../../../store/appActivity/appActivity.selectors';
import { InteractionManager, StatusBar } from 'react-native';
import { IS_ANDROID } from '../../../../utils/constants/env';
import { filterMenuButtonDataSet } from '../../analytics.constants';

const HIT_SLOP = {
  top: 10,
  left: 10,
  bottom: 10,
  right: 10,
};

const FilterMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tooltipMethodsRef = useRef(null);

  const sharedWithFilter = useSelector(sharedWithFilterSelector);
  const isMobileStatsExplanationVideoWatched = useSelector(isMobileStatsExplanationVideoWatchedSelector);
  const hasDistributions = useSelector(hasDistributionsSelector);
  const isFilterTooltipShown = useSelector(isFilterTooltipShownSelector);

  useEffect(() => {
    if (
      !hasDistributions ||
      !tooltipMethodsRef.current ||
      isFilterTooltipShown ||
      !isMobileStatsExplanationVideoWatched
    ) {
      return;
    }

    dispatch(setIsFilterTooltipShown(true));
    InteractionManager.runAfterInteractions(() => tooltipMethodsRef.current.show());
  }, [dispatch, isFilterTooltipShown, hasDistributions, isMobileStatsExplanationVideoWatched]);

  const handleMenuOpen = useCallback(() => {
    tooltipMethodsRef.current?.hide();
    dispatch(setFilterBottomSheetOpen(true));
  }, [dispatch]);

  const topAdjustment = useMemo(() => {
    if (IS_ANDROID) {
      return -StatusBar.currentHeight;
    }
  }, []);

  const hasFilters = sharedWithFilter.length > 0;

  return (
    <S.Container>
      <Tooltip
        methodsRef={tooltipMethodsRef}
        children={
          <S.FilterMenuButton
            dataSet={filterMenuButtonDataSet}
            hitSlop={HIT_SLOP}
            hasFilters={hasFilters}
            onPress={handleMenuOpen}
          >
            <FilterMenuIcon />
            {hasFilters && <S.Dot />}
          </S.FilterMenuButton>
        }
        text={t('analytics.header.filterTooltip')}
        placement={'bottom'}
        backgroundColor='rgba(0,0,0,0.3)'
        topAdjustment={topAdjustment}
        showChildInTooltip
        withGradient
      />
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    position: relative;
  `,
  FilterMenuButton: styled.TouchableOpacity<{ hasFilters: boolean }>`
    width: ${calcWidth(32)}px;
    height: ${calcHeight(28)}px;
    background-color: ${({ theme, hasFilters }) => (hasFilters ? theme.colors.overviewBackground : theme.colors.white)};

    border-radius: 4px;
    align-items: center;
    justify-content: center;
  `,
  Dot: styled.View`
    position: absolute;
    top: -${calcWidth(3)}px;
    right: -${calcWidth(3)}px;
    width: ${calcWidth(7)}px;
    height: ${calcWidth(7)}px;
    border-radius: ${calcWidth(7)}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default memo(FilterMenu);
