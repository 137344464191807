import { useState } from 'react';

export default (lettersLimit: number, initialValue: string = '') => {
  const [value, setValue] = useState(initialValue);

  const safeSetValue = (incomingValue: string): void => {
    if (incomingValue.length <= lettersLimit) {
      setValue(incomingValue);
    }
  };

  return { value, safeSetValue, lettersLimit };
};
