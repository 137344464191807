import React, { memo, useCallback, useEffect, useState } from 'react';
import AttributesList from '../common/AttributesList/AttributesList';
import { useSelector } from 'react-redux';
import { getCommentsAttributes } from '../../../store/attributes/attributes.slice';
import HeaderTabs from '../common/HeaderTabs';
import useCommentsAttributesMap from '../hooks/useCommentsAttributesMap';
import useRedirectFromAttributes from '../hooks/useRedirectFromAttributes';
import { IStackNavigation } from '../../../navigation/types';
import { ECurrentList } from '../../../store/analytics/analytics.types';
import { currentListSelector, isNeedToScrollUpCommentsSelector } from '../../../store/analytics/analytics.selector';
import { EAnalyticsScreenTabs } from '../Analytics.types';
import GuestsAttribute from '../common/GuestsAttribute';
import {
  selectedFilterAttributeValueIdsSelector,
  sharedWithFilterSelector,
} from '../../../store/attributes/attributes.selector';
import { COMMENTS_ATTRIBUTES_SCREEN, COMMENTS_LIST_SCREEN } from '../constants';
import useHeaderTabsDivider from '../hooks/useHeaderTabsDivider';
import { ESharedWithFilter } from '../../../store/attributes/attributes.types';
import { attributesListDataSet } from '../analytics.constants';

const CommentsAttributes: React.FC<IStackNavigation> = ({ navigation }) => {
  const { attributesMap } = useCommentsAttributesMap();
  const selectedAttributes = useSelector(selectedFilterAttributeValueIdsSelector);
  const [isHideGuestsAttribute, setIsHideGuestsAttribute] = useState(false);
  const [isFocusedMode, setIsFocusedMode] = useState(false);

  const isNeedToScrollUp = useSelector(isNeedToScrollUpCommentsSelector);
  const currentList = useSelector(currentListSelector);
  const sharedWithFilter = useSelector(sharedWithFilterSelector);

  const { handleScroll, isShowHeaderBottomDivider } = useHeaderTabsDivider();

  useRedirectFromAttributes({
    attributesMap,
    listScreen: COMMENTS_LIST_SCREEN,
    navigation,
  });

  useEffect(() => {
    if (currentList === ECurrentList.USERS) {
      navigation.replace(COMMENTS_LIST_SCREEN);
    }
  }, [currentList, navigation]);

  const renderListHeaderComponent = useCallback(
    ({ isRefreshing }) => {
      if (
        selectedAttributes.length ||
        isHideGuestsAttribute ||
        (sharedWithFilter.length === 1 && sharedWithFilter.includes(ESharedWithFilter.COMMUNICATION_HUB))
      ) {
        return null;
      }

      return <GuestsAttribute currentTab={EAnalyticsScreenTabs.COMMENTS} isRefreshing={isRefreshing} />;
    },
    [isHideGuestsAttribute, selectedAttributes.length, sharedWithFilter],
  );

  return (
    <>
      <HeaderTabs
        currentScreen={COMMENTS_ATTRIBUTES_SCREEN}
        attributesScreen={COMMENTS_ATTRIBUTES_SCREEN}
        listScreen={COMMENTS_LIST_SCREEN}
        isShowBottomDivider={isShowHeaderBottomDivider && !isFocusedMode}
        isComments
      />
      <AttributesList
        dataSet={attributesListDataSet}
        scrollEventThrottle={10}
        onScroll={handleScroll}
        onSelectAttribute={setIsHideGuestsAttribute}
        onSingleAttributeChanged={setIsFocusedMode}
        renderListHeaderComponent={renderListHeaderComponent}
        isNeedToScrollUp={isNeedToScrollUp}
        listType={EAnalyticsScreenTabs.COMMENTS}
        attributes={attributesMap}
        onLoad={getCommentsAttributes}
      />
    </>
  );
};

export default memo(CommentsAttributes);
