import { RootState } from '../index';
import { EUploadStatus } from '../../types/bite';
import { IBiteSettings } from './createBite.types';
import { ICreateBiteState } from './createBite.reducer';

export const createBiteSelector = (state: RootState): ICreateBiteState => state.createBite;

export const biteSettingsSelector = (state: RootState): IBiteSettings => createBiteSelector(state).biteSettings;

export const selectBiteIntroMedia = (state: RootState) => createBiteSelector(state).introMedia;

export const taskIdSelector = (state: RootState) => createBiteSelector(state).taskId;

export const introMediaProcessingStatusSelector = (state: RootState) => {
  return createBiteSelector(state).introMediaProcessingStatus;
};

export const introMediaDurationSelector = (state: RootState) => {
  return createBiteSelector(state).introMediaDuration;
};

export const introEnhancementsSelector = (state: RootState) => createBiteSelector(state).introEnhancements;

export const introSubtitlesSelector = (state: RootState) => createBiteSelector(state).introSubtitles;

export const activeSubtitlesSelector = (state: RootState) => {
  const introSubtitles = introSubtitlesSelector(state);
  return introSubtitles && introSubtitles.length > 0 ? introSubtitles.find((sub) => sub.enabled) || null : null;
};

export const introEnhancementsChangesSelector = (state: RootState) => {
  return createBiteSelector(state)?.introEnhancementsChanges;
};

export const changedSubtitlesSelector = (state: RootState) => {
  const locale = createBiteSelector(state)?.introEnhancementsChanges.subtitlesEnabledState?.locale;
  if (locale === undefined) {
    return null;
  }

  const introSubtitles = introSubtitlesSelector(state);
  return introSubtitles && introSubtitles.length > 0
    ? introSubtitles.find((sub) => sub.locale === locale) || null
    : null;
};

export const defaultSubtitlesSelector = (state: RootState) => {
  const introSubtitles = introSubtitlesSelector(state);
  return introSubtitles && introSubtitles.length > 0
    ? introSubtitles.find(({ upload_status }) => upload_status === EUploadStatus.done) || null
    : null;
};

export const changedOrActiveSubtitlesSelector = (state: RootState) => {
  const changesSubtitles = changedSubtitlesSelector(state);
  const activeSubtitles = activeSubtitlesSelector(state);
  return changesSubtitles || activeSubtitles || null;
};

export const introSubtitlesLinesSelector = (state: RootState) => createBiteSelector(state).introSubtitlesLines;

export const coverKeywordSuggestionSelector = (state: RootState) => createBiteSelector(state).coverKeywordSuggestion;

export const questionSuggestionSelector = (state: RootState) => createBiteSelector(state).questionSuggestion;
export const fetchingIntroSubtitlesLinesSelector = (state: RootState) =>
  createBiteSelector(state).fetchingIntroSubtitlesLines;
