import BitesApi, { BASE_BACKEND_URL, tokenRef } from '../';
import { IBitePostBody } from '../../../createBite/createBite.types';
import { IFetchBiteOptions } from '../../../bite/bite.types';
import { IBiteItem, IEnhancement } from '../../../../types/bite';
import { TGetBitesFeed } from '../../../bitesFeed/bitesFeed.types';
import { ISelectContentLocale } from '../../../createBite/createBites.actions';
import axios from 'axios';

export const fetchBites: TGetBitesFeed = (orgId, options) =>
  BitesApi.get(
    `/bites/?pagination=true&organization=${orgId}${options?.search ? `&search=${options?.search}` : ''}${
      options?.isWithoutEmptyBites ? `&without_empty_bites=${options?.isWithoutEmptyBites}` : ''
    }${options?.page ? `&page=${options?.page}` : ''}${options?.pageSize ? `&page_size=${options?.pageSize}` : ''}`,
  );

export const fetchBite = (biteId: number, { extend }: IFetchBiteOptions = {}) =>
  BitesApi.get(`/bites/${biteId}/${extend ? `?extend=${extend.join(',')}` : ''}`);

export const resetBiteCover = (biteId: number) =>
  BitesApi.patch(`/bites/${biteId}/`, { cover: null, cover_url: null, linked_cover_url: null });

export const postAndUpdateBite = (bite: IBitePostBody) => BitesApi.post('/create_or_update_bite/', bite);

export const updateBiteData = (biteId: number, body: Partial<IBitePostBody>) =>
  BitesApi.patch(`/bites/${biteId}/`, body);

export const deleteBite = (biteId: number) => BitesApi.delete(`/bites/${biteId}/`);

export const getUploadedVideoByTaskId = (taskId: string) => BitesApi.get(`/task_result/?task_id=${taskId}`);

export const getIntroMediaEnhancementsByTaskId = (taskId: string) => BitesApi.get(`/media/?task_id=${taskId}`);

export const getIntroMediaSubtitlesByTaskId = (taskId: string) => BitesApi.get(`/subtitles/?task_id=${taskId}`);

export const defineSubtitlesLocale = (taskId: string) =>
  axios.post(`${BASE_BACKEND_URL}/api/common_services/content/subtitles/define_locale`, {
    taskId,
  });

export const getSubtitlesLines = (subtitlesId: number) => BitesApi.get(`/subtitles/${subtitlesId}/get_lines/`);

export const updateSubtitlesLines = ({ taskId, locale, vtt }) =>
  BitesApi.put('/common_services/content/subtitles/vtt', { taskId, locale, vtt });

export const updateSubtitlesState = ({
  subtitlesId,
  enabled,
  approved,
}: {
  subtitlesId: number;
  enabled: boolean;
  approved?: boolean;
}) => BitesApi.patch(`/subtitles/${subtitlesId}/`, { enabled, approved });

export const generateSubtitles = ({ taskId, locale, sourceLocale }: ISelectContentLocale) =>
  BitesApi.post(`/subtitles/translate/?task_id=${taskId}&locale=${locale}&source_locale=${sourceLocale}`);

export const saveMediaChanges = (mediaId: number, payload: Partial<IEnhancement>) =>
  BitesApi.patch(`/media/${mediaId}/`, payload);

export const createBite = (body: Pick<IBiteItem, 'organization'> & Partial<IBiteItem>) => {
  return BitesApi.post('/bites/', body);
};

export const editBiteIntro = (biteId: number, body) => BitesApi.post(`/bites/${biteId}/intro/`, body);

export const deleteBiteIntro = (biteId: number) => BitesApi.delete(`/bites/${biteId}/intro/`);

export const editBiteQuestion = (biteId: number, body) => BitesApi.post(`/bites/${biteId}/question/`, body);

export const deleteBiteQuestion = (biteId: number) => BitesApi.delete(`/bites/${biteId}/question/`);

export const editBiteSummary = (biteId: number, body) => BitesApi.post(`/bites/${biteId}/summary/`, body);

export const deleteBiteSummary = (biteId: number) => BitesApi.delete(`/bites/${biteId}/summary/`);

export const getSummarySuggestion = (taskId: string) => {
  return BitesApi.get(`/suggestions/summary/?task_id=${taskId}`);
};

export const getQuestionSuggestion = (taskId: string) => {
  return BitesApi.get(`/suggestions/question/?task_id=${taskId}`);
};

export const reindexBites = ({ biteIds }: { biteIds: number[] }) =>
  axios.post(
    `${BASE_BACKEND_URL}/api/common_services/knowledge/bites/index`,
    { biteIds },
    {
      headers: {
        Authorization: `Bearer ${tokenRef.current}`,
      },
    },
  );

export const transactionalBiteCreated = ({ biteId }: { biteId: number }) =>
  BitesApi.post('/common_services/transactional/bite/created', { biteId });
