import { ITimelineItem, ITimelineItemsPositionsMap, RetryFunctionsMap } from './videoEditor.types';

export const videoEditorData = {
  // includes groups and regular items only on the root timeline
  rootTimelineItemsPositionsMap: {} as ITimelineItemsPositionsMap,
  // includes only regular items on the root timeline and all the nested timelines
  timelineItemsPositionsMap: {} as ITimelineItemsPositionsMap,
  rootStartTimelineItemsPositionsMap: {} as ITimelineItemsPositionsMap,
  rootEndTimelineItemsPositionsMap: {} as ITimelineItemsPositionsMap,
  startPlayingTs: null as number | null,
};
// @ts-ignore
window.videoEditorData = videoEditorData;

export const POSITIONS_MAP_STEP = 1;

export const retryFunctions: RetryFunctionsMap = {};
// @ts-ignore
window.retryFunctions = retryFunctions;

// each type should appear only in one group
export const timelineLayerAcceptGroups: ITimelineItem['type'][][] = [
  ['video', 'image'],
  ['text'],
  ['gif'],
  ['shape'],
  ['group'],
  ['audio'],
];
// derive from timelineLaylerAcceptGroups
export const timelineLayerAcceptByType = timelineLayerAcceptGroups.reduce((acc, group) => {
  group.forEach((type) => {
    acc[type] = group;
  });
  return acc;
}, {} as Record<ITimelineItem['type'], ITimelineItem['type'][]>);
