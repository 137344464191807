export const youtubeUrlParser = (url: string) => {
  const shortVideoId = extractYouTubeShortsId(url);
  if (shortVideoId) {
    return shortVideoId;
  }

  const videoId = extractYouTubeVideoId(url);
  if (videoId) {
    return videoId;
  }

  return null;
};

function extractYouTubeShortsId(url: string) {
  // Define the regular expression pattern to match YouTube Shorts URLs
  const pattern = /https:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;

  // Test if the URL matches the pattern and extract the video ID
  const match = url.match(pattern);

  // Return the extracted video ID if a match is found, otherwise return null
  return match ? match[1] : null;
}

const extractYouTubeVideoId = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
};
