import React from 'react';
import styled from 'styled-components/native';
import { ViewStyle } from 'react-native';

interface IProps {
  style: ViewStyle;
  colors: string[];
  children: React.ReactNode;
}

const LinearGradient: React.FC<IProps> = ({ style, children, colors }) => {
  return (
    <S.LinearGradient style={style} colors={colors}>
      {children}
    </S.LinearGradient>
  );
};

const S = {
  LinearGradient: styled.View<{ colors: string[] }>`
    background-image: linear-gradient(to bottom, ${({ colors }) => colors.join(',')});
  `,
};

export default LinearGradient;
