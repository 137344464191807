import React from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import CloseIcon from '../../../../assets/icons/close.svg';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { StyleProp, ViewProps } from 'react-native';
import Modal from '../../../../components/modals/ModalController';

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  onModalHide?: () => void;
  children: React.ReactNode;
}

const BaseModal: React.FC<IProps> = (props) => {
  const { isVisible, onClose, onModalHide, title, description, children } = props;

  const theme = useTheme();

  return (
    <Modal isVisible={isVisible} backdropOpacity={0.4} onModalHide={onModalHide}>
      <S.Container>
        <ShadowedContainer containerViewStyle={S.ShadowedContainerViewStyle}>
          <S.CloseButton onPress={onClose}>
            <CloseIcon fill={theme.colors.primaryBlue} />
          </S.CloseButton>
        </ShadowedContainer>
        {!!title && <S.Title>{title}</S.Title>}
        {!!description && <S.Description>{description}</S.Description>}
        <S.ButtonsContainer>{children}</S.ButtonsContainer>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    align-self: center;
    align-items: center;
    width: ${calcWidth(355)}px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    padding: ${calcHeight(55)}px ${calcWidth(35)}px 0;
  `,
  Title: styled.Text`
    max-width: ${calcWidth(250)}px;
    font-size: ${({ theme }) => theme.fontSizes.s18};
    line-height: ${({ theme }) => theme.fontSizes.s18 * 1.39};
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  Description: styled.Text`
    margin-top: ${calcHeight(12)}px;
    font-size: ${({ theme }) => theme.fontSizes.s15};
    line-height: ${calcHeight(21)}px;
    color: ${({ theme }) => theme.colors.gray11};
    text-align: center;
  `,
  CloseButton: styled.TouchableOpacity`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
    border-radius: ${calcWidth(40 / 2)}px;
    padding: ${calcWidth(10)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  ButtonsContainer: styled.View`
    width: 100%;
    margin-top: ${calcHeight(10)}px;
    align-items: center;
  `,
  ShadowedContainerViewStyle: {
    position: 'absolute',
    top: calcHeight(12),
    right: calcWidth(12),
  } as StyleProp<ViewProps>,
};

export default BaseModal;
