import React, { useCallback, useMemo } from 'react';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import VideoTypeIcon from '../../../assets/icons/videoEditor/leftSidebar/video-type.svg';
import VideoTypeSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/video-type-selected.svg';
import ImageTypeIcon from '../../../assets/icons/videoEditor/leftSidebar/image-type.svg';
import ImageTypeSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/image-type-selected.svg';
import AudioTypeIcon from '../../../assets/icons/videoEditor/leftSidebar/audio-type.svg';
import AudioTypeSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/audio-type-selected.svg';
import GifTypeIcon from '../../../assets/icons/videoEditor/leftSidebar/gif-type.svg';
import GifTypeSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/gif-type-selected.svg';
import StickerTypeIcon from '../../../assets/icons/videoEditor/leftSidebar/sticker-type.svg';
import StickerTypeSelectedIcon from '../../../assets/icons/videoEditor/leftSidebar/sticker-type-selected.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  cloudFileTypeSelector,
  stockFileTypeSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setFileType } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';

type Types = 'all' | 'sticker' | ICloudAsset['fileType'];

export const FileTypeSelector = ({ fileTypes }: { fileTypes: Types[] }) => {
  const dispatch = useDispatch();
  const fileType = useSelector(cloudFileTypeSelector);
  const stockFileType = useSelector(stockFileTypeSelector);

  const fileTypesMap = useMemo(
    () =>
      fileTypes.reduce((acc, type) => {
        acc[type] = true;
        return acc;
      }, {} as Record<Types, boolean>),
    [fileTypes],
  );

  const setFileTypeAll = useCallback(() => {
    dispatch(setFileType(null));
  }, [dispatch]);

  const setFileTypeVideo = useCallback(() => {
    dispatch(setFileType('video'));
  }, [dispatch]);

  const setFileTypeImage = useCallback(() => {
    dispatch(setFileType('image'));
  }, [dispatch]);

  const setFileTypeGif = useCallback(() => {
    dispatch(setFileType('gif'));
  }, [dispatch]);

  const setFileTypeSticker = useCallback(() => {
    dispatch(setFileType('sticker'));
  }, [dispatch]);

  const setFileTypeAudio = useCallback(() => {
    dispatch(setFileType('audio'));
  }, [dispatch]);

  return (
    <S.Tabs>
      {fileTypesMap.all && (
        <S.Tab isActive={fileType === null} onClick={setFileTypeAll}>
          <S.TabText isActive={fileType === null}>All</S.TabText>
        </S.Tab>
      )}
      {fileTypesMap.video && (
        <S.Tab isActive={fileType === 'video'} onClick={setFileTypeVideo}>
          {fileType === 'video' ? <VideoTypeSelectedIcon /> : <VideoTypeIcon />}
        </S.Tab>
      )}
      {fileTypesMap.image && (
        <S.Tab isActive={fileType === 'image'} onClick={setFileTypeImage}>
          {fileType === 'image' ? <ImageTypeSelectedIcon /> : <ImageTypeIcon />}
        </S.Tab>
      )}
      {fileTypesMap.gif && (
        <S.Tab isActive={!fileTypesMap.sticker && fileType === 'gif'} onClick={setFileTypeGif}>
          {stockFileType === 'gif' ? (
            !fileTypesMap.sticker ? (
              <StickerTypeSelectedIcon />
            ) : (
              <GifTypeSelectedIcon />
            )
          ) : !fileTypesMap.sticker ? (
            <StickerTypeIcon />
          ) : (
            <GifTypeIcon />
          )}
        </S.Tab>
      )}
      {fileTypesMap.sticker && (
        <S.Tab isActive={stockFileType === 'sticker'} onClick={setFileTypeSticker}>
          {stockFileType === 'sticker' ? <StickerTypeSelectedIcon /> : <StickerTypeIcon />}
        </S.Tab>
      )}
      {fileTypesMap.audio && (
        <S.Tab isActive={fileType === 'audio'} onClick={setFileTypeAudio}>
          {fileType === 'audio' ? <AudioTypeSelectedIcon /> : <AudioTypeIcon />}
        </S.Tab>
      )}
    </S.Tabs>
  );
};

export const S = {
  Tabs: styled.div`
    display: flex;
    gap: 4px;
    flex-shrink: 0;
    margin: 0 12px 10px;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Tab: styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    background-color: ${({ isActive }) => (isActive ? defaultTheme.colors.lightGray44 : 'transparent')};
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: ${defaultTheme.colors.lightGray44};
    }
  `,
  TabText: styled.span<{ isActive: boolean }>`
    color: ${({ isActive }) => (isActive ? defaultTheme.colors.blue6 : defaultTheme.colors.lightGray40)};
    font-size: 13px;
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
};
