import React, { useCallback } from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { useTranslation } from 'react-i18next';
import { ViewStyle } from 'react-native';
import Intercom from '../../../services/intercom/intercom';
import QuestionIcon from '../../../assets/icons/creationFlow/question-icon.svg';

interface IProps {
  style?: ViewStyle;
  onReadMorePress: () => void;
}

const SupportBlock: React.FC<IProps> = ({ style, onReadMorePress }) => {
  const { t } = useTranslation();

  const handleReadMore = useCallback(() => {
    onReadMorePress();
  }, [onReadMorePress]);

  const handleContact = useCallback(() => {
    Intercom.showNewMessage(t('googleSlides.intercom'));
  }, [t]);

  return (
    <S.Container style={style}>
      <QuestionIcon width={calcWidth(39)} height={calcHeight(39)} />
      <S.SupportTextContainer>
        <S.SupportContainerText>{t('support.title')}</S.SupportContainerText>
        <S.SupportContainerText>
          <S.LineButton onPress={handleReadMore}>
            <S.BlueText>{t('support.readMore')}</S.BlueText>
          </S.LineButton>{' '}
          {t('support.or')}{' '}
          <S.LineButton onPress={handleContact}>
            <S.BlueText>{t('support.contact')}</S.BlueText>
          </S.LineButton>
        </S.SupportContainerText>
      </S.SupportTextContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    width: ${calcWidth(310)}px;
    height: ${calcHeight(100)}px;
    align-items: flex-start;
    flex-direction: row;
  `,
  SupportTextContainer: styled.View`
    margin-left: ${calcWidth(10)}px;
  `,
  SupportContainerText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.text};
  `,
  LineButton: styled.TouchableOpacity`
    height: ${calcHeight(19)}px;
  `,
  BlueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    font-size: ${({ theme }) => theme.fontSizes.s16}px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    line-height: ${calcHeight(25)}px;
  `,
};

export default SupportBlock;
