import last from 'lodash/last';
import RNFetchBlob from 'rn-fetch-blob';
import getPlatformUri from '../getPlatformUri';
import { DEFAULT_MULTI_UPLOAD_PART_SIZE } from '../constants/common';
import withRetry, { IWithRetryProps } from '../withRetry';
import { log, logError } from '../../store/appActivity/appActivity.slice';
import store from '../../store';

export const formatFileFormFieldForNative = (
  fileName: string,
  mediaType: string,
  uri: string,
): {
  name: string;
  filename: string;
  type: string;
} => ({
  name: 'file',
  filename: fileName,
  type: `${mediaType}/${last(uri.split('.'))}`,
});

export const formatSinglePartUploadVideoForNativeFields = ({ uri, fields, fileName, mediaType }) => [
  ...Object.entries(fields).map(([fieldName, fieldData]) => ({
    name: fieldName,
    data: fieldData,
  })),
  {
    ...formatFileFormFieldForNative(fileName, mediaType, uri),
    data: RNFetchBlob.wrap(getPlatformUri(uri)),
  },
];

export const getS3ErrorCode = (response) => {
  if (typeof response !== 'string') {
    return null;
  }

  const r = response.match(/<Code>(.+)<\/Code>/);
  return r?.length > 1 ? r[1] : null;
};

export const buildPartsNumArray = (partsNum: number) => {
  return Array.from({ length: partsNum }, (_, index) => index + 1);
};

export const calculatePartsNum = (fileSize: number, optionalPartSize?: number) => {
  const partSize = optionalPartSize || DEFAULT_MULTI_UPLOAD_PART_SIZE;
  return Math.ceil(fileSize / partSize);
};

export const getFileParts = ({
  processId,
  uri,
  partSize = DEFAULT_MULTI_UPLOAD_PART_SIZE,
}: {
  processId: string;
  uri: string;
  partSize: number;
}): Promise<string[]> => {
  return withRetry(async ({ attempt, isLastAttempt, setErrorContext }: IWithRetryProps) => {
    const fileUri = getPlatformUri(uri);

    const logContext = {
      processId,
      data: {
        attempt,
        isLastAttempt,
        uri,
        fileUri,
        partSize,
      },
    };

    setErrorContext({
      ...logContext,
      action: 'getFilePartsData',
      processId,
    });

    store.dispatch(
      log({
        ...logContext,
        event: 'getFilePartsData: start',
      }),
    );

    return new Promise((resolve, reject) => {
      RNFetchBlob.fs.readStream(fileUri, 'base64', partSize).then((stream) => {
        const data: string[] = [];

        stream.open();
        stream.onData((b) => {
          data.push(<string>b);
        });

        stream.onError((error) => {
          store.dispatch(
            logError({
              ...logContext,
              event: 'getFilePartsData: stream.onError',
              data: {
                ...logContext.data,
                error,
              },
            }),
          );

          reject(error);
        });

        stream.onEnd(() => {
          store.dispatch(
            log({
              ...logContext,
              event: 'getFilePartsData: stream end',
            }),
          );

          resolve(data);
        });
      });
    });
  });
};
