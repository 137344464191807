import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnswersNum,
  getCommentsNum,
  getStatsOverview,
  getViewsNum,
} from '../../../store/analyticsOverview/analyticsOverview.slice';
import {
  viewsNumberSelector,
  answersNumberSelector,
  commentsNumberSelector,
} from '../../../store/analyticsOverview/analyticsOverview.selector';
import styled, { css, useTheme } from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { Divider } from '../../../components/shared';
import ArrowDown from '../../../assets/icons/feed/arrow-down.svg';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import Routes from '../../../navigation/routes';
import { EAnalyticsScreenTabs } from '../../analytics/Analytics.types';
import Skeleton from '../../../components/shared/Skeleton';
import { useTranslation } from 'react-i18next';
import Error from './Error';
import useAnalyticsTabs from '../../analytics/hooks/useAnalyticsTabs';
import {
  organizationAttributesErrorSelector,
  organizationAttributesLoadingSelector,
} from '../../../store/attributes/attributes.selector';
import { biteIdSelector, hasDistributionsSelector } from '../../../store/analytics/analytics.selector';
import { biteItemSelector, bitesLoadingStateMapSelector } from '../../../store/bite/bite.selectors';
import { log } from '../../../store/appActivity/appActivity.slice';
import { getIsRtl, getIsWebRtl } from '../../../locale/i18n';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  answersDataSet,
  commentsDataSet,
  numbersDataSet,
  overviewDataSet,
  overviewDataSetDisabled,
  viewsDataSet,
} from '../bite.constants';

interface IProps {
  isRefreshing: boolean;
}

const StatsOverview: React.FC<IProps> = ({ isRefreshing }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const isFocused = useIsFocused();

  const organizationAttributesError = useSelector(organizationAttributesErrorSelector);

  const isAttributesLoading = useSelector(organizationAttributesLoadingSelector);
  const hasDistributions = useSelector(hasDistributionsSelector);

  const isRtl = getIsRtl();
  const isWebRtl = getIsWebRtl();
  const biteId = useSelector(biteIdSelector);
  const bite = useSelector(biteItemSelector(biteId));
  const { isLoading: biteIsLoading, error: biteError } = useSelector(bitesLoadingStateMapSelector(biteId));

  const {
    data: viewsNumber,
    error: viewsNumberError,
    isLoading: viewsNumberIsLoading,
    isRequestInProgress: viewsNumberIsRequestInProgress,
  } = useSelector(viewsNumberSelector);
  const {
    data: answersNumber,
    error: answersNumberError,
    isLoading: answersNumberIsLoading,
    isRequestInProgress: answersNumberIsRequestInProgress,
  } = useSelector(answersNumberSelector);
  const {
    data: commentsNumber,
    error: commentsNumberError,
    isLoading: commentsNumberIsLoading,
    isRequestInProgress: commentsNumberIsRequestInProgress,
  } = useSelector(commentsNumberSelector);

  const { isViewsTabEnabled, isAnsweredTabEnabled, isCommentsTabEnabled } = useAnalyticsTabs();

  const isError =
    (!biteIsLoading && biteError) ||
    viewsNumberError ||
    answersNumberError ||
    commentsNumberError ||
    organizationAttributesError;

  const isCanProceedToDetails = bite?.bite_sections && !isAttributesLoading && hasDistributions !== null;

  const handleOverviewPress = useCallback(() => {
    if (!isCanProceedToDetails) {
      return;
    }

    dispatch(
      log({
        event: 'StatsOverview.handleOverviewPress',
        data: { isCanProceedToOverview: isCanProceedToDetails },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.Stats, { initialRouteName: EAnalyticsScreenTabs.OVERVIEW });
  }, [dispatch, isCanProceedToDetails, navigation]);

  const handleViewsPress = useCallback(() => {
    if (!isCanProceedToDetails) {
      return;
    }

    dispatch(
      log({
        event: 'StatsOverview.handleViewsPress',
        data: { isAttributesLoading, bite_sections: bite?.bite_sections },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.Stats, { initialRouteName: EAnalyticsScreenTabs.VIEWS });
  }, [bite?.bite_sections, dispatch, isAttributesLoading, isCanProceedToDetails, navigation]);

  const handleAnsweredPress = useCallback(() => {
    if (!isCanProceedToDetails) {
      return;
    }

    dispatch(
      log({
        event: 'StatsOverview.handleAnsweredPress',
        data: { isAttributesLoading, bite_sections: bite?.bite_sections },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.Stats, { initialRouteName: EAnalyticsScreenTabs.ANSWERS });
  }, [bite?.bite_sections, dispatch, isAttributesLoading, isCanProceedToDetails, navigation]);

  const handleCommentsPress = useCallback(() => {
    if (!isCanProceedToDetails) {
      return;
    }

    dispatch(
      log({
        event: 'StatsOverview.handleCommentsPress',
        data: { isAttributesLoading, bite_sections: bite?.bite_sections },
      }),
    );

    navigation.navigate(Routes.AnalyticsStack.Stats, { initialRouteName: EAnalyticsScreenTabs.COMMENTS });
  }, [bite?.bite_sections, dispatch, isAttributesLoading, isCanProceedToDetails, navigation]);

  const handleRefresh = useCallback(() => {
    dispatch(
      log({
        event: 'StatsOverview.handleRefresh',
      }),
    );

    dispatch(getStatsOverview());
  }, [dispatch]);

  useEffect(() => {
    if (!isFocused || biteIsLoading || isRefreshing) {
      return;
    }

    if (isViewsTabEnabled && !viewsNumberIsRequestInProgress && viewsNumber === null) {
      dispatch(getViewsNum());
    }

    if (isAnsweredTabEnabled && !answersNumberIsRequestInProgress && answersNumber === null) {
      dispatch(getAnswersNum());
    }

    if (isCommentsTabEnabled && !commentsNumberIsRequestInProgress && commentsNumber === null) {
      dispatch(getCommentsNum());
    }
  }, [
    answersNumber,
    answersNumberIsRequestInProgress,
    biteIsLoading,
    commentsNumber,
    commentsNumberIsRequestInProgress,
    dispatch,
    isAnsweredTabEnabled,
    isCommentsTabEnabled,
    isFocused,
    isRefreshing,
    isViewsTabEnabled,
    viewsNumber,
    viewsNumberIsRequestInProgress,
  ]);

  return (
    <S.Container>
      {isError ? (
        <Error onRefresh={handleRefresh} />
      ) : (
        <S.Content>
          <S.TouchableOpacity
            // @ts-ignore
            dataSet={isCanProceedToDetails ? overviewDataSet : overviewDataSetDisabled}
            isRtl={isWebRtl}
            onPress={handleOverviewPress}
          >
            <S.Title>{t('biteScreen.biteStatsOverview.title')}</S.Title>
            {isCanProceedToDetails && (
              <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>
                <ArrowDown color={theme.colors.primaryBlue} />
              </S.ArrowContainer>
            )}
          </S.TouchableOpacity>
          <S.Divider />
          {((biteIsLoading && !bite.bite_sections) || isViewsTabEnabled) && (
            <S.TouchableOpacity
              // @ts-ignore
              dataSet={viewsDataSet}
              isRtl={isWebRtl}
              onPress={handleViewsPress}
            >
              <S.Text>{t('biteScreen.biteStatsOverview.totalViews')}</S.Text>
              {((isAttributesLoading || viewsNumberIsLoading) && !isRefreshing) ||
              hasDistributions === null ||
              viewsNumber === null ? (
                <Skeleton width={40} height={20} borderRadius={4} />
              ) : (
                <>
                  <S.NumberText
                    // @ts-ignore
                    dataSet={numbersDataSet}
                  >
                    {viewsNumber}
                  </S.NumberText>
                  {isCanProceedToDetails && (
                    <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>
                      <ArrowDown color={theme.colors.primaryBlue} />
                    </S.ArrowContainer>
                  )}
                </>
              )}
            </S.TouchableOpacity>
          )}
          {((biteIsLoading && !bite.bite_sections) || isAnsweredTabEnabled) && (
            <>
              <S.Divider />
              <S.TouchableOpacity
                // @ts-ignore
                dataSet={answersDataSet}
                isRtl={isWebRtl}
                onPress={handleAnsweredPress}
              >
                <S.Text>{t('biteScreen.biteStatsOverview.answered')}</S.Text>

                {((isAttributesLoading || answersNumberIsLoading) && !isRefreshing) ||
                hasDistributions === null ||
                answersNumber === null ? (
                  <Skeleton width={40} height={20} borderRadius={4} />
                ) : (
                  <>
                    <S.NumberText
                      // @ts-ignore
                      dataSet={numbersDataSet}
                    >
                      {answersNumber}
                    </S.NumberText>
                    {isCanProceedToDetails && (
                      <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>
                        <ArrowDown color={theme.colors.primaryBlue} />
                      </S.ArrowContainer>
                    )}
                  </>
                )}
              </S.TouchableOpacity>
            </>
          )}
          {((biteIsLoading && !bite.bite_sections) || isCommentsTabEnabled) && (
            <>
              <S.Divider />
              <S.TouchableOpacity
                // @ts-ignore
                dataSet={commentsDataSet}
                isRtl={isWebRtl}
                onPress={handleCommentsPress}
              >
                <S.Text>{t('biteScreen.biteStatsOverview.comments')}</S.Text>
                {((isAttributesLoading || commentsNumberIsLoading) && !isRefreshing) ||
                hasDistributions === null ||
                commentsNumber === null ? (
                  <Skeleton width={40} height={20} borderRadius={4} />
                ) : (
                  <>
                    <S.NumberText
                      // @ts-ignore
                      dataSet={numbersDataSet}
                    >
                      {commentsNumber}
                    </S.NumberText>
                    {isCanProceedToDetails && (
                      <S.ArrowContainer isWebRtl={isWebRtl} isRtl={isRtl}>
                        <ArrowDown color={theme.colors.primaryBlue} />
                      </S.ArrowContainer>
                    )}
                  </>
                )}
              </S.TouchableOpacity>
            </>
          )}
        </S.Content>
      )}
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    margin-top: ${calcHeight(17)}px;
    width: ${calcWidth(351)}px;
    background: ${({ theme }) => theme.colors.overviewBackground};
    border-radius: 20px;
  `,
  Content: styled.View`
    width: 100%;
    padding: ${calcHeight(25)}px ${calcWidth(15)}px;
  `,
  Title: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s15};
    font-weight: 700;
  `,
  Text: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-style: normal;
    font-weight: 400;
  `,
  NumberText: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    font-style: normal;
    font-weight: 400;
    margin: 0 ${calcWidth(25)}px;
  `,
  TouchableOpacity: styled.TouchableOpacity<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    height: ${calcHeight(48)}px;
  `,
  Divider: styled(Divider)`
    background-color: ${({ theme }) => theme.colors.overviewDivider};
  `,
  ArrowContainer: styled.View<{ isWebRtl: boolean; isRtl: boolean }>`
    position: absolute;
    width: ${calcWidth(24)}px;
    height: ${calcHeight(24)}px;

    transform: ${({ isRtl }) => (isRtl ? 'rotate(90deg)' : 'rotate(-90deg)')};
    ${({ isWebRtl }) =>
      isWebRtl
        ? css`
            left: ${calcWidth(0)}px;
          `
        : css`
            right: ${calcWidth(0)}px;
          `};
  `,
  RefreshButton: styled.TouchableOpacity`
    margin-top: ${calcHeight(10)}px;
  `,
  RefreshButtonText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: ${({ theme }) => theme.fontSizes.s14};
    text-align: center;
    font-weight: 400;
  `,
};

export default memo(StatsOverview);
