import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { useSelector } from 'react-redux';
import CreationAnimation from '../../../components/shared/CreationAnimation';

import { calcHeight, calcWidth, isWeb } from '../../../utils/dimensions';

import { introMediaDurationSelector } from '../../../store/createBite/createBite.selectors';

import { useTranslation } from 'react-i18next';

import WarningIcon from '../../../assets/icons/triangle-exclamation.svg';
import { useTheme } from 'styled-components/native';
import LoadingBar from '../../creationBite/AIExpectation/common/LoadingBar';
interface IProps {
  initialProgress?: number;
  isVisible?: boolean;
}

const VideoUploadingOverlay: FC<IProps> = ({ initialProgress = 1, isVisible }) => {
  const { t } = useTranslation();

  const introMediaDuration = useSelector(introMediaDurationSelector) || 4 * 60;

  const progressBarTiming = introMediaDuration ? (Number(introMediaDuration) * 1000) / 100 : null;

  const [loadingCount, setLoadingCount] = useState(initialProgress);
  const theme = useTheme();

  useEffect(() => {
    if (!progressBarTiming) {
      return;
    }

    const interval = setInterval(() => {
      if (loadingCount < 100) {
        setLoadingCount(
          loadingCount < 80
            ? loadingCount + 1
            : loadingCount < 90
            ? loadingCount + 1 / (loadingCount / 4)
            : loadingCount + 1 / (loadingCount / 2),
        );
        return;
      }
      setLoadingCount(0);
    }, progressBarTiming);

    return () => clearInterval(interval);
  }, [progressBarTiming, loadingCount]);

  if (!isVisible) {
    return null;
  }

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <CreationAnimation />
        </S.AnimationContainer>
        <S.LoadingTitle>{t('createQuizStack.uploadingVideoInProgress')}</S.LoadingTitle>
        <S.LoadingBarContainer>
          <LoadingBar
            current={loadingCount}
            total={100}
            width={isWeb ? calcWidth(206) : calcWidth(106)}
            duration={progressBarTiming}
          />
        </S.LoadingBarContainer>
        {!isWeb && (
          <>
            <S.LoadingInfoWrapper>
              <WarningIcon style={S.WarningIconStyles} fill={theme.colors.yellowWarning} />
              <S.LoadingInfo>{t('aiExpectations.loadingInfo')}</S.LoadingInfo>
            </S.LoadingInfoWrapper>
          </>
        )}
      </S.Content>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.white};
    z-index: 12;
  `,
  Content: styled.View`
    flex: 6;
    align-items: center;
    justify-content: center;
  `,

  LoadingTitle: styled.Text`
    color: ${({ theme }) => theme.colors.text};
    margin-top: ${calcHeight(29)}px;
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
    text-align: center;
  `,
  LoadingBarContainer: styled.View`
    margin-top: ${calcHeight(21)}px;
  `,
  AnimationContainer: styled.View`
    margin-left: ${isWeb ? calcWidth(-355) : calcWidth(-135)}px;
  `,
  LoadingInfo: styled.Text`
    max-width: ${calcWidth(260)}px;
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s18}px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray4};
    margin-left: ${calcWidth(12)}px;
  `,
  LoadingInfoWrapper: styled.View`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: ${calcHeight(70)}px;
    border: 1px solid ${({ theme }) => theme.colors.gray4};
    border-radius: 10px;
    padding: ${calcWidth(12)}px;
  `,
  WarningIconStyles: {
    width: calcWidth(22),
    height: calcHeight(22),
  },
};

export default memo(VideoUploadingOverlay);
