import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/native';
import GenericModal from '../../../../../../components/modals/GenericModal/GenericModal';
import { IUserOrganizations } from '../../../../../../store/auth/auth.types';
import { updateUserOrganizationName } from '../../../../../../store/org/org.slice';
import {
  organizationRenameStatusSelector,
  organizationFormStatusSelector,
} from '../../../../../../store/org/org.selectors';
import { calcHeight, calcWidth, isWeb } from '../../../../../../utils/dimensions';
import { getErrorMessage } from '../../../../../../utils/general';

import RenameIcon from '../../../../../../assets/icons/rename.svg';
import FormTextInput from '../../../../../../components/shared/inputs/FormTextInput/FormTextInput';

interface IProps {
  organization: IUserOrganizations;
  onClose: () => void;
}

const OrganizationForm: React.FC<IProps> = (props) => {
  const { organization, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isVisible = useSelector(organizationFormStatusSelector);
  const { isLoading, error } = useSelector(organizationRenameStatusSelector);
  const [organizationName, setOrganizationName] = useState<string>('');

  useEffect(() => {
    setOrganizationName(isVisible ? organization.name : '');
  }, [isVisible, organization]);

  const confirmButton = {
    content: t('common.ok'),
    disabled: isLoading,
    onPress: () => {
      if (isLoading) {
        return;
      }
      dispatch(updateUserOrganizationName({ id: organization.id, name: organizationName }));
    },
  };

  const cancelButton = {
    content: t('common.discard'),
    disabled: isLoading,
    onPress: () => {
      onClose();
    },
  };

  return (
    <GenericModal isVisible={isVisible} confirmButton={confirmButton} cancelButton={cancelButton}>
      <RenameIcon
        style={{
          width: calcWidth(64),
          height: calcWidth(64),
          marginVertical: calcHeight(24),
          alignSelf: 'center',
        }}
      />
      <S.Title>{t('organizationForm.title')}</S.Title>
      <S.Input
        value={organizationName}
        autoCorrect={false}
        editable={!isLoading}
        placeholder={t('organizationForm.title')}
        onChangeText={setOrganizationName}
      />
      {error ? <S.Error>{getErrorMessage(error)}</S.Error> : null}
    </GenericModal>
  );
};

const Title = styled.Text(
  ({ theme }) => css`
    color: ${theme.colors.gray9};
    font-size: ${theme.fontSizes.s17};
    text-align: center;
  `,
);

const Input = styled(FormTextInput)<{ editable: boolean }>`
  margin: ${calcHeight(18)}px auto;
  ${isWeb
    ? css`
        margin: ${calcHeight(18)}px;
      `
    : null}
`;

const Error = styled.Text(
  ({ theme }) => css`
    color: ${theme.colors.failRed};
    font-size: ${theme.fontSizes.s13};
    text-align: center;
    margin-bottom: ${calcHeight(18)}px;
  `,
);

const S = {
  Title,
  Input,
  Error,
};

export default OrganizationForm;
