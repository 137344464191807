import BitesApi, { BASE_BACKEND_URL } from '../index';
import { TFetchCreators } from '../../../org/org.types';
import axios from 'axios';
import { Platform } from 'react-native';

export const fetchOrganization = (organizationId: number) => BitesApi.get(`organizations/${organizationId}/`);

export const fetchOrganizationCode = (queryParams: string) => BitesApi.get(`organization_code/?${queryParams}`);

export const fetchOrganizationAttributes = (organizationId: number) =>
  BitesApi.get(`organization/${organizationId}/attribute_title`);

export const fetchJoinOrganizationDeeplink = (accessCode: string) =>
  axios.post(`${BASE_BACKEND_URL}/api/common_services/deeplink/join-organization`, {
    accessCode,
    app: 'app',
    platform: Platform.OS,
  });

export const updateUserDefaultOrganization = (user_organization_id: number, body: any) =>
  BitesApi.patch(`user_organization/${user_organization_id}/`, body);

export const updateOrganizationNameRequest = (id: number, name: string) => {
  return BitesApi.patch(`organizations/${id}/`, { readable_name: name });
};

export const leaveOrganizationRequest = (id: number) => {
  return BitesApi.patch(`user_organization/${id}/`, { opted_out: true });
};

export const fetchCreatorsRequest: TFetchCreators = (id: number) => {
  return BitesApi.get(`organizations/${id}/creators/`);
};

export const validateAccessCode = (code: string) => {
  return BitesApi.post('/accounts/access_code/validate/', { code });
};
