import { EAnalyticsScreenTabs } from '../../screens/analytics/Analytics.types';
import Routes from '../../navigation/routes';
import { getHomeScreen } from './common';

export const getAnalyticsStatsRoute = (tab: EAnalyticsScreenTabs) => ({
  index: 2,
  routes: [
    getHomeScreen(),
    {
      name: Routes.AnalyticsStack.StackName,
      params: {
        screen: Routes.AnalyticsStack.BiteOverview,
      },
    },
    {
      name: Routes.AnalyticsStack.StackName,
      params: {
        screen: Routes.AnalyticsStack.Stats,
        params: {
          initialRouteName: tab,
        },
      },
    },
  ],
});
