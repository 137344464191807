import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EDrawerModal {
  Organization = 'Organization',
  Profile = 'Profile',
  InviteCollaborators = 'InviteCollaborators',
}

interface IState {
  openedModal: EDrawerModal | null;
}

const initialState: IState = {
  openedModal: null,
};

const MENU_STATE = 'MENU_STATE';

const menuStateSlice = createSlice({
  name: MENU_STATE,
  initialState,
  reducers: {
    setDrawerModal: (state, { payload }: PayloadAction<EDrawerModal>) => {
      state.openedModal = payload;
    },
    closeDrawerModal: (state) => {
      state.openedModal = null;
    },
  },
});

export const { closeDrawerModal, setDrawerModal } = menuStateSlice.actions;

export default menuStateSlice.reducer;
