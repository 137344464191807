import { EShareMode } from '../../utils/constants/bite';
import { TLocale } from '../../locale/i18n';

const Types = {
  SET_PLAYLIST_NAME: 'SET_PLAYLIST_NAME',
  SET_PLAYLIST_ABOUT: 'SET_PLAYLIST_ABOUT',
  SELECT_BITE: 'SELECT_BITE',
  UPDATE_SELECTED_BITES: 'UPDATE_SELECTED_BITES',
  REMOVE_SELECTED_BITE: 'REMOVE_SELECTED_BITE',
  REMOVE_SELECTED_BITES: 'REMOVE_SELECTED_BITES',
  UPDATE_PLAYLIST_SETTING: 'UPDATE_PLAYLIST_SETTING',
  POST_PLAYLIST_REQUEST: 'POST_PLAYLIST_REQUEST',
  POST_AND_UPDATE_PLAYLIST_SUCCESS: 'POST_AND_UPDATE_PLAYLIST_SUCCESS',
  UPDATE_PLAYLIST_REQUEST: 'UPDATE_PLAYLIST_REQUEST',
  UPDATE_PLAYLIST_SUCCESS: 'UPDATE_PLAYLIST_SUCCESS',
  SET_PLAYLIST_TO_EDIT: 'SET_PLAYLIST_TO_EDIT',
  UPDATE_EDITED_PLAYLIST: 'UPDATE_EDITED_PLAYLIST',
  UPDATE_EDITED_PLAYLIST_SHARE_MODE: 'UPDATE_PLAYLIST_SHARE_MODE',
  RESET_CREATE_PLAYLIST_STATE: 'RESET_CREATE_PLAYLIST_STATE',
  SET_PLAYLIST_AS_QUIZ: 'SET_PLAYLIST_AS_QUIZ',
  RESET_PLAYLIST_AS_QUIZ: 'RESET_PLAYLIST_AS_QUIZ',
  POST_AND_UPDATE_PLAYLIST_ERROR: 'POST_AND_UPDATE_PLAYLIST_ERROR',
  TOGGLE_SELECTED_TO_REMOVE_BITE: 'TOGGLE_SELECTED_TO_REMOVE_BITE',
  CLEAN_SELECTED_TO_REMOVE_BITES: 'CLEAN_TOGGLE_SELECTED_TO_REMOVE_BITES',
  SET_LINKED_COVER_URL: 'SET_LINKED_COVER_URL,',
};

export default Types;

export interface ISelectedBite {
  id: number;
  bite_preview: number;
  subject: string;
  cover_url: string | null;
  linked_cover_url: string | null;
  playlist_section?: string | null;
  order?: number;
  text?: string | null;
  watched?: number;
  participants?: number;
  comments?: number;
  branding?: {
    brand_share_url: string;
    locale: TLocale;
    logo_url: string;
    name: string;
  };
}
export interface IPlaylistSettings {
  is_public: boolean;
  is_linear: boolean;
}
export interface IPlaylistPostOrUpdateBiteShare {
  id?: number;
  bite: number;
  order: number;
  playlist_section?: string | null;
}

export interface IPlaylistPostOrUpdateBody extends IPlaylistSettings {
  organization?: number;
  subject: string;
  description: string;
  bite_shares: IPlaylistPostOrUpdateBiteShare[];
  is_quiz?: boolean;
  sharing_mode?: EShareMode;
  linked_cover_url?: string;
  cover?: number;
}
