import Types from './biteSummary.types';
import { ISummaryCard } from '../../types/biteSummary';

export const addSummaryCard = () => ({
  type: Types.ADD_SUMMARY_CARD,
});

export const deleteSummaryCard = (id: string) => ({
  type: Types.DELETE_SUMMARY_CARD,
  payload: id,
});

export const setSummaryCard = (card: ISummaryCard) => ({
  type: Types.SET_SUMMARY_CARD,
  payload: card,
});

export const filterEmptyCards = () => ({
  type: Types.FILTER_EMPTY_SUMMARY_CARDS,
});

export const setSummaryCards = (cards: ISummaryCard[]) => ({
  type: Types.SET_SUMMARY_CARDS,
  payload: cards,
});

export const setCreatedSummary = (state: boolean) => ({
  type: Types.SET_CREATED_SUMMARY,
  payload: state,
});

export const clearState = () => ({
  type: Types.CLEAR_STATE,
});
