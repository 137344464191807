import { cloneDeep } from 'lodash';
import { IBiteItem, QuestionSection, SummarySection, IntroSection } from '../types/bite';
import { initialState as questionInitialState } from '../store/biteQuestion/biteQuestion.reducer';
import { initialState as biteInitialState } from '../store/createBite/createBite.reducer';
import { IUserOrganizations } from '../store/auth/auth.types';
import { IBiteShare } from '../types/biteShare';

export const formatBiteFromServer = (serverBite: IBiteItem) => {
  const { bite_sections, ...restBite } = serverBite;

  const questionSection = bite_sections.find((section) => section.type === 'question') as QuestionSection;

  let newBiteQuestionState = cloneDeep(questionInitialState);

  if (questionSection) {
    // if question section was not skipped
    if (questionSection.questions.length) {
      const { choices, is_multi_choice, ...restQuestion } = questionSection.questions[0];
      newBiteQuestionState = {
        ...newBiteQuestionState,
        ...restQuestion,
        answers: choices!,
        //@ts-ignore
        media: questionSection?.media,
        isCreated: true,
        isEdit: true,
        isMultiChoice: is_multi_choice,
      };
      // if the user skipped the question section when creating the bite
    } else {
      newBiteQuestionState = {
        ...newBiteQuestionState,
        skipped: true,
      };
    }
  }

  const summaryCardsState = bite_sections.find((section: any) => section.type === 'summary') as SummarySection;

  const introSection = bite_sections.find((section: any) => section.type === 'intro') as IntroSection;

  const createBiteState = {
    ...biteInitialState,
    biteName: restBite.subject,
    cover: restBite.cover,
    introMedia: introSection?.media, // restBite.extend?.enhancements.find(({ enabled }) => enabled) || introSection.media,
    cover_url: restBite.cover_url,
    biteIcon: { id: restBite.icon, image: restBite.icon_url, name: '' },
    description: restBite.description,
    linked_cover_url: restBite.linked_cover_url,
    biteSettings: {
      is_private: restBite.is_private,
      discussion_enabled: restBite.discussion_enabled,
      skip_able: restBite.skip_able,
    },
    isEditMode: true,
    extend: serverBite.extend,
  };

  return {
    questionState: newBiteQuestionState,
    summaryCardsState: summaryCardsState?.summary_cards,
    createBiteState,
  };
};

export const getDefaultOrganization = (organizations: IUserOrganizations[]): IUserOrganizations => {
  const defaultOrganization = organizations.find((organization: IUserOrganizations) => {
    return organization.is_creator && organization.is_default && !organization.is_opted_out;
  });

  if (defaultOrganization) {
    return defaultOrganization;
  }

  const userCreatedOrganization = organizations.find(
    (organization: IUserOrganizations) => organization.is_creator && !organization.is_opted_out,
  );

  if (userCreatedOrganization) {
    return userCreatedOrganization;
  }

  return organizations[0];
};

export const formatActiveOrgIdFromServer = (organizations: IUserOrganizations[]) => {
  const organization = getDefaultOrganization(organizations);
  return organization?.id;
};

export const getBiteShareId = (data: IBiteItem | IBiteShare): number | null => {
  if (!data) {
    return null;
  }

  if (data.hasOwnProperty('bite_preview')) {
    return (data as IBiteItem).bite_preview;
  }

  return data.id;
};

export const getBiteId = (data: IBiteItem | IBiteShare): number | null => {
  if (!data) {
    return null;
  }

  if (data.hasOwnProperty('bite')) {
    return (data as IBiteShare).bite;
  }

  return data.id;
};
