import React from 'react';
import LottieView from 'lottie-react';
import styled from 'styled-components';
import { calcWidth } from '../../../../../utils/dimensions';

const EditEnhancementIcon = ({ disabled }) => {
  if (disabled) {
    return (
      <S.LottieView
        animationData={require('../../../../../assets/lottie/grey-enhancement-icon-lottie.json')}
        autoPlay
        loop
      />
    );
  }

  return (
    <S.LottieView
      animationData={require('../../../../../assets/lottie/green-enhancement-icon-lottie.json')}
      autoPlay
      loop
    />
  );
};

const S = {
  LottieView: styled(LottieView)`
    width: ${calcWidth(40)}px;
    height: ${calcWidth(40)}px;
  `,
};

export default EditEnhancementIcon;
