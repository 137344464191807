import React, { useCallback, useMemo, useState } from 'react';
import HeaderTabs from '../../common/HeaderTabs';
import styled from 'styled-components/native';
import { ANSWERED_ATTRIBUTES_SCREEN, ANSWERED_LIST_SCREEN } from '../../constants';

interface IProps {
  onScroll: (state: boolean) => void;
  customHandleOffset?: number;
  isFocusedMode: boolean;
}

const useAnimatedHeaderTabs = ({ onScroll, customHandleOffset, isFocusedMode }: IProps) => {
  const [headerY, setHeaderY] = useState(null);
  const [isNeedToShowHeaderTabs, setIsNeedToShowHeaderTabs] = useState(false);

  const scrollHandler = useCallback(
    (event) => {
      const topOffset = event.nativeEvent.contentOffset.y;
      if (customHandleOffset ? customHandleOffset < topOffset : headerY < topOffset) {
        onScroll(true);
        setIsNeedToShowHeaderTabs(true);
        return;
      }

      onScroll(false);
      setIsNeedToShowHeaderTabs(false);
    },
    [customHandleOffset, headerY, onScroll],
  );

  const handleHeaderLayout = useCallback((event) => {
    // 114 - upper margin of the header
    const y = event.nativeEvent.layout.top - 114;
    setHeaderY(y);
  }, []);

  const renderAnimatedHeaderTabs = useCallback(
    () => (
      <S.HeaderTabsContainer isNeedToShowHeaderTabs={isNeedToShowHeaderTabs}>
        <HeaderTabs
          currentScreen={ANSWERED_ATTRIBUTES_SCREEN}
          attributesScreen={ANSWERED_ATTRIBUTES_SCREEN}
          listScreen={ANSWERED_LIST_SCREEN}
          isShowBottomDivider={!isFocusedMode}
        />
      </S.HeaderTabsContainer>
    ),
    [isFocusedMode, isNeedToShowHeaderTabs],
  );

  const stickyHeaderStyles = useMemo(
    () => ({
      zIndex: 5,
      transform: [{ translateY: isNeedToShowHeaderTabs ? 0 : -10000 }],
    }),
    [isNeedToShowHeaderTabs],
  );

  return {
    handleHeaderLayout,
    renderAnimatedHeaderTabs,
    scrollHandler,
    isNeedToShowHeaderTabs,
    stickyHeaderStyles,
  };
};

const S = {
  HeaderTabsContainer: styled.View<{ isNeedToShowHeaderTabs: boolean }>`
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.white};
    transform: translateY(${({ isNeedToShowHeaderTabs }) => (isNeedToShowHeaderTabs ? 0 : -10000)}px);
    will-change: transform;
  `,
};

export default useAnimatedHeaderTabs;
