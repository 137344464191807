import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components/native';
import { StyleProp, ViewStyle, I18nManager } from 'react-native';

import { calcHeight, calcWidth } from '../../../../utils/dimensions';
import { isContentCreatorSelector } from '../../../../store/auth/auth.selectors';
import PlusIcon from '../../../../assets/icons/plus.svg';
import ShadowedContainer from '../../../../components/ShadowedContainer';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../../../navigation/routes';
import { resetCreatePlaylistState } from '../../../../store/createPlaylist/createPlaylist.actions';
import Tooltip, { ITooltipMethods } from '../../../../components/Tooltip/Tooltip';
import { hasBitesSelector } from '../../../../store/bite/bite.selectors';
import { useTranslation } from 'react-i18next';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import startBiteCreationFlow from '../../../../utils/bite/startBiteCreationFlow';
import i18n from 'i18next';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps {}
const buttonShadowSmStyles = {
  height: 48,
};
const buttonShadowLgStyles = {};
const NewObjectPanel: React.FC<IProps> = ({}) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const playlistTooltipRef = useRef<ITooltipMethods>({});

  const isContentCreator = useSelector(isContentCreatorSelector);
  const hasBites = useSelector(hasBitesSelector);

  const handleCreateBite = useCallback(() => {
    startBiteCreationFlow();
    dispatch(togglePanel(null));
  }, [dispatch]);

  const handleCreatePlaylist = useCallback(() => {
    if (!hasBites) {
      // keyboardDismiss();
      playlistTooltipRef.current?.show?.();
      return;
    }
    dispatch(togglePanel(null));
    dispatch(
      trackEvent({
        event: 'new_playlist',
      }),
    );
    dispatch(resetCreatePlaylistState());
    navigation.navigate(Routes.CreatePlaylistStack.StackName);
  }, [hasBites, dispatch, navigation]);

  const handleCreateQuiz = useCallback(() => {
    dispatch(togglePanel(null));
    dispatch(
      trackEvent({
        event: 'new_quiz',
      }),
    );
    navigation.navigate('CreateQuizStack');
  }, [dispatch, navigation]);

  return (
    <S.Container>
      <S.PlaylistAndQuizContainer>
        <Tooltip
          methodsRef={playlistTooltipRef}
          text={t('homeScreen.noBitesForPlaylist')}
          fontSize={theme.fontSizes.s10}
          withGradient
        >
          <S.ContainerLeft>
            <ShadowedContainer containerViewStyle={buttonShadowSmStyles}>
              <S.ButtonSmContainer onPress={handleCreatePlaylist} disabled={!isContentCreator} isGrayed={!hasBites}>
                <PlusIcon
                  width={calcWidth(14)}
                  height={calcWidth(14)}
                  fill={!hasBites ? theme.colors.gray19 : theme.colors.lightblue6}
                />
                <S.ButtonText
                  color={!hasBites ? theme.colors.gray19 : theme.colors.lightblue6}
                  fontSize={i18n.language === 'de' ? theme.fontSizes.s13 : theme.fontSizes.s14}
                >
                  {t('homeScreen.newPlaylist')}
                </S.ButtonText>
              </S.ButtonSmContainer>
            </ShadowedContainer>
          </S.ContainerLeft>
        </Tooltip>
        <S.ContainerRight>
          <ShadowedContainer containerViewStyle={buttonShadowSmStyles}>
            <S.ButtonSmContainer onPress={handleCreateQuiz} disabled={!isContentCreator}>
              <PlusIcon width={calcWidth(14)} height={calcWidth(14)} fill={theme.colors.purpleChoose} />
              <S.ButtonText color={theme.colors.purpleChoose} fontSize={theme.fontSizes.s14}>
                {t('homeScreen.newQuiz')}
              </S.ButtonText>
            </S.ButtonSmContainer>
          </ShadowedContainer>
        </S.ContainerRight>
      </S.PlaylistAndQuizContainer>
      <S.BiteContainer>
        <ShadowedContainer containerViewStyle={buttonShadowLgStyles}>
          <S.ButtonLgContainer onPress={handleCreateBite} disabled={!isContentCreator}>
            <PlusIcon width={calcWidth(24)} height={calcWidth(24)} fill={theme.colors.primaryBlue} />
            <S.ButtonText color={theme.colors.primaryBlue} fontSize={theme.fontSizes.s20}>
              {t('homeScreen.newBite')}
            </S.ButtonText>
          </S.ButtonLgContainer>
        </ShadowedContainer>
      </S.BiteContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    padding: ${calcHeight(32)}px ${calcHeight(8)}px ${calcHeight(70)}px;
    background-color: ${({ theme }) => theme.colors.transparentWhite2};
  `,
  PlaylistAndQuizContainer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${calcHeight(24)}px;
    width: 100%;
  `,
  ContainerLeft: styled.View`
    margin-left: ${calcWidth(22)}px;
  `,
  ContainerRight: styled.View`
    margin-right: ${calcWidth(22)}px;
  `,
  ButtonSmContainer: styled.TouchableOpacity<{ disabled?: boolean; isGrayed?: boolean }>`
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: ${calcWidth(150)}px;
    border-radius: 50px;
    background-color: ${({ theme, disabled, isGrayed }) =>
      disabled || isGrayed ? theme.colors.lightGray1 : theme.colors.white};
  `,
  ButtonText: styled.Text<{ color: string; fontSize: number }>`
    margin-left: ${calcWidth(16)}px;
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize}px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-transform: capitalize;
    max-width: ${calcWidth(100)}px;
  `,
  BiteContainer: styled.View`
    width: 100%;
    align-items: center;
  `,
  ButtonLgContainer: styled.TouchableOpacity<{ disabled?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: ${calcWidth(320)}px;
    height: ${calcHeight(60)}px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  `,
  TooltipText: styled.Text`
    color: ${({ theme }) => theme.colors.white};
  `,
  TooltipStyles: {
    marginTop: 7,
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
    // backgroundColor: `${defaultTheme.colors.primaryBlue}`,
  } as StyleProp<ViewStyle>,
  TooltipArrowStyles: {
    marginTop: -7,
  } as StyleProp<ViewStyle>,
  TooltipBackgroundStyles: {
    flexDirection: I18nManager.isRTL ? 'row-reverse' : 'row',
  } as StyleProp<ViewStyle>,
};

export default NewObjectPanel;
