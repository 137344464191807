import React, { useCallback } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import styled from 'styled-components/native';
import appStoreImage from '../../assets/images/app-store.png';
import playStoreImage from '../../assets/images/play-store.png';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../utils/constants/urls';
import { calcHeight, calcWidth } from '../../utils/dimensions';
import BitesLogoIcon from '../../assets/icons/bites-logo.svg';
import { useTranslation } from 'react-i18next';

enum MobileOS {
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown',
}

const getMobileOperatingSystem = (): MobileOS => {
  const userAgent = window.navigator ? navigator.userAgent : '';
  if (/android/i.test(userAgent)) {
    return MobileOS.Android;
  }
  //@ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return MobileOS.iOS;
  }
  return MobileOS.Unknown;
};

const RedirectToStores = () => {
  const { t } = useTranslation();
  const mobileOS = getMobileOperatingSystem();

  const onAppStoreClick = useCallback(() => {
    window.open(APP_STORE_URL);
  }, []);

  const onPlayStoreClick = useCallback(() => {
    window.open(PLAY_STORE_URL);
  }, []);

  return (
    <S.Container>
      <S.IconContainer>
        <BitesLogoIcon width={300} height={100} />
      </S.IconContainer>

      <S.Content>
        <S.Text>{t('authentication.redirectToStore')}</S.Text>
        <S.StoresContainer>
          {mobileOS !== MobileOS.Android && (
            <TouchableWithoutFeedback onPress={onAppStoreClick}>
              <S.AppStoreImage source={appStoreImage} />
            </TouchableWithoutFeedback>
          )}
          {mobileOS !== MobileOS.iOS && (
            <TouchableWithoutFeedback onPress={onPlayStoreClick}>
              <S.PlayStoreImage source={playStoreImage} />
            </TouchableWithoutFeedback>
          )}
        </S.StoresContainer>
      </S.Content>
    </S.Container>
  );
};

const S = {
  Container: styled.View`
    padding: 40px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    align-items: center;
  `,
  Content: styled.View`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Text: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s20};
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 20px;
    text-align: center;
  `,
  IconContainer: styled.View`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  StoresContainer: styled.View`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  AppStoreImage: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(250)}px;
    height: ${calcHeight(80)}px;
    align-self: center;
    margin-bottom: ${calcHeight(10)}px;
  `,
  PlayStoreImage: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(250)}px;
    height: ${calcHeight(80)}px;
    align-self: center;
    margin-bottom: ${calcHeight(10)}px;
  `,
};

export default RedirectToStores;
