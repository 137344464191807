import Smartlook from 'smartlook-react-native-wrapper';
import { ELogType } from '../../store/appActivity/appActivity.types';
import { isWeb } from '../../utils/dimensions';
import * as datadog from '../datadog';
import bitesCrmTrack from '../bitesCrm';
import { IS_PROD } from '../../utils/constants/env';

const log = async (data) => {
  const dataStr = JSON.stringify(data);
  if (!IS_PROD) {
    if (!isWeb) {
      console.log('Logging:', data?.event);
      console.log(data?.data);
    } else {
      console.log(data?.event, data?.data);
    }
  }

  try {
    await datadog.logInfo(data.event || ELogType.log, JSON.parse(dataStr));
  } catch (error) {}

  bitesCrmTrack({ ...data, logType: ELogType.log });
};
export default log;

export const logError = async (data) => {
  const dataStr = JSON.stringify(data);

  if (isWeb) {
    try {
      window.smartlook?.('error', JSON.parse(dataStr));
    } catch (error) {}
  } else {
    try {
      Smartlook.trackCustomEvent('error', JSON.parse(dataStr));
    } catch (error) {}
  }

  if (!isWeb) {
    console.error('Logging error:', data.event || ELogType.error);
    console.error(JSON.parse(dataStr));
  } else {
    console.error(data.event || ELogType.error, JSON.parse(dataStr));
  }

  try {
    await datadog.logError(data.event || ELogType.error, JSON.parse(dataStr));
  } catch (error) {}

  bitesCrmTrack({ ...data, logType: ELogType.error });
};
