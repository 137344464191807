import React from 'react';
import styled from 'styled-components/native';
import { calcHeight, calcWidth } from '../../../utils/dimensions';

interface Props {
  absolute?: boolean;
  children: any;
}

function WhiteBoxContainer(props: Props) {
  const { children, absolute } = props;

  return <S.Container absolute={absolute}>{children}</S.Container>;
}

const S = {
  Container: styled.View<{ absolute: boolean }>`
    background-color: ${({ theme }) => theme.colors.white};
    min-height: ${calcHeight(249)}px;
    width: ${calcWidth(299)}px;
    border-radius: 16px;
    overflow: hidden;
    padding: ${calcWidth(14)}px;
    ${({ absolute }) =>
      absolute
        ? `
					top: ${calcHeight(77)}px;
					left: 50%;
					margin-left: ${calcWidth(-150)}px;
					z-index: 10;
					position: absolute;
				`
        : `
					margin-top: ${calcHeight(-100)}
				`}
  `,
};

export default WhiteBoxContainer;
