import React, { memo } from 'react';
import styled from 'styled-components/native';
import Preloader from '../../../../components/shared/Preloader';

function SsoAuthLoader() {
  return (
    <S.Container>
      <Preloader />
    </S.Container>
  );
}

export default memo(SsoAuthLoader);

const S = {
  Container: styled.View`
    flex: 1;
    min-height: 400px;
    align-items: center;
    justify-content: center;
  `,
};
