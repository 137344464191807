import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';
import { IBiteItem } from '../../../types/bite';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import Checkmark from '../../../assets/icons/feed/checkmark.svg';
import { useSelector } from 'react-redux';
import { createPlaylistSelector } from '../../../store/createPlaylist/createPlaylist.selectors';

interface IProps {
  bite: IBiteItem;
}

const iconStyles = {
  fill: '#FFF',
};

const BiteInPlaylistSelectedOverlay: FC<IProps> = ({ bite }) => {
  const { selectedBites } = useSelector(createPlaylistSelector);

  const isSelected = useMemo(() => {
    return !!selectedBites.find((selectedBite) => selectedBite.id === bite.id);
  }, [selectedBites, bite.id]);

  const checkIconStyles = useMemo(() => ({ width: calcWidth(35), height: calcHeight(27) }), []);

  if (!isSelected) {
    return null;
  }

  return (
    <S.ChosenImageBg>
      <Checkmark {...iconStyles} style={checkIconStyles} />
    </S.ChosenImageBg>
  );
};

const S = {
  ChosenImageBg: styled.View`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    z-index: 3;
  `,
};

export default React.memo(BiteInPlaylistSelectedOverlay);
