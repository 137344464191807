import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BiteCreationRestoreModal from '../components/modals/AppModals/RestoreModal/BiteCreationRestoreModal';
import BiteEditionRestoreModal from '../components/modals/AppModals/RestoreModal/BiteEditionRestoreModal';
import { getDrafts } from '../services/videoEditor/videoEditor';
import { banubaDraftIdsSelector, currentFlowSelector } from '../store/appActivity/appActivity.selectors';
import { setCurrentFlow } from '../store/appActivity/appActivity.slice';
import { EAppFlow } from '../store/appActivity/appActivity.types';
import { isWeb } from '../utils/dimensions';
import { useIsMounted } from './useIsMounted';

const useRestoreVideoModal = () => {
  const dispatch = useDispatch();

  const currentFlow = useSelector(currentFlowSelector);
  const banubaDraftIds = useSelector(banubaDraftIdsSelector) || [];

  const isMountedRef = useIsMounted();

  const [modal, setModal] = useState<ReactElement>(null);

  useEffect(() => {
    const run = async () => {
      const drafts = await getDrafts();

      if (!isMountedRef.current) {
        return;
      }

      const banubaDraftIdsMap = banubaDraftIds.reduce((map, sequenceId) => {
        map[sequenceId] = true;
        return map;
      }, {});
      const hasNewDrafts = drafts?.some(({ sequenceId }) => !banubaDraftIdsMap[sequenceId]);

      if (
        (currentFlow?.flow === EAppFlow.BITE_CREATION || currentFlow?.flow === EAppFlow.BITE_EDITION) &&
        drafts.length > 0 &&
        ((currentFlow.hasIntroVideo && !currentFlow.videoUploadedToS3) || hasNewDrafts)
      ) {
        setModal(
          !currentFlow.biteId ? <BiteCreationRestoreModal /> : <BiteEditionRestoreModal biteId={currentFlow.biteId} />,
        );
      }
    };

    if (!isWeb) {
      run();
    }

    dispatch(setCurrentFlow(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return modal;
};

export default useRestoreVideoModal;
