import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { IBiteItem } from '../../types/bite';
import { updateBiteData } from '../api/bites-api/calls/bite.calls';
import { updatePlaylist } from '../api/bites-api/calls/playlist.calls';
import { fetchBiteToEditRequest, setBites } from '../bite/bite.actions';
import { postOrUpdateBiteSuccess } from '../createBite/createBites.actions';
import { updateEditedPlaylistShareMode } from '../createPlaylist/createPlaylist.actions';
import { IPlaylistPostOrUpdateBody } from '../createPlaylist/createPlaylist.types';
import { setPlaylists } from '../playlist/playlist.actions';
import { IPlaylist } from '../playlist/playlist.types';
import { ESharePanelCallbackType, IChangeItemShareMode } from '../../types/sharePanel';
import {
  changeBiteShareMode,
  changePlaylistShareMode,
  shareModeChangeFail,
  shareModeChangeSuccess,
} from './sharePanel.slice';
import { trackEvent } from '../appActivity/appActivity.slice';
import { shareDataSelector, sharePanelFromSelector } from './sharePanel.selectors';
import { EShareMode } from '../../utils/constants/bite';
import { indexBitesSaga } from '../bite/bite.saga';
import Types from '../bite/bite.types';

function* shareModeChangeSuccessSaga(data: IBiteItem | IPlaylist, callbackType: ESharePanelCallbackType | null) {
  switch (callbackType) {
    case ESharePanelCallbackType.EDIT_BITE: {
      yield put(fetchBiteToEditRequest(data.id));
      yield put(setBites([data as IBiteItem]));
      return;
    }
    case ESharePanelCallbackType.NEW_BITE: {
      yield put(postOrUpdateBiteSuccess(data as IBiteItem));
      yield put(setBites([data as IBiteItem]));
      return;
    }
    case ESharePanelCallbackType.BITE_IN_LIST: {
      yield put(setBites([data as IBiteItem]));
      return;
    }
    case ESharePanelCallbackType.NEW_PLAYLIST: {
      yield put(updateEditedPlaylistShareMode(data.sharing_mode));
      yield put(setPlaylists([data as IPlaylist]));
      return;
    }
    case ESharePanelCallbackType.PLAYLIST_IN_LIST: {
      yield put(setPlaylists([data as IPlaylist]));
      return;
    }
  }
}

function* changeBiteShareModeSaga({ payload }: PayloadAction<IChangeItemShareMode>) {
  try {
    const { body, itemId } = payload;

    const shareData = yield select(shareDataSelector);
    const { data } = yield call(updateBiteData, itemId, body);

    yield put(
      trackEvent({
        event: 'bite_change_sharing_mode',
        props: { bite_id: data.id, sharing_mode: payload.body.sharing_mode },
      }),
    );
    yield call(shareModeChangeSuccessSaga, data, payload.callbackType);

    if (
      shareData.sharing_mode !== body.sharing_mode &&
      (body.sharing_mode === EShareMode.NO_ONE || shareData.sharing_mode === EShareMode.NO_ONE)
    ) {
      yield spawn(indexBitesSaga, { payload: { biteIds: [itemId] }, type: Types.INDEX_BITES });
    }
    yield put(shareModeChangeSuccess(data));
  } catch (err) {
    yield put(shareModeChangeFail());
  }
}

function* changePlaylistShareModeSaga({ payload }: PayloadAction<IChangeItemShareMode>) {
  try {
    const body: Partial<IPlaylistPostOrUpdateBody> = { sharing_mode: payload.body.sharing_mode };
    const from = yield select(sharePanelFromSelector);

    const { data } = yield call(updatePlaylist, payload.itemId, body);
    yield put(
      trackEvent({
        event: 'playlist_change_sharing_mode',
        props: { playlist_id: data.id, sharing_mode: payload.body.sharing_mode, from },
      }),
    );

    yield call(shareModeChangeSuccessSaga, data, payload.callbackType);
    yield put(shareModeChangeSuccess(data));
  } catch (err) {
    yield put(shareModeChangeFail());
  }
}

export default function* sharePanelSaga() {
  yield all([
    takeLatest(changeBiteShareMode, changeBiteShareModeSaga),
    takeLatest(changePlaylistShareMode, changePlaylistShareModeSaga),
  ]);
}
