import React, { useCallback, useState } from 'react';
import styled from 'styled-components/native';
import { calcFontSize, calcWidth } from '../../../utils/dimensions';
import SharePanelInfoModal from '../../modals/SharePanelInfoModal/SharePanelInfoModal';
import InfoIcon from '../../../assets/icons/i.svg';

interface IProps {
  label: string;
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

const SharePanelCheckbox: React.FC<IProps> = (props) => {
  const { onChange, label, isChecked } = props;
  const [isInfoModalOpen, setInfoModalOpen] = useState<boolean>(false);

  const handleCheck = useCallback(() => {
    return onChange(!isChecked);
  }, [isChecked, onChange]);

  const handleOpenInfoModal = useCallback(() => {
    return setInfoModalOpen(true);
  }, []);

  const handleCloseInfoModal = useCallback(() => {
    return setInfoModalOpen(false);
  }, []);

  return (
    <>
      <S.Container>
        <S.Checkbox isChecked={isChecked} onPress={handleCheck}>
          {isChecked ? <CheckboxChecked /> : null}
        </S.Checkbox>

        <S.Label>{label}</S.Label>
        <S.InfoIconWrapper onPress={handleOpenInfoModal}>
          <InfoIcon />
        </S.InfoIconWrapper>
      </S.Container>

      <SharePanelInfoModal isVisible={isInfoModalOpen} onClose={handleCloseInfoModal} />
    </>
  );
};

const checkboxSize = 17;

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`;

const Checkbox = styled.TouchableOpacity<{ isChecked: boolean }>`
  width: ${calcWidth(checkboxSize)}px;
  height: ${calcWidth(checkboxSize)}px;
  margin-right: ${calcWidth(12)}px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme, isChecked }) => {
    return isChecked ? theme.colors.gray12 : theme.colors.gray15;
  }};
`;

const CheckboxChecked = styled.View`
  width: ${calcWidth(checkboxSize - 4)}px;
  height: ${calcWidth(checkboxSize - 4)}px;
  background-color: ${({ theme }) => theme.colors.gray12};
  border-radius: 5px;
`;

const Label = styled.Text`
  font-size: ${calcFontSize(17)}px;
  color: ${({ theme }) => theme.colors.gray15};
  font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
`;

const InfoIconWrapper = styled.TouchableOpacity`
  width: ${calcWidth(13)}px;
  height: ${calcWidth(13)}px;
  border-radius: ${calcWidth(13 / 2)}px;
  border-color: ${({ theme }) => theme.colors.lightText};
  margin-left: ${calcWidth(3)}px;
  border-width: 1px;
  align-items: center;
  justify-content: center;
`;

const S = {
  Container,
  Checkbox,
  CheckboxChecked,
  Label,
  InfoIconWrapper,
};

export default SharePanelCheckbox;
