import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { KeyboardAwareView } from '../../../../components/shared';
import GenericButton from '../../../../components/shared/buttons/GenericButton';
import { useTranslation } from 'react-i18next';
import useQuestion from '../useQuestion';
import useMedia, { formatFileTypesForWeb } from '../../../../hooks/useMedia';
import { useDispatch, useSelector } from 'react-redux';
import {
  questionMediaUploadingError,
  setQuestionContent,
  setQuestionMedia,
  setQuestionMediaDoneUploading,
} from '../../../../store/biteQuestion/biteQuestion.actions';
import { setBiteCoverFromUrl } from '../../../../store/createBite/createBites.actions';
import { createBiteSelector, questionSuggestionSelector } from '../../../../store/createBite/createBite.selectors';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import QuestionSticker from '../../../creationBite/common/QuestionSticker';
import MediaModal from '../../../creationBite/common/MediaModal';
import SuggestionHeader from '../../SuggestionHeader';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../../utils/constants/toastConfig';
import { questionContinueBtnDataSet } from '../question.constants';

interface IProps {
  onContinue: (localInputValue?: string) => void;
  isEditMode?: boolean;
  isLoading?: boolean;
  from: string;
}

const AddOpenEndedQuestion: React.FC<IProps> = ({ isLoading, onContinue, isEditMode, from }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uploadImageRef = useRef<() => Promise<any>>();

  const [isImageLoading, setImageLoading] = useState(false);

  const { introMedia, cover_url } = useSelector(createBiteSelector);
  const questionSuggestion = useSelector(questionSuggestionSelector);
  const { localInputValue, setLocalInputValue, localMedia, setLocalMedia } = useQuestion({
    from,
    questionSuggestion: questionSuggestion?.open,
  });

  const handleUploadImage = async (uri: string, mimeType?: string, editedImageUri?: string) => {
    handleCloseMediaModal();

    const uploadImage = uploadImageRef.current;

    if (!editedImageUri && typeof uploadImage !== 'function') {
      return;
    }

    setImageLoading(true);

    try {
      const { media: _media } = await uploadImage();
      setLocalMedia(_media);
    } catch (error) {
      dispatch(questionMediaUploadingError());

      Toast.show({
        type: EToastTypes.networkError,
        topOffset: 0,
      });
    }

    setImageLoading(false);
    dispatch(setQuestionMediaDoneUploading());
  };

  const {
    mediaURI,
    launchImageLibrary,
    launchImageCamera,
    uploadImage,
    clearState,
    dropZoneUploadingForWeb,
    handleSelectOnlineMedia,
  } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    from: 'OpenQuestion',
    onMediaSelectionCB: handleUploadImage,
  });

  useEffect(() => {
    uploadImageRef.current = uploadImage;
  }, [uploadImage]);

  const handleDeleteImage = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'delete_question_image',
        props: { question_type: 'OpenEnded', from },
      }),
    );

    setLocalMedia(null);
    clearState();
  }, [dispatch, from, setLocalMedia, clearState]);

  const handleAddImage = useCallback(() => {
    if (isWeb) {
      launchImageLibrary();
      return;
    }
    setMediaModalVisible(true);
  }, [launchImageLibrary]);

  const [isMediaModalVisible, setMediaModalVisible] = useState(false);

  const handleCloseMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const handleContinue = useCallback(() => {
    dispatch(setQuestionContent(localInputValue));
    dispatch(setQuestionMedia(localMedia));

    if (!introMedia && !cover_url) {
      dispatch(setBiteCoverFromUrl(localMedia?.image_url!));
    }

    onContinue(localInputValue);
  }, [cover_url, dispatch, introMedia, localMedia, onContinue, localInputValue]);

  return (
    <S.Container>
      <S.KeyboardAwareView>
        <S.Center>
          {questionSuggestion?.open && <SuggestionHeader title={t('addQuestion.questionSuggestion.title')} />}
          <QuestionSticker
            text={localInputValue}
            onChangeText={setLocalInputValue}
            color={theme.colors.orangeSticky}
            isImageLoading={isImageLoading}
            onAddImage={handleAddImage}
            mediaURI={mediaURI || localMedia?.image_url}
            onDeleteImage={handleDeleteImage}
          />
          <S.ContinueButton
            onPress={handleContinue}
            disabled={localInputValue.length < 5}
            isLoading={isLoading}
            content={t(`common.${isEditMode ? 'Save' : 'Continue'}`)}
            // @ts-ignore
            dataSet={questionContinueBtnDataSet}
          />
        </S.Center>
      </S.KeyboardAwareView>
      <MediaModal
        onSelectStockImage={handleSelectOnlineMedia}
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onClose={handleCloseMediaModal}
        isVisible={isMediaModalVisible}
        from='AddOpenEndedQuestion'
      />
      {dropZoneUploadingForWeb}
    </S.Container>
  );
};

const S = {
  KeyboardAwareView: styled(KeyboardAwareView)`
    flex: 1;
  `,
  Center: styled.View`
    align-self: center;
    position: relative;
    padding-bottom: 20px;
  `,
  Container: styled.View`
    flex: 1;
    align-items: center;
    background-color: white;
  `,
  ContinueButton: styled(GenericButton)`
    width: ${calcWidth(111)}px;
    align-self: flex-end;
    margin-top: ${calcHeight(24)}px;
  `,
};

export default AddOpenEndedQuestion;
