import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const useElementsPanelTitle = ({ config }) => {
  const { t } = useTranslation();
  const [itemTitles, setItemTitles] = useState([]);
  const itemTitlesLength = itemTitles?.length ? itemTitles.length - 1 : 0;
  const [subtitleSectionIndex, setSubtitleSectionIndex] = useState<number>(
    Math.floor(Math.random() * itemTitlesLength),
  );

  useEffect(() => {
    if (!config) {
      return;
    }

    let items = [];

    config.sections.forEach((section) => {
      items.push(...section.items.map((item) => item.label));
    });

    setItemTitles(items);

    const interval = setInterval(() => {
      setSubtitleSectionIndex(Math.floor(Math.random() * itemTitlesLength));
    }, 5000);

    return () => clearInterval(interval);
  }, [config, itemTitles.length, itemTitlesLength, subtitleSectionIndex, t]);

  return { title: t(itemTitles[subtitleSectionIndex]) };
};

export default useElementsPanelTitle;
