import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFullWidthContent } from '../../store/appActivity/appActivity.slice';
import styled from 'styled-components';
import Timeline from './Timeline';
import {
  isTimelineExpandedSeletor,
  isVideoLoadingSeletor,
  isVideoSavingSeletor,
  selectedTimelineItemIdSelector,
  timelineItemSeletor,
  videoLoadingErrorSeletor,
  videoResolutionSeletor,
} from '../../store/videoEditor/videoEditor.selectors';
import { cleanVideoEditor } from '../../store/videoEditor/videoEditor.slice';
import Canvas from './Canvas';
import Controls from './Controls.web';
import { userSelector } from '../../store/auth/auth.selectors';
import TextSettings from './RightSidebar/TextSettings.web';
import ShapeSettings from './RightSidebar/ShapeSettings.web';
import VideoSettings from './RightSidebar/VideoSettings.web';
import ImageSettings from './RightSidebar/ImageSettings.web';
import AudioSettings from './RightSidebar/AudioSettings.web';
import GeneralSettings from './RightSidebar/GeneralSettings.web';
// import Groups from './settings/Groups';
import defaultTheme from '../../themes/defaultTheme';
// import ShapeIcon from '../../assets/icons/videoEditor/shape.svg';
// import ShapeSelectedIcon from '../../assets/icons/videoEditor/shape-selected.svg';

import {
  IS_EXPANDED_TRANSITION_TS,
  SPACING,
  TIMELINE_CONTROLS_HEIGHT,
  PRIMARY_SIDEBAR_WIDTH,
  SECONDARY_SIDEBAR_WIDTH,
  SETTINGS_SIDEBAR_WIDTH,
  TOP_BAR_HEIGHT,
  videoEditorPortal,
} from './videoEditor.constants';
import TopBar from './TopBar.web';
import Preloader from '../../components/shared/Preloader';
import { useIsFocused } from '@react-navigation/native';
import { retryFunctions, videoEditorData } from '../../store/videoEditor/videoEditor.data';
import { VideoResolutionSelector } from './VideoResolutionSelector.web';
import { TIMELINE_CONTAINER_HEIGHT } from './Timeline.web';
import LeftSidebar from './LeftSidebar/LeftSidebar';

const SettingsComponentByType = {
  text: TextSettings,
  shape: ShapeSettings,
  video: VideoSettings,
  image: ImageSettings,
  audio: AudioSettings,
  gif: ImageSettings,
};

export type TSidebarButton =
  | 'CLOUD'
  | 'SCOPED'
  // | 'FILE'
  | 'BRAND'
  | 'FAVORITES'
  | 'STOCK'
  // | 'TALKING_AVATAR'
  // | 'AI_VIDEO'
  // | 'SHAPE'
  | 'RECORD'
  | 'TEXT';

const VideoEditorStarter = ({ route }) => {
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const isVideoLoading = useSelector(isVideoLoadingSeletor);
  const videoLoadingError = useSelector(videoLoadingErrorSeletor);
  const isVideoSaving = useSelector(isVideoSavingSeletor);

  const videoResolution = useSelector(videoResolutionSeletor);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused) {
      return;
    }

    document.querySelector('.RootWrapper')?.classList.add('full-width');
    dispatch(setIsFullWidthContent(true));

    return () => {
      document.querySelector('.RootWrapper')?.classList.remove('full-width');
      dispatch(setIsFullWidthContent(false));
    };
  }, [dispatch, isFocused]);

  useEffect(() => {
    return () => {
      dispatch(cleanVideoEditor());
    };
  }, [dispatch]);

  if (!user) {
    return null;
  }

  if (videoLoadingError) {
    return <div>Something went wrong</div>;
  }

  if (isVideoLoading || isVideoSaving) {
    return (
      <S.PreloaderContainer>
        <Preloader />
      </S.PreloaderContainer>
    );
  }

  if (!videoResolution) {
    return <VideoResolutionSelector />;
  }

  return <VideoEditor route={route} />;
};
export default VideoEditorStarter;

const VideoEditor = ({ route }) => {
  const dispatch = useDispatch();

  const isTimelineExpanded = useSelector(isTimelineExpandedSeletor);
  const timelineHeight = TIMELINE_CONTAINER_HEIGHT;

  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const SettingsComponent = timelineItem ? SettingsComponentByType[timelineItem?.type] : GeneralSettings;

  // const handleAddOverlay = useCallback(() => {
  //   dispatch(
  //     addToTimeline({
  //       timelineItem: {
  //         type: 'shape',
  //         x: 0,
  //         y: 0,
  //         width: 100,
  //         height: 100,
  //         color: 'rgba(0, 0, 0, 0.5)',
  //       },
  //     }),
  //   );
  // }, [dispatch]);

  // useEffect(() => {
  //   if (!video) {
  //     videoLoadedRef.current = null;
  //     setIsLoading(false);
  //     return;
  //   }

  //   if (videoLoadedRef.current) {
  //     return;
  //   }

  //   dispatch(
  //     loadVideo({
  //       onLoad: () => {
  //         videoLoadedRef.current = true;
  //         setIsLoading(false);
  //       },
  //       onError: () => {
  //         setIsLoading(false);
  //         setIsError(true);
  //       },
  //     }),
  //   );
  // }, [dispatch, video]);

  useEffect(() => {
    return () => {
      videoEditorData.rootTimelineItemsPositionsMap = {};
      videoEditorData.timelineItemsPositionsMap = {};
      videoEditorData.startPlayingTs = null;

      Object.keys(retryFunctions).forEach((key) => {
        delete retryFunctions[key];
      });
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (isWeb) {
  //     navigation.reset({
  //       index: 0,
  //       routes: [
  //         {
  //           name: Routes.HomeStack.StackName,
  //           params: {
  //             screen: Routes.HomeStack.Home,
  //           },
  //         },
  //       ],
  //     });
  //     return;
  //   }

  //   navigation.replace(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  // }, []);

  return (
    <>
      <S.Container>
        <LeftSidebar timelineHeight={timelineHeight} isTimelineExpanded={isTimelineExpanded} />
        <S.TopBar>
          <TopBar route={route} />
        </S.TopBar>
        <S.Canvas timelineHeight={timelineHeight}>
          <Canvas />
        </S.Canvas>
        {SettingsComponent && (
          <S.SettingsSidebar timelineHeight={timelineHeight} onClick={(e) => e.stopPropagation()}>
            <SettingsComponent />
          </S.SettingsSidebar>
        )}
        <S.Timeline isTimelineExpanded={isTimelineExpanded}>
          <Controls />
          <Timeline />
        </S.Timeline>
        <div ref={videoEditorPortal} />
      </S.Container>
    </>
  );
};

export const S = {
  PreloaderContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  `,
  Container: styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: ${defaultTheme.colors.lightGray43};

    & * {
      box-sizing: border-box;
      user-select: none;
    }
  `,
  TopBar: styled.div`
    position: absolute;
    top: 0px;
    left: ${SPACING + PRIMARY_SIDEBAR_WIDTH + SECONDARY_SIDEBAR_WIDTH + SPACING}px;
    right: ${SPACING + SETTINGS_SIDEBAR_WIDTH + SPACING}px;
    height: ${TOP_BAR_HEIGHT}px;
    display: flex;
    align-items: center;
    padding: 0px 8px 0 24px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
  `,
  Logo: styled.img`
    width: 25px;
    height: 32px;
  `,
  Canvas: styled.div<{
    timelineHeight: number;
  }>`
    position: absolute;
    top: ${TOP_BAR_HEIGHT + SPACING}px;
    left: ${SPACING + PRIMARY_SIDEBAR_WIDTH + SECONDARY_SIDEBAR_WIDTH + SPACING}px;
    right: ${SPACING + SETTINGS_SIDEBAR_WIDTH + SPACING}px;
    bottom: ${({ timelineHeight }) => TIMELINE_CONTROLS_HEIGHT + timelineHeight + SPACING}px;
  `,
  SettingsSidebar: styled.div<{
    timelineHeight: number;
  }>`
    position: absolute;
    top: ${SPACING}px;
    right: ${SPACING}px;
    bottom: ${({ timelineHeight }) => TIMELINE_CONTROLS_HEIGHT + timelineHeight + SPACING}px;
    width: ${SETTINGS_SIDEBAR_WIDTH}px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    background-color: ${defaultTheme.colors.white};
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Timeline: styled.div<{ isTimelineExpanded: boolean }>`
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: ${({ isTimelineExpanded }) =>
      !isTimelineExpanded ? SPACING + PRIMARY_SIDEBAR_WIDTH + SECONDARY_SIDEBAR_WIDTH + SPACING : 0}px;
    right: 0;
    border-top-left-radius: ${({ isTimelineExpanded }) => (!isTimelineExpanded ? 8 : 0)}px;
    overflow: hidden;
    box-shadow: 0px 0px ${({ isTimelineExpanded }) => (!isTimelineExpanded ? 4 : 0)}px 0px
      rgba(0, 0, 0, ${({ isTimelineExpanded }) => (!isTimelineExpanded ? 0.2 : 0)});
    transition: all ${IS_EXPANDED_TRANSITION_TS}ms ease-in-out;
  `,
};
