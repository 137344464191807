const Types = {
  FETCH_ICONS_REQUEST: 'FETCH_ICONS_REQUEST',
  FETCH_ICONS_SUCCUESS: 'FETCH_ICONS_SUCCUESS',
  SEARCH_ICONS_REQUEST: 'SEARCH_ICONS_REQUEST',
  SEARCH_ICONS_SUCCESS: 'SEARCH_ICONS_SUCCESS',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
};

export default Types;

export interface IIcon {
  id: number;
  name: string;
  image: string;
}
