import { IVideoConfig } from '../../../store/videoEditor/videoEditor.types';

export const getResizeTarget = (resolution: IVideoConfig['resolution']) => {
  const resizeTarget: Partial<IVideoConfig['resolution']> = {};

  if (resolution.width > resolution.height) {
    resizeTarget.height = resolution.height;
  } else {
    resizeTarget.width = resolution.width;
  }

  return resizeTarget;
};
