import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { organizationCodeSelector } from '../../store/org/org.selectors';
import { IChangeItemShareMode } from '../../types/sharePanel';
import {
  shareDataSelector,
  shareItemTypeSelector,
  shareItemIsInspirationBiteSelector,
  sharePanelCallbackTypeSelector,
  sharePanelVisibleSelector,
  shareViewModeSelector,
  sharePanelFromSelector,
} from '../../store/sharePanel/sharePanel.selectors';
import {
  ESharePanelViewMode,
  changeBiteShareMode,
  clearSharePanelState,
  hideSharePanel,
  sharePanelSetIsHiding,
  EShareItemType,
  changePlaylistShareMode,
} from '../../store/sharePanel/sharePanel.slice';
import { IBiteItem } from '../../types/bite';
import { IBiteShare } from '../../types/biteShare';
import { EShareMode } from '../../utils/constants/bite';
import { calcWidth, isWeb } from '../../utils/dimensions';
import { getBiteShareId } from '../../utils/formatDataFromServer';
import TeamsShareModal from '../modals/TeamsShareModal/TeamsShareModal';
import SharePanelBaseShare from './components/SharePanelBaseShare';
import SharePanelHeader from './components/SharePanelHeader';
import SharePanelModal from './components/SharePanelModal';
import SharePanelViewSettings from './components/SharePanelViewSettings';
import { useShare } from './hooks/useShare';
import Modal from '../modals/ModalController';
import SharePanelFullScreenViewSettings from './components/SharePanelFullScreenViewSettings';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import RequestNotificationPermissionsModal from '../modals/RequestNotificationPermissionsModal';
import useUserCanEdit from '../shared/useUserCanEdit';

interface IProps {}

const SharePanel: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSettingsVisible, setSettingsVisible] = useState(false);
  const [tmpShareMode, setTmpShareMode] = useState<EShareMode | null>(null);

  const organizationCode = useSelector(organizationCodeSelector);
  const isVisibleSharePanel = useSelector(sharePanelVisibleSelector);
  const shareData = useSelector(shareDataSelector);
  const shareItemType = useSelector(shareItemTypeSelector);
  const isInspirationBite = useSelector(shareItemIsInspirationBiteSelector);
  const shareViewMode = useSelector(shareViewModeSelector);
  const shareCallbackType = useSelector(sharePanelCallbackTypeSelector);
  const sharePanelFrom = useSelector(sharePanelFromSelector);

  const isFullView = shareViewMode === ESharePanelViewMode.FULL;
  const isOnlySettingsView = shareViewMode === ESharePanelViewMode.VIEW_OPTIONS;
  const isFullScreen = shareViewMode === ESharePanelViewMode.FULLSCREEN;

  const {
    hasTeamsShare,
    shareModeTitle,
    shareModeDescription,
    isAwaitingOpenTeams,
    isShareWithTeamsModalOpen,
    handleOpenTeamsModal,
    handleCloseTeamsModal,
    handleShareItem,
    shareLinkParams,
    handleCloseNotificationPermissionModal,
    handleOpenNotificationPermissionModal,
    isRequestNotificationPermissionsModalOpen,
  } = useShare({
    shareData,
    shareItemType,
    shareMode: tmpShareMode,
    isInspirationBite,
    from: sharePanelFrom,
  });

  const orgCode = organizationCode && organizationCode[0]?.code;
  const userCanEdit = useUserCanEdit(shareData);

  useEffect(() => {
    if (shareData) {
      setTmpShareMode(shareData.sharing_mode);
    }
  }, [shareData]);

  // reset state
  useEffect(() => {
    if (!isVisibleSharePanel) {
      setSettingsVisible(false);
      setTmpShareMode(null);
    }
  }, [isVisibleSharePanel]);

  useEffect(() => {
    setSettingsVisible(isOnlySettingsView);
  }, [isOnlySettingsView]);

  const handleToggleSettings = useCallback(() => {
    setSettingsVisible((state) => !state);
  }, []);

  const handleHideSharePanel = useCallback(() => {
    dispatch(hideSharePanel());
  }, [dispatch]);

  const handlePanelHideSuccessfully = useCallback(() => {
    if (!isAwaitingOpenTeams) {
      dispatch(clearSharePanelState());
    }
    dispatch(sharePanelSetIsHiding(false));
  }, [dispatch, isAwaitingOpenTeams]);

  const handleChangeShareMode = useCallback(
    async (shareMode: EShareMode) => {
      setTmpShareMode(shareMode);

      const params: IChangeItemShareMode = {
        itemId: shareData?.id,
        from: sharePanelFrom,
        callbackType: shareCallbackType,
        body: { sharing_mode: shareMode },
      };

      const action = shareItemType === EShareItemType.BITE ? changeBiteShareMode : changePlaylistShareMode;

      dispatch(action(params));
    },
    [shareData?.id, sharePanelFrom, shareCallbackType, shareItemType, dispatch],
  );

  const handleSwipeDown = useCallback(() => {
    if (isSettingsVisible) {
      setSettingsVisible(false);
      return;
    }

    handleHideSharePanel();
  }, [isSettingsVisible, setSettingsVisible, handleHideSharePanel]);

  const handleSwipeUp = useCallback(() => {
    setSettingsVisible(true);
  }, [setSettingsVisible]);

  const onSwipeComplete = useCallback(
    ({ swipingDirection }) => {
      if (swipingDirection === 'down') {
        handleSwipeDown();
      }

      if (swipingDirection === 'up') {
        handleSwipeUp();
      }
    },
    [handleSwipeDown, handleSwipeUp],
  );

  if (isFullScreen) {
    return (
      <Modal
        isVisible={isVisibleSharePanel}
        onModalHide={handlePanelHideSuccessfully}
        hideModalContentWhileAnimating
        useNativeDriver
      >
        <S.FullscreenContainer>
          <S.Header onBackButtonPress={handleHideSharePanel} title={t('share.fullscreenTitle')} />
          {shareData ? (
            <>
              <SharePanelFullScreenViewSettings
                sharingMode={tmpShareMode}
                accessCode={orgCode}
                onChangeOption={handleChangeShareMode}
              />
            </>
          ) : null}
        </S.FullscreenContainer>
      </Modal>
    );
  }

  return (
    <>
      <SharePanelModal
        isVisible={isVisibleSharePanel}
        onClose={handleHideSharePanel}
        onModalHide={handlePanelHideSuccessfully}
        onSwipeComplete={onSwipeComplete}
        useNativeDriver
        propagateSwipe
      >
        {shareData ? (
          <>
            <SharePanelHeader
              data={shareData}
              shareModeTitle={shareModeTitle}
              shareModeDescription={shareModeDescription}
              hasChangeBtn={userCanEdit && isFullView}
              isSettingsVisible={isSettingsVisible}
              onChangeViewSettings={handleToggleSettings}
            />

            {isFullView ? (
              <SharePanelBaseShare
                shareLinkParams={shareLinkParams}
                disabled={tmpShareMode === EShareMode.NO_ONE}
                onTeamShare={handleOpenTeamsModal}
                onShare={handleShareItem}
                hasTeamsShare={hasTeamsShare}
                content={shareItemType === EShareItemType.BITE ? t('common.shareBite') : t('common.Share')}
              />
            ) : null}

            <SharePanelViewSettings
              isVisible={isSettingsVisible}
              sharingMode={tmpShareMode}
              accessCode={orgCode}
              onChangeOption={handleChangeShareMode}
            />
            {!isWeb && !isSettingsVisible && <S.Space />}
          </>
        ) : null}
      </SharePanelModal>

      {isShareWithTeamsModalOpen ? (
        <TeamsShareModal
          isVisible={isShareWithTeamsModalOpen}
          closeModal={handleCloseTeamsModal}
          shareId={
            shareItemType === EShareItemType.BITE ? getBiteShareId(shareData as IBiteItem | IBiteShare) : shareData.id
          }
          onShare={handleOpenNotificationPermissionModal}
        />
      ) : null}
      <RequestNotificationPermissionsModal
        isVisible={isRequestNotificationPermissionsModalOpen}
        onClose={handleCloseNotificationPermissionModal}
      />
    </>
  );
};

const S = {
  Space: styled.View`
    height: ${calcWidth(38)}px;
  `,
  FullscreenContainer: styled.View`
    flex: 1;
    background: ${({ theme }) => theme.colors.white};
  `,
  HeaderTitle: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
  `,
  Header: styled(Header)`
    max-width: ${calcWidth(370)}px;
    align-self: center;
  `,
};

export default memo(SharePanel);
