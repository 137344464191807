import React, { FC, memo, useCallback, useRef } from 'react';
import { IStackNavigation } from '../../../navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { createBiteSelector, introMediaProcessingStatusSelector } from '../../../store/createBite/createBite.selectors';
import AIExpectation from './AIExpectation';
import Routes from '../../../navigation/routes';
import getHasEnhancementsEdit from '../../../utils/introMedia/getHasEnhancementsEdit';
import { CommonActions } from '@react-navigation/native';
import { log } from '../../../store/appActivity/appActivity.slice';
import { EImproves } from './aiExpectation.constants';

const IMPROVES = [
  EImproves.soundQuality,
  EImproves.backgroundNoise,
  EImproves.subtitles,
  EImproves.biteName,
  EImproves.biteCover,
];
interface IProps extends IStackNavigation {}

const AIExpectationSecond: FC<IProps> = ({ navigation, route }) => {
  const dispatch = useDispatch();

  const { introEnhancements, introSubtitles } = useSelector(createBiteSelector);
  const introMediaProcessingStatus = useSelector(introMediaProcessingStatusSelector);

  const isSuccess =
    introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.SUCCESS ||
    introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.SUCCESS;

  const isProcessing =
    !isSuccess &&
    (introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.INACTIVE ||
      introMediaProcessingStatus.subtitles === EIntroMediaProcessingStatus.PROCESSING ||
      introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.INACTIVE ||
      introMediaProcessingStatus.enhancements === EIntroMediaProcessingStatus.PROCESSING);

  const isAINotApplicable = !isSuccess && !isProcessing;

  const openTsRef = useRef(Date.now());

  const handleContinue = useCallback(() => {
    const hasEnhancementsEdit = getHasEnhancementsEdit({
      introEnhancements,
      introSubtitles,
    });

    const statusLabel = isSuccess ? 'Success' : isAINotApplicable ? 'AI Not Applicable' : 'Processing';
    dispatch(
      log({
        event: `AIExpectationSecond: continue from ${statusLabel}`,
        data: {
          introMediaProcessingStatus,
          hasEnhancementsEdit,
          introEnhancements,
          introSubtitles,
          screenTs: Date.now() - openTsRef.current,
          statusLabel,
        },
      }),
    );

    if (hasEnhancementsEdit) {
      navigation.replace(Routes.CreateBiteStack.BitesMagic, { isPrefilled: route.params?.isPrefilled });
      return;
    }

    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {
            name: Routes.CreateBiteStack.FinalScreen,
          },
        ],
      }),
    );
  }, [
    dispatch,
    introEnhancements,
    introMediaProcessingStatus,
    introSubtitles,
    isAINotApplicable,
    isSuccess,
    navigation,
    route.params?.isPrefilled,
  ]);

  return (
    <AIExpectation
      isSuccess={isSuccess}
      isAINotApplicable={isAINotApplicable}
      initialProgress={70}
      improves={IMPROVES}
      onContinue={handleContinue}
    />
  );
};

export default memo(AIExpectationSecond);
