import React, { memo } from 'react';
import { IAttributeValue, IGuestsAttributeValue, TDisplayMode } from '../../../../store/attributes/attributes.types';
import styled, { css } from 'styled-components/native';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../../utils/dimensions';
import Divider from '../../../../components/shared/Divider';
import { getIsWebRtl } from '../../../../locale/i18n';
import { absoluteValueTextDataSet, percentageValueTextDataSet } from '../../analytics.constants';

interface IProps {
  item: IAttributeValue | IGuestsAttributeValue;
  withDivider?: boolean;
  displayMode: TDisplayMode;
  hasMultipleValues?: boolean;
  isViewsTabEnabled: boolean;
  isAnsweredTabEnabled: boolean;
  isCommentsTabEnabled: boolean;
  isBoldTitle?: boolean;
  isLastItem?: boolean;
  dataSet?: any;
}

const AttributeValueItem: React.FC<IProps> = ({
  item,
  withDivider,
  displayMode,
  hasMultipleValues,
  isViewsTabEnabled,
  isAnsweredTabEnabled,
  isCommentsTabEnabled,
  isBoldTitle,
  dataSet,
}) => {
  const isRtl = getIsWebRtl();
  return (
    <S.ListItemContainer
      // @ts-ignore
      dataSet={dataSet}
    >
      <S.ListItem isRtl={isRtl}>
        <S.ListItemTitle isRtl={isRtl} isBoldTitle={isBoldTitle}>
          {item.name}
        </S.ListItemTitle>
        <S.IconsContainer isRtl={isRtl}>
          {item.views_absolute !== undefined && isViewsTabEnabled && (
            <S.IconContainer>
              {displayMode === 'absolute' ? (
                <S.ValueText
                  // @ts-ignore
                  dataSet={absoluteValueTextDataSet}
                >{`${item.views_absolute}${hasMultipleValues ? '' : '/' + item.total_users_count}`}</S.ValueText>
              ) : (
                <S.ValueText
                  // @ts-ignore
                  dataSet={percentageValueTextDataSet}
                >
                  {`${item.total_users_count ? ((item.views_absolute / item.total_users_count) * 100).toFixed(0) : 0}%`}
                </S.ValueText>
              )}
            </S.IconContainer>
          )}
          {item.answers_absolute !== undefined && isAnsweredTabEnabled && (
            <S.IconContainer>
              <S.ValueText>
                {displayMode === 'absolute'
                  ? `${item.answers_absolute}${hasMultipleValues ? '' : '/' + item.total_users_count}`
                  : `${
                      item.total_users_count ? ((item.answers_absolute / item.total_users_count) * 100).toFixed(0) : 0
                    }%`}
              </S.ValueText>
            </S.IconContainer>
          )}
          {item.comments_absolute !== undefined && isCommentsTabEnabled && (
            <S.IconContainer>
              <S.ValueText>
                {displayMode === 'absolute'
                  ? `${item.comments_absolute}${hasMultipleValues ? '' : '/' + item.total_users_count}`
                  : `${
                      item.total_users_count ? ((item.comments_absolute / item.total_users_count) * 100).toFixed(0) : 0
                    }%`}
              </S.ValueText>
            </S.IconContainer>
          )}
        </S.IconsContainer>
      </S.ListItem>

      {withDivider && <S.Divider />}
    </S.ListItemContainer>
  );
};

const S = {
  ListItemContainer: styled.View`
    padding: 0 ${calcWidth(19)}px;
  `,
  ListItem: styled.View<{ isRtl: boolean }>`
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
    padding: ${calcHeight(16)}px 0;
  `,
  ListItemTitle: styled.Text<{ isRtl: boolean; isBoldTitle: boolean }>`
    flex: 3;
    max-width: ${isWeb ? calcWidth(370) : deviceWidth - calcWidth(180)}px;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ theme, isBoldTitle }) => (isBoldTitle ? theme.fontSizes.s15 : theme.fontSizes.s14)}px;
    line-height: ${({ theme, isBoldTitle }) => (isBoldTitle ? theme.fontSizes.s15 : theme.fontSizes.s14)}px;
    text-align: ${({ isRtl }) => (isRtl ? 'right' : 'left')};
    font-weight: ${({ isBoldTitle }) => (isBoldTitle ? 700 : 400)};
    ${({ isRtl }) =>
      isRtl
        ? css`
            padding-left: ${calcWidth(12)}px;
          `
        : css`
            padding-right: ${calcWidth(12)}px;
          `}
  `,
  IconsContainer: styled.View<{ isRtl: boolean }>`
    flex: 1;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    width: ${isWeb ? calcWidth(512) : deviceWidth - calcWidth(38)}px;
    justify-content: flex-end;
    align-items: center;
    ${({ isRtl }) =>
      isRtl
        ? css`
            margin-right: ${calcWidth(10)}px;
          `
        : css`
            margin-left: ${calcWidth(10)}px;
          `}
  `,
  IconContainer: styled.View`
    align-items: center;
    height: ${calcHeight(24)}px;
    justify-content: center;
    width: ${calcWidth(50)}px;
  `,
  Divider: styled(Divider)`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  ValueText: styled.Text`
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    text-align: center;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes.s14}px;
    line-height: ${({ theme }) => theme.fontSizes.s14}px;
  `,
};

export default memo(AttributeValueItem);
