import React, { FC } from 'react';
import styled from 'styled-components/native';
import ToggleSwitch, { ToggleSwitchProps } from 'toggle-switch-react-native';
import { useTheme } from 'styled-components/native';

interface IProps extends ToggleSwitchProps {
  scale?: number;
}

const Switch: FC<IProps> = ({ scale = 0.6, ...switchProps }) => {
  const theme = useTheme();
  return (
    <S.SwitchContainer scale={scale}>
      <ToggleSwitch
        size='large'
        onColor={theme.colors.primaryBlue}
        offColor={theme.colors.lightGray9}
        // @ts-ignore
        thumbOnStyle={{
          left: 0,
        }}
        thumbOffStyle={{
          left: 0,
        }}
        {...switchProps}
      />
    </S.SwitchContainer>
  );
};

const SwitchContainer = styled.View<{ scale: number }>`
  transform: scale(${({ scale }) => scale});
`;

const S = { SwitchContainer };

export default Switch;
