import store from '../../store';
import { log } from '../../store/appActivity/appActivity.slice';
import { getIsFeatureEnabledForCurrentState } from '../featureFlags';
import { IUploadMultiUploadToS3WithDetails, IUploadSingleUploadToS3WithDetails, IUploadToS3 } from './uploadToS3.types';
import prepareS3SinglePartUploadDetails from './prepareS3SinglePartUploadDetails';
import prepareS3MultiUploadDetails from './prepareS3MultiUploadDetails';
import { isWeb } from '../dimensions';
import { DEFAULT_MULTI_UPLOAD_PART_SIZE } from '../constants/common';
import { IGetBlobFromLocalFileResult } from '../getBlobFromLocalFile';
import { FileWithPath } from 'react-dropzone';

interface IProps extends IUploadToS3 {
  logContext: any;
  filePartsCached: (string | Blob)[];
  getBlobFromLocalFile: () => Promise<IGetBlobFromLocalFileResult>;
}

const prepareS3UploadDetails = async ({
  uri,
  file,
  processId,
  endpoint,
  multiPartEndpoints,
  orgId,
  mediaType,
  logContext,
  filePartsCached,
  getBlobFromLocalFile,
}: IProps) => {
  const getIsWithMultipart = () => {
    const isMultiPartUploadEnabled = getIsFeatureEnabledForCurrentState('multiPartUpload');
    const { preSignEndpoint, completeEndpoint } = multiPartEndpoints;

    const isWithMultipart = isMultiPartUploadEnabled && preSignEndpoint && completeEndpoint;

    if (!isWithMultipart) {
      store.dispatch(
        log({
          event: 'getIsWithMultipart: multipart upload is not applicable',
          processId,
          data: {
            isMultiPartUploadEnabled,
            preSignEndpoint,
            completeEndpoint,
          },
        }),
      );
    }

    return isWithMultipart;
  };

  const getPartSize = () => {
    const config = store.getState()?.appActivity?.config;
    let partSize = config?.s3MultiPartUpload?.partSize || DEFAULT_MULTI_UPLOAD_PART_SIZE;
    partSize = partSize < DEFAULT_MULTI_UPLOAD_PART_SIZE ? DEFAULT_MULTI_UPLOAD_PART_SIZE : partSize;
    return partSize;
  };

  const splitIntoChunks = (props: { blob: Blob | FileWithPath; partSize: number }) => {
    const { blob, partSize } = props;

    const fileSize = blob.size;
    const partsNum = Math.ceil(fileSize / partSize);

    const parts = [];

    for (let i = 0; i < partsNum; i++) {
      const start = partSize * i;
      const end = Math.min(start + partSize, fileSize);

      const part = blob.slice(start, end);
      parts.push(part);
    }

    return parts;
  };

  const baseDetails = {
    uri,
    file,
    processId,
    mediaType,
  };

  store.dispatch(
    log({
      event: 'prepareS3UploadDetails: start',
      data: {
        ...logContext,
      },
    }),
  );

  const getSingleUploadDetails = async () => {
    const startTs = Date.now();

    store.dispatch(
      log({
        event: 'getSingleUploadDetails: start',
        processId,
      }),
    );

    const { fileName, s3UploadDetails } = await prepareS3SinglePartUploadDetails({
      uri,
      file,
      processId,
      orgId,
      endpoint,
    });

    const result = {
      ...baseDetails,
      s3UploadDetails,
      fileName,
    };

    store.dispatch(
      log({
        event: 'getSingleUploadDetails: done',
        processId,
        data: {
          result,
        },
        metrics: {
          prepareSingleS3UploadDetailsTs: Date.now() - startTs,
        },
      }),
    );

    return result;
  };

  const getMultiUploadDetails = async (): Promise<
    Omit<IUploadMultiUploadToS3WithDetails, 'parallelUploadsNum' | 'fileParts' | 'fileSize'>
  > => {
    const isWithMultipart = getIsWithMultipart();
    if (!isWithMultipart) {
      return null;
    }

    const { preSignEndpoint, completeEndpoint } = multiPartEndpoints;

    const startTs = Date.now();

    store.dispatch(
      log({
        event: 'getMultiUploadDetails: start',
        processId,
      }),
    );

    const { fileName, s3UploadDetails, partsNumArray } = await prepareS3MultiUploadDetails({
      processId,
      uri,
      file,
      endpoint: preSignEndpoint,
      getBlobFromLocalFile,
    });

    const result = {
      ...baseDetails,
      s3UploadDetails,
      fileName,
      completeEndpoint,
      partsNumArray,
    };

    store.dispatch(
      log({
        event: 'getMultiUploadDetails: done',
        processId,
        data: {
          result,
        },
        metrics: {
          prepareMultiS3UploadDetailsTs: Date.now() - startTs,
        },
      }),
    );

    return result;
  };

  const getFilePartsForFile = isWeb
    ? // function has to be marked as async,
      // because it is used in Promise.all with a catch
      async () => {
        const partSize = getPartSize();

        const fileSize = file.size;

        const isWithMultipart = getIsWithMultipart();
        if (!isWithMultipart) {
          return null;
        }

        const parts = splitIntoChunks({ blob: file, partSize });

        store.dispatch(
          log({
            event: 'getFilePartsForFile: done',
            processId,
            data: {
              partsNum: parts.length,
            },
            metrics: {
              fileSize,
            },
          }),
        );

        return parts;
      }
    : async () => {
        const isWithMultipart = getIsWithMultipart();
        if (!isWithMultipart) {
          return null;
        }

        if (filePartsCached) {
          store.dispatch(
            log({
              event: 'getFilePartsForFile: fileParts are already available',
              processId,
              data: {
                filePartsCached: filePartsCached.length,
              },
            }),
          );
          return filePartsCached;
        }

        const startTs = Date.now();

        const partSize = getPartSize();

        store.dispatch(
          log({
            event: 'getFilePartsForFile: start',
            processId,
            data: {
              partSize,
            },
          }),
        );

        const { blob } = await getBlobFromLocalFile();
        const fileSize = blob.size;

        const parts = splitIntoChunks({ blob, partSize });

        store.dispatch(
          log({
            event: 'getFilePartsForFile: getFileSizeLocal',
            processId,
          }),
        );

        store.dispatch(
          log({
            event: 'getFilePartsForFile: done',
            processId,
            data: {
              partsNum: parts.length,
            },
            metrics: {
              fileSize,
              getFilePartsTsPerMb: (Date.now() - startTs) / (fileSize / 1024 / 1024),
              getFilePartsTs: Date.now() - startTs,
            },
          }),
        );

        return parts;
      };

  const [singleUploadDetails, multiUploadDetails, fileParts]: [
    singleUploadDetails: IUploadSingleUploadToS3WithDetails,
    multiUploadDetails: IUploadMultiUploadToS3WithDetails,
    fileParts: string[],
  ] = await Promise.all([
    // single
    getSingleUploadDetails().catch(() => {
      store.dispatch(
        log({
          ...logContext,
          event: 'prepareS3UploadDetails: single - failed to get upload details',
        }),
      );
      return null;
    }),

    // multipart
    getMultiUploadDetails().catch(() => {
      store.dispatch(
        log({
          ...logContext,
          event: 'prepareS3UploadDetails: multi - failed to get upload details',
        }),
      );
      return null;
    }),

    // file parts
    getFilePartsForFile().catch((error) => {
      store.dispatch(
        log({
          ...logContext,
          event: 'prepareS3UploadDetails: failed to get file parts',
          data: {
            error,
          },
        }),
      );
      return null;
    }),
  ]);

  if (!singleUploadDetails && (!multiUploadDetails || !fileParts)) {
    store.dispatch(
      log({
        ...logContext,
        event: 'prepareS3UploadDetails: failed to get upload details',
        data: {
          singleUploadDetails,
          multiUploadDetails,
          filePartsNum: fileParts?.length,
        },
      }),
    );
    throw { message: 'prepareS3UploadDetails: failed to get upload details' };
  }

  if (multiUploadDetails && fileParts) {
    multiUploadDetails.fileParts = fileParts;
  }

  return {
    singleUploadDetails,
    multiUploadDetails: fileParts ? multiUploadDetails : null,
    getMultiUploadDetails,
  };
};
export default prepareS3UploadDetails;
