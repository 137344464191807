import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { EPanel } from '../../../../store/homeScreen/homeScreen.types';
import { calcFontSize, calcHeight, calcWidth, isWeb } from '../../../../utils/dimensions';
import { togglePanel } from '../../../../store/homeScreen/homeScreen.slice';
import { activePanelSelector } from '../../../../store/homeScreen/homeScreen.selectors';
import NewObjectPanel from './NewObjectPanel';
import OrganizationsPanel from './OrganizationsPanel';
import { IS_IOS } from '../../../../utils/constants/env';
import FeedSortingPanel from './FeedSortingPanel';
import FeedObjectTypePanel from './FeedObjectTypePanel';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import FeedCreatorsPanel from './FeedCreatorsPanel';
import { ViewStyle } from 'react-native';

interface IProps {}

const Panels: React.FC<IProps> = ({}) => {
  const dispatch = useDispatch();

  const activePanel = useSelector(activePanelSelector);

  const animatedNewObjectPanel = useSharedValue(0);
  const animatedOrganizationPanel = useSharedValue(0);
  const animatedFeedSortingPanel = useSharedValue(0);
  const animatedFeedObjectTypePanel = useSharedValue(0);
  const animatedFeedCreatorsPanel = useSharedValue(0);

  const closePanels = useCallback(() => {
    dispatch(togglePanel(null));
  }, [dispatch]);

  useEffect(() => {
    animatedNewObjectPanel.value = activePanel === EPanel.NEW_OBJECT ? -calcHeight(285) : 0;
    animatedOrganizationPanel.value = activePanel === EPanel.ORGANIZATIONS ? -calcHeight(isWeb ? 230 : 260 + 60) : 0;
    animatedFeedSortingPanel.value = activePanel === EPanel.FEED_SORTING ? -calcHeight(isWeb ? 100 : 190) : 0;
    animatedFeedObjectTypePanel.value = activePanel === EPanel.FEED_OBJECT_TYPE ? -calcHeight(isWeb ? 180 : 270) : 0;
    animatedFeedCreatorsPanel.value = activePanel === EPanel.CREATORS ? -calcHeight(isWeb ? 200 : 310) : 0;

    if (activePanel === null) {
      dispatch(
        trackEvent({
          event: 'close_panel',
        }),
      );
    }
  }, [
    activePanel,
    animatedFeedCreatorsPanel,
    animatedFeedObjectTypePanel,
    animatedFeedSortingPanel,
    animatedNewObjectPanel,
    animatedOrganizationPanel,
    dispatch,
  ]);

  const animatedNewObjectPanelStyle: Animated.AnimateStyle<ViewStyle> = useMemo(
    () => ({
      position: 'absolute',
      left: 0,
      right: 0,
      top: '100%',
      marginTop: 0,
    }),
    [],
  );

  const newObjectAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        ...animatedNewObjectPanelStyle,
        transform: [
          {
            translateY: withTiming(animatedNewObjectPanel.value, {
              duration: 100,
              easing: Easing.linear,
            }),
          },
        ],
      } as ViewStyle),
    [],
  );

  const organizationPanelAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        ...animatedNewObjectPanelStyle,
        transform: [
          {
            translateY: withTiming(animatedOrganizationPanel.value, {
              duration: 100,
              easing: Easing.linear,
            }),
          },
        ],
      } as ViewStyle),
    [],
  );

  const feedSortingPanelAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        ...animatedNewObjectPanelStyle,
        transform: [
          {
            translateY: withTiming(animatedFeedSortingPanel.value, {
              duration: 100,
              easing: Easing.linear,
            }),
          },
        ],
      } as ViewStyle),
    [],
  );

  const feedObjectTypePanelAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        ...animatedNewObjectPanelStyle,
        transform: [
          {
            translateY: withTiming(animatedFeedObjectTypePanel.value, {
              duration: 100,
              easing: Easing.linear,
            }),
          },
        ],
      } as ViewStyle),
    [],
  );

  const feedCreatorsPanelAnimatedStyle = useAnimatedStyle(
    () =>
      ({
        ...animatedNewObjectPanelStyle,
        transform: [
          {
            translateY: withTiming(animatedFeedCreatorsPanel.value, {
              duration: 100,
              easing: Easing.linear,
            }),
          },
        ],
      } as ViewStyle),
    [],
  );

  return (
    <>
      {activePanel !== null ? <S.Backdrop onPress={closePanels} /> : null}
      <Animated.View style={newObjectAnimatedStyle}>
        <NewObjectPanel />
      </Animated.View>
      <Animated.View style={organizationPanelAnimatedStyle}>
        <OrganizationsPanel />
      </Animated.View>
      <Animated.View style={feedSortingPanelAnimatedStyle}>
        <FeedSortingPanel />
      </Animated.View>
      <Animated.View style={feedObjectTypePanelAnimatedStyle}>
        <FeedObjectTypePanel />
      </Animated.View>
      <Animated.View style={feedCreatorsPanelAnimatedStyle}>
        <FeedCreatorsPanel />
      </Animated.View>
    </>
  );
};

const S = {
  Container: styled.View`
    width: 100%;
  `,
  CurveTopIcon: styled.View`
    position: absolute;
    top: ${calcWidth(-22)}px;
    align-items: center;
    width: 100%;
  `,
  ControlsContainer: styled.View<{ insets: { bottom: number } }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${calcHeight(4)}px ${calcWidth(16)}px ${({ insets }) => insets.bottom + calcHeight(4)}px;
    width: 100%;
    height: ${calcHeight(IS_IOS ? 90 : 65)}px;
    background-color: ${({ theme }) => theme.colors.white};
  `,
  LeftContainer: styled.View``,
  ExploreButton: styled.TouchableOpacity`
    align-items: center;
  `,
  CenterContainer: styled.View`
    top: ${calcWidth(-8)}px;
  `,
  CenterAbsContainer: styled.View`
    position: absolute;
    top: ${calcWidth(-8)}px;
    left: 50%;
    margin-left: -${calcWidth(30)}px;
  `,
  PlusButton: styled.TouchableOpacity<{ isDisabled: boolean }>`
    justify-content: center;
    align-items: center;
    width: ${calcWidth(60)}px;
    height: ${calcWidth(60)}px;
    border-radius: ${calcWidth(60) / 2}px;
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  `,
  RightContainer: styled.View``,
  OrganizationButton: styled.TouchableOpacity`
    align-items: center;
  `,
  LabelContainer: styled.View`
    margin-top: ${calcWidth(4)}px;
  `,
  Label: styled.Text`
    font-size: ${calcFontSize(10)}px;
    font-family: ${({ theme }) => theme.fontFamilies.OpenSansHebrew};
    text-transform: capitalize;
  `,
  CompanyLogoContainer: styled.View<{ isSelected: boolean }>`
    width: ${calcWidth(30)}px;
    height: ${calcHeight(30)}px;
    border: 1px ${({ theme, isSelected }) => (isSelected ? theme.colors.primaryBlue : 'transparent')} solid;
    border-radius: ${calcHeight(15)}px;
    overflow: hidden;
  `,
  CompanyLogo: styled.Image`
    resize-mode: contain;
    width: ${calcWidth(30)}px;
    height: ${calcHeight(30)}px;
  `,
  Backdrop: styled.TouchableOpacity`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.transparentBlack};
  `,
};

export default Panels;
