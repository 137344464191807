import React, { useCallback } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { IStackNavigation } from '../../navigation/types';
import IntroEnhancements from '../../components/introEnhancements';
import { clearBiteMedia, saveIntroEnhancementsChanges } from '../../store/createBite/createBites.actions';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';

interface IProps extends IStackNavigation {}

const EditBiteIntroEnhancements: React.FC<IProps> = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const dispatch = useDispatch();

  useFocusEffect(() => {
    dispatch(clearBiteMedia());
    dispatch(
      trackEvent({
        event: 'pageview',
        props: { page_title: 'Enhancements', from: 'edit' },
      }),
    );
  });

  const handleNextButtonPress = useCallback(() => {
    dispatch(saveIntroEnhancementsChanges());
    navigation.goBack();
  }, [dispatch, navigation]);

  return <IntroEnhancements isEdit useVimeo onNext={handleNextButtonPress} />;
};

export default EditBiteIntroEnhancements;
