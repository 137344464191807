import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  filterAttributesMapByIdSelector,
  initialAttributesMapByIdSelector,
  organizationAttributesSelector,
} from '../../../store/attributes/attributes.selector';

const useHasAttributesToRender = ({ attributesMap }) => {
  const organizationAttributes = useSelector(organizationAttributesSelector);
  const filterAttributesMapById = useSelector(filterAttributesMapByIdSelector);
  const initialAttributesMap = useSelector(initialAttributesMapByIdSelector);

  const hasAttributesToRender = useMemo(
    () =>
      organizationAttributes
        .filter((attr) => !filterAttributesMapById[attr.id].selectedValues.length)
        .some(
          (attr) =>
            attributesMap[attr.id].error ||
            attributesMap[attr.id].isLoading ||
            (initialAttributesMap[attr.id].data.values.length && attr.values === null) ||
            attr.values?.length,
        ),
    [attributesMap, filterAttributesMapById, initialAttributesMap, organizationAttributes],
  );

  return {
    hasAttributesToRender,
  };
};

export default useHasAttributesToRender;
