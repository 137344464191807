import React, { FC, useCallback, useMemo, useState } from 'react';
import styled, { css, useTheme, DefaultTheme } from 'styled-components/native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/core';

import { calcHeight, calcWidth, verticalScale, isWeb } from '../../../utils/dimensions';
import { CustomText } from '../../../components/shared';
import QuestionOptions from './QuestionOptions';
import { ISelectedBite } from '../../../store/createPlaylist/createPlaylist.types';
import ThreeDotsSvg from '../../../assets/icons/three-dots.svg';
import BottomButtonsModal from '../../../components/modals/BottomButtonsModal';
import { IButtonsConfig } from '../../../components/modals/BottomButtonsModal/BottomButtonsModal';
import { cleanEditAndCreateBiteState, fetchBiteToEditRequest } from '../../../store/bite/bite.actions';
import PlusIcon from '../../../assets/icons/plus.svg';
import Routes from '../../../navigation/routes';
import { QuizIntroItemDataSet } from './QuizIntro.constants';
import { StackNavigationProp } from '@react-navigation/stack';

const iconStyles = {
  height: verticalScale(25),
  width: verticalScale(25),
  fill: '#FFF',
};

interface IProps {
  bite: ISelectedBite;
  image: string;
  isOwner: boolean;
}

const QuizIntroItem: FC<IProps> = ({ bite, image, isOwner }) => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const theme = useTheme();
  const styles = getStyles(theme);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bottomButtonsModalConfig: IButtonsConfig = useMemo(() => {
    const topButtons = [];

    if (isOwner && bite?.id) {
      topButtons.push({
        text: t('common.edit'),
        action: () => {
          dispatch(fetchBiteToEditRequest(bite.id));
          navigation.navigate(Routes.CreateQuizStack.StackName, {
            screen: Routes.CreateQuizStack.QuizTellYourStory,
            params: { isEditMode: true },
          });
          setIsModalOpen(false);
        },
      });
    }

    return {
      topButtons,
      openLink: () => null,
    };
  }, [bite, dispatch, isOwner, navigation, t]);

  const imageSource = useMemo(
    () => ({
      uri: image,
    }),
    [image],
  );

  const handleAddIntro = useCallback(() => {
    dispatch(cleanEditAndCreateBiteState());
    navigation.navigate(Routes.CreateQuizStack.StackName, {
      screen: Routes.CreateQuizStack.QuizTellYourStory,
    });
  }, [dispatch, navigation]);

  const renderLeftActions = useCallback(
    (progress, dragX) => <QuestionOptions t={t} progress={progress} dragX={dragX} />,
    [t],
  );

  const handleOpenModal = useCallback(() => setIsModalOpen(true), []);
  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  return (
    <S.Container>
      <Swipeable containerStyle={styles.swipeableContainer} renderLeftActions={renderLeftActions}>
        <S.BiteCard>
          {bite ? (
            <S.PreviewImage source={imageSource} />
          ) : (
            <S.AddIntro onPress={handleAddIntro}>
              <PlusIcon {...iconStyles} />
            </S.AddIntro>
          )}
          <S.BiteTitleContainer>
            <S.BiteTitle lineBreakMode='tail'>{t('createQuizStack.editQuiz.intro')}</S.BiteTitle>
          </S.BiteTitleContainer>
          {bite && bottomButtonsModalConfig.topButtons.length > 0 && (
            <S.TouchableOpacity
              onPress={handleOpenModal}
              //@ts-ignore
              dataSet={QuizIntroItemDataSet}
            >
              <ThreeDotsSvg fill={theme.colors.darkGray1} width={28} height={28} />
            </S.TouchableOpacity>
          )}
          <BottomButtonsModal
            isVisible={isModalOpen}
            closeModal={handleCloseModal}
            buttonsConfig={bottomButtonsModalConfig}
          />
        </S.BiteCard>
      </Swipeable>
    </S.Container>
  );
};

const cardHeight = 55;

const getStyles = (theme: DefaultTheme) =>
  StyleSheet.create({
    swipeableContainer: {
      flex: 1,
      height: calcHeight(cardHeight),
      width: '100%',
      backgroundColor: theme.colors.white,
      borderRadius: 10,
      justifyContent: 'center',
      borderColor: theme.colors.lightGray5,
      borderWidth: 1,
    },
  });

const Container = styled.View`
  width: ${calcWidth(315)}px;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${calcHeight(10)}px;
`;

const DragIconContainer = styled.TouchableOpacity`
  padding-right: ${calcWidth(12)}px;
`;

const BiteCard = styled.View`
  flex-direction: row;
  height: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const PreviewImage = styled.Image`
  height: ${calcHeight(cardHeight - calcHeight(7.5))}px;
  width: ${calcHeight(cardHeight - calcHeight(7.5))}px;
  border-radius: 10px;
  margin-left: ${calcWidth(3)}px;
  background-color: ${({ theme }) => theme.colors.lightGray1};
`;

const BiteTitleContainer = styled.View`
  flex: 1;
  width: ${() => (isWeb ? '110px' : 'auto')};
  margin: 0 ${calcWidth(18)}px;
`;

const BiteTitle = styled(CustomText)(
  ({ theme }) => css`
    font-family: ${theme.fontFamilies.GilroyMedium};
    color: ${theme.colors.primaryBlue};
  `,
);

const TouchableOpacity = styled.TouchableOpacity`
  position: absolute;
  right: ${calcWidth(8)}px;
`;

const AddIntro = styled.TouchableOpacity`
  height: ${calcHeight(cardHeight - calcHeight(7.5))}px;
  width: ${calcHeight(cardHeight - calcHeight(7.5))}px;
  border-radius: 10px;
  margin-left: ${calcWidth(3)}px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  padding: ${calcWidth(12)}px;
`;

const S = {
  Container,
  DragIconContainer,
  PreviewImage,
  BiteCard,
  BiteTitleContainer,
  BiteTitle,
  TouchableOpacity,
  AddIntro,
};

export default QuizIntroItem;
